import React from 'react';
import CollapseCustom from '../../../components/collapse-custom';
import { useIntl, FormattedMessage } from 'react-intl';
import ReportReceiveItemFormPoActionColumns from './action-columns';
import { Input, Form, Select, DatePicker, Row, Col } from 'antd';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportReceiveItemFromPoAction = (props) => {
  const intl = useIntl();
  const { search, setSearch, setPage, setIsFetch, onFilterOrder, actionColumns, refTypeList, brand, model } = props;

  const handleApply = () => {
    setIsFetch((event) => !event);
    setPage(1);
  };

  const handleReset = () => {
    setIsFetch((event) => !event);
    setSearch((prev) => ({
      ...prev,
      warehouse: '',
      poNo: '',
      expNo: '',
      vendorOrSupplier: '',
      item: '',
      brand: [],
      model: [],
      lotNoOrSerialNo: '',
      licensePlate: '',
      remark: '',
    }));
    setPage(1);
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsWarehouse', defaultMessage: 'Warehouse' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReciveItemFromPoPHRefWarehouse', defaultMessage: 'Enter Warehouse' })}
                onChange={(e) => onFilterOrder(e.target.value, 'warehouse')}
                value={_.get(search, 'warehouse') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsPoNo', defaultMessage: 'PO No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'reportReciveItemFromPoPHRefPoNo',
                  defaultMessage: 'Enter PO No.',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'poNo')}
                value={_.get(search, 'poNo') || undefined}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsExpNo', defaultMessage: 'EXP No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReciveItemFromPoPHRefExpNo', defaultMessage: 'Enter EXP No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'expNo')}
                value={_.get(search, 'expNo') || undefined}
              />
            </Form.Item>
          </Col> */}

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsVendorOrSupplier', defaultMessage: 'Vendor or Supplier' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'reportReciveItemFromPoPHRefVendorOrSupplier',
                  defaultMessage: 'Enter Vendor or Supplier',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'vendorOrSupplier')}
                value={_.get(search, 'vendorOrSupplier') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsItem', defaultMessage: 'Item' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReciveItemFromPoPHRefItem', defaultMessage: 'Enter Item Code or Item Name' })}
                onChange={(e) => onFilterOrder(e.target.value, 'item')}
                value={_.get(search, 'item') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsBrand', defaultMessage: 'Brand' })}
                  req={false}
                />
              }
            >
              <Select
                showSearch
                allowClear={true}
                mode="multiple"
                onSearch={(value) => brand.setBrandSearch(value)}
                onChange={(value) => onFilterOrder(value, 'brand')}
                dataSource={brand.optionsBrand}
                filterOption={false}
                value={_.get(search, 'brand')}
                placeholder={intl.formatMessage({ id: 'reportReciveItemFromPoPHRefBrand', defaultMessage: 'Select Brand' })}
                // style={{ width: '100%' }}
                notFoundContent={brand.loadingBrand ? 'Loading...' : null}
              >
                {_.map(brand.optionsBrand, (item) => (
                  <Option value={item.item_brand_id}>{item.item_brand_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsModel', defaultMessage: 'Model' })}
                  req={false}
                />
              }
            >
              <Select
                showSearch
                allowClear={true}
                mode="multiple"
                onSearch={(value) => model.setModelSearch(value)}
                onChange={(value) => onFilterOrder(value, 'model')}
                dataSource={model.optionsModel}
                filterOption={false}
                value={_.size(_.get(search, 'brand')) ? _.get(search, 'model') : []}
                placeholder={intl.formatMessage({ id: 'reportReciveItemFromPoPHRefModel', defaultMessage: 'Select Model' })}
                style={{ width: '100%' }}
                notFoundContent={model.loadingModel ? 'Loading...' : null}
                disabled={_.size(_.get(search, 'brand')) ? false : true}
              >
                {_.map(model.optionsModel, (item) => (
                  <Option value={item.item_model_id}>{item.item_model_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsLotNoOrSerialNo', defaultMessage: 'Lot No. or Serial No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'reportReciveItemFromPoPHRefLotNoOrSerialNo',
                  defaultMessage: 'Enter Lot No. or Serial No.',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'lotNoOrSerialNo')}
                value={_.get(search, 'lotNoOrSerialNo') || undefined}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsLicensePlate', defaultMessage: 'License Plate' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReciveItemFromPoPHRefLicensePlate', defaultMessage: 'Enter License Plate' })}
                onChange={(e) => onFilterOrder(e.target.value, 'licensePlate')}
                value={_.get(search, 'licensePlate') || undefined}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReciveItemFromPoColumnsRemark', defaultMessage: 'Remark' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReciveItemFromPoPHRefRemark', defaultMessage: 'Enter Remark' })}
                onChange={(e) => onFilterOrder(e.target.value, 'remark')}
                value={_.get(search, 'remark') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]} >
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right', marginTop: -20 }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportReciveItemFromPoPHRefReceivedDateFrom', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportReciveItemFromPoPHRefReceivedDateTo', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => onFilterOrder(value, 'searchDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'searchDate')}
            />
            <ReportReceiveItemFormPoActionColumns actionColumns={actionColumns} />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default ReportReceiveItemFromPoAction;
