import React, { useContext, useEffect } from 'react'
import { ExpenseModal } from '.'
import { Row, Col, Input, Form, Divider, AutoComplete, Select } from 'antd'
import LabeRequireForm from '../../../../components/label-required-form'
import { useIntl } from 'react-intl'
import _ from 'lodash'

const { Option } = Select

const ExpenseFormVendor = (props) => {
  const { form } = props
  const intl = useIntl()
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { customerList, handleSearchCustomer, contactList, poSelect } = useContext(ExpenseModal)

  useEffect(() => {
    if (poSelect) {
      setFieldsValue({
        ['contact']: _.get(poSelect, 'vendorSupplierContactDetail.vendorSupplierContactId'),
      });
    }
  }, [poSelect])

  useEffect(() => {
    if (getFieldValue('contact')) {
      const filterContact = _.filter(contactList, (item) => { return item.customerContactId === getFieldValue('contact') })
      setFieldsValue({
        ['phone']: _.get(filterContact, '[0].customerContactPhone'),
      });
    }
  }, [getFieldValue('contact')])


  return (
    <div style={{ padding: '12px 24px 0 24px' }}>
      <Row gutter={[24, 2]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormVendor', defaultMessage: 'Vendor or Supplier' })} req={true} />}
          >
            {getFieldDecorator('vendor', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqVendor', defaultMessage: 'Please Select Vendor or Supplier' }),
                },
              ],
            })(
              <AutoComplete
                placeholder={intl.formatMessage({ id: 'expenseFormPHVendor', defaultMessage: 'Select Vendor or Supplier' })}
                onSearch={handleSearchCustomer}
                // onChange={(value) => handleChangeCustomer(value, true)}
                dataSource={customerList}
              >
                {
                  _.map(customerList, (item) =>
                    <Option value={item.customerId}>
                      {item.customerName}
                    </Option>
                  )
                }
              </AutoComplete>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormContact', defaultMessage: 'Contact' })} req={true} />}
          >
            {getFieldDecorator('contact', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqContact', defaultMessage: 'Please Select Contact' }),
                },
              ],
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'expenseFormPHContact', defaultMessage: 'Select Contact' })}
                disabled={getFieldValue('vendor') ? false : true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch

              >
                {
                  _.map(contactList, (item) =>
                    <Option value={item.customerContactId}>
                      {item.customerContactName}
                    </Option>
                  )
                }
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormPhone', defaultMessage: 'Phone' })} req={true} />}
          >
            {getFieldDecorator('phone', {
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'expenseFormPHPhone', defaultMessage: 'Enter Phone' })}
                disabled={getFieldValue('contact') ? false : true}
                readOnly={true}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider type="horizontal" style={{ margin: '24px 0 12px 0' }} />
    </div>
  )
}

export default ExpenseFormVendor
