import httpClient from '../../../components/axiosClient';

const getReportReconcile = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/reconcile-report`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const exportReportReconcile = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/reconcile-report-excel`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const getReportReceive = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/receive-and-payment-report`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const exportReportReceive = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/receive-and-payment-report-excel`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

export { getReportReconcile, getReportReceive, exportReportReconcile, exportReportReceive };