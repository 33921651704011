import React, { useState, useContext, useEffect } from 'react';
import { Modal, Row, Col, Form, Select, InputNumber, Spin } from 'antd';
import Button_01 from '../../v2/button_01';
import Button_02 from '../../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import { PlanningContext } from '../../../pages/load-and-route/context'
import './css/index.css'
import _ from 'lodash';

const { Option } = Select;

const AddItem = (props) => {
  const intl = useIntl();
  const { state, fnc } = useContext(PlanningContext);
  // const { handleLicensePlatePopupScroll, handleLicensePlateChangeSelect, handleLicensePlateChangeSearch, handleLicensePlateChange } = fnc
  // const { licenseplateLoading, licenseplateList } = state;
  const { form, data, visible, onCancel, onSave,
    handleLicensePlatePopupScroll,
    handleLicensePlateChangeSelect,
    handleLicensePlateChangeSearch,
    handleLicensePlateChange,
    licenseplateLoading,
    licenseplateList
  } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;
  const [assignee, setAssignee] = useState();
  const [loading, setLoading] = useState(false);


  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#9a9999' }}>*</span> : ''}
      </span>
    );
  };

  const setOptionLicense = (item) => {
    return (<Option key={item.id} label={`${item.serialNo !== null ? item.serialNo : ''} ${item.serialNo !== null ? '·' : ''} ${item.assetClassName}`} value={item.id}>{item.serialNo} {item.serialNo !== null ? '·' : ''} {item.assetClassName}</Option>);
  }

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if (
      (keyCode >= 48 && keyCode <= 57) ||
      keyCode === 65 ||
      keyCode === 66 ||
      keyCode === 46
    ) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const handleSave = () => {
    validateFields((err, values) => {
      if (err) return;
      setLoading(true)
      onSave(values, data);
      setLoading(false)
      setTimeout(() => {
        resetFields();
      }, 1000);
    })
  }

  const handleCancel = () => {
    setAssignee()
    onCancel();
    setTimeout(() => {
      resetFields();
    }, 1000);
  }

  useEffect(() => {
    const licensePlate = getFieldValue('licensePlate')
    if (licensePlate) {
      const filter = licenseplateList && licenseplateList.filter(item => { return item.id === licensePlate })
      if (_.size(filter) !== 0) {
        setAssignee(_.get(filter, '[0].borrower.fullname'))
      }
    }
  }, [getFieldValue('licensePlate')]);

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'planningTextAddItem', defaultMessage: 'Add an Item' })}
        centered
        visible={visible}
        onCancel={handleCancel}
        onOk={handleSave}
        footer={[
          <div>
            <Button_02 btnwidth='wd_at' onClick={handleCancel} disabled={loading} style={{ margin: '0px' }}>
              <FormattedMessage id='btnCancel' defaultMessage='Cancel' />
            </Button_02>
            <Button_01 type='primary' btnwidth='wd_at' onClick={loading === false ? handleSave : null} disabled={loading}>
              <FormattedMessage id='btnSave' defaultMessage='Save' />
            </Button_01>
          </div>
        ]}
      >
        <Spin spinning={loading}>
          <Form colon={false}>
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  className='vehicle-form-item'
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleLicensePlate', defaultMessage: 'License Plate' })} req={true} />}>
                  {getFieldDecorator('licensePlate', {
                    rules: [{
                      required: true,
                      message: intl.formatMessage({ id: 'vehicleLicensePlateValidate', defaultMessage: 'Please select License Plate' })
                    }],
                  })(
                    <Select
                      className='select-license-plate'
                      placeholder={intl.formatMessage({ id: 'vehicleLicensePlatePlacholer', defaultMessage: 'Select License Plate' })}
                      defaultActiveFirstOption={false}
                      showSearch
                      onPopupScroll={handleLicensePlatePopupScroll}
                      // onSelect={handleLicensePlateChangeSelect}
                      onSearch={handleLicensePlateChangeSearch}
                      onChange={handleLicensePlateChange}
                      loading={licenseplateLoading}
                      optionLabelProp="label"
                    >
                      {licenseplateList && licenseplateList.map((item) => setOptionLicense(item))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className='vehicle-form-item'
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleAssignee', defaultMessage: 'Assignee' })} req={false} />}>
                  {assignee || '-'}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  className='vehicle-form-item'
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleOrderNo', defaultMessage: 'Order No' })} req={false} />}>
                  <span>{_.get(data, 'orderNo') ? _.get(data, 'orderNo') : ''}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className='vehicle-form-item'
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleCustomer', defaultMessage: 'Customer' })} req={false} />}>
                  <span>{_.get(data, 'customerName') ? _.get(data, 'customerName') : ''}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 16]}>
              <Col span={12}>
                <Form.Item
                  className='vehicle-form-item'
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleItem', defaultMessage: 'Item' })} req={false} />}>
                  <span>{_.get(data, 'productName') ? _.get(data, 'productName') : ''}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className='vehicle-form-item'
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleQty', defaultMessage: 'Qty' })} req={true} />}>
                  {getFieldDecorator('qty', {
                    initialValue: _.get(data, 'total') !== undefined ? _.get(data, 'total') : undefined,
                    rules: [{
                      required: true,
                      message: intl.formatMessage({ id: 'vehicleQtyPlateValidate', defaultMessage: 'Please Enter Qty' })
                    }],
                  })(
                    <InputNumber
                      placeholder={intl.formatMessage({ id: 'vehicleQtyPlatePlacholer', defaultMessage: 'Enter Qty' })}
                      maxLength={4}
                      onKeyDown={handleKeyDownNumber}
                      max={_.get(data, 'total')}
                      min={1}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

const ModalAddItem = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {

  }
})(AddItem);

export default ModalAddItem;