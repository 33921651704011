import React, { useState, useEffect, useContext } from 'react'
import { Modal, Tabs, Form, Tag, Divider } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import ExpenseDetail from './detail'
import Button01 from '../../../../components/v2/button_01'
import Button02 from '../../../../components/v2/button_02'
import _ from 'lodash'
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency'
import ModalExpenseStatusForm from './status'
import ReactBnbGallery from 'react-bnb-gallery';
import { PageSettings } from '../../../../config/page-settings'
import { getExpenseDetail, getAttExpense } from '../../../../controllers/expense/expense'
import ModalExpensePaid from '../paid'
import ExpenseViewPaid from './view-paid'

const { TabPane } = Tabs

const ExpenseView = (props) => {
  const { visible, setVisible, form, trigger, setTrigger, defaultData, setDefaultData, handleDeleteExpense, bankList,
    creditCardTypeList, userList, reasonPaymentList, handleOpenModalEdit } = props
  const { checkPermissionAction } = useContext(PageSettings);
  const intl = useIntl()
  const [selectItem, setSelectItem] = useState([])
  const [attachmentData, setAttachmentData] = useState([])
  const [editDefault, setEditDefault] = useState()
  const [visibleStatus, setVisibleStatus] = useState(false)
  const [visiblePaid, setVisiblePaid] = useState(false)
  const [visiblePaidView, setVisiblePaidView] = useState(false)
  const [type, setType] = useState()
  const [stateModal, setStateModal] = useState(false)
  const [galleryImg, setGalleryImg] = useState([])
  const [numberImage, setNumberImage] = useState(0)
  const langValue = localStorage.getItem('langValue');
  const [triggerView, setTriggerView] = useState(false)
  const sumPrice = _.sumBy(selectItem, 'afterDiscount') || 0
  const amountInvoice = _.get(editDefault, 'amountInvoice') || 0
  const subTotal = sumPrice + amountInvoice
  const subTotalTax = ((parseInt(_.get(editDefault, 'tax.tax')) || 0) * subTotal) / 100
  const total = subTotal + subTotalTax
  const title = `${intl.formatMessage({ id: `expenseViewModalTitle`, defaultMessage: 'Expense' })} ・ ${_.get(editDefault, 'expenseNo')}`
  const draftTitle = intl.formatMessage({ id: `expenseViewModalTitle`, defaultMessage: 'Expense' })

  useEffect(() => {
    const getDataDefault = async () => {
      const response = await getExpenseDetail(_.get(defaultData, 'expenseId'));
      setEditDefault(_.get(response, 'data.data[0]'))
      setSelectItem(_.get(response, 'data.data[0].expenseDetail'))
    };
    if (defaultData && visible) {
      getDataDefault();
    }
  }, [defaultData, visible, triggerView, trigger])


  useEffect(() => {
    const getFileDefault = async () => {
      const att = await getAttExpense(_.get(defaultData, 'expenseId'))
      const mapAtt = _.map(_.get(att, 'data'), (item, index) => {
        return {
          id: item.fileHash,
          index: index + 1,
          attachmentName: item.fileName,
          remark: item.remark,
          file: [...item.fileUrl],
          fileRaw: item.fileUrl
        }
      })
      setAttachmentData(mapAtt)
    };
    if (defaultData && visible) {
      getFileDefault();
    }
  }, [defaultData, visible, trigger])



  const columnsDetail = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      width: 120,
      fixed: 'left',
      render: (text, record, index) => _.get(record, 'item.itemCode') || ""
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      render: (text, record, index) => _.get(record, 'item.itemName') || ""
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      render: (text, record, index) => _.get(record, 'qty') + " " + _.get(record, 'unit.unit'),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'price',
      key: 'price',
      render: (text, record, index) => _.get(record, 'price') + " " + "THB",
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscountPercentage', defaultMessage: 'Discount Percentage' }),
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (text, record, index) => _.get(record, 'discount.percent') + " " + "%",
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: (text, record, index) => numberFormatter(_.get(record, 'discount.total') || 0) + " " + "THB",
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      fixed: 'right',
      width: 120,
      render: (text, record, index) => numberFormatter(_.get(record, 'afterDiscount') || 0) + " " + "THB",
    },
  ]

  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left'
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      render: (text, record, index) => <span style={{ cursor: 'pointer' }} onClick={() => handlePreview(record, attachmentData)}>{record.attachmentName}</span>
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      // width: 150,
    },
  ]

  const handlePreview = (record, data) => {
    const isPDF = _.includes(_.get(record, 'attachmentName'), 'pdf')
    if (isPDF) return window.open(_.get(record, 'fileRaw'), '_blank');
    const mapPhoto = data.map((item, index) => {
      return {
        photo: _.get(item, 'fileRaw'),
        number: index,
        caption: _.get(item, 'attachmentName'),
        subcaption: _.get(item, 'remark'),
        thumbnail: _.get(item, 'fileRaw'),
      }
    })
    const filerDefault = _.filter(mapPhoto, (item) => { return item.photo === record.fileRaw })
    setGalleryImg(mapPhoto)
    setNumberImage(_.get(filerDefault, '[0].number'))
    setStateModal(true)
  }

  const handleSave = async (type) => {
    setType(type)
    setVisibleStatus(true)
  }

  const handleCancel = () => {
    setVisible(false)
    setSelectItem([])
    setAttachmentData([])
    setEditDefault()
    setDefaultData()
  }

  const handleButton = (data) => {
    if (data && editDefault) {
      if (data === "draft") {
        return [
          <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_at" onClick={() => handleDeleteExpense(_.get(defaultData, 'expenseId'))} >
            <FormattedMessage id="btnDeleteExpense" defaultMessage="Delete Expense" />
          </Button01>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModalEdit(defaultData)}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button01>
        ]
      } else if (data === "waiting") {
        return [
          <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('rejected')} >
            <FormattedMessage id="btnReject" defaultMessage="Reject" />
          </Button01>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')} >
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button01>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('approved')} >
            <FormattedMessage id="btnApprove" defaultMessage="Approve" />
          </Button01>,
        ]
      } else if (data === "approved") {
        return [
          <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_at" onClick={() => handleDeleteExpense(_.get(defaultData, 'expenseId'))} >
            <FormattedMessage id="btnDeleteExpense" defaultMessage="Delete Expense" />
          </Button01>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')} >
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button01>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handlewOpenModalPaid('paid')} >
            <FormattedMessage id="btnPaid" defaultMessage="Paid" />
          </Button01>,
        ]
      } else if (data === "paid") {
        return [
          <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_at" onClick={() => handleDeleteExpense(_.get(defaultData, 'expenseId'))} >
            <FormattedMessage id="btnDeleteExpense" defaultMessage="Delete Expense" />
          </Button01>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')} >
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button01>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handlewOpenModalPaid('payment')}>
            <FormattedMessage id="btnPaymentExpense" defaultMessage="Payment" />
          </Button01>,
        ]
      } else if (data === "rejected") {
        return [
          <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_at" onClick={() => handleDeleteExpense(_.get(defaultData, 'expenseId'))} >
            <FormattedMessage id="btnDeleteExpense" defaultMessage="Delete Expense" />
          </Button01>,
        ]
      } else {
        return [
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCancel} >
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button01>,
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handlewOpenModalPaid('payment')}>
            <FormattedMessage id="btnPaymentExpense" defaultMessage="Payment" />
          </Button01>,
        ]
      }
    }
  }

  const handlewOpenModalPaid = (type) => {
    if (type === 'paid') setVisiblePaid(true)
    if (type === 'payment') setVisiblePaidView(true)
  }

  return (
    <Modal
      title={_.get(editDefault, 'status.code') === "draft" ? draftTitle : title}
      visible={visible}
      onCancel={handleCancel}
      width={1100}
      centered={true}
      zIndex={100}
      bodyStyle={{ padding: 'unset', height: '650px', overflowY: 'auto' }}
      footer={handleButton(_.get(editDefault, 'status.code'))}
    >
      <div>
        <div style={{ padding: '12px 24px', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <FormattedMessage id="expenseViewModalTitleDetail" defaultMessage="Detail" />
          </div>
          <div style={{ textAlign: 'center', display: 'flex' }}>
            <Tag className="tag-center-style" color={_.get(editDefault, 'status.color')}>
              {_.get(editDefault, `status.lang[${langValue}]`)}
            </Tag>
          </div>
        </div>
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <ExpenseDetail
          form={form}
          columns={columnsDetail}
          columnsAtt={columnsAtt}
          selectItem={selectItem}
          attachmentData={attachmentData}
          editDefault={editDefault}
          subTotal={subTotal}
          subTotalTax={subTotalTax}
          total={total}
        />
      </div>
      <ModalExpenseStatusForm
        visible={visibleStatus}
        setVisible={setVisibleStatus}
        setVisibleMain={setVisible}
        type={type}
        data={defaultData}
        setTrigger={setTrigger}
        setDefaultData={setDefaultData}
        setEditDefault={setEditDefault}
      />
      {
        attachmentData.length > 0 ? <ReactBnbGallery
          show={stateModal}
          photos={galleryImg}
          onClose={() => setStateModal(false)}
          activePhotoIndex={numberImage}
        /> : null
      }

      <ModalExpensePaid
        visible={visiblePaid}
        setVisible={setVisiblePaid}
        editDefault={editDefault}
        bankList={bankList}
        creditCardTypeList={creditCardTypeList}
        userList={userList}
        setTrigger={setTrigger}
        setTriggerView={setTriggerView}
        total={total}
        reasonPaymentList={reasonPaymentList}
      />

      <ExpenseViewPaid
        visible={visiblePaidView}
        setVisible={setVisiblePaidView}
        editDefault={editDefault}
        reasonPaymentList={reasonPaymentList}
        setTrigger={setTriggerView}
        setTriggerMain={setTrigger}
      />

    </Modal >
  )
}

const ViewExpense = Form.create({
  name: 'po-form',
  mapPropsToFields() { }
})(ExpenseView);

export default ViewExpense
