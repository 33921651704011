import { Modal, Avatar, Badge, Icon } from 'antd';
import React from 'react';
import Button01 from '../../v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import { useState } from 'react';

const ModalAvatar = (props) => {
  const { visible, setVisible, avatar, imageFile, setImageFile, imageChecked, setImageChecked } = props;
  const intl = useIntl()
  const handleSelectAvatar = (item) => {
    setImageFile(item.path);
    setImageChecked(item);
    setVisible(false);
  };

  const renderAvatar = (item, index) => {
    return (
      <div className="render-item-avatar" key={index}>
        <div className="container-avatar-project">
          <Avatar className="avatar-project-select" size={56} src={item.path} onClick={() => handleSelectAvatar(item)} />
          {imageChecked && imageChecked.fileHash === item.fileHash ? (
            <div className="container-avatar-project after">
              <Icon className="icon-project-select" type="check" />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <Modal
      centered
      title={intl.formatMessage({ id: `modalProjectAvatarTextHeader`, defaultMessage: 'Avatar' })}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={500}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => setVisible(false)} style={{ margin: 'unset' }}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
    >
      <div className="render-group-avatar">{avatar && avatar.map((item, index) => renderAvatar(item, index))}</div>
    </Modal>
  );
};

export default ModalAvatar;
