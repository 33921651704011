import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getSaleOrderList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/saleorder/listsaleorder`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const createSaleOrder = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/saleorder/createsaleorder`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response.data;
  }
};

const updateSaleOrder = async (payload) => {
  try {
    const response = await httpClient.put(
      `/v3/quotation/manager/company/${comCode}/saleorder/updatesaleorder/${payload.saleOrderId}`,
      payload
    );
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response.data;
  }
};

const updateStatusSaleOrder = async (payload) => {
  try {
    const response = await httpClient.put(
      `/v3/quotation/manager/company/${comCode}/saleorder/updatestatussaleorder/${payload.id}`,
      payload
    );
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response.data;
  }
};

const getSaleOrderById = async (id) => {
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/saleorder/getsaleorderdetail/${id}`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getIntialListSaleOrder = async (key) => {
  //reason,tax
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/${key}/getdata`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getMemberIssueBy = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/item/manager/${comCode}/getlistmemberloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const uploadAttachment = async (formData) => {
  try {
    const response = await httpClient.put(`/v3/quotation/manager/company/${comCode}/saleorder/uploadfile`, formData);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getSaleOrderReportById = async (id) => {
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/saleorder/getsaleorderpaymentdetail/${id}`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getSaleOrderStatus = async (id) => {
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/status/getdata?module=saleorder`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getAttachmentSO = async (id) => {
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/saleorder/attachment/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteAttachmentSaleOrder = async (id) => {
  try {
    const response = await httpClient.delete(`/v3/quotation/manager/company/${comCode}/saleorder/removeattachment/${id}`);
    return response.data;
  } catch (error) {}
};

const getSummaryApproved = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/saleorder/summary`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const exportExcel = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/saleorder/listsaleorder/export`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export {
  getSaleOrderList,
  getIntialListSaleOrder,
  getSaleOrderById,
  createSaleOrder,
  updateSaleOrder,
  getMemberIssueBy,
  updateStatusSaleOrder,
  uploadAttachment,
  getSaleOrderReportById,
  getSaleOrderStatus,
  getAttachmentSO,
  deleteAttachmentSaleOrder,
  getSummaryApproved,
  exportExcel,
};
