import React, { useState, useEffect, useContext } from 'react';
import Styles from './css/list-view.css';
import { ListViewContext } from '../monitor-context';
import { Col, Row } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';

export default (props) => {
  const { LangCode } = props
  return (
    <ListViewContext.Consumer>
      {({ state, fnc, setState }) => (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={6} className="list-view-header-col-content">
              <p className="list-view-header-col-p"><FormattedMessage id={`monitorTabListViewHeaderListViewTaskWorkDesk`} defaultMessage="Task" /></p>
              <p className="list-view-header-col-text">{state.tasks}</p>
            </Col>
            <Col span={6} className="list-view-header-col-content">
              <p className="list-view-header-col-p"><FormattedMessage id={`monitorTabListViewHeaderListViewCompletedWorkDesk`} defaultMessage="Completed" /></p>
              <p className="list-view-header-col-text">{state.completed}</p>
            </Col>
            <Col span={6} className="list-view-header-col-content">
              <p className="list-view-header-col-p"><FormattedMessage id={`monitorTabListViewHeaderListViewWarningWorkDesk`} defaultMessage="Warning" /></p>
              <p className="list-view-header-col-text">{state.warning}</p>
            </Col>
            <Col span={6} className="list-view-header-col-content">
              <p className="list-view-header-col-p"><FormattedMessage id={`monitorTabListViewHeaderListViewTimeOutWorkDesk`} defaultMessage="Delayed" /></p>
              <p className="list-view-header-col-text">{state.timeout}</p>
            </Col>
          </Row>
        </div>
      )}
    </ListViewContext.Consumer>
  );
};
