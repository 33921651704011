import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Col, Divider, Form, Modal, Row, Select, Tabs, Input, DatePicker } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import LabeRequireForm from '../../../../components/label-required-form';
import { SelectCustomerHook } from './selectHook';
import _ from 'lodash';

const { Option } = Select;
const { Search } = Input;
const dateFormat = 'ddd, MMM DD YYYY';

const SelectCustomerModal = ({ form, title, visible, onOk, onCancel, setDataForm, dataForm, isOpenQuotation, x }) => {
  const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = form;
  const {
    customerList,
    customerSelect,
    customerLoading,
    handleCustomerPopupScroll,
    handleCustomerChangeSelect,
    handleCustomerChange,
    contactList,
    contactSelect,
    handleSelectContact,
    addressList,
    addressSelect,
    loadingAddress,
    handleSelectAddress,
    customerObj,
    setCustomerObj,
    contactObj,
    setContactObj,
    addressObj,
    setAddressObj,
  } = SelectCustomerHook();
  const intl = useIntl();

  console.log('dataForm customer form', dataForm);
  useEffect(() => {
    if (_.get(dataForm, 'customerId') && visible) {
      handleCustomerChangeSelect(_.get(dataForm, 'customerId'));
    }
  }, [_.get(dataForm, 'customerId'), visible]);

  useEffect(() => {
    if (dataForm && visible) {
      setFieldsValue({
        ['customer']: _.get(dataForm, 'customerId') || undefined,
        ['customerContact']: _.get(dataForm, 'customerContact') || undefined,
        ['customerContactPhone']: _.get(dataForm, 'customerContactPhone') || undefined,
        ['customerAddress']: _.get(dataForm, 'customerAddressBookId') || undefined,
      });
    }
  }, [dataForm, visible]);

  useEffect(() => {
    if (_.get(contactObj, 'customerContactId')) {
      setFieldsValue({
        ['customerContactPhone']: _.get(contactObj, 'customerContactPhone'),
      });
    }
  }, [contactObj]);

  // useEffect(() => {
  //   if (getFieldValue('customer')) {
  //     setFieldsValue({
  //       ['customerContact']: undefined,
  //       ['customerContactPhone']: null,
  //       ['customerAddress']: undefined,
  //     });
  //   }
  // }, [getFieldValue('customer')]);
  console.log('aaaaaaaaaaaaaaa', x);
  useEffect(() => {
    if (isOpenQuotation === false && visible === false && _.isFunction(resetFields())) {
      resetFields();
    }
  }, [isOpenQuotation]);

  console.log('customerObj', customerObj);

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('values', values);
      if (err) {
        return;
      }
      setDataForm((prev) => ({ ...prev, ...values, ...contactObj, ...addressObj, ...customerObj }));
      console.log('values', values);
      onOk();
    });
  };

  const setCustomerOption = (customer) => {
    return (
      <Option key={`${customer.customerId}`} value={`${customer.customerId}`}>
        {customer.customerName}
      </Option>
    );
  };

  const setContactOption = (contact) => {
    return (
      <Option key={`${contact.customerContactId}`} value={`${contact.customerContactId}`}>
        {contact.customerContactName}
      </Option>
    );
  };

  const setAddressOption = (address) => {
    return (
      <Option
        key={`${address.customerAddressBookId}`}
        value={`${address.customerAddressBookId}`}
        label={`${address.addressName} · ${address.fullAddress}`}
      >
        {address.addressName}
      </Option>
    );
  };
  const handleCustomerChangeSelectForm = (value) => {
    handleCustomerChangeSelect(value);
    // setFieldsValue({
    //   ['customerContact']: undefined,
    //   ['customerContactPhone']: null,
    //   ['customerAddress']: undefined,
    // });
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={onCancel}
      width={600}
      zIndex={1000}
      centered={true}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="customer" colon={false}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={true} />}
            >
              {getFieldDecorator('customer', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationWarnCustomer', defaultMessage: 'please select customer' }),
                  },
                ],
              })(
                <Select
                  className="select-list-customer-monitor"
                  placeholder={intl.formatMessage({ id: 'quotationHintCustomer', defaultMessage: 'Select Customer' })}
                  defaultActiveFirstOption={false}
                  showSearch
                  allowClear={false}
                  onPopupScroll={handleCustomerPopupScroll}
                  onSelect={handleCustomerChangeSelectForm}
                  onChange={handleCustomerChange}
                  loading={customerLoading}
                  filterOption={false}
                >
                  {customerList && customerList.map((customer) => setCustomerOption(customer))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelContact', defaultMessage: 'Contact' })} req={false} />}
            >
              {getFieldDecorator('customerContact', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationWarnContact', defaultMessage: 'please select contact' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'quotationHintContact', defaultMessage: 'Select Contact' })}
                  showSearch
                  onSelect={handleSelectContact}
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {contactList && contactList.map((contact) => setContactOption(contact))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelPhoneNumber', defaultMessage: 'Phone Number' })}
                  req={false}
                />
              }
            >
              {getFieldDecorator('customerContactPhone', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationWarnPhoneNumber', defaultMessage: 'please select Phone Number' }),
                  },
                ],
              })(
                <Input
                  readOnly
                  placeholder={intl.formatMessage({ id: 'quotationHintPhoneNumber', defaultMessage: 'Select Phone Number' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelModalCustomerAddress', defaultMessage: 'Address' })}
                  req={false}
                />
              }
            >
              {getFieldDecorator('customerAddress', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationWarnModalCustomerAddress', defaultMessage: 'Please select address' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'quotationHintModalCustomerAddress', defaultMessage: 'Select Address' })}
                  showSearch
                  onSelect={handleSelectAddress}
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {addressList && addressList.map((address) => setAddressOption(address))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

SelectCustomerModal.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalSelectCustomerForm = Form.create({ name: 'Customer_Form' })(SelectCustomerModal);

export default ModalSelectCustomerForm;
