import React, { useState, useEffect, useContext } from 'react';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Col, Row, Form, Select, DatePicker, Input, Dropdown, Menu, Button, Card, Divider, Icon } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css';
import moment from 'moment';
import { AllticketContext } from '../allticket-context';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const Filtter = (props) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(AllticketContext);
  const {
    ticketData,
    changeTab,
    isLoading,
    fillter,
    requestDetailData,
    changeTicketNo,
    brandData,
    modelData,
    totalBrand,
    totalModel,
  } = state;
  const { setChangeStatus, setFillter, setChangeTab, setChangeTicketNo, setEvent, setTotalBrand, setTotalModel } = setState;
  const {
    getApiSearch,
    changeToApproval,
    changeToReject,
    getApiApprove,
    getApiReject,
    handleBrandPopupScroll,
    handleModelPopupScroll,
  } = fnc;
  const { columns, form, tab } = props;
  const [rangeDate, setRangeDate] = useState(false);
  const paddingFilter = '2px 16px 0px';
  const statusLsit = _.get(ticketData, 'data.statusList');

  const handleChangeDate = (value) => {
    setRangeDate(value);
    setFillter({
      ...fillter,
      ['pmdatestart']: moment(_.get(value, '[0]')).format(dateFormat),
      ['pmdateto']: moment(_.get(value, '[1]')).format(dateFormat),
    });
    handleFillter(value);
  };

  const handleFillter = (value, code) => {
    console.log('valueFill', value);
    setFillter({ ...fillter, [code]: value });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    let customDate = moment(_.get(fillter,'startdate')).format('YYYY-MM-DD');
    return current < moment(customDate, 'YYYY-MM-DD');
  };

  // const handleChangeTicketNo = (value, code) => {
  //   console.log('valueFill', value);
  //   if (value === '') {
  //     handleFillter(value, code);
  //     setChangeTicketNo(value);
  //   } else {
  //     setChangeTicketNo(_.size(requestDetailData) > 0 ? _.get(requestDetailData, 'ticketNo') : null);
  //     handleFillter(value, code);
  //   }
  // };

  return (
    <div>
      <div>
        <Form>
          <Row gutter={24}>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item>
                <FormattedMessage id="RepairTicketBrandForm" defaultMessage="Brand" />
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder={intl.formatMessage({ id: 'TFFromWareHousePlaceHolder', defaultMessage: 'Select From WareHouse' })}
                  onPopupScroll={handleBrandPopupScroll}
                  onChange={(e) => handleFillter(e, 'brand')}
                >
                  {brandData &&
                    _.map(brandData, (item, index) => {
                      return (
                        <Option key={_.get(item, 'itemBrandId')} value={_.get(item, 'itemBrandId')}>
                          {_.get(item, 'itemBrandName')}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item>
                <FormattedMessage id="ReapirTicketModelForm" defaultMessage="Model" />
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder={intl.formatMessage({ id: 'TFFromWareHousePlaceHolder', defaultMessage: 'Select From WareHouse' })}
                  onPopupScroll={handleModelPopupScroll}
                  onChange={(e) => handleFillter(e, 'model')}
                >
                  {modelData &&
                    _.map(modelData, (item, index) => {
                      return (
                        <Option key={_.get(item, 'item_model_id')} value={_.get(item, 'item_model_id')}>
                          {_.get(item, 'item_model_name')}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item>
                <FormattedMessage id="TFItemCode" defaultMessage="Item Code" />

                <Input
                  className="inputWidth"
                  placeholder={intl.formatMessage({ id: 'TFItemCodePlaceHolder', defaultMessage: 'Enter Item Code' })}
                  onChange={(e) => {
                    handleFillter(e.target.value, 'itemCode')
                  }}
                  allowClear
                  // value={changeTicketNo ? changeTicketNo : ''}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item>
                <FormattedMessage id="TFItemName" defaultMessage="Item Name" />

                <Input
                  className="inputWidth"
                  placeholder={intl.formatMessage({ id: 'TFItemNamePlaceHolder', defaultMessage: 'Enter Item Name' })}
                  onChange={(e) => {
                    handleFillter(e.target.value, 'itemName')
                  }}
                  allowClear
                  // value={changeTicketNo ? changeTicketNo : ''}
                ></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item>
                <FormattedMessage id="TFLotNoorSerailNo" defaultMessage="Lot No. or Serial No." />

                <Input
                  className="inputWidth"
                  placeholder={intl.formatMessage({ id: 'TFLotNoorSerailNoPlaceHolder', defaultMessage: 'Enter Lot No. or Serial No.' })}
                  onChange={(e) => handleFillter(e.target.value, 'lotOrSerialNo')}
                  allowClear
                ></Input>
              </Form.Item>
            </Col>

            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item>
                <FormattedMessage id="TFFromExpiredDate" defaultMessage="From Expired Date" />
                <DatePicker
                  className="inputWidth"
                  placeholder={intl.formatMessage({ id: 'TFFromExpiredDatePlaceHolder', defaultMessage: 'Select From Expired Date' })}
                  format="ddd, MMM DD YYYY"
                  onChange={(e) => handleFillter(moment(e).format('YYYY-MM-DD'), 'startdate')}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item>
                <FormattedMessage id="TFToExpiredDate" defaultMessage="To Expired Date" />
                <DatePicker
                  className="inputWidth"
                  placeholder={intl.formatMessage({ id: 'TFToExpiredDatePlaceHolder', defaultMessage: 'Select To Expired Date' })}
                  format="ddd, MMM DD YYYY"
                  allowClear={true}
                  onChange={(e) => handleFillter(moment(e).format('YYYY-MM-DD'), 'enddate')}
                  disabled={_.get(fillter,'startdate') !== undefined ? false : true}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const FillterForm = Form.create({
  name: 'filter_form',
  mapPropsToFields() {},
})(Filtter);

export default FillterForm;
