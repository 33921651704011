import _ from 'lodash';
import httpClient from '../components/axiosClient';

// const accessToken = localStorage.getItem('accessToken');
// const langValue = localStorage.getItem('langValue');
const comCode = localStorage.getItem('comCode');
const memComId = localStorage.getItem('memComId');
const intervalCount = 3000;
let isData = false;
let isLoad;

const getQueueId = async (apiMethod = 'post', urlQueue = '', apiUrl = '', payload = {}) => {
  try {
    const body = {
      apiPayload: payload,
      // apiHeaders: {
      //   authorization: `Bearer ${accessToken}`,
      //   memcomid: memComId,
      //   lang: langValue,
      // },
      apiMethod: apiMethod,
      apiQuery: {},
      apiUrl: apiUrl,
      memComId: memComId,
    };

    console.log('useQueueApi urlQueue', urlQueue);

    const response = await httpClient.post(urlQueue, body);
    return _.get(response, 'data.data.queueId');
  } catch (error) {
    return undefined;
  }
};

const getQueueMessages = async ({ apiMethod = 'post', urlQueue = '', apiUrl = '', payload = {} }) => {
  if (apiMethod || urlQueue || apiUrl || payload) {
    const queueId = await getQueueId(apiMethod, urlQueue, apiUrl, payload);

    if (queueId) {
      return new Promise((resolve, reject) => {
        const intervalPoll = setInterval(async () => {
          isLoad = true;
          isData = false;
          try {
            const response = await httpClient.get(`/queue/manager/company/${comCode}/queuemessage/${queueId}`);
            console.log('resQueueApi', response);
            if (_.get(response, 'data.status.code') == 200 && _.get(response, 'data.data.waitingQueue') === true) {
              isLoad = false;
              isData = true;
              clearInterval(intervalPoll); 
              resolve({ ...response.data, isLoad: isLoad });
            } else {
              isLoad = false;
              isData = false;
              clearInterval(intervalPoll);
              resolve({ ...response.data, isLoad: isLoad });
            }
          } catch (error) {
            isLoad = false;
            isData = false;
            clearInterval(intervalPoll); 
            reject(error); 
          }
        }, intervalCount);
      });
    }
  }
};

// const getQueueMessages = async ({ apiMethod = 'post', urlQueue = '', apiUrl = '', payload = {} }) => {
//   if (apiMethod || urlQueue || apiUrl || payload) {
//     const queueId = await getQueueId(apiMethod, urlQueue, apiUrl, payload);

//     if (queueId) {
//       const intervalPoll = setInterval(async () => {
//         isLoad = true;
//         isData = false;
//         try {
//           const response = await httpClient.get(`/queue/manager/company/${comCode}/queuemessage/${queueId}`);
//           console.log('resQueueApi', response);
//           if (_.get(response, 'data.status.code') == 200) {
//             isLoad = false;
//             isData = true;
//             const data = response.data;
//             clearInterval(intervalPoll);

//             return { data, isLoad };
//           }
//         } catch (error) {
//           isLoad = false;
//           isData = false;

//           console.log('resQueueApi error', error.response);
//         }
//       }, intervalCount);
//     }
//   }
// };

export { getQueueMessages };
