import React from 'react';
import httpClient from '../../components/axiosClient';

const getByIdMaintenanceReport = async (maintenanceId) => {
  const comCode = localStorage.getItem('comCode');
  if (maintenanceId) {
    try {
      const response = await httpClient.get(`/v3/maintenance/manager/company/${comCode}/maintenance/${maintenanceId}`);
      if (response.status === 200) {
        return response.data.data.maintenance;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }
}

export default getByIdMaintenanceReport;