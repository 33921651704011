import React, { useEffect, useContext } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { TaskDetail } from '../../task-detail-context';
import { GetDataMoreDetail, UploadImage } from '../../../../../../controllers/more-detail/more-detail-api';
import _ from 'lodash';
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html';
import { v4 as uuidv4 } from 'uuid';
import { errorNotification } from '../../../../../v2/notification';
import { Icon, Card } from 'antd'
import { FormattedMessage } from 'react-intl';

export default () => {

  const { stateTask, setStateTask } = useContext(TaskDetail);

  const { editorStaet, imgSrc, allSrc } = stateTask
  const { setEditorStaet, setImgSrc, setAllSrc } = setStateTask

  const taskDetailId = _.get(stateTask, 'taskId')


  useEffect(() => {
    const getData = async () => {
      if (taskDetailId) {
        const respone = await GetDataMoreDetail(taskDetailId);
        if (_.size(respone) !== 0) {
          setStateTask.setTextValue(_.get(respone, 'data[0].detail'))
          setStateTask.setMoreDetailId(_.get(respone, 'data[0].id'))
          const html = _.get(respone, 'data[0].detail')
          if (html) {
            const contentBlock = htmlToDraft(html)
            if (contentBlock) {
              const content = ContentState.createFromBlockArray(contentBlock.contentBlocks)
              setEditorStaet(EditorState.createWithContent(content))
              setStateTask.setContentMoreDetail(true)
            }
          } else {
            setEditorStaet(EditorState.createEmpty())
            setStateTask.setContentMoreDetail(false)
          }

        }
      }
    }

    getData();

  }, [taskDetailId, stateTask.statusView,])


  const uploadCallback = (file) => {
    return new Promise(
      async (resolve, reject) => {
        const nameFolder = `task_more_detail/${uuidv4()}`
        const data = new FormData();
        data.append("file", file);
        data.append('moduleName', nameFolder);
        const response = await UploadImage(data)
        if (_.get(response, 'status') === 200) {
          allSrc.push({
            fileCodeIs: _.get(response, 'data.data.fileCodeIs'),
            fullPathIs: _.get(response, 'data.data.fullPathIs')
          })
          setAllSrc(allSrc)
          resolve({ data: { link: _.get(response, 'data.data.fullPathIs') } });
        }
        else {
          errorNotification(_.get(response, 'data.status.message'))
          return reject({});
        }
      }
    );
  }


  const handleEditStateChange = (value) => {
    const covertData = convertToRaw(value.getCurrentContent())
    const logImg = []
    _.map(covertData.entityMap, i => {
      logImg.push({
        fullPathIs: i.data.src
      })
    })
    setImgSrc(logImg)
    setEditorStaet(value)
    convertHTML(value)
  }

  const convertHTML = (value) => {
    let currentContentAsHTML = draftToHtml(convertToRaw(value.getCurrentContent()));
    setStateTask.setTextValue(currentContentAsHTML);
  }

  const config = {
    options: ['inline', 'list', 'textAlign', 'link', 'image', 'history'],
    inline: { options: ['bold', 'italic', 'underline'] },
    list: { options: ['unordered', 'ordered'], },
    link: { options: ['link'], },
    image: {
      uploadCallback: uploadCallback,
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      defaultSize: {
        height: '100',
        width: '80',
      },
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
    }

  }


  return (
    <div style={{ padding: '16px 24px' }}>
      {
        stateTask.statusView ? (
          <Editor
            editorState={editorStaet}
            toolbar={config}
            onEditorStateChange={handleEditStateChange}
            editorStyle={stateTask.statusView ? { border: "1px solid #F1F1F1", height: '400px' } : null}
            toolbarHidden={stateTask.statusView ? false : true}
            readOnly={stateTask.statusView ? false : true}
          />
        ) : stateTask.contentMoreDetail === true ? (
          <Editor
            editorState={editorStaet}
            toolbar={config}
            onEditorStateChange={handleEditStateChange}
            editorStyle={stateTask.statusView ? { border: "1px solid #F1F1F1", height: '400px' } : null}
            toolbarHidden={stateTask.statusView ? false : true}
            readOnly={stateTask.statusView ? false : true}
          />
        ) : (
          <Card className="task-status-card" style={{ height: '479px' }}>
            <div className="task-status-content-detail">
              <Icon className="task-status-content-icon" type="profile" />
              <p className="task-status-content-text">
              <FormattedMessage
                  id='taskDetailNoMoreDetail'
                  defaultMessage="No More Detail " />
              </p>
            </div>
          </Card>
        )
      }
    </div >
  )
}