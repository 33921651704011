import React from 'react';
import { Card, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import NoDataBackgroundModal from '../../../../../no-data-page/modal-no-data';

export default () => {
  return (
    <div style={{ padding: '16px' }}>
      <Card className="task-status-card" style={{ height: '479px' }}>
        {/* <div className="task-status-content-detail">
          <Icon className="task-status-content-icon" type="profile" />
          <p className="task-status-content-text">
            <FormattedMessage
              id='taskDetailNoMoreRequest'
              defaultMessage="No Request" />
          </p>
        </div> */}
        <NoDataBackgroundModal text1={{ id: 'taskDetailNoMoreRequest', text: 'No Request' }} />
      </Card>
    </div>
  );
};
