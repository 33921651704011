import React from 'react';
import './css/index.css';
import { Modal, Row, Col, Icon, Divider, Table, Input } from 'antd';
import Button02 from '../v2/button_02';
import _ from 'lodash';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useIntl, FormattedMessage } from 'react-intl';

const { TextArea } = Input;
export default (props) => {
  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsItemName', defaultMessage: 'Item Name' }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsUnitPrice', defaultMessage: 'Unit Price (Baht)' }),
      dataIndex: 'UnitPrice',
      key: 'UnitPrice',
      align: 'right',
      width: 180,
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.UnitPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      width: 110,
      align: 'right',
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsUnit', defaultMessage: 'Unit' }),
      dataIndex: 'itemsUnitName',
      key: 'itemsUnitName',
      align: 'right',
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsTotal', defaultMessage: 'Total (Baht)' }),
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.total).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
  ];

  const { data } = props;
  const dateFormat = 'ddd, MMM DD YYYY';
  const wareHouseName = _.get(data, 'wareHouseName');
  const wareHouseAddress = _.get(data, 'wareHouseAddress');
  const wareHousePhone = _.get(data, 'wareHousePhone');
  const billDate = _.get(data, 'billDate');
  const customerName = _.get(data, 'customerName');
  const customerAddressBookName = _.get(data, 'customerAddressBookName');
  const customerAddress = _.get(data, 'customerAddress');
  const customerPhone = _.get(data, 'customerPhone');
  const dueDate = _.get(data, 'dueDate');
  const detail = _.get(data, 'detail');
  const orederRemark = _.get(data, 'orederRemark');
  const discountTitle = _.get(data, 'discountTitle');
  const taxTitle = _.get(data, 'taxTitle');
  const subTotal = _.get(data, 'subTotal');
  const discount = _.get(data, 'discount');
  const tax = _.get(data, 'tax');
  const total = _.get(data, 'total');

  const handleCancel = () => {
    props.setVisible(false);
    props.setData();
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'modalSaleOrderTitleView', defaultMessage: 'View Sale Order ' })}
        width={1100}
        centered={true}
        visible={props.visible}
        onCancel={handleCancel}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          // <Button01 key="submit" type="primary" btnsize='wd_df' onClick={() => handleSave('completed')} disabled={itemSaleOrder && itemSaleOrder.length < 1 ? true : false}>
          //   <FormattedMessage id="btnSave" defaultMessage="Save" />
          // </Button01>,
        ]}
      >
        <Row>
          <Row>
            <Col span={12}>
              <div>
                <p>
                  <FormattedMessage id="saleOrderModalTextWareHouse" defaultMessage="Warehouse" />
                </p>
                <span>{wareHouseName}</span>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <p>
                  <FormattedMessage id="saleOrderModalTextCustomer" defaultMessage="Customer" />
                </p>
                <p>{customerName}</p>
                <p style={{ marginTop: '16px' }}>{customerAddressBookName}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div className="div-address-margin">
                <span>
                  <Icon type="environment" className="icon-map" style={{ color: '#0c4da2' }} />
                  {wareHouseAddress}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <p style={{ marginTop: '8px' }}>
                <Icon type="environment" className="icon-map" style={{ color: '#1D3557' }} />
                {customerAddress}
              </p>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div className="div-phone-margin">
                <span>
                  <FormattedMessage id="saleOrderModalTextPhone" defaultMessage="phone" />: {wareHousePhone}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="div-phone-customer-margin">
                <p>
                  <FormattedMessage id="saleOrderModalTextPhone" defaultMessage="phone" /> : {customerPhone}
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div className="div-phone-margin">
                <span>
                  <FormattedMessage id="saleOrderModalOrderDate" defaultMessage="Order Date" />: {moment(billDate).format(dateFormat)}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div className="div-shippingdate-customer-margin">
                <p>
                  <FormattedMessage id="saleOrderModalShippingDate" defaultMessage="Shipping Date" />: {moment(dueDate).format(dateFormat)}{' '}
                  <Icon type="calendar" />
                </p>
              </div>
            </Col>
          </Row>
        </Row>
        <Divider type="horizontal" className="divider-top-margin" />
        <div className="div-table">
          <Table
            columns={columns}
            dataSource={detail}
            pagination={false}
            scroll={detail && detail.length !== 0 ? { y: 175 } : { x: 'max-content' }}
          />
        </div>
        <Row className="div-Row-margin">
          <Col span={12}>
            <p className="p-style-calculate">
              <FormattedMessage id="saleOrderModalTexRemark" defaultMessage="Remark" />
            </p>
            <TextArea rows={5} autoSize={{ minRows: 5, maxRows: 5 }} value={orederRemark} disabled={true} className="text-area" />
          </Col>
          <Col span={12}>
            <Col span={13} style={{ textAlign: 'right' }}>
              <p className="p-style-calculate">
                <FormattedMessage id="saleOrderModalTexSubtotal" defaultMessage="Subtotal" />
              </p>
              <p className="p-style-calculate">
                <FormattedMessage id="saleOrderModalTexDiscount" defaultMessage="Discount" /> {discountTitle}
              </p>
              <p className="p-style-calculate">
                <FormattedMessage id="saleOrderModalTexSubtotalLessDiscount" defaultMessage="Subtotal Less Discount" />
              </p>
              <p className="p-style-calculate">
                <FormattedMessage id="saleOrderModalTexTax" defaultMessage="Tax" /> {taxTitle}
              </p>
              <p className="p-style-calculate">
                <FormattedMessage id="saleOrderModalTexTotal" defaultMessage="Total" />
              </p>
            </Col>
            <Col span={9} style={{ textAlign: 'right' }}>
              <p className="p-style-calculate">
                <NumberFormat
                  value={parseFloat(subTotal).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                />
              </p>
              <p className="p-style-calculate">
                <NumberFormat
                  value={parseFloat(discount).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                />
              </p>
              <p className="p-style-calculate">
                <NumberFormat
                  value={parseFloat(subTotal - discount).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                />
              </p>
              <p className="p-style-calculate">
                <NumberFormat
                  value={parseFloat(tax).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                />
              </p>
              <p className="p-style-calculate">
                <NumberFormat
                  value={parseFloat(total).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                />
              </p>
            </Col>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
