import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Dropdown, Button, Menu, Checkbox } from 'antd';
import Button_01 from '../../../components/v2/button_01';
import _ from 'lodash';

const PettyCashDetailActionColumn = (props) => {
  const { columnsNormal, listArrayColumns, defaultShowColumn, newColumns, textErrorSelectColumn, actionColumns,
    setNewDataColumns, setListArrayColumns, setDefaultShowColumn, setTextErrorSelectColumn, loading, visible,
    setVisible } = props
  const columns = actionColumns
  const setShowColumn = _.get(columnsNormal, 'setShowColumn')
  const columnMinimum = 1
  const ignoreColumn = ['index', 'documentNo'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [visibleDropdrown, setVisibleDropdrown] = useState(false)

  const handleVisibleChange = (flag) => {
    setVisibleDropdrown(flag);
  };

  const handleOnVisiblecolumns = () => {
    setVisibleDropdrown(cur => !cur);
    // if (!visible) {
    //   // FNC คืนค่า Default อัติโนมัติ
    //   const newListArrayColumns = [];
    //   newDataColumns.forEach((element) => {
    //     newListArrayColumns.push(element.key);
    //   });
    //   const reduceOBJ = _.reduce(
    //     newListArrayColumns,
    //     function (result, value, key) {
    //       result[value] = true;
    //       return result;
    //     },
    //     {}
    //   );
    //   setListArrayColumns(newListArrayColumns);
    //   setDefaultShowColumn(reduceOBJ);
    //   setTextErrorSelectColumn('');
    // }
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];

    if (listArrayColumns.length <= (columnMinimum ? columnMinimum : 4)) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }

    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };


  const menuColumn = () => {
    return (
      <Menu className="menu-columns" >
        <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => {
            return (
              <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
                <Checkbox value={item.key} onChange={checkedValuecolumns} checked={defaultShowColumn[item.key] ? true : false}>
                  <span style={{ fontSize: '13px' }}>{item.title}</span>
                </Checkbox>
              </div>
            )
          })}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>{textErrorSelectColumn}</Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };


  return (
    <>
      {
        !loading ? (
          <Dropdown
            className="list-view-dropdown"
            trigger={['click']}
            placement="bottomRight"
            overlay={menuColumn}
            onVisibleChange={handleVisibleChange}
            visible={visibleDropdrown}
          >
            <Button_01 type="primary" btnsize="wd_at" fontSize="sm" onClick={handleOnVisiblecolumns} className="style-buttun-columns">
              <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" /> <Icon type="down" />
            </Button_01>
          </Dropdown >
        ) : null
      }
    </>

  );
};

export default PettyCashDetailActionColumn;


