import httpClient from '../../components/axiosClient';

const getTaskDetail = async (memComID,taskId) => {
  const comCode = localStorage.getItem('comCode');
  const comId = localStorage.getItem('comId')

  try {
    const response = await httpClient.get(`/v3/task/manager/task-information/${memComID}/taskinform/${taskId}?comId=${comId}&isWeb=true`);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

export default getTaskDetail;