import React, { useContext, useEffect, useState } from 'react'
import { Card, Tree, Input } from 'antd'
import { NotificationContext } from './notification-context';
import _ from 'lodash';
import style from './css/notification-list.css'
import {
  Link,
  Redirect,
  BrowserRouter as Router,
  NavLink,
} from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

export default () => {

  const intl = useIntl();

  const { TreeNode, DirectoryTree } = Tree;
  const { notiState, notiSetState, notiFNC } = useContext(NotificationContext);
  



  return (
    <div>
      <Card
        title={intl.formatMessage({ id: 'notificationTitle', defaultMessage: 'Notification' })}
      >
        <div className='div-content-body-search'>
          <Input
            placeholder={intl.formatMessage({ id: 'notificationSearch', defaultMessage: 'Search...' })}
            onChange={notiFNC.onSearchChange}
            autoComplete="off"
          />
        </div>
        <div className='div-content-body-menu'>
          <DirectoryTree
            showIcon={false}
            onSelect={(e) => notiFNC.handleSelectMenu(e)}
          //defaultSelectedKeys={['task']}
          // selectedKeys={[app.state.rolesSelect]}
          >
            {notiState.menuDataSearch && notiState.menuDataSearch.map((menu) => (

              <TreeNode className="styleTreeNode"
                key={menu.notiTypeCode}
                // title={menu.name}   
                title={(
                  <div>
                    {/* <Link to={{ pathname: `/main/notification?typeCode=${menu.name}` }} Style="padding-left:5px">
                      {' '} */}
                    <span>{menu.name} ({menu.total})</span>
                    {/* {' '}
                    </Link> */}
                  </div>
                )}
              />

            ))}
          </DirectoryTree>
        </div>
      </Card>
    </div>
  )
}
