import React, { createContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { tabDefault, columnChange, defaultIgnoreColumns } from './initial/default';
import _ from 'lodash';
import ModalMemberExpense from './components/modal';
import { exportMemberExpenseResource, getListMemberExpensePage, getMemberExpenseDetail, updateMemberExpense } from '../../controllers/member-expense/member-expense-api';
import ModalAllowance from '../resource-allowance/components/modal';
import { errorNotification, successNotification } from '../workdesk/notification';
import moment from 'moment';
import { getAllowanceDetail } from '../../controllers/resource-allowance/allowance'
import { Modal } from 'antd';

const MemberExpenseProvider = createContext();

const MemberExpenseContext = ({ children }) => {
  const intl = useIntl();
  const tabData = tabDefault();
  const [activeTab, setActiveTab] = useState('pending');
  const defaultColumnsData = columnChange('pending');
  const newColumns = defaultIgnoreColumns(_.get(defaultColumnsData, 'columns'), '');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [actionColumns, setActionColumns] = useState([..._.get(defaultColumnsData, 'columns')]);
  const [listArrayColumns, setListArrayColumns] = useState([..._.get(defaultColumnsData, 'setShowColumnArr')]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ..._.get(defaultColumnsData, 'setShowColumn') });
  let arry = [];
  for (let index = 0; index < 22; index++) {
    arry.push({
      index: index + 1,
      statusCode: 'pending',
    });
  }

  const [datasource, setDatasource] = useState(arry);
  const [total, setTotal] = useState(_.size(arry));
  const [searchGroup, setSearchGroup] = useState({});
  const [tableChange, setTableChange] = useState({ page: 1, pageSize: 10, orderBy: null, orderField: null });
  const [trigger, setTrigger] = useState(false);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [defaultModal, setDefaultModal] = useState({ data: {}, type: 'pending', visible: false });
  const [visibleAllowance, setVisibleAllowance] = useState(false);
  const [editData, setEditData] = useState();
  const [dataFilter, setDataFilter] = useState({ memberList: undefined, teamList: undefined, numberType: undefined });
  const [rejectList, setRejectList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [tabkeys, setTabkeys] = useState('details');

  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      const payload = {
        tabCode: activeTab,
        memberIdList: _.get(searchGroup, 'memberIdList') ? [_.get(searchGroup, 'memberIdList')] : [],
        teamId: _.get(searchGroup, 'teamId') ? [_.get(searchGroup, 'teamId')] : [],
        memberExpenseTypeCode: _.get(searchGroup, 'memberExpenseTypeCode') ? [_.get(searchGroup, 'memberExpenseTypeCode')] : [],
        startDate: _.get(searchGroup, 'rangePickerDate[0]') ? moment(_.get(searchGroup, 'rangePickerDate[0]')).format('YYYY-MM-DD') : '',
        endDate: _.get(searchGroup, 'rangePickerDate[1]') ? moment(_.get(searchGroup, 'rangePickerDate[1]')).format('YYYY-MM-DD') : '',
        pageNumber: _.get(tableChange, 'page'),
        limit: _.get(tableChange, 'pageSize'),
        orderBy: _.get(tableChange, 'orderField') ? _.get(tableChange, 'orderField') : null,
        orderType: _.get(tableChange, 'orderBy') ? _.get(tableChange, 'orderBy') : null,
      };

      const response = await getListMemberExpensePage(payload);
      if (_.get(response, 'status.code') === 200) {
        setDatasource(_.get(response, 'data.memberExpenseList'));
        setTotal(_.get(response, 'data.totalItem'));
        setDataFilter({
          memberList: _.get(response, 'data.memberList'),
          teamList: _.get(response, 'data.teamList'),
          numberType: _.get(response, 'data.memberExpenseTypeList'),
          whtRateList: _.get(response, 'data.whtRateList'),
        });
        setRejectList(_.get(response, 'data.reasonReject'));
        setLoading(false);
      }
    };

    if (activeTab) {
      getApi();
    }
  }, [activeTab, trigger, tableChange, _.get(searchGroup, 'rangePickerDate')]);

  const handleChangeTab = (key) => {
    try {
      const columnsForTab = columnChange(key);
      const filterIgnore = defaultIgnoreColumns(_.get(columnsForTab, 'columns'), key);
      setNewDataColumns(filterIgnore);
      setActionColumns(_.get(columnsForTab, 'columns'));
      setListArrayColumns(_.get(columnsForTab, 'setShowColumnArr'));
      setDefaultShowColumn(_.get(columnsForTab, 'setShowColumn'));
      setActiveTab(key);
      setSelectList([]);
      setSelectedRowKeys([]);
      setTableChange({ ...tableChange, page: 1 });
      setSearchGroup({})
    } catch (error) {
      console.log('error::', error);
    }
  };

  const handleChangeStatus = async (status, resson) => {
    const body = {
      memberExpenseIdList: _.size(selectList) > 0 ? selectList : [_.get(defaultModal, 'data.memberExpenseId')],
      statusCode: status,
      statusRemark: resson ? _.get(resson, 'remark') : null,
      statusReasonId: resson ? _.get(resson, 'reasonId') : null,
    };
    const response = await updateMemberExpense(body);

    console.log('TOMTOM', response);

    if (_.get(response, 'status.code') === 200) {
      successNotification(_.get(response, 'status.message'));
      const calpage = _.size(selectedRowKeys) > 0 ?  (total - _.size(selectedRowKeys)) % _.get(tableChange, 'pageSize'): (total - 1) % _.get(tableChange, 'pageSize')
      setDefaultModal({
        data: {},
        type: '',
        visible: false,
      });
      setSelectList([]);
      setSelectedRowKeys([]);
      setTabkeys('details');
     

      console.log('calpage',calpage);
      

      if (calpage === 0 && _.get(tableChange,'page') !== 1) {
        setTableChange((e) => ({ ...tableChange, page: _.get(e,'page') - 1 }));
      } else {
        setTrigger((cur) => !cur);
      }
    } else {
      errorNotification(_.get(response, 'data.status.message'));
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      let tempselect = [];
      _.map(selectedRows, (item) => {
        console.log('selectedRows: ', item.memberExpenseId);
        tempselect.push(item.memberExpenseId);
      });
      setSelectList(tempselect);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const refreshExpenseType = async() => {
    const payload = {
      tabCode: activeTab,
      memberIdList: [],
      teamId: [],
      memberExpenseTypeCode: [],
      startDate: '',
      endDate:  '',
      pageNumber: 1,
      limit: 1,
      orderBy:  null,
      orderType: null,
    };

    const response = await getListMemberExpensePage(payload);
    if (_.get(response, 'status.code') === 200) {
      
      setDataFilter({
        memberList: _.get(response, 'data.memberList'),
        teamList: _.get(response, 'data.teamList'),
        numberType: _.get(response, 'data.memberExpenseTypeList'),
        whtRateList: _.get(response, 'data.whtRateList'),
      });
      return _.get(response, 'data.memberExpenseTypeList')
    }
  }

  const handleTableChange = (pagination, sorter, extra) => {
    let field = extra.field;

    if (extra.order) {
      if (extra.order === 'descend') {
        setTableChange((prev) => ({ ...prev, orderBy: 'desc' }));
      } else {
        setTableChange((prev) => ({ ...prev, orderBy: 'asc' }));
      }
    } else {
      setTableChange((prev) => ({ ...prev, orderBy: '' }));
    }

    if (pagination.current !== _.get(tableChange,'page')) {
      setSelectedRowKeys([]);
    }
    setTableChange((prev) => ({ ...prev, orderField: field, page: pagination.current, pageSize: pagination.pageSize }));
  };

  const handleOpenModal = async (record, type) => {
    if (type === 'create') {
      setDefaultModal({
        data: {},
        type: type,
        visible: true,
      });
    } else {
      const getDetail = await getMemberExpenseDetail(_.get(record, 'memberExpenseId'));
      if (_.get(getDetail, 'status') === 200) {
        setDefaultModal({
          data: _.get(getDetail, 'data.data'),
          type: type,
          visible: true,
        });
      }
    }
  };

  const handleOpenAllowance = async (id) => {
    const getDetail = await getAllowanceDetail(id);
    if (_.get(getDetail, 'status') === 200) {
      setEditData(_.get(getDetail, 'data.data'));
      setVisibleAllowance(true);
    }
  };

  const handleExport = async () => {
    try {
      let NewFormatExport = [];
      const defaultFormat = [
        {
          colName: intl.formatMessage({ id: 'memberExpenseCLMember', defaultMessage: 'Member' }),
          sequence: 1,
          colCode: 'memberName',
        },
        {
          colName: intl.formatMessage({ id: 'memberExpenseCLTeam', defaultMessage: 'Team' }),
          sequence: 2,
          colCode: 'teamName',
        },
        {
          colName: intl.formatMessage({ id: 'memberExpenseCLType', defaultMessage: 'Type' }),
          sequence: 3,
          colCode: 'typeName',
        },
        {
          colName: intl.formatMessage({ id: 'memberExpenseCLAmount', defaultMessage: 'Amount' }),
          sequence: 4,
          colCode: 'amountTxt',
        },
        {
          colName: intl.formatMessage({ id: 'memberExpenseCLWHTRate', defaultMessage: 'WHT Rate' }),
          sequence: 5,
          colCode: 'whtRateTxt',
        },
        {
          colName: intl.formatMessage({ id: 'memberExpenseCLWHT', defaultMessage: 'WHT' }),
          sequence: 6,
          colCode: 'whtTxt',
        },
        {
          colName: intl.formatMessage({ id: 'memberExpenseCLNetAmount', defaultMessage: 'Net Amount' }),
          sequence: 7,
          colCode: 'netAmountTxt',
        },
        {
          colName: intl.formatMessage({ id: 'memberExpenseCLRemark', defaultMessage: 'Remark' }),
          sequence: 8,
          colCode: 'remark',
        },

      ];

      if (activeTab !== 'pending') {
        NewFormatExport = _.concat(defaultFormat, [
          {
            colName: intl.formatMessage({ id: 'memberExpenseCLPlanPaymentDate', defaultMessage: 'Planned Payment Date' }),
            sequence: 9,
            colCode: 'planedPaymentDateTxt',
          },
          {
            colName: intl.formatMessage({ id: 'memberExpenseCLCreateDate', defaultMessage: 'Create Date' }),
            sequence: 10,
            colCode: 'createdDate',
          },
        ]);
        if (activeTab === 'reject') {
          NewFormatExport = _.concat(NewFormatExport, [
            {
              colName: intl.formatMessage({ id: 'resourceAllowanceRejectReason', defaultMessage: 'Reject Reason' }),
              sequence: 11,
              colCode: 'rejectReason',
            },
            {
              colName: intl.formatMessage({ id: 'resourceAllowanceRejectRemark', defaultMessage: 'Reject Remark' }),
              sequence: 12,
              colCode: 'rejectRemark',
            },
          ]);
        }
      } else {
        NewFormatExport = _.concat(defaultFormat, [
          {
            colName: intl.formatMessage({ id: 'memberExpenseCLCreateDate', defaultMessage: 'Create Date' }),
            sequence: 9,
            colCode: 'createdDate',
          },
        ]);
      }

      const body = {
        tabCode: activeTab,
        memberIdList: _.get(searchGroup, 'memberIdList') ? [_.get(searchGroup, 'memberIdList')] : [],
        teamId: _.get(searchGroup, 'teamId') ? [_.get(searchGroup, 'teamId')] : [],
        memberExpenseTypeCode: _.get(searchGroup, 'memberExpenseTypeCode') ? [_.get(searchGroup, 'memberExpenseTypeCode')] : [],
        startDate: _.get(searchGroup, 'rangePickerDate[0]') ? moment(_.get(searchGroup, 'rangePickerDate[0]')).format('YYYY-MM-DD') : '',
        endDate: _.get(searchGroup, 'rangePickerDate[1]') ? moment(_.get(searchGroup, 'rangePickerDate[1]')).format('YYYY-MM-DD') : '',
        pageNumber: 1,
        limit: total,
        orderBy: _.get(tableChange, 'orderField') ? _.get(tableChange, 'orderField') : null,
        orderType: _.get(tableChange, 'orderBy') ? _.get(tableChange, 'orderBy') : null,

        viewFormat: _.size(NewFormatExport) > 0 ? NewFormatExport : defaultFormat,
      };
      const response = await exportMemberExpenseResource(body);

      if (response.data.status.code === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) { }
  };

  const handleStatus = (status, defaultModal, handleChangeStatus) => {
    // if (status !== 'reject' ) {  
    const text =
      status === 'waiting' && _.get(defaultModal, 'statusCode') === 'pending'
        ? intl.formatMessage({ id: 'memberExpenseApprovalPending', defaultMessage: 'Do you want to send apprroval ?' })
        : status === 'waiting' &&
          (_.get(defaultModal, 'data.statusCode') === 'reject' || _.get(defaultModal, 'statusCode') === 'approve')
        ? intl.formatMessage({ id: 'memberExpenseApprovalReject', defaultMessage: 'Do you want to change status to waiting for approval?' })
        : status === 'pending'
        ? intl.formatMessage({ id: 'memberExpensePending', defaultMessage: 'Do you want to change status to pending ?' })
        : status === 'approve' && _.get(defaultModal, 'data.statusCode') === 'reject'
        ? intl.formatMessage({ id: 'memberExpenseApprovedReject', defaultMessage: 'Do you want to change status to approved ?' })
        : status === 'waitingpaid'
        ? intl.formatMessage({ id: 'memberExpenseSendApproved', defaultMessage: 'Do you want to approved ?' })
        : status === 'paid'
        ? intl.formatMessage({ id: 'memberExpenseSendPaid', defaultMessage: 'Do you want to paid ?' })
        : status === 'cancel'
        ? intl.formatMessage({ id: 'memberExpenseCancel', defaultMessage: 'Do you want to cancel?' })
        : '';
  
  
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: text,
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        
        handleChangeStatus(status )
      },
      onCancel() {},
    });
  //   } else {
  //     setVisibleStatus(true);
  //   }
  };
  

  return (
    <MemberExpenseProvider.Provider
      value={{
        state: {
          tabData,
          activeTab,
          searchGroup,
          tableChange,
          listArrayColumns,
          defaultShowColumn,
          textErrorSelectColumn,
          visible,
          actionColumns,
          loading,
          defaultColumnsData,
          newColumns,
          newDataColumns,
          datasource,
          selectedRowKeys,
          total,
          dataFilter,
          selectList,
        },
        setState: {
          setSearchGroup,
          setTableChange,
          setTrigger,
          setNewDataColumns,
          setListArrayColumns,
          setDefaultShowColumn,
          setTextErrorSelectColumn,
          setVisible,
          setSelectList,
          setSelectedRowKeys,
        },
        fnc: {
          handleChangeTab,
          rowSelection,
          handleTableChange,
          handleOpenModal,
          handleChangeStatus,
          handleExport,
          handleStatus,
        },
      }}
    >
      {children}

      <ModalMemberExpense
        defaultModal={defaultModal}
        setDefaultModal={setDefaultModal}
        handleOpenAllowance={handleOpenAllowance}
        dataFilter={dataFilter}
        setTrigger={setTrigger}
        rejectList={rejectList}
        handleChangeStatus={handleChangeStatus}
        tabkeys={tabkeys}
        setTabkeys={setTabkeys}
        refreshExpenseType={refreshExpenseType}
        handleStatus={handleStatus}
      />

      <ModalAllowance
        visible={visibleAllowance}
        setVisible={setVisibleAllowance}
        data={editData}
        setData={setEditData}
        isView={true}
      />
    </MemberExpenseProvider.Provider>
  );
};

export { MemberExpenseProvider, MemberExpenseContext };
