import React, { Component, useEffect } from 'react';

import { Col, Divider, Modal, Row, Table } from 'antd';

import { useReactToPrint } from 'react-to-print';

import _ from 'lodash';

import { FormattedMessage, useIntl } from 'react-intl';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import moment from 'moment';
import './index.css';

// import { useState } from "react";

export default class ARPrint extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.state = {
      ComloopTable: [],
      ComloopTableDetail: [],
      DataPO: this.props.record,
      DataCreadit: this.props.recordReport,
      totalCredit: 0,
      page: 0,
    };
  }

  render() {
    const DataQT = this.props.record;
    const DataDetail = this.props.recordReport;
    const totalNum = _.get(DataQT, 'total') ? _.get(DataQT, 'total') : 0;
    const signatureUser = this.props.signatureUser;
    const memComName = localStorage.getItem('name');
    const sizeChange = 11;
    const MaximumCal = 14;
    const MaximumRow = 15
    const chunkArrayTest = _.chunk(DataDetail, MaximumRow);
    let validDay = _.findLast(DataDetail, function(n) {
      return n;
    });
    const chuckLastIndex = _.findLastIndex(chunkArrayTest, (e) => {
      return e;
    });
    const chunkArrayDe = [...chunkArrayTest, { blank: _.size(chunkArrayTest) }];
    const chunkArray =
      _.size(chunkArrayTest[chuckLastIndex]) <= MaximumRow && _.size(chunkArrayTest[chuckLastIndex]) >= sizeChange
        ? chunkArrayDe
        : chunkArrayTest;

    console.log('LastINdex', DataQT);
    console.log('LastData', _.get(validDay, 'credit'));

    const LoopTableCredit = (indexchuck) => {
      const viewloop = [];
      let indexcount = 0;
      let chuckcount = indexchuck;

      const callsize = this.props.sizeRecordReport + 2 - (this.props.sizeRecordReport - _.size(this.state.DataCreadit));
      const callsizeLarge = callsize + 10;

      console.log('chunkArray', chuckcount, chunkArray, _.size(this.state.DataCreadit));
      for (let index = 0; _.size(chunkArray[chuckcount]) >= sizeChange ? index < MaximumRow : index < sizeChange; index++) {
        // for (let index = 0; this.props.sizeRecordReport >= 9 ? index < callsizeLarge: index < callsize; index++) {
        console.log('ArrayIndex', chunkArray[chuckcount], '/', index >= _.size(chunkArray[chuckcount]));
        let borderBottomLine = _.size(chunkArray[chuckcount]) >= sizeChange && index === MaximumRow ? '1px solid' : 'unset';
        let HeightSpace = _.size(chunkArray[chuckcount]) >= sizeChange ? 'tdCreaditTextBlank' : 'tdCreaditTextBlank10';
        viewloop.push(
          index >= _.size(chunkArray[chuckcount]) || _.get(chunkArray[chuckcount], 'blank') ? (
            <tr
              style={{
                height: '35px',
                paddingTop: '10px',
                fontSize: '17px',
              }}
            >
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
            </tr>
          ) : _.get(chunkArray[chuckcount][index], 'itemName') ? (
            // <>
            //   <tr
            //     style={{
            //       height: '20px',
            //       paddingTop: '10px',
            //       fontSize: '17px',
            //     }}
            //   >
            //     <td
            //       className="tdCreaditText"
            //       span={1}
            //       style={{
            //         textAlign: 'center',
            //         borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
            //       }}
            //     >
            //       {_.get(chunkArray[chuckcount][index], 'index')}
            //     </td>
            //     <td
            //       className="tdCreaditText"
            //       span={11}
            //       style={{
            //         textAlign: 'left',
            //         paddingLeft: '10px',
            //         borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
            //       }}
            //     >
            //       {_.get(chunkArray[chuckcount][index], 'itemName')}
            //     </td>
            //     <td
            //       className="tdCreaditText"
            //       span={4}
            //       style={{
            //         textAlign: 'right',
            //         paddingRight: '10px',
            //         borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
            //       }}
            //     >
            //       {/* {numberFormatter(_.get(chunkArray[chuckcount][index], 'pricePerUnit'))} */}
            //       {numberFormatter(_.get(chunkArray[chuckcount][index], 'pricePerUnitNum'))}
            //     </td>
            //     <td
            //       className="tdCreaditText"
            //       span={2}
            //       style={{
            //         textAlign: 'right',
            //         paddingRight: '10px',
            //         borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
            //       }}
            //     >
            //       {_.get(chunkArray[chuckcount][index], 'qtyNum')}
            //     </td>
            //     <td
            //       className="tdCreaditText"
            //       span={2}
            //       style={{
            //         borderLeft: '1px solid',
            //         textAlign: 'left',
            //         borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
            //       }}
            //     >
            //       {_.get(chunkArray[chuckcount][index], 'uom')}
            //     </td>
            //     <td
            //       className="tdCreaditText"
            //       span={4}
            //       style={{
            //         textAlign: 'right',
            //         paddingRight: '10px',
            //         borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
            //       }}
            //     >
            //       {/* {numberFormatter(_.get(chunkArray[chuckcount][index], 'totalPrice'))} */}
            //       {numberFormatter(_.get(chunkArray[chuckcount][index], 'totalNum'))}
            //     </td>
            //   </tr>
            // </>
            <>
              <tr
                style={{
                  height: '10px',
                  paddingTop: '10px',
                  fontSize: '17px',
                }}
              >
                <td
                  className="tdCreaditText"
                  span={1}
                  style={{
                    textAlign: 'center',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'index')}
                </td>
                <td
                  className="tdCreaditText"
                  span={11}
                  style={{
                    textAlign: 'left',
                    paddingLeft: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'itemCode')}
                </td>
                <td
                  className="tdCreaditText"
                  span={11}
                  style={{
                    textAlign: 'left',
                    paddingLeft: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'itemName')}
                </td>
                <td
                  className="tdCreaditText"
                  span={2}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'qtyTxt')}
                </td>
                <td
                  className="tdCreaditText"
                  span={4}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {numberFormatter(_.get(chunkArray[chuckcount][index], 'pricePerUnitNum'))}
                </td>
                <td
                  className="tdCreaditText"
                  span={2}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {numberFormatter(_.get(chunkArray[chuckcount][index], 'discountNum'))}
                </td>
                <td
                  className="tdCreaditText"
                  span={4}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {numberFormatter(_.get(chunkArray[chuckcount][index], 'totalNum'))}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr style={{ border: '1px solid', padding: ' 5px 15px', height: '20px' }}>
                <td
                  colspan="4"
                  style={{
                    fontSize: '17px',
                    borderBottom: '1px solid',
                    borderTop: '1px solid',

                    padding: '6px',

                    paddingLeft: '7px',
                  }}
                >
                  <FormattedMessage id="QTPrintTitlePhoneCredit" defaultMessage="เครดิต" /> {_.get(chunkArray[chuckcount][index], 'credit')}{' '}
                  {/* <FormattedMessage id="QTPrintTitlePhoneCredit" defaultMessage="เครดิต" /> {_.get(this.state.DataCreadit[index], 'credit')}{' '} */}
                  <FormattedMessage id="QTPrintTitlePhoneDay" defaultMessage="วัน" /> 
                  <FormattedMessage id="QTPrintTitlePhoneDay" defaultMessage="วันที่ครบกำหนด" />:{' '}
                  {_.get(chunkArray[chuckcount][index], 'creditday')}
                </td>
                <td
                  colspan="3"
                  style={{
                    fontSize: '17px',
                    textAlign: 'right',
                    borderBottom: '1px solid',
                    borderTop: '1px solid',

                    padding: '6px',
                  }}
                >
                  <FormattedMessage id="QTPrintTitleTotal" defaultMessage="รวม" />{' '}
                  {numberFormatter(_.get(chunkArray[chuckcount][index], 'total'))}
                </td>
              </tr>
            </>
          )
        );
      }
      let countPage = chuckcount + 1;
      if (countPage === _.size(chunkArray) - 1) {
        countPage = _.size(chunkArray) - 1;
      }
      // console.log('chunkArrayviewloop', viewloop);
      return viewloop;
      // this.setState({ ComloopTable: viewloop, page: countPage });
    };

    const ThaiNumberToText = (number) => {
      console.log('numberPrint', number);
      const splitDot = _.split(number, '.');
      const fullNumber = _.get(splitDot, '[0]');
      const dotNumber = _.get(splitDot, '[1]');
      const num = ['ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
      const pos = ['หน่วย', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน'];
      let res = '';

      for (let index = 0; index < _.size(fullNumber); index++) {
        if (fullNumber[index] !== '0') {
          console.log('fullNumber', fullNumber[index]);
          res = res + num[parseInt(fullNumber[index])];
          res = res + pos[_.size(fullNumber) - (index + 1)];
        }
      }
      let resDot = '';
      for (let index = 0; index < _.size(dotNumber); index++) {
        if (dotNumber[index] !== '0') {
          resDot = resDot + num[parseInt(dotNumber[index])];
          resDot = resDot + pos[_.size(dotNumber) - (index + 1)];
        }
      }
      res = res.replace('หนึ่งสิบ', 'สิบ');
      res = res.replace('ศูนย์หน่วย', '');
      res = res.replace('หนึ่งหน่วย', 'เอ็ด');
      res = res.replace('หนึ่งหน่วย', 'เอ็ด');
      res = res.replace('สองสิบ', 'ยี่สิบ');
      res = res.replace('หน่วย', '');
      if (res === 'เอ็ด') {
        res = 'หนึ่ง';
      }
      resDot = resDot.replace('หนึ่งสิบ', 'สิบ');
      resDot = resDot.replace('ศูนย์หน่วย', '');
      resDot = resDot.replace('หนึ่งหน่วย', 'เอ็ด');
      resDot = resDot.replace('หนึ่งหน่วย', 'เอ็ด');
      resDot = resDot.replace('สองสิบ', 'ยี่สิบ');
      resDot = resDot.replace('หน่วย', '');
      if (resDot === 'เอ็ด') {
        resDot = 'หนึ่ง';
      }
      // if () {

      // }
      if (parseInt(dotNumber) === 0) {
        return res + 'บาทถ้วน';
      }
      if (parseInt(dotNumber) > 0) {
        return res + 'บาท' + resDot + 'สตางค์';
      }
    };

    return (
      <div ref={this.props.ref}>
        {chunkArray.map((item, index) => {
          return (
            <div className="printable" style={{ padding: '55px', width: '1000px', height: '1200px' }}>
              <Row type="flex" align="middle">
                <Col span={12} style={{ fontSize: '24px' }}>
                  {_.get(DataQT, 'companyName')}
                  {/* <FormattedMessage id="QTPrintTitleCompany" defaultMessage="บริษัทแสงศิริเคมีเกษตรจำกัด" /> */}
                </Col>
                <Col span={12} style={{ textAlign: 'right', fontSize: '24px' }}>
                  <FormattedMessage id="ARTitleType" defaultMessage="ใบส่งของ" />
                </Col>
              </Row>
              <Row>
                <Col span={20} style={{ fontSize: '17px' }}>
                  {_.get(DataQT, 'companyAddress')}
                </Col>
                <Col span={4} style={{ fontSize: '20px', textAlign: 'right' }}>
                  <FormattedMessage id="QTPrintTitlePage" defaultMessage="หน้า" /> {index + 1} / {_.size(chunkArray)}
                </Col>
              </Row>
              <Row style={{  }}>
                <Col span={8} style={{ fontSize: '17px', color: '#050505',display: 'flex' }}>
                <span style={{ width: '115px' }}>
                  <FormattedMessage id="QTPrintTitleTax" defaultMessage="เลขผู้เสียภาษี" />:{' '}</span>
                  <span style={{ color: '#050505' }}>{_.get(DataQT, 'companyTaxNo')}</span>
                </Col>
              </Row>
              <Row style={{  }}>
                <Col span={8} style={{ fontSize: '17px', color: '#050505',display: 'flex' }}>
                <span style={{ width: '115px' }}>
                  <FormattedMessage id="QTPrintTitlePhone" defaultMessage="เบอร์โทรศัพท์" />:{' '}</span>
                  <span style={{ color: '#050505' }}>{_.get(DataQT, 'companyPhone')}</span>
                </Col>
              </Row>
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '50%' }}>
                  <div className="TopicData">
                    <FormattedMessage id="QTPrintTitleCustomer" defaultMessage="ลูกค้า" />{' '}
                  </div>
                  <div className="topiData30">{_.get(DataQT, 'customerName')}</div>
                  <div className="TopicData">{_.get(DataQT, 'customerAddress')} </div>
                  <div className="TopicData">
                    <FormattedMessage id="QTPrintTitleContact" defaultMessage="ผู้ติดต่อ" />:{' '}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {_.get(DataQT, 'customerContact')}
                  </div>
                  <div className="TopicData" style={{ paddingTop: '0px' }}>
                    <FormattedMessage id="QTPrintTitlePhoneContact" defaultMessage="เบอร์โทรศัพท์" />: {_.get(DataQT, 'customerPhone')}
                  </div>
                </div>

                <div>
                  <div className="TopicDataR">
                    <FormattedMessage id="ARPrintTitleARNO" defaultMessage="เลขที่ใบส่งของ" />
                  </div>
                  <div className="topiData30R">{_.get(DataQT, 'invoiceNo')}</div>
                  <div className="TopicDataR">
                    {' '}
                    <FormattedMessage id="QTPrintTitleDate" defaultMessage="วันที่ทำรายการ" />
                    <p style={{ textAlign: 'right', fontSize: '24px' }}>
                      {moment(_.get(DataQT, 'issueDate'))
                        .add(543, 'year')
                        .format('DD/MM/YYYY')}
                    </p>{' '}
                  </div>
                </div>
              </div> */}
              <div className="borderInfoTop" style={{ display: 'flex', marginTop: '10px', }}>
                <div className="paddingInFoTop" style={{ width: '550px', paddingBottom: '10px' }}>
                  <div className="TopicData" style={{ display: 'flex', paddingTop: '10px' }}>
                    <span style={{ width: '50%', display: 'flex' }}>
                      <span style={{ width: '105px' }}>
                        <FormattedMessage id="QTPrintTitleCustomerCode" defaultMessage="รหัสลูกค้า" />:
                      </span>
                      <span>{_.get(DataQT, 'customerCode')} </span>
                    </span>
                    <span style={{ width: '50%' }}>
                      {' '}
                      <FormattedMessage id="QTPrintTitleTax" defaultMessage="เลขผู้เสียภาษี" />
                      :&nbsp; {_.get(DataQT, 'customerTaxNo')}
                    </span>
                  </div>
                  <div className="TopicData" style={{ display: 'flex' }}>
                    <span style={{ width: '105px' }}>
                      <FormattedMessage id="QTPrintTitleCustomer" defaultMessage="ลูกค้า" />:
                    </span>
                    <span style={{}}>{_.get(DataQT, 'customerName')}</span>
                  </div>
                  <div className="TopicData" style={{ display: 'flex' }}>
                    <span style={{ width: '105px' }}>
                      <FormattedMessage id="SOPrintTitleAddress" defaultMessage="ที่อยู่" />:
                    </span>
                    <span style={{ width: '82%' }}>{_.get(DataQT, 'customerAddress')}</span>
                  </div>
                  <div className="TopicData" style={{ display: 'flex' }}>
                    {' '}
                    <span style={{ width: '50%' }}>
                      <FormattedMessage id="QTPrintTitleContact" defaultMessage="ผู้ติดต่อ" />:{' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {_.get(DataQT, 'customerContact')}{' '}
                    </span>
                    <span style={{ width: '50%' }}>
                      <FormattedMessage id="QTPrintTitlePhoneContact" defaultMessage="เบอร์โทรศัพท์" />: &nbsp;
                      {_.get(DataQT, 'customerPhone')}
                    </span>
                  </div>
                </div>

                <div style={{ width: '335px', borderLeft: '1px solid' }}>
                  <div className="paddingInFoTop" style={{ height: '50%', borderBottom: '1px solid' }}>
                    <div className="TopicDataRPO" style={{ paddingTop: '10px' }}>
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="ARPrintTitleARNO" defaultMessage="เลขที่ใบส่งของ" />:{' '}
                      </span>
                      <span style={{ width: '50%' }}> {_.get(DataQT, 'invoiceNo')}</span>
                    </div>
                    {/* <div className="topiData30R">{_.get(DataQT, 'poNo')}</div> */}
                    <div className="TopicDataRPO">
                      {' '}
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="QTPrintTitleDate" defaultMessage="วันที่ทำรายการ" />:
                      </span>
                      <span>
                        {' '}
                        {moment(_.get(DataQT, 'issueDate'))
                          .add(543, 'year')
                          .format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </div>

                  <div className="paddingInFoTop" style={{ height: '50%' }}>
                    <div className="TopicDataRPO" style={{ paddingTop: '10px' }}>
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="ARTitleSaleOrderNumber" defaultMessage="เลขที่ใบสั่งขาย" />:{' '}
                      </span>
                      <span style={{ width: '50%' }}> {_.get(DataQT, 'saleOrderNo')}</span>
                    </div>
                    <div className="TopicDataRPO">
                      {' '}
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="ARTitleSaleOrderDate" defaultMessage="วันที่ใบสั่งขาย" />:
                      </span>
                      <span>
                        {moment(_.get(DataQT, 'saleOrderDate'))
                          .add(543, 'year')
                          .format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table" style={{ border: '1px solid', width: '890px', marginBottom: '0px', borderBottom: 'unset' }}>
                {/* <thead style={{ backgroundColor: '#E2E2E2' }}>
                  <tr className="tdBG" style={{  }}>
                    <th className="thHeadCredit" style={{ width: '50px' }}>
                      <div className="conTh">#</div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '401px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleList" defaultMessage="ชื่อรายการ" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '125px' }}>
                      <div className="conTh">
                        {' '}
                        <FormattedMessage id="QTPrintTitleUnitprice" defaultMessage="ราคาต่อหน่วย" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '100px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleNumber" defaultMessage="จำนวน" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '100px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleUnit" defaultMessage="หน่วย" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '125px' }}>
                      <div className="conTh">
                        {' '}
                        <FormattedMessage id="QTPrintTitlePrice" defaultMessage="ราคา" />
                      </div>
                    </th>
                  </tr>
                </thead> */}

                <thead>
                  {/* <tr className="tdBG" style={{ fontWeight: 'bold' }}> */}
                  <tr className="tdBG">
                    <th className="thHeadCredit" style={{ width: '50px' }}>
                      <div className="conTh">#</div>
                    </th>
                    {/* <th style={{ border: '1px solid', textAlign: 'center', width: '400px',fontWeight: 'bold' }}> */}
                    <th className="thHeadCredit" style={{ width: '125px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleCode" defaultMessage="รหัสสินค้า" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '277px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleList" defaultMessage="ชื่อรายการ" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '100px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleNumber" defaultMessage="จำนวน" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '120px' }}>
                      <div className="conTh">
                        {' '}
                        <FormattedMessage id="QTPrintTitleUnitprice" defaultMessage="ราคาต่อหน่วย" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '93px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleDiscount" defaultMessage="ส่วนลด" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '125px' }}>
                      <div className="conTh">
                        {' '}
                        <FormattedMessage id="QTPrintTitlePrice" defaultMessage="ราคา" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ height: '10px' }}>{_.map(LoopTableCredit(index), (item) => item)}</tbody>
              </table>
              {_.size(chunkArray[index]) >= sizeChange ? null : (
                <>
                  {' '}
                  <table width="100%" style={{ border: '1px solid', borderBottom: '1px' }}>
                    <tbody style={{ height: '10px' }}>
                      <tr style={{ borderTop: '1px solid', height: '20px' }}>
                        {/* <td style={{ fontSize: '15px', borderTop: '1px solid', padding: ' 6px 7px', width: '25%' }}>
                          <span style={{  }}>
                            <FormattedMessage id="ARTitleSaleOrderNumber" defaultMessage="เลขที่ใบสั่งขาย" />
                          </span>
                          : {_.get(DataQT, 'saleOrderNo')}
                        </td>
                        <td
                          style={{ fontSize: '15px', borderTop: '1px solid', padding: ' 6px 7px', width: '25%', borderLeft: '1px solid' }}
                        >
                          <span style={{  }}>
                            <FormattedMessage id="ARTitleSaleOrderDate" defaultMessage="วันที่ใบสั่งขาย" />
                          </span>
                          :{' '}
                          {moment(_.get(DataQT, 'saleOrderDate'))
                            .add(543, 'year')
                            .format('DD/MM/YYYY')}
                        </td> */}
                        <td
                          colSpan="2"
                          style={{
                            fontSize: '17px',
                            borderTop: '1px solid',
                            paddingBottom: '0px',
                            padding: ' 3px 12px',
                            width: '50%',
                          }}
                        >
                          <FormattedMessage id="QTPrintTitleRemark" defaultMessage="หมายเหตุ" />:
                        </td>
                        <td
                          className="tdPriceText"
                          colspan="3"
                          style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', verticalAlign: 'bottom' }}
                        >
                          <FormattedMessage id="QTPrintTitlePhoneTotalPrice" defaultMessage="ราคารวม" />
                        </td>
                        <td
                          className="tdPrice"
                          style={{ textAlign: 'right', paddingRight: '10px', borderTop: '1px solid', verticalAlign: 'bottom' }}
                        >
                          {numberFormatter(_.get(DataQT, 'subtotal'))}
                        </td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        {/* <td
                          colSpan="2"
                          style={{
                            fontSize: '17px',
                            borderTop: '1px solid',
                            paddingBottom: '0px',
                            padding: ' 6px 7px',
                            
                          }}
                        >
                          <FormattedMessage id="QTPrintTitleRemark" defaultMessage="หมายเหตุ" />:
                        </td> */}
                        <td className="tdPriceText" rowspan="4" colSpan="2" style={{ paddingTop: '1px', verticalAlign: 'top' }}>
                          {_.get(DataQT, 'remark')}
                        </td>
                        <td colspan="3" className="tdPriceText">
                          <FormattedMessage id="QTPrintTitleDiscount" defaultMessage="ส่วนลด" /> {_.get(DataQT, 'discountPercent')}%
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'discountNum'))}</td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        {/* <td className="tdPriceText" rowspan="3" colSpan="2" style={{ paddingTop: '1px', verticalAlign: 'top' }}>
                          {_.get(DataQT, 'remark')}
                        </td> */}
                        <td colspan="3" className="tdPriceText">
                          <FormattedMessage id="QTPrintTitlesubTotalAfterDiscount" defaultMessage="ราคารวมหลังหักส่วนลด" />
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'subtotalAfterDiscount'))}</td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        <td colspan="3" className="tdPriceText">
                          <FormattedMessage id="QTPrintTitleTaxPre" defaultMessage="ภาษี" /> {_.get(DataQT, 'taxPercentage')}%
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'taxNum'))}</td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        <td colspan="3" className="tdPriceText">
                          <FormattedMessage id="QTPrintTitlesubAllTotal" defaultMessage="รวมทั้ั้งสิ้น" />
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'total'))}</td>
                      </tr>
                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        <td colspan="6" style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', padding: ' 6px 7px' }}>
                          <span style={{}}>
                            <FormattedMessage id="QTPrintTitlesubAllLabel" defaultMessage="ตัวอักษร" />
                          </span>
                          : {_.get(DataQT, 'total') === null ? 'ศูนย์บาทถ้วน' : ThaiNumberToText(totalNum.toFixed(2))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              <table width="100%" style={{ border: '1px solid' }}>
                <tbody style={{ height: '10px' }}>
                  <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                    <td className="tdSignatureText">
                      {/* <td style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', width: '25%' }}> */}
                      <FormattedMessage id="ARPrintTitleReciver" defaultMessage="ผู้รับสินค้า" />
                    </td>
                    <td className="tdSignatureText">
                      <FormattedMessage id="ARPrintTitleSender" defaultMessage="ผู้ส่งสินค้า" />
                    </td>
                    <td className="tdSignatureText">
                      <FormattedMessage id="ARPrintTitleReciverPrice" defaultMessage="ผู้รับเงิน" />
                    </td>
                    <td className="tdSignatureText">
                      <FormattedMessage id="ARPrintTitleCheck" defaultMessage="ผู้ตรวจสอบ" />
                    </td>
                  </tr>
                  <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                    <td className="tdSignatureLine">
                      <>
                        <div div classname="lineheight" />
                        _______________________
                      </>
                    </td>
                    <td className="tdSignatureLine">
                      <>
                        <div div classname="lineheight" />
                        _______________________
                      </>
                    </td>
                    <td className="tdSignatureLine">
                      <div div classname="lineheight" />
                      _______________________
                    </td>
                    <td className="tdSignatureLine">
                      <>
                        <div div classname="lineheight" />
                        _______________________
                      </>
                    </td>
                  </tr>
                  <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                    <td className="tdSignatureCircle">
                      (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                    </td>
                    <td className="tdSignatureCircle">
                      (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                    </td>
                    <td className="tdSignatureCircle">
                      (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                      {/* {_.get(DataQT, 'receiveBy') ? (
                            '( ' + _.get(DataQT, 'receiveBy') + ' )'
                            // memComName
                          ): (
                            <>
                             {memComName ? '( ' + memComName +  ' )':  <>(<span style={{ marginRight: '125px', marginLeft: '50px' }} />)</> }
                            </>
                          )} */}
                    </td>
                    <td className="tdSignatureCircle">
                      (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                    </td>
                  </tr>
                  <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                    <td className="tdSignatureLine">
                      <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                    </td>
                    <td className="tdSignatureLine">
                      <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                    </td>
                    <td className="tdSignatureLine">
                      <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                      {/* {_.get(DataQT, 'receiveDate') ? (
                            // _.get(DataQT, 'receiveDate')
                            moment(_.get(DataQT, 'receiveDate'))
                              .add(543, 'year')
                              .format('DD/MM/YYYY')
                          ): (
                            // _.get(signatureUser, '[0].fileUrl') ? moment()
                            // .add(543, 'year')
                            // .format('DD/MM/YYYY')  : 
                            moment()
                            .add(543, 'year')
                            .format('DD/MM/YYYY')
                          )} */}
                    </td>
                    <td className="tdSignatureLine">
                      <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                    </td>
                  </tr>
                </tbody>
              </table>{' '}
            </div>
          );
        })}
      </div>
    );
  }
}
