import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import Button_01 from '../v2/button_01';
import Button_02 from '../v2/button_02';

const { Option } = Select;

const ListItemFilter = (props) => {
  const { onChange, searchGroup, onAllowClear, onClick, refType, tempchange, onCancel } = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  console.log('tempchange', tempchange);

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '310px',padding: '5px 0px 0px 24px' }}>
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div style={{ padding: '16px 16px 8px 0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalCreateBrandTitleBrand" defaultMessage="Brand" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'brand')}
                value={_.get(searchGroup, 'brand')}
                placeholder={intl.formatMessage({
                  id: 'modalCreateBrandTitleBrandPlaceholder',
                  defaultMessage: 'Enter Brand Code or Name',
                })}
              />
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalCreateBrandTitleStatus" defaultMessage="Status" />
            </div>
            <Select
              showSearch
              onChange={(value) => onChange(value, 'status')}
              filterOption={false}
              value={_.get(searchGroup, 'status')}
              placeholder={intl.formatMessage({ id: 'modalCreateBrandTitleStatusPlaceholder', defaultMessage: 'Select Status' })}
              style={{ width: '100%' }}
            >
              {/* {_.map(refType, (item) => (
                <Option key={item.code} value={item.code}>
                  {item.refTxt}
                </Option>
              ))} */}
               <Option value={'Active'}>
                  {'Active'}
                </Option>
                <Option value={'Inactive'}>
                  {'Inactive'}
                </Option>
            </Select>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalCreateBrandTitleRemark" defaultMessage="Remark" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'remark')}
                value={_.get(searchGroup, 'remark')}
                placeholder={intl.formatMessage({ id: 'modalCreateBrandTitleRemarkPlaceholder', defaultMessage: 'Enter Remark' })}
              />
            </div>
          </div>
          {/* <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="ReferenceFilterToCreatedDate" defaultMessage="To Created Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                value={_.get(searchGroup, 'issueDateEnd') ? moment(_.get(searchGroup, 'issueDateEnd')) : ''}
                style={{ width: '100%' }}
                onChange={(e) => onChange(e, 'issueDateEnd')}
                placeholder={intl.formatMessage({ id: 'ReferenceFilterToCreatedDatePlaceHolder', defaultMessage: 'Select Created Date' })}
              />
            </div>
          </div> */}
        </div>
        <div style={{ textAlign: 'center', padding: '0px 16px 16px 0px', display: 'flex', justifyContent: 'end' }}>
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()}>
          <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
        </Button_02>

        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => onClick()}>
          <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
        </Button_01>
      </div>
      </div>

    </div>
  );
};

export default ListItemFilter;
