import React from 'react'
import { Checkbox, Row, Col } from 'antd';
import '../../css/index.css'
import { FormattedMessage } from 'react-intl';
import _ from 'lodash'
import Nodata from '../../../image/Nodata.svg'

const SearchItemType = (props) => {
  const { itemType } = props

  const handleCheckboxChange = checkedList => {
    itemType.setItemTypeSelect(checkedList);
    itemType.setItemTypeInterminate(!!checkedList.length && checkedList.length < itemType.itemTypeData.length);
    itemType.setItemTypeCheckAll(checkedList.length === itemType.itemTypeData.length);
  };

  const handleCheckAllChange = e => {
    itemType.setItemTypeSelect(e.target.checked ? _.map(itemType.itemTypeData, (item) => { return item.itemTypeId }) : []);
    itemType.setItemTypeInterminate(false);
    itemType.setItemTypeCheckAll(e.target.checked);
  };

  return (
    <div className='searchItemRequestContent'>
      <div className='searchItemRequestContentHeader'>
        <div>
          <span>{_.size(itemType.itemTypeSelect)} <FormattedMessage id="textItem" defaultMessage="item" /></span>
        </div>
        <div>
          <span><FormattedMessage id="searchItemRequestTextItemType" defaultMessage="Item Type" /></span>
        </div>
      </div>
      <div className='searchItemRequestContentCheckAll'>
        <Checkbox
            indeterminate={itemType.itemTypeInterminate}
            onChange={handleCheckAllChange}
            checked={itemType.itemTypeCheckAll}
        >
          <FormattedMessage id="searchItemRequestTextItemType" defaultMessage="Item Type" />
        </Checkbox>
      </div>
      <div style={{ maxHeight: '360px', overflowY: _.size(itemType.itemTypeData) < 9 ? 'hidden' : 'scroll' }}>
        {
          _.size(itemType.itemTypeData) > 0 ? (
            <Checkbox.Group
              style={{ width: '100%' }}
              value={itemType.itemTypeSelect}
              onChange={handleCheckboxChange}
            >
              {
                _.map(itemType.itemTypeData, (item) => (
                  <Row className='searchItemRequestContentDetail' style={{ paddingTop: '12px' }}>
                    <Col span={24}>
                      <Checkbox value={item.itemTypeId}>{item.itemTypeName}</Checkbox>
                    </Col>
                  </Row>
                ))
              }
            </Checkbox.Group>
          ) : (
            <div className='searchItemRequestContentNoDataValidate'>
              <div>
                <img src={Nodata} style={{ width: '100px' }}></img>
              </div>
              <div style={{ color: '#e4e4e4' }}>
                <FormattedMessage id="searchItemRequestTextWarehouseNoItemType" defaultMessage="No Item Type" />
              </div>
              <div style={{ color: '#e4e4e4' }}>
                <FormattedMessage id="searchItemRequestTextWarehouseValidateItem" defaultMessage="Please Select Warehouse" />
              </div>
            </div >
          )
        }
      </div >
    </div >
  )
}

export default SearchItemType
