import React, { useState } from 'react';
import { Input, Icon } from 'antd';


const AppinputSearch = (props) => {
  return (
    <Input
      placeholder={props.placeholder}
      prefix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
      style={props.style}
      value={props.value}
      onChange={props.onChange}
      className={props.className}
    />
  );
};

export default AppinputSearch;
