import React, { useEffect, useState } from 'react';
import { getCustomerLoadMore } from '../controllers/getCustomer/get-customer-new';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import _ from 'lodash';
import './css/selectCustomer.css'

const { Option } = Select;
export default ({ setCustomerListView }) => {
  const intl = useIntl();
  const [customerListMaster, setCustomerListMaster] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerPage, setCustomerPage] = useState(0);
  const [customerPageSize, setCustomerPageSize] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customerSelect, setCustomerSelect] = useState();
  const [isCustomerLoadMore, setIsCustomerLoadMore] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const pageStart = customerPage * customerPageSize + 1;
      const pageEnd = customerPage * customerPageSize + 10;

      const data = await getCustomerLoadMore(pageStart, pageEnd);
      const setCustomerListData = _.map(_.get(data, 'customers'), (o) => {
        return setDataCustomer(o);
      });
      const customers = _.uniqBy(_.concat(customerList, setCustomerListData), 'customerId');
      setCustomerList(customers);
      setCustomerListMaster(customers);
      setCustomerLoading(false);
      setIsCustomerLoadMore(_.size(setCustomerListData) > 0 ? true : false);
    }

    if (isCustomerLoadMore) {
      getData();
    }
  }, [customerPage]);

  const setDataCustomer = (customer) => {
    return {
      customerId: _.get(customer, 'customerId'),
      customerName: _.get(customer, 'customerName')
    }
  }

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!customerLoading && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight - 50)) {
      setCustomerLoading(true);
      setCustomerPage(customerPage + 1);
    }
  }

  const handleCustomerChangeSelect = (value) => {
    if (value !== customerSelect) {
      const filterCustomer = customerListMaster && customerListMaster.map((item) => {
        return {
          customerId: item.customerId,
          customerName: item.customerName
        }
      }).filter((e) => e.customerId === value);
      setCustomerList(customerListMaster);
      setCustomerSelect(value);
      setCustomerListView(filterCustomer)
    }
  }

  const handleCustomerChange = (value) => {
    if (!value) {
      setCustomerSelect();
      setCustomerListView([])
    }
  }

  const setOptionCustomer = (customer) => {
    return (<Option value={`${customer.customerId}`}>{customer.customerName}</Option>);
  }

  return (
    <Select
      className='select-list-customer-monitor'
      placeholder={intl.formatMessage({ id: `taskDistancePlaceholderSelectCustomer`, defaultMessage: 'Select Customer' })}
      defaultActiveFirstOption={false}
      showSearch
      allowClear={true}
      onPopupScroll={handleCustomerPopupScroll}
      onSelect={handleCustomerChangeSelect}
      onChange={handleCustomerChange}
      loading={customerLoading}
      filterOption={false}
    >
      {customerList && customerList.map((customer) => setOptionCustomer(customer))}
    </Select>

  )
}