import React, { useContext, useState, useEffect } from 'react';
import { Card, Row, Col, Icon, Input, Select, Dropdown, Menu, Checkbox, Button } from 'antd';
import Button01 from '../../../../../components/v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import AssetSummaryContext from '../context';
import _ from 'lodash';
import './css/actionAssetClass.css'

const { Option } = Select;

const ActionAssetClass = (props) => {
  const {
    columns,
    setShowColumn,
    setShowColumnArr,
    newDataColumns,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    listArrayColumns,
    defaultShowColumn,
    setScrollTableX,
    newColumns,
    columnNewSearch
  } = props
  const intl = useIntl();
  const { state, setState, fnc } = useContext(AssetSummaryContext);
  const { orgId, orgData, loading, fliterByColumn, fieldChange } = state;
  const { handleChangeOption } = fnc;
  const { setOrgId, setFieldChange, setFliterByColumn } = setState;

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');

  const [visible, setVisible] = useState(false);

  const ignoreColumn = [
    'index', 'assetNo'
  ];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) { // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(newListArrayColumns, function (result, value, key) {
        result[value] = true;
        return result;
      }, {});
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ)
      setTextErrorSelectColumn('');
    }
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 2) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find(
        (element) => element == columns[i].key,
      );
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }

    if (_.size(setNewArr) <= 5) {
      setScrollTableX(900)
    } else if (_.size(setNewArr) === 6) {
      setScrollTableX(1100)
    } else if (_.size(setNewArr) === 7) {
      setScrollTableX(1200)
    } else if (_.size(setNewArr) === 8) {
      setScrollTableX(1400)
    } else if (_.size(setNewArr) === 9) {
      setScrollTableX(1600)
    }else if (_.size(setNewArr) === 10) {
      setScrollTableX(1800)
    }else if (_.size(setNewArr) === 11) {
      setScrollTableX(2000)
    }else if (_.size(setNewArr) === 12) {
      setScrollTableX(2200)
    }

    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    setScrollTableX(900)
  };

  const onChangeSearch = (value) => {
    setFieldChange(value)
  };

  const handleSelect = (value) => {
    setFliterByColumn(value);
  }

  const handleOrgId = (value) => {
    setOrgId(value)
  }

  const menuColumn = () => {
    return (
      <Menu className="menu-columns">
        <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={checkedValuecolumns}
                checked={defaultShowColumn[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    )
  }


  return (
    <div className='asset-action-search-all'>
      <div>
        <Input
          className="monitor-view-input-action action-input"
          placeholder={intl.formatMessage({
            id: 'hintSearch',
            defaultMessage: 'Search',
          })}
          prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
          onChange={(e) => onChangeSearch(e.target.value)}
          autoComplete="off"
          value={fieldChange}
          defaultValue=''
        />
        <Select
          className="monitor-select-action action-select"
          placeholder={intl.formatMessage({
            id: 'hintSelect',
            defaultMessage: 'Select',
          })}
          onSelect={(value) => handleSelect(value)}
          defaultValue='all'
          value={fliterByColumn}
        >
          <Option value="all">
            <FormattedMessage id="reportOutsideReportHintSeleteAllColumns" defaultMessage="All columns" />
          </Option>
          {columnNewSearch.map((item, index) =>
            <Option key={index} value={item.key}>{item.title}</Option>
          )}
        </Select>
      </div>
      <div>
        <Select
          className="asset-select-team"
          placeholder={intl.formatMessage({
            id: 'hintSelect',
            defaultMessage: 'Select Team',
          })}
          value={orgId}
          defaultValue={[]}
          onChange={(e) => handleOrgId(e)}
          maxTagCount={1}
          mode="multiple"
          showSearch
          filterOption={(input, option) =>
            option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {orgData && orgData.map((e, index) => (
            <Option key={index} value={e.id}>{e.name} ({e.total})</Option>))}
        </Select>

        <Dropdown
          className="list-view-dropdown"
          trigger={['click']}
          placement="bottomRight"
          overlay={menuColumn}
          onVisibleChange={handleVisibleChange}
          visible={visible}
        >
          <Button01
            type="primary"
            btnsize="wd_at"
            fontSize="sm"
            onClick={handleOnVisiblecolumns}
            className='style-buttun-columns'
          >
            <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" />{" "}
            <Icon type="down" />
          </Button01>
        </Dropdown>
      </div>

    </div>
  )
}

export default ActionAssetClass;