import httpClient from '../../components/axiosClient';

const handleCreateSkillList = async (skillId, name, description) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    skillGroupId: skillId,
    name: name,
    description: description
  }
  try {
    const response = await httpClient.post(`/v3/resource/manager/company/${comCode}/skill/create`, body);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

const handleEditSkillList = async (name, description, skillId, skillGroupId) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    skillGroupId: skillGroupId,
    name: name,
    description: description
  }
  try {
    const response = await httpClient.put(`/v3/resource/manager/company/${comCode}/skill/update/${skillId}`, body);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }

}

const handleDeletedSkillKist = async (skillId) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v3/resource/manager/company/${comCode}/skill/remove/${skillId}`);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }

}

export {
  handleCreateSkillList, handleEditSkillList, handleDeletedSkillKist
}