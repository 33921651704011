import React, { useContext, useState, useRef } from 'react';
import { Table, Popover, Button, List } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { ProjectContext } from '../context';
import { PageSettings } from '../../../config/page-settings';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import '../project-list/css/index.css';
import { Pagination } from 'antd';

const StakeholderSource = (props) => {
  const { stakedata, columns, totalstake, handlePagination, handleSizeChange, paginationPage } = props;

  const showTotal = (totalstake) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };
  return (
    <div>
      <Table
        columns={columns}
        dataSource={stakedata}
        pagination={{
          total: totalstake,
          current: paginationPage,
          showTotal: showTotal,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: handlePagination,
          onShowSizeChange: handleSizeChange,
        }}
      />
      <div className="total-items-member">
        <span style={{ fontSize: '13px' }}>
          <FormattedMessage id="lblTotal" defaultMessage="Total" />
          {` ${totalstake || 0} `}
          <FormattedMessage id="lblitems" defaultMessage="items" />
        </span>
      </div>
    </div>
  );
};

export default StakeholderSource;
