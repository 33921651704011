import React, { useEffect } from 'react'
import { Form, Row, Col, Input, Select, DatePicker, Divider, Icon } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import moment from 'moment'

const { Option } = Select;
const { TextArea } = Input;

export default (props) => {
  const {
    form,
    initialCustomerLoading,
    handleCustomerPopupScroll,
    initialManagerList,
    initialManagerLoading,
    handleManagerPopupScroll,
    handleManagerChangeSearch,
    handleCustomerChangeSearch,
    setVisibleAddCustomer,
    initialCustomerList,
    newCustomer,
    setInitialCustomerList,
    priorityData,
    projectTypeData,
  } = props;
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    if (newCustomer) {
      const mapData = [
        {
          customerId: newCustomer.customerId,
          comtomerName: newCustomer.customerName,
          phone: newCustomer.phone,
          position: newCustomer.position,
        },
      ];
      const concatData = _.uniqBy(_.concat(mapData, initialCustomerList), 'customerId');
      setInitialCustomerList(concatData);
      setTimeout(() => {
        setFieldsValue({
          ['customer']: _.get(newCustomer, 'customerId'),
        });
      }, 1000);
    }
  }, [_.get(newCustomer, 'customerId')]);

  const validatorStartDate = (rule, value, callback) => {
    const startDate = value;
    const dueDate = getFieldValue(`planFinish`);
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm').toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm').toString();
    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  const validatorDueDate = (rule, value, callback) => {
    const startDate = getFieldValue(`planStart`);
    const dueDate = value;
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm').toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm').toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };


  return (
    <div>
      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalProjectTextProjectNo', defaultMessage: 'Project No.' })} req={false} />
              }
            >
              {getFieldDecorator('projectNo', {
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'modalProjectValidateProjectNo', defaultMessage: 'Please enter project no' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalProjectPlaceholderProjectNo', defaultMessage: 'Project No.' })}
                  autoComplete="off"
                  disabled={true}
                ></Input>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalProjectTextProjectName', defaultMessage: 'Project Name' })} req={true} />
              }
            >
              {getFieldDecorator('projectName', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalProjectValidateProjectName', defaultMessage: 'Please enter project name' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalProjectPlaceholderProjectName', defaultMessage: 'Enter Project Name' })}
                  autoComplete="off"
                ></Input>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalProjectTextProjectType', defaultMessage: 'Project Type' })} req={true} />
              }
            >
              {getFieldDecorator('projectType', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalProjectValidateProjectType', defaultMessage: 'Please select project type' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalProjectPlaceholderProjectType', defaultMessage: 'Select Project Type' })}
                  showSearch
                  filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {projectTypeData &&
                    projectTypeData.map((item) => (
                      <Option key={item.projectTypeId} value={item.projectTypeId}>
                        {item.projectTypeName}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalProjectTextPriority', defaultMessage: 'Priority' })} req={true} />}
            >
              {getFieldDecorator('priority', {
                initialValue: _.get(priorityData[2], 'projectPriorityId'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalProjectValidatePriority', defaultMessage: 'Please select priority' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalProjectPlaceholderPriority', defaultMessage: 'Select Priority' })}
                  showSearch
                  filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {priorityData &&
                    priorityData.map((item) => (
                      <Option value={item.projectPriorityId} key={item.projectPriorityId}>
                        <img src={item.icon} style={{ width: '7px', height: '7px' }}></img> {item.projectPriorityName}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalProjectTextCustomer', defaultMessage: 'Customer' })} req={false} />}
            >
              {getFieldDecorator('customer', {
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'modalProjectValidateCustomer', defaultMessage: 'Please select customer' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderCustomer', defaultMessage: 'Select Customer' })}
                  defaultActiveFirstOption={true}
                  filterOption={false}
                  showSearch
                  loading={initialCustomerLoading}
                  onPopupScroll={handleCustomerPopupScroll}
                  onSearch={handleCustomerChangeSearch}
                  // onChange={handleCustomerChange}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setVisibleAddCustomer(true);
                        }}
                      >
                        <Icon type="plus" className="order-icon-plus" />
                        <span className="order-add-customer">
                          <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                          <FormattedMessage id="modalOrderTextCustomer" defaultMessage="Customer" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {initialCustomerList && initialCustomerList.map((item) => <Option value={item.customerId}>{item.comtomerName}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={
                <LabeRequire
                  text={intl.formatMessage({ id: 'modalProjectTextProjectManager', defaultMessage: 'Project Manager' })}
                  req={true}
                />
              }
            >
              {getFieldDecorator(
                'projectManager', {
                initialValue: localStorage.getItem('memComId'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'modalProjectValidateProjectManager',
                      defaultMessage: 'Please select project manager',
                    }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({
                    id: 'modalProjectPlaceholderProjectManager',
                    defaultMessage: 'Select Project Manager',
                  })}
                  defaultActiveFirstOption={true}
                  filterOption={false}
                  showSearch
                  loading={initialManagerLoading}
                  onPopupScroll={handleManagerPopupScroll}
                  onSearch={handleManagerChangeSearch}
                >
                  {initialManagerList &&
                    initialManagerList.map((item) => (
                      <Option key={item.mem_com_id} value={item.mem_com_id}>
                        {item.fullname}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalProjectTextPlanStart', defaultMessage: 'Plan Start' })} req={true} />
              }
            >
              {getFieldDecorator('planStart', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalProjectValidatePlanStart', defaultMessage: 'Please select plan start' }),
                  },
                  {
                    validator: validatorStartDate,
                  },
                ],
              })(
                <DatePicker
                  placeholder={intl.formatMessage({ id: 'modalProjectPlaceholderPlanStart', defaultMessage: 'Plan Start' })}
                  format="ddd, MMM DD, YYYY"
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalProjectTextPlanFinish', defaultMessage: 'Plan Finish' })} req={true} />
              }
            >
              {getFieldDecorator('planFinish', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalProjectValidatePlanFinish', defaultMessage: 'Please select plan finish' }),
                  },
                  {
                    validator: validatorDueDate,
                  },
                ],
              })(
                <DatePicker
                  placeholder={intl.formatMessage({ id: 'modalProjectPlaceholderPlanFinish', defaultMessage: 'Select Plan Finish' })}
                  format="ddd, MMM DD, YYYY"
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="modal-project"
              label={
                <LabeRequire
                  text={intl.formatMessage({ id: 'modalProjectTextReferenceNo', defaultMessage: 'Reference No.' })}
                  req={false}
                />
              }
            >
              {getFieldDecorator('referenceNo', {
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'modalProjectValidateReferenceNo', defaultMessage: 'Please enter reference no.' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalProjectPlaceholderReferenceNo', defaultMessage: 'Enter Reference No.' })}
                ></Input>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider type="horizontal" />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              className="modal-project"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalProjectTextRemark', defaultMessage: 'Remark' })} req={false} />}
            >
              {getFieldDecorator('remark', {
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'modalProjectValidateRemark', defaultMessage: 'Please enter remark' }),
                  },
                ],
              })(<TextArea rows={5} autoSize={{ minRows: 5, maxRows: 5 }} maxLength={250} style={{ borderRadius: '10px' }} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
