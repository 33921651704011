import React, { useState, useEffect, memo, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Transfer, Table, notification, Icon } from 'antd';
import Modal from '../../../components/v2/modal';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Input from '../../../components/v2/input';
import httpClient from '../../../components/axiosClient';
import difference from 'lodash/difference';
import { PageSettings } from '../../../config/page-settings';
import { fncGetUsers } from '../../../controllers/user/users';
import _ from 'lodash';
import cssStyle from './css/modalMember.css';



const filterOption = (inputValue, items) => {
  if (!inputValue) {
    return items;
  }
  const lowercasedFilter = inputValue.toLowerCase();
  const filteredData = items.filter(item => {
    return Object.keys(item).some(key =>
      typeof item[key] === "string" && item[key].toLowerCase().includes(lowercasedFilter)
    );
  })
  return filteredData;
};

// Customize Table Transfer
const TableTransfer = memo(({ leftColumns, rightColumns, intl, ...restProps }) => (

  <Transfer className="transfer-member" {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      let isLeft = direction === 'left';
      let defalutData = [];
      defalutData = filterOption(
        restProps.search[isLeft ? 0 : 1],
        filteredItems,
      );

      return (
        <div>
          <Input
            className="input-search-member"
            value={restProps.search[isLeft ? 0 : 1]}
            onChange={(e) => {
              restProps.handleSearch(direction, e.target.value);
            }}
            placeholder={intl.formatMessage({ id: 'orgTabMemberModalHintSearch', defaultMessage: 'Search' })}
            prefix={<Icon className="input-search-member-prefix" type="search" />}
            autoComplete="off"
          />
          <Table
            className="transfer-table-member"
            rowKey={record => record.key}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={defalutData}
            size="small"
            pagination={false}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
            scroll={{ y: 300 }}
          />
        </div>
      );
    }}
  </Transfer>
));

export default memo((props) => {

  const mApp = useContext(PageSettings);
  const intl = useIntl();
  const { setPaginationSize, setPaginationPage } = props;
  const members = props.dataMembers;


  let orgId = props.orgId;
  let created_by = localStorage.getItem('memComId');

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selected, setSelected] = useState([]);
  const [inputSearch, setInputSearch] = useState(['', '']);

  const leftTableColumns = [
    {
      dataIndex: 'fullname',
      title: intl.formatMessage({ id: 'orgTabMemberColumnContactName', defaultMessage: 'Contact Name' }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'orgTabMemberColumnEmail', defaultMessage: 'Email' }),
    },
  ];

  const rightTableColumns = [
    {
      dataIndex: 'fullname',
      title: intl.formatMessage({ id: 'orgTabMemberColumnContactName', defaultMessage: 'Contact Name' }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'orgTabMemberColumnEmail', defaultMessage: 'Email' }),
    },
  ];

  useEffect(() => {
    getMock();
    getMember(members);
  }, [props]);

  const showModal = () => {
    setVisible(true);
  };


  const handleSave = async () => {
    const body = {
      user_id: targetKeys,
      created_by: created_by,
      org_id: orgId,
    };


    try {
      const response = await httpClient.post('/v2/manageteammember', body);

      if (response.status === 200) {
        if (response.data.data.length > 0) {
          infoNotification(response.data.data);
          // props.setrefreshDataMemBer(!props.refreshDataMemBer);
          props.setRefreshTable(curren => !curren);

          setPaginationSize(10);
          setPaginationPage(1);
          setVisible(false);
          setSelected([]);
          setInputSearch(['', '']);
        } else {
          successNotification(response.data.message);
          props.setRefreshTable(!props.refreshTable);

          setPaginationSize(10);
          setPaginationPage(1);
          setVisible(false);
          setSelected([]);
          setInputSearch(['', '']);
        }
      }
    } catch (error) {
      setVisible(false);
      setSelected([]);
      setInputSearch(['', '']);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setLoading(false);
    setInputSearch(['', '']);
    setSelected([]);
    getMember(props.dataMembers);
    getMock();
  };

  const getMock = async () => {
    const mockData = [];
    const users = await fncGetUsers(created_by, mApp.comId, true);
    for (let i = 0; i < users.length; i++) {
      const data = {
        key: users[i].mem_com_id.toString(),
        fullname: users[i].fullname,
        email: users[i].email,
      };
      mockData.push(data);
    }
    setMockData(mockData);
  };

  const getMember = (dataMembers) => {
    const targetKeys = [];
    if (dataMembers.members) {
      for (let i = 0; i < dataMembers.members.length; i++) {
        targetKeys.push(dataMembers.members[i].mem_com_id.toString());
      }
    }
    setTargetKeys(targetKeys);
  };


  const handleChange = (targetKeys, direction, moveKeys) => {
    setTargetKeys(targetKeys);
  };

  const handleSearch = (dir, value) => {
    let option = [...inputSearch];
    if (dir === 'left') {
      option[0] = value;
    } else {
      option[1] = value;
    }
    setInputSearch(option);
  };

  const selectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelected([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const infoNotification = (data) => {
    data.map((item, index) => {
      return (
        <div key={index}>
          {
            notification[item.type]({
              message: item.message,
              description: item.data ? item.data.map((item) => item.name) : null,
            })
          }
        </div>
      )
    })
  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          {checkPermissionAction('P3PG1C5', 'P3PG1C5A2') ? (
            <Button01 type="primary" fontsize="sm" btnsize="wd_at" onClick={() => showModal()}>
              <FormattedMessage id="orgTabMemberBtnAddMember" defaultMessage="Add Member" />
            </Button01>
          ) : null
          }
          <Modal
            className="member"
            width={'85%'}
            visible={visible}
            title={<FormattedMessage id="orgTabMemberModalTitleMember" defaultMessage="Member" />}
            onOk={handleSave}
            onCancel={handleCancel}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}}
                key="back"
                fontsize="sm"
                btnsize="wd_df"
                onClick={handleCancel}
              >
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01
                type="primary"
                key="submit"
                fontsize="sm"
                btnsize="wd_df"
                loading={loading}
                onClick={handleSave}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <TableTransfer
              titles={[
                intl.formatMessage({ id: 'orgTabMemberModalTxtUsers', defaultMessage: 'Users' }),
                intl.formatMessage({ id: 'orgTabMemberModalTxtMember', defaultMessage: 'Member' })
              ]}
              dataSource={mockData}
              targetKeys={targetKeys}
              onChange={handleChange}
              selectedKeys={selected}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
              onSelectChange={selectChange}
              search={inputSearch}
              onSearch={handleSearch}
              handleSearch={handleSearch}
              intl={intl}
            />
          </Modal>
        </div>
      )}
    </PageSettings.Consumer>
  );
},
  (prev, next) => {
    return (prev.orgId === next.orgId && prev.dataMembers === next.dataMembers)
  }
);
