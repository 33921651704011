import React, { useState, useEffect } from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import UserAvatar from './userAvatar';
import SendChangPassword from './sendChangPassword';
import cssStyle from './css/index.css';
import { Icon, Col, Modal, Row } from 'antd';
import { Link } from 'react-router-dom';
import HttpClient from '../../../../components//httpClient';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import { useAppContext } from '../../../../includes/indexProvider';


let client = HttpClient();

const ChangPassword = (props) => {
  const intl = useIntl();
  const app = useAppContext();
  const forgotPassword = app.state.show.forgotPassword;
  const labelShow = props.labelShow;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [value, setValue] = useState();

  const profileImage = localStorage.getItem('userAvatar');
  const adminName = localStorage.getItem('adminName');
  const memComId = localStorage.getItem('memComId');

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const res = await client.get(`/v2/users/1/${memComId}`);
    setUser(res.data[0]);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      const data = {
        email: value == undefined ? user.email : value,
      };
      const result = await client.post('/v2/createcode/', data);
      if (result.status == 200) {
        setTimeout(() => {
          setLoading(false);
          setVisible(false);
          window.location.href = '/validatecode';
          signOut();
        }, 500);
      } else console.error();
    } catch (error) {}
  };

  const signOut = () => {
    const allLocal = localStorage;
    allLocal.clear();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const valueOnChange = (value) => {
    setValue(value);
  };

    return (
        <div>
          {forgotPassword === false ? null :
            <Link className="login-form-forgot" style={{float: 'left'}} onClick={showModal}>{labelShow.forgotPassword ? labelShow.forgotPassword : "Forgot password ?"}</Link>
          }
            <Modal 
                style={{paddingTop: '10px'}}
                title={<FormattedMessage id="userMgntUsersTitleChangePassword" defaultMessage="Change Password" />}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" onClick={handleCancel}>
                        {labelShow.cancel ? labelShow.cancel : "Cancel"}
                    </Button02>,
                    <Button01 key="submit" type="primary" loading={loading} onClick={handleOk}>
                        {labelShow.btnContinue ? labelShow.btnContinue : "Continue"}
                    </Button01>,
                ]}
            >
                <Row> 
                    <Col span={15}>
                        <SendChangPassword user={user} labelShow={labelShow} onChange={valueOnChange}  />
                    </Col>
                    <Col span={9}>
                        <UserAvatar user={user} />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default ChangPassword;
