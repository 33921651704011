import React, { useState, useEffect } from 'react'
import { Modal, Form, Select, Input } from 'antd'
import Button01 from '../../../../components/v2/button_01'
import Button02 from '../../../../components/v2/button_02'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import LabeRequireForm from '../../../../components/label-required-form'
import { cancelPaymentExpense } from '../../../../controllers/expense/expense'
import { successNotification, errorNotification } from '../../../../components/v2/notification'

const { Option } = Select
const { TextArea } = Input

const ModalExpenseStatusCancel = (props) => {
  const { visible, setVisible, form, data, expenseId, reasonPaymentList, setTrigger, setVisibleMain, setTriggerMain } = props
  const { getFieldDecorator, validateFields, resetFields } = form
  const intl = useIntl()
  const [disbleButton, setDisbleButton] = useState(false)
  const type = _.get(data, 'medthod.code')
  const title = type === 'cash' ? intl.formatMessage({ id: `expenseBtnCash`, defaultMessage: 'Cash' }) :
    type === 'banktransfer' ? intl.formatMessage({ id: `expenseBtnBankTranfer`, defaultMessage: 'Bank Tranfer' }) :
      type === 'creditcard' ? intl.formatMessage({ id: `expenseBtnCreditCard`, defaultMessage: 'Credit Card' }) :
        type === 'cheque' ? intl.formatMessage({ id: `expenseBtnCheque`, defaultMessage: 'Cheque' }) :
          type === 'claim' ? intl.formatMessage({ id: `expenseBtnClaim`, defaultMessage: 'Claim' }) : ''

  console.log("ModalExpenseStatusCancel", data)

  const handleConfirmStatus = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setDisbleButton(true)
      const filterReason = _.filter(reasonPaymentList, (item) => { return item.code === _.get(values, 'reason') })
      const body = {
        "expenseId": expenseId,
        "paymentExpenseId": _.get(data, '_id'), //_id
        "reason": {
          ..._.get(filterReason, '[0]'),
          "remark": values.remark || ""
        }
      }
      const response = await cancelPaymentExpense(body)
      if (response.status === 200) {
        successNotification(_.get(response, 'data.status.message'))
        setTrigger(cur => !cur)
        setTriggerMain(cur => !cur)
        setVisible(false)
        setVisibleMain(false)
        resetFields()
      } else {
        errorNotification(_.get(response, 'data.status.message'))
      }
      setDisbleButton(false)
    })

  }

  const handleCancel = () => {
    setVisible(false)
    resetFields();
  }

  return (
    <Modal
      title={`${intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}・${title}`}
      visible={visible}
      width={370}
      centered={true}
      onCancel={handleCancel}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel} disabled={!disbleButton > 0 ? false : true}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02 >,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleConfirmStatus} style={{ margin: 'unset' }} disabled={!disbleButton > 0 ? false : true}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={
            <LabeRequireForm text="Reason" req={true} />
          }
        >
          {getFieldDecorator('reason', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'modalRequestStatusFormReasonValidate', defaultMessage: 'Please Select Reason' }),
              },
            ],
          })(
            <Select
              placeholder={intl.formatMessage({ id: 'modalRequestStatusFormReasonPlaceholder', defaultMessage: 'Select Reason' })}
            >
              {
                _.map(reasonPaymentList, (item) => <Option key={item.code}>{item.txt}</Option>)
              }
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={
            <LabeRequireForm text="Remark" req={false} />
          }
        >
          {getFieldDecorator('remark', {
          })(
            <TextArea
              autosize={{ minRows: 3, maxRows: 3 }}
              maxLength={250}
            />
          )}
        </Form.Item>
      </Form>
    </Modal >
  )
}
const ModalExpenseCancelForm = Form.create({
  name: 'global_state',
})(ModalExpenseStatusCancel);

export default ModalExpenseCancelForm