import React, { useState, useEffect } from 'react';
import { AutoComplete, Form } from 'antd';
import { useIntl } from 'react-intl';
import { getCustomerAddressBook } from '../../controllers/task/task-distance';
import _ from 'lodash';
import LabeRequireForm from '../label-required-form';

const { Option } = AutoComplete;

const LoadmoreDestination = (props) => {
  const intl = useIntl();
  const { form, data, setData, required, companyId, customerId, disabled, initialId, initialName, visible, staticId } = props;
  const getFieldDecorator = form ? form.getFieldDecorator : null;
  const [destinationData, setDestinationData] = useState({});
  const [destinationDataMaster, setDestinationDataMaster] = useState({});
  const [destinationlist, setDestinationlist] = useState([]);
  const [destinationListMaster, setDestinationListMaster] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [firstStep, setFirstStep] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkList = async () => {
      if (customerId && companyId && initialId && visible && customerId === staticId) {
        setSearch(initialName);
        setFirstStep(true);
        setLoading(true);
      } else {
        setSearch('');
        setFirstStep(false);
        setDestinationData({});
        setDestinationDataMaster({});
        setDestinationlist([]);
        setDestinationListMaster([]);
        setPage(1);
        setLoading(true);
      }
    };

    if (visible) {
      checkList();
    }
  }, [initialId, visible, customerId, companyId]);

  useEffect(() => {
    if (companyId && customerId && firstStep && loading) {
      getDestinationData();
    }
  }, [page, companyId, customerId, firstStep, loading]);

  console.log('companyId', companyId, customerId, firstStep, loading, initialId, visible);

  const getDestinationData = async () => {
    const body = {
      companyAddressId: companyId,
      customerId: customerId,
      searchCustomerAddress: search,
      pageNumber: page,
      rowPerPage: 20,
    };
    try {
      const response = await getCustomerAddressBook(body);
      const mergeList = [...destinationlist, ..._.get(response, 'destinationList', [])];
      const mergeListMaster = [...destinationListMaster, ..._.get(response, 'destinationList', [])];
      setDestinationData(response);
      setDestinationDataMaster(response);
      setDestinationlist(_.uniqBy(mergeList, 'customerAddressId'));
      setDestinationListMaster(_.uniqBy(mergeListMaster, 'customerAddressId'));
      setLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSelect = (value, option) => {
    if (form) {
      form.setFieldsValue({ destination: value });
      setData(_.get(option, 'props.data'));
    } else {
      setData((prev) => ({ ...prev, destination: value }));
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (_.get(destinationData, 'allDestinations') !== _.size(destinationlist)) setPage(page + 1);
    }
  };

  const handleSearch = _.debounce(async (value) => {
    setSearch(value);
    if (value && value !== '') {
      const body = {
        companyAddressId: companyId,
        customerId: customerId,
        searchCustomerAddress: value,
        pageNumber: 1,
        rowPerPage: 20,
      };
      const response = await getCustomerAddressBook(body);
      setDestinationData(response);
      setDestinationlist(_.get(response, 'destinations', []));
    } else {
      setDestinationData(destinationDataMaster);
      setDestinationlist(destinationListMaster);
    }
  }, 500);

  return (
    <Form.Item
      onClick={() => setFirstStep(true)}
      label={<LabeRequireForm text={intl.formatMessage({ id: 'destinationHeader', defaultMessage: 'Destination' })} req={required} />}
    >
      {form ? (
        getFieldDecorator('destination', {
          initialValue: initialId,
          rules: [
            {
              required: required,
              message: intl.formatMessage({
                id: 'destinationValidate',
                defaultMessage: 'Please Select Destination',
              }),
            },
          ],
        })(
          <AutoComplete
            onSelect={handleSelect}
            onPopupScroll={handleScroll}
            onSearch={handleSearch}
            placeholder={intl.formatMessage({ id: 'destinationPlaceholder', defaultMessage: 'Select Destination' })}
            disabled={disabled}
          >
            {search && !_.includes(search, 'ไม่กำหนด') ? null : (
              <Option key="notSpecified" value="notSpecified" data={{ id: 'notSpecified' }}>
                ไม่กำหนด
              </Option>
            )}
            {_.map(destinationlist, (item) => (
              <Option key={_.get(item, 'customerAddressId')} value={_.get(item, 'customerAddressId')} data={item}>
                {_.get(item, 'addressName')}
              </Option>
            ))}
          </AutoComplete>
        )
      ) : (
        <AutoComplete
          onSelect={handleSelect}
          onPopupScroll={handleScroll}
          onSearch={handleSearch}
          placeholder={intl.formatMessage({ id: 'destinationPlaceholder', defaultMessage: 'Select Destination' })}
          value={_.get(data, 'destination')}
          disabled={disabled}
        >
          {search && !_.includes(search, 'ไม่กำหนด') ? null : (
            <Option key="notSpecified" value="notSpecified" data={{ id: 'notSpecified' }}>
              ไม่กำหนด
            </Option>
          )}
          {_.map(destinationlist, (item) => (
            <Option key={_.get(item, 'customerAddressId')} value={_.get(item, 'customerAddressId')} data={item}>
              {_.get(item, 'addressName')}
            </Option>
          ))}
        </AutoComplete>
      )}
    </Form.Item>
  );
};

LoadmoreDestination.defaultProps = {
  required: true,
  disabled: false,
};

export default LoadmoreDestination;
