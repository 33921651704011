import httpClient from '../../components/axiosClient';

const getCustomeField = async (taskTypeId) => {
  const comCode = localStorage.getItem('comCode');
  const taskType ='task_type'
  try {
    const response = await httpClient.get(`/v3/custom-field/manager/company/${comCode}/custom-fields/entity/${taskType}/entity-id/${taskTypeId}`);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

export default getCustomeField;