import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Modal, Badge } from 'antd';
import Button01 from '../../../../components/v2/button_01';

export default (props) => {
  const { visible, onCancel, txtWarningSuffix, viewWarningDetail } = props;

  return (
    <Modal
      className="shift-modal-schedule"
      title={
        <div>
          Warning <Badge className="shift-planning-title-dot" color="#1D3557" /> {txtWarningSuffix}
        </div>
      }
      centered={true}
      visible={visible}
      onOk={onCancel}
      onCancel={onCancel}
      bodyStyle={viewWarningDetail && viewWarningDetail.length > 7 ? { height: '450px', overflow: 'auto' } : null}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" size={'small'} onClick={onCancel}>
          <FormattedMessage id="btnOK" defaultMessage="OK" />
        </Button01>,
      ]}
    >
      {viewWarningDetail.map((item, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <p style={{ fontSize: '12px', color: '#1D3557' }}>{moment(item.workDate, 'YYYY-MM-DD').format('ddd, MMM DD, YYYY')}</p>
          {item.warning.map((e, i) => (
            <p key={i} style={{ fontSize: '12px', color: '#1D3557' }}>
              - {e}
            </p>
          ))}
        </div>
      ))}
    </Modal>
  );
};
