import React, { useCallback, useContext, useState } from 'react';
import { Card, Form, Row, Checkbox, Col } from 'antd';
import styles from './css/holiday.css'
import styled from 'styled-components';
import Button1 from '../../../components/v2/button_01';
import httpClient from '../../../components/axiosClient';
import { ShiftSettings } from '../config/shift-type-settings';
import { PageSettings } from '../../../config/page-settings';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';


export const CheckBoxForm = (props) => {
  const intl = useIntl();
  const { state, fnc } = useContext(ShiftSettings);

  const { workDay, form } = props;
  const { getFieldDecorator, validateFields } = form;

  const comId = localStorage.getItem('comId');

  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };


  const checkedValues = (Checked) => {
  };

  const handleWorkDaySave = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (err) {
        return
      }

      return preparedBody(values, state.workDay)
    })
  };

  const preparedBody = async (values, workDay) => {
    const mapDay = values.work_Day.map((item) => {
      let found = workDay.findIndex((e) => e.id.indexOf(item) !== -1 ? true : false);

      let myObj = new Object();
      if (found > -1) {
        myObj[workDay[found].id] = true;
      }
      else {
        myObj[workDay[found].id] = false;
      }
      return myObj;
    });

    const mon = mapDay.find(item => item.mon) === undefined ? false : true;
    const tue = mapDay.find(item => item.tue) === undefined ? false : true;
    const wed = mapDay.find(item => item.wed) === undefined ? false : true;
    const thu = mapDay.find(item => item.thu) === undefined ? false : true;
    const fri = mapDay.find(item => item.fri) === undefined ? false : true;
    const sat = mapDay.find(item => item.sat) === undefined ? false : true;
    const sun = mapDay.find(item => item.sun) === undefined ? false : true;


    const body = {
      mon: mon,
      tue: tue,
      wed: wed,
      thu: thu,
      fri: fri,
      sat: sat,
      sun: sun,
    }

    try {
      const results = await httpClient.post(`/resource/manager/company/${comId}/workday`, body);
      fnc.successNotification(results.data.message);
    } catch {
      fnc.failedNotification();
      console.error('........................');
    }
  }
  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Form onSubmit={handleWorkDaySave}>
            <Card
              className='letter-space border-card-bottom'
              title={<FormattedMessage id="settingHolidaysTitle" defaultMessage="Holidays" />}
              extra={
                <Form.Item>
                  {checkPermissionAction('P6S4PG1C1', 'P6S4PG1C1A2') ? 
                  <Button1 className='button-font-size' type="primary" btnsize="wd_df" htmlType="submit"  >
                    <FormattedMessage id="btnSave" defaultMessage="Save" />
                  </Button1> : null}
                </Form.Item>
              }
            >
              <Form.Item className='row-space holidays-form-item' label={<LabeRequire text={<FormattedMessage id="settingHolidaysTxtWorkingDay" defaultMessage="Working Day" />} req={true} />}>
                {getFieldDecorator('work_Day', {
                  rules: [
                    { required: true, message: intl.formatMessage({ id: 'settingHolidaysValidateWorkingDay', defaultMessage: 'Please select working day.' }) },
                  ],
                })(
                  <Checkbox.Group onChange={checkedValues} style={{ width: '100%' }}>
                    <Row>
                      {state.workDay && state.workDay.map((workDay, key) => (
                        <Col key={workDay.id} span={4} style={{ width: 'auto' }}>
                          <Checkbox
                            className="workDay-checkbox checkbox-border"
                            value={workDay.id}
                            role="checkbox"
                            data-testid={`checkbox-${workDay.id}`}
                          >
                            {workDay.day}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>
            </Card>
          </Form>
        </div>
      )}
    </PageSettings.Consumer>

  )
}

const WorkDayForm = React.memo(Form.create({
  name: 'work_day_form',
  mapPropsToFields(props) {
    const { workDays } = props;
    const setWorkDay = [];
    _.forEach(workDays, (value, key) => {
      if (value === true) {
        setWorkDay.push(key);
      }
    });
    return {
      work_Day: Form.createFormField({
        value: setWorkDay,
      })
    }
  }
})(CheckBoxForm));

export default WorkDayForm;