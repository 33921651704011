import React, { useState, useCallback } from 'react'
import { Card, Table, Popover, Row, Icon, Modal } from 'antd'
import Button03 from '../../../components/v2/button_03'
import Button01 from '../../../components/v2/button_01'
import CreateModal from '../../../components/modal-create-adress-map-v2/index'
import EditModal from '../../../components/modal-edit-adress-map-v2/index'
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import {
  successNotification,
  errorNotification,
  warningNotification,
} from '../../../components/v2/notification';
import httpClient from '../../../components/axiosClient';
import { PageSettings } from '../../../config/page-settings';
import moment from 'moment'


export default ({ addressBookData, informationData, setTriggerAddToLocation, triggerAddToLocation }) => {
  const intl = useIntl();
  const { confirm } = Modal;
  const comCode = localStorage.getItem('comCode');

  const [valueAddressEdit, setValueAddressEdit] = useState("");

  const [valueDefaultEdit, setValueDefaultEdit] = useState()

  const [latlngToEdit, setLatlngToEdit] = useState({});

  const [visibleModalCreate, setvisibleModalCreate] = useState(false);
  const [visibleModalEdit, setvisibleModalEdit] = useState(false)

  const [formRef, setFormRef] = useState(null);
  const [formRefEditLocation, setFormRefEditLocation] = useState(null);


  const customerName = _.get(informationData, 'customerName')
  const customerId = _.get(informationData, 'customerId')





  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      // fixed: 'left',
      width: '5%',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'customerAddressBookTableColumnAddressName', defaultMessage: 'Address Name' }),
      dataIndex: 'addressName',
      key: 'addressName',
      width: '10%',
      // fixed: 'left'
    },
    {
      title: intl.formatMessage({ id: 'ModalLocationLocationAddress', defaultMessage: 'Address' }),
      dataIndex: 'address',
      key: 'address',
      width: '30%',
    },
    // {
    //   title: intl.formatMessage({ id: 'ModalLocationLocationMapLocation', defaultMessage: 'Map Location' }),
    //   dataIndex: 'fullAddress',
    //   key: 'fullAddress',
    //   width: '30%',
    // },
    {
      title: intl.formatMessage({ id: 'ModalLocationLocationServiceTime', defaultMessage: 'Service Time' }),
      dataIndex: 'serviceTime',
      key: 'serviceTime',
      width: '20%',
      render: (text, record, index) => {
        const mapRecord = record.serviceTime.map((item, index) => {
          return `${moment(item.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(item.endTime, 'HH:mm:ss').format('HH:mm')}  ${index !== record.serviceTime.length - 1 ? ', ' : ''}`
        })
        return (
          <span>{mapRecord}</span>
        )
      }
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => (
        <PageSettings.Consumer>
          {({ checkPermissionAction }) =>
            <Popover placement="leftTop" key={index} zIndex={999}
              content={
                <>
                  <Row>
                    {
                      checkPermissionAction('P14PG2C3', 'P14PG2C3A3') ? (
                        <Button01
                          btnsize="wd_df"
                          type="link" ghost
                          onClick={() => {
                            setvisibleModalEdit(true)
                            setValueDefaultEdit(record)
                          }}
                        >
                          <p><FormattedMessage id="btnEdit" defaultMessage="Edit" /></p>
                        </Button01>
                      ) : null
                    }
                  </Row>
                  <Row>
                    {
                      checkPermissionAction('P14PG2C3', 'P14PG2C3A4') ? (
                        <Button01
                          btnsize="wd_df"
                          type="link" ghost
                          onClick={() => handleDelete(record.customerAddressBookId)}
                        >
                          <p><FormattedMessage id="btnDelete" defaultMessage="Delete" /></p>
                        </Button01>) : null
                    }

                  </Row>

                </>
              }

            >
              <Icon type="more" />
            </Popover >}
        </PageSettings.Consumer>
      )
    },
  ];


  const handleEditCancel = () => {
    formRefEditLocation.resetFields();
    setvisibleModalEdit(false);
    setValueDefaultEdit();
    // setValueAddressEdit("");
    setLatlngToEdit({})
  }


  const saveFormRefEditLocation = useCallback(node => {
    if (node !== null) {
      setFormRefEditLocation(node);
    }
  }, []);



  const handleEdit = (valueDefaultEdit) => {
    formRefEditLocation.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      else {



        const customerAddressBookId = _.get(valueDefaultEdit, 'customerAddressBookId')

        const body = {
          customerId: customerId,
          addressName: values.locationName,
          customerName: customerName,
          contactName: undefined,
          fullAddress: values.location,
          address: values.location,
          subDistrict: undefined,
          district: undefined,
          province: undefined,
          postCode: undefined,
          lat: latlngToEdit.lat,
          lng: latlngToEdit.lng,
          description: values.description,
        }
        try {
          const response = await httpClient.put(
            `/v3/business-partner/manager/company/${comCode}/customer-address-books/${customerAddressBookId}`,
            body,
          );
          if (response.status == 200) {
            formRefEditLocation.resetFields();
            setvisibleModalEdit(false);
            setLatlngToEdit({});
            setTriggerAddToLocation(!triggerAddToLocation);
            successNotification(response.data.status.message);
          }
          else {
            formRefEditLocation.resetFields();
            setvisibleModalEdit(false);
            setLatlngToEdit({});

          }

        } catch (error) {
          formRefEditLocation.resetFields();
          setvisibleModalEdit(false);
          setLatlngToEdit({});
          errorNotification(error.response.data.status.message);
        }



      }

    });
  };

  const handleDelete = (customerAddressBookId) => {
    confirm({
      className: "customer-modal-confirm",
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerAddressBookDeleteConfirmText', defaultMessage: 'Are you sure to delete address book ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      async onOk() {
        try {

          const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/customer-address-books/${customerAddressBookId}`);

          if (response.status == 200) {
            setTriggerAddToLocation(!triggerAddToLocation)
            // successNotification(response.data.status.message);
            if (response.data.status.message !== '') {
              successNotification(response.data.status.message);
            } else {
              successNotification('Delete Success');
            }

          } else {
            errorNotification(response.data.status.message);
          }

        } catch (error) {

        }
      },
      onCancel() {

      }
    })
  }


  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        <div>
          <Card
            title={intl.formatMessage({ id: 'customerAddressBookCardHeader', defaultMessage: 'Address Book' })}
            extra={checkPermissionAction('P14PG2C3', 'P14PG2C3A2') ?
              (<Button03 type='link' onClick={() => setvisibleModalCreate(true)}>
                + <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button03>) : null
            }
          >
            <Table
              dataSource={addressBookData}
              columns={columns}
              pagination={false}
            />
          </Card>

          <CreateModal
            visible={visibleModalCreate}
            setVisible={setvisibleModalCreate}
            customerId={customerId}
            customerName={customerName}
            setTrigger={setTriggerAddToLocation}
          />

          <EditModal
            visible={visibleModalEdit}
            setVisible={setvisibleModalEdit}
            setTrigger={setTriggerAddToLocation}
            valueDefault={valueDefaultEdit}
            setValueDefault={setValueDefaultEdit}
            customerId={customerId}
          />
        </div>}
    </PageSettings.Consumer>
  )
}
