import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Modal, Tabs, Form, Tag } from 'antd';
import { ModalTabs, TitleModal } from '../initial/modal';
import ModalTabsDetail from './details';
import ModalTabsHistory from './history';
import { FooterModal, fncApi } from '../initial/modal';
import ModalSelectItemPayDeduct from '../../../components/modal-select-item-pay-deduct';
import { getItemBasePay } from '../../../controllers/base-pay/basepay';
import moment from 'moment';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import ModalStatus from '../../resource-allowance/components/modal/modalStatus';
import { useIntl } from 'react-intl';

const { TabPane } = Tabs;

const ModalFormMemberExpense = (props) => {
  const {
    defaultModal,
    setDefaultModal,
    form,
    handleOpenAllowance,
    dataFilter,
    setTrigger,
    rejectList,
    handleChangeStatus,
    tabkeys,
    setTabkeys,
    refreshExpenseType,
    handleStatus,
  } = props;
  const { validateFields, resetFields, setFieldsValue, setFieldValue, getFieldValue } = form;
  const intl = useIntl();
  const Title = TitleModal(_.get(defaultModal, 'type'));
  const [visibleModalItem, setVisibleModalItem] = useState(false);
  const [toggleListItem, setToggleListItem] = useState(false);
  const [listItem, setListItem] = useState([]);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [fillTeam, setFillTeam] = useState([]);
  const [fillMember, setFillMember] = useState([]);
  const [fillExpense, setFillExpense] = useState([]);
  const [basePay, setBasePay] = useState();
  const refAmount = useRef();

  console.log('setBasePay', basePay);

  useEffect(() => {
    if (_.get(defaultModal, 'visible') === true && _.get(defaultModal, 'type') === 'create') {
      handleSelectExpend('pay');
    } else if (_.get(defaultModal, 'visible') === true && _.get(defaultModal, 'type') === 'edit') {
      const relese = handleSelectExpend(_.get(defaultModal, 'data.numberType'));
      const fineType = _.filter(relese, (item) => {
        return item.basePayId === _.get(defaultModal, 'data.basePayId');
      });

      setFieldsValue({
        expenseItem: _.size(fineType) > 0 ? _.get(defaultModal, 'data.basePayId') : _.get(defaultModal, 'data.basePayName'),
      });
    }
  }, [_.get(defaultModal, 'visible'), _.get(defaultModal, 'type')]);

  const handleSave = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const body = {
        amount: _.get(defaultModal, 'data.fromAllowance')
          ? _.get(defaultModal, 'data.amount')
          : _.get(values, 'expenseType') === 'pay'
          ? _.get(values, 'amount', 0)
          : _.get(values, 'expenseType') === 'deduct' || _.get(defaultModal, 'data.numberType') === 'deduct'
          ? -_.get(values, 'amount', 0)
          : _.get(values, 'amount', 0),
        whtRate: _.get(values, 'taxType'),
        wht: _.get(values, 'tax', 0),
        netAmount: _.get(values, 'netAmount', 0),
        planedPaymentDate: _.get(values, 'planPaymentDate') ? moment(_.get(values, 'planPaymentDate')).format('YYYY-MM-DD') : null,
        memComId: _.get(values, 'member'),
        refereance: _.get(values, 'reference', null),
        remark: _.get(values, 'remark', null),
        teamId: _.get(values, 'team', null),
        statusCode: type,
        numberType: _.get(values, 'expenseType'),
        basePayId: _.get(values, 'expenseItem'),
        memberExpenseId: _.get(defaultModal, 'data.memberExpenseId', null),
      };

      console.log('TBODY',body);
      
      const response = await fncApi(_.get(defaultModal, 'type'), body);
      if (_.get(response, 'data.status.code') === 200) {
        successNotification(_.get(response, 'data.status.message'));
        setTrigger((cur) => !cur);
        handleCancel();
        return;
      } else {
        errorNotification(_.get(response, 'data.status.message'));
      }
    });
  };

  const handleCancel = () => {
    setTabkeys('details');
    setDefaultModal({
      data: {},
      type: '',
      visible: false,
    });
    setFillExpense([]);
    setFillMember([]);
    setFillTeam([]);
  };

  const handleChangeEdit = (type) => {
    setDefaultModal({
      ...defaultModal,
      type: type,
    });
    if (type === 'verify') resetFields();
  };

  const handleAddItem = () => {
    setVisibleModalItem(true);
  };

  //------------------------- basepay--------------------------------------//

  const onSelectItem = async (values) => {
    const newtype = await refreshExpenseType();

    const tempteam = _.filter(newtype, (item) => {
      return item.noType === _.get(values, 'noType');
    });
    console.log('newtype', tempteam);

    setFillExpense(tempteam);

    setFieldsValue({
      expenseType: _.get(values, 'noType'),
      expenseItem: _.get(values, 'basePayId'),
    });
  };

  const refeApi = async () => {
    const tempitem = getFieldValue('expenseItem');
    const temptype = getFieldValue('expenseType');
    const newtype = await refreshExpenseType();

    const fineItem = _.filter(newtype, (item) => {
      return item.basePayId === tempitem;
    });

    const tempteam = _.filter(newtype, (item) => {
      return item.noType === temptype;
    });

    console.log('fineItem', fineItem, '/item', tempitem);

    setFillExpense(tempteam);

    if (_.size(fineItem) === 0) {
      setFieldsValue({
        expenseType: temptype,
        expenseItem: undefined,
      });
    } else {
      setFieldsValue({
        expenseType: temptype,
        expenseItem: tempitem,
      });
    }
  };

  //------------------------- ---------------------------------------------//

  const handleReject = () => {
    setVisibleStatus(true);
  };

  const handleRejectSave = (resson) => {
    handleChangeStatus('reject', resson);
    setDefaultModal({
      data: {},
      type: '',
      visible: false,
    });

    setVisibleStatus(false);
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const handleSelectMember = (item) => {
    if (item) {
      const tempmember = _.filter(_.get(dataFilter, 'memberList'), ['memComId', item]);
      const tempteam = _.filter(_.get(dataFilter, 'teamList'), (item) => {
        console.log('itemTeamID', _.get(item, 'teamId'), '/', tempmember);

        return _.includes(_.get(tempmember, '[0].teamIdList'), _.get(item, 'teamId'));
      });
      setFillTeam(tempteam);
    } else {
      setFillTeam([]);
    }
  };

  const handleSelectTeam = (value) => {
    if (value) {
      const tempteam = _.filter(_.get(dataFilter, 'memberList'), (item) => {
        const t = _.includes(_.get(item, 'teamIdList'), value);

        return t ? item : undefined;
      });

      setFillMember(tempteam);
    } else {
      setFillMember([]);
    }
  };

  const handleSelectExpend = (value) => {
    const tempteam = _.filter(_.get(dataFilter, 'numberType'), (item) => {
      return item.noType === value;
    });

    setFillExpense(tempteam);

    return tempteam;
  };

  const mouseOver = () => {
    if (_.get(defaultModal, 'data.fromAllowance') === false) {
      refAmount.current.blur();
    }
  };

  return (
    <Modal
      title={Title}
      visible={_.get(defaultModal, 'visible')}
      onCancel={handleCancel}
      width={760}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={
        <FooterModal
          data={_.get(defaultModal, 'data')}
          type={_.get(defaultModal, 'type')}
          fnc={{
            handleSave,
            handleChangeEdit,
            handleCancel,
            setTrigger,
            setDefaultModal,
            handleChangeStatus,
            handleReject,
            mouseOver,
            handleStatus,
          }}
        />
      }
    >
      {_.get(defaultModal, 'type') === 'create' ? (
        <>
          <ModalTabsDetail
            form={form}
            isCreate={true}
            isEdit={false}
            handleOpenAllowance={handleOpenAllowance}
            handleAddItem={handleAddItem}
            defaultModal={defaultModal}
            dataFilter={dataFilter}
            listItem={listItem}
            handleSelectTeam={handleSelectTeam}
            handleSelectMember={handleSelectMember}
            handleSelectExpend={handleSelectExpend}
            fillTeam={fillTeam}
            fillMember={fillMember}
            fillExpense={fillExpense}
            refAmount={refAmount}
          />
        </>
      ) : (
        <Tabs
          className="monitor-main-tabs customer-tab-margin viewTicketTab"
          animated={false}
          size="large"
          defaultActiveKey="details"
          onChange={callback}
          activeKey={tabkeys}
          tabBarExtraContent={
            <div className="tag-center-div" style={{ padding: '12px 12px' }}>
              <Tag style={{ borderRadius: '15px', minWidth: '85px', textAlign: 'center' }} color={_.get(defaultModal, 'data.statusColor')}>
                {_.get(defaultModal, 'data.statusName')}
              </Tag>
            </div>
          }
        >
          {_.map(ModalTabs, (item) => {
            return (
              <TabPane key={_.get(item, 'code')} tab={_.get(item, 'text')}>
                {_.get(item, 'code') === 'details' ? (
                  <ModalTabsDetail
                    form={form}
                    isCreate={false}
                    isEdit={_.get(defaultModal, 'type') === 'edit' ? true : false}
                    handleOpenAllowance={handleOpenAllowance}
                    handleAddItem={handleAddItem}
                    defaultModal={defaultModal}
                    dataFilter={dataFilter}
                    listItem={listItem}
                    notAlw={_.get(defaultModal, 'data.fromAllowance') === false ? true : false}
                    handleSelectTeam={handleSelectTeam}
                    handleSelectMember={handleSelectMember}
                    fillTeam={fillTeam}
                    fillMember={fillMember}
                    handleSelectExpend={handleSelectExpend}
                    fillExpense={fillExpense}
                    refAmount={refAmount}
                  />
                ) : (
                  <ModalTabsHistory historyList={_.get(defaultModal, 'data.statusLogs')} />
                )}
              </TabPane>
            );
          })}
        </Tabs>
      )}

      <ModalSelectItemPayDeduct
        visible={visibleModalItem}
        setVisble={setVisibleModalItem}
        setTrigger={setToggleListItem}
        setItem={onSelectItem}
        reApi={refeApi}
      />

      <ModalStatus
        title={`${intl.formatMessage({ id: 'resourceAllowanceModalStatusReject', defaultMessage: 'Rejected' })}`}
        visible={visibleStatus}
        setVisible={setVisibleStatus}
        handleSave={handleRejectSave}
        rejectList={rejectList}
        data={_.get(defaultModal, 'data')}
      />
    </Modal>
  );
};

const ModalMemberExpense = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {},
})(ModalFormMemberExpense);

export default ModalMemberExpense;
