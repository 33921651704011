import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import PersonalInformation from '../../users/user/personalInfomation';
import PersonalInformation from '../../users/user/personalInfomation-new';

import PayD from '../../users/user/payD/index';
import EmergencyContact from '../../users/user/emergencyContact';
import EquipmentTools from '../../users/user/equipment-tools/index';

import Team from '../../users/user/team/index';
import Skill from '../../users/user/skill';
import Role from '../../users/user/role';
import Document from '../../users/user/document/index';
import ChangePassword from './changePassword';
import GetLang from '../../../includes/language';
import httpClient from '../../../components/axiosClient';

import { useAppContext } from '../../../includes/indexProvider';
import { Row, Col, Layout } from 'antd';
import cssStyle from './css/manageAccount.css';
import _ from 'lodash';
import logoPDF from '../../login/image/pdf.png';
import Signature from '../../users/user/signature';

export default React.memo((props) => {
  const comCode = localStorage.getItem('comCode');

  const app = useAppContext();
  const params = useParams();
  const user_Id = localStorage.getItem('memComId'); // user on table id

  const [labelShow, setLabelShow] = useState({});
  const [paydSource, setPaydSource] = useState();
  const [paydDefault, setPaydDefault] = useState();
  const [checkPayDShow, setCheckPaydShow] = useState();

  const [documentCompany, setDocumentCompany] = useState([]);
  const [document, setDocument] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(1);
  const companyId = localStorage.getItem('companyId');
  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');
  const loginUser = localStorage.getItem('userId');
  const userId = localStorage.getItem('userId');

  const pageCode = localStorage.getItem('pageCode');

  useEffect(() => {
    Lang();
  }, []);

  useEffect(() => {
    paydData();
  }, [refresh, refresh2]);

  const Lang = async () => {
    const res = await GetLang({
      companyId: comId,
      lang: app.state.langValue,
      pageCode: pageCode,
    });
    setLabelShow(res);
  };


  const paydData = async () => {
    const dataSource = await httpClient.get(`/company/user/${user_Id}`);

    const defaultClassId = dataSource.data.PayDSummary.memClassId !== 0 ? dataSource.data.PayDSummary.memClassId : "Disable";
    const defaultClassName = dataSource.data.PayDSummary.memClassId !== 0 ? dataSource.data.PayDSummary.class : "Disable";



    try {
      setPaydSource(dataSource);
      setPaydDefault([defaultClassId, defaultClassName]);
      setCheckPaydShow(defaultClassName)
      setDocumentCompany(dataSource.data.documentCompany)
      setDocument(dataSource.data.documents)
    }
    catch (error) {

    }
  }


  document.map((obj, index) => {
    const fileDoc = {
      uid: index + 1,
      document_id: obj.docId,
      document_name: obj.name,
      original: obj.type === 'pdf' ? logoPDF : obj.path,
      thumbnail: obj.type === 'pdf' ? logoPDF : obj.path,
      thumbUrl: obj.type === 'pdf' ? logoPDF : obj.path,
      url: obj.type === 'pdf' ? logoPDF : obj.path,
      originalPath: obj.path,
      type: obj.type,
    };

    const found = documentCompany.findIndex((ele) => ele.docComId === obj.docComId);
    if (found >= 0) {
      if (!_.isArray(documentCompany[found].files)) {
        documentCompany[found].files = [];
      }
      const checkIsFile = documentCompany[found].files.findIndex((ele) => ele.document_id === fileDoc.document_id);
      if (checkIsFile === -1) {
        documentCompany[found].files.push(fileDoc);
      }
    }
  });


  return (
    <div>
      <Layout className="ant-layout">
        <Layout>
          <Row gutter={[10, 10]}>
            <Col span={18}>
              <Row className="user-info">
                <PersonalInformation
                  user_id={memComId}
                  companyId={companyId}
                  memComId={memComId}
                  loginUser={loginUser}
                  labelShow={labelShow}
                />
              </Row>

              <Row className="child-column1">
                {paydSource ?
                  <PayD
                    paydSource={paydSource}
                    paydDefault={paydDefault}
                    memComId={memComId}
                    comId={comId}
                    labelShow={labelShow}
                    langValue={app.state.langValue}
                    checkPayDShow={checkPayDShow}
                  /> : null}
              </Row>

              <Row className="child-column1">
                <EmergencyContact
                  companyId={companyId}
                  labelShow={labelShow}
                  statusManageContact
                />
              </Row>

              <Row className="child-column1">
                <EquipmentTools
                  userId={params.user_id}
                />
              </Row>
            </Col>

            <Col
              span={6}
              // gutter={[15, 20]}
              style={{ paddingLeft: '10px', paddingTop: '0px', marginTop: '6px' }}
            >
              <Row className="team-card-myProfile">
                <ChangePassword
                  userId={loginUser}
                  companyId={companyId}
                  labelShow={labelShow}
                />
              </Row>

              <Row span={6} className="mt-20" >
                <Team
                  userId={memComId}
                  companyId={companyId}
                  createdBy={memComId}
                  labelShow={labelShow}
                />
              </Row>

              <Row span={6} className="mt-20">
                <Role
                  userId={memComId}
                  companyId={companyId}
                  createdBy={memComId}
                  labelShow={labelShow}
                />
              </Row>

              <Row span={6} className="mt-20">
                <Skill
                  userId={memComId}
                  companyId={companyId}
                  createdBy={memComId}
                  labelShow={labelShow}
                />
              </Row>

              <Row span={6} className="mt-20">
                <Document
                  memComId={memComId}
                  companyId={comId}
                  labelShow={labelShow}
                  documentCompany={documentCompany}
                  onChangeFile={(i) => {
                    let ii = refresh + 1
                    setRefresh(ii);
                  }}
                  onChangeFile2={(index, file) => {
                    let i = refresh2 + 1
                    setRefresh2(i);
                    paydData();
                  }}
                />
              </Row>

              <Row span={6} className="mt-20">
                <Signature
                  memComId={memComId}
                />
              </Row>
            </Col>
          </Row>
        </Layout>
      </Layout>
    </div>
  );
});