import React, { useEffect } from 'react';
import { Select, Checkbox, Table, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../label-required-form';
import _ from 'lodash';
import NoDataBackground from '../../no-data-page';
import Button_01 from '../../v2/button_01';
import ItemMasterMainModal from '../../create-edit-item-master/modal-main';

const { Option } = Select;

const ListItemDetail = (props) => {
  const {
    columns,
    handleClickRow,
    onChange,
    vendorData,
    itemList,
    itemListTotal,
    handlePagination,
    handleSizeChange,
    listPage,
    form,
    onClickVisibleItemMaster,
  } = props;
  const intl = useIntl();
  const { getFieldDecorator, getFieldValue } = form;

  useEffect(() => {
    if (getFieldValue('vendor')) {
      onChange(getFieldValue('vendor'), 'vendor');
    }
  }, [getFieldValue('vendor')]);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  return (
    <div style={{ height: '625px', padding: '16px' }}>
      <div style={{ marginBottom: '24px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <Form form={form} layout="vertical">
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'purchesModalFormVendor', defaultMessage: 'Vendor or Supplier' })}
                  req={false}
                />
              }
            >
              {getFieldDecorator(
                'vendor',
                {}
              )(
                <Select
                  placeholder={intl.formatMessage({ id: 'purchesModalFormPlaceholderVendor', defaultMessage: 'Select Vendor or Supplier' })}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  style={{ width: '400px' }}
                  onSelect={(e) => onChange(e, 'vendor')}
                >
                  {vendorData && vendorData.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Form>
          <Checkbox onChange={(e) => onChange(e.target.checked, 'purchase')}>
            <FormattedMessage id="modalListItemDetailRecent" defaultMessage="Recently Purchased Items" />
          </Checkbox>
        </div>
      </div>
      {_.size(itemList) > 0 ? (
        <div>
          <Table
            columns={columns}
            dataSource={itemList}
            scroll={{ y: 380 }}
            onChange={handlePagination}
            pagination={{
              showTotal: showTotal,
              defaultCurrent: 1,
              total: itemListTotal,
              current: listPage,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              onShowSizeChange: handleSizeChange,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href) {
                    handleClickRow(record);
                  }
                },
              };
            }}
          />
          {itemList && itemList.length !== 0 ? (
            <div className="total-items-timeAttendance">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${itemListTotal || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <NoDataBackground
            text1={{ id: 'nodataPo1', text: 'Item Not Found' }}
            text2={{ id: 'nodataPo2', text: 'Please Select or Create New Item Master' }}
          />
          <div style={{ textAlign: 'center', paddingTop: '16px' }}>
            <Button_01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={() => onClickVisibleItemMaster()}>
              <FormattedMessage id="btnCreate temMaster" defaultMessage="Create Item Master" />
            </Button_01>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default ListItemDetail;
