import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Modal, Select, Typography, Icon, Col, Row, Divider } from 'antd';
import Api from '../../../components/httpClient';
import { notificationWithIcon } from '../../../components/notification';
import pathUrt from '../../../function/pathUrl';
import cssStyle from './css/cssCard.css';
import clientNew from '../../../components/axiosClient';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { PageSettings } from '../../../config/page-settings';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';

const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;
let client = Api();

export default (props) => {
  const intl = useIntl();

  const [dataSource, setToDatasource] = useState([]);
  const [dataSourceId, setToDatasourceId] = useState([]);
  const [stateModal, setStateModal] = useState(false);
  const [select, setSelect] = useState([]);
  const [defaultArr, setDefaultArr] = useState([]);
  const createBy = props.createdBy;
  const userId = props.userId;
  const checkPathUrl = pathUrt('/user-management/my-profile');

  useEffect(() => {
    checkApi();
  }, []);

  const checkApi = async () => {
    const fectId = await clientNew.get(`/v2/roles/${userId}`);
    if (fectId.data.length > 0) {
      getId();
    }
  };

  const showModal = async () => {
    let arr = [];
    let arrPerGoId = [];
    const res = await clientNew.get(`/v2/permissiongroups/${userId}`);
    const fectTag = await clientNew.get(`/v2/roles/${userId}`);
    for (let i = 0; i < fectTag.data.length; i++) {
      arr.push(fectTag.data[i].per_gro_id);
      arrPerGoId.push(fectTag.data[i].per_gro_id);
    }

    setSelect(arrPerGoId);
    setDefaultArr(arr);
    setToDatasource(res.data);
    setStateModal(!stateModal);
  };
  const handleCancel = (e) => {
    setStateModal(false);
  };
  const addDataList = () => {
    setStateModal(false);

    let data = {
      user_id: userId,
      created_by: createBy,
      per_gro_id: select,
    };
    create(data);
  };

  const create = async (data = {}) => {
    let postApi = '/v3/resource-old/manager/create/role';
    await clientNew
      .post(postApi, data)
      .then((respone) => {
        if (respone.status === 200) {
          successNotification(respone.data.status.message);
        }
      })
      .catch((error) => {
        notificationWithIcon('error', 'Create fail.');
      });
    getId();
  };

  const getId = async () => {
    const fectId = await clientNew.get(`/v2/roles/${userId}`);
    setToDatasourceId(fectId.data);
  };

  const handleChangeOption = (value, options) => {
    const dataCreate = [];
    options.forEach((element) => {
      dataCreate.push(element.key);
    });
    setSelect(dataCreate);
    setDefaultArr(value);
  };

  const confirmDelete = async (id, index) => {
    Modal.confirm({
      className: 'userProfile-modal-confirm',
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'dashed',
      },

      onOk() {
        Delete(id, index);
      },
    });
  };

  const Delete = async (id, index) => {
    let todoNew = [...dataSourceId];
    todoNew.splice(index, 1);
    setToDatasourceId(todoNew);
    await clientNew
      .delete(`/v2/role/${createBy}/${id}`)
      .then((respone) => {
        if (respone.status === 200) {
          notificationWithIcon('success', respone.data.data);
        }
      })
      .catch((error) => {
        notificationWithIcon('error', 'Delete fail.');
      });
  };

  let show = dataSourceId.length ? (
    dataSourceId.map((item, index) => (
      <div>
        <Row>
          <Col span={20}>{item.name}</Col>

          <Col span={4}>
            <PageSettings.Consumer>
              {({ checkPermissionAction }) => (
                <div style={{ display: 'initial' }}>
                  {checkPermissionAction('P2S1PG2C5', 'P2S1PG2C5A3') ? (
                    checkPathUrl === true ? null : (
                      <Icon type="more" style={{ float: 'right' }} onClick={(e) => confirmDelete(item.mem_per_gro_id, index)} />
                    )
                  ) : null}
                </div>
              )}
            </PageSettings.Consumer>
          </Col>
        </Row>
        <Divider className="usersDetail-content-divider" />
      </div>
    ))
  ) : (
    <Text type={'secondary'} style={{ paddingLeft: '15px' }}>
      <FormattedMessage id="userMgntUsersDetailLblRoleTxt" defaultMessage="Please assign to role" />
    </Text>
  );

  let option = dataSource.map((item, index) => (
    <Option
      key={item.per_gro_id}
      value={item.per_gro_id}
      placeholder={intl.formatMessage({ id: 'userMgntUsersModalHintSelete', defaultMessage: 'Please select' })}
    >
      {item.name}
    </Option>
  ));

  const onClickDeleteTags = (tagsId) => {
    let index = defaultArr.indexOf(tagsId);
    let newDefaultArr = [...defaultArr];
    newDefaultArr.splice(index, 1);
    setDefaultArr(newDefaultArr);
  };

  return (
    <Card
      title={<FormattedMessage id="userMgntUsersDetailTitleRole" defaultMessage="Role" />}
      extra={
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {checkPermissionAction('P2S1PG2C5', 'P2S1PG2C5A2') ? (
                checkPathUrl === true ? null : (
                  <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => showModal()}>
                    <FormattedMessage id="userMgntUsersDetailBtnAssignNew" defaultMessage="Assign" />
                  </Button01>
                )
              ) : null}
            </div>
          )}
        </PageSettings.Consumer>
      }
    >
      {show}
      <Modal
        visible={stateModal}
        // onOk={addDataList}
        // onCancel={handleCancel}
        // okText={intl.formatMessage({ id: 'userDetailModalBtnSave', defaultMessage: 'Save' })}
        // cancelText={intl.formatMessage({ id: 'userDetailModalBtnCancel', defaultMessage: 'Cancel' })}
        className="button-modal"
        title={
          <Title style={{ fontSize: '15px', fontWeight: 400 }}>
            <FormattedMessage id="userMgntUsersDetailTitleRole" defaultMessage="Role" />
          </Title>
        }
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} className="btn-style-new" key="back" fontsize="sm" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 className="btn-style-new2" key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={addDataList}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Select
          className="user-management-role-select"
          mode="multiple"
          allowClear="true"
          autoClearSearchValue="true"
          placeholder={intl.formatMessage({ id: 'userMgntUsersModalHintSelete', defaultMessage: 'Please select' })}
          onChange={handleChangeOption}
          value={defaultArr}
          style={{ width: '100%' }}
          onDeselect={(tags, options) => onClickDeleteTags(tags)}
        >
          {option}
        </Select>
      </Modal>
    </Card>
  );
};
