import React, { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Col, Row, Tag, InputNumber } from 'antd'
import { CreateTaskContext } from './create-task-context'
import TabInformation from './create-task-information'
import TabMoreDetail from './create-task-more-detail'
import Team from './create-task-team'
import Assignee from './create-task-assignee'
import './css/index.css'
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../v2/button_01';
import Button02 from '../../../v2/button_02';
import _ from 'lodash';
import { getTaskTypeData, getCustomerData, getCustomerLocationAndConatact, getCustomeField } from '../../../../controllers/task/create-task';
import AddBusinessPartner from '../../../add-business-partner/index'
import AddContact from '../../../add-contact-v2/index'
import AddFromLocation from '../../../from-location/add/index'
import EditFromLocation from '../../../from-location/edit/index'
import AddAddressBookV2 from '../../../modal-create-adress-map-v2/index'
import EditAddressBookV2 from '../../../modal-edit-adress-map-v2/index'
import ModalAssignee from './create-task-assignee-modal'
import { EditorState } from 'draft-js';
import moment from 'moment';
import httpClient from '../../../../components/axiosClient';
import { deleteImg, CreateDataMoreDetail } from '../../../../controllers/more-detail/more-detail-api';
import { successNotification, errorNotification } from '../../../../components/v2/notification';

const { TabPane } = Tabs;
const { confirm } = Modal;

export default (props) => {
  const { visible, setVisible, setTrigger } = props
  const intl = useIntl();
  //-------------------Ref --------------------------
  const informationRef = useRef();
  const teamRef = useRef();
  //-------------------Visible --------------------------
  const [visibleCustomer, setVisibleCustomer] = useState(false);
  const [visibleContact, setVisibleContact] = useState(false);
  const [visibleAddCompanyAddress, setVisibleAddCompanyAddress] = useState(false);
  const [visibleEditComapanyAddress, setVisibleEditComapanyAddress] = useState(false);
  const [visibleAddCustomerAddress, setVisibleAddCustomerAddress] = useState(false);
  const [visibleEditCustomerAddress, setVisibleEditCustomerAddress] = useState(false);
  const [visibleAssignee, setVisibleAssignee] = useState(false);
  //-------------------Data --------------------------
  const [taskTypeData, setTaskTypeData] = useState([]);
  const [priorityData, setPriorityData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [fromLocationData, setFromLocationData] = useState([]);
  const [toLocationData, setToLocationData] = useState([]);
  const [editFromLocationData, setEditFromLocationData] = useState();
  const [editTolocationData, setEditTolocationData] = useState();
  const [customFieldData, setCustomFieldData] = useState([])
  //------------------- New Data from Modal ------------
  const [newCustomer, setNewCustomer] = useState();
  const [newContact, setNewContact] = useState();
  const [newCompanyAddress, setNewCompanyAddress] = useState();
  const [newCustomerAddress, setNewCustomerAddress] = useState();
  //------------------- Utility -----------------------
  const [defaultPriority, setDefaultPriority] = useState();
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [orgId, setOrgId] = useState();
  const [taskTypeId, setTaskTypeId] = useState();
  const [member, setMember] = useState([]);
  const [triggerCustomer, setTriggerCustomer] = useState(false);
  const [triggerContact, setTriggerContact] = useState(false);
  const [triggerCustomerAddress, setTriggerCustomerAddress] = useState(false);
  const [triggerCompanyAddress, setTriggerCompanyAddress] = useState(false);
  const [disTancePolyline, setDisTancePolyline] = useState();
  const [dataMemberAssignee, setDataMemberAssignee] = useState([]);
  const [numberTask, setNumberTask] = useState(1);
  const [editorStaet, setEditorStaet] = useState(EditorState.createEmpty())
  const [imgSrc, setImgSrc] = useState([])
  const [allSrc, setAllSrc] = useState([])
  const [disbleEditor, setDisbleEditor] = useState(false)
  const [textValue, setTextValue] = useState('')
  const comId = localStorage.getItem('comId');

  const handleCreate = () => {
    let infoError = false
    let teamError = false

    let setInfo = null;
    informationRef.current.validateFields((err, values) => {
      setInfo = values;
      if (err) {
        return infoError = true
      }
    });
    let setTeam = null;
    teamRef.current.validateFields((err, values) => {
      setTeam = values;
      if (err) {
        return teamError = true
      }
    });

    if (infoError !== true && teamError !== true) {

      confirm({
        className: "customer-modal-confirm",
        centered: true,
        icon: null,
        title: intl.formatMessage({ id: `monitorCreateTaskModalConfirm`, defaultMessage: 'Are you sure to save task.' }),
        okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: "primary",
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: "danger",
        },
        async onOk() {
          const mapMember = dataMemberAssignee.map((e) => { return e.memComId })
          const numberFromStart = _.get(setInfo, 'startDate') ? Number(moment(_.get(setInfo, 'startDate'), 'YYYY-MM-DD HH:mm:ss').unix()) : undefined;
          const numberToStart = _.get(setInfo, 'dueDate') ? Number(moment(_.get(setInfo, 'dueDate'), 'YYYY-MM-DD HH:mm:ss').unix()) : undefined;
          const filterTo = toLocationData && toLocationData.filter(item => { return item.customerAddressBookId === _.get(setInfo, 'toLocation') })
          const filterName = contactData && contactData.filter((col) => setInfo.contact.includes(col.customerContactId));

          const body = {
            statusCode: "new",
            orgId: _.get(setTeam, 'team') ? _.get(setTeam, 'team') : '',
            assignees: mapMember ? mapMember : '',
            orderRefCode: " ",
            planStart: numberFromStart ? numberFromStart : '',
            planFinish: numberToStart ? numberToStart : '',
            cusId: _.get(setInfo, 'customer') ? _.get(setInfo, 'customer') : '',
            cusName: customerName ? customerName : '',
            cusEmail: " ",
            cusPhone: '',
            deliveryAddress: _.get(filterTo, '[0]') ? _.get(filterTo, '[0].address') : '',
            lat: _.get(filterTo, '[0]') ? _.get(filterTo, '[0].lat') : '',
            lng: _.get(filterTo, '[0]') ? _.get(filterTo, '[0].lng') : '',
            remark: _.get(setInfo, 'remark') ? _.get(setInfo, 'remark') : undefined,
            sla: '',
            requestNo: '',
            priorityId: _.get(setInfo, 'priority') ? _.get(setInfo, 'priority') : '',
            customerContactId: _.get(setInfo, 'contact') ? _.get(setInfo, 'contact') : '-',
            contacts: [
              {
                name: filterName && filterName !== undefined ? filterName[0].customerContactName : '-',
                phone: _.get(setInfo, 'phone') ? _.get(setInfo, 'phone') : '-',
                email: '-'
              }
            ],
            toAddressId: _.get(setInfo, 'toLocation') ? _.get(setInfo, 'toLocation') : '',
            copy: numberTask,
            taskName: _.get(setInfo, 'taskName') ? _.get(setInfo, 'taskName') : ''
          }


          if (customFieldData && customFieldData !== undefined) {
            for (let index = 0; index < customFieldData.length; index++) {
              // const element = array[index];
              const entityToField = _.get(customFieldData[index], 'entityToField')
              if (entityToField) {
                body[entityToField] = _.get(setInfo, entityToField) || '';
              }

            }
          }

          try {
            const response = await httpClient.post(`/v3/task/manager/company/${comId}/task-type/${setInfo.taskType}/add-task/?menuType=workdesk`, body);
            if (response.status == 200) {
              if (textValue && textValue !== '<p></p>' && textValue !== '<p></p>\n') {
                const diff = _.differenceBy(allSrc, imgSrc, 'fullPathIs')
                const mapDiff = diff && diff.map(item => { return item.fileCodeIs })
                await deleteImg(mapDiff)
                await CreateDataMoreDetail(_.get(response, 'data.data.taskId'), textValue)
                setAllSrc([])
                setImgSrc([])

              }
              successNotification(response.data.status.message);
              setVisible(false)
              setNewCustomer()
              setNewContact()
              setCustomerId();
              setCustomerName();
              setNewCustomerAddress();
              setNewCompanyAddress();
              informationRef.current.resetFields()
              teamRef.current.resetFields()
              setOrgId();
              setTaskTypeId();
              setDataMemberAssignee([]);
              setTextValue('')
              setDisTancePolyline();
              setEditorStaet(EditorState.createEmpty());
              setTrigger.setRefreshTable(current => !current);
              setTrigger.setRefreshTaskDetailsData(current => !current);
              setTrigger.setRefreshApiGanttView(current => !current);
              setTrigger.setRefreshDataTaskPool(current => !current);
            }
            else {
              errorNotification(response.data.status.message);
            }
          } catch (error) {
          }


        },
        onCancel() { },
      });
    }

  }
  const handleCancel = () => {
    setVisible(false)
    informationRef.current.resetFields()
    teamRef.current.resetFields()
    setNewCustomer()
    setNewContact()
    setCustomerId();
    setCustomerName();
    setNewCustomerAddress();
    setNewCompanyAddress();
    setOrgId();
    setTaskTypeId();
    setDataMemberAssignee([]);
    setTextValue('')
    setDisTancePolyline();
    setEditorStaet(EditorState.createEmpty());
    setAllSrc([])
    setImgSrc([])
  }

  useEffect(() => {
    const GetDefaultData = async () => {
      const taskTypeData = await getTaskTypeData('workdesk')
      Promise.all([taskTypeData, customerData]).then((values) => {
        //---------------[0]----------------------------
        setTaskTypeData(_.get(values[0], 'data.taskType'))
        setPriorityData(_.get(values[0], 'data.priority'))
        const filerDefault = _.get(values[0], 'data.priority').filter(item => {
          return item.isDefault === true
        })
        setDefaultPriority(filerDefault[0])
        setTeamData(_.get(values[0], 'data.team'))
        //---------------[1]----------------------------
      })
    }
    GetDefaultData();
  }, []);


  useEffect(() => {
    const getCustomerAll = async () => {
      const dataCustomer = await getCustomerData(10000, 'workdesk');
      setCustomerData(_.get(dataCustomer, 'data.customers'))
    }
    getCustomerAll();
  }, [triggerCustomer])

  useEffect(() => {
    const getCustomerCotactLocation = async () => {
      if (customerId) {
        const dataContact = await getCustomerLocationAndConatact(customerId, 'workdesk');
        setContactData(_.get(dataContact, 'data.data.contactCustomer'))
        setFromLocationData(_.get(dataContact, 'data.data.fromLocations'))
        setToLocationData(_.get(dataContact, 'data.data.toLocations'))
      }
    }
    getCustomerCotactLocation();
  }, [customerId, triggerContact, triggerCustomerAddress, triggerCompanyAddress])


  useEffect(() => {
    const CustomeField = async () => {
      const responseCustom = await getCustomeField('task_type', taskTypeId, 'transportation')
      setCustomFieldData(responseCustom.data)
    }
    CustomeField();

  }, [taskTypeId]);


  return (
    <>
      <CreateTaskContext.Provider
        value={{
          CreateState: {
            taskTypeData,
            priorityData,
            teamData,
            defaultPriority,
            customerData,
            contactData,
            newCustomer,
            triggerContact,
            newContact,
            fromLocationData,
            toLocationData,
            disTancePolyline,
            newCustomerAddress,
            newCompanyAddress,
            visibleAssignee,
            orgId,
            taskTypeId,
            member,
            dataMemberAssignee,
            customFieldData,
            editorStaet,
            imgSrc,
            allSrc,
            disbleEditor,
            textValue,
          },
          CreateSetState: {
            setContactData,
            setVisibleCustomer,
            setVisibleContact,
            setCustomerId,
            setDisTancePolyline,
            setVisibleAddCustomerAddress,
            setCustomerName,
            setVisibleEditCustomerAddress,
            setEditTolocationData,
            setVisibleAddCompanyAddress,
            setVisibleEditComapanyAddress,
            setEditFromLocationData,
            setVisibleAssignee,
            setOrgId,
            setTaskTypeId,
            setMember,
            setDataMemberAssignee,
            setEditorStaet,
            setImgSrc,
            setAllSrc,
            setDisbleEditor,
            setTextValue,
          },
          CreateFNC: {

          }
        }}
      >
        <Modal
          title={intl.formatMessage({ id: 'createTaskMntTilteCreate', defaultMessage: 'Create a Task' })}
          centered={true}
          onOk={handleCreate}
          onCancel={handleCancel}
          visible={visible}
          bodyStyle={{ padding: 'unset', height: '98%' }}
          width={1150}
          footer={
            <div>
              <div>
                <div>
                  <div style={{ float: 'left', lineHeight: '32px', paddingRight: '5px', height: '32px', paddingLeft: '4px' }}>
                    <FormattedMessage id="monitorCreateTaskTxtCopiesTask" defaultMessage="Copies (Max 10)" /> :
                  </div>
                  <div style={{ float: 'left' }}>
                    <InputNumber
                      min={1}
                      type="number"
                      size='default'
                      className='create-task-copies-task-number '
                      max={10}
                      value={numberTask}
                      onChange={(e) => setNumberTask(e)}
                    />
                  </div>
                </div>
                <div >
                  <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" onClick={handleCancel} style={{ margin: 'unset' }}>
                    <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                  </Button02>
                  <Button01 key="submit" type="primary" onClick={() => handleCreate()} id='saveButton'>
                    <FormattedMessage id="btnSave" defaultMessage="Save" />
                  </Button01>
                </div>
              </div>
            </div>
          }
        >
          <Row className='create-task-body-panel'>
            <Col span={18} className='create-task-boder-div-style'>
              <Tabs className='create-task-tab-tabpane'>
                <TabPane tab={intl.formatMessage({ id: 'createTaskMntTabInformation', defaultMessage: 'Information' })} key='1'>
                  <TabInformation ref={informationRef} />
                </TabPane>
                <TabPane tab={intl.formatMessage({ id: 'createTaskMntTabMoreDetails', defaultMessage: 'More Details' })} key='2'>
                  <TabMoreDetail />
                </TabPane>
              </Tabs>
            </Col>
            <Col span={6}>
              <div className='create-task-border-style'>
                <span className='create-task-text-status'><FormattedMessage id="createTaskMntLBStatus" defaultMessage="Status" /></span>
                <Tag color="#1890ff" className='create-task-tag-status'><FormattedMessage id="createTaskMntLBNew" defaultMessage="New" /></Tag>
              </div>
              <Team ref={teamRef} />
              <Assignee />
            </Col>
          </Row>
        </Modal>

        <ModalAssignee />
        {
          visibleCustomer &&
          <AddBusinessPartner
            visible={visibleCustomer}
            setVisible={setVisibleCustomer}
            setCustomerData={setNewCustomer}
            setTrigger={setTriggerCustomer}
          />
        }
        {
          visibleContact &&
          <AddContact
            visible={visibleContact}
            setVisible={setVisibleContact}
            customerId={informationRef.current && informationRef.current.getFieldValue(`customer`)}
            setTriggerAPI={setTriggerContact}
            setContactID={setNewContact}
          />
        }
        <AddFromLocation
          visible={visibleAddCompanyAddress}
          setVisible={setVisibleAddCompanyAddress}
          setTrigger={setTriggerCompanyAddress}
          toLocation={setNewCompanyAddress}
        />

        <EditFromLocation
          visible={visibleEditComapanyAddress}
          setVisible={setVisibleEditComapanyAddress}
          valueDefault={editFromLocationData}
          setTrigger={setTriggerCompanyAddress}
        />

        <AddAddressBookV2
          visible={visibleAddCustomerAddress}
          setVisible={setVisibleAddCustomerAddress}
          customerId={customerId}
          customerName={customerName}
          toLocation={setNewCustomerAddress}
          setTrigger={setTriggerCustomerAddress}
        />

        <EditAddressBookV2
          visible={visibleEditCustomerAddress}
          setVisible={setVisibleEditCustomerAddress}
          customerId={customerId}
          customerName={customerName}
          valueDefault={editTolocationData}
          setTrigger={setTriggerCustomerAddress}
        />
      </CreateTaskContext.Provider >
    </>
  )
}
