import React from 'react';
import './css/index.css';
import { Row, Col, Divider, Table } from 'antd';
import Button01 from '../../../components/v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../config/page-settings';

export default (props) => {
  const { columns, setVisibleModal, skillList,onChangeTable } = props;

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div className="table-list">
          <div className="table-list-container-header">
            <Row className="table-list-container">
              <Col span={12}>
                <span className="table-list-header-title">
                  <FormattedMessage id="skillHeaderSkillList" defaultMessage="Skills" />
                </span>
              </Col>
              <Col span={12} className="maintenance-header-right">
                {checkPermissionAction('P15PG1C1', 'P15PG1C1A5') ? (
                  <Button01 type="primary" onClick={() => setVisibleModal(true)}>
                    <FormattedMessage id="skillDetailAddSkill" defaultMessage="Add Skill" />
                  </Button01>
                ) : null}
              </Col>
            </Row>
          </div>
          <Divider type="horizontal" />
          <div style={{ padding: '0px 24px' }}>
            <Table
              className="skill-list-table"
              columns={columns}
              pagination={false}
              dataSource={skillList}
              scroll={{ y: `calc(100vh - 496px)` }}
              onChange={onChangeTable}
            />
          </div>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
