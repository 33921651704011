import React, { useState, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Form, Select, Input, notification, TimePicker, Row, Col } from 'antd';
import MapComponent from './map/index';
import httpClient from '../../../components/axiosClient';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { useAppContext } from '../../../includes/indexProvider';
import { PageSettings } from '../../../config/page-settings';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import styles from './css/addArea.css';
import stylesIndex from './css/index.css';

const { Option } = Select;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends React.Component {
    render() {
      const {
        areaList,
        taskTypes,
        maxBuffer,
        selectAreaList,
        visible,
        onChange,
        onCancel,
        onCreate,
        onSearch,
        onBlur,
        onFocus,
        intl,
        form,
        langValueState,
        customerData,
      } = this.props;
      const { getFieldDecorator, getFieldValue } = form;
      console.log('add-area:::::::', localStorage.getItem('GEO_APIKEY'));
      const format = langValueState == 'EN' ? 'h:mm A' : 'HH:mm';

      const LabeRequire = (props) => {
        const { text, req } = props;
        return (
          <span>
            {text}&nbsp;
            {req ? <LabelRequire>*</LabelRequire> : ''}
          </span>
        );
      };

      const LabelRequire = styled.label`
        color: #ff0000;
      `;

      const compareStartTime = (rule, value, callback) => {
        let valueEndTime = form.getFieldValue(`endTime`);
        if (value >= valueEndTime) {
          callback(
            <span>
              {intl.formatMessage({
                id: 'orgTabServiceValidateStartTime',
                defaultMessage: 'The start time should not be greater than or equal to the end time.',
              })}
            </span>
          );
        }
        callback();
      };

      const compareEndTime = (rule, value, callback) => {
        let valueStartTime = form.getFieldValue(`startTime`);
        if (value <= valueStartTime) {
          callback(
            <span>
              {intl.formatMessage({
                id: 'orgTabServiceValidateEndTime',
                defaultMessage: 'The end time should not be less than or equal to the start time.',
              })}
            </span>
          );
        }
        callback();
      };

      return (
        <Modal
          title={<FormattedMessage id="orgTabServiceModalAddTitle" defaultMessage="Add Area" />}
          className="label-from-AddArea"
          centered
          bodyStyle={{ height: '600px', overflowY: 'auto' }}
          visible={visible}
          onOk={onCreate}
          onCancel={onCancel}
          width={790}
          footer={[
            <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" fontsize="sm" btnsize="wd_df" onClick={onCancel} margin=" unset">
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={onCreate}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>,
          ]}
        >
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  className="label-from"
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabServiceModalLblName" defaultMessage="Services area name" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabServiceRequireName',
                          defaultMessage: 'Please select your services area name',
                        }),
                      },
                    ],
                  })(
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'orgTabServiceModalHintName',
                        defaultMessage: 'Please enter services area name',
                      })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="label-from"
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabServiceModalLblAreaName" defaultMessage="Area name or CODE" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('area', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'orgTabServiceRequireAreaName', defaultMessage: 'Please select your area.' }),
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      placeholder={intl.formatMessage({ id: 'orgTabServiceModalHintAreaName', defaultMessage: 'Please select area' })}
                      onSearch={onSearch}
                      onChange={onChange}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {areaList.map((item, i) => (
                        <Option key={item.areaCode} value={item.areaCode}>
                          {item.areaName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item className="label-from">
              {localStorage.getItem('GEO_APIKEY') ? <MapComponent areaList={selectAreaList} /> : null}
            </Form.Item>

            <Form.Item
              className="label-from"
              label={
                <LabeRequire
                  text={<FormattedMessage id="orgTabServiceModalLblTaskType" defaultMessage="Task type or task category" />}
                  req={true}
                />
              }
            >
              {getFieldDecorator('category', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'orgTabServiceRequireTaskType', defaultMessage: 'Please select your category.' }),
                  },
                ],
              })(
                <Select
                  className="addArea-select-tag"
                  placeholder={intl.formatMessage({
                    id: 'orgTabServiceModalHintTaskType',
                    defaultMessage: 'Please select task type or task category',
                  })}
                  mode="multiple"
                >
                  {taskTypes.map((item, i) => (
                    <Option key={i} value={item.taskTypeCode}>
                      {item.taskTypeName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <div className="services-div-time-period">
              <Row gutter={16}>
                <Col span={16} className="services-row-col-time-period">
                  <Form.Item
                    className="label-from"
                    label={
                      <LabeRequire
                        text={<FormattedMessage id="orgTabServiceModalLblTimeperiod" defaultMessage="Time period" />}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('startTime', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'orgTabServiceRequireTimeperiodStart',
                            defaultMessage: 'Please choose your start time.',
                          }),
                        },
                        {
                          validator: compareStartTime,
                        },
                      ],
                    })(
                      <TimePicker
                        className="time-period-timePicker"
                        placeholder={intl.formatMessage({
                          id: 'orgTabServiceModalHintTimeperiodStart',
                          defaultMessage: 'Please enter start time',
                        })}
                        use12Hours
                        format={format}
                      />
                    )}
                  </Form.Item>
                  <span className="service-span-time-period"> - </span>
                  <Form.Item
                    // className="time-period-end-label-from"
                    label={<span className="service-label-transparent">Time period</span>}
                  >
                    {getFieldDecorator('endTime', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'orgTabServiceRequireTimeperiodEnd',
                            defaultMessage: 'Please choose your end time',
                          }),
                        },
                        {
                          validator: compareEndTime,
                        },
                      ],
                    })(
                      <TimePicker
                        className="time-period-timePicker"
                        placeholder={intl.formatMessage({
                          id: 'orgTabServiceModalHintTimeperiodEnd',
                          defaultMessage: 'Please enter end time',
                        })}
                        use12Hours
                        format={format}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} className="services-row-col-time-period">
                  <Form.Item
                    className="label-from"
                    label={<LabeRequire text={<FormattedMessage id="orgTabServiceModalLblSLA" defaultMessage="SLA (min.)" />} req={true} />}
                  >
                    {getFieldDecorator('buffer', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabServiceRequireSLA', defaultMessage: 'Please select your SLA.' }),
                        },
                        {
                          pattern: new RegExp(/^[0-9\b]+$/),
                          message: intl.formatMessage({
                            id: 'orgTabServiceRequireSLAMustNumber',
                            defaultMessage: 'The SLA must only be number.',
                          }),
                        },
                      ],
                    })(
                      <Input
                        placeholder={intl.formatMessage({ id: 'orgTabServiceModalHintSLA', defaultMessage: 'Please enter min' })}
                        autoComplete="off"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  className="label-from"
                  label={<LabeRequire text={<FormattedMessage id="orgTabServiceModalLblCustomer" defaultMessage="Customer" />} />}
                >
                  {getFieldDecorator('customer', {
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: 'orgTabServiceRequireCustomer', defaultMessage: 'Please select your customer' }),
                      },
                    ],
                  })(
                    <Select
                      className="addArea-select-tag"
                      placeholder={intl.formatMessage({ id: 'orgTabServiceModalHintCustomer', defaultMessage: 'Please select customer' })}
                      mode="multiple"
                    >
                      {customerData.map((item, i) => (
                        <Option key={i} value={item.customerId}>
                          {item.customerName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Row>
            </div>
          </Form>
        </Modal>
      );
    }
  }
);

export default ({ areaList, taskTypes, maxBuffer, comId, orgId, refreshDataServices, customerData }) => {
  const intl = useIntl();
  const appGetlang = useAppContext();
  const langValueState = _.get(appGetlang, 'state.langValue');
  const [visible, setVisible] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const [selectAreaList, setSelectAreaList] = useState([]);

  moment.locale(langValueState);

  const onChange = async (value) => {
    try {
      const result = await httpClient.get(`/companies/master-areas/${value}`);
      if (value) {
        result.data.filter((element) => {
          if (element.areaCode === value) {
            setSelectAreaList(element.coordinates);
          }
        });
      }
    } catch {
      return;
    }
  };

  const handleCreate = async () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const data = {
        comCode: parseInt(comId),
        orgCode: parseInt(orgId),
        buffer: parseInt(values.buffer),
        areaCode: values.area,
        name: values.name,
        taskTypeId: values.category,
        startTime: moment(values.startTime).format('HH:mm:ss'),
        endTime: moment(values.endTime).format('HH:mm:ss'),
        customerId: values.customer,
      };

      try {
        const result = await httpClient.post('/organization/services/', data);
        if (result.status === 200) {
          createAreaSuccess(result.data);
          refreshDataServices();
        } else {
          createAreaError(result.data);
        }
      } catch (error) {}
      formRef.resetFields();
      setSelectAreaList([]);
      setVisible(false);
    });
  };

  const createAreaSuccess = (message) => {
    notification.success({
      message: message,
    });
  };

  const createAreaError = (message) => {
    notification.error({
      message: message,
    });
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
      setSelectAreaList([]);
    });
    formRef.resetFields();
    setSelectAreaList([]);
    setVisible(false);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          {checkPermissionAction('P3PG1C6', 'P3PG1C6A2') ? (
            <Button01 type="primary" fontsize="sm" btnsize="wd_lg" onClick={showModal}>
              <FormattedMessage id="orgTabServiceBtnAddArea" defaultMessage="Add Area" />
            </Button01>
          ) : null}
          <CollectionCreateForm
            areaList={areaList}
            taskTypes={taskTypes}
            maxBuffer={maxBuffer}
            selectAreaList={selectAreaList}
            onChange={(value) => onChange(value)}
            ref={saveFormRef}
            visible={visible}
            onCancel={() => handleCancel()}
            onCreate={() => handleCreate()}
            intl={intl}
            langValueState={langValueState}
            customerData={customerData}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};
