import React from 'react'
import RequestType from './request-type'

export default function index() {
  return (
    <div>
      <RequestType/>
    </div>
  )
}
