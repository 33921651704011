import React, { useContext, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon, Tag, Button, Modal, Menu } from 'antd';
import Button01 from '../../../components/v2/button_01';
import { OrdersContext } from '../context';
import { getOrdersList, getOrdersListExport, getOrdersById, deleteById, editOrder } from '../../../controllers/orders-controller/api';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';

import OrderCreate from '../../../components/order-component/add/index';
import OrderViewEdit from '../../../components/order-component/view-edit/index';
import IconExcel from '../../../components/image/excel.svg';
import ActionOrders from './action-orders';
import OrderSource from './source-orders';
import _ from 'lodash';
import moment from 'moment';
import './css/index.css';
import ModalImportOrder from '../import';
import Button_02 from '../../../components/v2/button_02';
import ButtonActionGroup from '../../../components/action-button';
import { v4 as uuidv4 } from 'uuid';

const dateFormat = 'ddd, MMM DD YYYY';
const dateFormatChange = 'YYYY-MM-DD';
const today = new Date();

const OrderList = () => {
  const { state, setState, fnc } = useContext(OrdersContext);
  const {
    dataSource,
    toggle,
    mainTrigger,
    fliterByColumn,
    fieldChange,
    fieldSort,
    orderSort,
    paginationPage,
    paginationSize,
    itemTypeSelect,
    customerSelect,
    destinationSelect,
    sourceSelect,
    dateSelect,
    timeSelect,
    sizeSelect,
    openImport,
    total,
    statusSelect,
  } = state;
  const { setLoading, setTotal, setDataSource, setSummaryData, setMainTrigger, setOpenImport, setStatusListData } = setState;
  const { handleToggle } = fnc;
  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColOrderNo`, defaultMessage: 'Order No.' }),
      dataIndex: 'orderNo',
      key: 'orderNo',
      width: 180,
      fixed: 'left',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColCustomer`, defaultMessage: 'Customer' }),
      dataIndex: 'customer.comtomerName',
      key: 'customer.comtomerName',
      width: 200,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColStatus`, defaultMessage: 'Status' }),
      dataIndex: 'statusText',
      key: 'statusText',
      width: 150,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return (
          <div className="tag-center-div-order">
            <Tag className="tag-center-style-order" color={record.statusColor}>
              {record.statusText}
            </Tag>
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSize`, defaultMessage: 'Size' }),
      dataIndex: 'size.sizeName',
      key: 'size.sizeName',
      width: 130,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColWeight`, defaultMessage: 'Weight (kg)' }),
      dataIndex: 'weightTxt',
      key: 'weightTxt',
      width: 160,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColParcel`, defaultMessage: 'Parcels' }),
      dataIndex: 'parcel',
      key: 'parcel',
      width: 100,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColContact`, defaultMessage: 'Contact' }),
      dataIndex: 'contact.customerContactName',
      key: 'contact.customerContactName',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'contact.customerContactPhone',
      key: 'contact.customerContactPhone',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDestination`, defaultMessage: 'Destination' }),
      dataIndex: 'destination.address',
      key: 'destination.address',
      width: 300,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDeliveryDate`, defaultMessage: 'Delivery Date' }),
      dataIndex: 'deliveryDateTxt',
      key: 'deliveryDateTxt',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDeliveryTime`, defaultMessage: 'Delivery Time' }),
      dataIndex: 'deliveryTimeTxt',
      key: 'deliveryTimeTxt',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSource`, defaultMessage: 'Source' }),
      dataIndex: 'source.address',
      key: 'source.address',
      width: 300,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColReferenceType`, defaultMessage: 'Reference Type' }),
      dataIndex: 'referenceType.refTypeName',
      key: 'referenceType.refTypeName',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColReferenceNo`, defaultMessage: 'Reference No.' }),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColRemark`, defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const setShowColumn = {
    'index': true,
    'orderNo': true,
    'customer.comtomerName': true,
    'statusText': true,
    'size.sizeName': false,
    'weightTxt': false,
    'parcel': true,
    'contact.customerContactName': true,
    'contact.customerContactPhone': false,
    'destination.address': true,
    'deliveryDateTxt': true,
    'deliveryTimeTxt': true,
    'source.address': false,
    'referenceType.refTypeName': false,
    'referenceNo': false,
    'remark': false,
  };

  const setShowColumnArr = [
    'index',
    'orderNo',
    'customer.comtomerName',
    'statusText',
    'parcel',
    'contact.customerContactName',
    'destination.address',
    'deliveryDateTxt',
    'deliveryTimeTxt',
  ];

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <p onClick={() => handleExport()}>
          <FormattedMessage id="btnExport" defaultMessage="Export" />
        </p>
      </Menu.Item>

      <Menu.Item key="1">
        <p onClick={() => setOpenImport(true)}>
          <FormattedMessage id="ordersButtonImportOrders" defaultMessage="Import Orders" />
        </p>
      </Menu.Item>

      <Menu.Item key="2">
        <p onClick={() => setVisibleCreate(true)}>
          <FormattedMessage id="ordersButtonCreateOrders" defaultMessage="Create Order" />
        </p>
      </Menu.Item>
    </Menu>
  );

  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState();
  const [disabled, setDisabled] = useState(false);
  const [editData, setEditData] = useState();
  const [statusModal, setStatusModal] = useState('view');
  const ignoreColumn = ['size.sizeName', 'weightTxt', 'contact.customerContactPhone', 'source.address', 'remark', 'referenceType.refTypeName', 'referenceNo' ,];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = ['index', 'deliveryDateTxt', 'deliveryTimeTxt', 'statusText', 'referenceNo','parcel'];
  const columnNewSearch = columns.filter((col) => !ignoreSearchColumn.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  console.log("newDataColumns", newDataColumns)

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [triggerModal, setTriggerModal] = useState(false);
  const [scrollTableX, setScrollTableX] = useState(2500);
  const [url, setUrl] = useState();

  useEffect(() => {
    setLoading(true);
    const getApiOrderList = async () => {
      let search = {};
      if (fliterByColumn !== 'all') {
        const result = { [fliterByColumn]: fieldChange };
        search = result;
      } else {
        const filterData = newDataColumns.filter(
          (item) => ['index', 'deliveryDateTxt', 'deliveryTimeTxt', 'statusText','parcel'].indexOf(item.key) === -1
        );
        const result = filterData.reduce((obj, cur) => ({ ...obj, [cur.key]: fieldChange }), {});
        search = result;
      }

      const item_type_id = itemTypeSelect || undefined; 
      const customer_id = _.get(customerSelect, 'customerId') || undefined;
      const customer_address_book_id = _.get(destinationSelect, 'customer_address_book_id') || undefined;
      const company_address_book_id = _.get(sourceSelect, 'company_address_book_id') || undefined;
      const date_picker = dateSelect ? moment(dateSelect).format(dateFormatChange) : undefined;

      const payload = {
        search: search,
        pageNumber: fieldChange || _.size(statusSelect) > 0 ? 1 : paginationPage,
        limit: paginationSize,
        orderBy: fieldSort,
        orderType: orderSort,
        itemTypeList: item_type_id,
        'customer.customerId': customer_id,
        deliveryDate: date_picker,
        deliveryTime: timeSelect,
        'destination.customer_address_book_id': customer_address_book_id,
        'source.company_address_book_id': company_address_book_id,
        sizeList: _.size(sizeSelect) === 0 ? undefined : sizeSelect,
        filterStatus: statusSelect
      };

      // console.log('search:::', payload)

      const response = await getOrdersList(payload);
      setUrl({ url: _.get(response.data, 'deliveryOrderTemplateXlsx'), noti: _.get(response.status, 'message') });
      setDataSource(_.get(response.data, 'list') || []);
      setTotal(_.get(response.data, 'allRows') || []);
      setStatusListData(_.get(response.data, 'statusList') || [])
      setSummaryData([
        _.get(response.data, 'orderCount') || 0,
        _.get(response.data, 'completeCount') || 0,
        _.get(response.data, 'incompleteCount') || 0,
      ]);
      setLoading(false);
      // console.log('getApiOrderList:::', _.get(response.data, 'list'));
    };
    getApiOrderList();
  }, [
    itemTypeSelect,
    customerSelect,
    destinationSelect,
    sourceSelect,
    dateSelect,
    timeSelect,
    mainTrigger,
    fliterByColumn,
    fieldChange,
    fieldSort,
    orderSort,
    paginationPage,
    paginationSize,
    triggerModal,
    sizeSelect,
    statusSelect
  ]);

  const handleExport = async () => {
    setDisabled(true);
    let search = {};
    if (fliterByColumn !== 'all') {
      const result = { [fliterByColumn]: fieldChange };
      search = result;
    } else {
      const filterData = newDataColumns.filter(
        (item) => ['index', 'deliveryDateTxt', 'deliveryTimeTxt', 'statusText'].indexOf(item.key) === -1
      );
      const result = filterData.reduce((obj, cur) => ({ ...obj, [cur.key]: fieldChange }), {});
      search = result;
    }

    const item_type_id = itemTypeSelect || undefined;
    const customer_id = _.get(customerSelect, 'customerId') || undefined;
    const customer_address_book_id = _.get(destinationSelect, 'customer_address_book_id') || undefined;
    const company_address_book_id = _.get(sourceSelect, 'company_address_book_id') || undefined;
    const date_picker = dateSelect ? moment(dateSelect).format(dateFormatChange) : undefined;

    const payload = {
      search: search,
      pageNumber: fieldChange ? 1 : paginationPage,
      limit: total,
      orderBy: fieldSort,
      orderType: orderSort,
      itemTypeList: item_type_id,
      'customer.customerId': customer_id,
      deliveryDate: date_picker,
      deliveryTime: timeSelect,
      'destination.customer_address_book_id': customer_address_book_id,
      'source.company_address_book_id': company_address_book_id,
      sizeList: _.size(sizeSelect) === 0 ? undefined : sizeSelect,
      viewFormat: [
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColOrderNo', defaultMessage: 'Order No.' }),
          sequence: 1,
          colCode: 'orderNo',
        },
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColCustomer', defaultMessage: 'Customer' }),
          sequence: 2,
          colCode: 'customer.comtomerName',
        },
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColStatus', defaultMessage: 'Status' }),
          sequence: 3,
          colCode: 'statusText',
        },
        {
          colName: intl.formatMessage({ id: `transportationOrdersColSize`, defaultMessage: 'Size' }),
          sequence: 4,
          colCode: 'size.sizeName',
        },
        {
          colName: intl.formatMessage({ id: `transportationOrdersColWeight`, defaultMessage: 'Weight (kg)' }),
          sequence: 5,
          colCode: 'weightTxt',
        },
        {
          colName: intl.formatMessage({ id: `transportationOrdersColParcel`, defaultMessage: 'Parcels' }),
          sequence: 6,
          colCode: 'parcel',
        },
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColContact', defaultMessage: 'Contact' }),
          sequence: 7,
          colCode: 'contact.customerContactName',
        },
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColPhone', defaultMessage: 'Phone' }),
          sequence: 8,
          colCode: 'contact.customerContactPhone',
        },
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColDestination', defaultMessage: 'Destination' }),
          sequence: 9,
          colCode: 'destination.address',
        },
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColDeliveryDate', defaultMessage: 'Delivery Date' }),
          sequence: 10,
          colCode: 'deliveryDateTxt',
        },
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColDeliveryTime', defaultMessage: 'Delivery Time' }),
          sequence: 11,
          colCode: 'deliveryTimeTxt',
        },
        {
          colName: intl.formatMessage({ id: 'transportationOrdersColSource', defaultMessage: 'Source' }),
          sequence: 12,
          colCode: 'source.address',
        },
        {
          colName: intl.formatMessage({ id: `transportationOrdersColReferenceType`, defaultMessage: 'Reference Type' }),
          sequence: 13,
          colCode: 'referenceType.refTypeName',
        },
        {
          colName: intl.formatMessage({ id: `transportationOrdersColReferenceNo`, defaultMessage: 'Reference No.' }),
          sequence: 14,
          colCode: 'referenceNo',
        },
        { colName: intl.formatMessage({ id: 'transportationOrdersColRemark', defaultMessage: 'Remark' }), sequence: 14, colCode: 'remark' },
      ],
    };

    const response = await getOrdersListExport(payload);
    if (response.status === 200) {
      console.log('urlexport', response);
      window.open(_.get(response, 'data.data.data.url'));
      successNotification(response.data.status.message);
      setDisabled(false);
    } else {
      errorNotification(response.data.status.message);
      setDisabled(false);
    }
  };

  const handleDownLoadTemplate = () => {
    if (_.get(url, 'url')) {
      window.open(_.get(url, 'url'));
      successNotification(_.get(url, 'noti'));
    } else {
      errorNotification(_.get(url, 'noti'));
    }
  };

  const iconExcel = () => <img className="icon-excel" src={IconExcel} />;

  const hanldeClickModal = async (data, status) => {
    const response = await getOrdersById(_.get(data, 'orderId'));
    const dataApi = _.get(response, 'data.data')
    console.log('dataApi',dataApi);
    const newData = {
      ...dataApi,
      sourceDetail: _.get(dataApi, 'sourceDetail.company_address_book_id') ?
        {
          ...dataApi.sourceDetail,
          company_temp: false
        } : {
          ...dataApi.sourceDetail,
          company_address_book_id: uuidv4(),
          company_temp: true
        },
      destinationDetail: _.get(dataApi, 'destinationDetail.customer_address_book_id') ? {
        ...dataApi.destinationDetail,
        customer_temp: false
      } : {
        ...dataApi.destinationDetail,
        customer_address_book_id: uuidv4(),
        customer_temp: true
      },
      customerDetail: _.get(dataApi, 'customerDetail.customerId') ? {
        ...dataApi.customerDetail,
        customer_temp: false
      } : {
        ...dataApi.customerDetail,
        customerId: uuidv4(),
        customer_temp: true
      },
    };
    setEditData(newData);
    setStatusModal(status);
    setVisibleEdit(true);
  };

  const handleDelete = (data, status) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: `monitorCreateTaskModalConfirmDeleteOrder`, defaultMessage: 'Are you sure to delete order' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        console.log('DeleteOrder',_.get(data, 'orderId'));
        const response = await deleteById(_.get(data, 'orderId'));
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setMainTrigger((current) => !current);
          setVisibleEdit(false)
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  const handleCancel = (data, status) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: `monitorCreateTaskModalConfirmCancelOrder`, defaultMessage: 'Are you sure to cancel order' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const body = {
          statusDetail: {
            sequence: 2,
            statusCode: 'canceled',
            statusColor: '#9A9999',
            statusId: '62722dedc2449a45a412130e',
            statusName: 'Canceled',
            statusText: { TH: 'ยกเลิก', EN: 'Canceled' },
          },
        };
        const response = await editOrder(body, _.get(data, 'orderId'), status);
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setMainTrigger((current) => !current);
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  const handleConfirm = (data, status) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: `monitorCreateTaskModalConfirmConfirmOrder`, defaultMessage: 'Are you sure to comfirm order' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const body = {
          statusDetail: {
            sequence: 1,
            statusCode: 'confirmed',
            statusColor: '#21723E',
            statusId: '62722d9ac2449a45a412130d',
            statusName: 'Confirmed',
            statusText: { TH: 'ตกลง', EN: 'Confirmed' },
          },
        };
        const response = await editOrder(body, _.get(data, 'orderId'), status);
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setMainTrigger((current) => !current);
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  return (
    <div>
      <Card
        className="custom-card-orders"
        title={
          <>
            <Icon
              type={toggle ? 'menu-unfold' : 'menu-fold'}
              style={{ cursor: 'pointer', color: '#e73845', marginRight: 15 }}
              onClick={handleToggle}
            />
            {intl.formatMessage({ id: `transportationOrderTitle`, defaultMessage: 'Orders' })}
          </>
        }
        extra={
          <div style={{ display: 'flex' }}>
            <PageSettings.Consumer>
              {({ checkPermissionAction }) => (
                <div>
                  {checkPermissionAction('P38PG1C1', 'P38PG1C1A6') ? (
                    <ButtonActionGroup menu={menu} />
                  ) : // <Button className="button-link-export" type="link" onClick={handleExport} disabled={disabled}>
                    //   <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
                    // </Button>
                    null}
                </div>
              )}
            </PageSettings.Consumer>
            {/* <PageSettings.Consumer>
              {({ checkPermissionAction }) => (
                <div>
                  {checkPermissionAction('P38PG1C1', 'P38PG1C1A2') ? (
                    <Button_02
                      // type="primary"
                      btnsize="wd_at"
                      fontSize="sm"
                      onClick={() => setOpenImport(true)}
                    >
                      <FormattedMessage id="ordersButtonImportOrders" defaultMessage="Import Orders" />
                    </Button_02>
                  ) : null}
                </div>
              )}
            </PageSettings.Consumer>
            <PageSettings.Consumer>
              {({ checkPermissionAction }) => (
                <div>
                  {checkPermissionAction('P38PG1C1', 'P38PG1C1A2') ? (
                    <Button01
                      type="primary"
                      btnsize="wd_at"
                      fontSize="sm"
                      style={{ margin: 'unset' }}
                      onClick={() => setVisibleCreate(true)}
                    >
                      <FormattedMessage id="ordersButtonCreateOrders" defaultMessage="Create Order" />
                    </Button01>
                  ) : null}
                </div>
              )}
            </PageSettings.Consumer> */}
          </div>
        }
      >
        <div className="order-containerlist-pd">
          <div>
            <ActionOrders
              columns={columns}
              setShowColumn={setShowColumn}
              setShowColumnArr={setShowColumnArr}
              columnNewSearch={columnNewSearch}
              newDataColumns={newDataColumns}
              listArrayColumns={listArrayColumns}
              defaultShowColumn={defaultShowColumn}
              newColumns={newColumns}
              setNewDataColumns={setNewDataColumns}
              setListArrayColumns={setListArrayColumns}
              setDefaultShowColumn={setDefaultShowColumn}
              setScrollTableX={setScrollTableX}
            />
          </div>
          <div>
            <OrderSource
              newDataColumns={newDataColumns}
              hanldeClickModal={hanldeClickModal}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              scrollTableX={scrollTableX}
              handleConfirm={handleConfirm}
            />
          </div>
        </div>
      </Card>

      <OrderCreate visible={visibleCreate} setVisible={setVisibleCreate} setTriggerModal={setTriggerModal} />

      <OrderViewEdit
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        setTriggerModal={setTriggerModal}
        editData={editData}
        statusModal={statusModal}
        setStatusModal={setStatusModal}
        handleDelete={handleDelete}
      />

      <ModalImportOrder open={openImport} setOpen={setOpenImport} handleDownLoadTemplate={handleDownLoadTemplate} />
    </div>
  );
};

export default OrderList;
