import React, { useState, useRef } from 'react';
import Button01 from '../../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Popover, Button } from 'antd';
import _ from 'lodash';

const PurchaseCreateDelivery = (props) => {
  const { columns, setVisibleAddDeli, deliveryList, selectItem, handleDeleteDelivery, handleEditDelivery } = props;
  const ref = useRef();
  const intl = useIntl();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const handleDelete = (value, record) => {
    handleDeleteDelivery(value, record);
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const handleEdit = (value) => {
    handleEditDelivery(value);
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.index, record)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div style={{ height: '540px', overflowY: 'auto' }}>
      <div style={{ textAlign: 'end', marginBottom: '16px', padding: '0 24px' }}>
        <Button01
          key="submit"
          type="primary"
          btnsize={_.sumBy(selectItem, 'countTemp') > 0 ? '30%' : '30%'}
          onClick={() => setVisibleAddDeli(true)}
        >
          <FormattedMessage id="btnAdd" defaultMessage="Add" />
          {_.sumBy(selectItem, 'countTemp') > 0
            ? `(${intl.formatMessage({ id: `purchesModalTitleDeliveryPendingItem`, defaultMessage: 'Pending items' })}:${_.round(_.sumBy(selectItem, 'countTemp'),2)})`
            : `(${intl.formatMessage({ id: `purchesModalTitleDeliveryPendingItem`, defaultMessage: 'Pending items' })}:0)`}
        </Button01>
      </div>
      <div style={{ padding: '0 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          columns={columns}
          dataSource={deliveryList}
          pagination={false}
          scroll={{ x: _.sumBy(columns, 'width') }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      </div>
    </div>
  );
};

export default PurchaseCreateDelivery;
