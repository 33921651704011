import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Avatar, Icon, Modal, Table, Tabs, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { CreateTaskContext } from './create-task-context';
import _ from 'lodash';
import Button02 from '../../../v2/button_02';
import Button01 from '../../../v2/button_01';
import './css/index.css';

const { TabPane } = Tabs;

export default ({ visibleModal, setVisibleModal }) => {
  const { CreateState, CreateSetState, CreateFNC } = useContext(CreateTaskContext);
  const {
    filterAvailable,
    filterNotAvailable,
    pageAssigneeAvailable,
    pageAssigneeNotAvailable,
    searchAssignee,
    selectTab,
    memberAvailable,
    memberNotAvailable,
    memberAssignee,
    dataMemberAssignee,
    memberAvailableArrray,
    memberNotAvailableArrray,
    loadingAvailable,
    loadingNotAvailable,
    searchUnassignee,
  } = CreateState;
  const {
    setFilterAvailable,
    setFilterNotAvailable,
    setPageAssigneeAvailable,
    setPageSizeAssigneeAvailable,
    setPageAssigneeNotAvailable,
    setPageSizeAssigneeNotAvailable,
    setSearchUnassignee,
    setSelectTab,
    setOrderSortAvailable,
    setFieldSortAvailable,
    setFieldSortNotAvailable,
    setOrderSortNotAvailable,
    setMemberAssignee,
    setDataMemberAssignee,
  } = CreateSetState;

  const intl = useIntl();

  const availableTotalInitail = _.get(memberAvailable, 'count_available') || 0;
  const availableNotTotalInitail = _.get(memberNotAvailable, 'count_notavailable') || 0;

  const [arrayAvailable, setArrayAvailable] = useState([]);
  const [arrayNotAvailable, setArrayNotAvailable] = useState([]);

  const [memberAssigneeShow, setMemberAssigneeShow] = useState([]);
  const [loadingView, setLoadingView] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (memberAvailableArrray) {
      const memComID = dataMemberAssignee.map((e) => {
        return e.memComId;
      });

      const filterdata =
        arrayAvailable !== undefined &&
        memberAvailableArrray?.map((el) => {
          return {
            distance: el.distance,
            index: el.index,
            isAssignee: el.isAssignee,
            isWaringReset: el.isWaringReset,
            memComId: el.memComId,
            memOrgId: el.memOrgId,
            orgId: el.orgId,
            name: el.name,
            phone: el.phone,
            profile: el.profile,
            status: el.status,
            subtasks: el.subtasks,
            color: memComID.includes(el.memComId) ? '#BCC5D3' : '#1D3557          ',
            statusButton: memComID.includes(el.memComId) ? true : false,
          };
        });
      setArrayAvailable(filterdata);
    }
  }, [memberAvailableArrray, dataMemberAssignee, pageAssigneeAvailable]);

  useEffect(() => {
    if (memberNotAvailableArrray) {
      const memComID = dataMemberAssignee.map((e) => {
        return e.memComId;
      });

      const filterdata =
        arrayNotAvailable !== undefined &&
        memberNotAvailableArrray?.map((el) => {
          return {
            distance: el.distance,
            index: el.index,
            isAssignee: el.isAssignee,
            isWaringReset: el.isWaringReset,
            memComId: el.memComId,
            memOrgId: el.memOrgId,
            orgId: el.orgId,
            name: el.name,
            phone: el.phone,
            profile: el.profile,
            status: el.status,
            subtasks: el.subtasks,
            color: memComID.includes(el.memComId) ? '#BCC5D3' : '#1D3557',
            statusButton: memComID.includes(el.memComId) ? true : false,
          };
        });
      setArrayNotAvailable(filterdata);
    }
  }, [memberNotAvailableArrray, dataMemberAssignee, pageAssigneeNotAvailable]);

  useEffect(() => {
    setLoadingView(true);
    if (searchValue !== '') {
      const filterDataMember = _.filter(dataMemberAssignee, (ele) => {
        return ele.name.toLowerCase().includes(searchValue.toLowerCase()) || ele.phone.toLowerCase().includes(searchValue.toLowerCase());
      });
      setMemberAssigneeShow(filterDataMember);
      setLoadingView(false);
    } else {
      setMemberAssigneeShow(dataMemberAssignee);
      setLoadingView(false);
    }
  }, [searchValue]);

  const handleTab = (key) => {
    setSelectTab(key);
  };

  const handleAssign = (record, index) => {
    setDataMemberAssignee((elements) => [...elements, record]);
  };

  const handelRemove = (index, record) => {
    const MemComID = record.memComId;
    const setDataAssignee = dataMemberAssignee.filter((col) => !MemComID.includes(col.memComId));
    setDataMemberAssignee(setDataAssignee);
  };

  const columnsAssignee = [
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColIndex`, defaultMessage: '#' }),
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 190,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'phone',
      key: 'phone',
      width: 130,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColStatus`, defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColDistance`, defaultMessage: 'Distance (km.)' }),
      dataIndex: 'distance',
      key: 'distance',
      width: 130,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColSubtasks`, defaultMessage: 'Subtasks' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return <img key={i} className="table-status-img" src={item.icon} loading="lazy" />;
            } else {
              return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} loading="lazy" />;
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      width: 145,
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handelRemove(index, record)}>
              <FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnAssigneeBtnRemove" defaultMessage="Remove" />
            </Button02>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColIndex`, defaultMessage: '#' }),
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.index}</div>,
    },
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => (
        <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>
          <Avatar src={record.profile} />
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 190,
      sorter: true,
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.name}</div>,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'phone',
      key: 'phone',
      width: 130,
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.phone}</div>,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColStatus`, defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.status}</div>,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColDistance`, defaultMessage: 'Distance (km.)' }),
      dataIndex: 'distance',
      key: 'distance',
      width: 130,
      align: 'center',
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.distance}</div>,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColSubtasks`, defaultMessage: 'Subtasks' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) => (
        <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>
          {record.subtasks.map((item, i) => {
            const iconExcel = () => {
              if (item.done == true) {
                return <img key={i} className="table-status-img" src={item.icon} loading="lazy" />;
              } else {
                return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} loading="lazy" />;
              }
            };

            return (
              <div className="table-status">
                <div key={i} className="table-status-key">
                  <Icon className="table-status-icon" component={iconExcel} />
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      dataIndex: 'options',
      key: 'options',
      width: 145,
      render: (text, record, index) => (
        <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>
          <Button02 style={{margin : '0px 0px 0px 10px'}}
            className="custom-disable-button-assign"
            btnsize="wd_df"
            disabled={record.statusButton}
            onClick={() => handleAssign(record, index)}
          >
            <FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnAvailableBtnAssign" defaultMessage="Assign" />
          </Button02>
        </div>
      ),
    },
  ];

  const handleSearchAssignee = (val) => {
    setSearchValue(val);
  };

  const handleSearchAssigneeStatus = (val) => {
    setSearchUnassignee(val);
  };

  const handleChangeAvailable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSortAvailable('desc');
      } else {
        setOrderSortAvailable('asc');
      }
    } else {
      setOrderSortAvailable('');
    }
    setFieldSortAvailable(field);
  };

  const handleChangeNotAvailable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSortNotAvailable('desc');
      } else {
        setOrderSortNotAvailable('asc');
      }
    } else {
      setOrderSortNotAvailable('');
    }
    setFieldSortNotAvailable(field);
  };

  const handleCancel = () => {
    CreateSetState.setVisibleAssignee(false);
    setPageAssigneeAvailable(1);
    setPageSizeAssigneeAvailable(10);
    setPageAssigneeNotAvailable(1);
    setPageSizeAssigneeNotAvailable(10);
    setSearchUnassignee('');
    setSearchValue('');
    setOrderSortNotAvailable('');
    setFieldSortNotAvailable('');
    setOrderSortAvailable('');
    setFieldSortAvailable('');
  };

  const handlePaginationAvailable = (val) => {
    setPageAssigneeAvailable(val);
  };

  const handleSizeChangeAvailable = (current, size) => {
    setPageAssigneeAvailable(current);
    setPageSizeAssigneeAvailable(size);
  };

  const handlePaginationNotAvailable = (val) => {
    setPageAssigneeNotAvailable(val);
  };

  const handleSizeChangeNotAvailable = (current, size) => {
    setPageAssigneeNotAvailable(current);
    setPageSizeAssigneeNotAvailable(size);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const operations = (
    <div>
      <Input
        prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder={intl.formatMessage({ id: 'monitorCreateTaskAddAssigneeSearch', defaultMessage: 'Search…' })}
        style={{ width: '210px' }}
        onChange={(event) => handleSearchAssigneeStatus(event.target.value)}
        value={searchUnassignee}
      />
    </div>
  );
  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'monitorCreateTaskAddAssigneeTxtAddAssignee', defaultMessage: 'Add Assignees' })}
        visible={CreateState.visibleAssignee}
        onCancel={handleCancel}
        centered={true}
        width={1150}
        footer={[
          <Button01 btnsize="wd_df" type="primary" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button01>,
        ]}
        className="custom-body-model-add-assignee"
      >
        <Row>
          <Col span={24} className="assignee-resource-col-left" style={{ height: 570 }}>
            <div className="assignee-resource-col">
              <Row>
                <Col className="assignee-resource-col-col-left">
                  <div className="header-assignee-layout">
                    <p className="assignee-label-custom">
                      <FormattedMessage id={`monitorTaskAddAssigneeTxtAssignee`} defaultMessage={`Assignee`} /> (
                      {searchValue ? _.size(memberAssigneeShow) : _.size(dataMemberAssignee)})
                    </p>
                    <Input
                      prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={intl.formatMessage({ id: 'monitorCreateTaskAddAssigneeSearch', defaultMessage: 'Search…' })}
                      style={{ width: '210px' }}
                      onChange={(event) => handleSearchAssignee(event.target.value)}
                      value={searchValue}
                    />
                  </div>
                  <div className="body-assignee-layout">
                    <Table
                      className="assignee-resource-table"
                      scroll={{ y: 160 }}
                      dataSource={searchValue ? memberAssigneeShow : dataMemberAssignee}
                      columns={columnsAssignee}
                      pagination={false}
                      loading={loadingView}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="assignee-resource-col-col-right">
                  <Tabs
                    animated={false}
                    className="custom-tab-assignee"
                    defaultActiveKey="1"
                    onTabClick={(key) => handleTab(key)}
                    tabBarExtraContent={operations}
                  >
                    <TabPane
                      className="custom-tabpane-assignee-first"
                      tab={`${intl.formatMessage({
                        id: `monitorTaskAddAssigneeTabpaneAvalible`,
                        defaultMessage: `Available`,
                      })} (${availableTotalInitail})`}
                      key="1"
                    >
                      <div className="body-assignee-layout">
                        <Table
                          className="assignee-resource-table"
                          scroll={{ y: 160 }}
                          dataSource={arrayAvailable}
                          columns={columns}
                          loading={loadingAvailable}
                          onChange={handleChangeAvailable}
                          pagination={{
                            total: availableTotalInitail,
                            current: pageAssigneeAvailable,
                            pageSizeOptions: ['10', '20', '30', '40', '50'],
                            showSizeChanger: true,
                            locale: { items_per_page: '' },
                            showTotal: showTotal,
                            onChange: handlePaginationAvailable,
                            onShowSizeChange: handleSizeChangeAvailable,
                          }}
                        />
                        {arrayAvailable.length > 0 ? (
                          <div className="total-items-member">
                            <span style={{ fontSize: '13px' }}>
                              <FormattedMessage id="lblTotal" defaultMessage="Total" />
                              {` ${availableTotalInitail || 0} `}
                              <FormattedMessage id="lblitems" defaultMessage="items" />
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </TabPane>
                    <TabPane
                      tab={`${intl.formatMessage({
                        id: `monitorTaskAddAssigneeTabpaneNotAvailable`,
                        defaultMessage: `Not Available`,
                      })} (${availableNotTotalInitail})`}
                      key="2"
                    >
                      <div className="body-assignee-layout">
                        <Table
                          className="assignee-resource-table"
                          scroll={{ y: 160 }}
                          dataSource={arrayNotAvailable}
                          columns={columns}
                          loading={loadingNotAvailable}
                          onChange={handleChangeNotAvailable}
                          pagination={{
                            total: availableNotTotalInitail,
                            current: pageAssigneeNotAvailable,
                            pageSizeOptions: ['10', '20', '30', '40', '50'],
                            showSizeChanger: true,
                            locale: { items_per_page: '' },
                            showTotal: showTotal,
                            onChange: handlePaginationNotAvailable,
                            onShowSizeChange: handleSizeChangeNotAvailable,
                          }}
                        />
                        {arrayNotAvailable.length > 0 ? (
                          <div className="total-items-member">
                            <span style={{ fontSize: '13px' }}>
                              <FormattedMessage id="lblTotal" defaultMessage="Total" />
                              {` ${availableNotTotalInitail || 0} `}
                              <FormattedMessage id="lblitems" defaultMessage="items" />
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
