import React, { useState, useEffect } from 'react';
import { AutoComplete, Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import LabeRequireForm from '../label-required-form';
import { getGroupSaleOrderLoadMore } from '../../controllers/orders-controller/api';

const { Option } = Select;

const LoadmoreSaleOrderV2 = (props) => {
  const intl = useIntl();
  const { form, data, setData, required, initialId, initialName, visible, title, placeholder, id } = props;
  const getFieldDecorator = form ? form.getFieldDecorator : null;

  console.log('ssssssssid', id)

  const [dataInfo, setDataInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [total, setTotal] = useState(0)
  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    setData(undefined, 'saleOrderSelect')
    setPage(0);
    setSearch('');
    setIsLoadMore(true)
    setDataInfo([])
    setLoading(false)
    setTotal(0)
    setTrigger(!trigger)
  }, [id])

  useEffect(() => {
    console.log('isLoadMore', _.size(dataInfo), total, page, search)
    if(_.size(dataInfo) >= total) {
      setIsLoadMore(false)
    } else {
      setIsLoadMore(true)
    }
  }, [dataInfo])

  useEffect(() => {
    const getData = async () => {
      const pageStart = page * pageSize;
      const pageEnd = page * pageSize + 10;

      const res = await getGroupSaleOrderLoadMore(id, pageStart, pageEnd, search);
      const data = _.get(res, 'data.deiliverySaleOrderNoList')
      setTotal(_.get(res, 'data.totalItem'))

      const list = search ? data : _.concat(dataInfo, data);

      setDataInfo(list);
      setLoading(false);
    };
    if (isLoadMore) {
      getData();
    }
  }, [page, search, trigger]);

  const handlePopupScroll = (e) => {
    let target = e.target;
    if (!loading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setLoading(true);
      setPage(page + 1);
    }
  };

  const handleChange = (value) => {
    console.log('handleChange', value);
    if (!value) {
      setData(undefined, 'saleOrderSelect')
      setPage(0);
      setSearch();
      setIsLoadMore(true)
      setDataInfo([])
    } else {
      setData(value, 'saleOrderSelect')
    }
  };

  const handleSearchChange = (value) => {
    console.log('handleCustomerSearchChange', value);
    setPage(0);
    setSearch(value);
    setIsLoadMore(true)
  };

  return (
    <Form.Item label={<LabeRequireForm text={title} req={required} />}>
      {form ? (
        getFieldDecorator('saleOrder', {
          initialValue: initialId,
          rules: [
            {
              required: required,
              message: intl.formatMessage({
                id: 'saleOrderValidate',
                defaultMessage: 'Please Select Sale Order',
              }),
            },
          ],
        })()
        // <AutoComplete onSelect={handleSelect} onPopupScroll={handleScroll} onSearch={handleSearch} placeholder={placeholder}>
        //   {_.map(list, (item) => (
        //     <Option key={_.get(item, 'saleOrderId')} value={_.get(item, 'saleOrderId')} data={item}>
        //       {_.get(item, 'saleOrderNo')}
        //     </Option>
        //   ))}
        // </AutoComplete>
      ) : (
        <Select
          className="select-list-customer-monitor"
          placeholder={placeholder}
          defaultActiveFirstOption={false}
          allowClear
          showSearch
          onPopupScroll={handlePopupScroll}
          onChange={handleChange}
          onSearch={handleSearchChange}
          loading={loading}
          filterOption={false}
          value={_.get(data, 'saleOrderSelect')}
        >
          {dataInfo &&
            dataInfo.map((item) => (
              <Option key={item} value={item} data={item}>
                {item}
              </Option>
            ))}
        </Select>
      )}
    </Form.Item>
  );
};

LoadmoreSaleOrderV2.defaultProps = {
  required: true,
};

export default LoadmoreSaleOrderV2;
