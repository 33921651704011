import React, { useEffect } from 'react'
import { Form, Input, Modal } from 'antd'
import styled from 'styled-components';
// import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import httpClient from '../axiosClient';
import {
  successNotification,
  errorNotification,
  warningNotification,
} from '../v2/notification';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

const AddContact = (props) => {
  const { form, visible, setVisible, customerId, setTrigger, setContactID, setTriggerAPI, contactData } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const comCode = localStorage.getItem('comCode');
  const intl = useIntl();
  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };


  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const body = {
        customerId: customerId,
        customerContactName: values.name,
        customerContactEmail: values.email,
        customerContactPhone: values.phone,
        position: values.position,
        remark: values.remark
      }

      try {
        const response = await httpClient.put(`/v3/business-partner/manager/company/${comCode}/customer-contacts/${_.get(contactData, 'customerContactId')}`, body);
        if (response.status === 200) {
          setVisible(false)
          successNotification(response.data.status.message);
          resetFields();
          if (setTriggerAPI) {
            setTriggerAPI(current => !current)
          }
          setTimeout(() => {
            if (setTrigger) {
              setTrigger(customerId);
            }
            if (setContactID) {
              setContactID(response.data.data.customerContactId)
            }
          }, 500);

        } else {
          setVisible(false)
          resetFields();
        }

      } catch (error) {
        setVisible(false)
        errorNotification(error.response.data.status.message);
        resetFields();
      }



    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields();
  }

  return (
    <div>
      <Modal
        visible={visible}
        centered={true}
        onCancel={handleCancel}
        title='Contact'
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}}
            key="back"
            btnsize="wd_df"
            onClick={() => handleCancel()}
          >
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01
            key="submit"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleCreate()}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form>
          <Form.Item>
            {/* <span><LabeRequire text={<FormattedMessage id="monitorCreateTaskTxtFromLocation" defaultMessage="Name" />} req={true} /></span> */}
            <span className='text-style'><FormattedMessage id="ModalContactHeadTextName" defaultMessage="Name" /><LabeRequire req={true} /></span>
            {getFieldDecorator("name", {
              initialValue: _.get(contactData, 'customerContactName'),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                  // message: intl.formatMessage({ id: 'monitorCreateTaskValidationFromLocation', defaultMessage: 'Please select Location' })

                }
              ]
            })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off" ></Input>)}
          </Form.Item>


          <Form.Item>
            <span className='text-style'><FormattedMessage id="ModalContactHeadTextPostion" defaultMessage="Position" /><LabeRequire req={true} /></span>
            {getFieldDecorator("position", {
              initialValue: _.get(contactData, 'position'),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'ModalContactValidatePosition', defaultMessage: 'Please Enter Position' })
                }
              ]
            })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderPosition', defaultMessage: 'Enter Position' })} autoComplete="off" ></Input>)}
          </Form.Item>


          <Form.Item>
            <span className='text-style'><FormattedMessage id="ModalContactHeadTextEmail" defaultMessage="Email" /></span>
            {getFieldDecorator("email", {
              initialValue: _.get(contactData, 'customerContactEmail'),
              rules: [
                {
                  type: 'email',
                  pattern: new RegExp("^[0-9A-Za-z@._]*$"),
                  message: intl.formatMessage({ id: 'ModalContactValidateEmail', defaultMessage: 'Please input email format' })
                },
              ]
            })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderEmail', defaultMessage: 'Enter Email' })} autoComplete="off" ></Input>)}
          </Form.Item>

          <Form.Item>
            <span className='text-style'><FormattedMessage id="ModalContactHeadTextPhone" defaultMessage="Phone" /><LabeRequire req={true} /></span>
            {getFieldDecorator("phone", {
              initialValue: _.get(contactData, 'customerContactPhone'),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'ModalContactValidatePhone', defaultMessage: 'Please Enter Phone' })
                }
              ]
            })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderPhone', defaultMessage: 'Enter Phone' })} autoComplete="off" ></Input>)}
          </Form.Item>

          <Form.Item>
            <span className='text-style'><FormattedMessage id="ModalContactHeadTextRemark" defaultMessage="Remark" /></span>
            {getFieldDecorator("remark", {
              initialValue: _.get(contactData, 'remark'),
            })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderRemark', defaultMessage: 'Enter Remark' })} autoComplete="off" ></Input>)}
          </Form.Item>

        </Form>
      </Modal>
    </div>
  )
}
const Contact = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {

  }
})(AddContact);

export default Contact;