import React from 'react'
import './css/index.css'
import styled from 'styled-components';
import { Form, Input, Row, Col } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

const skillDetail = (props) => {
  const { intl, form, statusEdit, statusAdd, skillDetail } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;


  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  return (
    <Form>
      <div style={{ padding: '24px' }}>
        <Row>
          {statusAdd === true ? (
            <Col span={8}>
              <span className='text-style'><FormattedMessage id="skillDetailSkillGroupNameText" defaultMessage="Skill Group Name" /><LabeRequire req={true} /></span>
              <Form.Item style={{ marginTop: '10px' }}>
                {getFieldDecorator("skillNameAdd", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'skillDetailValidateSkillGroupName', defaultMessage: 'Please Enter Name' })

                    }
                  ]
                })(<Input
                  placeholder={intl.formatMessage({ id: 'skillDetailPlaceholderGroupName', defaultMessage: 'Enter Name' })}
                  autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
          )
            : (
              <Col span={8}>
                <span className='text-style'><FormattedMessage id="skillDetailSkillGroupNameText" defaultMessage="Skill Group Name" /><LabeRequire req={true} /></span>
                <Form.Item style={{ marginTop: '10px' }}>
                  {getFieldDecorator("skillName", {
                    initialValue: _.get(skillDetail,'name'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'skillDetailValidateSkillGroupName', defaultMessage: 'Please Enter Name' })

                      }
                    ]
                  })(<Input
                    // style={statusEdit === true ? { border: 'unset', padding: 'unset' } : null}
                    placeholder={intl.formatMessage({ id: 'skillDetailPlaceholderGroupName', defaultMessage: 'Enter Name' })} autoComplete="off"
                    readOnly={false}></Input>)}
                </Form.Item>
              </Col>
            )}

        </Row>
      </div>
    </Form>
  )
}

const skill = Form.create({
  name: "modal_form",

})(skillDetail);

export default skill;
