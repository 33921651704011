import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  getItemQuotation,
  createQuotation,
  getDefaultStarted,
  updateStatus,
  getReason,
  getQuotationById,
  countPrint,
} from '../../controllers/quotation/quotation-api.js';
import { cancelQutation } from '../../components/view-quotation/modal-cancel-quotation.js';
import { deleteQuotation } from '../../components/view-quotation/modal-delete-quotation';
import { PageSettings } from '../../config/page-settings';
import PriceReportV1 from '../../components/price-report/price-report-v1.jsx';
import ActionReportV2 from '../../components/action-report/action-report-v2.jsx';
import QuotationReportTable from './quotation-report-table.jsx';
import CreateQuotation from '../../components/create-quotation/index';
import EditQuotation from '../../components/edit-quotation/index';
import ViewCustomer from '../../components/view-customer/index';
import getCustomerApi from '../../controllers/getCustomer/get-customer-api.js';
import { successNotification, errorNotification, warningNotification } from '../../components/v2/notification';
import { Card, Icon, Tag, Popover, Button, Modal, Select } from 'antd';
import httpClient from '../../components/axiosClient';
import moment from 'moment';
import _, { debounce } from 'lodash';
import styleIndex from './css/index.css';
import ViewQuotation from '../../components/view-quotation/index.js';
import ApproveQuotation from '../../components/view-quotation/modal-approve-quotation.js';
import ModalReject from '../../components/view-quotation/modal-reject-quotation.js';
import { ButtonApprove, ButtonReject, ButtonView, ButtonEdit, ButtonCancel, ButtonDelete } from '../../components/action-table/index';
import PrintTest from '../../components/print-report/print-quotation';
import NumberFormat from 'react-number-format';
import ReactToPrint from 'react-to-print';
import Button01 from '../../components/v2/button_01.js';

const { Option } = Select;
const today = new Date();
const dateFormat = 'ddd, MMM DD YYYY';
const { confirm } = Modal;

const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

const Quotation = () => {
  const [visiblePopover, setVisiblePopover] = useState(false);
  const intl = useIntl();

  const langConFirmCancel = {
    title: intl.formatMessage({ id: 'quotationModalCancelConfirm', defaultMessage: 'Are you sure to cancel Quotation?' }),
    ok: intl.formatMessage({ id: 'quotationModalCancelBtnConfirm', defaultMessage: 'Confirm' }),
    cancel: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
  };

  const langConFirmDelete = {
    title: intl.formatMessage({ id: 'quotationModalDeleteConfirm', defaultMessage: 'Are you sure to delete Quotation?' }),
    ok: intl.formatMessage({ id: 'quotationModalDeleteBtnConfirm', defaultMessage: 'Confirm' }),
    cancel: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      fixed: 'left',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'quotationColQuotationNo', defaultMessage: 'Quotations No.' }),
      dataIndex: 'quotationNo',
      key: 'quotationNo',
      sorter: 'true',
      fixed: 'left',
      width: 160,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'quotationColCustomerName', defaultMessage: 'Customer Name' }),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: 'true',
      fixed: 'left',
      width: 200,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'quotationColTotal', defaultMessage: 'Total (Baht)' }),
      dataIndex: 'totalBath',
      key: 'totalBath',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.totalBath).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({ id: 'quotationColCustomerAddress', defaultMessage: 'Customer Address' }),
      dataIndex: 'customerAddress',
      key: 'customerAddress',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'quotationColSaleOrder', defaultMessage: 'Sales Order' }),
      dataIndex: 'saleOrder',
      key: 'saleOrder',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'quotationColDateOfIssue', defaultMessage: 'Date of Issue' }),
      dataIndex: 'createDate',
      key: 'createDate',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'quotationColSale', defaultMessage: 'Sale' }),
      dataIndex: 'sale',
      key: 'sale',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'quotationColWareHouse', defaultMessage: 'WareHouse' }),
      dataIndex: 'wareHourse',
      key: 'wareHourse',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const value = record.wareHourse.map((item) => item.warehouseName);
        return <div>{value.toString()}</div>;
      },
    },
    {
      title: intl.formatMessage({ id: 'quotationColStatus', defaultMessage: 'Status' }),
      dataIndex: 'statusView',
      key: 'statusView',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <Tag className="tag-status-all-style" color={record.statusColor}>
          {record.statusView}
        </Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 70,
      render: (text, record, index) => fncActionTable(record, index),
    },
  ];

  const setShowColumn = {
    index: true,
    quotationNo: true,
    customerName: true,
    totalBath: true,
    customerAddress: false,
    saleOrder: true,
    createDate: true,
    sale: false,
    wareHourse: false,
    statusView: true,
    action: true,
  };

  const setShowColumnArr = ['index', 'quotationNo', 'customerName', 'totalBath', 'saleOrder', 'createDate', 'statusView', 'action'];

  const ignoreColumn = ['customerAddress', 'sale', 'wareHourse'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreFilterColumns = ['index', 'quotationNo', 'customerName', 'action'];
  const ignoreSearchColumns = ['index', 'createDate', 'action'];

  const [loading, setLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [dateTrigger, setDateTrigger] = useState('');
  const [fieldChang, setFieldChang] = useState('');
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [startDate, setStartDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(today).format('YYYY-MM-DD'));

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [visible, setVisible] = useState(false);

  const [triggerApi, setTriggerApi] = useState(false);
  const [reason, setReason] = useState([]);
  const [quotationData, setQuotationData] = useState([]);
  const [priceReportDetail, setPriceReportDetail] = useState({});
  const [scrollTableX, setScrollTableX] = useState(0);

  // --------------------- State Modal Create Quotation -----------------------------------
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleViewCustomer, setvisibleViewCustomer] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [customerView, setCustomerView] = useState();

  // --------------------- State Modal Edit Quotation -----------------------------------
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [dataView, setDataView] = useState();
  const [dataHeader, setDataHeader] = useState();
  const [discout, setDiscout] = useState([]);
  const [selectDiscount, setSelectDiscount] = useState();

  // --------------------- State Modal View Quotation -----------------------------------
  const [visibleViewQuotation, setVisibleViewQuotation] = useState(false);
  const [viewData, setViewData] = useState();

  const [visibleModalApprove, setVisibleModalApprove] = useState(false);
  const [visibleModalReject, setVisibleModalReject] = useState(false);

  const [remark, setRemark] = useState('');

  const componentRef = useRef();

  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      const response = await getItemQuotation(
        startDate,
        endDate,
        fieldChang,
        fliterByColumn,
        fieldSort,
        orderSort,
        paginationPage,
        paginationSize
      );

      if (isObject(response) && response.status.code === 200) {
        setQuotationData(_.get(response, 'data'));
        setPriceReportDetail({
          excludeTax: _.get(response, 'excludeTax'),
          includeTax: _.get(response, 'includeTax'),
          total: _.get(response, 'total'),
        });
        setTotal(_.get(response, 'allRows'));
        setLoading(false);
      } else {
        setPriceReportDetail({
          excludeTax: 0.0,
          includeTax: 0.0,
          total: 0.0,
        });
        setLoading(false);
      }
    };
    getApi();
  }, [triggerApi, dateTrigger, fieldChang, fliterByColumn, extraSort]);

  useEffect(() => {
    const getCustomerData = async () => {
      const getData = await getCustomerApi();
      setCustomer(_.get(getData, 'customers'));
    };
    getCustomerData();
  }, []);

  useEffect(() => {
    if (visibleModal === true) {
      const getReasonData = async () => {
        const getData = await getReason();
        const getRemark = await getDefaultStarted();
        setReason(getData);
        setRemark(_.get(getRemark, 'remark'));
      };
      getReasonData();
    }
  }, [visibleModal]);

  useEffect(() => {
    const getReasonData = async () => {
      const getData = await getReason();
      setReason(getData);
    };
    getReasonData();
  }, []);

  const handleCreateQuotation = (e) => {
    setVisibleModal(true);
  };

  const onChangeSearch = debounce((value) => {
    setFieldChang(value);
    // setPaginationPage(1);
  }, 500);

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const onSelectRangePicker = (date, dateString) => {
    setStartDate(moment(date[0]).format('YYYY-MM-DD'));
    setEndDate(moment(date[1]).format('YYYY-MM-DD'));
    setDateTrigger(dateString);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    if (_.size(setNewArr) > 8) {
      setScrollTableX(2000);
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    setScrollTableX(0);
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
      // setScrollTableX(0)
    }
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const handleCloseCustomerView = () => {
    setvisibleViewCustomer(false);
  };

  const fncActionTable = (record, index) => {
    return (
      <div>
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              {record.statusCode === 'cancel' ? (
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                        <ButtonView onClick={handleViewQuotation} record={record} />
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              ) : record.statusCode === 'approve' ? (
                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                          <ButtonView onClick={handleViewQuotation} record={record} />
                        ) : null}
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A9') ? (
                          <ReactToPrint
                            trigger={() => (
                              <Button style={{ width: 100 }} type="link" ghost>
                                <p>
                                  <FormattedMessage id="btnPrint" defaultMessage="Print" />
                                </p>
                              </Button>
                            )}
                            content={() => componentRef.current}
                            onBeforeGetContent={() => handlePrint(record)}
                            onAfterPrint={() => hanldeAfterPrint(_.get(record, 'quotationId'))}
                          />
                        ) : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>
              ) : record.statusCode === 'draft' ? (
                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                          <ButtonView onClick={handleViewQuotation} record={record} />
                        ) : null}
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A3') ? (
                          <ButtonEdit onClick={handleEditQuotation} record={record} />
                        ) : null}
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A8') ? <ButtonDelete onClick={hanndleDelete} record={record} /> : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>
              ) : record.statusCode === 'reject' ? (
                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                          <ButtonView onClick={handleViewQuotation} record={record} />
                        ) : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>
              ) : record.statusCode === 'waiting' ? (
                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                          <ButtonView onClick={handleViewQuotation} record={record} />
                        ) : null}
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A5') ? (
                          <ButtonApprove onClick={handleApproved} record={record} />
                        ) : null}
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A6') ? <ButtonReject onClick={handleReject} record={record} /> : null}
                        {checkPermissionAction('P28PG1C1', 'P28PG1C1A7') ? (
                          <ButtonCancel onClick={hanndleCancelStatus} record={record} />
                        ) : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>
              ) : null}
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      </div>
    );
  };

  const handlePrint = async (e) => {
    const response = await getQuotationById(_.get(e, 'quotationId'));
    if (_.size(response) !== 0) {
      setDataView(_.get(response, 'data[0]'));
      setDataHeader(_.get(response, 'comInfo'));
      const defaultStart = await getDefaultStarted();
      if (defaultStart) {
        const filter =
          defaultStart &&
          defaultStart.dataDiscount.filter((item) => {
            return item.discountId === _.get(response, 'data[0].discountId');
          });
        setSelectDiscount(_.get(filter, '[0]'));
      }
    }
  };

  const hanldeAfterPrint = async (id) => {
    await countPrint(id);
  };

  const handleViewQuotation = async (e) => {
    const response = await getQuotationById(_.get(e, 'quotationId'));
    if (_.size(response) !== 0) {
      setDataView(_.get(response, 'data[0]'));
      setDataHeader(_.get(response, 'comInfo'));
      setVisibleViewQuotation(true);
    }
  };

  const handleEditQuotation = async (e) => {
    const response = await getQuotationById(_.get(e, 'quotationId'));
    if (_.size(response) !== 0) {
      setDataEdit(_.get(response, 'data[0]'));
      setRemark(response.data[0].remark);
      setVisibleEditModal(true);
    }
  };

  const handleApproved = (status, record) => {
    setViewData(record);
    setVisibleModalApprove(true);
  };

  const handleReject = (status, record) => {
    setViewData(record);
    setVisibleModalReject(true);
  };

  const hanndleDelete = (status, record) => {
    deleteQuotation(langConFirmDelete, record, setTriggerApi);
  };

  const hanndleCancelStatus = (status, record) => {
    cancelQutation(langConFirmCancel, record, setTriggerApi);
  };

  return (
    <div className="quotation">
      <Card
        className="quotation-card"
        title={intl.formatMessage({ id: 'quotationHeaderQuotation', defaultMessage: 'Quotation' })}
        extra={
          <PageSettings.Consumer>
            {({ checkPermissionAction }) => (
              <div>
                {checkPermissionAction('P28PG1C1', 'P28PG1C1A2') ? (
                  <Button01 btnsize="wd_sm" type="primary" className="quotation-card-extra" href="#" onClick={handleCreateQuotation}>
                    <FormattedMessage id="quotationBtnCreateQuotation" defaultMessage="Create Quotation" />
                  </Button01>
                ) : null}
              </div>
            )}
          </PageSettings.Consumer>
        }
      >
        <PriceReportV1 priceReportDetail={priceReportDetail} />

        <div className="action-quotation-container">
          <ActionReportV2
            value={{
              columns,
              ignoreFilterColumns,
              ignoreSearchColumns,
              fliterByColumn,
              visible,
              defaultShowColumn,
              textErrorSelectColumn,
            }}
            fnc={{
              onChangeSearch,
              onFliterByColumn,
              onSelectRangePicker,
              checkedValuecolumns,
              handleCheckOk,
              handleReset,
              handleVisibleChange,
              handleOnVisiblecolumns,
            }}
          />
        </div>

        <QuotationReportTable
          loading={loading}
          dataSource={quotationData}
          columns={newDataColumns}
          total={total}
          scrollTableX={scrollTableX}
          fnc={{
            handleChange,
            handlePagination,
            handleSizeChange,
          }}
        />
      </Card>

      <CreateQuotation
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        setTriggerApi={setTriggerApi}
        remark={remark}
        setRemark={setRemark}
      />

      <EditQuotation
        visibleModal={visibleEditModal}
        setVisibleModal={setVisibleEditModal}
        setTriggerApi={setTriggerApi}
        dataEdit={dataEdit}
        remark={remark}
        setRemark={setRemark}
      />

      <ViewCustomer visible={visibleViewCustomer} handleClose={handleCloseCustomerView} customer={customerView} />

      <ViewQuotation
        componentName="quotation-report"
        visible={visibleViewQuotation}
        setVisible={setVisibleViewQuotation}
        setTriggerApi={setTriggerApi}
        data={dataView}
        dataHeader={dataHeader}
        // setData={setDataView}
      />

      <ApproveQuotation
        visible={visibleModalApprove}
        setVisible={setVisibleModalApprove}
        setVisibleView={setVisibleViewQuotation}
        setTriggerApi={setTriggerApi}
        data={viewData}
      />

      <ModalReject
        visible={visibleModalReject}
        setVisible={setVisibleModalReject}
        setVisibleView={setVisibleViewQuotation}
        setTriggerApi={setTriggerApi}
        data={viewData}
        reason={reason}
      />
      {dataView && dataHeader && selectDiscount ? (
        <PrintTest ref={componentRef} data={dataView} dataHeader={dataHeader} selectDiscount={selectDiscount} />
      ) : null}
    </div>
  );
};

export default Quotation;
