import React, { useState, useEffect, useRef, createRef, useMemo, memo } from 'react';
import { Avatar, Icon, Row, Col, Modal, Form, DatePicker, Select, Tag } from 'antd';
import {
  TimelineMonth,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import { Internationalization, extend } from '@syncfusion/ej2-base';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import styled from 'styled-components';
import httpClient from '../../../../components/axiosClient';
import Provider from '../../provider';
import notification from '../../../../components/v2.1.0/notification';
import moment from 'moment';
import { L10n } from '@syncfusion/ej2-base';
import _ from 'lodash';
import Filter from './filter';
import Button01 from '../../../../components/v2/button_01';
import Planning from '../planning/planning';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings'


const instance = new Internationalization();
const datenow = moment();
const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

const CustomDateHeader = styled.div`
  text-align: center;
`;

const DivShiftSchedule = styled.div`
  padding: 0px 24px 24px 24px;
`;

const CustomDateHeaderText = styled.p`
  padding: 0;
  margin: 0;
`;

L10n.load({
  'en-US': {
    'schedule': {
      'saveButton': 'Add',
      'cancelButton': 'Cancel',
      'deleteButton': 'Remove',
      'newEvent': 'Add Shift',
    },
  }
});

export default memo((props) => {
  const intl = useIntl();
  const app = Provider.useAppContext();
  const { day, shifts, resources, resourcesArr, handleClickPopupOpen, key, refresh } = props;

  const [month, setMonth] = useState(parseInt(moment().month(app.state.shiftsMonth).format('MM') - 1));
  const [year, setYear] = useState(parseInt(app.state.shiftsYear));
  const scheduleObj = useRef();
  const monthRef = createRef();
  const yearRef = createRef();
  const [planningVisibled, setPlanningVisibled] = useState(false);
  const [shiftPlanningDisabled, setShiftPlanningDisabled] = useState(true);
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [rangePickerValue, setRangePickerValue] = useState([]);

  // let resourcesData = extend([], resources, null, true);
  // let shiftsData = extend([], shifts, null, true);




  const handleChangeMonth = async (val) => {
    app.fnc.setShiftsMonth(val);
  };

  const handleChangeYear = async (val) => {
    app.fnc.setShiftsYear(val);
  };

  const fncSetShift = async (yearMonth) => {
    const shifts = await getShift(`${yearMonth}`);
    const setShifts = generateEvents(shifts);

    scheduleObj.current.eventSettings.dataSource = setShifts;
  }

  const getShift = async (thisDate) => {
    const data = {
      com_id: app.state.comId,
      org_id: app.state.orgId,
      search_team: {
        name: '',
      },
      members: {
        conditional: [],
        order: 'fullname ASC',
        limit: '10',
        index_page: '1',
      },
      services: {},
      shift: {
        date: `${thisDate}`
      },
    };

    try {
      const response = await httpClient.post('/v2/organizationdetails', data);
      if (response.status === 200) {
        return response.data.shift
      }
      else {
        return false;
      }
    }
    catch (error) {
      return false;
    }
  }

  const generateEvents = (data) => {
    const shiftsArr = [];
    if (data) {
      data.forEach((object) => {
        object.shifts.forEach((element) => {
          const { mem_shift_id, shift_name, shift_date, mem_id, color } = element;
          shiftsArr.push({
            Id: mem_shift_id,
            Subject: shift_name,
            StartTime: moment(shift_date).format('YYYY-MM-DD 00:00'),
            EndTime: moment(shift_date).format('YYYY-MM-DD 23:59'),
            Color: color,
            ResourceId: mem_id,
            IsAllDay: true
          });
        });
      });
    }
    return shiftsArr;
  }

  const checkShiftPlanning = () => {
    const dateSelect = moment(app.state.shiftsYear + ' ' + app.state.shiftsMonth, 'YYYY MMMM');
    const diff = dateSelect.diff(datenow, 'months');
    if (diff >= 0 && dateSelect.format('YYYY-MM') !== datenow.format('YYYY-MM')) {
      setShiftPlanningDisabled(false);
    }
    else {
      setShiftPlanningDisabled(true);
    }
  };

  useEffect(() => {
    const getDataByMonthYear = () => {
      if (scheduleObj.current !== null) {
        let scheduleSelectDate = moment(scheduleObj.current.selectedDate).format('YYYY-MM');
        let txtYear = app.state.shiftsYear;
        let txtMonth = moment().month(app.state.shiftsMonth).format('MM');
        // const shifts = await getShift(`${txtYear}-${txtMonth}`);
        // const setShifts = generateEvents(shifts);
        // scheduleObj.current.eventSettings.dataSource = setShifts;
        if (scheduleSelectDate !== `${txtYear}-${txtMonth}`) {
          scheduleObj.current.selectedDate = `${txtYear}-${txtMonth}`;
        }
      }


    }

    // if(app.state.shiftsType.length > 0) {
    setTimeout(() => {
      getDataByMonthYear();
    }, 500);

    // }

  }, [app.state.shiftsYear, app.state.shiftsMonth]);

  useEffect(() => {

    const getShiftSettings = async () => {
      try {
        const shiftSettings = await httpClient.get(`/resource/manager/organization/${app.state.orgId}/shift/rules?comId=${app.state.comId}`);
        if (shiftSettings.status === 200) {
          if (shiftSettings.data.shiftTypeRules.length > 0 && shiftSettings.data.shiftRuleStatus === 'Enable') {
            // setShiftPlanningDisabled(false);
            checkShiftPlanning();
          }
          else {
            setShiftPlanningDisabled(true);
          }
        }
        else {
          setShiftPlanningDisabled(true);
        }
      }
      catch (error) {
        setShiftPlanningDisabled(true);
      }
    }

    getShiftSettings();

  }, [app.state.orgId, app.state.shiftsYear, app.state.shiftsMonth]);

  const customRenderCell = (args) => {
    if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
      let sumResource = _.sumBy(scheduleObj.current.resources[0].properties.dataSource, (e) => { return e.Text !== '' ? 1 : 0; });
      let target = args.element.querySelector('.e-resource-text');
      target.innerHTML = `<div style="padding: 5px 15px;"><b> ${intl.formatMessage({ id: 'orgShiftPlanningLblResources', defaultMessage: 'Resources' })} (` + sumResource + ')</b></div>';
    }
  };

  const customResourceHeaderTemplate = (props) => {
    const checkResourceMockData = props.resourceData.Id.toString().indexOf('mockdata');
    const pathImage = getPathImage(props);
    return (
      checkResourceMockData ? 
        <div className='template-wrap' title={getName(props)}>
          <Avatar className='avatar-border' src={pathImage} />
          <span className="shift-scheduler-column-name">{_.truncate(getName(props), {'length': 20})}</span>
        </div> : <div></div>
    );
  };

  const getPathImage = (value) => {
    return value.resourceData.pathImage;
  };

  const getName = (value) => {
    return value.resourceData[value.resource.textField];
  };

  const handlePopupOpen = (args) => {
    args.cancel = true;
    if (args.type === 'Editor') {
      if (args.data.isReadonly) args.cancel = true;
    }

    if (args.type === 'QuickInfo') {
      if (!_.isInteger(args.data.Id)) {
        args.cancel = true;
        // args.element.style.backgroundColor = args.data.color;
        // let ePopupHeader = args.element.querySelector('.e-popup-header');
        // ePopupHeader.setAttribute('style', { backgroundColor:args.data.color });
      }

      // args.element.querySelector('.e-edit').remove();
    }

    if (args.type === 'DeleteAlert') {
      // args.cancel = true;
    }

  };

  const handlePopupClose = async (args) => {
    if (args.type === 'Editor') {
      if (args.data) {

      }
    }

    if (args.type === 'DeleteAlert') {


    }

  };

  const editorTemplate = (props) => {
    const { ResourceId, StartTime, EndTime } = props;
    const resourceIndex = _.findIndex(resources, function (o) { return o.Id === ResourceId; });
    const resourceDetail = { ...resourcesArr[resourceIndex] };

    return (
      <table className="custom-event-editor" style={{ width: '100%', cellpadding: '5' }}>
        <tbody>
          <tr>
            <td rowSpan={9} style={{ width: 250, paddingLeft: 50, paddingTop: 10, paddingRight: 50, paddingBottom: 10 }}>
              <Avatar size={150} src={`${process.env.REACT_APP_IMG_HOST}` + resourceDetail.profile_img} style={{ alignItems: 'center' }} />
            </td>
          </tr>
          <tr>
            <td colSpan={3} className="e-textlabel">
              <h2>{resourceDetail.fullname}</h2>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">
              <Icon type="mail" style={{ fontSize: '18px' }} />
            </td>
            <td colSpan={3}>
              {resourceDetail.email}
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">
              <Icon type="phone" style={{ fontSize: '18px' }} />
            </td>
            <td colSpan={3}>
              {resourceDetail.phone}
            </td>
          </tr>
          {/* <tr>
            <td colSpan={4} className="e-textlabel">
              <DateRangePickerComponent 
                id="DateRange" 
                placeholder='Select a range' 
                data-name="DateRange" 
                className="e-field" 
                format='dd/MM/yyyy' 
                startDate={StartTime} 
                endDate={StartTime}
                required={true} />
            </td>
          </tr> */}
          <tr>
            <td className="e-textlabel">From</td>
            <td colSpan={3}>
              <DatePickerComponent format='dd/MM/yyyy' id="StartTime" data-name="StartTime" value={StartTime} className="e-field"></DatePickerComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">To</td>
            <td colSpan={3}>
              <DatePickerComponent format='dd/MM/yyyy' id="EndTime" data-name="EndTime" value={StartTime} className="e-field"></DatePickerComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Shift Type</td>
            <td colSpan={3} className="e-textlabel">
              <DropDownListComponent
                id="EventType"
                placeholder='Choose status'
                data-name="EventType"
                className="e-field"
                style={{ width: '100%' }}
                dataSource={app.state.shiftsType.map((element) => element.name)}
                value={props.EventType || null}
                required={true}>
              </DropDownListComponent>
              {/* <input type='hidden' className="e-field" id='ResourceId' data-name='ResourceId' defaultValue={ResourceId} value={ResourceId} /> */}
            </td>
          </tr>
          <tr style={{ display: 'none' }}>
            <td className="e-textlabel">Member</td>
            <td colSpan={3}>
              <MultiSelectComponent className="e-field" data-name="ResourceId" dataSource={resources} fields={{ text: 'Text', value: 'Id' }} value={[ResourceId]} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  // const eventTemplate = (props) => {
  //   return (
  //     <div className="template-wrap" style={{ color: '#000', background: props.Color, display: 'grid', alignItems: 'center', width: '100%', textAlign: 'center' }}>
  //       {props.Subject}
  //     </div>
  //   );
  // };

  const quickHeader = (props) => {
    const { Subject, Color } = props;

    return (
      <div>
        {props.elementType === 'cell' ?
          <div className="e-cell-header">
            <div className="e-header-icon-wrapper">
              <button className="e-close" title="Close"></button>
            </div>
          </div> :
          <div className="e-popup-header" style={{ backgroundColor: Color }}>
            <div className="e-header-icon-wrapper">
              <button className="e-delete e-icons e-control e-btn e-lib e-flat e-round e-small e-icon-btn" title="Delete">
              </button>
              <button className="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn" title="Close">
              </button>
            </div>
            <div className="e-subject-wrap">
              <div className="e-subject e-text-ellipsis" title="Aliens vs Humans">{Subject}</div>
            </div>
          </div>
        }
      </div>
    );
  }

  const quickContent = (props) => {
    const { StartTime, EndTime } = props;

    const txtDate = instance.formatDate(StartTime, { type: 'date', skeleton: 'long' });
    const txtStartTime = instance.formatDate(StartTime, { skeleton: 'hm' });
    const txtEndTime = instance.formatDate(EndTime, { skeleton: 'hm' });

    return (<div>
      {props.elementType === 'cell' ?
        <div className="e-cell-content e-template">
          <form className="e-schedule-form">
            <div>
              <input className="subject e-field" type="text" name="Subject" placeholder="Title" />
            </div>
            <div>
              <input className="location e-field" type="text" name="Location" placeholder="Location" />
            </div>
          </form>
        </div> :
        <div className="e-event-content e-template">
          <div className="e-subject-wrap">
            <div className="e-date-time">
              <div className="e-time-icon e-icons" style={{ fontSize: 20, marginRight: 15 }}></div>
              <div className="e-date-time-wrapper e-text-ellipsis">
                <div className="e-date-time-details e-text-ellipsis">{txtDate} ({txtStartTime}&nbsp;-&nbsp;{txtEndTime})</div>
              </div>
            </div>
          </div>
        </div>}
    </div>);
  }

  const handleActionBegin = async (args) => {
    if (args.requestType === 'eventCreate') {
      args.cancel = true;
      const addShift = [];


      const resourceIndex = _.findIndex(resources, function (o) { return o.Id === parseInt(args.data[0].ResourceId); });
      const resourceDetail = { ...resourcesArr[resourceIndex] };
      const shiftIndex = _.findIndex(app.state.shiftsType, function (o) { return o.name === args.data[0].EventType; });
      const shiftDetail = app.state.shiftsType[shiftIndex];

      // args.addedRecords[0].Subject = args.data[0].EventType;
      // args.addedRecords[0].Color = shiftDetail.color;

      try {
        const payload = {
          comId: app.state.comId,
          orgId: app.state.orgId,
          memId: resourceDetail.mem_id,
          memOrgId: resourceDetail.mem_org_id,
          shiftId: shiftDetail.shift_id,
          from: moment(args.data[0].StartTime).format('YYYY-MM-DD'),
          to: moment(args.data[0].EndTime).format('YYYY-MM-DD')
        }

        const response = await httpClient.post('/members-shifts', payload);
        if (response.status === 200) {

          if (!response.data.reject) {

            // if(!response.data.memberShiftNew) {
            //   response.data.forEach(element => {
            //     addShift.push({
            //       Id: element.mem_shift_id,
            //       Subject: element.shift_name,
            //       StartTime: moment(element.shift_date).format('YYYY-MM-DD 00:00'),
            //       EndTime: moment(element.shift_date).format('YYYY-MM-DD 23:59'),
            //       Color: element.color,
            //       ResourceId: element.mem_id,
            //       IsAllDay: true
            //     });
            //   });
            // }
            // else {
            //   response.data.data.forEach(element => {
            //     addShift.push({
            //       Id: element.mem_shift_id,
            //       Subject: element.shift_name,
            //       StartTime: moment(element.shift_date).format('YYYY-MM-DD 00:00'),
            //       EndTime: moment(element.shift_date).format('YYYY-MM-DD 23:59'),
            //       Color: element.color,
            //       ResourceId: element.mem_id,
            //       IsAllDay: true
            //     });
            //   });
            // }

            //   args.addedRecords = addShift;

            await fncSetShift(moment(args.data[0].StartTime).format('YYYY-MM'));
            notification({ status: 'success', message: 'Add shift successfully' });
          }
          else {
            args.cancel = true;
            notification({ status: 'error', message: response.data.reject });
          }

        }
        else {
          args.cancel = true;
          notification({ status: 'error', message: response.data.data });
        }
      }
      catch (error) {
        args.cancel = true;
      }

    }

    if (args.requestType === 'eventRemove') {
      const deleteShift = await httpClient.delete(`/members-shifts/${args.data[0].Id}`);
      if (deleteShift.data.status === 200) {
        notification({ status: 'success', message: deleteShift.data.data });
      }
      else {
        notification({ status: 'error', message: deleteShift.data.data });
      }
    }
  }

  const handleComplete = async (args) => {

  }

  // const handleFailure = (args) => {

  // }

  const addShift = () => {
  }

  const handleClick = () => {
    scheduleObj.current.selectedDate = '2020-07';
    // scheduleObj.current.eventRendered = [{
    //       Color: "#0000",
    //   EndTime: "2020-05-15 00:00",
    //   Id: 103,
    //   IsAllDay: true,
    //   ResourceId: 51,
    //   StartTime: "2020-05-15 00:00",
    //   Subject: "gg"
    //     }];
  }

  const handleOpenShiftPlanning = () => {
    setPlanningVisibled(true);
  }

  const handlePlanningCancel = (year, month) => {
    setPlanningVisibled(false);

    if (!_.isObject(year)) app.fnc.setShiftsYear(year);
    if (month) app.fnc.setShiftsMonth(month);
    app.fnc.getRefresh();
  }

  const onEventRendered = (args) => {
    let height = 38;
    let left = parseInt(args.element.style.left) + 6;

    args.element.style.width = '125px';
    args.element.style.height = height + 'px';
    args.element.style.color = '#ffffff';
    args.element.style.backgroundColor = args.data.Color;
    args.element.style.margin = '0px';
    args.element.style.borderRadius = '3px';
    args.element.style.left = left + 'px';
    args.element.querySelector('.e-inner-wrap').style.padding = '0px 12px';
    args.element.querySelector('.e-inner-wrap').style.fontSize = '12px';
    args.element.querySelector('.e-time').style.fontSize = '8px';
    args.element.querySelector('.e-time').innerHTML = args.data.TeamName + ' ' + args.data.Start + ' - ' + args.data.Finish;
  }

  const getDateHeaderText = (value, skeleton) => {
    return instance.formatDate(value, { skeleton: skeleton });
  }

  const dateHeaderTemplate = (props) => {
    return (
      <div style={{ height: 50, padding: 5 }}>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'E')}</div>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'd')}</div>
      </div>
    );
  }

  const eventTemplate = (props) => {

    return (
      <div>xxxxxxx</div>
    )
  }



  const handleClickCell = (args) => {
   

    setFormData({
      memId: resources[args.groupIndex].Id,
      memComId: resources[args.groupIndex].memComId,
      pathImage: process.env.REACT_APP_IMG_HOST + resources[args.groupIndex].pathImage,
      name: resources[args.groupIndex].Text,
      email: resources[args.groupIndex].email || '',
      phone: resources[args.groupIndex].phone || '',
      startDate: args.startTime,
      endDate: args.endTime
    });
    setFormVisible(true);
    setRangePickerValue([args.startTime, args.endTime]);
  }

  const handleCancelForm = () => {
    setFormVisible(false);
  }

  const handleOkForm = () => {
    setFormVisible(false);
  }

  const FormAddShift = Form.create(
    {
      name: 'shift_form',
      mapPropsToFields(props) {
        return {
          'range-picker': Form.createFormField({
            value: [moment(rangePickerValue[0]), moment(rangePickerValue[0])]
          })
        }
      }
    }
  )((props) => {

    const { getFieldDecorator } = props.form;

    return (
      <Form>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row gutter={[16, 8]} style={{ textAlign: 'center' }}>
              <Col span={24}>
                <Avatar size={96} src={formData.pathImage || ''} />
              </Col>
            </Row>
          </Col>
          <Col span={16}>
          <Row gutter={[16, 8]}>
              <Col span={24}>
                <h3>{formData.name || ''}</h3>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]} type='flex' justify='space-around' align='middle'>
                  <Col span={2}>
                    <Icon type='mail' />
                  </Col>
                  <Col span={22}>
                    <label>{formData.email || ''}</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]} type='flex' justify='space-around' align='middle'>
                  <Col span={2}>
                    <Icon type='phone' />
                  </Col>
                  <Col span={22}>
                    <label>{formData.phone || ''}</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]} type='flex' justify='space-around' align='middle'>
                  <Col span={2}>
                    <Icon type='calendar' />
                  </Col>
                  <Col span={22}>
                    <Form.Item>
                      {getFieldDecorator('range-picker', {
                        rules: [
                          {
                            type: 'array',
                            required: true,
                            message: intl.formatMessage({ id: 'orgShiftPlanningValidateTime', defaultMessage: 'Please select time.' })
                          }
                        ],
                      }
                      )(
                        <RangePicker
                          placeholder={[intl.formatMessage({ id: 'orgShiftPlanningModalHintStartDate', defaultMessage: 'Please input start date' }),
                          intl.formatMessage({ id: 'orgShiftPlanningModalHintEndDate', defaultMessage: 'Please input end date' })
                          ]}
                          // disabledDate={disabledDate}
                          size='small'
                          allowClear={false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]} type='flex' justify='space-around' align='middle'>
                  <Col span={2}>
                    <Icon type='menu' />
                  </Col>
                  <Col span={22}>
                    <Form.Item>
                      {getFieldDecorator('select', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'orgShiftPlanningModalHintSelectShift', defaultMessage: 'Please select shift' })
                          }
                        ],
                      })(
                        <Select size='small' placeholder={intl.formatMessage({ id: 'orgShiftPlanningModalHintSelectShift', defaultMessage: 'Please select shift' })} style={{ width: '100%' }}>
                          {/* {app.shiftTypeRule.map && app.shiftTypeRule.map((e) =>
                            <Option key={`shift-type-key-${e.shiftId}`} value={e.shiftId}>{e.shiftName}</Option>)} */}
                        </Select>)}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  });

  return useMemo(() => {
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            <Row gutter={[8, 8]} align='middle' type='flex'>
              <Col span={12} >
                <Filter
                  monthRef={monthRef}
                  month={month}
                  onChangeMonth={handleChangeMonth}
                  yearRef={yearRef}
                  year={year}
                  onChangeYear={handleChangeYear} />
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {checkPermissionAction('P3PG1C7', 'P3PG1C7A4') ? (
                  <div style={{ padding: '8px 24px 16px 24px' }}>
                    <Button01
                      type='primary'
                      fontsize='sm'
                      btnsize='wd_at'
                      onClick={handleOpenShiftPlanning}
                      disabled={shiftPlanningDisabled}
                    >
                      <FormattedMessage id="orgShiftPlanningLblShift" defaultMessage="Shift Planning" />
                    </Button01>
                  </div>
                )
                  : null
                }

                {planningVisibled ? <Planning
                  comId={app.state.comId}
                  orgId={app.state.orgId}
                  yearMonth={`${year}-${month}`}
                  resourcesOld={resources}
                  visibled={planningVisibled}
                  onCancel={handlePlanningCancel}
                /> : null}

              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} >
                <DivShiftSchedule>
                  {app.state.shiftsType.length > 0 ?
                    <ScheduleComponent
                      ref={scheduleObj}
                      width='100%'
                      // height="650px"
                      cssClass='schedule-cell-dimension-shift'
                      rowAutoHeight={true}
                      // selectedDate={new Date(year, month, day)}
                      selectedDate={app.state.mainShiftsDateTimePicker}
                      showHeaderBar={false}
                      showQuickInfo={false}
                      workDays={[0, 1, 2, 3, 4, 5, 6]}
                      group={{ resources: ['Resources'] }}
                      dateHeaderTemplate={dateHeaderTemplate}
                      renderCell={customRenderCell}
                      resourceHeaderTemplate={customResourceHeaderTemplate}
                      popupOpen={handlePopupOpen}
                      popupClose={handlePopupClose}
                      // editorTemplate={editorTemplate}
                      eventSettings={{ 
                        // template: eventTemplate,
                        dataSource: shifts,
                        // dataSource: shiftsData,
                      }}
                      // eventSettings={{ dataSource: shifts, template: eventTemplate }}
                      // quickInfoTemplates={{ header: quickHeader, content: quickContent }}
                      actionBegin={handleActionBegin}
                      actionComplete={handleComplete}
                      // actionBegin={handleFailure}
                      // enablePersistence={true}
                      eventRendered={onEventRendered}
                      cellClick={handleClickCell}
                    >
                      <ViewsDirective>
                        <ViewDirective option='TimelineMonth' />
                      </ViewsDirective>

                      <ResourcesDirective>
                        <ResourceDirective
                          field='ResourceId'
                          title='Resource'
                          name='Resources'
                          allowMultiple={false}
                          textField='Text'
                          idField='Id'
                          colorField='Color'
                          dataSource={resources}
                          // dataSource={resourcesData}
                        >
                        </ResourceDirective>
                      </ResourcesDirective>

                      <Inject services={[TimelineMonth]} />
                    </ScheduleComponent> :
                    <div></div>
                  }
                </DivShiftSchedule>
              </Col>
            </Row>

            <Modal
              title={intl.formatMessage({ id: 'orgShiftPlanningModalTitleAdd', defaultMessage: 'Add Shift' })}
              centered
              width={550}
              visible={formVisible}
              onOk={handleOkForm}
              onCancel={handleCancelForm}
              okText={intl.formatMessage({ id: 'btnSave', defaultMessage: 'Save' })}
              cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
            >
              <FormAddShift />
            </Modal>
          </div>
        )}

      </PageSettings.Consumer>
    );
  }, [app.state.shiftsType, shifts, resources, planningVisibled, shiftPlanningDisabled, refresh, formVisible]);
}, 
(prev, next) => 
  prev.shifts.length === next.shifts.length && prev.orgId === next.orgId && prev.refresh === next.refresh
);