import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Modal } from 'antd';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';

import Button03 from '../../components/v2/button_03';
import Button04 from '../../components/v2/button_04';
import { successNotification, errorNotification, warningNotification } from '../../components/v2/notification';
import createServiceArea from '../../controllers/service-area/create-service-area';
import editServiceArea from '../../controllers/service-area/edit-service-area';
import deleteServiceArea from '../../controllers/service-area/delete-service-area';
import ServiceAreaForm from './service-area-form';
import ServiceAreaView from './service-area-view';
import ServiceAreaMap from './service-area-map';
import CradDefaultV2 from '../../components/crad-default/crad-default-v2.jsx';
import { ServiceAreaContext } from './index';
import { RiRoadMapLine } from 'react-icons/ri';
import { PageSettings } from '../../config/page-settings';
import _ from 'lodash';

import styles from './css/index.css';
import NoDataBackground from '../../components/no-data-page';
import CardAverageSpeed from './service-average-speed';
import ServiceAverageSpeedModalForm from './service-average-speed-modal';

let markers = [];
let polygons = [];

const description = {
  text1: 'serviseAreaDefaultTxtDetailDescription1',
  message1: 'Please select a service area',
  text2: 'serviseAreaDefaultTxtDetailDescription2',
  message2: 'Service area will appear here.',
};

const Customization = (props) => {
  const {} = props;
  const intl = useIntl();
  const [formRef, setFormRef] = useState(null);
  const { mainState, mainSetState } = useContext(ServiceAreaContext);
  const [polygonData, setPolygonData] = useState([]);
  const [markerData, setMarkerData] = useState([]);
  // const [areaName , setAreaName] = useState("");

  const fncAreaCreate = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      if (_.size(markerData) <= 0) {
        errorNotification(
          intl.formatMessage({
            id: 'serviseAreaNotificationTheServiceAreaMustBeMarked',
            defaultMessage: 'The service area must be marked.',
          })
        );
      } else {
        let coordinates = [];
        let areaType = _.size(polygonData) > 0 ? 'polygon' : 'point';
        let marker = { lat: markerData.position.lat(), lng: markerData.position.lng() };

        if (_.size(polygonData) > 0) {
          const path = polygonData[0].getPath();
          for (let i = 0; i < path.length; i++) {
            let pathArray = { lat: path.getAt(i).lat(), lng: path.getAt(i).lng() };
            coordinates.push(pathArray);
          }
        }

        const body = {
          areaCode: values.areaName,
          areaName: values.areaName,
          areaColor: '',
          areaCenter: marker,
          areaType: areaType,
          coordinates: _.size(polygonData) > 0 ? coordinates : marker,
        };

        const response = await createServiceArea({ body });

        if (response.status.code === 200) {
          successNotification(response.status.message);
          mainSetState.setTriggerApi((current) => !current);
          setPolygonData([]);
          setMarkerData([]);
          setTimeout(() => {
            mainSetState.setServiceAreaId(_.get(response, 'data.areaMasterDetail.areaMasterId'));
            mainSetState.setActionAddNew('view');
          }, 500);
        } else {
          errorNotification(response.status.message);
        }
      }
    });
  };

  const fncEditeArea = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let editAreaId = mainState.serviceAreaId;

      if (mainState.serviceAreaDataById) {
        if (_.size(markerData) <= 0) {
          errorNotification(
            intl.formatMessage({
              id: 'serviseAreaNotificationTheServiceAreaMustBeMarked',
              defaultMessage: 'The service area must be marked.',
            })
          );
        } else {
          let coordinates = [];
          let areaType = _.size(polygonData) > 0 ? 'polygon' : 'point';
          let marker = { lat: markerData.position.lat(), lng: markerData.position.lng() };

          if (_.size(polygonData) > 0) {
            const path = polygonData[0].getPath();
            for (let i = 0; i < path.length; i++) {
              let pathArray = { lat: path.getAt(i).lat(), lng: path.getAt(i).lng() };
              coordinates.push(pathArray);
            }
          }

          const body = {
            areaCode: values.areaName,
            areaName: values.areaName,
            areaColor: '',
            areaCenter: marker,
            areaType: areaType,
            coordinates: _.size(polygonData) > 0 ? coordinates : marker,
          };

          const response = await editServiceArea({ body, editAreaId });

          if (response.status.code === 200) {
            successNotification(response.status.message);
            let data = response.data.areaMasterDetail;
            mainSetState.setServiceAreaDataById({
              areaCode: data.areaCode,
              areaMasterId: data.areaMasterId,
              areaName: data.areaName,
              comCode: '',
              coordinates: data.coordinates,
            });
            mainSetState.setTriggerApi((current) => !current);
            setPolygonData([]);
            setMarkerData([]);
            setTimeout(() => {
              mainSetState.setServiceAreaId(_.get(response, 'data.areaMasterDetail.areaMasterId'));
              mainSetState.setActionAddNew('view');
            }, 500);
          } else {
            errorNotification(response.status.message);
          }
        }
      }
    });
  };

  const fncDetailArea = () => {
    mainSetState.setActionAddNew('edit');
  };

  const fncDeleteArea = async () => {
    const deleteAreaId = mainState.serviceAreaId;
    if (deleteAreaId) {
      Modal.confirm({
        className: 'service-area-modal-confirm',
        centered: true,
        icon: null,
        title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
        okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        async onOk() {
          const response = await deleteServiceArea({ deleteAreaId });
          if (response.status.code === 200) {
            successNotification(response.status.message);
            mainSetState.setServiceAreaId(null);
            mainSetState.setActionAddNew('default');
            mainSetState.setServiceAreaDataById({
              areaCode: '',
              areaMasterId: '',
              areaName: '',
              comCode: '',
              coordinates: [],
            });
            mainSetState.setTriggerApi((current) => !current);
          } else {
            errorNotification(response.status.message);
          }
        },
        onCancel() {},
      });
    }
  };

  const fncAreaCancel = () => {
    formRef.resetFields();
    setPolygonData([]);
    setMarkerData([]);

    if (_.size(markerData)) {
      markers[0].setMap(null);
    }

    if (_.size(polygonData)) {
      polygons[0].setMap(null);
    }
    mainSetState.setActionAddNew('default');
  };

  const fncAreaCancelEdit = () => {
    mainSetState.setActionAddNew('view');
    // markers[0].setMap(null);
    // polygons[0].setMap(null);
    setPolygonData([]);
    setMarkerData([]);
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const setClearPolygons = () => {
    _.drop(polygons);
  };

  return (
    <div className="customization">
      <div className="customization-container">
        <Card
          className="customization-card"
          title={intl.formatMessage({ id: 'serviseAreaTitleArea', defaultMessage: 'Area' })}
          extra={
            mainState.actionAddNew === 'default' ? null : mainState.actionAddNew === 'view' ? (
              <ServiceAreaDetail fncDeleteArea={fncDeleteArea} fncDetailArea={fncDetailArea} />
            ) : mainState.actionAddNew === 'edit' ? (
              <ServiceAreaEdit fncAreaCancelEdit={fncAreaCancelEdit} fncEditeArea={fncEditeArea} />
            ) : (
              <ServiceAreaCreate fncAreaCancel={fncAreaCancel} fncAreaCreate={fncAreaCreate} />
            )
          }
        >
          <div
            className={
              mainState.actionAddNew === 'default' ? 'customization-container-body-default' : 'customization-container-body scroll-sm'
            }
          >
            {mainState.actionAddNew === 'default' ? (
              // <CradDefaultV2 description={description} iconBlankSpace={iconBlankSpace} />
              <NoDataBackground
                text1={{ id: 'nodataXService1', text: 'Please select a Service Area' }}
                text2={{ id: 'nodataXService2', text: 'Service Area will appear here.' }}
              />
            ) : (
              <div>
                <div className="customization-container-form">
                  {mainState.actionAddNew === 'view' ? (
                    <ServiceAreaView areaData={mainState.serviceAreaDataById} />
                  ) : (
                    <ServiceAreaForm ref={saveFormRef} areaData={mainState.serviceAreaDataById} mainSetState={mainSetState} />
                  )}
                </div>
                <div className="customization-container-map">
                  <ServiceAreaMap
                    markers={markers}
                    polygons={polygons}
                    areaData={mainState.serviceAreaDataById}
                    setClearPolygons={setClearPolygons}
                    polygonData={polygonData}
                    markerData={markerData}
                    setPolygonData={setPolygonData}
                    setMarkerData={setMarkerData}
                  />
                </div>
                {mainState.actionAddNew !== 'create' ? (
                  <div style={{ marginTop: 40 }}>
                    <CardAverageSpeed record={mainState.serviceAreaDataById} />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

const ServiceAreaCreate = ({ fncAreaCancel, fncAreaCreate }) => {
  return (
    <div>
      <Button02 style={{ margin: '0px 0px 0px 10px' }} fontColor="fc_black" onClick={fncAreaCancel}>
        <FormattedMessage id="serviseAreaBtnCancel" defaultMessage="Cancel" />
      </Button02>
      <Button01 type="primary" onClick={fncAreaCreate} styleMargin="mg_cs3">
        <FormattedMessage id="serviseAreaBtnSave" defaultMessage="Save" />
      </Button01>
    </div>
  );
};

const ServiceAreaDetail = ({ fncDeleteArea, fncDetailArea }) => {
  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {checkPermissionAction('P18PG1C1', 'P18PG1C1A4') ? (
              <Button02 style={{ margin: '0px 0px 0px 10px' }} fontColor="fc_black" onClick={fncDeleteArea}>
                <FormattedMessage id="serviseAreaBtnDelete" defaultMessage="Delete" />
              </Button02>
            ) : null}

            {checkPermissionAction('P18PG1C1', 'P18PG1C1A3') ? (
              <Button01 type="primary" onClick={fncDetailArea} styleMargin="mg_cs3">
                <FormattedMessage id="serviseAreaBtnEdit" defaultMessage="Edit" />
              </Button01>
            ) : null}
          </div>
        )}
      </PageSettings.Consumer>
    </div>
  );
};

const ServiceAreaEdit = ({ fncAreaCancelEdit, fncEditeArea }) => {
  return (
    <div>
      <Button02 style={{ margin: '0px 0px 0px 10px' }} fontColor="fc_black" onClick={fncAreaCancelEdit}>
        <FormattedMessage id="serviseAreaBtnCancel" defaultMessage="Cancel" />
      </Button02>
      <Button01 type="primary" onClick={fncEditeArea} styleMargin="mg_cs3">
        <FormattedMessage id="serviseAreaBtnSave" defaultMessage="Save" />
      </Button01>
    </div>
  );
};

const iconBlankSpace = () => <RiRoadMapLine />;

export default Customization;
