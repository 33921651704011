import React from 'react'
import { AutoComplete, Icon } from 'antd'
import './index.css'
import { useIntl } from 'react-intl'

export default (props) => {
  const { addressArea, handleSearchLocation, handleSelect, children } = props
  const intl = useIntl()
  return (
    <AutoComplete
      className="address-map-autocomplete-center"
      dataSource={addressArea}
      onSearch={handleSearchLocation}
      onSelect={handleSelect}
      filterOption={false}
      placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
      prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
    >
      {children}
    </AutoComplete>
  )
}
