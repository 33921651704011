import React, { useContext } from 'react';
import { Card, Tabs } from 'antd';
import AllowanceTable from './table';
import { AllowanceProvider } from '../context';
import _ from 'lodash';
import '../index.css';
import Button01 from '../../../components/v2/button_01';
import { FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../config/page-settings';

const { TabPane } = Tabs;

const AllowanceContent = () => {
  const { state, fnc } = useContext(AllowanceProvider);
  const { tab, tabValue } = state;
  const { handleTabChange,handleExport } = fnc;
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;

  return (
    <div>
      {checkPermissionAction('P80PG1C1', 'P80PG1C1A1') ? (
        <Card bodyStyle={{ padding: 'unset', height: 'calc(100vh - 110px)' }}>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            animated={false}
            activeKey={tabValue}
            onTabClick={handleTabChange}
            tabBarExtraContent={
              <div className="resourceAllowanceExtra">
                <Button01 btnsize="wd_at" type="primary" onClick={() => handleExport()}>
                  <FormattedMessage id="btnExportExcel" defaultMessage="Export Excel" />
                </Button01>
              </div>
            }
          >
            {_.map(tab, (item) => (
              <TabPane tab={item.name} key={item.code}>
                <AllowanceTable />
              </TabPane>
            ))}
          </Tabs>
        </Card>
      ) : null}
    </div>
  );
};

export default AllowanceContent;
