import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Icon } from 'antd';
import SkillMenu from './skill-menu';
import SkillDetail from './skill-detail';
import getSkill from '../../../controllers/skill/get-skill';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import getbyId from '../../../controllers/skill/get-by-id';
import _ from 'lodash';
import { PageSettings } from '../../../config/page-settings';
import NoDataBackground from '../../../components/no-data-page';

export default () => {
  const intl = useIntl();
  const [skillData, setSkillData] = useState([]);
  const [selectSkill, setSelectSkill] = useState();
  const [statusAdd, setStatusAdd] = useState(false);
  const [statusEdit, setStatusEdit] = useState(true);
  const [triggerAPI, setTriggerAPI] = useState(false);
  const [skillDetail, setSkillDetail] = useState();
  const [skillList, setSkillList] = useState([]);
  const [defaultKeys, setDefaultKeys] = useState();
  const [stateFilter, setStateFilter] = useState();
  const [sortMenu, setSortMenu] = useState();
  const [sort, setSort] = useState(10);
  const [event, setEvent] = useState(false);

  useEffect(() => {
    const skillData = async () => {
      const response = await getSkill();
      if (response) {
        setSkillData(response.skillGroup);
        setStateFilter(response.skillGroup);
      }
    };

    skillData();
  }, [triggerAPI]);

  useEffect(() => {
    if (selectSkill) {
      handleSelectSkill(selectSkill);
    }
    
  }, [event])
  

  const handleSelectSkill = async (key) => {
    console.log('skillSort',sort);
    const response = await getbyId(key.toString(),_.get(sort, 'order') === 'descend' ? 'desc' : 'asc',_.get(sort, 'field'),);
    if (_.size(response) !== 0) {
      setSkillDetail(response.detail);
      setSkillList(response.detail.skills);
    }
    setSelectSkill(key);
    setStatusAdd(false);
    setStatusEdit(true);
    setDefaultKeys(key.toString());
  };

  const onChangeTable = (value,index,sort) => {
    setSort(sort)
    setEvent((current) => !current);
  };

  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          checkPermissionAction('P15PG1C1', 'P15PG1C1A1') ? (
            <Row gutter={[16]}>
              <Col span={6}>
                <SkillMenu
                  menuData={skillData}
                  setMenuData={setSkillData}
                  setSelectSkill={setSelectSkill}
                  setStatusAdd={setStatusAdd}
                  statusAdd={statusAdd}
                  setStatusEdit={setStatusEdit}
                  setSkillDetail={setSkillDetail}
                  setSkillList={setSkillList}
                  handleSelectSkill={handleSelectSkill}
                  defaultKeys={defaultKeys}
                  stateFilter={stateFilter}
                  sortMenu={sortMenu}
                  setSortMenu={setSortMenu}
                />
              </Col>
              <Col span={18}>
                {selectSkill ? (
                  <SkillDetail
                    intl={intl}
                    statusAdd={statusAdd}
                    setStatusAdd={setStatusAdd}
                    statusEdit={statusEdit}
                    setStatusEdit={setStatusEdit}
                    setTriggerAPI={setTriggerAPI}
                    skillDetail={skillDetail}
                    setSelectSkill={setSelectSkill}
                    skillList={skillList}
                    handleSelectSkill={handleSelectSkill}
                    setDefaultKeys={setDefaultKeys}
                    sort={sort}
                    onChangeTable={onChangeTable}
                  />
                ) : (
                  <Card className="blank-card">
                    {/* <div className='blank-content'>
                      <div>
                        <Icon className="blank-icon" type="user" />
                      </div>
                      <div>
                        <p className="blank-text">
                          <FormattedMessage id="skillDetailBlankPage1" defaultMessage="Please select skill group." /> <br />
                          <FormattedMessage id="skillDetailBlankPage2" defaultMessage="Skill Information will appear here." />
                        </p>
                      </div>
                    </div> */}
                    <NoDataBackground
                      text1={{ id: 'nodataXSkill1', text: 'Please select a Skill Group' }}
                      text2={{ id: 'nodataXSkill2', text: 'Skill Information will appear here.' }}
                    />
                  </Card>
                )}
              </Col>
            </Row>
          ) : null
        }
      </PageSettings.Consumer>
    </div>
  );
};
