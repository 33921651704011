import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Popover, Button, Icon, Modal, Tag } from 'antd';
import TableInvoice from './table-invoice';
import ActionInvoice from './action-invoice';
import SummaryInvoice from './summary-invoice';
import Button01 from '../../../components/v2/button_01';
import moment from 'moment';
import { debounce } from 'lodash';
import httpClient from '../../../components/axiosClient';
import { successNotification } from '../../../components/v2/notification';
import NumberFormat from 'react-number-format';
import PrintTest from '../../../components/print/print-test';
import ReactToPrint from 'react-to-print';
import CountPrint from '../../../controllers/invoice/count-print-invoice';
import { PageSettings } from '../../../config/page-settings';
import style from './css/index.css';
import _ from 'lodash';

const { confirm } = Modal;

export default ({
  setVisibleAddInvoice,
  setTriggerOnClickCreate,
  triggerOnClickCreate,
  setVisibleEditInvoice,
  setData,
  triggerAPI,
  setDataSelection,
  setVisibleViewInvoice,
  setCustomerId,
  setTriggerID,
  triggerID,
  setDataCompany,
  triggerPrint,
}) => {
  const componentRef = useRef();
  const intl = useIntl();

  const [visiblePopover, setVisiblePopover] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({ id: 'invoiceMainPageColumnTableInvoiceNo', defaultMessage: 'Invoice No' }),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: (a, b) => a.invoiceNo - b.invoiceNo,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'invoiceMainPageColumnTableCustomerName', defaultMessage: 'Customer Name' }),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: (a, b) => a.customerName - b.customerName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'invoiceMainPageColumnTableTotalTask', defaultMessage: 'Total Task' }),
      dataIndex: 'totalTask',
      key: 'totalTask',
      align: 'center',
      sorter: (a, b) => a.totalTask - b.totalTask,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'invoiceMainPageColumnTableTotalBaht', defaultMessage: 'Total (Baht)' }),
      dataIndex: 'totalBath',
      key: 'totalBath',
      align: 'center',
      sorter: (a, b) => a.totalBaht - b.totalBaht,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.totalBath).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({ id: 'invoiceMainPageColumnTableCreateDate', defaultMessage: 'Create Date' }),
      dataIndex: 'createDate',
      key: 'createDate',
      sorter: (a, b) => a.createDate - b.createDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'invoiceMainPageColumnTableStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        if (record.status == 'draft') {
          return (
            <div className="tag-center-div">
              <Tag className="tag-center-style" color="#707070">
                <FormattedMessage id="invoiceMainPageColumnTableStatusDraft" defaultMessage="Draft" />
              </Tag>
            </div>
          );
        } else if (record.status == 'cancel') {
          return (
            <div className="tag-center-div">
              <Tag className="tag-center-style" color="#9a9999">
                <FormattedMessage id="invoiceMainPageColumnTableStatusCancel" defaultMessage="Cancel" />
              </Tag>
            </div>
          );
        } else if (record.status == 'submit') {
          return (
            <div className="tag-center-div">
              <Tag className="tag-center-style" color="#21723e">
                <FormattedMessage id="invoiceMainPageColumnTableStatusComplete" defaultMessage="Complete" />
              </Tag>
            </div>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      align: 'center',
      width: 70,
      render: (text, record, index) => fncActionTable(record, index),
    },
  ];

  const setShowColumn = {
    index: true,
    invoiceNo: true,
    customerName: true,
    totalTask: true,
    totalBath: true,
    createDate: true,
    status: true,
    edit: true,
  };

  const setShowColumnArr = ['index', 'invoiceNo', 'customerName', 'totalTask', 'totalBath', 'createDate', 'status', 'edit'];

  const comCode = localStorage.getItem('comCode');
  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  //----------------------------- Action ---------------------------------
  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';
  const dateNowRangePicker = [moment(today, dateFormat), moment(today, dateFormat)];
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [visible, setVisible] = useState(false);
  const [rangePicker, setRangePicker] = useState(dateNowRangePicker);
  const [fromDate, setFromDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [selectSearchData, setSelectSearchData] = useState('all');
  const [fieldChang, setFieldChang] = useState('');
  const [dataInvoice, setDataInvoice] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [dataSummaryHeader, setDataSummaryHeader] = useState();
  const [dataReprint, setdataReprint] = useState();
  const [dataCompanyReprint, setdataCompanyReprint] = useState();

  const [refreshCancel, setRefreshCancel] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);

  //----------------------------- Normal State ---------------------------------

  useEffect(() => {
    getDataTable();
  }, [
    fromDate,
    toDate,
    fieldChang,
    selectSearchData,
    orderSort,
    fieldSort,
    paginationPage,
    refreshCancel,
    refreshDelete,
    triggerAPI,
    paginationSize,
  ]);

  const getDataTable = async () => {
    const body = {
      startDate: fromDate,
      endDate: toDate,
      search: fieldChang,
      searchBy: selectSearchData,
      orderType: orderSort,
      orderBy: fieldSort,
      paging: fieldChang === '' ? paginationPage : 1,
      rowsPerPages: paginationSize,
    };
    try {
      const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/invoice/getdata/search`, body);
      if (response.status == 200) {
        setDataInvoice(response.data.data.data);
        setTotal(response.data.data.allRows);
        setDataSummaryHeader(response.data.data);
      }
    } catch (error) {
      return;
    }
  };

  const handleEdit = async (record) => {
    setTriggerID(!triggerID);
    try {
      const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/invoice/getdatabyid/${record.invoiceId}`);

      if (response.status == 200) {
        setData(response.data.data[0]);
        setCustomerId(response.data.data ? response.data.data[0].customerId : undefined);
        setVisibleEditInvoice(true);
        setDataSelection(response.data.data[0].detail);
      }
    } catch (error) {
      return;
    }
  };

  const handleView = async (record) => {
    // console.log('handleView record:: ', record)

    try {
      const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/invoice/getdatabyid/to-print/${record.invoiceId}`);
      // console.log('handleView response api::: ', response)
      if (response.status == 200) {
        setData(response.data.data.items[0]);
        setDataCompany(response.data.data.comInfo);
        setVisibleViewInvoice(true);
      }
    } catch (error) {
      return;
    }
  };

  const modalConfirmCancel = (invId) => {
    confirm({
      className: 'holiday-modal-confirm',
      icon: 'none',
      title: intl.formatMessage({ id: 'invoiceMainPageCancelInvoiceText', defaultMessage: 'Are you sure cancel this invoice ?' }),
      okType: 'danger',
      centered: true,
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      okText: intl.formatMessage({ id: 'settingHolidaysModalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      async onOk() {
        const body = {
          invoiceStatus: 'cancel',
        };
        try {
          const response = await httpClient.put(`/v3/invoice/manager/company/${comCode}/invoice/updatedata?invoiceId=${invId}`, body);
          if (response.status === 200) {
            successNotification(response.data.message);
            setRefreshCancel(!refreshCancel);
          }
        } catch (error) {
          return;
        }
        setRefreshCancel(false);
      },
      onCancel() {},
    });
  };

  const modalConfirmDelete = (invId) => {
    confirm({
      className: 'holiday-modal-confirm',
      icon: 'none',
      title: intl.formatMessage({ id: 'invoiceMainPageDeleteInvoiceText', defaultMessage: 'Are you sure delete this invoice ?' }),
      okType: 'danger',
      centered: true,
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      okText: intl.formatMessage({ id: 'settingHolidaysModalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      async onOk() {
        const body = {
          deleted: true,
        };
        try {
          const response = await httpClient.put(`/v3/invoice/manager/company/${comCode}/invoice/updatedata?invoiceId=${invId}`, body);
          if (response.status === 200) {
            successNotification(response.data.message);
            setRefreshDelete(!refreshDelete);
          }
        } catch (error) {}
        setRefreshDelete(false);
      },
      onCancel() {},
    });
  };

  const Reprint = async (record) => {
    try {
      const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/invoice/getdatabyid/to-print/${record.invoiceId}`);

      if (response.status == 200) {
        setdataReprint(response.data.data.items[0]);
        setdataCompanyReprint(response.data.data.comInfo);
      }
    } catch (error) {
      return;
    }
  };

  const countPrintsInvoice = async (invoiceId) => {
    await CountPrint(invoiceId);
    setVisiblePopover(false);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const onSelectRangePicker = (date, dateString) => {
    setRangePicker(dateString);
    setFromDate(moment(date[0]).format('YYYY-MM-DD'));
    setToDate(moment(date[1]).format('YYYY-MM-DD'));
  };

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const fncActionTable = (record, index) => {
    if (record.status === 'draft') {
      return (
        <div>
          <Popover
            key={index}
            placement="leftTop"
            trigger="hover"
            visiblePopover={visiblePopover}
            onVisibleChange={handleOnVisiblePopover}
            zIndex={999}
            content={
              <div style={{ display: 'grid' }}>
                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P10PG1C1', 'P10PG1C1A3') ? (
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>

                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P10PG1C1', 'P10PG1C1A4') ? (
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => modalConfirmDelete(record.invoiceId)}>
                            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>
              </div>
            }
          >
            <Icon type="more" />
          </Popover>
        </div>
      );
    } else if (record.status === 'submit') {
      return (
        <div>
          <Popover
            key={index}
            placement="leftTop"
            trigger="hover"
            visiblePopover={visiblePopover}
            onVisibleChange={handleOnVisiblePopover}
            zIndex={999}
            content={
              <div style={{ display: 'grid' }}>
                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P10PG1C1', 'P10PG1C1A8') ? (
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                            <FormattedMessage id="btnView" defaultMessage="View" />
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>

                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P10PG1C1', 'P10PG1C1A9') ? (
                          <ReactToPrint
                            trigger={() => (
                              <Button style={{ width: 100 }} type="link" ghost>
                                <FormattedMessage id="btnReprint" defaultMessage="Re-print" />
                              </Button>
                            )}
                            content={() => componentRef.current}
                            onBeforeGetContent={() => Reprint(record)}
                            onAfterPrint={() => countPrintsInvoice(record.invoiceId)}
                          />
                        ) : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>

                <>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <div>
                        {checkPermissionAction('P10PG1C1', 'P10PG1C1A5') ? (
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => modalConfirmCancel(record.invoiceId)}>
                            <p>
                              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                            </p>
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </PageSettings.Consumer>
                </>
              </div>
            }
          >
            <Icon type="more" />
          </Popover>
        </div>
      );
    } else if (record.status === 'cancel') {
      return (
        <div>
          <Popover
            key={index}
            placement="leftTop"
            trigger="hover"
            visiblePopover={visiblePopover}
            onVisibleChange={handleOnVisiblePopover}
            zIndex={999}
            content={
              <div style={{ display: 'grid' }}>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P10PG1C1', 'P10PG1C1A8') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                          <p>
                            <FormattedMessage id="btnView" defaultMessage="View" />
                          </p>
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </div>
            }
          >
            <Icon type="more" />
          </Popover>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Card
      className="invoice-card"
      title={intl.formatMessage({ id: 'invoiceMainPageCardHeadertext', defaultMessage: 'Invoice' })}
      extra={
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {checkPermissionAction('P10PG1C1', 'P10PG1C1A2') ? (
                <Button01
                  type="primary"
                  btnsize="wd_sm"
                  styleMargin="mg_cs3"
                  onClick={() => {
                    setVisibleAddInvoice(true);
                    setTriggerOnClickCreate(!triggerOnClickCreate);
                  }}
                >
                  <FormattedMessage id="invoiceMainPageButtonAddInvoice" defaultMessage="Create Invoice" />
                </Button01>
              ) : null}
            </div>
          )}
        </PageSettings.Consumer>
      }
    >
      <div>
        <SummaryInvoice dataInvoice={dataSummaryHeader} />
      </div>
      <div className="margin-component">
        <ActionInvoice
          columns={columns}
          checked={defaultShowColumn}
          checkedValuecolumns={checkedValuecolumns}
          textErrorSelectColumn={textErrorSelectColumn}
          handleCheckOk={handleCheckOk}
          handleReset={handleReset}
          visible={visible}
          handleVisibleChange={handleVisibleChange}
          handleOnVisiblecolumns={handleOnVisiblecolumns}
          onSelectRangePicker={onSelectRangePicker}
          onChange={handleSearch}
          onSelect={handleSelectData}
          selectSearchData={selectSearchData}
        />
      </div>
      <div>
        <TableInvoice
          columns={newDataColumns}
          dataInvoice={dataInvoice}
          handleChange={handleChange}
          handlePagination={handlePagination}
          handleSizeChange={handleSizeChange}
          showTotal={showTotal}
          total={total}
        />
      </div>
      {dataInvoice && dataInvoice.length !== 0 ? (
        <div className="total-items-overtime-report">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      ) : null}
      {dataReprint !== undefined && dataCompanyReprint !== undefined ? (
        <PrintTest ref={componentRef} data={dataReprint} dataCompany={dataCompanyReprint} />
      ) : null}
    </Card>
  );
};
