import React, { useRef, useState, useEffect } from 'react';
import { Modal, Tabs, Form, Row, Col, Spin } from 'antd';
import Overview from './overview';
import Details from './details';
import Upload from './upload';
import Resizer from 'react-image-file-resizer';
import DefaultProfile from '../../image/NoImage.svg';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css';
import _ from 'lodash';
import { updatAsset, saveAssetImg } from '../../../controllers/asset-resource/asset-controller';
import moment from 'moment';
import { successNotification, errorNotification } from '../../v2/notification';
import DetailView from './detail-view';
import UploadView from './upload-view';
import History from './history';
import OverviewView from './overview-view';
import { getByIdAsset } from '../../../controllers/asset-resource/asset-controller';

const { TabPane } = Tabs;

const ModalAddAsset = React.memo((props) => {
  const { visible, setVisible, form, typeModal, defaultEdit, setMainTrigger, setIsEdit } = props;
  const intl = useIntl();
  const { validateFields, resetFields } = form;
  const imageRef = useRef();
  const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');
  const [imageAsset, setImageAsset] = useState();
  const [statusEdit, setStatusEdit] = useState('view');
  const [dataDefault, setDataDefault] = useState();
  const [trigger, setTrigger] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [disbleButton, setDisbleButton] = useState(false);
  const [keyTab, setKeyTab] = useState('1');

  const priceRef = useRef();
  const salvagePriceRef = useRef();
  const assetPriceRef = useRef();

  useEffect(() => {
    const getData = async () => {
      if (visible && defaultEdit) {
        const response = await getByIdAsset(_.get(defaultEdit, 'assetId'));
        setImageFileDefault(_.get(response, 'data[0].pathImage'));
        setDataDefault(_.get(response, 'data[0]'));
      }
    };
    getData();
  }, [_.get(defaultEdit, 'assetId'), visible, trigger]);

  const handleOk = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setDisbleButton(true);
      const assetValueDate = _.get(values, 'assetValueDate') ? moment(_.get(values, 'assetValueDate')).format('YYYY-MM-DD') : undefined;
      const purchaseDate = _.get(values, 'purchaseDate') ? moment(_.get(values, 'purchaseDate')).format('YYYY-MM-DD') : undefined;
      const mapSuitable = _.get(values, 'suitable')
        ? _.get(values, 'suitable').map((item) => {
          return {
            suitableId: item,
          };
        })
        : undefined;

      const body = {
        assetClassId: _.get(values, 'assetClass') ? _.get(values, 'assetClass') : undefined,
        transactionTypeId: _.get(values, 'source') ? _.get(values, 'source') : undefined,
        assetBrandId: _.get(values, 'brand') ? _.get(values, 'brand') : undefined,
        assetModelId: _.get(values, 'model') ? _.get(values, 'model') : undefined,
        assetTypeId: _.get(values, 'assetType') ? _.get(values, 'assetType') : undefined,
        assetName: _.get(values, 'assetName') ? _.get(values, 'assetName') : undefined,
        salvagePrice: _.get(values, 'salvagePrice') ? _.get(values, 'salvagePrice') : 0,
        price: _.get(values, 'price') ? _.get(values, 'price') : 0,
        orgId: _.get(values, 'owner') ? _.get(values, 'owner') : undefined,
        assetValueDate: assetValueDate ? assetValueDate : null,
        postingDate: purchaseDate ? purchaseDate : null,
        description: _.get(values, 'remark') ? _.get(values, 'remark') : '',
        registerBy: _.get(values, 'register') ? _.get(values, 'register') : undefined,
        serialNo: _.get(values, 'serialNo') ? _.get(values, 'serialNo') : undefined,
        detail: [],
        suitable: mapSuitable,
        assetBookValue: _.get(values, 'assetBookValue') ? _.get(values, 'assetBookValue') : 0,
      };

      const response = await updatAsset(_.get(dataDefault, 'assetId'), body);
      if (response.status === 200) {
        successNotification(response.message);
        setIsEdit(true);
        if (typeModal !== 'view') {
          setVisible(false);
        }
        setDisbleButton(false);
        // setVisible(false)
        if (imageAsset) {
          let formData = new FormData();
          formData.append('file', imageAsset);
          await saveAssetImg(_.get(dataDefault, 'assetId'), formData);
        }
        // getDataOverview();
        setImageAsset();
        setImageFile();
        resetFields();
        setStatusEdit('view');
        setTrigger((current) => !current);
        setMainTrigger((current) => !current);
        imageRef.current.value = null;
      } else {
        setDisbleButton(false);
        errorNotification(response.message);
      }
    });
  };

  const handleCLose = () => {
    setVisible(false);
    setImageAsset();
    setImageFile();
    resetFields();
    setStatusEdit('view');
    imageRef.current.value = null;
  };

  const handleCancel = () => {
    setStatusEdit('view');
    setImageFile();
    setImageAsset();
    imageRef.current.value = null;
  };

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);
        reader.onloadend = () => {
          const image = reader.result;
          //setImage(image);
          setImageFile(image);
        };
        reader.readAsDataURL(file);

        const fileChange = dataURLtoFile(image, file.name);
        setImageAsset(fileChange);
      } else {
        errorNotification('File not supported');
      }
    }
  };

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const deleteImage = (e) => {
    setImageFile('');
    setImageAsset();
    imageRef.current.value = null;
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const tabKeyChange = (value) => {
    // console.log('tabKeyChange::', value);
    setKeyTab(value);
  };

  const mouseOver = () => {

    priceRef.current.blur();
    salvagePriceRef.current.blur()
    assetPriceRef.current.blur()
  }

  return (
    <Modal
      title={_.get(dataDefault, 'assetNo')}
      visible={visible}
      width={871}
      centered={true}
      onCancel={handleCLose}
      bodyStyle={{ padding: '0px', height: 'auto', minHeight: '510px' }}
      footer={
        typeModal === 'view'
          ? statusEdit === 'view'
            ? [
              <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCLose}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button02>,
              <Button01
                key="submit"
                type="primary"
                btnsize="wd_df"
                onClick={() => {
                  setSpinner(true);
                  setStatusEdit('edit');
                  setTimeout(() => {
                    setSpinner(false);
                  }, 1000);
                }}
              >
                <FormattedMessage id="btnEdit" defaultMessage="Edit" />
              </Button01>,
            ]
            : [
              <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel} disabled={disbleButton}>
                <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleOk} disabled={disbleButton} onMouseOver={mouseOver}>
                <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
              </Button01>,
            ]
          : [
            <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCLose} disabled={disbleButton}>
              <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleOk} disabled={disbleButton} onMouseOver={mouseOver}>
              <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
            </Button01>,
          ]
      }
    >
      <Tabs className="asset-tab-tabpane" onChange={tabKeyChange}>
        <TabPane tab={intl.formatMessage({ id: 'modalAssetAssetTabOverView', defaultMessage: 'Overview' })} key="1">
          {typeModal === 'view' && statusEdit === 'view' ? (
            <Row style={{ padding: '4px 24px 24px 0px' }}>
              <Col span={7}>
                <UploadView imageRef={imageRef} imageFile={imageFile} imageFileDefault={imageFileDefault} />
              </Col>
              <Col span={17}>
                <OverviewView form={form} dataDefault={dataDefault} />
              </Col>
            </Row>
          ) : (
            <Row style={{ padding: '4px 24px 24px 0px' }}>
              <Col span={7}>
                <Upload
                  imageRef={imageRef}
                  imageFile={imageFile}
                  imageFileDefault={imageFileDefault}
                  handleChangePicture={handleChangePicture}
                  showOpenFileDialog={showOpenFileDialog}
                  deleteImage={deleteImage}
                />
              </Col>
              <Col span={17}>
                <Overview form={form} dataDefault={dataDefault} setSpinner={setSpinner} />
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'modalAssetAssetTabDetails', defaultMessage: 'Details' })} key="2">
          {typeModal === 'view' && statusEdit === 'view' ? (
            <Row style={{ padding: '4px 24px 24px 0px' }}>
              <Col span={7}>
                <UploadView imageRef={imageRef} imageFile={imageFile} imageFileDefault={imageFileDefault} />
              </Col>
              <Col span={17}>
                <DetailView form={form} dataDefault={dataDefault} />
              </Col>
            </Row>
          ) : (
            <Row style={{ padding: '4px 24px 24px 0px' }}>
              <Col span={7}>
                <Upload
                  imageRef={imageRef}
                  imageFile={imageFile}
                  imageFileDefault={imageFileDefault}
                  handleChangePicture={handleChangePicture}
                  showOpenFileDialog={showOpenFileDialog}
                  deleteImage={deleteImage}
                />
              </Col>
              <Col span={17}>
                <Details form={form} dataDefault={dataDefault} priceRef={priceRef} salvagePrice={salvagePriceRef} assetPriceRef={assetPriceRef} />
              </Col>
            </Row>
          )}
        </TabPane>
        {/* <TabPane tab="History" key="3">
          <Row>
            <Col span={24}>
              <History dataDefault={dataDefault} keyTab={keyTab} visible={visible} />
            </Col>
          </Row>
        </TabPane> */}
      </Tabs>
    </Modal>
  );
});

const ModalAsset = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) { },
})(ModalAddAsset);

export default React.memo(ModalAsset, (prev, next) => {
  return prev.visible === next.visible;
});
