import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import LabeRequireForm from '../label-required-form/index';
import addPositionMaster from '../../controllers/team/post-add-position-master';
import updatePositionMaster from '../../controllers/team/put-update-position-master';
import { successNotification, errorNotification } from '../../components/v2/notification';
import './position-modal.css';

const PositionModalForm = (props) => {
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields } = props.form;

  const [isOpenBtn, setIsOpenBtn] = useState(false);

  const handleAddPosition = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setIsOpenBtn(true);
      if (props.statusOpen === 'add') {
        if (values.positionName) {
          const response = await addPositionMaster({ positionName: values.positionName });
          if (response.status >= 400) {
            errorNotification(response.statusText);
            setIsOpenBtn(false);
          } else {
            props.setTriggerPositionApi((current) => !current);
            successNotification(response.status.message);
            setTimeout(() => {
              // props.setSeletePositionData(response.data.positionMaster);
              props.setFieldsValue({ ['position']: response.data.positionMaster.positionId });
            }, 500);
            setIsOpenBtn(false);
          }
        }
      }

      if (props.statusOpen === 'edit') {
        if (values.positionName) {
          const response = await updatePositionMaster({
            positionName: values.positionName,
            positionId: props.seletePositionData.positionId,
          });
          if (response.status >= 400) {
            errorNotification(response.statusText);
            setIsOpenBtn(false);
          } else {
            props.setTriggerPositionApi((current) => !current);
            successNotification(response.status.message);
            setTimeout(() => {
              // props.setSeletePositionData(response.data.positionMaster);
              props.setFieldsValue({ ['position']: response.data.positionMaster.positionId });
            }, 500);
            setIsOpenBtn(false);
          }
        }
      }
      props.setIsOpenModalPosition(false);
      resetFields();
    });
  };

  const handleCancel = () => {
    props.setIsOpenModalPosition(false);
    setIsOpenBtn(false);
    resetFields();
  };

  return (
    <div className="assign-team">
      <Modal
        className="assign-team-modal"
        centered
        width={600}
        visible={props.isOpenModalPosition}
        title={
          props.statusOpen === 'add'
            ? intl.formatMessage({ id: 'teamAddEditValidatePositionAddNew', defaultMessage: 'Add New Position' })
            : intl.formatMessage({ id: 'teamAddEditValidatePositionEditNew', defaultMessage: 'Edit Position' })
        }
        onOk={handleAddPosition}
        onCancel={handleCancel}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}}  key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleAddPosition} disabled={isOpenBtn}>
            <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'teamAddEditPositionName', defaultMessage: 'Position Name' })} req={true} />
            }
          >
            {getFieldDecorator('positionName', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'teamAddEditValidatePosition', defaultMessage: 'Enter Position Name' }),
                },
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'teamAddEditPlaceholerPosition', defaultMessage: 'Enter Position Name' })}
                maxLength={100}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const AddPositionModal = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields(props) {
    return {
      positionName: Form.createFormField({
        value: props.seletePositionData !== undefined ? props.seletePositionData.positionName : undefined,
      }),
    };
  },
})(PositionModalForm);

export default AddPositionModal;
