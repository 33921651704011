import React, { useEffect, useState, useRef } from 'react';
import { Card, Col, Icon, Row } from 'antd';
import Button_01 from '../../components/v2/button_01';
import { FormattedMessage } from 'react-intl';
import FineFillter from './fillter';
import { FineContext } from './finecontext';
import _ from 'lodash';
import FineTable from './fineTables';
import FineModalForm from './fine-create-modal';
import FineViewModalForm from './fine-view-modal';
import { getFinelist, getReason } from '../../controllers/fine/fineapi';
import moment from 'moment';
import { PageSettings } from '../../config/page-settings';

const FinePage = () => {
  const [dataFine, setDataFine] = useState([]);
  const [searchGroup, setSearchGroup] = useState([]);
  const [record, setRecord] = useState();
  const [reason, setReason] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleViewModal, setVisibleViewModal] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [event, setEvent] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [sort, setSort] = useState([]);
  const form = useRef();

  useEffect(() => {
    getFineApi();
  }, [page, size, event, searchGroup]);

  useEffect(() => {
    getReasonitem();
  }, []);

  const getFineApi = async () => {
    setIsLoading(true);

    try {
      const payload = {
        where: {
          fineNo: searchGroup.no || undefined,
          employeeName: searchGroup.employee || undefined,
          amountMinimum: searchGroup.min || undefined,
          amountMaximum: searchGroup.max || undefined,
          fineStartDate: searchGroup.start ? moment(searchGroup.start).format('YYYY-MM-DD') : undefined,
          fineEndDate: searchGroup.end ? moment(searchGroup.end).format('YYYY-MM-DD') : undefined,
          reasonIds: searchGroup.reason || undefined,
        },
        order: _.get(sort, 'field'),
        orderBy: _.get(sort, 'order') === 'descend' ? 'desc' : 'asc',
        page: page,
        pageSize: size,
      };
      console.log('payloadAllo', payload);
      const response = await getFinelist(payload);
      console.log('response', response);
      if (_.get(response.status, 'code') === 200) {
        setDataFine(response.data);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const getReasonitem = async () => {
    try {
      const payload = {
        where: {},
        order: [['created_at', 'asc']],
        page: 1,
        pageSize: 1000,
      };
      const response = await getReason(payload);
      if (_.get(response.status, 'code') === 200) {
        setReason(response.data);
        setEvent((current) => !current);
      }
    } catch (error) {}
  };

  const getRecord = async (id) => {
    setIsLoading(true);
    console.log('idFine', id);
    try {
      const payload = {
        where: {
          fineNo: _.get(id, 'fineNo'),
        },
        order: 'fineNo',
        orderBy: 'asc',
        page: 1,
        pageSize: 10,
      };
      console.log('payloadAllo', payload);
      const response = await getFinelist(payload);
      console.log('response', response);
      if (_.get(response.status, 'code') === 200) {
        setRecord(_.get(response.data, 'rows[0]'));
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="FineNo" defaultMessage="Fine No." />,
      dataIndex: 'fineNo',
      key: 'fineNo',
      sorter: true,
      fixed: 'left',
    },
    {
      title: <FormattedMessage id="FineEmployee" defaultMessage="Employee" />,
      dataIndex: 'employeeName',
      key: 'employeeName',
      sorter: true,
      fixed: 'left',
    },
    {
      title: <FormattedMessage id="FineAmount" defaultMessage="Amount" />,
      dataIndex: 'fineAmount',
      key: 'amount',
      sorter: true,
      render: (text, record, index) => <span>{record.amountText} THB</span>,
      // width: 200,
    },
    {
      title: <FormattedMessage id="FineDate" defaultMessage="Fine Date" />,
      key: 'fineDate',
      dataIndex: 'fineDate',
      sorter: true,
      render: (text, record, index) => <span>{record.fineDateText} THB</span>,
    },
    {
      title: <FormattedMessage id="FineReason" defaultMessage="Reason" />,
      key: 'fineReasonId',
      dataIndex: 'reasonName',
      sorter: true,
      render: (text, record, index) => <span>{record.fineReasonName} THB</span>,
    },
    {
      title: <FormattedMessage id="FineDescription" defaultMessage="Description" />,
      key: 'description',
      dataIndex: 'description',
      sorter: true,
    },
  ];

  const handleOpenModal = (type, record) => {
    setVisibleModal(true);
    console.log('openmodal', form);
    if (type === 'edit') {
      getRecord(record);
    } else {
      setRecord();
    }
  };

  const handleOpenViewModal = (record) => {
    setVisibleViewModal(true);
    setVisibleModal(false);
    getRecord(record);
  };

  const handleChangePage = (page) => {
    console.log('pageFine', page);
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={handleToggle}>
          <Icon type={toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="menuFine" defaultMessage="Fine" />
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          checkPermissionAction(`P51PG1C1`, `P51PG1C1A1`) ? (
            <FineContext.Provider
              value={{
                state: {
                  searchGroup,
                  isLoading,
                  page,
                  size,
                  visibleModal,
                  visibleViewModal,
                  record,
                  reason,
                  dataFine,
                  toggle,
                },
                setState: {
                  setSearchGroup,
                  setIsLoading,
                  setPage,
                  setSize,
                  setVisibleModal,
                  setVisibleViewModal,
                  setEvent,
                  setSort,
                },
                fnc: {
                  handleOpenModal,
                  handleOpenViewModal,
                  handleChangePage,
                  handleChangeSize,
                  getRecord,
                },
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={toggle ? 0 : 6}>
                  <Card
                    className="left-card-asset"
                    bodyStyle={{ padding: 'unset' }}
                    title={<FormattedMessage id="Filtter" defaultMessage="Fillter" />}
                  >
                    <FineFillter />
                  </Card>
                </Col>
                <Col span={toggle ? 24 : 18}>
                  <Card
                    className="right-card-asset"
                    bodyStyle={{ padding: 'unset' }}
                    title={<HeaderAndToggle />}
                    extra={
                      checkPermissionAction(`P51PG1C1`, `P51PG1C1A2`) ? (
                      <div className="extra-user-details">
                        <div>
                          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModal('create')}>
                            <FormattedMessage id="btnCreate" defaultMessage="Create" />
                          </Button_01>
                        </div>
                      </div>
                      ): null
                    }
                  >
                    <FineTable columns={columns} />
                  </Card>
                </Col>
              </Row>

              <FineModalForm />
              <FineViewModalForm />
            </FineContext.Provider>
          ) : null
        }
      </PageSettings.Consumer>
    </div>
  );
};

export default FinePage;
