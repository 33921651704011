import React from 'react';
import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

export const fncHasTeam = async (comId = null) => {
  try {
    const body = {
      comId: comId,
    };
    const result = await httpClient.post(`/v5/resource/manager/company/${comCode}/web/member-has-team`, body);
    if (result.status === 200) {
      return result.data.data;
    }
    else {
      return false;
    }
  }
  catch (e) {
    return false;
  }
}