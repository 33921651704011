import React from 'react'
import './css/index.css'
import { Form, Row, Col, Select } from 'antd'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import styled from 'styled-components';

const { Option } = Select

export default (props) => {
  const { editData } = props

  const intl = useIntl()

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e', fontWeight: 'bold' }}>*</span> : ''}
      </span>
    );
  };


  const SelectStatus = styled(Select)`
    width: 'auto';
    .ant-select-selection--single {
      border-radius: 20px;
      height: 24px;
      color: #fff;
      width: fit-content;
      font-size: 13px;
      background-color: ${_.get(editData, 'statusDetail.statusColor')};
      border: 1px solid ${_.get(editData, 'statusDetail.statusColor')};
    }
    .ant-select-selection-selected-value {
      margin-right: 5px;
      margin-top: 0px;
      color: #ffffff !important;
    }
    .ant-select-arrow .ant-select-arrow-icon svg {
      fill : #fff;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover,
    .ant-select-open .ant-select-selection {
      border-color: ${_.get(editData, 'statusDetail.statusColor')} !important;
      box-shadow: none !important;
    }
  `;


  return (
    <div style={{ padding: '16px 24px' }}>
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextCustomer', defaultMessage: 'Customer' })} req={false} />}>
              <div className='order-view-text-div-inline2'>
                <span className='order-view-text'>{_.get(editData, 'customerDetail.comtomerName')}</span>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextStatus', defaultMessage: 'Status' })} req={false} />}>
              <SelectStatus
                dropdownStyle={{ minWidth: '200px' }}
                size='small'
                value={_.get(editData, 'statusDetail.statusId')}
                disabled={true}
                showArrow={false}
              >
                <Option key={_.get(editData, 'statusDetail.statusId')}>
                  {_.get(editData, 'statusDetail.statusName')}
                </Option>
              </SelectStatus>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextSize', defaultMessage: 'Size' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData, 'size.sizeName')}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextWeight', defaultMessage: 'Weight' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData, 'weightTxt')}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view order-modal-form-view-remark'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextSource', defaultMessage: 'Source' })} req={false} />}>
              <div className='order-view-text-div-inline'>
                <span className='order-view-text order-view-text-div-inline'>{`${_.get(editData, 'sourceDetail.address_name') || ""} ${_.get(editData, 'sourceDetail.address_name') && _.get(editData, 'sourceDetail.address') ? "·" : ""} ${_.get(editData, 'sourceDetail.address') || ""}`}</span>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view order-modal-form-view-remark'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextDestination', defaultMessage: 'Destination' })} req={false} />}>
              <div className='order-view-text-div-inline'>
                <span className='order-view-text'>{`${_.get(editData, 'destinationDetail.address_name')} · ${_.get(editData, 'destinationDetail.address')}`}</span>F
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextDeliveryDate', defaultMessage: 'Delivery Date' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData, 'deliveryDateTxt') || '-'}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextDeliveryTime', defaultMessage: 'Delivery Time' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData, 'deliveryTimeTxt') || '-'}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextContact', defaultMessage: 'Contact' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData, 'contactDetail.customerContactName')}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextPhone', defaultMessage: 'Phone' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData, 'contactDetail.customerContactPhone')}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextReferenceType', defaultMessage: 'Reference Type' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData.referenceType, 'refTypeName') || '-'}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='order-modal-form-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextRefernceNo', defaultMessage: 'Refernce No.' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData, 'referenceNo') || '-'}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              className='order-modal-form-view order-modal-form-view-remark'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextRemark', defaultMessage: 'Remark' })} req={false} />}>
              <span className='order-view-text'>{_.get(editData, 'remark') || '-'}</span>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
