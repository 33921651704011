import { Col, DatePicker, Form, Row, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import { handleKeyDownDecimal2Fixed } from '../../../../component-function/fnc-number';
const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const PriceTaskFormNoTax = ({ form, selectItem, setDataForm, dataForm, initialTaxList, record }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const issueDate = getFieldValue('date');
  const intl = useIntl();
  const discount = parseFloat(getFieldValue('discount') || 0);
  const discountNumber = parseFloat(getFieldValue('discountNumber') || 0.0);
  // const tax = getFieldValue('tax');
  // const taxNumber = parseFloat(getFieldValue('taxNumber') || 0.0);
  const tax = 0
  const taxNumber = 0
  const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, (o) => _.toNumber(o.sumOtherAmount)) : 0.0;
  // const sumTotal = 3000.02

  const subtotalDiscount = sumTotal - discountNumber;
  const totalAll = parseFloat(subtotalDiscount) + taxNumber;
  const totalAllDisplay = numberFormatter(parseFloat(subtotalDiscount) + taxNumber);
  const sumTotalSum = sumTotal;
  console.log('PriceForm', selectItem, dataForm);
  console.log('PriceForm y', _.toNumber(sumTotal), sumTotal, _.toNumber(subtotalDiscount));
  console.log('PriceForm X', discount, discountNumber, tax, taxNumber, sumTotal, subtotalDiscount, totalAll);

  console.log('recorddddddddddd', record, discount, discountNumber, dataForm);

  const [startUseEffectSumTotalSum, setStartUseEffectSumTotalSum] = useState(false);

  useEffect(() => {
    console.log('sumTotalSum', sumTotalSum, discount);

    if (startUseEffectSumTotalSum) {
      if (discount) setFieldsValue({ ['discountNumber']: ((sumTotalSum * discount) / 100).toFixed(2) });
      // if (tax) setFieldsValue({ ['taxNumber']: ((parseFloat(sumTotalSum) * tax) / 100).toFixed(2) });

      if (sumTotalSum === 0) {
        console.log('sumTotalSum === 0');
        setFieldsValue({
          ['discount']: 0,
          ['discountNumber']: 0,
          ['tax']: 0,
          ['taxNumber']: 0,
        });
        setStartUseEffectSumTotalSum(false);
      }
    } else {
      setStartUseEffectSumTotalSum(true);
    }
  }, [sumTotalSum]);

  useEffect(() => {
    if (tax >= 0) {
      setFieldsValue({
        ['taxNumber']: ((parseFloat(subtotalDiscount) * tax) / 100).toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        sumTotals: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: (parseFloat(subtotalDiscount) * tax) / 100,
        taxPercentage: tax,
        discountPercent: discount,
        totalAll: totalAll,
        discountNumber : discountNumber
      }));
    }
  }, [tax, subtotalDiscount]);

  const onChangeDiscount = (value) => {
    console.log('onChangeDiscount', value);
    if (value && value > 0) {
      console.log('PriceForm X discountNumber', (sumTotal * value) / 100, sumTotal * value);
      setFieldsValue({
        // ['discount']: value.toFixed(2),
        ['discountNumber']: ((sumTotal * value) / 100).toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        sumTotals: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        taxPercentage: tax,
        discountPercent: discount,
        totalAll: totalAll,
        discountNumber : discountNumber
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        sumTotals: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        taxPercentage: tax,
        discountPercent: discount,
        totalAll: totalAll,
        discountNumber : discountNumber
      }));
    }
  };

  const onChangeDiscountNumber = (value) => {
    if (value && value > 0) {
      setFieldsValue({
        ['discount']: ((value / sumTotal) * 100).toFixed(2),
        // ['discountNumber']: value.toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        sumTotals: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        taxPercentage: tax,
        discountPercent: discount,
        totalAll: totalAll,
        discountNumber : discountNumber
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        sumTotals: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        taxPercentage: tax,
        discountPercent: discount,
        totalAll: totalAll,
        discountNumber : discountNumber
      }));
    }
  };

  // useEffect(() => {
  //   setDataForm((o) => ({
  //     ...o,
  //     totalAll: totalAll,
  //   }));
  // }, [tax, subtotalDiscount, discountNumber, sumTotal, discount, sumTotal]);
  return (
    <Form name="pirce" className="po-form">
      <Row gutter={[24]}>
        <Col span={10}>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {getFieldDecorator('remark', { initialValue: _.get(record, 'remark') || '' })(
                  <TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={250} />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelSubtotal" defaultMessage="Subtotal" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(sumTotal)} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelDiscount" defaultMessage="Discount" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">
                  <Form.Item colon={false}>
                    {getFieldDecorator('discount', {
                      initialValue: _.get(record, 'discountPercent') ? _.get(record, 'discountPercent').toFixed(2) : 0.0,
                    })(
                      <Input
                        suffix={intl.formatMessage({ id: 'quotationWarnPercent', defaultMessage: '%' })}
                        onChange={(e) => onChangeDiscount(e.target.value)}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="group-form-item-price-input">
                  <Form.Item colon={false}>
                    {getFieldDecorator('discountNumber', {
                      initialValue: _.get(record, 'discount') ? _.get(record, 'discount').toFixed(2) : 0.0,
                    })(
                      <Input
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                        onChange={(e) => onChangeDiscountNumber(e.target.value)}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelSubtotalAfterDiscount" defaultMessage="Subtotal After Discount" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(subtotalDiscount)} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          {/* <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelTax" defaultMessage="Tax" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">
                  <Form.Item colon={false}>
                    {getFieldDecorator('tax', {
                      initialValue: _.get(record, 'taxPercent') || _.get(initialTaxList, '[0].percentage'),
                    })(
                      <Select>
                        {_.map(initialTaxList, (item, i) => (
                          <Option key={i} value={item.percentage}>
                            {item.titleCode}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className="group-form-item-price-input">
                  <Form.Item colon={false}>
                    {getFieldDecorator('taxNumber', {
                      initialValue: _.get(record, 'tax') || 0.0,
                    })(
                      <Input
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        readOnly={true}
                        suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div> */}
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelTotal" defaultMessage="Total" />
              </span>
            </div>
            <div>
              <span>
                {totalAllDisplay} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PriceTaskFormNoTax.defaultProps = {};

export default PriceTaskFormNoTax;
