import React, { useEffect } from 'react'
import Style from './css/add.css'
import { Form, Row, Col, Input, DatePicker, Select, AutoComplete } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

const AddFormInsurance = (props) => {
  const { form, insuranceData, insuranceTypeData, setInsuranceTypeData, insuranceById, typeModal } = props
  const data = typeModal === "edit" ? _.get(insuranceById, 'assetInsuranceInfo') : {}
  const intl = useIntl()
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const dateFormat = 'DD/MM/YYYY';

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const children = insuranceData.map(item =>
    <Option key={item.insuranceId}>
      {item.insuranceName}
    </Option>
  );

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const insuranceName = getFieldValue('insuranceName')
    if (getFieldValue('insuranceName')) {
      const filterData = _.filter(insuranceData, (item) => { return item.insuranceId === insuranceName })
      setInsuranceTypeData(_.get(filterData, '[0].insuranceTypeList'))
    }
  }, [getFieldValue('insuranceName')]);

  // useEffect(() => {
  //   const Premium = getFieldValue('insuranceTypePremium') || 0
  //   const Discount = getFieldValue('insuranceDiscount') || 0
  //   console.log('insuranceDiscount', getFieldValue('insuranceDiscount'), Discount)

  //   if (Premium && Discount) {
  //     setFieldsValue({ ['insuranceDiscountPremium']: Premium - Discount ? Premium - Discount : null })
  //   } else if (Discount === 0){
  //     setFieldsValue({ ['insuranceDiscountPremium']: Premium ? Premium : null })
  //   }

  // }, [getFieldValue('insuranceDiscount')])

  //   useEffect(() => {
  //   console.log('insuranceDiscountPremium', getFieldValue('insuranceDiscountPremium'))
  //   const Premium = getFieldValue('insuranceTypePremium') || 0
  //   const DiscountPremium = parseFloat(getFieldValue('insuranceDiscountPremium') || 0)
  //   if (Premium && DiscountPremium) {
  //     setFieldsValue({ ['insuranceDiscount']: Premium - DiscountPremium ? Premium - DiscountPremium : null})
  //   } else if (DiscountPremium === 0) {
  //     setFieldsValue({ ['insuranceDiscount']: Premium ? Premium : null })
  //   }

  // }, [getFieldValue('insuranceDiscountPremium')]);

  // useEffect(() => {
  //   console.log('insuranceTypePremium', getFieldValue('insuranceTypePremium'))
  //   const Premium = getFieldValue('insuranceTypePremium') || 0

  //   if(Premium) {
  //     setFieldsValue({ ['insuranceDiscount']: null })
  //     setFieldsValue({ ['insuranceDiscountPremium']: null })
  //   }

  // }, [getFieldValue('insuranceTypePremium')])

  const onChangePremium = (premium) => {
    setFieldsValue({ ['insuranceDiscount']: null })
    setFieldsValue({ ['insuranceDiscountPremium']: null })
  }

  const onChangeDiscount = (discount) => {
    const Premium = getFieldValue('insuranceTypePremium') || 0

    if(discount && Premium) {
      setFieldsValue({ ['insuranceDiscountPremium']: Premium - discount ? Premium - discount : null })
    } else if (!discount) {
      setFieldsValue({ ['insuranceDiscountPremium']: Premium ? Premium : null })
    }
  }

  const onChangeDiscountPremium = (discountPremium) => {
    const Premium = getFieldValue('insuranceTypePremium') || 0
    if (Premium && discountPremium) {
      setFieldsValue({ ['insuranceDiscount']: Premium - discountPremium ? Premium - discountPremium : null})
    } else if (!discountPremium) {
      setFieldsValue({ ['insuranceDiscount']: Premium ? Premium : null })
    } 
  }

  // useEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   const Premium = getFieldValue('insuranceTypePremium') || 0
  //   const Discount = getFieldValue('insuranceDiscount') || 0
  //   if (Premium && Discount) {
  //     setFieldsValue({ ['insuranceDiscountPremium']: Premium - Discount })
  //   }

  // }, [getFieldValue('insuranceTypePremium'), getFieldValue('insuranceDiscount')]);

  // useEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   const Premium = getFieldValue('insuranceTypePremium') || 0
  //   const DiscountPremium = parseFloat(getFieldValue('insuranceDiscountPremium') || 0)
  //   if (Premium && DiscountPremium) {
  //     setFieldsValue({ ['insuranceDiscount']: Premium - DiscountPremium })
  //   }

  // }, [getFieldValue('insuranceTypePremium'), getFieldValue('insuranceDiscountPremium')]);

  // useEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   const Discount = parseFloat(getFieldValue('insuranceDiscount') || 0)
  //   const DiscountPremium = parseFloat(getFieldValue('insuranceDiscountPremium') || 0)
  //   if (Discount && DiscountPremium) {
  //     setFieldsValue({ ['insuranceTypePremium']: DiscountPremium + Discount })
  //   }

  // }, [getFieldValue('insuranceDiscountPremium'), getFieldValue('insuranceDiscount')]);


  // const handleKeyDownNumber = (e) => {
  //   console.log('key down', e.key.charCodeAt(0));
  //   let keyCode = e.key.charCodeAt(0);
  //   if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66) {
  //     return true;
  //   } else {
  //     e.preventDefault();
  //   }
  // };
  //Decimal
  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 1) e.preventDefault();
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => { return o.charCodeAt(0) === 46 ? 1 : 0 });
        if (sumDot >= 1) e.preventDefault();
      }
    }
    else if (keyCode === 45) {
      e.preventDefault();
    }
    else {
      e.preventDefault();
    }
  }


  const validatorMinusValue = (rule, value, callback) => {
    if (value) {
      if (value < 0) {
        callback(<FormattedMessage id="AssetModalTitleFormInsuranceValidator" defaultMessage="Amount must not be negative" />);
      } else {
        callback();
      }
    } else {
      callback();
    }
  };


  return (
    <div>
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item>
              <span className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsurance" defaultMessage="Insurance" /><LabeRequire req={true} /></span>
              {getFieldDecorator("insuranceName", {
                initialValue: _.get(data, 'insuranceId'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'AssetModalTitleFormInsuranceVL', defaultMessage: 'Please Enter Insurance' })
                  }
                ]
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'AssetModalTitleFormInsurancePH', defaultMessage: 'Enter Insurance Name' })}
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {children}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <span className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsuranceDate" defaultMessage="Insurance Date" /><LabeRequire req={true} /></span>
              {getFieldDecorator("insuranceDate", {
                initialValue: _.get(data, 'insuranceDateFrom') && _.get(data, 'insuranceDateTo') ? [moment(_.get(data, 'insuranceDateFrom'), 'YYYY-MM-DD'), moment(_.get(data, 'insuranceDateTo'), 'YYYY-MM-DD')] : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'AssetModalTitleFormInsuranceDateVL', defaultMessage: 'Please Select Insurance Date' })
                  }
                ]
              })(
                <RangePicker
                  format={dateFormat}
                  placeholder={[intl.formatMessage({ id: 'AssetModalTitleFormInsuranceStartDatePH', defaultMessage: 'Insurance Start Date' }),
                  intl.formatMessage({ id: 'AssetModalTitleFormInsuranceEndDatePH', defaultMessage: 'Insurance End Date' })]}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item>
              <span className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsuranceType" defaultMessage="Insurance Type" /><LabeRequire req={true} /></span>
              {getFieldDecorator("insuranceType", {
                initialValue: _.get(data, 'insuranceTypeId'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'AssetModalTitleFormInsuranceTypeVL', defaultMessage: 'Please Select Insurance Type' })
                  }
                ]
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'AssetModalTitleFormInsuranceStartDatePH', defaultMessage: 'Select Insurance Type' })}
                  disabled={getFieldValue('insuranceName') ? false : true}
                >
                  {
                    insuranceTypeData && insuranceTypeData.map(item =>
                      <Option key={item.insuranceTypeId}>
                        {item.typeNameEn}
                      </Option>
                    )
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <span className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsurancePremium" defaultMessage="Premium" /><LabeRequire req={true} /></span>
              {getFieldDecorator("insuranceTypePremium", {
                initialValue: _.get(data, 'premium'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'AssetModalTitleFormInsurancePremiumVL', defaultMessage: 'Please Enter Premium' })
                  },
                  {
                    validator: validatorMinusValue,
                  }
                ]
              })(
                <Input
                  onKeyDown={handleKeyDownNumberDecimal}
                  placeholder={intl.formatMessage({ id: 'AssetModalTitleFormInsurancePremiumPH', defaultMessage: 'Enter Premium' })}
                  onChange={(e) => onChangePremium(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item>
              <span className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsuranceDiscount" defaultMessage="Discount" /><LabeRequire req={false} /></span>
              {getFieldDecorator("insuranceDiscount", {
                initialValue: _.get(data, 'discount'),
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'AssetModalTitleFormInsuranceDiscountVL', defaultMessage: 'Please Enter Discount' })
                  },
                  {
                    validator: validatorMinusValue,
                  }
                ]
              })(
                <Input
                  onKeyDown={handleKeyDownNumberDecimal}
                  placeholder={intl.formatMessage({ id: 'AssetModalTitleFormInsuranceDiscountPH', defaultMessage: 'Enter Discount' })}
                  onChange={(e) => onChangeDiscount(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <span className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsuranceDiscountPremium" defaultMessage="Premium after discount" /><LabeRequire req={false} /></span>
              {getFieldDecorator("insuranceDiscountPremium", {
                initialValue: _.get(data, 'premiumAftDiscount'),
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'AssetModalTitleFormInsuranceDiscounPremiumVL', defaultMessage: 'Please Enter Premium after discount' })
                  },
                  {
                    validator: validatorMinusValue,
                  }
                ]
              })(
                <Input
                  onKeyDown={handleKeyDownNumberDecimal}
                  placeholder={intl.formatMessage({ id: 'AssetModalTitleFormInsuranceDiscountPremiumPH', defaultMessage: 'Enter Premium after discount' })}
                  onChange={(e) => onChangeDiscountPremium(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div >
  )
}

export default AddFormInsurance
