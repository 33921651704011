import React, { useContext } from 'react';
import { SaleOrderProvider } from './context';

import TranferItemPage from './tranfer-page';

const TranferItemMainPage = () => {
  return (
    <SaleOrderProvider>
      <TranferItemPage />
    </SaleOrderProvider>
  );
};

export default TranferItemMainPage;
