import { Col, Modal, Row } from 'antd';
import _ from 'lodash';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import { deleteWareHouse, getWareHouseDetail } from '../../controllers/warehouse/warehouse';
import WarehouseContext from './context';
import CardWarehouseDetails from './warehouse-detail';
import CardWarehouse from './warehouse-item';
import WarehouseModalForm from './warehouse-modal/warehouseModalForm';

const WarehousePage = () => {
  const intl = useIntl();
  const { checkPermissionAction } = useContext(PageSettings);
  const [toggle, setToggle] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [warehouse, setWarehouse] = useState([]);
  const [warehouseMaster, setWarehouseMaster] = useState([]);
  const [warehousePage, setWarehousePage] = useState(1);
  const [warehousePageLoading, setWarehousePageLoading] = useState(false);
  const [warehousePageHasMore, setWarehousePageHasMore] = useState(true);
  const [searchWarehouse, setSearchWarehouse] = useState('');
  const [warehouseId, setWarehouseId] = useState();
  const [warehouseDetail, setWarehouseDetail] = useState();
  const [warehouseActive, setWarehouseActive] = useState();

  const [warehouseIsOpen, setWarehouseIsOpen] = useState(false);
  const [warehouseRecord, setWarehouseRecord] = useState();
  const [warehouseDataFormResponse, setWarehouseDataFormResponse] = useState();

  const [warehouseTypeList, setWarehouseTypeList] = useState([]);
  const [warehouseServiceDataList, setWarehouseServiceDataList] = useState([]);
  const [warehouseManageTypeList, setWarehouseManageTypeList] = useState([]);
  const [warehouseManagerList, setWarehouseManagerList] = useState([]);
  const [roleCheck, setRoleCheck] = useState(false);

  const [event, setEvent] = useState(false);
  const [eventDetail, setEventDetail] = useState(false);
  const [eventWarhouse, setEventWarehouse] = useState(false);

  const [key, setKey] = useState('details');

  const handleDeleteWarehouse = (warehouse_Id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteWareHouse(warehouse_Id);
          if (response.status === 200) {
            if (_.get(response.data.data, 'warehouseId')) {
              let whId = _.get(response.data.data, 'warehouseId');
              let findIndexArr = _.findIndex(warehouse, function(o) {
                return o.warehouseId === whId;
              });

              if (findIndexArr > -1) {
                let filterArr = _.filter(warehouse, function(o) {
                  return o.warehouseId !== whId;
                });
                setWarehouse(filterArr);
              }
            }
            successNotification(_.get(response.data.status, 'message'));
            setWarehouseId();
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      },
      onCancel() {},
    });
  };

  const handleEditWarehouse = async (warehouse_Id) => {
    try {
      const payload = {
        warehouseId: warehouse_Id,
      };
      const response = await getWareHouseDetail(payload);
      setWarehouseRecord(_.get(response.data, 'data'));
      setWarehouseIsOpen(true);
    } catch (error) {}
  };

  console.log('keyyyyyy', key);
  return (
    <WarehouseContext.Provider
      value={{
        state: {
          toggle,
          warehouse,
          warehouseMaster,
          warehousePage,
          warehousePageLoading,
          warehousePageHasMore,
          searchWarehouse,
          warehouseId,
          warehouseDetail,
          warehouseActive,
          trigger,
          warehouseIsOpen,
          warehouseRecord,
          warehouseTypeList,
          warehouseDataFormResponse,
          warehouseServiceDataList,
          warehouseManageTypeList,
          warehouseManagerList,
          loadingComponent,
          key,
          roleCheck,
          event,
          eventDetail,
          eventWarhouse,
        },
        setState: {
          setToggle,
          setWarehouse,
          setWarehouseMaster,
          setWarehousePage,
          setWarehousePageLoading,
          setWarehousePageHasMore,
          setSearchWarehouse,
          setWarehouseId,
          setWarehouseDetail,
          setWarehouseActive,
          setTrigger,
          setWarehouseIsOpen,
          setWarehouseRecord,
          setWarehouseTypeList,
          setWarehouseDataFormResponse,
          setWarehouseServiceDataList,
          setWarehouseManageTypeList,
          setWarehouseManagerList,
          setLoadingComponent,
          setKey,
          setRoleCheck,
          setEvent,
          setEventDetail,
          setEventWarehouse,
        },
        fnc: { handleDeleteWarehouse, handleEditWarehouse },
      }}
    >
      {checkPermissionAction('P52PG1C1', 'P52PG1C1A1') ||
      checkPermissionAction('P52PG1C2A1', 'P52PG1C2A1') ||
      checkPermissionAction('P52PG1C2A1', 'P52PG1C3A1') ||
      checkPermissionAction('P52PG1C4', 'P52PG1C4A1') ? (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={toggle ? 0 : 5}>
              <CardWarehouse />
            </Col>
            <Col span={toggle ? 24 : 19}>
              <CardWarehouseDetails />
            </Col>
          </Row>
        </div>
      ) : null}

      <WarehouseModalForm
        record={warehouseRecord}
        setRecord={setWarehouseRecord}
        visible={warehouseIsOpen}
        setVisible={setWarehouseIsOpen}
      />
    </WarehouseContext.Provider>
  );
};

export default WarehousePage;
