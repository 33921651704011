import React from 'react'
import { Input } from 'antd'
import Search from '../components/action-search'
import Team from '../components/action-team'
import GroupBy from '../components/action-groupby'

export default (props) => {
  const { handleChangeOption, orgData, mainOrgId, handleSearch, handleSelect, searchValue, selectBy, setOrgData, setInputValue,
    inputValue, setSearchValue, setOrgIdRaw, orgIdRaw, handleSelectGroupBy, selectGroupBy, initialCustomerLoading, handleCustomerPopupScroll,
    handleCustomerChangeSearch, initialCustomerList, setMainCustomerId, mainCustomerId, setTypeGantt, handleChangeTeam, customerIdRaw } = props
  return (
    <div>
      <div className='container-map-header'>
        <div>
          <Search
            handleSearch={handleSearch}
            handleSelect={handleSelect}
            searchValue={searchValue}
            selectBy={selectBy}
            setInputValue={setInputValue}
            inputValue={inputValue}
            setSearchValue={setSearchValue}
          />
        </div>
        <div className='action-team-all'>
          <GroupBy
            handleSelectGroupBy={handleSelectGroupBy}
            selectGroupBy={selectGroupBy}
          />
          <Team
            handleChangeOption={handleChangeOption}
            orgData={orgData}
            mainOrgId={mainOrgId}
            setOrgData={setOrgData}
            setOrgIdRaw={setOrgIdRaw}
            orgIdRaw={orgIdRaw}
            selectGroupBy={selectGroupBy}
            initialCustomerLoading={initialCustomerLoading}
            handleCustomerPopupScroll={handleCustomerPopupScroll}
            handleCustomerChangeSearch={handleCustomerChangeSearch}
            initialCustomerList={initialCustomerList}
            setMainCustomerId={setMainCustomerId}
            mainCustomerId={customerIdRaw}
            setTypeGantt={setTypeGantt}
            handleChange={handleChangeTeam}
          />
        </div >
      </div >
    </div >
  )
}
