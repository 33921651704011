import React from 'react';
import { Icon } from 'antd';

const DrawerButton = ({ onClick, buttonText, buttonIcon }) => {
  return (
    <div class="note-button">
      <button onClick={onClick}>
        {buttonIcon ? buttonIcon : <Icon className='Icon-button-note ' type="form" />}
        <span className="button-text">{buttonText}</span>
      </button>
    </div>
  );
};

export default DrawerButton;
