import React, { useState, useEffect, useContext } from 'react';
import { Avatar, Col, Modal, Row, Table, Tabs, Divider, Icon, Badge, Spin } from 'antd';
import { TaskDetail } from '../../task-detail-context';
import Button02 from '../../../../../v2/button_02';
import AssigneeTaskDetails from './assignee-modal-task-detail';
import TrackingDetails from './assignee-modal-tracking';
import httpClient from '../../../../../axiosClient';
import styled from 'styled-components';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import { successNotification, errorNotification } from '../../../../../v2/notification';

const { TabPane } = Tabs;
const { confirm } = Modal;

const StylesModal = styled(Modal)`
  .ant-modal-body {
    padding: unset;
  }
  .ant-modal-content {
    max-height: 700px;
    height: 700px;
  }
`;

const comId = localStorage.getItem('comId');

export default React.memo((props) => {
  const intl = useIntl();
  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);
  const memId = localStorage.getItem('memId');

  const mainTaskIdAssign = _.get(stateTask, 'taskId');
  const [resourceTaskData, setResourceTaskData] = useState([]);
  const [resorceDataAssign, setResorceDataAssign] = useState([]);
  const [resorceDataCandidates, setResorceDataCandidates] = useState([]);
  const [resorceDataAvalible, setResorceDataAvalible] = useState([]);
  const [resorceDataOnGoing, setResorceDataOnGoing] = useState([]);
  const [resorceDataOff, setResorceDataOff] = useState([]);
  const [information, setInformation] = useState([]);
  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(false);

  const columnsAssignee = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnAssigneeName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnAssigneeShift`, defaultMessage: 'Shift' }),
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnAssigneeDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnAssigneeSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return <img key={i} className="table-status-img" src={item.icon} />;
            } else {
              return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />;
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handelRemove(index, record)}>
              <FormattedMessage id={`monitorTabDetailModalAssigneeResourceColumnAssigneeBtnRemove`} defaultMessage="Remove" />
            </Button02>
          </div>
        );
      },
    },
  ];

  const columnsCandidate = [
    {
      dataIndex: 'partImage',
      key: 'partImage',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnCandidateName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnCandidateShift`, defaultMessage: 'Shift' }),
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnCandidateDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnCandidateSubtasks`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return <img key={i} className="table-status-img" src={item.icon} />;
            } else {
              return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />;
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handleAssign(record, index)}>
              <FormattedMessage id={`monitorTabDetailModalAssigneeResourceColumnCandidateBtnAssign`} defaultMessage="Assign" />
            </Button02>
          </div>
        );
      },
    },
  ];

  const columnsAvailable = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnAvailableName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnAvailableShift`, defaultMessage: 'Shift' }),
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnAvailableDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnAvailableSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return <img key={i} className="table-status-img" src={item.icon} />;
            } else {
              return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />;
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handleAssign(record, index)}>
              <FormattedMessage id={`monitorTabDetailModalAssigneeResourceColumnAvailableBtnAssign`} defaultMessage="Assign" />
            </Button02>
          </div>
        );
      },
    },
  ];

  const columnsOnGoing = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnOnGoingName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnOnGoingShift`, defaultMessage: 'Shift' }),
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnOnGoingDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnOnGoingSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return <img key={i} className="table-status-img" src={item.icon} />;
            } else {
              return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />;
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handleAssign(record, index)}>
              <FormattedMessage id={`monitorTabDetailModalAssigneeResourceColumnOnGoingBtnAssign`} defaultMessage="Assign" />
            </Button02>
          </div>
        );
      },
    },
  ];

  const columnsOff = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnOffName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnOffShift`, defaultMessage: 'Shift' }),
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnOffDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorTabDetailModalAssigneeResourceColumnOffSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return <img key={i} className="table-status-img" src={item.icon} />;
            } else {
              return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />;
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handleAssign(record, index)}>
              <FormattedMessage id={`monitorTabDetailModalAssigneeResourceColumnOffBtnAssign`} defaultMessage="Assign" />
            </Button02>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (mainTaskIdAssign && stateTask.visibleAssignee === true) {
      async function getTaskResourceData() {
        const response = await httpClient.get(`/v3/task/manager/task-resource/${mainTaskIdAssign}/all?menuType=workdesk`);

        if (response.status === 200) {
          setResourceTaskData(response.data.data);
          setResorceDataAssign(response.data.data.assignee);
          setResorceDataCandidates(response.data.data.candidates);
          setResorceDataAvalible(response.data.data.available);
          setResorceDataOnGoing(response.data.data.onGoing);
          setResorceDataOff(response.data.data.off);
          setInformation(response.data.data.information);
          setTracking(response.data.data.tracking);
          setLoading(false);
        }
      }
      getTaskResourceData();
    }
  }, [
    mainTaskIdAssign,
    stateTask.refreshTable,
    stateTask.refreshDateAndTime,
    stateTask.valueModalVisibleStatus,
    stateTask.visibleAssignee,
  ]);

  const handelRemove = (index, record) => {
    confirm({
      className: 'assignee-remove-modal',
      icon: null,
      centered: true,
      title: intl.formatMessage({
        id: `monitorTabDetailModalAssigneeResourceModalRemove`,
        defaultMessage: 'Are you sure to remove assignee',
      }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          if (information.taskId && record.memComId) {
            const response = await httpClient.delete(
              `/v3/task/manager/task/${information.taskId}/unassign/${record.memComId}?menuType=workdesk`
            );
            if (response.status == 200) {
              const tasks = stateTask.scheduleObjNew.current.getEvents();
              const thisTask = _.find(tasks, { TaskId: information.taskId, ResourceId: _.toInteger(record.memComId) });
              if (thisTask) stateTask.scheduleObjNew.current.deleteEvent(thisTask.Id);
              setStateTask.setRefreshTable((current) => !current);
              setStateTask.setRefreshTaskDetailsData((current) => !current);
              setStateTask.setRefreshDataTaskPool((current) => !current);
              setStateTask.setRefreshApiGanttView((current) => !current);
              setStateTask.setTriggerInformation((current) => !current);
              setStateTask.setTriggerApi((current) => !current);
              successNotification(response.data.status.message);
            } else {
              errorNotification(response.data.status.message);
            }
          }
        } catch (error) {
          errorNotification(error.response.status.message);
        }
      },
      onCancel() {},
    });
  };

  const handleAssign = (record, index) => {
    confirm({
      className: 'assignee-confirm-modal',
      icon: null,
      centered: true,
      title: intl.formatMessage({
        id: `monitorTabDetailModalAssigneeResourceModalAssigne`,
        defaultMessage: 'Do you want to assign this assignee?',
      }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        resetAssigneeTask(record);
      },
      onCancel() {},
    });
  };

  const resetAssigneeTask = (record) => {
    confirm({
      className: 'assignee-reset-modal',
      icon: null,
      title: intl.formatMessage({
        id: `monitorTabDetailModalAssigneeResourceModalReset`,
        defaultMessage: 'Do you want to reset this assignee task?',
      }),
      okText: intl.formatMessage({ id: 'btnKeep', defaultMessage: 'Keep' }),
      async onOk() {
        try {
          const body = {
            memComId: record.memComId,
            resetSubtask: false,
            memId: Number(memId),
          };
          const response = await httpClient.put(
            `/v3/task/manager/company/${comId}/monitor/task/${information.taskId}/reassign?menuType=workdesk`,
            body
          );
          if (response.status == 200) {
            setStateTask.setRefreshTable((current) => !current);
            setStateTask.setRefreshTaskDetailsData((current) => !current);
            setStateTask.setRefreshApiGanttView((current) => !current);
            setStateTask.setRefreshDataTaskPool((current) => !current);
            setStateTask.setTriggerInformation((current) => !current);
            setStateTask.setTriggerApi((current) => !current);
            successNotification(response.data.status.message);
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {
          errorNotification(error.response.status.message);
        }
      },
      async onCancel() {
        try {
          const body = {
            memComId: record.memComId,
            resetSubtask: true,
            memId: Number(memId),
          };
          const responseReset = await httpClient.put(
            `/v3/task/manager/company/${comId}/monitor/task/${information.taskId}/reassign?menuType=workdesk`,
            body
          );
          if (responseReset.status == 200) {
            setStateTask.setRefreshTable((current) => !current);
            setStateTask.setRefreshTaskDetailsData((current) => !current);
            setStateTask.setTriggerInformation((current) => !current);
            setStateTask.setTriggerApi((current) => !current);
            successNotification(responseReset.data.status.message);
          } else {
            errorNotification(responseReset.data.status.message);
          }
        } catch (error) {
          errorNotification(error.responseReset.status.message);
        }
      },
      cancelText: intl.formatMessage({ id: 'btnReset', defaultMessage: 'Reset' }),
      centered: true,
    });
  };

  const TitleModal = () => {
    return (
      <div>
        <Badge color={information.statusColor} className="Statusdot" />
        <span className="assignee-details-title">{information.taskNo}</span>
        <Badge color="#1D3557" className="assignee-beetwen-Badge-dot" />
        <span className="assignee-details-title">{information.taskTypeName}</span>
      </div>
    );
  };

  const handleCancel = (e) => {
    if (e) {
      if (e.currentTarget.tagName.toLowerCase() !== 'button') return;
    }
    setStateTask.setVisibleAssignee(false);
  };

  return (
    <div>
      <StylesModal
        className="assignee-resource-modal"
        centered={true}
        title={<TitleModal />}
        footer={null}
        width={1200}
        visible={stateTask.visibleAssignee}
        onCancel={handleCancel}
      >
        <Spin spinning={loading}>
          <Row>
            <Col span={16} className="assignee-resource-col-left" style={{ height: 662 }}>
              <div className="assignee-resource-col-div">
                <Row>
                  <Col className="assignee-resource-col-col-left">
                    <p className="assignee-label">
                      <FormattedMessage id={`monitorTabDetailModalAssigneeResourceSyleModalAssignee`} defaultMessage="Assignee" />
                    </p>
                    <Table
                      className="assignee-resource-table"
                      scroll={{ y: 170 }}
                      // dataSource={stateMain.resourceTaskData.assignee || []}
                      dataSource={resorceDataAssign}
                      columns={columnsAssignee}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="assignee-resource-col-col-right">
                    <Tabs defaultActiveKey="1">
                      {/* <TabPane tab={intl.formatMessage({ id: 'monitorTabDetailModalAssigneeResourceSyleModalTablePaneCandidate', defaultMessage: 'Candidates' })} key="1">
                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 150 }}
                        dataSource={resorceDataCandidates}
                        // dataSource={stateMain.resourceTaskData.candidates || []}
                        columns={columnsCandidate}
                      />
                    </TabPane> */}
                      <TabPane
                        tab={intl.formatMessage({
                          id: `monitorTabDetailModalAssigneeResourceSyleModalTablePaneAvalible`,
                          defaultMessage: 'Avalible',
                        })}
                        key="2"
                      >
                        <Table
                          className="assignee-resource-table"
                          rowKey={(record) => record.memComId}
                          scroll={{ y: 150 }}
                          dataSource={resorceDataAvalible}
                          // dataSource={stateMain.resourceTaskData.available || []}
                          columns={columnsAvailable}
                        />
                      </TabPane>
                      <TabPane
                        tab={intl.formatMessage({
                          id: `monitorTabDetailModalAssigneeResourceSyleModalTablePaneOnGoing`,
                          defaultMessage: 'On Going',
                        })}
                        key="3"
                      >
                        <Table
                          className="assignee-resource-table"
                          rowKey={(record) => record.memComId}
                          scroll={{ y: 150 }}
                          dataSource={resorceDataOnGoing}
                          // dataSource={stateMain.resourceTaskData.onGoing || []}
                          columns={columnsOnGoing}
                        />
                      </TabPane>
                      <TabPane
                        tab={intl.formatMessage({
                          id: `monitorTabDetailModalAssigneeResourceSyleModalTablePaneOff`,
                          defaultMessage: 'Off',
                        })}
                        key="4"
                      >
                        <Table
                          className="assignee-resource-table"
                          rowKey={(record) => record.memComId}
                          scroll={{ y: 150 }}
                          dataSource={resorceDataOff}
                          // dataSource={stateMain.resourceTaskData.off || []}
                          columns={columnsOff}
                        />
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
              </div>
              {/* <Divider className="assignee-resource-footer-divider" />
            <div className="assignee-resource-footer">
              <Button02 style={{margin : '0px 0px 0px 10px'}}
                btnsize="wd_df"
                // onClick={() => setStateMain.setVisibleAssignee(false)}
              >
                Cancel
              </Button02>
              <Button01
                btnsize="wd_df"
                type="primary"
                // onCilck={() => setStateMain.setVisibleAssignee(false)}
              >
                Confirm
              </Button01>
            </div> */}
            </Col>
            <Divider className="assignee-resource-divider" type="vertical" />
            <Col span={8} className="assignee-resource-col-right">
              <div className="assignee-details-div-assignee-tracking scroll-sm">
                <Row>
                  <Col span={24}>
                    <AssigneeTaskDetails information={information} />
                  </Col>
                </Row>
                <Divider className="assignee-resource-divider-trackingDetails" />
                <Row>
                  <Col span={24}>
                    <TrackingDetails tracking={tracking} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Spin>
      </StylesModal>
    </div>
  );
});
