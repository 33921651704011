import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Avatar, Icon, Modal, Badge, Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { ShiftPlanningContext } from '../shift-context';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import moment from 'moment';
import _ from 'lodash';
import ShiftModalDelete from '../shift-modal-delete-shift';
import ShiftModalAddPlanning from '../shift-modal-add-shift-planning';
import httpClient from '../../../../components/axiosClient';
import styled from 'styled-components';
import stylesShiftIndex from '../css/index.css';
import Alertshift from '../../../../components/image/alertshift.svg';

import {
  TimelineMonth,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  DragAndDrop,
} from '@syncfusion/ej2-react-schedule';
import { Internationalization } from '@syncfusion/ej2-base';
import { v4 as uuidv4 } from 'uuid';
import notification from '../../../../components/v2.1.0/notification';

const instance = new Internationalization();

export default React.memo(
  (props) => {
    const intl = useIntl();
    const { statePlanning, fncStatePlanning } = props;
    const langValue = localStorage.getItem('langValue') || 'EN';
    const [formRef, setFormRef] = useState(null);
    const [visibleDetailWork, setVisibleDetailWork] = useState(false);
    const [visibleDetailWorkRes, setVisibleDetailWorkRes] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [shiftDataSource, setShiftDataSource] = useState([]);
    const [dataResource, setDataResource] = useState([]);
    const [rangePickerValue, setRangePickerValue] = useState([]);
    const [formData, setFormData] = useState({});
    // const [warningDate, setWarningDate] = useState([]);
    // const [warningResource, setWarningResource] = useState([]);
    const [viewWarningDetailIndex, setViewWarningDetailIndex] = useState();
    const [viewWarningDetailRsourceIndex, setViewWarningDetailRsourceIndex] = useState();
    const [resourcesPlannig, setResourcesPlannig] = useState();
    const [viewWarningDetail, setViewWarningDetail] = useState([]);
    const [viewWarningDetailResource, setViewWarningDetailResource] = useState([]);
    const [txtWarningSuffix, setTxtWarningSuffix] = useState('');
    const [txtWarningSuffixResource, setTxtWarningSuffixResource] = useState('');

    // console.log('shift planning task datSource::: ', shiftDataSource);
    // console.log('shift planning resources Plannig::: ', statePlanning);
    // console.log('shift planning resourcesPlannig::: ', resourcesPlannig);
    // console.log('shift planning statePlanning scheduleObj::: ', statePlanning.scheduleObj.current);

    useEffect(() => {
      if (statePlanning.scenarioIndex !== null && statePlanning.scenarios.length > 0 && shiftDataSource.length === 0) {
        // setWarningDate(statePlanning.warningDate);
        // setWarningResource(statePlanning.warningResource);

        setShiftDataSource(statePlanning.scenarios[statePlanning.scenarioIndex].scenarioShiftList);

        // statePlanning.scheduleObj.current.eventSettings.dataSource = statePlanning.scenarios[statePlanning.scenarioIndex].scenarioShiftList;
        // statePlanning.scheduleObj.current.resources[0].dataSource = statePlanning.resourcesPlannig;
      }
    });

    useEffect(() => {
      if (_.size(statePlanning.resourcesPlannig) !== 0) {
        setResourcesPlannig(statePlanning.resourcesPlannig);
      }

      if (_.get(statePlanning.scheduleObj, 'current')) {
        if (statePlanning.scheduleObj.current.isRendered) _.delay(() => statePlanning.scheduleObj.current.refreshEvents(), 500);
      }
    }, [statePlanning.resourcesPlannig]);

    useEffect(() => {
      setTimeout(() => {
        if (_.get(statePlanning.scheduleObj, 'current.element')) {
          setWarningDateIcon(statePlanning.warningDate);
          setWarningResourceIcon(statePlanning.warningResource);
        }
      }, 1000);
    }, [statePlanning.warningDate, statePlanning.warningResource]);

    useEffect(() => {
      if (viewWarningDetailIndex) setViewWarning();
      if (viewWarningDetailRsourceIndex) setViewWarningResource();
    }, [viewWarningDetailIndex, viewWarningDetailRsourceIndex]);

    const saveFormRef = useCallback((node) => {
      if (node !== null) {
        setFormRef(node);
      }
    }, []);

    const setViewWarning = () => {
      let txt = '';
      let data = [];
      const getWarning = _.find(statePlanning.warningDate, ['day', _.toString(viewWarningDetailIndex)]);
      if (getWarning) {
        txt = moment(getWarning.workDate, 'YYYY-MM-DD').format('ddd, MMM DD, YYYY');
        const getShiftTypeRules = _.get(getWarning, 'shiftTypeRules');
        if (getShiftTypeRules) data = getShiftTypeRules;
      }
      setTxtWarningSuffix(txt);
      setViewWarningDetail(data);
      setVisibleDetailWork(true);
    };

    const setViewWarningResource = () => {
      let txt = '';
      let data = [];
      const getWarning = _.find(statePlanning.warningResource, ['memComId', viewWarningDetailRsourceIndex]);
      if (getWarning) {
        txt = getWarning.fullname;
        const getShiftTypeRules = _.get(getWarning, 'shiftTypeRules');
        if (getShiftTypeRules) data = getShiftTypeRules;
      }
      setTxtWarningSuffixResource(txt);
      setViewWarningDetailResource(data);
      setVisibleDetailWorkRes(true);
    };

    const imgIcon = () => <img src={Alertshift} style={{ width: '17px', height: '17px' }}></img>;

    const dateHeaderTemplate = (props) => {
      const dMd = getDateHeaderText(props.date, 'Md');
      const dE = getDateHeaderText(props.date, 'E');
      const dD = getDateHeaderText(props.date, 'd');

      return (
        <div style={{ height: 50, padding: 5 }}>
          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <div style={{ lineHeight: 1.5, fontSize: 15 }}>{dE}</div>
            <Icon
              className={`date-icon-${dD}`}
              style={{
                color: '#ff0000',
                fontSize: '16px',
                marginLeft: '6px',
                position: 'absolute',
                right: 20,
                display: 'none',
              }}
              // type="message"
              // theme="filled"
              component={imgIcon}
              onClick={() => fncStatePlanning.handleOpenModalDateWarning(dD)}
            />
          </div>
          <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{dD}</div>
        </div>
      );
    };

    const customResourceHeaderTemplate = (props) => {
      const { resourceData } = props;
      const checkResourceMockData = props.resourceData.Id.toString().indexOf('mockdata');
      return checkResourceMockData ? (
        <div className="shift-template-wrap" title={resourceData.Text}>
          <Avatar size={32} className="avatar-border" src={process.env.REACT_APP_IMG_HOST + resourceData.pathImage} />
          <span className="shift-scheduler-column-name">{_.truncate(resourceData.Text, { length: 20 })}</span>
          <Icon
            className={`resource-icon-${props.resourceData.memComId}`}
            style={{
              color: '#ff0000',
              fontSize: '16px',
              marginLeft: '6px',
              position: 'absolute',
              right: 20,
              display: 'none',
            }}
            // type="message"
            // theme="filled"
            component={imgIcon}
            onClick={() => fncStatePlanning.handleOpenModalResourceWarning(props.resourceData.memComId)}
          />
        </div>
      ) : (
        <div></div>
      );
    };

    const customRenderCell = (args, resourcesPlannig) => {
      if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
        let resourceCollection = statePlanning.scheduleObj.current.getResourceCollections();
        let target = args.element.querySelector('.e-resource-text');
        target.innerHTML =
          `<div style="padding: 0px 24px; text-align: center;"><b> ${intl.formatMessage({
            id: 'orgShiftPlanningLblResources',
            defaultMessage: 'Resources',
          })} (` +
          _.sumBy(_.get(resourceCollection, '[0].dataSource'), (e) => (e.memComId !== '' ? 1 : 0)) +
          ')</div>';
      }
    };

    const onEventRendered = (args) => {
      let width = parseInt(args.element.style.width);
      let height = 38;
      let left = parseInt(args.element.style.left) + 6;

      if (width >= 270) {
        width = 261;
      } else {
        width = 125;
      }

      args.element.style.width = width + 'px';
      args.element.style.height = height + 'px';
      args.element.style.color = '#ffffff';
      args.element.style.backgroundColor = args.data.Color;
      args.element.style.margin = '0px';
      args.element.style.borderRadius = '3px';
      args.element.style.left = left + 'px';

      args.element.querySelector('.e-appointment-details').style.alignItems = 'center';
      args.element.querySelector('.e-inner-wrap').style.padding = '0px 12px';
      args.element.querySelector('.e-inner-wrap').style.fontSize = '12px';
      args.element.querySelector('.e-time').style.lineHeight = '1.5';
      args.element.querySelector('.e-time').style.fontSize = '8px';
      args.element.querySelector('.e-time').innerHTML =
        args.data.orgName + ' ' + moment(args.data.StartTime).format('HH:mm') + ' - ' + moment(args.data.EndTime).format('HH:mm');
    };

    const handleDrag = (args) => {
      if (args.data.isHoliday || args.data.isLeave || args.data.orgId !== statePlanning.orgId.toString()) args.cancel = true;
    };

    const handleDrop = async (args) => {
      // console.log('shift planning handleDrop::: ', args.data);
      // console.log('shift planning handleDrop ResourceId::: ', args.data.ResourceId);
      // console.log('shift planning includes::: ', String(_.get(args, 'data.ResourceId')).indexOf('mockdata'));

      // const checkOverlap = checkShiftTimePeriod(args.data.Id, args.data.StartTime, args.data.EndTime, args.data.ResourceId);
      // args.data.StartTime = moment(args.data.StartTime).format('YYYY-MM-DD HH:mm');
      // args.data.EndTime = moment(args.data.EndTime).format('YYYY-MM-DD HH:mm');
      // if (checkOverlap) args.cancel = true;
      // const warings = await getWarningByFix();
      // statePlanning.setWarningDate(warings.warningDate);
      // statePlanning.setWarningResource(warings.warningResource);

      // เต้ทำเรื่อง Defect เรื่อง planing shift ระบบสามารถลาก shift ไปช่องที่ไม่มี Member ได้
      if (String(_.get(args, 'data.ResourceId')).indexOf('mockdata') === -1) {
        const checkOverlap = checkShiftTimePeriod(args.data.Id, args.data.StartTime, args.data.EndTime, args.data.ResourceId);
        args.data.StartTime = moment(args.data.StartTime).format('YYYY-MM-DD HH:mm');
        args.data.EndTime = moment(args.data.EndTime).format('YYYY-MM-DD HH:mm');
        if (checkOverlap) args.cancel = true;
        const warings = await getWarningByFix();
        statePlanning.setWarningDate(warings.warningDate);
        statePlanning.setWarningResource(warings.warningResource);
      } else {
        args.cancel = true;
      }
    };

    const checkShiftTimePeriod = (id, start, end, memId) => {
      const events = statePlanning.scheduleObj.current.getEvents();
      const startUnix = moment(start).format('X');
      const endUnix = moment(end).format('X');

      const findShiftDateTime = _.findIndex(events, (e) => {
        const xStartUnix = moment(e.StartTime).format('X');
        const xEndUnix = moment(e.EndTime).format('X');
        return e.Id !== id &&
          e.isHoliday === false &&
          e.ResourceId === memId &&
          ((startUnix >= xStartUnix && startUnix <= xEndUnix) || (endUnix >= xStartUnix && endUnix <= xEndUnix))
          ? true
          : false;
      });

      if (findShiftDateTime > -1) {
        notification({ status: 'error', message: intl.formatMessage({ id: 'orgShiftPlanningLblError', defaultMessage: 'Error.' }) });
      }
      return findShiftDateTime > -1 ? true : false;
    };

    const onPopupOpen = (args) => {
      if (args.name === 'popupOpen') {
        args.cancel = true;
      }
    };

    const getDateHeaderText = (value, skeleton) => {
      return instance.formatDate(value, { skeleton: skeleton });
    };

    const getPathImage = (value) => {
      return value.resourceData.pathImage;
    };

    const getName = (value) => {
      return value.resourceData[value.resource.textField];
    };

    const handleClickEvent = (args) => {
      // console.log('handleClickEvent::: ', args);
      // console.log('handleClickEvent statePlanning::: ', statePlanning.resourcesPlannig);
      // console.log('handleClickEvent scheduleObj::: ', statePlanning.scheduleObj);

      const getResourceData = statePlanning.scheduleObj.current.resources[0].dataSource;

      // console.log('handleClickEvent getResourceData::: ', getResourceData);

      // if (_.size(statePlanning.resourcesPlannig) !== 0) {
      if (_.size(getResourceData) !== 0) {
        if (args.event.isHoliday || args.event.isLeave) return;

        const findMemberIndex = _.findIndex(getResourceData, (e) => {
          return e.Id === args.event.ResourceId ? true : false;
        });
        // console.log('handleClickEvent findMemberIndex::: ', findMemberIndex);

        // setFormData({
        //   Id: args.event.Id,
        //   Subject: args.event.Subject,
        //   Color: args.event.Color,
        //   memId: args.event.ResourceId,
        //   memComId: args.event.memComId,
        //   pathImage: process.env.REACT_APP_IMG_HOST + statePlanning.resourcesPlannig[findMemberIndex].pathImage,
        //   name: statePlanning.resourcesPlannig[findMemberIndex].Text,
        //   email: statePlanning.resourcesPlannig[findMemberIndex].email || '',
        //   phone: statePlanning.resourcesPlannig[findMemberIndex].phone || '',
        //   startDate: moment(args.event.StartTime).locale(langValue.toLowerCase()).format('DD/MM/YYYY hh:mm A'),
        //   endDate: moment(args.event.EndTime).locale(langValue.toLowerCase()).format('DD/MM/YYYY hh:mm A')
        // });

        setFormData({
          Id: args.event.Id,
          Subject: args.event.Subject,
          Color: args.event.Color,
          memId: args.event.ResourceId,
          memComId: args.event.memComId,
          pathImage: process.env.REACT_APP_IMG_HOST + getResourceData[findMemberIndex].pathImage,
          name: getResourceData[findMemberIndex].Text,
          email: getResourceData[findMemberIndex].email || '',
          phone: getResourceData[findMemberIndex].phone || '',
          startDate: moment(args.event.StartTime)
            .locale(langValue.toLowerCase())
            .format('DD/MM/YYYY hh:mm A'),
          endDate: moment(args.event.EndTime)
            .locale(langValue.toLowerCase())
            .format('DD/MM/YYYY hh:mm A'),
        });

        setEditVisible(true);
      }
    };

    const handleCellClick = (args) => {
      // console.log('handleCellClick::: ', args);
      // console.log('handleCellClick scheduleObj::: ', statePlanning.scheduleObj);
      // console.log('handleCellClick statePlanning resourcesPlannig::: ', resourcesPlannig);
      const getResourceData = statePlanning.scheduleObj.current.getResourcesByIndex(args.groupIndex);
      // console.log('handleCellClick getResourceData::: ', getResourceData);

      // if (resourcesPlannig) {
      // const checkResourceMockData = resourcesPlannig[args.groupIndex].Id.toString().indexOf('mockdata');
      const checkResourceMockData = getResourceData.resource.dataSource[args.groupIndex].Id.toString().indexOf('mockdata');
      // console.log('handleCellClick checkResourceMockData::: ', checkResourceMockData);

      if (checkResourceMockData > -1) return;
      // setFormData({
      //   memId: statePlanning.resourcesPlannig[args.groupIndex].Id,
      //   memComId: statePlanning.resourcesPlannig[args.groupIndex].memComId,
      //   pathImage: process.env.REACT_APP_IMG_HOST + statePlanning.resourcesPlannig[args.groupIndex].pathImage,
      //   name: statePlanning.resourcesPlannig[args.groupIndex].Text,
      //   email: statePlanning.resourcesPlannig[args.groupIndex].email || '',
      //   phone: statePlanning.resourcesPlannig[args.groupIndex].phone || '',
      //   startDate: args.startTime,
      //   endDate: args.endTime
      // });

      setFormData({
        memId: getResourceData.resource.dataSource[args.groupIndex].Id,
        memComId: getResourceData.resource.dataSource[args.groupIndex].memComId,
        pathImage: process.env.REACT_APP_IMG_HOST + getResourceData.resource.dataSource[args.groupIndex].pathImage,
        name: getResourceData.resource.dataSource[args.groupIndex].Text,
        email: getResourceData.resource.dataSource[args.groupIndex].email || '',
        phone: getResourceData.resource.dataSource[args.groupIndex].phone || '',
        startDate: args.startTime,
        endDate: args.endTime,
      });

      setRangePickerValue([args.startTime, args.endTime]);
      setFormVisible(true);
      // }
    };

    const handleOkDelete = () => {
      Modal.confirm({
        className: 'shift-schedule-modal-confirm',
        centered: true,
        icon: null,
        title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
        okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        onOk: async () => {
          statePlanning.scheduleObj.current.deleteEvent(formData.Id);
          const warings = await getWarningByFix();
          statePlanning.setWarningDate(warings.warningDate);
          statePlanning.setWarningResource(warings.warningResource);
          setEditVisible(false);
        },
      });
    };

    const handleSubmit = (e) => {
      formRef.validateFields(async (err, values) => {
        if (!err) {
          let data = [];
          let formStartDate = values['range-picker'][0].format('YYYY-MM-DD');
          let formEndDate = values['range-picker'][1].format('YYYY-MM-DD');
          let formSelect = values['shift'];
          let formSubject = '';
          let formShiftMasterId = '';
          let formShiftTypeRuleId = '';
          let formStartTime = '00:00 AM';
          let formEndTime = '23:59 PM';
          let formColor = '';

          const findShiftMasterIndex = _.findIndex(statePlanning.shiftTypeRule, (e) => {
            return e.shiftId === formSelect ? true : false;
          });

          if (findShiftMasterIndex > -1) {
            formShiftMasterId = statePlanning.shiftTypeRule[findShiftMasterIndex].shiftId;
            formSubject = statePlanning.shiftTypeRule[findShiftMasterIndex].shiftName;
            formColor = statePlanning.shiftTypeRule[findShiftMasterIndex].shiftColor;
            formStartTime = statePlanning.shiftTypeRule[findShiftMasterIndex].shiftStartTime;
            formEndTime = statePlanning.shiftTypeRule[findShiftMasterIndex].shiftEndTime;
            formShiftTypeRuleId = statePlanning.shiftTypeRule[findShiftMasterIndex].shiftTypeRuleId;
          }

          while (formStartDate <= formEndDate) {
            const newShiftId = uuidv4();
            const newFormStart = moment(formStartDate + ' ' + formStartTime, 'YYYY-MM-DD hh:mm a').format();
            const newFormEnd = moment(formStartDate + ' ' + formEndTime, 'YYYY-MM-DD hh:mm a').format();
            const checkOverlap = checkShiftTimePeriod(newShiftId, newFormStart, newFormEnd, formData.memId);

            if (!checkOverlap) {
              data.push({
                Id: newShiftId,
                ResourceId: formData.memId,
                Subject: formSubject,
                StartTime: formStartDate + ' ' + moment(formStartTime, 'hh:mm a').format('HH:mm'),
                EndTime: formStartDate + ' ' + moment(formEndTime, 'hh:mm a').format('HH:mm'),
                memComId: formData.memComId,
                orgId: statePlanning.orgId.toString(),
                orgName: statePlanning.orgName,
                IsAllDay: false,
                isHoliday: false,
                isLeave: false,
                workDate: formStartDate,
                ShiftMasterId: formShiftMasterId,
                shiftTypeRuleId: formShiftTypeRuleId,
                Color: formColor,
              });
            }

            formStartDate = moment(formStartDate, 'YYYY-MM-DD')
              .add(1, 'days')
              .format('YYYY-MM-DD');
          }

          statePlanning.scheduleObj.current.addEvent(data);

          const warings = await getWarningByFix();
          // setWarningIcon();
          // const sss = getEventsAll();
          statePlanning.setWarningDate(warings.warningDate);
          statePlanning.setWarningResource(warings.warningResource);
          setFormVisible(false);
          formRef.resetFields();
        }
      });
    };

    const handleCancelDelete = () => {
      setEditVisible(false);
    };

    const handleCancel = () => {
      setFormVisible(false);
      formRef.resetFields();
    };

    const getEventsAll = () => {
      const events = statePlanning.scheduleObj.current.eventSettings.dataSource;
      const eventsThisOrg = _.filter(events, (o) => o.orgId === statePlanning.orgId.toString());
      return _.size(eventsThisOrg)
        ? eventsThisOrg.map((o) => {
            return {
              shiftMasterId: o.ShiftMasterId,
              memId: o.ResourceId,
              memComId: o.memComId,
              workDate: o.workDate,
            };
          })
        : [];
    };

    const setWarningIcon = () => {
      let gR = statePlanning.scheduleObj.current.getResourceCollections();
      let r = _.get(gR, '[0].dataSource');

      for (let i = 1; i <= 31; i++) {
        statePlanning.scheduleObj.current.element.querySelector(`.date-icon-${i}`).style.display = '';
      }

      _.forEach(r, (o) => {
        statePlanning.scheduleObj.current.element.querySelector(`.resource-icon-${o.Id}`).style.display = '';
      });
    };

    const setWarningDateIcon = (wd) => {
      const dayOfMonth = moment(statePlanning.thisYearMonth, 'YYYY-MM').daysInMonth();
      for (let i = 1; i <= dayOfMonth; i++) {
        if (_.findIndex(wd, ['day', _.toString(i)]) > -1) {
          statePlanning.scheduleObj.current.element.querySelector(`.date-icon-${i}`).style.display = '';
        } else {
          statePlanning.scheduleObj.current.element.querySelector(`.date-icon-${i}`).style.display = 'none';
        }
      }
    };

    const setWarningResourceIcon = (wr) => {
      const resource = statePlanning.scheduleObj.current.resources[0].dataSource;
      _.forEach(resource, (o) => {
        const setElement = statePlanning.scheduleObj.current.element.querySelector(`.resource-icon-${o.memComId}`);

        if (_.findIndex(wr, ['memComId', o.memComId]) > -1) {
          setElement.style.display = '';
        } else {
          if (setElement) setElement.style.display = 'none';
        }
      });
    };

    const handleCancelViewShiftWarning = () => {
      setViewWarningDetailIndex();
      setViewWarningDetailRsourceIndex();
      setVisibleDetailWork(false);
      setVisibleDetailWorkRes(false);
    };

    const getWarningByFix = async () => {
      const getShiftAll = statePlanning.scheduleObj.current.eventSettings.dataSource;
      try {
        const response = await httpClient.post(
          `/v1/resource/manager/shift/planning/company/${statePlanning.comId}/organization/${statePlanning.orgId}`,
          { year: statePlanning.shiftsYear, month: statePlanning.shiftsMonth, shift: getShiftAll }
        );
        if (response.status === 200) {
          return { warningDate: response.data.warningDate, warningResource: response.data.warningResource };
        }
      } catch (error) {
        return false;
      }
      return false;
    };

    return shiftDataSource.length >= 0 ? (
      <div className="shift-schedule-planning-cssClass-div">
        <ScheduleComponent
          ref={statePlanning.scheduleObj}
          cssClass="shift-schedule-planning-cssClass"
          width="100%"
          height="100%"
          showHeaderBar={false}
          rowAutoHeight={true}
          showQuickInfo={false}
          workDays={[0, 1, 2, 3, 4, 5, 6]}
          selectedDate={moment(statePlanning.thisYearMonth + '-01', 'YYYY-MM-DD')._d}
          currentView="TimelineMonth"
          dateHeaderTemplate={dateHeaderTemplate}
          resourceHeaderTemplate={customResourceHeaderTemplate}
          eventRendered={onEventRendered}
          renderCell={(args) => customRenderCell(args, statePlanning.resourcesPlannig)}
          dragStart={handleDrag}
          dragStop={handleDrop}
          popupOpen={onPopupOpen}
          eventClick={handleClickEvent}
          cellClick={handleCellClick}
          group={{
            resources: ['Resources'],
          }}
          eventSettings={{
            dataSource: shiftDataSource,
          }}
        >
          <ResourcesDirective>
            <ResourceDirective
              field="ResourceId"
              title="MemberShift"
              name="Resources"
              allowMultiple={false}
              idField="Id"
              textField="Text"
              // dataSource={dataResource}
              dataSource={statePlanning.resourcesPlannig}
            />
          </ResourcesDirective>

          <ViewsDirective>
            <ViewDirective option="TimelineMonth" />
          </ViewsDirective>

          <Inject services={[TimelineMonth, DragAndDrop]} />
        </ScheduleComponent>

        {/* <Modal
          className="shift-modal-schedule"
          title={
            <div>
              Warning{' '}<Badge className="shift-planning-title-dot" color="#1D3557" />{' '}{txtWarningSuffix}
            </div>
          }
          centered={true}
          visible={visibleDetailWork}
          onOk={handleCancelViewShiftWarning}
          onCancel={handleCancelViewShiftWarning}
          bodyStyle={viewWarningDetail && viewWarningDetail.length > 7 ? { height: '450px', overflow: 'auto' } : null}
          footer={[
            <Button01 key="submit" type="primary" btnsize="wd_df" size={"small"} onClick={handleCancelViewShiftWarning}>
              <FormattedMessage
                id="btnOK"
                defaultMessage="OK"
              />
            </Button01>,
          ]}
        >
          {viewWarningDetail.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <p style={{ fontSize: '12px', color: '#1D3557' }}>{item.shiftMasterName}</p>
              {item.warning.map((e, i) => (
                <p key={i} style={{ fontSize: '12px', color: '#1D3557' }}>- {e}</p>
              ))}
            </div>
          ))}
        </Modal> */}

        {/* <Modal
          className="shift-modal-schedule"
          title={
            <div>
              Warning{' '}<Badge className="shift-planning-title-dot" color="#1D3557" />{' '}{txtWarningSuffixResource}
            </div>
          }
          centered={true}
          visible={visibleDetailWorkRes}
          onOk={handleCancelViewShiftWarning}
          onCancel={handleCancelViewShiftWarning}
          bodyStyle={viewWarningDetailResource && viewWarningDetailResource.length > 7 ? { height: '450px', overflow: 'auto' } : null}
          footer={[
            <Button01 key="submit" type="primary" btnsize="wd_df" size={"small"} onClick={handleCancelViewShiftWarning}>
              <FormattedMessage
                id="btnOK"
                defaultMessage="OK"
              />
            </Button01>,
          ]}
        >
          {viewWarningDetailResource.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <p style={{ fontSize: '12px', color: '#1D3557' }}>{moment(item.workDate, 'YYYY-MM-DD').format('ddd, MMM DD, YYYY')}</p>
              {item.warning.map((e, i) => (
                <p key={i} style={{ fontSize: '12px', color: '#1D3557' }}>- {e}</p>
              ))}
            </div>
          ))}
        </Modal> */}

        <Modal
          className="shift-modal-schedule"
          title={intl.formatMessage({ id: 'orgShiftPlanningModalTitleDelete', defaultMessage: 'Delete Shift' })}
          centered={true}
          width={550}
          visible={editVisible}
          onOk={handleOkDelete}
          onCancel={handleCancelDelete}
          footer={[
            <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={handleCancelDelete}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={handleOkDelete}>
              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
            </Button01>,
          ]}
        >
          <ShiftModalDelete formData={formData} />
        </Modal>

        <ShiftModalAddPlanning
          ref={saveFormRef}
          formData={formData}
          onCancel={() => handleCancel()}
          onCreate={() => handleSubmit()}
          rangePickerValue={rangePickerValue}
          visible={formVisible}
          statePlanning={statePlanning}
          rangePickerDisabled={'true'}
        />
      </div>
    ) : null;
  },
  (prev, next) => {
    // console.log('-------------------- memo --------------------');
    // console.log(_.isMatch(prev.statePlanning, next.statePlanning));
    // console.log(prev);
    // console.log(next);
    return (
      _.isMatch(prev.statePlanning, next.statePlanning) &&
      _.isMatch(prev.statePlanning.warningDate, next.statePlanning.warningDate) &&
      _.isMatch(prev.statePlanning.warningResource, next.statePlanning.warningResource)
    );
  }
);
