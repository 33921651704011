import React, { useContext } from 'react'
import AppinputSearch from '../../../../components/inputSearch'
import { useIntl, FormattedMessage } from 'react-intl'
import { DeliveryContext } from '../..'
import _ from 'lodash'
import { Avatar } from 'antd'
import DefaultProfile from '../../../../components/image/NoImageR.png';

const BranchList = () => {
  const intl = useIntl()
  const { handleSearchBranch, searchBranch, handleSelectBranch, selectBranch, branchList, onScrollBranch } = useContext(DeliveryContext)

  const checkImg = (img) => {
    if (_.includes(img, '/img/null') || !img) {
      return DefaultProfile
    } else {
      return img
    }
  }

  return (
    <div>
      <div style={{ padding: '16px 16px 8px 16px' }}>
        <AppinputSearch
          placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
          onChange={handleSearchBranch}
          value={searchBranch}
        />
      </div>
      <div className='branchList' onScroll={onScrollBranch}>
        {
          _.map(branchList, (item, index) => (
            <div key={index} className={selectBranch === item.id ? 'branchItemSelect' : 'branchItem'} onClick={() => handleSelectBranch(item.id, item)}>
              <div>
                <Avatar shape="square" size={64} src={checkImg(item.branchPhotoUrl)} />
              </div>
              <div style={{ marginLeft: '16px' }}>
                <div className='branchTitle'>
                  {item.branchCode} · {item.branchName}
                </div>
                <div style={{ marginTop: '8px' }}>
                  <span className='branchTotalOrder'><FormattedMessage id="deliveryOrderBranchTotalOrder" defaultMessage="Total order" />: </span>
                  <span className='branchTotalOrder'>{item.orderTotal}</span>
                  {" "}
                  <span className='branchTotalOrder'>
                    {
                      parseInt(item.orderTotal) > 1 ? (
                        <FormattedMessage id="deliveryOrderBranchOrdersTxt" defaultMessage="orders" />
                      ) : (
                        <FormattedMessage id="deliveryOrderBranchOrderTxt" defaultMessage="order" />
                      )
                    }
                  </span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div >
  )
}

export default BranchList
