import React, { useState, useEffect } from 'react';
import MapTracking from './map-speed-tracking';
import GraphTracking from './graph-speed-tracking';
import httpClient from '../../../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';

const comId = localStorage.getItem('comId')

export default (props) => {

  const { resourceId, date, mainOrgIdString, resourceData, setTrackingResource, trackingResource, LangCode } = props;
  const dateFormat = moment(date).format('YYYY-MM-DD');
  const [taskStatusData, setTaskStatusData] = useState([]);
  const [latLngResource, setlatLngResource] = useState({ lat: 13.90607, lng: 100.51913 });


  useEffect(() => {
    if (resourceId) {
      const getTaskStatus = async () => {
        try {
          const response = await httpClient.get(
            `/v3/task/manager/company/${comId}/my/${resourceId}/task/group?startDate=${dateFormat}&endDate=${dateFormat}&orgId=${mainOrgIdString}&menuType=transportation`,
          );
          if (response.status === 200) {

            setTaskStatusData(_.toArray(response.data));
            const arrayChunk = _.chunk(_.toArray(response.data), 3);

            let arrTask = [];
            _.map(arrayChunk[0], el => { arrTask.push(...el.tasks) });

            const colorData = _.filter(resourceData, (el) => { return el.memComId === resourceId }).map((e) => { return e.statusOnGoingColor })
            const colorStatus = colorData && colorData.toString();


            setTrackingResource({
              resId: resourceId,
              openSpeedTrack: true,
              dataSpeedTrack: { ...arrayChunk[1][0] },
              dataTask: arrTask,
              statusColor: colorStatus
            })
          }
        } catch (error) {
          return
        }
      }

      getTaskStatus();

      const interval = setInterval(() => {
        getTaskStatus();
      }, 30000);
      return () => clearInterval(interval);

    }
  }, [resourceId]);



  return (
    <div>
      <MapTracking
        trackingResource={trackingResource}
        setTrackingResource={setTrackingResource}
        setlatLngResource={setlatLngResource}
        latLngResource={latLngResource}
      />
      <GraphTracking
        trackingResource={trackingResource}
        setTrackingResource={setTrackingResource}
        setlatLngResource={setlatLngResource}
        latLngResource={latLngResource}
        date={dateFormat}
        LangCode={LangCode}
      />
    </div>
  )
}