import React, { useState, useEffect, useContext } from 'react';
import { Table, Col, Row, Form, Select, DatePicker, Input, Dropdown, Menu, Button, Card } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { AllowanceContext } from './allowancecontext';
import _ from 'lodash';
import { searchAllowanceList } from '../../../controllers/setting/allowanceapi';
const { Option } = Select;
const InputGroup = Input.Group;

const Fillter = (props) => {
  const { form } = props;
  const { state, setState, fnc } = useContext(AllowanceContext);
  const { allowanceListLeft, allowanceListRight, sourcList, destinationList, page, size, isLoading,searchGroup } = state;
  const { setAllowanceListLeft, setIsLoading,setSearchGroup } = setState;
  const {} = fnc;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const [isFilter, setIsFilter] = useState(false);
  const [isTask, setIsTask] = useState(true);
  const dataTask = _.get(allowanceListRight, 'taskTypeIdList');
  const dataSource = _.get(sourcList, 'list');
  const dataDestination = _.get(destinationList, 'list');
  const dataDistance = _.get(allowanceListLeft, 'list');

  console.log('allowanceListRight', dataDistance);
  console.log('allowanceListLeft', destinationList);
  console.log('allowanceLoad', isLoading);


  const handleOpenFilter = () => {
    setIsFilter(!isFilter);
    setSearchGroup([])
  };

  const handleserachAllowance = async () => {
    setIsLoading(true);
    try {
      let payload = {
        taskTypeId: getFieldValue('tasktype'),
        sourceId: getFieldValue('source'),
        destinationId: getFieldValue('destination'),
        distanceFrom: searchGroup.From || 0,
        distanceTo: searchGroup.to || 1000,
        pageNumber: page,
        limit: size,
        orderBy: '',
        orderType: '',
      };
      console.log('testdatacom', payload);
      const response = await searchAllowanceList(payload);
      if (response.status.code === 200) {
        setIsLoading(false);
        setAllowanceListLeft(response.data);
      }
    } catch (error) {}
  };

  const onChange = async (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const clearValue = async (e,code) => {
    if (e === undefined) {
      setSearchGroup({...searchGroup, [code]: '' });
    }
    
  };

  return (
    <div>
      <div style={{ padding: '16px' }}>
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenFilter()}>
          <FormattedMessage id="assetpreventiveFillter" defaultMessage="Filter" />{' '}
          <DownOutlined style={{ fontSize: '12px', paddingLeft: '12%' }} />
        </Button_01>
      </div>
      {isFilter === true && (
        <div className="filterBackground">
          <Form>
            <Row gutter={24}>
              <Col span={11} style={{ padding: '16px 26px 0px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="allowanceTaskType" defaultMessage="Task Type" />
                  </span>
                    <Select
                      onSelect={(e) => onChange(e, 'task')}
                      onChange={(e)=> clearValue(e, 'task')}
                      style={{ width: '100%' }}
                      allowClear
                      placeholder={intl.formatMessage({ id: 'allowanceSelectTaskType', defaultMessage: 'Select Task Type' })}
                      // onClear={clearValue('task')}
                    >
                      {dataTask &&
                        _.map(dataTask, (item, index) => {
                          return (
                            <Option key={_.get(item, 'taskTypeId')} value={_.get(item, 'taskTypeId')}>
                              {_.get(item, 'taskTypeName')}
                            </Option>
                          );
                        })}
                    </Select>
                </Form.Item>
              </Col>
              <Col span={11} style={{ padding: '16px 26px 0px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="allowanceSource" defaultMessage="Source" />
                  </span>

                    <Select
                      onSelect={(e) => onChange(e, 'source')}
                      style={{ width: '100%' }}
                      allowClear
                      onChange={(e)=> clearValue(e, 'source')}
                      placeholder={intl.formatMessage({ id: 'allowanceSelectSource', defaultMessage: 'Select Source' })}
                    >
                      {dataSource &&
                        _.map(dataSource, (item, index) => {
                          return (
                            <Option key={_.get(item, 'sourceId')} value={_.get(item, 'sourceId')}>
                              {_.get(item, 'addressName')}
                            </Option>
                          );
                        })}
                    </Select>

                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={11} style={{ padding: '16px 26px 16px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="allowanceDestination" defaultMessage="Destination" />
                  </span>

                    <Select
                    allowClear
                    onSelect={(e) => onChange(e, 'destination')}
                      style={{ width: '100%' }}
                      onChange={(e)=> clearValue(e, 'destination')}
                      placeholder={intl.formatMessage({ id: 'allowanceSelectDestination', defaultMessage: 'Select Destination' })}
                    >
                      {dataDestination &&
                        _.map(dataDestination, (item, index) => {
                          return (
                            <Option key={_.get(item, 'destinationId')} value={_.get(item, 'destinationId')}>
                              {_.get(item, 'addressName')}
                            </Option>
                          );
                        })}
                    </Select>
                </Form.Item>
              </Col>
              <Col span={11} style={{ padding: '16px 26px 16px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="allowanceDistance" defaultMessage="Distance (km)" />
                  </span>

                  <InputGroup compact>
                    <Row>
                      <Col span={11}>
                        <Input
                          className="warehouseMovementInput1"
                          style={{ textAlign: 'center' }}
                          allowClear
                          value={_.get(searchGroup, 'From')}
                          onChange={(e) => onChange(e.target.value, 'From')}
                          placeholder={intl.formatMessage({ id: 'AllowanceFillterTo', defaultMessage: 'Enter From Distance' })}
                        />
                      </Col>

                      <Col span={2}>
                        <Input
                          className="warehouseMovementInputComma"
                          style={{
                            pointerEvents: 'none',
                            backgroundColor: '#fff',
                          }}
                          placeholder="~"
                          disabled
                        />
                      </Col>
                      <Col span={11}>
                        <Input
                          className="warehouseMovementInput2"
                          style={{ textAlign: 'center' }}
                          allowClear
                          value={_.get(searchGroup, 'to')}
                          onChange={(e) => onChange(e.target.value, 'to')}
                          placeholder={intl.formatMessage({ id: 'AllowanceFillterTo', defaultMessage: 'Enter To Distance' })}
                        />
                      </Col>
                    </Row>
                  </InputGroup>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

const AllowanceFillter = Form.create({
  name: 'Allowance_filter_form',
  mapPropsToFields() {},
})(Fillter);

export default AllowanceFillter;
