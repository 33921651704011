import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import routes from './config/main-route';
import { PageSettings } from './config/main-settings';
import messages from './language/lang/en.json';
import TxtVersions from './pages/versions/txt-versions';

export default () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [locale, setLocale] = useState('en');
  const [messagesValue, setMessageValue] = useState();

  const handlerAuthenticate = (status) => {
    setIsAuthenticated(status);
  };

  const handlerLocale = async (language) => {
    setLocale(language);
  };

  return (
    <div>
      <PageSettings.Provider
        value={{
          isAuthenticated,
          handlerAuthenticate,
          handlerLocale,
          setMessageValue,
        }}
      >
        <IntlProvider locale={locale} messages={messagesValue || messages}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          </Switch>
        </IntlProvider>
      </PageSettings.Provider>
      <TxtVersions />
    </div>
  );
};
