import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import LabeRequireForm from '../../../../components/label-required-form';
import { handleKeyDownNumber } from '../../../../component-function/fnc-number';
import { getConvertUoMBigger } from '../../../../controllers/po/po';

const { Option } = Select;
const { TextArea } = Input;

const RejectModal = ({ form, title, visible, onOk, onCancel, selectItem, setSelectItem, record, closeItemTranferModal, typeEdit }) => {
  const { getFieldDecorator, resetFields, getFieldValue } = form;
  const intl = useIntl();
  const qtyitem = _.filter(selectItem, (e) => e.movementId === _.get(record, 'movementId'));
  const maxQTY =
    qtyitem[0] !== undefined && typeEdit === 'add'
      ? parseInt(_.get(record, 'qtyNum')) - parseInt(_.get(qtyitem, '[0].qtyStack'))
      : _.get(record, 'qtyNum');

  const [stockList, setStockList] = useState([]);
  const [uomList, setUomList] = useState([]);

  useEffect(() => {
    const getStockList = async () => {
      // const response = await getStockListById(_.get(data, 'itemMasterId'));
      // const unitData = await getConvertUoM(_.get(data, 'itemMasterId'));
      const unitData = await getConvertUoMBigger(_.get(record, 'itemMasterId'));

      // setStockList(_.get(response, 'data.data.stockList'));
      setUomList(_.get(unitData, 'data.data'));
    };
    if (record && visible) {
      getStockList();
    }
  }, [visible]);

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('values', values);
      if (err) {
        return;
      }

      const addmoreitem = _.findIndex(selectItem, { movementId: _.get(record, 'movementId') });
      const editmoreitem = _.findIndex(selectItem, (item) => {
        return _.get(item, 'uomId') === _.get(values, 'unit') && _.get(item, 'movementId') === _.get(record, 'movementId');
      });
      const FilterUom = _.filter(selectItem, (item) => {
        return item.uomId === _.get(values, 'unit');
      });
      const uomName = _.filter(uomList, (item) => {
        return item.uomId === getFieldValue('unit');
      });
      // const tempItem = [];

      console.log('addmoreitem', addmoreitem, '/', maxQTY);

      const payload = {
        expiredDateTxt: _.get(record, 'expiredDateTxt'),
        fda: _.get(record, 'fda'),
        itemCode: _.get(record, 'itemCode'),
        itemMasterId: _.get(record, 'itemMasterId'),
        itemName: _.get(record, 'itemName'),
        lotOrSerialNo: _.get(record, 'lotOrSerialNo'),
        manufacturingDateTxt: _.get(record, 'manufacturingDateTxt'),
        numberType: _.get(record, 'numberType'),
        qty:
          addmoreitem !== -1 && typeEdit === 'add' && _.size(FilterUom) > 0
            ? parseInt(_.get(values, 'qty')) + parseInt(_.get(qtyitem, '[0].qtyStack'))
            : typeEdit === 'edit' && addmoreitem !== -1 && editmoreitem !== -1 && _.get(record, 'uomId') !== _.get(values, 'unit')
            ? parseInt(_.get(values, 'qty')) + parseInt(_.get(selectItem[editmoreitem], 'qty'))
            : parseInt(_.get(values, 'qty')),
        qtyNum: parseInt(_.get(record, 'qtyNum')),
        qtyStack:
          addmoreitem !== -1 && typeEdit === 'add' && _.size(FilterUom) > 0
            ? parseInt(_.get(values, 'qty')) + parseInt(_.get(qtyitem, '[0].qtyStack'))
            : typeEdit === 'edit' && addmoreitem !== -1 && editmoreitem !== -1 && _.get(record, 'uomId') !== _.get(values, 'unit')
            ? parseInt(_.get(values, 'qty')) + _.get(selectItem[editmoreitem], 'qtyStack')
            : parseInt(_.get(values, 'qty')),
        receiveBy: _.get(record, 'receiveBy'),
        receiveDate: _.get(record, 'receiveDate'),
        remarkTran: _.get(values, 'remarkTran'),
        warehouseId: _.get(record, 'warehouseId'),
        movementId: _.get(record, 'movementId'),
        uomId: _.get(values, 'unit'),
        uom: _.get(uomName, '[0].name') ? _.get(uomName, '[0].name') : 'Sack',
      };

      let temprecord = [];

      console.log('FilterUom', FilterUom, '/', selectItem);

      if ((addmoreitem !== -1 && _.size(FilterUom) > 0) || typeEdit === 'edit') {
        if (editmoreitem !== -1 && _.get(record, 'uomId') !== _.get(values, 'unit')) {
          const DeleteUOM = _.filter(selectItem, (item) => {
            return (
              // item.uomId !== _.get(record, 'uomId')
              _.get(item, 'movementId') !== _.get(record, 'movementId') ||
              (_.get(item, 'uomId') !== _.get(record, 'uomId') && _.get(item, 'movementId') === _.get(record, 'movementId'))

              // item.uomId !== _.get(record, 'unit') && item.movementId === _.get(record, 'movementId')
            );
          });

          const editmoreitem2 = _.findIndex(DeleteUOM, (item) => {
            return _.get(item, 'uomId') === _.get(values, 'unit') && _.get(item, 'movementId') === _.get(record, 'movementId');
          });

          console.log('DeleteUOM', DeleteUOM);
          temprecord = [...DeleteUOM];
          console.log('temprecord1', temprecord);
          temprecord[editmoreitem2] = payload;
          console.log('temprecord2', temprecord);
        } else if (editmoreitem !== -1) {
          temprecord = [...selectItem];
          temprecord[editmoreitem] = payload;
        } else {
          temprecord = [...selectItem];
          temprecord[addmoreitem] = payload;
        }
      } else {
        temprecord.push(...selectItem, payload);
      }

      console.log('payload', temprecord);

      // onOk(payload);
      setSelectItem(temprecord);
      onCancel();
      closeItemTranferModal();
      resetFields();
    });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };

  const handleAmount = (e, value) => {
    console.log('AmountMax', _.get(qtyitem, '[0].qtyNum') !== maxQTY, '/', _.get(qtyitem, '[0].qtyNum'), '/', maxQTY);

    const FilterUom = _.filter(uomList, (item) => {
      return item.uomId === getFieldValue('unit');
    });

    const altQty = _.get(FilterUom, '[0].altQty');

    const parsValue = parseFloat(value * altQty);

    console.log('BABABAB', parsValue, '/', altQty);

    if (parsValue > maxQTY) {
      e.target.value = maxQTY;
    } else if (parsValue <= 0 && maxQTY !== 0) {
      e.target.value = 1;
    }

    // if (parseInt(value) > maxQTY) {
    //   e.target.value = maxQTY;
    // } else if (parseInt(value) <= 0 && maxQTY !== 0  ) {
    //   e.target.value = 1;
    // }
  };

  const validatorQty = (rule, value, callback) => {
    if (value) {
      console.log('selectItemQty sumBySelectItem', value, '/', getFieldValue('unit'));

      const FilterUom = _.filter(uomList, (item) => {
        return item.uomId === getFieldValue('unit');
      });

      const altQty = _.get(FilterUom, '[0].altQty');

      const parsValue = parseFloat(value * altQty || 0);

      console.log('altQty', altQty);
      if (getFieldValue('unit')) {
        if (parsValue === 0) {
          callback(intl.formatMessage({ id: 'tranferQTYZero', defaultMessage: 'QTY must more than 0' }));
        } else if (parsValue > maxQTY) {
          callback(intl.formatMessage({ id: 'tranferQTYMore', defaultMessage: 'QTY more than stock' }));
        } else {
          callback();
        }
      } else {
        callback(intl.formatMessage({ id: 'tranferQTYUnit', defaultMessage: 'Please Select Unit' }));
      }

      // else if (parsValue > qtyRemaining){
      //   callback(`Remaining stock ${qtyRemaining} items`)
      // }
    } else {
      callback();
    }
  };

  return (
    <Modal
      title={
        typeEdit === 'edit'
          ? intl.formatMessage({ id: 'TFTransferItemEdit', defaultMessage: 'Edit Transfer Item' })
          : intl.formatMessage({ id: 'TFTransferItem', defaultMessage: 'Transfer Item' })
      }
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      width={500}
      centered={true}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="status" colon={false}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'TFItem', defaultMessage: 'Item' })} req={false} />}>
              {_.get(record, 'itemName')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'TFLotNoorSerailNo', defaultMessage: 'Lot No. or Serial No.' })}
                  req={false}
                />
              }
            >
              {_.get(record, 'lotOrSerialNo')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'TFQTY', defaultMessage: 'Qty' })} req={false} />}>
              {typeEdit === 'edit'
                ? _.get(record, 'warehouseQty')
                  ? _.get(record, 'warehouseQty')
                  : _.get(record, 'qtyNum')
                : _.get(record, 'qtyNum')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'TFTransferQty', defaultMessage: 'Transfer Qty' })} req={true} />}
            >
              {getFieldDecorator('qty', {
                initialValue: typeEdit === 'edit' ? _.get(record, 'qtyStack') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'TFTransferQtyValidate', defaultMessage: 'Please Enter Transfer Qty' }),
                  },
                  {
                    validator: validatorQty,
                  },
                ],
              })(
                <Input
                  // onChange={(e) => {
                  //   handleAmount(e, e.target.value);
                  // }}
                  onKeyDown={handleKeyDownNumber}
                  placeholder={intl.formatMessage({ id: 'TFTransferQtyPlaceHolder', defaultMessage: 'Enter Transfer Qty' })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />}
            >
              {getFieldDecorator('unit', {
                initialValue: typeEdit === 'edit' ? _.get(record, 'uomId') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderUnit', defaultMessage: 'Select Unit' })}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                >
                  {_.map(uomList, (item) => (
                    <Option value={item.uomId}>{item.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelRemark', defaultMessage: 'Remark' })} req={false} />}
            >
              {getFieldDecorator('remarkTran', {
                initialValue:
                  typeEdit === 'edit' ? (_.get(record, 'remarkTran') ? _.get(record, 'remarkTran') : _.get(record, 'remark')) : '',
              })(<TextArea autoSize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

RejectModal.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalQTYTranferForm = Form.create({ name: 'reject_Form' })(RejectModal);

export default ModalQTYTranferForm;
