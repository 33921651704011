import React from 'react';
import httpClient from '../../components/axiosClient';

export const fncGetUsers = async (memComId, comId = null, isResignDate = false) => {
  try {
    const body = {
      user_id: memComId,
      like: [],
      equal: [],
      date: [],
      order: `fullname asc`,
      limit: `1000`,
      index_page: `1`,
      com_id: comId,
      notResign: isResignDate
    };
    const result = await httpClient.post('/v2/searchusers', body);
    if (result.status === 200) {
      return result.data.data;
    }
    else {
      return false;
    }
  }
  catch (e) {
    return false;
  }
}