import React, { useRef, useCallback, useContext, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import _ from 'lodash';
import './css/index.css';
import { CompanyLocation } from './context';
import IconMarker from '../image/LocationEngagement.svg';
import Button05 from '../v2/button_05';
import Button06 from '../v2/button_06';
import { FormattedMessage } from 'react-intl';

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

export default () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    id: 'google-map-script',
  });

  const { companyState, companySetState, companyFNC } = useContext(CompanyLocation);
  const mapRef = useRef();

  const [map, setMap] = useState(null);

  const libraries = ['places'];
  const mapContainerStyle = {
    height: `450px`,
    width: '100%',
    position: 'relative',
  };

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: 'cooperative',
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    setMap(map);
  }, []);

  // const onLoad = useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  console.log('addressssss', companyState.selectAddress ,companyState.dragMarker);

  return (
    <div style={{ marginTop: '12px' }}>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={mapContainerStyle}
        center={companyState.center}
        zoom={10}
        options={defaultMapOption}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {companyState.selectAddress &&
          companyState.selectAddress.map((item) => (
            <Marker
              key={item.addressBookId}
              position={companyState.position}
              animation={window.google.maps.Animation.DROP}
              draggable={true}
              icon={{
                url: IconMarker,
                scaledSize: new window.google.maps.Size(50, 50),
                anchor: new window.google.maps.Point(12, 42),
              }}
              onClick={() => {
                companyFNC.onClickMarker(item.addressBookId);
              }}
              onDragEnd={companyFNC.onDragEnd}
            >
              {companyState.dragMarker && companyState.dragMarker === item.addressBookId ? (
                <InfoWindow
                  position={companyState.position}
                  onCloseClick={companyFNC.onCloseMarker}
                  visible={companyState.visibleInfoWindow}
                  options={{
                    pixelOffset: new window.google.maps.Size(-12.5, 2),
                  }}
                  
                >
                  <div className="location-map-info-padding">
                    <div className="location-map-info-div-margin">
                      <p className="location-map-info-header" style={{ marginBottom: '5px' }}>
                        {_.get(item, 'name')}
                      </p>
                      <span className="location-map-info-detail">{_.get(item, 'fullAddress')}</span>
                    </div>
                    <div className="location-map-info-button-align">
                      {_.get(item, 'status') === 'normal_address' && companyState.customerId ? (
                        <Button06
                          key="back"
                          btnsize="wd_df"
                          btnfontsize="smm"
                          onClick={() => {
                            // companySetState.setStatuFromsAdd('in');
                            // companySetState.setVisibleAdd(true);
                            // companyFNC.handleCreateAddressBookId(item)
                            companyFNC.handleSaveeEditLocation(item)
                          }}
                        >
                          <FormattedMessage id="btnSaveAddress" defaultMessage="Save Address" />
                        </Button06>
                      ) : null}
                      <Button05 key="submit" type="primary" btnsize="wd_df" btnfontsize="smm" onClick={() => companyFNC.handleCreate(item)}>
                        <FormattedMessage id="btnSelectLocation" defaultMessage="Select This Location" />
                      </Button05>
                    </div>
                  </div>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
      </GoogleMap>
    </div>
  );
};
