import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Divider, Table } from 'antd';
import Button02 from '../../../../components/v2/button_02';
import Button01 from '../../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../index.css';
import ViewAction from './action';
import { getPointHistory } from '../../../../controllers/point/point';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const ViewPoint = (props) => {
  const { visible, setVisible, defaultData } = props;
  const intl = useIntl();
  const [searchGroup, setSearchGroup] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [historyTotal, setHistoryTotal] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [orderSort, setOrderSort] = useState();
  const [fieldSort, setFieldSort] = useState();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      // render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsDate', defaultMessage: 'Date' }),
      dataIndex: 'createdAtWeb',
      key: 'createdAtWeb',
      width: 120,
    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsAmount', defaultMessage: 'Amount (Pt)' }),
      dataIndex: 'pointAmountNoUnit',
      key: 'pointAmountNoUnit',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsDescription', defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      width: 150,
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsStatus', defaultMessage: 'Status' }),
      dataIndex: 'statusTxt',
      key: 'statusTxt',
      width: 100,
    },
  ];

  useEffect(() => {
    const getDataAndFilter = async () => {
      const body = {
        pointTypeCode: _.get(defaultData, 'pointTypeCode'),
        memberId: _.get(defaultData, 'memComId'),
        actionCode: _.get(searchGroup, 'action') || '',
        startDate: _.get(searchGroup, 'fromDate') ? moment(_.get(searchGroup, 'fromDate')).format('YYYY-MM-DD') : '',
        endDate: _.get(searchGroup, 'toDate') ? moment(_.get(searchGroup, 'toDate')).format('YYYY-MM-DD') : '',
        pageNumber: paginationPage,
        limit: paginationSize,
      };
      const response = await getPointHistory(body);
      setHistoryData(_.get(response, 'data.data.pointHistory'));
      setActionData(
        _.filter(_.get(response, 'data.data.capsuleFiltering'), (item) => {
          return item.code === 'pointAction';
        })
      );
      setHistoryTotal(_.get(response, 'data.data.totalItems'));
    };
    if (visible) {
      getDataAndFilter();
    }
  }, [visible, searchGroup, paginationPage,paginationSize]);

  const handleClose = () => {
    setVisible(false);
    setSearchGroup([]);
    setHistoryData([]);
    setActionData([]);
    setHistoryTotal(0);
    setPaginationPage(1);
    setPaginationSize(10);
  };

  const TitleHeader = () => {
    return <div>Point Details・{_.get(defaultData, 'memberName')}</div>;
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  return (
    <Modal
      title={<TitleHeader />}
      onCancel={handleClose}
      visible={visible}
      centered={true}
      width={825}
      bodyStyle={{ padding: 'unset' }}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose} margin="unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        // <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
        //   <FormattedMessage id="btnSave" defaultMessage="Save" />
        // </Button01>,
      ]}
    >
      <Row gutter={[8]} style={{ padding: '24px 24px 0 24px' }}>
        <Col span={10}>
          <div className="campaignPointCardTitleFilter">
            <FormattedMessage id="menuCampaignPointViewNameText" defaultMessage="Name" />
          </div>
          <div className="campaignPointCardTitleFilterValue">
            {_.get(defaultData, 'role')}・{_.get(defaultData, 'memberName')}
          </div>
        </Col>
        <Col span={7}>
          <div className="campaignPointCardTitleFilter">
            <FormattedMessage id="menuCampaignPointViewPointTypeText" defaultMessage="Point Type" />
          </div>
          <div className="campaignPointCardTitleFilterValue">{_.get(defaultData, 'pointTypeCode')}</div>
        </Col>
        <Col span={7}>
          <div className="campaignPointCardTitleFilter">
            <FormattedMessage id="menuCampaignPointViewBalanceText" defaultMessage="Balance (Pt)" />
          </div>
          <div className="campaignPointCardTitleFilterValue">
            <NumberFormat value={parseFloat(_.get(defaultData, 'pointAmount')).toFixed(2)} displayType={'text'} thousandSeparator={true} />
          </div>
        </Col>
      </Row>
      <div style={{ padding: '0 24px' }}>
        <Divider type="horizontal" />
      </div>
      <ViewAction searchGroup={searchGroup} setSearchGroup={setSearchGroup} actionData={actionData} />
      <div style={{ padding: '24px' }}>
        <Table
          columns={columns}
          bodyStyle={{ overflowX: 'auto' }}
          dataSource={historyData}
          scroll={{ x: 600, y: '220px' }}
          onChange={handleChange}
          pagination={{
            total: historyTotal,
            current: paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
        />
        {_.size(historyData) > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${historyTotal || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default ViewPoint;
