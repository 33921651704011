import React, { useContext } from 'react'
import { DeliveryContext } from '../../..'
import { Avatar, Row, Col, Icon, Divider, Switch } from 'antd'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import DefaultProfile from '../../../../../components/image/NoImageR.png';
import { updateBranch } from '../../../../../controllers/delivery-order/branch'
import { successNotification, errorNotification } from '../../../../../components/v2/notification'
import { PageSettings } from '../../../../../config/page-settings'

const DeliveryInformation = () => {
  const { selectBranchData, branchList, setBranchList, setSelectBranchData } = useContext(DeliveryContext)
  const { checkPermissionAction } = useContext(PageSettings)

  const onChange = async (checked) => {
    try {
      const payload = {
        branchCode: _.get(selectBranchData, 'branchCode'),
        branchName: _.get(selectBranchData, 'branchName'),
        branchPhone: _.get(selectBranchData, 'branchPhone'),
        branchAddressName: _.get(selectBranchData, 'branchAddressName'),
        branchAddressDescription: _.get(selectBranchData, 'branchAddressDescription'),
        branchAddressLat: _.get(selectBranchData, 'branchAddressLat'),
        branchAddressLng: _.get(selectBranchData, 'branchAddressLng'),
        branchRemark: _.get(selectBranchData, 'branchRemark'),
        branchStatus: checked,
      };
      const response = await updateBranch(_.get(selectBranchData, 'id'), payload);
      if (_.get(response, 'status') === 200) {
        if (_.get(response, 'data.data.id')) {
          setSelectBranchData(_.get(response, 'data.data'))
          setBranchList(_.map(branchList, (item) => {
            return item.id === _.get(response, 'data.data.id') ? _.get(response, 'data.data') : item
          }));
          successNotification(_.get(response.data.status, 'message'));
        }
      }
    } catch (error) {
      errorNotification(_.get(error.response.data.status, 'message'));
    }
  };

  const checkImg = (img) => {
    if (_.includes(img, '/img/null')) {
      return DefaultProfile
    } else {
      return img
    }
  }

  return (
    <div style={{ padding: '24px 56px 0 56px' }}>
      {
        checkPermissionAction('P58PG1C1', 'P58PG1C1A3') ? (
          <div style={{ textAlign: 'end', marginBottom: '16px' }}>
            <Switch checked={_.get(selectBranchData, 'branchStatus') || false} onChange={onChange} />
          </div>
        ) : null
      }
      <Row gutter={[56]}>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Avatar shape="square" size={200} src={checkImg(_.get(selectBranchData, 'branchPhotoUrl'))} />
        </Col>
        <Col span={16}>
          <div className='deliveryDetailTitle'>
            {_.get(selectBranchData, 'branchCode')} · {_.get(selectBranchData, 'branchName')}
          </div>
          <div style={{ marginTop: '24px', color: '#1D3557', fontSize: '12px' }}>
            <FormattedMessage id="deliveryOrderInformationPhone" defaultMessage="Phone Number" />
          </div>
          <div style={{ marginTop: '8px', color: '#050505', fontSize: '13px' }}>
            {_.get(selectBranchData, 'branchPhone')}
          </div>
          <div style={{ marginTop: '16px', color: '#1D3557', fontSize: '12px' }}>
            <FormattedMessage id="deliveryOrderInformationAddress" defaultMessage="Address" />
          </div>
          <div style={{ marginTop: '8px' }}>
            <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
            <span style={{ color: '#050505', fontSize: '13px' }}>{_.get(selectBranchData, 'branchAddressDescription')}</span>
          </div>
          <Divider type="horizontal" />
          <div style={{ color: '#1D3557', fontSize: '12px' }}>
            <FormattedMessage id="deliveryOrderInformationRemark" defaultMessage="Remark" />
          </div>
          <div style={{ marginTop: '8px', color: '#050505', fontSize: '13px' }}>
            {_.get(selectBranchData, 'branchRemark') || '-'}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default DeliveryInformation
