import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');
const comId = localStorage.getItem('comId');

const createGasprice = async (body) => {
  try {
    const response = await httpClient.post(`/v1/travelexpense/manager/company/${comCode}/gas-price/create`, body);
    if (response.status === 200) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false;
  }
};

export default createGasprice;
