import React, { useEffect, useState } from 'react'
import './index.css'
import { getMenuOrder } from '../../controllers/order-menu/order'
import queryString from 'query-string';
import _ from 'lodash'
import { Card, Row, Col } from 'antd'

const ViewMenu = () => {
  const parsed = queryString.parse(window.location.search);
  const { comCode, id, lang } = parsed
  const [menuData, setMenuData] = useState()

  useEffect(() => {
    const getData = async () => {
      const response = await getMenuOrder(comCode, id)
      setMenuData(_.get(response, 'data.data'))
    }
    if (comCode && id) {
      getData()
    }
  }, [comCode, id])

  console.log("menuData", menuData)

  return (
    <div className='orderMenuBackground'>
      <div className='orderMenuCardBackground'>
        {
          _.map(_.get(menuData, 'orderSummary') || [], (item, index) => (
            <div style={{ padding: '0 2%', margin: '2% 0' }}>
              <Card
                className='orderMenuCard'
                style={index !== 0 ? { marginTop: '14px' } : null}
                bodyStyle={{ padding: 'unset' }}
              >
                <Row gutter={[16]}>
                  <Col span={8}>
                    <img src={item.itemImg} alt={item.name} style={{ width: '100%' }} />
                  </Col>
                  <Col span={16} style={{ padding: '2%' }}>
                    <div className='orderMenuCardText'>
                      {item.itemName}
                    </div>
                    <div className='orderMenuCardText'>
                      {item.priceTxt}
                    </div>
                    <div className='orderMenuCardText' style={{ marginTop: '2%' }}>
                      x {item.qty}
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          ))
        }
        <div style={{ padding: '0 14px' }}>
          <div className='orderMenuCardSummary'>
            <div className='orderMenuCardSummaryTitle'>
              {lang === "TH" ? "Subtotal" : "ผลรวมย่อย"}
            </div>
            <div className='orderMenuCardSummaryTitle'>
              {_.get(menuData, 'subtotalTxt')}
            </div>
          </div>
          <div className='orderMenuCardSummary'>
            <div className='orderMenuCardSummaryTitle'>
              {lang === "TH" ? "Delivert Fee" : "ค่าจัดส่ง"}
            </div>
            <div className='orderMenuCardSummaryTitle'>
              {_.get(menuData, 'deliveryFeeTxt')}
            </div>
          </div>
          <div className='orderMenuCardSummary'>
            <div className='orderMenuCardSummaryTitle'>

              {lang === "TH" ? "Total" : "ทั้งหมด"}
            </div>
            <div className='orderMenuCardSummaryValue'>
              {_.get(menuData, 'totalTxt')}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ViewMenu
