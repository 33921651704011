import React, { useLayoutEffect, useState } from 'react';
import Style from './css/index.css';
import { Modal, Form, Row, Col, Input, DatePicker, Select, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_02 from '../../v2/button_02';
import Button_01 from '../../v2/button_01';
import UploadTable from '../table-upload';
import ClaimHistoryView from './claim-history-view';
import _ from 'lodash';
import ReactBnbGallery from 'react-bnb-gallery';
import {
  deleteAssetClaimHistory,
  deleteClaimDocument,
  getAssetInsuranceByClaimDate,
  updateAssetClaimHistory,
} from '../../../controllers/asset-resource/asset-controller';
import UploadFileModal from '../modal-upload';
import { useEffect } from 'react';
import { errorNotification, successNotification } from '../../v2/notification';
import moment from 'moment';
import httpClient from '../../../components/axiosClient';
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';

const { Option } = Select;
const ViewEditClaimForm = (props) => {
  const { assetId, record, claimRef, visible, setVisible, setTriggerApi, trigger, setRecordClaim, form } = props;
  // console.log('record', record);
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const dateFormat = 'ddd, MMM DD YYYY';
  const [openUpload, setOpenUpload] = useState(false);
  const [attData, setAttData] = useState([]);
  const [typeAtt, setTypeAtt] = useState('add');
  const [defaultAtt, setDefaultAtt] = useState();
  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();
  const insuranceDate = getFieldValue('insuranceDate');
  const damageValue = getFieldValue('damageValue');
  const claimableAmount = getFieldValue('claimableAmount');
  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  const [insuranceDataClaim, setInsuranceDataClaim] = useState([]);
  const [memberClaim, setMemeberClaim] = useState();
  const [memberListMaster, setMemberListMaster] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberPage, setMemberPage] = useState(1);
  const [memberLoading, setMemberLoading] = useState(false);
  const [memberSelect, setMemberSelect] = useState();
  const [memberSearch, setMemberSearch] = useState();
  const [memberSearchPage, setMemberSearchPage] = useState(1);
  const [ismemberLoadMore, setIsMemberLoadMore] = useState(true);
  const [ismemberSearchLoadMore, setIsMemberSearchLoadMore] = useState(true);

  const dateByForm = moment(insuranceDate).format('YYYY-MM-DD');
  const dateById = _.get(record, 'data') ? _.get(record.data.assetClaimInfo, 'claimDate') : '';

  console.log('record', record, visible, memberList);
  console.log('bathhhhh', parseFloat(damageValue), parseFloat(claimableAmount));
  console.log("insuranceDataClaim", insuranceDataClaim)
  ///////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getData = async () => {
      const like = memberSearch ? [{ key: '', val: memberSearch }] : [];
      const indexPage = memberSearch ? 1 : memberPage;
      const payload = {
        user_id: memComId,
        like: like,
        equal: [],
        date: [],
        order: 'created_at ASC',
        limit: 10,
        index_page: indexPage,
        com_id: comId,
      };
      const result = await httpClient.post('/v2/searchusers', payload);
      if (result.status === 200) {
        const setDataMember = _.map(_.get(result, 'data.data'), (item) => {
          return {
            memComId: _.get(item, 'mem_com_id'),
            fullname: _.get(item, 'fullname'),
            phone: _.get(item, 'phone'),
          };
        });

        const members = _.uniqBy(_.concat(memberList, setDataMember), 'memComId');

        const memberFindIndex = _.findIndex(members, function (o) {
          return o.memComId === parseInt(_.get(record.data.assetClaimInfo, 'claimById'));
        });

        if (memberFindIndex > 0) {
          setMemberList(members);
          setMemberListMaster(members);
          setMemberLoading(false);
          setIsMemberLoadMore(_.size(setDataMember) > 0 ? true : false);
        } else {
          const membersNew = _.uniqBy(
            _.concat(members, [
              {
                memComId: parseInt(_.get(record.data.assetClaimInfo, 'claimById')),
                fullname: _.get(record.data.assetClaimInfo, 'claimBy'),
              },
            ]),
            'memComId'
          );
          setMemberList(membersNew);
          setMemberListMaster(membersNew);
          setMemberLoading(false);
          setIsMemberLoadMore(_.size(setDataMember) > 0 ? true : false);
        }
      }
    };

    if (_.get(record, 'data') && ismemberLoadMore && visible === true) {
      getData();
    }
  }, [memberPage, visible]);

  useEffect(() => {
    const getData = async () => {
      if (!memberSearch) {
        setMemberList(memberListMaster);
        setMemberLoading(false);
      } else {
        const like = memberSearch ? [{ key: '', val: memberSearch }] : [];
        const indexPage = memberSearch ? 1 : memberSearchPage;
        const payload = {
          user_id: memComId,
          like: like,
          equal: [],
          date: [],
          order: 'created_at ASC',
          limit: 10,
          index_page: indexPage,
          com_id: comId,
        };
        const result = await httpClient.post('/v2/searchusers', payload);
        if (result.status === 200) {
          const setDataMember = _.map(_.get(result, 'data.data'), (item) => {
            return {
              memComId: _.get(item, 'mem_com_id'),
              fullname: _.get(item, 'fullname'),
              phone: _.get(item, 'phone'),
            };
          });
          const members = _.uniqBy(_.concat(memberList, setDataMember), 'memComId');
          setMemberList(members);
          setMemberLoading(false);
          setIsMemberSearchLoadMore(_.size(setDataMember) > 0 ? true : false);
        }
      }
    };

    if (_.get(record, 'data') && ismemberSearchLoadMore && visible === true) {
      getData();
    }
  }, [memberSearch, memberSearchPage, visible]);

  useEffect(() => {
    if (_.get(record, 'data') && visible === true) {
      let members = _.uniqBy(
        _.concat(memberList, [
          {
            memComId: parseInt(_.get(record.data.assetClaimInfo, 'claimById')),
            fullname: _.get(record.data.assetClaimInfo, 'claimBy'),
          },
        ]),
        'memComId'
      );
      setMemberListMaster(members);
      setMemberList(members);
    }
  }, [_.get(record, 'data'), visible]);

  const handleMemberPopupScroll = (e) => {
    let target = e.target;
    if (!memberLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 20) {
      setMemberLoading(true);
      if (memberSearch) {
        setMemberSearchPage(memberSearchPage + 1);
      } else {
        setMemberPage(memberPage + 1);
      }
    }
  };

  const handleMemberChange = (value) => {
    if (!value) {
      setMemberSelect();
    }
  };

  const handleMemberChangeSelect = (value, e) => {
    const val = _.get(e, 'props');

    if (value !== memberSelect) {
      setMemberSearch();
      setMemberSelect(value);
      if (val) {
        setMemeberClaim(val);
      } else {
        setMemeberClaim();
      }
    }
  };

  const handleMemberChangeSearch = (value) => {
    setMemberLoading(true);
    setIsMemberSearchLoadMore(true);
    setMemberList([]);
    setMemberSearchPage(1);
    setMemberSearch(value);
  };

  const options =
    memberList.map &&
    memberList.map((item) => (
      <Option key={`key-${item.memComId}`} value={item.memComId}>
        {item.fullname}
      </Option>
    ));
  ///////////////////////////////////////////////////////////////////////////////////////////////

  useLayoutEffect(() => {
    const getAssetInsuranceByClaimDateApi = async () => {
      try {
        const resp = await getAssetInsuranceByClaimDate(assetId, dateByForm);
        setInsuranceDataClaim(_.get(resp.data, 'assetInsurance'));
      } catch (error) { }
    };

    if (assetId && visible) {
      getAssetInsuranceByClaimDateApi();
    }
  }, [dateByForm, visible]);

  useEffect(() => {
    if (_.get(record, 'data')) {
      const loopDoc = _.map(_.get(record.data, 'assetClaimDoc'), (item, index) => {
        return {
          no: `${index + 1}`,
          name: item.fileName,
          size: item.fileSizeMb,
          type: item.fileType,
          remark: item.remark,
          url: item.filePath,
          fileHash: item.fileHash,
        };
      });
      setAttData(loopDoc);
    }
  }, [_.get(record, 'data'), trigger]);

  useEffect(() => {
    if (parseFloat(claimableAmount) > parseFloat(damageValue)) {
      setFieldsValue({ ['claimableAmount']: damageValue });
    }
  }, [claimableAmount]);

  useEffect(() => {
    if (
      parseFloat(record ? _.get(record.data.assetClaimInfo, 'damageVal') : 0) === parseFloat(damageValue) &&
      parseFloat(record ? _.get(record.data.assetClaimInfo, 'claimableTotal') : 0) === parseFloat(claimableAmount)
    ) {
      return;
    } else {
      if (parseFloat(damageValue)) {
        setFieldsValue({ ['claimableAmount']: '' });
      }
    }
  }, [damageValue]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) return;

      if (values) {
        console.log('values', values);
        let newAttData = attData && attData.map((item) => item.fileHash);
        try {
          const payload = {
            assetInsuranceId: values.insuranceCompany,
            claimDate: moment(values.insuranceDate).format('YYYY-MM-DD hh:mm:ss'),
            claimBy: _.get(memberClaim, 'children'),
            claimByMemComId: _.get(memberClaim, 'value'),
            serialNo: values.claimRef || '',
            damageVal: parseFloat(values.damageValue) || 0,
            claimableTotal: parseFloat(values.claimableAmount) || 0,
            listFileHash: newAttData,
          };
          const response = await updateAssetClaimHistory(_.get(record.data.assetClaimInfo, 'claimId'), payload);
          if (_.get(response.data.status, 'code') === 1) {
            successNotification(_.get(response.data.status, 'message'));
            setMemberList([]);
            setMemberListMaster([]);
          }
        } catch (error) {
          console.log(error);
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      }
      resetFiledsAll();
    });
  };

  const onCancel = () => {
    if (_.get(record, 'stamp') === 'view') {
      setRecordClaim({ ...record, type: 'view' });
    } else {
      resetFiledsAll();
    }
  };

  const resetFiledsAll = () => {
    setMemberPage(1);
    setMemberLoading(false);
    setMemberSelect();
    setMemberList([]);
    setMemberListMaster([]);
    setMemberSearchPage(1);
    setMemberSearch();
    setIsMemberLoadMore(true);
    setMemeberClaim();
    setIsMemberSearchLoadMore(true);
    setAttData([]);
    setDefaultAtt();
    setTriggerApi((current) => !current);
    setVisible(false);
    resetFields();
  };

  const onCancelOut = () => {
    setVisible(false);
    setRecordClaim();
    setAttData([]);
    resetFields();
  };

  const handleDelete = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteAssetClaimHistory(id);
        if (response.status.code === 1) {
          successNotification(response.data.status.message);
          setTriggerApi((current) => !current);
          setVisible(false);
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  const handleViewToEdit = () => {
    setRecordClaim({ ...record, type: 'edit' });
  };

  const handleDeleteIMG = async (hash) => {
    await deleteClaimDocument(hash);
    const filter = _.filter(attData, (item) => {
      return item.fileHash !== hash;
    });
    setAttData(filter);
  };

  const handlePreview = async (file) => {
    console.log('filehandlePreview', file);
    let isPDF = _.get(file, 'type') === 'application/pdf' ? true : false;
    if (isPDF) return window.open(_.get(file, 'url'), '_blank');
    const mapdata = {
      photo: _.get(file, 'url'),
      number: 1,
      caption: _.get(file, 'remark'),
      thumbnail: _.get(file, 'url'),
    };
    setModalView(mapdata);
    setStateModal(!stateModal);
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={() => setStateModal(false)} />
      </div>
    );
  };

  const optionInsuranceDataClaim = (item) => (
    <Option key={item.assetInsuranceId} value={item.assetInsuranceId}>
      {item.insuranceName}
    </Option>
  );

  const optionClaimRef = (item, index) => (
    <Option key={`${item}-${index}`} value={`${item}`}>
      {item}
    </Option>
  );

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => onCancelOut()}
        centered={true}
        title={<FormattedMessage id="ModalTitleClaimDetails" defaultMessage="Claim Details" />}
        width={760}
        bodyStyle={{ padding: '24px' }}
        footer={
          _.get(record, 'type') === 'edit'
            ? [
              <Button_02 key="back" btnsize="wd_df" onClick={onCancel} style={{ margin: 'unset' }}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button_02>,
              <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button_01>,
            ]
            : [
              <Button_02
                key="back"
                btnsize="wd_df"
                onClick={() => handleDelete(_.get(record.data.assetClaimInfo, 'claimId'))}
                style={{ margin: 'unset' }}
              >
                <FormattedMessage id="btnDelete" defaultMessage="Delete" />
              </Button_02>,
              <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleViewToEdit}>
                <FormattedMessage id="btnEdit" defaultMessage="Edit" />
              </Button_01>,
            ]
        }
      >
        {_.get(record, 'type') === 'edit' ? (
          <Form>
            <Row gutter={16}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AssetModalTitleFormInsuranceType" defaultMessage="Insurance Date" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('insuranceDate', {
                      initialValue: _.get(record.data.assetClaimInfo, 'claimDate')
                        ? moment(_.get(record.data.assetClaimInfo, 'claimDate'), 'YYYY-MM-DD')
                        : '',
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AssetModalTitleFormInsuranceDateVL',
                            defaultMessage: 'Please Select Insurance Date',
                          }),
                        },
                      ],
                    })(
                      <DatePicker
                        allowClear={false}
                        format={dateFormat}
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({ id: 'AssetModalTitleFormInsuranceDatePH', defaultMessage: 'Insurance  Date' })}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AssetModalTitleFormInsuranceCompany" defaultMessage="Insurance Company" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('insuranceCompany', {
                      initialValue: _.get(record.data.assetClaimInfo, 'assetInsurance.assetInsuranceId'),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AssetModalTitleFormInsuranceCompanyVL',
                            defaultMessage: 'Please Select Insurance Company',
                          }),
                        },
                      ],
                    })(
                      <Select
                        placeholder={intl.formatMessage({
                          id: 'modalAssetAssetClassInsuranceCompanyPlaceholder',
                          defaultMessage: 'Select Insurance Company',
                        })}
                        style={{ width: '100%' }}
                        defaultActiveFirstOption={false}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        allowClear={false}
                      >
                        {insuranceDataClaim && insuranceDataClaim.map((item) => optionInsuranceDataClaim(item))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AssetModalTitleFormClaimBy" defaultMessage="Claim By" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('claimBy', {
                      initialValue: parseFloat(_.get(record.data.assetClaimInfo, 'claimById')),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AssetModalTitleFormClaimByVL',
                            defaultMessage: 'Please Enter Claim By',
                          }),
                        },
                      ],
                    })(
                      <Select
                        loading={memberLoading}
                        allowClear={false}
                        showSearch
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({
                          id: 'AssetModalTitleFormClaimByPH',
                          defaultMessage: 'Enter Claim By',
                        })}
                        optionFilterProp="children"
                        onPopupScroll={handleMemberPopupScroll}
                        onSearch={handleMemberChangeSearch}
                        onChange={handleMemberChange}
                        onSelect={handleMemberChangeSelect}
                      >
                        {options}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AssetModalTitleFormAssetClaimReference" defaultMessage="Asset Claim Reference" />
                    </span>
                    {getFieldDecorator('claimRef', {
                      initialValue: _.get(record.data.assetClaimInfo, 'claimRef'),
                      rules: [
                        {
                          required: false,
                          message: intl.formatMessage({
                            id: 'AssetModalTitleFormAssetClaimReferenceVL',
                            defaultMessage: 'Please Select Asset Claim Reference',
                          }),
                        },
                      ],
                    })(
                      <Select
                        placeholder={intl.formatMessage({
                          id: 'modalAssetAssetClassAssetClaimReferencePlaceholder',
                          defaultMessage: 'Select Asset Claim Reference',
                        })}
                        style={{ width: '100%' }}
                        defaultActiveFirstOption={false}
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {claimRef && claimRef.map((item) => optionClaimRef(item))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AssetModalTitleFormDamageValue" defaultMessage="Damage Value (Baht)" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('damageValue', {
                      initialValue: _.get(record.data.assetClaimInfo, 'damageVal'),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AssetModalTitleFormDamageValueVL',
                            defaultMessage: 'Please Enter Damage Value',
                          }),
                        },
                      ],
                    })(
                      <Input
                        placeholder={intl.formatMessage({ id: 'AssetModalTitleFormDamageValuePH', defaultMessage: 'Enter Damage Value' })}
                        autoComplete="off"
                        onKeyDown={handleKeyDownDecimal2Fixed}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AssetModalTitleFormClaimableAmount" defaultMessage="Claimable Amount (Baht)" />
                    </span>
                    {getFieldDecorator('claimableAmount', {
                      initialValue: _.get(record.data.assetClaimInfo, 'claimableTotal'),
                      rules: [
                        {
                          required: false,
                          message: intl.formatMessage({
                            id: 'AssetModalTitleFormClaimableAmountVL',
                            defaultMessage: 'Please Enter Claimable Amount',
                          }),
                        },
                      ],
                    })(
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'AssetModalTitleFormClaimableAmountPH',
                          defaultMessage: 'Enter Claimable Amount',
                        })}
                        autoComplete="off"
                        onKeyDown={handleKeyDownDecimal2Fixed}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Form>
        ) : (
          <ClaimHistoryView record={record} />
        )}
        <UploadTable
          checkedType={_.get(record, 'type')}
          setVisible={setOpenUpload}
          attData={attData}
          setTypeAtt={setTypeAtt}
          setDefaultAtt={setDefaultAtt}
          handleDeleteIMG={handleDeleteIMG}
          handlePreview={handlePreview}
        />
      </Modal>
      <UploadFileModal
        visible={openUpload}
        setVisible={setOpenUpload}
        setAttData={setAttData}
        attData={attData}
        typeAtt={typeAtt}
        defaultAtt={defaultAtt}
      />

      <HandlerModal />
    </div>
  );
};

const ClaimHistoryViewEdit = Form.create({
  name: 'modal_form',
  mapPropsToFields() { },
})(ViewEditClaimForm);

export default ClaimHistoryViewEdit;
