import React, { createContext, useEffect, useState } from 'react';
import {
  GetSignaturePrint,
  GetSignaturePrintIssu,
  createQuotation,
  deleteAttachmentQuotation,
  exportExcel,
  getAttachment,
  getIntialListQuotation,
  getMemberIssueBy,
  getQuotationById,
  getQuotationList,
  getQuotationReportById,
  getQuotationStatus,
  getSummaryApproved,
  updateQuotation,
  updateStatusQuotation,
  uploadAttachment,
} from '../../controllers/quotation/quotation-api-new';
import _ from 'lodash';
import moment from 'moment';
import { getMemberAsset } from '../../controllers/asset-resource/asset-controller';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { useIntl } from 'react-intl';
import getCustomerVenderApi from '../../controllers/getCustomer/get-customer-vender'

const QuotationContext = createContext();

const QuotationProvider = ({ children }) => {
  const intl = useIntl();
  const [isOpenQuotation, seIsOpenQuotation] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [record, setRecord] = useState();
  const [recordReport, setRecordReport] = useState();
  const [signatureUser, setSignatureUser] = useState();
  const [signatureIssu, setSignatureIssu] = useState();
  const [sizeRecordReport, setSizeRecordReport] = useState();

  const [openUpload, setOpenUpload] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();

  const [tabKey, setTabKey] = useState('waiting');
  const [initialMemberList, setInitialMemberList] = useState([]);
  const [initialTaxList, setInitialTaxList] = useState([]);
  const [initialReasonList, setInitialReasonList] = useState([]);
  const [initialCampaignList, setInitialCampaignList] = useState([]);

  const [statusList, setStatusList] = useState([]);

  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleWaiting, setVisibleWaiting] = useState(false);
  const [reportDataWaiting, setReportDataWaiting] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [moment().subtract(2, 'months').startOf('month'), moment().endOf('month')],
  });

  const [reportDataApproved, setReportDataApproved] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [moment().subtract(2, 'months').startOf('month'), moment().endOf('month')],
    summary: {},
  });

  const [reportDataRejected, setReportDataRejected] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [moment().subtract(2, 'months').startOf('month'), moment().endOf('month')],
  });

  const [reportDataCanceled, setReportDataCanceled] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [moment().subtract(2, 'months').startOf('month'), moment().endOf('month')],
  });
  const [vendorData, setVendorData] = useState([])

  useEffect(() => {
    setReportDataWaiting((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tab: 'waiting',
        startDate: _.get(reportDataWaiting, 'rangePickerDate[0]')
          ? moment(_.get(reportDataWaiting, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : '',
        endDate: _.get(reportDataWaiting, 'rangePickerDate[1]')
          ? moment(_.get(reportDataWaiting, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : '',
        paging: _.get(reportDataWaiting, 'paginationPage'),
        rowsPerPages: _.get(reportDataWaiting, 'paginationSize'),
        orderBy: _.get(reportDataWaiting, 'fieldSort'),
        orderType: _.get(reportDataWaiting, 'orderSort'),
        filter: {
          quotationNo: _.get(reportDataWaiting, 'quotationNo'),
          saleOrderNo: _.get(reportDataWaiting, 'saleOrderNo'),
          customerName: _.get(reportDataWaiting, 'customerName'),
          statusCode: _.get(reportDataWaiting, 'status'),
        },
      };
      const response = await getQuotationList(payload);
      if (_.get(response, 'status.code') === 200) {
        setReportDataWaiting((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.data'),
          quotationTotal: _.get(response, 'data.total'),
        }));
      }
    };
    setReportDataWaiting((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'waiting') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataWaiting, 'rangePickerDate[0]'),
    _.get(reportDataWaiting, 'rangePickerDate[1]'),
    _.get(reportDataWaiting, 'paginationPage'),
    _.get(reportDataWaiting, 'paginationSize'),
    _.get(reportDataWaiting, 'fieldSort'),
    _.get(reportDataWaiting, 'orderSort'),
    _.get(reportDataWaiting, 'quotationNo'),
    _.get(reportDataWaiting, 'saleOrderNo'),
    _.get(reportDataWaiting, 'customerName'),
    _.get(reportDataWaiting, 'status'),
  ]);

  useEffect(() => {
    setReportDataApproved((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tab: 'approved',
        startDate: _.get(reportDataApproved, 'rangePickerDate[0]')
          ? moment(_.get(reportDataApproved, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : '',
        endDate: _.get(reportDataApproved, 'rangePickerDate[1]')
          ? moment(_.get(reportDataApproved, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : '',
        paging: _.get(reportDataApproved, 'paginationPage'),
        rowsPerPages: _.get(reportDataApproved, 'paginationSize'),
        orderBy: _.get(reportDataApproved, 'fieldSort'),
        orderType: _.get(reportDataApproved, 'orderSort'),
        filter: {
          quotationNo: _.get(reportDataApproved, 'quotationNo'),
          saleOrderNo: _.get(reportDataApproved, 'saleOrderNo'),
          customerName: _.get(reportDataApproved, 'customerName'),
        },
      };
      const response = await getQuotationList(payload);
      if (_.get(response, 'status.code') === 200) {
        getApiSummary(payload);
        setReportDataApproved((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.data'),
          quotationTotal: _.get(response, 'data.total'),
        }));
      }
    };
    setReportDataApproved((prev) => ({ ...prev, loading: false }));

    const getApiSummary = async (payload) => {
      const responseSummary = await getSummaryApproved(payload);
      setReportDataApproved((prev) => ({
        ...prev,
        summary: _.get(responseSummary, 'data'),
      }));
    };

    if (tabKey === 'approved') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataApproved, 'rangePickerDate[0]'),
    _.get(reportDataApproved, 'rangePickerDate[1]'),
    _.get(reportDataApproved, 'paginationPage'),
    _.get(reportDataApproved, 'paginationSize'),
    _.get(reportDataApproved, 'fieldSort'),
    _.get(reportDataApproved, 'orderSort'),
    _.get(reportDataApproved, 'quotationNo'),
    _.get(reportDataApproved, 'saleOrderNo'),
    _.get(reportDataApproved, 'customerName'),
  ]);

  useEffect(() => {
    setReportDataRejected((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tab: 'rejected',
        startDate: _.get(reportDataRejected, 'rangePickerDate[0]')
          ? moment(_.get(reportDataRejected, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : '',
        endDate: _.get(reportDataRejected, 'rangePickerDate[1]')
          ? moment(_.get(reportDataRejected, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : '',
        paging: _.get(reportDataRejected, 'paginationPage'),
        rowsPerPages: _.get(reportDataRejected, 'paginationSize'),
        orderBy: _.get(reportDataRejected, 'fieldSort'),
        orderType: _.get(reportDataRejected, 'orderSort'),
        filter: {
          quotationNo: _.get(reportDataRejected, 'quotationNo'),
          saleOrderNo: _.get(reportDataRejected, 'saleOrderNo'),
          customerName: _.get(reportDataRejected, 'customerName'),
        },
      };
      const response = await getQuotationList(payload);
      if (_.get(response, 'status.code') === 200) {
        setReportDataRejected((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.data'),
          quotationTotal: _.get(response, 'data.total'),
        }));
      }
    };
    setReportDataRejected((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'rejected') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataRejected, 'rangePickerDate[0]'),
    _.get(reportDataRejected, 'rangePickerDate[1]'),
    _.get(reportDataRejected, 'paginationPage'),
    _.get(reportDataRejected, 'paginationSize'),
    _.get(reportDataRejected, 'fieldSort'),
    _.get(reportDataRejected, 'orderSort'),
    _.get(reportDataRejected, 'quotationNo'),
    _.get(reportDataRejected, 'saleOrderNo'),
    _.get(reportDataRejected, 'customerName'),
  ]);

  useEffect(() => {
    setReportDataCanceled((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tab: 'canceled',
        startDate: _.get(reportDataCanceled, 'rangePickerDate[0]')
          ? moment(_.get(reportDataCanceled, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : '',
        endDate: _.get(reportDataCanceled, 'rangePickerDate[1]')
          ? moment(_.get(reportDataCanceled, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : '',
        paging: _.get(reportDataCanceled, 'paginationPage'),
        rowsPerPages: _.get(reportDataCanceled, 'paginationSize'),
        orderBy: _.get(reportDataCanceled, 'fieldSort'),
        orderType: _.get(reportDataCanceled, 'orderSort'),
        filter: {
          quotationNo: _.get(reportDataCanceled, 'quotationNo'),
          saleOrderNo: _.get(reportDataCanceled, 'saleOrderNo'),
          customerName: _.get(reportDataCanceled, 'customerName'),
        },
      };
      const response = await getQuotationList(payload);
      if (_.get(response, 'status.code') === 200) {
        setReportDataCanceled((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.data'),
          quotationTotal: _.get(response, 'data.total'),
        }));
      }
    };
    setReportDataCanceled((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'canceled') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataCanceled, 'rangePickerDate[0]'),
    _.get(reportDataCanceled, 'rangePickerDate[1]'),
    _.get(reportDataCanceled, 'paginationPage'),
    _.get(reportDataCanceled, 'paginationSize'),
    _.get(reportDataCanceled, 'fieldSort'),
    _.get(reportDataCanceled, 'orderSort'),
    _.get(reportDataCanceled, 'quotationNo'),
    _.get(reportDataCanceled, 'saleOrderNo'),
    _.get(reportDataCanceled, 'customerName'),
  ]);

  useEffect(() => {
    const getApi = async () => {
      let payload = {};
      const result = await getMemberIssueBy(payload);
      const response = await getCustomerVenderApi(10000);
      setInitialMemberList(result || []);
      setVendorData(_.get(response, 'customers'));
    };
    getApi();
  }, []);

  useEffect(() => {
    const getApi = async () => {
      let payload = {};
      const result = await getMemberIssueBy(payload);
      const response = await getQuotationStatus();
      setInitialMemberList(result || []);
      setStatusList(response || []);
    };
    getApi();
  }, []);

  useEffect(() => {
    const getApi = async () => {
      const resultTax = await getIntialListQuotation('tax');
      const resultReason = await getIntialListQuotation('reason');
      const resultCampaign = await getIntialListQuotation('campaign');
      setInitialTaxList(resultTax || []);
      setInitialReasonList(resultReason || []);
      setInitialCampaignList(resultCampaign || []);
    };
    getApi();
  }, []);

  console.log('initialReasonList', initialReasonList);
  const handleOpenModalQuotation = async (row, code) => {
    if (row) {
      try {
        const response = await getQuotationById(row.quotationId);
        const responseAttachment = await getAttachment(row.quotationId);
        const responsereport = await getQuotationReportById(row.quotationId);
        const responseSignature = await GetSignaturePrint();
        const responseSignatureIssu = await GetSignaturePrintIssu(_.get(response, 'data.data[0].issueBy'));
        console.log('getQuotationById', code, responseAttachment);
        if (_.get(response, 'status.code')) {
          setRecord({ ..._.get(response, 'data.data[0]'), code: code, attachment: _.get(responseAttachment, 'data') });
          handleCountSize(_.get(responsereport, 'data.data'));
          setSignatureUser(_.get(responseSignature, 'data'));
          setSignatureIssu(_.get(responseSignatureIssu, 'data'));
          seIsOpenQuotation(true);
        }
      } catch (error) { }
    } else {
      seIsOpenQuotation(true);
      setRecord();
    }
  };

  const refreshModalQuotation = async (quotationId, code) => {
    try {
      const response = await getQuotationById(quotationId);
      const responseAttachment = await getAttachment(quotationId);
      const responsereport = await getQuotationReportById(quotationId);
      const responseSignature = await GetSignaturePrint();
      const responseSignatureIssu = await GetSignaturePrintIssu(_.get(response, 'data.data[0].issueBy'));
      console.log('getQuotationById', code, responseAttachment);
      if (_.get(response, 'status.code')) {
        setRecord({ ..._.get(response, 'data.data[0]'), code: code, attachment: _.get(responseAttachment, 'data') });
        handleCountSize(_.get(responsereport, 'data.data'));
        setSignatureUser(_.get(responseSignature, 'data'));
        setSignatureIssu(_.get(responseSignatureIssu, 'data'));
      }
    } catch (error) { }
  };

  const handleCountSize = (data) => {
    let count = 0;
    let reportmap = [];

    console.log('recordReportData', data);

    _.map(data, (item) => {
      let total = _.sumBy(_.get(item, 'detail'), 'total');
      // reportmap.push({ credit: item.credit, role: 'head', total: total });
      _.map(_.get(item, 'detail'), (detail) => {
        count = count + 1;
        reportmap.push({ ...detail, index: count });
        if (detail.remark !== '') {
          reportmap.push({ remarkline: detail.remark, role: 'head', total: total });
        }

      });
    });

    setRecordReport(reportmap);
    console.log('reportmap', reportmap);
    setSizeRecordReport(_.size(reportmap));
  };

  const handleOpenModalUpload = (row) => {
    console.log('handleOpenModalUpload', row);
    if (row) {
      setOpenUpload(true);
      setDefaultAttachment(row);
      setTypeAttachment('edit');
    } else {
      setOpenUpload(true);
      setDefaultAttachment();
      setTypeAttachment('add');
    }
  };

  const handleSaveQuotation = async (payload, resetFields) => {
    console.log('handleSaveQuotation', payload, attachmentData);
    try {
      let response;

      if (payload.quotationId) {
        response = await updateQuotation(payload);
      } else {
        response = await createQuotation(payload);
      }
      console.log('updateQuotation', response)

      if (_.size(attachmentData) > 0) {
        for (let index = 0; index < attachmentData.length; index++) {
          const file = attachmentData[index].fileRaw;
          const fileHash = attachmentData[index].id;
          const formData = new FormData();
          formData.append('file', file);
          formData.append('fileHash', fileHash);
          formData.append('quotationId', payload.code === 'edit' ? payload.quotationId : response.data.quotationId);
          formData.append('remark', attachmentData[index].remark ? attachmentData[index].remark : '');
          await uploadAttachment(formData);
        }
      }

      console.log('createQuotation', response);
      if (_.get(response, 'status.code') === 200) {
        successNotification(_.get(response, 'status.message'));
        setIsFetch((prev) => !prev);
        seIsOpenQuotation(false);
        resetFields();
      } else {
        errorNotification(_.get(response, 'data.status.message'));
        return;
      }
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelQuotation = () => {
    seIsOpenQuotation(false);
    setRecord();
    setRecordReport();
  };

  const handleOpenApprove = (value) => {
    setVisibleApprove(true);
  };

  const handleSaveApprove = async (value, resetFields) => {
    try {
      const response = await updateStatusQuotation(value);
      console.log('updateStatusQuotation', response);

      if (_.get(response, 'data.status.code') === 400 || _.get(response, 'data.status.code') === 500) {
        errorNotification(_.get(response, 'data.status.message'));
        return;
      } else {
        const response2 = await getQuotationById(response.data.quotationId);
        console.log('getQuotationById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data.data[0]'), code: value.code });
          successNotification('Success');
          resetFields();
        }
      }

      // if (response) {
      //   const response2 = await getQuotationById(response.quotationId);
      //   console.log('getQuotationById', value.key, response2, response);
      //   if (_.get(response2, 'status.code')) {
      //     setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
      //     successNotification('Success');
      //   }
      // }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleApprove(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelApprove = () => {
    setVisibleApprove(false);
  };

  const handleOpenReject = (value) => {
    setVisibleReject(true);
  };

  const handleSaveReject = async (value, resetFields) => {
    try {
      const response = await updateStatusQuotation(value);
      console.log('updateStatusQuotation', response);

      if (_.get(response, 'data.status.code') === 400 || _.get(response, 'data.status.code') === 500) {
        errorNotification(_.get(response, 'data.status.message'));
        return;
      } else {
        const response2 = await getQuotationById(response.data.quotationId);
        console.log('getQuotationById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data.data[0]'), code: value.code });
          successNotification('Success');
          resetFields();
        }
      }
      // if (response) {
      //   const response2 = await getQuotationById(response.quotationId);
      //   console.log('getQuotationById', value.key, response2, response);
      //   if (_.get(response2, 'status.code')) {
      //     setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
      //     successNotification('Success');
      //   }
      // }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleReject(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelReject = (value) => {
    setVisibleReject(false);
  };

  const handleOpenCancel = (value) => {
    setVisibleCancel(true);
  };

  const handleSaveCancel = async (value) => {
    try {
      const response = await updateStatusQuotation(value);
      console.log('updateStatusQuotation', response);

      if (response) {
        const response2 = await getQuotationById(response.data.quotationId);
        console.log('getQuotationById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data.data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleCancel(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelCancelQT = (value) => {
    setVisibleCancel(false);
  };

  const handleOpenDelete = (value) => {
    setVisibleDelete(true);
  };

  const handleSaveDelete = async (value, resetFields) => {
    console.log('handleSaveDelete', value);

    try {
      const response = await updateStatusQuotation(value);
      console.log('updateStatusQuotation', response);

      if (_.get(response, 'data.status.code') === 400 || _.get(response, 'data.status.code') === 500) {
        errorNotification(_.get(response, 'data.status.message'));
      } else {
        if (_.get(value, 'status') === 'deleted') {
          setIsFetch((prev) => !prev);
          setVisibleDelete(false);
          seIsOpenQuotation(false);
          successNotification('Success');
          resetFields();
        } else {
          const response2 = await getQuotationById(response.data.quotationId);
          console.log('getQuotationById', value.key, response2, response);
          if (_.get(response2, 'status.code')) {
            setRecord({ ..._.get(response2, 'data.data[0]'), code: value.code });
            setIsFetch((prev) => !prev);
            setVisibleDelete(false);
            successNotification('Success');
            resetFields();
          }
        }
      }
      // if (response) {
      //   const response2 = await getQuotationById(response.quotationId);
      //   console.log('getQuotationById', value.key, response2, response);
      //   if (_.get(response2, 'status.code')) {
      //     setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
      //     successNotification('Success');
      //   }
      // }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
    } catch (error) {
      console.log('errorrrrrrrr', error, _.get(error, 'response'));
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelDelete = (value) => {
    setVisibleDelete(false);
  };

  const handleOpenWaiting = (value) => {
    setVisibleWaiting(true);
  };

  const handleSaveWaiting = async (value, resetFields) => {
    try {
      const response = await updateStatusQuotation(value);
      console.log('updateStatusQuotation', response);

      if (_.get(response, 'data.status.code') === 400 || _.get(response, 'data.status.code') === 500) {
        errorNotification(_.get(response, 'data.status.message'));
        return;
      } else {
        const response2 = await getQuotationById(response.data.quotationId);
        console.log('getQuotationById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data.data[0]'), code: value.code });
          successNotification('Success');
          resetFields();
        }
      }

      // if (response) {
      //   const response2 = await getQuotationById(response.quotationId);
      //   console.log('getQuotationById', value.key, response2, response);
      //   if (_.get(response2, 'status.code')) {
      //     setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
      //     successNotification('Success');
      //   }
      // }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleWaiting(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelWaitng = (value) => {
    setVisibleWaiting(false);
  };

  const handleDeleteAttAchment = async (id) => {
    try {
      let response;
      response = await deleteAttachmentQuotation(id);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleExport = async () => {
    try {

      const tabValue =
        tabKey === 'waiting'
          ? reportDataWaiting
          : tabKey === 'approved'
            ? reportDataApproved
            : tabKey === 'rejected'
              ? reportDataRejected
              : tabKey === 'canceled'
                ? reportDataCanceled
                : null

      console.log('handleExport', tabKey, tabValue)

      const payload = {
        tab: tabKey,
        startDate: _.get(tabValue, 'rangePickerDate[0]')
          ? moment(_.get(tabValue, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : '',
        endDate: _.get(tabValue, 'rangePickerDate[1]')
          ? moment(_.get(tabValue, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : '',
        paging: 1,
        rowsPerPages: _.get(tabValue, 'quotationTotal'),
        orderBy: _.get(tabValue, 'fieldSort'),
        orderType: _.get(tabValue, 'orderSort'),
        filter: {
          quotationNo: _.get(tabValue, 'quotationNo'),
          saleOrderNo: _.get(tabValue, 'saleOrderNo'),
          customerName: _.get(tabValue, 'customerName'),
          statusCode: _.get(tabValue, 'status'),
        },
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'quotationLabelQuotationNo', defaultMessage: 'Quotation No.' }),
            sequence: 1,
            colCode: 'quotationNo',
          },
          {
            colName: intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' }),
            sequence: 2,
            colCode: 'customerName',
          },
          {
            colName: intl.formatMessage({ id: 'quotationLabelItem', defaultMessage: 'Item' }),
            sequence: 3,
            colCode: 'totalItem',
          },
          {
            colName: intl.formatMessage({ id: 'quotationLabelTotal', defaultMessage: 'Total' }),
            sequence: 4,
            colCode: 'total',
          },
          {
            colName: intl.formatMessage({ id: 'quotationLabelStatus', defaultMessage: 'Status' }),
            sequence: 5,
            colCode: 'status.txt',
          },
          {
            colName: intl.formatMessage({ id: 'quotationLabelIssuedBy', defaultMessage: 'Issued by' }),
            sequence: 6,
            colCode: 'issuedName',
          },
          {
            colName: intl.formatMessage({ id: 'quotationLabelIssuedDate', defaultMessage: 'Issued Date' }),
            sequence: 7,
            colCode: 'issueDate',
          },
        ],
      };

      const response = await exportExcel(payload);

      if (response.data.getUrl.status.code === 200) {
        window.open(response.data.getUrl.data.url);
        // successNotification(response.data.getUrl.message);
      } else {
        // errorNotification(response.data.getUrl.message);
      }

      console.log('exportExcel', response)
    } catch (error) { }
  };

  return (
    <QuotationContext.Provider
      value={{
        state: {
          isOpenQuotation,
          isFetch,
          openUpload,
          attachmentData,
          typeAttachment,
          defaultAttachment,
          reportDataWaiting,
          reportDataApproved,
          reportDataRejected,
          reportDataCanceled,
          initialMemberList,
          initialTaxList,
          initialReasonList,
          initialCampaignList,
          record,
          visibleApprove,
          visibleReject,
          visibleCancel,
          visibleDelete,
          visibleWaiting,
          recordReport,
          sizeRecordReport,
          signatureUser,
          statusList,
          signatureIssu,
          vendorData,
          tabKey
        },
        setState: {
          seIsOpenQuotation,
          setIsFetch,
          setOpenUpload,
          setAttachmentData,
          setTypeAttachment,
          setDefaultAttachment,
          setTabKey,
          setReportDataWaiting,
          setReportDataApproved,
          setReportDataRejected,
          setReportDataCanceled,
          setRecord,
          setSizeRecordReport,
        },
        func: {
          handleOpenModalQuotation,
          handleSaveQuotation,
          handleOpenModalUpload,
          handleCancelQuotation,
          handleOpenModalUpload,
          handleOpenApprove,
          handleSaveApprove,
          handleCancelApprove,
          handleOpenReject,
          handleSaveReject,
          handleCancelReject,
          handleOpenCancel,
          handleSaveCancel,
          handleCancelCancelQT,
          handleOpenDelete,
          handleSaveDelete,
          handleCancelDelete,
          handleOpenWaiting,
          handleSaveWaiting,
          handleCancelWaitng,
          handleDeleteAttAchment,
          refreshModalQuotation,
          handleExport,
        },
      }}
    >
      {children}
    </QuotationContext.Provider>
  );
};

export { QuotationContext, QuotationProvider };
