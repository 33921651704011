import React, { useState, useRef } from 'react'
import { Table, Button, Popover } from 'antd'
import { FormattedMessage } from 'react-intl'
import { PageSettings } from '../../config/page-settings'
import _ from 'lodash'

const UomTableConvert = (props) => {
  const { columns, dataSource, handleDelete, total, page, handleChange } = props
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>

              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record)}>
                      <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                    </Button>
                  </>

                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: true }}
        onChange={handleChange}
        pagination={{
          total: total,
          showTotal: showTotal,
          defaultCurrent: 1,
          current: page,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href && _.get(record,'active') === true) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />
      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      {_.size(columns) > 0 ? (
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default UomTableConvert
