import React, { useState, useEffect, useContext } from 'react'
import './index.css'
import { Card, Row, Col, Tabs, Dropdown, Icon, Menu, Modal } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../components/v2/button_01';
import BranchList from './component/branch';
import DeliveryDetail from './component/detail';
import DeliveryShipment from './component/shipment';
import DeliveryShipmentDetail from './component/shipment-detail';
import { StickyContainer, Sticky } from 'react-sticky';
import { DownOutlined } from '@ant-design/icons';
import NoDataBackground from '../../components/no-data-page';
import { getBranchList, deleteBranch, getBranchManager, getBranchManagerList, getBranchMemberList } from '../../controllers/delivery-order/branch';
import { getRole } from '../../controllers/role';
import { fncGetUsers } from '../../controllers/user/users';
import _ from 'lodash'
import { useDebounce } from '../../controllers/debounce';
import BranchModalForm from './component/branch/modal/modal';
import { successNotification, errorNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import { getStatus, getOrdersList, getOrdersListExport } from '../../controllers/orders-controller/api';
import { getSizeList } from '../../controllers/delivery-order/size';
import getCustomerApi from '../../controllers/getCustomer/get-customer-api'
import moment from 'moment';
import { useAppContext } from '../../includes/indexProvider';

const { TabPane } = Tabs
export const DeliveryContext = React.createContext();

const DeliveryOrder = () => {
  const intl = useIntl()
  const { checkPermissionAction } = useContext(PageSettings)
  const app = useAppContext();
  const [toggle, setToggle] = useState(false);
  const [visibleBranch, setVisibleBranch] = useState(false)
  const [searchBranch, setSearchBranch] = useState()
  const [sizeBranch, setSizeBranch] = useState(20)
  const debouceSearchBranch = useDebounce(searchBranch, 500)
  const [selectBranch, setSelectBranch] = useState()
  const [selectBranchData, setSelectBranchData] = useState()
  const [selectShipmentData, setSelectShipmentData] = useState()
  const [tabDelivery, setTabDelivery] = useState('detail')
  const [branchList, setBranchList] = useState([])
  const [branchDataFormResponse, setBranchDataFormResponse] = useState()
  const [recordbranch, setRecordbranch] = useState()
  const [trigger, setTrigger] = useState(false)
  const [managerList, setManagerList] = useState([])
  const [memberList, setMemberList] = useState([])
  const [memberTotal, setMemberTotal] = useState(0)
  const [memberLoading, setMemberLoading] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [orderTotal, setOrderTotal] = useState(0)
  const [orderData, setOrderData] = useState()
  const [roleList, setRoleList] = useState([])
  const [userList, setUserList] = useState([])
  const [manageList, setManageList] = useState([])
  const [memberAll, setMemberAll] = useState([])
  const [triggerManager, setTriggerManager] = useState(false)
  const [triggerMember, setTriggerMember] = useState(false)
  const [triggerOrder, setTriggerOrder] = useState(false)
  const [memberField, setMemberField] = useState('email')
  const [memberOrder, setMemberOrder] = useState('asc')
  const [memberPage, setMemberPage] = useState(1)
  const [memberSize, setMemberSize] = useState(10)
  const [memberInput, setMemberInput] = useState('')
  const [memberColumns, setMemberColumns] = useState('all')
  const debouceSearchMember = useDebounce(memberInput, 500)

  const [shipmentField, setShipmentField] = useState()
  const [shipmentOrder, setShipmentOrder] = useState()
  const [shipmentPage, setShipmentPage] = useState(1)
  const [shipmentSize, setShipmentSize] = useState(10)
  const [shipmentInput, setShipmentInput] = useState('')
  const debouceSearchShipment = useDebounce(shipmentInput, 500)

  const [visibleCreateOrder, setVisibleCreateOrder] = useState(false)
  const [statusData, setStatusData] = useState()
  const [sizeList, setSizeList] = useState([])

  const [fileHash, setFileHash] = useState()
  const [openImport, setOpenImport] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false)
  const [customerData, setCustomerData] = useState([])
  const [searchOrder, setSearchOrder] = useState({});
  const debouceSearchOrder = useDebounce(searchOrder, 500)

  useEffect(() => {
    const initialData = async () => {
      const comId = localStorage.getItem('comId');
      const memComId = localStorage.getItem('memComId');
      const managerData = await getBranchManager()
      const roleData = await getRole()
      const userData = await fncGetUsers(memComId, comId, true);
      const statusList = await getStatus();
      const sizeData = await getSizeList({
        where: {},
        page: 1,
        limit: 100000,
      })
      const dataCustomer = await getCustomerApi(10000);
      setCustomerData(_.get(dataCustomer, 'customers'));
      const filter = _.get(statusList, 'data.statusList').filter((item) => {
        return item.statusCode === 'open';
      });
      setStatusData(_.get(filter, '[0]'));
      setManagerList(_.get(managerData, 'data.data.branchManagerCode'))
      setRoleList(_.get(roleData, 'data.data'))
      setUserList(userData);
      setSizeList(_.get(sizeData, 'data.data.list'))
    }
    initialData()
  }, [])

  useEffect(() => {
    const getDeliveryBranch = async () => {
      const body = {
        where: {
          $or: [
            {
              branchCode: {
                $regex: `.*${searchBranch || ""}.*`
              }
            },
            {
              branchName: {
                $regex: `.*${searchBranch || ""}.*`
              }
            }
          ],
        },
        page: 1,
        pageSize: sizeBranch,
        sortBy: 'branchName',
        sort: 'desc'
      }
      const response = await getBranchList(body)
      setBranchList(_.get(response, 'data.data.list'))
    }
    getDeliveryBranch()
  }, [debouceSearchBranch, sizeBranch, trigger])

  useEffect(() => {
    const getManagerList = async () => {
      const body = {
        where: {
          branchId: selectBranch
        },
        page: 1,
        pageSize: 100,
        sortBy: 'createdAt',
        sort: 'desc'
      }
      const response = await getBranchManagerList(body)
      setManageList(_.get(response, 'data.data.list'))
    }
    if (selectBranch) {
      getManagerList()
    }
  }, [selectBranch, triggerManager])

  useEffect(() => {
    const getMemberList = async () => {
      setMemberLoading(true)
      const body = {
        where: {
          [memberColumns === '' ? 'all' : memberColumns]: memberInput
        },
        page: memberPage,
        pageSize: memberSize,
        sortBy: memberField,
        sort: memberOrder,
      }
      const response = await getBranchMemberList(selectBranch, body)
      setMemberList(_.get(response, 'data.data.list'))
      setMemberTotal(_.get(response, 'data.data.total'))
      setMemberAll(_.get(response, 'data.data.memComIds'))
      setMemberLoading(false)
    }
    if (selectBranch) {
      getMemberList()
    }
  }, [selectBranch, triggerMember, memberColumns, debouceSearchMember, memberPage, memberSize, memberField, memberOrder])

  useEffect(() => {
    const getOrderList = async () => {
      setLoadingOrder(true)
      const body = {
        search: {
          "branchDetail.branchId": selectBranch,
          "orderNo": _.get(searchOrder, 'doNo'),
          "orderType": _.get(searchOrder, 'orderType'),
          "detail.taskNo": _.get(searchOrder, 'taskNo'),
          "customer.comtomerName": _.get(searchOrder, 'customer'),
          "startDate": _.get(searchOrder, 'deliveryDate') && _.size(searchOrder.deliveryDate) ? moment(_.get(searchOrder, 'deliveryDate.[0]')).format('YYYY-MM-DD') : undefined,
          "endDate": _.get(searchOrder, 'deliveryDate') && _.size(searchOrder.deliveryDate) ? moment(_.get(searchOrder, 'deliveryDate.[1]')).format('YYYY-MM-DD') : undefined,
          "paymentStatus": _.get(searchOrder, 'payment'),
        },
        filterStatus: _.get(searchOrder, 'status') || [],
        pageNumber: shipmentPage,
        limit: shipmentSize,
        orderBy: shipmentField,
        orderType: shipmentOrder,
        "detailTypeCode": 'deliveryOrderParcel',
        "saleOrderNo": _.get(searchOrder, 'saleOrderSelect') ? [_.get(searchOrder, 'saleOrderSelect')] : undefined,
      }
      const response = await getOrdersList(body)
      setOrderList(_.get(response.data, 'list') || [])
      setOrderTotal(_.get(response.data, 'allRows') || [])
      setOrderData(response)
      setLoadingOrder(false)
    }
    if (selectBranch) {
      getOrderList()
    }
  }, [selectBranch, triggerOrder, debouceSearchShipment, shipmentPage, shipmentSize, shipmentField, shipmentOrder, debouceSearchOrder])


  const onScrollBranch = (e) => {
    const target = e.target;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      setSizeBranch(prevValue => prevValue + 20);
    }
  }

  useEffect(() => {
    if (branchDataFormResponse) {
      let newArr = [];
      const filterBranch = _.filter(branchList, (item) => {
        return item.id === _.get(branchDataFormResponse, 'id')
      })
      const mergedObject = Object.assign({}, _.get(filterBranch, '[0]'), branchDataFormResponse);
      newArr.push(mergedObject, ...branchList);
      setBranchList(_.uniqBy(newArr, 'id'));
    }
  }, [branchDataFormResponse]);

  console.log("branchList", branchList)


  const handleModalBranch = () => {
    setVisibleBranch(true)
  }

  const handleSearchBranch = (e) => {
    setSearchBranch(e.target.value)
  }

  const handleSelectBranch = (value, data) => {
    setSelectBranch(value)
    setSelectBranchData(data)
    setShipmentPage(1)
    setShipmentSize(10)
    setSearchOrder({})
  }

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleTabChange = (value) => {
    setTabDelivery(value)
  }

  const handleEditBranch = (data) => {
    setRecordbranch(data)
    setVisibleBranch(true)
  }

  const handleClickShipment = (record) => {
    setSelectShipmentData(record)
  }

  const handleExportOrder = async () => {
    const payload = {
      search: {
        "branchDetail.branchId": selectBranch,
        "orderNo": _.get(searchOrder, 'doNo'),
        "orderType": _.get(searchOrder, 'orderType'),
        "detail.taskNo": _.get(searchOrder, 'taskNo'),
        "customer.comtomerName": _.get(searchOrder, 'customer'),
        "startDate": _.get(searchOrder, 'deliveryDate') && _.size(searchOrder.deliveryDate) ? moment(_.get(searchOrder, 'deliveryDate.[0]')).format('YYYY-MM-DD') : undefined,
        "endDate": _.get(searchOrder, 'deliveryDate') && _.size(searchOrder.deliveryDate) ? moment(_.get(searchOrder, 'deliveryDate.[1]')).format('YYYY-MM-DD') : undefined,
        "paymentStatus": _.get(searchOrder, 'payment'),
      },
      pageNumber: 1,
      limit: orderTotal,
      orderBy: shipmentField,
      orderType: shipmentOrder,
      detailTypeCode: 'deliveryOrderParcel',
      saleOrderNo: _.get(searchOrder, 'saleOrderSelect') ? [_.get(searchOrder, 'saleOrderSelect')] : undefined,
      viewFormat: [
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsDO', defaultMessage: 'DO No.' }),
          sequence: 1,
          colCode: 'orderNo',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsOrderType', defaultMessage: 'Order Type' }),
          sequence: 2,
          colCode: 'orderType',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsTaskNo', defaultMessage: 'Task No.' }),
          sequence: 3,
          colCode: 'taskNo',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsCustomer', defaultMessage: 'Customer' }),
          sequence: 4,
          colCode: 'customer.comtomerName',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsContact', defaultMessage: 'Contact' }),
          sequence: 5,
          colCode: 'contact.customerContactName',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsPhone', defaultMessage: 'Phone' }),
          sequence: 6,
          colCode: 'contact.customerContactPhone',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsParcels', defaultMessage: 'Parcels' }),
          sequence: 7,
          colCode: 'totalParcel',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsPrice', defaultMessage: 'Price (THB)' }),
          sequence: 8,
          colCode: 'total',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsPayment', defaultMessage: 'Payment' }),
          sequence: 9,
          colCode: 'paymentStatusText',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsOrderStatus', defaultMessage: 'Order Status' }),
          sequence: 10,
          colCode: 'statusText',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsDeliveryDate', defaultMessage: 'Delivery Date' }),
          sequence: 11,
          colCode: 'deliveryDateNewTxt',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsDeliveryTime', defaultMessage: 'Delivery Time' }),
          sequence: 12,
          colCode: 'deliveryTimeTxt',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsAssignee', defaultMessage: 'Assignee' }),
          sequence: 13,
          colCode: 'assignee',
          width: 150,
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsActualCompletedDate', defaultMessage: 'Actual Completed Date' }),
          sequence: 14,
          colCode: 'actualShippingDate',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsActualCompletedTime', defaultMessage: 'Actual Completed Time' }),
          sequence: 15,
          colCode: 'actualShippingTime',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsSourceName', defaultMessage: 'Source Name' }),
          sequence: 16,
          colCode: 'source.address_name',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsSourceAddress', defaultMessage: 'Source Address' }),
          sequence: 17,
          colCode: 'source.address',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsDestinationName', defaultMessage: 'Destination Name' }),
          sequence: 18,
          colCode: 'destination.address_name',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsDestination', defaultMessage: 'Destination' }),
          sequence: 19,
          colCode: 'destination.address',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsReference', defaultMessage: 'Reference No.' }),
          sequence: 20,
          colCode: 'referenceNo',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsSoNo', defaultMessage: 'SO No' }),
          sequence: 21,
          colCode: 'saleOrderNo',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsRemark', defaultMessage: 'Remark' }),
          sequence: 22,
          colCode: 'remark',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsCreateDate', defaultMessage: 'Create Date' }),
          sequence: 23,
          colCode: 'createdAtDate',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsCreateTime', defaultMessage: 'Create Time' }),
          sequence: 24,
          colCode: 'createdAtTime',
        },
      ],
    };



    const response = await getOrdersListExport(payload);
    if (response.status === 200) {
      window.open(_.get(response, 'data.data.data.url'));
      successNotification(response.data.status.message);
    } else {
      errorNotification(response.data.status.message);
    }
  }

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={0}>
      {({ style }) => (
        <Row style={{ height: '52px' }}>
          <Col span={1} className="col-button-hide">
            <div style={{ cursor: 'pointer' }} onClick={handleToggle}>
              <Icon type={toggle ? 'menu-unfold' : 'menu-fold'} style={{ border: 'unset', marginLeft: '12px', color: '#e73845' }} />
            </div>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff', top: 'unset' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  const menuColumnDetail = () => {
    return (

      <Menu>
        {
          checkPermissionAction('P58PG1C1', 'P58PG1C1A3') ? (
            <Menu.Item key="1" onClick={() => handleEditBranch(selectBranchData)}>
              <p key="1">
                <FormattedMessage id="btnEdit" defaultMessage="Edit" />
              </p>
            </Menu.Item>
          ) : null
        }
        {
          checkPermissionAction('P58PG1C1', 'P58PG1C1A4') ? (
            <Menu.Item key="2" onClick={() => handleDeleteBranch(selectBranch)}>
              <p key="2" >
                <FormattedMessage id="btnDelete" defaultMessage="Delete" />
              </p>
            </Menu.Item>
          ) : null
        }
      </Menu>
    );
  };

  const menuColumnShipment = () => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => setVisibleCreateOrder(true)}>
          <p key="1">
            <FormattedMessage id="deliveryOrderButtonCreateOrder" defaultMessage="Create Order" />
          </p>
        </Menu.Item>
        <Menu.Item key="2">
          <p key="2" onClick={() => setOpenImport(true)}>
            <FormattedMessage id="deliveryOrderButtonImport" defaultMessage="Import Orders" />
          </p>
        </Menu.Item>
        <Menu.Item key="3">
          <p key="3" onClick={handleExportOrder}>
            <FormattedMessage id="deliveryOrderButtonExort" defaultMessage="Export All Orders" />
          </p>
        </Menu.Item>
      </Menu>
    );
  };

  const handleDeleteBranch = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteBranch(id);
          if (response.status === 200) {
            if (_.get(selectBranchData, 'id')) {
              let whId = _.get(selectBranchData, 'id')
              let findIndexArr = _.findIndex(branchList, function (o) {
                return o.id === whId;
              });
              if (findIndexArr > -1) {
                let filterArr = _.filter(branchList, function (o) {
                  return o.id !== whId;
                });
                setBranchList(filterArr);
              }
            }
            successNotification(_.get(response.data.status, 'message'));
            setSelectBranch();
            setSelectBranchData()
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      },
      onCancel() { },
    });

  }

  const handleCancelShipmentDetail = () => {
    setSelectShipmentData()
    setTriggerOrder(cur => !cur)
    app.fnc.getCodeAndDataDrawer('', '')
  }

  const onFilterOrder = async (value, code) => {
    setSearchOrder({ ...searchOrder, [code]: value });
    setShipmentPage(1)
  };

  console.log("searchOrder", searchOrder)


  return (
    <div>
      <DeliveryContext.Provider
        value={{
          handleSearchBranch,
          searchBranch,
          handleSelectBranch,
          selectBranch,
          selectBranchData,
          branchList,
          setBranchList,
          onScrollBranch,
          setBranchDataFormResponse,
          setRecordbranch,
          setTrigger,
          handleClickShipment,
          setSelectBranchData,
          managerList,
          roleList,
          userList,
          manageList,
          setTriggerManager,
          setTriggerMember,
          memberList,
          memberTotal,
          searchMember: {
            memberColumns,
            setMemberColumns,
            memberField,
            setMemberField,
            memberInput,
            setMemberInput,
            setMemberOrder,
            memberPage,
            setMemberPage,
            memberSize,
            setMemberSize,
          },
          visibleCreateOrder,
          setVisibleCreateOrder,
          statusData,
          orderList,
          orderTotal,
          searchShipment: {
            shipmentField,
            setShipmentField,
            shipmentInput,
            setShipmentInput,
            setShipmentOrder,
            shipmentPage,
            setShipmentPage,
            setShipmentSize,
          },
          selectShipmentData,
          setSelectShipmentData,
          handleCancelShipmentDetail,
          setTriggerOrder,
          sizeList,
          memberAll,
          setFileHash,
          orderData,
          fileHash,
          openImport,
          setOpenImport,
          memberLoading,
          loadingOrder,
          customerData,
          onFilterOrder,
          searchOrder,
          setSearchOrder
        }}
      >
        {
          checkPermissionAction(`P58PG1C1`, `P58PG1C1A1`) ? (
            <div>
              {
                selectShipmentData ? (
                  <DeliveryShipmentDetail />
                ) : (
                  <Row gutter={[16]}>
                    <Col span={toggle ? 0 : 6}>
                      <Card
                        title={intl.formatMessage({ id: `deliveryOrderBranchTitle`, defaultMessage: 'Store' })}
                        headStyle={{ padding: '0 0 0 24px ' }}
                        extra={
                          checkPermissionAction(`P58PG1C1`, `P58PG1C1A2`) ? (
                            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleModalBranch('add')}>
                              <FormattedMessage id="btnCreate" defaultMessage="Create" />
                            </Button01>
                          ) : null
                        }
                        bodyStyle={{ height: 'calc(100vh - 165px)', padding: 'unset' }}
                      >
                        <BranchList />
                      </Card>
                    </Col>
                    <Col span={toggle ? 24 : 18}>
                      {
                        selectBranch ? (
                          <Card bodyStyle={{ padding: 'unset' }}>
                            <StickyContainer>
                              <Tabs
                                className="delivery-tabs"
                                style={{ marginTop: '6px' }}
                                size="large"
                                onChange={handleTabChange}
                                renderTabBar={handleRenderTopBar}
                                activeKey={tabDelivery}
                                animated={false}
                                tabBarExtraContent={
                                  checkPermissionAction('P58PG1C1', 'P58PG1C1A3') || checkPermissionAction('P58PG1C1', 'P58PG1C1A4') ? (
                                    <Dropdown
                                      overlay={tabDelivery === "detail" ? menuColumnDetail : menuColumnShipment}
                                      trigger={['click']}
                                      placement="bottomRight"
                                      className="ant-dropdown-custom"
                                    >
                                      <Button01 key="submit" type="primary" btnsize="wd_df" onClick={(e) => e.preventDefault()}>
                                        <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                                        <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                                      </Button01>
                                    </Dropdown>
                                  ) : null
                                }
                              >
                                <TabPane tab={intl.formatMessage({ id: `deliveryOrderTabsDetail`, defaultMessage: 'Details' })} key="detail">
                                  <DeliveryDetail />
                                </TabPane>
                                <TabPane tab={intl.formatMessage({ id: `deliveryOrderTabsShipment`, defaultMessage: 'Shipment' })} key="shipment">
                                  <DeliveryShipment />
                                </TabPane>
                              </Tabs>
                            </StickyContainer>
                          </Card>
                        ) : (
                          <div style={{ padding: '24px', backgroundColor: '#fff' }}>
                            <Card className="deliveryBlank">
                              <NoDataBackground
                                text1={{ id: 'nodataXDeliveryOrder1', text: 'Please select a Branch' }}
                                text2={{ id: 'nodataXDeliveryOrder2', text: 'Branch will appear here.' }}
                              />
                            </Card>
                          </div>
                        )
                      }
                    </Col>
                  </Row>
                )}
            </div>
          ) : null
        }

        <BranchModalForm
          visible={visibleBranch}
          setVisible={setVisibleBranch}
          record={recordbranch}
          setRecord={setRecordbranch}
        />
      </DeliveryContext.Provider>
    </div >
  )
}

export default DeliveryOrder
