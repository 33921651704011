import httpClient from '../../components/axiosClient';

const searchPoint = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/pointpage`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getRole = async () => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v1/pointcommission/manager/company/${comCode}/web/pointpage/rolefilter`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getNameLoadmore = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/pointpage/memberfilter`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getPointSummary = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/summarypoint`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getPointHistory = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/memberpointlog`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const importPointExcel = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/point/import-exceldata`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const searchExcelPoint = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/point/preview-exceldata`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const confirmImportPoint = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/point/create-dataimport`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
}

const exportPoint = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/memberping/export`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const exportPointDetail = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/memberpoint-detail/export`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}


export { searchPoint, getRole, getNameLoadmore, getPointSummary, getPointHistory, importPointExcel, searchExcelPoint, confirmImportPoint, exportPoint,exportPointDetail };