import React from 'react'
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

const tabDefault = () => {
  return [
    {
      text: <FormattedMessage id="memberExpenseTabPending" defaultMessage="Pending" />,
      code: 'pending'
    },
    {
      text: <FormattedMessage id="memberExpenseTabWaitingForApprove" defaultMessage="Waiting for Approval" />,
      code: 'waiting'
    },
    {
      text: <FormattedMessage id="memberExpenseTabWaitingForPayment" defaultMessage="Waiting for Payment" />,
      code: 'waitingpaid'
    },
    {
      text: <FormattedMessage id="memberExpenseTabPaid" defaultMessage="Paid" />,
      code: 'paid'
    },
    {
      text: <FormattedMessage id="memberExpenseTabRejected" defaultMessage="Rejected" />,
      code: 'reject'
    },
    {
      text: <FormattedMessage id="memberExpenseTabCanceled" defaultMessage="Canceled" />,
      code: 'cancel'
    },
  ]
}

const columnChange = (tab) => {
  let defaultColumns = {
    columns: [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 70,
        align: 'center',
        fixed: 'left',
      },
      {
        title: <FormattedMessage id="memberExpenseCLMember" defaultMessage="Member" />,
        dataIndex: 'memberName',
        key: 'memberName',
        width: 200,
        fixed: 'left',
        sorter: true,
      },
      {
        title: <FormattedMessage id="memberExpenseCLTeam" defaultMessage="Team" />,
        dataIndex: 'teamName',
        key: 'teamName',
        width: 150,
        sorter: true,
      },
      {
        title: <FormattedMessage id="memberExpenseCLType" defaultMessage="Type" />,
        dataIndex: 'typeName',
        key: 'typeName',
        width: 150,
        sorter: true,
      },
      {
        title: <FormattedMessage id="memberExpenseCLAmount" defaultMessage="Amount" />,
        dataIndex: 'amountTxt',
        key: 'amountTxt',
        width: 150,
        sorter: true,
        align: 'right',
        // render: (text, record, index) => numberFormatter(_.get(record, 'amount', 0)) + " " + 'THB'
      },
      {
        title: <FormattedMessage id="memberExpenseCLWHTRate" defaultMessage="WHT Rate" />,
        dataIndex: 'whtRateTxt',
        key: 'whtRateTxt',
        width: 150,
        sorter: true,
      },
      {
        title: <FormattedMessage id="memberExpenseCLWHT" defaultMessage="WHT" />,
        dataIndex: 'whtTxt',
        key: 'whtTxt',
        width: 200,
        sorter: true,
        align: 'right',
      },
      {
        title: <FormattedMessage id="memberExpenseCLNetAmount" defaultMessage="Net Amount" />,
        dataIndex: 'netAmountTxt',
        key: 'netAmountTxt',
        width: 150,
        sorter: true,
        align: 'right',
      },
      {
        title: <FormattedMessage id="memberExpenseCLRemark" defaultMessage="Remark" />,
        dataIndex: 'remark',
        key: 'remark',
        width: 200,
      },
    ],
    setShowColumnArr: [
      'index',
      'memberName',
      'teamName',
      'typeName',
      'netAmountTxt',
      'remark',
    ],
    setShowColumn: {
      index: true,
      memberName: true,
      teamName: true,
      typeName: true,
      amountTxt: false,
      whtRateTxt: false,
      whtTxt: false,
      netAmountTxt: true,
      remark: true,
    },
  }
  if (tab === 'pending') {
    return {
      columns: defaultColumns.columns.concat(
        [{
          title: <FormattedMessage id="memberExpenseCLCreateDate" defaultMessage="Create Date" />,
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: 150,
        sorter: true,
        }]
      ),
      setShowColumnArr: defaultColumns.setShowColumnArr.concat(['createdDate']),
      setShowColumn: {
        ...defaultColumns.setShowColumn,
        createdDate: true
      }
    }
  }
  else if (tab === 'waiting' || tab === 'cancel' || tab === 'waitingpaid' || tab === 'paid') {
    return {
      columns: defaultColumns.columns.concat(
        [{
          title: <FormattedMessage id="memberExpenseCLPlanPaymentDate" defaultMessage="Planned Payment Date" />,
          dataIndex: 'planedPaymentDateTxt',
          key: 'planedPaymentDateTxt',
          width: 200,
        },{
          title: <FormattedMessage id="memberExpenseCLCreateDate" defaultMessage="Create Date" />,
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: 150,
        sorter: true,
        }]
      ),
      setShowColumnArr: defaultColumns.setShowColumnArr.concat(['planedPaymentDateTxt','createdDate']),
      setShowColumn: {
        ...defaultColumns.setShowColumn,
        planedPaymentDateTxt: true,
        createdDate: true,
      }
    }
  } 
  else if (tab === 'reject') {
    return {
      columns: defaultColumns.columns.concat(
        [{
          title: <FormattedMessage id="memberExpenseCLPlanPaymentDate" defaultMessage="Planned Payment Date" />,
          dataIndex: 'planedPaymentDateTxt',
          key: 'planedPaymentDateTxt',
          width: 200,
        },
        {
          title: <FormattedMessage id="memberExpenseCLCreateDate" defaultMessage="Create Date" />,
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: 150,
        sorter: true,
        },
          {
            title: <FormattedMessage id="memberExpenseCLRejectedReason" defaultMessage="Rejected Reason" />,
            dataIndex: 'rejectReason',
            key: 'rejectReason',
            width: 200,
          },
          {
            title: <FormattedMessage id="memberExpenseCLRejectedRemark" defaultMessage="Rejected Remark" />,
            dataIndex: 'rejectRemark',
            key: 'rejectRemark',
            width: 200,
          },

        ]
      ),
      setShowColumnArr: defaultColumns.setShowColumnArr.concat(['planedPaymentDateTxt','createdDate',]),
      setShowColumn: {
        ...defaultColumns.setShowColumn,
        planedPaymentDateTxt: true,
        createdDate: true,
      }
    }
  } else {
    return {}
  }
}

const defaultIgnoreColumns = (columns, key) => {
  const ignoreColumn = key === 'waitingForPayment' || key === 'paid' ? ['amountTxt', 'whtRateTxt', 'whtTxt', 'approvedBy'] : ['amountTxt', 'whtRateTxt', 'whtTxt'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  return newColumns
}



export { tabDefault, columnChange, defaultIgnoreColumns }
