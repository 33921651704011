import React, { useState, useEffect, useContext, useRef } from 'react';
import { Avatar, Modal, Row, Col, Icon, DatePicker, Select, Form, Tag, Spin } from 'antd';
import {
  TimelineMonth,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  DragAndDrop,
  Schedule,
} from '@syncfusion/ej2-react-schedule';
import { Internationalization } from '@syncfusion/ej2-base';
import { PagePlanningSettings } from './page-planning-settings';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import notification from '../../../../components/v2.1.0/notification';
import Button01 from '../../../../components/v2/button_01';
import styles from '../css/scheduler.css';
// import styles from '../css/scheduler.css'
import { right } from 'glamor';

const scheduleOptions = new Schedule();
const instance = new Internationalization();
const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

const mockDataDetail = [
  {
    shift: 'Morning Shift',
    detail: [
      {
        text: 'Employees who do more overtime than required.',
      },
      {
        text: 'The number of member in that shift is less or more than required.',
      },
      {
        text: 'Shift that do more consecutively.',
      },
    ],
  },
  {
    shift: 'Afternoon Shift',
    detail: [
      {
        text: 'Maximum overtime member over.',
      },
    ],
  },
];

export default React.memo(() => {
  const intl = useIntl();
  const app = useContext(PagePlanningSettings);
  const [dataSource, setDataSource] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [visibleDetailWork, setVisibleDetailWork] = useState(false);
  const [formData, setFormData] = useState({});
  const [editVisible, setEditVisible] = useState(false);
  const [rangePickerValue, setRangePickerValue] = useState([]);
  const [spinLoading, setSpinLoading] = useState(false);
  const formRef = useRef();
  const langValue = localStorage.getItem('langValue') || 'EN';

  useEffect(() => {
    // setSpinLoading(true);
    if (app.scenarioIndex !== null && app.scenarios.length > 0) {
      // const setIsReadonly = [];
      // for(let o of app.scenarios[app.scenarioIndex].scenarioShiftList) {
      //   setIsReadonly.push({
      //     ...o,
      //     isReadonly: o.isLeave || o.isHoliday ? true : false
      //   });
      // }
      setDataSource(app.scenarios[app.scenarioIndex].scenarioShiftList);
      // setSpinLoading(false);

      // scheduleObj.current.dataBinding();
    }
  });

  // useEffect=(() => {
  //   if (dataSource && dataSource.length > 1) {
  //     setSpinLoading(false);
  //   } else {
  //     setSpinLoading(false);
  //   }
  // }, [dataSource]);

  const checkShiftTimePeriod = (id, start, end, memId) => {
    const events = app.scheduleObj.current.getEvents();
    const startUnix = moment(start).format('X');
    const endUnix = moment(end).format('X');
    const findShiftDateTime = _.findIndex(events, (e) => {
      const xStartUnix = moment(e.StartTime).format('X');
      const xEndUnix = moment(e.EndTime).format('X');
      return e.Id !== id &&
        e.isHoliday === false &&
        e.ResourceId === memId &&
        ((startUnix >= xStartUnix && startUnix <= xEndUnix) || (endUnix >= xStartUnix && endUnix <= xEndUnix))
        ? true
        : false;
    });
    if (findShiftDateTime > -1) {
      notification({ status: 'error', message: intl.formatMessage({ id: 'orgShiftPlanningLblError', defaultMessage: 'Error.' }) });
    }
    return findShiftDateTime > -1 ? true : false;
  };

  const getDateHeaderText = (value, skeleton) => {
    return instance.formatDate(value, { skeleton: skeleton });
  };

  const customRenderCell = (args, resources) => {
    if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
      let target = args.element.querySelector('.e-resource-text');
      target.innerHTML =
        `<div style="padding: 0px 24px; text-align: center;"><b> ${intl.formatMessage({
          id: 'orgShiftPlanningLblResources',
          defaultMessage: 'Resources',
        })} (xxx` +
        resources.length +
        ')</div>';
    }
  };

  const dateHeaderTemplate = (props) => {
    return (
      <div style={{ height: 50, padding: 5 }}>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <div style={{ lineHeight: 1.5, fontSize: 15 }}>{getDateHeaderText(props.date, 'E')}</div>
          <Icon
            style={{ color: '#fe6203', fontSize: '16px', marginLeft: '6px' }}
            type="exclamation-circle"
            onClick={() => setVisibleDetailWork(true)}
          />
        </div>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'd')}</div>
      </div>
    );
  };

  const resourceHeaderTemplate = (props) => {
    const { resourceData } = props;
    const checkResourceMockData = props.resourceData.Id.toString().indexOf('mockdata');
    return checkResourceMockData ? (
      <div>
        <Avatar size={32} src={process.env.REACT_APP_IMG_HOST + resourceData.pathImage} />
        <span style={{ margin: '15px', fontSize: '12px' }}>{resourceData.Text}</span>
        {/* <Icon 
          style={{ color: '#fe6203', 
            fontSize: '16px', 
            float: 'right', 
            padding: '16px 0px' 
          }} 
          type="exclamation-circle" 
          onClick={() => setVisibleDetailWork(true)}
        /> */}
      </div>
    ) : (
      <div></div>
    );
  };

  const onDataBinding = (args) => {
    // args.result.push();
  };

  const onEventRendered = (args) => {
    let width = parseInt(args.element.style.width);
    let height = 38;
    let left = parseInt(args.element.style.left) + 6;

    if (width >= 270) {
      width = 261;
    } else {
      width = 125;
    }

    args.element.style.width = width + 'px';
    args.element.style.height = height + 'px';
    args.element.style.color = '#ffffff';
    args.element.style.backgroundColor = args.data.Color;
    args.element.style.margin = '0px';
    args.element.style.borderRadius = '3px';
    args.element.style.left = left + 'px';

    args.element.querySelector('.e-appointment-details').style.alignItems = 'center';
    args.element.querySelector('.e-inner-wrap').style.padding = '0px 12px';
    args.element.querySelector('.e-inner-wrap').style.fontSize = '12px';
    // args.element.querySelector('.e-subject').style.fontWeight = '100';
    args.element.querySelector('.e-time').style.lineHeight = '1.5';
    args.element.querySelector('.e-time').style.fontSize = '8px';
    args.element.querySelector('.e-time').innerHTML =
      moment(args.data.StartTime).format('HH:mm') + ' - ' + moment(args.data.EndTime).format('HH:mm') + ' - ' + args.data.orgName;

    // let cellHeight = scheduleObj.current.element.querySelector('.e-appointment-wrapper');
    // let appHeight = (args.data.EndTime.getTime() - args.data.StartTime.getTime()) / (60 * 1000) * (cellHeight * scheduleObj.current.timeScale.slotCount) / scheduleObj.current.timeScale.interval;

    // args.element.style.height = appHeight + 'px';

    // if(args.element.style.top === '42px') {
    //   args.element.style.top = '27px';
    // }

    // if(args.element.style.top === '82px') {
    //   args.element.style.top = '52px';
    // }
  };

  const onPopupOpen = (args) => {
    if (args.name === 'popupOpen') {
      args.cancel = true;
    }
  };

  const handleClickEvent = (args) => {
    if (args.event.isHoliday || args.event.isLeave) return;

    const findMemberIndex = _.findIndex(app.resources, (e) => {
      return e.Id === args.event.ResourceId ? true : false;
    });
    setFormData({
      Id: args.event.Id,
      Subject: args.event.Subject,
      Color: args.event.Color,
      memId: args.event.ResourceId,
      memComId: args.event.memComId,
      pathImage: process.env.REACT_APP_IMG_HOST + app.resources[findMemberIndex].pathImage,
      name: app.resources[findMemberIndex].Text,
      email: app.resources[findMemberIndex].email || '',
      phone: app.resources[findMemberIndex].phone || '',
      startDate: moment(args.event.StartTime)
        .locale(langValue.toLowerCase())
        .format('DD/MM/YYYY hh:mm A'),
      endDate: moment(args.event.EndTime)
        .locale(langValue.toLowerCase())
        .format('DD/MM/YYYY hh:mm A'),
    });
    setEditVisible(true);
    // app.scheduleObj.current.deleteEvent(args.event.Id, 'DeleteOccurrence');
  };

  const handleClickCloseEdit = () => {
    setEditVisible(false);
  };

  const handleClickDelete = () => {
    confirm({
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete?' }),
      okText: intl.formatMessage({ id: 'settingHolidaysModalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      onOk() {
        app.scheduleObj.current.deleteEvent(formData.Id);
        setEditVisible(false);
      },
      onCancel() {},
    });
  };

  const ShiftEdit = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Row gutter={[16, 8]} style={{ textAlign: 'center' }}>
            <Col span={24}>
              <Avatar size={96} src={formData.pathImage || ''} />
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <h3>{formData.name || ''}</h3>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Row gutter={[16, 8]} type="flex" justify="space-around" align="middle">
                <Col span={2}>
                  <Icon type="mail" />
                </Col>
                <Col span={22}>
                  <label>{formData.email || ''}</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Row gutter={[16, 8]} type="flex" justify="space-around" align="middle">
                <Col span={2}>
                  <Icon type="phone" />
                </Col>
                <Col span={22}>
                  <label>{formData.phone || ''}</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Row gutter={[16, 8]} type="flex" justify="space-around" align="middle">
                <Col span={2}>
                  <Icon type="calendar" />
                </Col>
                <Col span={22}>
                  <label>
                    {formData.startDate || ''} - {formData.endDate || ''}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Row gutter={[16, 8]} type="flex" justify="space-around" align="middle">
                <Col span={2}>
                  <Icon type="menu" />
                </Col>
                <Col span={22}>
                  <label>
                    <Tag color={formData.Color || '#ffffff'}>{formData.Subject || ''}</Tag>
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const handleClick = (args) => {
    const checkResourceMockData = app.resources[args.groupIndex].Id.toString().indexOf('mockdata');
    if (checkResourceMockData > -1) return;

    setFormData({
      memId: app.resources[args.groupIndex].Id,
      memComId: app.resources[args.groupIndex].memComId,
      pathImage: process.env.REACT_APP_IMG_HOST + app.resources[args.groupIndex].pathImage,
      name: app.resources[args.groupIndex].Text,
      email: app.resources[args.groupIndex].email || '',
      phone: app.resources[args.groupIndex].phone || '',
      startDate: args.startTime,
      endDate: args.endTime,
    });
    setFormVisible(true);
    setRangePickerValue([args.startTime, args.endTime]);
  };

  const handleCancel = () => {
    setFormVisible(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.validateFields((err, values) => {
      if (!err) {
        let data = [];
        let formStartDate = values['range-picker'][0].format('YYYY-MM-DD');
        let formEndDate = values['range-picker'][1].format('YYYY-MM-DD');
        let formSelect = values['select'];
        let formSubject = '';
        let formShiftMasterId = '';
        let formStartTime = '00:00 AM';
        let formEndTime = '23:59 PM';
        let formColor = '';

        const findShiftMasterIndex = _.findIndex(app.shiftTypeRule, (e) => {
          return e.shiftId === formSelect ? true : false;
        });

        if (findShiftMasterIndex > -1) {
          formShiftMasterId = app.shiftTypeRule[findShiftMasterIndex].shiftId;
          formSubject = app.shiftTypeRule[findShiftMasterIndex].shiftName;
          formColor = app.shiftTypeRule[findShiftMasterIndex].shiftColor;
          formStartTime = app.shiftTypeRule[findShiftMasterIndex].shiftStartTime;
          formEndTime = app.shiftTypeRule[findShiftMasterIndex].shiftEndTime;
        }

        while (formStartDate <= formEndDate) {
          const newShiftId = uuidv4();
          const newFormStart = moment(formStartDate + ' ' + formStartTime, 'YYYY-MM-DD hh:mm a').format();
          const newFormEnd = moment(formStartDate + ' ' + formEndTime, 'YYYY-MM-DD hh:mm a').format();
          const checkOverlap = checkShiftTimePeriod(newShiftId, newFormStart, newFormEnd, formData.memId);

          if (!checkOverlap) {
            data.push({
              Id: newShiftId,
              ResourceId: formData.memId,
              Subject: formSubject,
              StartTime: formStartDate + ' ' + moment(formStartTime, 'hh:mm a').format('HH:mm'),
              EndTime: formStartDate + ' ' + moment(formEndTime, 'hh:mm a').format('HH:mm'),
              memComId: formData.memComId,
              orgId: app.orgId.toString(),
              IsAllDay: false,
              isHoliday: false,
              isLeave: false,
              workDate: formStartDate,
              ShiftMasterId: formShiftMasterId,
              Color: formColor,
            });
          }

          formStartDate = moment(formStartDate, 'YYYY-MM-DD')
            .add(1, 'days')
            .format('YYYY-MM-DD');
        }

        app.scheduleObj.current.addEvent(data);
        setFormVisible(false);
      }
    });
  };

  const disabledDate = (current) => {
    return app.thisYearMonth !== current.format('YYYY-MM') ? true : false;
  };

  const ShiftForm = Form.create({
    name: 'shift_type_form',
    mapPropsToFields(props) {
      return {
        'range-picker': Form.createFormField({
          value: [moment(rangePickerValue[0]), moment(rangePickerValue[0])],
        }),
      };
    },
  })((props) => {
    const { getFieldDecorator } = props.form;

    return (
      <Form>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row gutter={[16, 8]} style={{ textAlign: 'center' }}>
              <Col span={24}>
                <Avatar size={96} src={formData.pathImage || ''} />
              </Col>
            </Row>
          </Col>
          <Col span={16}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <h3>{formData.name || ''}</h3>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]} type="flex" justify="space-around" align="middle">
                  <Col span={2}>
                    <Icon type="mail" />
                  </Col>
                  <Col span={22}>
                    <label>{formData.email || ''}</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]} type="flex" justify="space-around" align="middle">
                  <Col span={2}>
                    <Icon type="phone" />
                  </Col>
                  <Col span={22}>
                    <label>{formData.phone || ''}</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]} type="flex" justify="space-around" align="middle">
                  <Col span={2}>
                    <Icon type="calendar" />
                  </Col>
                  <Col span={22}>
                    <Form.Item>
                      {getFieldDecorator('range-picker', {
                        rules: [
                          {
                            type: 'array',
                            required: true,
                            message: intl.formatMessage({ id: 'orgShiftPlanningValidateTime', defaultMessage: 'Please select time.' }),
                          },
                        ],
                      })(
                        <RangePicker
                          placeholder={[
                            intl.formatMessage({ id: 'orgShiftPlanningModalHintStartDate', defaultMessage: 'Please input start date' }),
                            intl.formatMessage({ id: 'orgShiftPlanningModalHintEndDate', defaultMessage: 'Please input end date' }),
                          ]}
                          disabledDate={disabledDate}
                          size="small"
                          allowClear={false}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]} type="flex" justify="space-around" align="middle">
                  <Col span={2}>
                    <Icon type="menu" />
                  </Col>
                  <Col span={22}>
                    <Form.Item>
                      {getFieldDecorator('select', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'orgShiftPlanningModalHintSelectShift',
                              defaultMessage: 'Please select shift',
                            }),
                          },
                        ],
                      })(
                        <Select
                          size="small"
                          placeholder={intl.formatMessage({
                            id: 'orgShiftPlanningModalHintSelectShift',
                            defaultMessage: 'Please select shift',
                          })}
                          style={{ width: '100%' }}
                        >
                          {app.shiftTypeRule.map &&
                            app.shiftTypeRule.map((e) => (
                              <Option key={`shift-type-key-${e.shiftId}`} value={e.shiftId}>
                                {e.shiftName}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  });

  const handleDrag = (args) => {
    if (args.data.isHoliday || args.data.isLeave || args.data.orgId !== app.orgId.toString()) args.cancel = true;
  };

  const handleDrop = (args) => {
    const checkOverlap = checkShiftTimePeriod(args.data.Id, args.data.StartTime, args.data.EndTime, args.data.ResourceId);
    if (checkOverlap) args.cancel = true;
  };

  const eventTemplate = (args) => {
    return <div style={{ color: '#fff' }}>xxxxxx</div>;
  };

  return (
    <PagePlanningSettings.Consumer>
      {({ scheduleObj, thisYearMonth, resources, scenarios, scenarioIndex }) =>
        dataSource.length > 0 ? (
          <Spin spinning={spinLoading}>
            <div>
              <ScheduleComponent
                ref={scheduleObj}
                cssClass="schedule-cell-dimension-shift"
                width="100%"
                // height='650px'
                showHeaderBar={false}
                rowAutoHeight={true}
                showQuickInfo={false}
                workDays={[0, 1, 2, 3, 4, 5, 6]}
                selectedDate={moment(thisYearMonth + '-01', 'YYYY-MM-DD')._d}
                currentView="TimelineMonth"
                eventSettings={{
                  dataSource: dataSource,
                }}
                eventRendered={onEventRendered}
                group={{
                  resources: ['Resources'],
                }}
                renderCell={(args) => customRenderCell(args, resources)}
                dateHeaderTemplate={dateHeaderTemplate}
                resourceHeaderTemplate={resourceHeaderTemplate}
                popupOpen={onPopupOpen}
                cellClick={handleClick}
                eventClick={handleClickEvent}
                dragStart={handleDrag}
                dragStop={handleDrop}
              >
                <ResourcesDirective>
                  <ResourceDirective
                    field="ResourceId"
                    title="MemberShift"
                    name="Resources"
                    allowMultiple={false}
                    dataSource={resources}
                    idField="Id"
                    textField="Text"
                  />
                </ResourcesDirective>

                <ViewsDirective>
                  <ViewDirective option="TimelineMonth" />
                </ViewsDirective>

                <Inject services={[TimelineMonth, DragAndDrop]} />
              </ScheduleComponent>

              <Modal
                title="Warning * Wed, Sep 20, 2020"
                visible={visibleDetailWork}
                onOk={() => setVisibleDetailWork(false)}
                onCancel={() => setVisibleDetailWork(false)}
                footer={[
                  <Button01 key="submit" type="primary" btnsize="wd_df" size={'small'} onClick={() => setVisibleDetailWork(false)}>
                    <FormattedMessage id="btnOK" defaultMessage="OK" />
                  </Button01>,
                ]}
              >
                {mockDataDetail.map((item, index) => (
                  <div key={index} style={{ marginBottom: '20px' }}>
                    <p style={{ fontSize: '12px', color: '#1D3557' }}>{item.shift}</p>
                    {item.detail.map((e, i) => (
                      <p key={i} style={{ fontSize: '12px', color: '#1D3557' }}>
                        - {e.text}
                      </p>
                    ))}
                  </div>
                ))}
              </Modal>

              <Modal
                title={intl.formatMessage({ id: 'orgShiftPlanningModalTitleAdd', defaultMessage: 'Add Shift' })}
                centered
                width={550}
                visible={formVisible}
                onOk={handleSubmit}
                onCancel={handleCancel}
                okText={intl.formatMessage({ id: 'btnSave', defaultMessage: 'Save' })}
                cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
              >
                <ShiftForm ref={formRef} />
              </Modal>

              <Modal
                title={intl.formatMessage({ id: 'orgShiftPlanningModalTitleEdit', defaultMessage: 'Edit Shift' })}
                centered
                width={550}
                visible={editVisible}
                onOk={handleClickDelete}
                onCancel={handleClickCloseEdit}
                okText={intl.formatMessage({ id: 'btnDelete', defaultMessage: 'Delete' })}
                cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
              >
                <ShiftEdit />
              </Modal>
            </div>
          </Spin>
        ) : null
      }
    </PagePlanningSettings.Consumer>
  );
});
