import { Col, DatePicker, Form, Input, Row, Select, Tag, Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import CustomTableComponent from '../components/table';
import CollapseCustom from '../../../components/collapse-custom';
import { messageLabel } from '../../../components/message-component';
import _ from 'lodash';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import { InvoiceContext } from '../context';
import QuotationModal from '../modal/saleorder/invoice-modal';
import moment from 'moment';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import ActionColumnInvoice from '../components/action-column';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const InvoiceCard = () => {
  const { state, setState, func } = useContext(InvoiceContext);
  const { isOpenQuotation, reportDataWaiting, statusList, customerList } = state;
  const { seIsOpenQuotation, setReportDataWaiting, setIsFetch } = setState;
  const { handleOpenModalQuotation,handleCustomerPopupScroll } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataWaiting;
  const intl = useIntl();

  console.log('reportDataWaiting', reportDataWaiting);

  const handleFieldSort = (value) => {
    setReportDataWaiting((prev) => ({ ...prev, fieldSort: value }));
  };

  const handleOrderSort = (value) => {
    setReportDataWaiting((prev) => ({ ...prev, orderSort: value }));
  };

  const handlePaginationPage = (value) => {
    setReportDataWaiting((prev) => ({ ...prev, paginationPage: value }));
  };

  const handlePaginationSize = (value) => {
    setReportDataWaiting((prev) => ({ ...prev, paginationSize: value }));
  };

  const handleSelectRangePicker = (value) => {
    setReportDataWaiting((prev) => ({ ...prev, rangePickerDate: value, paginationPage: 1, }));
  };

  const handleApply = (value) => {
    setReportDataWaiting((prev) => ({ ...prev, paginationPage: 1 }));
    setIsFetch((event) => !event);
  };

  const handleReset = (value) => {
    setReportDataWaiting((prev) => ({ ...prev, invoiceNo: '', saleOrderNo: '', customerId: '',statusCode: [],paginationPage: 1 }));
    setIsFetch((event) => !event);
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    // {
    //   title: messageLabel('quotationLabelQuotationNo', 'Quotation No'),
    //   dataIndex: 'quotationNo',
    //   key: 'quotationNo',
    //   sorter: true,
    //   fixed: 'left',
    //   width: 150,
    // },
    {
      title: messageLabel('invoiceLabelINVNo', 'INV No.'),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('invoiceLabelSONo', 'SO No.'),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      // width: 200,
    },
    {
      title: messageLabel('invoiceLabelCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      render: (text, record, index) => _.get(record, 'customerName'),
    },
    {
      title: messageLabel('invoiceLabelBLNo', 'BL No.'),
      dataIndex: 'billingNo',
      key: 'billingNo',
      sorter: true,
      render: (text, record, index) => _.get(record,'billingNo') || '-',
    },
    {
      title: messageLabel('invoiceLabelStatus', 'Status'),
      dataIndex: 'statusTxt',
      key: 'statusTxt',
      sorter: true,
      width: 200,
      align: 'center',
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={_.get(record, 'statusColor')}>
            {_.get(record, 'statusTxt')}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('invoiceLabelTotal', 'Total'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      width: 200,
      align: 'right',
      // render: (text, record, index) => (
      //   // _.get(record, 'total') ? (
      //   <span>{numberFormatDecimal(_.get(record, 'totalTxt'))}</span>
      // ),
      // ) : null,
    },
    {
      title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
      dataIndex: 'issueBy',
      key: 'issueBy',
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('invoiceLabelTeam', 'Team'),
      dataIndex: 'team',
      key: 'team',
      sorter: true,
      render: (text, record, index) => _.get(record, 'team') || '-',
    },
    {
      title: messageLabel('invoiceLabelIssued Date', 'Issued Date'),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: true,
      //   render: (text, record, index) =>
      //     _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format(dateFormat) : null,
    },
  ];

  const setShowColumn = {
    index: true,
    invoiceNo: true,
    customerName: true,
    saleOrderNo: true,
    totalItem: true,
    billingNo: true,
    statusTxt: true,
    totalTxt: true,
    issueBy: true,
    issueDateTxt: true,
  };

  const setShowColumnArr = [
    'index',
    'invoiceNo',
    'customerName',
    'saleOrderNo',
    'totalItem',
    'billingNo',
    'statusTxt',
    'totalTxt',
    'issueBy',
    'issueDateTxt',
  ];

  const ignoreColumn = ['team'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);

  const advancedFilter = (action, value) => {
    const handleChange = (value, key) => {
      action((prev) => ({ ...prev, [key]: value }));
    };

    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelINVNo', defaultMessage: 'INV No.' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(value, 'invoiceNo')}
                onChange={(e) => handleChange(e.target.value, 'invoiceNo')}
                placeholder={intl.formatMessage({ id: 'invoiceLabelINVNoPlaceHolder', defaultMessage: 'Enter INV No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelSONo', defaultMessage: 'SO No.' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(value, 'saleOrderNo')}
                onChange={(e) => handleChange(e.target.value, 'saleOrderNo')}
                placeholder={intl.formatMessage({ id: 'invoiceLabelSONoPlaceHolder', defaultMessage: 'Select SO No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })} req={false} />}
            >
              {/* <Input
                allowClear
                value={_.get(value, 'customerName')}
                onChange={(e) => handleChange(e.target.value, 'customerName')}
                placeholder={intl.formatMessage({ id: 'invoiceLabelCustomerPlaceHolder', defaultMessage: 'Select Customer' })}
              /> */}
              <Select
                allowClear
                showSearch
                value={_.get(value, 'customerId') || undefined}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'VoucherCustomerPlaceHolder', defaultMessage: 'Select Customer' })}
                onPopupScroll={handleCustomerPopupScroll}
                onChange={(e) => handleChange(e, 'customerId')}
                onSearch={(e) => handleChange(e, 'customerId')}
              >
                {_.map(customerList, (item, index) => (
                  <Option key={item.customerId} value={item.customerId}>
                    {item.customerName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelStatus', defaultMessage: 'Status' })} req={false} />}
            >
              <Select
                allowClear
                value={_.get(value, 'statusCode') || undefined}
                onChange={(value) => handleChange(value, 'statusCode')}
                placeholder={intl.formatMessage({ id: 'invoiceLabelStatusPlaceHolder', defaultMessage: 'Select Status' })}
                mode="multiple"
              >
                {statusList &&
                  statusList.map((item) => (
                    <Option key={item.statusCode} value={item.statusCode}>
                      {item.statusTxt}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
              <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
            </Button_02>
            
            <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handleApply()}>
              <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
            </Button_01>
            
          </Col>
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    // console.log('content', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'view')}>
            <FormattedMessage id="btnView" defaultMessage="View" />
          </Button>
        </div>
        {_.get(record, 'statusCode') === 'draft' && (
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'edit')}>
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <div>
              <RangePicker
                value={rangePickerDate}
                format={dateFormat}
                onChange={handleSelectRangePicker}
                allowClear={true}
                placeholder={[
                  intl.formatMessage({ id: 'saleorderFilterPHFormDueDate', defaultMessage: 'Select Form Date' }),
                  intl.formatMessage({ id: 'saleorderFilterPHToDueDate', defaultMessage: 'Selet To Date' }),
                ]}
              />
              {/* <div style={{ display: 'flex' }}> */}
              <ActionColumnInvoice
                columns={columns}
                setShowColumn={setShowColumn}
                newDataColumns={newDataColumns}
                listArrayColumns={listArrayColumns}
                defaultShowColumn={defaultShowColumn}
                newColumns={newColumns}
                setNewDataColumns={setNewDataColumns}
                setListArrayColumns={setListArrayColumns}
                setDefaultShowColumn={setDefaultShowColumn}
                textErrorSelectColumn={textErrorSelectColumn}
                setTextErrorSelectColumn={setTextErrorSelectColumn}
                visible={visible}
                setVisible={setVisible}
                columnMinimum={1}
              />
              {/* </div> */}
            </div>
          }
        >
          {advancedFilter(setReportDataWaiting, reportDataWaiting)}
        </CollapseCustom>
      </div>
      <div className="content-body-report">
        <CustomTableComponent
          rowKey={(record) => record.saleOrderId}
          columns={newDataColumns}
          dataSource={quotationList}
          scroll={{ x: true }}
          role={true}
          onRow={true}
          total={quotationTotal}
          loading={loading}
          paginationPage={paginationPage}
          setFieldSort={handleFieldSort}
          setOrderSort={handleOrderSort}
          setPaginationSize={handlePaginationSize}
          setPaginationPage={handlePaginationPage}
          contentAction={contentAction}
        />
      </div>
    </div>
  );
};

export default InvoiceCard;
