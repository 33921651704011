import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Modal, Select, Typography, Icon, Col, Row, Divider, Form, InputNumber, Popover, Button } from 'antd';
import Api from '../../../components/httpClient';
import { notificationWithIcon } from '../../../components/notification';
import pathUrt from '../../../function/pathUrl';
import cssStyle from './css/cssCard.css';
import clientNew from '../../../components/axiosClient';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { PageSettings } from '../../../config/page-settings';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import LabeRequire from '../../../components/v2/label-require';
import _ from 'lodash';
import LabeRequireForm from '../../../components/label-required-form';
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';
import SuffixInput from '../../../components/v2/suffix';
import ModalSelectItemPayDeduct from '../../../components/modal-select-item-pay-deduct';
import { messageLabel } from '../../../components/message-component';
import {
  creatUserBasePay,
  deleteUserBasePay,
  getItemBasePay,
  getUserBasePay,
  updateUserBasePay,
} from '../../../controllers/base-pay/basepay';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';

const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;
let client = Api();

const BasePay = (props) => {
  const intl = useIntl();

  const [dataSourceId, setToDatasourceId] = useState([]);
  const [stateModal, setStateModal] = useState(false);
  const [defaultArr, setDefaultArr] = useState([]);
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const createBy = props.createdBy;
  const userId = props.userId;
  const checkPathUrl = pathUrt('/user-management/my-profile');

  const [itemUser, setItemUser] = useState([]);
  const [listItem, setListItem] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [toggleListItem, setToggleListItem] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [visiblePopover, setVisiblePopover] = useState(null);

  const { form } = props;
  const { getFieldDecorator, resetFields, getFieldValue, setFieldsValue } = form;

  useEffect(() => {
    const getAPI = async () => {
      const body = {
        limit: 10000,
        page: 1,
      };

      const response = await getUserBasePay(body, userId);
      if (_.get(response, 'data.status.code') === 200) {
        setItemUser(response.data.data.basePayList);
      }
    };
    getAPI();
  }, [toggle]);

  useEffect(() => {
    const getAPI = async () => {
      const body = {
        limit: 10000,
        page: 1,
        // status: 'active',
        noType: 'pay'
      };

      const response = await getItemBasePay(body);

      if (_.get(response, 'data.status.code') === 200) {
        // เช็คเรื่องถ้ากด edit แล้ว ต้องมีตัวที่ต้องเป็น inactive
        const data = _.get(response, 'data.data.basePayList');
        const filterItemAction = _.filter(data, (item) => item.statusCode === 'active');
        // ถ้าเลือกตัวที่เป็น inactive -> ให้เพิ่มเข้าไปใน list 
        if (_.get(dataForm, 'action') === 'edit') {
          const filterEditItem = _.chain(data)
            .filter((item) => item.basePayId === _.get(dataForm, 'basePayId'))
            .head()
            .value();
          setListItem(_.get(filterEditItem, 'statusCode') === 'inactive' ? filterItemAction.concat(filterEditItem) : filterItemAction);
        } else {
          setListItem(filterItemAction);
        }
      }
    };
    getAPI();
  }, [toggleListItem]);

  const handleCancel = (e) => {
    resetFields();
    setStateModal(false);
  };

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const payload =
        _.get(dataForm, 'action') === 'edit'
          ? {
              basePayId: _.get(values, 'basePayId'),
              amount: _.get(values, 'amount') || 0,
            }
          : {
              basePayId: _.get(values, 'basePayId'),
              userId: userId,
              amount: _.get(values, 'amount') || 0,
            };

      try {
        const response =
          _.get(dataForm, 'action') === 'edit'
            ? await updateUserBasePay(payload, userId, _.get(dataForm, 'basePayMemberId'))
            : await creatUserBasePay(payload);

        if (_.get(response, 'data.status.code') === 200) {
          successNotification(_.get(response, 'data.status.message'));
          handleCancel();
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.data.message);
      }

      setToggle((event) => !event);
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteUserBasePay(userId, _.get(record, 'basePayMemberId'));
          if (_.get(response, 'data.status.code') === 200) {
            successNotification(response.data.status.message);
            setToggle((event) => !event);
          }
        } catch (error) {
          errorNotification(error.response.data.data.message);
        }
      },
      onCancel() {},
    });
  };

  const handleButtonClick = () => {
    setVisiblePopover(null);
  };

  const handleRowClick = (index) => {
    setVisiblePopover(visiblePopover === index ? null : index);
  };

  const handleMouseLeave = () => {
    setVisiblePopover(null);
    // setOffsetPopover([0, 0]);
  };

  let show = itemUser.length
    ? itemUser.map((item, index) => (
        <div>
          <Popover
            key={index}
            placement="leftTop"
            // trigger="hover"
            trigger="click"
            zIndex={999}
            visible={visiblePopover === index}
            content={
              <div style={{ display: 'grid' }}>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A3') ? (
                        checkPathUrl === true ? null : (
                          <Button
                            style={{ width: 100 }}
                            type="link"
                            ghost
                            onClick={() => {
                              handleButtonClick();
                              handleOpenModal(item, 'edit');
                            }}
                          >
                            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                          </Button>
                        )
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>

                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A4') ? (
                        checkPathUrl === true ? null : (
                          <Button
                            style={{ width: 100 }}
                            type="link"
                            ghost
                            onClick={() => {
                              handleButtonClick();
                              handleDelete(item);
                            }}
                          >
                            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                          </Button>
                        )
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </div>
            }
          >
            <Row onClick={() => handleRowClick(index)}>
              <Col span={12} style={{ textAlign: 'left' }}>
                {item.basePayName}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {numberFormatter(item.amount)} THB
              </Col>
            </Row>
          </Popover>

          <Divider className="usersDetail-content-divider" />
        </div>
      ))
    : null;
  // <Text type={'secondary'} style={{ paddingLeft: '15px' }}>
  //   <FormattedMessage id="userMgntUsersDetailLblRoleTxt" defaultMessage="Please assign to role" />
  // </Text>

  // const handleOnVisiblePopover = (visiblePopover) => {
  //   setVisiblePopover(visiblePopover);
  // };

  let option = listItem.map((item, index) => (
    <Option key={item.basePayId} value={item.basePayId}>
      {item.basePayName}
    </Option>
  ));

  const handleOpenModal = (record, action) => {
    setDataForm({ ...record, action: action });
    setStateModal(true);
    setToggleListItem((event) => !event);
  };

  const currencyFormatter = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const currencyParser = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\$\s?|(,*)/g, '');
    }
  };

  const onSelectItem = (itemId) => {
    setFieldsValue({
      ['basePayId']: itemId,
    });
  };

  const onCheckItem = () => {
    // เช็คเรื่องถ้า Select item ที่เป็น active อยู่ แล้วไปเปลียนเป็น inactive จะต้องกลับมาเครียร์ค่า
    const getBasePay = getFieldValue('basePayId');
    if (getBasePay) {
      const filter = _.filter(listItem, (item) => item.basePayId === getBasePay);
      if (_.size(filter) === 0) {
        setFieldsValue({
          ['basePayId']: undefined,
        });
      }
    }
  };

  return (
    <Card
      onMouseLeave={handleMouseLeave}
      title={<FormattedMessage id="userMgntUsersDetailTitleBasePay" defaultMessage="Base Pay" />}
      extra={
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A2') ? (
                checkPathUrl === true ? null : (
                  <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModal({}, 'add')}>
                    <FormattedMessage id="userMgntUsersDetailBtnAssignNew" defaultMessage="Assign" />
                  </Button01>
                )
              ) : null}
            </div>
          )}
        </PageSettings.Consumer>
      }
    >
      {show}
      <Modal
        visible={stateModal}
        onOk={handleSave}
        onCancel={handleCancel}
        // okText={intl.formatMessage({ id: 'userDetailModalBtnSave', defaultMessage: 'Save' })}
        // cancelText={intl.formatMessage({ id: 'userDetailModalBtnCancel', defaultMessage: 'Cancel' })}
        className="button-modal"
        title={
          _.get(dataForm, 'action') === 'edit' ? (
            <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557' }}>
              <FormattedMessage id="userMgntUsersDetailTitleEditBasePay" defaultMessage="Edit Base Pay" />
            </Title>
          ) : (
            <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557' }}>
              <FormattedMessage id="userMgntUsersDetailTitleBasePay" defaultMessage="Base Pay" />
            </Title>
          )
        }
        footer={[
          <Button02
            style={{ margin: '0px 0px 0px 10px', fontSize: '13px' }}
            className="btn-style-new"
            key="back"
            fontsize="sm"
            btnsize="wd_df"
            onClick={handleCancel}
          >
            <FormattedMessage id="btnCloseBasePay" defaultMessage="Close" />
          </Button02>,
          <Button01
            style={{ fontSize: '13px' }}
            className="btn-style-new2"
            key="submit"
            fontsize="sm"
            btnsize="wd_df"
            type="primary"
            onClick={handleSave}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'modalBasePayFormItem', defaultMessage: 'Item' })} req={true} />}
          >
            {getFieldDecorator('basePayId', {
              initialValue: _.get(dataForm, 'basePayId'),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'modalBasePayFormValidateItem',
                    defaultMessage: 'Please Select Item',
                  }),
                },
              ],
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'modalBasePayFormPlaceItem', defaultMessage: 'Select Item' })}
                className="user-management-role-select"
                style={{ width: '100%' }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <PageSettings.Consumer>
                      {({ checkPermissionAction }) => (
                        <>
                          {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A5') ? (
                            <div>
                              <Divider style={{ margin: '4px 0' }} />
                              <div
                                style={{ padding: '4px 8px', cursor: 'pointer' }}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => {
                                  setVisibleModalAdd(true);
                                }}
                              >
                                <Icon type="plus" /> <FormattedMessage id="modalBasePayFormItemAddItem" defaultMessage="Add Item" />
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                    </PageSettings.Consumer>
                  </div>
                )}
              >
                {option}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'modalBasePayFormAmount', defaultMessage: 'Amount' })} req={false} />}
          >
            {getFieldDecorator('amount', {
              initialValue: _.get(dataForm, 'amount', 0.0),
            })(
              <InputNumber
                className="disabled-handle-count"
                placeholder={intl.formatMessage({ id: 'modalBasePayFormPlaceAmount', defaultMessage: 'Enter Amount' })}
                suffix="THB"
                onKeyDown={handleKeyDownDecimal2Fixed}
                formatter={currencyFormatter}
                parser={currencyParser}
                // ref={refPrice.inputNumberPrice1}
              />
            )}
            <SuffixInput text="THB" />
          </Form.Item>
        </Form>
      </Modal>

      <ModalSelectItemPayDeduct
        visible={visibleModalAdd}
        setVisble={setVisibleModalAdd}
        menuCode={'basePay'}
        // setItem={setItem}
        onSelectItem={onSelectItem}
        setTrigger={setToggleListItem}
        onCheckItem={onCheckItem}
      />
    </Card>
  );
};

const ModalBasePayForm = Form.create({
  name: 'global_state',
})(BasePay);

export default ModalBasePayForm;
