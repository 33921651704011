import React, { useEffect, useState } from 'react';
import './css/index.css';
import { Modal, Row, Col, Spin, Form } from 'antd';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { FormattedMessage, useIntl } from 'react-intl';
import Detail from './detail';
import Map from './map';
import { CompanyLocation } from './context';
import _, { debounce } from 'lodash';
import searchLocation from '../../controllers/location/serch-location';
import { v4 as uuidv4 } from 'uuid';
import { getSearchLocation } from '../../controllers/support-service/api';
import { errorNotification, successNotification } from '../v2/notification';
import { deletedToAddress } from '../../controllers/task/create-task';
import AddAddressBookV2 from '../modal-create-adress-map-v5/index';
import EditAddressBook from '../modal-edit-adress-map-v5/index';
import { useAppContext } from '../../includes/indexProvider';
import FormAddress from '../form-address';
import httpClient from '../axiosClient';
import { tryStatement } from 'babel-types';

const comCode = localStorage.getItem('comCode');

const FormCustomerLocation = (props) => {
  const {
    visible,
    setVisible,
    selectAddress,
    setSelectAddress,
    setCreateFromData,
    fromOutSide,
    setFromOutSide,
    trigger,
    setTrigger,
    visibleAdd,
    setVisibleAdd,
    statusFromAdd,
    setStatuFromsAdd,
    customerId,
    customerName,
    keyMenu,
    setKeyMenu,
    toLocationData,
    createToData,
    center,
    setCenter,
    position,
    setPosition,
    visibleInfoWindow,
    setVisibleInfoWindow,
    createFromData,
    form,
  } = props;
  const { validateFields, setFieldsValue } = form;
  const intl = useIntl();
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany');
  const [addressData, setAddressData] = useState([]);
  const [dragMarker, setDragMarker] = useState();
  const [loading, setLoading] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [fromLocationAdd, setFromLocationAdd] = useState();
  const [fromLocationEdit, setFromLocationEdit] = useState();
  const [searchValue, setSearchValue] = useState('');

  const handleCreate = (data) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = {
        ...data,
        name: _.get(values, 'locationName'),
        address: _.get(values, 'address'),
        fullAddress: _.get(data, 'fullAddress'),
      };
      setSelectAddress([
        {
          ...data,
          name: _.get(values, 'locationName'),
          address: _.get(values, 'address'),
          fullAddress: _.get(data, 'fullAddress'),
        },
      ]);
      setCreateFromData(body);
      setVisible(false);
      setAddressData([]);
      form.resetFields();
    });
  };

  const hanldeCancel = () => {
    setVisible(false);
    setAddressData([]);
  };

  useEffect(() => {
    if (visible) {
      handleSearchLocation();
    }
  }, [trigger, _.get(toLocationData, '[0].addressBookId'), customerId, visible]);

  const handleSearchLocation = debounce(async (fieldChang) => {
    setLoading(true);
    let newData = await searchLocation(customerId, fieldChang);
    // let newData = await searchLocation(fieldChang);
    setSearchValue(fieldChang);
    const mapData = _.get(newData, 'data')
      ? _.get(newData, 'data').map((item) => {
          return {
            address: item.address,
            fullAddress: item.fullAddress,
            addressBookId: item.addressBookId === '' ? uuidv4() : item.addressBookId,
            lat: item.lat,
            lng: item.lng,
            name: item.name,
            status: item.addressBookId === '' ? 'normal_address' : 'customer_address',
          };
        })
      : [];
    if (_.get(toLocationData, '[0].addressBookId')) {
      setAddressData(toLocationData.concat(mapData));
    } else {
      setAddressData(mapData);
    }
    // }
    setLoading(false);
  }, 200);

  useEffect(() => {
    if (_.size(selectAddress) > 0) {
      setDragMarker(_.get(selectAddress, '[0].addressBookId'));
      setKeyMenu([_.get(selectAddress, '[0].addressBookId')]);
    }
  }, []);

  const handleClickMenu = (props) => {
    setKeyMenu(props.selectedKeys);
    const filter =
      addressData &&
      addressData.filter((item) => {
        return item.addressBookId === props.key;
      });

    const mapData = filter.map((o) => {
      return {
        ...o,
        fullAddress: o.fullAddress,
      };
    });
    if (_.size(mapData) !== 0) {
      setSelectAddress(mapData);
      setCenter({
        lat: parseFloat(_.get(mapData, '[0].lat')),
        lng: parseFloat(_.get(mapData, '[0].lng')),
      });
      setPosition({
        lat: parseFloat(_.get(mapData, '[0].lat')),
        lng: parseFloat(_.get(mapData, '[0].lng')),
      });

      setTimeout(() => {
        setDragMarker(_.get(mapData, '[0].addressBookId'));
        setVisibleInfoWindow(true);
      }, 500);
    }
  };

  const onClickMarker = (id) => {
    setDragMarker(id);
    setVisibleInfoWindow(true);
  };

  const onCloseMarker = () => {
    setDragMarker();
    setVisibleInfoWindow(false);
  };

  const onDragEnd = async (evt) => {
    let area = await getSearchLocation({ location: evt.latLng });
    if (area) {
      const uuid = uuidv4();
      setSelectAddress([
        {
          address: area.address,
          fullAddress: area.address,
          lat: area.lat,
          lng: area.lng,
          name: area.name,
          addressBookId: uuid,
          status: 'normal_address',
        },
      ]);
      setCenter({
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      });
      setPosition({
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      });
      setVisibleInfoWindow(true);
      setDragMarker(uuid);
      // setKeyMenu([]);
      form.resetFields();
    }
  };

  useEffect(() => {
    // if (navigator.geolocation && visible === true && _.size(selectAddress) === 0) {
    //   navigator.geolocation.getCurrentPosition(positionMyself, errorNavigator);
    // }
    if (visible === true && _.size(selectAddress) === 0) {
      errorNavigator()
    }
  }, [visible, trigger, selectAddress]);

  // const positionMyself = async (position) => {
  //   setLoading(true)
  //   let area = await getSearchLocation({ Default: position });
  //   if (area) {
  //     const uuid = uuidv4()
  //     setSelectAddress([{
  //       address: area.address,
  //       lat: area.lat,
  //       lng: area.lng,
  //       name: area.name,
  //       addressBookId: uuid,
  //       status: "normal_address"
  //     }]);

  //     setCenter(
  //       {
  //         lat: parseFloat(_.get(area, 'lat')),
  //         lng: parseFloat(_.get(area, 'lng')),
  //       }
  //     )
  //     setPosition(
  //       {
  //         lat: parseFloat(_.get(area, 'lat')),
  //         lng: parseFloat(_.get(area, 'lng')),
  //       }
  //     )
  //     setVisibleInfoWindow(true)
  //     setDragMarker(uuid)
  //     setKeyMenu([])
  //     setLoading(false)
  //   }
  // }

  const errorNavigator = async () => {
    setLoading(true);
    let area = await getSearchLocation({ latlngPure: latlng });
    if (area) {
      const uuid = uuidv4();
      setSelectAddress([
        {
          address: area.address,
          fullAddress: area.address,
          lat: area.lat,
          lng: area.lng,
          name: area.name,
          addressBookId: uuid,
          status: 'normal_address',
        },
      ]);

      setCenter({
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      });
      setPosition({
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      });
      setVisibleInfoWindow(true);
      setDragMarker(uuid);
      setKeyMenu([]);
    }
    setLoading(false);
  };

  console.log('sideeeeee', fromLocationAdd, fromLocationEdit, fromOutSide, selectAddress);

  useEffect(() => {
    if (fromLocationAdd) {
      console.log('fromLocationAdd', fromLocationAdd);
      setSelectAddress([
        {
          address: _.get(fromLocationAdd, 'address'),
          fullAddress: _.get(fromLocationAdd, 'address'),
          lat: parseFloat(_.get(fromLocationAdd, 'lat')),
          lng: parseFloat(_.get(fromLocationAdd, 'lng')),
          name: _.get(fromLocationAdd, 'addressName'),
          addressBookId: _.get(fromLocationAdd, 'customerAddressBookId'),
          status: 'customer_address',
        },
      ]);
      setCenter({
        lat: parseFloat(_.get(fromLocationAdd, 'lat')),
        lng: parseFloat(_.get(fromLocationAdd, 'lng')),
      });
      setPosition({
        lat: parseFloat(_.get(fromLocationAdd, 'lat')),
        lng: parseFloat(_.get(fromLocationAdd, 'lng')),
      });
      console.log('fromLocationAdd', fromLocationAdd);
      setKeyMenu(_.get(fromLocationAdd, 'customerAddressBookId'));

      setTimeout(() => {
        setDragMarker(_.get(fromLocationAdd, 'customerAddressBookId'));
        setVisibleInfoWindow(true);
      }, 500);

      setFromLocationAdd();
      setSearchValue('');
    }
  }, [_.get(fromLocationAdd, 'customerAddressBookId')]);

  useEffect(() => {
    if (fromLocationEdit) {
      setSelectAddress([
        {
          address: _.get(fromLocationEdit, 'address'),
          fullAddress: _.get(fromLocationAdd, 'address'),
          lat: parseFloat(_.get(fromLocationEdit, 'lat')),
          lng: parseFloat(_.get(fromLocationEdit, 'lng')),
          name: _.get(fromLocationEdit, 'addressName'),
          addressBookId: _.get(fromLocationEdit, 'customerAddressBookId'),
          status: 'customer_address',
        },
      ]);
      setCenter({
        lat: parseFloat(_.get(fromLocationEdit, 'lat')),
        lng: parseFloat(_.get(fromLocationEdit, 'lng')),
      });
      setPosition({
        lat: parseFloat(_.get(fromLocationEdit, 'lat')),
        lng: parseFloat(_.get(fromLocationEdit, 'lng')),
      });
      setKeyMenu(_.get(fromLocationEdit, 'customerAddressBookId'));

      setTimeout(() => {
        setDragMarker(_.get(fromLocationEdit, 'customerAddressBookId'));
        setVisibleInfoWindow(true);
      }, 500);

      setFromLocationEdit();
      setSearchValue('');
    }
  }, [_.get(fromLocationEdit, 'customerAddressBookId')]);

  useEffect(() => {
    if (fromOutSide) {
      setSelectAddress([
        {
          address: _.get(fromOutSide, 'address'),
          fullAddress: _.get(fromOutSide, 'address'),
          lat: parseFloat(_.get(fromOutSide, 'lat')),
          lng: parseFloat(_.get(fromOutSide, 'lng')),
          name: _.get(fromOutSide, 'addressName'),
          addressBookId: _.get(fromOutSide, 'customerAddressBookId'),
          status: 'customer_address',
        },
      ]);
      setCenter({
        lat: parseFloat(_.get(fromOutSide, 'lat')),
        lng: parseFloat(_.get(fromOutSide, 'lng')),
      });
      setPosition({
        lat: parseFloat(_.get(fromOutSide, 'lat')),
        lng: parseFloat(_.get(fromOutSide, 'lng')),
      });
      setKeyMenu(_.get(fromOutSide, 'customerAddressBookId'));

      setTimeout(() => {
        setDragMarker(_.get(fromOutSide, 'customerAddressBookId'));
        setVisibleInfoWindow(true);
      }, 1000);

      setFromOutSide();
      setSearchValue('');
    }
  }, [_.get(fromOutSide, 'customerAddressBookId')]);

  const handleOpenEdit = (data) => {
    setVisibleEdit(true);
  };

  const deletedAddress = async (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'companyProfileDeleteAddress', defaultMessage: 'Are you sure to delete address ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deletedToAddress(record.addressBookId);
          if (_.size(response) !== 0) {
            successNotification(response.status.message);
            setKeyMenu([]);
            setSelectAddress([]);
            setTrigger((curent) => !curent);
            if (_.get(createToData, 'addressBookId') === record.addressBookId) {
              setCreateFromData();
            }
          }
        } catch (error) {
          return;
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const handleCreateRefresh = () => {
    // handleSearchLocation('');
    setTrigger(true);
    setSelectAddress([]);
    setKeyMenu([]);
  };

  const handleSaveeEditLocation = async (values) => {
    console.log('handleSaveeEditLocation', values);
    if (_.get(keyMenu, '[0]') && _.get(values, 'status') === 'customer_address') {
      Modal.confirm({
        className: 'customer-modal-confirm',
        centered: true,
        icon: null,
        title: intl.formatMessage({ id: 'companyProfileEditAddress', defaultMessage: 'Are you sure to edit an address ?' }),
        okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        async onOk() {
          try {
            const valuex = await form.validateFields();
            const body = {
              customerId: customerId,
              customerName: customerName,
              contactName: undefined,
              addressName: valuex.locationName,
              fullAddress: values.address,
              address: valuex.address,
              subDistrict: undefined,
              district: undefined,
              province: undefined,
              postCode: undefined,
              lat: parseFloat(values.lat),
              lng: parseFloat(values.lng),
              serviceTime: [],
            };
            console.log('handleSaveeEditLocation', valuex, values);

            const response = await httpClient.put(
              `/v3/business-partner/manager/company/${comCode}/customer-address-books/${_.get(keyMenu, '[0]')}`,
              body
            );
            console.log('handleSaveeEditLocation', valuex, values, response);

            if (response.status == 200) {
              if (setTrigger) {
                setTrigger((current) => !current);
              }

              setSelectAddress([
                {
                  address: _.get(response, 'data.data.address'),
                  fullAddress: _.get(response, 'data.data.fullAddress'),
                  lat: parseFloat(_.get(response, 'data.data.lat')),
                  lng: parseFloat(_.get(response, 'data.data.lng')),
                  name: _.get(response, 'data.data.addressName'),
                  addressBookId: _.get(response, 'data.data.customerAddressBookId'),
                  status: 'customer_address',
                },
              ]);

              setPosition({
                lat: _.get(response, 'data.data.lat'),
                lng: _.get(response, 'data.data.lng'),
              });
              setCenter({
                lat: _.get(response, 'data.data.lat'),
                lng: _.get(response, 'data.data.lng'),
              });

              setTimeout(() => {
                setDragMarker(_.get(response, 'data.data.customerAddressBookId'));
                setVisibleInfoWindow(true);
              }, 500);
            }
          } catch (error) {
            console.log('handleSaveeEditLocation error', error);
            return;
          }
        },
        onCancel() {
          return;
        },
      });
    } else {
      Modal.confirm({
        className: 'customer-modal-confirm',
        centered: true,
        icon: null,
        title: intl.formatMessage({ id: 'companyProfileSaveAddress', defaultMessage: 'Are you sure to save an address ?' }),
        okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        async onOk() {
          try {
            const valuex = await form.validateFields();
            const body = {
              customerId: customerId,
              customerName: customerName,
              contactName: undefined,
              addressName: valuex.locationName,
              fullAddress: values.address,
              address: valuex.address,
              subDistrict: undefined,
              district: undefined,
              province: undefined,
              postCode: undefined,
              lat: parseFloat(values.lat),
              lng: parseFloat(values.lng),
              serviceTime: [],
            };
            console.log('handleSaveeEditLocation', valuex, values);

            const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-address-books`, body);
            console.log('handleSaveeEditLocation', valuex, values, response);

            if (response.status == 200) {
              if (setTrigger) {
                setTrigger((current) => !current);
              }

              setSelectAddress([
                {
                  address: _.get(response, 'data.data.address'),
                  fullAddress: _.get(response, 'data.data.fullAddress'),
                  lat: parseFloat(_.get(response, 'data.data.lat')),
                  lng: parseFloat(_.get(response, 'data.data.lng')),
                  name: _.get(response, 'data.data.addressName'),
                  addressBookId: _.get(response, 'data.data.customerAddressBookId'),
                  status: 'customer_address',
                },
              ]);

              setPosition({
                lat: _.get(response, 'data.data.lat'),
                lng: _.get(response, 'data.data.lng'),
              });
              setCenter({
                lat: _.get(response, 'data.data.lat'),
                lng: _.get(response, 'data.data.lng'),
              });

              setTimeout(() => {
                setDragMarker(_.get(response, 'data.data.customerAddressBookId'));
                setVisibleInfoWindow(true);
              }, 500);
            }
          } catch (error) {
            console.log('handleSaveeEditLocation error', error);
            return;
          }
        },
        onCancel() {
          return;
        },
      });
    }
  };

  console.log('checkaddresssss', _.size(selectAddress), _.get(selectAddress, '[0].status'), customerId);
  const footer =
    _.size(selectAddress) > 0 && _.get(selectAddress, '[0].status') === 'normal_address' && customerId
      ? [
          <Button02 style={{ margin: 'unset' }} key="back" btnsize="wd_df" onClick={() => hanldeCancel()} margin="unset">
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01
            key="save"
            type="primary"
            btnsize="wd_ct"
            style={{ margin: '0 8px 0 8px' }}
            onClick={() => {
              handleSaveeEditLocation(_.get(selectAddress, '[0]'));
              // handleCreateAddressBookId(item)
              // setStatuFromsAdd('in');
              // setVisibleAdd(true);
            }}
          >
            <FormattedMessage id="btnSaveAddress" key="save" defaultMessage="Save Address" />
          </Button01>,
          <Button01
            key="submit"
            type="primary"
            btnsize="wd_at"
            style={{ margin: 'unset' }}
            onClick={() => handleCreate(_.get(selectAddress, '[0]'))}
          >
            <FormattedMessage id="btnSelect" defaultMessage="Select" />
          </Button01>,
        ]
      : _.size(selectAddress) > 0 && _.get(selectAddress, '[0].status') === 'customer_address' && customerId
      ? [
          <Button02 style={{ margin: 'unset' }} key="back" btnsize="wd_df" onClick={() => hanldeCancel()} margin="unset">
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01
            key="delete"
            type="primary"
            btnsize="wd_at"
            style={{ margin: '0 8px' }}
            onClick={() => deletedAddress(_.get(selectAddress, '[0]'))}
          >
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button01>,

          <Button01
            key="save"
            type="primary"
            btnsize="wd_ct"
            style={{ margin: '0 8px 0 0' }}
            onClick={() => {
              handleSaveeEditLocation(_.get(selectAddress, '[0]'));
              // handleCreateAddressBookId(item)
              // setStatuFromsAdd('in');
              // setVisibleAdd(true);
            }}
          >
            <FormattedMessage id="btnSaveAddress" defaultMessage="Save Address" />
          </Button01>,
          <Button01
            key="submit"
            type="primary"
            btnsize="wd_at"
            style={{ margin: 'unset' }}
            onClick={() => handleCreate(_.get(selectAddress, '[0]'))}
          >
            <FormattedMessage id="btnSelect" defaultMessage="Select" />
          </Button01>,
        ]
      : [
          <Button02 style={{ margin: 'unset' }} key="back" btnsize="wd_df" onClick={() => hanldeCancel()}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01
            key="submit"
            type="primary"
            btnsize="wd_at"
            style={{ margin: '0 0 0 8px' }}
            onClick={() => handleCreate(_.get(selectAddress, '[0]'))}
          >
            <FormattedMessage id="btnSelect" defaultMessage="Select" />
          </Button01>,
        ];

  console.log('dragMarker', dragMarker);

  return (
    <CompanyLocation.Provider
      value={{
        companyState: {
          addressData,
          position,
          center,
          dragMarker,
          visibleInfoWindow,
          selectAddress,
          keyMenu,
          searchValue,
          customerId,
        },
        companySetState: {
          setSelectAddress,
          setVisibleAdd,
          setStatuFromsAdd,
          setSearchValue,
        },
        companyFNC: {
          handleSearchLocation,
          handleClickMenu,
          onClickMarker,
          onDragEnd,
          onCloseMarker,
          handleOpenEdit,
          deletedAddress,
          handleCreate,
          handleCreateRefresh,
          handleSaveeEditLocation,
        },
      }}
    >
      <Form>
        <Modal
          title={intl.formatMessage({ id: 'modalSelectAddressTextHeader', defaultMessage: 'Select Location' })}
          width={1200}
          centered={true}
          onCancel={hanldeCancel}
          visible={visible}
          bodyStyle={{ padding: 'unset' }}
          footer={footer}
        >
          <Spin spinning={loading}>
            <Row>
              <Col span={8}>
                <Detail />
              </Col>
              <Col span={16}>
                <div className="location-company-map-padding">
                  <FormAddress form={form} address={_.get(selectAddress, '[0]')} />
                  <Map />
                </div>
              </Col>
            </Row>
          </Spin>
        </Modal>

        <AddAddressBookV2
          customerId={customerId}
          customerName={customerName}
          visible={visibleAdd}
          setVisible={setVisibleAdd}
          triggerAPI={handleSearchLocation}
          selectAddress={selectAddress}
          toLocation={setFromLocationAdd}
          statusFromAdd={statusFromAdd}
          setCreateFromData={setCreateFromData}
        />

        <EditAddressBook
          customerId={customerId}
          customerName={customerName}
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          triggerAPI={handleSearchLocation}
          selectAddress={selectAddress}
          toLocation={setFromLocationEdit}
        />
      </Form>
    </CompanyLocation.Provider>
  );
};

const ModalSelectCustomerLocation = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {},
})(FormCustomerLocation);

export default ModalSelectCustomerLocation;
