import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Switch, Row } from 'antd';
import { useRoleContext } from './RoleContext';
import Button01 from '../../components/v2/button_01';
import { notificationWithIcon } from '../../components/notification';
import httpClient from '../../components/axiosClient';
import Form from './Form';
import RoleMember from './RoleMember';
import { FormattedMessage } from 'react-intl';
import cssStyle from './css/Role.css';
import _ from 'lodash';


export default (props) => {
  const app = useRoleContext();
  const [roleName, setRoleName] = useState();
  const formRef = useRef();




  useEffect(() => {
    if (props.addNewRole === true) {
      // props.setCheckSwitch(false);
    } else {
      if (app.state.roleTracking === 0) {
        app.fnc.setAlamRoleTracking(false)
      }
      else if (app.state.roleTracking === 1) {
        app.fnc.setAlamRoleTracking(true)
      }
      if (app.state.alamRequeset == 0) {
        app.fnc.setAlamRequestSwitch(false)
      }
      else if (app.state.alamRequeset == 1) {
        app.fnc.setAlamRequestSwitch(true)
      }
      if (app.state.alamTask == 0) {
        app.fnc.setAlamTaskSwitch(false)
      }
      else if (app.state.alamTask == 1) {
        app.fnc.setAlamTaskSwitch(true)
      }
    }
  }, [app.state.roleTracking, app.state.alamTask, app.state.alamRequest, app.state.rolesSelectName])


  /**
   * Handler submit
   */
  const handlerSave = async () => {
    formRef.current.validateFields().then(async (success) => {
      const data = {
        name: success.roleName,
        description: '',
        statusTrackLocation: success.roleTracking === true ? 1 : 0,
        alarmRequest: success.alarmRequest === true ? 1 : 0,
        alarmTask: success.alarmTask === true ? 1 : 0,
      };

      if (app.state.rolesSelect) {
        try {
          const response = await httpClient.put(`/company/${app.state.comId}/role/${app.state.rolesSelect}`, data);
          if (response.status === 200) {
            notificationWithIcon('success', response.data.message);
            app.fnc.setRolesSelectName(success.roleName);
            // setSwitchControl();
            // setSwitchAlamRequestControl();
            // setSwitchAlamTaskControl();
          }
          else {
            notificationWithIcon('error', response.data.message);
          }
        }
        catch (error) {
          notificationWithIcon('error', error.response.data.message);
        }
      }
      else {
        try {
          const response = await httpClient.post(`/company/${app.state.comId}/role`, data);
          if (response.status === 200) {
            notificationWithIcon('success', response.data.data);
            app.fnc.setRolesSelectName(success.roleName);
            app.fnc.setAlamRoleTracking(success.roleTracking)
            app.fnc.setAlamTaskSwitch(success.alarmTask)
            app.fnc.setAlamRequestSwitch(success.alarmRequest)
          }
          else {
            notificationWithIcon('error', response.data.message);
          }
        }
        catch (error) {
          notificationWithIcon('error', error.response.data.message);
        }
      }
    }).catch((error) => {
    });
  }

  return (
    <Card
      className={app.state.rolesSelectName ? null : 'card-padding'}
      title={app.state.rolesSelectName}
      bordered={false}
      extra={
        <Button01
          style={{ margin: '0px' }}
          type="primary"
          fontsize="md"
          onClick={handlerSave}
          btnsize="wd_df"
        >
          <FormattedMessage id="UserManagementRoleBtnSave" defaultMessage="Save" />
        </Button01>
      }
    >
      <div className="role-content-body">
        <Row className='role-padding-style'>
          <Col span={24}>
            <Form
              ref={formRef}
              roleName={app.state.rolesSelectName}
              alamRoleTracking={app.state.alamRoleTracking}
              alamRequestSwitch={app.state.alamRequestSwitch}
              alamTaskSwitch={app.state.alamTaskSwitch}

            />
          </Col>
          {/* <Col span={5}>
            <div style={{ paddingTop: '23px' }}>
              <span style={{ marginRight: '16px' }}><FormattedMessage id="UserManagementRoleTrackingText" defaultMessage="Tracking Location" /></span>
              <Switch
                onChange={(e) => handelOnchange(e)}
                // defaultChecked={app.state.roleTracking == 0 ? true : false}
                checked={app.state.alamRoleTracking}
              />
            </div>
          </Col>

          <Col span={5}>
            <div style={{ paddingTop: '23px' }}>
              <span style={{ marginRight: '16px' }}><FormattedMessage id="UserManagementRoleAlarmRequestText" defaultMessage="Alarm Request" /></span>
              <Switch
                onChange={(e) => handelOnchangeAlsmRequest(e)}
                checked={app.state.alamRequestSwitch}
              />
            </div>
          </Col>

          <Col span={5} classname="div-padding-style">
            <div style={{ paddingTop: '23px' }}>
              <span style={{ marginRight: '16px' }}><FormattedMessage id="UserManagementRoleAlarmTaskText" defaultMessage="Alarm Task" /></span>
              <Switch
                onChange={(e) => handelOnchangeAlsmTask(e)}
                checked={app.state.alamTaskSwitch}
              />
            </div>

          </Col> */}
        </Row>
        <RoleMember />
      </div>
    </Card>
  );
}