import React from 'react';

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.4 32">
    <linearGradient
      id="Path_6214_00000098207929209033982460000015922659508923494561_"
      gradientUnits="userSpaceOnUse"
      x1="-235.5767"
      y1="139.772"
      x2="-236.2587"
      y2="139.041"
      gradientTransform="matrix(36.1431 0 0 -27.3379 8556.042 3833.1084)"
    >
      <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
      <stop offset="1" style={{ stopColor: '#443C3C' }} />
    </linearGradient>
    <path
      style={{ fill: `url(#Path_6214_00000098207929209033982460000015922659508923494561_)` }}
      d="M16.3,31.6l36.1-12.3l-21-15.1L16.3,31.6z"
    />
    <g text-anchor="middle">
      <path
        fill={props.color}
        d="M16,0C9.6,0,4.4,5.2,4.4,11.6c0,7.9,10.4,19.6,10.8,20.1c0.4,0.4,1,0.5,1.5,0.1
      c0,0,0.1-0.1,0.1-0.1c0.4-0.5,10.8-12.1,10.8-20.1C27.6,5.2,22.4,0,16,0z M16,17.4c-3.2,0-5.8-2.6-5.8-5.8s2.6-5.8,5.8-5.8
      c3.2,0,5.8,2.6,5.8,5.8v0C21.8,14.8,19.2,17.4,16,17.4z"
      />
      <circle cx="16" cy="12" r="10" fill="white" />
      <g node-id="itemdat-1" style={{ opacity: 1 }} transform="matrix(1,0,0,1,-170,115)" class="node" level="1" />
      <text x="16" y="15" style={{ height: 65, fontSize: 12 }} id="text-label" fill="black">
        {props.text}
        <g data-edit-id-filed="itemdat-1" transform="matrix(1,0,0,1,130,5)" />
      </text>
    </g>
  </svg>
);

export default SvgComponent;
