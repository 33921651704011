import React from 'react';
import _ from 'lodash';
import './css/index.css';
import NumberFormat from 'react-number-format';
import {FormattedMessage } from 'react-intl';

export default ({ data }) => {
  const quotationNo = _.get(data, 'quotationNo') || '-';
  const totalBath = _.get(data, 'totalBath') ? _.get(data, 'totalBath') : 0;
  const itemSize = _.size(data.detail) ? _.size(data.detail) : 0;

  return (
    <div className='view-detail'>
      <div className='view-detail-data'>
      <FormattedMessage id="ModalViewQuotationDetailQuotationNo" defaultMessage="Quotation No" />: {quotationNo}, {itemSize} <FormattedMessage id="ModalViewQuotationDetailItem" defaultMessage="Item" />, <FormattedMessage id="ModalViewQuotationDetailTotal" defaultMessage="Total" />  <NumberFormat value={_.floor(totalBath, 2).toFixed(2)} displayType={'text'} thousandSeparator={true} /> <FormattedMessage id="ModalViewQuotationDetailBaht" defaultMessage="Baht" />,
      </div>
    </div>
  )
}