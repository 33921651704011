import React, { useState, useEffect, useContext } from 'react';
import { Table, Col, Row, Form, Select, DatePicker, Input,} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { FineContext } from './finecontext';
import Button_01 from '../../components/v2/button_01';
const { Option } = Select;
const InputGroup = Input.Group;
const { RangePicker } = DatePicker;
const dateFormat = 'ddd, MMM DD YYYY';

const Fillter = (props) => {
  const { form } = props;
  const { state, setState, fnc } = useContext(FineContext);
  const { searchGroup,reason } = state;
  const { setSearchGroup } = setState;
  const {} = fnc;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const [isFilter, setIsFilter] = useState(false);
  const [rangeDate, setRangeDate] = useState(false);
  const paddingFillter = '2px 26px 0px 16px';

  console.log('resonfine',reason);

  const handleOpenFilter = () => {
    setIsFilter(!isFilter);
    setSearchGroup([]);
  };

  // const handleserachAllowance = async () => {
  //   setIsLoading(true);
  //   try {
  //     let payload = {
  //       taskTypeId: getFieldValue('tasktype'),
  //       sourceId: getFieldValue('source'),
  //       destinationId: getFieldValue('destination'),
  //       distanceFrom: searchGroup.From || 0,
  //       distanceTo: searchGroup.to || 1000,
  //       pageNumber: page,
  //       limit: size,
  //       orderBy: '',
  //       orderType: '',
  //     };
  //     console.log('testdatacom', payload);
  //     const response = await searchAllowanceList(payload);
  //     if (response.status.code === 200) {
  //       setIsLoading(false);
  //       setAllowanceListLeft(response.data);
  //     }
  //   } catch (error) {}
  // };

  const onChange = async (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const clearValue = async (e, code) => {
    if (e === undefined) {
      setSearchGroup({ ...searchGroup, [code]: '' });
    }
  };

  const handleChangeDate = (value) => {
    console.log('finedate',value);
    setRangeDate(value);
    setSearchGroup({ ...searchGroup, ['start']: value[0],['end']: value[1] });
  };

  return (
    <div>
      {/* <div style={{ padding: '16px' }}>
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenFilter()}>
          <FormattedMessage id="assetpreventiveFillter" defaultMessage="Filter" />{' '}
          <DownOutlined style={{ fontSize: '12px', paddingLeft: '12%' }} />
        </Button_01>
      </div>
      {isFilter === true && ( */}
        <div className="filterBackground">
          <Form>
            <Row gutter={24}>
              <Col span={24} style={{ padding: paddingFillter }}>
                <Form.Item>
                  <span >
                    <FormattedMessage id="FineNo" defaultMessage="Fine No." />
                  </span>
                  <Input
                    onChange={(e) => onChange(e.target.value, 'no')}
                    style={{ width: '100%' }}
                    allowClear
                    placeholder={intl.formatMessage({ id: 'FineEnterTaskType', defaultMessage: 'Enter Fine No.' })}
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ padding: paddingFillter }}>
                <Form.Item>
                  <span >
                    <FormattedMessage id="FineEmployee" defaultMessage="Employee" />
                  </span>

                  <Input
                    onChange={(e) => onChange(e.target.value, 'employee')}
                    style={{ width: '100%' }}
                    allowClear
                    placeholder={intl.formatMessage({ id: 'FineEnterEmployee', defaultMessage: 'Enter Employee' })}
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ padding: paddingFillter }}>
                <Form.Item>
                  <span>
                    <FormattedMessage id="FineAmount" defaultMessage="Amount" />
                  </span>

                  <InputGroup compact>
                    <Row>
                      <Col span={11}>
                        <Input
                          className="warehouseMovementInput1"
                          style={{ textAlign: 'center' }}
                          allowClear
                          // value={_.get(searchGroup, 'min')}
                          onChange={(e) => onChange(e.target.value, 'min')}
                          placeholder={intl.formatMessage({ id: 'FineEnterAmountMin', defaultMessage: 'Enter Minimum' })}
                        />
                      </Col>

                      <Col span={2}>
                        <Input
                          className="warehouseMovementInputComma"
                          style={{
                            pointerEvents: 'none',
                            backgroundColor: '#fff',
                          }}
                          placeholder="~"
                          disabled
                        />
                      </Col>
                      <Col span={11}>
                        <Input
                          className="warehouseMovementInput2"
                          style={{ textAlign: 'center' }}
                          allowClear
                          // value={_.get(searchGroup, 'max')}
                          onChange={(e) => onChange(e.target.value, 'max')}
                          placeholder={intl.formatMessage({ id: 'FineEnterAmountMax', defaultMessage: 'Enter Maximum' })}
                        />
                      </Col>
                    </Row>
                  </InputGroup>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} style={{ padding: paddingFillter }}>
                <Form.Item>
                  <span >
                    <FormattedMessage id="FineDate" defaultMessage="Fine Date" />
                  </span>
                  <RangePicker
                      style={{ width: '100%' }}
                      onChange={handleChangeDate}
                      // onCalendarChange={(e) => onChange}
                      defaultValue={rangeDate}
                      placeholder={[
                        intl.formatMessage({
                          id: 'FinePlaceholderDateFrom',
                          defaultMessage: 'Select From Date',
                        }),
                        intl.formatMessage({
                          id: 'FinePlaceholderDateTo',
                          defaultMessage: 'Select To Date',
                        }),
                      ]}
                      format={dateFormat}
                      allowClear
                    />
                </Form.Item>
              </Col>
              <Col span={24} style={{ padding: '2px 26px 0px 16px ' }}>
                <Form.Item>
                  <span >
                    <FormattedMessage id="FineReason" defaultMessage="Reason" />
                  </span>
                  <Select
                    allowClear
                    onSelect={(e) => onChange(e, 'reason')}
                    style={{ width: '100%' }}
                    onChange={(e) => clearValue(e, 'reason')}
                    placeholder={intl.formatMessage({ id: 'FineSelectReason', defaultMessage: 'Select Reason' })}
                  >
                    {reason &&
                        _.map(reason, (item, index) => {
                          return (
                            <Option key={_.get(item, 'fineReasonId')} value={_.get(item, 'fineReasonId')}>
                              {_.get(item, 'fineReasonName')}
                            </Option>
                          );
                        })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      
    </div>
  );
};

const FineFillter = Form.create({
  name: 'Allowance_filter_form',
  mapPropsToFields() {},
})(Fillter);

export default FineFillter;
