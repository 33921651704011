import React, { useEffect, useState } from 'react';
import { List, Avatar, Input, Icon } from 'antd';
import _ from 'lodash';
import './css/member.css'
import { useIntl } from 'react-intl';
import HTTPClient from '../../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

export default React.memo((props) => {
  const { onClick, mainOrgIdArr } = props;
  const intl = useIntl();
  const [members, setMembers] = useState([]);
  const [membersView, setMembersView] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const docMember = {
          orgIdList: _.map(mainOrgIdArr, (orgId) => { return _.toString(orgId); })
        }
        const getMember = await HTTPClient.post(`/v4/resource/manager/company/${comCode}/task-map/member/organization`, docMember);
        const countMember = _.get(getMember, 'data.data.result.count');
        const listMember = _.get(getMember, 'data.data.result.list');
        if(countMember && countMember > 0) {
          const newMember = _.map(listMember, (m) => { 
            return {
              memComId: _.toInteger(_.get(m, 'mem_com_id')),
              pathImage: _.get(m, 'profile'),
              fullname: _.get(m, 'name'),
              phone: _.get(m, 'phone'),
              lat: _.get(m, 'lat') || 0,
              lng: _.get(m, 'lng') || 0,
              currentLat: _.get(m, 'lat') || 0,
              currentLng: _.get(m, 'lng') || 0
            };
          });
          setMembers(newMember);
          setMembersView(newMember);
        }
        else {
          setMembers([]);
          setMembersView([]);
        }
      }
      catch(e) {
        setMembers([]);
        setMembersView([]);
      }
    }

    getData();
  }, [mainOrgIdArr]);
  
  const handleClickMember = (memComId) => _.isFunction(onClick) ? onClick(memComId) : null;

  const delayedQuery = _.debounce((value) => {
    const valueLowerCase = _.lowerCase(value);
    const search = _.filter(members, (o) => { return _.includes(_.lowerCase(o.fullname), valueLowerCase) || _.includes(_.lowerCase(o.phone), valueLowerCase); });
    setMembersView(search);
  }, 100);

  const handleSearchMember = (e) => {
    delayedQuery(e.target.value);
  }

  return (
    <div className='member-mapview-div'>
      <div className='member-mapview-input-padding'>
        <Input
          className="member-mapview-input"
          placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
          prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
          allowClear
          onChange={handleSearchMember}
          autoComplete="off"
        />
      </div>
      <div className='member-mapview-list-padding'>
        <List
          itemLayout='horizontal'
          dataSource={membersView}
          renderItem={item => (
            <List.Item
              onClick={() => handleClickMember(item)}
              style={{
                cursor: 'pointer'
              }}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.pathImage} />}
                title={item.fullname}
                description={item.phone}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}, (prev, next) => {
  // return _.isMatch(prev.mainOrgIdArr, next.mainOrgIdArr);
})