import { Card, Col, Modal, Row, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
// import LeftCardApproval from './left-card';
import { AllticketContext } from './allticket-context';
// import RightCardApproval from './right-card';
import _ from 'lodash';
import LeftCardAllTicket from './left-card';
import RightCardAllticket from './right-card';

import { FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings';
import { getBrandReportTicket, getModelReportTicket, getTicketData, getTicketDetail } from '../../../../controllers/ticket/ticket';
import Button_01 from '../../../../components/v2/button_01';
import { fncGetUsers } from '../../../../controllers/user/users';
import { getVendor } from '../../../../controllers/customer-vendor/customer-vendor';
import { getItemPOList, getStatusPurchase } from '../../../../controllers/receice-po/receive';

const ModalPurchase = (props) => {
  const { visibleTicket, closeTicketModal, setRequestDetailData, requestDetailData,vendorFilterdata, setVendorFilterdata,setLotNo } = props;
  const [paginationPage, setPaginationPage] = useState();
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [menuList, setMenuList] = useState([]);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [checkedKeysTeam, setCheckedKeysTeam] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [changeStatus, setChangeStatus] = useState([]);
  const [changeTab, setChangeTab] = useState('1');
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [ticketData, setTicketData] = useState([]);
  const [event, setEvent] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState();
  const [changeTicketNo, setChangeTicketNo] = useState('');
  const [changeVender, setChangeVender] = useState('');
  const [brandData, setBrandData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [totalBrand, setTotalBrand] = useState(10);
  const [totalModel, setTotalModel] = useState(10);
  const [vendorData, setVendorData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  useEffect(() => {
    async function getUser() {
      try {
        const body = {
          searchBy: '',
          searchVal: 'all',
          page: 1,
          limit: 10000,
        };
        const memComId = localStorage.getItem('memComId');
        const response = await fncGetUsers(memComId);
        const vendorResponse = await getVendor(body);
        setVendorData(vendorResponse.customers);
      } catch (error) {}
    }
    getUser();
  }, []);

  useEffect(() => {
    const getDataTable = async () => {
      const body = {
        customerId: _.get(fillter, 'vender') ? [_.get(fillter, 'vender')] : [],
        poNo: _.get(fillter, 'pono') ? _.get(fillter, 'pono') : undefined,
        statusCode: _.get(fillter, 'status') ? [_.get(fillter, 'status')] : [] ,
        fromPoDate: _.get(fillter, 'startdate') ? _.get(fillter, 'startdate') : undefined,
        toPoDate: _.get(fillter, 'enddate') ? _.get(fillter, 'enddate') : undefined,
        pageNumber: page,
        limit: size,
        orderBy: _.get(sort, 'field'),
        orderType: _.get(sort, 'order') === 'descend' ? 'desc' : 'asc',
      };
      console.log('bodyTicket', body);
      const responase = await getItemPOList(body);
      setTicketData(_.get(responase, 'data.data.poList'));
      setTotal(_.get(responase, 'data.data.totalItem'));
      setIsLoading(false);
    };
    if (visibleTicket === true) {
      setIsLoading(true);
      getDataTable();
      console.log('tikcetData', ticketData);
    }
  }, [event, checkedKeysTeam, page, size]);

  useEffect(() => {
    
    setFillter({ ...fillter, ['pono']: _.get(requestDetailData, 'poNo') });
    setFillter({ ...fillter, ['vender']: _.get(vendorFilterdata, 'vendorSupplierId') });
    setChangeTicketNo(_.get(requestDetailData, 'poNo'));
    setChangeVender(_.get(vendorFilterdata, 'vendorSupplierId'))
    setEvent((current) => !current);
    
  }, [requestDetailData, visibleTicket,vendorFilterdata]);

  useEffect(() => {

    async function getStatus() {

      const response = await getStatusPurchase();
      setStatusData(response.data.data.statusList);
    }
    getStatus();
  }, []);

  useEffect(() => {
    getBrand();
  }, [totalBrand]);

  useEffect(() => {
    if (_.get(fillter, 'brand') !== undefined) {
      getModel();
    }
  }, [totalModel, _.get(fillter, 'brand')]);

  const getBrand = async () => {
    const body = {
      indexStart: 0,
      indexEnd: totalBrand,
      searchName: '',
    };

    const responase = await getBrandReportTicket(body);
    console.log('brandData', responase.data);
    setBrandData(_.get(responase, 'data.data.list'));
    // setTotal(_.get(responase, 'data.data.totalItems'));
    setIsLoading(false);
  };

  const getModel = async () => {
    const body = {
      indexStart: 0,
      indexEnd: totalBrand,
      searchName: '',
      assetBrandId: [_.get(fillter, 'brand')],
    };

    const responase = await getModelReportTicket(body);
    console.log('ModelData', responase.data);
    setModelData(_.get(responase, 'data.data.list'));
    // setTotal(_.get(responase, 'data.data.totalItems'));
    setIsLoading(false);
  };

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };

  const allKeysTeam = getAllKeys(_.get(menuList, 'data.team'));
  const onCheckTeam = (checkedKeys) => {
    console.log('allKeysTeam', checkedKeys);
    setCheckedKeysTeam(checkedKeys);
    setIndeterminateTeam(!!_.size(checkedKeys) && _.size(checkedKeys) < _.size(allKeysTeam));
    setCheckAllTeam(_.size(checkedKeys) === _.size(allKeysTeam));
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleVisibleCreate = () => {
    setVisibleCreate(true);
  };

  const ClearFillter = () => {

  }

  return (
    // <PageSettings.Consumer>
    //   {({ checkPermissionAction }) =>
    //     checkPermissionAction(`P53PG1C1`, `P53PG1C1A1`) ? (
          <AllticketContext.Provider
            value={{
              state: {
                paginationPage,
                spanRight,
                spanLeft,
                menuList,
                indeterminateTeam,
                checkedKeysTeam,
                searchList,
                checkAllTeam,
                changeTab,
                isLoading,
                fillter,
                selectedRowKeys,
                toggle,
                visibleCreate,
                ticketData,
                event,
                editRecord,
                sort,
                ticketId,
                page,
                total,
                isLoading,
                requestDetailData,
                changeTicketNo,
                changeVender,
                vendorFilterdata,
                brandData,
                modelData,
                totalBrand,
                totalModel,
                vendorData,
                statusData,
              },
              setState: {
                setSpanRight,
                setSpanLeft,

                setPaginationPage,
                setCheckedKeysTeam,
                setIndeterminateTeam,
                setChangeStatus,
                setChangeTab,
                setCheckAllTeam,
                setIsLoading,
                setFillter,
                setSelectedRowKeys,
                setToggle,
                setVisibleCreate,
                setEditRecord,
                setEvent,
                setSort,
                setRequestDetailData,
                setVendorFilterdata,
                setChangeTicketNo,
                setChangeVender,
                setBrandData,
                setModelData,
                setTotalBrand,
                setTotalModel,
                setLotNo,
              },
              fnc: {
                onCheckTeam,
                handleToggle,
                handleChangePage,
                handleChangeSize,
                handleVisibleCreate,
                closeTicketModal,
              },
            }}
          >
            <Modal
              visible={visibleTicket}
              onCancel={() => {closeTicketModal();setFillter([]);}}
              centered={true}
              title={<FormattedMessage id="recivePOSelectPurchaseOrder" defaultMessage="Select Purchase Order" />}
              width={'90%'}
              style={{ marginTop: '15px' }}
              bodyStyle={{ padding: '0px 0px', height: 'calc(100vh - 230px)' }}
              footer={[
                <div style={{ paddingTop: '10px' }}>
                  <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {closeTicketModal();setFillter([]);}} style={{ marginTop: '10px' }}>
                    <FormattedMessage id="btnClose" defaultMessage="Close" />
                  </Button_01>
                </div>,
              ]}
            >
              <Row gutter={[16, 16]}>
                <Col span={toggle ? 0 : 6} style={{ padding: '8px 0px 8px 8px' }}>
                  <LeftCardAllTicket />
                </Col>
                <Col span={toggle ? 24 : 18} style={{ padding: '8px 8px 8px 0px' }}>
                  <RightCardAllticket />
                </Col>
              </Row>
            </Modal>
          </AllticketContext.Provider>
    //     ) : null
    //   }
    // </PageSettings.Consumer>
  );
};

export default ModalPurchase;
