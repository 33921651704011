import React from 'react';
import httpClient from '../../components/axiosClient';

const getMaintenanceReport = async (data) => {
  const comCode = localStorage.getItem('comCode');
  try {

    const body = {
      startDate: data.startDate,
      endDate: data.endDate,
      search: data.fieldChang,
      searchBy: data.fliterByColumn,
      orderType: data.orderSort,
      orderBy: data.fieldSort,
      paging: data.paginationPage,
      rowsPerPages: data.paginationSize,
    }

    const response = await httpClient.post(`/v3/maintenance/manager/company/${comCode}/find-list-maintenance`, body);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getMaintenanceReport;