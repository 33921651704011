import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Select, DatePicker, Input, Divider, Icon, AutoComplete, ConfigProvider } from 'antd';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import AddBusinessPartner from '../add-business-partner/index';
import getAddressBook from '../../controllers/getCustomer/get-address-book';
import { getItemCode, getItemName, getItemNo } from '../../controllers/maintenance-report/get-item-code-no';
import _, { debounce } from 'lodash';
import './css/index.css';
import AddAddressBook from '../../components/modal-create-adress-map-v3';
import { useAppContext } from '../../includes/indexProvider';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import moment from 'moment';
import { checkDocument } from '../../controllers/maintenance-report/getDocumentNo';

const { Option } = Select;

const MaintenanceDetail = (props) => {
  //----------------------- Center State -------------------------
  const {
    form,
    refAddress,
    vendorData,
    vendorAddress,
    setTrigger,
    setVendorAddress,
    itemName,
    setItemName,
    itemNo,
    setItemNo,
    itemCode,
    setItemCode,
    itemNoAll,
    setItemNoAll,
    setVenderId,
    venderId,
    itemMasterId,
    setItemMasterId,
    addressId,
    setAddressId,
    itemNoId,
    setItemNoId,
    numberState,
    setNumberState,
  } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue, setFields } = form;
  const intl = useIntl();
  const app = useAppContext();
  const [locale, setLocale] = useState(enUS);

  //----------------------- State --------------------------------
  const [visibleAddCustomer, setVisibleAddCustomer] = useState(false);
  const [visibleAddAddress, setVisibleAddAddress] = useState(false);
  const [customerName, setCustomerName] = useState();
  const [triggerAddress, setTriggerAddress] = useState(false);

  //------------------------ Center FNC --------------------------

  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let changeLang = langValueState === 'EN' ? enUS : thTH;
    setLocale(changeLang);
    if (changeLang == 'EN') {
      moment.locale('en');
    } else if (changeLang == 'TH') {
      moment.locale('th-th');
    }
  };

  const LabelRequire = styled.label`
    color: #ff1010;
  `;

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    const getAddressVender = async () => {
      const venderName = getFieldValue(`venderId`);
      if (venderName && venderName !== undefined) {
        const response = await getAddressBook(venderName);
        if (response) {
          setVendorAddress(response.data.toLocations);
        }
        setFieldsValue({
          ['address']: undefined,
        });
        refAddress.current.innerHTML = 'Address';
      }
    };
    getAddressVender();
  }, [getFieldValue(`venderId`), triggerAddress]);

  useEffect(() => {
    setTimeout(() => {
      setFieldsValue({
        ['venderId']: venderId,
      });
    }, 500);
  }, [venderId]);

  useEffect(() => {
    setTimeout(() => {
      setFieldsValue({
        ['address']: addressId,
      });
    }, 500);
  }, [addressId]);

  useEffect(() => {
    const venderId = getFieldValue(`venderId`);
    if (venderId !== undefined && venderId !== '') {
      const filterVendorName = vendorData.filter((col) => venderId.includes(col.customerId));
      setCustomerName(filterVendorName[0]);
    }
  }, [getFieldValue(`venderId`)]);

  //--------------------------------- item code ------------------------------
  const getValueItemCode = debounce(async (fieldChang) => {
    if (fieldChang !== '') {
      const data = await getItemCode(fieldChang);
      setItemCode(data);
      setItemName(data);
      let arr = [];
      _.map(data, (el) => {
        arr.push(...el.item);
      });
      setItemNo(arr);
    } else {
      setItemCode([]);
      setItemName([]);
      setItemNo([]);
    }
    // }
  }, 200);

  const selectItemCode = async (value) => {
    setNumberState(1);
    setItemNo([]);
    const filterItem = itemCode.filter((col) => value.includes(col.itemMasterId));
    const data = await getItemCode(_.get(filterItem[0], 'itemCode'));
    setItemCode(data);
    setItemName(data);
    let arr = [];
    _.map(data, (el) => {
      arr.push(...el.item);
    });
    setItemNo(arr);

    setItemMasterId(_.get(filterItem[0], 'itemMasterId'));

    setFieldsValue({
      ['itemName']: _.get(filterItem[0], 'itemName'),
    });
    setFieldsValue({
      ['itemNo']: undefined,
    });
  };

  const onBlurItemCode = async () => {
    const getItemCodeFilter = getFieldValue(`itemCode`);
    if (getItemCodeFilter !== undefined && getItemCodeFilter !== '') {
      if (itemMasterId) {
        setFieldsValue({
          ['itemCode']: itemMasterId,
        });
        setFieldsValue({
          ['itemName']: itemMasterId,
        });
      } else {
        if (_.size(itemCode) !== 0) {
          const filterItem = itemCode && itemCode.filter((col) => getItemCodeFilter.includes(col.itemCode));
          if (filterItem.length !== 0) {
            setFieldsValue({
              ['itemCode']: _.get(filterItem[0], 'itemCode'),
            });
            setFieldsValue({
              ['itemName']: _.get(filterItem[0], 'itemName'),
            });
          } else {
            setItemCode([]);
            setItemName([]);
            setItemNo([]);
            setFieldsValue({
              ['itemCode']: undefined,
            });
            setFieldsValue({
              ['itemName']: undefined,
            });
            setFieldsValue({
              ['itemNo']: undefined,
            });
          }
        } else {
          setItemCode([]);
          setItemName([]);
          setItemNo([]);
          setFieldsValue({
            ['itemCode']: undefined,
          });
          setFieldsValue({
            ['itemName']: undefined,
          });
          setFieldsValue({
            ['itemNo']: undefined,
          });
        }
      }
    } else {
      setItemCode([]);
      setItemName([]);
      setItemNo([]);
      setItemMasterId();
      setFieldsValue({
        ['itemCode']: undefined,
      });
      setFieldsValue({
        ['itemName']: undefined,
      });
      setFieldsValue({
        ['itemNo']: undefined,
      });
    }
  };

  //----------------------------------------------------------------------------

  //--------------------------------- item Name ------------------------------

  const getValueItemName = debounce(async (fieldChang) => {
    if (fieldChang !== '') {
      const data = await getItemName(fieldChang);
      setItemName(data);
      setItemCode(data);
      let arr = [];
      _.map(data, (el) => {
        arr.push(...el.item);
      });
      setItemNo(arr);
    } else {
      setItemCode([]);
      setItemName([]);
      setItemNo([]);
    }
  }, 200);

  const selectItemName = async (value) => {
    setNumberState(2);
    setItemNo([]);
    const filterItem = itemName.filter((col) => value.includes(col.itemMasterId));
    const data = await getItemName(_.get(filterItem[0], 'itemName'));
    setItemMasterId(_.get(filterItem[0], 'itemMasterId'));
    setItemName(data);
    setItemCode(data);
    let arr = [];
    _.map(data, (el) => {
      arr.push(...el.item);
    });
    setItemNo(arr);
    setFieldsValue({
      ['itemCode']: _.get(filterItem[0], 'itemCode'),
    });
    setFieldsValue({
      ['itemNo']: undefined,
    });
  };

  const onBlurItemName = async () => {
    const getItemNameFilter = getFieldValue(`itemName`);
    if (getItemNameFilter !== undefined && getItemNameFilter !== '') {
      if (itemMasterId) {
        setFieldsValue({
          ['itemCode']: itemMasterId,
        });
        setFieldsValue({
          ['itemName']: itemMasterId,
        });
      } else {
        if (itemName.length !== 0) {
          const filterItem = itemName && itemName.filter((col) => getItemNameFilter.includes(col.itemName));
          if (filterItem.length !== 0) {
            setFieldsValue({
              ['itemCode']: _.get(filterItem[0], 'itemCode'),
            });
            setFieldsValue({
              ['itemName']: _.get(filterItem[0], 'itemName'),
            });
          } else {
            setItemCode([]);
            setItemName([]);
            setItemNo([]);
            setFieldsValue({
              ['itemCode']: undefined,
            });
            setFieldsValue({
              ['itemName']: undefined,
            });
            setFieldsValue({
              ['itemNo']: undefined,
            });
          }
        } else {
          setItemCode([]);
          setItemName([]);
          setItemNo([]);
          setFieldsValue({
            ['itemCode']: undefined,
          });
          setFieldsValue({
            ['itemName']: undefined,
          });
          setFieldsValue({
            ['itemNo']: undefined,
          });
        }
      }
    } else {
      setItemCode([]);
      setItemName([]);
      setItemNo([]);
      setItemMasterId();
      setFieldsValue({
        ['itemCode']: undefined,
      });
      setFieldsValue({
        ['itemName']: undefined,
      });
      setFieldsValue({
        ['itemNo']: undefined,
      });
    }
  };

  //----------------------------------------------------------------------------

  //--------------------------------- item no ----------------------------------
  const getValueItemNo = debounce(async (fieldChang) => {
    const getFieldItemCode = getFieldValue(`itemCode`);
    const getFieldItemName = getFieldValue(`itemName`);

    if (getFieldItemCode == undefined && getFieldItemName == undefined) {
      if (fieldChang !== '') {
        const data = await getItemNo(fieldChang);
        setItemName(data);
        setItemCode(data);
        let arr = [];
        _.map(data, (el) => {
          arr.push(...el.item);
        });
        setItemNo(arr);
      } else {
        setItemCode([]);
        setItemName([]);
        setItemNo([]);
      }
    }
  }, 200);

  const selectItemNo = async (value) => {
    const getFieldItemCode = getFieldValue(`itemCode`);
    const getFieldItemName = getFieldValue(`itemName`);
    if (getFieldItemCode === undefined && getFieldItemName === undefined) {
      setNumberState(3);
    }
    const filterItem = itemNoAll.filter((col) => value.includes(col.item[0].itemId));
    const data = await getItemNo(_.get(filterItem[0], 'item[0].itemNo'));
    setItemCode(data);
    setItemName(data);
    setItemMasterId(_.get(filterItem[0], 'itemMasterId'));
    setItemNoId(value);
    let arr = [];
    _.map(data, (el) => {
      arr.push(...el.item);
    });
    // setItemNo(arr)

    setFieldsValue({
      ['itemName']: _.get(filterItem[0], 'itemMasterId'),
    });
    setFieldsValue({
      ['itemCode']: _.get(filterItem[0], 'itemMasterId'),
    });
  };

  const onBlurItemNo = () => {
    const getFieldItemNo = getFieldValue(`itemNo`);
    const getFieldItemCode = getFieldValue(`itemCode`);
    const getFieldItemName = getFieldValue(`itemName`);

    if (getFieldItemCode !== undefined && getFieldItemCode !== '' && getFieldItemName !== undefined && getFieldItemName !== '') {
      if (getFieldItemNo !== undefined && getFieldItemNo !== '') {
        if (itemNoId) {
          setFieldsValue({
            ['itemNo']: itemNoId,
          });
        } else {
          setItemCode([]);
          setItemName([]);
          setItemNo([]);
          setItemNoId();
          setFieldsValue({
            ['itemNo']: undefined,
          });
          setFieldsValue({
            ['itemName']: undefined,
          });
          setFieldsValue({
            ['itemCode']: undefined,
          });
        }
      } else {
        if (numberState === 3) {
          setItemCode([]);
          setItemName([]);
          setItemNo([]);
          setItemNoId();
          setFieldsValue({
            ['itemNo']: undefined,
          });
          setFieldsValue({
            ['itemName']: undefined,
          });
          setFieldsValue({
            ['itemCode']: undefined,
          });
        }
      }
    }
  };

  useEffect(() => {
    const getDataDefault = async () => {
      const data = await getItemNo('');
      setItemNoAll(data);
    };
    getDataDefault();
  }, []);

  //----------------------------------------------------------------------------

  //--------------------------------------------------------------
  //------------------------- Component FNC ----------------------
  useEffect(() => {
    const address = getFieldValue(`address`);
    if (address && address !== undefined) {
      const filterVendor = vendorAddress && vendorAddress.filter((col) => address.includes(col.customerAddressBookId));
      refAddress.current.innerHTML =
        filterVendor && filterVendor.length !== 0
          ? filterVendor[0].address
          : intl.formatMessage({ id: 'maintenanceModalDetailAddressText', defaultMessage: 'Address' });
    }
  }, [getFieldValue(`address`)]);

  //--------------------------------------------------------------

  const childrenItemCode = itemCode && itemCode.map((item) => <Option key={item.itemMasterId}>{item.itemCode}</Option>);

  const childrenItemName = itemName && itemName.map((item) => <Option key={item.itemMasterId}>{item.itemName}</Option>);

  const childrenItemNo = itemNo && itemNo.length !== 0 ? itemNo.map((item) => <Option key={item.itemId}>{item.itemNo}</Option>) : null;

  const validatorDueDate = (rule, value, callback) => {
    if (value) {
      const startDate = getFieldValue(`maintenanceDate`);
      const formateStartDate = moment(startDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
      const formateNextDate = moment(value, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
      if (formateNextDate <= formateStartDate) {
        callback(intl.formatMessage({ id: 'maintenanceModalDetailValidateNextMaintenanceDate', defaultMessage: 'Next date more than start date' }));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const checkValueDucument = debounce(async () => {
    const val = getFieldValue('docNo');
    if (val === '') return;
    const valCheck = await checkDocument(val);

    if (valCheck === false) {
      setFields({
        docNo: {
          value: val,
          errors: [new Error('docNo is duplicate')],
        },
      });
    }
  }, 600);

  return (
    <div className="create-maintenance-detail">
      <Row>
        <Row gutter={[16]}>
          <Col span={6}>
            <Form.Item className="validate-form">
              <p className="text-style" style={{ marginLeft: '11px' }}>
                <FormattedMessage id="maintenanceModalDetailDocNo" defaultMessage="Doc No." />
                <LabeRequire req={true} />
              </p>
              {getFieldDecorator('docNo', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'maintenanceModalDetailValidateDetailDocNo',
                      defaultMessage: 'Please enter doc no.',
                    }),
                  },
                ],
              })(
                <Input
                  className="detail-input-style"
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderDetailDocNo', defaultMessage: 'Enter Doc No.' })}
                  style={{ marginLeft: '11px' }}
                  onChange={checkValueDucument}
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item className="validate-form">
              <p className="text-style" style={{ marginLeft: '11px' }}>
                <FormattedMessage id="maintenanceModalDetailItemCode" defaultMessage="Item Code." />
                <LabeRequire req={true} />
              </p>
              {getFieldDecorator('itemCode', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'maintenanceModalDetailValidateItemCode',
                      defaultMessage: 'Please enter item code.',
                    }),
                  },
                ],
              })(
                <AutoComplete
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderItemCode', defaultMessage: 'Enter Item Code' })}
                  onSearch={getValueItemCode}
                  onSelect={selectItemCode}
                  filterOption={false}
                  style={{ width: '100%' }}
                  className="autocompleted-style"
                  onBlur={onBlurItemCode}
                >
                  {childrenItemCode}
                </AutoComplete>
              )}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item style={{ paddingLeft: '60px' }}>
              <p className="text-style">
                <FormattedMessage id="maintenanceModalDetailVenderName" defaultMessage="Vender Name." />
                <LabeRequire req={true} />
              </p>
              {getFieldDecorator('venderId', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'maintenanceModalDetailValidateVenderName',
                      defaultMessage: 'Please select vender name.',
                    }),
                  },
                ],
              })(
                <Select
                  className="detail-select-style"
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderVenderName', defaultMessage: 'Select Vender' })}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => setVisibleAddCustomer(true)}
                      >
                        <Icon type="plus" className="customer-icon-plus" />
                        <span className="text-add-customer">
                          <FormattedMessage id="maintenanceModalDetailAddVender" defaultMessage="Add Vendor" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {vendorData && vendorData.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16]}>
          <Col span={6}>
            <Form.Item className="validate-form">
              <p className="text-style" style={{ marginLeft: '11px' }}>
                <FormattedMessage id="maintenanceModalDetailItemNo" defaultMessage="Item No." />
                <LabeRequire req={true} />
              </p>
              {getFieldDecorator('itemNo', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'maintenanceModalDetailValidateItemNo', defaultMessage: 'Please enter item no.' }),
                  },
                ],
              })(
                <AutoComplete
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderItemNo', defaultMessage: 'Enter Item No' })}
                  key={(item) => item.itemId}
                  onSearch={getValueItemNo}
                  onSelect={selectItemNo}
                  // filterOption={(input, option) =>
                  //   option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                  style={{ width: '100%' }}
                  className="autocompleted-style"
                  onBlur={onBlurItemNo}
                >
                  {childrenItemNo}
                </AutoComplete>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item className="validate-form">
              <p className="text-style" style={{ marginLeft: '11px' }}>
                <FormattedMessage id="maintenanceModalDetailItemName" defaultMessage="Item Name." />
                <LabeRequire req={true} />
              </p>
              {getFieldDecorator('itemName', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'maintenanceModalDetailValidateItemName',
                      defaultMessage: 'Please enter item name.',
                    }),
                  },
                ],
              })(
                <AutoComplete
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderItemName', defaultMessage: 'Enter Item Name' })}
                  onSearch={getValueItemName}
                  onSelect={selectItemName}
                  filterOption={false}
                  style={{ width: '100%' }}
                  className="autocompleted-style"
                  onBlur={onBlurItemName}
                >
                  {childrenItemName}
                </AutoComplete>
              )}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item style={{ paddingLeft: '60px' }}>
              {getFieldDecorator('address', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'maintenanceModalDetailValidateAddress', defaultMessage: 'Please select address.' }),
                  },
                ],
              })(
                <Select
                  className="detail-select-style"
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderAddress', defaultMessage: 'Select Address' })}
                  disabled={getFieldValue(`venderId`) ? false : true}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => setVisibleAddAddress(true)}
                      >
                        <Icon type="plus" className="customer-icon-plus" />
                        <span className="text-add-customer">
                          <FormattedMessage id="saleOrderModalAddAddress" defaultMessage="Add Address" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {vendorAddress && vendorAddress.map((item) => <Option key={item.customerAddressBookId}>{item.addressName}</Option>)}
                </Select>
              )}
              <p className="text-style">
                <Icon type="environment" className="icon-map" style={{ color: '#1D3557' }} />{' '}
                <span ref={refAddress}>
                  <FormattedMessage id="maintenanceModalDetailAddressText" defaultMessage="Address" />
                </span>
              </p>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16]}>
          <Col span={6}>
            <Form.Item className="validate-form">
              <p className="text-style" style={{ marginLeft: '11px' }}>
                <FormattedMessage id="maintenanceModalDetailMaintenanceDate" defaultMessage="Maintenance Date" />
                <LabeRequire req={true} />
              </p>
              {getFieldDecorator('maintenanceDate', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'maintenanceModalDetailValidateMaintenanceDate',
                      defaultMessage: 'Please select maintenance date.',
                    }),
                  },
                ],
              })(
                <DatePicker
                  className="detail-date-picker-style"
                  dropdownClassName="date-picker-dropdown"
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderDate', defaultMessage: 'Select Date' })}
                  format="ddd, MMM DD, YYYY"
                  style={{ marginLeft: '11px' }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item style={{ marginTop: '3px' }}>
              <p className="text-style" style={{ marginLeft: '11px' }}>
                <FormattedMessage id="maintenanceModalDetailNextMaintenanceDate" defaultMessage="Next Maintenance Date" />
              </p>
              {getFieldDecorator('nextMaintenanceDate', {
                rules: [
                  {
                    validator: validatorDueDate,
                  },
                ],
              })(
                <DatePicker
                  className="detail-date-picker-style"
                  dropdownClassName="date-picker-dropdown"
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderDate', defaultMessage: 'Select Date' })}
                  format="ddd, MMM DD, YYYY"
                  style={{ marginLeft: '11px' }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10}>
            {/* <Row style={{ marginTop: '36.4px', paddingLeft: '60px' }}>
              <Col span={4} style={{ alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                <span className='text-style' style={{ marginTop: '7px' }} ><FormattedMessage id="maintenanceModalDetailPhone" defaultMessage="Phone" /> : </span>
              </Col>
              <Col span={20}>
                <Form.Item style={{ paddingLeft: '5px' }}>
                  {getFieldDecorator("phone", {
                  })(
                    <Input className='detail-input-style'
                      placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderPhone', defaultMessage: 'Enter Phone' })}
                    ></Input>
                  )}
                </Form.Item>
              </Col>
            </Row> */}

            <Form.Item style={{ paddingLeft: '60px' }}>
              <p className="text-style">
                <FormattedMessage id="maintenanceModalDetailPhone" defaultMessage="Phone" />
              </p>
              {getFieldDecorator(
                'phone',
                {}
              )(
                <Input
                  className="detail-input-style"
                  placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderPhone', defaultMessage: 'Enter Phone' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Row>
      <AddBusinessPartner
        setVisible={setVisibleAddCustomer}
        visible={visibleAddCustomer}
        type="vendor"
        setTrigger={setTrigger}
        setId={setVenderId}
      />

      <AddAddressBook
        visible={visibleAddAddress}
        setVisible={setVisibleAddAddress}
        customerId={getFieldValue(`venderId`)}
        customerName={customerName && customerName.customerName}
        setTrigger={setTriggerAddress}
        setAddressId={setAddressId}
      />
    </div>
  );
};

const Maintenance = Form.create({
  name: 'modal_form',
})(MaintenanceDetail);

export default Maintenance;
