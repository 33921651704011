import React, { useContext, useEffect, useState } from 'react';
import { Row, Spin } from 'antd';
import _ from 'lodash';
import { useAppContext } from '../../../includes/indexProvider';
import { ActionNoteContext } from '../../note-components/note_Provirder';
import { getFeedList } from '../../../controllers/note/feed';
import moment from 'moment';
import { Preview01, Preview02, Preview03, Preview04, Preview05, PreviewPlus } from '../preview'
import ReactBnbGallery from 'react-bnb-gallery';
import { useDebounce } from '../../../controllers/debounce';
import FeedNoteUser from '../components/user';
import FeedNoteTags from '../components/tags';
import FeedNoteText from '../components/text';
import FeedNoteImage from '../components/image';
import FeedNoteLocation from '../components/location';
import FeedNoteFile from '../components/file';

const DrawerDONote = ({ data }) => {
  const app = useAppContext();
  const { visibleDrawer, triggerDrawer } = app.state
  const note = useContext(ActionNoteContext);
  const { indexStart, indexEnd, scrollDivRef, loading } = note.state;
  const { setIndexStart, setIndexEnd } = note.setState
  const { setLoading } = note.setState;
  const { handleScrollEnd, handleScroll } = note.fnc;
  const memComId = localStorage.getItem('memComId')
  const [feedList, setFeedList] = useState([])
  const [feedTotal, setFeedTotal] = useState(0)
  const [OpenGallery, setOpenGallery] = useState(false)
  const [galleryImg, setGalleryImg] = useState([])
  const [numberImage, setNumberImage] = useState(0)
  const debounceScroll = useDebounce(indexEnd, 500)

  console.log("data", data)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const body = {
          "formCode": "deliveryorder",
          "feedRefId": data,
          "indexStart": indexStart,
          "indexEnd": indexEnd,
          "orderBy": "createdAt",
          "orderType": "desc"
        }
        const response = await getFeedList(body)
        setFeedList((prev) => {
          const cloneData = _.uniqBy([..._.get(response, 'data.data.feedsList'), ...prev], 'feedsId')
          return _.sortBy(cloneData, [function (o) { return o.createdAt; }]);
        })
        setFeedTotal(_.get(response, 'data.data.totalItem'))
        setTimeout(() => {
          setLoading(false)
        }, 500);
        setTimeout(() => {
          handleScrollEnd(indexEnd)
        }, 500);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (visibleDrawer) {
      fetchData()
    } else {
      setIndexStart(0)
      setIndexEnd(10)
    }
    const intervalId = setInterval(fetchData, 36000);
    return () => clearInterval(intervalId);
  }, [data, debounceScroll, visibleDrawer, triggerDrawer]);

  const fncPreview = (imageList, item) => {
    let sum = imageList.length;
    switch (sum) {
      case 1:
        return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
    }
  };

  const handlePreviewImage = (page, data, itemData) => {
    const mapPhoto = data && _.size(data) !== 0 ? data.map((item, index) => {
      return {
        photo: _.get(item, 'uri'),
        number: index,
        caption: _.get(item, 'remark'),
        subcaption: `Noted By ${_.get(itemData, 'createdByName')}`,
        thumbnail: _.get(item, 'uri'),
      }
    }
    ) : []
    setGalleryImg(mapPhoto)
    setOpenGallery(true)
    setNumberImage(page)
  }

  return (
    <div className='content-drawer' ref={scrollDivRef} onScroll={(e) => handleScroll(e, feedTotal)}>
      <Spin spinning={loading}>
        {_.map(feedList, (i) => {
          const isMemComId = _.get(i, 'createdById') === memComId ? true : false
          const isOnlyMap = _.size(_.get(i, 'feedsTags')) === 0 && !_.get(i, 'feedsDescription') &&
            _.size(_.get(i, 'feedsImages')) === 0 && _.get(i, 'feedsLocation') ? true : false
          const mapImgae = _.map(_.get(i, 'feedsImages'), (item) => {
            return {
              ...item,
              uri: _.get(item, 'url'),
            }
          })
          return (
            <Row gutter={[8, 8]} style={{ marginBottom: '32px' }} key={_.get(i, 'feedsId')}>
              <FeedNoteUser
                isMemComId={isMemComId}
                name={_.get(i, 'createdByName')}
                date={moment.unix(_.get(i, 'createdAt')).format('DD/MM/YYYY HH:mm')}
                image={_.get(i, 'memberProfile')}
              />
              {
                _.size(_.get(i, 'feedsTags')) > 0 ? (
                  <FeedNoteTags isMemComId={isMemComId} tags={_.get(i, 'feedsTags')} />
                ) : null
              }
              {_.get(i, 'feedsDescription') ? (
                <FeedNoteText isMemComId={isMemComId} text={_.get(i, 'feedsDescription')} />
              ) : null
              }
              {_.get(i, 'feedsLocation') ? (
                <FeedNoteLocation isMemComId={isMemComId} isOnlyMap={isOnlyMap} item={_.get(i, 'feedsLocation')} />
              ) : null
              }
              {
                _.size(_.get(i, 'feedsAttachments')) > 0 ? (
                  <FeedNoteFile isMemComId={isMemComId} fileList={_.get(i, 'feedsAttachments')} />
                ) : null
              }
              {
                _.size(mapImgae) > 0 ? (
                  <FeedNoteImage isMemComId={isMemComId} image={mapImgae} item={i} fncPreview={fncPreview} />
                ) : null
              }


              <ReactBnbGallery
                show={OpenGallery}
                photos={galleryImg}
                onClose={() => setOpenGallery(false)}
                activePhotoIndex={numberImage}
              />
            </Row>
          );
        })}
      </Spin>
    </div>
  );
};

export default DrawerDONote;
