import React, { useState, useMemo, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import './css/index.css';
import getSearchLocation from '../../function/search-location/getLocation';
import Map from './map-location';
import _, { debounce } from 'lodash';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const ModalMapLocation = React.memo((props) => {
  const { stateCustomer, setStateCustomer } = props;
  const { position, center, autoComplteLocation } = stateCustomer
  const { setPosition, setCenter, setAutoComplteLocation, setAutoComplteValue } = setStateCustomer
  const intl = useIntl();
  const { visible, setLatlngCustomer, onCancel, onCreate, form, CustomerData } = props;
  const { getFieldDecorator } = form;
  // const { stateCustomer } = useContext(CustomerContext);
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [addressArea, setAddressArea] = useState([]);

  console.log("position", position, center)

  useEffect(() => {
    if (_.get(CustomerData, 'lat') && _.get(CustomerData, 'lng')) {
      setPosition({
        lat: _.get(CustomerData, 'lat'),
        lng: _.get(CustomerData, 'lng')
      });
      setCenter({
        lat: _.get(CustomerData, 'lat'),
        lng: _.get(CustomerData, 'lng')
      });
    } else {
      setPosition({
        lat: _.get(latlng, 'lat'),
        lng: _.get(latlng, 'lng')
      });
      setCenter({
        lat: _.get(latlng, 'lat'),
        lng: _.get(latlng, 'lng')
      });
    }
  }, [_.get(CustomerData, 'customerId')])


  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await getSearchLocation({ name: fieldChang });
    setAddressArea(newData);
  }, 200);

  const handleSelect = (key, option) => {
    if (isNaN(key) == false) {
      form.setFieldsValue({ ['location']: option.props.children });
      form.setFieldsValue({ ['description']: option.props.children });
      setAutoComplteLocation(option.props.children)
      setAutoComplteValue(option.props.children)
      setPosition({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setCenter({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setLatlngCustomer({ lat: addressArea[key].lat, lng: addressArea[key].lng });
    } else {
      setPosition(latlng);
      setCenter(latlng);
      form.setFieldsValue({
        ['location']: undefined
      });
      setAutoComplteLocation()
      setAutoComplteValue()
    }
  }

  useMemo(() => {
    if (stateCustomer.valueAddress && stateCustomer.valueAddress !== undefined && visible) {
      form.setFieldsValue({
        ['location']: stateCustomer.valueAddress
      });
      form.setFieldsValue({
        ['description']: stateCustomer.valueAddress
      });
      setAutoComplteValue(stateCustomer.valueAddress)
    } else {
      form.setFieldsValue({
        ['locationName']: _.get(stateCustomer, 'filterCustomerLocation.addressName')
      });
      form.setFieldsValue({
        ['location']: _.get(stateCustomer, 'filterCustomerLocation.fullAddress')
      });
      form.setFieldsValue({
        ['description']: _.get(stateCustomer, 'filterCustomerLocation.description')
      });
    }

  }, [stateCustomer.valueAddress, visible]);


  const children = addressArea.map(item =>
    <Option key={item.key}>
      {item.address}
    </Option>
  );

  return (
    <Modal
      visible={visible}
      title={intl.formatMessage({ id: 'ModalLocationHeaderCreateLocation', defaultMessage: 'Select Location' })}
      bodyStyle={{ height: 'calc(100vh - 230px)', maxHeight: '610px', overflow: 'auto', overflowX: 'hidden' }}
      width={824}
      centered
      onCancel={onCancel}
      onOk={onCreate}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={onCreate}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationName', defaultMessage: 'Location Name' })} req={true} />}
        >
          {getFieldDecorator('locationName', {
            initialValue: stateCustomer.filterCustomerLocation && stateCustomer.filterCustomerLocation !== undefined ? _.get(stateCustomer, 'filterCustomerLocation.addressName') : _.get(CustomerData, 'locationName'),
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationValidateLocationName', defaultMessage: 'Please enter location name' })
              },
            ],
          })(<Input placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderLocationName', defaultMessage: 'Enter Location Name' })} autoComplete="off"
          />)}
        </Form.Item>

        <Form.Item label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationAddress', defaultMessage: 'Address' })} req={true} />}
        >
          {getFieldDecorator(
            'description', {
            // initialValue: stateCustomer.filterCustomerLocation && stateCustomer.filterCustomerLocation !== undefined ? _.get(stateCustomer, 'filterCustomerLocation.description') : undefined,
            initialValue: stateCustomer.valueAddress && stateCustomer.valueAddress !== undefined ? _.get(stateCustomer, 'valueAddress') :
              stateCustomer.filterCustomerLocation && stateCustomer.filterCustomerLocation !== undefined ? _.get(stateCustomer, 'filterCustomerLocation.description') : undefined,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationLocationValidateAddress', defaultMessage: 'Please enter address' })
              },
            ],
          }

          )(
            <Input placeholder={intl.formatMessage({ id: 'ModalLocationLocationPlaceholderAddress', defaultMessage: 'Enter Address' })} autoComplete="off" ></Input>
          )}
        </Form.Item>
        <div>
          <Map
            position={position}
            center={center}
            setPosition={setPosition}
            setCenter={setCenter}
            setLatlngCustomer={setLatlngCustomer}
            setStateCustomer={setStateCustomer}
            addressArea={addressArea}
            handleSearchLocation={handleSearchLocation}
            handleSelect={handleSelect}
            children={children}
            autoComplteLocation={autoComplteLocation}
            setAutoComplteLocation={setAutoComplteLocation}
          />
        </div>

      </Form>
    </Modal>
  )
});

const ModalMap = Form.create({
  name: 'modal_form',
})(ModalMapLocation);

export default React.memo(ModalMap);
