import { Modal, Col, Row, Form, Select, Input, InputNumber, DatePicker, Icon, Spin, Menu, Popover, Button } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Button_01 from '../../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css';
import GoogleMapReact from 'google-map-react';
import { GoogleMap, useLoadScript, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import _, { debounce } from 'lodash';
import IconMarker from '../../../../components/image/LocationEngagement.svg';
import Button_05 from '../../../../components/v2/button_05';
import Button_06 from '../../../../components/v2/button_06';
import Popup from 'reactjs-popup';
import getSearchLocation from '../../../../function/search-location/getLocation';
import { v4 as uuidv4 } from 'uuid';
import { searchLocationCompany } from '../../../../controllers/task/create-task';

const TicketLocationModal = (props) => {
  const {
    visibleLocation,
    selectLocation,
    setSelectLocation,
    setVisibleLocation,
    setVisibleInfoWindow,
    visibleInfoWindow,
    center,
    setCenter,
    position,
    setPosition,
    selectPostAddress,
    setSelectPostAddress,
    typeAddress,
  } = props;
  const intl = useIntl();

  const mapPolyRef = useRef(null);
  const [googleMapzoom, setGoogleMapzoom] = useState(12);
  const [googleMapcenter, setGoogleMapcenter] = useState({ lat: 13.90607, lng: 100.51913 });

  const [map, setMap] = useState(null);
  const mapRef = useRef();

  const [activeMarker, setActiveMarker] = useState(null);
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [keyMenu, setKeyMenu] = useState([]);
  const [selectAddress, setSelectAddress] = useState([]);
  const [dragMarker, setDragMarker] = useState();
  const [addressData, setAddressData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  console.log('addressData', addressData);
  console.log('selectLocation', selectLocation);
  console.log('selectAddress', selectAddress);
  console.log('center', center);
  console.log('position', position);
  console.log('visibleInfoWindow', selectPostAddress);
  console.log('visibleInfoWindow', visibleInfoWindow);

  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await searchLocationCompany(fieldChang);
    const mapData = _.get(newData, 'data.data').map((item) => {
      return {
        address: item.address,
        addressBookId: item.addressBookId === '' ? uuidv4() : item.addressBookId,
        lat: item.lat,
        lng: item.lng,
        name: item.name,
        status: item.addressBookId === '' ? 'normal_address' : 'customer_address',
      };
    });
    // if (_.get(fromLocationData, '[0].addressBookId')) {
    //   setAddressData(fromLocationData.concat(mapData))
    // } else {
    setVisibleInfoWindow(false);
    setAddressData(mapData);
    // }
  }, 200);

  useEffect(() => {
    const positionMyself = async () => {
      if (_.size(selectLocation) > 0) {
        if (_.get(selectLocation, 'serviceLocationLat')) {
          setSelectAddress([
            {
              address: _.get(selectLocation, 'serviceLocationAddress'),
              lat: _.get(selectLocation, 'serviceLocationLat'),
              lng: _.get(selectLocation, 'serviceLocationLng'),
              name: '',
              addressBookId: _.get(selectLocation, 'serviceLocationRefId'),
              status: 'normal_address',
            },
          ]);
          setTimeout(() => {
            setDragMarker(_.get(selectLocation, 'serviceLocationRefId'));
          }, 500);

          setCenter({
            lat: _.get(selectLocation, 'serviceLocationLat'),
            lng: _.get(selectLocation, 'serviceLocationLng'),
          });
          setPosition({
            lat: _.get(selectLocation, 'serviceLocationLat'),
            lng: _.get(selectLocation, 'serviceLocationLng'),
          });
        } else {
          setSelectAddress(selectLocation);
          setTimeout(() => {
            setDragMarker(_.get(selectLocation, '[0].addressBookId'));
          }, 500);
          setCenter({
            lat: parseFloat(_.get(selectLocation, '[0].lat')),
            lng: parseFloat(_.get(selectLocation, '[0].lng')),
          });
          setPosition({
            lat: parseFloat(_.get(selectLocation, '[0].lat')),
            lng: parseFloat(_.get(selectLocation, '[0].lng')),
          });
        }
      } else {
        setSelectAddress([
          {
            address: addressData[0].address,
            lat: addressData[0].lat,
            lng: addressData[0].lng,
            name: addressData[0].name,
            addressBookId: addressData[0].addressBookId,
          },
        ]);
        setDragMarker(_.get(addressData, '[0].addressBookId'));
        setCenter({
          lat: parseFloat(_.get(addressData, '[0].lat')),
          lng: parseFloat(_.get(addressData, '[0].lng')),
        });
        setPosition({
          lat: parseFloat(_.get(addressData, '[0].lat')),
          lng: parseFloat(_.get(addressData, '[0].lng')),
        });
      }
      setVisibleInfoWindow(true);
      setKeyMenu([]);
      // }
    };
    handleSearchLocation();
    if (visibleLocation === true) {
      if (typeAddress === 'Service') {
        positionMyself();
      }
      if (typeAddress === 'Post') {
        positionPost();
      }
    }
  }, [visibleLocation]);

  const positionPost = async () => {
    if (_.size(selectPostAddress) > 0) {
      if (_.get(selectPostAddress, 'addressBookId')) {
        setSelectAddress([selectPostAddress]);

        setCenter({
          lat: parseFloat(_.get(selectPostAddress, 'lat')),
          lng: parseFloat(_.get(selectPostAddress, 'lng')),
        });
        setPosition({
          lat: parseFloat(_.get(selectPostAddress, 'lat')),
          lng: parseFloat(_.get(selectPostAddress, 'lng')),
        });
        setTimeout(() => {
          setDragMarker(_.get(selectPostAddress, 'addressBookId'));
        }, 500);
      } else {
        setSelectAddress([
          {
            address: _.get(selectPostAddress, 'address'),
            lat: _.get(selectPostAddress, 'lat'),
            lng: _.get(selectPostAddress, 'lng'),
            name: '',
            addressBookId: _.get(selectPostAddress, 'address'),
            status: 'normal_address',
          },
        ]);

        setCenter({
          lat: _.get(selectPostAddress, 'lat'),
          lng: _.get(selectPostAddress, 'lng'),
        });
        setPosition({
          lat: _.get(selectPostAddress, 'lat'),
          lng: _.get(selectPostAddress, 'lng'),
        });
        setTimeout(() => {
          setDragMarker(_.get(selectPostAddress, 'address'));
        }, 500);
      }
    } else {
      setSelectAddress([
        {
          address: addressData[0].address,
          lat: addressData[0].lat,
          lng: addressData[0].lng,
          name: addressData[0].name,
          addressBookId: addressData[0].addressBookId,
        },
      ]);

      setCenter({
        lat: parseFloat(_.get(addressData, '[0].lat')),
        lng: parseFloat(_.get(addressData, '[0].lng')),
      });
      setPosition({
        lat: parseFloat(_.get(addressData, '[0].lat')),
        lng: parseFloat(_.get(addressData, '[0].lng')),
      });
    }
    setTimeout(() => {
      setDragMarker(_.get(addressData, '[0].addressBookId'));
      setVisibleInfoWindow(true);
    }, 500);

    setKeyMenu([]);
  };

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    id: 'google-map-script',
  });

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  const renderMenu = () =>
    _.map(addressData, (item) => {
      return (
        <Menu.Item className="location-company-detail-div-map" key={item.addressBookId}>
          <div>
            <Row>
              <Col span={23}>
                <span
                  className={
                    'location-company-detail-address-name'
                    // item.addressBookId === _.get(companyState, 'keyMenu[0]')
                    //   ? 'location-company-detail-address-name2'
                    //   : 'location-company-detail-address-name'
                  }
                >
                  {item.name}
                </span>
              </Col>
            </Row>
            <Row>
              <div
                className={
                  'location-company-detail-address'
                  // ? 'location-company-detail-address2'
                  // : 'location-company-detail-address'
                }
              >
                {item.address}
              </div>
            </Row>
          </div>
        </Menu.Item>
      );
    });
  // ));

  const onDragEnd = async (evt) => {
    let area = await getSearchLocation({ location: evt.latLng });
    if (area) {
      const uuid = uuidv4();
      setSelectAddress([
        {
          address: area.address,
          lat: area.lat,
          lng: area.lng,
          name: area.name,
          addressBookId: uuid,
          status: 'normal_address',
        },
      ]);

      setCenter({
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      });
      setPosition({
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      });
      setVisibleInfoWindow(true);
      setDragMarker(uuid);
      // setKeyMenu([])
    }
  };

  const handleClickMenu = (props) => {
    setKeyMenu([props.selectedKeys]);
    const filter =
      addressData &&
      addressData.filter((item) => {
        return item.addressBookId === props.selectedKeys[0];
      });

    if (_.size(filter) > 0) {
      console.log('keypop', _.get(filter, '[0].lat'));

      setSelectAddress(filter);

      setCenter({
        lat: parseFloat(_.get(filter, '[0].lat')),
        lng: parseFloat(_.get(filter, '[0].lng')),
      });
      setPosition({
        lat: parseFloat(_.get(filter, '[0].lat')),
        lng: parseFloat(_.get(filter, '[0].lng')),
      });
      setTimeout(() => {
        setDragMarker(_.get(filter, '[0].addressBookId'));
        setVisibleInfoWindow(true);
      }, 500);
    }
  };

  const handleClickSelect = () => {
    if (typeAddress === 'Service') {
      setSelectLocation(selectAddress);
    }
    if (typeAddress === 'Post') {
      setSelectPostAddress(_.get(selectAddress, '[0]'));
    }
    setVisibleInfoWindow(false);
    setVisibleLocation(false);
  };

  const closeLocation = () => {
    setVisibleLocation(false);
    setVisibleInfoWindow(false);
  };

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: 'cooperative',
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const mapContainerStyle = {
    height: 'calc(100vh - 265px)',
    width: '100%',
    position: 'relative',
  };

  const onClickMarker = (id) => {
    setDragMarker(id);
    setVisibleInfoWindow(true);
  };

  const onCloseMarker = () => {
    setDragMarker();
    setVisibleInfoWindow(false);
  };

  return (
    <Modal
      visible={visibleLocation}
      onCancel={() => closeLocation()}
      centered={true}
      title={<FormattedMessage id="CreateTicket" defaultMessage='Submit a Ticket' />}
      zIndex={999}
      width={'70%'}
      style={{ marginTop: '15px' }}
      bodyStyle={{ padding: '0px 0px', height: 'calc(100vh - 230px)' }}
      footer={[
        <div style={{ paddingTop: '10px' }}>
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => closeLocation()} style={{ marginTop: '10px' }}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_01>
        </div>,
      ]}
    >
      {/* <Spin> */}
      <Row>
        <Col span={8}>
          <div className="left-side-card">
            <div>
              <Input
                className="action-report-input-7"
                placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                // prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  handleSearchLocation(e.target.value);
                }}
                autoComplete="off"
                value={searchValue}
              />
            </div>
            <div style={{ height: 'calc(100vh - 230px)', width: '100%' }}>
              <Menu
                style={{ overflowY: 'auto', overflowX: 'hidden', borderRight: '0px', height: 'calc(100vh - 305px)' }}
                mode="inline"
                onSelect={handleClickMenu}
                selectedKeys={keyMenu}
              >
                {renderMenu()}
              </Menu>
            </div>
          </div>
        </Col>
        <Col span={16}>
          <div style={{ borderLeft: '1px solid #e8e8e8', width: '100%', padding: '16px' }}>
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={10}
              options={defaultMapOption}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {selectAddress &&
                _.map(selectAddress, (item) => (
                  <Marker
                    key={item.addressBookId}
                    position={position}
                    animation={_.get(window, 'google.maps.Animation.DROP')}
                    draggable={true}
                    icon={{
                      url: IconMarker,
                      scaledSize: new window.google.maps.Size(50, 50),
                      anchor: new window.google.maps.Point(12, 42),
                    }}
                    onClick={() => onClickMarker(item.addressBookId)}
                    onDragEnd={onDragEnd}
                  >
                    {dragMarker && dragMarker === item.addressBookId ? (
                      <InfoWindow
                        position={position}
                        onCloseClick={onCloseMarker}
                        visible={visibleInfoWindow}
                        options={{
                          pixelOffset: new window.google.maps.Size(-12.5, 2),
                        }}
                      >
                        <div className="location-map-info-padding" style={{ padding: '12px' }}>
                          <div className="location-map-info-div-margin">
                            <p className="location-map-info-header" style={{ marginBottom: '5px' }}>
                              {item.name}
                            </p>
                            <span className="location-map-info-detail">{item.address}</span>
                          </div>
                          <div className="location-map-info-button-align">
                            <Button_05 key="submit" type="primary" btnsize="wd_df" btnfontsize="smm" onClick={() => handleClickSelect()}>
                              <FormattedMessage id="btnSelectLocation" defaultMessage="Select This Location" />
                            </Button_05>
                          </div>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                ))}
            </GoogleMap>
          </div>
        </Col>
      </Row>
      {/* </Spin> */}
    </Modal>
  );
};

export default TicketLocationModal;
