import React, { useEffect, useState } from 'react';
import { Avatar, Tag, ConfigProvider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import 'moment/locale/th';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import { useAppContext } from '../../../../../../includes/indexProvider';

export default React.memo((props) => {


  const app = useAppContext();
  const langValue = app.state.langValue;

  const dateFormat = 'ddd, MMM DD YYYY';
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;
    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };


  const { information } = props;

  // const information = stateMain.information;
  const formateStartDate = moment(information.startDateView).format('ddd, MMM DD, YYYY');
  const formatDueDate = moment(information.endDateView).format('ddd, MMM DD, YYYY');



  const AssigneeeTaskMock = [{
    imagePath: '',
    name: 'IKEA Bang Yai',
    phone: '02-8457686',
    startDate: 'Mon,Feb 17 ,2020',
    dueDate: 'Mon,Feb 17 ,2020',
    area: 'Ratchada',
    remark: '-',
    address: 'Address: Moo. 6 109 Kanchanaphisek Rd, SaoThong Hin, Bang Yai District, Nonthaburi 11140',
    status: 'Scheduled',
    statusColor: '#67d0ff'
  }]


  return (
    <ConfigProvider locale={locale}>
      <div
        className='assignee-task-details-card'
      >
        {AssigneeeTaskMock.map((item) => (
          <div className="content">
            <div>
              <div className="content-header">
                <div className="content-header-avatar">
                  {information ? <Avatar size={50} src={information.customerPathImage} /> : <Avatar size={50} icon='user' />}
                </div>
                <div className="content-header-name">
                  <p className='content-header-text-p-name'>{information && information.customerName || '-'}</p>
                  <p className='content-header-text-p-phone'>{item.phone}</p>
                </div>
                <div className="content-header-tag">
                  <Tag className="div-details-tag-status" style={{ backgroundColor: information && information.statusColor }}>
                    <p className="div-details-tag-p">{information && information.statusName || '-'}</p>
                  </Tag>
                </div>
              </div>

              <div className="styte-task-details">
                <span className="styte-details-text-p"><FormattedMessage id={`monitorTabDetailModalAssigneeTaskDetailTxtStartDate`} defaultMessage="Plan start date" />: </span>
                <p className='container-detail-task'>{information && formateStartDate || '-'}</p>
              </div>
              <div className="styte-task-details">
                <span className="styte-details-text-p"><FormattedMessage id={`monitorTabDetailModalAssigneeTaskDetailTxtDueDate`} defaultMessage="Due date" />: </span>
                <p className='container-detail-task'>{information && formatDueDate || '-'}</p>
              </div>
              <div className="styte-task-details">
                <span className="styte-details-text-p"><FormattedMessage id={`monitorTabDetailModalAssigneeTaskDetailTxtStartArea`} defaultMessage="Area" />: </span>
                <p className='container-detail-task'>{information && information.area || '-'}</p>
              </div>
              <div className="styte-task-details">
                <span className="styte-details-text-p"><FormattedMessage id={`monitorTabDetailModalAssigneeTaskDetailTxtRemark`} defaultMessage="Remark" />: </span>
                <p className='container-detail-task'>{information && information.remark || '-'}</p>
              </div>
              <div className="styte-details-address-card">
                <span className="styte-details-text-p"><FormattedMessage id={`monitorTabDetailModalAssigneeTaskDetailTxtAddress`} defaultMessage="Address" />: </span>
                <p className='container-detail-task-address'>{information && information.address || '-'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ConfigProvider>
  )
})