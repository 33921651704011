import httpClient from '../../../components/axiosClient';
import moment from 'moment';
import {
  successNotification,
  errorNotification,
} from './notification';
import _ from 'lodash';

const comId = localStorage.getItem('comId');
const memId = localStorage.getItem('memId');

const fncDragStopApi = async (props, newEventData) => {
  // const { args } = props;

  let resourceId = _.get(newEventData, 'ResourceId');
  let memComOldId = _.get(newEventData, 'memComOldId');
  let startTime = moment(_.get(props, 'data.StartTime')).unix();
  let endTime = moment(_.get(props, 'data.EndTime')).unix();
  let orgId = _.get(newEventData, 'OrgId');
  let orgIdOld = _.get(newEventData, 'orgIdOld');
  let memOrgId = _.get(newEventData, 'ResourceOrgId');
  // let typeApi = _.get(props,'typeApi')

  const body = {
    memComId: resourceId === 999999 ? null : resourceId.toString(),
    memComId_old: memComOldId.toString(),
    planStart: startTime,
    planFinish: endTime,
    orgId: orgId.toString(),
    memId: Number(memId),
    version: '2',
    oldOrgId: orgIdOld.toString(),
    memOrgId: memOrgId,
  };

  try {
    const response = await httpClient.put(
      `/v3/task/manager/company/${comId}/monitor/task/${_.get(props, 'data.TaskId')}/reassign?menuType=transportation`,
      // `/v3/task/manager/company/${comId}/monitor/task/548444/reassign`,
      body,
    );

    if (response.status === 200) {
      successNotification(response.data.status.message);
      return response;
    } else {
      errorNotification(response.data.status.message);
      return response;
    }
  } catch (error) {
    if (error.response.status >= 400) {
      errorNotification(error.response.data.status.message);
      return error.response;
    }
  }
}

const fncResizeStopApi = async (props) => {

  let startTime = moment(_.get(props, 'data.StartTime')).unix();
  let endTime = moment(_.get(props, 'data.EndTime')).unix();

  const body = {
    planStart: startTime,
    planFinish: endTime,
    memId: Number(memId)
  };

  try {
    const response = await httpClient.put(
      `/v3/task/manager/company/${comId}/monitor/task/${_.get(props, 'data.TaskId')}/replan?menuType=transportation`,
      // `/v3/task/manager/company/${comId}/monitor/task/asddasdsa/replan`,
      body,
    );

    if (response.status === 200) {
      successNotification(response.data.status.message);
      return response.data;
    } else {
      errorNotification(response.data.status.message);
      return response.data;
    }
  } catch (error) {
    errorNotification(error.response.statusText);
    return error.response;
  }
}

const fncDragTaskPoolApi = async (props) => {

  try {
    const body = {
      memComId: props.resourceId === 999999 ? null : props.resourceId,
      planStart: props.startTime,
      planFinish: props.endTime,
      orgId: props.groupTeam,
      memId: Number(memId)
    };

    const response = await httpClient.put(
      `/v3/task/manager/company/${comId}/monitor/task/${props.taskId}/reassign/taskpool?menuType=transportation`,
      body,
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response;
  }
}

const fncGetDataGanttViewApi = async (props) => {
  // const { startDate, endDate, orgId, search, fieldSearch } = props;


  // try {
  //   const response = await httpClient.get(
  //     `/v3/task/manager/monitor/task?startDate=${startDate}&endDate=${endDate}&com_id=${comId}&org_id=${orgId}&searchBy=${search}&search=${fieldSearch}`
  //   );


  // } catch (error) {
  // }
};


export { fncDragStopApi, fncResizeStopApi, fncDragTaskPoolApi, fncGetDataGanttViewApi }