import React from 'react'
import { Card, Divider, Icon, Checkbox, Row, Col } from 'antd'
import '../index.css'
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'

const ItemRequestFilter = (props) => {
  const { panelWarehouse, setPanelWarehouse, panelItemBrand, setPanelItemBrand, panelItemGroup,
    setPanelItemGroup, panelItemType, setPanelItemType, warehouse, itemBrand, itemGroup, itemType } = props
  const intl = useIntl();

  const handleCheckboxWarehouseChange = (checkedList) => {
    warehouse.setWarehouseSelect(checkedList);
    warehouse.setWarehouseInterminate(!!checkedList.length && checkedList.length < warehouse.warehouseData.length);
    warehouse.setWarehouseCheckAll(checkedList.length === warehouse.warehouseData.length);
  };

  const handleCheckWarehouseAllChange = (e) => {
    warehouse.setWarehouseSelect(e.target.checked ? _.map(warehouse.warehouseData, (item) => { return item.warehouseId }) : []);
    warehouse.setWarehouseInterminate(false);
    warehouse.setWarehouseCheckAll(e.target.checked);
  };

  const handleCheckboxItemBrandChange = (checkedList) => {
    itemBrand.setItemBrandSelect(checkedList);
    itemBrand.setItemBrandInterminate(!!checkedList.length && checkedList.length < itemBrand.itemBrandData.length);
    itemBrand.setItemBrandCheckAll(checkedList.length === itemBrand.itemBrandData.length);
  };

  const handleCheckAllItemBrandChange = (e) => {
    itemBrand.setItemBrandSelect(e.target.checked ? _.map(itemBrand.itemBrandData, (item) => { return item.itemBrandId }) : []);
    itemBrand.setItemBrandInterminate(false);
    itemBrand.setItemBrandCheckAll(e.target.checked);
  };

  const handleCheckboxItemGroupChange = (checkedList) => {
    itemGroup.setItemGroupSelect(checkedList);
    itemGroup.setItemGroupInterminate(!!checkedList.length && checkedList.length < itemGroup.itemGroupData.length);
    itemGroup.setItemGroupCheckAll(checkedList.length === itemGroup.itemGroupData.length);
  };

  const handleCheckAllItemGroupChange = (e) => {
    itemGroup.setItemGroupSelect(e.target.checked ? _.map(itemGroup.itemGroupData, (item) => { return item.itemGroupId }) : []);
    itemGroup.setItemGroupInterminate(false);
    itemGroup.setItemGroupCheckAll(e.target.checked);
  };

  const handleCheckboxItemTypeChange = checkedList => {
    itemType.setItemTypeSelect(checkedList);
    itemType.setItemTypeInterminate(!!checkedList.length && checkedList.length < itemType.itemTypeData.length);
    itemType.setItemTypeCheckAll(checkedList.length === itemType.itemTypeData.length);
  };

  const handleCheckAllItemTypeChange = e => {
    itemType.setItemTypeSelect(e.target.checked ? _.map(itemType.itemTypeData, (item) => { return item.itemTypeId }) : []);
    itemType.setItemTypeInterminate(false);
    itemType.setItemTypeCheckAll(e.target.checked);
  };



  return (
    <div>
      <Card
        className='itemRequestCard'
        title={intl.formatMessage({ id: 'filterItemRequest', defaultMessage: 'Filter' })}
        bodyStyle={{ minHeight: 'calc(100vh - 165px)', padding: 'unset' }}
      >
        <div className='itemRequestFilterContent'>
          <div>
            <Checkbox
              indeterminate={warehouse.warehouseInterminate}
              onChange={handleCheckWarehouseAllChange}
              checked={warehouse.warehouseCheckAll}
            >
              <span className='itemRequestFilterContentHeadText'><FormattedMessage id="searchItemRequestTextWarehouse" defaultMessage="Warehouse" /></span>
            </Checkbox>
          </div>
          <div>
            <span onClick={() => setPanelWarehouse((current) => !current)} style={{ cursor: 'pointer' }}>
              <FormattedMessage id="filterTaskSelected" defaultMessage="Selected" /> {_.size(warehouse.warehouseSelect)} {panelWarehouse ? <Icon type="up" /> : <Icon type="down" />}
            </span>
          </div>
        </div>
        {
          panelWarehouse ? (
            <Checkbox.Group
              style={{ width: '100%', marginLeft: '32px' }}
              value={warehouse.warehouseSelect}
              onChange={handleCheckboxWarehouseChange}
            >
              <Row gutter={[16, 16]}>
                {
                  _.map(warehouse.warehouseData, (item) => (
                    <Col span={24}>
                      <Checkbox value={item.warehouseId}>{item.warehouseName}</Checkbox>
                    </Col>
                  ))
                }
              </Row>
            </Checkbox.Group>
          ) : null
        }
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div className='itemRequestFilterContent'>
          <div>
            <Checkbox
              indeterminate={itemBrand.itemBrandInterminate}
              onChange={handleCheckAllItemBrandChange}
              checked={itemBrand.itemBrandCheckAll}
            >
              <span className='itemRequestFilterContentHeadText'><FormattedMessage id="searchItemRequestTextItemBrand" defaultMessage="Item Brand" /></span>
            </Checkbox>
          </div>
          <div>
            <span onClick={() => setPanelItemBrand((current) => !current)} style={{ cursor: 'pointer' }}>
              <FormattedMessage id="filterTaskSelected" defaultMessage="Selected" /> {_.size(itemBrand.itemBrandSelect)} {panelItemBrand ? <Icon type="up" /> : <Icon type="down" />}
            </span>
          </div>
        </div>
        {
          panelItemBrand ? (
            <Checkbox.Group
              style={{ width: '100%', marginLeft: '32px' }}
              value={itemBrand.itemBrandSelect}
              onChange={handleCheckboxItemBrandChange}
            >
              <Row gutter={[16, 16]}>
                {
                  _.map(itemBrand.itemBrandData, (item) => (
                    <Col span={24}>
                      <Checkbox value={item.itemBrandId}>{item.itemBrandName}</Checkbox>
                    </Col>
                  ))
                }
              </Row>
            </Checkbox.Group>
          ) : null
        }
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div className='itemRequestFilterContent'>
          <div>
            <Checkbox
              indeterminate={itemGroup.itemGroupInterminate}
              onChange={handleCheckAllItemGroupChange}
              checked={itemGroup.itemGroupCheckAll}
            >
              <span className='itemRequestFilterContentHeadText'><FormattedMessage id="searchItemRequestTextItemGroup" defaultMessage="Item Group" /></span>
            </Checkbox>
          </div>
          <div>
            <span onClick={() => setPanelItemGroup((current) => !current)} style={{ cursor: 'pointer' }}>
              <FormattedMessage id="filterTaskSelected" defaultMessage="Selected" /> {_.size(itemGroup.itemGroupSelect)} {panelItemGroup ? <Icon type="up" /> : <Icon type="down" />}
            </span>
          </div>
        </div>
        {
          panelItemGroup ? (
            <Checkbox.Group
              style={{ width: '100%', marginLeft: '32px' }}
              value={itemGroup.itemGroupSelect}
              onChange={handleCheckboxItemGroupChange}
            >
              <Row gutter={[16, 16]}>
                {
                  _.map(itemGroup.itemGroupData, (item) => (
                    <Col span={24}>
                      <Checkbox value={item.itemGroupId}>{item.itemGroupName}</Checkbox>
                    </Col>
                  ))
                }
              </Row>
            </Checkbox.Group>
          ) : null
        }
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div className='itemRequestFilterContent'>
          <div>
            <Checkbox
              indeterminate={itemType.itemTypeInterminate}
              onChange={handleCheckAllItemTypeChange}
              checked={itemType.itemTypeCheckAll}
            >
              <span className='itemRequestFilterContentHeadText'><FormattedMessage id="searchItemRequestTextItemType" defaultMessage="Item Type" /></span>
            </Checkbox>
          </div>
          <div>
            <span onClick={() => setPanelItemType((current) => !current)} style={{ cursor: 'pointer' }}>
              <FormattedMessage id="filterTaskSelected" defaultMessage="Selected" /> {_.size(itemType.itemTypeSelect)} {panelItemType ? <Icon type="up" /> : <Icon type="down" />}
            </span>
          </div>
        </div>
        {
          panelItemType ? (
            <Checkbox.Group
              style={{ width: '100%', marginLeft: '32px' }}
              value={itemType.itemTypeSelect}
              onChange={handleCheckboxItemTypeChange}
            >
              <Row gutter={[16, 16]}>
                {
                  _.map(itemType.itemTypeData, (item) => (
                    <Col span={24}>
                      <Checkbox value={item.itemTypeId}>{item.itemTypeName}</Checkbox>
                    </Col>
                  ))
                }
              </Row>
            </Checkbox.Group>
          ) : null
        }
      </Card>
    </div>
  )
}

export default ItemRequestFilter
