import React, { useEffect, useState, useContext } from 'react';
import { Modal, Row, Col } from 'antd';
import Button_01 from '../../v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { successNotification, errorNotification } from '../../v2/notification';
import moment from 'moment';
import './css/index.css';
import { PlanningContext } from '../../../pages/load-and-route/context';
import Vehicle from './component/vehicle';
import SimpleMap from './component/map';

const comCode = localStorage.getItem('comCode');

export default (props) => {
  const { data, visible, onClose } = props;
  const { fnc, state, setState } = useContext(PlanningContext);
  const intl = useIntl();

  return (
    <div>
      <Modal
        title={`${intl.formatMessage({ id: 'previewOrderPlaning', defaultMessage: 'Preview Order Planning' })}`}
        width={'90%'}
        className="modal-waiting-order"
        centered={true}
        visible={visible}
        onCancel={onClose}
        style={{marginTop: '25px'}}
        bodyStyle={{ height: 'calc(100vh - 155px)',padding: '0px 8px'}}
        footer={[
          <Button_01 type="primary" btnwidth="wd_at" onClick={onClose}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_01>,
        ]}
      >
        <div>
          <Row gutter={[16, 16]}>
            <Col style={{padding: '8px 0px 0px 0px'}} span={6}>
              <Vehicle/>
            </Col>
            <Col style={{padding: '8px 0px 0px 0px'}} span={18}>
              <SimpleMap/>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
