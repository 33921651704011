import React, { useContext, useState, useEffect } from 'react';
import { PageEngagement } from '../../../config/page-settings';
import { getPostOne } from '../../../controllers/engagement';

import PostHeaderView from '../../../components/post/post-header-view-modal';
import PostMessage from '../../../components/post/post-message-view-modal';
import PostAddress from '../../../components/post/post-address';
import PostTags from '../../../components/post/post-tags-view-modal';
import { Modal } from 'antd';
import _ from 'lodash';
import {
  Preview01,
  Preview02,
  Preview03,
  Preview04,
  Preview05,
  PreviewPlus,
  PreviewSingle,
} from '../../../components/preview-picture/preview-picture.js';
import Button01 from '../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import stylesPost from '../css/engagement-post.css';
import stylesIndex from '../css/index.css';

export default React.memo((props) => {
  const { } = props;
  const { fnc, postId, visibleViewPost } = useContext(PageEngagement);


  const [checked, setChecked] = useState('default');
  const [postViewData, setPostViewData] = useState([]);
  const [NumberImage, setNumberImage] = useState(0)
  // console.log('postViewData:::: ', postViewData);


  useEffect(() => {
    const getApi = async () => {
      if (postId) {
        const response = await getPostOne(postId);
        setPostViewData(response[0]);
      }
    }
    getApi();
  }, [postId]);

  const handleHashtag = (data) => {

  }

  const handleAddress = (data) => {
    if (data) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'addressLat')}, ${_.get(data, 'addressLng',)}`, '_blank');
    }
  }

  const handleTags = (data) => {
    let isPDF = /pdf/g.test(data.name.toLowerCase());
    if (isPDF) return window.open(data.pathImage, '_blank');
  }

  const handlePreviewImage = (page) => {
    // setChecked('view');
    setNumberImage(page)

  }

  const handleBack = (data) => {
    setChecked('default');
  }

  const fncPreview = (imageList, setVisible) => {
    if (imageList && imageList.length > 0) {
      let sum = imageList.length;
      switch (sum) {
        case 1:
          return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        case 2:
          return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        case 3:
          return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        case 4:
          return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        case 5:
          return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        default:
          return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      }
    } else {
      return null
    }
  };

  const handleClose = () => {
    fnc.handleCancelModalViewPost();
    setChecked('default');
    setNumberImage(0)
  }





  return (
    <div className='engagement-post'>
      <Modal
        className='engagement-modal-create-view-edit'
        title='View Post'
        width={650}
        visible={visibleViewPost}
        onCancel={handleClose}
        footer={[
          <Button01
            key='submit'
            type='primary'
            btnsize='wd_df'
            onClick={handleClose}
          >
            <FormattedMessage id='btnClose' defaultMessage='Close' />
          </Button01>
        ]}
      >
        <div className='engagement-modal-body'>
          <PostHeaderView postData={postViewData} />

          <div className='engagement-modal-create-post'>
            <PostMessage postData={postViewData} handleHashtag={handleHashtag} />
            {postViewData && postViewData.addressName !== '' ? (
              <PostAddress postData={postViewData} handleAddress={handleAddress} />
            ) : null}
            {postViewData && postViewData.fileList !== '' ? (
              <PostTags postData={postViewData.fileList} handleTags={handleTags} />
            ) : null}
          </div>

          <div>
            {fncPreview(postViewData.imageList)}
            {/* {postViewData && checked === 'default' ?  :
              checked === 'view' ? (
                <div>
                  <PreviewSingle action='view' imageList={postViewData.imageList} handleBack={handleBack} />

                </div>
              ) : null
            } */}
          </div>
        </div>
       
      </Modal>
    </div>
  );
}, (prev, next) => {
  return true;
});