import React from 'react'
import { Form, Row, Col, Input, Divider, InputNumber } from 'antd'
import LabeRequireForm from '../../../components/label-required-form'
import { useIntl, FormattedMessage } from 'react-intl'
import LoadmoreCustomer from '../../../components/loadmore/customer'
import LoadmoreProject from '../../../components/loadmore/project'
import LoadmoreTaskType from '../../../components/loadmore/taskType'
import LoadmoreSource from '../../../components/loadmore/source'
import LoadmoreDestination from '../../../components/loadmore/destination'
import './index.css'
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number'
import _ from 'lodash'
import SuffixInput from '../../../components/v2/suffix'
import { getDistance } from '../../../controllers/task/setting'
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency'

const ModalSetttingForm = (props) => {
  const { form, selectData, setSelectData, type, data, visible, refPrice } = props
  const { getFieldDecorator, setFieldsValue, getFieldValue, setFields } = form
  const intl = useIntl()

  const setDataCustomer = (value) => {
    setFieldsValue({
      ['destination']: undefined,
    });
    setSelectData({
      ...selectData,
      customer: value
    })
  }

  const setDataProject = (value) => {
    setSelectData({
      ...selectData,
      project: value
    })
  }

  const setDataTaskType = (value) => {
    setSelectData({
      ...selectData,
      taskType: value
    })
  }

  const setDataSource = (value) => {
    setFieldsValue({
      ['destination']: undefined,
    });
    setSelectData({
      ...selectData,
      source: value
    })
  }

  const setDataDestination = async (value) => {
    if (_.get(value, 'id') !== "notSpecified") {
      if (_.get(value, 'auto') && _.get(value, 'distance') === 0) {
        const body = {
          "sourceId": _.get(value, 'companyAddressId'),
          "destinationId": _.get(value, 'customerAddressId')
        }
        const response = await getDistance(body)
        setFieldsValue({
          ['distance']: numberFormatter(_.get(response, 'data.data.distanceMeter', 0) / 1000),
        });
      } else if (_.get(value, 'auto') && _.get(value, 'distance') > 0) {
        setFieldsValue({
          ['distance']: numberFormatter(_.get(value, 'distance', 0)),
        });
      } else if (!_.get(value, 'auto')) {
        setFieldsValue({
          ['distance']: numberFormatter(_.get(value, 'heavyCar', 0)),
        });
      }
    } else {
      setFieldsValue({
        ['distance']: '0',
      });
    }
    setSelectData({
      ...selectData,
      destination: value
    })
  }

  const validatorMinPriceValue = (rule, value, callback) => {
    const minValue = parseFloat(value || 0)
    const maxValue = parseFloat(getFieldValue('gMaximum') || 0)
    if (minValue) {
      if (minValue > maxValue) {
        callback(<FormattedMessage id="modalTaskSettingGMinimumVLDT" defaultMessage="Gas Price (Minimum) more than (Maximum)" />);
      } else {
        setFields({
          gMaximum: {
            value: maxValue,
            errors: null
          }
        });
        callback();
      }
    } else {
      callback();
    }
  };

  const validatorMaxPriceValue = (rule, value, callback) => {
    const maxValue = parseFloat(value || 0)
    const minValue = parseFloat(getFieldValue('gMinimum') || 0)
    if (maxValue) {
      if (maxValue < minValue) {
        callback(<FormattedMessage id="modalTaskSettingGMinimumVLDT" defaultMessage="Gas Price (Maximum) less than (Minimum)" />);
      } else {
        setFields({
          gMinimum: {
            value: minValue,
            errors: null
          }
        });
        callback();
      }
    } else {
      callback();
    }
  };

  const handleChangeAllowance = (value) => {
    const allowance = parseFloat(value || 0)
    setFieldsValue({
      ['driver1']: (allowance).toFixed(2),
      ['driver2']: 0.00,
    });
  }

  const handleChangeDriver1 = (value) => {
    const allowance = parseFloat(getFieldValue('allowance') || 0)
    const driver1 = parseFloat(value || 0)
    setFieldsValue({
      ['driver2']: (allowance - driver1).toFixed(2),
    });
  }

  const validatorMaxAllowanceDriver1 = (rule, value, callback) => {
    const maxValue = parseFloat(value || 0)
    const allowance = parseFloat(getFieldValue('allowance') || 0)
    if (maxValue) {
      if (maxValue > allowance) {
        callback(<FormattedMessage id={`modalTaskSettingDriver1${type === 'allowance' ? 'Allowance' : 'TripAllowance'}VLDT`} defaultMessage={`Driver 1 more than ${type === 'allowance' ? 'Allowance' : 'Trip Allowance'}`} />);
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const handleChangeDriver2 = (value) => {
    const allowance = parseFloat(getFieldValue('allowance') || 0)
    const driver2 = parseFloat(value || 0)
    setFieldsValue({
      ['driver1']: (allowance - value).toFixed(2),
    });
  }

  const validatorMaxAllowanceDriver2 = (rule, value, callback) => {
    const maxValue = parseFloat(value || 0)
    const allowance = parseFloat(getFieldValue('allowance') || 0)
    if (maxValue) {
      if (maxValue > allowance) {
        callback(<FormattedMessage id={`modalTaskSettingDriver2${type === 'allowance' ? 'Allowance' : 'TripAllowance'}VLDT`} defaultMessage={`Driver 2 more than ${type === 'allowance' ? 'Allowance' : 'Trip Allowance'}`} />);
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const currencyFormatter = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const currencyParser = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\$\s?|(,*)/g, '');
    }
  };

  return (
    <Form form={form} layout="vertical" className='transporationSettingForm'>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <LoadmoreCustomer
            form={form}
            setData={setDataCustomer}
            initialId={_.get(data, 'customerDetail.id') === null ? 'notSpecified' : _.get(data, 'customerDetail.id', undefined)}
            initialName={_.get(data, 'customerDetail.name') === null ? 'ไม่กำหนด' : _.get(data, 'customerDetail.name', undefined)}
            visible={visible}
          />
        </Col>
        <Col span={12}>
          <LoadmoreProject
            form={form}
            setData={setDataProject}
            initialId={_.get(data, 'projectDetail.id') === null ? 'notSpecified' : _.get(data, 'projectDetail.id', undefined)}
            initialName={_.get(data, 'projectDetail.name') === null ? 'ไม่กำหนด' : _.get(data, 'projectDetail.name', undefined)}
            visible={visible}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <LoadmoreTaskType
            form={form}
            setData={setDataTaskType}
            initialId={_.get(data, 'taskTypeDetail.id') === null ? 'notSpecified' : _.get(data, 'taskTypeDetail.id', undefined)}
            initialName={_.get(data, 'taskTypeDetail.name') === null ? 'ไม่กำหนด' : _.get(data, 'taskTypeDetail.id', undefined)}
            visible={visible}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <LoadmoreSource
            form={form}
            setData={setDataSource}
            initialId={_.get(data, 'sourceLocationDetail.id') === null ? 'notSpecified' : _.get(data, 'sourceLocationDetail.id', undefined)}
            initialName={_.get(data, 'sourceLocationDetail.name') === null ? 'ไม่กำหนด' : _.get(data, 'sourceLocationDetail.id', undefined)}
            visible={visible}
          />
        </Col>
        <Col span={12}>
          <LoadmoreDestination
            form={form}
            setData={setDataDestination}
            staticId={_.get(data, 'customerDetail.id')}
            companyId={_.get(selectData, 'source.comAddressBookId')}
            customerId={_.get(selectData, 'customer.customerId')}
            disabled={(_.get(selectData, 'source.comAddressBookId') && _.get(selectData, 'customer.customerId')) ||
              (_.get(selectData, 'customer.id') === "notSpecified" || _.get(selectData, 'source.id') === "notSpecified") ? false : true
            }
            initialId={_.get(data, 'destinationLocationDetail.id') === null ? 'notSpecified' : _.get(data, 'destinationLocationDetail.id', undefined)}
            initialName={_.get(data, 'destinationLocationDetail.name') === null ? 'ไม่กำหนด' : _.get(data, 'destinationLocationDetail.name', undefined)}
            visible={visible}
          />
        </Col>
      </Row>
      {
        type === 'allowance' ? (
          
          <Row gutter={[16, 8]} style={{display: 'none'}}>
            <Col span={12}>
              <Form.Item label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalTaskSettingDistance', defaultMessage: 'Distance (km)' })}
                  req={false}
                />}
              >
                {
                  getFieldDecorator('distance', {
                    initialValue: numberFormatter(_.get(data, 'detail.distance', 0)),
                  })(
                    <Input defaultValue="-" disabled={true} />
                  )
                }
              </Form.Item>
            </Col>
          </Row>
        ) : null
      }
      {
        type === 'freight' ? (
          <>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalTaskSettingGMinimum', defaultMessage: 'Gas Price (Minimum)' })}
                    req={true}
                  />}
                >
                  {
                    getFieldDecorator('gMinimum', {
                      initialValue: _.get(data, 'detail.minPrice', 0.00),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'modalTaskSettingGMinimumVLD',
                            defaultMessage: 'Please Enter Gasprice (Minimum)',
                          }),
                        },
                        {
                          validator: validatorMinPriceValue,
                        }
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        placeholder={intl.formatMessage({ id: 'modalTaskSettingGMinimumPH', defaultMessage: 'Enter Gasprice (Minimum)' })}
                        suffix='THB'
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        ref={refPrice.inputNumberPrice1}
                      />
                    )
                  }
                  <SuffixInput text="THB" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalTaskSettingGMaximum', defaultMessage: 'Gas Price (Maximum)' })}
                    req={true}
                  />}
                >
                  {
                    getFieldDecorator('gMaximum', {
                      initialValue: _.get(data, 'detail.maxPrice', 0.00),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'modalTaskSettingGMaximumVLD',
                            defaultMessage: 'Please Enter Gasprice (Maximum)',
                          }),
                        },
                        {
                          validator: validatorMaxPriceValue,
                        }
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        placeholder={intl.formatMessage({ id: 'modalTaskSettingGMaximumPH', defaultMessage: 'Enter Gasprice (Maximum)' })}
                        suffix='THB'
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        ref={refPrice.inputNumberPrice2}
                      />
                    )
                  }
                  <SuffixInput text="THB" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalTaskSettingPricePerTrip', defaultMessage: 'Price per Trip' })}
                    req={true}
                  />}
                >
                  {
                    getFieldDecorator('pricePerTrip', {
                      initialValue: _.get(data, 'detail.price', 0.00),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'modalTaskSettingPricePerTripVLD',
                            defaultMessage: 'Please Enter Price per Trip',
                          }),
                        },
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        placeholder={intl.formatMessage({ id: 'modalTaskSettingPricePerTripPH', defaultMessage: 'Enter Price per Trip' })}
                        suffix='THB'
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        ref={refPrice.inputNumberPrice3}
                      />
                    )
                  }
                  <SuffixInput text="THB" />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null
      }
      {
        type === 'allowance' || type === 'tripAllowance' ? (
          <>
            <Divider type='horizontal' />
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item label={
                  <LabeRequireForm
                    text={intl.formatMessage({
                      id: type === 'allowance' ? 'modalTaskSettingAllowance' : 'modalTaskSettingTripAllowance',
                      defaultMessage: type === 'allowance' ? 'Allowance' : 'Trip Allowance'
                    })}
                    req={true}
                  />}
                >
                  {
                    getFieldDecorator('allowance', {
                      initialValue: _.get(data, 'detail.price', 0.00),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: type === 'allowance' ? 'modalTaskSettingAllowanceVLD' : 'modalTaskSettingTripAllowanceVLD',
                            defaultMessage: type === 'allowance' ? 'Please Enter Allowance' : 'Please Enter Trip Allowance',
                          }),
                        },
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        placeholder={intl.formatMessage({ id: type === 'allowance' ? 'modalTaskSettingAllowancePH' : 'modalTaskSettingTripAllowancePH', defaultMessage: type === 'allowance' ? 'Enter Allowance' : 'Enter Trip Allowance' })}
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        onChange={handleChangeAllowance}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        ref={refPrice.inputNumberPrice1}
                      />
                    )
                  }
                  <SuffixInput text="THB" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalTaskSettingDriver1', defaultMessage: 'Driver 1' })}
                    req={true}
                  />}
                >
                  {
                    getFieldDecorator('driver1', {
                      initialValue: _.get(data, 'detail.price1', 0.00),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'modalTaskSettingDriver1VLD',
                            defaultMessage: 'Please Enter Driver 1',
                          }),
                        },
                        {
                          validator: validatorMaxAllowanceDriver1
                        }
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        placeholder={intl.formatMessage({ id: 'modalTaskSettingDriver1PH', defaultMessage: 'Enter Driver 1' })}
                        suffix='THB'
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        onChange={handleChangeDriver1}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        ref={refPrice.inputNumberPrice2}
                      />
                    )
                  }
                  <SuffixInput text="THB" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalTaskSettingDriver2', defaultMessage: 'Driver 2' })}
                    req={true}
                  />}
                >
                  {
                    getFieldDecorator('driver2', {
                      initialValue: _.get(data, 'detail.price2', 0.00),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'modalTaskSettingDriver2VLD',
                            defaultMessage: 'Please Enter Driver 2',
                          }),
                        },
                        {
                          validator: validatorMaxAllowanceDriver2
                        }
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        placeholder={intl.formatMessage({ id: 'modalTaskSettingDriver2PH', defaultMessage: 'Enter Driver 2' })}
                        suffix='THB'
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        onChange={handleChangeDriver2}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        ref={refPrice.inputNumberPrice2}
                      />
                    )
                  }
                  <SuffixInput text="THB" />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null
      }
    </Form >
  )
}

export default ModalSetttingForm
