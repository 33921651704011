import React, { useState, useEffect, useRef } from 'react';
import { Modal, Layout, Badge } from 'antd';
import Scenario from './scenario';
import Schedule from './schedule';
import { PagePlanningSettings } from './page-planning-settings';
import { PageSettings } from '../../../../config/page-settings';

import moment from 'moment';
import httpClient from '../../../../components/axiosClient';
import notification from '../../../../components/v2.1.0/notification';
import _ from 'lodash';
import Provider from '../../provider';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from '../css/shift.css';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';

const { Content, Sider } = Layout;

export default React.memo((props) => {
  const intl = useIntl();
  const app = Provider.useAppContext();
  const { comId, orgId, visibled, onCancel, yearMonth, resourcesOld } = props;
  const [resources, setResources] = useState([]);
  const [scenario, setScenario] = useState();
  const [scenarioIndex, setScenarioIndex] = useState(0);
  const [scenarios, setScenarios] = useState([]);
  const [shiftTypeRule, setShiftTypeRule] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const thisYearMonth = moment(app.state.shiftsYear + ' ' + app.state.shiftsMonth, 'YYYY MMMM').format('YYYY-MM');
  const thisMonth = moment(app.state.shiftsMonth, 'MMMM').format('MM');
  const scheduleObj = useRef();

  useEffect(() => {
    const getScenarios = async () => {
      try {
        const result = await httpClient.post(`/v1/resource/manager/shift/planning/company/${comId}/organization/${orgId}`, {
          year: app.state.shiftsYear,
          month: thisMonth,
        });
        if (result.status === 200) {
          const setMembers = [];
          _.forEach(result.data.members, (e) => {
            const filterShiftMemberArr = _.filter(result.data.scenario[0].scenarioShiftList, (el) => {
              return el.ResourceId === e.memId && el.isLeave === false && el.isHoliday === false && el.orgId === orgId.toString()
                ? true
                : false;
            });

            const findIndexResourceOld = _.findIndex(resourcesOld, (el) => {
              return el.Id === e.memId ? true : false;
            });

            if (_.size(filterShiftMemberArr) > 0 || findIndexResourceOld > -1) {
              setMembers.push({
                Id: e.memId,
                memComId: e.memComId,
                Text: e.fullname,
                email: e.email,
                phone: e.phone,
                pathImage: e.pathImage,
              });
            }
          });

          if (_.size(setMembers) <= 11) {
            for (let i = _.size(setMembers); i <= 11; i++) {
              setMembers.push({
                Id: 'mockdata-' + i,
                memComId: '',
                Text: '',
                email: '',
                phone: '',
                pathImage: '',
              });
            }
          }

          setResources(setMembers);
          setShiftTypeRule(result.data.shiftTypeRule);
          setScenarios(result.data.scenario);
          setScenario(result.data.scenario[0].scenarioId);
          setScenarioIndex(0);
        } else {
          setScenarios([]);
        }
      } catch (error) {
        setScenarios([]);
      }
    };

    const getShiftSettings = async () => {
      try {
        const result = await httpClient.get(`/resource/manager/organization/${orgId}/shift/rules?comId=${comId}`);
        if (result.status === 200) {
          setShiftTypeRule(result.data.shiftTypeRules);
        } else {
          setShiftTypeRule([]);
        }
      } catch (error) {
        setShiftTypeRule([]);
      }
    };

    getScenarios();
    // getShiftSettings();
  }, [orgId]);

  const handleChangeScenario = ({ key }) => {
    const found = scenarios.findIndex((o) => o.scenarioId === key);
    setScenario(key);
    setScenarioIndex(found);
  };

  const handleApply = async () => {
    // return;

    setConfirmLoading(true);

    const payload = [];

    _.forEach(scheduleObj.current.eventsData, (shift) => {
      // _.forEach(scenarios[scenarioIndex].scenarioShiftList, (shift) => {
      if (parseInt(shift.orgId) === parseInt(orgId)) {
        const shiftDate = moment(shift.StartTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
        const resourceIndex = _.findIndex(payload, (o) => o.memId === shift.ResourceId);
        if (resourceIndex >= 0) {
          const shiftIndex = _.findIndex(payload[resourceIndex].shifts, (o) => o.shiftMasterId === shift.ShiftMasterId);
          if (shiftIndex >= 0) {
            payload[resourceIndex].shifts[shiftIndex].shiftDate.push(shiftDate);
          } else {
            const shiftDoc = {
              shiftMasterId: shift.ShiftMasterId,
              shiftDate: [shiftDate],
            };
            payload[resourceIndex].shifts.push(shiftDoc);
          }
        } else {
          const resourceDoc = {
            memId: shift.ResourceId,
            shifts: [
              {
                shiftMasterId: shift.ShiftMasterId,
                shiftDate: [shiftDate],
              },
            ],
          };
          payload.push(resourceDoc);
        }
      }
    });

    try {
      const result = await httpClient.post(
        `/resource/manager/company/${comId}/organization/${orgId}/shift-planning/form-date/${thisYearMonth}`,
        { resources: payload }
      );
      if (result.data.status === 200) {
        notification({ status: 'success', message: result.data.message });
      } else {
        notification({ status: 'success', message: result.data.message });
      }
    } catch (error) {
      notification({ status: 'error', message: 'Error.' });
    }

    setConfirmLoading(false);

    let year = moment(thisYearMonth, 'YYYY-MM').format('YYYY');
    let month = moment(thisYearMonth, 'YYYY-MM').format('MMMM');
    onCancel(year, month);
  };

  return (
    <PagePlanningSettings.Provider
      value={{
        scheduleObj,
        thisYearMonth,
        scenario,
        scenarioIndex,
        scenarios,
        resources,
        shiftTypeRule,
        orgId,
        handleChangeScenario,
      }}
    >
      <Modal
        width={'90%'}
        centered
        // style={{
        //   height: '85vh'
        // }}
        title={
          <>
            <div
              style={{
                position: 'relative',
                height: 55,
                top: -16,
                marginBottom: -33,
                paddingTop: 16,
                fontWeight: 500,
                color: '#1D3557',
              }}
            >
              <FormattedMessage id="orgShiftPlanningLblShift" defaultMessage="Shift Planning" />{' '}
              <FormattedMessage id="orgShiftPlanningLblOn" defaultMessage="On" />{' '}
              {thisYearMonth ? moment(thisYearMonth, 'YYYY-MM').format('MMMM YYYY') : ''}
              {/* {' - '}  */}
              <Badge className="shift-planning-dot" color="#1D3557" />
              {app.state.organizationsDetail.name}
            </div>
          </>
        }
        visible={visibled}
        okText={intl.formatMessage({ id: 'orgShiftPlanningBtnApply', defaultMessage: 'Apply' })}
        cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
        onOk={handleApply}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        // bodyStyle={{
        //   padding: 0,
        //   padding: '16px',
        //   height: '81vh',
        //   overflowY: 'hidden'
        // }}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={onCancel}>
            Return
          </Button02>,
          <Button01 key="submit" type="primary" fontsize="sm" btnsize="wd_df" onClick={handleApply}>
            Submit
          </Button01>,
        ]}
      >
        <Layout
          style={{
            // height: '60vh',
            padding: 0,
            background: '#fff',
          }}
        >
          {/* <Sider width={200} style={{ background: '#fff', inlineSize: 200 }}>
            <div 
              style={{ 
                textAlign: 'center', 
                width: 200, 
                height: 50, 
                marginTop: 1, 
                padding: 15, 
                background: '#fff', 
                borderRight: '1px solid #e8e8e8', 
                borderBottom: '1px solid #e8e8e8', 
                lineHeight: 1.5, 
                color: '#000000', 
                fontSize: 15 }}
            >
              {thisYearMonth ? moment(thisYearMonth, 'YYYY-MM').format('MMMM YYYY') : ''}
            </div> 
            <Scenario />
          </Sider> */}
          <Content style={{ padding: 0, background: '#fff' }}>
            <Schedule />
          </Content>
        </Layout>
      </Modal>
    </PagePlanningSettings.Provider>
  );
});
