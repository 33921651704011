import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';

import { Table, Divider, Tag, Button, Popover } from 'antd';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import './css/index.css'

const AssetclaimSummaryTable = (props) => {
  const dataSource = _.get(props.claimData, 'claimHistory') || [];
  const columns = _.get(props, 'columns') || [];
  const isLoading = _.get(props, 'isLoading');
  const page = _.get(props, 'page');
  const handleActionViewEdit = _.get(props, 'handleActionViewEdit');
  const totalItems = _.get(props.claimData, 'totalItems') || 0;

  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                {/* <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P36PG1C1', 'P36PG1C1A4') ? ( */}
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleActionViewEdit(record, 'view')}>
                  <FormattedMessage id="btnView" defaultMessage="View" />
                </Button>
                {/* //       ) : null}
                //     </div>
                //   )}
                // </PageSettings.Consumer> */}
              </>

              <>
                {/* <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P36PG1C1', 'P36PG1C1A3') ? ( */}
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleActionViewEdit(record, 'edit')}>
                  <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                </Button>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleActionViewEdit(record, 'delete')}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
                {/* ) : null}
                    </div>
                  )}
                </PageSettings.Consumer> */}
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };
  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        className="custom-table-claim"
        dataSource={dataSource || []}
        columns={columns || []}
        loading={isLoading}
        onChange={_.get(props, 'handleChange')}
        rowKey={(record) => record.claimId}
        scroll={{ x: 2000 }}
        // scroll={{ x: _.size(dataSource) > 0 ? 2000 : 0 }}
        pagination={{
          total: totalItems,
          current: page,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          showTotal: showTotal,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />

      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}

      <div className="total-items-member">
        <span style={{ fontSize: '13px' }}>
          <FormattedMessage id="lblTotal" defaultMessage="Total" />
          {` ${totalItems || 0} `}
          <FormattedMessage id="lblitems" defaultMessage="items" />
        </span>
      </div>
    </div>
  );
};
export default AssetclaimSummaryTable;
