import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { Icon, Checkbox, Col, Row, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import AssetSummaryContext from '../context';
import _ from 'lodash';
import {
  getInitialAssetClass,
  getTypeAsset,
  getBrandAsset,
  getModelAsset,
  getStatusAsset,
} from '../../../controllers/asset-resource/asset-controller';
import './css/filterAssetClass.css';
import AssetAdvanceSearch from './advanceAssetClass';

const CheckboxGroup = Checkbox.Group;
let pageSige = 10;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const FilterAssetClass = () => {
  const intl = useIntl();
  const typeScrollRef = useRef(null);
  const brandScrollRef = useRef(null);
  const modelScrollRef = useRef(null);

  const { state, setState, fnc } = useContext(AssetSummaryContext);
  const {
    assetSelect,
    assetSelectObj,
    checkedType,
    checkedBrand,
    checkedModel,
    checkedStatus,
    mainTrigger,
    dataChecked,
    dataAsset,
  } = state;

  const { setCheckedType, setCheckedBrand, setCheckedModel, setCheckedStatus, setTriggerItem, setDataAsset, setDataChecked } = setState;

  const [typePage, setTypePage] = useState(1);
  const [dataType, setDataType] = useState([]);
  const [dataSliceType, setDataSliceType] = useState([]);
  const [checkedListType, setCheckedListType] = useState([]);
  const [planOptionsType, setPlanOptionsType] = useState([]);
  const [visiblePanelType, setVisiblePanelType] = useState(false);
  const [checkAllType, setCheckAllType] = useState(true);
  const [indeterminateType, setIndeterminateType] = useState(false);
  const [loadingType, setLoadingType] = useState(false);

  const [brandPage, setBrandPage] = useState(1);
  const [dataBrand, setDataBrand] = useState([]);
  const [dataSliceBrand, setDataSliceBrand] = useState([]);
  const [checkedListBrand, setCheckedListBrand] = useState([]);
  const [planOptionsBrand, setPlanOptionsBrand] = useState([]);
  const [visiblePanelBrand, setVisiblePanelBrand] = useState(false);
  const [checkAllBrand, setCheckAllBrand] = useState(true);
  const [indeterminateBrand, setIndeterminateBrand] = useState(false);
  const [loadingBrand, setLoadingBrand] = useState(false);

  const [modelPage, setModelPage] = useState(1);
  const [dataModel, setDataModel] = useState([]);
  const [dataSliceModel, setDataSliceModel] = useState([]);
  const [checkedListModel, setCheckedListModel] = useState([]);
  const [planOptionsModel, setPlanOptionsModel] = useState([]);
  const [visiblePanelModel, setVisiblePanelModel] = useState(false);
  const [checkAllModel, setCheckAllModel] = useState(true);
  const [indeterminateModel, setIndeterminateModel] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);

  const [dataStatus, setDataStatus] = useState([]);
  const [dataSliceStatus, setDataSliceStatus] = useState([]);
  const [checkedListStatus, setCheckedListStatus] = useState([]);
  const [planOptionsStatus, setPlanOptionsStatus] = useState([]);
  const [visiblePanelStatus, setVisiblePanelStatus] = useState(false);
  const [checkAllStatus, setCheckAllStatus] = useState(true);
  const [indeterminateStatus, setIndeterminateStatus] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  //---------------------------------------------------------------------------------------------------------------------------------------------//
  //-------------------------------------------------------------All-----------------------------------------------------------------------------//
  //---------------------------------------------------------------------------------------------------------------------------------------------//
  useEffect(() => {
    const getApi = async () => {
      let payloadType = {
        assetClassId: assetSelect,
      };
      const resultType = await getTypeAsset(payloadType);
      const typeData = _.get(resultType, 'dataList').map((item) => {
        return {
          assetTypeCode: item.assetTypeCode,
          assetTypeId: item.assetTypeId,
          assetTypeName: item.assetTypeName + ' ' + `(${item.totalAsset})`,
          checked: true,
        };
      });

      let payloadBrand = { assetClassId: assetSelect };
      const resultBrand = await getBrandAsset(payloadBrand);
      console.log('resultBrand', resultBrand);
      const brandData = _.get(resultBrand, 'dataList').map((item) => {
        return {
          assetBrandId: item.assetBrandId,
          assetBrandName: item.assetBrandName + ' ' + `(${item.totalAsset})`,
          checked: true,
        };
      });

      const checklistDefault = _.map(brandData, (value) => value.assetBrandId);

      let payloadModel = {
        assetClassId: assetSelect,
        assetBrandId: checklistDefault,
      };
      const resultModel = await getModelAsset(payloadModel);
      const modelData = _.get(resultModel, 'dataList').map((item) => {
        return {
          assetModelId: item.assetModelId,
          assetModelName: item.assetModelName + ' ' + `(${item.totalAsset})`,
          assetBrandId: item.assetBrandId,
          checked: true,
        };
      });

      const checklistModelDefault = _.map(modelData, (value) => value.assetModelId);

      let payloadStatus = {
        assetClassId: assetSelect,
        assetBrandId: checklistDefault,
        assetModelId: checklistModelDefault,
      };
      const resultStatus = await getStatusAsset(payloadStatus);
      const statusData = resultStatus.map((item) => {
        return {
          txt: item.txt + ' ' + `(${item.totalAsset})`,
          code: item.code,
          checked: true,
        };
      });

      let [typeA, brandA, modelA, statusA, assetSelectA] = await Promise.all([typeData, brandData, modelData, statusData, assetSelectObj]);

      setDataAsset({
        type: typeA,
        brand: brandA,
        model: modelA,
        status: statusA,
        assetObj: assetSelectA,
      });
    };
    getApi();
  }, [assetSelect]);

  useEffect(() => {
    const getApi = async () => {
      if (checkedListBrand) {
        let payloadModel = {
          assetClassId: assetSelect,
          assetBrandId: checkedListBrand,
        };
        const resultModel = await getModelAsset(payloadModel);
        const modelData = _.map(_.get(resultModel, 'dataList'), (item) => {
          return {
            assetModelId: item.assetModelId,
            assetModelName: item.assetModelName + ' ' + `(${item.totalAsset})`,
            assetBrandId: item.assetBrandId,
            checked: true,
          };
        });
        const oldAsset = { ...dataAsset, model: modelData };
        setDataAsset(oldAsset);
      }
    };
    getApi();
  }, [checkedListBrand]);

  //---------------------------------------------------------------------------------------------------------------------------------------------//
  //-------------------------------------------------------------Type----------------------------------------------------------------------------//
  //---------------------------------------------------------------------------------------------------------------------------------------------//

  useEffect(() => {
    if (_.size(_.get(dataAsset, 'type')) >= 0) {
      let checkedList = _.get(dataAsset, 'type').map((item) => item.assetTypeName);
      let option = _.get(dataAsset, 'type').map((item) => {
        return {
          label: item.assetTypeName,
          value: item.assetTypeId,
        };
      });
      setPlanOptionsType(option);
      setCheckedListType(checkedList);
    }

    if (_.get(dataAsset, 'type')) {
      const defaultCheckedList =
        _.get(dataAsset, 'type') && _.get(dataAsset, 'type').map((item) => (item.checked === true ? item.assetTypeId : undefined));
      if (setCheckedListType) {
        setCheckedListType(defaultCheckedList);
      }
      setIndeterminateType(_.size(defaultCheckedList) === _.size(_.get(dataAsset, 'type')) ? false : true);
      setCheckAllType(true);
    }
  }, [_.get(dataAsset, 'type'), assetSelect]);

  useEffect(() => {
    if (checkedListType) {
      const filterCheckedListType = _.filter(_.get(dataAsset, 'type'), (e) => checkedListType.includes(e.assetTypeId));
      const checked = filterCheckedListType.map((item) => {
        return {
          assetTypeId: item.assetTypeId,
          assetTypeCode: item.assetTypeCode,
        };
      });
      // setDataChecked({ ...dataChecked, type: checked });
      setCheckedType(checked);
    }
  }, [checkedListType]);

  //---------------------------------------------------------------------------------------------------------------------------------------------//
  //-------------------------------------------------------------Brand---------------------------------------------------------------------------//
  //---------------------------------------------------------------------------------------------------------------------------------------------//

  useEffect(() => {
    if (_.size(_.get(dataAsset, 'brand')) >= 0) {
      let checkedList = _.get(dataAsset, 'brand').map((item) => item.assetBrandName);
      let option = _.get(dataAsset, 'brand').map((item) => {
        return {
          label: item.assetBrandName,
          value: item.assetBrandId,
        };
      });
      setPlanOptionsBrand(option);
      setCheckedListBrand(checkedList);
    }

    if (_.get(dataAsset, 'brand')) {
      const defaultCheckedList =
        _.get(dataAsset, 'brand') && _.get(dataAsset, 'brand').map((item) => (item.checked === true ? item.assetBrandId : undefined));
      if (setCheckedListBrand) {
        setCheckedListBrand(defaultCheckedList);
      }
      setIndeterminateBrand(_.size(defaultCheckedList) === _.size(_.get(dataAsset, 'brand')) ? false : true);
      setCheckAllBrand(true);
    }
  }, [_.get(dataAsset, 'brand'), assetSelect, mainTrigger]);

  useEffect(() => {
    if (checkedListBrand) {
      const filterCheckedListBrand = _.filter(_.get(dataAsset, 'brand'), (e) => checkedListBrand.includes(e.assetBrandId));
      const checked = filterCheckedListBrand.map((item) => {
        return {
          assetBrandId: item.assetBrandId,
          assetBrandName: item.assetBrandName,
        };
      });
      // setDataChecked({ ...dataChecked, brand: checked });
      setCheckedBrand(checked);
    }
  }, [checkedListBrand]);

  //---------------------------------------------------------------------------------------------------------------------------------------------//
  //-------------------------------------------------------------Model---------------------------------------------------------------------------//
  //---------------------------------------------------------------------------------------------------------------------------------------------//

  useEffect(() => {
    const getApi = async () => {
      let payload = {
        assetClassId: assetSelect,
        assetBrandId: checkedListBrand,
      };
      const result = await getModelAsset(payload);
      const data = _.get(result, 'dataList').map((item) => {
        return {
          assetModelId: item.assetModelId,
          assetModelName: item.assetModelName + ' ' + `(${item.totalAsset})`,
          assetBrandId: item.assetBrandId,
          checked: true,
        };
      });
      setDataModel(data);
    };
    getApi();
  }, [_.size(checkedListBrand)]);

  // useEffect(() => {
  //   if (_.size(dataModel) >= 0 && assetSelect) {

  //     let firstPageIndex = (modelPage - 1) * pageSige;
  //     let lastPageIndex = firstPageIndex + pageSige;

  //     let dataSlice = dataModel.slice(firstPageIndex, lastPageIndex);
  //     setDataSliceModel(prev => [...prev, ...dataSlice]);
  //   }
  // }, [modelPage, dataModel, assetSelect])

  useEffect(() => {
    if (_.size(_.get(dataAsset, 'model')) >= 0) {
      let checkedList = _.get(dataAsset, 'model').map((item) => item.assetModelName);
      let option = _.get(dataAsset, 'model').map((item) => {
        return {
          label: item.assetModelName,
          value: item.assetModelId,
        };
      });
      setPlanOptionsModel(option);
      setCheckedListModel(checkedList);
    }

    if (_.get(dataAsset, 'model')) {
      const defaultCheckedList =
        _.get(dataAsset, 'model') && _.get(dataAsset, 'model').map((item) => (item.checked === true ? item.assetModelId : undefined));
      if (setCheckedListModel) {
        setCheckedListModel(defaultCheckedList);
      }
      setIndeterminateModel(_.size(defaultCheckedList) === _.size(_.get(dataAsset, 'model')) ? false : true);
      if (checkAllBrand === false) {
        if (indeterminateBrand) {
          console.log('checkModal1');
          setIndeterminateModel(false);
          setCheckAllModel(true);
        } else {
          console.log('checkModal2');
          setCheckAllModel(false);
          setIndeterminateModel(false);
        }
      } else {
        setCheckAllModel(true);
      }
    }
  }, [_.get(dataAsset, 'model'), assetSelect]);

  useEffect(() => {
    if (checkedListModel) {
      const filterCheckedListModel = _.filter(_.get(dataAsset, 'model'), (e) => checkedListModel.includes(e.assetModelId));
      const checked = filterCheckedListModel.map((item) => {
        return {
          assetModelId: item.assetModelId,
          assetBrandId: item.assetBrandId,
          assetModelName: item.assetModelName,
        };
      });
      setCheckedModel(checked);
    }
  }, [checkedListModel]);

  //---------------------------------------------------------------------------------------------------------------------------------------------//
  //-------------------------------------------------------------Status--------------------------------------------------------------------------//
  //---------------------------------------------------------------------------------------------------------------------------------------------//

  useEffect(() => {
    const getApi = async () => {
      const checklistDefault = _.map(checkedBrand, (value) => value.assetBrandId);
      const checklistModelDefault = _.map(checkedModel, (value) => value.assetModelId);
      let payloadStatus = {
        assetClassId: assetSelect,
        assetBrandId: checklistDefault,
        assetModelId: checklistModelDefault,
      };
      const result = await getStatusAsset(payloadStatus);
      const data = result.map((item) => {
        return {
          txt: item.txt + ' ' + `(${item.totalAsset})`,
          code: item.code,
          checked: true,
        };
      });
      setDataStatus(data);
    };
    getApi();
  }, [_.size(_.get(dataAsset, 'model')), assetSelect, checkedBrand, checkedModel]);

  useEffect(() => {
    if (_.size(_.get(dataAsset, 'status')) >= 0) {
      let checkedList = _.get(dataAsset, 'status').map((item) => item.txt);
      let option = _.get(dataAsset, 'status').map((item) => {
        return {
          label: item.txt,
          value: item.code,
        };
      });

      setPlanOptionsStatus(option);
      setCheckedListStatus(checkedList);
    }

    if (_.size(_.get(dataAsset, 'status'))) {
      const defaultCheckedList =
        _.get(dataAsset, 'status') && _.get(dataAsset, 'status').map((item) => (item.checked === true ? item.code : undefined));
      if (setCheckedListStatus) {
        setCheckedListStatus(defaultCheckedList);
      }
      setIndeterminateStatus(_.size(defaultCheckedList) === _.size(_.get(dataAsset, 'status')) ? false : true);
    }
  }, [_.get(dataAsset, 'status'), assetSelect, checkedBrand, checkedModel]);

  useEffect(() => {
    if (checkedListStatus) {
      const filterCheckedListStatus = _.filter(_.get(dataAsset, 'status'), (e) => checkedListStatus.includes(e.code));
      const checked = filterCheckedListStatus.map((item) => item.code);
      setCheckedStatus(checked);
    }
  }, [_.get(dataAsset, 'status'), checkedListStatus]);

  //---------------------------------------------------------------------------------------------------------------------------------------------//
  //-------------------------------------------------------------LoadMore------------------------------------------------------------------------//
  //---------------------------------------------------------------------------------------------------------------------------------------------//

  const onLoadMoreType = () => {
    if (typeScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = typeScrollRef.current;
      if (!loadingType && scrollTop + clientHeight + 10 >= scrollHeight) {
        setLoadingType(true);
        setTypePage((prevPage) => prevPage + 1);
      }
    }
  };

  const onLoadMoreBrand = () => {
    if (brandScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = brandScrollRef.current;
      if (!loadingBrand && scrollTop + clientHeight + 10 >= scrollHeight) {
        setLoadingBrand(true);
        setBrandPage((prevPage) => prevPage + 1);
      }
    }
  };

  const onLoadMoreModel = () => {
    if (modelScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = modelScrollRef.current;
      if (!loadingModel && scrollTop + clientHeight + 10 >= scrollHeight) {
        setLoadingModel(true);
        setModelPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleCheckAllType = (e) => {
    let planOptionsTypes = _.get(dataAsset, 'type').map((item) => item.assetTypeId);
    setCheckedListType(e.target.checked ? planOptionsTypes : []);
    setIndeterminateType(false);
    setCheckAllType(e.target.checked);
  };

  const handleCheckAllBrand = (e) => {
    let planOptionsBrands = _.get(dataAsset, 'brand').map((item) => item.assetBrandId);
    setCheckedListBrand(e.target.checked ? planOptionsBrands : []);
    setIndeterminateBrand(false);
    setCheckAllBrand(e.target.checked);
    setTriggerItem(true);
    if (e.target.checked === false) {
      setCheckAllModel(false);
      setIndeterminateModel(false);
    }
  };

  const handleCheckAllModel = (e) => {
    let planOptionsModels = _.get(dataAsset, 'model').map((item) => item.assetModelId);
    setCheckedListModel(e.target.checked ? planOptionsModels : []);
    setIndeterminateModel(false);
    setCheckAllModel(e.target.checked);
    setTriggerItem(true);
  };

  const onChangeGroupType = (checkedList) => {
    setCheckedListType(checkedList);
    setCheckAllType(checkedList.length === planOptionsType.length);
    setIndeterminateType(!!checkedList.length && checkedList.length < planOptionsType.length);
  };

  const onChangeGroupBrand = (checkedList) => {
    setCheckedListBrand(checkedList);
    setCheckAllBrand(checkedList.length === planOptionsBrand.length);
    setIndeterminateBrand(!!checkedList.length && checkedList.length < planOptionsBrand.length);
  };

  const onChangeGroupModel = (checkedList) => {
    setCheckedListModel(checkedList);
    setCheckAllModel(checkedList.length === planOptionsModel.length);
    setIndeterminateModel(!!checkedList.length && checkedList.length < planOptionsModel.length);
  };

  const onChangeGroupStatus = (checkedList) => {
    setCheckedListStatus(checkedList);
    setCheckAllStatus(checkedList.length === planOptionsStatus.length);
    setIndeterminateStatus(!!checkedList.length && checkedList.length < planOptionsStatus.length);
  };

  const handleCollapseType = () => {
    setVisiblePanelType((current) => !current);
    setVisiblePanelBrand(false);
    setVisiblePanelModel(false);
    setVisiblePanelStatus(false);
  };

  const handleCollapseBrand = () => {
    setVisiblePanelType(false);
    setVisiblePanelBrand((current) => !current);
    setVisiblePanelModel(false);
    setVisiblePanelStatus(false);
  };

  const handleCollapseModel = () => {
    setVisiblePanelType(false);
    setVisiblePanelBrand(false);
    setVisiblePanelModel((current) => !current);
    setVisiblePanelStatus(false);
  };

  const handleCollapseStatus = () => {
    setVisiblePanelType(false);
    setVisiblePanelBrand(false);
    setVisiblePanelModel(false);
    setVisiblePanelStatus((current) => !current);
  };

  const headerTitleType = () => {
    return (
      <div>
        <Row className="custom-row-select-all">
          <Col span={14}>
            <Checkbox indeterminate={indeterminateType} onChange={handleCheckAllType} checked={checkAllType}>
              <span>
                <FormattedMessage id="assetCardListType" defaultMessage="Type" />
              </span>
            </Checkbox>
          </Col>
          <Col span={8}>
            <span className="select-text-panel-style">{`${intl.formatMessage({
              id: `filterTaskSelected`,
              defaultMessage: 'Selected',
            })} ${_.size(checkedListType)}`}</span>
          </Col>
          <Col span={1} onClick={() => handleCollapseType()} style={{ cursor: 'pointer' }}>
            {visiblePanelType ? <Icon type="up" /> : <Icon type="down" />}
          </Col>
        </Row>
      </div>
    );
  };

  const headerTitleBrand = () => {
    return (
      <div>
        <Row className="custom-row-select-all">
          <Col span={14}>
            <Checkbox indeterminate={indeterminateBrand} onChange={handleCheckAllBrand} checked={checkAllBrand}>
              <span>
                <FormattedMessage id="assetCardListBrand" defaultMessage="Brand" />
              </span>
            </Checkbox>
          </Col>
          <Col span={8}>
            <span className="select-text-panel-style">{`${intl.formatMessage({
              id: `filterTaskSelected`,
              defaultMessage: 'Selected',
            })} ${_.size(checkedListBrand)}`}</span>
          </Col>
          <Col span={1} onClick={() => handleCollapseBrand()} style={{ cursor: 'pointer' }}>
            {visiblePanelBrand ? <Icon type="up" /> : <Icon type="down" />}
          </Col>
        </Row>
      </div>
    );
  };

  const headerTitleModel = () => {
    return (
      <div>
        <Row className="custom-row-select-all">
          <Col span={14}>
            <Checkbox indeterminate={indeterminateModel} onChange={handleCheckAllModel} checked={checkAllModel}>
              <span>
                <FormattedMessage id="assetCardListModel" defaultMessage="Model" />
              </span>
            </Checkbox>
          </Col>
          <Col span={8}>
            <span className="select-text-panel-style">{`${intl.formatMessage({
              id: `filterTaskSelected`,
              defaultMessage: 'Selected',
            })} ${_.size(checkedListModel)}`}</span>
          </Col>
          <Col span={1} onClick={() => handleCollapseModel()} style={{ cursor: 'pointer' }}>
            {visiblePanelModel ? <Icon type="up" /> : <Icon type="down" />}
          </Col>
        </Row>
      </div>
    );
  };

  const headerTitleAssetAdvance = () => {
    return (
      <div>
        <Row className="custom-row-select-all">
          <Col span={14}>
            <span className="header-title-advance-filter">
              <FormattedMessage id="assetCardListAssetAdvance" defaultMessage="Advanced Filter" />
            </span>
          </Col>
          <Col span={8}>
            <span className="select-text-panel-style">{`${intl.formatMessage({
              id: `filterTaskSelected`,
              defaultMessage: 'Selected',
            })} ${_.size(checkedListStatus)}`}</span>
          </Col>
          <Col span={1} onClick={() => handleCollapseStatus()} style={{ cursor: 'pointer' }}>
            {visiblePanelStatus ? <Icon type="up" /> : <Icon type="down" />}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      <div className="container-group-select-all">
        {headerTitleType()}
        {visiblePanelType ? (
          <div onScroll={() => onLoadMoreType()} ref={typeScrollRef} className="sroll-checkbox-group scroll-sm-none">
            <CheckboxGroup
              className="select-group-style-asset"
              options={planOptionsType}
              value={checkedListType}
              onChange={onChangeGroupType}
            />
          </div>
        ) : null}
      </div>
      <Divider className="style-mg-0" type="horizontal" />
      <div className="container-group-select-all">
        {headerTitleBrand()}
        {visiblePanelBrand ? (
          <div onScroll={() => onLoadMoreBrand()} ref={brandScrollRef} className="sroll-checkbox-group scroll-sm-none">
            <CheckboxGroup
              className="select-group-style-asset"
              options={planOptionsBrand}
              value={checkedListBrand}
              onChange={onChangeGroupBrand}
            />
          </div>
        ) : null}
      </div>
      <Divider className="style-mg-0" type="horizontal" />
      <div className="container-group-select-all">
        {headerTitleModel()}
        {visiblePanelModel ? (
          <div onScroll={() => onLoadMoreModel()} ref={modelScrollRef} className="sroll-checkbox-group scroll-sm-none">
            <CheckboxGroup
              className="select-group-style-asset"
              options={planOptionsModel}
              value={checkedListModel}
              onChange={onChangeGroupModel}
            />
          </div>
        ) : null}
      </div>
      <Divider className="style-mg-0" type="horizontal" />
      <div className="container-group-select-all">
        {headerTitleAssetAdvance()}
        {visiblePanelStatus ? (
          <div className="sroll-checkbox-group-status scroll-sm-none">
            <div>
              <span className="asset-datepicker-label">
                <FormattedMessage id="assetCardListAssetAdvanceStatus" defaultMessage="Status" />
              </span>
            </div>
            <CheckboxGroup
              className="select-group-style-status"
              options={planOptionsStatus}
              value={checkedListStatus}
              onChange={onChangeGroupStatus}
            />
            <AssetAdvanceSearch />
          </div>
        ) : null}
      </div>
      <Divider className="style-mg-0" type="horizontal" />
    </div>
  );
};

export default FilterAssetClass;
