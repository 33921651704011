import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const searchAllowanceList = async (payload) => {
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/allowance/list`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const searchSourceList = async (payload) => {
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/allowance/searchsource`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const searchDestinationList = async (payload) => {
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/allowance/searchdestination`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getDistance = async (payload) => {
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/source-destination/distance`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getAllowanceList = async () => {
  try {
    const response = await httpClient.get(`/v4/task/manager/company/${comCode}/allowancesetting`);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const createAllowance = async (payload) => {

    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/allowance`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }

};

const updateAllowance = async (payload, allowanceId) => {

    const response = await httpClient.put(`/v4/task/manager/company/${comCode}/allowance/${allowanceId}`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }

};

const deleteAllowance = async (allowanceId) => {

    const response = await httpClient.delete(`/v4/task/manager/company/${comCode}/allowance/${allowanceId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }

};

const saveSettingTaskRate = async (payload) => {

    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/allowancesetting/taskrate`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  // } catch (error) {
  //   return error.response;
  // }
};

const saveSettingDistanceRate = async (payload) => {
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/allowancesetting/distancerate`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveSettingTripRate = async (payload) => {

    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/allowancesetting/triprate`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }

};

export {
  searchAllowanceList,
  searchSourceList,
  searchDestinationList,
  getAllowanceList,
  getDistance,
  createAllowance,
  updateAllowance,
  deleteAllowance,
  saveSettingTaskRate,
  saveSettingDistanceRate,
  saveSettingTripRate,
};
