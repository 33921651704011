import React, { useContext, useEffect, useState } from 'react';
import Information from './information';
import Contact from './contact';
import Address from './address-book';
import TeamService from './team-service';
import { Card, Col, Row, Tabs } from 'antd';
import style from './css/index.css';
import { useParams } from 'react-router';
import httpClient from '../../../components/axiosClient';
import _, { debounce } from 'lodash';
import { PageSettings } from '../../../config/page-settings';
import { FormattedMessage, useIntl } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import Bank from './bank';
import Document from './document';
import logoPDF from '../../../pages/login/image/pdf.png';

const { TabPane } = Tabs;

export default () => {
  const comCode = localStorage.getItem('comCode');
  const intl = useIntl();
  const { checkPermissionAction } = useContext(PageSettings);

  const paramCustomerId = useParams();
  const customerId = _.get(paramCustomerId, 'customer_id');
  const [informationData, setInformationData] = useState();
  const [contactData, setContactData] = useState();
  const [addressBookData, setAddressBookData] = useState();
  const [teamService, setTeamService] = useState();
  const [triggerAddContact, setTriggerAddContact] = useState(false);
  const [triggerAddToLocation, setTriggerAddToLocation] = useState(false);
  const [triggerTeamService, settriggerTeamService] = useState(false);
  const [triggerDeleteImg, settriggerDeleteImg] = useState(false);
  //-----------------Pagination-------------------------------------//
  const [pageCon, setPageCon] = useState(1);
  const [sizeCon, setSizeCon] = useState(10);
  const [pageAdd, setPageAdd] = useState(1);
  const [sizeAdd, setSizeAdd] = useState(10);
  const [pageTeam, setPageTeam] = useState(1);
  const [sizeTeam, setSizeTeam] = useState(10);
  const [totalCon, setTotalCon] = useState(0);
  const [totalAdd, setTotalAdd] = useState(0);
  const [totalTeam, setTotalTeam] = useState(0);
  const [selectSearchCon, setSelectSearchCon] = useState('all');
  const [selectObjCon, setSelectObjCon] = useState();
  const [selectSearchAdd, setSelectSearchAdd] = useState('all');
  const [selectObjAdd, setSelectObjAdd] = useState();
  const [sortCon, setSortCon] = useState([]);
  const [sortAdd, setSortAdd] = useState([]);
  const [sortTeam, setSortTeam] = useState([]);
  const [fieldChangeCon, setFieldChangeCon] = useState('');
  const [fieldChangeAdd, setFieldChangeAdd] = useState('');
  const [fieldChangeTeam, setFieldChangeTeam] = useState('');
  const [loadingCon, setLoadingCon] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingTeam, setLoadingTeam] = useState(false);

  // tab contact & address book
  const checkPermissionActionTab = checkPermissionAction('P8PG2C2', 'P8PG2C2A2')
    ? 'Contact'
    : checkPermissionAction('P8PG2C3', 'P8PG2C3A2')
    ? 'Address'
    : '';
  const [tabKey, setTabKey] = useState(checkPermissionActionTab);
  const [visibleModalContact, setVisibleModalContact] = useState(false);
  const [visibleModalAddressBookCreate, setvisibleModalAddressBookCreate] = useState(false);

  // document
  const [document, setDocument] = useState([])
  const [documentCompany, setDocumentCompany] = useState([]);
  const [triggerDocument, setTriggerDocument] = useState(false)

  console.log('customerId', customerId, informationData)


  useEffect(() => {
    getData();
  }, [customerId, triggerAddContact, triggerAddToLocation, triggerTeamService, triggerDeleteImg]);

  useEffect(() => {
    getContectData();
  }, [pageCon, sizeCon, triggerAddContact, fieldChangeCon, selectSearchCon, sortCon]);

  useEffect(() => {
    getAddressData();
  }, [pageAdd, sizeAdd, fieldChangeAdd, selectSearchAdd, sortAdd, triggerAddToLocation]);

  useEffect(() => {
    getDocumentFile();
    setTimeout(() => {
      getDocument();
    }, 500)
  }, [triggerDocument])

  const getData = async () => {
    try {
      const response = await httpClient.get(
        `/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}?disableContact=Y&disableAddress=Y`
      );

      if (response.status == 200) {
        setInformationData(response.data.data);
        // setContactData(response.data.data.contacts);
        // setAddressBookData(response.data.data.addressBooks);
        setTeamService(response.data.data.teamservice);
        setTotalTeam(response.data.data.teamservice.length);
      }
    } catch (error) {}
  };

  const getContectData = async () => {
    setLoadingCon(true);
    try {
      const body = {
        pageNumber: pageCon,
        limit: sizeCon,
        orderBy: _.get(sortCon, 'field'),
        orderType: _.get(sortCon, 'order') === 'descend' ? 'desc' : 'asc',
        searchObj:
          selectSearchCon === 'all'
            ? {
                customerContactName: fieldChangeCon,
                customerContactEmail: fieldChangeCon,
                customerContactPhone: fieldChangeCon,
                position: fieldChangeCon,
              }
            : selectObjCon,
        customerId: customerId,
      };

      const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-contacts/search`, body);

      if (response.status == 200) {
        setContactData(response.data.data.list);
        setTotalCon(response.data.data.totalItem);
        setLoadingCon(false);
      }
    } catch (error) {}
  };

  const getAddressData = async () => {
    setLoadingAdd(true);
    try {
      const body = {
        pageNumber: pageAdd,
        limit: sizeAdd,
        orderBy: _.get(sortAdd, 'field'),
        orderType: _.get(sortAdd, 'order') === 'descend' ? 'desc' : 'asc',
        searchObj:
          selectSearchAdd === 'all'
            ? {
                address: fieldChangeAdd,
                addressName: fieldChangeAdd,
              }
            : selectObjAdd,
        customerId: customerId,
      };

      const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-address/search`, body);
      if (response.status == 200) {
        setAddressBookData(response.data.data.list);
        setTotalAdd(response.data.data.totalItem);
        setLoadingAdd(false);
      }
    } catch (error) {}
  };

  const getDocument = async () => {
    try {
      const body = {

      }
      const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/getuploadDocumnetS3`, body);
      console.log('getDocument response', response)
      setDocumentCompany(_.sortBy(response.data, 'docComId'))
    } catch (error) {}
  }

  const getDocumentFile = async () => {
    try {
      const body = {
        customerId: customerId
      }
      const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/getUploadDocumentType`, body);
      setDocument(response.data);
      console.log('getDocumentFile', response)
    } catch (error) {}
  }

  const mapDocumentsToCompany = (documents, companies) => {
    documents.forEach((obj, index) => {
      const fileDoc = {
        uid: index + 1,
        document_id: obj.docId,
        document_name: obj.name,
        original: obj.type === 'pdf' ? logoPDF : obj.path,
        thumbnail: obj.type === 'pdf' ? logoPDF : obj.path,
        thumbUrl: obj.type === 'pdf' ? logoPDF : obj.path,
        url: obj.type === 'pdf' ? logoPDF : obj.path,
        originalPath: obj.path,
        type: obj.type,
      };
  
      const found = companies.findIndex(
        (ele) => ele.docComId === obj.docComId,
      );
  
      if (found >= 0) {
        if (!_.isArray(companies[found].files)) {
          companies[found].files = [];
        }
  
        const isFileExist = companies[found].files.findIndex(
          (ele) => ele.document_id === fileDoc.document_id,
        );
  
        if (isFileExist === -1) {
          companies[found].files.push(fileDoc);
        }
      }
    });
  
    return companies;
  };

  document.map((obj, index) => {
    const fileDoc = {
      uid: index + 1,
      document_id: obj.docId,
      document_name: obj.name,
      original: obj.type === 'pdf' ? logoPDF : obj.path,
      thumbnail: obj.type === 'pdf' ? logoPDF : obj.path,
      thumbUrl: obj.type === 'pdf' ? logoPDF : obj.path,
      url: obj.type === 'pdf' ? logoPDF : obj.path,
      originalPath: obj.path,
      type: obj.type,
    };

    const found = documentCompany.findIndex(
      (ele) => ele.docComId === obj.docComId,
    );
    if (found >= 0) {
      if (!_.isArray(documentCompany[found].files)) {
        documentCompany[found].files = [];
      }
      const checkIsFile = documentCompany[found].files.findIndex(
        (ele) => ele.document_id === fileDoc.document_id,
      );
      if (checkIsFile === -1) {
        documentCompany[found].files.push(fileDoc);
      }
    }
  });

  const handleSearchCon = debounce((fieldChang) => {
    if (selectSearchCon === 'all') {
      setSelectObjCon({
        customerContactName: fieldChang,
        customerContactEmail: fieldChang,
        customerContactPhone: fieldChang,
        position: fieldChang,
      });
      setFieldChangeCon(fieldChang);
    } else {
      setSelectObjCon({
        [selectSearchCon]: fieldChang,
      });
      setFieldChangeCon(fieldChang);
    }
  }, 500);

  const handleSelectDataCon = (value) => {
    if (value === 'all') {
      setSelectObjCon({
        customerContactName: fieldChangeCon,
        customerContactEmail: fieldChangeCon,
        customerContactPhone: fieldChangeCon,
        position: fieldChangeCon,
      });
      setSelectSearchCon(value === undefined ? 'all' : value);
    } else {
      setSelectSearchCon(value === undefined ? 'all' : value);
      setSelectObjCon({
        [value]: fieldChangeCon,
      });
    }
  };

  const handleSearchAdd = debounce((fieldChang) => {
    if (selectSearchAdd === 'all') {
      setSelectObjAdd({
        address: fieldChang,
        addressName: fieldChang,
      });
      setFieldChangeAdd(fieldChang);
    } else {
      setSelectObjAdd({
        [selectSearchAdd]: fieldChang,
      });
      setFieldChangeAdd(fieldChang);
    }
  }, 500);

  const handleSelectDataAdd = (value) => {
    if (value === 'all') {
      setSelectObjAdd({
        address: fieldChangeAdd,
        addressName: fieldChangeAdd,
      });
      setSelectSearchAdd(value === undefined ? 'all' : value);
    } else {
      setSelectSearchAdd(value === undefined ? 'all' : value);
      setSelectObjAdd({
        [value]: fieldChangeAdd,
      });
    }
  };

  const onChangeTableCon = (value, index, sort) => {
    setSortCon(sort);
  };

  const onChangeTableAdd = (value, index, sort) => {
    setSortAdd(sort);
  };

  const onChangeTableTeam = (value, index, sort) => {
    setSortTeam(sort);
  };

  const callback = (key) => {
    setTabKey(key);
  };

  const tabContent = () => {
    console.log('tabKey', tabKey);
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          tabKey === 'Contact' && checkPermissionAction('P8PG2C2', 'P8PG2C2A2') ? (
            <Button01
              type="primary"
              onClick={() => {
                setVisibleModalContact(true);
              }}
            >
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button01>
          ) : tabKey === 'Address' && checkPermissionAction('P8PG2C3', 'P8PG2C3A2') ? (
            <Button01
              type="primary"
              onClick={() => {
                setvisibleModalAddressBookCreate(true);
              }}
            >
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button01>
          ) : null
        }
      </PageSettings.Consumer>
    );
  };

  console.log('documentCompany', documentCompany)

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div className="padding-card-component-top">
          <Row gutter={[16]}>
            <Col span={18}>
              <Row className="padding-card-component">
                {checkPermissionAction('P8PG2C1', 'P8PG2C1A1') ? (
                  <Information
                    informationData={informationData}
                    setTriggerAddContact={setTriggerAddContact}
                    triggerAddContact={triggerAddContact}
                    settriggerDeleteImg={settriggerDeleteImg}
                    triggerDeleteImg={triggerDeleteImg}
                  />
                ) : null}
              </Row>
              {checkPermissionAction('P8PG2C2', 'P8PG2C2A1') || checkPermissionAction('P8PG2C3', 'P8PG2C3A1') ? (
                <Row className="padding-card-component">
                  <Card className="monitor-main-card" style={{ height: 'auto', }}>
                    <Tabs
                      className="monitor-main-tabs customer-tab-margin"
                      size="large"
                      defaultActiveKey="waiting"
                      onChange={callback}
                      animated={false}
                      tabBarExtraContent={tabContent()}
                      tabBarStyle={{ padding: '0px 24px '}}
                      // style={{padding: '0px 24px'}}
                    >
                      {checkPermissionAction('P8PG2C2', 'P8PG2C2A1') ? (
                        <TabPane tab={intl.formatMessage({ id: 'customerContactCardHeader', defaultMessage: 'Contact' })} key="Contact">
                          <Contact
                            contactData={contactData}
                            informationData={informationData}
                            setTriggerAddContact={setTriggerAddContact}
                            triggerAddContact={triggerAddContact}
                            setPageCon={setPageCon}
                            setSizeCon={setSizeCon}
                            selectSearchCon={selectSearchCon}
                            onSelect={handleSelectDataCon}
                            total={totalCon}
                            onChange={handleSearchCon}
                            onChangeTableCon={onChangeTableCon}
                            loading={loadingCon}
                            pageCon={pageCon}
                            visibleModalContact={visibleModalContact}
                            setVisibleModalContact={setVisibleModalContact}
                          />
                        </TabPane>
                      ) : null}

                      {checkPermissionAction('P8PG2C3', 'P8PG2C3A1') ? (
                        <TabPane
                          tab={intl.formatMessage({ id: 'customerAddressBookCardHeader', defaultMessage: 'Address Book' })}
                          key="Address"
                        >
                          <Address
                            addressBookData={addressBookData}
                            informationData={informationData}
                            setTriggerAddToLocation={setTriggerAddToLocation}
                            triggerAddToLocation={triggerAddToLocation}
                            setPageAdd={setPageAdd}
                            setSizeAdd={setSizeAdd}
                            selectSearchAdd={selectSearchAdd}
                            onSelect={handleSelectDataAdd}
                            total={totalAdd}
                            onChange={handleSearchAdd}
                            onChangeTableAdd={onChangeTableAdd}
                            loading={loadingAdd}
                            pageAdd={pageAdd}
                            visibleModalAddressBookCreate={visibleModalAddressBookCreate}
                            setvisibleModalAddressBookCreate={setvisibleModalAddressBookCreate}
                          />
                        </TabPane>
                      ) : null}
                    </Tabs>
                  </Card>
                </Row>
              ) : null}

              <Row className="padding-card-component">
                {checkPermissionAction('P8PG2C4', 'P8PG2C4A1') ? (
                  <TeamService
                    customerId={customerId}
                    settriggerTeamService={settriggerTeamService}
                    triggerTeamService={triggerTeamService}
                    teamService={teamService}
                    setPageTeam={setPageTeam}
                    setSizeTeam={setSizeTeam}
                    total={totalTeam}
                    onChangeTableTeam={onChangeTableTeam}
                    loading={loadingTeam}
                    pageTeam={pageTeam}
                  />
                ) : null}
              </Row>
            </Col>

            <Col span={6}>
              <div className="frame">
                <Document
                  setTrigger={setTriggerDocument}
                  customerId={customerId}
                  documentCompany={documentCompany}
                />
              </div>

              <div className="frame">
                <Bank customerId={customerId}/>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
