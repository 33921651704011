import React from 'react'
import { Form, Row, Col, Select, Input } from 'antd'
import LabeRequireForm from '../../../../components/label-required-form'
import { useIntl } from 'react-intl'
import _ from 'lodash'

const { Option } = Select;

const PettyCashFormCRUD = (prop) => {
  const intl = useIntl()
  const { form, userList, teamList, handleChangeTeam, handleChangeUser, defaultModal } = prop
  const { getFieldDecorator } = form;

  return (
    <Form form={form} layout="vertical" className="pettyCashModalForm" style={{ padding: '24px' }}>
      <Row gutter={[16, 8]} id='Form-PettyCashName'>
        <Col span={24}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormName', defaultMessage: 'Petty Cash Name' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('pettyCashName', {
              initialValue: _.get(defaultModal, 'type') === 'edit' ? _.get(defaultModal, 'data.pettyCashName') : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'pettyCashModalFormNameVL', defaultMessage: 'Please Enter Petty Cash Name' }),
                },
              ],
            }
            )(<Input
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({ id: 'pettyCashModalFormNamePH', defaultMessage: 'Enter Petty Cash Name' })}
            />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]} id='Form-Team&ReceivibleBy'>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormTeam', defaultMessage: 'Team' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('team', {
              initialValue: _.get(defaultModal, 'type') === 'edit' ? _.get(defaultModal, 'data.team.teamId') : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'pettyCashModalFormTeamVL', defaultMessage: 'Please Select Team' }),
                },
              ],
            }
            )(
              <Select
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormTeamPH', defaultMessage: 'Select Team' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                allowClear
                onChange={handleChangeTeam}
              >
                {_.map(_.get(teamList, 'list'), (item) => {
                  return <Option key={item.org_id}>{item.name}</Option>;
                })}
              </Select>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormResponsibleby', defaultMessage: 'Responsible by' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('responsibleBy', {
              initialValue: _.get(defaultModal, 'type') === 'edit' ? _.get(defaultModal, 'data.responsibleBy.responsibleId') : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'pettyCashModalFormResponsiblebyVL', defaultMessage: 'Please Responsible by' }),
                },
              ],
            }
            )(
              <Select
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormResponsiblebyPH', defaultMessage: 'Select Responsible by' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                allowClear
                onChange={handleChangeUser}
              >
                {_.map(_.get(userList, 'list'), (item) => {
                  return <Option key={item.mem_com_id}>{item.fullname}</Option>;
                })}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]} id='Form-Remark'>
        <Col span={24}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormRemark', defaultMessage: 'Remark' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('remark', {
              initialValue: _.get(defaultModal, 'type') === 'edit' ? _.get(defaultModal, 'data.remark') : undefined,
            }
            )(
              <Input.TextArea
                autoComplete="off"
                autoSize={{ minRows: 3, maxRows: 3 }}
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormRemarkPH', defaultMessage: 'Enter Remark' })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default PettyCashFormCRUD
