import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import { GanttViewContext } from '../monitor-context';
import styles from './css/gentt-view.css';

import {
  Row,
  Input,
  Col,
  DatePicker,
  Select,
  List,
  Icon,
  ConfigProvider,
  Button,
} from 'antd';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';

const { Option } = Select;

export default React.memo(({ stateMain }) => {
    const intl = useIntl();
    const {
      state,
      setState,
      fnc,
    } = useContext(GanttViewContext);
    const app = useAppContext();
    const langValue = app.state.langValue;

    const dateFormat = 'ddd, MMM DD YYYY';

    const [locale, setLocale] = useState(enUS);

    useEffect(() => {
      changeLocale();
    }, []);

    const selectSearch = [
      {
        nameKey: intl.formatMessage({ id: 'monitorTabGanttViewSelectSearchTaskNo', defaultMessage: 'Task No' }),
        key: 'task_no'
      },
      {
        nameKey: intl.formatMessage({ id: 'monitorTabGanttViewSelectSearchCustomerName', defaultMessage: 'Customer Name' }),
        key: 'customerName'
      },
      {
        nameKey: intl.formatMessage({ id: 'monitorTabGanttViewSelectSearchAssigneeName', defaultMessage: 'Assignee Name' }),
        key: 'assigneeName'
      },
    ];
    

    const changeLocale = () => {
      let codeLang = langValue ? langValue : 'EN';
      let changeLang = codeLang === 'EN' ? enUS : thTH;
      setLocale(changeLang);
      if (!changeLang) {
        moment.locale('en');
      } else {
        moment.locale('th-th');
      }
    };

    const filterOption = (input, option) => {
      // console.log('gantt filterOption input::: ', input);
      // console.log('gantt filterOption option::: ', option);
    }

    return (
      <div>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Row>
              <List.Item className="gantt-view-list-left">
                <Input
                  className="gantt-view-input"
                  placeholder={intl.formatMessage({
                    id: 'hintSearch',
                    defaultMessage: 'Search',
                  })}
                  prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                  onChange={(e) => fnc.handleSearch(e.target.value)}
                  autoComplete="off"
                />
                <Select
                  className="gantt-view-select"
                  placeholder={intl.formatMessage({
                    id: 'hintSelect',
                    defaultMessage: 'Select',
                  })}
                  onSelect={(value) => fnc.handleSelect(value)}
                  defaultValue='all'
                >
                  <Option value="all">
                    <FormattedMessage id="reportOutsideReportHintSeleteAllColumns" defaultMessage="All columns" />
                  </Option>
                  {selectSearch.map((item, index) =>
                    <Option key={index} value={item.key}>{item.nameKey}</Option>
                  )}
                </Select>
              </List.Item>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <List.Item className="gantt-view-list-right">
                <Select
                  ref={stateMain.selectRef}
                  className="gantt-view-select-team"
                  placeholder={intl.formatMessage({
                    id: 'hintSelect',
                    defaultMessage: 'Select Team',
                  })}
                  value={stateMain.mainOrgIdArr}
                  defaultValue={[]}
                  onChange={fnc.handleChangeOption}
                  maxTagCount={1}
                  mode="multiple"
                  showSearch
                  // filterOption={filterOption}
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {stateMain.mainOrganizationTeamData && stateMain.mainOrganizationTeamData.map((e, index) => (
                    <Option
                      disabled={
                        state.stateMain.mainOrgIdArr.length > 3
                          ? state.stateMain.mainOrgIdArr.includes(e.orgId)
                            ? false
                            : true
                          : false
                      }
                      key={index} value={e.orgId}>
                      {e.orgName}</Option>
                  ))}
                </Select>
              </List.Item>
            </Row>
          </Col>
        </Row>
      </div>
    );
  });
