import React from 'react';
import { Tag } from 'antd';
import _ from 'lodash'

const RenderTag = ({ fileList, handleTags }) => {
  const splitText = (val) => {
    let lastDotIndex = val.lastIndexOf('.');
    let everythingAfterTheFinalDot = val.substring(lastDotIndex + 1);
    return everythingAfterTheFinalDot;
  }

  return (
    <Tag className='post-tags' key={fileList.filehash} onClick={() => handleTags(fileList)}  >
      <span title={fileList.descriptionPhoto ? fileList.descriptionPhoto : "file"}>{_.size(fileList.descriptionPhoto) > 30 ? fileList.descriptionPhoto.substring(0, 30) + '...' + splitText(fileList.descriptionPhoto) : fileList.descriptionPhoto ? fileList.descriptionPhoto : "file"}</span>
    </Tag>
  )
}

export { RenderTag };