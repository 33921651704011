import { Card, Col, Row, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { PageSettings } from '../../config/page-settings';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { ReciveItemPOContext } from './reciveitempo-context';
import CardTableRecivePO from './table';
import ModalFormReceiveItem from './modal/po';
import { getBrandReportTicket, getModelReportTicket } from '../../controllers/ticket/ticket';
import { getBrandListData, getModelListData, getNumberType, getReceiveItemGroup, getReceiveItemType, getReceivePOData, getWareHouseListData } from '../../controllers/receice-po/receive';
import moment from 'moment';

const ReciveItemPO = () => {
  const [paginationPage, setPaginationPage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [poList, setPOList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [event, setEvent] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState();
  const [searchGroup, setSearchGroup] = useState();
  const [brandList, setBrandList] = useState([]);
  const [brandId, setBrandId] = useState('');
  const [dataModel, setDataModel] = useState([]);
  const [warehouseList, setWarehouseList] = useState();
  const [totalBrand, setTotalBrand] = useState(10);
  const [totalModel, setTotalModel] = useState(10);
  const [totalWarhouse, setTotalWarhouse] = useState(10);
  const [totalType, setTotalType] = useState(10);
  const [totalGroup, setTotalGroup] = useState(10);
  const [numbertypeList, setNumberTypeList] = useState(10);
  const [trigger, setTrigger] = useState(false);
  const [itemGroupList, setItemGroupList] = useState();
  const [itemTypeList, setItemTypeList] = useState();
  const [typeview, setTypeView] = useState([]);
  //--------------------------------------------------
  const [warhousePage, setWarhousePage] = useState(10);
  const [warhouseLoading, setWarhouseLoading] = useState(false);
  const [warehouseSerch, setWarehouseSerch] = useState('');
  const [warehouseTotal, setWarehouseTotal] = useState('');

  useEffect(() => {
    const getWarehouse = async () => {
      const body = {
        indexStart: 0,
        indexEnd: warhousePage,
        searchName: warehouseSerch || '',
      };
  
      const responase = await getWareHouseListData(body);
      setWarehouseList(_.get(responase, 'data.data.warehouseList'));
      setWarhouseLoading(false);
      setWarehouseTotal(_.get(responase, 'data.data.totalItem'))
    };
    getWarehouse();
    console.log('warehouseSerch',warehouseSerch);
  }, [totalWarhouse,warhousePage,warehouseSerch]);

  useEffect(() => {
    const getBrand = async () => {
      const body = {
        indexStart: 0,
        indexEnd: totalBrand,
        item_brand_name: '',
      };
  
      const responase = await getBrandListData(body);
      setBrandList(_.get(responase, 'data.data.list'));
    };
    getBrand();
  }, [totalBrand]);

  useEffect(() => {
    const getModel = async () => {
      const body = {
        indexStart: 0,
        indexEnd: totalBrand,
        item_model_name: '',
        item_brand_id: [brandId],
      };
  
      const responase = await getModelListData(body);
      console.log('ModelData', responase.data);
      setDataModel(_.get(responase, 'data.data.list'));
    };
    if (brandId) {
      getModel();
    }
  }, [totalModel, brandId]);

  useEffect(() => {
    const getItemGroup = async () => {
      const body = {
        indexStart: 0,
        indexEnd: totalBrand,
        item_group_name: '',
      };
  
      const responase = await getReceiveItemGroup(body);
      setItemGroupList(_.get(responase, 'data.data.list'));
    };
    getItemGroup();
  }, [totalGroup]);

  useEffect(() => {
    const getItemType = async () => {
      const body = {
        indexStart: 0,
        indexEnd: totalBrand,
        item_type_name: '',
      };
  
      const responase = await getReceiveItemType(body);
      setItemTypeList(_.get(responase, 'data.data.itemTypeList'));
    };
    getItemType();
  }, [totalType]);

  useEffect(() => {
    async function getReceivePO() {
      const body = {
        warehouseId: _.get(searchGroup, 'warehouse') ? [_.get(searchGroup, 'warehouse')] : [],
        poNo: _.get(searchGroup, 'pono') ? _.get(searchGroup, 'pono') : undefined,
        itemName: _.get(searchGroup, 'itemname') ? _.get(searchGroup, 'itemname') : undefined,
        lotSerialNo: _.get(searchGroup, 'lotser') ? _.get(searchGroup, 'lotser') : undefined ,
        itemGroupCode: _.get(searchGroup, 'itemgroup') ? [_.get(searchGroup, 'itemgroup')] : [],
        itemTypeCode: _.get(searchGroup, 'itemtype') ? [_.get(searchGroup, 'itemtype')] : [],
        brand: _.get(searchGroup, 'brand') ? [_.get(searchGroup, 'brand')] : [],
        model: _.get(searchGroup, 'model') ? [_.get(searchGroup, 'model')] : [],
        menufacturingDateFrom: _.get(searchGroup, 'mandate.[0]') ? moment(_.get(searchGroup, 'mandate.[0]')).format('YYYY-MM-DD') : undefined ,
        menufacturingDateTo: _.get(searchGroup, 'mandate.[1]') ? moment(_.get(searchGroup, 'mandate.[1]')).format('YYYY-MM-DD') : undefined,
        expiredDateFrom: _.get(searchGroup, 'expiredDate.[0]') ? moment(_.get(searchGroup, 'expiredDate.[0]')).format('YYYY-MM-DD') : undefined,
        expiredDateTo: _.get(searchGroup, 'expiredDate.[0]') ? moment(_.get(searchGroup, 'expiredDate.[1]')).format('YYYY-MM-DD') : undefined,
        pageNumber: page,
        limit: size,
      };

      const response = await getReceivePOData(body);
      setPOList(response.data.data.itemList);
      setTotal(response.data.data.totalItem);
      setIsLoading(false);
    }
    setIsLoading(true);
    getReceivePO();
  }, [searchGroup, page, size, trigger]);

  useEffect(() => {
    async function getNumber() {
      const response = await getNumberType();
      setNumberTypeList(response.data.data.numberTypeList);
    }
    getNumber();
  }, []);

  



 

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleVisibleCreate = () => {
    setVisibleCreate(true);
  };

  const handleView = (record) => {
    setTypeView(record);
    setVisibleCreate(true);
  };

  const handleEdit = async (record) => {
    handleVisibleCreate();
  };

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!warhouseLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50 && warhousePage < warehouseTotal ) {
      setWarhousePage(warhousePage + 10);
      setWarhouseLoading(true);
    }
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction(`P60PG1C1`, `P60PG1C1A1`) ? (
          <ReciveItemPOContext.Provider
            value={{
              state: {
                poList,
                paginationPage,
                isLoading,
                fillter,
                visibleCreate,
                event,
                editRecord,
                sort,
                page,
                total,
                isLoading,
                searchGroup,
                brandId,
                dataModel,
                brandList,
                totalBrand,
                totalModel,
                totalGroup,
                totalType,
                warehouseList,
                totalWarhouse,
                numbertypeList,
                trigger,
                itemGroupList,
                itemTypeList,
                typeview,
              },
              setState: {
                setPaginationPage,
                setIsLoading,
                setFillter,
                setVisibleCreate,
                setEditRecord,
                setEvent,
                setSort,
                setSearchGroup,
                setBrandId,
                setTotalBrand,
                setTotalModel,
                setTotalType,
                setTotalGroup,
                setTotalWarhouse,
                setTrigger,
                setTypeView,
                setWarehouseSerch,
              },
              fnc: {
                handleChangePage,
                handleChangeSize,
                handleVisibleCreate,
                handleView,
                handleEdit,
                handleCustomerPopupScroll,
              },
            }}
          >
            <Row gutter={[16, 16]}>
              <CardTableRecivePO />
            </Row>
            <ModalFormReceiveItem
              visible={visibleCreate}
              setVisible={setVisibleCreate}
              numbertypeList={numbertypeList}
              warehouseList={warehouseList}
            />
          </ReciveItemPOContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default ReciveItemPO;
