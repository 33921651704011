import React from 'react'
import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Icon, Divider } from 'antd'
import LabeRequireForm from '../../../../components/label-required-form'
import { useIntl, FormattedMessage } from 'react-intl'
import SuffixInput from '../../../../components/v2/suffix'
import _ from 'lodash'
import moment from 'moment'
import { handleKeyDownDecimal2Fixed } from '../../../../component-function/fnc-number'

const { Option } = Select

const PettyCashFormReceivePaid = (props) => {
  const intl = useIntl()
  const { form, categoryList, userList, handleOpenModalCategory } = props
  const { getFieldDecorator } = form
  const filterCategoryList = _.filter(categoryList, (item) => { return item.type !== 'transfer' })

  const currencyFormatter = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };


  return (
    <Form form={form} layout="vertical" className="pettyCashModalForm" style={{ padding: '24px' }}>
      <Row gutter={[18, 6]} id='Form-Category&Remark'>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormCategory', defaultMessage: 'Category' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('category', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'pettyCashModalFormCategoryVL', defaultMessage: 'Please Select Category' }),
                },
              ],
            }
            )(
              <Select
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormCategoryPH', defaultMessage: 'Select Category' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                      onMouseDown={e => e.preventDefault()}
                      onClick={handleOpenModalCategory}
                    >
                      <Icon type="plus" style={{ color: '#595959' }} />
                      <span style={{ marginLeft: '8px', color: '#595959', fontSize: '13px' }}><FormattedMessage id="pettyCashModalFormCategoryAdd" defaultMessage="Add Category" /></span>
                    </div>
                  </div>
                )}
              >
                {
                  _.map(filterCategoryList, (item) => {
                    return (
                      <Option value={_.get(item, '_id')}>
                        {_.get(item, 'name')}
                      </Option>
                    )
                  })
                }
              </Select>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormRemark', defaultMessage: 'Remark' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('remark', {
            }
            )(
              <Input
                autoComplete="off"
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormRemarkPH', defaultMessage: 'Enter Remark' })}
              />
            )}
          </Form.Item>
        </Col>
      </Row >
      <Row gutter={[18, 6]} id='Form-Amount&Reference'>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormAmount', defaultMessage: 'Amount' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('amount', {
              initialValue: 0.00,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'pettyCashModalFormAmountVL', defaultMessage: 'Please Enter Amount' }),
                },
              ],
            }
            )(
              <InputNumber
                className="disabled-handle-count"
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormAmountPH', defaultMessage: 'Enter Amount' })}
                formatter={currencyFormatter}
                parser={currencyParser}
                controls={false}
                onKeyDown={handleKeyDownDecimal2Fixed}
              />
            )}
            <SuffixInput text="THB" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormReference', defaultMessage: 'Reference' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('reference', {
            }
            )(
              <Input
                autoComplete="off"
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormReferencePH', defaultMessage: 'Enter Reference' })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[18, 6]} id='Form-ActionBy&TransactionDate'>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormActionby', defaultMessage: 'Action By' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('actionBy', {
              initialValue: localStorage.getItem('memComId'),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'pettyCashModalFormActionbyVL', defaultMessage: 'Please Select Action By' }),
                },
              ],
            }
            )(
              <Select
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormActionbyPH', defaultMessage: 'Select Action By' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {_.map(_.get(userList, 'list'), (item) => {
                  return <Option key={item.mem_com_id}>{item.fullname}</Option>;
                })}
              </Select>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormTransactionDate', defaultMessage: 'Transaction Date' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('transactionDate', {
              initialValue: moment(),
            }
            )(
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={'DD/MM/YYYY HH:mm'}
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormTransactionDatePH', defaultMessage: 'Select Transaction Date' })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form >
  )
}

export default PettyCashFormReceivePaid
