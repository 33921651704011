import { Table, Card, Col, Row, Tabs, Button, Icon, Menu, Dropdown, Popover } from 'antd';
import React, { useContext, useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { PageSettings } from '../../../config/page-settings';
import { Sticky, StickyContainer } from 'react-sticky';
import Button01 from '../../v2/button_01';
import Css from './css/index.css';
import _ from 'lodash';
import NoDataBackground from '../../no-data-page';
import { DownOutlined } from '@ant-design/icons';
import { AllticketContext } from '../allticket-context';
import ButtonActionGroup from '../../action-button';

const { TabPane } = Tabs;

const RightCardAllticket = () => {
  const { state, setState, fnc } = useContext(AllticketContext);
  const {
    checkedKeysRequest,
    checkedKeysTeam,
    isLoading,
    toggle,
    selectedRowKeys,
    ticketData,
    page,
    total,
  } = state;
  const { setToggle, setEvent, setSort, setRequestDetailData, } = setState;
  const { handleChangePage, handleChangeSize, handleVisibleCreate, handleView, handleEdit, closeTicketModal } = fnc;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(1700);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const data = _.get(ticketData, 'data.ticketList');

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="AllTicketTicketNo" defaultMessage="Ticket No." />,
      dataIndex: 'ticketNo',
      key: 'ticketNo',
      sorter: true,
      fixed: 'left',
      width: 200,
    },
    {
      title: <FormattedMessage id="AllTicketTicketType" defaultMessage="Ticket Type" />,
      dataIndex: 'ticketType',
      key: 'ticketType',
      sorter: true,
      width: 150,
    },
    // {
    //   title: <FormattedMessage id="AllTicketStatus" defaultMessage="Status" />,
    //   dataIndex: 'status',
    //   key: 'status',
    //   sorter: true,
    //   width: 150,
    //   render: (data, record) => {
    //     return (
    //       <span>
    //         <p
    //           style={{
    //             // backgroundColor: record['statusColor'],
    //             backgroundColor: _.get(record, 'statusColor'),
    //             color: 'white',
    //             textAlign: 'center',
    //             borderRadius: '10px',
    //             height: '23px',
    //             width: '95px',
    //           }}
    //         >
    //           {data}
    //         </p>
    //       </span>
    //     );
    //   },
    //   // width: 200,
    // },
    {
      title: <FormattedMessage id="AllTicketRequestTeam" defaultMessage="Team." />,
      key: 'team',
      dataIndex: 'team',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketAssetNo" defaultMessage="Asset No." />,
      dataIndex: 'ticketTypeDetail.assetNo',
      key: 'ticketTypeDetail.assetNo',
      sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="RepairTicketSerialNo" defaultMessage="Serial No." />,
      dataIndex: 'ticketTypeDetail.serialNo',
      key: 'ticketTypeDetail.serialNo',
      sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="AllTicketdetail" defaultMessage="Ticket Details" />,
      key: 'ticketDetail',
      dataIndex: 'ticketDetail',
      width: 500,
    },
    {
      title: <FormattedMessage id="AllticketSubmittedDate" defaultMessage="Submitted Date" />,
      key: 'submittedDate',
      dataIndex: 'submittedDateTxt',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="AllTicketRequiredDate" defaultMessage="Required Date" />,
      key: 'requiredDate',
      dataIndex: 'requiredDateTxt',
      width: 200,
      sorter: true,
    },
  ];

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item>
          <Button ghost type="link" onClick={handleVisibleCreate}>
            <FormattedMessage id="btnCreate" defaultMessage="Create" />
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={handleToggle}>
          <Icon type={toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="ticket" defaultMessage="Ticket" />
          </p>
        </div>
      </div>
    );
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  // const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
  //   return (
  //     <div>
  //       <Popover
  //         placement="right"
  //         trigger="hover"
  //         align={{ offset: offsetPopover }}
  //         visible={visiblePopoverTable}
  //         zIndex={10000}
  //         content={
  //           <div style={{ display: 'grid' }}>
  //             <PageSettings.Consumer>
  //               {({ checkPermissionAction }) => (
  //                 <>
  //                   <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
  //                     <FormattedMessage id="btnView" defaultMessage="View" />
  //                   </Button>
  //                   {console.log('statusrecord', _.get(record, 'status'))}
  //                   {_.get(record, 'status') === 'Open' || _.get(record, 'status') === 'Draft' ? (
  //                     <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
  //                       <FormattedMessage id="btnEdit" defaultMessage="Edit" />
  //                     </Button>
  //                   ) : null}
  //                 </>
  //               )}
  //             </PageSettings.Consumer>
  //           </div>
  //         }
  //       >
  //         <div style={{ width: 0, height: 0 }} ref={ref}></div>
  //       </Popover>
  //     </div>
  //   );
  // };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  return (
    <div>
      {_.size(checkedKeysRequest) !== 0 || _.size(checkedKeysTeam) !== 0 ? (
        // <PageSettings.Consumer>
        //   {({ checkPermissionAction }) =>
        //     checkPermissionAction(`P1PG1`, `P1PG1A1`) ? (
              <Card
                title={<HeaderAndToggle />}
                className="right-cards"
                // extra={
                //   <div className="display-extra-button-group" style={{ padding: '0px 0px' }}>
                //     <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                //       <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                //         <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                //         <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                //       </Button01>
                //     </Dropdown>
                //   </div>
                // }
              >
                <div className="containerTables" onMouseLeave={handleMouseLeave}>
                  <Row gutter={16}>
                    <Table
                      className="custom-table-claim"
                      dataSource={data || []}
                      columns={columns}
                      scroll={{ x: scrollTableX, y: `calc(100vh - 313px)` }}
                      loading={isLoading}
                      onChange={onChangeTable}
                      rowClassName="rowcursor"
                      pagination={{
                        total: totalItems,
                        current: page,
                        defaultCurrent: 1,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        showSizeChanger: true,
                        locale: { items_per_page: '' },
                        showTotal: showTotal,
                        onChange: handleChangePage,
                        onShowSizeChange: handleChangeSize,
                      }}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            if (!event.target.href) {
                              // const { x, y } = ref.current.getBoundingClientRect();
                              // setVisiblePopoverTable(true);
                              // setOffsetPopover([event.pageX - x, event.pageY - y]);
                              // setRecordPopover(record);
                              setRequestDetailData(record);
                              closeTicketModal();
                            }
                          },
                        };
                      }}
                    />
                    {/* {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)} */}
                    <div className="total-items-member">
                      <span style={{ fontSize: '13px' }}>
                        <FormattedMessage id="lblTotal" defaultMessage="Total" />
                        {` ${totalItems || 0} `}
                        <FormattedMessage id="lblitems" defaultMessage="items" />
                      </span>
                    </div>
                  </Row>
                </div>
              </Card>
        //     ) : null
        //   }
        // </PageSettings.Consumer>
      ) : (
        <div style={{ padding: '24px', backgroundColor: '#fff' }}>
          <Card className="detail-card-blank-page">
            <NoDataBackground
              text1={{ id: 'nodataApproval1', text: 'Please select a Request' }}
              text2={{ id: 'nodataApproval2', text: 'Request will appear here.' }}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default RightCardAllticket;
