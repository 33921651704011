import React, { useState, useContext, useRef } from 'react';
import { CreateTaskContext } from './create-task-context';
import _ from 'lodash';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import { UploadImage } from '../../../../controllers/more-detail/more-detail-api';
import { v4 as uuidv4 } from 'uuid';
import draftToHtml from 'draftjs-to-html';
import { errorNotification } from './../../../v2/notification'
import './css/index.css'


export default () => {

  const { CreateState, CreateSetState, CreateFNC } = useContext(CreateTaskContext);

  const { editorStaet, allSrc, checkEnableTaskName } = CreateState
  const { setEditorStaet, setAllSrc, setImgSrc } = CreateSetState


  const uploadCallback = (file) => {
    return new Promise(
      async (resolve, reject) => {
        const nameFolder = `task_more_detail/${uuidv4()}`
        const data = new FormData();
        data.append("file", file);
        data.append('moduleName', nameFolder);
        const response = await UploadImage(data)
        if (_.get(response, 'status') === 200) {
          allSrc.push({
            fileCodeIs: _.get(response, 'data.data.fileCodeIs'),
            fullPathIs: _.get(response, 'data.data.fullPathIs')
          })
          setAllSrc(allSrc)
          resolve({ data: { link: _.get(response, 'data.data.fullPathIs') } });
        }
        else {
          errorNotification(_.get(response, 'data.status.message'))
          return reject({});
        }
      }
    );
  }

  const handleEditStateChange = (value) => {
    const covertData = convertToRaw(value.getCurrentContent())
    // console.log('covertData', covertData)
    const logImg = []
    _.map(covertData.entityMap, i => {
      logImg.push({
        fullPathIs: i.data.src
      })
    })
    // console.log('currentContentAsHTML ::', value)
    setImgSrc(logImg)
    setEditorStaet(value)
    convertHTML(value)
  }

  const convertHTML = (value) => {
    let currentContentAsHTML = draftToHtml(convertToRaw(value.getCurrentContent()));
    CreateSetState.setTextValue(currentContentAsHTML);

  }

  const config = {
    options: ['inline', 'list', 'textAlign', 'link', 'image', 'history'],
    inline: { options: ['bold', 'italic', 'underline'] },
    list: { options: ['unordered', 'ordered'], },
    link: { options: ['link'], },
    image: {
      uploadCallback: uploadCallback,
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      defaultSize: {
        height: '100',
        width: '80',
      },
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
    }

  }

  return (
    <div style={{padding:'16px 28px 16px 28px'}}>
      {/* <div > */}
      <Editor
        editorState={editorStaet}
        toolbar={config}
        onEditorStateChange={handleEditStateChange}
        editorStyle={checkEnableTaskName === 'workforce' ? { border: "1px solid #F1F1F1", height: '335px' } : { border: "1px solid #F1F1F1", height: '400px' }}
        editorClassName='editor-class'
      // readOnly={disbleEditor}
      // onBlur={onFoucsId(true)}
      />
      {/* </div> */}
    </div>
  )
}