import React from 'react'
import { Checkbox, Row, Col, Icon, Divider, Select, Button } from 'antd';
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import Button01 from '../../v2/button_01';

const { Option } = Select

const ListItemFilter = (props) => {
  const { onChange, searchGroup, itemCode, itemName, onClick } = props
  const intl = useIntl()

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '580px' }}>
        <div style={{ padding: '16px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextItemCode" defaultMessage="Item Code" />
            </div>
            <div>
              <Select
                showSearch
                allowClear={true}
                onSearch={(value) => itemCode.setItemCodeSearch(value)}
                onChange={(value) => onChange(value, 'itemCode')}
                dataSource={itemCode.optionsItemCode}
                filterOption={false}
                value={_.get(searchGroup, 'itemCode')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemCodePlaceholder', defaultMessage: 'Enter Item Code' })}
                style={{ width: '100%' }}
                notFoundContent={itemCode.loadingItemCode ? 'Loading...' : null}
              >
                {itemCode.optionsItemCode &&
                  itemCode.optionsItemCode.map((item) => (
                    <Option key={item.itemCode} value={item.itemCode}>
                      {item.itemCode}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextItemName" defaultMessage="Item Name" />
            </div>
            <div>
              <Select
                showSearch
                allowClear={true}
                onSearch={(value) => itemName.setItemNameSearch(value)}
                onChange={(value) => onChange(value, 'itemName')}
                dataSource={itemName.optionsItemName}
                filterOption={false}
                value={_.get(searchGroup, 'itemName')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemNamePlaceholder', defaultMessage: 'Enter Item Name' })}
                style={{ width: '100%' }}
              >
                {itemName.optionsItemName &&
                  itemName.optionsItemName.map((item, index) => (
                    <Option key={item.itemCode} value={item.itemName}>
                      {item.itemName}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px' }}>
        <Button01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={onClick}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button01>
      </div>
    </div>

  )
}

export default ListItemFilter
