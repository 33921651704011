import {
  Table,
  Card,
  Col,
  Row,
  Tabs,
  Button,
  Icon,
  Menu,
  Dropdown,
  Popover,
  Checkbox,
  Form,
  Input,
  Select,
  DatePicker,
  Divider,
} from 'antd';
import React, { useContext, useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Css from './css/index.css';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { PageSettings } from '../../../config/page-settings';
import Button_01 from '../../../components/v2/button_01';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import moment from 'moment';
import { ARReportContext } from '../arreport-context';
import ButtonActionGroup from '../../../components/action-button';
import { messageLabel } from '../../../components/message-component';
import Tag from '../../users/inviteUser/tag';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const CardTableRecivePO = () => {
  const { state, setState, fnc } = useContext(ARReportContext);
  const {
    poList,
    isLoading,
    page,
    total,
    searchGroup,
    brandList,
    dataModel,
    brandId,
    totalBrand,
    totalModel,
    warehouseList,
    totalWarhouse,
    totalGroup,
    totalType,
    statusList,
    memberList,
  } = state;
  const {
    setEvent,
    setSort,
    setSearchGroup,
    setPaginationPage,
    setBrandId,
    dataDefault,
    setTotalBrand,
    setTotalModel,
    setTotalWarhouse,
    setTotalType,
    setTotalGroup,
    setTypeView,
  } = setState;
  const {
    handleExport,
    handleCustomerPopupScroll,
  } = fnc;
  const { handleChangePage, handleChangeSize, handleVisibleCreate, handleView, handleEdit } = fnc;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(1750);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const itemGroupCode = _.get(dataDefault, 'itemGroup');
  const itemTypeCode = _.get(dataDefault, 'itemType');
  const itemBrand = _.get(dataDefault, 'itemBrand');
  const [datePicker, setDatePicker] = useState([moment().startOf('month'), moment().endOf('month')]);
  console.log('dataDefault', dataDefault);
  const data = [{ total: '1' }];

  const setShowColumn = {
    movementId: true,
    warehouseName: true,
    poNo: true,
    vendorOrSupplier: true,
    itemCode: true,
    itemName: true,
    lotSerialNo: true,
    qtyTxt: true,
    menufacturingDateTxt: true,
    expiredDateTxt: true,
    receivedBy: true,
    receivedDateTxt: true,
  };

  const setShowColumnArr = [
    'movementId',
    'warehouseName',
    'poNo',
    'vendorOrSupplier',
    'itemCode',
    'itemName',
    'lotSerialNo',
    'qtyTxt',
    'menufacturingDateTxt',
    'expiredDateTxt',
    'receivedBy',
    'receivedDateTxt',
  ];

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
    },
    {
      title: messageLabel('quotationLabelSaleOrderNo', 'Sale Order No.'),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      fixed: 'left',
      width: 200,
      ellipsis: true,
    },
    {
      title: messageLabel('ARInvoiceNo', 'Invoice No.'),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: true,
      
      width: 200,
    },
    {
      title: messageLabel('quotationLabelCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      // width: 200,
    },

    // {
    //   title: messageLabel('ARPaymentTermNo', 'Payment Term No.'),
    //   dataIndex: 'paymentTermNo',
    //   key: 'paymentTermNo',
    //   sorter: true,
    //   render: (text, record, index) => _.get(record, 'paymentTermNo'),
    // },
    {
      title: messageLabel('quotationLabelTotal', 'Total'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      align: 'right',
      width: 150,
    },
    {
      title: messageLabel('quotationLabelStatus', 'Status'),
      dataIndex: 'statusTxt',
      key: 'statusTxt',
      sorter: true,
      width: 200,
      align: 'center',
      render: (text, record, index) => (
        <span>
        <p
          style={{
            // backgroundColor: record['statusColor'],
            backgroundColor: _.get(record, 'statusColor'),
            color: 'white',
            textAlign: 'center',
            borderRadius: '10px',
            // height: '23px',
            // width: '95px',
          }}
        >
          {record.statusTxt}
        </p>
      </span>
      ),
    },
    {
      title: messageLabel('ARRemark', 'Remark'),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      width: 300,
    },
    {
      title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
      dataIndex: 'issueBy',
      key: 'issueBy',
      sorter: true,
      width: 150,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: true,
      width: 150,
      // render: (text, record, index) =>
      //   _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
    },
  ];

  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreColumnButton = ['movementId', 'warehouseName'];
  const columnNew = columns.filter((col) => !ignoreColumnButton.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                    {_.get(record, 'status') === 'Open' || _.get(record, 'status') === 'Draft' ? (
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button>
                    ) : null}
                  </>
                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  const menuSelectColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox value={item.key} onChange={checkedValuecolumns} checked={defaultShowColumn[item.key] ? true : false}>
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>{textErrorSelectColumn}</Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 3) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const onChange = (value, code) => {
    console.log('valueFillter', value);
    setSearchGroup({ ...searchGroup, [code]: value });
    setPaginationPage(1);
  };

  const children =
    dataModel &&
    dataModel.map((item) => (
      <Option key={item.assetModelId} value={item.assetModelId}>
        {item.assetModelName}
      </Option>
    ));

  const formFilterStock = () => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelSaleOrderNo', defaultMessage: 'Sale Order No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                onChange={(e) => onChange(e.target.value, 'saleOrderNo')}
                placeholder={intl.formatMessage({ id: 'quotationHintSaleOrderNo', defaultMessage: 'Enter Sale Order No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'ARInvoiceNo', defaultMessage: 'Invoice No.' })} req={false} />}
            >
              <Input
                allowClear
                onChange={(e) => onChange(e.target.value, 'invoice')}
                placeholder={intl.formatMessage({ id: 'ARInvoiceNoPalceHolder', defaultMessage: 'Enter Invoice No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />
              }
            >
              <Select
                placeholder={intl.formatMessage({ id: 'quotationHintCustomer', defaultMessage: 'Select Customer' })}
                onChange={(e) => onChange(e, 'customer')}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onPopupScroll={handleCustomerPopupScroll}
              >
                {memberList &&
                  memberList.map((item, index) => {
                    return (
                      <Option key={index} value={item.customerId}>
                        {item.customerName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          {/* <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'ARPaymentTermNo', defaultMessage: 'Payment Term No.' })} req={false} />
              }
            >
              <Input
                allowClear
                onChange={(e) => onChange(e.target.value, 'payment')}
                placeholder={intl.formatMessage({ id: 'ARPaymentTermNoPlaceHolder', defaultMessage: 'Enter Payment Term No' })}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'AllticketStatusForm', defaultMessage: 'Status' })} req={false} />}
            >
              <Select
              allowClear
                placeholder={intl.formatMessage({ id: 'AllticketEndStatueSelectForm', defaultMessage: 'Select Status' })}
                showSearch
                onChange={(e) => onChange(e, 'status')}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {statusList &&
                  statusList.map((item, index) => {
                    return (
                      <Option key={index} value={item.statusCode}>
                        {item.statuxTxt}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button ghost type="link" onClick={() => {handleExport();}}>
          <FormattedMessage id="btnExport" defaultMessage="Export" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          checkPermissionAction(`P1PG1`, `P1PG1A1`) ? (
            <Card
              title={<FormattedMessage id="ARReport" defaultMessage="AR Report" />}
              className="right-cards scroll-sm"
              style={{ margin: '8px'}}
              extra={<ButtonActionGroup menu={menuColumn} />}
            >
              <div style={{ marginTop: '20px' }}>
                <CollapseCustom
                  label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
                  extra={<RangePicker format={dateFormat} onChange={(value) => {onChange(value, 'issueDate');setDatePicker(value)}} allowClear={true} value={datePicker}/> }
                >
                  {formFilterStock()}
                </CollapseCustom>
              </div>
              <div className="containerTables scroll-sm" onMouseLeave={handleMouseLeave} style={{overflowY: 'scroll', height: 'calc(90vh - 180px)' }}>
                <Row gutter={16}>
                  <Table
                    className="custom-table-claim "
                    dataSource={poList || []}
                    columns={newDataColumns}
                    scroll={{ x: scrollTableX}}
                    // scroll={{ x: scrollTableX, y: `calc(100vh - 366px)` }}
                    loading={isLoading}
                    onChange={onChangeTable}
                    pagination={{
                      total: totalItems,
                      current: page,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      showSizeChanger: true,
                      locale: { items_per_page: '' },
                      showTotal: showTotal,
                      onChange: handleChangePage,
                      onShowSizeChange: handleChangeSize,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          if (!event.target.href) {
                            const { x, y } = ref.current.getBoundingClientRect();
                            setVisiblePopoverTable(true);
                            setOffsetPopover([event.pageX - x, event.pageY - y]);
                            setRecordPopover(record);
                          }
                        },
                      };
                    }}
                  />
                  <div className="total-items-member">
                    <span style={{ fontSize: '13px' }}>
                      <FormattedMessage id="lblTotal" defaultMessage="Total" />
                      {` ${totalItems || 0} `}
                      <FormattedMessage id="lblitems" defaultMessage="items" />
                    </span>
                  </div>
                </Row>
              </div>
            </Card>
          ) : null
        }
      </PageSettings.Consumer>
    </div>
  );
};

export default CardTableRecivePO;
