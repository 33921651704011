import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Table, Modal, Form, Input, Row, Col, Popover, Icon, Button } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Button03 from '../../../components/v2/button_03';
import { useParams } from 'react-router-dom';
import httpClient from '../../../components/axiosClient';
import cssStyle from './css/table.css';
import cssStyles from './css/emergencyContact.css';
import notification from '../../../components/v2.1.0/notification';
import { PageSettings } from '../../../config/page-settings';

const { confirm } = Modal;

export default React.memo((props) => {
  const { statusManageContact, statusPersanalInformation } = props;
  const intl = useIntl();
  const [dataUsers, setDataUsers] = useState([]);
  const [visiblePopover, setVisiblePopover] = useState(false);

  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false);
  const [memContactId, setMemContactId] = useState();
  const [eName, setEName] = useState();
  const [eRelation, setERelation] = useState();
  const [ePhone, setEPhone] = useState();
  const [eEmail, setEEmail] = useState();
  const formRef = useRef();

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (load) {
      getUsers();
      setLoad(false);
    }
  }, [load]);

  const param = useParams();
  const memComId = param.user_id;

  const getUsers = async () => {
    let result = await httpClient.get(`/v2/contacts/${memComId}/`);
    setDataUsers(result.data);
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const columns = [
    {
      title: '#',
      align: 'center',
      width: 70,
      dataIndex: 'row_index',
      key: 'row_index',
    },
    {
      title: intl.formatMessage({ id: 'userMgntUsersDetailColEmerContactName', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: 'userMgntUsersDetailColEmerContactRelation', defaultMessage: 'Relation' }),
      dataIndex: 'relation',
      key: 'relation',
    },
    {
      title: intl.formatMessage({ id: 'userMgntUsersDetailColEmerContactPhone', defaultMessage: 'Phone' }),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: intl.formatMessage({ id: 'userMgntUsersDetailColEmerContactEmail', defaultMessage: 'Email' }),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      key: 'more',
      align: 'center',
      width: 70,
      render: (text, record, index) => (
        <PageSettings.Consumer>
          {({ checkPermissionAction }) =>
            checkPermissionAction('P2S1PG2C2', 'P2S1PG2C2A3') || checkPermissionAction('P2S1PG2C2', 'P2S1PG2C2A4') ? (
              <Popover
                key={index}
                content={actionPopover(record)}
                placement="leftTop"
                visiblePopover={visiblePopover}
                onVisibleChange={handleOnVisiblePopover}
                zIndex={999}
              >
                <Icon type="more" />
              </Popover>
            ) : null
          }
        </PageSettings.Consumer>
      ),

      // <Popover
      //   key={record.mem_con_id}
      //   placement="leftTop"
      //   trigger="hover"
      //   // visible={visiblePopover}
      //   // onVisibleChange={handleVisibleChange}
      //   zIndex={999}
      //   content={
      //     <div>
      //       {statusManageContact ?
      //         <>
      //           <div>
      //             <Button01
      //               btnsize='wd_df'
      //               type='link'
      //               ghost
      //               onClick={() => handleEditContact(record)}
      //             >
      //               <FormattedMessage id='userMgntUsersDetailLblBtnEditEmerContact' defaultMessage='Edit' />
      //             </Button01>
      //           </div>
      //           <div>
      //             <Button01
      //               btnsize='wd_df'
      //               type='link'
      //               ghost
      //               onClick={() => handleDeleteContact(record.mem_con_id)}>
      //               <FormattedMessage id='userMgntUsersDetailLblBtnDeleteEmerContact' defaultMessage='Delete' />
      //             </Button01>
      //           </div>
      //         </>
      //         :
      //         <PageSettings.Consumer>
      //           {({ checkPermissionAction }) => (
      //             <>
      //               {checkPermissionAction('P2S1PG2C2', 'P2S1PG2C2A3') ?
      //                 <div>
      //                   <Button01
      //                     btnsize='wd_df'
      //                     type='link'
      //                     ghost
      //                     onClick={() => handleEditContact(record)}
      //                   >
      //                     <FormattedMessage id='userMgntUsersDetailLblBtnEditEmerContact' defaultMessage='Edit' />
      //                   </Button01>
      //                 </div>
      //                 :
      //                 ''
      //               }
      //               {checkPermissionAction('P2S1PG2C2', 'P2S1PG2C2A4') ?
      //                 <div>
      //                   <Button01
      //                     btnsize='wd_df'
      //                     type='link'
      //                     ghost
      //                     onClick={() => handleDeleteContact(record.mem_con_id)}>
      //                     <FormattedMessage id='userMgntUsersDetailLblBtnDeleteEmerContact' defaultMessage='Delete' />
      //                   </Button01>
      //                 </div>
      //                 :
      //                 ''
      //               }
      //             </>
      //           )}
      //         </PageSettings.Consumer>
      //       }
      //     </div>
      //   }
      // >
      //   <Icon type='more' />
      // </Popover>
    },
  ];

  // if (statusManageContact) {
  //   columns.push({
  //     title: '',
  //     key: 'more',
  //     align: 'right',
  //     render: (text, record, index) =>
  //       <Popover
  //         placement='left'
  //         content={<>
  //           <div>
  //             <>
  //               <div>
  //                 <Button01
  //                   btnsize='wd_df'
  //                   type='link'
  //                   ghost
  //                   onClick={() => handleEditContact(record)}
  //                 >
  //                   <FormattedMessage id='userMgntUsersDetailLblBtnEditEmerContact' defaultMessage='Edit' />
  //                 </Button01>
  //               </div>
  //               <div>
  //                 <Button01
  //                   btnsize='wd_df'
  //                   type='link'
  //                   ghost
  //                   onClick={() => handleDeleteContact(record.mem_con_id)}>
  //                   <FormattedMessage id='userMgntUsersDetailLblBtnDeleteEmerContact' defaultMessage='Delete' />
  //                 </Button01>
  //               </div>
  //             </>
  //           </div>
  //         </>
  //         }
  //       >
  //         <Icon type='more' />
  //       </Popover>
  //   });

  // } else {
  //   columns.push({
  //     title: '',
  //     key: 'more',
  //     align: 'right',
  //     render: (text, record, index) =>
  //       <Popover
  //         placement='left'
  //         content={<>
  //           <div>
  //             <PageSettings.Consumer>
  //               {({ checkPermissionAction }) => (
  //                 <>
  //                   {checkPermissionAction('P2S1PG2C2A1', 'P2S1PG2C2A3') ?
  //                     <div>
  //                       <Button01
  //                         btnsize='wd_df'
  //                         type='link'
  //                         ghost
  //                         onClick={() => handleEditContact(record)}
  //                       >
  //                         <FormattedMessage id='userMgntUsersDetailLblBtnEditEmerContact' defaultMessage='Edittt' />
  //                       </Button01>
  //                     </div>
  //                     :
  //                     ''
  //                   }
  //                   {checkPermissionAction('P2S1PG2C2A1', 'P2S1PG2C2A4') ?
  //                     <div>
  //                       <Button01
  //                         btnsize='wd_df'
  //                         type='link'
  //                         ghost
  //                         onClick={() => handleDeleteContact(record.mem_con_id)}>
  //                         <FormattedMessage id='userMgntUsersDetailLblBtnDeleteEmerContact' defaultMessage='Deletettt' />
  //                       </Button01>
  //                     </div>
  //                     :
  //                     ''
  //                   }
  //                 </>
  //               )}
  //             </PageSettings.Consumer>
  //           </div>
  //         </>
  //         }
  //       >

  //         <Icon type='more' />
  //       </Popover>
  //   });
  // }

  // if (getToggle('REACT_APP_CONTACT_ADD')) {
  //   columns.push({
  //     title: '',
  //     key: 'more',
  //     align: 'right',
  //     render: (text, record, index) =>
  //       <Popover
  //         placement='left'
  //         content={<>
  //           <div>
  //             {statusManageContact !== true ?
  //               <>
  //                 <div>
  //                   <Button01
  //                     btnsize='wd_df'
  //                     type='link'
  //                     ghost
  //                     onClick={() => handleEditContact(record)}
  //                   >
  //                     <FormattedMessage id='userMgntUsersDetailLblBtnEditEmerContact' defaultMessage='Edit' />
  //                   </Button01>
  //                 </div>
  //                 <div>
  //                   <Button01
  //                     btnsize='wd_df'
  //                     type='link'
  //                     ghost
  //                     onClick={() => handleDeleteContact(record.mem_con_id)}>
  //                     <FormattedMessage id='userMgntUsersDetailLblBtnDeleteEmerContact' defaultMessage='Delete' />
  //                   </Button01>
  //                 </div>
  //               </>
  //               :
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <>
  //                     {checkPermissionAction('P2S1PG2C2A1', 'P2S1PG2C2A3') ?
  //                       <div>
  //                         <Button01
  //                           btnsize='wd_df'
  //                           type='link'
  //                           ghost
  //                           onClick={() => handleEditContact(record)}
  //                         >
  //                           <FormattedMessage id='userMgntUsersDetailLblBtnEditEmerContact' defaultMessage='Edittt' />
  //                         </Button01>
  //                       </div>
  //                       :
  //                       ''
  //                     }
  //                     {checkPermissionAction('P2S1PG2C2A1', 'P2S1PG2C2A4') ?
  //                       <div>
  //                         <Button01
  //                           btnsize='wd_df'
  //                           type='link'
  //                           ghost
  //                           onClick={() => handleDeleteContact(record.mem_con_id)}>
  //                           <FormattedMessage id='userMgntUsersDetailLblBtnDeleteEmerContact' defaultMessage='Deletettt' />
  //                         </Button01>
  //                       </div>
  //                       :
  //                       ''
  //                     }
  //                   </>
  //                 )}
  //               </PageSettings.Consumer>
  //             }
  //           </div>
  //         </>
  //         }
  //       >

  //         <Icon type='more' />
  //       </Popover>
  //   });
  // }

  const BtnContactAdd = () => {
    return (
      <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleAddContact()}>
        <FormattedMessage id="userMgntUsersDetailBtnAssignNew" defaultMessage="Assign" />
      </Button01>
    );
  };

  const BtnContactAddPers = () => {
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {checkPermissionAction('P2S1PG2C2', 'P2S1PG2C2A2') ? (
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleAddContact()}>
                <FormattedMessage id="userMgntUsersDetailBtnAssignNew" defaultMessage="Assign" />
              </Button01>
            ) : (
              ''
            )}
          </div>
        )}
      </PageSettings.Consumer>
    );
  };

  const handleAddContact = () => {
    setMemContactId();
    setEName();
    setERelation();
    setEPhone();
    setEEmail();
    setVisible(true);
  };

  const handleEditContact = (args) => {
    setMemContactId(args.mem_con_id);
    setEName(args.name);
    setERelation(args.relation);
    setEPhone(args.phone);
    setEEmail(args.email);
    setVisible(true);
  };

  const handleDeleteContact = (id) => {
    confirm({
      className: 'emergency-contact-modal-confirm',
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      centered: true,
      icon: null,
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const contact = await httpClient.delete(`/v3/resource/manager/contact/deletedata/${id}`);
          if (contact.status === 200) {
            notification({ status: 'success', message: contact.data.status.message });
          } else {
            notification({ status: 'error', message: contact.data.status.message });
          }
        } catch (e) {
          notification({ status: 'error', message: e.response.statusText });
        }
        setLoad(true);
      },
    });
  };

  const handleCloseContact = () => {
    setVisible(false);
  };

  const handleOkContact = (e) => {
    e.preventDefault();
    formRef.current.validateFields(async (err, value) => {
      if (!err) {
        const contactDoc = {
          memComId: Number(memComId),
          name: value.name,
          relation: value.relation,
          phone: value.phone,
          email: value.email,
        };

        if (!memContactId) {
          try {
            const contact = await httpClient.post(`/v3/resource/manager/contact/createdata`, contactDoc);
            if (contact.status === 200) {
              notification({ status: 'success', message: contact.data.status.message });
            } else {
              notification({ status: 'error', message: contact.data.status.message });
            }
          } catch (error) {
            notification({ status: 'error', message: error.response.data.status.message });
          }
        } else {
          try {
            const contact = await httpClient.put(`/v3/resource/manager/contact/updatedata/${memContactId}`, contactDoc);
            if (contact.status === 200) {
              notification({ status: 'success', message: contact.data.status.message });
            } else {
              notification({ status: 'error', message: contact.data.status.message });
            }
          } catch (error) {
            notification({ status: 'error', message: error.response.data.status.message });
          }
        }

        setLoad(true);
        setVisible(false);
      }
    });
  };

  const actionPopover = (record) => {
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {statusManageContact ? (
              <>
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditContact(record)}>
                    <FormattedMessage id="userMgntUsersDetailLblBtnEditEmerContact" defaultMessage="Edit" />
                  </Button>
                </div>
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteContact(record.mem_con_id)}>
                    <FormattedMessage id="userMgntUsersDetailLblBtnDeleteEmerContact" defaultMessage="Delete" />
                  </Button>
                </div>
              </>
            ) : (
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <>
                      {checkPermissionAction('P2S1PG2C2', 'P2S1PG2C2A3') ? (
                        <div>
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditContact(record)}>
                            <FormattedMessage id="userMgntUsersDetailLblBtnEditEmerContact" defaultMessage="Edit" />
                          </Button>
                        </div>
                      ) : null}

                      {checkPermissionAction('P2S1PG2C2', 'P2S1PG2C2A4') ? (
                        <div>
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteContact(record.mem_con_id)}>
                            <FormattedMessage id="userMgntUsersDetailLblBtnDeleteEmerContact" defaultMessage="Delete" />
                          </Button>
                        </div>
                      ) : null}
                    </>
                  )}
                </PageSettings.Consumer>
              </>
            )}
          </div>
        )}
      </PageSettings.Consumer>
    );
  };

  const FormContact = Form.create({
    name: 'horizontal_login',
    mapPropsToFields(props) {
      return {
        name: Form.createFormField({
          value: eName,
        }),
        relation: Form.createFormField({
          value: eRelation,
        }),
        phone: Form.createFormField({
          value: ePhone,
        }),
        email: Form.createFormField({
          value: eEmail,
        }),
      };
    },
  })((props) => {
    const { getFieldDecorator } = props.form;

    return (
      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <FormattedMessage id="userMgntUsersDetailFormLblTitle" defaultMessage="We need a few details to create your Contact." />
          </Col>

          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'userMgntUsersDetailFormRequireInputName',
                      defaultMessage: 'Please input your name!',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'userMgntUsersDetailFormInputName', defaultMessage: 'Enter name' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('relation', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'userMgntUsersDetailFormRequireInputRelation',
                      defaultMessage: 'Please input your relation!',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'userMgntUsersDetailFormInputRelation', defaultMessage: 'Enter relation' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'userMgntUsersDetailFormRequireInputPhone',
                      defaultMessage: 'Please input your phone!',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'userMgntUsersDetailFormInputPhone', defaultMessage: 'Enter phone' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    type: 'email',
                    message: intl.formatMessage({
                      id: 'userMgntUsersDetailFormRequireInputEmail',
                      defaultMessage: 'Please input your email!',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'userMgntUsersDetailFormInputEmail', defaultMessage: 'Enter email' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  });

  return (
    <Card
      className="header-font-card"
      title={<FormattedMessage id="userMgntUsersDetailEmerContact" defaultMessage="Emergency Contact" />}
      extra={statusManageContact ? <BtnContactAdd /> : <BtnContactAddPers />}
    >
      <Table
        rowKey={(record) => record.row_index}
        // size='middle'
        columns={columns}
        dataSource={dataUsers}
        // className='table'
        // pagination={{ position: 'none', pageSize: 50 }}
        pagination={false}
      />

      <Modal
        title={`${
          !memContactId
            ? intl.formatMessage({ id: 'userMgntUsersDetailModalTitleAdd', defaultMessage: 'Add' })
            : intl.formatMessage({ id: 'userMgntUsersDetailModalTitleEdit', defaultMessage: 'Edit' })
        }${intl.formatMessage({ id: 'userMgntUsersDetailModalTitleEmerContact', defaultMessage: ' Emergency Contact' })}`}
        visible={visible}
        onCancel={handleCloseContact}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} className="btn-style-new" key="back" fontsize="sm" btnsize="wd_df" onClick={handleCloseContact}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 className="btn-style-new2" key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={handleOkContact}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <FormContact ref={formRef} />
      </Modal>
    </Card>
  );
});
