import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Modal, Transfer, Table } from 'antd'
import Button_02 from '../../../../../components/v2/button_02';
import Button_01 from '../../../../../components/v2/button_01';
import _ from 'lodash'
import { updateBranchMemberList } from '../../../../../controllers/delivery-order/branch';
import { successNotification, errorNotification } from '../../../../../components/v2/notification';

const MemberModal = (props) => {
  const { visible, setVisible, userList, id, memberList, setTrigger } = props
  const intl = useIntl()
  const [targetKeys, setTargetKeys] = useState([])
  const mapUser = _.map(userList, (item) => {
    return {
      key: item.mem_com_id.toString(),
      title: item.fullname,
      description: item.phone,
    }
  })

  useEffect(() => {
    if (visible && _.size(memberList) > 0) {
      setTargetKeys(_.map(memberList, (item) => { return _.toString(item) }))
    } else {
      setTargetKeys([])
    }
  }, [visible])


  const leftTableColumns = [
    {
      title: intl.formatMessage({ id: `memberModalColumnsName`, defaultMessage: 'Name' }),
      dataIndex: 'title',
    },
    {
      title: intl.formatMessage({ id: `memberModalColumnsPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'description',
    },
  ];
  const rightTableColumns = [
    {
      title: intl.formatMessage({ id: `memberModalColumnsName`, defaultMessage: 'Name' }),
      dataIndex: 'title',
    },
    {
      title: intl.formatMessage({ id: `memberModalColumnsPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'description',
    },
  ];

  const handleSave = async () => {
    const body = { branchMember: _.map(targetKeys, (item) => { return parseInt(item) }) }
    const response = await updateBranchMemberList(id, body)
    if (_.get(response, 'status') === 200) {
      successNotification(_.get(response, 'data.status.message'))
      setTrigger(cur => !cur)
      setVisible(false)
    } else {
      errorNotification(_.get(response, 'data.status.message'))
    }
  }

  const handleCancel = () => {
    setVisible(false)
  }

  console.log("targetKeys", targetKeys)

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={false}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? _.difference(treeSelectedKeys, listSelectedKeys)
              : _.difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys)
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `memberModalTilteCreate`, defaultMessage: 'Add Member' })}
      centered={true}
      width={1200}
      visible={visible}
      onCancel={handleCancel}
      bodyStyle={{ padding: '24px' }}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <TableTransfer
        dataSource={mapUser}
        targetKeys={targetKeys}
        onChange={onChange}
        showSearch
        filterOption={(input, option) =>
          option.title.indexOf(input) > -1 || option.description.indexOf(input) > -1
        }
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
      />
    </Modal>
  )
}

export default MemberModal
