import React, { useContext } from 'react'
import { Row, Col, Input, Select } from 'antd'
import style from './css/action-notification.css'
import { NotificationContext } from './notification-context';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_03 from '../../components/v2/button_03';


export default ({ columns, selectedRowKeys }) => {

  const intl = useIntl();

  const { Option } = Select;
  const { notiState, notiSetState, notiFNC } = useContext(NotificationContext);

  const ignoreSearchColumn = ['index', 'option'];
  const columnNewSearc = columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  return (
    <div style={{ paddingBottom: '16px' }}>
      <Row>
        <Col span={5}>
          <Input className='input-style action-input border-searth-allcol'
            placeholder={intl.formatMessage({ id: 'notificationSearch', defaultMessage: 'Search...' })} onChange={(e) => notiFNC.handleSearch(e.target.value)}
            autoComplete="off"
          ></Input>

        </Col>
        <Col span={3}>
          <Select className='input-style action-select' value={notiState.selectSearchData} onChange={notiFNC.handleSelectBy}>
            <Option value="all">
              <FormattedMessage id="reportLeaveReportHintSeleteAllColumns" defaultMessage="All columns" />
            </Option>
            {columnNewSearc.map((item, i) => (
              <Option key={i} value={item.key}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={16} className='text-action'>
          {notiState.disableAcknowledge !== undefined && notiState.disableAcknowledge == false ?
            // (<p className='actknowlege-style-disable'>  <FormattedMessage id="notificationAcknowledgeLbl" defaultMessage="Acknowledge" /></p>)
            (<Button_03 type='link' disabled ><FormattedMessage id="notificationAcknowledgeLbl" defaultMessage="Acknowledge" /></Button_03>)
            :
            // (<p className='actknowlege-style' onClick={notiFNC.acknowledgeStatus}>  <FormattedMessage id="notificationAcknowledgeLbl" defaultMessage="Acknowledge" /></p>)
            (<Button_03 type='link' onClick={notiFNC.acknowledgeStatus}><FormattedMessage id="notificationAcknowledgeLbl" defaultMessage="Acknowledge" /></Button_03>)
          }
        </Col>
      </Row>
    </div>
  )
}
