import { Divider, Input, Modal, Tabs, Tag } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import _ from 'lodash';
import ModalAllowanceDetail from './detail';
import ModalAllowanceAction from './modalAction';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import { updateAllowanceDetail } from '../../../../controllers/resource-allowance/allowance';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import ModalStatus from './modalStatus';
import { PageSettings } from '../../../../config/page-settings';
import { StickyContainer } from 'react-sticky';
import ModalAllowanceHistory from './history';
import './index.css';

const { TabPane } = Tabs;

const ModalAllowance = (props) => {
  const { visible, setVisible, data, setData, allowancType, setTrigger, rejectList, setTableChange, tableChange, total, isView } = props;
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const permissonEdit = checkPermissionAction('P80PG1C1', 'P80PG1C1A2');
  const permissonPending = checkPermissionAction('P80PG1C1', 'P80PG1C1A3');
  const permissonWaiting = checkPermissionAction('P80PG1C1', 'P80PG1C1A4');
  const permissonReject = checkPermissionAction('P80PG1C1', 'P80PG1C1A5');
  const permissonPaid = checkPermissionAction('P80PG1C1', 'P80PG1C1A8');
  const permissonApprove = checkPermissionAction('P80PG1C1', 'P80PG1C1A6');
  const intl = useIntl();
  const [visibleAction, setVisibleAction] = useState(false);
  const [editData, setEditData] = useState();
  const [allowanceItem, setAllowanceItem] = useState([]);
  const [remark, setRemark] = useState('');
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [tabkeys, setTabkeys] = useState('details');

  useEffect(() => {
    if (visible) {
      setAllowanceItem(_.get(data, 'allowanceItem'));
      setRemark(_.get(data, 'remark'));
    }
  }, [visible]);

  const handleStatus = (status) => {
    if (status === 'waiting' || status === 'pending' || status === 'approve' || status === 'paid') {
      const text =
        status === 'waiting' && _.get(data, 'statusCode') === 'pending'
          ? intl.formatMessage({ id: 'lblSendApprovalPending', defaultMessage: 'Do you want to send apprroval ?' })
          : status === 'waiting' && (_.get(data, 'statusCode') === 'reject' || _.get(data, 'statusCode') === 'approve')
          ? intl.formatMessage({ id: 'lblSendApprovalReject', defaultMessage: 'Do you want to change status to waiting for approval?' })
          : status === 'pending'
          ? intl.formatMessage({ id: 'lblSendPending', defaultMessage: 'Do you want to change status to pending ?' })
          : status === 'approve' && _.get(data, 'statusCode') === 'reject'
          ? intl.formatMessage({ id: 'lblSendApprovedReject', defaultMessage: 'Do you want to change status to approved?' })
          : status === 'approve'
          ? intl.formatMessage({ id: 'lblSendApproved', defaultMessage: 'Do you want to approved ?' })
          : status === 'paid'
          ? intl.formatMessage({ id: 'lblSendPaid', defaultMessage: 'Do you want to paid ?' })
          : '';
      Modal.confirm({
        className: 'customer-modal-confirm',
        centered: true,
        icon: null,
        title: text,
        okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        async onOk() {
          handleSave({ status: status });
        },
        onCancel() {},
      });
    } else {
      setVisibleStatus(true);
    }
  };

  const handleSave = async (status) => {
    const body = {
      statusCode: _.get(status, 'status', undefined),
      statusRemark: _.get(status, 'remark', undefined),
      statusReasonId: _.get(status, 'reasonId', undefined),
      allowanceItem: _.map(allowanceItem, (item) => {
        return {
          ...item,
          allowanceItemId: item.newItem ? undefined : item.allowanceItemId,
        };
      }),
      remark: remark,
    };
    const response = await updateAllowanceDetail(_.get(data, 'allowanceId'), body);

    if (_.get(response, 'data.status.code') === 200) {
      successNotification(_.get(response, 'data.status.message'));
      setVisible(false);
      setData();
      setIsEdit(false);
      setTabkeys('details');
      const calpage = (total - 1) % _.get(tableChange, 'pageSize');

      if (calpage === 0) {
        setTableChange({ ...tableChange, page: 1 });
      } else {
        setTrigger((cur) => !cur);
      }
    } else {
      errorNotification(_.get(response, 'data.status.message'));
    }
  };

  const handleClose = (type) => {
    setVisible(false);
    setData();
    setIsEdit(false);
    setTabkeys('details');
  };

  const footer =
    _.get(data, 'statusCode') === 'pending'
      ? [
          [
            <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button02>,
            <>
              {permissonEdit ? (
                isEdit ? (
                  <Button01 style={{ margin: 'unset' }} key="draft" type="primary" btnsize="wd_px" onClick={() => handleSave()}>
                    <FormattedMessage id="btnSave" defaultMessage="Save" />
                  </Button01>
                ) : (
                  <Button01 style={{ margin: 'unset' }} key="draft" type="primary" btnsize="wd_px" onClick={() => handleEdit()}>
                    <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                  </Button01>
                )
              ) : null}
            </>,
            <>
              {permissonWaiting ? (
                <Button01 key="submit" type="primary" btnsize="wd_at" onClick={() => handleStatus('waiting')}>
                  <FormattedMessage id="btnSendApproval" defaultMessage="Send Approval" />
                </Button01>
              ) : null}
            </>,
          ],
        ]
      : _.get(data, 'statusCode') === 'waiting'
      ? [
          <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <>
            {permissonPending ? (
              <Button01 style={{ margin: 'unset' }} key="draft" type="primary" btnsize="wd_px" onClick={() => handleStatus('pending')}>
                <FormattedMessage id="btnPending" defaultMessage="Pending" />
              </Button01>
            ) : null}
          </>,
          <>
            {permissonReject ? (
              <Button01 key="submit" type="primary" btnsize="wd_px" onClick={() => handleStatus('reject')}>
                <FormattedMessage id="btnReject" defaultMessage="Reject" />
              </Button01>
            ) : null}
          </>,
          <>
            {permissonApprove ? (
              <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_px" onClick={() => handleStatus('approve')}>
                <FormattedMessage id="btnApprove" defaultMessage="Approve" />
              </Button01>
            ) : null}
          </>,
        ]
      : _.get(data, 'statusCode') === 'approve' && _.get(data, 'canUpdateStatus') === true
      ? [
          <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <>
            {permissonWaiting ? (
              <Button01 style={{ margin: 'unset' }} key="draft" type="primary" btnsize="wd_px" onClick={() => handleStatus('waiting')}>
                <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
              </Button01>
            ) : null}
          </>,
          <>
            {permissonReject ? (
              <Button01 key="submit" type="primary" btnsize="wd_px" onClick={() => handleStatus('reject')}>
                <FormattedMessage id="btnReject" defaultMessage="Reject" />
              </Button01>
            ) : null}
          </>,
          <>
            {permissonPaid ? (
              <Button01 style={{ margin: 'unset' }} key="paid" type="primary" btnsize="wd_px" onClick={() => handleStatus('paid')}>
                <FormattedMessage id="btnPaid" defaultMessage="Paid" />
              </Button01>
            ) : null}
          </>,
        ]
      : _.get(data, 'statusCode') === 'reject'
      ? [
          <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <>
            {permissonWaiting ? (
              <Button01 style={{ margin: 'unset' }} key="draft" type="primary" btnsize="wd_px" onClick={() => handleStatus('waiting')}>
                <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
              </Button01>
            ) : null}
          </>,
          <>
            {permissonApprove ? (
              <Button01 key="submit" type="primary" btnsize="wd_px" onClick={() => handleStatus('approve')}>
                <FormattedMessage id="btnApprove" defaultMessage="Approve" />
              </Button01>
            ) : null}
          </>,
        ]
      : [
          <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
        ];

  const handleAddOrEdit = (data) => {
    if (data) setEditData(data);
    setVisibleAction(true);
  };

  const handleDeleteItem = (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const isDelete = _.filter(allowanceItem, (item) => {
          return item.allowanceItemId !== _.get(record, 'allowanceItemId');
        });
        const sortIndex = _.map(isDelete, (item, index) => {
          return {
            ...item,
            index: index + 1,
          };
        });
        setAllowanceItem(sortIndex);
        setVisiblePopoverTable(false);
        setRecordPopover();
        setOffsetPopover([0, 0]);
      },
      onCancel() {},
    });
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const operations = (
    <div className="tag-center-div" style={{ padding: '12px 12px' }}>
      <Tag style={{ width: 'auto', minWidth: '85px', padding: '0px 25px', borderRadius: '10px' }} color={_.get(data, 'statusColor')}>
        {_.get(data, 'statusTxt')}
      </Tag>
    </div>
  );

  return (
    <Modal
      title={intl.formatMessage({ id: `resourceAllowanceModalView`, defaultMessage: 'Allowance' }) + '・' + _.get(data, 'allowanceNo')}
      visible={visible}
      centered={true}
      width={1100}
      onCancel={handleClose}
      bodyStyle={{ padding: 'unset' }}
      footer={
        isView
          ? [
              <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button02>,
            ]
          : footer
      }
    >
      <StickyContainer>
        <Tabs
          className="monitor-main-tabs customer-tab-margin viewTicketTab"
          size="large"
          defaultActiveKey="details"
          onChange={callback}
          animated={false}
          tabBarExtraContent={operations}
          activeKey={tabkeys}
        >
          <TabPane
            className="viewTicketTab"
            tab={intl.formatMessage({ id: `AllowanceLabelDetails`, defaultMessage: 'Details' })}
            key="details"
          >
            {/* <div style={{ height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 24px' }}>
              <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
                <FormattedMessage id="resourceAllowanceModalDetail" defaultMessage="Detail" />
              </div>
              <div>
                <Tag
                  style={{ width: 'auto', minWidth: '85px', padding: '0px 25px', borderRadius: '10px' }}
                  color={_.get(data, 'statusColor')}
                >
                  {_.get(data, 'statusTxt')}
                </Tag>
              </div>
            </div>
            <Divider type="horizontal" style={{ margin: 'unset' }} /> */}
            <>
              <div style={{ height: '500px', overflowY: 'auto' }}>
                <ModalAllowanceDetail
                  data={data}
                  handleAddOrEdit={handleAddOrEdit}
                  handleDeleteItem={handleDeleteItem}
                  allowanceItem={allowanceItem}
                  permissonEdit={permissonEdit}
                  isEdit={isEdit}
                  popOver={{
                    visiblePopoverTable,
                    recordPopover,
                    offsetPopover,
                    setVisiblePopoverTable,
                    setRecordPopover,
                    setOffsetPopover,
                  }}
                />
              </div>
              <Divider type="horizontal" style={{ margin: 'unset' }} />
              <div style={{ height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '24px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ color: '#1D3557', fontSize: '12px', marginRight: '8px', fontWeight: 'bold' }}>
                    <FormattedMessage id="resourceAllowanceModalRemark" defaultMessage="Remark" />:
                  </div>
                  {_.get(data, 'statusCode') === 'pending' && permissonEdit && isEdit ? (
                    <Input style={{ width: '518px' }} value={remark} onChange={(e) => setRemark(e.target.value)} />
                  ) : (
                    remark
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ color: '#1D3557', fontSize: '12px', marginRight: '60px', fontWeight: 'bold' }}>
                    <FormattedMessage id="resourceAllowanceModalTotal" defaultMessage="Total" /> ({_.size(allowanceItem)}{' '}
                    <FormattedMessage id="resourceAllowanceModalItem" defaultMessage="Item" />) :
                  </div>
                  <div style={{ color: '#6490CF', fontSize: '18px' }}>
                    {numberFormatter(_.sumBy(allowanceItem, 'amount').toFixed(2))} THB
                  </div>
                </div>
              </div>
            </>
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: `AllowanceLabelHistory`, defaultMessage: 'History' })} key="history">
            <ModalAllowanceHistory historyList={_.get(data, 'statusLogs')} />
          </TabPane>
        </Tabs>
      </StickyContainer>

      <ModalAllowanceAction
        visible={visibleAction}
        setVisible={setVisibleAction}
        data={editData}
        setData={setEditData}
        allowancType={allowancType}
        allowanceItem={allowanceItem}
        setAllowanceItem={setAllowanceItem}
      />

      <ModalStatus
        title={`${intl.formatMessage({ id: 'resourceAllowanceModalStatusReject', defaultMessage: 'Rejected' })}・${_.get(
          data,
          'allowanceNo'
        )}`}
        visible={visibleStatus}
        setVisible={setVisibleStatus}
        handleSave={handleSave}
        rejectList={rejectList}
        data={data}
      />
    </Modal>
  );
};

ModalAllowance.dafaultProps = {
  isView: false,
};

export default ModalAllowance;
