import { ExportOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import CollapseTaskDetail from './collapse-task';
import { Card, Tag } from 'antd';
import DeliveryOrderTaskDetailForm from './form-task-detail';
import { DeliveryShipmentDetailContext } from '..';
import _ from 'lodash';
import { DeliveryContext } from '../../..';
import NoDataBackground from '../../../../../components/no-data-page';

const DeliveryOrderTaskDetail = () => {
  const { handleOpenModalTaskDetail, taskDetailData } = useContext(DeliveryShipmentDetailContext);
  const { selectShipmentData } = useContext(DeliveryContext);

  return (
    <div style={{ padding: '8px', height: 'calc(100vh - 165px)', overflowY: 'auto', backgroundColor: '#FAFAFA' }}>
      {_.size(taskDetailData) > 0 ? (
        _.map(taskDetailData, (i) => {
          return <div style={{ padding: '24px', overflowY: 'auto', backgroundColor: '#FFFFFF',marginBottom: '17px',borderRadius: '8px' }}>
            <CollapseTaskDetail
              label={
                <div style={{ display: 'flex', alignItems: 'center',fontSize: '14px' }}>
                  <div>
                    <FormattedMessage id="DOTask" defaultMessage="Task" />
                  </div>
                  <div
                    className="memberExpenseAllowanceLink"
                    style={{ cursor: 'pointer', color: '#6490CF', fontWeight: 'normal' }}
                    onClick={() => handleOpenModalTaskDetail(_.get(i, 'taskId'), 'view')}
                  >
                    {_.get(i, 'information.taskNo')} <ExportOutlined style={{ marginLeft: '8px' }} />
                  </div>
                </div>
              }
              extra={
                <Tag
                  className="tag-center-style"
                  style={{ textAlign: 'center', maxWidth: '110px', width: 'auto', minWidth: '80px' }}
                  color={_.get(i, 'information.statusColor')}
                >
                  {_.get(i, 'information.statusName')}
                </Tag>
              }
            >
              <DeliveryOrderTaskDetailForm data={i} />
            </CollapseTaskDetail>
          </div>
        })
      ) : (
        <div style={{ padding: '24px', backgroundColor: '#fff' }}>
          <Card style={{ height: 'calc(90vh - 160px)' }} className="deliveryBlank">
            <NoDataBackground
              text1={{ id: 'nodataDOTaskDetail1', text: 'No Task' }}
              text2={{ id: 'nodataDOTaskDetail2', text: 'Task will appear here.' }}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default DeliveryOrderTaskDetail;
