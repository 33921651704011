import React, { useState, useEffect, useContext, useMemo } from 'react';
import { ResponsiveLine, Line } from '@nivo/line';
import { useIntl, FormattedMessage } from 'react-intl';
import { MonitorContext } from '../monitor-context';
import moment from 'moment';
import _, { debounce } from 'lodash';
import { Icon, Tag, Button } from 'antd';
import MyTag from '../../../components/v2/checkableTag';
import ButtonCircle from '../../../components/v2/button-circle';
import stylesGraphSpeedTracking from './css/index.css';
import last from 'lodash/last';
import * as time from 'd3-time';
import { timeFormat } from 'd3-time-format';
import { withKnobs, boolean, select } from '@storybook/addon-knobs';

const { CheckableTag } = Tag;

const commonProperties = {
  // width: 1260,
  // height: 180,
  // animate: true,
  margin: { top: 10, right: 50, bottom: 50, left: 40 },
  enableSlices: 'x',
};

const mockData_1 = [
  { x: '2021-09-07 06:34', y: 10 },
  { x: '2021-09-07 07:34', y: 20 },
  { x: '2021-09-07 08:34', y: 90 },
  { x: '2021-09-07 10:34', y: 40 },
  { x: '2021-09-07 11:34', y: 50 },
  { x: '2021-09-07 12:34', y: 80 },
  { x: '2021-09-07 13:34', y: 70 },
  { x: '2021-09-07 14:34', y: 10 },
  { x: '2021-09-07 15:34', y: 20 },
  { x: '2021-09-07 16:34', y: 100 },
  { x: '2021-09-07 17:34', y: 110 },
  { x: '2021-09-07 18:34', y: 70 },
  { x: '2021-09-07 19:34', y: 60 },
  { x: '2021-09-07 21:34', y: 110 },
];

const mockData_2 = [
  { x: '2021-09-07 06:50', y: -5 },
  { x: '2021-09-07 07:50', y: 2 },
  { x: '2021-09-07 08:50', y: 4 },
  { x: '2021-09-07 10:50', y: 15 },
  { x: '2021-09-07 11:50', y: -10 },
  { x: '2021-09-07 12:50', y: 8 },
  { x: '2021-09-07 13:50', y: 40 },
  { x: '2021-09-07 14:40', y: 15 },
  { x: '2021-09-07 15:40', y: 25 },
  { x: '2021-09-07 16:40', y: 35 },
  { x: '2021-09-07 17:50', y: 28 },
  { x: '2021-09-07 18:50', y: -9 },
  { x: '2021-09-07 19:50', y: 5 },
  { x: '2021-09-07 20:50', y: -18 },
];

export default function GraphSpeedTracking(props) {
  const { value, setState } = props;
  const { stateMain, setStateMain } = useContext(MonitorContext);
  const { LangCode } = stateMain;
  let carRegistration = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.dataNoVehicle');
  let profile = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.memberProfile')
    ? _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.memberProfile')
    : '';

  let xMin = moment(stateMain.mainDate, 'YYYY-MM-DD')
    .startOf('day')
    .toDate();
  let xMax = moment(stateMain.mainDate, 'YYYY-MM-DD')
    .endOf('day')
    .toDate();

  const [dataLine, setDataLine] = useState([]);
  const [dataLineAll, setDataLineAll] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [datatrackConfig, setDatatrackConfig] = useState([]);
  const [isMobileGPS, setIsMobileGPS] = useState(true);
  const [isVehicleGPS, setIsVehicleGPS] = useState(false);
  const [isTemperature, setIsTemperature] = useState(false);

  useEffect(() => {
    const fncMapData = async () => {
      const polylineData = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.directions');

      const dataSpeedTrack = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack');

      const trackingConfig = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.trackingConfig');

      if (polylineData) {
        const newData = [
          {
            id: 'mobileGPS',
            color: '#1D3557',
            data: dataSpeedTrack.directions.map((e) => {
              return {
                x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
                y: e.speed,
              };
            }),
          },
          {
            id: 'vehicleGPS',
            color: '#79c749',
            data: dataSpeedTrack.directionsGPS.map((e) => {
              return {
                x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
                y: e.speed,
              };
            }),
          },
        ];

        const newTemperatureData = [
          {
            id: 'temperature',
            color: '#1890ff',
            data: dataSpeedTrack.directionsGPS.map((e) => {
              return {
                x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
                y: e.temperature,
              };
            }),
          },
        ];

        setDataLine(newData);
        setTempData(newTemperatureData);

        setDataLineAll(newData.concat(newTemperatureData));
      }

      if (trackingConfig) {
        const newConfig =
          trackingConfig &&
          trackingConfig
            .filter((item) => item.mobileUse === true)
            .map((o) => {
              return {
                axis: 'y',
                value: o.speed,
                lineStyle: {
                  stroke: o.codeColor,
                  strokeWidth: 1,
                  strokeDasharray: 5,
                },
              };
            });
        setDatatrackConfig(newConfig);
      }
    };
    fncMapData();
  }, [stateMain.mainResSpeedTrack]);

  useEffect(() => {
    const dataSpeedTrack = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack');

    if (isMobileGPS === true && isVehicleGPS === false && isTemperature === false) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: [],
        },
      ];

      const newTemperatureData = [
        {
          id: 'temperature',
          color: '#1890ff',
          data: [],
        },
      ];
      setTempData(newTemperatureData);
      setDataLine(newData);
    }
  }, [stateMain.mainResSpeedTrack]);

  const handleCloseSpeedTrack = () => {
    setStateMain.setMainResSpeedTrack({
      resId: null,
      openSpeedTrack: false,
      dataSpeedTrack: null,
      dataTask: [],
      statusColor: null,
    });
    setStateMain.setGoogleMapcenter(value.latLngResource);
  };

  // const dataLine = useMemo(() => {
  //   let newData = [];
  //   if (polylineData) {
  //     polylineData.map(item => {
  //       newData.push({
  //         x: moment(item.date + ' ' + item.time, 'YYYY-MM-DD hh:mm A').toDate(),
  //         y: item.speed
  //       })
  //     })
  //     return newData;
  //   }
  // }, [polylineData]);

  // const speedTooltip = ({ slice }) => {
  //   let points = slice.points[0].data;
  //   return (
  //     <div className="speed-tooltip">
  //       <div className="speed-tooltip-content">
  //         <span className="speed-tooltip-text">speed:</span>
  //         <span className="speed-tooltip-text">
  //           &nbsp;{points.yFormatted}&nbsp;
  //         </span>
  //         <span className="speed-tooltip-text">km/hr</span>
  //       </div>
  //       <div>
  //         <span className="speed-tooltip-text">
  //           {moment(points.xFormatted).format('hh:mm A')}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };

  const handleChangeMobile = (checked) => {
    const dataSpeedTrack = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack');
    if (checked === false && isVehicleGPS === true) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: [],
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
      ];
      setDataLine(newData);
      setIsMobileGPS(checked);
    } else if (checked === true && isVehicleGPS === false) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: [],
        },
      ];
      setDataLine(newData);
      setIsMobileGPS(checked);
    } else if (checked === true && isVehicleGPS === true) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
      ];
      setDataLine(newData);
      setIsMobileGPS(checked);
    } else if (checked === false && isVehicleGPS === false) {
      if (checked === false && isVehicleGPS === false && isTemperature === false) {
        return;
      } else {
        const newData = [
          {
            id: 'mobileGPS',
            color: '#1D3557',
            data: [],
          },
          {
            id: 'vehicleGPS',
            color: '#79c749',
            data: [],
          },
        ];
        setDataLine(newData);
      }
      setIsMobileGPS(checked);
    }
  };

  const handleChangeVehicle = (checked) => {
    const dataSpeedTrack = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack');

    if (checked === false && isMobileGPS === true) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: [],
        },
      ];
      setDataLine(newData);
      setIsVehicleGPS(checked);
    } else if (checked === true && isMobileGPS === false) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: [],
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
      ];
      setDataLine(newData);
      setIsVehicleGPS(checked);
    } else if (checked === true && isMobileGPS === true) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
      ];
      setDataLine(newData);
      setIsVehicleGPS(checked);
    } else if (checked === false && isMobileGPS === false) {
      if (checked === false && isMobileGPS === false && isTemperature === false) {
        return;
      } else {
        const newData = [
          {
            id: 'mobileGPS',
            color: '#1D3557',
            data: [],
          },
          {
            id: 'vehicleGPS',
            color: '#79c749',
            data: [],
          },
        ];
        setDataLine(newData);
      }
      setIsVehicleGPS(checked);
    }
  };

  const handleChangeTemperature = (checked) => {
    const dataSpeedTrack = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack');
    if (checked === true) {
      const newTemperatureData = [
        {
          id: 'temperature',
          color: '#1890ff',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.temperature,
            };
          }),
        },
      ];
      setTempData(newTemperatureData);
      setIsTemperature(checked);
    } else {
      if (checked === false && isMobileGPS === false && isVehicleGPS === false) {
        return;
      } else {
        const newTemperatureData = [
          {
            id: 'temperature',
            color: '#1890ff',
            data: [],
          },
        ];
        setTempData(newTemperatureData);
      }
      setIsTemperature(checked);
    }
  };

  return (
    <div className="graph-speed-tracking">
      <div className="graph-speed-tracking-container">
        <div className="graph-speed-tracking-detail">
          <div className="speed-tracking-detail">
            <span className="speed-tracking-text">{`${profile.name} ${profile.lastname}`}</span>
            <span className="speed-tracking-text-dot" />
            <span className="speed-tracking-text">{profile.phone}</span>
            {carRegistration &&
              carRegistration.map((item) => (
                <Tag className="speed-tracking-text-tag" key={item.itemId}>
                  {item.ItemNo}
                </Tag>
              ))}
          </div>
          <div className="speed-tracking-detail-button">
            <MyTag styleCss={'checkable-tag-orange'} checked={isMobileGPS} onChange={handleChangeMobile}>
              <FormattedMessage id={`monitorSpeedTrakingMobileGPSWorkForce`} defaultMessage="Mobile GPS" />
            </MyTag>
            <MyTag styleCss={'checkable-tag-green'} checked={isVehicleGPS} onChange={handleChangeVehicle}>
              <FormattedMessage id={`monitorSpeedTrakingVehicleGPSWorkForce`} defaultMessage="Vehicle GPS" />
            </MyTag>
            <MyTag styleCss={'checkable-tag-bule'} checked={isTemperature} onChange={handleChangeTemperature}>
              <FormattedMessage id={`monitorSpeedTrakingTemperatureWorkForce`} defaultMessage="Temperature" />
            </MyTag>

            <ButtonCircle
              style={{ marginLeft: '15px', marginRight: '10px' }}
              backColor={'#f0f2f5'}
              shape="circle"
              onClick={() => handleCloseSpeedTrack()}
            >
              <Icon type="close" />
            </ButtonCircle>
          </div>
        </div>
        <div className="graph-container">
          <div className="graph-text">
            <div className="graph-text-km-hr">km/hr</div>
            <div className="graph-text-C">ํC</div>
          </div>
          <div className="graph-speed-tracking-wrapper">
            <div className="graph-speed-tracking-graph">
              <ResponsiveLine
                {...commonProperties}
                // curve="linear"
                // isInteractive={false}
                // useMesh={false}
                enableGridX={false}
                enableGridY={false}
                enablePoints={false}
                lineWidth={2}
                layers={['grid', 'axes', 'lines', 'markers', 'legends']}
                data={dataLine}
                xScale={{
                  type: 'time',
                  format: 'native',
                  min: xMin,
                  max: xMax,
                }}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 120,
                }}
                axisLeft={{
                  tickValues: [0, 60, 80, 120],
                  legendPosition: 'middle',
                  legendOffset: -40,
                  tickSize: 5,
                  tickPadding: 8,
                }}
                axisBottom={{
                  format: '%H:%M',
                  tickValues: 'every 1 hours',
                  legendPosition: 'middle',
                  tickSize: 5,
                  tickPadding: 12,
                }}
                colors={{ datum: 'color' }}
                // tooltip={({ point  }) => {
                //     return (
                //       <div className="speed-tooltip">
                //         Graph 1
                //       </div>
                //     )
                //   }}
                // sliceTooltip={speedTooltip}
                // sliceTooltip={({ slice }) => {
                //   return (
                //     <div className="speed-tooltip">
                //       Graph1
                //     </div>
                //   )
                // }}
                markers={datatrackConfig}
                // theme={getColoredAxis('#1D3557')}
                // theme={getColoredAxis('##F7F8F8')}
                // fill={'#1D3557'}
              />
            </div>

            <div className="graph-speed-tracking-secondGraph">
              <SecondGraph dataLine={dataLine} tempData={tempData} xMin={xMin} xMax={xMax} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// I want this to be on top of the other graph
const SecondGraph = ({ dataLine, tempData, xMin, xMax }) => {
  const sumData = dataLine.concat(tempData);

  return (
    <div className="graph-speed-tracking-secondGraph">
      <ResponsiveLine
        {...commonProperties}
        // curve="linear"
        data={tempData}
        colors={{ datum: 'color' }}
        // theme={getColoredAxis('#1D3557')}
        // theme={getColoredAxis('##F7F8F8')}
        // fill={'#1D3557'}
        axisRight={{
          tickValues: [-33, -18, 4, 15, 40],
          legendPosition: 'middle',
          legendOffset: 40,
          tickSize: 5,
          tickPadding: 8,
        }}
        yScale={{
          type: 'linear',
          min: -33,
          max: 40,
        }}
        xScale={{
          type: 'time',
          format: 'native',
          min: xMin,
          max: xMax,
        }}
        // axisBottom={{
        //   format: '%H:%M',
        //   tickValues: 'every 1 hours',
        //   legendPosition: 'middle',
        //   tickSize: 5,
        //   tickPadding: 12,
        // }}
        axisBottom={null}
        isInteractive={false}
        useMesh={false}
        axisLeft={null}
        axisTop={null}
        lineWidth={2}
        enablePoints={false}
        enableGridX={false}
        enableGridY={false}
        // tooltip={({ point  }) => {
        //   return (
        //     <div className="speed-tooltip">
        //       Graph 2 point
        //     </div>
        //   )
        // }}
        // sliceTooltip={({ slice }) => {
        //   return (
        //     <div className="speed-tooltip">
        //       Graph2
        //     </div>
        //   )
        // }}
      />
    </div>
  );
};

const speedTooltip = ({ slice }) => {
  let points = slice.points[0].data;

  return (
    <div className="speed-tooltip">
      {/* <div className="speed-tooltip-content">
        <span className="speed-tooltip-text">speed:</span>
        <span className="speed-tooltip-text">
          &nbsp;{points.yFormatted}&nbsp;
        </span>
        <span className="speed-tooltip-text">km/hr</span>
      </div>
      <div>
        <span className="speed-tooltip-text">
          {moment(points.xFormatted).format('hh:mm A')}
        </span>
      </div> */}
    </div>
  );
};

const getColoredAxis = (color) => {
  return {
    axis: {
      ticks: {
        line: {
          stroke: color,
        },
        text: { fill: color },
      },
      legend: {
        text: {
          fill: color,
        },
      },
    },
  };
};
