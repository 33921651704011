import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import '../css/inviteUser.css';

const { Option } = Select;

export default (props) => {
    const [selectedItem, setSelectedItem] = useState();
    const roleSelect = JSON.parse(localStorage.getItem('roleSelect'));
    const roleId = JSON.parse(localStorage.getItem('roleId'));
    const roleName = JSON.parse(localStorage.getItem('roleName'));

    localStorage.setItem('selectedRole', JSON.stringify(selectedItem));

    useEffect(() => {
        setSelectedItem();
    }, [props.visible])


    const children = [];
    for (let i = 0; i < roleName.length; i++) {
        children.push(<Option key={roleId[i]} value={roleId[i]}>{roleName[i]}</Option>);
    }

    const handleChange = (value) => {
        setSelectedItem(value);
        props.functionCallRole(value);
    }

    let optionItems = roleSelect.map((item, index) =>
        <option key={index} value={item.per_gro_id.toString()} >
            {item.name}
        </option>
    );

    return (
        <div>
            <Select
                placeholder={<span><FormattedMessage id="userMgntUserModalHintRole" defaultMessage="Add role of users"/></span>}
                onChange={handleChange}
                style={{ width: '100%', marginTop: '10px' }}
                mode='multiple'
                allowClear='true'
                key={roleId}
                value={selectedItem}
            >
                {children}
            </Select>
        </div>
  );
};
