import httpClient from '../../components/axiosClient';

const getListTripAllowance = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/trip-allowance-setting/paging`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getGasPrice = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/freight-setting/paging`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const saveGasPrice = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/freight-setting`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const saveTripAllowance = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/trip-allowance-setting`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const saveAllowance = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/allowance-setting`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const editTripAllowance = async (payload, id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/task-setting/manager/${comCode}/trip-allowance-setting/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const editAllowance = async (payload, id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/task-setting/manager/${comCode}/allowance-setting/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const editGasPrice = async (payload, id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/task-setting/manager/${comCode}/freight-setting/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const deleteGasPrice = async (payload, id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.patch(`/v1/task-setting/manager/${comCode}/freight-setting/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const deleteAllowance = async (payload, id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.patch(`/v1/task-setting/manager/${comCode}/allowance-setting/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const deleteTripAllowance = async (payload, id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.patch(`/v1/task-setting/manager/${comCode}/trip-allowance-setting/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const editFreightPrice = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/task-setting/manager/${comCode}/freight-config/freight-price-today`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getAllowanceList = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/allowance-setting/paging`, payload);
    if (response.status === 200) {
      return response
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getDistance = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/company-distance/to/customer-address/gpsdistance`, payload)
    if (response.status === 200) {
      return response
    } else {
      return [];
    }
  } catch (error) { return []; }
}

const settingAllowanceExport = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/allowance-setting/paging/export`, payload);
    if (response.status === 200) {
      console.log('TestAloowance');
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const settingTripAllowanceExport = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/trip-allowance-setting/paging/export`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const settingGasPriceExport = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/task-setting/manager/${comCode}/freight-setting/paging/export`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export {
  getListTripAllowance,
  saveGasPrice,
  saveTripAllowance,
  saveAllowance,
  getGasPrice,
  editGasPrice,
  editTripAllowance,
  editAllowance,
  deleteGasPrice,
  deleteAllowance,
  deleteTripAllowance,
  editFreightPrice,
  getAllowanceList,
  settingAllowanceExport,
  settingTripAllowanceExport,
  settingGasPriceExport,
  getDistance
};
