import React, { useState, useEffect } from "react";
import { Select } from 'antd';
import httpClient from '../../../../components/axiosClient';
import _, { set } from 'lodash';
import FilterResult from "./filter-result";
import { v4 as uuidv4 } from 'uuid';


const { Option } = Select;
const ActionAllSelect = (props) => {
  const { assetNo, setActionSelected } = props;
  const [action, setAction] = useState();
  const [dataAction, setDataAction] = useState();
  const [assetActions, setAssetActions] = useState([]);

  useEffect(() => {
    getAllAction();
  }, [assetNo])


  const getAllAction = async () => {
    const payload = {
      indexStart: 0,
      // indexEnd: 3,
      actionType: 'all',
      createdBy: '',
    };
    const result = await httpClient.post(`/v3/log/manager/asset/${assetNo}/logs/list/from/2022-11-01/to/2022-11-10`, payload);

    setAction(result);
    // console.log("xxxxxxsrr", result);
    if (result.status === 200) {
      const assetResult = _.get(result, 'data.data.actionsList')
      setAssetActions(assetResult)
    }
  }

  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
    setDataAction(value)
    setActionSelected(value)
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  const options = assetActions.map && assetActions.map((item) =>
    <Option
      key={item.code}
      value={item.code}
    >
      {item.actionName}
    </Option>);


  return (
    <div>
      <Select
        showSearch
        style={{ width: 120 }}
        // placeholder={"All"}
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue="All"
      >
        {options}
      </Select>
    </div>
  )







}


export default ActionAllSelect;