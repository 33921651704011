import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
// import ModalCustomerQuotationForm from '....//select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import Button_02 from '../../../../../components/v2/button_02';
import Button_01 from '../../../../../components/v2/button_01';
import { handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import { AccountReceiveContext } from '../../../context';
import { v4 as uuidv4 } from 'uuid';
import './index.css'

const { Option } = Select;
const { TextArea, Search } = Input;
const dateFormat = 'ddd, MMM DD YYYY';

const ModalVoucherAR = ({
  form,
  title,
  visible,
  onOk,
  onCancel,
  saleOrderId,
  code,
  setSelectItem,
  selectItem,
  setReceiveData,
  receiveData,
  typepatment,
  paymentItem,
  openVoucherListModal,
  setPaymentItem,
  handleSelectVoucher,
  paidamount,
  setPaidamount,
  totalCal,
}) => {
  const intl = useIntl();
  const { state, setState, func } = useContext(AccountReceiveContext);
  const { paidData } = state;
  const { setAttachmentPaidData, setPaidData } = setState;
  const { handleOpenModalUpload } = func;
  const { getFieldDecorator, validateFields, resetFields } = form;

  useEffect(() => {
    if (visible) {

      validateFields(['amount'], (errors, values) => {

        if (errors) {
          console.log('Validation failed:', errors);
        } else {
          if (paidamount > totalCal) {
            setPaidamount(totalCal);
          }
        }
      });
    }
   
  }, [paidamount]);

  const handleSavePayment = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const useamount = handleSelectVoucher();
      console.log('sizeSee', useamount);
      const body = {
        id: uuidv4(),
        index: _.size(selectItem) + 1,
        statusCode: 'success',
        paymentId: _.get(paidData, 'paymentId'),
        description: values.remark,
        reasonId: '',
        methodName: 'Voucher',
        bankName: '',
        statusTxt: 'Success',
        reasonTxt: '',
        amountTxt: numberFormatter(paidamount),
        detail: {
          voucherNo: _.get(paymentItem, 'voucherNo'),
          brandId: _.get(paymentItem, 'brand.[0]'),
          modelId: _.get(paymentItem, 'model.[0]'),
          methodCode: 'voucher',
          voucherId: _.get(paymentItem, 'voucherId'),
          voucherName: _.get(paymentItem, 'voucherName'),
          amount: parseFloat(paidamount),
          paidDate: moment(values.paidDate).format('YYYY-MM-DD'),
        },
      };
      setSelectItem((prevItems) => [...prevItems, body]);
      // }
      setReceiveData(parseFloat(receiveData) + parseFloat(paidamount));
      // setReceiveData(parseFloat(receiveData) + parseFloat(useamount));
      // setPaymentItem([]);

      onCancel();
      resetFields();
    });
  };

  const handleAmount = (e, value) => {
    console.log('');
    if (parseFloat(value) > totalCal) {
      e.target.value = totalCal;
      setPaidamount(totalCal);
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => {
        resetFields();
        setPaymentItem([]);
        setPaidamount(0);
        onCancel();
      }}
      width={618}
      centered={true}
      bodyStyle={{ padding: '16px 24px' }}
      footer={[
        <Button_02
          style={{ margin: '0px 0px 0px 10px' }}
          key="back"
          btnsize="wd_df"
          onClick={() => {
            onCancel();
            resetFields();
            setPaymentItem([]);
            setPaidamount(0);
          }}
        >
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSavePayment()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="cash" colon={false}>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'ARVoucher', defaultMessage: 'Voucher' })} req={true} />}>
              {getFieldDecorator('voucherName', {
                initialValue: _.size(paymentItem) > 0 ? _.get(paymentItem, 'voucherName') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'ARVoucherPlaceHolder',
                      defaultMessage: 'Please Select Voucher',
                    }),
                  },
                ],
              })(
                <Search
                  className="voucherinput"
                  readOnly={true}
                  style={{ cursor: 'pointer', backgroundColor: '#' }}
                  placeholder={intl.formatMessage({
                    id: 'ARVoucherPlaceHolder',
                    defaultMessage: 'Select Voucher',
                  })}
                  onSearch={openVoucherListModal}
                  onClick={openVoucherListModal}
                  enterButton
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'ARBalance', defaultMessage: 'Balance' })} req={true} bold={true} />}
            >
              {getFieldDecorator('amount', {
                initialValue: _.size(paymentItem) > 0 ? numberFormatter(_.get(paymentItem, 'voucherBalance')) : numberFormatter(0),
              })(
                <Input
                  readOnly
                  onChange={(e) => handleAmount(e, e.target.value)}
                  style={{ backgroundColor: '#F8F8F8' }}
                  onKeyDown={handleKeyDownNumber}
                  placeholder={intl.formatMessage({ id: 'ARAmountPlaceHolder', defaultMessage: 'Enter Amount' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'ARPaidAmount', defaultMessage: 'Paid Amount' })} req={true} bold={true} />
              }
            >
              {getFieldDecorator('paidamount', {
                initialValue: paidamount ? numberFormatter(paidamount) : numberFormatter(0),
              })(
                <Input
                  readOnly
                  style={{ backgroundColor: '#F8F8F8' }}
                  onKeyDown={handleKeyDownNumber}
                  placeholder={intl.formatMessage({ id: 'ARAmountPlaceHolder', defaultMessage: 'Enter Amount' })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'ARPaidDate', defaultMessage: 'Paid Date' })} req={true} bold={true} />
              }
            >
              {getFieldDecorator('paidDate', {
                // initialValue: _.get(dataForm, 'issueDate') ? moment(_.get(dataForm, 'issueDate'), 'YYYY-MM-DD') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'ARPaidDateValidate', defaultMessage: 'Please Select Paid Date' }),
                  },
                ],
              })(
                <DatePicker
                  allowClear={false}
                  format={dateFormat}
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'ARPaidDatePlaceHolder', defaultMessage: 'Select Paid Date' })}
                />
              )}
              {/* : _.get(dataForm, 'issueDate')? _.get(dataForm, 'issueDateTxt'): '-' */}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              colon={false}
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })} req={false} />
              }
            >
              {getFieldDecorator(
                'remark',
                {}
              )(<Input placeholder={intl.formatMessage({ id: 'ARRemarkPlaceHolder', defaultMessage: 'Enter Remark' })} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalVoucherAR.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalVoucherARForm = Form.create({ name: 'Customer_Form' })(ModalVoucherAR);

export default ModalVoucherARForm;
