import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Row, Col, Select, InputNumber, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import _ from 'lodash';
import '../../index.css';
import LabeRequireForm from '../../../../components/label-required-form';
import getTeamUser from '../../../../controllers/user/get-user-team';
import getTeam from '../../../../controllers/user/get-team';
import { handleKeyDownDecimal2Fixed } from '../../../../component-function/fnc-number';
import { getTeamOrg } from '../../../../controllers/team/team';
import { fncGetUsers } from '../../../../controllers/user/users';
import { v4 as uuidv4 } from 'uuid';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';

const { Option } = Select;

const ModalFormAllowanceAction = (props) => {
  const { form, visible, setVisible, data, setData, allowancType, allowanceItem, setAllowanceItem } = props;
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  // const filterData = itemType ? _.filter(allowancType, (item) => { return item.amountType.includes(itemType) }) : []
  const [isSearch, setIsSearch] = useState(false);
  const amountRef = useRef(null);
  const [userList, setUserList] = useState({});
  const [teamList, setTeamList] = useState({});
  const notFirstAssignee = _.get(data,'index') >=2 ? true : false

  console.log('MOMMOMOO',data);
  

  useEffect(() => {
    const getInitialData = async () => {
      const memComId = localStorage.getItem('memComId');
      const getAssignee = await fncGetUsers(memComId);
      const getTeam = await getTeamOrg();
      setUserList({
        list: getAssignee,
        master: getAssignee,
      });
      setTeamList({
        list: _.get(getTeam, 'data'),
        master: _.get(getTeam, 'data'),
      });
    };
    getInitialData();
  }, []);

  useEffect(() => {
    const getDefault = async () => {
      const body = { search: '', searchBy: 'all', paging: 1, rowsPerPages: 1000 };
      const getAssigneeByTeam = await getTeamUser(_.get(data, 'orgId'), body);
      const getTeamByAssignee = await getTeam(_.get(data, 'assigneeId'));
      const mapAssign = _.map(_.get(getAssigneeByTeam, 'data.members.list'), (item) => {
        return {
          mem_com_id: parseFloat(item.memComId),
          fullname: item.fullname,
        };
      });
      const mapTeam = _.map(getTeamByAssignee, (item) => {
        return {
          org_id: parseFloat(item.org_id),
          name: item.name,
        };
      });
      setUserList({
        ...userList,
        list: mapAssign,
      });
      setTeamList({
        ...teamList,
        list: mapTeam,
      });
    };
    if (visible && data) {
      getDefault();
    } else {
      setUserList({
        ...userList,
        list: _.get(userList, 'master'),
      });
      setTeamList({
        ...teamList,
        list: _.get(teamList, 'master'),
      });
    }
  }, [visible, data]);

  const handleClose = () => {
    setVisible(false);
    resetFields();
    setData();
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const findAssignee = _.find(_.get(userList, 'list'), (item) => { return parseFloat(item.mem_com_id) === parseFloat(_.get(values, 'assignee')) })
      const findItem = _.find(allowancType, (item) => { return item.code === _.get(values, 'item') })
      const findTeam = _.find(_.get(teamList, 'list'), (item) => { return parseFloat(item.org_id) === parseFloat(_.get(values, 'team')) })
      const isDeduct = _.get(values, 'itemType') === 'deduct' ? _.get(values, 'amount', 0) * -1 : _.get(values, 'amount', 0)
      console.log("findAssignee", userList)
      const body = {
        allowanceItemId: data ? _.get(data, 'allowanceItemId') : uuidv4(),
        amount: parseFloat(isDeduct),
        amountTxt: `${numberFormatter((isDeduct).toFixed(2))} THB`,
        assignee: _.get(findAssignee, 'fullname') === '-' ? null : _.get(findAssignee, 'fullname') ?  _.get(findAssignee, 'fullname') : _.get(data, 'assignee')  ,
        assigneeId: _.get(values, 'assignee'),
        index: data ? _.get(data, 'index') : _.size(allowanceItem) + 1,
        isDefault: data ? _.get(data, 'isDefault') : false,
        item: _.get(data, 'isDefault') ? _.get(data, 'item') : _.get(findItem, 'txt'),
        itemAllowanceCode: _.get(data, 'isDefault') ? _.get(data, 'itemAllowanceCode') : _.get(values, 'item'),
        orgId: _.get(values, 'team'),
        payOrDeduct: _.get(values, 'itemType'),
        remark: _.get(values, 'remark'),
        team: _.get(findTeam, 'name') ?  _.get(findTeam, 'name')  : _.get(data, 'team'),
        newItem: data ? false : true,
      };
      setAllowanceItem(
        data
          ? _.map(allowanceItem, (item) => {
            return item.index === body.index ? body : item;
          })
          : allowanceItem.concat([body])
      );
      setVisible(false);
      resetFields();
      setData();
    });
  };

  const handleChangeTeam = async (value) => {
    if (!isSearch) {
      if (value) {
        const assignee = getFieldValue('assignee');
        const body = { search: '', searchBy: 'all', paging: 1, rowsPerPages: 1000 };
        const getAssigneeByTeam = await getTeamUser(value, body);
        const mapAssign = _.map(_.get(getAssigneeByTeam, 'data.members.list'), (item) => {
          return {
            mem_com_id: parseFloat(item.memComId),
            fullname: item.fullname,
          };
        });
        const findAssignee = _.find(mapAssign, (item) => {
          return item.mem_com_id === parseFloat(assignee);
        });
        setUserList({
          ...userList,
          list: mapAssign,
        });
        setFieldsValue({ team: value });
        setFieldsValue({ assignee: findAssignee ? assignee : undefined });
      } else {
        setIsSearch(false);
        setTeamList({ ...teamList, list: _.get(teamList, 'master') });
        setUserList({ ...userList, list: _.get(userList, 'master') });
        setFieldsValue({ assignee: undefined });
      }
    } else {
      setFieldsValue({ team: value });
    }
  };

  const handleChangeUser = async (value) => {
    if (!isSearch) {
      if (value) {
        const team = getFieldValue('team');
        const getTeamByAssignee = await getTeam(value);
        const mapTeam = _.map(getTeamByAssignee, (item) => {
          return {
            org_id: parseFloat(item.org_id),
            name: item.name,
          };
        });
        const findTeam = _.find(mapTeam, (item) => {
          return item.org_id === parseFloat(team);
        });
        setTeamList({
          ...teamList,
          list: mapTeam,
        });
        setFieldsValue({ assignee: value });
        setFieldsValue({ team: findTeam ? team : undefined });
      } else {
        setIsSearch(false);
        setTeamList({ ...teamList, list: _.get(teamList, 'master') });
        setUserList({ ...userList, list: _.get(userList, 'master') });
        setFieldsValue({ team: undefined });
      }
    } else {
      setFieldsValue({ assignee: value });
    }
  };

  const currencyFormatter = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const currencyParser = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\$\s?|(,*)/g, '');
    }
  };

  const mouseOver = () => {
    amountRef.current.blur();
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `resourceAllowanceModal${data ? 'Edit' : 'Add'}`,
        defaultMessage: `${data ? 'Edit' : 'Add'} Allowance Item`,
      })}
      visible={visible}
      centered={true}
      width={600}
      onCancel={handleClose}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
        <Button01
          style={{ margin: 'unset' }}
          key="draft"
          type="primary"
          btnsize="wd_px"
          onClick={handleSave}
          onMouseOver={() => mouseOver()}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical" className="resourceAllowanceForm">
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'resourceAllowanceModalItem', defaultMessage: 'Item' })}
                      req={_.get(data, 'isDefault') ? false : true}
                    />
                  }
                >
                  {getFieldDecorator('itemType', {
                    initialValue: _.get(data, 'payOrDeduct', 'pay'),
                  })(
                    <Select
                      className="resourceAllowanceModalFormSelectLeft"
                      style={{
                        borderTopRightRadius: 'unset',
                        borderBottomRightRadius: 'unset',
                        color: _.get(data, 'isDefault') === true ? '#050505' : null,
                      }}
                      disabled={_.get(data, 'isDefault')}
                    >
                      <Option key="pay">
                        <FormattedMessage id="resourceAllowanceModalItemPay" defaultMessage="Pay" />
                      </Option>
                      <Option key="deduct">
                        <FormattedMessage id="resourceAllowanceModalItemDeduct" defaultMessage="Deduct" />
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label={<LabeRequireForm text="" req={false} />}>
                  {getFieldDecorator('item', {
                    initialValue: _.get(data, 'itemAllowanceCode'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'resourceAllowanceModalItemVLD',
                          defaultMessage: 'Please Select Item',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="resourceAllowanceModalFormSelectRight"
                      style={{
                        color: _.get(data, 'isDefault') === true ? '#050505' : null,
                      }}
                      placeholder={intl.formatMessage({
                        id: 'resourceAllowanceModalItemPHD',
                        defaultMessage: 'Select Item',
                      })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      disabled={_.get(data, 'isDefault')}
                    >
                      {_.map(allowancType, (item) => {
                        return <Option key={item.code}>{item.txt}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'resourceAllowanceModalTeam', defaultMessage: 'Team' })} req={notFirstAssignee ? false : true} />}
            >
              {getFieldDecorator('team', {
                initialValue: _.get(data, 'orgId'),
                rules: notFirstAssignee ? null :  [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'resourceAllowanceModalTeamVLD', defaultMessage: 'Please Select Team' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'resourceAllowanceModalTeamPHD', defaultMessage: 'Select Customer' })}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  allowClear
                  onChange={handleChangeTeam}
                >
                  {_.map(_.get(teamList, 'list'), (item) => {
                    return <Option key={item.org_id}>{item.name}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceModalAssignee', defaultMessage: 'Assignee' })}
                  req={notFirstAssignee ? false : true}
                />
              }
            >
              {getFieldDecorator('assignee', {
                initialValue: _.get(data, 'assigneeId'),
                rules: notFirstAssignee ? null :  [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'resourceAllowanceModalAssigneeVLD', defaultMessage: 'Please Select Assignee' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'resourceAllowanceModalAssigneePHD', defaultMessage: 'Select Assignee' })}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  allowClear
                  onChange={handleChangeUser}
                >
                  {_.map(_.get(userList, 'list'), (item) => {
                    return <Option key={item.mem_com_id}>{item.fullname}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'resourceAllowanceModalAmount', defaultMessage: 'Amount' })} req={notFirstAssignee ? false : true} />
              }
            >
              {getFieldDecorator('amount', {
                initialValue: data ? Math.abs(_.get(data, 'amount')) : 0,
                rules: notFirstAssignee ? null :  [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'resourceAllowanceModalAmountVLD', defaultMessage: 'Please Enter Amount' }),
                  },
                ] ,
              })(
                <InputNumber
                  placeholder={intl.formatMessage({ id: 'resourceAllowanceModalAmountPHD', defaultMessage: 'Enter Amount' })}
                  className="disabled-handle-count"
                  onKeyDown={handleKeyDownDecimal2Fixed}
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  ref={amountRef}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'resourceAllowanceModalRemark', defaultMessage: 'Remark' })} req={false} />
              }
            >
              {getFieldDecorator('remark', {
                initialValue: _.get(data, 'remark'),
              })(<Input placeholder={intl.formatMessage({ id: 'resourceAllowanceModalRemarkPHD', defaultMessage: 'Enter Remark' })} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ModalAllowanceAction = Form.create({
  name: 'allowance-form',
  mapPropsToFields() { },
})(ModalFormAllowanceAction);

export default ModalAllowanceAction;
