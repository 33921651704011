import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon, Divider, Row, Col, List, Button } from 'antd';
import './css/index.css';
import _ from 'lodash';
import Nodata from '../../../../components/image/Nodata.svg';
import { FormattedMessage } from 'react-intl';
import { PlanningContext } from '../../../../pages/load-and-route/context';
import AppAvatar from '../../../avatar';
import InfiniteScroll from 'react-infinite-scroller';

export default () => {
  const ref = useRef();
  const { state, setState, fnc } = useContext(PlanningContext);
  const { vehicleScrollRef, vehicle, active, vehicleList, selectMark, hasMore, showData } = state;
  const { setHasMore, setShowData } = setState;
  const { handleSelectVehicleMap, onLoadMore } = fnc;
  const [loading, setLoading] = useState(false);


  const handleInfiniteOnLoad = () => {
    console.log('TestScroll', showData);
    setLoading(true);

    if (showData.length === vehicleList.length) {
      setHasMore(false);
      return;
    }
    setShowData(_.concat(showData, _.slice(vehicleList, showData.length, showData.length + 10)));
  };

  const vehicleRender = vehicleList && (
    <InfiniteScroll initialLoad={false} pageStart={0} loadMore={handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
      <List
        dataSource={showData}
        renderItem={(item) => {
          return (
            <List.Item key={item.id}
              style={{ backgroundColor: active === item.id && selectMark === true ? "#e0e7f5" : '#FFF' }}
            >
              <div
                className={`div-load-and-route-list`}
                key={item.id}
                onClick={() => handleSelectVehicleMap(item.id)}
              >
                <Row gutter={[16, 16]}>
                  <Col span={5}>
                    <AppAvatar size={50} icon={'user'} src={_.get(item.borrower, 'memberProfile') || ' '} />
                  </Col>
                  <Col span={19}>
                    <div className="lisence-load-and-route-div">
                      <div style={{ marginBottom: '4px' }}>
                        <span className={`text-span-vehicle ${active === item.id ? 'active-select-text' : ''}`}>
                          {_.get(item.borrower, 'fullname')} · {_.get(item.borrower, 'phone')}{' '}
                        </span>
                      </div>
                      <div>
                        <img
                          src={item.icon}
                          className={active === item.id || item.sending === 'vehicle_sending' ? 'vehicleSvg-active' : 'vehicleSvg'}
                        />
                      </div>
                    </div>

                    <div style={{ marginBottom: '4px' }}>
                      <span style={{ background: item.colorCode }} className={`text-span-vehicle spanVehicle`}>
                        {_.get(item, 'serialNo')} · {_.get(item, 'maxWeight')}
                        {' W'}
                      </span>
                    </div>

                    <div>
                      <span className={`text-span-vehicle ${active === item.id ? 'active-select-text' : ''}`}>
                        {item.loadedView} · {item.orderTotal} · {item.itemTotal}{' '}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              ;
            </List.Item>
          );
        }}
      ></List>
    </InfiniteScroll>
  );

  return (
    <div ref={vehicleScrollRef} className="list-vehicles scroll-sm">
      {vehicleList && _.size(vehicleList) !== 0 ? (
        vehicleRender
      ) : (
        <div className="postby-no-member">
          <div>
            <img src={Nodata} style={{ width: '100px' }}></img>
          </div>
          <div style={{ color: '#e4e4e4' }}>
            <FormattedMessage id="textNoData" defaultMessage="No Data" />
          </div>
        </div>
      )}
    </div>
  );
};
