import React, { useState, useContext } from 'react'
import { MonitorContext } from '../../monitor-context';
import { Card, Divider, Avatar, Row, Col, Tag, Modal } from 'antd'
import Button01 from '../../../../components/v2/button_01';
import styles from './css/task.css';
import AssigneeResource from '../../component/assignee-resource';
import moment from 'moment';
import _ from 'lodash'
import { FormattedMessage } from 'react-intl';


moment.locale('en');


export default React.memo((props) => {
  const { intl, task, resource, stateMain, setStateMain, } = props;
  const propIntl = intl;

  const [visibleAssignee, setVisibleAssignee] = useState(false);
  const { LangCode } = stateMain
  const address = props.task.address;
  const endTime = props.task.endTime;
  const memComIdTask = props.task.memComId;
  const startTime = props.task.startTime;
  const statusColor = props.task.statusColor;
  const statusId = props.task.statusId;
  const statusName = props.task.statusName;
  const taskId = props.task.taskId;
  const taskName = props.task.taskName;
  const taskTypeId = props.task.taskTypeId;
  const taskTypeName = props.task.taskTypeName;
  const assignee_img = props.task.assignee_img;
  const priorityName = props.task.priorityName;
  const task_no = props.task.task_no;
  const pathTaskStatus = props.task.pathTaskStatus;


  const resIdLoop = _.get(props, 'resource');

  const filterRes = resIdLoop && resIdLoop.filter(e => {
    return e.memComId === memComIdTask;
  })

  const assigneeName = _.get(task, 'assigneeName');

  const HeaderAssignee = (resource) => {
    return (
      <div>
        {
          resource.resourceinTask.length === 1 ? (
            resource && resource.resourceinTask.map((res) => (
              <Row>
                <div className='assignee-group'>
                  <Col span={8}>
                    <Avatar src={res.assignee_img ? res.assignee_img : ''} shape='circle' size='large'></Avatar>
                  </Col>
                  <Col span={16}>
                    <span className='ant-card-head-title-popover'>{res.name ? res.name : ''}</span><br />
                    <span className='ant-card-head-title-popover'>{res.phone ? res.phone : ''}</span>
                  </Col>
                </div>
              </Row>
            ))
          ) : resource.resourceinTask.length > 1 ? (
            <Row>
              <div className='assignee-group'>
                <Col span={8}>
                  <Avatar icon='team' shape='circle' size='large'></Avatar>
                </Col>
                <Col span={16}>
                  <span className='font-all'>{resource.resourceinTask.length} {" "}
                    <FormattedMessage id={`monitorTabMapViewTaskCardTexAssigneesWorkDesk`} defaultMessage="Assignees" />
                  </span>
                </Col>
              </div>
            </Row>

          ) : (<Row>
            <div className='assignee-group'>
              <Col span={8}>
                <Avatar icon='team' shape='circle' size='large'></Avatar>
              </Col>
              <Col span={16}>
                <span className='font-all'>
                  <FormattedMessage id={`monitorTabMapViewTaskCardTexNoAssigneeWorkDesk`} defaultMessage="No Assignee" />
                </span>
              </Col>
            </div>
          </Row>)
        }

      </div>
    )
  }

  const styleStatus = {
    backgroundColor: `${statusColor}`,
  };

  const headerButton = (taskId) => {
    if (taskId) {
      setStateMain.setMainTaskIdAssign(taskId);
      setStateMain.setVisibleAssignee(true);
    }
  }

  const handleOnClickTask = (taskId, pathTaskStatus) => {
    if (taskId) {
      setStateMain.setMainTaskId(taskId);
      setStateMain.setMarkerStatus(pathTaskStatus);
      setStateMain.setVisibleTaskDetailModal(true);
      setStateMain.setMarkerStatus(pathTaskStatus)
    }
  }



  return (
    <Card
      title={<HeaderAssignee resourceinTask={assigneeName} />}
      extra={<Button01 onClick={() => headerButton(task.taskId)} btnsize="wd_at" type="primary">{propIntl.formatMessage({ id: `monitorTabMapViewTaskCardAssignWorkDesk`, defaultMessage: 'Assignee' })}</Button01>}
      className='card-task'
      bodyStyle={{ padding: 0 }}
    >
      <div className="task-content">
        <div
          className="task-content-text-statusCode"
          onClick={() => handleOnClickTask(task.taskId, pathTaskStatus)}
        >
          <p className="task-content-text-p-statusCode">{propIntl.formatMessage({ id: `monitorTabMapViewTaskCardTaskWorkDesk`, defaultMessage: 'Task Nos' })} # {task_no}</p>

        </div>
        <div className="task-content-content-container">
          {/* <div className="task-content-styte-details">
            <span className="task-content-text"> {propIntl.formatMessage({ id: 'monitorTabMapViewTaskCardArea', defaultMessage: 'Area' })} : </span>
            <p className='task-content-text-p'>{taskName}</p>
          </div> */}
          <div className="task-content-styte-details">
            <span className="task-content-text">{propIntl.formatMessage({ id: `monitorTabMapViewaskCardTaskNameWorkDesk`, defaultMessage: 'Task Name' })} : </span>
            <p className='task-content-text-p'>{taskName}</p>
          </div>
          <div className="task-content-styte-details">
            <span className="task-content-text">{propIntl.formatMessage({ id: `monitorTabMapViewTaskCardPriorityWorkDesk`, defaultMessage: 'Priority' })} : </span>
            <p className='task-content-text-p'>{priorityName}</p>
          </div>
          <div className="task-content-styte-details">
            <span className="task-content-text">{propIntl.formatMessage({ id: `monitorTabMapViewTaskCardPlanStartDateWorkDesk`, defaultMessage: 'Start date' })} : </span>
            <p className='task-content-text-p'>{moment.unix(startTime).format('LL')}</p>
          </div>
          <div className="task-content-styte-details">
            <span className="task-content-text">{propIntl.formatMessage({ id: `monitorTabMapViewTaskCardDeadlineWorkDesk`, defaultMessage: 'Due Date' })} : </span>
            <p className='task-content-text-p'>{moment.unix(endTime).format('h:mm A')}</p>
          </div>
          <div className="task-content-styte-details">
            <span className="task-content-text">{propIntl.formatMessage({ id: `monitorTabMapViewTaskCardStatusWorkDesk`, defaultMessage: 'Status' })} : </span>
            <Tag className="div-details-tag-status" style={styleStatus}>
              <p className="div-details-tag-p">{statusName}</p>
            </Tag>
          </div>
        </div>
        <Divider />
        <div className="task-content-content-container">
          <div className="task-content-styte-details-address">
            <span className="task-content-text">{propIntl.formatMessage({ id: `monitorTabMapViewTaskCardAddressWorkDesk`, defaultMessage: 'Address' })} : </span>
            <p className='task-content-address-text-p'>{address}</p>
          </div>
        </div>
      </div>
    </Card>
  )
},
  //   (prev , next) =>{
  //   return prev.stateMain === next.stateMain
  //   && prev.visibleAssignee === next.visibleAssignee
  //   && prev.setVisibleAssignee === next.setVisibleAssignee
  // }
);