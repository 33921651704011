import React from 'react'
import { TreeSelect, Checkbox } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'

export default React.memo((props) => {
  const { dataMember, onSelectTeamPostBy, memberOrganizationSelect, memberCheckAll, memberCountChecked, memberCountAll, onCheckAllMember, memberIndeterminate } = props
  const intl = useIntl();

  return (
    <div>
      <div style={{ padding: '16px' }}>
        <TreeSelect
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: '460px', overflow: 'auto', width: '300px' }}
          placeholder={intl.formatMessage({ id: 'lblSeleteHintSelete', defaultMessage: 'Please Select' })}
          treeDefaultExpandAll
          treeData={dataMember}
          allowClear
          onChange={onSelectTeamPostBy}
          value={memberOrganizationSelect}
        />
      </div>
      <div className='summary-customer-checked2'>
        <div>
          <Checkbox
            checked={memberCheckAll}
            onChange={onCheckAllMember}
            indeterminate={memberIndeterminate}>
            <FormattedMessage id='engagementTabCustomerTextSelected' defaultMessage='Selected' /></Checkbox>
        </div>
        <div>
          <span>{memberCountChecked} of {memberCountAll}</span>
        </div>
      </div>
    </div>
  )
}, (prev, next) => {
  return _.isMatch(prev, next)
})
