import React, { useState } from 'react';
import CustomerForm from './customer-form-view';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import CustomTableComponent from '../../../components/table';
import PriceForm from './price-form-view';
import { Button, Divider } from 'antd';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import ReactBnbGallery from 'react-bnb-gallery';
import PriceFormInvoice from '../price-form-pending';

const DetailsTabView = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    dataForm,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    record,
    checkQty,
  } = property;
  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();

  console.log('ViewView',record,'/',dataForm);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: (a, b) => {
        return a.itemCode.localeCompare(b.itemCode);
      },
      width: 150,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: (a, b) => {
        return a.itemName.localeCompare(b.itemName);
      },
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qtyTxt',
      key: 'qtyTxt',
      sorter: (a, b) => {
        return a.qtyTxt.localeCompare(b.qtyTxt);
      },
      // render: (text, record, index) => console.log('qtyTxt');,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'pricePerUnit',
      key: 'pricePerUnit',
      sorter: (a, b) => {
        return a.pricePerUnit.localeCompare(b.pricePerUnit);
      },
      // render: (text, record, index) => _.get(record,'pricePerUnit') + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'ARColumnCredits', defaultMessage: 'Credit (days)' }),
      dataIndex: 'credit',
      key: 'credit',
      sorter: (a, b) => {
        return a.credit.localeCompare(b.credit);
      },
      render: (text, record, index) => (record.credit ? record.credit : '-'),
    },
    // {
    //   title: intl.formatMessage({ id: 'purchesOrderModalColumnPromotionCampaign', defaultMessage: 'Promotion or Campaign' }),
    //   dataIndex: 'promotionCampaign',
    //   key: 'promotionCampaign',
    //   render: (text, record, index) => record.promotionCampaign || '-',
    // },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountTHB',
      key: 'discountTHB',
      sorter: (a, b) => {
        return a.discountTHB.localeCompare(b.discountTHB);
      },
      render: (text, record, index) => _.get(record, 'discountPercent').toFixed(2) + '%' + ' · ' + _.get(record, 'discountTHB'),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      fixed: 'right',
      width: 150,
      sorter: (a, b) => {
        return a.totalTxt.localeCompare(b.totalTxt);
      },
      // render: (text, record, index) => _.get(record,'totalTxt') + ' ' + 'THB',
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      render: (x, data) =>
        _.get(record, 'code') === 'view' ? <span onClick={() => handlePreview(data)}>{data.attachmentName}</span> : data.attachmentName,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  console.log('attData', record);

  const deleteAtt = (value) => {
    const filterSelect = _.filter(attData, (item) => {
      return item.id !== value;
    });
    setAttData(filterSelect);
  };

  const handleEditUploadInside = (value) => {
    handleOpenModalUpload(value);
  };

  const deleteItem = (value) => {
    const filterSelect = _.filter(selectItem, (item) => {
      return item.itemMasterId !== value;
    });
    setSelectItem(filterSelect);
  };

  const contentActionItem = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record.itemMasterId)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record.id)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const handlePreview = async (file) => {
    console.log('filehandlePreview', file);
    let isPDF = _.get(file, 'attachmentName').includes('.pdf');
    if (isPDF) return window.open(_.get(file, 'fileRaw'), '_blank');
    const mapdata = {
      photo: _.get(file, 'fileRaw'),
      number: 1,
      caption: _.get(file, 'remark') !== 'undefined' ? _.get(file, 'remark') : undefined,
      thumbnail: _.get(file, 'fileRaw'),
    };

    console.log('mapdata', mapdata);
    setModalView(mapdata);
    setStateModal(!stateModal);
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={() => setStateModal(false)} />
      </div>
    );
  };

  return (
    <div className="scroll-sm scroll-height">
      <div className="content-body">
        <CustomerForm
          form={form}
          setDataForm={setDataForm}
          dataForm={dataForm}
          initialMemberList={initialMemberList}
          handleOpen={handleOpen}
        />
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelItem" defaultMessage="Item" />
          </span>
          <div>
            {/* <Button_01 key="add" style={{ margin: '0px 0px 0px 10px' }} type="primary" btnsize="wd_df" onClick={() => setVisibleItem(true)}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01> */}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={false}
            role={false}
            columns={columns}
            dataSource={selectItem}
            scroll={{ x: true }}
            contentAction={contentActionItem}
            rowKey={(record) => record.uuid}
          />
          {checkQty ? (
            <span style={{ color: '#E73845', fontSize: '14px' }}>
              <FormattedMessage
                id="lblAvailableStockQuantity"
                defaultMessage="*The highlighted quantity is over the available stock quantity, please recheck."
              />
            </span>
          ) : null}
          <div className="content-body-price">
          <PriceFormInvoice
              form={form}
              selectItem={selectItem}
              setDataForm={setDataForm}
              dataForm={dataForm}
              initialTaxList={initialTaxList}
              record={record}
              // form={form}
              // selectItem={selectItem}
              // setDataForm={setDataForm}
              // dataForm={dataForm}
              // initialTaxList={initialTaxList}
              // record={record}
              // onChangeDiscount={onChangeDiscount}
              // searchGroup={searchGroup}
            />
            {/* <PriceForm form={form} selectItem={selectItem} setDataForm={setDataForm} dataForm={dataForm} initialTaxList={initialTaxList} /> */}
          </div>
        </div>
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelAttactment" defaultMessage="Attactment" />
          </span>
          <div>
            {/* <Button_01
              key="add"
              style={{ margin: '0px 0px 0px 10px' }}
              type="primary"
              btnsize="wd_df"
              onClick={() => handleOpenModalUpload()}
            >
              <FormattedMessage id="btnUpload" defaultMessage="Upload" />
            </Button_01> */}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={false}
            role={true}
            columns={columnsAttach}
            dataSource={attData}
            scroll={{ x: true }}
            contentAction={contentAction}
          />
          <HandlerModal />
        </div>
      </div>
    </div>
  );
};

export default DetailsTabView;
