import React, { useEffect, useState } from 'react';
import CollapseCustom from '../../../components/collapse-custom';
import { Col, Form, Input, Row, Select } from 'antd';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import LabeRequireForm from '../../../components/label-required-form';
import { getCustomerLoadMore } from '../../../controllers/getCustomer/get-customer-new';
import './index.css';
import LoadmoreCustomer from '../../../components/loadmore/customer';
import LoadmoreProject from '../../../components/loadmore/project';
import LoadmoreTaskType from '../../../components/loadmore/taskType';

const { Option } = Select;
const InputGroup = Input.Group;

const FilterSetting = ({ type, search, setSearch, handleApply, handleReset, onFilterOrder, extra }) => {
  const intl = useIntl();

  const formFilter = () => {
    return (
      <Form layout="vertical" className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <LoadmoreCustomer
              data={search}
              setData={setSearch}
              required={false}
            />
          </Col>

          <Col span={8}>
            <LoadmoreProject
              data={search}
              setData={setSearch}
              required={false}
            />
          </Col>

          <Col span={8}>
            <LoadmoreTaskType
              data={search}
              setData={setSearch}
              required={false}
            />
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'settingTransportationColumnsSource', defaultMessage: 'Source' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'settingTransportationPHRefSource',
                  defaultMessage: 'Enter Source',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'source')}
                value={_.get(search, 'source') || undefined}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'settingTransportationColumnsDestination', defaultMessage: 'Destination' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'settingTransportationPHRefDestination',
                  defaultMessage: 'Enter Destination',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'destination')}
                value={_.get(search, 'destination') || undefined}
              />
            </Form.Item>
          </Col>

          {/* {type === 'Allowance' ? (
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'settingTransportationColumnsDistance', defaultMessage: 'Distance (km)' })}
                    req={false}
                  />
                }
              >
                <InputGroup compact>
                  <Input
                    className="borderDistantLeft"
                    style={{ width: '50%' }}
                    placeholder={intl.formatMessage({
                      id: 'settingTransportationColumnsDistanceFrom',
                      defaultMessage: 'Select From Distance',
                    })}
                    onChange={(e) => onFilterOrder(e.target.value, 'distance1')}
                    value={_.get(search, 'distance1') || undefined}
                  />
                  <Input
                    className="borderDistantRight"
                    style={{ width: '50%', borderLeft: 0 }}
                    placeholder={intl.formatMessage({ id: 'settingTransportationColumnsDistanceTo', defaultMessage: 'Select To Distance' })}
                    onChange={(e) => onFilterOrder(e.target.value, 'distance2')}
                    value={_.get(search, 'distance2') || undefined}
                  />
                </InputGroup>
              </Form.Item>
            </Col>
          ) : ( */}
            <Col span={8} style={{ textAlign: 'right' }}>
              <Form.Item label={<LabeRequireForm text={''} req={false} />}>
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                  <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                </Button_01>
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                  <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                </Button_02>
              </Form.Item>
            </Col>
          {/* )} */}
        </Row>
        {/* {type === 'Allowance' ? (
          <>
            <Row gutter={[24, 2]}>
              <Col span={16}></Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                <Form.Item label={<LabeRequireForm text={''} req={false} />}>
                  <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                    <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                  </Button_01>
                  <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                    <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                  </Button_02>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null} */}
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={extra ? extra : null}
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default FilterSetting;
