import React, { useState, useEffect } from 'react';
import './css/index.css';
import { Form, InputNumber, Row, Col, Select, DatePicker, Divider, Icon, Avatar, Input, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  loadmoreCustomer,
  loadmoreItemType,
  loadmoreDestination,
  loadmoreSource,
  durationTime,
  getStatus,
  getReferancetype,
  getSize,
} from '../../../controllers/orders-controller/api';
import _ from 'lodash';
import { getCustomerLocationAndConatact } from '../../../controllers/task/create-task';
import styled from 'styled-components';
import AddContact from '../../add-contact-v2/index';
import { successNotification, errorNotification } from '../../v2/notification';
import { addContact } from '../../../controllers/task/create-task';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

export default (props) => {
  const {
    form,
    setVisibleAddCustomer,
    newCustomer,
    setInitialCustomerList,
    initialCustomerList,
    initialItemTypeList,
    setInitialItemTypeList,
    initialDestinationList,
    setInitialDestinationList,
    initialSourceList,
    setInitialSourceList,
    contactData,
    setContactData,
    setStatusData,
    statusData,
    setVisibleAddFromLocation,
    newFromLocation,
    handleEditFromLocation,
    triggerEditLocation,
    updateEditFromLocation,
    setNewFromLocation,
    setUpdateEditFromLocation,
    setVisibleAddToLocation,
    setNewToLocation,
    newToLocation,
    handleEditToLocation,
    setUpdateEditToLocation,
    updateEditToLocation,
    setVisibleContact,
    visibleContact,
    triggerContact,
    setTriggerContact,
    setNewContact,
    newContact,
    temporaryData,
    setTemporaryData,
    statusSave,
    setStatusSave,
    visible,
    editData,
    customerSearch,
    setCustomerSearch,
    itemTypeSearch,
    setItemTypeSearch,
    destinationSearch,
    setDestinationSearch,
    sourceSearch,
    setSourceSearch,
    customerPage,
    setCustomerPage,
    customerSearchPage,
    setCustomerSearchPage,
    itemTypePage,
    setItemTypePage,
    itemTypeSearchPage,
    setItemTypeSearchPage,
    destinationPage,
    setDestinationPage,
    destinationSearchPage,
    setDestinationSearchPage,
    sourcePage,
    setSourcePage,
    sourceSearchPage,
    setSourceSearchPage,
    sizeList,
    setSizeList,
    referenceTypeList,
    setReferenceTypeList,
  } = props;

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();

  const [durationData, setDurationData] = useState([]);
  const [statusInfo, setStatusInfo] = useState();
  //--------------------------Customer -------------------------------
  const [initialCustomerListMaster, setInitialCustomerListMaster] = useState([]);
  const [initialCustomerLoading, setInitialCustomerLoading] = useState(false);
  const [isCustomerLoadMore, setIsCustomerLoadMore] = useState(true);
  const [isCustomerSearchLoadMore, setIsCustomerSearchLoadMore] = useState(true);
  const [customerPageSize, setCustomerPageSize] = useState(10);
  //--------------------------------- item type --------------------------------
  const [initialItemTypeListMaster, setInitialItemTypeListMaster] = useState([]);
  const [initialItemTypeLoading, setInitialItemTypeLoading] = useState(false);
  const [isItemTypeLoadMore, setIsItemTypeLoadMore] = useState(true);
  const [isItemTypeSearchLoadMore, setIsItemTypeSearchLoadMore] = useState(true);
  const [itemTypePageSize, setItemTypePageSize] = useState(10);
  //--------------------------------- Destination --------------------------------
  const [initialDestinationListMaster, setInitialDestinationListMaster] = useState([]);
  const [initialDestinationLoading, setInitialDestinationLoading] = useState(false);
  const [isDestinationLoadMore, setIsDestinationLoadMore] = useState(true);
  const [isDestinationSearchLoadMore, setIsDestinationSearchLoadMore] = useState(true);
  const [destinationPageSize, setDestinationPageSize] = useState(10);
  //--------------------------------- Source --------------------------------
  const [initialSourceListMaster, setInitialSourceListMaster] = useState([]);
  const [initialSourceLoading, setInitialSourceLoading] = useState(false);
  const [isSourceLoadMore, setIsSourceLoadMore] = useState(true);
  const [isSourceSearchLoadMore, setIsSourceSearchLoadMore] = useState(true);
  const [sourcePageSize, setSourcePageSize] = useState(10);

  console.log('editData', editData);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e', fontWeight: 'bold' }}>*</span> : ''}
      </span>
    );
  };

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    const splitValue = _.split(e.target.value, '.');
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 1) e.preventDefault();
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => {
          return o.charCodeAt(0) === 46 ? 1 : 0;
        });
        if (sumDot >= 1) e.preventDefault();
      }
    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }
    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 3 && keyCode !== 66 && keyCode !== 65 && e.target.selectionStart > e.target.value.indexOf('.'))
        e.preventDefault();
    }
  };

  //--------------------------------- Size --------------------------------

  useEffect(() => {
    const getApi = async () => {
      const response = await getReferancetype();
      setReferenceTypeList(_.get(response.data.data, 'list'));
    };
    getApi();
  }, []);

  const handleReferancetypeChange = (value, e) => {
    // console.log(value, e.props)
  };
  //--------------------------------- Size --------------------------------

  //--------------------------------- Size --------------------------------

  useEffect(() => {
    const getApi = async () => {
      const response = await getSize();
      setSizeList(_.get(response.data.data, 'list'));
    };
    getApi();
  }, []);

  const handleSizeChange = (value, e) => {
    // console.log(value, e.props)
    // // setSizeSelect(value)
  };
  //--------------------------------- Size --------------------------------

  //---------------------------------- Customer -----------------------------------------------

  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: customerPage * customerPageSize + 1,
        indexEnd: customerPage * customerPageSize + 10,
        customerName: customerSearch,
      };
      const result = await loadmoreCustomer(payload);
      const setAssetClassListData = _.map(_.get(result, 'customers'), (o) => {
        return o;
      });
      const defaultData = setAssetClassListData.concat(_.get(editData, 'customerDetail'));
      const customers = _.uniqBy(_.concat(initialCustomerList, defaultData), 'customerId');
      setInitialCustomerList(customers);
      setInitialCustomerListMaster(customers);
      setIsCustomerLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
      setInitialCustomerLoading(false);
    };

    if (isCustomerLoadMore && visible === true) {
      getData();
    }
  }, [customerPage, visible]);

  useEffect(() => {
    const getData = async () => {
      if (!customerSearch) {
        setInitialCustomerList(initialCustomerListMaster);
        setInitialCustomerLoading(false);
      } else {
        let payload = {
          indexStart: customerSearchPage * customerPageSize + 1,
          indexEnd: customerSearchPage * customerPageSize + 10,
          customerName: customerSearch,
        };
        const result = await loadmoreCustomer(payload);
        const setAssetClassListData = _.map(_.get(result, 'customers'), (o) => {
          return o;
        });
        const Customer = _.uniqBy(_.concat(initialCustomerList, setAssetClassListData), 'customerId');
        setInitialCustomerList(Customer);
        setIsCustomerSearchLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
        setInitialCustomerLoading(false);
      }
    };

    if (isCustomerSearchLoadMore) {
      getData();
    }
  }, [customerSearch, customerSearchPage]);

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (
      !initialCustomerLoading &&
      (_.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight ||
        _.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight - 1)
    ) {
      setInitialCustomerLoading(true);
      if (customerSearch) {
        setCustomerSearchPage(customerSearchPage + 1);
      } else {
        setCustomerPage(customerPage + 1);
      }
    }
  };

  const handleCustomerChangeSearch = (value) => {
    setInitialCustomerLoading(true);
    setIsCustomerSearchLoadMore(true);
    setInitialCustomerList([]);
    setCustomerSearchPage(0);
    setCustomerSearch(value);
  };

  const handleCustomerChange = () => {
    form.setFieldsValue({
      ['destination']: undefined,
    });
    form.setFieldsValue({
      ['source']: undefined,
    });
    form.setFieldsValue({
      ['contact']: undefined,
    });
    form.setFieldsValue({
      ['phone']: undefined,
    });
    setInitialDestinationList([]);
    setInitialSourceList([]);
    setCustomerSearch();
    setDestinationPage(0);
  };

  useEffect(() => {
    if (newCustomer) {
      const mapData = [
        {
          customerId: newCustomer.customerId,
          comtomerName: newCustomer.customerName,
          phone: newCustomer.phone,
          position: newCustomer.position,
        },
      ];
      const concatData = _.uniqBy(_.concat(mapData, initialCustomerList), 'customerId');
      setInitialCustomerList(concatData);
      setTimeout(() => {
        setFieldsValue({
          ['customer']: _.get(newCustomer, 'customerId'),
        });
        setFieldsValue({
          ['destination']: undefined,
        });
        setFieldsValue({
          ['source']: undefined,
        });
        setFieldsValue({
          ['contact']: undefined,
        });
        setFieldsValue({
          ['phone']: undefined,
        });
        setInitialDestinationList([]);
        setInitialSourceList([]);
      }, 1000);
    }
  }, [_.get(newCustomer, 'customerId')]);

  //---------------------------------- Customer -----------------------------------------------
  //---------------------------------- Item Type ----------------------------------------------
  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: itemTypePage * itemTypePageSize + 1,
        indexEnd: itemTypePage * itemTypePageSize + 10,
        item_type_name: itemTypeSearch,
      };
      const result = await loadmoreItemType(payload);
      const setAssetClassListData = _.map(_.get(result, 'data.itemTypeList'), (o) => {
        return o;
      });
      const defaultData = setAssetClassListData.concat(_.get(editData, 'itemTypeDetail'));
      const itemType = _.uniqBy(_.concat(initialItemTypeList, defaultData), 'item_type_id');
      setInitialItemTypeList(itemType);
      setInitialItemTypeListMaster(itemType);
      setIsItemTypeLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
      setInitialItemTypeLoading(false);
    };

    if (isItemTypeLoadMore && visible === true) {
      getData();
    }
  }, [itemTypePage, visible]);

  useEffect(() => {
    const getData = async () => {
      if (!itemTypeSearch) {
        setInitialItemTypeList(initialItemTypeListMaster);
        setInitialItemTypeLoading(false);
      } else {
        let payload = {
          indexStart: itemTypeSearchPage * itemTypePageSize + 1,
          indexEnd: itemTypeSearchPage * itemTypePageSize + 10,
          item_type_name: itemTypeSearch,
        };
        const result = await loadmoreItemType(payload);
        const setAssetClassListData = _.map(_.get(result, 'data.itemTypeList'), (o) => {
          return o;
        });
        const itemType = _.uniqBy(_.concat(initialItemTypeList, setAssetClassListData), 'item_type_id');
        setInitialItemTypeList(itemType);
        setIsItemTypeSearchLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
        setInitialItemTypeLoading(false);
      }
    };

    if (isItemTypeSearchLoadMore) {
      getData();
    }
  }, [itemTypeSearch, itemTypeSearchPage]);

  const handleItemTypePopupScroll = (e) => {
    let target = e.target;
    if (
      !initialItemTypeLoading &&
      (_.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight ||
        _.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight - 1)
    ) {
      setInitialItemTypeLoading(true);
      if (itemTypeSearch) {
        setItemTypeSearchPage(itemTypeSearchPage + 1);
      } else {
        setItemTypePage(itemTypePage + 1);
      }
    }
  };

  const handleItemTypeChangeSearch = (value) => {
    setInitialItemTypeLoading(true);
    setIsItemTypeSearchLoadMore(true);
    setInitialItemTypeList([]);
    setItemTypeSearchPage(0);
    setItemTypeSearch(value);
  };

  const onChangeItemType = () => {
    setItemTypeSearch();
  };

  //---------------------------------- Item Type ----------------------------------------------

  //---------------------------------- Destination ----------------------------------------------
  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: destinationPage * destinationPageSize + 1,
        indexEnd: destinationPage * destinationPageSize + 10,
        address_name: destinationSearch,
      };
      const result = await loadmoreDestination(payload, getFieldValue('customer'));
      const setAssetClassListData = _.map(_.get(result, 'data.addressList'), (o) => {
        return o;
      });
      const defaultData = setAssetClassListData.concat(_.get(editData, 'destinationDetail'));
      console.log("defaultData", setAssetClassListData, defaultData)
      const destination = _.uniqBy(_.concat(initialDestinationList, defaultData), 'customer_address_book_id');
      setInitialDestinationList(destination);
      setInitialDestinationListMaster(destination);
      setIsDestinationLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
      setInitialDestinationLoading(false);
    };

    const getDatNoCustomer = async () => {
      const defaultData = _.get(editData, 'destinationDetail')
      const destination = _.uniqBy(_.concat(initialDestinationList, defaultData), 'customer_address_book_id');
      console.log("loadmore2", destination)
      setInitialDestinationList(destination);
      setInitialDestinationListMaster(destination);
      setIsDestinationLoadMore(false);
      setInitialDestinationLoading(false);
    }

    if (isDestinationLoadMore && getFieldValue('customer')) {
      console.log("getDatNoCustomerApi")
      getData();
    } else {
      console.log("getDatNoCustomerTemp")
      getDatNoCustomer()
    }
  }, [destinationPage, getFieldValue('customer'), visible]);

  useEffect(() => {
    const getData = async () => {
      if (!destinationSearch) {
        setInitialDestinationList(initialDestinationListMaster);
        setInitialDestinationLoading(false);
      } else {
        let payload = {
          indexStart: destinationSearchPage * destinationPageSize + 1,
          indexEnd: destinationSearchPage * destinationPageSize + 10,
          address_name: destinationSearch,
        };
        const result = await loadmoreDestination(payload, getFieldValue('customer'));
        const setAssetClassListData = _.map(_.get(result, 'data.addressList'), (o) => {
          return o;
        });
        const destination = _.uniqBy(_.concat(initialDestinationList, setAssetClassListData), 'customer_address_book_id');
        setInitialDestinationList(destination);
        setIsDestinationSearchLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
        setInitialDestinationLoading(false);
      }
    };

    if (isDestinationSearchLoadMore && getFieldValue('customer')) {
      getData();
    }
  }, [destinationSearch, destinationSearchPage]);

  const handleDestinationPopupScroll = (e) => {
    let target = e.target;
    if (
      !initialDestinationLoading &&
      (_.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight ||
        _.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight - 1)
    ) {
      setInitialDestinationLoading(true);
      if (destinationSearch) {
        setDestinationSearchPage(destinationSearchPage + 1);
      } else {
        setDestinationPage(destinationPage + 1);
      }
    }
  };

  const handleDestinationChangeSearch = (value) => {
    setInitialDestinationLoading(true);
    setIsDestinationSearchLoadMore(true);
    setInitialDestinationList([]);
    setDestinationSearchPage(0);
    setDestinationSearch(value);
  };

  const handleDestinationChange = () => {
    form.setFieldsValue({
      ['source']: undefined,
    });
    setDestinationSearch();
  };

  useEffect(() => {
    if (_.get(newToLocation, 'customerAddressBookId')) {
      const mapData = [
        {
          address: _.get(newToLocation, 'fullAddress'),
          address_name: _.get(newToLocation, 'addressName'),
          customer_address_book_id: _.get(newToLocation, 'customerAddressBookId'),
          description: _.get(newToLocation, 'address'),
          lat: _.get(newToLocation, 'lat'),
          lng: _.get(newToLocation, 'lng'),
        },
      ];
      const source = _.uniqBy(_.concat(mapData, initialDestinationList), 'customer_address_book_id');
      setInitialDestinationList(source);
      setTimeout(() => {
        setFieldsValue({
          ['destination']: _.get(newToLocation, 'customerAddressBookId'),
        });
        setFieldsValue({
          ['source']: undefined,
        });
      }, 1000);
      setNewToLocation();
    }
  }, [_.get(newToLocation, 'customerAddressBookId')]);

  useEffect(() => {
    if (_.get(updateEditToLocation, 'customerAddressBookId')) {
      const mapData = [
        {
          address: _.get(updateEditToLocation, 'address'),
          address_name: _.get(updateEditToLocation, 'addressName'),
          customer_address_book_id: _.get(updateEditToLocation, 'customerAddressBookId'),
          description: _.get(updateEditToLocation, 'address'),
          lat: _.get(updateEditToLocation, 'lat'),
          lng: _.get(updateEditToLocation, 'lng'),
        },
      ];
      const source = _.uniqBy(_.concat(mapData, initialDestinationList), 'customerAddressBookId');
      setInitialDestinationList(source);
      setUpdateEditToLocation();
    }
  }, [_.get(updateEditToLocation, 'customerAddressBookId')]);

  //---------------------------------- Destination ----------------------------------------------
  //---------------------------------- Source ----------------------------------------------
  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: sourcePage * sourcePageSize + 1,
        indexEnd: sourcePage * sourcePageSize + 10,
        address_name: sourceSearch,
        customerAddressId: getFieldValue('destination'),
      };
      const result = await loadmoreSource(payload);
      const setAssetClassListData = _.map(_.get(result, 'data.addressList'), (o) => {
        return o;
      });
      const defaultData = setAssetClassListData.concat(_.get(editData, 'sourceDetail'));
      const source = _.uniqBy(_.concat(initialSourceList, defaultData), 'company_address_book_id');
      const filterDestination =
        source &&
        source.filter((item) => {
          return item.selectedFromDestination === true;
        });
      setInitialSourceList(source);
      setInitialSourceListMaster(source);
      setIsSourceLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
      setInitialSourceLoading(false);
      if (_.get(editData, 'sourceDetail.company_address_book_id') !== getFieldValue('source')) {
        setFieldsValue({
          ['source']: _.size(filterDestination) !== 0 ? _.get(filterDestination, '[0].company_address_book_id') : undefined,
        });
      }
    };

    if (isSourceLoadMore && getFieldValue('destination')) {
      getData();
    }
  }, [sourcePage, getFieldValue('destination'), triggerEditLocation, visible]);


  useEffect(() => {
    const getData = async () => {
      if (!sourceSearch) {
        setInitialSourceList(initialSourceListMaster);
        setInitialSourceLoading(false);
      } else {
        let payload = {
          indexStart: sourceSearchPage * sourcePageSize + 1,
          indexEnd: sourceSearchPage * sourcePageSize + 10,
          address_name: sourceSearch,
          customerAddressId: getFieldValue('destination'),
        };
        const result = await loadmoreSource(payload);
        const setAssetClassListData = _.map(_.get(result, 'data.addressList'), (o) => {
          return o;
        });
        const source = _.uniqBy(_.concat(initialSourceList, setAssetClassListData), 'company_address_book_id');
        setInitialSourceList(source);
        setIsSourceSearchLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
        setInitialSourceLoading(false);
      }
    };

    if (isSourceSearchLoadMore) {
      getData();
    }
  }, [sourceSearch, sourceSearchPage]);

  const handleSourcePopupScroll = (e) => {
    let target = e.target;
    if (
      !initialSourceLoading &&
      (_.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight ||
        _.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight - 1)
    ) {
      setInitialSourceLoading(true);
      if (sourceSearch) {
        setSourceSearchPage(sourceSearchPage + 1);
      } else {
        setSourcePage(sourcePage + 1);
      }
    }
  };

  const handleSourceChangeSearch = (value) => {
    setInitialSourceLoading(true);
    setIsSourceSearchLoadMore(true);
    setInitialSourceList([]);
    setSourceSearchPage(0);
    setSourceSearch(value);
  };

  const onChangeSource = () => {
    setSourceSearch();
  };

  useEffect(() => {
    if (_.get(newFromLocation, 'comAddressBookId')) {
      const mapData = [
        {
          address: _.get(newFromLocation, 'address'),
          address_name: _.get(newFromLocation, 'addressName'),
          company_address_book_id: _.get(newFromLocation, 'comAddressBookId'),
          description: _.get(newFromLocation, 'description'),
          lat: _.get(newFromLocation, 'lat'),
          lng: _.get(newFromLocation, 'lng'),
          selectedFromDestination: false,
        },
      ];
      const source = _.uniqBy(_.concat(mapData, initialSourceList), 'company_address_book_id');
      setInitialSourceList(source);
      setTimeout(() => {
        setFieldsValue({
          ['source']: _.get(newFromLocation, 'comAddressBookId'),
        });
      }, 1000);
      setNewFromLocation();
    }
  }, [_.get(newFromLocation, 'comAddressBookId')]);

  useEffect(() => {
    if (_.get(updateEditFromLocation, 'comAddressBookId')) {
      const mapData = [
        {
          address: _.get(updateEditFromLocation, 'address'),
          address_name: _.get(updateEditFromLocation, 'addressName'),
          company_address_book_id: _.get(updateEditFromLocation, 'comAddressBookId'),
          description: _.get(updateEditFromLocation, 'description'),
          lat: _.get(updateEditFromLocation, 'lat'),
          lng: _.get(updateEditFromLocation, 'lng'),
          selectedFromDestination: false,
        },
      ];
      const source = _.uniqBy(_.concat(mapData, initialSourceList), 'company_address_book_id');
      setInitialSourceList(source);
      setUpdateEditFromLocation();
    }
  }, [_.get(updateEditFromLocation, 'comAddressBookId')]);

  //---------------------------------- Source ----------------------------------------------
  // ---------------------------------- Time ----------------------------------------------
  useEffect(() => {
    const getApi = async () => {
      const response = await durationTime();
      setDurationData(_.get(response, 'data.times'));
    };
    getApi();
  }, []);

  // ---------------------------------- Time ----------------------------------------------
  // ---------------------------------- Contact ----------------------------------------------
  useEffect(() => {
    const getApi = async () => {
      if (getFieldValue('customer')) {
        const response = await getCustomerLocationAndConatact(getFieldValue('customer'), 'transportation');
        const mapData = [
          {
            customerContactName: _.get(editData, 'contactDetail.customerContactName'),
            customerContactPhone: _.get(editData, 'contactDetail.customerContactPhone'),
            position: _.get(editData, 'contactDetail.position'),
            customerContactId: _.get(editData, 'contactDetail.customerContactId')
              ? _.get(editData, 'contactDetail.customerContactId')
              : 'temporary',
          },
        ];
        const dataDefault = mapData.concat(_.get(response, 'data.data.contactCustomer'));
        setContactData(_.get(editData, 'contactDetail.customerContactId') ? _.get(response, 'data.data.contactCustomer') : dataDefault);
      }
    };
    getApi();
  }, [getFieldValue('customer'), triggerContact]);

  useEffect(() => {
    const contactId = getFieldValue('contact');
    if (contactId) {
      // console.log('contactData ::', contactData)
      const filterData =
        contactData &&
        contactData.filter((item) => {
          return item.customerContactId === contactId;
        });
      setStatusSave(_.get(filterData, '[0].customerContactId'));
      form.setFieldsValue({
        ['phone']: _.get(filterData, '[0].customerContactPhone'),
      });
    }
  }, [getFieldValue('contact'), _.size(temporaryData), _.size(contactData)]);

  useEffect(() => {
    if (newContact) {
      setTimeout(() => {
        setFieldsValue({
          ['contact']: newContact,
        });
      }, 1000);
      setNewContact();
    }
  }, [newContact]);

  useEffect(() => {
    if (_.size(temporaryData) !== 0) {
      const filterData =
        contactData &&
        contactData.filter((item) => {
          return item.customerContactId !== 'temporary';
        });
      const concatArry = temporaryData && temporaryData.concat(filterData);
      setContactData(concatArry);
      setTemporaryData([]);
    }
  }, [_.size(temporaryData)]);

  // ---------------------------------- Contact ----------------------------------------------
  // ---------------------------------- status ----------------------------------------------

  useEffect(() => {
    const getApi = async () => {
      const orderId = _.get(editData, 'orderId') || '';
      const response = await getStatus(orderId);
      setStatusData(_.get(response, 'data.statusList'));
    };
    if (visible === true) {
      getApi();
    }
  }, [visible]);

  useEffect(() => {
    const status = getFieldValue('status');
    // console.log('status ::', status)
    if (status) {
      const filterData =
        statusData &&
        statusData.filter((item) => {
          return item.statusId === status;
        });
      setStatusInfo(_.get(filterData, '[0]'));
    }
  }, [getFieldValue('status')]);

  // console.log('statusData ::', _.get(statusData, '[0].statusId'))

  const SelectStatus = styled(Select)`
    width: '100%';
    .ant-select-selection--single {
      border-radius: 20px !important;
      height: 24px;
      color: #fff;
      width: '100%';
      font-size: 13px;
      background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : _.get(editData, 'statusDetail.statusColor'))};
      border: 1px solid ${(props) => (props.borderColor ? props.borderColor : _.get(editData, 'statusDetail.statusColor'))};
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .ant-select-selection-selected-value {
      margin-right: 5px;
      margin-top: 0px;
    }
    .ant-select-arrow .ant-select-arrow-icon svg {
      fill: #fff;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover,
    .ant-select-open .ant-select-selection {
      border-color: ${(props) => (props.backgroundColor ? props.backgroundColor : _.get(editData, 'statusDetail.statusColor'))} !important;
      box-shadow: none !important;
    }
  `;

  const handleSaveContactTemporary = () => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmCustomerContact`,
        defaultMessage: 'Are you sure to save customer contact.',
      }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const filterData =
          contactData &&
          contactData.filter((item) => {
            return item.customerContactId === 'temporary';
          });
        const body = {
          customerId: getFieldValue('customer'),
          customerContactName: _.get(filterData, '[0].customerContactName'),
          customerContactEmail: _.get(filterData, '[0].customerContactEmail'),
          customerContactPhone: _.get(filterData, '[0].customerContactPhone'),
          position: _.get(filterData, '[0].position'),
          remark: _.get(filterData, '[0].remark'),
        };
        const response = await addContact(body);
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setTriggerContact((current) => !current);
          setNewContact(response.data.data.customerContactId);
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  return (
    <div style={{ padding: '16px 24px' }}>
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={19}>
            <Form.Item
              className="order-modal-form"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextCustomer', defaultMessage: 'Customer' })} req={true} />}
            >
              {getFieldDecorator('customer', {
                initialValue: editData ? _.get(editData, 'customerDetail.customerId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateCustomer', defaultMessage: 'Please select customer' }),
                  },
                ],
              })(
                <Select
                  disabled={!_.get(editData, 'canEditInfomation')}
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderCustomer', defaultMessage: 'Select Customer' })}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  showSearch
                  loading={initialCustomerLoading}
                  onPopupScroll={handleCustomerPopupScroll}
                  onSearch={handleCustomerChangeSearch}
                  onChange={handleCustomerChange}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setVisibleAddCustomer(true);
                        }}
                      >
                        <Icon type="plus" className="order-icon-plus" />
                        <span className="order-add-customer">
                          <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                          <FormattedMessage id="modalOrderTextCustomer" defaultMessage="Customer" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {initialCustomerList && initialCustomerList.map((item) => <Option value={item.customerId}>{item.comtomerName}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              className="order-modal-form"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextStatus', defaultMessage: 'Status' })} req={true} />}
            >
              {getFieldDecorator('status', {
                initialValue: editData ? _.get(editData, 'statusDetail.statusId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateStatus', defaultMessage: 'Please select status' }),
                  },
                ],
              })(
                <SelectStatus
                  dropdownStyle={{ minWidth: '200px' }}
                  size="small"
                  backgroundColor={_.get(statusInfo, 'statusColor')}
                  borderColor={_.get(statusInfo, 'statusColor')}
                  disabled={!_.get(editData, 'canEditStatus')}
                >
                  {statusData && statusData.map((item) => <Option value={item.statusId}>{item.statusName}</Option>)}
                </SelectStatus>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextSize', defaultMessage: 'Size' })} req={true} />}
            >
              {getFieldDecorator('size', {
                initialValue: editData ? _.get(editData, 'size.sizeId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateSize', defaultMessage: 'Please select Size' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderSize', defaultMessage: 'Select Size' })}
                  defaultActiveFirstOption={false}
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  onChange={handleSizeChange}
                  disabled={!_.get(editData, 'canEditInfomation')}
                >
                  {sizeList &&
                    sizeList.map((item) => (
                      <Option key={item.sizeId} value={item.sizeId}>
                        {item.sizeName}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextWeight', defaultMessage: 'Weight (kg)' })} req={true} />}
            >
              {getFieldDecorator('weight', {
                initialValue: editData ? _.get(editData, 'weight') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateWeight', defaultMessage: 'Please enter Weight' }),
                  },
                ],
              })(
                <InputNumber
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderWeight', defaultMessage: 'Enter Weight' })}
                  className="order-item-inputNumber"
                  maxLength={11}
                  min={0}
                  max={9999999999}
                  style={{ width: '100%' }}
                  onKeyDown={handleKeyDownNumber}
                  disabled={!_.get(editData, 'canEditInfomation')}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {console.log('editData', editData)}
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextSource', defaultMessage: 'Source' })} req={true} />}
            >
              {getFieldDecorator('source', {
                initialValue: editData ? _.get(editData, 'sourceDetail.company_address_book_id') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateSource', defaultMessage: 'Please select source' }),
                  },
                ],
              })(
                <Select
                  disabled={getFieldValue('destination') ? false : true}
                  // disabled={!_.get(editData, 'canEditInfomation')}
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderSource', defaultMessage: 'Select Source' })}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  showSearch
                  loading={initialSourceLoading}
                  onPopupScroll={handleSourcePopupScroll}
                  onSearch={handleSourceChangeSearch}
                  onChange={onChangeSource}
                  optionLabelProp="label"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setVisibleAddFromLocation(true);
                        }}
                      >
                        <Icon type="plus" className="order-icon-plus" />
                        <span className="order-add-customer">
                          <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                          <FormattedMessage id="modalOrderTextSource" defaultMessage="Source" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {initialSourceList &&
                    initialSourceList.map((item) => (
                      <Option value={item.company_address_book_id} label={`${item.address_name} · ${item.address}`}>
                        <Row>
                          <Col span={20} className="order-text-select">
                            <span>
                              {item.address_name} · {item.address}
                            </span>
                          </Col>
                          <Col span={4}>
                            <span onClick={() => handleEditFromLocation(item)}>
                              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                            </span>
                          </Col>
                        </Row>
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalOrderTextDestination', defaultMessage: 'Destination' })} req={true} />
              }
            >
              {getFieldDecorator('destination', {
                initialValue: editData ? _.get(editData, 'destinationDetail.customer_address_book_id') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateDestination', defaultMessage: 'Please select destination' }),
                  },
                ],
              })(
                <Select
                  disabled={getFieldValue('customer') ? false : true}
                  // disabled={!_.get(editData, 'canEditInfomation')}
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderDestination', defaultMessage: 'Select Destination' })}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  showSearch
                  loading={initialDestinationLoading}
                  onPopupScroll={handleDestinationPopupScroll}
                  onSearch={handleDestinationChangeSearch}
                  onChange={handleDestinationChange}
                  optionLabelProp="label"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setVisibleAddToLocation(true);
                        }}
                      >
                        <Icon type="plus" className="order-icon-plus" />
                        <span className="order-add-customer">
                          <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                          <FormattedMessage id="modalOrderTextDestination" defaultMessage="Destination" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {initialDestinationList &&
                    initialDestinationList.map((item) => (
                      <Option value={item.customer_address_book_id} label={`${item.address_name} · ${item.address}`}>
                        <Row>
                          <Col span={20} className="order-text-select">
                            <span>
                              {item.address_name} · {item.address}
                            </span>
                          </Col>
                          <Col span={4}>
                            <span onClick={() => handleEditToLocation(item)}>
                              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                            </span>
                          </Col>
                        </Row>
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalOrderTextDeliveryDate', defaultMessage: 'Delivery Date' })} req={true} />
              }
            >
              {getFieldDecorator('deliveryDate', {
                initialValue: editData ? moment(_.get(editData, 'deliveryDate'), 'YYYY-MM-DD') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateDeliveryDate', defaultMessage: 'Please select delivery date' }),
                  },
                ],
              })(
                <DatePicker
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderDeliveryDate', defaultMessage: 'Select Delivery Date' })}
                  style={{ width: '100%' }}
                  format="ddd, MMM DD, YYYY"
                  disabled={!_.get(editData, 'canEditInfomation')}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalOrderTextDeliveryTime', defaultMessage: 'Delivery Time' })} req={true} />
              }
            >
              {getFieldDecorator('deliveryTime', {
                initialValue: editData ? _.get(editData, 'deliveryTime') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateDeliveryTime', defaultMessage: 'Please select delivery time' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderDeliveryTime', defaultMessage: 'Select Delivery Time' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!_.get(editData, 'canEditInfomation')}
                >
                  {durationData && durationData.map((item) => <Option value={item.val}>{item.txt}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextContact', defaultMessage: 'Contact' })} req={true} />}
            >
              {getFieldDecorator('contact', {
                initialValue: editData
                  ? _.get(editData, 'contactDetail.customerContactId')
                    ? _.get(editData, 'contactDetail.customerContactId')
                    : 'temporary'
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalOrderValidateContact', defaultMessage: 'Please select contact' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderContact', defaultMessage: 'Select Contact' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={getFieldValue('customer') ? false : true}
                  // disabled={!_.get(editData, 'canEditInfomation')}
                  optionLabelProp="label"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setVisibleContact(true);
                        }}
                      >
                        <Icon type="plus" className="order-icon-plus" />
                        <span className="order-add-customer">
                          <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                          <FormattedMessage id="customerContactCardHeader" defaultMessage="Contact" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {contactData &&
                    contactData.map((item) => (
                      <Option key={item.customerContactId} label={item.customerContactName}>
                        <Row>
                          <Col span={6}>
                            <Avatar size="large" icon="user" />
                          </Col>
                          <Col span={18}>
                            <div style={{ fontSize: '13px', color: '#1D3557' }}>{item.customerContactName}</div>
                            <div style={{ fontSize: '11px', color: '#1D3557' }}>
                              {item.position} · {item.customerContactPhone}
                            </div>
                          </Col>
                        </Row>
                      </Option>
                    ))}
                </Select>
              )}
              {statusSave === 'temporary' ? (
                <div className="create-task-status-save" onClick={handleSaveContactTemporary}>
                  <FormattedMessage id="monitorTabDetailSaveContactCustomer" defaultMessage="Save to Customer Contact" />
                </div>
              ) : null}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextPhone', defaultMessage: 'Phone' })} req={false} />}
            >
              {getFieldDecorator('phone', {
                initialValue: editData ? _.get(editData, 'contactDetail.customerContactPhone') : undefined,
              })(
                <Input
                  disabled={getFieldValue('contact') ? false : true}
                  // disabled={!_.get(editData, 'canEditInfomation')}
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderPhone', defaultMessage: 'Enter Phone' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={
                <LabeRequire
                  text={intl.formatMessage({ id: 'modalOrderTextReferenceType', defaultMessage: 'Reference Type' })}
                  req={false}
                />
              }
            >
              {getFieldDecorator('referenceType', {
                initialValue: editData ? _.get(editData, 'referenceType.refTypeId') : undefined,
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'modalOrderValidateReferenceType', defaultMessage: 'Please select Reference Type' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderReferenceType', defaultMessage: 'Select Reference Type' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!_.get(editData, 'canEditInfomation')}
                >
                  {referenceTypeList &&
                    referenceTypeList.map((item) => (
                      <Option key={item.refTypeId} value={item.refTypeId}>
                        {item.refTypeName}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="order-modal-form"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'modalOrderTextRefernceNo', defaultMessage: 'Refernce No.' })} req={false} />
              }
            >
              {getFieldDecorator('refernceNo', {
                initialValue: editData ? _.get(editData, 'referenceNo') : undefined,
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'modalOrderValidateRefernceNo', defaultMessage: 'Please select Refernce No.' }),
                  },
                ],
              })(
                <Input
                  disabled={!_.get(editData, 'canEditInfomation')}
                  placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderRefernceNo', defaultMessage: 'Enter Refernce No' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              className="order-modal-form"
              label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderTextRemark', defaultMessage: 'Remark' })} req={false} />}
            >
              {getFieldDecorator('remark', {
                initialValue: editData ? _.get(editData, 'remark') : undefined,
              })(<TextArea disabled={!_.get(editData, 'canEditInfomation')} autosize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <AddContact
        visible={visibleContact}
        setVisible={setVisibleContact}
        customerId={getFieldValue(`customer`)}
        setTriggerAPI={setTriggerContact}
        setContactID={setNewContact}
        setTemporaryData={setTemporaryData}
      />
    </div>
  );
};
