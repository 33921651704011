import React from 'react';
import { Form, Icon, Input, Modal, Select, Upload } from 'antd';
import { FormattedMessage } from 'react-intl';
import LabeRequireForm from '../label-required-form';
import Button_02 from '../v2/button_02';
import Button_01 from '../v2/button_01';
import _ from 'lodash';
import './index.css'
const { Option } = Select;
const parcelModal = ({ form, title, visible, setVisible, trigger, setTrigger, subTaskWfId, intl, fileList, setFileList }) => {
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const status = getFieldValue('status');
  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        setVisible(false);
        setTrigger((prev) => !prev);
        resetFields();
      } catch (error) {}
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
  };

  const onChangeUpload = () => {};

  const handlePreview = () => {};

  const handleRemoveImg = () => {};

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      width={450}
      bodyStyle={{ padding: '12px 24px 24px 24px' }}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form>
        <Form.Item>
          <span className="text-style">
            <FormattedMessage id="paecelFormTextStatus" defaultMessage="Status" />
            <LabeRequireForm req={true} />
          </span>
          {getFieldDecorator('status', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'paecelFormTextValidateStatus', defaultMessage: 'Please Select Status' }),
              },
            ],
          })(
            <Select
              placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderStatus', defaultMessage: 'Select Status' })}
              autoComplete="off"
            >
              <Option value={'1'}>Status 1</Option>
              <Option value={'2'}>Status 2</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <span className="text-style">
            <FormattedMessage id="paecelFormTextReason" defaultMessage="Reason" />
            <LabeRequireForm req={true} />
          </span>
          {getFieldDecorator('reason', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'paecelFormTextValidateReason', defaultMessage: 'Please Select Reason' }),
              },
            ],
          })(
            <Select
              placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderReason', defaultMessage: 'Select Reason' })}
              autoComplete="off"
              disabled={status ? false : true}
            >
              <Option value={'1'}>Reason 1</Option>
              <Option value={'2'}>Reason 2</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <span className="text-style">
            <FormattedMessage id="paecelFormTextPhoto" defaultMessage="Photo" />
          </span>
          {getFieldDecorator(
            'photo',
            {}
          )(
            <Upload
              className="upload-image-parcel-display"
              style={{ marginTop: '8px' }}
              onChange={onChangeUpload}
              listType="picture-card"
              fileList={fileList}
              onPreview={(file) => handlePreview(file)}
              onRemove={() => handleRemoveImg()}
              accept=".pdf, .jpg, .png"
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
              beforeUpload={() => {
                return false;
              }}
            >
              {_.size(fileList) === 0 ? uploadButton : null}
            </Upload>
          )}
        </Form.Item>

        <Form.Item>
          <span className="text-style">
            <FormattedMessage id="paecelFormTextRemark" defaultMessage="Remark" />
          </span>
          {getFieldDecorator(
            'remark',
            {}
          )(
            <Input
              placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderRemark', defaultMessage: 'Enter Remark' })}
              autoComplete="off"
            ></Input>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const ParcelModal = Form.create({
  name: 'modal_parcel_form',
  mapPropsToFields(props) {},
})(parcelModal);

export default ParcelModal;
