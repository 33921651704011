import React from 'react';
import { Tag, Avatar } from 'antd';
import _ from 'lodash';
import './css/index.css';
import { FormattedMessage } from 'react-intl';

export default ({ data }) => {

  const statusCode = _.get(data, 'statusCode');
  const updatebyName = _.get(data, 'updatebyName') ? _.get(data, 'updatebyName') : '';
  const comment = _.get(data, 'comment') ? _.get(data, 'comment') : '';
  const reason = _.get(data, 'reason') ? _.get(data, 'reason') : '';
  const updateDate = _.get(data, 'updateDate') ? _.get(data, 'updateDate') : '';
  const updatebyProfileImg = _.get(data, 'updatebyProfileImg');

  return (
    <div className="view-header">
      {statusCode === 'approve' || statusCode === 'reject' ?
        <div className="view-card-head">
          <div className="modal-view-card-head-left">
            <div className="modal-view-card-head-left-avatar">
              <Avatar size={52} src={updatebyProfileImg} />
            </div>
            <div className="modal-view-card-left-text">
              <div className='modal-view-card-left-text-p'>
                <p className="modal-view-card-left-text-head">
                  {statusCode === 'approve' ? 'Approved' : 'Reject'} <FormattedMessage id="ModalViewQuotationCommentBy" defaultMessage="By" /> {updatebyName}
                  {statusCode === 'approve' ?
                    <p>{comment}</p>
                    : <p>{reason}, {comment}</p>}
                </p>
                <p className="modal-view-card-left-text-dot">.</p>
                <p className="modal-view-card-text-12">
                  <FormattedMessage id="ModalViewQuotationCommentOn" defaultMessage="On" /> {updateDate}
                </p>
              </div>
            </div>
          </div>
          <div className="modal-view-card-head-right">
          </div>
        </div>
        : null
      }

    </div>
  )
}