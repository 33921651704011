import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import styles from './css/index.css';
import { useIntl, FormattedMessage } from 'react-intl';
import { ServiceAreaContext } from './index';
import _, { initial } from 'lodash';
import styled from 'styled-components';

const ComponentForm = (props) => {
  const { form, areaData } = props;
  const intl = useIntl();
  const { getFieldDecorator, validateFields } = form;

  const [values, setValues] = useState("");

  useEffect(() => {
    let areaName = form.getFieldsValue(["areaName"]).areaName;
    if (areaName) {
      setValues(areaName);
    } else {
      setValues("");
    }
  }, [form.getFieldsValue(["areaName"])]);

  const onBlur = () => {
      if (values) {
        props.mainSetState.setAreaName(values);
      } else {
        props.mainSetState.setAreaName("");
      }
  }

  const LabelRequire = styled.label`
    color: #ff1010;
  `;

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };
  
   return (
     <div className="service-area-form">
       <Form>
        <Form.Item
          className="service-area-form-item"
          label={
            <LabeRequire 
              text={intl.formatMessage({ id: 'serviseAreaLblServicesAreaName', defaultMessage: 'Services Area Name'})}
              req={true} 
            />
          }
        >
          {getFieldDecorator("areaName", { 
            initialValue: areaData.areaName ? areaData.areaName : undefined,
            rules: [{ 
              required: true, 
              message: intl.formatMessage({ id: 'serviseAreaValidateServicesAreaName', defaultMessage: 'Please enter areaName.'})
            }],
          })(
            <Input 
              className='services-area-form-input' 
              placeholder={intl.formatMessage({ id: 'serviseAreaHinServicesAreaName', defaultMessage: 'Enter Services Area Name'})}
              onBlur={onBlur}
              autoComplete="off"
            />
          )}
          </Form.Item>
       </Form>
     </div>
   )
}

const ServiceAreaForm = Form.create({ 
  name: 'service_area_form',
})(ComponentForm);

export default ServiceAreaForm;