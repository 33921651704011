import React from 'react';
import { Tag } from 'antd';
import _ from 'lodash';

const RenderTag = ({ fileList, handleTags, handleRemove,type }) => {
  const splitText = (val) => {
    let lastDotIndex = val.lastIndexOf('.');
    let everythingAfterTheFinalDot = val.substring(lastDotIndex + 1);
    return everythingAfterTheFinalDot;
  };

  return type === 'edit' ? (
    <Tag
      className="post-tags"
      key={fileList.filehash}
      onClick={() => handleTags(fileList)}
      onClose={() => handleRemove(fileList.attachmentId, fileList)}
      closable
    >
      <span title={fileList.descriptionPhoto ? fileList.descriptionPhoto : 'file'}>
        {_.size(fileList.descriptionPhoto) > 30
          ? fileList.descriptionPhoto.substring(0, 30) + '...' + splitText(fileList.descriptionPhoto)
          : fileList.descriptionPhoto
          ? fileList.descriptionPhoto
          : 'file'}
      </span>
    </Tag>
  ) : (
    <Tag
      className="post-tags"
      key={fileList.attachmentId}
      onClick={() => handleTags(fileList)}
      onClose={() => handleRemove(fileList.attachmentId, fileList)}
      closable
    >
      <span title={fileList.name}>
        {_.get(fileList, 'name.length') > 30 ? fileList.name.substring(0, 30) + '...' + splitText(fileList.name) : fileList.name}
      </span>
    </Tag>
  );
};

export { RenderTag };
