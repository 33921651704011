import React, { useContext } from 'react'
import './css/index.css'
import { Card, Form, Row, Col, Select, DatePicker, Input } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form/index'
import _ from 'lodash'
import { TravelExpenseContext } from './context'

const { Option } = Select

const FilterTravelExpense = (props) => {
  const { state, fnc } = useContext(TravelExpenseContext)
  const intl = useIntl()
  return (
    <Card
      title={intl.formatMessage({ id: 'travelExpenseFilterText', defaultMessage: 'Filter' })}
      bodyStyle={{ minHeight: 'calc(100vh - 170px)', padding: '8px 16px' }}
    >
      <Form colon={false} className='travelExpenseForm'>
        <Row gutter={[16]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'travelExpenseFormTeamText', defaultMessage: 'Team' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                value={(_.get(state, 'searchGroup.team'))}
                onChange={(value) => fnc.onChange(value, 'team')}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={intl.formatMessage({ id: 'travelExpenseFormTeamPlaceholder', defaultMessage: 'Select Team' })}
              >
                {state.teamData &&
                  state.teamData.map((item) => (
                    <Option key={item.org_id} value={item.org_id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'travelExpenseFormEmployeeText', defaultMessage: 'Employee' })}
                  req={false}
                />
              }
            >
              <Select
                disabled={_.get(state, 'searchGroup.team') ? false : true}
                value={(_.get(state, 'searchGroup.employee'))}
                maxTagCount={1}
                mode='multiple'
                onChange={(value) => fnc.onChange(value, 'employee')}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={intl.formatMessage({ id: 'travelExpenseFormEmployeePlaceholder', defaultMessage: 'Select Employee' })}
              >
                {state.employeeData &&
                  state.employeeData.map((item) => (
                    <Option key={item.mem_com_id} value={item.mem_com_id}>
                      {item.fullname}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'travelExpenseFormTripOrTaskText', defaultMessage: 'Trip or Task No.' })}
                  req={false}
                />
              }
            >
              <Input
                onChange={(value) => fnc.onChange(value.target.value, 'tripOrTask')}
                size='default'
                placeholder={intl.formatMessage({ id: 'travelExpenseFormTripOrTaskPlaceholder', defaultMessage: 'Select Trip or Task No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'travelExpenseFormStartDateText', defaultMessage: 'Start Date' })}
                  req={false}
                />
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                onChange={(value) => fnc.onChange(value, 'startDate')}
                placeholder={intl.formatMessage({ id: 'travelExpenseFormStartDatePlaceholder', defaultMessage: 'Select Period' })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'travelExpenseFormEndDateText', defaultMessage: 'End Date' })}
                  req={false}
                />
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                onChange={(value) => fnc.onChange(value, 'endDate')}
                placeholder={intl.formatMessage({ id: 'travelExpenseFormEndDatePlaceholder', defaultMessage: 'Select Period' })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default FilterTravelExpense
