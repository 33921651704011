import React from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import LabeRequireForm from '../../../../components/label-required-form';
import _ from 'lodash';

const { Option } = Select;
const { TextArea } = Input;

const CancelModal = ({ form, title, visible, onOk, onCancel, initialReasonList,}) => {
  const { getFieldDecorator, resetFields } = form;
  const intl = useIntl();

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('values', values);
      if (err) {
        return;
      }

      const payload = {
        reasonId: values.reasonId,
        remark: values.resonRemark || '',
      };

      console.log('payload', payload);

      onOk(payload);
      resetFields();
    });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };

  const setReasonOption = (reason) => {
    return (
      <Option key={`${reason.reasonId}`} value={`${reason.reasonId}`}>
        {reason.reasonTxt}
      </Option>
    );
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={onCancel}
      width={500}
      centered={true}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="status" colon={false}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelReason', defaultMessage: 'Reason' })} req={true} />}
            >
              {getFieldDecorator('reasonId', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationWarnReason', defaultMessage: 'please select Reason' }),
                  },
                ],
              })(
                <Select
                  className="select-list-customer-monitor"
                  placeholder={intl.formatMessage({ id: 'quotationHintReason', defaultMessage: 'Select Reason' })}
                  defaultActiveFirstOption={false}
                  allowClear={false}
                >
                  {initialReasonList && initialReasonList.map((reason) => setReasonOption(reason))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelRemark', defaultMessage: 'Remark' })} req={false} />}
            >
              {getFieldDecorator('resonRemark', {})(<TextArea autoSize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

CancelModal.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCancelVoucher = Form.create({ name: 'cancel_Form' })(CancelModal);

export default ModalCancelVoucher;
