import React from 'react'
import CollapseCustom from '../../../../components/collapse-custom/index'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, DatePicker, Select, Col, Row } from 'antd'
import _ from 'lodash'
import LabeRequireForm from '../../../../components/label-required-form'

const { Option } = Select

const ViewAction = (props) => {
  const { searchGroup, setSearchGroup, actionData } = props
  const intl = useIntl()

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const formFilter = () => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'menuCampaignPointViewFromDateText', defaultMessage: 'From Date' })} req={false} />
              }
            >
              <DatePicker
                allowClear
                value={_.get(searchGroup, 'fromDate')}
                onChange={(value) => onChange(value, 'fromDate')}
                placeholder={intl.formatMessage({ id: 'menuCampaignPointViewFromDatePlaceholder', defaultMessage: 'Select From Date' })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'menuCampaignPointViewToDateText', defaultMessage: 'To Date' })} req={false} />
              }
            >
              <DatePicker
                allowClear
                value={_.get(searchGroup, 'toDate')}
                onChange={(value) => onChange(value, 'toDate')}
                placeholder={intl.formatMessage({ id: 'menuCampaignPointViewToDatePlaceholder', defaultMessage: 'Select To Date' })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'menuCampaignPointViewActionText', defaultMessage: 'Action' })} req={false} />
              }
            >
              <Select
                allowClear
                value={_.get(searchGroup, 'action')}
                onChange={(value) => onChange(value, 'action')}
                placeholder={intl.formatMessage({ id: 'menuCampaignPointViewActionPlaceholder', defaultMessage: 'Select Action' })}
                style={{ width: '100%' }}
              >
                {
                  _.map(_.get(actionData, '[0].dataSub'), (item) =>
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form >
    );
  };

  return (
    <div>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  )
}

export default ViewAction
