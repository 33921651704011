import React, { useEffect } from 'react'
import { Row, Col, Form, Input } from 'antd'
import LabeRequire from './v2/label-require'
import { useIntl } from 'react-intl'
import _ from 'lodash'

const FormAddress = (props) => {
  const { form, address } = props
  const { getFieldDecorator } = form;
  const intl = useIntl()

  return (
    <Form form={form}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Form.Item
            label={
              <LabeRequire text={intl.formatMessage({ id: 'FormAddressLocationName', defaultMessage: 'Location Name' })} req={true} />
            }
            className="form-customer"
            colon={false}
          >
            {getFieldDecorator(
              'locationName', {
              initialValue: _.get(address, 'name', ''),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'FormAddressValidateLocationName', defaultMessage: 'Please enter location name' }),
                },
              ],
            }
            )(<Input
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({ id: 'FormAddressPlaceholderLocationName', defaultMessage: 'Enter location name' })}
            />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Form.Item
            label={
              <LabeRequire text={intl.formatMessage({ id: 'FormAddressAddress', defaultMessage: 'Address' })} req={true} />
            }
            className="form-customer"
            colon={false}
          >
            {getFieldDecorator(
              'address', {
              initialValue: _.get(address, 'address', ''),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'FormAddressValidateAddressName', defaultMessage: 'Please enter address name' }),
                },
              ],
            }
            )(<Input
              style={{ width: '100%' }}
              // readOnly={true}
              placeholder={intl.formatMessage({ id: 'FormAddressPlaceholderAddress', defaultMessage: 'Enter address' })}
            />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default FormAddress