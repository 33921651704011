import React, { useContext } from 'react';
import _ from 'lodash';
import { Avatar } from 'antd';
import {
  TimelineMonth,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import { Internationalization } from '@syncfusion/ej2-base';
import { useIntl } from 'react-intl';
import { PageOrganizationTabShift } from '../../../config/page-settings';

const instance = new Internationalization();

export default () => {
  const appShiftV2 = useContext(PageOrganizationTabShift);
  const intl = useIntl();

  const customResourceHeaderTemplate = (props) => {
    const checkResourceMockData = props.resourceData.Id.toString().indexOf('mockdata');
    const pathImage = getPathImage(props);
    return (
      checkResourceMockData ? 
        <div className='shift-template-wrap' title={getName(props)}>
          <Avatar size={32} className='avatar-border' src={pathImage} />
          <span className="shift-scheduler-column-name">{_.truncate(getName(props), {'length': 20})}</span>
        </div> : <div></div>
    );
  };

  const customRenderCell = (args) => {
    if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
      let sumResource = _.size(appShiftV2.scheduleObj.current.resources[0].dataSource);
      let target = args.element.querySelector('.e-resource-text');
      target.innerHTML = `<div style="padding: 5px 15px;"><b> ${intl.formatMessage({ id: 'orgShiftPlanningLblResources', defaultMessage: 'Resources' })} (` + sumResource + ')</b></div>';
    }
  };

  const dateHeaderTemplate = (props) => {
    return (
      <div style={{ height: 50, padding: 5 }}>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'E')}</div>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'd')}</div>
      </div>
    );
  }

  const onEventRendered = (args) => {
    let height = 38;
    let left = parseInt(args.element.style.left) + 6;
    args.element.style.width = '126px';
    args.element.style.height = height + 'px';
    args.element.style.color = '#ffffff';
    args.element.style.backgroundColor = args.data.Color;
    args.element.style.margin = '0px';
    args.element.style.borderRadius = '3px';
    args.element.style.left = left + 'px';
    args.element.querySelector('.e-inner-wrap').style.padding = '0px 12px';
    args.element.querySelector('.e-inner-wrap').style.fontSize = '12px';
    args.element.querySelector('.e-time').style.fontSize = '8px';
    args.element.querySelector('.e-time').innerHTML = args.data.TeamName + ' ' + args.data.Start + ' - ' + args.data.Finish;
  }

  const getPathImage = (value) => {
    return value.resourceData.pathImage;
  };

  const getName = (value) => {
    return value.resourceData[value.resource.textField];
  };

  const getDateHeaderText = (value, skeleton) => {
    return instance.formatDate(value, { skeleton: skeleton });
  }

  return (
    <PageOrganizationTabShift.Consumer>
      {({scheduleObj, dataResource, dataSource, handleClickViewShift, handleClickAddShift, handleDoubleClickCell, handleMoreEventClick, onPopupOpen}) => (
        <ScheduleComponent
          ref={scheduleObj}
          id='schedule'
          cssClass='shift-schedule-cssClass'
          width='100%'
          height='auto'
          rowAutoHeight={true}
          showHeaderBar={false}
          showQuickInfo={false}
          currentView='TimelineMonth'
          workDays={[0, 1, 2, 3, 4, 5, 6]}
          group={{ resources: ['Resources'] }}
          // selectedDate={scheduleDate}
          renderCell={customRenderCell}
          dateHeaderTemplate={dateHeaderTemplate}
          resourceHeaderTemplate={customResourceHeaderTemplate}
          eventRendered={onEventRendered}
          eventClick={handleClickViewShift}
          cellClick={handleClickAddShift}
          eventSettings={{ 
            dataSource: dataSource,
          }}
          cellDoubleClick={handleDoubleClickCell}
          moreEventsClick={handleMoreEventClick}
          popupOpen={onPopupOpen}
        >
          <ViewsDirective>
            <ViewDirective option='TimelineMonth' />
          </ViewsDirective>
          <ResourcesDirective>
            <ResourceDirective
              field='ResourceId'
              title='Resource'
              name='Resources'
              allowMultiple={false}
              textField='Text'
              idField='Id'
              colorField='Color'
              dataSource={dataResource}
            />
          </ResourcesDirective>
          <Inject services={[ TimelineMonth ]} />
        </ScheduleComponent>
      )}
    </PageOrganizationTabShift.Consumer>
  );
}