import React, { useEffect } from 'react'
import { Form, Modal, Input, DatePicker, Row, Col, Select, InputNumber } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button_02 from '../../../../components/v2/button_02'
import Button_01 from '../../../../components/v2/button_01'
import LabeRequireForm from '../../../../components/label-required-form'
import _ from 'lodash'
import moment from 'moment'
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency'
import { v4 as uuidv4 } from 'uuid';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../component-function/fnc-number'

const { Option } = Select

const ExpensePaid = (props) => {
  const { visible, setVisible, form, type, bankList, creditCardTypeList, paidItem, setPaidItem, total, receive } = props
  const { getFieldDecorator, resetFields, validateFields, getFieldValue, setFieldsValue } = form;
  const intl = useIntl()
  const title = type === 'cash' ? intl.formatMessage({ id: `expenseBtnCash`, defaultMessage: 'Cash' }) :
    type === 'banktransfer' ? intl.formatMessage({ id: `expenseBtnBankTranfer`, defaultMessage: 'Bank Tranfer' }) :
      type === 'creditcard' ? intl.formatMessage({ id: `expenseBtnCreditCard`, defaultMessage: 'Credit Card' }) :
        type === 'cheque' ? intl.formatMessage({ id: `expenseBtnCheque`, defaultMessage: 'Cheque' }) :
          type === 'claim' ? intl.formatMessage({ id: `expenseBtnClaim`, defaultMessage: 'Claim' }) : ''

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = setBody(values)
      setPaidItem([...paidItem, body])
      setVisible(false)
      resetFields()
    })
  }

  const setBody = (values) => {
    let body1 = {
      "tempId": uuidv4(),
      "medthod": {
        "code": type,
        "lang": {
          "EN": title,
          "TH": title
        }
      },
      "amount": parseFloat(_.get(values, 'amount') || 0),
      "paidDate": moment(_.get(values, 'paidDate')).format('YYYY-MM-DD'),
      "remark": _.get(values, 'remark') || "",
      "status": {
        "code": "success",
        "lang": {
          "EN": "Success",
          "TH": "สำเร็จ",
        }
      },
    }
    let body2 = {}
    if (type === "cash" || type === "claim") {
      body2 = {}
    } else if (type === "banktransfer") {
      const filterBank = _.filter(bankList, (item) => { return item.code === _.get(values, 'bankTranferTo') })
      body2 = {
        "detail": {
          "bank": {
            "code": _.get(values, 'bankTranferTo'),
            "accountNo": _.get(values, 'bankTranferAccount'),
            "lang": {
              "EN": _.get(filterBank, '[0].txt'),
              "TH": _.get(filterBank, '[0].txt'),
            }
          }
        },
      }
    } else if (type === "creditcard") {
      const filterBank = _.filter(bankList, (item) => { return item.code === _.get(values, 'creditBankIssue') })
      body2 = {
        "detail": {
          "bank": {
            "code": _.get(values, 'creditBankIssue'),
            "lang": {
              "EN": _.get(filterBank, '[0].txt'),
              "TH": _.get(filterBank, '[0].txt'),
            }
          },
          "creditCardType": {
            "code": _.get(values, 'creditCardType')
          },
          "creditCardNo": _.get(values, 'creditCardNo'),
          "cardHolderName": _.get(values, 'creditCardName')
        }
      }
    } else if (type === "cheque") {
      const filterBank = _.filter(bankList, (item) => { return item.code === _.get(values, 'chequeBank') })
      body2 = {
        "detail": {
          "bank": {
            "code": _.get(values, 'chequeBank'),
            "lang": {
              "EN": _.get(filterBank, '[0].txt'),
              "TH": _.get(filterBank, '[0].txt'),
            }
          },
          "chequeNo": _.get(values, 'chequeNo'),
          "chequeDate": moment(_.get(values, 'chequeDate')).format('YYYY-MM-DD'),
          "payTo": _.get(values, 'chequePayTo'),
        },
      }
    }
    return {
      ...body1,
      ...body2
    }
  }

  useEffect(() => {
    const amount = getFieldValue('amount') || 0
    const cal = total - receive
    if (amount > cal) {
      setFieldsValue({ ['amount']: (cal || 0).toFixed(2) })
    }
  }, [getFieldValue('amount')])


  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  const validatorAmount = (rule, value, callback) => {
    if (value) {
      const parsValue = parseFloat(value || 0)
      if (parsValue === 0) {
        callback('Amount must more than 0');
      } else {
        callback();
      }
    }
    else {
      callback();
    }
  }

  const currencyFormatter = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleCancel}
      width={600}
      centered={true}
      bodyStyle={{ padding: '12px 24px 24px 24px' }}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form colon={false}>
        {
          type === "banktransfer" ? <BankTranfer form={form} bankList={bankList} /> :
            type === "creditcard" ? <CreditCard form={form} bankList={bankList} creditCardTypeList={creditCardTypeList} /> :
              type === "cheque" ? <Cheque form={form} bankList={bankList} /> : null
        }
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormamount', defaultMessage: 'Amount' })} req={true} />}
            >
              {getFieldDecorator('amount', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'expenseFormReqAmount', defaultMessage: 'Please Enter Amount' }),
                  },
                  {
                    validator: validatorAmount
                  }
                ],
              })(
                <InputNumber
                  className='disabled-handle-count'
                  placeholder={intl.formatMessage({ id: 'expenseFormPHAmount', defaultMessage: 'Enter Amount' })}
                  onKeyDown={handleKeyDownDecimal2Fixed}
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  controls={false}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormPaidDate', defaultMessage: 'Paid Date' })} req={true} />}
            >
              {getFieldDecorator('paidDate', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'expenseFormReqPaidDate', defaultMessage: 'Please Select Paid Date' }),
                  },
                ],
              })(
                <DatePicker
                  placeholder={intl.formatMessage({ id: 'expenseFormPHPaidDate', defaultMessage: 'Select Paid Date' })}
                  style={{ width: '100%' }}
                  format='DD/MM/YYYY'
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormRemark', defaultMessage: 'Remark' })} req={false} />}
            >
              {getFieldDecorator('remark', {
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'expenseFormPHRemark', defaultMessage: 'Enter Remark' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const BankTranfer = (props) => {
  const { form, bankList } = props
  const { getFieldDecorator } = form;
  const intl = useIntl()

  return (
    <Row gutter={[24, 2]}>
      <Col span={12}>
        <Form.Item
          label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormBankTranferTo', defaultMessage: 'To Bank' })} req={true} />}
        >
          {getFieldDecorator('bankTranferTo', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'expenseFormReqBankTranferTo', defaultMessage: 'Please Select Bank' }),
              },
            ],
          })(
            <Select
              placeholder={intl.formatMessage({ id: 'expenseFormPHBankTranferTo', defaultMessage: 'Select Bank' })}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {
                _.map(bankList, (item) => (
                  <Option key={item.code}>
                    {item.txt}
                  </Option>
                ))
              }
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormBankTranferAccount', defaultMessage: 'To Account No.' })} req={false} />}
        >
          {getFieldDecorator('bankTranferAccount', {
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'expenseFormPHBankTranferAccount', defaultMessage: 'Enter Account No.' })}
              onKeyDown={handleKeyDownNumber}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}

const CreditCard = (props) => {
  const { form, bankList, creditCardTypeList } = props
  const { getFieldDecorator } = form;
  const intl = useIntl()

  const validatorDigit = (rule, value, callback) => {
    if (value) {
      if (value.length !== 4) {
        callback(<FormattedMessage id="expenseFormCreditCardDigitValidate" defaultMessage="Please enter 4 digits" />)
      } else {
        callback();
      }
    } else {
      callback();
    }
  }


  return (
    <>
      <Row gutter={[24, 2]}>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormCreditCardType', defaultMessage: 'Credit Card Type' })} req={true} />}
          >
            {getFieldDecorator('creditCardType', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqCreditCardType', defaultMessage: 'Please Select Credit Card Type' }),
                },
              ],
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'expenseFormPHCreditCardType', defaultMessage: 'Select Credit Card Type' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {
                  _.map(creditCardTypeList, (item) => (
                    <Option key={item.code}>
                      {item.txt}
                    </Option>
                  ))
                }
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormCreditCardNo', defaultMessage: 'Credit Card No. (Last 4 digits)' })} req={true} />}
          >
            {getFieldDecorator('creditCardNo', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqCreditCardNo', defaultMessage: 'Please Enter Credit Credit Card No.' }),
                },
                {
                  validator: validatorDigit
                }
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'expenseFormPHCreditCardNo', defaultMessage: 'Enter Credit Card No. (Last 4 digits)' })}
                onKeyDown={handleKeyDownNumber}
                maxLength={4}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 2]}>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormCreditCardName', defaultMessage: 'Card Holder Name' })} req={true} />}
          >
            {getFieldDecorator('creditCardName', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqCreditCardName', defaultMessage: 'Please Enter Card Holder Name' }),
                },
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'expenseFormPHCreditCardName', defaultMessage: 'Enter Enter Card Holder Name' })}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormCreditBankIssue', defaultMessage: 'Bank Issue' })} req={true} />}
          >
            {getFieldDecorator('creditBankIssue', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqCreditBankIssue', defaultMessage: 'Please Select Bank Issue' }),
                },
              ],
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'expenseFormPHCreditBankIssue', defaultMessage: 'Select Bank Issue' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {
                  _.map(bankList, (item) => (
                    <Option key={item.code}>
                      {item.txt}
                    </Option>
                  ))
                }
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>

  )
}

const Cheque = (props) => {
  const { form, bankList } = props
  const { getFieldDecorator } = form;
  const intl = useIntl()

  return (
    <>
      <Row gutter={[24, 2]}>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormChequeBank', defaultMessage: 'Bank' })} req={true} />}
          >
            {getFieldDecorator('chequeBank', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqChequeBank', defaultMessage: 'Please Select Bank' }),
                },
              ],
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'expenseFormPHChequeBank', defaultMessage: 'Select Bank' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {
                  _.map(bankList, (item) => (
                    <Option key={item.code}>
                      {item.txt}
                    </Option>
                  ))
                }
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormChequeNo', defaultMessage: 'Cheque No.' })} req={true} />}
          >
            {getFieldDecorator('chequeNo', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqChequeNo', defaultMessage: 'Please Enter Cheque No.' }),
                },
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'expenseFormPHChequeNo', defaultMessage: 'Enter Cheque No.' })}
                onKeyDown={handleKeyDownNumber}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 2]}>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormChequePayTo', defaultMessage: 'Pay To' })} req={true} />}
          >
            {getFieldDecorator('chequePayTo', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqChequePayTo', defaultMessage: 'Please Enter Pay To' }),
                },
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'expenseFormPHChequePayTo', defaultMessage: 'Enter Name' })}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormChequeDate', defaultMessage: 'Cheque Date' })} req={true} />}
          >
            {getFieldDecorator('chequeDate', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqChequeDate', defaultMessage: 'Please Selct Cheque Date' }),
                },
              ],
            })(
              <DatePicker
                placeholder={intl.formatMessage({ id: 'expenseFormPHChequeDate', defaultMessage: 'Select Cheque Date' })}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>

  )
}

const ExpensePaidForm = Form.create({
  name: 'po-form',
  mapPropsToFields() { }
})(ExpensePaid);

export default ExpensePaidForm
