import React from 'react';
import CustomerForm from './customer-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import { messageLabel } from '../../../../components/message-component';
import CustomTableComponent from '../../components/table';
import PriceForm from './price-form';
import { Button, Divider, Dropdown } from 'antd';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal';
import { DownOutlined } from '@ant-design/icons';

const DetailsTab = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    dataForm,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    setIsOpenQt,
    isOpenQt,
    handleOpenQuotation,
    formCustomerRef,
    isOpenQuotation,
    record,
    handleOpenModalEdit,
    onChangeDiscount,
    searchGroup,
    handleDeleteAttAchment,
    menuColumn,
    checkQty,
  } = property;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: (a, b) => {
        return a.itemCode.localeCompare(b.itemCode);
      },
      width: 120,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: (a, b) => {
        return a.itemName.localeCompare(b.itemName);
      },
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: (a, b) => {
        return a.qty.localeCompare(b.qty);
      },
      render: (text, record, index) => (
        <span style={{ color: record.isOutOfStock ? '#E73845' : null }}>{record.qty + ' ' + record.uomName}</span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'pricePerUnit',
      key: 'pricePerUnit',
      sorter: (a, b) => {
        return a.pricePerUnit.toString().localeCompare(b.pricePerUnit.toString());
      },
      // sorter: (a, b) => { return a.pricePerUnit.localeCompare(b.pricePerUnit)},
      render: (text, record, index) => numberFormatter(record.pricePerUnit) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnCredits', defaultMessage: 'Credit (days)' }),
      dataIndex: 'credits',
      key: 'credits',
      render: (text, record, index) => _.get(record, 'credits', '-') !== "" ? _.get(record, 'credits', '-') :
        _.get(record, 'credit', '-') !== "" ? _.get(record, 'credit', '-') : "-",
    },
    // {
    //   title: intl.formatMessage({ id: 'purchesOrderModalColumnPromotionCampaign', defaultMessage: 'Promotion or Campaign' }),
    //   dataIndex: 'promotionCampaign',
    //   key: 'promotionCampaign',
    //   render: (text, record, index) => record.promotionCampaign || '-',
    // },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: (text, record, index) => numberFormatter(record.discountPrice ? record.discountPrice : 0) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: (a, b) => {
        return a.totalPrice.localeCompare(b.totalPrice);
      },
      // fixed: 'right',
      width: 150,
      render: (text, record, index) => numberFormatter(record.totalPrice) + ' ' + 'THB',
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: (a, b) => {
        return a.itemCode.localeCompare(b.itemCode);
      },
      width: 350,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  // const deleteAtt = (value) => {
  //   const filterSelect = _.filter(attData, (item) => {
  //     return item.id !== value;
  //   });
  //   setAttData(filterSelect);
  // };

  const handleEditUploadInside = (value) => {
    handleOpenModalUpload(value);
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const deleteItem = (value) => {
    console.log('deleteQT', selectItem, '/', value);
    const filterSelect = _.filter(selectItem, (item) => {
      return item.uuid !== value;
    });
    setSelectItem(filterSelect);
  };

  const deleteAtt = (value) => {
    console.log('deleteAtt', value);
    if (_.get(value, 'form') === 'backend') {
      handleDeleteAttAchment(_.get(value, 'id'));
    }

    const filterSelect = _.filter(attData, (item) => {
      return item.id !== value.id;
    });
    setAttData(filterSelect);
  };

  const contentActionItem = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalEdit(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record.uuid)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  console.log('attData', attData);

  return (
    <div className="scroll-sm scroll-height">
      <div className="content-body">
        <CustomerForm
          form={form}
          setDataForm={setDataForm}
          dataForm={dataForm}
          initialMemberList={initialMemberList}
          handleOpen={handleOpen}
          handleOpenQuotation={handleOpenQuotation}
          formCustomerRef={formCustomerRef}
          isOpenQuotation={isOpenQuotation}
          record={record}
          setSelectItem={setSelectItem}
        />
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelItem" defaultMessage="Item" />
          </span>
          <div>
            {/* <Button_01
              key="add"
              style={{ margin: '0px 0px 0px 10px' }}
              disabled={!_.get(dataForm, 'customerName')}
              type="primary"
              btnsize="wd_df"
              onClick={() => setVisibleItem(true)}
            >
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01> */}
            <Dropdown
              overlay={menuColumn}
              trigger={['click']}
              placement="bottomRight"
              disabled={_.get(dataForm, 'customerId') ? false : true}
            >
              <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }}>
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button_01>
            </Dropdown>
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columns}
            dataSource={selectItem}
            // total={_.size(selectItem)}
            scroll={{ x: 1000 }}
            contentAction={contentActionItem}
            rowKey={(record) => record.uuid}
          />
          {checkQty ? (
            <span style={{ color: '#E73845', fontSize: '14px' }}>
              <FormattedMessage
                id="lblAvailableStockQuantity"
                defaultMessage="*The highlighted quantity is over the available stock quantity, please recheck."
              />
            </span>
          ) : null}

          <div className="content-body-price">
            <PriceForm
              form={form}
              selectItem={selectItem}
              setDataForm={setDataForm}
              dataForm={dataForm}
              initialTaxList={initialTaxList}
              record={record}
              onChangeDiscount={onChangeDiscount}
              searchGroup={searchGroup}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelAttactment" defaultMessage="Attactment" />
          </span>
          <div>
            <Button_01
              key="add"
              style={{ margin: '0px 0px 0px 10px' }}
              type="primary"
              btnsize="wd_df"
              onClick={() => handleOpenModalUpload()}
            >
              <FormattedMessage id="btnUpload" defaultMessage="Upload" />
            </Button_01>
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columnsAttach}
            dataSource={_.sortBy(attData, ['index'])}
            scroll={{ x: true, y: 200 }}
            contentAction={contentAction}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsTab;
