import React, { useState, useEffect, useRef, createRef, useMemo, memo, useContext, useCallback } from 'react';
import { ShiftContext } from './shift-context'; 
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, Spin, Avatar, Modal, notification } from 'antd';
import {
  TimelineMonth,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import { Internationalization, extend } from '@syncfusion/ej2-base';
import { L10n } from '@syncfusion/ej2-base';

import httpClient from '../../../components/axiosClient';
import Provider from '../provider';
import ShiftAction from './shift-action';
import ShiftSchedule from './shift-schedule';
import _ from 'lodash';
import moment from 'moment';
import stylesShiftIndex from './css/index.css';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import ShiftFormAddShift from './shift-modal-add-shift';
import ShiftModalDelete from './shift-modal-delete-shift';

const instance = new Internationalization();
const datenow = moment();

L10n.load({
  'en-US': {
    'schedule': {
      'saveButton': 'Add',
      'cancelButton': 'Cancel',
      'deleteButton': 'Remove',
      'newEvent': 'Add Shift',
    },
  }
});

const onEventRendered = (args) => {
  let height = 38;
  let left = parseInt(args.element.style.left) + 6;
  args.element.style.height = height + 'px';
  args.element.style.color = '#ffffff';
  args.element.style.backgroundColor = args.data.Color;
  args.element.style.margin = '0px';
  args.element.style.borderRadius = '3px';
  args.element.style.left = left + 'px';
  args.element.querySelector('.e-inner-wrap').style.padding = '0px 12px';
  args.element.querySelector('.e-inner-wrap').style.fontSize = '12px';
  args.element.querySelector('.e-time').style.fontSize = '8px';
  args.element.querySelector('.e-time').innerHTML = args.data.TeamName + ' ' + args.data.Start + ' - ' + args.data.Finish;
}

export default React.memo(({ orgIdSelect, refreshMain, refreshSaveFromMain }) => {
  const intl = useIntl();
  const app = Provider.useAppContext();

  const today = new Date();
  const datenow = moment();
  const dateTimeApi = 'YYYY-MM-DD HH:mm';
  const shiftsDateNow = moment(today).format(dateTimeApi);
  const scheduleObj = useRef();
  const comId = localStorage.getItem('comId');
  const comCode = localStorage.getItem('comCode');
  const langValue = localStorage.getItem('langValue') || 'EN';

  let shiftsYearMain = _.get(app, 'state.shiftsYear');
  let shiftsMonthMain = _.get(app, 'state.shiftsMonth');
  // let refreshMain = _.get(app, 'state.refresh');
  // let refreshSaveFromMain = _.get(app, 'state.refreshSaveFrom');

  const [resourcesArr, setResourcesArr] = useState([]);
  const [resourceShiftData, setResourceShiftData] = useState([]);
  const [shiftsData, setShiftsData] = useState([]);
  const [loadingShift, setLoadingShift] = useState(false);

  const [shiftsYear, setShiftsYear] = useState(moment().format('YYYY'));
  const [shiftsMonth, setShiftsMonth] = useState(moment().format('MMMM'));
  const [mainShiftsDateTimePicker, setMainShiftsDateTimePicker] = useState(shiftsDateNow);

  const [planningVisibled, setPlanningVisibled] = useState(false);
  const [shiftPlanningDisabled, setShiftPlanningDisabled] = useState(true);

  const [refreshApiShift, setRefreshApiShift] = useState(false);

  // ---------------------****------------- State Schedule ---------------****---------------------------------

  const [resourceCellgroupIndex, setResourceCellgroupIndex] = useState(null);
  const [resourceCellArgs, setResourceCellArgs] = useState({});
  const [shiftEventArgs, setShiftEventArgs] = useState({});
  const [formVisible, setFormVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [formData, setFormData] = useState({});
  const [rangePickerValue, setRangePickerValue] = useState([]);
  const [formRef, setFormRef] = useState(null);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);

  const resourceData_Sch = extend([], resourceShiftData, null, true);
  const shiftsData_Sch = extend([], shiftsData, null, true);



  useEffect(() => {
    if (app.state.keyTabsMain === 'shift') {
      getOrganizationShiftData();
    }
  }, [app.state.keyTabsMain, orgIdSelect, shiftsMonth, shiftsYear, refreshMain, refreshSaveFromMain, refreshApiShift]);

  useEffect(() => {
    getShiftSettings();
  }, [orgIdSelect, shiftsMonth, shiftsYear]);

  const getOrganizationShiftData = async () => {
    setLoadingShift(true);
    const data = {
      com_id: comId,
      org_id: orgIdSelect,
      search_team: {
        name: '',
      },
      members: {
        conditional: [],
        order: 'fullname ASC',
        limit: '100',
        index_page: '1',
      },
      services: {},
      shift: {
        date: `${shiftsYear}-${moment().month(shiftsMonth).format('MM')}`
      },
    };

    try {
      const response = await httpClient.post('/v2/organizationdetails', data);

      if (response.status === 200) {

        const resourceData = response.data.members.data ? response.data.members.data : [];
        const shiftData = response.data.shift ? response.data.shift : [];

        await setResourcesArr(resourceData);
        await newResourceShiftData(resourceData);
        await newShiftData(shiftData);
        await setLoadingShift(false);
      }
    } catch (error) {
      setLoadingShift(false);
    }
  };

  const getShiftSettings = async () => {
    try {
      const shiftSettings = await httpClient.get(`/resource/manager/organization/${app.state.orgId}/shift/rules?comId=${app.state.comId}`);
      if (shiftSettings.status === 200) {
        checkShiftPlanning();
        // if (shiftSettings.data.shiftTypeRules.length > 0 && shiftSettings.data.shiftRuleStatus === 'Enable') {
        //   checkShiftPlanning();
        // }
        // else {
        //   setShiftPlanningDisabled(true);
        // }
      }
      else {
        setShiftPlanningDisabled(true);
      }
    }
    catch (error) {
      setShiftPlanningDisabled(true);
    }
  }

  const newResourceShiftData = async (resourceData) => {
    let newResource = [];
    resourceData.map((object) => {
      const { mem_id, fullname, profile_img, email, phone, mem_com_id } = object;
      newResource.push({
        Id: mem_id,
        Text: fullname,
        email: email,
        phone: phone,
        pathImage: profile_img,
        memComId: mem_com_id,
      });
    });

    setResourceShiftData(newResource);


    // let matchResource = await _.isMatch(resourceShiftData, newResource);



    // if (matchResource === true) {
    //   return
    // } else {
    //   setResourceShiftData(newResource);
    // }
  }

  const newShiftData = async (shiftData) => {
    let newShift = [];
    shiftData.forEach((object) => {
      object.shifts.forEach((element) => {
        const { mem_shift_id, shift_name, shift_date, mem_id, color, is_holiday, teamName, start, finish, mem_com_id, default_profile_img, } = element;
        newShift.push({
          Id: mem_shift_id,
          Subject: shift_name,
          StartTime: moment(shift_date).format('YYYY-MM-DD 00:00'),
          EndTime: moment(shift_date).format('YYYY-MM-DD 23:59'),
          Color: color,
          ResourceId: Number(mem_id),
          MemComId: object.mem_com_id,
          PathImage: object.default_profile_img,
          Name: object.fullname,
          Email: object.email,
          Phone: object.phone,
          IsAllDay: true,
          isReadonly: is_holiday ? true : false,
          TeamName: teamName ? teamName : '',
          Start: moment(start, 'HH:mm:ss').format('HH:mm'),
          Finish: moment(finish, 'HH:mm:ss').format('HH:mm'),
          ThisOrgSelect: orgIdSelect
        });
      });
    });

    setShiftsData(newShift);
    // let matchShiftsData = await _.isMatch(shiftsData, newShift);


    // if (matchShiftsData === true) {
    //   return
    // } else {
    //   setShiftsData(newShift);
    // }

    // await setShiftsData(newShift);
  }

  const handleChangeMonth = (valueMonth) => {
    setShiftsMonth(valueMonth);
  }

  const handleChangeYear = (valueYear) => {
    setShiftsYear(valueYear);
  }

  const checkShiftPlanning = () => {
    const dateSelect = moment(shiftsYear + ' ' + shiftsMonth, 'YYYY MMMM');
    const diff = dateSelect.diff(datenow, 'months');
    if (diff >= 0) {
      setShiftPlanningDisabled(false);
    }
    else {
      setShiftPlanningDisabled(true);
    }
  };

  // ---------------------****------------- Fnc Schedule ---------------****---------------------------------

  const customRenderCell = (args) => {
    if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
      let sumResource = _.sumBy(scheduleObj.current.resources[0].properties.dataSource, (e) => { return e.Text !== '' ? 1 : 0; });
      let target = args.element.querySelector('.e-resource-text');
      target.innerHTML = `<div style="padding: 5px 15px;"><b> ${intl.formatMessage({ id: 'orgShiftPlanningLblResources', defaultMessage: 'Resources' })} (` + sumResource + ')</b></div>';
    }
  };

  const customResourceHeaderTemplate = (props) => {
    const checkResourceMockData = props.resourceData.Id.toString().indexOf('mockdata');
    const pathImage = getPathImage(props);
    return (
      checkResourceMockData ? 
        <div className='shift-template-wrap'>
          <Avatar size={32} className='avatar-border' src={pathImage} />
          <span className="shift-scheduler-column-name">{getName(props)}</span>
        </div> : <div></div>
    );
  };

  const handleClickCell = (args) => {
    if (args.name === "cellClick") {
      setResourceCellgroupIndex(args.groupIndex);
      setResourceCellArgs(args);
      setFormVisible(true);
    }
  }

  const handleClickEvent = (args) => {
    if (args.event.isHoliday || args.event.isLeave) return;
    if (args.name === "eventClick") {
      fncCheckDeleteShift(args.event.Id, args.event.ThisOrgSelect);
      setFormData({
        Id: args.event.Id,
        Subject: args.event.Subject,
        Color: args.event.Color,
        memId: args.event.ResourceId,
        memComId: args.event.MemComId,
        pathImage: process.env.REACT_APP_IMG_HOST + args.event.PathImage,
        name: args.event.Name,
        email: args.event.Email,
        phone: args.event.Phone,
        startDate: moment(args.event.StartTime).locale(langValue.toLowerCase()).format('DD/MM/YYYY hh:mm A'),
        endDate: moment(args.event.EndTime).locale(langValue.toLowerCase()).format('DD/MM/YYYY hh:mm A')
      });
      setVisibleDelete(true);
    }
  }

  useMemo(() => {
    resourceData_Sch.filter((obj, index) => index === resourceCellgroupIndex).map(item => {
        setFormData({
          memId: item.Id,
          memComId: item.memComId,
          pathImage: item.pathImage,
          name: item.Text,
          email: item.email || '',
          phone: item.phone || '',
          startDate: resourceCellArgs.startTime,
          endDate: resourceCellArgs.endTime
        });
      });
      setRangePickerValue([resourceCellArgs.startTime, resourceCellArgs.endTime]);
  }, [resourceCellArgs]);

  const fncSetShift = async (yearMonth) => {
    const shifts = await getShift(`${yearMonth}`);
    const setShifts = generateEvents(shifts);
    scheduleObj.current.eventSettings.dataSource = setShifts;
  }

  const getShift = async (thisDate) => {
    const data = {
      com_id: comId,
      org_id: orgIdSelect,
      search_team: {
        name: '',
      },
      members: {
        conditional: [],
        order: 'fullname ASC',
        limit: '10',
        index_page: '1',
      },
      services: {},
      shift: {
        date: `${thisDate}`
      },
    };

    try {
      const response = await httpClient.post('/v2/organizationdetails', data);
      if (response.status === 200) {
        return response.data.shift
      }
      else {
        return false;
      }
    }
    catch (error) {
      return false;
    }
  }

  const generateEvents = (data) => {
    const shiftsArr = [];
    if (data) {
      data.forEach((object) => {
        object.shifts.forEach((element) => {
          const { mem_shift_id, shift_name, shift_date, mem_id, color } = element;
          shiftsArr.push({
            Id: mem_shift_id,
            Subject: shift_name,
            StartTime: moment(shift_date).format('YYYY-MM-DD 00:00'),
            EndTime: moment(shift_date).format('YYYY-MM-DD 23:59'),
            Color: color,
            ResourceId: mem_id,
            IsAllDay: true
          });
        });
      });
    }
    return shiftsArr;
  }

  const handlePopupOpen = (args) => {
    args.cancel = true;
    if (args.type === 'Editor') {
      if (args.data.isReadonly) args.cancel = true;
    }

    if (args.type === 'QuickInfo') {
      if (!_.isInteger(args.data.Id)) {
        args.cancel = true;
      }
    }

    if (args.type === 'DeleteAlert') {
      // args.cancel = true;
    }
  };

  const getPathImage = (value) => {
    return value.resourceData.pathImage;
  };

  const getName = (value) => {
    return value.resourceData[value.resource.textField];
  };

  const dateHeaderTemplate = (props) => {
    return (
      <div style={{ height: 50, padding: 5 }}>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'E')}</div>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'd')}</div>
      </div>
    );
  }
  
  const getDateHeaderText = (value, skeleton) => {
    return instance.formatDate(value, { skeleton: skeleton });
  }

  const handleAddShiftSubmit = async (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const resourceIndex = _.findIndex(resourceData_Sch, function (o) { return o.Id === parseInt(formData.memId); });
      const resourceDetail = { ...resourcesArr[resourceIndex] };

       try {
        const body = {
          comId: comId,
          orgId: orgIdSelect,
          memId: resourceDetail.mem_id,
          memOrgId: resourceDetail.mem_org_id,
          shiftId: values.shift,
          from: moment(values.range_picker[0]).format('YYYY-MM-DD'),
          to: moment(values.range_picker[1]).format('YYYY-MM-DD')
        }
        const response = await httpClient.post(`/v2/members-shifts/create`, body);       

        if (response.status === 200) {
          successNotification(response.data.message);
          formRef.resetFields();
          setFormVisible(false);
          setRefreshApiShift(true);
        } else {
          errorNotification(response.data.data);
          formRef.resetFields();
          setFormVisible(false);
        }
      } catch (error) {
        setFormVisible(false);
        formRef.resetFields();
      }
    })
  }

  const handleDeleteShift = async () => {
    try {
      const response = await httpClient.delete(`/v2/members-shifts/delete/${formData.Id}`);
      if (response.status === 200) {
        successNotification(response.data.data);
        setVisibleDelete(false)
        setRefreshApiShift(true);
      } else {
        errorNotification(response.data.data);
        setVisibleDelete(false)
      }
    } catch (error) {
    }
  }

  const fncCheckDeleteShift = async (memShiftId, ThisOrgSelect) => {
    if (memShiftId) {
      try {
        const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/validate-delete/member-shift/${memShiftId}?orgId=${ThisOrgSelect}`);
        if (response.status === 200) {
          await setDisabledBtnDelete(response.data.data.hasClockIn);
        }
      } catch (error) {
        setDisabledBtnDelete(true);
      };
    }
  }

  const handleCancelAddShift = () => {
    setFormVisible(false);
    formRef.resetFields();
  }

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.error({
      message: message,
    });
  };

  return (
    // <ShiftContext.Provider
    //   value={{
    //     stateShiftsMain: {
    //       resourceShiftData,
    //       resourcesArr,
    //       shiftsData,
    //       shiftsYear,
    //       shiftsMonth,
    //       planningVisibled,
    //       shiftPlanningDisabled,
    //       mainShiftsDateTimePicker
    //     },
    //     setStateShiftsMain: {
    //       setShiftsYear,
    //       setShiftsMonth,
    //       setMainShiftsDateTimePicker,
    //       setPlanningVisibled,
    //       setShiftPlanningDisabled,
    //       setRefreshApiShift,
    //     },
    //     fncShiftsMain: {
    //       handleChangeMonth,
    //       handleChangeYear,
    //     }
    //   }}
    // >
      <div>
        <ShiftAction  
          shiftsMonth={shiftsMonth}
          shiftsYear={shiftsYear}
          setShiftsMonth={setShiftsMonth}
          setShiftsYear={setShiftsYear}
          shiftPlanningDisabled={shiftPlanningDisabled}
          planningVisibled={planningVisibled}
          setMainShiftsDateTimePicker={setMainShiftsDateTimePicker}
          setPlanningVisibled={setPlanningVisibled}
          handleChangeMonth={handleChangeMonth}
          handleChangeYear={handleChangeYear}
        />
        <Spin spinning={loadingShift}>
          <div>
            {resourceShiftData.length && (
              <div className="shift-schedule-div">
                <ScheduleComponent
                  ref={scheduleObj}
                  cssClass='shift-schedule-cssClass'
                  id="schedule"
                  width="100%"
                  height="unset"
                  delayUpdate="true"
                  rowAutoHeight={true}
                  showHeaderBar={false}
                  showQuickInfo={false}
                  currentView='TimelineMonth'
                  workDays={[0, 1, 2, 3, 4, 5, 6]}
                  group={{ resources: ['Resources'] }}
                  selectedDate={mainShiftsDateTimePicker}
                  renderCell={customRenderCell}
                  dateHeaderTemplate={dateHeaderTemplate}
                  resourceHeaderTemplate={customResourceHeaderTemplate}
                  popupOpen={handlePopupOpen}
                  eventRendered={onEventRendered}
                  eventClick={handleClickEvent}
                  cellClick={handleClickCell}
                  // actionBegin={handleActionBegin}
                  eventSettings={{ 
                    dataSource: shiftsData_Sch,
                  }}
                >
                  <ViewsDirective>
                    <ViewDirective option='TimelineMonth' />
                  </ViewsDirective>
                  <ResourcesDirective>
                    <ResourceDirective
                      field='ResourceId'
                      title='Resource'
                      name='Resources'
                      allowMultiple={false}
                      textField='Text'
                      idField='Id'
                      colorField='Color'
                      dataSource={resourceData_Sch}
                    />
                  </ResourcesDirective>
                  <Inject services={[ TimelineMonth ]} />
                </ScheduleComponent>

                <ShiftFormAddShift 
                  ref={saveFormRef}
                  formData={formData}
                  onCreate={() => handleAddShiftSubmit()} 
                  onCancel={() => handleCancelAddShift()}
                  rangePickerValue={rangePickerValue}
                  visible={formVisible}
                  rangePickerDisabled={'false'}
                  shiftsType={app.state.shiftsType}
                />

                <Modal
                  className="shift-modal-schedule"
                  title={intl.formatMessage({ id: 'orgShiftPlanningModalTitleDelete', defaultMessage: 'Delete Shift' })}
                  centered={true}
                  width={550}
                  visible={visibleDelete}
                  onOk={() => handleDeleteShift()}
                  onCancel={() => setVisibleDelete(false)}
                  footer={[
                    <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={() => setVisibleDelete(false)}>
                      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                    </Button02>,
                    disabledBtnDelete === false ? (
                      <Button01 key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={() => handleDeleteShift()} disabled={disabledBtnDelete}>
                        <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                      </Button01>
                    ) : null
                  ]}
                >
                  <ShiftModalDelete formData={formData} />
                </Modal>
              </div>
            )}
          </div>

          {/* <div>
            {resourceShiftData.length > 0 ? (
              <ShiftSchedule 
                orgIdSelect={orgIdSelect}
                resourceShiftData={resourceShiftData} 
                shiftsData={shiftsData} 
                refreshMain={refreshMain} 
                mainShiftsDateTimePicker={mainShiftsDateTimePicker}
                resourcesArr={resourcesArr} 
                setRefreshApiShift={setRefreshApiShift}
              />
            ) : null}
          </div> */}
        </Spin>
    
    
      </div>
    // </ShiftContext.Provider>
  )
})