import React from 'react';
import { GaspriceContext } from './Gaspricecontext';
import { Col, Row } from 'antd';
import GaspriceTable from './components/GaspriceTable';

function Gasprice() {
  return (
    <GaspriceContext.Provider
      value={{
        state: {},
        setState: {},
        fnc: {},
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <GaspriceTable />
        </Col>
      </Row>
    </GaspriceContext.Provider>
  );
}

export default Gasprice;
