import React, { Component } from 'react'
import './print.css'
import { Row, Col, Input } from 'antd'
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
const { TextArea } = Input;
export default class Printtest extends Component {
  constructor(props) {
    super(props)
    this.state = props

  }

  componentDidUpdate(props) {
    if (this.state.print !== props.print) {
      this.setState({
        print: props.print
      });
    }
  }

  render() {

    const MaximumRow = 15
    const TotalPage = _.ceil(this.props.data.detail.length / MaximumRow)
    const StartRow = 0
    const chunkArray = _.chunk(this.props.data.detail, MaximumRow)
    const indexOfArray = _.indexOf(chunkArray)
    // const mapLengthData = chunkArray.map(item => {return item.length})

    let i = 0
    let runningNumber = 1

    return (
      <div className='print-source'>
        <div className="print-container" style={{ margin: "0", padding: "0" }}>
          {
            chunkArray.map((item, index) =>
              // <div class='book'>
              <div class='page'>
                <div class='subpage'>
                  <div class='subpage-header'>
                    <p class='subpage-header-company-name'>{this.props.dataCompany.invHeaderName}</p>
                    <p>{this.props.dataCompany.invHeaderAddress}</p>
                    <p><span>Tax No: {this.props.dataCompany.invHeaderTaxNo}</span><span class='m-l-30'>Phone: {this.props.dataCompany.invHeaderPhone}</span></p>
                    <div class='header-logo'>Invoice</div>
                  </div>
                  <div class='subpage-customer'>
                    <div class='subpage-customer-left'>
                      <p class='subpage-customer-name'>Customer</p>
                      <p class='subpage-customer-address-name'>{this.props.data.customerName}</p>
                      <p class='subpage-customer-address'>{this.props.data.customerAddress}</p>
                      <p>Tax No: {this.props.data.taxNo}</p>
                      <p>Phone: {this.props.data.customerPhone}</p>
                    </div>
                    <div class='subpage-customer-right'>
                      <p class='subpage-inovice-header'>Invoice No.</p>
                      <p class='subpage-inovice-no'>{this.props.data.invoiceNo}</p>
                      <p class='subpage-date-of-issue-header'>Date Of Issue</p>
                      <p class='subpage-invoice-date'>{moment(this.props.data.billDate, 'YYYY-MM-DD').format('ddd, MMM DD YYYY')}</p>
                    </div>
                  </div>
                  <div class='subpage-item'>
                    <table class='invoice-table-item'>
                      <tr>
                        <th>#</th>
                        <th>Task No.</th>
                        <th>Task Type</th>
                        <th>Unit Price (Baht)</th>
                        <th>Qty</th>
                        <th>Total (Baht)</th>
                      </tr>
                      {item.map((item, index) => (
                        <tr>
                          <td>{(runningNumber++)}</td>
                          <td>{item.taskNo}</td>
                          <td>{item.taskTypeName}</td>
                          <td><NumberFormat value={item.UnitPrice} displayType={'text'} thousandSeparator={true} /></td>
                          <td><NumberFormat value={item.qty} displayType={'text'} thousandSeparator={true} /></td>
                          <td><NumberFormat value={item.total} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>
                      ))}

                    </table>
                  </div>
                  {(chunkArray.length - 1) === i++ ? (
                    <div class='subpage-footer'>
                      <div class='subpage-footer-left'>
                        <p>Remark</p>
                        <TextArea value={this.props.data.invoiceRemark} disabled={true} rows={5} autoSize={{ minRows: 5, maxRows: 5 }} className='text-area' />
                      </div>
                      <div class='subpage-footer-right'>
                        <div class='subpage-footer-right-sub'>
                          <div class='subpage-footer-right-sub-left'>
                            <p>Subtotal</p>
                            <p><span>Discount</span> <span>{this.props.data.discountTitle}</span></p>
                            <p>Subtotal Less Discount</p>
                            <p><span>Tax</span> <span>{this.props.data.taxTitle}</span></p>
                            <p>Total</p>
                          </div>
                          <div class='subpage-footer-right-sub-right'>
                            <p>{_.floor(this.props.data.subTotal, 2).toFixed(2)} Baht</p>
                            <p>{_.floor(this.props.data.discount, 2).toFixed(2)} Baht</p>
                            <p>{_.floor(this.props.data.subTotal - this.props.data.discount, 2).toFixed(2)} Baht</p>
                            <p>{_.floor(this.props.data.tax, 2).toFixed(2)} Baht</p>
                            <p>{_.floor(this.props.data.total, 2).toFixed(2)} Baht</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                  }



                </div>
              </div>
              // </div>
            )

          }
        </div>
      </div >

    )

  }
}
