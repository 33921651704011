import React from 'react';
import { Tabs, Menu, Row, Col, Divider, Tag, Form, Select } from 'antd';
import ButtonActionGroup from '../../components/action-button';
import { useIntl, FormattedMessage } from 'react-intl';
import { StickyContainer } from 'react-sticky';
import './index.css';
import Button_01 from '../../components/v2/button_01';
import _ from 'lodash';
import UomTable from './uomTable';
import UomTableConvert from './uomTableConvert';
import CollapseCustom from '../../components/collapse-custom';
import LabeRequireForm from '../../components/label-required-form';
import Button_02 from '../../components/v2/button_02';

const { TabPane } = Tabs;
const { Option } = Select;

const UOMDetail = (props) => {
  const intl = useIntl();
  const {
    changeTab,
    uomData,
    selectCategory,
    handleCategory,
    handleDelete,
    handleOpenUom,
    setVisibleUOM,
    setSelectUoM,
    handleDeleteUoM,
    tabChange,
    handleOpenConvert,
    handleOpenConvertEdit,
    convertList,
    handleDeleteConvertUoM,
    setTypeUOM,
    setIsFetch,
    searchConvert,
    setSearchConvert,
    statusList,
  } = props;

  console.log('convertList', convertList);

  const columnsDetail = [
    {
      title: '#',
      dataIndex: 'i',
      key: 'i',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: `uomColumnName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `uomColumnDescription`, defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const columnsRevert = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: `uomColumnBaseQty`, defaultMessage: 'Base Qty' }),
      dataIndex: 'baseQty',
      key: 'baseQty',
      align: 'center',
      width: 120,
    },
    {
      title: intl.formatMessage({ id: `uomColumnBaseUom`, defaultMessage: 'Base UoM' }),
      dataIndex: 'baseUomName',
      key: 'baseUomName',
      width: 120,
    },
    {
      title: intl.formatMessage({ id: `uomColumnAltQty`, defaultMessage: 'Alt Qty' }),
      dataIndex: 'altQty',
      key: 'altQty',
      align: 'center',
      width: 120,
    },
    {
      title: intl.formatMessage({ id: `uomColumnAltUom`, defaultMessage: 'Alt UoM' }),
      dataIndex: 'altUomName',
      key: 'altUomName',
      width: 120,
    },
    {
      title: intl.formatMessage({ id: 'uomColumnStatus', defaultMessage: 'Status' }),
      dataIndex: 'statusTxt',
      key: 'statusTxt',
      width: 120,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag style={{ fontSize: '14px' }} className="tag-center-style" color={_.get(record, 'statusColor')}>
            {_.get(record, 'statusTxt')}
          </Tag>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: `uomColumnDescription`, defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (text, record, index) => <div>{record.description}</div>,
    },
    {
      title: intl.formatMessage({ id: `uomColumnCreatedBy`, defaultMessage: 'Created by' }),
      dataIndex: 'createdByName',
      key: 'createdByName',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: `uomColumnCreatedDate`, defaultMessage: 'CreatedDate' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
    },
    {
      title: intl.formatMessage({ id: `uomColumnDeletedBy`, defaultMessage: 'Deleted by' }),
      dataIndex: 'deletedByName',
      key: 'deletedByName',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: `uomColumnDeletedDate`, defaultMessage: 'Deleted Date' }),
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      width: 150,
    },
  ];
  // text-overflow: ellipsis;
  // overflow: hidden;
  // width: 160px;
  // white-space: nowrap;

  const handleOpenModal = (record) => {
    setTypeUOM('edit');
    setVisibleUOM(true);
    setSelectUoM(record);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <p onClick={() => handleCategory('edit')}>
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </p>
      </Menu.Item>
      <Menu.Item key="1">
        <p onClick={() => handleDelete(selectCategory)}>
          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
        </p>
      </Menu.Item>
    </Menu>
  );

  const operations =
    tabChange === '1' ? (
      <div className="UOMoperations">
        <ButtonActionGroup menu={menu} />
      </div>
    ) : (
      <div className="UOMoperations">
        <Button_01 type="primary" btnsize="wd_df" fontSize="sm" onClick={handleOpenConvert}>
          <FormattedMessage id="btnAdd" defaultMessage="Add" />
        </Button_01>
      </div>
    );

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'uomColumnBaseUom', defaultMessage: 'Base UoM' })} req={false} />}
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'uomPHRefBaseUom', defaultMessage: 'Select Base UoM' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'baseUomId')}
                value={_.get(searchConvert, 'baseUomId') || undefined}
              >
                {_.map(uomData, (item) => (
                  <Option key={item.uomId} value={item.uomId}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'uomColumnAltUom', defaultMessage: 'Alt UoM' })} req={false} />}
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'uomPHRefAltUom', defaultMessage: 'Select Alt UoM' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'altUomId')}
                value={_.get(searchConvert, 'altUomId') || undefined}
              >
                {_.map(uomData, (item) => (
                  <Option key={item.uomId} value={item.uomId}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'uomColumnStatus', defaultMessage: 'Status' })} req={false} />}
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'uomPHRefStatus', defaultMessage: 'Select Status' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'active')}
                value={_.get(searchConvert, 'active') || undefined}
              >
                {_.map(statusList, (item) => (
                  <Option key={item.active} value={item.active}>{item.txt}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const onFilterOrder = async (value, code) => {
    setSearchConvert((prev) => ({...prev, [code]: value }))
  };

  const handleApply = () => {
    setIsFetch((event) => !event)
    setSearchConvert((prev) => ({...prev, page: 1}))
  };

  const handleReset = () => {
    setIsFetch((event) => !event)
    setSearchConvert((prev) => ({...prev, page: 1, active: undefined, baseUomId: undefined, altUomId: undefined}))
  };

  const handleChange = (pagination) => {
    setIsFetch((event) => !event)
    setSearchConvert((prev) => ({...prev, page: pagination.current, limit: pagination.pageSize}))
  };

  return (
    <div>
      <StickyContainer style={{ width: '100%' }}>
        <Tabs defaultActiveKey="1" onChange={changeTab} tabBarExtraContent={operations}>
          <TabPane tab={intl.formatMessage({ id: `TabDetail`, defaultMessage: 'Details' })} key="1">
            <div style={{ padding: '24px' }}>
              <Row gutter={16}>
                <Col span={8}>
                  <p className="uomDetailCateagoryText">
                    <FormattedMessage id="uomDetailCategoryText" defaultMessage="UoM Category" />
                  </p>
                  <p className="uomDetailCateagoryTextLabel">{_.get(selectCategory, 'name')}</p>
                </Col>
                <Col span={16}>
                  <p className="uomDetailCateagoryText">
                    <FormattedMessage id="uomDetailDescriptionText" defaultMessage="Description" />
                  </p>
                  <p className="uomDetailCateagoryTextLabel">{_.get(selectCategory, 'description')}</p>
                </Col>
              </Row>
            </div>
            <div style={{ padding: '24px 24px 0 24px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="uomDetailUOM">
                  <FormattedMessage id="menuUOM" defaultMessage="UoM" />
                </div>
                <div>
                  <Button_01 type="primary" btnfontsize="sm" style={{ margin: 'unset' }} onClick={() => handleOpenUom('add')}>
                    <FormattedMessage id="btnCreate" defaultMessage="Create" />
                  </Button_01>
                </div>
              </div>
            </div>
            <Divider type="horizontal" />
            <div style={{ padding: '0 24px' }}>
              <UomTable columns={columnsDetail} dataSource={uomData} handleEdit={handleOpenModal} handleDelete={handleDeleteUoM} />
            </div>
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: `TabConvert`, defaultMessage: 'Convert' })} key="2">
            <div style={{ marginTop: '16px' }}>
              <CollapseCustom
                label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
                // extra={}
              >
                {formFilter()}
              </CollapseCustom>
            </div>

            <div style={{ padding: '15px 24px 24px 24px' }}>
              <UomTableConvert
                columns={columnsRevert}
                dataSource={_.get(convertList, 'list')}
                // handleEdit={handleOpenConvertEdit}
                handleDelete={handleDeleteConvertUoM}
                total={_.get(convertList, 'total')}
                page={_.get(searchConvert, 'page')}
                handleChange={handleChange}
              />
            </div>
          </TabPane>
        </Tabs>
      </StickyContainer>
    </div>
  );
};

export default UOMDetail;
