import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getSummaryAccount = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/pagesummary`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getSaleOrderTOARList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/page`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};


const getSaleOrderPendingList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/pengininvoice`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getSaleOderDetail = async (id) => {
  try {
    const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/saleorder/ar/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getARDetail = async (id) => {
  try {
    const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/saleorder/ar/detil/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getSaveAR = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getSaveNewAR = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/newar`, payload);
    console.log('ARpaddingCustom',response);
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) { console.log('ARpaddingCustomerror',error.response); return error.response}
}

const uploadARAttachment = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/document`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const uploadARAttachmentPaid = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/paiddocument`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getARPaidDetail = async (id) => {
  try {
    const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/saleorder/ar/paiddetil/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateARStatus = async (payload) => {
  try {
    const response = await httpClient.put(`/v3/invoice/manager/company/${comCode}/saleorder/ar`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getARReportList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/report`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getARReportExport = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/reportexport`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getARVoucherPaid = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/voucherforpaid`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getARReportListNew = async (payload) => {
  try {
    // const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/report`, payload);
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/ar_report`, payload);
    // รอปรับ
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getARReportExportNew = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/ar-report-excel`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
}



export {
  getSummaryAccount,
  getSaleOrderTOARList,
  getARDetail,
  getSaveAR,
  uploadARAttachment,
  getARPaidDetail,
  uploadARAttachmentPaid,
  updateARStatus,
  getARReportList,
  getARReportExport,
  getARVoucherPaid,
  getSaleOderDetail,
  getSaleOrderPendingList,
  getSaveNewAR,
  getARReportListNew,
  getARReportExportNew,
};
