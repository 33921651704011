import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Input, Icon, Menu, Dropdown, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import WarehouseContext from '../context';
import WarehouseList from './warehouse-list';
import Button_01 from '../../../components/v2/button_01';
import { getWareHouseList, warehouseListExport } from '../../../controllers/warehouse/warehouse';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';

const CardWarehouse = ({}) => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const {
    warehouse,
    warehousePage,
    warehousePageLoading,
    warehousePageHasMore,
    searchWarehouse,
    warehouseId,
    trigger,
    warehouseDataFormResponse,
    roleCheck,
    event,
    key,
    eventDetail,
    eventWarhouse,
  } = state;
  const {
    setWarehouse,
    setWarehousePage,
    setWarehousePageLoading,
    setSearchWarehouse,
    setWarehousePageHasMore,
    setWarehouseId,
    setWarehouseIsOpen,
    setWarehouseTypeList,
    setWarehouseManageTypeList,
  } = setState;
  const {} = fnc;

  let limit = 10;
  const [allItem, setAllitem] = useState(10);

  console.log('warehouse', warehouse);
  useEffect(() => {
    if (warehouseDataFormResponse) {
      let newArr = [];
      newArr.push(warehouseDataFormResponse, ...warehouse);
      setWarehouse(_.uniqBy(newArr, 'warehouseId'));
    }
  }, [warehouseDataFormResponse]);

  useEffect(() => {
    const setDataUpdate = async () => {
      if (key === 'stock') {
        const payload = {
          indexStart: 0,
          indexEnd: warehousePage * limit,
          searchLeftMenu: searchWarehouse,
        };
        const loadmoreWarehouse = await getWareHouseList(payload);

        if (_.size(warehouse) || warehousePage === 1) {
          console.log('TestWarhouse', _.size(warehouse));
          setWarehouse(_.get(loadmoreWarehouse.data.data, 'warehouseList'));
          setAllitem(_.get(loadmoreWarehouse.data.data, 'allItem'));
          setWarehouseTypeList(_.get(loadmoreWarehouse.data.data, 'warehouseTypeList'));
          setWarehouseManageTypeList(_.get(loadmoreWarehouse.data.data, 'manageTypeList'));
          setWarehousePageLoading(true);
        } else {
          setWarehousePageHasMore(false);
        }
      }
    };

    setDataUpdate();
  }, [event]);

  useEffect(() => {
    const setDataUpdate = async () => {
      if (key === 'details') {
        const payload = {
          indexStart: 0,
          indexEnd: warehousePage * limit,
          searchLeftMenu: searchWarehouse,
        };
        const loadmoreWarehouse = await getWareHouseList(payload);

        if (_.size(warehouse) || warehousePage === 1) {
          console.log('TestWarhouse', _.size(warehouse));
          setWarehouse(_.get(loadmoreWarehouse.data.data, 'warehouseList'));
          setAllitem(_.get(loadmoreWarehouse.data.data, 'allItem'));
          setWarehouseTypeList(_.get(loadmoreWarehouse.data.data, 'warehouseTypeList'));
          setWarehouseManageTypeList(_.get(loadmoreWarehouse.data.data, 'manageTypeList'));
          setWarehousePageLoading(true);
        } else {
          setWarehousePageHasMore(false);
        }
      } else {
        const payload = {
          indexStart: 0,
          indexEnd: warehousePage * limit,
          searchLeftMenu: searchWarehouse,
        };
        const loadmoreWarehouse = await getWareHouseList(payload);

        if (_.size(warehouse) || warehousePage === 1) {
          console.log('TestWarhouse', _.size(warehouse));
          setWarehouse(_.get(loadmoreWarehouse.data.data, 'warehouseList'));
          setAllitem(_.get(loadmoreWarehouse.data.data, 'allItem'))
          setWarehouseTypeList(_.get(loadmoreWarehouse.data.data, 'warehouseTypeList'));
          setWarehouseManageTypeList(_.get(loadmoreWarehouse.data.data, 'manageTypeList'));
          setWarehousePageLoading(true);
        } else {
          setWarehousePageHasMore(false);
        }
      }
    };
    console.log('evenWarhouse',eventWarhouse);
    
    setDataUpdate();
  }, [eventDetail,eventWarhouse]);

  useEffect(() => {
    const setData = async () => {
      if (warehousePage >= 1) {
        const payload = {
          indexStart: (warehousePage - 1) * limit + 1 - 1,
          indexEnd: warehousePage * limit,
          searchLeftMenu: searchWarehouse,
        };
        const loadmoreWarehouse = await getWareHouseList(payload);

        console.log('loadmoreWarehouse', loadmoreWarehouse);
        if (_.size(warehouse) || warehousePage === 1) {
          console.log('TestWarhouse', _.size(warehouse));
          setWarehouse(_.concat(warehouse, _.get(loadmoreWarehouse.data.data, 'warehouseList')));
          setAllitem(_.get(loadmoreWarehouse.data.data, 'allItem'));
          setWarehouseTypeList(_.get(loadmoreWarehouse.data.data, 'warehouseTypeList'));
          setWarehouseManageTypeList(_.get(loadmoreWarehouse.data.data, 'manageTypeList'));
          setWarehousePageLoading(true);
        } else {
          setWarehousePageHasMore(false);
        }
      }
    };

    setData();
  }, [warehousePage, searchWarehouse]);

  const handleChangeWareHousePage = (page) => {
    if (warehousePageLoading && warehousePageHasMore) {
      setWarehousePage(warehousePage + 1);
      setWarehousePageLoading(false);
    }
  };

  const handleSelectWareHouse = (id) => {
    console.log('handleSelectWareHouse', id);
    setWarehouseId(id);
    // if (id !== warehouseId) {
    //   if (warehouseId !== undefined) {
    //     var cls = document.getElementById(warehouseId);
    //     cls.classList.remove('warehouse-item-active');
    //   }
    //   var cls = document.getElementById(id);
    //   cls.classList.add('warehouse-item-active');
    // }
  };

  const handleSearchWareHouse = _.debounce((text) => {
    setWarehouse([]);
    setWarehousePage(1);
    setSearchWarehouse(text ? text : '');
    setWarehousePageHasMore(true);
    setWarehousePageLoading(false);
  }, 500);

  const handleExport = async () => {
    try {
      const body = {
        viewFormat: [
          {
            colName: 'Warehouse Code',
            sequence: 1,
            colCode: 'warehouseCode',
          },
          {
            colName: 'Warehouse Name',
            sequence: 2,
            colCode: 'warehouseName',
          },
          {
            colName: 'Phone',
            sequence: 3,
            colCode: 'warehousePhone',
          },
          {
            colName: 'Type',
            sequence: 4,
            colCode: 'warehouseTypeDisplay',
          },
          {
            colName: 'Gross Profit Margin',
            sequence: 5,
            colCode: 'grossProfitMargin',
          },
          {
            colName: 'Address',
            sequence: 6,
            colCode: 'address',
          },
          {
            colName: 'Remark',
            sequence: 7,
            colCode: 'remark',
          },
        ],
      };
      const response = await warehouseListExport(body, 0, allItem, searchWarehouse);

      console.log('ExportWareHouse', response);

      window.open(response.data.data.url);
      successNotification(response.status.message);
    } catch (error) {
      errorNotification(error.response.status.message);
    }
  };

  const menuColumn = () => {
    return (
      <Menu>
        {checkPermissionAction(`P52PG1C1`, `P52PG1C1A2`) ? (
          <Menu.Item key="1" onClick={() => setWarehouseIsOpen(true)}>
            <Button key="1" ghost type="link">
              <FormattedMessage id="orgTreeBtnCreate" defaultMessage="Create" />
            </Button>
          </Menu.Item>
        ) : null}
        {checkPermissionAction(`P52PG1C1`, `P52PG1C1A7`) ? (
        <Menu.Item key="2" onClick={() => handleExport()}>
          <Button key="2" ghost type="link">
            <FormattedMessage id="btnExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item>
      ) : null}
      </Menu>
    );
  };

  return (
    <Card
      title={intl.formatMessage({ id: `warehouseTitle`, defaultMessage: 'Warehouse' })}
      className="left-card-asset"
      extra={
        checkPermissionAction(`P52PG1C1`, `P52PG1C1A2`) || checkPermissionAction(`P52PG1C1`, `P52PG1C1A7`) ? (
        <Dropdown
          overlay={menuColumn}
          trigger={['click']}
          // onVisibleChange={setVisible}
          // visible={visible}
          placement="bottomRight"
          className="ant-dropdown-custom"
        >
          <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }} onClick={() => {}}>
            <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
            <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
          </Button_01>
        </Dropdown>
      ) : null
      }

    >
      <div className="div-content-asset">
        <div className="div-content-asset-select" style={{ padding: '0px 24px' }}>
          <Input
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
            autoComplete="off"
            onChange={(event) => handleSearchWareHouse(event.target.value)}
          />
        </div>
        <div className="render-list-warehouse scroll-sm">
          <WarehouseList
            warehouse={warehouse}
            onLoadMore={handleChangeWareHousePage}
            warehousePageLoading={warehousePageLoading}
            warehousePageHasMore={warehousePageHasMore}
            handleSelectWareHouse={handleSelectWareHouse}
          />
        </div>
      </div>
    </Card>
  );
};

export default CardWarehouse;
