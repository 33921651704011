import React from 'react';
import NumberFormat from 'react-number-format';

const numberFormatDecimal = (value) => {
  return <NumberFormat value={parseFloat(value).toFixed(2)} displayType={'text'} thousandSeparator={true} />;
};

const numberFormatNoDecimal = (value) => {
  return <NumberFormat value={parseFloat(value)} displayType={'text'} thousandSeparator={true} />;
};

export { numberFormatDecimal, numberFormatNoDecimal };
