import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Form,
  Row,
  Col,
  Popover,
  Button,
  Icon,
  Input,
  TimePicker,
  Select,
  Avatar,
  Modal,
  TreeSelect,
  ConfigProvider,
  InputNumber,
} from 'antd';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import { ShiftSettings } from '../config/shift-type-settings';
import httpClient from '../../../components/axiosClient';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { metaProperty } from 'babel-types';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import { useAppContext } from '../../../includes/indexProvider';
import {} from '../css/shift.css';
import getAllTeam from '../../../controllers/get-all-team/get-all-team';

const { Option } = Select;
const { TextArea } = Input;
const { TreeNode } = TreeSelect;

let sumHour = 0;
let statusTimePeriod = false;

const LabelRequire = styled.label`
  color: #ff1010;
`;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const LabeRequireTime = (props) => {
  const { text, req } = props;
  return (
    <span>
      <span style={{ fontSize: '13px', color: '#000000' }}>{text}</span>&nbsp;
      {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

export default React.memo(
  Form.create({
    name: 'shift-type-form',
    mapPropsToFields(props) {
      const { record } = props;

      const icon = record ? record.icon_code.split('/') : [];
      let type = '';
      if (record) {
        if (record.shift_types) {
          if (record.shift_types.length > 0) {
            type = record.shift_types[0].shift_types_id;
          }
        }
      }

      return {
        shiftId: Form.createFormField({
          value: record ? record.shift_id : '',
        }),
        icon: Form.createFormField({
          value: icon.length > 0 ? icon[icon.length - 1] : '',
        }),
        iconCode: Form.createFormField({
          value: icon.length > 0 ? record.icon_code : '',
        }),
        name: Form.createFormField({
          value: record ? record.name : '',
        }),
        color: Form.createFormField({
          value: record ? record.color : '',
        }),
        type: Form.createFormField({
          value: record ? type : undefined,
        }),
        timeSpanList: Form.createFormField({
          value: [
            {
              start_time: null,
              end_time: null,
            },
          ],
        }),
        description: Form.createFormField({
          value: record ? record.description : '',
        }),
      };
    },
  })((props) => {
    const intl = useIntl();
    const comId = localStorage.getItem('comId');
    const memComId = localStorage.getItem('memComId');
    const { setRecord } = props;
    const { state, fnc } = useContext(ShiftSettings);
    const {
      getFieldDecorator,
      getFieldValue,
      getFieldsValue,
      validateFields,
      setFieldsValue,
      setFields,
      resetFields,
      getFieldError,
      isFieldTouched,
    } = props.form;

    const [icon, setIcon] = useState();
    const [color, setColor] = useState();
    const [btnIconStatus, setBtnIconStatus] = useState('#E5E5E5');
    const [btnColorStatus, setBtnColorStatus] = useState('#E5E5E5');

    useEffect(() => {
      const shiftId = getFieldValue('shiftId');
      if (shiftId) {
        const icon = getFieldValue('iconCode');
        const color = getFieldValue('color');
        setIcon(icon);
        setColor(color);
      }

      if (!state.visibleAdd) {
        setIcon();
        setColor();
        setBtnIconStatus('#E5E5E5');
        setBtnColorStatus('#E5E5E5');
      }
      changeLocale();
    }, [state]);

    const app = useAppContext();
    const langValue = app.state.langValue;
    const [locale, setLocale] = useState(enUS);

    const langValueState = _.get(app, 'state.langValue');
    const format = langValueState == 'EN' ? 'h:mm A' : 'HH:mm';
    // const format = langValueState == 'EN' ? 'HH:mm' : 'HH:mm';

    moment.locale(langValueState);

    const changeLocale = () => {
      // let codeLang = langValue ? langValue : 'EN';
      let changeLang = langValueState === 'EN' ? enUS : thTH;
      setLocale(changeLang);
      // if (!changeLang) {
      //   moment.locale('en');
      // } else {
      //   moment.locale('th-th');
      // }
      if (changeLang == 'EN') {
        moment.locale('en');
      } else if (changeLang == 'TH') {
        moment.locale('th-th');
      }
    };

    const handleChangeIcon = (url, fileHash) => {
      setIcon(url);
      setFieldsValue({ icon: fileHash });
      setBtnIconStatus('#E5E5E5');
    };

    const handleChangeColor = (code) => {
      setColor(code);
      setFieldsValue({ color: code });
      setBtnColorStatus('#E5E5E5');
    };

    const handleAddTimeSpan = (index) => {
      let formValue = { ...getFieldsValue() };
      formValue['timeSpanList'] = [
        ...formValue['timeSpanList'],
        {
          start_time: null,
          end_time: null,
        },
      ];
      setFieldsValue(formValue);
    };

    const handleDeleteTimeSpan = (index) => {
      let formValue = { ...getFieldsValue() };
      if (!(formValue['timeSpanList'] && formValue['timeSpanList'].length)) return;
      formValue['timeSpanList'].splice(index, 1);
      setFieldsValue(formValue);
    };

    const IconPopover = () => {
      return (
        <div style={{ width: 145, height: 100, padding: 5 }}>
          <ShiftSettings.Consumer>
            {({ state }) =>
              state.shiftTypesIcon.map &&
              state.shiftTypesIcon.map((o, i) => (
                <img
                  key={`icon-pop-${i}`}
                  src={o.filePath}
                  style={{
                    width: 25,
                    margin: 10,
                    float: 'left',
                  }}
                  onClick={() => handleChangeIcon(o.filePath, o.fileHash)}
                />
              ))
            }
          </ShiftSettings.Consumer>
        </div>
      );
    };

    const ColorPopover = () => {
      return (
        <div style={{ width: 238, height: 180, padding: 5 }}>
          <ShiftSettings.Consumer>
            {({ state }) => (
              <div>
                <div style={{ color: '1D3557', fontSize: '12px' }}>
                  <FormattedMessage id="settingShiftTypePopoverColourText" defaultMessage="Please select a color" />
                </div>
                {state.shiftTypesColor.map &&
                  state.shiftTypesColor.map((v, i) => (
                    <Avatar
                      key={`color-pop-${i}`}
                      size={32}
                      onClick={() => handleChangeColor(v)}
                      style={{
                        backgroundColor: v,
                        verticalAlign: 'middle',
                        margin: 3,
                      }}
                      shape="square"
                    >
                      {color === v ? <Icon type="check" /> : null}
                    </Avatar>
                  ))}
              </div>
            )}
          </ShiftSettings.Consumer>
        </div>
      );
    };

    const handleSubmit = async (e) => {
      validateStatusIconAndName();
      validateStatusColorAndType();

      e.preventDefault();
      validateFields(async (err, values) => {
        const shiftId = getFieldValue('shiftId');
        if (err) {
          if (err.icon) {
            setBtnIconStatus('#f5222d');
          }
          if (err.color) {
            setBtnColorStatus('#f5222d');
          }
          return false;
        }

        const momentTime = values.timePeriod.filter((item) => {
          return item.start_time !== null;
        });

        const times =
          momentTime &&
          momentTime.map((o) => {
            return {
              startTime: moment(o.start_time._d)
                .locale('en')
                .format('h:mm A'),
              endTime: moment(o.end_time._d)
                .locale('en')
                .format('h:mm A'),
            };
          });

        const filterAll = values.team.filter((item) => {
          return item === 'All';
        });

        const calcTime =
          momentTime &&
          momentTime.map((item) => {
            if (item.start_time > item.end_time) {
              const fineMinute =
                (moment(_.get(item, 'end_time'))
                  .add(1, 'day')
                  .unix() -
                  moment(_.get(item, 'start_time')).unix()) /
                60;
              return fineMinute;
            } else {
              const fineMinute = (moment(_.get(item, 'end_time')).unix() - moment(_.get(item, 'start_time')).unix()) / 60;
              return fineMinute;
            }
          });

        if (_.size(momentTime) >= 2) {
          const unix =
            momentTime &&
            momentTime.map((item) => {
              return {
                start_time: moment(item.start_time).unix(),
                end_time: moment(item.end_time).unix(),
              };
            });
          for (let i = 0; i < _.size(unix); i++) {
            let ii = 0;
            if (_.get(unix, `[${i}].end_time`) && _.get(unix, `[${i}].start_time`))
              for (let t of unix) {
                if (i !== ii) {
                  let caseEndTimeInOldTimePeriod = unix[i].end_time <= t.end_time && t.start_time <= unix[i].end_time;
                  let caseStartTimeInOldTimePeriod = unix[i].start_time <= t.end_time && t.start_time <= unix[i].start_time;
                  let caseOldTimePeriodIsInTime = unix[i].start_time <= t.start_time && t.end_time <= unix[i].end_time;

                  if (caseStartTimeInOldTimePeriod || caseEndTimeInOldTimePeriod || caseOldTimePeriodIsInTime) {
                    // console.log('caseStartTimeInOldTimePeriod true')
                    statusTimePeriod = true;
                    // return statusTimePeriod = true
                  } else {
                    // console.log('caseStartTimeInOldTimePeriod false')
                    // return statusTimePeriod = false
                    statusTimePeriod = false;
                  }
                  ii += 1;
                }
              }
          }
        } else {
          statusTimePeriod = false;
        }

        const sumValue = _.sum(calcTime);
        let data = [];
        loopObject(teamData, data);
        const intersection = data.filter((element) => values.team.includes(element.value));
        const mapTeam =
          intersection &&
          intersection.map((item) => {
            return {
              orgId: item.value,
              orgName: item.title,
            };
          });
        // console.log('diffTeam ::', intersection, data, values.team)

        if (values.icon === '' || values.color === '' || statusTimePeriod === true) {
          return;
        } else {
          const body = {
            name: values.name,
            description: values.description,
            start: '08:00:00',
            finish: '17:00:00',
            codeRef: '',
            iconCode: values.icon,
            color: values.color,
            shiftType: memComId,
            shiftTypesId: values.type,
            createdBy: memComId,
            createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            timePeriod: times,
            lateMinute: parseInt(values.lateTime),
            workMinute: sumValue,
            statusAllTeam: _.size(filterAll) !== 0 ? true : false,
            teamList: _.size(filterAll) !== 0 ? [] : mapTeam,
          };

          try {
            const result = await httpClient.post(`/resource/manager/company/${comId}/shift-master`, body);

            if (result.status === 200) {
              fnc.successNotification(result.data.message);
              resetFields();
              setIcon();
              setColor();
              setBtnIconStatus('#E5E5E5');
              setBtnColorStatus('#E5E5E5');
              setRecord();
              fnc.refreshDataServices();
              fnc.setVisibleAdd(false);
              sumHour = 0;
              statusTimePeriod = false;
            } else {
              fnc.failedNotification(result.data.message);
            }
          } catch (error) {
            if (error.response.status === 400) {
              fnc.failedNotification(error.response.data.message);
            }
          }
        }
      });
    };

    const loopObject = (list, data) => {
      for (let index = 0; index < list.length; index++) {
        const object = list[index];
        if (object.children) {
          const dataReturn = loopObject(object.children, data);
          data.concat(dataReturn);
        }
        data.push({
          title: object.title,
          value: object.value,
        });
      }
      return data;
    };

    const handleCancelModal = () => {
      setStatusIcon();
      setsStatusName();
      setStatusColor();
      setsStatusType();
      setFields({
        startTimeNew: {
          error: 'success',
        },
        endTimeNew: {
          error: 'success',
        },
      });
      resetFields();
      setIcon();
      setColor();
      setBtnIconStatus('#E5E5E5');
      setBtnColorStatus('#E5E5E5');
      setRecord();
      fnc.setVisibleAdd(false);
      sumHour = 0;
      statusTimePeriod = false;
    };

    getFieldDecorator('timeSpanList', {
      initialValue: [
        {
          start_time: null,
          end_time: null,
        },
      ],
    });
    const timeSpanList = getFieldValue('timeSpanList');
    const timeSpanLength = timeSpanList.length;

    const formItemsStartTime =
      timeSpanList?.length &&
      timeSpanList.map((o, i) => {
        const disabledAdd = () => {
          if (getFieldValue(`timePeriod[${i}].start_time`) && getFieldValue(`timePeriod[${i}].end_time`)) {
            return false;
          } else {
            return true;
          }
        };
        const timePeriodStartTime = getFieldValue(`timePeriod[${i}].start_time`);
        const timePeriodEndTime = getFieldValue(`timePeriod[${i}].end_time`);
        const timeAll = getFieldValue(`timePeriod`);

        if (
          timePeriodStartTime !== undefined &&
          timePeriodStartTime !== null &&
          timePeriodEndTime !== undefined &&
          timePeriodEndTime !== null
        ) {
          const calcTime =
            timeAll &&
            timeAll.map((item) => {
              if (item.start_time && item.end_time) {
                if (item.start_time > item.end_time) {
                  const fineMinute =
                    (_.get(item, 'end_time')
                      .add(1, 'day')
                      .unix() -
                      _.get(item, 'start_time').unix()) /
                    60;
                  if (Number.isNaN(fineMinute) === false) {
                    return fineMinute;
                  }
                } else {
                  const fineMinute = (moment(_.get(item, 'end_time')).unix() - moment(_.get(item, 'start_time')).unix()) / 60;
                  if (Number.isNaN(fineMinute) === false) {
                    return fineMinute;
                  }
                }
              }
            });
          const filter =
            calcTime &&
            calcTime.filter((item) => {
              return item !== undefined;
            });
          const sumValue = _.sum(filter);
          const findHour = _.floor(sumValue / 60);
          const findMinute = Math.floor(sumValue % 60);
          sumHour = findMinute < 10 ? `${findHour}:0${findMinute}` : `${findHour}:${findMinute}`;
        }

        return (
          <Row key={`row-item-start-time-${i}`} gutter={[8, 8]} align="middle">
            <Col span={10}>
              <Form.Item>
                {getFieldDecorator(`timePeriod[${i}].start_time`, {
                  initialValue: o.start_time !== null ? moment(o.start_time, format) : null,
                  ...(getFieldValue(`timePeriod[${i}].end_time`) || i !== timeSpanList.length - 1 || timeSpanList.length == 1
                    ? {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'settingShiftTypeValidateStartTime',
                              defaultMessage: 'Please select starting time.',
                            }),
                          },
                        ],
                      }
                    : {}),
                })(
                  // <ConfigProvider locale={locale}>
                  <TimePicker
                    id="error"
                    use12Hours
                    defaultOpenValue={moment()}
                    allowClear={false}
                    format={format}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({
                      id: 'settingShiftTypeHintStartTime',
                      defaultMessage: 'Please select starting time',
                    })}
                  />
                  // </ConfigProvider>
                )}
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item>
                {getFieldDecorator(`timePeriod[${i}].end_time`, {
                  initialValue: o.end_time !== null ? moment(o.end_time, format) : null,
                  ...(getFieldValue(`timePeriod[${i}].start_time`) || i !== timeSpanList.length - 1 || timeSpanList.length == 1
                    ? {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'settingShiftTypeValidateEndTime',
                              defaultMessage: 'Please select ending time.',
                            }),
                          },
                        ],
                      }
                    : {}),
                })(
                  // <ConfigProvider locale={locale}>
                  <TimePicker
                    use12Hours
                    defaultOpenValue={moment()}
                    format={format}
                    allowClear={false}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({
                      id: 'settingShiftTypeHintEndTime',
                      defaultMessage: 'Please select ending time',
                    })}
                  />
                  // </ConfigProvider>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <ConfigProvider locale={locale}>
                {i === timeSpanLength - 1 ? (
                  <Button style={{ width: '100%', padding: 5 }} onClick={() => handleAddTimeSpan(i)} disabled={disabledAdd()}>
                    <FormattedMessage id="btnAdd" defaultMessage="Add" />
                  </Button>
                ) : (
                  <Button style={{ width: '100%', padding: 5 }} onClick={() => handleDeleteTimeSpan(i)}>
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </Button>
                )}
              </ConfigProvider>
            </Col>
          </Row>
        );
      });

    const [statusIcon, setStatusIcon] = useState();
    const [statusName, setsStatusName] = useState();
    const [statusColor, setStatusColor] = useState();
    const [statusType, setsStatusType] = useState();
    const nameStatus = getFieldValue(`name`);
    const iconStatus = getFieldValue(`icon`);
    const colorStatus = getFieldValue(`color`);
    const typeStatus = getFieldValue(`type`);
    const [teamData, setTeamData] = useState([]);

    useEffect(() => {
      const getTeam = async () => {
        const respons = await getAllTeam();
        // console.log('respons ::', respons)
        const AllTeam = [
          {
            children: [],
            parent: '',
            title: 'All Team',
            value: 'All',
          },
        ];
        const concat = AllTeam.concat(respons);
        setTeamData(concat);
      };
      getTeam();
    }, []);

    const renderTreeNodes = (data) =>
      data.map((item) => {
        if (item.children) {
          const teamSelect = getFieldValue('team');
          if (_.size(teamSelect) !== 0) {
            const filterData =
              teamSelect &&
              teamSelect.filter((item) => {
                return item === 'All';
              });
            if (_.size(filterData) !== 0) {
              item.disabled = item.value === 'All' ? false : true;
            } else {
              item.disabled = item.value === 'All' ? true : false;
            }
          } else {
            item.disabled = false;
          }
          return (
            <TreeNode key={item.value} title={item.title} value={item.value} disabled={item.disabled}>
              {renderTreeNodes(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode {...item} key={item.value} title={item.title} value={item.value} />;
      });

    useEffect(() => {
      if (iconStatus && nameStatus) {
        setStatusIcon();
        setsStatusName();
      } else if (iconStatus) {
        setStatusIcon();
      } else if (nameStatus) {
        setsStatusName();
      }

      if (colorStatus && typeStatus) {
        setStatusColor();
        setsStatusType();
      } else if (colorStatus) {
        setStatusColor();
      } else if (typeStatus) {
        setsStatusType();
      }
    }, [nameStatus, iconStatus, colorStatus, typeStatus]);

    const validateStatusIconAndName = () => {
      if (!nameStatus && !iconStatus) {
        setsStatusName('error');
        setStatusIcon('error');
        setBtnIconStatus('#f5222d');
      } else if (!nameStatus) {
        setsStatusName('error');
        setStatusIcon();
        setBtnIconStatus('#E5E5E5');
      } else if (!iconStatus) {
        setsStatusName();
        setStatusIcon('error');
        setBtnIconStatus('#f5222d');
      } else {
        setStatusIcon();
        setsStatusName();
        setBtnIconStatus('#E5E5E5');
      }
    };

    const validateStatusColorAndType = () => {
      if (!colorStatus && !typeStatus) {
        setStatusColor('error');
        setsStatusType('error');
        setBtnColorStatus('#f5222d');
      } else if (!colorStatus) {
        setStatusColor('error');
        setsStatusType();
        setBtnColorStatus('#f5222d');
      } else if (!typeStatus) {
        setStatusColor();
        setsStatusType('error');
        setBtnColorStatus('#E5E5E5');
      } else {
        setStatusColor();
        setsStatusType();
        setBtnColorStatus('#E5E5E5');
      }
    };

    // console.log('sumHour ::', sumHour)

    const handleKeyDownNumberDecimal = (e) => {
      let keyCode = e.key.charCodeAt(0);

      if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
        if (keyCode === 46 && e.target.value.indexOf('.') > 0) {
          e.preventDefault();
        } else {
          return true;
        }
      } else {
        e.preventDefault();
      }
    };

    return (
      <ShiftSettings.Consumer>
        {({ state, fnc }) => (
          <Modal
            className="shift-type-modal"
            centered
            width={600}
            bodyStyle={{ padding: '24px 24px 10px 24px' }}
            visible={state.visibleAdd}
            title={intl.formatMessage({
              id: 'settingShiftTypeTitle',
              defaultMessage: 'Shift Type',
            })}
            onCancel={handleCancelModal}
            onOk={handleSubmit}
            footer={[
              <Button_02 key="back" btnsize="wd_df" onClick={handleCancelModal}>
                <FormattedMessage id="modalBtnCancel" defaultMessage="Cancel" />
              </Button_02>,
              <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleSubmit} style={{ margin: 'unset' }}>
                <FormattedMessage id="modalBtnSave" defaultMessage="Save" />
              </Button_01>,
            ]}
          >
            <Form className="shift-type-form">
              <Row gutter={[8, 8]} align="middle">
                <div>
                  <Col span={3}>
                    {/* <Col span={5}> */}

                    <Form.Item
                      className="shift-type-form-setting"
                      style={{ paddingBottom: 'unset' }}
                      validateStatus={statusIcon}
                      label={
                        <LabeRequire
                          text={intl.formatMessage({
                            id: 'settingShiftTypeLblIcon',
                            defaultMessage: 'Icon',
                          })}
                          req={true}
                        />
                      }
                    >
                      <Popover trigger="click" placement="bottomLeft" content={<IconPopover />}>
                        <Button
                          size="large"
                          style={{
                            width: '95%',
                            height: '32px',
                            marginTop: 0,
                            borderColor: btnIconStatus,
                            // borderColor: btnIconStatus,
                          }}
                        >
                          {icon ? <img src={icon} style={{ width: 20 }} /> : <Icon type="plus" />}
                        </Button>
                      </Popover>
                      {getFieldDecorator('icon', {
                        // rules: [
                        //   {
                        //     required: true,
                        //     message: intl.formatMessage({
                        //       id: 'settingShiftTypeValidateIcon',
                        //       defaultMessage: 'Please select icon.',
                        //     }),
                        //   },
                        // ],
                      })(<Input type="hidden" autoComplete="off" />)}
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      className="shift-type-form-setting shift-type-form-setting2"
                      validateStatus={statusColor}
                      label={
                        <LabeRequire
                          text={intl.formatMessage({
                            id: 'settingShiftTypeLblColor',
                            defaultMessage: 'Color',
                          })}
                          req={true}
                        />
                      }
                    >
                      <Popover trigger="click" placement="bottomLeft" content={<ColorPopover />}>
                        <div
                          style={{
                            padding: '3px',
                            height: '33px',
                            border: '1px solid #E5E5E5',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {!color ? (
                            <Icon type="plus" className="shift-hover-icon-pluse" />
                          ) : (
                            <Button
                              // shape="circle"
                              style={{
                                width: '100%',
                                height: '25px',
                                marginTop: 0,
                                backgroundColor: color,
                                borderColor: 'unset',
                                border: 'unset',
                              }}
                            ></Button>
                          )}
                        </div>
                      </Popover>
                      {getFieldDecorator('color', {
                        // rules: [
                        //   {
                        //     required: true,
                        //     message: intl.formatMessage({
                        //       id: 'settingShiftTypeValidateColor',
                        //       defaultMessage: 'Please select color.',
                        //     }),
                        //   },
                        // ],
                      })(<Input type="hidden" autoComplete="off" />)}
                    </Form.Item>
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      className="shift-type-form-setting"
                      validateStatus={statusName}
                      label={
                        <LabeRequire
                          text={intl.formatMessage({
                            id: 'settingShiftTypeLblName',
                            defaultMessage: 'Name',
                          })}
                          req={true}
                        />
                      }
                    >
                      {getFieldDecorator('name', {
                        // rules: [
                        //   {
                        //     required: true,
                        //     message: intl.formatMessage({
                        //       id: 'settingShiftTypeValidateShiftName',
                        //       defaultMessage: 'Please enter shift type name.',
                        //     }),
                        //   },
                        //   // {
                        //   //   validator:validatorIcon,
                        //   // }
                        // ],
                      })(
                        <Input
                          placeholder={intl.formatMessage({
                            id: 'settingShiftTypeHintName',
                            defaultMessage: 'Please enter shift type name',
                          })}
                          autoComplete="off"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </div>
              </Row>
              {statusIcon == 'error' && statusName == 'error' && statusColor == 'error' ? (
                <p className="validation-css">
                  <FormattedMessage
                    id="settingShiftTypeValidateIconAndColorAndShiftName"
                    defaultMessage="Please select icon and color and enter shift type name."
                  />
                </p>
              ) : null || (statusIcon == 'error' && statusName == 'error') ? (
                <p className="validation-css">
                  <FormattedMessage
                    id="settingShiftTypeValidateIconAndShiftName"
                    defaultMessage="Please select icon and enter shift type name."
                  />
                </p>
              ) : null || (statusColor == 'error' && statusName == 'error') ? (
                <p className="validation-css">
                  <FormattedMessage
                    id="settingShiftTypeValidateColorAndShiftName"
                    defaultMessage="Please select color and enter shift type name."
                  />
                </p>
              ) : null || (statusColor == 'error' && statusIcon == 'error') ? (
                <p className="validation-css">
                  <FormattedMessage id="settingShiftTypeValidateColorAndIcon" defaultMessage="Please select icon and color." />
                </p>
              ) : null || statusIcon == 'error' ? (
                <p className="validation-css">
                  <FormattedMessage id="settingShiftTypeValidateIcon" defaultMessage="Please select icon." />
                </p>
              ) : null || statusName == 'error' ? (
                <p className="validation-css">
                  <FormattedMessage id="settingShiftTypeValidateShiftName" defaultMessage="Please enter shift type name." />
                </p>
              ) : null || statusColor == 'error' ? (
                <p className="validation-css">
                  <FormattedMessage id="settingShiftTypeValidateColor" defaultMessage="Please select color." />
                </p>
              ) : null}
              <Row gutter={[8, 8]} align="middle">
                <Col span={10}>
                  <LabeRequireTime
                    text={intl.formatMessage({ id: 'settingShiftTypeLblStartTime', defaultMessage: 'Start Time' })}
                    req={true}
                  />
                </Col>
                <Col span={14}>
                  <LabeRequireTime text={intl.formatMessage({ id: 'settingShiftTypeLblEndTime', defaultMessage: 'End Time' })} req={true} />
                </Col>
              </Row>
              {formItemsStartTime}
              <Row>
                <Col span={24}>
                  {statusTimePeriod ? (
                    <p className="validation-css">
                      <FormattedMessage id="settingShiftTypeValidateOverLap" defaultMessage="The selected time ranges must not overlap" />
                    </p>
                  ) : null}
                </Col>
              </Row>
              <Row gutter={[8, 8]} align="middle">
                <Col span={10}>
                  <Form.Item
                    className="shift-type-form-setting"
                    label={<LabeRequire text={intl.formatMessage({ id: 'settingShiftTypeLblType', defaultMessage: 'Type' })} req={true} />}
                  >
                    {getFieldDecorator('type', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'settingShiftTypeValidateType',
                            defaultMessage: 'Please enter late time.',
                          }),
                        },
                      ],
                    })(
                      <Select
                        placeholder={intl.formatMessage({
                          id: 'settingShiftTypeHintType',
                          defaultMessage: 'Please select type',
                        })}
                        style={{ width: '100%' }}
                      >
                        {state.shiftTypes &&
                          state.shiftTypes.map((o, i) => (
                            <Option key={`type-${i}`} value={o.shift_types_id}>
                              {o.shift_types_name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    className="shift-type-form-setting"
                    label={
                      <LabeRequire
                        text={intl.formatMessage({
                          id: 'settingShiftTypeLblLateTimeShow',
                          defaultMessage: 'Late Entry (Min)',
                        })}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator(`lateTime`, {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'settingShiftTypeValidateLatetime',
                            defaultMessage: 'Please enter late time.',
                          }),
                        },
                      ],
                    })(
                      <InputNumber
                        placeholder={intl.formatMessage({
                          id: 'settingShiftTypeLblLateTimeShow',
                          defaultMessage: 'Late Entry (Min)',
                        })}
                        className="shift-type-inputNumber"
                        min={0}
                        max={9999999999}
                        onKeyDown={handleKeyDownNumberDecimal}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    className="shift-type-form-setting"
                    label={
                      <LabeRequire
                        text={intl.formatMessage({
                          id: 'settingShiftTypeLblTotalWorkingHour',
                          defaultMessage: 'Total Working Hours',
                        })}
                        req={false}
                      />
                    }
                  >
                    <Input readOnly={true} value={sumHour} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]} align="middle">
                <Col span={24}>
                  <Form.Item
                    className="shift-type-form-setting"
                    label={
                      <LabeRequire
                        text={intl.formatMessage({
                          id: 'settingShiftTypeLblTeam',
                          defaultMessage: 'Team',
                        })}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('team', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'settingShiftTypeValidateTeam',
                            defaultMessage: 'Please select team.',
                          }),
                        },
                      ],
                    })(
                      <TreeSelect
                        className="shift-type-tree-select"
                        showSearch={true}
                        dropdownStyle={{ maxHeight: '400px', overflow: 'auto', width: '300px' }}
                        placeholder={intl.formatMessage({ id: 'lblSeleteHintSeleteTeam', defaultMessage: 'Please Select' })}
                        allowClear
                        multiple
                        treeNodeFilterProp="title"
                        filterTreeNode={(search, item) => {
                          return item.props.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                        }}
                        maxTagCount={3}
                      >
                        {renderTreeNodes(teamData)}
                      </TreeSelect>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]} align="middle">
                <Col span={24}>
                  <Form.Item
                    className="shift-type-form-setting"
                    label={intl.formatMessage({
                      id: 'settingShiftTypeLblDescription',
                      defaultMessage: 'Description',
                    })}
                  >
                    {getFieldDecorator('description', {})(<TextArea autoSize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </ShiftSettings.Consumer>
    );
  })
);
