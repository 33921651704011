import React from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import LabeRequireForm from '../../label-required-form';
import Button_02 from '../../v2/button_02';
import Button_01 from '../../v2/button_01';
import { createBrandData, createGroupData, createTypeData } from '../../../controllers/createBrandModel';
import { errorNotification, successNotification } from '../../v2/notification';

const { Option } = Select;
const { TextArea } = Input;

const CreateModelBrand = ({ form, title, visible, onOk, onCancel, setEvent, recordBrand, setRecordBrand, groupModalType }) => {
  const { getFieldDecorator, resetFields } = form;
  const intl = useIntl();

  console.log('recordBrand', recordBrand);

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('values', values);
      if (err) {
        return;
      }

      const payload = groupModalType === 'type' ? {
        itemTypeId:  _.get(recordBrand, 'itemTypeId') ? _.get(recordBrand, 'itemTypeId') : undefined,
        itemTypeName:  _.get(values, 'itemTypeName'),
        itemTypeNo: _.get(values, 'itemTypeNo'),
        itemTypeDescription: _.get(values, 'itemTypeDescription'),
        active: 'true',
      } : {
        itemGroupId:  _.get(recordBrand, 'itemGroupId') ? _.get(recordBrand, 'itemGroupId') : undefined,
        itemGroupName:  _.get(values, 'itemGroupName'),
        itemGroupNo: _.get(values, 'itemGroupNo'),
        itemGroupDescription: _.get(values, 'itemGroupDescription'),
        active: 'true',
      };

      try {
        const response =  groupModalType === 'type' ? await createTypeData(payload) : await createGroupData(payload);
        setRecordBrand(response.data);

        console.log('setRecordBrand', response, recordBrand);

        if (_.get(response, 'status.code') === 200) {
          successNotification(response.status.message);
          handleCancel();
          setEvent((prev) => !prev);
        } else {
          errorNotification(_.get(response, 'status.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.message);
      }

      // console.log('payload', payload);

      // onOk(payload);
    });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };

  const setReasonOption = (reason) => {
    return (
      <Option key={`${reason.reasonId}`} value={`${reason.reasonId}`}>
        {reason.titleCode}
      </Option>
    );
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={onCancel}
      width={500}
      centered={true}
      zIndex={1200}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      {groupModalType === 'type' ? (
        <Form name="status" colon={false}>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalCreateGroupTypeCode', defaultMessage: 'Type Code' })} req={true} />
                }
              >
                {getFieldDecorator('itemTypeNo', {
                  initialValue: _.get(recordBrand, 'itemTypeNo') ? _.get(recordBrand, 'itemTypeNo') : '',
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalCreateGroupTypeCodeValdiate', defaultMessage: 'Please Enter Type Code' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalCreateGroupTypeCodePlaceHolder', defaultMessage: 'Enter Type Code' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalCreateGroupTypeName', defaultMessage: 'Type Name' })} req={true} />
                }
              >
                {getFieldDecorator('itemTypeName', {
                  initialValue: _.get(recordBrand, 'itemTypeName') ? _.get(recordBrand, 'itemTypeName') : '',
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalCreateGroupTypepNameValdiate', defaultMessage: 'Please Enter Type Name' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalCreateGroupTypeNamePlaceHolder', defaultMessage: 'Enter Type Name' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalCreateGroupDescription', defaultMessage: 'Description' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('itemTypeDescription', {
                  initialValue: _.get(recordBrand, 'itemTypeDescription') ? _.get(recordBrand, 'itemTypeDescription') : '',
                })(
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    maxLength={250}
                    placeholder={intl.formatMessage({ id: 'modalCreateGroupDescriptionPlaceHolder', defaultMessage: 'Enter Description' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form name="status" colon={false}>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalCreateGroupGroupCode', defaultMessage: 'Group Code' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('itemGroupNo', {
                  initialValue: _.get(recordBrand, 'itemGroupNo') ? _.get(recordBrand, 'itemGroupNo') : '',
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalCreateGroupGroupCodeValidator', defaultMessage: 'Please Enter Group Code' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalCreateGroupGroupCodePlaceHolder', defaultMessage: 'Enter Group Code' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalCreateGroupGroupName', defaultMessage: 'Group Name' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('itemGroupName', {
                  initialValue: _.get(recordBrand, 'itemGroupName') ? _.get(recordBrand, 'itemGroupName') : '',
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalCreateGroupGroupNameValidator', defaultMessage: 'Please Enter Group Name' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalCreateGroupGroupNamePlaceHolder', defaultMessage: 'Enter Group Name' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalCreateGroupDescription', defaultMessage: 'Description' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('itemGroupDescription', {
                  initialValue: _.get(recordBrand, 'itemGroupDescription') ? _.get(recordBrand, 'itemGroupDescription') : '',
                })(
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    maxLength={250}
                    placeholder={intl.formatMessage({ id: 'modalCreateGroupDescriptionPlaceHolder', defaultMessage: 'Enter Description' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

CreateModelBrand.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCreateBrandForm = Form.create({ name: 'reject_Form' })(CreateModelBrand);

export default ModalCreateBrandForm;
