import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getQuotationList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/quaotation/listquotation`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const createQuotation = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/quaotation/createquotation`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;;
  }
};

const updateQuotation = async (payload) => {
  try {
    const response = await httpClient.put(
      `/v3/quotation/manager/company/${comCode}/quaotation/updatequotation/${payload.quotationId}`,
      payload
    );
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;;
  }
};

const updateStatusQuotation = async (payload) => {
  try {
    const response = await httpClient.put(
      `/v3/quotation/manager/company/${comCode}/quaotation/updatestatusquotation/${payload.id}`,
      payload
    );
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return;
    }
  } catch (error) {
    return error.response;
  }
};

const getQuotationById = async (id) => {
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/quaotation/getquotationdetail/${id}`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getIntialListQuotation = async (key) => {
  //reason,tax
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/${key}/getdata`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getMemberIssueBy = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/item/manager/${comCode}/getlistmemberloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const uploadAttachment = async (formData) => {
  try {
    const response = await httpClient.put(`/v3/quotation/manager/company/${comCode}/quaotation/uploadfile`, formData);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAttachment = async (id) => {
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/quaotation/attachment/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const finddataItemmasterbyId = async (id, payload) => {
  try {
    const response = await httpClient.post(`/v3/item/manager/${comCode}/finddataitemmasterbyid/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getQuotationReportById = async (id) => {
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/quaotation/getquotationpaymentdetail/${id}`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const GetSignaturePrint = async () => {
  const memComId = localStorage.getItem('memComId');
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v4/resource/manager/company/${comCode}/uploads/type/member-signature-image/member/${memComId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) { }
};

const GetSignaturePrintIssu = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v4/resource/manager/company/${comCode}/uploads/type/member-signature-image/member/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) { }
};

const getQuotationStatus = async (id) => {
  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/status/getdata?module=quotation`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getListItemQuotation = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/quaotation/itemmaster/search`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const deleteAttachmentQuotation = async (id) => {
  try {
    const response = await httpClient.delete(`/v3/quotation/manager/company/${comCode}/quaotation/removeattachment/${id}`)
    return response.data;
  } catch (error) {

  }
}

const getSummaryApproved = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/quaotation/summary`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const exportExcel = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/quaotation/listquotation/export`, payload);
    console.log('response Excel', response)
    return response.data;
  } catch (error) {
    console.log('error', error)
  }
}

const getItemCheckStock = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/instock/itemlistqty`, payload);
    console.log('response getItemCheckStock', response)
    return response.data;
  } catch (error) {
    console.log('error', error)
  }
}

const SaleOrderPDFDownloader = async (no, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/po/manager/company/${comCode}/sale-pdf/${no}`, payload, {
      responseType: 'blob'
    })
    if (response.status === 200) {
      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const QuotationPDFDownloader = async (no, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/po/manager/company/${comCode}/quotation-pdf/${no}`, payload, {
      responseType: 'blob'
    })
    if (response.status === 200) {
      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

export {
  getQuotationList,
  deleteAttachmentQuotation,
  getIntialListQuotation,
  getQuotationById,
  createQuotation,
  updateQuotation,
  getMemberIssueBy,
  updateStatusQuotation,
  uploadAttachment,
  finddataItemmasterbyId,
  getQuotationReportById,
  GetSignaturePrint,
  getQuotationStatus,
  GetSignaturePrintIssu,
  getAttachment,
  getListItemQuotation,
  getSummaryApproved,
  exportExcel,
  getItemCheckStock,
  SaleOrderPDFDownloader,
  QuotationPDFDownloader
};
