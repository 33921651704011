import React from 'react';
import { Divider, Table, Row, Col } from 'antd';
import Button03 from '../v2/button_03';
import styleIndex from './css/index.css';
import { useIntl, FormattedMessage } from 'react-intl';

export default function MaintenanceParts({ columns, dataSource, setVisible, view }) {
  const intl = useIntl();

  return (
    <div className="parts">
      <div className="maintenance-container-header">
        <Row className="maintenance-container-row">
          <Col span={12} className="maintenance-header-lift">
            <span className="maintenance-header-title">
              <FormattedMessage id="maintenanceTitleParts" defaultMessage="Parts" />
            </span>
          </Col>
          <Col span={12} className="maintenance-header-right">
            {view ? (
              <span />
            ) : (
              <Button03 type="link" onClick={() => setVisible(true)}>
                {' '}
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button03>
            )}
          </Col>
        </Row>
      </div>
      <Divider className="maintenance-sub-divider" />
      <div className="maintenance-sub-report-table">
        <Table
          size="middle"
          columns={columns}
          dataSource={dataSource}
          rowKey={(record, index) => index}
          scroll={dataSource && dataSource.length !== 0 ? { y: 163 } : { x: 'max-content' }}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
}
