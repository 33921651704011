import httpClient from '../../components/axiosClient';

const exportTaskExcel = async (tab, fromDate, toDate, fieldChang, fliterByColumn, paginationPage, paginationSize, orgId, checkedAll, formate) => {
  const comCode = localStorage.getItem('comCode');

  const body = {
    orgId: orgId ? orgId : undefined,
    members: checkedAll,
    search: [{
      key: fliterByColumn,
      value: fieldChang,
    }],
    limit: paginationSize,
    paging: paginationPage,
    startDate: fromDate,
    endDate: toDate,
    viewFormat: formate
  }


  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comCode}/search/travel-exprenses/${tab}/export`, body);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

export default exportTaskExcel;