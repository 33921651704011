import React from 'react';
import _ from 'lodash';
import { Card, Row, Col, Select, Input, Table, Result, Icon } from 'antd';
import Btn from '../../components/v2/button_01';
import Btns from '../../components/v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageTaskDistance } from '../../config/page-settings';
import { PageSettings } from '../../config/page-settings';
import NodataIMG from '../../components/image/Nodata_web.svg';
import './index.css';
import NoDataBackground from '../../components/no-data-page';

const { Option } = Select;

export default () => {
  const intl = useIntl();
  const handleMouseOverCancel = (e) => {
    document.getElementById('input-cancel-task-distance').focus();
  };

  const handleMouseOverSave = (e) => {
    document.getElementById('input-save-task-distance').focus();
  };

  const setOptionCustomer = (customer) => {
    return <Option value={`${customer.customerId}`}>{customer.customerName}</Option>;
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <PageTaskDistance.Consumer>
          {({
            columns,
            fromLocationSelect,
            customerList,
            customerLoading,
            customerSelect,
            addressBookList,
            searchAddressBook,
            loading,
            page,
            totalPage,
            rowPerPage,
            enableEdit,
            saveLoading,
            toggle,
            handleCustomerPopupScroll,
            handleCustomerChange,
            handleCustomerChangeSelect,
            handleCustomerChangeSearch,
            handleAddressBookSearch,
            handlePagination,
            handleSizeChange,
            handleChangeTable,
            handleEnableEdit,
            handleSaveCustomerAddressBook,
            handleToggle,
          }) =>
            _.head(fromLocationSelect) ? (
              <Card
                title={
                  <>
                    <Icon
                      type={toggle ? 'menu-unfold' : 'menu-fold'}
                      style={{ cursor: 'pointer', color: '#e73845', marginRight: 15 }}
                      onClick={handleToggle}
                    />
                    {intl.formatMessage({ id: `taskDistanceDestinationText`, defaultMessage: 'Destination' })}
                  </>
                }
                style={{
                  height: 'calc(100vh - 112px)',
                }}
                bodyStyle={
                  customerSelect
                    ? {}
                    : {
                        height: '94%',
                      }
                }
                extra={
                  customerSelect && _.size(addressBookList) > 0 ? (
                    enableEdit ? (
                      <>
                        <Btns
                          id="input-cancel-task-distance"
                          onClick={() => handleEnableEdit(false)}
                          onMouseOver={handleMouseOverCancel}
                          disabled={saveLoading}
                        >
                          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                        </Btns>
                        <Btn
                          id="input-save-task-distance"
                          className="button-custom-style-task-distance"
                          onClick={handleSaveCustomerAddressBook}
                          onMouseOver={handleMouseOverSave}
                          loading={saveLoading}
                        >
                          <FormattedMessage id="btnSave" defaultMessage="Save" />
                        </Btn>
                      </>
                    ) : checkPermissionAction('P6S5PG1C1', 'P6S5PG1C1A2') ? (
                      <Btn className="button-custom-style-task-distance" type="primary" onClick={() => handleEnableEdit(true)}>
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Btn>
                    ) : null
                  ) : null
                }
              >
                <Row gutter={[30, 16]}>
                  <Col span={24}>
                    <Select
                      placeholder={intl.formatMessage({ id: `taskDistancePlaceholderSelectCustomer`, defaultMessage: 'Select Customer' })}
                      defaultActiveFirstOption={false}
                      showSearch
                      allowClear={true}
                      style={{
                        width: 258,
                        marginRight: 12,
                      }}
                      onPopupScroll={handleCustomerPopupScroll}
                      onSelect={handleCustomerChangeSelect}
                      onSearch={handleCustomerChangeSearch}
                      onChange={handleCustomerChange}
                      loading={customerLoading}
                      disabled={!_.head(fromLocationSelect) ? true : false}
                      filterOption={false}
                    >
                      {customerList && customerList.map((customer) => setOptionCustomer(customer))}
                    </Select>
                    <Input
                      allowClear
                      placeholder={intl.formatMessage({
                        id: `taskDistancePlaceholderSearchAddress`,
                        defaultMessage: 'Search Address Book',
                      })}
                      prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      disabled={!customerSelect ? true : false}
                      style={{ width: 280 }}
                      onChange={(e) => handleAddressBookSearch(e.target.value)}
                      value={searchAddressBook}
                    />
                  </Col>
                </Row>
                {customerSelect ? (
                  <Row gutter={[30, 16]}>
                    <Col span={24}>
                      <Table
                        className="pagination-custom-select"
                        rowKey={(record) => record.index}
                        loading={loading}
                        columns={columns}
                        dataSource={addressBookList}
                        scroll={{ y: `calc(100vh - 392px)` }}
                        onChange={handleChangeTable}
                        pagination={{
                          total: totalPage,
                          showTotal: showTotal,
                          defaultCurrent: 1,
                          current: page,
                          pageSizeOptions: ['10', '20', '30', '40', '50'],
                          showSizeChanger: true,
                          locale: { items_per_page: '' },
                          onChange: handlePagination,
                          onShowSizeChange: handleSizeChange,
                        }}
                      />

                      <div className="total-items-task-report">
                        <span style={{ fontSize: '13px' }}>
                          <FormattedMessage id="lblTotal" defaultMessage="Total" />
                          {` ${totalPage || 0} `}
                          <FormattedMessage id="lblitems" defaultMessage="items" />
                        </span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '92%',
                      backgroundColor: '#f5f5f5',
                      marginTop: 18,
                    }}
                  >
                    <Result
                      icon={<Icon type="car" style={{ fontSize: 36, color: '#adacac' }} />}
                      style={{
                        backgroundColor: 'transparent',
                        fontSize: 14,
                        color: '#adacac',
                        fontWeight: 'bold',
                      }}
                      extra={
                        <div>
                          <p style={{ marginBottom: 15 }}>
                            <FormattedMessage id="taskDistanceEmptyPage1" defaultMessage="Please select a Customer" />
                          </p>
                          <p>
                            <FormattedMessage id="taskDistanceEmptyPage2" defaultMessage="Destination will appear here." />
                          </p>
                        </div>
                      }
                    />
                  </div>
                )}
              </Card>
            ) : (
              <Card
                style={{
                  height: 'calc(100vh - 112px)',
                  padding: 24,
                }}
                bodyStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  // backgroundColor: '#f5f5f5',
                }}
              >
                {/* <Result
                  icon={<Icon type='car' style={{ fontSize: 36, color: '#adacac' }} />}
                  style={{
                    backgroundColor: 'transparent',
                    fontSize: 16,
                    color: '#adacac',
                    fontWeight: 'bold'
                  }}
                  extra={ */}
                {/* <div className='blank-task-distance'>
                  <div>
                    <img src={NodataIMG} style={{ width: '220px' }}></img>
                  </div>
                  <p className='blank-task-distance-font-top' style={{ marginBottom: 15, color: '#1D3557' }} ><FormattedMessage id='taskdistansePleaseselectaFromLocation' defaultMessage='Please select a From Location ' /></p>
                  <p className='blank-task-distance-font-bottom'><FormattedMessage id='taskdistanseDestinationwillappearhere' defaultMessage='Destination will appear here.' /></p>
                </div> */}
                <NoDataBackground
                  text1={{ id: 'nodataXTaskDistance1', text: 'Please select a From Location' }}
                  text2={{ id: 'nodataXTaskDistance2', text: 'Destination will appear here.' }}
                />
                {/* }
                /> */}
              </Card>
            )
          }
        </PageTaskDistance.Consumer>
      )}
    </PageSettings.Consumer>
  );
};
