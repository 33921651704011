import React, { useRef, useCallback, useContext, useEffect, useState } from 'react';
import { GoogleMap, LoadScript, useLoadScript, Marker, withScriptjs, StandaloneSearchBox } from '@react-google-maps/api';
import Geocode from "react-geocode";
import _ from 'lodash';
// import { MonitorContext, TaskDetailsContext, InformationContext } from '../../pages/monitor-v1/monitor-context';
import getSearchLocation from '../../function/search-location/getLocation';

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

export default ({ idMap, mapModal, location, position, center, setPosition, setCenter, setLatlngTo,setValueAddress }) => {
  // const { stateTask, setStateTask , fncStateTask } = useContext(TaskDetailsContext);
  // const { stateInfor, setStateInfor, fncInfor } = useContext(InformationContext);
  const libraries = ["places"];
  const mapContainerStyle = {
    height: `400px`,
    width: '100%',
    position: 'relative'
  }

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onDragEnd = async (evt) => {

    setLatlngTo({ lat: evt.latLng.lat(), lng: evt.latLng.lng() });
    // fncStateTask.fncSearchLocation({ location: evt.latLng });
    let area = await getSearchLocation({ location: evt.latLng });

    setValueAddress(area.address);
  }

  return (
    <div>
      <GoogleMap
        id={`${idMap}`}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        <Marker
          position={position}
          animation={window.google.maps.Animation.DROP}
          draggable={true}
          onDragEnd={onDragEnd}
        />
      </GoogleMap>
    </div>
  )
}
