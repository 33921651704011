import React from 'react';
import { Icon, Divider } from 'antd';
import TaskIcon from '../../../../../components/image/Task.svg';
import TaskOrange from '../../../../../components/image/TaskOrange.svg';
import '../css/index.css'

export default (props) => {
  const { hideUser, hideTask, showResource, showTask } = props;

  const BagIcon = () => (<img src={TaskIcon} style={{ width: 23 }}  ></img>)
  const BagIconOrange = () => (<img src={TaskOrange} style={{ width: 23 }}   ></img>)

  return (
    <div className='map-view-control-tracking'>
      <Icon
        type="user"
        onClick={hideUser}
        className={showResource ? 'active-user-map' : 'unactive-user-map'}
      />
      <Divider className='map-view-control-divider' type="vertical" />
      <Icon
        onClick={hideTask}
        // className={props.taskActive}
        component={showTask ? BagIconOrange : BagIcon}
      />
    </div>
  )
}