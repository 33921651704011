import React from 'react'
import { Row, Col, Card } from 'antd'
import '../index.css'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

const ItemRequestContent = (props) => {
  const { data, handleClickItem, onScroll } = props
  return (
    <div className='itemRequestContentScroll' onScroll={onScroll}>
      {
        _.map(data, (item, index) => (
          <Row gutter={[11, 11]} key={index}>
            {
              _.map(item, (e, i) => (
                <Col span={6} key={i}>
                  <Card
                    className='itemRequestCard'
                    bodyStyle={{ padding: 'unset' }}
                    onClick={() => handleClickItem(e)}
                  >
                    <div className='itemRequestContentCard'>
                      {
                        e.isHavaImg ? (
                          <div style={{ display: 'flex', justifyContent: 'center', height: "97px", backgroundImage: `url(${e.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                        ) : null
                      }
                      <div className='itemRequestContentTop'>
                        <div className='itemRequestContentTopName' style={{ fontSize: e.isHavaImg ? '14px' : '20px' }}>{e.itemCode + " - " + e.itemMasterName + " " + e.branName + " " + e.modelName }</div>
                        <div className='itemRequestContentTopWareHouse'>{e.warehouseName}</div>
                      </div>
                      <div className='itemRequestContentBottom'>
                        <div className='itemRequestContentBottomStock'><FormattedMessage id="searchItemRequestTextStock" defaultMessage="In Stock" /> : {e.inStockTxt}</div>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))
            }
          </Row>
        ))
      }
    </div >
  )
}

export default ItemRequestContent
