import React, { useContext, useState, useEffect, useRef } from 'react';
import { PageEngagement } from '../../../config/page-settings';
import { editPost } from '../../../controllers/engagement';
import PostHeaderView from '../../../components/post/post-header-view-modal';
import PostMessage from '../../../components/post/post-message';
import PostAddress from '../../../components/post/post-address';
import { Modal, Icon, Input, Spin } from 'antd';
import _ from 'lodash';
import Button02 from '../../../components/v2/button_02';
import Button01 from '../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import stylesPost from '../css/engagement-post.css';
import stylesIndex from '../css/index.css';
import { getPostOne } from '../../../controllers/engagement';
import ModalSelectLocation from '../../../components/modal-select-location/index';
import Resizer from 'react-image-file-resizer';
import {
  Preview01,
  Preview02,
  Preview03,
  Preview04,
  Preview05,
  PreviewPlus,
  PreviewSingle,
} from '../../../components/preview-picture/preview-picture';
import { v4 as uuidv4 } from 'uuid';
import { RenderTag } from '../../../components/post-component/post-tag';

const { TextArea } = Input;

export default React.memo(
  (props) => {
    const imageRef = useRef();
    const fileRef = useRef();
    const intl = useIntl();
    const { fnc, engagementTimeline, visibleEditPost, postId } = useContext(PageEngagement);
    const [visibleModalLocation, setVisibleModalLocation] = useState(false);
    const [addressData, setAddressData] = useState();
    const [locationData, setLocationData] = useState([]);
    const [center, setCenter] = useState({ lat: 13.90607, lng: 100.51913 });
    const [position, setPosition] = useState({ lat: 13.90607, lng: 100.51913 });
    const [visibleInfo, setVisibleInfo] = useState(false);
    const [selectAddress, setSelectAddress] = useState([]);
    const [dragMarker, setDragMarker] = useState();
    const [latLng, setLatLng] = useState({});
    const [visibleCreateAddress, setVisibleCreateAddress] = useState(false);
    const [toLocation, setToLocation] = useState();
    const [fieldChange, setFieldChange] = useState('');
    const [valueInput, setValueInput] = useState();
    const [triggerApi, setTriggerApi] = useState(false);
    const [message, setMessage] = useState('');

    const [dataEditPost, setDataEditPost] = useState([]);
    const [defaultAddress, setDefaultAddress] = useState([]);
    const [defaultFileImg, setDefaultFileImg] = useState([]);
    const [customerId, setCustomerId] = useState();
    const [tagFile, setTagFile] = useState([]);
    const [imageFileList, setImageFileList] = useState([]);
    const [checked, setChecked] = useState('default');
    const [loading, setLoading] = useState(false);
    const [selectAddressData, setSelectAddressData] = useState();

    // console.log('postId::: ', postId);

    useEffect(() => {
      if (postId) {
        const getData = async () => {
          const response = await getPostOne(postId);
          // console.log('useEffect getData API::: ', response);
          setDataEditPost(_.get(response, '[0]'));
          setMessage(_.get(response[0], 'description'));
          setTagFile(_.get(response[0], 'fileList'));
          setImageFileList(_.get(response[0], 'imageList'));
          setDefaultAddress(_.get(response, '[0]'));
          setDefaultFileImg([..._.get(response, '[0].fileList'), ..._.get(response, '[0].imageList')]);
        };
        getData();
      }
    }, [postId]);

    useEffect(() => {
      setCustomerId(_.get(defaultAddress, 'customerId'));
      if (_.get(defaultAddress, 'addressBook.addressBookId')) {
        setSelectAddress([
          {
            address: _.get(defaultAddress, 'addressBook.fullAddress'),
            lat: _.get(defaultAddress, 'addressBook.lat'),
            lng: _.get(defaultAddress, 'addressBook.lng'),
            name: _.get(defaultAddress, 'addressBook.addressName'),
            addressId: _.get(defaultAddress, 'addressBook.addressBookId'),
          },
        ]);
        setAddressData({
          address: _.get(defaultAddress, 'addressBook.fullAddress'),
          lat: _.get(defaultAddress, 'addressBook.lat'),
          lng: _.get(defaultAddress, 'addressBook.lng'),
          name: _.get(defaultAddress, 'addressBook.addressName'),
          addressId: _.get(defaultAddress, 'addressBook.addressBookId'),
        });
        setLatLng({
          lat: _.get(defaultAddress, 'addressBook.lat'),
          lng: _.get(defaultAddress, 'addressBook.lng'),
        });
        setCenter({
          lat: _.get(defaultAddress, 'addressBook.lat'),
          lng: _.get(defaultAddress, 'addressBook.lng'),
        });
        setPosition({
          lat: _.get(defaultAddress, 'addressBook.lat'),
          lng: _.get(defaultAddress, 'addressBook.lng'),
        });
      } else {
        if (_.get(defaultAddress, 'addressName') !== '') {
          setSelectAddress([
            {
              address: _.get(defaultAddress, 'addressName'),
              lat: _.get(defaultAddress, 'addressLat'),
              lng: _.get(defaultAddress, 'addressLng'),
              name: _.get(defaultAddress, 'addressName'),
              addressId: null,
            },
          ]);
          setAddressData({
            address: _.get(defaultAddress, 'addressName'),
            lat: _.get(defaultAddress, 'addressLat'),
            lng: _.get(defaultAddress, 'addressLng'),
            name: _.get(defaultAddress, 'addressName'),
            addressId: null,
          });
          setLatLng({
            lat: _.get(defaultAddress, 'addressLat'),
            lng: _.get(defaultAddress, 'addressLng'),
          });
          setCenter({
            lat: _.get(defaultAddress, 'addressLat'),
            lng: _.get(defaultAddress, 'addressLng'),
          });
          setPosition({
            lat: _.get(defaultAddress, 'addressLat'),
            lng: _.get(defaultAddress, 'addressLng'),
          });
        } else {
        }
      }
    }, [defaultAddress]);

    const handleSaveEdit = async () => {
      setLoading(true);
      if (postId) {
        let lat = _.get(addressData, 'lat') ? _.get(addressData, 'lat') : undefined;
        let lng = _.get(addressData, 'lng') ? _.get(addressData, 'lng') : undefined;
        // let addressId = _.get(addressData, 'addressId');

        const data = {
          remark: message,
          lat: lat,
          lng: lng,
          entityRef: 'customer',
          entityRefId: postId,
          imageList: imageFileList,
          fileList: tagFile,
          fileListMaster: defaultFileImg,
          addressBookId: _.get(addressData, 'addressId'),
        };

        try {
          const response = await editPost(data, postId);
          fnc.handleOkModalEditPost();
          setSelectAddress([]);
          setDragMarker();
          setVisibleInfo(false);
          setLatLng({ lat: 13.90607, lng: 100.51913 });
          setToLocation();
          setFieldChange();
          setValueInput();
          setAddressData({
            address: undefined,
            lat: undefined,
            lng: undefined,
            name: undefined,
            addressId: null,
          });
          setChecked('default');
          if (response.success === true) {
            successNotification(response.message);
            if (_.size(response.dataFile) !== 0) {
              const sumbyObject = response.dataFile.filter((item) => {
                return item.success === true;
              });
              successNotification(`Attachment has ${_.size(sumbyObject)}/${_.size(response.dataFile)} uploaded`);
            }
            if (_.size(response.dataImage) !== 0) {
              const sumbyObject = response.dataImage.filter((item) => {
                return item.success === true;
              });
              successNotification(`Photo has ${_.size(sumbyObject)}/${_.size(response.dataImage)} uploaded`);
            }
            fnc.handleOkModalEditPost();
            fnc.handleTimelineRefresh();
            setLoading(false);
          } else {
            errorNotification(response.message);
            setLoading(false);
          }

          setSelectAddress([]);
          setDragMarker();
          setLatLng({ lat: 13.90607, lng: 100.51913 });
          setToLocation();
          setFieldChange();
          setValueInput();
          setAddressData({
            address: undefined,
            lat: undefined,
            lng: undefined,
            name: undefined,
            addressId: null,
          });
          setChecked('default');
        } catch {
          fnc.handleOkModalEditPost();
          setLoading(false);
        }
      }
    };

    const handleCancelEdit = () => {
      fnc.handleCancelModalEditPost();
      setSelectAddress([]);
      setDragMarker();
      setVisibleInfo(false);
      setLatLng({ lat: 13.90607, lng: 100.51913 });
      setToLocation();
      setFieldChange();
      setValueInput();
      setAddressData({
        address: undefined,
        lat: undefined,
        lng: undefined,
        name: undefined,
        addressId: null,
      });
      setChecked('default');
    };

    const handleHashtag = (data) => {};

    const handleAddress = (data) => {
      if (data) {
        window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'addressLat')}, ${_.get(data, 'addressLng')}`, '_blank');
      }
    };

    const handleCloseAddress = (data) => {
      if (data.addressId === addressData.addressId) {
        setAddressData({
          address: undefined,
          lat: undefined,
          lng: undefined,
          name: undefined,
          addressId: null,
        });
        setDragMarker();
        setVisibleInfo(false);
      }
    };

    const onChangeMessage = (val) => {
      setMessage(val);
    };

    const showOpenFileDialog = (e) => {
      imageRef.current.value = null;
      if (loading !== true) {
        imageRef.current.click();
      }
    };

    const showOpenFileDialogFile = () => {
      fileRef.current.value = null;
      if (loading !== true) {
        fileRef.current.click();
      }
    };

    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          720,
          720,
          'JPEG',
          70,
          0,
          (uri) => {
            resolve(uri);
          },
          'base64'
        );
      });

    const dataURLtoFile = (dataurl, filename) => {
      const arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      for (var i = 0; i < bstr.length; i++) {
        u8arr[i] = bstr.charCodeAt(i);
      }
      return new File([u8arr], filename, { type: mime });
    };

    // const handleChangePicture = async (e) => {
    //   let newArr = [...imageFileList];
    //   const file = e.target.files;
    //   for (let i = 0; i < file.length; i++) {
    //     newArr.push(
    //       {
    //         attachmentId: uuidv4(),
    //         description: '',
    //         pathImage: URL.createObjectURL(file[i]),
    //         file: file[i],
    //       }
    //     )
    //   }
    //   setImageFileList(newArr);
    //   Array.from(e.target.files).map(
    //     (file) => URL.revokeObjectURL(file) // avoid memory leak
    //   );
    // }

    const handleChangePicture = async (e) => {
      let newArr = [...imageFileList];
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = await resizeFile(files[i]);
        const fileResize = dataURLtoFile(file, files[i].name);

        newArr.push({
          attachmentId: uuidv4(),
          description: '',
          pathImage: URL.createObjectURL(fileResize),
          file: fileResize,
        });
      }
      setImageFileList(newArr);
      Array.from(newArr).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    };

    // const handleChangeFile = (e) => {
    //   let newArr = [...tagFile];
    //   const file = e.target.files;
    //   for (let i = 0; i < file.length; i++) {
    //     newArr.push(
    //       {
    //         attachmentId: uuidv4(),
    //         description: '',
    //         file: file[i],
    //         name: file[i].name
    //       }

    //     )
    //   }
    //   setTagFile(newArr);
    // }

    const handleChangeFile = (e) => {
      let newArr = [...tagFile];
      const file = e.target.files;

      if (file) {
        let fileMin = _.filter(file, (el) => el.size <= 2097152);

        if (file.length !== fileMin.length) {
          let fileCount = file.length - fileMin.length;
          errorNotification(
            intl.formatMessage({
              id: 'errorImageAndFileMoreThan2MB',
              defaultMessage: 'Upload failed, please upload a file that is less than 2 MB.',
            })
          );
        }

        for (let i = 0; i < fileMin.length; i++) {
          if (fileMin[i].name.match(/\.(pdf|PDF)$/) && fileMin[i].size <= 2097152) {
            newArr.push({
              attachmentId: uuidv4(),
              description: '',
              file: fileMin[i],
              name: fileMin[i].name,
            });
            setTagFile(newArr);
          }
        }
      }
    };

    const removeFile = (val) => {
      let newArr = [...tagFile];
      const remove = _.filter(newArr, (item) => {
        return item.attachmentId !== val;
      });
      setTagFile(remove);
    };

    const handleTags = (data) => {
      if (_.get(data, 'file')) {
        const fileTag = URL.createObjectURL(data.file);
        let isPDF = /pdf/g.test(data.name);
        if (isPDF) return window.open(fileTag, '_blank');
      }
    };

    const renderTagFile = (fileList, removeFile) => {
      return (
        <div className="post-tags-view-modal">
          <div className="post-tags-view">
            {fileList && fileList.map((item) => <RenderTag fileList={item} handleTags={handleTags} handleRemove={removeFile} />)}
          </div>
        </div>
      );
    };

    const renderPreview = (imageList) => {
      let sum = imageList.length;
      switch (sum) {
        case 1:
          return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        case 2:
          return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        case 3:
          return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        case 4:
          return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        case 5:
          return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
        default:
          return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      }
    };

    const handlePreviewImage = (data) => {
      setChecked('actionView');
    };

    const handleBack = (data) => {
      setChecked('default');
    };

    const handleDeleteSingleImage = (data) => {
      let newArr = [...imageFileList];
      if (data && data.attachmentId) {
        const remove = _.filter(newArr, (item) => {
          return item.attachmentId !== data.attachmentId;
        });
        setImageFileList(remove);
      }
    };

    const onChangeCommentImg = (e, item, index) => {
      let newArr = [...imageFileList];
      if (e.target.value.length >= 0) {
        newArr[index].description = e.target.value;
      }
      setImageFileList(newArr);
    };

    const handleOpenModal = () => {
      if (loading !== true) {
        setVisibleModalLocation(true);
        setDragMarker();
        setVisibleInfo(false);
      } else {
        setVisibleModalLocation(false);
      }
    };

    return (
      <div className="engagement-post">
        <Modal
          className="engagement-modal-create-view-edit"
          title={intl.formatMessage({ id: 'engagementTextEditPost', defaultMessage: 'Edit Post' })}
          width={650}
          visible={visibleEditPost}
          onOk={handleSaveEdit}
          onCancel={handleCancelEdit}
          footer={[
            <div className="modal-create-post-footer-group">
              <div className="modal-create-post-footer-icon">
                <div style={{ paddingRight: '18px' }}>
                  <Icon type="camera" onClick={showOpenFileDialog} style={{ color: '#1D3557' }} />
                </div>
                <div style={{ paddingRight: '18px' }}>
                  <Icon type="environment" style={{ cursor: 'pointer', color: '#1D3557' }} onClick={handleOpenModal} />
                </div>
                <div>
                  <Icon type="file-text" onClick={showOpenFileDialogFile} style={{ color: '#1D3557' }} />
                </div>
              </div>
              <div>
                <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancelEdit} disabled={loading}>
                  <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                </Button02>
                <Button01
                  key="submit"
                  type="primary"
                  btnsize="wd_df"
                  // disabled={_.size(tagFile) === 0 && _.size(imageFileList) === 0 && message === '' && message === undefined && _.get(addressData,'addressId') === null ? true : false}
                  disabled={
                    (_.size(tagFile) === 0 &&
                      _.size(imageFileList) === 0 &&
                      message === '' &&
                      _.get(addressData, 'address') === undefined) ||
                    loading === true
                      ? true
                      : false
                  }
                  // disabled={true}
                  onClick={handleSaveEdit}
                >
                  <FormattedMessage id="btnSave" defaultMessage="Save" />
                </Button01>
              </div>
            </div>,
          ]}
        >
          <Spin spinning={loading}>
            <div className="engagement-modal-body">
              <input
                ref={imageRef}
                type="file"
                multiple
                style={{ display: 'none' }}
                accept=".jpg, .png, .JPG, .PNG"
                onChange={handleChangePicture}
              />
              <input ref={fileRef} type="file" multiple style={{ display: 'none' }} accept=".pdf" onChange={handleChangeFile} />
              <PostHeaderView postData={dataEditPost} />

              <div className="engagement-modal-create-post">
                <TextArea
                  placeholder="Type a message or hashtag for describe"
                  className="text-area-post"
                  rows={1}
                  onChange={(e) => onChangeMessage(e.target.value)}
                  value={message}
                  autoSize={{ minRows: 1, maxRows: 4 }}
                />
                {_.get(addressData, 'address') && _.get(addressData, 'address') !== '' ? (
                  <PostAddress
                    action="edit"
                    postData={{
                      addressLat: _.get(addressData, 'lat'),
                      addressLng: _.get(addressData, 'lng'),
                      addressName: _.get(addressData, 'address'),
                      addressId: _.get(addressData, 'addressId'),
                    }}
                    handleAddress={handleAddress}
                    handleCloseAddress={handleCloseAddress}
                  />
                ) : null}

                {tagFile && tagFile.length > 0 ? renderTagFile(tagFile, removeFile) : null}
              </div>

              <PreviewSingle
                action="actionView"
                imageList={imageFileList}
                handleDeleteSingleImage={handleDeleteSingleImage}
                onChangeCommentImg={onChangeCommentImg}
                handleBack={handleBack}
                type="edit"
              />
            </div>
          </Spin>
        </Modal>
        <ModalSelectLocation
          value={{
            visibleModalLocation,
            locationData,
            center,
            position,
            visibleInfo,
            selectAddress,
            dragMarker,
            latLng,
            visibleCreateAddress,
            toLocation,
            fieldChange,
            valueInput,
            triggerApi,
            defaultAddress,
            addressData,
            selectAddressData,
          }}
          fnc={{
            setVisibleModalLocation,
            setAddressData,
            setCenter,
            setPosition,
            setVisibleInfo,
            setSelectAddress,
            setDragMarker,
            setLatLng,
            setVisibleCreateAddress,
            setToLocation,
            setFieldChange,
            setValueInput,
            setTriggerApi,
            setLocationData,
            setSelectAddressData,
          }}
          customer={customerId}
        />
      </div>
    );
  },
  (prev, next) => {
    return true;
  }
);
