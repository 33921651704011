import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Form, Menu, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import PurchaseCreateDetail from './detail';
import PurchaseCreateDelivery from './delivery';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import ListItem from '../../../../components/list-item/list/index';
import ModalCreateListItem from '../../../../components/list-item/add';
import ModalEditListItem from '../../../../components/list-item/edit';
import ModalDeliveryCreate from '../../../../components/delivery/add';
import ModalDeliveryEdit from '../../../../components/delivery/edit';
import moment from 'moment';
import _ from 'lodash';
import { numberFormatter, numberParser } from '../../../../component-function/fnc-inputnumber-currency';
import { savePo, updateStatusPo, uploadPoAttachment } from '../../../../controllers/po/po';
import ModalUploadFile from '../../../../components/modal-upload';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import Resizer from 'react-image-file-resizer';
import { useDebounce } from '../../../../controllers/debounce';
import ModalCreateListServiceItemPO from '../list-item/add-service';
import ModalEditListServiceItemPO from '../list-item/edit-service';
import ListItemServicePO from '../list-item/list-service';
import { select } from 'glamor';
import ReferenceTab from '../../../../components/reference';
import { savetListReferenceTab } from '../../../../controllers/reference-tab';
import { Round } from '../../../../component-function/lodash';

const { TabPane } = Tabs;

const PurchaseCreate = (props) => {
  const { visible, setVisible, form, setTrigger, initial } = props;
  const { vendorData, taxList, deliveryType, vehicleList } = initial;
  const intl = useIntl();
  const { validateFields, resetFields, getFieldValue } = form;
  const [tabChange, setTabChange] = useState('1');
  const [visibleItem, setVisibleItem] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [visibleAddDeli, setVisibleAddDeli] = useState(false);
  const [visibleEditDeli, setVisibleEditDeli] = useState(false);
  const [deliveryDefault, setDeliveryDefault] = useState();
  const [createData, setCreateData] = useState();
  const [contactData, setContactData] = useState([]);
  const [selectItem, setSelectItem] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [disbleButton, setDisbleButton] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();
  const [searchGroup, setSearchGroup] = useState({ discount: 0, discountNumber: 0.0 });
  const [searchGroupList, setSearchGrouList] = useState([]);

  //--------------------------------------------------------------------------
  const [visibleListService, setVisibleListService] = useState(false);
  const [visibleAddService, setVisibleAddService] = useState(false);
  const [visibleEditService, setVisibleEditService] = useState(false);
  const [creatService, setCreatService] = useState();
  const [editService, setEditService] = useState();
  const [loading, setLoading] = useState(false);

  //---------------------------------------------------------------------------
  const [refItem, setRefItem] = useState([]);

  const columnsDetail = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 120,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      render: (text, record, index) => record.qty + ' ' + record.uomName,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (text, record, index) => numberFormatter(record.pricePerUnit) + ' ' + 'THB',
    },
    // {
    //   title: intl.formatMessage({ id: 'purchesOrderModalColumnPromotionCampaign', defaultMessage: 'Promotion or Campaign' }),
    //   dataIndex: 'promotionCampaign',
    //   key: 'promotionCampaign',
    //   render: (text, record, index) => record.promotionCampaign || '-',
    // },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscountPercentage', defaultMessage: 'Discount Percentage' }),
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (text, record, index) => numberFormatter(record.discountPercentage) + ' ' + '%',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: (text, record, index) => numberFormatter(record.discountPrice) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      fixed: 'right',
      width: 160,
      render: (text, record, index) => numberFormatter(record.totalPrice) + ' ' + 'THB',
    },
  ];

  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const columnsDelivery = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryDate', defaultMessage: 'Delivery Date' }),
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      width: 150,
      sorter: true,
      render: (text, record, index) => moment(_.get(record, 'deliveryDate'), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryVehicle', defaultMessage: 'Vehicle' }),
      dataIndex: 'vehicleTxt',
      key: 'vehicleTxt',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryType', defaultMessage: 'Delivery Type' }),
      dataIndex: 'deliveryTypeName',
      key: 'deliveryTypeName',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryAddress', defaultMessage: 'Delivery Address' }),
      dataIndex: 'deliveryAddress',
      key: 'deliveryAddress',
      width: 300,
      render: (text, record, index) =>
        _.get(record, 'deliveryAddressDetail.addressName') + ' - ' + _.get(record, 'deliveryAddressDetail.fullAddress'),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnTotalItems', defaultMessage: 'Total Items' }),
      dataIndex: 'total',
      key: 'total',
      width: 150,
      sorter: true,
      render: (text, record, index) => _.get(record, 'total') + ' - ' + 'Items',
    },
  ];

  useEffect(() => {
    const ChangeDiscount = () => {
      const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalPrice') : 0.0;
      console.log('TestValue', _.get(searchGroup, 'discount'), '/', (sumTotal * parseInt(_.get(searchGroup, 'discount') || 0)) / 100);

      setSearchGroup({
        discount: _.get(searchGroup, 'discount'),
        discountNumber: ((sumTotal * parseFloat(_.get(searchGroup, 'discount') || 0)) / 100).toFixed(2),
      });
    };

    if (_.size(selectItem) > 0) {
      ChangeDiscount();
    }
  }, [selectItem]);

  const handleSave = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        setTabChange('1');
        return;
      }
      // const filterDelivery = _.filter(selectItem, (item) => { return item.countTemp !== 0 })
      // if (_.size(filterDelivery) > 0) {
      //   setTabChange('2')
      // } else {
      setDisbleButton(true);
      const filterContact = _.filter(contactData, (item) => {
        return _.get(item, 'customerContactId') === _.get(values, 'contact');
      });
      const body = {
        vendorSupplierId: _.get(values, 'vendor'),
        vendorSupplierContactDetail: {
          vendorSupplierContactId: _.get(filterContact, '[0].customerContactId'),
          vendorSupplierContactName: _.get(filterContact, '[0].customerContactName'),
          vendorSupplierContactPhone: _.get(filterContact, '[0].customerContactPhone'),
        },
        paymentTerm: parseFloat(_.get(values, 'paymentTerm')),
        quotationNo: _.get(values, 'quotationNo') || '',
        issueDate: moment(_.get(values, 'issueDate')).format('YYYY-MM-DD'),
        referenceNo: _.get(values, 'referenceNo') || '',
        itemPo: _.map(selectItem, (item) => {
          return {
            index: item.index,
            qty: item.qty,
            uomId: item.uomId,
            itemMasterId: item.itemMasterId,
            pricePerUnit: item.pricePerUnit,
            promotionCampaign: item.promotionCampaign,
            discountPercentage: item.discountPercentage,
            discountPrice: item.discountPrice,
            totalPrice: item.totalPrice,
            remark: item.remark,
            itemPoId: item.itemPoId,
            categoryCode: item.type
          };
        }),
        deliveryPo: _.map(deliveryList, (item) => {
          return {
            ...item,
            itemPoId: item.itemPoId,
            itemList: _.map(item.itemList, (e) => {
              return {
                ...e,
                data: undefined,
              };
            }),
            vehicleTypeCode: _.get(item, 'vehicleTypeCode'),
            assetId: _.get(item, 'vehicleInternal.assetId'),
            serialNo: _.get(item, 'vehicleInternal.serialNo'),
            brandName: _.get(item, 'vehicleInternal.brandName'),
            modelName: _.get(item, 'vehicleInternal.modelName'),
            assetName: _.get(item, 'vehicleInternal.assetName') || _.get(item, 'vehicleOther'),
          };
        }),
        remark: _.get(values, 'remark') || '',
        subtotal: _.sumBy(selectItem, 'totalPrice'),
        discountPercent: parseFloat(_.get(searchGroup, 'discount')),
        discount: parseFloat(_.get(searchGroup, 'discountNumber')),
        subtotalAfterDis: _.sumBy(selectItem, 'totalPrice') - parseFloat(_.get(searchGroup, 'discountNumber')),
        taxPercent: _.get(values, 'tax'),
        tax: numberParser(_.get(values, 'taxNumber')),
        total:
          _.sumBy(selectItem, 'totalPrice') -
          parseFloat(_.get(searchGroup, 'discountNumber')) +
          numberParser(_.get(values, 'taxNumber') || 0),
      };
      const response = await savePo(body);
      if (response.status === 200) {
        successNotification(_.get(response, 'data.status.message'));
        if (type === 'waiting') {
          const reppo = await updateStatusPo(_.get(response, 'data.data.poId'), {
            statusCode: 'waiting',
            remark: '',
            reason: '',
            actionBy: localStorage.getItem('memComId'),
          });
          if (_.size(refItem) > 0) {
            const dataCreate = _.get(reppo, 'data.data');
            const payload = {
              referenceType: 'po',
              referenceId: _.get(dataCreate, 'poId'),
              referenceNo: _.get(dataCreate, 'poNo'),
              businessPartnerName: _.get(dataCreate, 'vendorSupplier'),
              businessPartnerId: _.get(dataCreate, 'vendorSupplierId'),
              createdBy: localStorage.getItem('memComId'),
              createdByName: _.get(dataCreate, 'createdBy'),
              createdDate: _.get(dataCreate, 'issueDate'),
              refToList: refItem,
            };
            await savetListReferenceTab(payload);
          }
        }
        if (_.size(attachmentData) > 0) await uploadData(attachmentData, _.get(response, 'data.data.poId'));

        setTrigger((cur) => !cur);
        setVisible(false);
        resetFields();
        setDeliveryList([]);
        setSelectItem([]);
        setTabChange('1');
        setAttachmentData([]);
        setDisbleButton(false);
        setSearchGroup({ discount: 0, discountNumber: 0.0 });
        setSearchGrouList([]);
        setRefItem([]);
      } else {
        errorNotification(_.get(response, 'data.status.message'));
        setDisbleButton(false);
      }
      // }
    });
  };

  const uploadData = async (att, id) => {
    for (let index = 0; index < att.length; index++) {
      const element = att[index];
      const temp = _.get(element, 'fileRaw');
      if (_.get(temp, 'type') === 'image/jpeg' || _.get(temp, 'type') === 'image/png') {
        const image = await resizeFile(temp);
        const fileChange = dataURLtoFile(image, _.get(temp, 'name').toLowerCase());
        let formData = new FormData();
        formData.append('file', fileChange);
        formData.append('fileName', _.get(temp, 'name'));
        formData.append('poId', id);
        formData.append('remark', element.remark || '');
        await uploadPoAttachment(formData);
      } else if (_.get(temp, 'type') === 'application/pdf') {
        let formDataPDF = new FormData();
        formDataPDF.append('file', temp);
        formDataPDF.append('fileName', _.get(temp, 'name'));
        formDataPDF.append('poId', id);
        formDataPDF.append('remark', element.remark || '');
        await uploadPoAttachment(formDataPDF);
      }
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const openModalEdit = (value) => {
    setEditData(value);
    setEditService(value)
    if (_.get(value, 'type') === 'product') setVisibleEdit(true);
    if (_.get(value, 'type') === 'service') setVisibleEditService(true);
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setDeliveryList([]);
    setSelectItem([]);
    setTabChange('1');
    setAttachmentData([]);
    setSearchGroup({ discount: 0, discountNumber: 0.0 });
    setSearchGrouList([]);
    setDisbleButton(false);
    setRefItem([]);
  };

  const handleCreateUpload = () => {
    setTypeAttachment('add');
    setOpenUpload(true);
  };

  const handleEditUpload = (data) => {
    setDefaultAttachment(data);
    setTypeAttachment('edit');
    setOpenUpload(true);
  };

  const handleTabChange = (tab) => {
    setTabChange(tab);
  };

  const handleDeleteDelivery = (value, data) => {
    const filterSelect = _.filter(deliveryList, (item) => {
      return item.index !== value;
    });
    const mapRoleback = _.map(selectItem, (item) => {
      const filterData = _.filter(data.itemList, (e) => {
        return item.itemPoId === e.itemPoId;
      });
      return {
        ...item,
        // count: _.size(filterData) > 0 ? item.count + _.get(filterData, '[0].qty') : item.count,
        countTemp: _.size(filterData) > 0 ? _.get(filterData, '[0].qty') + item.countTemp : item.countTemp,
      };
    });
    setDeliveryList(filterSelect);
    setSelectItem(mapRoleback);
  };

  console.log('delilist', deliveryList, selectItem);

  const handleEditDelivery = (value) => {
    setDeliveryDefault(value);
    setVisibleEditDeli(true);
  };

  const onChange = async (value, code) => {
    const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalPrice') : 0.0;
    console.log('TestValue', value, '/', parseFloat(value || 0.0) / sumTotal);
    if (code === 'discount') {
      setSearchGroup({ discount: value, discountNumber: Round((sumTotal * parseFloat(value || 0)) / 100) });
    } else if (code === 'discountNumber' && _.size(selectItem) > 0) {
      setSearchGroup({
        discount: ((parseFloat(value || 0.0) / sumTotal) * 100).toFixed(2),
        discountNumber: Round(value),
      });
    }
  };

  console.log('loading', disbleButton, selectItem);

  const handleOpenModalListService = () => {
    setVisibleListService(true);
  };

  const menuColumn = (
    <Menu>
      <Menu.Item onClick={() => setVisibleItem(true)}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnItem" defaultMessage="Item" />
        </Button>
      </Menu.Item>
      <Menu.Item onClick={handleOpenModalListService}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnService" defaultMessage="Service" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      title={intl.formatMessage({ id: `purchesModalTitleCreate`, defaultMessage: 'Create Purchase Order' })}
      visible={visible}
      onCancel={handleCancel}
      width={800}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel} disabled={!disbleButton > 0 ? false : true}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          style={{ margin: 'unset' }}
          key="draft"
          type="primary"
          btnsize="wd_px"
          onClick={() => handleSave('draft')}
          disabled={_.size(selectItem) && !disbleButton > 0 && _.size(refItem) <= 0 ? false : true}
        >
          <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
        </Button01>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleSave('waiting')}
          disabled={_.size(selectItem) > 0 && !disbleButton ? false : true}
        >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Tabs animated={false} onChange={handleTabChange} activeKey={tabChange} className="po-tabs-modal">
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleDetail`, defaultMessage: 'Detail' })} key="1">
          <PurchaseCreateDetail
            form={form}
            columns={columnsDetail}
            columnsAtt={columnsAtt}
            setVisibleItem={setVisibleItem}
            vendorData={vendorData}
            contactData={contactData}
            setContactData={setContactData}
            selectItem={selectItem}
            openModalEdit={openModalEdit}
            setSelectItem={setSelectItem}
            taxList={taxList}
            handleCreateUpload={handleCreateUpload}
            attachmentData={attachmentData}
            setAttachmentData={setAttachmentData}
            handleEditUpload={handleEditUpload}
            onChange={onChange}
            searchGroup={searchGroup}
            menuColumn={menuColumn}
            deliveryList={deliveryList}
            setDeliveryList={setDeliveryList}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleDelivery`, defaultMessage: 'Delivery' })} key="2">
          <PurchaseCreateDelivery
            columns={columnsDelivery}
            setVisibleAddDeli={setVisibleAddDeli}
            deliveryList={deliveryList}
            selectItem={selectItem}
            handleDeleteDelivery={handleDeleteDelivery}
            handleEditDelivery={handleEditDelivery}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleReference`, defaultMessage: 'Reference' })} key="3">
          <ReferenceTab visiblemodal={visible} selectItem={refItem} setSelectItem={setRefItem} typeRef={'po'} />
        </TabPane>
      </Tabs>
      <ModalUploadFile
        visible={openUpload}
        setVisible={setOpenUpload}
        setAttData={setAttachmentData}
        attData={attachmentData}
        typeAtt={typeAttachment}
        defaultAtt={defaultAttachment}
      />
      <ListItem
        visible={visibleItem}
        setVisible={setVisibleItem}
        setVisibleAdd={setVisibleAdd}
        setCreateData={setCreateData}
        vendorData={vendorData}
        form={form}
        searchGroup={searchGroupList}
        setSearchGroup={setSearchGrouList}
      />
      <ModalCreateListItem
        visible={visibleAdd}
        setVisible={setVisibleAdd}
        data={createData}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />
      <ModalEditListItem
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        data={editData}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        deliveryList={deliveryList}
      />
      <ListItemServicePO
        visible={visibleListService}
        setVisible={setVisibleListService}
        setVisibleAdd={setVisibleAddService}
        setCreateData={setCreatService}
        vendorData={vendorData}
        form={form}
      />
      <ModalCreateListServiceItemPO
        visible={visibleAddService}
        setVisible={setVisibleAddService}
        data={creatService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />
      <ModalEditListServiceItemPO
        visible={visibleEditService}
        setVisible={setVisibleEditService}
        data={editService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalDeliveryCreate
        vendorId={getFieldValue('vendor')}
        visible={visibleAddDeli}
        setVisible={setVisibleAddDeli}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        deliveryList={deliveryList}
        setDeliveryList={setDeliveryList}
        deliveryType={deliveryType}
        vehicleList={vehicleList}
      />
      <ModalDeliveryEdit
        vendorId={getFieldValue('vendor')}
        visible={visibleEditDeli}
        setVisible={setVisibleEditDeli}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        deliveryList={deliveryList}
        setDeliveryList={setDeliveryList}
        deliveryType={deliveryType}
        deliveryDefault={deliveryDefault}
        vehicleList={vehicleList}
      />
    </Modal>
  );
};

const CreatePurchase = Form.create({
  name: 'po-form',
  mapPropsToFields() { },
})(PurchaseCreate);

export default CreatePurchase;
