import { AutoComplete, Checkbox, Col, Divider, Form, Icon, Input, InputNumber, Modal, Row, Select, TimePicker } from 'antd';
import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import WarehouseContext from '../context';
import _ from 'lodash';
import { useEffect } from 'react';
import './css/index.css';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import {
  getItemsCode,
  getItemsMaster,
  getLocationByItem,
  saveWarehouseStock,
  updateWarehouseStock,
} from '../../../controllers/warehouse/warehouse';
import moment from 'moment';
import { useDebounce } from '../../../controllers/debounce';
import { handleKeyDownDecimal2Fixed, handleKeyDownPhone, handleKeyDownNumber } from '../../../component-function/fnc-number';
const { TextArea } = Input;
const { Option } = Select;
const WarehouseStockModal = ({ form, record, setRecord, visible, setVisible, setTriggerStock }) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { warehouseId } = state;
  const { setTrigger, setEvent } = setState;
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const format = 'HH:mm';
  const [binLocationList, setBinLocationList] = useState([]);

  const [searhByCode, setSearchByCode] = useState('');
  const debouncCode = useDebounce(searhByCode, 500);
  const [searhByName, setSearchByName] = useState('');
  const debounceName = useDebounce(searhByName, 500);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [codeEnd, setCodeEnd] = useState(10);
  const [nameEnd, setNameEnd] = useState(10);

  const [selectItem, setSelectItem] = useState();
  const extraCost = getFieldValue('extraCost');
  const standardCost = getFieldValue('standardCost');
  const extraPrice = getFieldValue('extraPrice');
  const standardPrice = getFieldValue('standardPrice');

  useEffect(() => {
    if (record) {
      const item = _.get(record, 'itemMasterDetail');
      itemCodeList.push(item);
      itemNameList.push(item);
      setItemCodeList(itemCodeList);
      setItemNameList(itemNameList);
      setSelectItem(item);
    }
  }, [record]);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        code: searhByCode,
        indexStart: 0,
        indexEnd: codeEnd,
        categoryCode: 'product',
      };
      const response = await getItemsCode(payload);
      console.log('responseItemCode', response);
      const list = _.get(response.data.data, 'itemMasterList');
      setItemCodeList(_.uniqBy(_.concat(itemCodeList, list), 'itemMasterId'));
    };

    getItemCode();
  }, [debouncCode, codeEnd]);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        name: searhByName,
        indexStart: 0,
        indexEnd: nameEnd,
        categoryCode: 'product',
      };
      const response = await getItemsMaster(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setItemNameList(_.uniqBy(_.concat(itemNameList, list), 'itemMasterId'));
    };

    getItemCode();
  }, [debounceName, nameEnd]);

  useEffect(() => {
    const getLocationForStock = async () => {
      const payload = {
        itemMasterDetail: selectItem,
        warehouseId: warehouseId,
      };
      const response = await getLocationByItem(payload);
      setBinLocationList(_.get(response.data.data, 'binLocationList'));
    };
    if (selectItem) {
      getLocationForStock();
    }
  }, [selectItem]);

  useEffect(() => {
    if (_.toNumber(extraCost) > -1) {
      let sum = _.toNumber(standardCost) + _.toNumber(extraCost);
      setFieldsValue({ totalCost: sum });
    }
  }, [extraCost, standardCost]);

  useEffect(() => {
    if (_.toNumber(extraPrice) > -1) {
      let sum = _.toNumber(standardPrice) + _.toNumber(extraPrice);
      setFieldsValue({ totalPrice: sum });
    }
  }, [extraPrice, standardPrice]);

  const handleSelectCode = (value) => {
    resetFields(['extraCost', 'extraPrice', 'max', 'min', 'time', 'bin']);
    if (value) {
      const filterArr = itemCodeList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemName: _.get(filterArr[0], 'itemName'),
        standardCost: parseFloat(_.get(filterArr[0], 'itemStandardCost')).toFixed(2),
        totalCost: parseFloat(_.get(filterArr[0], 'itemStandardCost')).toFixed(2),
        standardPrice: parseFloat(_.get(filterArr[0], 'itemStandardPrice')).toFixed(2),
        totalPrice: parseFloat(_.get(filterArr[0], 'itemStandardPrice')).toFixed(2),
      });
    }
  };

  const handleSelectName = (value) => {
    resetFields(['extraCost , extraPrice', 'max', 'min', 'time', 'bin']);
    if (value) {
      const filterArr = itemNameList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemCode: _.get(filterArr[0], 'itemCode'),
        standardCost: parseFloat(_.get(filterArr[0], 'itemStandardCost')).toFixed(2),
        totalCost: parseFloat(_.get(filterArr[0], 'itemStandardCost')).toFixed(2),
        standardPrice: parseFloat(_.get(filterArr[0], 'itemStandardPrice')).toFixed(2),
        totalPrice: parseFloat(_.get(filterArr[0], 'itemStandardPrice')).toFixed(2),
      });
    }
  };

  const handleSearchCode = _.debounce((value) => {
    setSearchByCode(value || '');
  }, 500);

  const handleSearchName = _.debounce((value) => {
    setSearchByName(value || '');
  }, 500);

  const onOk = (open) => {
    validateFields(async (err, values) => {
      if (err) {
        console.log('err', err);

        return;
      }

      console.log('err', values);

      if (values) {
        console.log('onOk', values);
        const payload = {
          warehouseId: warehouseId,
          itemMasterDetail: selectItem,
          maxQty: payloadToNumber(_.get(values, 'max')) || 0,
          minQty: payloadToNumber(_.get(values, 'min')) || 0,
          leadTime: payloadToNumber(_.get(values, 'time')) || 0,
          remark: '',
          binLocationIdList: payloadToNumber(_.get(values, 'bin')) || [],
          standardCost: payloadToNumber(_.get(values, 'standardCost')) || 0,
          extraCost: payloadToNumber(_.get(values, 'extraCost')) || 0,
          totalCost: payloadToNumber(_.get(values, 'totalCost')) || 0,
          standardPrice: payloadToNumber(_.get(values, 'standardPrice')) || 0,
          extraPrice: payloadToNumber(_.get(values, 'extraPrice')) || 0,
          totalPrice: payloadToNumber(_.get(values, 'totalPrice')) || 0,
        };
        console.log('payload', payload);
        // return;
        if (_.get(record, '_id')) {
          try {
            const response = await updateWarehouseStock(_.get(record, '_id'), payload);
            console.log('updateWarehouseStock', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              setVisible(open);
              utilResetValue();
              setEvent((current) => !current);
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {}
        } else {
          try {
            const response = await saveWarehouseStock(payload);
            console.log('saveWarehouseStock', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              setVisible(open);
              utilResetValue();
              setEvent((current) => !current);
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {}
        }
      }
    });
  };

  const payloadToNumber = (value) => {
    return _.toNumber(value);
  };

  const utilResetValue = () => {
    setTrigger((current) => !current);

    setTriggerStock((c) => !c);
    setSelectItem();
    setBinLocationList([]);
    // setItemCodeList([]);
    // setItemNameList([]);
    resetFields();
    setRecord();
    setCodeEnd(10);
  };

  const onCancel = () => {
    setVisible(false);
    // setItemCodeList([]);
    // setItemNameList([]);
    resetFields();
    setSelectItem();
    setRecord();
  };

  const validatorMinQty = (rule, value, callback) => {
    let max = getFieldValue('max');
    if (payloadToNumber(value) > payloadToNumber(max)) {
      callback(
        intl.formatMessage({ id: 'modalAssignWareHouseCallbackMinNotBeLessMax', defaultMessage: 'Min Qty must not be more than Max Qty' })
      );
    }
    callback();
  };

  const validatorMaxQty = (rule, value, callback) => {
    let min = getFieldValue('min');
    if (payloadToNumber(value) < payloadToNumber(min)) {
      callback(
        intl.formatMessage({ id: 'modalAssignWareHouseCallbackMaxNotBeLessMin', defaultMessage: 'Max Qty must not be less than Min Qty' })
      );
    }
    callback();
  };

  const onScrollCode = (e) => {
    const target = e.target;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      setCodeEnd((prevValue) => prevValue + 10);
    }
  };

  const onScrollName = (e) => {
    const target = e.target;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      setNameEnd((prevValue) => prevValue + 10);
    }
  };

  return (
    <Modal
      title={
        _.get(record, '_id')
          ? intl.formatMessage({ id: `warehouseTitleStockEditItem`, defaultMessage: 'Edit Item' })
          : intl.formatMessage({ id: `warehouseTitleStockAddItem`, defaultMessage: 'Add Item' })
      }
      visible={visible}
      centered
      width={700}
      bodyStyle={{ padding: '6px 24px 24px 24px' }}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => onOk(false)}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
        _.get(record, '_id') ? null : (
          <Button_01 key="continue" style={{ margin: '0px 0px 0px 10px' }} type="primary" btnsize="wd_at" onClick={() => onOk(true)}>
            <FormattedMessage id="btnSaveAndContinue" defaultMessage="Save and Continue" />
          </Button_01>
        ),
      ]}
    >
      <Form colon={false}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'stockLabelItemCode', defaultMessage: 'Item Code' })} req={true} />
                  }
                >
                  {getFieldDecorator('itemCode', {
                    initialValue: _.get(record, '_id') ? _.get(record.itemMasterDetail, 'itemMasterId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'reqStockLabelItemCode', defaultMessage: 'Please select item code' }),
                      },
                    ],
                  })(
                    <Select
                      onSearch={handleSearchCode}
                      onSelect={(value, evnt) => handleSelectCode(value, evnt)}
                      onPopupScroll={onScrollCode}
                      // dataSource={itemCodeList}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={intl.formatMessage({ id: 'placeholderStockLabelItemCode', defaultMessage: 'Select Item Code' })}
                    >
                      {itemCodeList &&
                        itemCodeList.map((item) => (
                          <Option key={_.get(item,'itemCode')} value={_.get(item,'itemMasterId')}>
                            {_.get(item,'itemCode')}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'stockLabelItemName', defaultMessage: 'Item Name' })} req={true} />
                  }
                >
                  {getFieldDecorator('itemName', {
                    initialValue: _.get(record, '_id') ? _.get(record.itemMasterDetail, 'itemMasterId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'reqStockLabelItemName', defaultMessage: 'Please select item Name' }),
                      },
                    ],
                  })(
                    <Select
                      onSearch={handleSearchName}
                      onSelect={handleSelectName}
                      onPopupScroll={onScrollName}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={intl.formatMessage({ id: 'placeholderStockLabelItemName', defaultMessage: 'Select Item Name' })}
                    >
                      {itemNameList &&
                        itemNameList.map((item, index) => (
                          <Option key={_.get(item,'itemCode')} value={_.get(item,'itemMasterId')}>
                            {_.get(item,'itemName')}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'stockLabelStandardCost', defaultMessage: 'Standard Cost' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('standardCost', {
                    initialValue: _.get(record, 'standardCost') ? _.get(record, 'standardCost') : 0,
                    rules: [],
                  })(<Input onKeyDown={handleKeyDownDecimal2Fixed} disabled={true} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'stockLabelExtraCost', defaultMessage: 'Extra Cost' })} req={false} />
                  }
                >
                  {getFieldDecorator('extraCost', {
                    initialValue: _.get(record, 'extraCost') ? _.get(record, 'extraCost') : 0,
                    rules: [],
                  })(
                    <InputNumber
                      className="disabled-handle-count"
                      min={0}
                      step={0.01}
                      max={9999999999}
                      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onKeyDown={handleKeyDownDecimal2Fixed}
                      disabled={getFieldValue('itemCode') || getFieldValue('itemName') ? false : true}
                      placeholder={intl.formatMessage({
                        id: 'placeholderStockLabelExtraCost',
                        defaultMessage: 'Enter Extra Cost',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'stockLabelTotalCost', defaultMessage: 'Total Cost' })} req={false} />
                  }
                >
                  {getFieldDecorator('totalCost', {
                    initialValue: _.get(record, 'totalCost') ? _.get(record, 'totalCost') : 0,
                    rules: [],
                  })(
                    <InputNumber
                      className="disabled-handle-count"
                      min={0}
                      step={0.01}
                      max={9999999999}
                      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onKeyDown={handleKeyDownDecimal2Fixed}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'stockLabelStandardPrice', defaultMessage: 'Standard Price' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('standardPrice', {
                    initialValue: _.get(record, 'standardPrice') ? _.get(record, 'standardPrice') : 0,
                    rules: [],
                  })(
                    <InputNumber
                      className="disabled-handle-count"
                      min={0}
                      step={0.01}
                      max={9999999999}
                      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onKeyDown={handleKeyDownDecimal2Fixed}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'stockLabelExtraPrice', defaultMessage: 'Extra Price' })} req={false} />
                  }
                >
                  {getFieldDecorator('extraPrice', {
                    initialValue: _.get(record, 'extraPrice') ? _.get(record, 'extraPrice') : 0,
                    rules: [],
                  })(
                    <InputNumber
                      className="disabled-handle-count"
                      min={0}
                      step={0.01}
                      max={9999999999}
                      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onKeyDown={handleKeyDownDecimal2Fixed}
                      disabled={getFieldValue('itemCode') || getFieldValue('itemName') ? false : true}
                      placeholder={intl.formatMessage({
                        id: 'placeholderStockLabelExtraPrice',
                        defaultMessage: 'Enter Extra Price',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'stockLabelTotalPrice', defaultMessage: 'Total Price' })} req={false} />
                  }
                >
                  {getFieldDecorator('totalPrice', {
                    initialValue: _.get(record, 'totalPrice') ? _.get(record, 'totalPrice') : 0,
                    rules: [],
                  })(
                    <InputNumber
                      className="disabled-handle-count"
                      min={0}
                      step={0.01}
                      max={9999999999}
                      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onKeyDown={handleKeyDownDecimal2Fixed}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'stockLabelMinQty', defaultMessage: 'Min Qty' })} req={true} />}
                >
                  {getFieldDecorator('min', {
                    initialValue: _.get(record, 'minQty') ? _.get(record, 'minQty') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqMinQty', defaultMessage: 'Please enter Min Qty' }),
                      },
                      {
                        validator: validatorMinQty,
                      },
                    ],
                  })(
                    <Input
                      onKeyDown={handleKeyDownNumber}
                      placeholder={intl.formatMessage({
                        id: 'placeholderStockLabelMinQty',
                        defaultMessage: 'Enter Min Qty',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'stockLabelMaxQty', defaultMessage: 'Max Qty' })} req={true} />}
                >
                  {getFieldDecorator('max', {
                    initialValue: _.get(record, 'maxQty') ? _.get(record, 'maxQty') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqMaxQty', defaultMessage: 'Please enter Max Qty' }),
                      },
                      {
                        validator: validatorMaxQty,
                      },
                    ],
                  })(
                    <Input
                      onKeyDown={handleKeyDownNumber}
                      placeholder={intl.formatMessage({
                        id: 'placeholderStockLabelMaxQty',
                        defaultMessage: 'Enter Max Qty',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'stockLabelLeadTime', defaultMessage: 'Lead Time (Day)' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('time', {
                    initialValue: _.get(record, 'leadTime') ? _.get(record, 'leadTime') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqLeadTime', defaultMessage: 'Please Lead Time' }),
                      },
                    ],
                  })(
                    <Input
                      onKeyDown={handleKeyDownNumber}
                      placeholder={intl.formatMessage({
                        id: 'placeholderStockLabelLeadTime',
                        defaultMessage: 'Enter Lead Time',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'stockLabelBinLocation', defaultMessage: 'Bin Location' })}
                      req={false}
                    />
                  }
                >
                  {console.log('warehouseBin1', _.get(record, 'binLocation'))}
                  {getFieldDecorator('bin', {
                    initialValue: _.get(record, 'binLocation') ? _.map(_.get(record, 'binLocation'), (item) => item.binLocationId) : [],
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: 'warehouseModalReqBinLocation', defaultMessage: 'Please select Bin Location' }),
                      },
                    ],
                  })(
                    <Select
                      disabled={getFieldValue('itemCode') || getFieldValue('itemName') ? false : true}
                      placeholder={intl.formatMessage({
                        id: 'warehouseModalReqNamePlaceholderBinLocation',
                        defaultMessage: 'Enter Bin Location',
                      })}
                      maxTagCount={1}
                      mode="multiple"
                      optionLabelProp="label"
                      showSearch
                      filterOption={(input, option) =>
                        option.props.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {binLocationList &&
                        binLocationList.map((item) => (
                          <Option key={item.binLoationId} value={item.binLoationId} label={item.name}>
                            {console.log('warehouseBin', item)}
                            {_.get(item, 'name')}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const WarehouseStockModalForm = Form.create({
  name: 'warehouse-form',
})(WarehouseStockModal);

export default WarehouseStockModalForm;
