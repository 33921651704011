
import React, { useEffect, useContext } from 'react';
import { language } from '../language/language2';
import { useKeycloak } from '@react-keycloak/web';
import { PageSettings } from '../config/main-settings';
import _ from 'lodash';



export default () => {
  const { keycloak } = useKeycloak();
  const { setMessageValue } = useContext(PageSettings);

  useEffect(() => {
    async function fetchData() {
      const response = await language();
      setMessageValue(response);
    }
    fetchData();
  }, [keycloak.authenticated]);

  return;
}
