import React, { useEffect, useState } from 'react'
import ViewInformation from './information-view'
import EditInformation from './information-edit'
import { Form } from 'antd'
import _ from 'lodash'

const FormModal = (props) => {
  const { view, form, value, fnc } = props
  const { cutomeFieldData, informationData, markerPointView, polylineDataView, addressData } = value
  const { } = fnc

  return (
    <div className='scroll-task-detail'>
      {
        view === 'view' ?
          <ViewInformation
            data={informationData}
            form={form}
            cutomeFieldData={cutomeFieldData}
            markerPointView={markerPointView}
            polylineDataView={polylineDataView}
            addressData={addressData}
          /> :
          <EditInformation
          />
      }
    </div>
  )
}

const InformationForm = Form.create({
  name: "modal_form",
})(FormModal);

export default InformationForm;

