import httpClient from '../../components/axiosClient';

const addPositionMaster = async (props) => {

  const comCode = localStorage.getItem('comCode');

  // console.log('addPositionMaster props::: ', props);

  if (props.positionName) {

    const body = {
      positionName: props.positionName
    }

    try {
      const response = await httpClient.post(`/v4/resource/manager/company/${comCode}/position-master`, body);
      if (response.status === 200) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error.response;
    }
  }
}

export default addPositionMaster;