import firebase from 'firebase/app'
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBKDWdImHsPTpRC53kloyXRX_PDaVEK_RA",
  authDomain: "rapidwork-c81c3.firebaseapp.com",
  databaseURL: "https://rapidwork-c81c3-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "rapidwork-c81c3",
  storageBucket: "rapidwork-c81c3.appspot.com",
  messagingSenderId: "119591296849",
  appId: "1:119591296849:web:6f0af5979e0034443d3d70"
};

firebase.initializeApp(firebaseConfig);

export const dbInital = firebase.database();
