import React, { useState, useEffect, useRef } from 'react';
import { Modal, Tag, Card, Row, Col, Avatar, BackTop, Icon, Button } from 'antd';
import UserAllSelect from '../assetcomponent/user-all-select';
import ActionAllSelect from '../assetcomponent/action-all-select';
import AssetDateSelect from '../assetcomponent/date-all-select';
import Button01 from '../../../../components/v2/button_01';
import httpClient from '../../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import HistoryNodata from '../assetcomponent/no-asset-history';
import './css/index.css';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactBnbGallery from 'react-bnb-gallery';
import { Preview01, Preview02, Preview03, Preview04, Preview05, PreviewPlus } from '../assetcomponent/preview-picture';
// const [historyDateFilter, sethistoryDateFilter] = useState([moment().startOf('month'), moment().endOf('month')]);

const ViewAssetHistory = (props) => {
  const { visible, assetNo, onOk, onCancel, users } = props;
  const dateFormat = 'YYYY-MM-DD';
  const dateStart = moment().startOf('month');
  const dateEnd = moment().endOf('month');
  const scrollRef = useRef();
  const [userCreateBy, setuserCreateBy] = useState();
  const [actionSelected, setActionSelected] = useState();
  const [dateFilterSelectedStart, setDateFilterSelectedStart] = useState(dateStart.format(dateFormat));
  const [dateFilterSelectedEnd, setDateFilterSelectedEnd] = useState(dateEnd.format(dateFormat));
  const [historyDateFilterStart, setHistoryDateFilterStart] = useState([dateStart, dateEnd]);
  const [allDataList, setAllDataList] = useState();
  const [visibleX, setVisibleX] = useState(false);
  const intl = useIntl();
  const [OpenGallery, setOpenGallery] = useState(false);
  const [galleryImg, setGalleryImg] = useState([]);
  const [numberImage, setNumberImage] = useState(0);

  useEffect(() => {
    console.log('-------- useEffect ViewAssetHistory --------');
    setuserCreateBy('');
    setActionSelected('all');
    setDateFilterSelectedStart(dateStart.format(dateFormat));
    setDateFilterSelectedEnd(dateEnd.format(dateFormat));
    setHistoryDateFilterStart([moment().startOf('month'), moment().endOf('month')]);
    // setAllDataList([]);
  }, [onCancel]);

  useEffect(() => {
    getAllActionModalPage();
  }, [assetNo, userCreateBy, actionSelected, dateFilterSelectedStart, dateFilterSelectedEnd]);

  const getAllActionModalPage = async () => {
    // if (!userCreateBy) {
    //   return 'all';
    // }
    const payload = {
      indexStart: 0,
      // indexEnd: 2,
      actionType: actionSelected ? actionSelected : 'all',
      createdBy: userCreateBy ? userCreateBy.toString() : '',
    };
    const datetopack = `${dateFilterSelectedStart}/to/${dateFilterSelectedEnd}`;
    const result = await httpClient.post(`/v3/log/manager/asset/${assetNo}/logs/list/from/${datetopack}`, payload);
    if (result.status === 200) {
      // const assetResult = _.get(result, 'data.data.list')
      const assetResult = _.get(result, 'data.data.list');
      // const cardDataList = _.map(_.get(assetResult, 'data.list'))
      setAllDataList(assetResult);
      console.log('xxxxxxxxxxxxxxxxxx', assetResult);
    }
  };

  const handlePreviewImage = (page, data, itemData) => {
    // fnc.handleOpenViewModuleAction('post', postData.postId);
    console.log('itemxxxxxx', data);

    const mapPhoto =
      data && _.size(data) !== 0
        ? data.map((item, index) => {
          return {
            photo: _.get(item, 'url'),
            number: index,
            caption: _.get(item, 'description'),
            subcaption: `Noted By ${_.get(itemData, 'OverviewTxtRow1')}`,
            thumbnail: _.get(item, 'url'),
          };
        })
        : [];
    setGalleryImg(mapPhoto);
    setOpenGallery(true);
    setNumberImage(page);
  };

  const fncPreview = (imageList, item) => {
    let sum = imageList.length;
    switch (sum) {
      case 1:
        return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
    }
  };

  const AddressMap = (data) => {
    console.log('AddressMap', data.data);
    const { address, lat, lng } = data.data;
    return (
      <div className="show-address" onClick={() => handleAddress({ lat, lng })}>
        - at {address}
      </div>
    );
  };

  const handleAddress = ({ lat, lng }) => {
    if (lat && lng) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${lat}, ${lng}`, '_blank');
    }
  };

  const allCardDataList = _.map(allDataList, (item, index) => {
    console.log('allDataList', allDataList);

    const ShowAddress = allDataList.map && allDataList.map((item) => (item.logDetail.address ? `- at ${item.logDetail.address}` : ''));

    if (item.txtDisplayRow3.length === 4) {
      console.log('leng=4');
      if (item.action === 'edit') {
        console.log('length ', item.txtDisplayRow3.length);
        console.log('edit>4');
        if (item.txtDisplayRow3[0].style === 'normal') {
          return (
            <Card Card width={1200} style={{ marginBottom: 10 }} key={`${index}`}>
              <div className="main-line">
                <div className="line-histort-img">
                  <img src={item.profileImage} />
                </div>
                <div>
                  <div className="line-histort1">
                    <div style={{ marginRight: '10px', marginTop: 'none' }}>{item.OverviewTxtRow1}</div>
                    <div style={{ marginRight: '10px' }}>{item.OverviewTxtRow2 || 'Information Technology'}</div>
                    <div style={{ marginRight: '10px' }} className="txt-bold">
                      {item.txtDisplayRow1[1].value}
                    </div>
                    <div style={{ marginRight: '10px' }}>{item.txtDisplayRow1[2].value}</div>
                  </div>
                  <div className="line-histort2">
                    <div style={{ marginRight: '10px' }}>{item.txtDisplayRow2[0].value}</div>
                  </div>
                </div>
              </div>
              <Row>
                <div>
                  <span style={{ marginRight: '10px' }}>{item.txtDisplayRow3[0].value}</span>
                  <span style={{ marginRight: '10px' }} className="txt-bold">
                    {item.txtDisplayRow3[1].value}
                  </span>
                  <span style={{ marginRight: '10px' }}>{item.txtDisplayRow3[2].value}</span>
                  <span style={{ marginRight: '10px' }} className="txt-bold">
                    {item.txtDisplayRow3[3].value}
                  </span>
                </div>
              </Row>
            </Card>
          );
        }
      }
    }
    if (item.txtDisplayRow3.length === 2) {
      console.log('leng = 2');
      if (item.action === 'edit') {
        console.log('leng=2 edit=2');
        return (
          <Card Card width={1200} style={{ marginBottom: 10 }} key={`${index}`}>
            <div className="main-line">
              <div className="line-histort-img">
                <img src={item.profileImage} />
              </div>
              <div>
                <div className="line-histort1">
                  <div style={{ marginRight: '10px', marginTop: 'none' }}>{item.OverviewTxtRow1}</div>
                  <div style={{ marginRight: '10px' }}>{item.OverviewTxtRow2 || 'Information Technology'}</div>
                  <div style={{ marginRight: '15px' }} className="txt-bold">
                    {item.txtDisplayRow1[1].value}
                  </div>
                  <div style={{ marginRight: '10px' }}>{item.txtDisplayRow1[2].value}</div>
                </div>
                <div className="line-histort2">
                  <div style={{ marginRight: '10px' }}>{item.txtDisplayRow2[0].value}</div>
                </div>
                <div style={{ marginRight: '10px' }}></div>
              </div>
            </div>
            <Row>
              <div>
                <span style={{ marginRight: '10px' }}>{item.txtDisplayRow3[0].value}</span>
                <span style={{ marginRight: '10px' }} className="txt-bold">
                  {item.txtDisplayRow3[1].value}
                </span>
              </div>
            </Row>
          </Card>
        );
      }
    }
    if (item.action === 'borrow') {
      console.log('leng<4 borrow<4');
      return (
        <Card Card width={1200} style={{ marginBottom: 10 }} key={`${index}`}>
          <div className="main-line">
            <div className="line-histort-img">
              <img src={item.profileImage} />
            </div>
            <div>
              <div className="line-histort1">
                <div style={{ marginRight: '10px', marginTop: 'none' }}>{item.OverviewTxtRow1}</div>
                <div style={{ marginRight: '10px' }}>{item.OverviewTxtRow2 || 'Information Technology'}</div>
                <div style={{ marginRight: '10px' }} className="txt-bold">
                  {item.txtDisplayRow1[1].value}
                </div>
                <div style={{ marginRight: '10px' }}>{item.txtDisplayRow1[2].value}</div>
              </div>
              <div className="line-histort2">
                <div style={{ marginRight: '10px' }}>{item.txtDisplayRow2[0].value}</div>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Tag className="status-tag" color={item.txtDisplayRow3[0].color}>
                  {item.txtDisplayRow3[0].value}
                </Tag>
              </div>
            </div>
          </div>
          <Row>
            <div>
              <div className="show-action-description">{item.logDetail.requestComment}</div>
              {item.logDetail.address && <AddressMap data={item.logDetail} />}
              {/* <div className="show-address"> */}
              {/* <p
                  onClick={() => handleAddress({
                    addressLat: item.logDetail.lat,
                    addressLng: item.logDetail.lng,
                    addressName: item.logDetail.address,
                  })}
                >{ShowAddress}</p> */}

              {/* {ShowAddress} */}
              {/* </div> */}
              <div>{allDataList ? fncPreview(item.logDetail.requestAttachment, item) : null}</div>
              <ReactBnbGallery
                show={OpenGallery}
                photos={galleryImg}
                onClose={() => setOpenGallery(false)}
                activePhotoIndex={numberImage}
              />
            </div>
          </Row>
        </Card>
      );
    } else {
      console.log('leng<4');
      if (item.action === 'edit') {
        console.log('leng<4 edit<4');
        return (
          <Card Card width={1200} style={{ marginBottom: 10 }} key={`${index}`}>
            <div className="main-line">
              <div className="line-histort-img">
                <img src={item.profileImage} />
              </div>
              <div>
                <div className="line-histort1">
                  <div style={{ marginRight: '10px', marginTop: 'none' }}>{item.OverviewTxtRow1}</div>
                  <div style={{ marginRight: '10px' }}>{item.OverviewTxtRow2 || 'Information Technology'}</div>
                  <div style={{ marginRight: '10px' }} className="txt-bold">
                    {item.txtDisplayRow1[1].value}
                  </div>
                  <div style={{ marginRight: '10px' }}>{item.txtDisplayRow1[2].value}</div>
                </div>
                <div className="line-histort2">
                  <div style={{ marginRight: '10px' }}>{item.txtDisplayRow2[0].value}</div>
                </div>
                <div style={{ marginRight: '10px' }}></div>
              </div>
            </div>
            <Row>
              <div>
                <span style={{ marginRight: '10px' }}>{item.txtDisplayRow3[0].value}</span>
              </div>
            </Row>
          </Card>
        );
      }
      console.log('leng<4 other<4');
      console.log('length ', item.txtDisplayRow3.length);
      return (
        <Card Card width={1200} style={{ marginBottom: 10 }} key={`${index}`}>
          <div className="main-line">
            <div className="line-histort-img">
              <img src={item.profileImage} />
            </div>
            <div>
              <div className="line-histort1">
                <div style={{ marginRight: '10px', marginTop: 'none' }} className="txt-bold">
                  {item.OverviewTxtRow1}
                </div>
                <div style={{ marginRight: '10px' }}>{item.OverviewTxtRow2 || 'Information Technology'}</div>
                <div style={{ marginRight: '10px' }} className="txt-bold">
                  {item.txtDisplayRow1[1].value}
                </div>
                <div style={{ marginRight: '10px' }}>{item.txtDisplayRow1[2].value}</div>
              </div>
              <div className="line-histort2">
                <div style={{ marginRight: '10px' }}>{item.txtDisplayRow2[0].value}</div>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Tag className="status-tag" color={item.txtDisplayRow3[0].color}>
                  {item.txtDisplayRow3[0].value}
                </Tag>
              </div>
            </div>
          </div>
          <Row>
            <div>
              <div className="show-action-description">{item.logDetail.requestComment}</div>
              {item.logDetail.address && <AddressMap data={item.logDetail} />}
              {/* <div className="show-address"> */}
              {/* <p
                  onClick={() => handleAddress({
                    addressLat: item.logDetail.lat,
                    addressLng: item.logDetail.lng,
                    addressName: item.logDetail.address,
                  })}
                >{ShowAddress}</p> */}
              {/* {ShowAddress} */}
              {/* </div> */}
              <div>{allDataList ? fncPreview(item.logDetail.requestAttachment, item) : null}</div>
              <ReactBnbGallery
                show={OpenGallery}
                photos={galleryImg}
                onClose={() => setOpenGallery(false)}
                activePhotoIndex={numberImage}
              />
            </div>
          </Row>
        </Card>
      );
    }
  });

  const toggleVisible = (x) => {
    const scrolled = scrollRef.current.scrollTop;
    if (scrolled > 300) {
      setVisibleX(true);
    } else if (scrolled <= 300) {
      setVisibleX(false);
    }
  };

  window.addEventListener('scroll', toggleVisible);

  const scrollToTop = () => {
    scrollRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const ButtonBackToTop = () => {
    return (
      <div style={{ display: visibleX ? 'inline' : 'none' }} className="fab-container">
        <Button size="large" className="fab-button-claim" shape="circle" icon="arrow-up" onClick={scrollToTop} />
      </div>
    );
  };

  // const historycard = _.map(_.get(result, 'data.data'), (item) => {
  //   return {
  //     memComId: _.get(item, 'mem_com_id'),
  //     fullname: _.get(item, 'fullname'),
  //     phone: _.get(item, 'phone')
  //   };
  // });

  return (
    <Modal
      // className="ant-modal-body tre"
      title={<FormattedMessage id="historyModalTitle" defaultMessage="History" />}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width={1000}
      centered
      style={{
        top: 1,
      }}

      footer={[
        <Button01 type="primary" onClick={onCancel}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
      bodyStyle={{
        height: '550px',

        // overflowY: 'auto',
        padding: 0,

      }}
    >
      {visible ? (
        <div
          style={{
            background: '#f2f3f5',
            paddingTop: '10px',
            paddingRight: '30px',
            paddingBottom: '10px',
            paddingLeft: '24px',
            height: '550px',
            // maxHeight: '586px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Card width={1200} style={{ marginBottom: 10 }}>
            <Row>
              <Col span={8} style={{ width: '260px' }}>
                <UserAllSelect setuserCreateBy={setuserCreateBy} />
              </Col>
              <Col span={4} style={{ width: '160px' }}>
                <ActionAllSelect assetNo={assetNo} setActionSelected={setActionSelected} />
              </Col>
              <Col span={6} offset={6}>
                <AssetDateSelect
                  setDateFilterSelectedStart={setDateFilterSelectedStart}
                  setDateFilterSelectedEnd={setDateFilterSelectedEnd}
                  historyDateFilterStart={historyDateFilterStart}
                  setHistoryDateFilterStart={setHistoryDateFilterStart}
                />
              </Col>
            </Row>
          </Card>

          {_.size(allDataList) > 0 ? (
            <div ref={scrollRef} style={{ overflowY: 'scroll' }} onScroll={toggleVisible}>
              {allCardDataList} <ButtonBackToTop />
            </div>
          ) : (
            <HistoryNodata />
          )}
        </div>
      ) : null}
    </Modal>
  );
};

export default ViewAssetHistory;
