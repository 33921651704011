import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Divider, Select, AutoComplete, InputNumber, Radio, Icon } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import { handleKeyDownDecimal2Fixed } from '../../component-function/fnc-number';
import { getUoMData } from '../../controllers/item/getUomByCategory';
import { numberFormatter, numberParser } from '../../component-function/fnc-inputnumber-currency';

const { TextArea } = Input;

const CreateItemMaster = ({
  form,
  record,
  dataDefault,
  setBrandId,
  dataModel,
  setUomOption,
  uomOption,
  handleCategoryProduct,
  handleCategoryService,
  proCheck,
  serCheck,
  handleOpenGroupModal,
  createItem,
  setCreateItem,
  setBrandModal,
  groupTypeId,
  typeModal,
  warehouseData,
  handleCustomerPopupScroll,
  handleSearchWarhouse,
  visible,
}) => {
  const { Option } = Select;
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  // const { warehouseData, handleCustomerPopupScroll, handleSearchWarhouse } = warehouseOJ;
  const itemGroupCode = _.get(dataDefault, 'itemGroup');
  const itemTypeCode = _.get(dataDefault, 'itemType');
  const itemBrand = _.get(dataDefault, 'itemBrand');
  const pickingType = _.get(dataDefault, 'pickingType');
  const uomCategory = _.get(dataDefault, 'uomCategory');
  const uomCategoryForm = getFieldValue('uomCategory');
  const [checkGroup, setCheckGroup] = useState(false);

  const x = getFieldValue('brandName') ? getFieldValue('brandName') : '';

  // console.log('setModalForm', createItem, record, dataModel, itemBrand, _.size(_.filter(itemBrand, (o) => o.itemBrandId === _.get(record, 'brandId'))), _.filter(itemBrand, (o) => o.itemBrandId === _.get(record, 'brandId')))

  useEffect(() => {
    setBrandId(x);
    if (x === '') {
      setFieldsValue({ ['modelName']: undefined });
      // setCreateItem({});
    }
  }, [getFieldValue('brandName')]);

  useEffect(() => {
    if (_.get(createItem, 'brandId')) {
      setFieldsValue({ ['brandName']: _.get(createItem, 'brandId') });
    }
    if (_.get(createItem, 'modelId')) {
      setFieldsValue({ ['modelName']: _.get(createItem, 'modelId') });
    }
  }, [createItem]);

  useEffect(() => {
    if (getFieldValue('brandName') && _.get(record, 'itemModelId')) {
      setFieldsValue({ ['modelName']: _.get(record, 'itemModelId') });
    }
  }, [_.get(record, 'itemModelId')]);

  useEffect(() => {
    const handleGetUoM = async () => {
      const response = await getUoMData(uomCategoryForm);
      setUomOption(_.get(response, 'data'));
    };
    if (uomCategoryForm) {
      handleGetUoM();
    }
  }, [uomCategoryForm]);

  
  useEffect(() => {
    const handleSelectModel = (key, opt) => {
      const tempGroupData = _.filter(itemGroupCode,(col) => {return col.itemGroupId === _.get(record,'itemGroupId')});
      const tempTypeData = _.filter(itemTypeCode,(col) => {return col.typeId === _.get(record,'itemTypeId')});
      console.log('tempGroupData',_.get(record, 'itemTypeCode'),'/record',record);
      if (_.size(tempGroupData) <= 0) {
        setCheckGroup(true)
        setFieldsValue({ ['itemGroupCode']: '-' + ' ' + _.get(record, 'itemGroupName') });
      }
      else {
        setFieldsValue({ ['itemGroupCode']: _.get(record, 'itemGroupId') });
      }
      if (_.size(tempTypeData) <= 0) {
        setCheckGroup(true)
        setFieldsValue({ ['itemTypeCode']: '-' + ' ' + _.get(record, 'itemTypeName') });
      }
      else {
        setFieldsValue({ ['itemTypeCode']: _.get(record, 'itemTypeId') });
      }
      //
    };
    console.log('sizeRecord',_.size(record),'/record',record);
    if (record  && visible === true) {
      
      handleSelectModel();
    }
  }, [record,visible]);

   

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
      </span>
    );
  };

  const children = _.size(dataModel) || _.get(record,'modelName') !== null? (
      _.map(dataModel, (item) => {
      if (item.itemModelId && item.itemModelId !== null) {
        return (
          <Option key={item.itemModelId} value={item.itemModelId}>
            {item.itemModelNo + ' ' + item.itemModelName}
          </Option>
        );
      } else {
        return null;
      }
    }) 
  // ) 
  // : item.itemModelId && item.itemModelId !== null ? (
  //   <Option key={_.get(record, 'modelId')} value={_.get(record, 'modelId')}>
  //     {_.get(record, 'itemModelNo') + ' ' + _.get(record, 'modelName')}
  //   </Option>
  ) : null;

  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {
        e.preventDefault();
      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => {
          return o.charCodeAt(0) === 46 ? 1 : 0;
        });
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }
    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  };

  const handleSelectModel = (key, opt) => {
    const brandData = dataModel && dataModel.filter((col) => key.includes(col.itemModelId));
    setFieldsValue({ ['brandName']: _.get(brandData[0], 'itemBrandId') });
  };

  const onSelectChange = (e) => {
    if (e) {
      setFieldsValue({ ['modelName']: undefined });
    }
  };

  
 

  return (
    <div>
      <Form layout="vertical">
        <div className="padding-div-top">
          <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextItemCode" defaultMessage="Item Code" />
                <LabeRequire req={true} />
              </span>
              <Form.Item className="error-text-modal">
                {getFieldDecorator('itemCode', {
                  initialValue: record && record.itemCode ? record.itemCode : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalItemMasterFormItemCodeValidate', defaultMessage: 'Please enter item code' }),
                    },
                  ],
                })(
                  <Input
                    // className="input-header-style"
                    size="medium"
                    placeholder={intl.formatMessage({ id: 'modalItemMasterFormItemCode', defaultMessage: 'Enter Item Code' })}
                    maxLength={30}
                    autoComplete="off"
                  />
                )}
                {/* <LabeRequire
                // req={true}
                /> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextItemName" defaultMessage="Item Name" />
                <LabeRequire req={true} />
              </span>
              <Form.Item className="error-text-modal">
                {getFieldDecorator('itemName', {
                  initialValue: record && record.itemName ? record.itemName : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidate', defaultMessage: 'Please enter item name' }),
                    },
                  ],
                })(
                  <Input
                    // className="input-header-style"
                    size="medium"
                    placeholder={intl.formatMessage({ id: 'modalItemMasterFormItemName', defaultMessage: 'Enter Item Name' })}
                    maxLength={255}
                    autoComplete="off"
                  ></Input>
                )}
                {/* <LabeRequire
                //  req={true} 
                /> */}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextItemGroup" defaultMessage="Item Group" />
                <LabeRequire req={true} />
              </span>
              <Form.Item className="error-text-modal">
                {getFieldDecorator('itemGroupCode', {
                  initialValue: _.get(groupTypeId, 'groupId')
                    ? _.get(groupTypeId, 'groupId')
                    : record && record.itemGroupId
                    ? record.itemGroupId
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'modalItemMasterFormItemValidateItemGroupCode',
                        defaultMessage: 'Please Select Item Group',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderItemGroupCode',
                      defaultMessage: 'Select Item Group',
                    })}
                    showSearch
                    size="medium"
                    // className="border-create-master-select"
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            handleOpenGroupModal('group');
                          }}
                        >
                          <Icon type="plus" />{' '}
                          <FormattedMessage id="modalItemMasterFormItemAddorViewAll" defaultMessage="Add or View All" />
                        </div>
                      </div>
                    )}
                  >
                    {itemGroupCode &&
                      itemGroupCode.map((item, i) => (
                        <Option value={item.itemGroupId}>{item.itemGroupNo + ' ' + item.itemGroupName}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextItemType" defaultMessage="Item Type" />
                <LabeRequire req={true} />
              </span>
              <Form.Item className="error-text-modal">
                {getFieldDecorator('itemTypeCode', {
                  initialValue: _.get(groupTypeId, 'typeId')
                    ? _.get(groupTypeId, 'typeId')
                    : record && record.itemTypeId
                    ? record.itemTypeId
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'modalItemMasterFormItemValidateItemTypeCode',
                        defaultMessage: 'Please Select Item Type',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceItemTypeCode',
                      defaultMessage: 'Select Item Type',
                    })}
                    showSearch
                    // className="border-create-master-select"
                    size="medium"
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            handleOpenGroupModal('type');
                          }}
                        >
                          <Icon type="plus" />{' '}
                          <FormattedMessage id="modalItemMasterFormItemAddorViewAll" defaultMessage="Add or View All" />
                        </div>
                      </div>
                    )}
                  >
                    {itemTypeCode &&
                      itemTypeCode.map((item, i) => <Option value={item.typeId}>{item.itemtypeNo + ' ' + item.itemtypeName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextBrandName" defaultMessage="Brand Name" />
                <LabeRequire req={true} />
              </span>
              <Form.Item className="error-text-modal">
                {getFieldDecorator('brandName', {
                  initialValue: _.get(createItem, 'brandId')
                    ? _.get(createItem, 'brandId')
                    : record && record.brandId
                    ? record.brandId
                    : _.get(record, 'brandName') ? _.get(record, 'itemBrandNo') + ' ' +_.get(record, 'brandName') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'modalItemMasterFormItemValidateBrandName',
                        defaultMessage: 'Please Select BrandName',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalItemMasterFormItemPlaceBrandName', defaultMessage: 'Select BrandName' })}
                    showSearch
                    size="medium"
                    // className="border-create-master-select"
                    onSelect={onSelectChange}
                    allowClear
                    // value={_.get(createItem, 'brandId')
                    // ? _.get(createItem, 'brandId')
                    // : record && record.brandId
                    // ? record.brandId
                    // : undefined}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            setBrandModal(true);
                          }}
                        >
                          <Icon type="plus" />{' '}
                          <FormattedMessage id="modalItemMasterFormItemAddorViewAll" defaultMessage="Add or View All" />
                        </div>
                      </div>
                    )}
                  >
                    {itemBrand &&
                      itemBrand.map((item, i) => <Option value={item.itemBrandId}>{item.itemBrandNo + ' ' + item.itemBrandName}</Option>)}
                    {_.size(_.filter(itemBrand, (o) => o.itemBrandId === _.get(record, 'brandId'))) === 0 && _.get(record, 'brandId') ? (
                      <Option value={_.get(record, 'brandId')}>{_.get(record, 'itemBrandNo') + ' ' + _.get(record, 'brandName')}</Option>
                    ) : (
                      undefined
                    )}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextModelName" defaultMessage="Model Name" />
                <LabeRequire req={false} />
              </span>
              <Form.Item className="error-text-modal">
                {getFieldDecorator('modelName', {
                  initialValue: _.get(createItem, 'modelId')
                    ? _.get(createItem, 'modelId') 
                    : _.get(record, 'itemModelId')
                    ? _.get(record, 'itemModelId')
                    : _.get(record, 'modelName') ? _.get(record, 'itemModelNo') + ' ' +_.get(record, 'modelName') : undefined,
                  rules: [
                    {
                      required: false,
                      message: intl.formatMessage({
                        id: 'modalItemMasterFormItemValidateModelName',
                        defaultMessage: 'Please Select ModelName',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalItemMasterFormItemPlaceModelName', defaultMessage: 'Select ModelName' })}
                    disabled={getFieldValue('brandName') ? false : true}
                    onSelect={handleSelectModel}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // value={_.get(createItem, 'modelId')
                    // ? _.get(createItem, 'modelId')
                    // :record && record.modelId ? _.get(record, 'modelId') : undefined}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            setBrandModal(true);
                          }}
                        >
                          <Icon type="plus" />{' '}
                          <FormattedMessage id="modalItemMasterFormItemAddorViewAll" defaultMessage="Add or View All" />
                        </div>
                      </div>
                    )}
                    // size="medium"
                  >
                    {children}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextWidth" defaultMessage="Width (m)" />
                <LabeRequire
                  req={true}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('width', {
                  initialValue: record && record.width ? _.get(record, 'width') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateWidth', defaultMessage: 'Please Enter Width' }),
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderWidth',
                      defaultMessage: 'Enter Width(m)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextLength" defaultMessage="Length (m)" />
                <LabeRequire
                  req={true}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('length', {
                  initialValue: record && record.length ? _.get(record, 'length') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateLength', defaultMessage: 'Please Enter Length' }),
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderLength',
                      defaultMessage: 'Enter Length(m)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextHeight" defaultMessage="Height (m)" />
                <LabeRequire
                  req={true}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('height', {
                  initialValue: record && record.height ? _.get(record, 'height') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateHeight', defaultMessage: 'Please Enter Height' }),
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderHeight',
                      defaultMessage: 'Enter Height(m)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextWeight" defaultMessage="Weight (kg)" />
                <LabeRequire
                  req={true}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('weight', {
                  initialValue: record && record.weight ? _.get(record, 'weight') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateWeight', defaultMessage: 'Please Enter Weight' }),
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderWeight',
                      defaultMessage: 'Enter Weight(kg)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="uomCategoryText" defaultMessage="Category" />
                <LabeRequire req={true} />
              </span>
              <Form.Item>
                {getFieldDecorator('category', {
                  // initialValue: record && record.uomCategoryId ? _.get(record, 'uomCategoryId') : undefined,
                })(
                  <div
                    className="categoryBorder"
                    style={{
                      background: proCheck === true ? '#ECEFF7' : '#fff',
                      marginTop: '5px',
                      border: proCheck === true ? '1px solid #C6CFE2' : '1px solid #D9D9D9',
                      color: proCheck === true ? '#1D3557' : '#000000A6',
                    }}
                  >
                    <Radio checked={proCheck} onClick={handleCategoryProduct}>
                      <span style={{ color: proCheck === true ? '#1D3557' : '#000000A6' }}>
                        <FormattedMessage id="modalItemMasterFormItemNameTextProduct" defaultMessage="Product" />
                      </span>
                    </Radio>
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('category', {
                  // initialValue: record && record.uomId ? _.get(record, 'uomId') : undefined,
                })(
                  <div
                    className="categoryBorder"
                    style={{
                      background: serCheck === true ? '#ECEFF7' : '#fff',
                      marginTop: '33px',
                      border: serCheck === true ? '1px solid #C6CFE2' : '1px solid #D9D9D9',
                    }}
                  >
                    <Radio checked={serCheck} onClick={handleCategoryService} disabled={typeModal === 'po' ? true : false}>
                      <span style={{ color: serCheck === true ? '#1D3557' : '#000000A6' }}>
                        <FormattedMessage id="modalItemMasterFormItemNameTextService" defaultMessage="Service" />
                      </span>
                    </Radio>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextUoMCategory" defaultMessage="UoM Category " />
                <LabeRequire req={true} />
              </span>
              <Form.Item>
                {getFieldDecorator('uomCategory', {
                  initialValue: record && record.uomCategoryId ? _.get(record, 'uomCategoryId') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'modalItemMasterFormItemNameValidateUoMCategory',
                        defaultMessage: 'Please Select UoM Category',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderUOMCategory',
                      defaultMessage: 'Select UoM Category',
                    })}
                  >
                    {_.map(uomCategory, (item) => (
                      <Option key={item.uomCategoryId}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextUom" defaultMessage="UoM" />
                <LabeRequire req={true} />
              </span>
              <Form.Item>
                {getFieldDecorator('uom', {
                  initialValue: record && record.uomId ? _.get(record, 'uomId') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateUoM', defaultMessage: 'Please Select UoM' }),
                    },
                  ],
                })(
                  <Select
                    disabled={uomCategoryForm ? false : true}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderUOM',
                      defaultMessage: 'Select UoM',
                    })}
                  >
                    {_.map(uomOption, (item) => (
                      <Option key={item.uomId}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          {typeModal === 'po' ? (
            <Row gutter={[6, 5]}>
              <Col span={12}>
                <span className="item-label-detail">
                  <FormattedMessage id="modalItemMasterFormItemNameWarehouse" defaultMessage="Warehouse" />
                  <LabeRequire req={true} />
                </span>
                <Form.Item>
                  {getFieldDecorator('warehouseId', {
                    initialValue: record && record.pickingTypeId ? _.get(record, 'pickingTypeId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalItemMasterFormItemNameWarehouseValidator',
                          defaultMessage: 'Please Select Warehouse',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'modalItemMasterFormItemPlaceholderWarehouse',
                        defaultMessage: 'Select Warehouse',
                      })}
                      onPopupScroll={handleCustomerPopupScroll}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      onSearch={(e) => {
                        handleSearchWarhouse(e);
                      }}
                    >
                      {_.map(warehouseData, (item) => (
                        <Option key={item.pickingTypeId} value={item.warehouseId}>
                          {item.warehouseCode + ' ' + item.warehouseName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          {/* <Row gutter={[6, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNamePickingType" defaultMessage="Picking Type" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('pickingType', {
                  initialValue: record && record.pickingTypeId ? _.get(record, 'pickingTypeId') : undefined,
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderPickingType',
                      defaultMessage: 'Select Picking Type',
                    })}
                  >
                    {
                      _.map(pickingType, (item) => (
                        <Option key={item.pickingTypeId}>
                          {item.name}
                        </Option>
                      ))
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row> */}
          {/* <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameStandardCost" defaultMessage="Standard Cost" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('standardCost', {
                  initialValue: record && record.standardCost ? _.get(record, 'standardCost') : 0,
                })(
                  // <InputNumber
                  //   formatter={numberFormatter}
                  //   parser={numberParser}
                  //   step={0.01}
                  //   className="inventory-inputNumber"
                  // />
                  <Input
                  onKeyDown={handleKeyDownDecimal2Fixed}
                />
                )}

              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameStandardPrice" defaultMessage="Standard Price" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('standardPrice', {
                  initialValue: record && record.standardPrice ? _.get(record, 'standardPrice') : 0,
                })(
                  // <InputNumber
                  //   formatter={numberFormatter}
                  //   parser={numberParser}
                  //   step={0.01}
                  //   className="inventory-inputNumber"
                  // />
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                  />
                )}
              </Form.Item>
            </Col>
          </Row> */}
        </div>
        <Divider className="divider-custom-itemmaster" type="horizontal" />
        <div className="padding-div-bottom">
          <p style={{ paddingBottom: '18px ' }} className="item-label-detail">
            <FormattedMessage id="warehouseDetailRemark" defaultMessage="Remark" />
          </p>
          <Form.Item>
            {getFieldDecorator('descriptions', {
              initialValue: record && record.description ? record.description : undefined,
            })(
              <TextArea
                placeholder={intl.formatMessage({
                  id: 'modalItemPlaceholderRemark',
                  defaultMessage: 'Enter Remark',
                })}
                autosize={{ minRows: 3, maxRows: 3 }}
                maxLength={250}
              />
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

const ModalForm = Form.create({
  name: 'global_state',
})(CreateItemMaster);

export default ModalForm;
