import React, { useState } from 'react';
import './index.css';
import _ from 'lodash';
import ReactBnbGallery from 'react-bnb-gallery';
import { Preview01, Preview02, Preview03, Preview04, Preview05, PreviewPlus } from '../../../../components/note-monitor/preview-picture';
import { RenderTag } from './post-tag';
import { FormattedMessage } from 'react-intl';

const ViewDescription = (props) => {
  const { viewData, hanldeClickMap } = props;
  const [OpenGallery, setOpenGallery] = useState(false);
  const [galleryImg, setGalleryImg] = useState([]);
  const [numberImage, setNumberImage] = useState(0);

  const handlePreviewImage = (page, data, itemData) => {
    const mapPhoto =
      data && _.size(data) !== 0
        ? data.map((item, index) => {
            return {
              photo: _.get(item, 'uri'),
              number: index,
              caption: _.get(item, 'descriptionPhoto'),
              subcaption: `Noted By ${_.get(itemData, 'member.fullname')}`,
              thumbnail: _.get(item, 'uri'),
            };
          })
        : [];
    setGalleryImg(mapPhoto);
    setOpenGallery(true);
    setNumberImage(page);
  };

  const fncPreview = (imageList, item) => {
    let sum = imageList.length;
    switch (sum) {
      case 1:
        return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
    }
  };

  const handleTags = (data) => {
    window.open(data.fileUrl, '_blank');
  };

  const renderTagFile = (fileList, removeFile) => {
    return (
      <div className="post-tags-view-modal" style={{ marginTop: '8px' }}>
        <div className="post-tags-view">{fileList && fileList.map((item) => <RenderTag fileList={item} handleTags={handleTags} />)}</div>
      </div>
    );
  };

  return (
    <div style={{ padding: '24px', height: '435px', overflowY: 'scroll' }}>
      <div>
        <div>
          <div className="viewTicketTextTopic">
            <FormattedMessage id="AllTicketTopic" defaultMessage="Topic" />
          </div>
          <div className="viewTicketTextValue">
            {_.map(_.get(viewData, 'topicIdList'), (item, index) => {
              return (
                <span>
                  {item.name} {index === _.size(viewData.topicIdList) - 1 ? '' : ','}
                </span>
              );
            })}
          </div>
        </div>
        <div style={{ marginTop: '24px' }}>
          <div>{_.get(viewData, 'ticketDescriptionList.description')}</div>
        </div>
        {_.size(_.get(viewData, 'ticketDescriptionList.address')) > 0 ? (
          <div style={{ marginTop: '14px' }}>
            <div
              style={{ cursor: 'pointer' }}
              className="viewTicketTextValueAddress"
              onClick={() => hanldeClickMap(_.get(viewData, 'ticketDescriptionList.lat'), _.get(viewData, 'ticketDescriptionList.lng'))}
            >
              {` - at ${_.get(viewData, 'ticketDescriptionList.address')} >`}
            </div>
          </div>
        ) : null}

        {viewData && viewData.ticketDescriptionList.attachmentsFile.length > 0
          ? renderTagFile(viewData.ticketDescriptionList.attachmentsFile)
          : null}
        <div style={{ marginTop: '16px' }}>
          <div>
            {viewData
              ? fncPreview(
                  _.map(viewData.ticketDescriptionList.attachmentsPhoto, (item) => {
                    return {
                      uri: item.fileUrl,
                      descriptionPhoto: item.descriptionPhoto,
                    };
                  }),
                  { member: { fullname: viewData.submitBy } }
                )
              : null}
          </div>
        </div>
      </div>
      <ReactBnbGallery show={OpenGallery} photos={galleryImg} onClose={() => setOpenGallery(false)} activePhotoIndex={numberImage} />
    </div>
  );
};

export default ViewDescription;
