import React, { useState, useContext } from 'react';
import { Table, Divider, Col, Row, Icon, Popover, Modal, Upload, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { MaintenanceContext } from './maintenance-context';
import logoPDF from '../../pages/login/image/pdf.png';
import Button01 from '../v2/button_01';
import Button03 from '../v2/button_03';

export default function MaintenanceAttachment(props) {
  const intl = useIntl();
  const { setVisible, view } = props;
  const { mainState, setMainState, fncMain } = useContext(MaintenanceContext);

  let dataAttachmentById = mainState && mainState.dataAttachmentById;

  const [visiblePopover, setVisiblePopover] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'maintenanceModalAttachmentColumnsAttachmentName', defaultMessage: 'Attachment Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 220,
      render: (text, record, index) => (
        <div style={{ width: '170px' }}>
          <span className="attachmentName-hidden-text">{record.name}</span>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'maintenanceModalAttachmentColumnsFile', defaultMessage: 'File' }),
      dataIndex: 'attachments',
      key: 'attachments',
      width: 300,
      render: (text, record, index) => fncFile(record, index),
    },
    {
      title: intl.formatMessage({ id: 'maintenanceModalAttachmentColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'description',
      key: 'description',
      render: (text, record, index) => (
        <div style={{ width: '380px' }}>
          <span className="attachmentName-hidden-text">{record.description}</span>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 70,
      render: (text, record, index) => fncActionTable(record, index),
    },
  ];

  const fncFile = (record, index) => {
    return (
      <div className="maintenance-column-file">
        {/* {record.attachments.map((item, index) =>
          <div key={item.maintenanceAttachmentId} className='maintenance-column-file-text' onClick={() => fncMain.modalEditAttachment(record)}>
            <span className='maintenance-column-file-name'>{item.name}</span>
            {index !== record.attachments.length - 1 ? (
              <span>,</span>
            ) : null}
          </div>
        )} */}

        {/* {record.attachments.map((item, index) =>
          <div key={item.uid} className='maintenance-column-file-thumbnail'>
            <Avatar shape="square" size="large" src={item.thumbnail} />
          </div>
        )} */}

        <Upload
          className="attachment-upload"
          listType="picture-card"
          fileList={record.attachments}
          onPreview={() => fncMain.modalEditAttachment(record)}
          onPreview={!view ? () => fncMain.modalEditAttachment(record) : null}
          beforeUpload={() => {
            return false;
          }}
          showUploadList={{
            showPreviewIcon: view === true ? false : true,
            showRemoveIcon: false,
          }}
        />
      </div>
    );
  };

  const modalConfirmDelete = (record, index) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const filteredItems = mainState.dataAttachmentById.filter((item) => item.id !== record.id);
        setMainState.setDataAttachmentById(filteredItems);
      },
      onCancel() {},
    });
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const fncActionTable = (record, index) => {
    return (
      <div>
        {view ? (
          ''
        ) : (
          <Popover
            key={index}
            placement="leftTop"
            trigger="hover"
            // visiblePopover={visiblePopover}
            // onVisibleChange={handleOnVisiblePopover}
            // zIndex={999}
            content={
              <div className="table-action-columns">
                <>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => fncMain.modalEditAttachment(record)}>
                    <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                  </Button>
                </>

                <>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => modalConfirmDelete(record)}>
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </Button>
                </>
              </div>
            }
          >
            <Icon type="more" />
          </Popover>
        )}
      </div>
    );
  };

  return (
    <div className="attachment">
      <div className="maintenance-container-header">
        <Row className="maintenance-container-row">
          <Col span={12} className="maintenance-header-lift">
            <span className="maintenance-header-title">
              <FormattedMessage id="maintenanceModalAttachmentTitleAttachment" defaultMessage="Attachment" />
            </span>
          </Col>
          <Col span={12} className="maintenance-header-right">
            {view ? (
              ''
            ) : (
              <Button03 className="maintenance-header-button" type="link" onClick={() => setVisible(true)}>
                {' '}
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button03>
            )}
          </Col>
        </Row>
      </div>
      <Divider className="maintenance-sub-divider" />
      <div className="maintenance-sub-report-table">
        <Table
          size="middle"
          rowKey={(record) => record.id}
          onRow={
            view
              ? (record) => {
                  return {
                    onClick: () => fncMain.modalEditAttachment(record),
                  };
                }
              : null
          }
          dataSource={dataAttachmentById}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
}
