import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Tabs, DatePicker, Badge } from 'antd';
import client from '../../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios'
import PaydSummary from './paydSummary';
import PaydMyHours from './paydMyHours';
import PaydStatement from './paydStatement';
import pathUrt from '../../../../function/pathUrl';
import cssPayD from '../css/payD.css';
import cssStyle from './css/index.css';
import { PageSettings } from '../../../../config/page-settings';

const { TabPane } = Tabs;

export default (props) => {
  const intl = useIntl();

  const checkPathUrl = pathUrt('/user-management/my-profile');

  const param = useParams();
  const userId = param.user_id;
  const memComId = props.memComId;
  const paydSummary = _.get(props, 'paydSource.data.PayDSummary');
  const memClassId = _.get(props, 'paydSource.data.PayDSummary.memClassId');
  const defaultStatement = _.get(props, 'paydSource.data.PayDStatement');
  const overall = _.get(props, 'paydSource.data.PayDSummary.memClassAmount');
  const checkPayDShow = _.get(props, 'checkPayDShow')

  const labelShow = props.labelShow;
  const langValue = localStorage.getItem('langValue');

  const [statement, setStatement] = useState(defaultStatement);
  const [showTabs, setShowTabs] = useState(false);

  useEffect(() => {
    setShowTabs(memClassId === 0 ? false : true);
    setStatement(defaultStatement);
  }, [defaultStatement]);

  const callback = (key) => {
  }

  const onSelectDate = async (selected) => {
    let dateData = moment(selected).format("-MM-DD");
    if (langValue === 'TH') {
      let year = parseInt(moment(selected).format("YYYY"));
      dateData = String(year + dateData);
    } else {
      let year = parseInt(moment(selected).format("YYYY"));
      dateData = String(year + dateData);
    }

    await client.get(`/payd-statements/${dateData}/members/${memComId}`).then((response) => {
      setStatement(response.data);
    }).catch((error) => {
    })
  }

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const switchCase = () => {
    if (showTabs === false && checkPathUrl === true) {
      return <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <Tabs className="tabs" size={'large'} onChange={callback}>
            {checkPermissionAction('P2S1PG2C11', 'P2S1PG2C11A1') || checkPathUrl === true ?
              <TabPane tab={<FormattedMessage id="userMgntUsersDetailColHoursTime" defaultMessage="Time Attendance" />} className='content-tabs-myHours' >
                <PaydMyHours memComId={memComId} userId={userId} langValue={langValue} />
              </TabPane>
              : null
            }
          </Tabs>
        )}
      </PageSettings.Consumer>
    } else {
      if (checkPayDShow === 'Disable') {
        return <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <Tabs className="tabs" size={'large'} defaultActiveKey="1" onChange={callback} >
              {checkPermissionAction('P2S1PG2C11', 'P2S1PG2C11A1') || checkPathUrl === true ?
                <TabPane tab={<FormattedMessage id="userMgntUsersDetailColHoursTime" defaultMessage="Time Attendance" />} key="1" className='content-tabs-myHours' >
                  <PaydMyHours memComId={memComId} userId={userId} labelShow={labelShow} langValue={langValue} />
                </TabPane>
                : null
              }
            </Tabs>
          )}
        </PageSettings.Consumer>
      } else {
        return <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <Tabs className="tabs" size={'large'} defaultActiveKey="1" onChange={callback} >
              {/* {checkPermissionAction('P2S1PG2C9', 'P2S1PG2C9A1') || checkPathUrl === true ?
                <TabPane tab={<FormattedMessage id="userMgntUsersDetailTabPayD" defaultMessage="PayD Summary" />} key="1" className='content-tabs-summary'>
                  <PaydSummary paydSummary={paydSummary} formatNumber={formatNumber} labelShow={labelShow} />
                </TabPane>
                : null
              } */}
              {/* 
              {checkPermissionAction('P2S1PG2C10', 'P2S1PG2C10A1') || checkPathUrl === true ?
                <TabPane tab={<FormattedMessage id="userMgntUsersDetailTabStatement" defaultMessage="Statement" />} key="2" className='content-tabs-statement'>
                  <PaydStatement statement={statement} overall={overall} onSelectDate={onSelectDate} formatNumber={formatNumber} labelShow={labelShow} langValue={langValue} />
                </TabPane>
                : null
              } */}

              {checkPermissionAction('P2S1PG2C11', 'P2S1PG2C11A1') || checkPathUrl === true ?
                <TabPane tab={<FormattedMessage id="userMgntUsersDetailTabHours" defaultMessage="Hours" />} key="3" className='content-tabs-myHours' >
                  <PaydMyHours memComId={memComId} userId={userId} labelShow={labelShow} langValue={langValue} />
                </TabPane>
                : null
              }
            </Tabs>
          )}
        </PageSettings.Consumer>
      }




    }
  }

  return (
    <div>
      {switchCase()}
    </div>
  )
}