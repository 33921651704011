import React from 'react'
import { Icon, Avatar } from 'antd'
import './css/index.css'
import { FormattedMessage } from 'react-intl'

export default (props) => {
  const { imageRef, imageFile, imageFileDefault, handleChangePicture, showOpenFileDialog, deleteImage } = props


  return (
    <div className="image-container-asset">
      <Avatar
        className='avartar-asset'
        shape="square"
        size={150}
        src={`${imageFile ? imageFile : imageFileDefault}`} />
      <input
        ref={imageRef}
        type="file"
        style={{ display: 'none' }}
        accept=".jpg, .png, .JPG, .PNG"
        onChange={handleChangePicture}
      />
      {imageFile ?
        <div className='image-container-asset after' style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
          <Icon type="upload" theme='outlined' style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
          &nbsp;
          &nbsp;
          <Icon type="delete" theme='outlined' style={{ fontSize: '25px' }} onClick={deleteImage} />
        </div>
        :
        <div className='image-container-asset after' style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
          <Icon type="upload" theme='outlined' style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
        </div>
      }

      <div className='asset-description-img'>
        <FormattedMessage id='modalAssetAssetUploadDescription1' defaultMessage='Only PNG or JPG file and recommended' />
        <FormattedMessage id='modalAssetAssetUploadDescription2' defaultMessage='size: 356 x 356 pixels or 1:1 ratio' />
      </div>
    </div>
  )
}
