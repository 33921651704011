import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Input, Form } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import cssStyle from './css/index.css';

const ModalFormComponent = ({ visible, onCancel, onCreate, form }) => {
  const intl = useIntl();
  const { getFieldDecorator } = form;
  return (
    <Modal
      className="modalform"
      visible={visible}
      title={<FormattedMessage id="orgTabDetailContactsModalTitle" defaultMessage="Contacts" />}
      okText={intl.formatMessage({ id: 'btnSave', defaultMessage: 'Save' })}
      cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
      onCancel={onCancel}
      onOk={onCreate}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={onCancel} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={onCreate}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <span className="title">
          <FormattedMessage id="orgTabDetailContactsModalLbl" defaultMessage="We need a few details to create your Contact." />
        </span>

        <Form.Item>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'orgTabDetailContactsModalHintName', defaultMessage: 'Please enter contact name.' }),
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'orgTabDetailContactsModalHintName', defaultMessage: 'Please enter contact name' })}
              autoComplete="off"
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('phone', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'orgTabDetailContactsModalHintPhone',
                  defaultMessage: 'Please enter contact phone number.',
                }),
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({
                id: 'orgTabDetailContactsModalHintPhone',
                defaultMessage: 'Please enter contact phone number.',
              })}
              autoComplete="off"
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('email')(
            <Input
              placeholder={intl.formatMessage({ id: 'orgTabDetailContactsModalHintEmail', defaultMessage: 'Please enter contact email.' })}
              autoComplete="off"
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('description')(
            <Input
              type="textarea"
              placeholder={intl.formatMessage({
                id: 'orgTabDetailContactsModalHintDescription',
                defaultMessage: 'Please enter contact description.',
              })}
              autoComplete="off"
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ModalForm = Form.create({ name: 'modal_form' })(ModalFormComponent);

export default ModalForm;
