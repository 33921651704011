import React, { useState, useCallback, useEffect } from 'react';
import { Card, Row, Col, Popover, Icon, Modal, Menu, Dropdown } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button03 from '../../../components/v2/button_03';
import './css/index.css';
import styled from 'styled-components';
import SkillGroup from './skill-group';
import SkillList from './skill-list';
import ModalSkill from '../../../components/modal-skill/index';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';

import { handleCreateSkill, handleEditSkill, handleDeletedSkill } from '../../../controllers/skill/add-edit-delete';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import _ from 'lodash';
import getbyId from '../../../controllers/skill/get-by-id';
import { handleDeletedSkillKist } from '../../../controllers/skill/modal-add-edit-delete';
import { PageSettings } from '../../../config/page-settings';
import ButtonActionGroup from '../../../components/action-button';
import Button_01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';

export default (props) => {
  const {
    intl,
    statusAdd,
    setStatusEdit,
    statusEdit,
    setStatusAdd,
    setTriggerAPI,
    skillDetail,
    setSelectSkill,
    skillList,
    handleSelectSkill,
    setDefaultKeys,
    onChangeTable
  } = props;
  const [visibleModal, setVisibleModal] = useState(false);
  const [refForm, setRefForm] = useState(null);
  const [defaultValue, setDefaultValue] = useState();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'skillDetailTableSkillName', defaultMessage: 'Skill Name' }),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'skillDetailTableDescription', defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: intl.formatMessage({ id: 'skillDetailTableUsers', defaultMessage: 'Users' }),
      dataIndex: 'totalMember',
      key: 'totalMember',
      sorter: true,
    },

    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => (
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <Popover
              placement="leftTop"
              trigger="hover"
              key={index}
              content={
                <>
                  <Row>
                    {checkPermissionAction('P15PG1C1', 'P15PG1C1A6') ? (
                      <Button01
                        btnsize="wd_df"
                        type="link"
                        ghost
                        onClick={() => {
                          handleEditSkillList(record);
                        }}
                      >
                        <p>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </p>
                      </Button01>
                    ) : null}
                  </Row>
                  <Row>
                    {checkPermissionAction('P15PG1C1', 'P15PG1C1A7') ? (
                      <Button01 btnsize="wd_df" type="link" ghost onClick={() => handleDeleteSkillList(record.skillId)}>
                        <p>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </p>
                      </Button01>
                    ) : null}
                  </Row>
                </>
              }
            >
              <Icon type="more" />
            </Popover>
          )}
        </PageSettings.Consumer>
      ),
    },
  ];

  const savePrivacy = useCallback((node) => {
    if (node !== null) {
      setRefForm(node);
    }
  }, []);

  const handleEdit = () => {
    setStatusEdit(false);
    setStatusAdd(false);
  };

  const handleDelete = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'skillTextDelete', defaultMessage: 'Are you sure you want to delete skill ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await handleDeletedSkill(_.get(skillDetail, 'skillGroupId'));
        if (_.size(response) !== 0) {
          successNotification(response.status.message);
          setTriggerAPI((current) => !current);
          setSelectSkill();
        } else {
          errorNotification('Error');
          setTriggerAPI((current) => !current);
        }
      },
      onCancel() {},
    });
  };

  const handleCancel = () => {
    if (statusEdit === false && statusAdd === true) {
      setSelectSkill();
      refForm.resetFields();
      setStatusEdit(true);
      setStatusAdd(false);
    } else if (statusEdit === false && statusAdd === false) {
      refForm.resetFields();
      setStatusEdit(true);
      setStatusAdd(false);
    }
  };

  const handleSave = async () => {
    refForm.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      if (statusAdd === true) {
        const response = await handleCreateSkill(values.skillNameAdd, undefined);
        if (_.size(response) !== 0) {
          successNotification(response.status.message);
          setTriggerAPI((current) => !current);
          setDefaultKeys(String(response.data.skillGroup.skillGroupId));
          handleSelectSkill(response.data.skillGroup.skillGroupId);
        } else {
          errorNotification('Error');
          setTriggerAPI((current) => !current);
        }
      } else {
        const responseEdit = await handleEditSkill(values.skillName, undefined, _.get(skillDetail, 'skillGroupId'));
        if (_.size(responseEdit) !== 0) {
          successNotification(responseEdit.status.message);
          setTriggerAPI((current) => !current);
        } else {
          errorNotification('Error');
          setTriggerAPI((current) => !current);
        }
      }

      setStatusEdit(true);
      setStatusAdd(false);
      // refForm.resetFields()
    });
  };

  const handleDeleteSkillList = async (skillId) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'skillTextDelete', defaultMessage: 'Are you sure you want to delete skill ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await handleDeletedSkillKist(skillId);
        if (_.size(response) !== 0) {
          successNotification(response.status.message);
          handleSelectSkill(_.get(skillDetail, 'skillGroupId'));
        } else {
          errorNotification(response.status.message);
        }
      },
      onCancel() {},
    });
  };

  const handleEditSkillList = (record) => {
    setVisibleModal(true);
    setDefaultValue(record);
  };

  const menu = (checkPermissionAction) => (
    <Menu>
      {checkPermissionAction('P15PG1C1', 'P15PG1C1A4') && (
        <Menu.Item key="0">
          <p onClick={() => handleSave()}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </p>
        </Menu.Item>
      )}
      {checkPermissionAction('P15PG1C1', 'P15PG1C1A4') && (
        <Menu.Item key="1">
          <p onClick={() => handleDelete()}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </p>
        </Menu.Item>
      )}
      {/* <Menu.Item key="1">
        <p onClick={() => handleDelete()}>
          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
        </p>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Card
            className="card-skill-detail"
            title={intl.formatMessage({ id: 'skillDetailHeader', defaultMessage: 'Skill' })}
            extra={
              <ButtonActionGroup menu={menu(checkPermissionAction)} />
              // <Dropdown overlay={menu(checkPermissionAction)} trigger={['click']} placement="bottomRight">
              //   <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
              //     <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
              //     <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              //   </Button_01>
              // </Dropdown>
              // statusEdit === true ? (
              //   <div>
              //     {checkPermissionAction('P15PG1C1', 'P15PG1C1A4') ? (
              //       <Button02 style={{margin : '0px 0px 0px 10px'}} fontColor="fc_black" onClick={handleDelete}>
              //         <FormattedMessage id="btnDelete" defaultMessage="Delete" />
              //       </Button02>
              //     ) : null}
              //     {checkPermissionAction('P15PG1C1', 'P15PG1C1A3') ? (
              //       <Button01 type="primary" onClick={handleEdit}>
              //         <FormattedMessage id="btnEdit" defaultMessage="Edit" />
              //       </Button01>
              //     ) : null}
              //   </div>
              // ) : (
              //   <div>
              //     <Button02 style={{margin : '0px 0px 0px 10px'}} fontColor="fc_black" onClick={handleCancel}>
              //       <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              //     </Button02>
              //     <Button01 type="primary" onClick={handleSave}>
              //       <FormattedMessage id="btnSave" defaultMessage="Save" />
              //     </Button01>
              //   </div>
              // )
            }
          >
            <SkillGroup intl={intl} statusEdit={statusEdit} ref={savePrivacy} statusAdd={statusAdd} skillDetail={skillDetail} />
            <SkillList columns={columns} setVisibleModal={setVisibleModal} skillList={skillList}  onChangeTable={onChangeTable} />
          </Card>

          <ModalSkill
            visible={visibleModal}
            setVisible={setVisibleModal}
            skillDefault={_.get(skillDetail, 'skillGroupId')}
            handleSelectSkill={handleSelectSkill}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            setDefaultKeys={setDefaultKeys}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};
