import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Col, Row, Table, Button, Menu, Checkbox } from 'antd';
import { MonitorContext } from '../../transportation-context';
import ActionSearch from '../components/action-search-list';
import ActionTeam from '../components/action-team-list';
import '../css/list.css';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

export default () => {
  const intl = useIntl();
  const { state, fnc, setState } = useContext(MonitorContext);
  const { initialData, mainOrgId, mainOrgIdStr, resourceData, searchValue, selectBy, orgData, orgIdRaw, searchValueList, selectByList, loadingListView, LangCode } = state;
  const { handleChangeOption, handleSearch, handleSelect, handleSelectList, handleSearchList } = fnc;
  const { setOrgData, setOrgIdRaw, setSearchValueList } = setState;


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index

    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableTaskNo${LangCode}`, defaultMessage: 'Task No.' }),
      dataIndex: 'taskNo',
      key: 'taskNo',
      width: 150,
      fixed: 'left',
      // sorter: (a, b) => a.taskNo - b.taskNo,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <a className="list-view-a-task-view" key={record.taskId} onClick={() => fnc.handleOnClickTaskListView(record)}>{record.taskNo}</a>
      )
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableTaskType${LangCode}`, defaultMessage: 'Task Type' }),
      dataIndex: 'taskType',
      key: 'taskType',
      width: 130,
      fixed: 'left',
      // sorter: (a, b) => a.taskType - b.taskType,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <span>{record.taskType.taskTypeName}</span>
      )
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableCustomer${LangCode}`, defaultMessage: 'Customer' }),
      dataIndex: 'customerName',
      key: 'customerName',
      width: 150,
      fixed: 'left',
      // sorter: (a, b) => a.customerName - b.customerName,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableContactName${LangCode}`, defaultMessage: 'Contact Name' }),
      dataIndex: 'contactName',
      key: 'contactName',
      width: 250,
      // fixed: 'left',
      // sorter: (a, b) => a.contact - b.contact,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <span>{record.contactName}</span>
      )
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableContactPhone${LangCode}`, defaultMessage: 'Contact Phone' }),
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      width: 180,
      // sorter: (a, b) => a.contactPhone - b.contactPhone,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <span>{_.get(record, 'contact[0].phone') || ""}</span>
      )
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableFromLocation${LangCode}`, defaultMessage: 'From Location' }),
      dataIndex: 'fromAddress',
      key: 'fromAddress',
      render: (text, record, index) => (
        <span title={record.fromAddress} className='span-address-ellipsis'>{record.fromAddress}</span>
      )
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableToLocation${LangCode}`, defaultMessage: 'To Location' }),
      dataIndex: 'deliveryAddress',
      key: 'deliveryAddress',
      render: (text, record, index) => (
        <span title={record.deliveryAddress} className='span-address-ellipsis'>{record.deliveryAddress}</span>
      )
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableStatus${LangCode}`, defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      // sorter: (a, b) => a.status - b.status,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <span>{record.status.statusName}</span>
      )
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableStartDate${LangCode}`, defaultMessage: 'Start Date' }),
      dataIndex: 'startDateView',
      key: 'startDateView',
      // sorter: (a, b) => a.startDateView - b.startDateView,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableDueDate${LangCode}`, defaultMessage: 'Due Date' }),
      dataIndex: 'endDateView',
      key: 'endDateView',
      // sorter: (a, b) => a.endDateView - b.endDateView,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableActualStartDate${LangCode}`, defaultMessage: 'Actual Start Date' }),
      dataIndex: 'actualStartDate',
      key: 'actualStartDate',
      // sorter: (a, b) => a.actualStartDate - b.actualStartDate,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableActualCompletedDate${LangCode}`, defaultMessage: 'Actual Completed Date' }),
      dataIndex: 'actualCompletedDate',
      key: 'actualCompletedDate',
      // sorter: (a, b) => a.actualCompletedDate - b.actualCompletedDate,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorMapPopoverReference${LangCode}`, defaultMessage: 'Reference' }),
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableRemark${LangCode}`, defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableReference1${LangCode}`, defaultMessage: 'Reference 1' }),
      dataIndex: 'reference1',
      key: 'reference1',
      // sorter: (a, b) => a.reference1 - b.reference1,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableReference2${LangCode}`, defaultMessage: 'Reference 2' }),
      dataIndex: 'reference2',
      key: 'reference2',
      // sorter: (a, b) => a.reference2 - b.reference2,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableReference3${LangCode}`, defaultMessage: 'Reference 3' }),
      dataIndex: 'reference3',
      key: 'reference3',
      // sorter: (a, b) => a.reference3 - b.reference3,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableCreatedDate${LangCode}`, defaultMessage: 'Created Date' }),
      dataIndex: 'createdDate',
      key: 'createdDate',
      // sorter: (a, b) => a.createdDate - b.createdDate,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `reportTaskReportViewTableAssigneeName${LangCode}`, defaultMessage: 'Assignee Name' }),
      dataIndex: 'assignee',
      key: 'assignee',
      // sorter: (a, b) => a.assigneeName - b.assigneeName,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <span title={record.assignee} className='span-address-ellipsis'>{record.assignee}</span>
      )
    },
  ];

  const setShowColumn = {
    index: true,
    taskNo: true,
    taskType: true,
    customerName: true,
    contactName: true,
    contactPhone: false,
    fromAddress: false,
    deliveryAddress: false,
    status: false,
    startDateView: true,
    endDateView: true,
    actualStartDate: true,
    actualCompletedDate: true,
    reference: false,
    remark: false,
    reference1: false,
    reference2: false,
    reference3: false,
    createdDate: false,
    assignee: true,
  };

  const setShowColumnArr = [
    'index',
    'taskNo',
    'taskType',
    'customerName',
    'contactName',
    'startDateView',
    'endDateView',
    'actualStartDate',
    'actualCompletedDate',
    'assignee'
  ];


  const ignoreColumn = ['contactPhone', 'fromAddress', 'deliveryAddress', 'status', 'reference', 'remark', 'reference1', 'reference2', 'reference3', 'createdDate',];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = ['index', 'startDateView', 'endDateView', 'actualStartDate', 'actualCompletedDate', 'createdDate', 'status'];
  const columnNewSearch = columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [visible, setVisible] = useState(false);
  const [scrollTableX, setScrollTableX] = useState(2000)


  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) { // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(newListArrayColumns, function (result, value, key) {
        result[value] = true;
        return result;
      }, {});
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ)
      setTextErrorSelectColumn('');
    }
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find(
        (element) => element == columns[i].key,
      );
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }

    if (_.size(setNewArr) <= 6) {
      setScrollTableX(1000)
    } else if (_.size(setNewArr) <= 9) {
      setScrollTableX(1500)
    } else if (_.size(setNewArr) <= 12) {
      setScrollTableX(2000)
    } else if (_.size(setNewArr) <= 15) {
      setScrollTableX(3000)
    } else if (_.size(setNewArr) <= 17) {
      setScrollTableX(3800)
    } else if (_.size(setNewArr) <= 19) {
      setScrollTableX(3800)
    }

    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    setScrollTableX(2000)
  };

  const handlePagination = (page) => {
    setState.setPaginationPage(page);
  }

  const handleSizeChange = (current, size) => {
    setState.setPaginationSize(size);
    setState.setPaginationPage(current);
  }

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setState.setOrderSort('desc');
      } else {
        setState.setOrderSort('asc');
      }
    } else {
      setState.setOrderSort('');
    }
    setState.setFieldSort(field);
  };

  const showTotal = (total) => {
    return <span style={{ fontSize: '13px' }}><FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " /></span>
  };


  return (
    <div className='container-list'>
      <div className='container-list-header'>
        <div>
          <ActionSearch
            handleSearch={handleSearchList}
            handleSelect={handleSelectList}
            searchValue={searchValueList}
            selectBy={selectByList}
            selectSearch={columnNewSearch}
            setInputValue={setState.setInputValue}
            setSearchValue={setSearchValueList}
            setPaginationPage={setState.setPaginationPage}
          />
        </div>
        <div className='action-team-all'>
          <ActionTeam
            orgData={orgData}
            setOrgData={setOrgData}
            mainOrgId={mainOrgId}
            handleChangeOption={handleChangeOption}
            handleOnVisiblecolumns={handleOnVisiblecolumns}
            handleVisibleChange={handleVisibleChange}
            visible={visible}
            columns={columns}
            checkedValuecolumns={checkedValuecolumns}
            defaultShowColumn={defaultShowColumn}
            textErrorSelectColumn={textErrorSelectColumn}
            handleCheckOk={handleCheckOk}
            handleReset={handleReset}
            setOrgIdRaw={setOrgIdRaw}
            orgIdRaw={orgIdRaw}
          />
        </div>
      </div>
      <div>
        <Table
          onChange={handleChange}
          loading={loadingListView}
          columns={newDataColumns}
          scroll={{ x: scrollTableX, y: `calc(100vh - 360px)` }}
          dataSource={state.listView}
          pagination={{
            total: state.total,
            current: state.paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
            onChange: handlePagination,
            onShowSizeChange: handleSizeChange,
          }}
        />
        {state.total ?
          <div
            className="total-items-overtime-report"
          >
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${state.total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
          : null
        }
      </div>
    </div>
  )
}