import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getProjectList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/findlist/project`, payload);
    return response.data;
  } catch (err) {
    return [];
  }
};

const getAvatarList = async () => {
  try {
    const response = await httpClient.get(`/v3/project/manager/${comCode}/findlist/avatar`);
    return response.data;
  } catch (err) {
    return [];
  }
};

const getProjectById = async (id) => {
  try {
    const response = await httpClient.get(`/v3/project/manager/${comCode}/getbyid/project/${id}`);
    return response.data;
  } catch (error) {
    return [];
  }
};



// const getSearchStakeHolder = async () => {
//   try {
//     const source = {
//       data: [
//         {
//           index: 1,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 2,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 3,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 4,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 5,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 6,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 7,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 8,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 9,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 10,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//         {
//           index: 11,
//           name: 'Kittisak Khongsahwatcharoen',
//           team: 'UX/UI Designer',
//           email: 'Ux/UI@mail.com',
//           position: 'UX/UI Designer',
//           phone: '0616511234',
//         },
//       ],
//       allRows: 11,
//     };

//     return source;
//   } catch (error) {
//     return [];
//   }
// };

const getMembersLoadmore = async (props) => {
  const memComId = localStorage.getItem('memComId');
  const comId = localStorage.getItem('comId');

  const body = {
    com_id: `${comId}`,
    date: [],
    equal: [],
    index_page: '1',
    like: props.managerName ? [{ key: '', val: props.managerName }] : [],
    limit: props.indexEnd.toString(),
    notResign: true,
    order: 'fullname asc',
    user_id: `${memComId}`,
  };

  try {
    const response = await httpClient.post(`/v2/searchusers`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getInitialDataProject = async () => {
  try {
    const response = await httpClient.get(`/v3/project/manager/${comCode}/findlist/initial`);
    return response.data;
  } catch (err) {
    return [];
  }
};

const createProject = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/create/project`, payload);
    return response;
  } catch (err) {
    return [];
  }
};

const editProject = async (payload, id) => {
  try {
    const response = await httpClient.put(`/v3/project/manager/${comCode}/update/project/${id}`, payload);
    return response;
  } catch (err) {
    return [];
  }
};

const deleteProject = async (id) => {
  try {
    const response = await httpClient.delete(`/v3/project/manager/${comCode}/delete/project/${id}`);
    return response;
  } catch (err) {
    return [];
  }
};

const saveSkateholder = async (payload, id) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/create/project-stackholder/${id}`, payload);
    return response;
  } catch (err) {
    return [];
  }
};

const deleteStakeholder = async (id) => {
  try {
    const response = await httpClient.delete(`/v3/project/manager/${comCode}/delete/project-stackholder/${id}`);
    return response;
  } catch (err) {
    return [];
  }
};

const getSearchStakeHolder = async (payload, id) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/findlist/project-stackholderbyprojectid/${id}`, payload);
    return response;
  } catch (error) {
    return [];
  }
};

export {
  getProjectList,
  getProjectById,
  getSearchStakeHolder,
  getAvatarList,
  getMembersLoadmore,
  getInitialDataProject,
  createProject,
  editProject,
  deleteProject,
  saveSkateholder,
  deleteStakeholder,
};
