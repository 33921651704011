import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Tree, Col, Row, Icon, Checkbox, Form } from 'antd';
import '../FilterMyrequest/FilterMyrequest.css';
import _ from 'lodash';
import FilterTable from '../TableMyrequest/FilterTable';

const { TreeNode } = Tree;

export default React.memo((props) => {
  const {
    Data,
    checkedKeysRequest,
    checkedKeysTeam,
    expandedKeys,
    selectedKeys,
    indeterminateRequest,
    indeterminateTeam,
    setIndeterminateTeam,
    setIndeterminateRequest,
    setPaginationPage,
    LangCode,
    onCheckRequest,
    onCheckTeam,
    onExpand,
    onSelect,
    setCheckAllRequest,
    checkAllRequest,
    setDisbleApply,
  } = props;
  const intl = useIntl();
  const teamData = _.get(Data, 'team');
  const requestData = _.get(Data, 'reqTypeGroup');
  const [visiblePanelRequest, setVisiblePanelStage] = useState(false);
  const [visiblePanelTeam, setVisiblePanelTeam] = useState(false);
  const [checkAllTeam, setCheckAllTeam] = useState(false);

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };
  const allKeysRequest = getAllKeys(requestData);

  console.log('teamData', _.size(requestData));
  console.log('teamData', requestData);

  let getAllKeyTeams = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];
      result.push(...[x.orgId, ...childKeys]);
    });

    return result;
  };
  const allKeysTeam = getAllKeyTeams(teamData);

  const handleCheckAllRequest = (e) => {
    setIndeterminateRequest(false);
    setCheckAllRequest(e.target.checked);
    setPaginationPage(1);
    setDisbleApply(false);
    if (e.target.checked === true) {
      console.log('AllkeyRequest', allKeysRequest);
      onCheckRequest(allKeysRequest);
    } else {
      onCheckRequest([]);
    }
  };

  const handleCheckAllTeam = (e) => {
    setIndeterminateTeam(false);
    setCheckAllTeam(e.target.checked);
    setPaginationPage(1);
    setDisbleApply(false);
    if (e.target.checked === true) {
      onCheckTeam(allKeysTeam);
    } else {
      onCheckTeam([]);
    }
  };

  const headerTitleRequest = (res) => {
    return (

      <div>
      <Row>
        <Col span={14}>
        <Checkbox onChange={handleCheckAllRequest} indeterminate={indeterminateRequest} checked={checkAllRequest}>
            <span>
              {_.get(res, 'stageName')}
              <FormattedMessage id="ToReviewRequestType" defaultMessage="Request Type" />
            </span>
          </Checkbox>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
        <span className="select-text-panel-style
        .">{`${intl.formatMessage({
            id: `filterTaskSelected`,
            defaultMessage: 'Selected',
          })} ${_.size(checkedKeysRequest)}`}</span>
        </Col>
        <Col span={2} onClick={() => setVisiblePanelStage((current) => !current)} style={{ cursor: 'pointer', textAlign: 'right' }}>
          {visiblePanelTeam ? <Icon type="up" /> : <Icon type="down" />}
        </Col>
      </Row>
    </div>

      // <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      //   <div>
      //     <Checkbox onChange={handleCheckAllRequest} indeterminate={indeterminateRequest} checked={checkAllRequest}>
      //       <span>
      //         {_.get(res, 'stageName')}
      //         <FormattedMessage id="ToReviewRequestType" defaultMessage="Request Type" />
      //       </span>
      //     </Checkbox>
      //   </div>
      //   <div style={{ textAlign: 'right' }}>
      //     <span className="select-text-panel-style-myrequest">{`${intl.formatMessage({
      //       id: `filterTaskSelected`,
      //       defaultMessage: 'Selected',
      //     })} ${_.size(checkedKeysRequest)}`}</span>
      //     <span onClick={() => setVisiblePanelStage((current) => !current)} style={{ cursor: 'pointer', textAlign: 'right' }}>
      //       {visiblePanelRequest ? <Icon type="up" /> : <Icon type="down" />}
      //     </span>
      //   </div>
      // </div>
    );
  };

  const headerTitleTeam = (res) => {
    return (
      <div>
        <Row>
          <Col span={14}>
            <Checkbox onChange={handleCheckAllTeam} indeterminate={indeterminateTeam} checked={checkAllTeam}>
              <span>
                {_.get(res, 'teamChkboxName')}
                <FormattedMessage id="ToReviewRequestTeam" defaultMessage="Team" />
              </span>
            </Checkbox>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <span className="select-text-panel-style">{`${intl.formatMessage({
              id: `filterTaskSelected`,
              defaultMessage: 'Selected',
            })} ${_.size(checkedKeysTeam)}`}</span>
          </Col>
          <Col span={2} onClick={() => setVisiblePanelTeam((current) => !current)} style={{ cursor: 'pointer', textAlign: 'right' }}>
            {visiblePanelTeam ? <Icon type="up" /> : <Icon type="down" />}
          </Col>
        </Row>
      </div>

      // <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      //   <div>
      //     <Checkbox onChange={handleCheckAllTeam} indeterminate={indeterminateTeam} checked={checkAllTeam}>
      //       <span>{_.get(res, 'teamChkboxName')}<FormattedMessage id="ToReviewRequestTeam" defaultMessage="Team" /></span>
      //     </Checkbox>
      //   </div>
      //   <div style={{textAlign: 'right'}}>
      //     <span className="select-text-panel-style-myrequest">{`${intl.formatMessage({
      //       id: `filterTaskSelected`,
      //       defaultMessage: 'Selected',
      //     })} ${_.size(checkedKeysTeam)}`}</span>
      //     <span onClick={() => setVisiblePanelTeam((current) => !current)} style={{ cursor: 'pointer', textAlign: 'right' }}>
      //       {visiblePanelTeam ? <Icon type="up" /> : <Icon type="down" />}
      //     </span>
      //   </div>
      // </div>
    );
  };

  const renderTreeNodesRequest = (data) =>
    data.map((item) => {
      const title = <span>{item.reqTypeName}</span>;

      if (item.reqTypeList) {
        return (
          <TreeNode title={item.reqTypeName} key={item.reqTypeCode}>
            {renderTreeNodesRequest(item.reqTypeList)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.reqTypeCode} title={item.reqTypeName} />;
    });

  const renderTreeNodesTeam = (data) =>
    data.map((item) => {
      const title = <span>{item.teamName}</span>;

      if (item.children) {
        return (
          <TreeNode title={item.name} key={item.orgId}>
            {renderTreeNodesTeam(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.orgId} title={item.teamName} />;
    });

  return (
    <div>
      <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb' }}>
        <div>
          {headerTitleRequest()}
          {visiblePanelRequest ? (
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={false}
              onCheck={onCheckRequest}
              checkedKeys={checkedKeysRequest}
              onSelect={onSelect}
              selectedKeys={selectedKeys}
            >
              {renderTreeNodesRequest(_.get(Data, 'reqTypeGroup'))}
            </Tree>
          ) : null}
        </div>
      </div>

      <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb', borderBottom: '1px solid #e4e6eb' }}>
        <div>
          {headerTitleTeam()}
          {visiblePanelTeam ? (
            <div>
              <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={false}
                onCheck={onCheckTeam}
                checkedKeys={checkedKeysTeam}
              >
                {renderTreeNodesTeam(_.get(Data, 'team'))}
              </Tree>
            </div>
          ) : null}
        </div>
      </div>
      <div style={{ padding: '5px' }}></div>
    </div>
  );
});
