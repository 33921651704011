import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Divider,
  Calendar,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Switch,
  Tag,
  TreeSelect,
  Popover,
  notification,
  Button,
  ConfigProvider,
  Modal,
  InputNumber,
} from 'antd';
import styled from 'styled-components';
import { PageSettings } from './config/page-setting';
import httpClient from '../../../components/axiosClient';
// import MaximumOverTime from './shift-rules-maximum-overtime';
import MaximumOverTime from './shift-rules-maximum-overtime-test01';
import moment from 'moment';
import Card from '../../../components/v2/card';
import Button1 from '../../../components/v2/button_01';
import styles from './css/shift-rules.css';
import cssStyle2 from './css/multiple-datepicker.css';
import _ from 'lodash';

const { Option } = Select;

const ButtonAdd = styled(Button)`
  width: 57px;
  font-size: 13px;
  border-radius: 2px;
`;

const ButtonEdit = styled(Button)`
  width: 57px;
  font-size: 13px;
  border-radius: 2px;
  padding: 0 8px;
`;

const StyledInput = styled(Input)`
  width: 307px;
  .ant-input-group-addon {
    width: 65px;
    font-size: 13px;
  }
`;
const LabelRequire = styled.label`
  color: #ff1010;
  font-size: 13px;
`;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <p style={{ margin: '0px' }}>
      {text}&nbsp;
      {req ? <LabelRequire>*</LabelRequire> : ''}
    </p>
  );
};

function toValidArray(value) {
  const arr = Array.isArray(value) ? value : [value];
  return arr.filter((e) => e !== null && e !== undefined); // must be ==
}

const FormItems = (props) => {
  const intl = useIntl();
  const { form, valueWorkingHours, index, last, add, remove, workHours, switchShiftRules, hoursType, shiftWorkingHours } = props;
  const { getFieldDecorator, getFieldValue, getFieldError } = form;

  const hoursPer = form.getFieldValue(`shiftWorkHrRule`);

  const maxWorkDateType = valueWorkingHours.maxWorkDateType === null ? 'day' : valueWorkingHours.maxWorkDateType;

  const [checkDuplicate, setCheckDuplicate] = useState();
  // const [valueMin, setValueMin] = useState(24);

  const disabledMin = getFieldError(`shiftWorkHrRule[${index}].minWorkHour`);
  const disabledMax = getFieldError(`shiftWorkHrRule[${index}].maxWorkHour`);
  const disabledType = getFieldError(`shiftWorkHrRule[${index}].maxWorkDateType`);

  useEffect(() => {
    if (hoursPer) {
      const reduceAsMap =
        hoursPer &&
        hoursPer.reduce(
          (acc, curr) => {
            const hour = curr.maxWorkDateType;
            return [...acc, hour];
          },
          [hoursPer]
        );
      setCheckDuplicate(checkIfDuplicateExists(reduceAsMap));
    }
  }, [hoursPer]);

  const compareMinWorkHour = (rule, value, callback) => {
    if (value < 0) {
      callback('Minimum Work Hours must not be equal to 0 and not less than 0. !');
    }

    const hourDateType = form.getFieldValue(`shiftWorkHrRule[${index}].maxWorkDateType`);
    const found = workHours.findIndex((val) => val.dateType.toLowerCase() === hourDateType);
    let hourTypeValue = workHours[found].hourDateType;

    if (found > -1) {
      if (value > workHours[found].hourDateType) {
        callback(
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingRequireHoursMustNotExceed',
              defaultMessage: 'Maximum work hours must not exceed',
            })}
            <span>{` ${hourTypeValue} `}</span>
          </span>
        );
      }
    }
    callback();
  };

  const compareMaxWorkHour = (rule, value, callback) => {
    let minWorkHour = form.getFieldValue(`shiftWorkHrRule[${index}].minWorkHour`);
    if (parseInt(value) < parseInt(minWorkHour)) {
      callback(
        <span>
          {intl.formatMessage({
            id: 'orgTabShiftSettingRequireMaximumOverTimeProhibitedLess',
            defaultMessage: 'Prohibited members less than the minimum work hours.',
          })}
        </span>
      );
    }
    const hourDateType = form.getFieldValue(`shiftWorkHrRule[${index}].maxWorkDateType`);
    const found = workHours.findIndex((val) => val.dateType.toLowerCase() === hourDateType);
    let hourTypeValue = workHours[found].hourDateType;

    if (found > -1) {
      if (value > workHours[found].hourDateType) {
        callback(
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingRequireHoursMustNotExceed',
              defaultMessage: 'Maximum work hours must not exceed',
            })}
            <span>{` ${hourTypeValue} `}</span>
          </span>
        );
      }
    }
    callback();
  };

  const compareWorkType = (rule, value, callback) => {
    const compare = form.getFieldValue('shiftWorkHrRule');
    const arr = compare.map((item) => {
      return item.maxWorkDateType;
    });
    if (value) {
      callback('compare');
    } else {
      callback();
    }
  };

  const compareWorkDateType = (rule, value, callback) => {
    const workDateType = form.getFieldValue(`shiftWorkHrRule[0].maxWorkDateType`);
    const everySelect = workDateType.every((type) => type.maxWorkDateType === value.toString());

    if (everySelect === true) {
      callback(
        <span>
          {intl.formatMessage({
            id: 'orgTabShiftSettingRequireDateType',
            defaultMessage: 'Do not repeat the types of work days.',
          })}
        </span>
      );
    }
    callback();
  };

  const hasErrors = (fieldsError) => {
    let hasErrors = fieldsError.shiftWorkHrRule[index];
    return Object.keys(hasErrors).some((field) => hasErrors[field]);
  };

  const checkTypeHoursPer = (rule, value, callback, index) => {
    if (hoursPer.length === shiftWorkingHours.length) {
      _.forEach(hoursPer, (ele, i) => {
        if (index !== i) {
          if (value === ele.maxWorkDateType) {
            callback(
              <span>
                {intl.formatMessage({
                  id: 'orgTabShiftSettingRequireDateType',
                  defaultMessage: 'Do not repeat the types of work days.',
                })}
              </span>
            );
          }
        }
      });
      callback();
    } else {
      _.forEach(shiftWorkingHours, (ele, i) => {
        if (index !== i) {
          if (value === ele.maxWorkDateType) {
            callback(
              <span>
                {intl.formatMessage({
                  id: 'orgTabShiftSettingRequireDateType',
                  defaultMessage: 'Do not repeat the types of work days.',
                })}
              </span>
            );
          }
        }
      });
      callback();
    }
  };

  const checkIfDuplicateExists = (arr) => {
    return new Set(arr).size !== arr.length;
  };

  const handleChangeWorkHours = (value) => {
    const hourDateType = form.getFieldValue(`shiftWorkHrRule[${index}].maxWorkDateType`);
    const found = workHours.findIndex((val) => val.dateType.toLowerCase() === hourDateType);
    let hourTypeValue = workHours[found].hourDateType;
    // setValueMin(hourTypeValue);
  };

  const disabledAdd = () => {
    if (getFieldValue(`shiftWorkHrRule[${index}].minWorkHour`) && getFieldValue(`shiftWorkHrRule[${index}].maxWorkHour`)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div key={index} className="div-form-item">
      <Form.Item
        className="form-item-working-minimum-hours"
        label={
          index === 0 ? (
            <LabeRequire text={<FormattedMessage id="orgTabShiftSettingLblMinimumWork" defaultMessage="Minimum work hours" />} req={true} />
          ) : (
            ''
          )
        }
        validateStatus={disabledMin && switchShiftRules ? 'error' : ''}
        help={disabledMin && switchShiftRules ? disabledMin : ''}
      >
        {getFieldDecorator(`shiftWorkHrRule[${index}].minWorkHour`, {
          initialValue: valueWorkingHours.minWorkHour,
          rules: [
            {
              required: switchShiftRules,
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireMinimumWork',
                defaultMessage: 'Please enter your minimum working hours.',
              }),
            },
            {
              pattern: new RegExp(/^[0-9\b]+$/),
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireOnlyNumber',
                defaultMessage: 'Value should contain just number.',
              }),
            },
            {
              validator: compareMinWorkHour,
            },
          ],
        })(
          <Input
            className="shift-rules-maximum-input"
            placeholder={intl.formatMessage({
              id: 'orgTabShiftSettingHintMinimumWork',
              defaultMessage: 'Please enter minimum working hours',
            })}
            type="number"
            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : '')}
            disabled={!switchShiftRules}
            autoComplete="off"
            min="0"
          />
        )}
      </Form.Item>
      <Form.Item
        className="form-item-working-hours"
        label={
          index === 0 ? (
            <LabeRequire text={<FormattedMessage id="orgTabShiftSettingLblMaximumWork" defaultMessage="Maximum work hours" />} req={true} />
          ) : (
            ''
          )
        }
        validateStatus={disabledMax && switchShiftRules ? 'error' : ''}
        help={disabledMax && switchShiftRules ? disabledMax : ''}
      >
        {getFieldDecorator(`shiftWorkHrRule[${index}].maxWorkHour`, {
          initialValue: valueWorkingHours.maxWorkHour,
          rules: [
            {
              required: switchShiftRules,
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireMaximumWork',
                defaultMessage: 'Please enter your maximum working hours.',
              }),
            },
            {
              pattern: new RegExp(/^[0-9\b]+$/),
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireOnlyNumber',
                defaultMessage: 'Value should contain just number.',
              }),
            },
            {
              validator: compareMaxWorkHour,
            },
          ],
        })(
          <Input
            className="shift-rules-maximum-input"
            placeholder={intl.formatMessage({
              id: 'orgTabShiftSettingHintMaximumWork',
              defaultMessage: 'Please enter maximum working hours',
            })}
            type="number"
            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : '')}
            disabled={!switchShiftRules}
            autoComplete="off"
            min="0"
          />
        )}
      </Form.Item>
      <Form.Item
        className="form-item-select-working-hours"
        label={index === 0 ? <span className="span-color">ฺ</span> : ''}
        validateStatus={disabledType && switchShiftRules && checkDuplicate ? 'error' : ''}
        help={disabledType && switchShiftRules && checkDuplicate ? disabledType : ''}
      >
        {getFieldDecorator(`shiftWorkHrRule[${index}].maxWorkDateType`, {
          initialValue: maxWorkDateType,
          rules: [
            {
              required: switchShiftRules,
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireDateMonthYear',
                defaultMessage: 'Please enter date, month, year.',
              }),
            },
            {
              validator: (rule, value, callback) => checkTypeHoursPer(rule, value, callback, index),
            },
          ],
        })(
          <Select
            style={{ width: '324px', fontSize: '13px' }}
            disabled={!switchShiftRules}
            placeholder={intl.formatMessage({
              id: 'orgTabShiftSettingHintTypeHours',
              defaultMessage: 'Please enter Type/hours',
            })}
            onChange={handleChangeWorkHours}
          >
            {workHours.map((workHours) => (
              <Option key={workHours.dateType}>{workHours.displayTxt}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item className="form-item-button" label={index === 0 ? <span className="span-color">ฺ</span> : ''}>
        {index === last - 1 && index !== hoursType ? (
          <ButtonAdd onClick={add} disabled={!switchShiftRules || disabledAdd()}>
            <FormattedMessage id="orgTabShiftSettingShiftRulesBtnAdd" defaultMessage="Add" />
          </ButtonAdd>
        ) : (
          <ButtonEdit onClick={() => remove(valueWorkingHours, index)} disabled={!switchShiftRules}>
            <FormattedMessage id="orgTabShiftSettingShiftRulesBtnDelete" defaultMessage="Delete" />
          </ButtonEdit>
        )}
      </Form.Item>
    </div>
  );
};

const ShiftRules = React.memo((props) => {
  const intl = useIntl();
  const {
    trigerLoading,
    shiftData,
    workHours,
    holidays,
    treeTeams,
    orgId,
    refreshTable,
    shiftReserveTeam,
    setRefreshTable,
    shiftWorkHrRule,
    maxOverTimeRule,
    switchShiftRules,
    setSwitchShiftRules,
    locale,
    switchSubteam,
    comId,
    switchApply,
    setSwitchApply,
    setStatusApplySubTeam,
    statusApplySubTeam,
    txtApplySubTeam,
    setTxtApplySubTeam,
  } = useContext(PageSettings);

  const StyledHolidays = styled.div`
    width: 100%;
    height: auto;
    background: ${switchShiftRules === true ? '#ffffff' : '#f5f5f5'};
    border: 1px solid #e5e5e5;
    border-radius: 1px;
    padding: 0px 5px;
    margin: 0px 0px 12px 0px;
    align-items: center;
    :hover {
      border: ${switchShiftRules === true ? '1px solid #1D3557' : '1px solid #E5E5E5'};
      border-radius: 1px;
    }
  `;

  const StyleIcon = styled(Icon)`
    float: 'right';
    position: 'initial';
    :hover {
      color: ${switchShiftRules === true ? 'red' : '#000000'};
      opacity: 0.6;
    }
  `;

  // let shiftRuleId = shiftData.shiftRuleId ? '/' + shiftData.shiftRuleId : '';
  let shiftRuleId = shiftData.shiftRuleId ? shiftData.shiftRuleId : '';
  let hoursType = workHours.length - 1;
  const teamHolidays = holidays ? holidays.map((item) => moment(item.holidayDate)) : [];
  const format = 'DD MMM YY';

  const { getFieldDecorator, getFieldError, validateFields, getFieldsValue, getFieldValue, resetFields, setFieldsValue } = props.form;

  const disabledMaxOver = getFieldError(`maxOverTime`);
  const disabledMaxOverType = getFieldError(`maxOverDateType`);
  const disabledContinuous = getFieldError(`continuousWorkDay`);
  const [treeValue, setTreeValue] = useState(undefined);
  const [datesHoliday, setDatesHoliday] = useState();
  // const [visiblePopover, setVisiblePopover] = useState(false);
  const [treeTeamvalue, setTreeTeamvalue] = useState(shiftReserveTeam);
  const [disabled, setDisabled] = useState(true);
  const [shiftWorkingHours, setShiftWorkingHours] = useState([
    {
      mimHours: null,
      maxHours: null,
      workinghour: 'day',
    },
  ]);

  const [maxOverTime, setMaxOverTime] = useState([
    {
      maxOverTimeHour: null,
      maxOverTimeMember: null,
      maxOverDateType: 'day',
    },
  ]);

  useEffect(() => {
    if (teamHolidays.length > 0) {
      setDatesHoliday(teamHolidays);
    } else {
      setDatesHoliday([]);
    }
    setDisabled(getFieldValue('swapShift') === true ? false : true);
  }, [trigerLoading, orgId]);

  useEffect(() => {
    fncWorkHours();
    fncMaxOverTime();
  }, [trigerLoading, orgId]);

  const fncWorkHours = () => {
    let arrWorkingHours = { ...getFieldsValue() };
    if (shiftWorkHrRule?.length >= 1) {
      arrWorkingHours['shiftWorkHrRule'] = [...shiftWorkHrRule];
      setShiftWorkingHours(arrWorkingHours['shiftWorkHrRule']);
      props.form.setFieldsValue(arrWorkingHours);
    } else {
      arrWorkingHours['shiftWorkHrRule'] = [
        {
          minWorkHour: null,
          maxWorkHour: null,
          maxWorkDateType: 'day',
        },
      ];
      setShiftWorkingHours(arrWorkingHours['shiftWorkHrRule']);
      props.form.setFieldsValue(arrWorkingHours);
    }
  };

  const fncMaxOverTime = () => {
    let arrMaxOverTime = { ...getFieldsValue() };
    if (maxOverTimeRule?.length >= 1) {
      arrMaxOverTime['maxOverTimeRule'] = [...maxOverTimeRule];
      setMaxOverTime(arrMaxOverTime['maxOverTimeRule']);
      props.form.setFieldsValue(arrMaxOverTime);
    } else {
      arrMaxOverTime['maxOverTimeRule'] = [
        {
          maxOverTimeHour: null,
          maxOverTimeMember: null,
          maxOverDateType: 'day',
        },
      ];
      setMaxOverTime(arrMaxOverTime['maxOverTimeRule']);
      props.form.setFieldsValue(arrMaxOverTime);
    }
  };

  const handleSubmit = async (e, eventTrigger) => {
    e.preventDefault();
    if (eventTrigger !== 'click') return false;
    props.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const selectTree = values.shiftReserveTeamRules;
      const treeTeam = selectTree.join().split(',') === useEffect ? [] : selectTree.join().split(',');

      const holiday = convertMonths(datesHoliday);
      const shiftRuleStatus = switchShiftRules === true ? 'Enable' : 'Disable';

      const newShiftWorkHrRule = values.shiftWorkHrRule.map((item) => {
        return {
          minWorkHour: parseInt(item.minWorkHour),
          maxWorkHour: parseInt(item.maxWorkHour),
          maxWorkDateType: item.maxWorkDateType,
        };
      });

      const maxOverTimeRule = values.maxOverTimeRule.map((item) => {
        return {
          maxOverTimeHour: parseInt(item.maxOverTimeHour),
          maxOverTimeMember: parseInt(item.maxOverTimeMember),
          maxOverDateType: item.maxOverDateType,
        };
      });

      const maxOverTime = maxOverTimeRule.filter((item) => {
        return item.maxOverTimeHour !== null;
      });

      const body = {
        comId: comId,
        orgId: `${orgId}`,
        shiftRuleStatus: shiftRuleStatus,
        shiftWorkHrRule: newShiftWorkHrRule,
        maxOverTimeRule: maxOverTime,
        continuousWorkDay: parseInt(values.continuousWorkDay),

        swapShift: values.swapShift,
        gteLevel: values.gteLevel,
        lteLevel: values.lteLevel,
        anyShiftType: values.anyShiftType,
        shiftReserveTeamRules: treeTeam,
        teamHolidays: holiday,
        statusSubTeam: switchSubteam,
      };

      try {
        const response = await httpClient.post(`/resource/manager/organization/shift/rules/save/${shiftRuleId}`, body);

        if (response.status === 200) {
          shiftRulesNotificationSuccess(response.data.message);
          setRefreshTable(!refreshTable);
        } else {
          shiftRulesNotificationError(response.data.message);
        }
      } catch (error) {
        shiftRulesNotificationError(error.response.data.message);
      }
    });
  };

  const onChangeTreeSelect = (value) => {
    setTreeValue(value);
  };

  const arrValues = useMemo(() => toValidArray(datesHoliday), [datesHoliday]);

  const customRenderDate = useCallback(
    (current) => {
      if (arrValues.some((e) => current.isSame(e, 'day'))) {
        return <div className="selectedDate">{current.format('DD')}</div>;
      }
      return <div>{current.format('DD')}</div>;
    },
    [arrValues]
  );

  const onChange = useCallback(
    (date) => {
      const index = arrValues.findIndex((e) => e.isSame(date, 'day'));
      const temp = [...arrValues];
      if (index !== -1) {
        temp.splice(index, 1);
      } else {
        temp.push(date);
      }
      setDatesHoliday(temp);
    },
    [arrValues, setDatesHoliday]
  );

  const onClose = (index) => {
    let newVal = [...arrValues];
    newVal.splice(index, 1);
    setDatesHoliday(newVal);
  };

  const onDeselect = useCallback(
    (date) => {
      const newVal = arrValues.filter((e) => !e.isSame(date));
      setDatesHoliday(newVal);
    },
    [arrValues, setDatesHoliday]
  );

  const convertMonths = (months) => {
    const arrMonths = [...months];
    const holidayFormat = [];
    arrMonths.forEach((ele) => {
      holidayFormat.push(moment(ele).format('YYYY-MM-DD'));
    });
    return holidayFormat;
  };

  const onSwitchSwapShift = (checked) => {
    setDisabled(!checked);
    setFieldsValue({
      gteLevel: false,
      lteLevel: false,
      anyShiftType: false,
    });
  };

  const handleVisibleChange = (visible) => {
    props.setVisiblePopover(!props.visiblePopover);
  };

  const hidePopover = (e) => {
    e.preventDefault();
    props.setVisiblePopover(false);
  };

  const CalendarSelect = () => {
    return (
      <div className="site-calendar-demo-card">
        <ConfigProvider locale={locale}>
          <Calendar
            className="calendar-card"
            fullscreen={false}
            onSelect={onChange}
            dateFullCellRender={(current) => customRenderDate(current)}
            monthCellRender={() => moment().format('DD MMM YY')}
          />
        </ConfigProvider>
      </div>
    );
  };

  const onChangeTreeTeams = (value, label, extra) => {
    setTreeTeamvalue(...treeTeamvalue, value.map(String));
  };

  const saveShiftRulesNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const shiftRulesNotificationSuccess = (message) => {
    notification.success({
      message: message,
    });
  };

  const shiftRulesNotificationError = (message) => {
    notification.error({
      message: message,
    });
  };

  const validateMinimumWorkingTime = (rule, value, callback) => {
    if (value > 1000) {
      callback(
        <span>
          {intl.formatMessage({
            id: 'orgTabShiftSettingRequireMaximumValue',
            defaultMessage: 'Not more than 1000.',
          })}
        </span>
      );
    }
    callback();
  };

  const validateMaximum = (rule, value, callback) => {
    if (value) {
      if (value > 1000) {
        callback(
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingRequireMaximumValue',
              defaultMessage: 'Not more than 1000.',
            })}
          </span>
        );
      }
    } else {
      callback();
    }
    callback();
  };

  const add = () => {
    let arrWorkingHours = { ...getFieldsValue() };
    arrWorkingHours['shiftWorkHrRule'] = [
      ...arrWorkingHours['shiftWorkHrRule'],
      {
        minWorkHour: null,
        maxWorkHour: null,
        maxWorkDateType: 'day',
      },
    ];
    setShiftWorkingHours(arrWorkingHours['shiftWorkHrRule']);
    props.form.setFieldsValue(arrWorkingHours);
  };

  const remove = (value, index) => {
    let arrWorkingHours = { ...getFieldsValue() };
    if (!(arrWorkingHours['shiftWorkHrRule'] && arrWorkingHours['shiftWorkHrRule'].length)) return;
    arrWorkingHours['shiftWorkHrRule'].splice(index, 1);
    setShiftWorkingHours(arrWorkingHours['shiftWorkHrRule']);
    props.form.setFieldsValue(arrWorkingHours);
  };

  const handleAddMaxOverTime = () => {
    let arrMaxOverTime = { ...getFieldsValue() };
    arrMaxOverTime['maxOverTimeRule'] = [
      ...arrMaxOverTime['maxOverTimeRule'],
      {
        maxOverTimeHour: null,
        maxOverTimeMember: null,
        maxOverDateType: 'day',
      },
    ];
    setMaxOverTime(arrMaxOverTime['maxOverTimeRule']);
    props.form.setFieldsValue(arrMaxOverTime);
  };

  const handleDeleteMaxOverTime = (value, index) => {
    let arrMaxOverTime = { ...getFieldsValue() };
    if (!(arrMaxOverTime['maxOverTimeRule'] && arrMaxOverTime['maxOverTimeRule'].length)) return;
    arrMaxOverTime['maxOverTimeRule'].splice(index, 1);
    setMaxOverTime(arrMaxOverTime['maxOverTimeRule']);
    props.form.setFieldsValue(arrMaxOverTime);
  };

  const onChangeSwitchShiftRules = async (checked) => {
    setSwitchShiftRules(checked);
  };

  const handleSwitch = (checked) => {
    Modal.confirm({
      className: 'confirm-modal',
      icon: null,
      title: 'Are you sure to Apply this setting',
      okText: intl.formatMessage({
        id: 'modalConfirmBtnYes',
        defaultMessage: 'Yes',
      }),
      cancelText: intl.formatMessage({
        id: 'modalConfirmBtnNo',
        defaultMessage: 'No',
      }),
      centered: true,
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const shiftRuleStatus = checked === true ? 'Enable' : 'Disable';
        const body = {
          comId: `${comId}`,
          orgId: `${orgId}`,
          shiftRuleStatus: shiftRuleStatus,
        };

        try {
          const response = await httpClient.post(`/resource/manager/organization/shift/rules/save/${shiftRuleId}`, body);
          if (response.status === 200) {
            setSwitchApply(!switchApply);
            shiftRulesNotificationSuccess(response.data.message);
          } else {
            shiftRulesNotificationSuccess(response.data.message);
          }
        } catch (err) {
          console.error();
          shiftRulesNotificationError(err.message);
        }
      },
      onCancel() {},
    });
  };

  const TitleShiftRules = () => {
    return (
      <div>
        <FormattedMessage id="orgTabShiftSettingTitleShiftRules" defaultMessage="Shift Rules" />
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        {statusApplySubTeam ? (
          <span>
            (
            <FormattedMessage id="orgShiftSettingApplyFromTxt" defaultMessage="Apply from" /> {txtApplySubTeam}{' '}
            <FormattedMessage id="orgShiftSettingSettingTxt" defaultMessage="Setting" />)
          </span>
        ) : null}
      </div>
    );
  };

  return (
    <PageSettings.Consumer>
      {() => (
        <Form onSubmit={handleSubmit}>
          <Card
            title={<TitleShiftRules />}
            bordered={false}
            extra={
              <Form.Item>
                <span style={{ marginRight: '16px' }}>
                  <FormattedMessage id="orgShiftSettingApplythisSettingTxt" defaultMessage="Apply this setting" />
                </span>
                <Switch onClick={handleSwitch} checked={switchShiftRules} style={{ marginRight: '40px' }} />
                <Button1 type="primary" btnsize="wd_df" fontsize="sm" onClick={(e) => handleSubmit(e, 'click')}>
                  <FormattedMessage id="orgTabShiftSettingBtnSave" defaultMessage="Save" />
                </Button1>
              </Form.Item>
            }
          >
            <div className="shift-rules-from-body">
              {shiftWorkingHours
                ? shiftWorkingHours.map((item, i) => (
                    <FormItems
                      form={props.form}
                      valueWorkingHours={item}
                      index={i}
                      last={shiftWorkingHours?.length}
                      add={add}
                      remove={remove}
                      workHours={workHours}
                      switchShiftRules={switchShiftRules}
                      hoursType={hoursType}
                      shiftWorkingHours={shiftWorkingHours}
                    />
                  ))
                : null}

              <Divider className="divider-x" />

              {maxOverTime
                ? maxOverTime.map((item, i) => (
                    <MaximumOverTime
                      form={props.form}
                      valueOverTime={item}
                      index={i}
                      last={maxOverTime?.length}
                      handleAddMaxOverTime={handleAddMaxOverTime}
                      handleDeleteMaxOverTime={handleDeleteMaxOverTime}
                      workHours={workHours}
                      switchShiftRules={switchShiftRules}
                      hoursType={hoursType}
                      maxOverTime={maxOverTime}
                    />
                  ))
                : null}

              <Divider className="divider-x" />

              <Form.Item
                className="maximum-continuous"
                label={
                  <LabeRequire
                    text={
                      <FormattedMessage
                        id="orgTabShiftSettingLblMaximumContinuousWorkingDay"
                        defaultMessage="Maximum Continuous Working Day"
                      />
                    }
                    req={true}
                  />
                }
                validateStatus={disabledContinuous && switchShiftRules ? 'error' : ''}
                help={disabledContinuous && switchShiftRules ? disabledContinuous : ''}
              >
                {getFieldDecorator('continuousWorkDay', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingRequireMaximumContinuousWorkingDay',
                        defaultMessage: 'Please input your working day.',
                      }),
                    },
                    {
                      pattern: new RegExp(/^[0-9\b]+$/),
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingRequireOnlyNumber',
                        defaultMessage: 'Value should contain just number.',
                      }),
                    },
                    {
                      validator: validateMaximum,
                    },
                  ],
                })(
                  <StyledInput
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingHintMaximumContinuousWorkingDay',
                      defaultMessage: 'Please enter your maximum continuous working day',
                    })}
                    disabled={!switchShiftRules}
                    type="number"
                  />
                )}
              </Form.Item>

              <Form.Item
                className="switch-swapShift"
                label={<FormattedMessage id="orgTabShiftSettingLblSwapShiftWith" defaultMessage="Swap shift with" />}
              >
                {getFieldDecorator('swapShift', {
                  valuePropName: 'checked',
                })(<Switch className="swapShift" onChange={onSwitchSwapShift} disabled={!switchShiftRules} />)}
              </Form.Item>

              <Row>
                <Col span={8}>
                  <Form.Item
                    className="switch-swapShift"
                    label={<FormattedMessage id="orgTabShiftSettingLblSameOrHigherLevel" defaultMessage="Same or higher level" />}
                  >
                    {getFieldDecorator('gteLevel', {
                      valuePropName: 'checked',
                    })(<Switch className="swapShift-condition" disabled={disabled || !switchShiftRules} />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="switch-swapShift"
                    label={<FormattedMessage id="orgTabShiftSettingLblSameOrLowerLevel" defaultMessage="Same or lower level" />}
                  >
                    {getFieldDecorator('lteLevel', {
                      valuePropName: 'checked',
                    })(<Switch className="swapShift-condition" disabled={disabled || !switchShiftRules} />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="switch-swapShift"
                    label={<FormattedMessage id="orgTabShiftSettingLblAnyShiftType" defaultMessage="Any shift type" />}
                  >
                    {getFieldDecorator('anyShiftType', {
                      valuePropName: 'checked',
                    })(<Switch className="swapShift-condition" disabled={disabled || !switchShiftRules} />)}
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                className="shift-rules-form-item"
                label={
                  <FormattedMessage
                    id="orgTabShiftSettingLblTeam"
                    defaultMessage="In case of insufficient people, select someone from the team"
                  />
                }
              >
                {getFieldDecorator('shiftReserveTeamRules', {
                  initialValue: treeTeamvalue,
                })(
                  <TreeSelect
                    className="treeSelect-tags"
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingHintTeam',
                      defaultMessage: 'Please enter your select someone from the team',
                    })}
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={treeTeams}
                    treeNodeFilterProp={'title'}
                    multiple
                    allowClear
                    treeDefaultExpandAll
                    onChange={onChangeTreeTeams}
                    disabled={!switchShiftRules}
                  />
                )}
              </Form.Item>

              <Form.Item
                className="shift-rules-form-item"
                label={<FormattedMessage id="orgTabShiftSettingLblTeamHolidays" defaultMessage="Team Holidays" />}
              >
                {getFieldDecorator('select-holiday')(
                  <Popover
                    className="popover-select-holiday"
                    content={switchShiftRules === true ? <CalendarSelect /> : null}
                    trigger="click"
                    // visible={props.visiblePopover}
                    // onVisibleChange={handleVisibleChange}
                    placement="bottomRight"
                  >
                    <StyledHolidays
                      placeholder={intl.formatMessage({
                        id: 'orgTabShiftSettingHintTeamHolidays',
                        defaultMessage: 'Please enter your team holidays',
                      })}
                    >
                      {arrValues.length > 0 ? null : (
                        <p className="p-tag-placeholder">
                          <FormattedMessage id="orgTabShiftSettingHintTeamHolidays" defaultMessage="Please enter your team holidays" />
                        </p>
                      )}
                      {arrValues.map((date, index) => (
                        <Tag
                          className="shift-holiday-tag"
                          style={{
                            backgroundColor: switchShiftRules === true ? '#ffffff' : '#f5f5f5',
                            color: switchShiftRules === true ? '#1D3557' : 'rgba(0, 0, 0, 0.33)',
                            border: switchShiftRules === true ? '1px solid #1D3557' : '1px solid #e8e8e9',
                            borderRadius: '2px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                          }}
                          key={date}
                          onClose={() => onDeselect(date)}
                          closable={switchShiftRules}
                          disabled={false}
                        >
                          {moment(date).format('DD MMM YY')}
                        </Tag>
                      ))}
                      <div className="div-icon-tag">
                        <StyleIcon type="calendar" />
                      </div>
                    </StyledHolidays>
                  </Popover>
                )}
              </Form.Item>
            </div>
          </Card>
        </Form>
      )}
    </PageSettings.Consumer>
  );
});

const WrappedRegistrationForm = Form.create({
  name: 'shiftRules',
  mapPropsToFields(props) {
    const { formData } = props;

    const maximumOverTime = () => {
      if (formData && _.get(props, 'formData.maxOverTimeRule.value')?.length >= 1) {
        return _.get(props, 'formData.maxOverTimeRule.value');
      } else {
        return [
          {
            maxOverTimeHour: null,
            maxOverTimeMember: null,
            maxOverDateType: 'day',
          },
        ];
      }
    };

    return {
      maxWorkHour: Form.createFormField({
        value: formData ? formData.maxWorkHour.value : '',
      }),
      maxWorkDateType: Form.createFormField({
        value: formData && formData.maxWorkDateType.value ? formData.maxWorkDateType.value : 'day',
      }),
      maxOverDateType: Form.createFormField({
        value: formData && formData.maxOverDateType.value ? formData.maxOverDateType.value : 'day',
      }),
      swapShift: Form.createFormField({
        value: formData ? formData.swapShift.value : false,
      }),
      gteLevel: Form.createFormField({
        value: formData ? formData.gteLevel.value : false,
      }),
      lteLevel: Form.createFormField({
        value: formData ? formData.lteLevel.value : false,
      }),
      anyShiftType: Form.createFormField({
        value: formData ? formData.anyShiftType.value : false,
      }),
      continuousWorkDay: Form.createFormField({
        value: formData ? formData.continuousWorkDay.value : '',
      }),
      shiftReserveTeamRules: Form.createFormField({
        value: formData ? formData.shiftReserveTeam.value : '',
      }),
      maxOverTime: Form.createFormField({
        value: maximumOverTime(),
      }),
    };
  },
  onValuesChange(props, values, allValues) {},
})(ShiftRules);

export default React.memo(WrappedRegistrationForm);
