import React from 'react';
import _ from 'lodash';
import { Row, Col, Timeline, Badge } from 'antd';
import styled from 'styled-components';
import CommentDetails from './assignee-modal-comment';
import { FormattedMessage } from 'react-intl';

const StatusName = styled.span`
  font-weight: bold;
  font-size: 13px;
  color: #1d3557;
`;

const TimeStamp = styled.span`
  font-size: 12px;
  color: #1d3557;
`;

export default React.memo((props) => {
  const { tracking } = props;

  const trackingTimeline =
    tracking &&
    tracking.map((item, index) => (
      <Timeline.Item key={index} dot={<Badge className="dot-badge" color={item.statusColor || '#BCC5D3'} />}>
        <div className="div-name-comment">
          <StatusName>{item.statusName || '-'}</StatusName>
          {item.comments ? <CommentDetails comments={item.comments || []} /> : null}
        </div>
        <TimeStamp>{item.timestampView || '-'}</TimeStamp>
      </Timeline.Item>
    ));

  return (
    <div>
      <Row className="tracking-row">
        <Col span={24} className="title-col-label">
          <span className="tracking-title-label">
            <FormattedMessage id={`monitorTabDetailModalAssigneeResourceTrackingTxtTitle`} defaultMessage="Tracking" />
          </span>
        </Col>
      </Row>
      <Row className="tracking-row">
        <Col span={24}>
          <Timeline className="assignee-tracking-timeline">{trackingTimeline}</Timeline>
        </Col>
      </Row>
    </div>
  );
});
