import React from 'react';
import { Button } from 'antd';
import cssStyle from './css/card.css';
import styled from 'styled-components';

export default (props) => {
  const Buttons = styled(Button)`
    background-color: ${props.backColor};
    border-color: #f0f2f5;
    &:hover {
      color: #1d3557;
      background: #e0e7f5;
      border-color: #e0e7f5;
    }
  `;

  return <Buttons {...props}>{props.children}</Buttons>;
};
