import React, { useEffect, useState } from 'react';
import { RepairticketContext } from './repair-ticket-context';
import _ from 'lodash';
import { getBrandReportTicket, getModelReportTicket, getTicketData, getTicketDetail, searchReportTicket } from '../../../controllers/ticket/ticket';
import { getMenuFilter } from '../../../controllers/approval/approval-controller';
import { PageSettings } from '../../../config/page-settings';
import { Col, Row } from 'antd';
import LeftCardRapirTicket from './left-card';
import RightCardRepairTicket from './right-card';

const RepairTicketReport = (props) => {
  const [checkedListRequest, setCheckedListRequest] = useState();
  const [checkteam, setCheckTeam] = useState();
  const [orgIdRaw, setOrgIdRaw] = useState();
  const [paginationPage, setPaginationPage] = useState();
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [menuList, setMenuList] = useState([]);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [checkedKeysTeam, setCheckedKeysTeam] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [changeStatus, setChangeStatus] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [changeTab, setChangeTab] = useState('1');
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [ticketData, setTicketData] = useState([]);
  const [event, setEvent] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState();
  const [totalBrand, setTotalBrand] = useState(10);
  const [totalModel, setTotalModel] = useState(10);

  useEffect(() => {
    const getApiMenu = async () => {
      const response = await getMenuFilter();
      setMenuList(response.data);
      const allteam = _.map(response.data.data.team,'orgId')
      onCheckTeam(allteam)
    };
    getApiMenu();
  }, []);

  useEffect(() => {
    const getDataTable = async () => {
      // const body = {
      //   teamList: checkedKeysTeam,
      //   ticketNo: _.get(fillter, 'reno'),
      //   startDate: _.get(fillter, 'startdate'),
      //   endDate: _.get(fillter, 'enddate'),
      //   statusList: _.get(fillter, 'status'),
      //   ticketTypeList: [],
      //   pageNumber: page,
      //   limit: size,
      //   orderBy: _.get(sort, 'field'),
      //   orderType: _.get(sort, 'order') === 'descend' ? 'desc' : 'asc',
      // };

      const body = {
        team: checkedKeysTeam,
        ticketNo: _.get(fillter, 'reno'),
        startDate: _.get(fillter, 'startdate'),
        endDate: _.get(fillter, 'enddate'),
        status: _.get(fillter, 'status') !== undefined ? [_.get(fillter, 'status')] : _.get(fillter, 'status'),
        brand: _.get(fillter, 'brand') !== undefined ? [_.get(fillter, 'brand')] : _.get(fillter, 'brand'),
        model: _.get(fillter, 'model') !== undefined ? [_.get(fillter, 'model')] : _.get(fillter, 'model'),
        assetName: _.get(fillter, 'assetname'),
        serialNo: _.get(fillter, 'serailno'),
        pageNumber: page,
        limit: size,
      };

      console.log('bodyTicket', body);
      // const responase = await getTicketData(body);
      const responase = await searchReportTicket(body);
      const mapIndex = _.map(_.get(responase, 'data.data.list'), (item, index) => {
        return {
          ...item,
          index: (page - 1) * size + index + 1
        }
      })
      setTicketData({
        data: {
          ..._.get(responase, 'data.data'),
          ticketList: mapIndex
        }
      });
      // setTicketData(_.get(responase, 'data.data'));
      setTotal(_.get(responase, 'data.data.totalItems'));
      setIsLoading(false);
    };
    setIsLoading(true);
    getDataTable();
    
    console.log('tikcetData', ticketData);
  }, [event, checkedKeysTeam, fillter, page, size]);

  useEffect(() => {
    getBrand();
  }, [totalBrand])

  useEffect(() => {
    if (_.get(fillter, 'brand') !== undefined) {
      getModel();
    }
    
  }, [totalModel,_.get(fillter, 'brand')])
  

  const getBrand = async () => {
    const body = {
      indexStart: 0,
      indexEnd: totalBrand,
      searchName: '',
    };

    const responase = await getBrandReportTicket(body);
    console.log('brandData',responase.data);
    setBrandData(_.get(responase, 'data.data.list'));
    // setTotal(_.get(responase, 'data.data.totalItems'));
    setIsLoading(false);
  };

  const getModel = async () => {
    const body = {
      indexStart: 0,
      indexEnd: totalBrand,
      searchName: '',
      assetBrandId: [_.get(fillter, 'brand')]
    };


    const responase = await getModelReportTicket(body);
    console.log('ModelData',responase.data);
    setModelData(_.get(responase, 'data.data.list'));
    // setTotal(_.get(responase, 'data.data.totalItems'));
    setIsLoading(false);
  };

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };

  const allKeysTeam = getAllKeys(_.get(menuList, 'data.team'));
  const onCheckTeam = (checkedKeys) => {
    setCheckedKeysTeam(checkedKeys);
    setIndeterminateTeam(!!_.size(checkedKeys) && _.size(checkedKeys) < _.size(allKeysTeam));
    setCheckAllTeam(_.size(checkedKeys) === _.size(allKeysTeam));
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleVisibleCreate = () => {
    setVisibleCreate(true);
  };

  const handleView = (record) => {
    setTicketId(record.ticketId);
    setVisibleView(true);
  };

  const handleEdit = async (record) => {
    const responase = await getTicketDetail(_.get(record, 'ticketId'));
    setEditRecord(_.get(responase, 'data.data.rawData'));
    setTicketId(record.ticketId);
    handleVisibleCreate();
    console.log('editRecord1', _.get(responase, 'data.data'));
    // console.log('editRecord',_.get(responase,'data.data.rawData'));
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction(`P53PG1C1`, `P53PG1C1A1`) ? (
          <RepairticketContext.Provider
            value={{
              state: {
                checkedListRequest,
                checkteam,
                orgIdRaw,
                paginationPage,
                spanRight,
                spanLeft,
                menuList,
                indeterminateTeam,
                checkedKeysTeam,
                searchList,
                checkAllTeam,
                changeTab,
                isLoading,
                fillter,
                selectedRowKeys,
                toggle,
                visibleCreate,
                ticketData,
                event,
                editRecord,
                sort,
                ticketId,
                page,
                size,
                total,
                isLoading,
                brandData,
                totalBrand,
                modelData,
                totalModel,
              },
              setState: {
                setSpanRight,
                setSpanLeft,
                setCheckedListRequest,
                setCheckTeam,
                setOrgIdRaw,
                setPaginationPage,
                setCheckedKeysTeam,
                setIndeterminateTeam,
                setChangeStatus,
                setChangeTab,
                setCheckAllTeam,
                setIsLoading,
                setFillter,
                setSelectedRowKeys,
                setToggle,
                setVisibleCreate,
                setEditRecord,
                setEvent,
                setSort,
                setTotalBrand,
                setTotalModel,
                setPage,
              },
              fnc: {
                onCheckTeam,
                handleToggle,
                handleChangePage,
                handleChangeSize,
                handleVisibleCreate,
                handleView,
                handleEdit,
              },
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={toggle ? 0 : 6}>
                <LeftCardRapirTicket />
              </Col>
              <Col span={toggle ? 24 : 18}>
                <RightCardRepairTicket />
              </Col>
            </Row>
            {/* <CreateModalForm /> */}
            {/* <ViewModal visible={visibleView} setVisible={setVisibleView} ticketId={ticketId} /> */}
          </RepairticketContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default RepairTicketReport;
