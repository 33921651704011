import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactBnbGallery from 'react-bnb-gallery';
import { Collapse, Col, Divider, Row, Icon, Avatar, Upload, Input, Modal, Card, Badge, notification } from 'antd';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';

import { TaskDetailContext } from '../context';
import { PageSettings } from '../../../config/page-settings';
import { successNotification } from '../../../components/v2/notification';
import { Round } from '../../../component-function/lodash';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../component-function/fnc-number';
import { numberFormatter, numberFormatterCheckDot, numberParser } from '../../../component-function/fnc-inputnumber-currency';
import { EditTruckWeight, subTaskApi } from '../../../controllers/task/task-detail';

import NoDataBackgroundModal from '../../no-data-page/modal-no-data';
import ModalAddEngagment from '../../../components/modal-add-post-engagement';
import ModalEditEngagment from '../../../components/modal-edit-post-engagement';
import ModalViewOnlyEngagment from '../../../components/modal-viewOnly-post/index';
import downloadImage from '../../../controllers/downLoad-image';

import iconnumeric from '../../../assets/svg/numeric.svg';
import '../../monitor-component/transportation/task-detail/component/css/subtask.css';
import style from '../../monitor-component/transportation/task-detail/component/css/subtask.css';

const { Panel } = Collapse;

const StyleModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 400px;
    max-height: 500px;
  }

  .ant-modal-close {
    color: #ffffff;
    right: 667px;
  }

  .ant-modal-close-x {
    font-size: 24px;
  }

  .image-gallery-index {
    margin: 0px 20px 0px 20px;
  }

  .image-gallery-right-nav {
    right: 30%;
  }

  .image-gallery-left-nav {
    left: 30%;
  }
`;

export default (props) => {
  console.log('props', props);
  const intl = useIntl();

  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;

  const taskId = _.get(props, 'taskDetail.taskId');
  const fncSubTask = _.get(props, 'funcGetTask');
  const [dataSource, setDataSource] = useState({});
  const subtask = dataSource.subtask;
  const subtaskLength = Array.isArray(subtask) ? subtask.length : 0;
  const { state, setState } = useContext(TaskDetailContext);
  const { detailData } = state;
  const assigneeDetail = _.get(detailData, 'information.assignee');
  const taskNo = _.get(props, 'taskDetail.information.information.taskNo');
  const taskName = _.get(props, 'taskDetail.information.information.taskName');

  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState([]);
  const [startIndexGallery, setStartIndexGallery] = useState(0);

  const [triggerPost, setTriggerPost] = useState(false);
  const [visibleViewPost, setVisibleViewPost] = useState(false);
  const [visibleAddPost, setVisibleAddPost] = useState(false);
  const [visibleEditPost, setVisibleEditPost] = useState(false);
  const [editPostData, setEditPostData] = useState();
  const [subTaskWfId, setSubTaskWfId] = useState();

  const [openGallery, setOpenGallery] = useState(false);
  const [galleryImg, setGalleryImg] = useState([]);
  const [numberImage, setNumberImage] = useState(0);

  const [subTaskWFIdChange, setSubTaskWFIdChange] = useState();

  const resourceSelect = assigneeDetail && assigneeDetail.filter((item) => state.keys.includes(item.memComId));
  const selectResKeys = resourceSelect.map((a) => a.memComId);

  useEffect(() => {
    const getData = async () => {
      if (_.isFunction(fncSubTask) && taskId) {
        const subTask = await fncSubTask(taskId);
        setDataSource(subTask);
      }
    };
    getData();
  }, [taskId]);

  useEffect(() => {
    setState.setActiveKey([]);
  }, [taskId]);

  const handleCancel = () => {
    setStateModal(false);
  };

  const handleDownload = async (val, taskNo, totalAssigneeFile, typePhoto, subtask) => {
    if (val) {
      try {
        const response = await downloadImage(val, taskNo, typePhoto);
        await openNotification(response, totalAssigneeFile);
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${taskNo}_${subtask}.zip`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {}
    }
  };

  const key = 'download';
  const openNotification = (response, totalAssigneeFile) => {
    notification.open({
      key,
      message: `${intl.formatMessage({ id: 'notiDownloading', defaultMessage: 'Downloading' })} ${totalAssigneeFile} ${
        totalAssigneeFile > 1
          ? `${intl.formatMessage({ id: 'notiPhotos', defaultMessage: 'photos' })}`
          : `${intl.formatMessage({ id: 'notiPhoto', defaultMessage: 'photo' })}`
      }`,
      icon: <Icon type="loading" />,
    });
    if (response) {
      setTimeout(() => {
        notification.open({
          key,
          message: `${intl.formatMessage({ id: 'notiPhotohas', defaultMessage: 'Photo has' })} ${totalAssigneeFile} ${intl.formatMessage({
            id: 'notiDownloaded',
            defaultMessage: 'downloaded',
          })}`,
          icon: <Icon type="check-circle" style={{ color: '#52c41a' }} />,
        });
      }, 1000);
    }
  };

  const handleDownloadNote = async (val, taskNo, totalAssigneeFile, typePhoto, subtask, subTaskWf) => {
    if (val) {
      try {
        const response = await downloadImage(val, taskNo, typePhoto, subTaskWf);
        await openNotificationNote(response, totalAssigneeFile);
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${taskNo}_${subtask}.zip`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {}
    }
  };

  const openNotificationNote = (response) => {
    notification.open({
      key,
      message: intl.formatMessage({ id: 'subtaskNoteDownloading', defaultMessage: 'Downloding Files' }),
      icon: <Icon type="loading" />,
    });
    if (response) {
      setTimeout(() => {
        notification.open({
          key,
          message: intl.formatMessage({ id: 'subtaskNoteDownloaded', defaultMessage: 'File has downloaded' }),
          icon: <Icon type="check-circle" style={{ color: '#52c41a' }} />,
        });
      }, 1000);
    }
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <StyleModal visible={stateModal} onCancel={handleCancel} footer={null} width={'100%'}>
          <ImageGallery
            className={style.imagegallery}
            items={modalView}
            showPlayButton={false}
            showIndex={true}
            startIndex={startIndexGallery}
            showFullscreenButton={false}
          />
        </StyleModal>
      </div>
    );
  };

  const handlePreviewImage = (data, itemData, items) => {
    const mapPhoto =
      data && _.size(data) !== 0
        ? data.map((item, index) => {
            return {
              photo: _.get(item, 'url'),
              number: index,
              caption: '',
              subcaption: `Photo By ${_.get(items, 'fullname')}`,
              thumbnail: _.get(item, 'url'),
            };
          })
        : [];

    setGalleryImg(mapPhoto);
    setOpenGallery(true);
    setNumberImage(itemData.uid);
  };

  const callback = (val) => {
    setState.setActiveKey(val);

    let lastElement = val.slice(-1);
    setSubTaskWFIdChange(_.get(lastElement, [0]));
  };

  const TitleName = () => (
    <div>
      <div style={{ textAlign: 'left' }}>
        <span className="assignee-details-title">{taskNo}</span>
        <Badge color="#1D3557" className="beetwen-badge-dot" />
        <span className="assignee-details-title">{taskName}</span>
      </div>
    </div>
  );

  const headerNoExpand = (item, selectResKeys, assigneeDetail, taskDetailId, taskNo, subtaskCode, subTaskWf, subTaskWFIdChange) => {
    const assigneeRender = item.assignee.filter(
      (e) =>
        selectResKeys.includes(e.memComId) ||
        _.findIndex(assigneeDetail, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );
    let totalAssignee = assigneeRender.reduce((accumulator, item) => {
      return accumulator + _.get(item, 'subtaskDetail[0].totalFileView');
    }, 0);

    return (
      <Row className="header-collapse">
        <Col span={7} className="subtask-header-icon-text">
          <img src={item.icon} style={{ width: '30px', height: '18px', paddingRight: '10px' }}></img>
          <span className="text-space-header">{item.subtaskName}</span>
        </Col>
        <Col span={17}>
          <div>
            <Col span={assigneeRender.length === 0 && subtaskCode === 'note_task' ? 6 : 5} style={{ display: 'flex' }}>
              {assigneeRender.length !== 0 ? (
                subtaskCode === 'note_task' ? (
                  <p className="text-space-by">{intl.formatMessage({ id: 'subtaskNoteNotedby', defaultMessage: 'Noted by' })}</p>
                ) : (
                  <p className="text-space-by">{intl.formatMessage({ id: 'subtaskNoteDoneby', defaultMessage: 'Done by' })}</p>
                )
              ) : assigneeRender.length === 0 && subtaskCode === 'note_task' ? (
                <p
                  className="text-space-by button-note-sub-task"
                  onClick={() => {
                    setVisibleAddPost(true);
                    setSubTaskWfId(subTaskWFIdChange);
                  }}
                >
                  {' '}
                  <FormattedMessage id="subtaskNoteAddNote" defaultMessage="Add Note" />
                </p>
              ) : (
                ''
              )}
              &nbsp;
            </Col>
            <Col span={assigneeRender.length === 0 && subtaskCode === 'note_task' ? 17 : 18} style={{ display: 'flex' }}>
              <div className="text-space-name">
                {selectResKeys &&
                  assigneeRender.map((res, index) => (
                    <span className="text-space">
                      {(index ? ', ' : '') + res.fullname + ' '}
                      {item && item.subtaskCode === 'take_photo' && item.assignee.length !== 0
                        ? `(${_.get(res, 'subtaskDetail[0].totalFileView')})`
                        : null}
                      {item && item.subtaskCode === 'take_photo_solar' && item.assignee.length !== 0
                        ? `(${_.get(res, 'subtaskDetail[0].totalFileView')})`
                        : null}
                    </span>
                  ))}
                &nbsp;
              </div>
              {item && item.subtaskCode === 'take_photo' && assigneeRender.length !== 0 ? (
                <Icon
                  type="download"
                  className="icon-download-subtask"
                  style={{ color: '#1D3557', marginTop: '2px', marginRight: '8px' }}
                  onClick={() => handleDownload(taskDetailId, taskNo, totalAssignee, 'take_photo', item.subtaskName)}
                />
              ) : (
                undefined
              )}
              {item && item.subtaskCode === 'take_photo_solar' && assigneeRender.length !== 0 ? (
                <Icon
                  type="download"
                  className="icon-download-subtask"
                  style={{ color: '#1D3557', marginTop: '2px', marginRight: '8px' }}
                  onClick={() => handleDownload(taskDetailId, taskNo, totalAssignee, 'take_photo_solar', 'Take_Photo_Solar')}
                />
              ) : (
                undefined
              )}
              {item && item.subtaskCode === 'note_task' && assigneeRender.length !== 0 ? (
                <Icon
                  type="download"
                  className="icon-download-subtask"
                  style={{ color: '#1D3557', marginTop: '2px', marginRight: '8px' }}
                  onClick={() => handleDownloadNote(taskDetailId, taskNo, totalAssignee, 'note_task', item.subtaskName, subTaskWf)}
                />
              ) : (
                undefined
              )}
            </Col>
          </div>
          {item.assignee.length > 3 ? <Col span={1}> ({item.assignee.length})&nbsp; </Col> : null}
        </Col>
      </Row>
    );
  };

  const StartTask = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space-address">
                    <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const TruckWeight = useMemo(() => (props) => {
    const { sequence, assignee, icon, version } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    console.log('assigneeRender', assigneeRender);

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} height={30} width={30} />
                    {/* <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} /> */}
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <iconnumeric fill={{color: 'rgb(178, 178, 178)'}} /> */}
                    <img
                      src={iconnumeric}
                      fill={{ color: 'rgb(178, 178, 178)' }}
                      style={{ width: '30px', height: '18px', paddingRight: '10px' }}
                    ></img>

                    {/* <span>{item.updatedAt ? item.updatedAt : '-'}</span> */}
                      <>
                        <span>{item.currentNumber ? <>{numberFormatterCheckDot(item.currentNumber)} kg</> : '-'}</span>
                        {checkPermissionAction('P5PG1C9', 'P5PG1C9A2') ? (
                          <span style={{ paddingLeft: '10px', cursor: 'pointer' }}>
                          </span>
                        ) : null}
                      </>
      
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const PickupCheckIn = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space-address">
                    <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space">
                    <span className="icon-space">Reason :</span>&nbsp;
                    <span>{item.reason ? item.reason : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const CheckIn = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space-address">
                    <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const TakePhoto = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    const assigneeFiles = assigneeRender.map((item) => {
      return item;
    });

    return (
      resourceSelectNew &&
      assigneeRender.map((items, i) => (
        <Row key={items.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-photo-css">
              <Col span={3}>
                {' '}
                <Avatar src={items.pathImage} size="large" />{' '}
              </Col>
              <Col span={21}>
                <div className="subtask-preview-div-upload">
                  {items.subtaskDetail.map((item, index) => {
                    return (
                      <div style={{ width: 'fit-content' }}>
                        <Upload
                          className="subtask-preview-upload"
                          listType="picture-card"
                          fileList={item.files}
                          onPreview={(files) => handlePreviewImage(item.files || [], files, items)}
                          showUploadList={{
                            showRemoveIcon: false,
                          }}
                        />
                        <ReactBnbGallery
                          show={openGallery}
                          photos={galleryImg}
                          onClose={() => setOpenGallery(false)}
                          activePhotoIndex={numberImage}
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const TakePhotoSolar = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    const assigneeFiles = assigneeRender.map((item) => {
      return item;
    });

    return (
      resourceSelectNew &&
      assigneeRender.map((items, i) => (
        <Row key={items.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-photo-css">
              <Col span={3}>
                {' '}
                <Avatar src={items.pathImage} size="large" />{' '}
              </Col>
              <Col span={21}>
                <div className="subtask-preview-div-upload">
                  {items.subtaskDetail.map((item, index) => {
                    return (
                      <div style={{ width: 'fit-content' }}>
                        <Upload
                          className="subtask-preview-upload"
                          listType="picture-card"
                          fileList={item.files}
                          onPreview={(files) => handlePreviewImage(item.files || [], files, items)}
                          showUploadList={{
                            showRemoveIcon: false,
                          }}
                        />
                        <ReactBnbGallery
                          show={openGallery}
                          photos={galleryImg}
                          onClose={() => setOpenGallery(false)}
                          activePhotoIndex={numberImage}
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const POD = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((items, i) => (
        <Row key={items.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                {' '}
                <Avatar src={items.pathImage} size="large" />{' '}
              </Col>
              {items.subtaskDetail.map((item, index) => {
                let newPathPOD = [];
                const path = {
                  uid: index,
                  url: item.files.path,
                  original: item.files.path,
                  thumbnail: item.files.path,
                };
                newPathPOD.push(path);
                return (
                  <Col span={21}>
                    <div className="text-space">
                      <Icon type="user" className="icon-space" style={{ color: '#b2b2b2' }} />
                      <span>
                        {item.role ? item.role : '-'} / {item.typeName ? item.typeName : '-'}
                      </span>
                    </div>
                    <div className="text-space">
                      <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                      <span>{item.updatedAt}</span>
                    </div>
                    <div>
                      <Upload
                        listType="picture-card"
                        fileList={newPathPOD}
                        onPreview={(files) => handlePreviewImage(newPathPOD || [], files, items)}
                        showUploadList={{
                          showRemoveIcon: false,
                        }}
                      />
                      <ReactBnbGallery
                        show={openGallery}
                        photos={galleryImg}
                        onClose={() => setOpenGallery(false)}
                        activePhotoIndex={numberImage}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const CompleteTask = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space-address">
                    <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const Note = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject, subWf } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    const pustKeyValue = _.map(assigneeRender, (el) => {
      el.subtaskDetail = _.map(el.subtaskDetail, (e) => {
        e.memComId = el.memComId;
        e.fullname = el.fullname;
        e.pathImage = el.pathImage;
        return e;
      });
      return el;
    });

    let arrAssigneeRender = [];
    _.map(pustKeyValue, (el) => {
      arrAssigneeRender.push(...el.subtaskDetail);
    });
    let arrAssigneeRenderSort = _.orderBy(arrAssigneeRender, ['createdAt'], ['desc']);
    let arrAssigneeRenderShow = _.filter(arrAssigneeRenderSort, (ele, index) => index < 3);
    return (
      <div>
        {resourceSelectNew &&
          arrAssigneeRenderShow.map((item, i) => (
            <Row key={i}>
              <Col span={10} offset={6}>
                <Row className="data-list-css">
                  <Col span={3}>
                    <Avatar src={item.pathImage} size="large" />
                  </Col>
                  <Col span={21}>
                    <div className="text-space">
                      <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                      <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                    </div>
                    {item.description ? (
                      <div className="text-space">
                        <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                        <span>{item.description ? item.description : '-'}</span>
                      </div>
                    ) : null}
                    {item.attachmentsPhoto.length > 0 ? (
                      <div className="text-space">
                        <Icon type="picture" className="icon-space" style={{ color: '#b2b2b2' }} />
                        <span>{item.attachmentsPhoto.length} Photos</span>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        <Row>
          <Col span={10} offset={6}>
            <Row className="data-list-css">
              <Col span={20}>
                {arrAssigneeRenderSort.length > 0 ? (
                  <div
                    className="button-note-sub-task"
                    onClick={() => {
                      setVisibleViewPost(true);
                      setSubTaskWfId(subWf);
                    }}
                  >
                    <FormattedMessage id="subtaskNoteViewAll" defaultMessage="View All" /> ({arrAssigneeRenderSort.length}{' '}
                    <FormattedMessage id="subtaskNoteNotes" defaultMessage="Notes" />)
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <div className="div-subtask-collapse">
      {subtaskLength !== 0 ? (
        <Collapse
          className="sub-task-collapse"
          expandIconPosition="right"
          bordered={true}
          activeKey={state.activeKey}
          onChange={callback}
          expandIcon={({ isActive }) => <Icon type="up" rotate={isActive ? 0 : 180} />}
        >
          {subtask !== undefined &&
            subtask.map((item, index) => {
              return (
                <Panel
                  className="panel-css"
                  header={headerNoExpand(
                    item,
                    selectResKeys,
                    assigneeDetail,
                    taskId,
                    taskNo,
                    item.subtaskCode,
                    item.subTaskWfId,
                    subTaskWFIdChange
                  )}
                  key={item.subTaskWfId}
                >
                  {item.subtaskCode === 'start_task' ? (
                    <StartTask item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeDetail} key={item.subTaskWfId} />
                  ) : null}
                  {console.log('menusubtask', item)}
                  {item.subtaskCode === 'update_number' ? (
                    <TruckWeight
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'pickup_check_in' ? (
                    <PickupCheckIn
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'check_in' ? (
                    <CheckIn item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeDetail} key={item.subTaskWfId} />
                  ) : null}
                  {item.subtaskCode === 'take_photo' ? (
                    <TakePhoto item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeDetail} key={item.subTaskWfId} />
                  ) : null}
                  {item.subtaskCode === 'take_photo_solar' ? (
                    <TakePhotoSolar
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'pod_task' ? (
                    <POD item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeDetail} key={item.subTaskWfId} />
                  ) : null}
                  {item.subtaskCode === 'complete_task' ? (
                    <CompleteTask
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'note_task' ? (
                    <Note
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                      subWf={item.subTaskWfId}
                    />
                  ) : null}
                </Panel>
              );
            })}
        </Collapse>
      ) : (
        <div className="container-div-component-content-subtask">
          <Card className="task-status-card" style={{ height: '479px' }}>
            {/* <div className="task-status-content-detail">
                  <Icon className="task-status-content-icon" type="profile" />
                  <p className="task-status-content-text">
                    <FormattedMessage
                      id='taskDetailNoSubtask'
                      defaultMessage="No Subtask" />
                  </p>
                </div> */}
            <NoDataBackgroundModal text1={{ id: 'taskDetailNoSubtask', text: 'No Subtask' }} />
          </Card>
        </div>
      )}

      <HandlerModal />
      {visibleViewPost && (
        <ModalViewOnlyEngagment
          visible={visibleViewPost}
          setVisible={setVisibleViewPost}
          id={taskId}
          TitleName={TitleName}
          setVisibleAddPost={setVisibleAddPost}
          trigger={triggerPost}
          setTrigger={setTriggerPost}
          setVisibleEditPost={setVisibleEditPost}
          setEditPostData={setEditPostData}
          subTaskWfId={subTaskWfId}
        />
      )}
    </div>
  );
};
