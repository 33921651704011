import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Icon, Modal, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../includes/indexProvider';
import { successNotification, errorNotification } from '../../components/v2/notification';
import CreateAddress from '../../components/modal-create-address-noAPI/index';
import CompanyForm from './company-form';
import CompanyMap from './company-map';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import UploadImageProfile from '../../components/upload-image-profile/index';
import updateDetailCompany from '../../controllers/company-profile/put-update-detail-company';
import updateImageCompany from '../../controllers/company-profile/put-update-image-company';
import _ from 'lodash';
import styles from './css/index.css';

const ModalEditCompany = ({ form, companyInfoData, setCompanyInfoData, visible, setVisible, setTriggerApi }) => {
  const intl = useIntl();
  const app = useAppContext();

  const [isOpenModalAddress, setIsOpenModalAddress] = useState(false);
  const [defalseCompany, setDefalseCompany] = useState(null);
  const [defalseAddress, setDefalseAddress] = useState(null);
  const [imageProfile, setImageProfile] = useState();
  const [defalseFileData, setDefalseFileData] = useState(null);
  const [autoComplteLocation, setAutoComplteLocation] = useState();
  const [autoComplteValue, setAutoComplteValue] = useState();

  useEffect(() => {
    if (companyInfoData !== null) {
      setDefalseCompany({
        ...companyInfoData,
      });
      setDefalseAddress({
        locationName: _.get(companyInfoData, 'name'),
        address: _.get(companyInfoData, 'address'),
        mapLocation: _.get(companyInfoData, 'fullAddress'),
        lat: parseFloat(_.get(companyInfoData, 'lat')),
        lng: parseFloat(_.get(companyInfoData, 'lng')),
      });
      setDefalseFileData({
        profileImage: companyInfoData.profileImage,
      });
    }
  }, [companyInfoData]);

  const handleCreate = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const response = await updateDetailCompany({ dataForm: values, addressCompany: defalseAddress });
      if (response.status === 200) {
        if (imageProfile) {
          await updateImageCompany({ file: imageProfile });
        }
        successNotification(response.data.message);
        setTriggerApi((curren) => !curren);
        app.fnc.setTriggerApiCompany((curren) => !curren);
        setAutoComplteLocation();
        setAutoComplteValue();
        setTimeout(() => {
          setDefalseCompany(null);
          setDefalseAddress(null);
          setDefalseFileData(null);
          setCompanyInfoData(null);
          form.resetFields();
        }, 500);
      } else {
        errorNotification(response.data.message);
        setTimeout(() => {
          setDefalseCompany(null);
          setDefalseAddress(null);
          setDefalseFileData(null);
          setCompanyInfoData(null);
          form.resetFields();
        }, 500);
      }
      setVisible(false);
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setAutoComplteLocation();
    setAutoComplteValue();
    setTimeout(() => {
      setDefalseCompany(null);
      setDefalseAddress(null);
      setDefalseFileData(null);
      setCompanyInfoData(null);
      form.resetFields();
      setImageProfile();
    }, 500);
  };

  const handleOpenCreateAddress = () => {
    setDefalseAddress(defalseAddress);
    setIsOpenModalAddress(true);
  };

  return (
    <div className="edit-company">
      <Modal
        className="edit-company-modal"
        title={intl.formatMessage({ id: 'companyInformationModalTitleEditCompany', defaultMessage: 'Edit Company' })}
        centered="true"
        visible={visible}
        width={700}
        onCancel={handleCancel}
        onOk={handleCreate}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel} margin="unset">
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <div className="edit-company-modal">
          <div className="edit-company-modal-container">
            <Row>
              <Col span={7} className="edit-company-modal-avatar">
                <UploadImageProfile defalseFileData={defalseFileData} imageProfile={imageProfile} setImageProfile={setImageProfile} />
              </Col>
              <Col span={17}>
                <div className="edit-company-modal-form">
                  <CompanyForm form={form} companyInfoData={companyInfoData} />
                </div>

                <Divider className="edit-company-modal-divider" />

                <div className="edit-company-modal-address">
                  <div className="edit-company-address">
                    <span className="edit-company-address-text">
                      <FormattedMessage id="companyInformationModalTextAddress" defaultMessage="Address" />
                    </span>
                  </div>
                  <Row gutter={[16, 8]}>
                    <Col span={11}>
                      <CompanyMap defalseAddress={defalseAddress} />
                    </Col>
                    <Col span={13}>
                      <div className="edit-company-address-location">
                        <span className="edit-company-address-text-section">
                          {defalseAddress && defalseAddress.locationName ? defalseAddress.locationName : '-'}
                        </span>
                        <div className="edit-company-address-text-marker" onClick={handleOpenCreateAddress}>
                          <Icon
                            type="environment"
                            style={{
                              fontSize: '12px',
                              color: '#1D3557',
                              margin: '5px',
                            }}
                          />{' '}
                          &nbsp;
                          <span className="edit-company-address-text-default">
                            {defalseAddress && defalseAddress.address ? defalseAddress.address : '-'}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      <CreateAddress
        visible={isOpenModalAddress}
        setVisible={setIsOpenModalAddress}
        defalseAddress={defalseAddress}
        setDefalseAddress={setDefalseAddress}
        autoComplteLocation={autoComplteLocation}
        setAutoComplteLocation={setAutoComplteLocation}
        autoComplteValue={autoComplteValue}
        setAutoComplteValue={setAutoComplteValue}
      />
    </div>
  );
};

const ModalForm = Form.create({
  name: 'modal_form',
})(ModalEditCompany);

export default ModalForm;
