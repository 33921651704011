import React, { useState, useEffect, useMemo } from 'react';
import { Card, Table, Modal, Transfer, Input, Icon, Popover, Badge, Row, Col, List, Select, Avatar } from 'antd';
import { useRoleContext } from './RoleContext';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import httpClient from '../../components/axiosClient';
import { successNotification, errorNotification, warningNotification } from '../../components/v2/notification';
import difference from 'lodash/difference';
import cssStyle from './css/Role.css';
import { fncGetUsers } from '../../controllers/user/users';
import { useIntl, FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';
import _ from 'lodash';
import { element } from 'prop-types';

const { confirm } = Modal;
const { Option } = Select;

const filterOption = (inputValue, items) => {

  if (!inputValue) {
    return items;
  }

  const lowercasedFilter = inputValue.toLowerCase();
  const filteredData = items.filter(item => {
    return Object.keys(item).some(key =>
      typeof item[key] === "string" && item[key].toLowerCase().includes(lowercasedFilter)
    );
  })
  return filteredData;
};

const TableTransfer = ({ leftColumns, rightColumns, intl, ...restProps }) => {
  return (
    <Transfer {...restProps} showSelectAll={false} >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;
        const rowSelection = {
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled, }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        let isLeft = direction === 'left';
        let defalutData = [];
        defalutData = filterOption(
          restProps.search[isLeft ? 0 : 1],
          filteredItems,
        );

        return (
          <div>
            <Input
              className="input-search-role"
              value={restProps.search[isLeft ? 0 : 1]}
              onChange={(e) => {
                restProps.handleSearch(direction, e.target.value);
              }}
              placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
              prefix={<Icon className="input-search-role-prefix" type='search' />}
              autoComplete="off"
            />
            <Table
              style={{ pointerEvents: listDisabled ? 'none' : null }}
              rowKey={record => record.key}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={defalutData}
              pagination={false}
              size='small'
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
              scroll={{ y: 300 }}
            />
          </div>
        );
      }}
    </Transfer>
  );
}

export default (props) => {
  const app = useRoleContext();
  const intl = useIntl();

  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);

  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [inputSearch, setInputSearch] = useState(['', '']);
  const [memberKeys, setMemberKeys] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [defasultRoleId, setDefasultRoleId] = useState(app.state.rolesSelect);

 

  const columns = [
    {
      title: '#',
      select: 'Index',
      align: 'center',
      width: 70,
      key: 'index',
      render: (text, record, index) => record.index,
    },
    {
      dataIndex: 'profileImg',
      key: 'profileImg',
      width: 70,
      render: (text, record, index) => 
      (
        <Avatar src={record.profileImg} />
      )
      ,
    },
    {
      title: intl.formatMessage({ id: 'UserManagementRoleColumnName', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: 'UserManagementRoleColumnEmail', defaultMessage: 'Email' }),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: intl.formatMessage({ id: 'UserManagementRoleColumnPhoneNumber', defaultMessage: 'Phone Number' }),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: intl.formatMessage({ id: 'UserManagementRoleColumnStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => (
        <Badge color={text === 'Enable' ? 'blue' : '#9a9999'} text={text} />
      )
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => (
        <Popover placement="leftTop"
          content={
            <Button01
              btnsize="wd_df"
              type="link" ghost
              onClick={() => handlerDelete(record.memPerGroId)}>
              <p className="approver-delete-p">
                <FormattedMessage id="UserManagementRoleTxtDelete" defaultMessage="Delete" />
              </p>
            </Button01>}
        >
          <Icon type="more" />
        </Popover>)
    },
  ];

  const ignoreSearchColumn = ['index','profileImg'];
  const columnNewSearch = columns.filter((col) => !ignoreSearchColumn.includes(col.key));
  const [selectSearchData, setSelectSearchData] = useState('all');
  const [fieldChang, setFieldChang] = useState('');


  const handleSelectSearch = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value)
  }

  const handleSearchInput = debounce((fieldChang) => {
    setCurrent(1);
    setFieldChang(fieldChang ? fieldChang : '');
  }, 500);

  useMemo(() => {
    if (app.state.rolesSelect) {
      setCurrent(1);
    }
  }, [app.state.rolesSelect]);

  const leftTableColumns = [
    {
      dataIndex: 'name',
      title: intl.formatMessage({ id: 'UserManagementRoleColumnName', defaultMessage: 'Name' }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'UserManagementRoleColumnEmail', defaultMessage: 'Email' }),
    },
  ];

  const rightTableColumns = [
    {
      dataIndex: 'name',
      title: intl.formatMessage({ id: 'UserManagementRoleColumnName', defaultMessage: 'Name' }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'UserManagementRoleColumnEmail', defaultMessage: 'Email' }),
    },
  ];

  // useEffect(() => {
  //   const getUser = async () => {
  //     try {
  //       const response = await fncGetUsers(app.state.memComId, app.state.comId);
  //       setUsers(response);
  //     }
  //     catch (error) {
  //     }
  //   }

  //   // const getMember = async () => {
  //   //   try {
  //   //     const response = await httpClient.get(`/company/${app.state.comId}/permissionGroup/${app.state.rolesSelect}/member`);
  //   //     if (response.status >= 200 && response.status < 300) {
  //   //       const memberId = _.get(response.data, 'data');
  //   //       setMembers(memberId);
  //   //     }
  //   //   }
  //   //   catch (error) {
  //   //   }
  //   // }

  //   const handleInput = async () => {
  //     try {
  //       const response = await httpClient.get(`/company/${app.state.comId}/permissionGroup/${app.state.rolesSelect}/member/search?searchBy=${selectSearchData}&searchVal=${fieldChang}`);
  //       // const response = await httpClient.get(`/company/${app.state.comId}/permissionGroup/${app.state.rolesSelect}/member/search?searchBy=${selectSearchData}&searchVal=${fieldChang}&limit=${}&page=${}`);
  //       if (response.status == 200 && response.status < 300) {
  //         setMembers(response.data.data);
  //       }
  //     }
  //     catch (error) {

  //     }
  //   }

  //   if (app.state.rolesSelect) {
  //     getUser();
  //     //getMember();
  //     handleInput();
  //   }

  //   // if (!app.state.rolesSelect) {
  //   //   getUser();
  //   // }
  // }, [app.state.rolesSelect, refresh, fieldChang, selectSearchData]);


  useEffect(() => {
    getMember();
    getUser();
    // if (app.state.rolesSelect) {
    //   setDefasultRoleId(app.state.rolesSelect);
    // }
  }, [app.state.rolesSelect, refresh, fieldChang, selectSearchData, current, pageSize]);

  const getUser = async () => {
    try {
      const response = await fncGetUsers(app.state.memComId, app.state.comId,true);
      const newUsersData = response.map(item => {
        return {
          key: item.mem_com_id,
          name: item.name + ' ' + item.lastname,
          email: item.email
        }
      });

      setUsers(newUsersData);
    }
    catch (error) {
    }
  }

  const getMember = async () => {
    // let newCurrent = app.state.rolesSelect ? 1 : current;
    // if (app.state.rolesSelect) {
      
    // }
    console.log('roleSelect',app.state.rolesSelect);
    try {
      const response = await httpClient.get(
        `/company/${app.state.comId}/permissionGroup/${app.state.rolesSelect}/member/search?searchBy=${selectSearchData}&searchVal=${fieldChang}&limit=${pageSize}&page=${current}
      `);
      if (response.status == 200 && response.status < 300) {
        setMembers(response.data.data);
        setTotal(response.data.allRows);
      }
    } catch (error) {
    }
  }

  const handlerAddMember = async () => {
    const memberKeys = [];
    // members.forEach((element) => {
    //   memberKeys.push(element.memComId);
    // });

    

    try {
      const response = await httpClient.get(
        `/company/${app.state.comId}/permissionGroup/${app.state.rolesSelect}/member/search?searchBy=all&searchVal=&limit=${total}&page=${1}
      `);

      if (response.status == 200) {
        response.data.data.forEach((element) => {
          memberKeys.push(element.memComId);
        });
      }
    } catch (error) {
    }

    setMemberKeys(memberKeys);
    setInputSearch(['', '']);
    setSelected([]);
    setVisible(true);
  }

  const handlerCancelMember = () => {
    setVisible(false);
    setMemberKeys([]);
    setInputSearch(['', '']);
  }

  const handlerChangeSelect = (nextTargetKeys) => {
    setMemberKeys(nextTargetKeys);
  }

  const selectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelected([...sourceSelectedKeys, ...targetSelectedKeys]);
  }

  const handleSearch = (dir, value) => {
    let option = [...inputSearch];
    if (dir === 'left') {
      option[0] = value;
    } else {
      option[1] = value;
    }
    setInputSearch(option);
  };

  /**
   * Handler Save
   */
  const handlerSaveMembers = async () => {
    const data = {
      'member': memberKeys
    }
    try {
      // const response = await httpClient.post(`/company/${app.state.comId}/permissionGroup/${app.state.rolesSelect}/member/create`, data);
      const response = await httpClient.post(`/v3/resource/manager/company/${app.state.comId}/permissionGroup/${app.state.rolesSelect}/member/create`, data);
      if (response.status === 200) {
        setCurrent(1);
        setPageSize(10);
        successNotification(response.data.status.message);
        setVisible(false);
        setRefresh(!refresh);
      }
    }
    catch (error) {
    }
  }

  const handlerDelete = async (memPerGroId) => {
    Modal.confirm({
      className: "role-modal-confirm",
      title: intl.formatMessage({ id: 'UserManagementRoleLblDelete', defaultMessage: 'Do you want to delete these member ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okButtonProps: {
        style: {
          width: '96px',
          background: '#6490cf',
          border: '1px solid #6490cf',
          borderRadius: '8px',
          hover: {
            background: '#ffffff',
            color: '#6490cf',
          },
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
          border: '1px solid #6490cf',
          borderRadius: '8px',
          backgroundColor: '#ffffff',
          color: '#6490CF',
          
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v2/role/${app.state.memComId}/${memPerGroId}`);
          if (response.status === 200) {
            successNotification(response.data.data);
            setRefresh(!refresh);
          }
        }
        catch (error) {
        }
      },
      onCancel() { },
    });
  }
  
  const dataSource = app.state.rolesSelect ? members.map((elements, index) => {
    return {
      key: 'members-keys-' + index,
      memPerGroId: elements.memPerGroId,
      name: elements.name,
      email: elements.email,
      phone: elements.phone,
      status: elements.status,
      profileImg: elements.profileImg
    }
  }) : [];

  const dataUsers = users && users.map && users.map((elements, index) => {
    return {
      key: elements.mem_com_id,
      name: elements.name + ' ' + elements.lastname,
      email: elements.email
    }
  });

  const handlePagination = (current) => {
    setCurrent(current);
  }

  const handleSizeChange = (current, size) => {
    setCurrent(current);
    setPageSize(size);
  }

  const showTotal = (total) => {
    return <span><FormattedMessage id="pageSize" defaultMessage="Rows per page:" /></span>;
  };

  return (
    <div>
      <Card
        className="role-card-member"
        title={intl.formatMessage({ id: 'UserManagementRoleTitleMember', defaultMessage: 'Member' })}
        bordered={false}
      >
        <Row style={{ paddingBottom: 20 }}>
          <Col span={21}>
            <Row>
              <List.Item className="list-view-list">
                <Input
                  className="list-view-input action-input"
                  placeholder={intl.formatMessage({
                    id: 'hintSearch',
                    defaultMessage: 'Search',
                  })}
                  prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                  onChange={(e) => handleSearchInput(e.target.value)}
                  autoComplete="off"
                // style={{width:284}}
                />
                <Select
                  className="list-view-select action-select"
                  placeholder={intl.formatMessage({
                    id: 'hintSelect',
                    defaultMessage: 'Select',
                  })}
                  onSelect={(value) => handleSelectSearch(value)}
                  value={selectSearchData}
                >
                  <Option value="all">
                    <FormattedMessage id="reportOutsideReportHintSeleteAllColumns" defaultMessage="All columns" />
                  </Option>
                  {columnNewSearch.map((item, i) => (
                    <Option key={i} value={item.key}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </List.Item>
            </Row>
          </Col>
          <Col span={3}>
            {
              app.state.rolesSelect ?
                <Button01
                  style={{ margin: "0px", float: "right" }}
                  type='primary'
                  fontsize='md'
                  btnsize='auto'
                  onClick={handlerAddMember}
                >
                  <FormattedMessage id="UserManagementRoleBtnAddMember" defaultMessage="Add Member" />
                </Button01> : null
            }
          </Col>
        </Row>


        <Table
          rowKey={record => record.memId}
          size={'middle'}
          columns={columns}
          sizeWidth='lg'
          dataSource={members}
          // dataSource={dataSource ? dataSource : []}
          // onChange={handlePagination}
          pagination={{
            size: "small",
            total: total,
            showTotal: showTotal,
            onChange: handlePagination,
            onShowSizeChange: handleSizeChange,
            current: current,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
        />

        {members.length !== 0 ?
          <div className="role-total-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        : null}
      </Card>

      <Modal
        title={app.state.rolesSelectName}
        visible={visible}
        onOk={handlerSaveMembers}
        onCancel={handlerCancelMember}
        width={'65%'}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key='back' btnsize="wd_df" onClick={handlerCancelMember} >
            <FormattedMessage id="modalBtnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key='submit' type='primary' btnsize="wd_df" onClick={handlerSaveMembers} >
            <FormattedMessage id="modalBtnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <TableTransfer
          titles={[
            intl.formatMessage({ id: 'UserManagementRoleTxtUser', defaultMessage: 'User' }),
            intl.formatMessage({ id: 'UserManagementRoleTxtMember', defaultMessage: 'Member' })
          ]}
          // dataSource={dataUsers}
          dataSource={users}
          targetKeys={memberKeys}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          intl={intl}
          onChange={handlerChangeSelect}
          onSelectChange={selectChange}
          selectedKeys={selected}
          search={inputSearch}
          onSearch={handleSearch}
          handleSearch={handleSearch}
        />
      </Modal>
    </div>
  );
}