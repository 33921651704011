import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Modal, Form, Input, Select, InputNumber } from 'antd';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import styled from 'styled-components';
import './css/index.css';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';
import { composeInitialProps } from 'react-i18next';

const { Option } = Select;
const locale = "en-us";

const ModalFormComponent = ({
  form,
  visible,
  handleCancel,
  handleAddItem,
  handleKeyUp,
  visibleModalAdd,
  focusInput,
  triggerAddItem,
  unitData }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = form;
  const ref = useRef(null);
  const intl = useIntl();

  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p-validate">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const qty = getFieldValue('qty') !== undefined ? _.floor(getFieldValue('qty'), 2).toFixed(2) : 0;

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      resetFields();
      setFieldsValue({ ['unitPrice']: 0 })
      setFieldsValue({ ['total']: 0 })
    }
  }, [visible, focusInput, triggerAddItem])


  useEffect(() => {
    const calculate = getFieldValue('unitPrice') > 0 ? parseFloat(getFieldValue('unitPrice')) * qty : 0 * qty
    setFieldsValue({ ['total']: _.floor(calculate, 2).toFixed(2) })

  }, [qty, getFieldValue('unitPrice')])


  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {

      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {

        e.preventDefault();

      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => { return o.charCodeAt(0) === 46 ? 1 : 0 })
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }

    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  }

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66) {
      return true;
    }
    else {
      e.preventDefault();
    }
  }

  const checkQtyValue = (rule, value, callback) => {
    if (value < 1) {
      callback('Qty must not be less than 1')
    } else {
      callback()
    }
  }

  const onBlur = () => {

  }

  return (
    <div>
      <Modal
        title={intl.formatMessage({
          id: 'modalMaintenanceColumnsTitleAddParts',
          defaultMessage: 'Add Parts',
        })}
        className="sale-oreder-modal-add-item"
        visible={visible}
        onOk={handleAddItem}
        onCancel={handleCancel}
        width={1200}
        centered
        // footer={[
        //   <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleCancel}>
        //     <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        //   </Button02>,
        //   <Button01 htmlType="submit" key="submit" type="primary" btnsize='wd_df' onClick={handleAddItem}  >
        //     <FormattedMessage id="btnAdd" defaultMessage="Add" />
        //   </Button01>,
        // ]}

        okText={intl.formatMessage({ id: 'btnAdd', defaultMessage: 'Add' })}
        cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
        okButtonProps={
          {
            style: {
              width: '96px'
            }
          },
          {
            type: "primary"
          }
        }
        cancelButtonProps={
          {
            style: {
              width: '96px'
            }
          },
          {
            type: "danger"
          }
        }

      >
        <div className="sale-oreder-modal-add-body">
          <Form className="modal-add-form" layout="inline" onKeyUp={handleKeyUp} onSubmit={handleAddItem}>
            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalPartformlabPartCode', defaultMessage: 'Part Code' })} req={true} />}
            >
              {getFieldDecorator('partCode', {
                rules: [{ required: true, message: intl.formatMessage({ id: 'modalAddItemModalPartformValidatePartCode', defaultMessage: 'Please input part code' }) }],
              }
              )(
                <Input ref={ref} autoFocus={focusInput} onBlur={onBlur} style={{ width: '150px' }} placeholder={intl.formatMessage({ id: 'modalAddItemModalPartformPlaceholderPartCode', defaultMessage: 'Please input PartCode' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>

            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalPartformlabPartNo', defaultMessage: 'Part No' })} req={true} />}
            >
              {getFieldDecorator('partNo', {
                rules: [{ required: true, message: intl.formatMessage({ id: 'modalAddItemModalPartformValidatePartNo', defaultMessage: 'Please input part no' }) }],
              }
              )(
                <Input style={{ width: '150px' }} placeholder={intl.formatMessage({ id: 'modalAddItemModalPartformPlaceholderPartNo', defaultMessage: 'Please input PartNo' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>

            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalPartformlabPartName', defaultMessage: 'Part Name' })} req={true} />}
            >
              {getFieldDecorator('partName', {
                rules: [{ required: true, message: intl.formatMessage({ id: 'modalAddItemModalPartformValidatePartName', defaultMessage: 'Please input part name' }) }],
              }
              )(
                <Input style={{ width: '200px' }} placeholder={intl.formatMessage({ id: 'modalAddItemModalPartformPlaceholderPartName', defaultMessage: 'Please input PartName' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>
            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalPartformlblQty', defaultMessage: 'Qty' })} req={true} />}
            >
              {getFieldDecorator('qty', {
                initialValue: 1,
                rules: [
                  { required: true, message: intl.formatMessage({ id: 'modalAddItemModalPartformValidateQty', defaultMessage: 'Please input qty' }) },
                  { validator: checkQtyValue }
                ],
              }
              )(<InputNumber
                className='display-none'
                style={{ width: '120px' }}
                placeholder={intl.formatMessage({ id: 'modalAddItemModalPartformPlaceholderQty', defaultMessage: 'Please input Qty' })}
                maxLength={10}
                onKeyDown={handleKeyDownNumber}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />)}

            </Form.Item>
            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalPartformlblUnit', defaultMessage: 'Unit' })} req={true} />}
            >
              {getFieldDecorator('unit', {
                rules: [{ required: true, message: intl.formatMessage({ id: 'modalAddItemModalPartformValidatelblUnit', defaultMessage: 'Please input unit' }) }],
              }
              )(<Select style={{ width: '150px' }} placeholder={intl.formatMessage({ id: 'modalAddItemModalPartformPlaceholderUnit', defaultMessage: 'Select Unit' })}>
                {unitData && unitData.map((item, index) => (
                  <Option key={item.itemsUnitId} value={item.itemsUnitId}>{item.itemsUnitName}</Option>
                ))}
              </Select>)}
            </Form.Item>

            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalPartformlabUnitPrice', defaultMessage: 'Unit Price (Baht)' })} req={true} />}
            >
              {getFieldDecorator('unitPrice', {
                initialValue: 0,
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'modalAddItemModalPartformValidateUnitPrice', defaultMessage: 'Please input unit price' }),
                }
                ],
              }
              )(
                <InputNumber
                  className='sale-oreder-none-count'
                  style={{ width: '150px' }}
                  placeholder={intl.formatMessage({ id: 'modalAddItemModalPartformPlaceholderUnitPrice', defaultMessage: '0.00' })}
                  maxLength={12}
                  onKeyDown={handleKeyDownNumberDecimal}
                  formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  autoComplete={false}
                />
              )}
            </Form.Item>

            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalPartformlabTotal', defaultMessage: 'Total (Baht)' })} />}
            >
              {getFieldDecorator('total', {
                // initialValue: _.floor(0, 2).toFixed(2),
                // rules: [
                //   {
                //     pattern: new RegExp(^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$"),
                //     // message: intl.formatMessage({ id: 'ModalContactValidateEmail', defaultMessage: 'Please input email format' })
                //   },
                // ]
              }
              )(<InputNumber
                className='sale-oreder-none-count'
                placeholder={intl.formatMessage({ id: 'modalAddItemModalPartformPlaceholderUnitPrice', defaultMessage: '0.00' })}
                style={{ width: '140px', marginTop: 8 }}
                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                disabled
              />)}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const ModalAddParts = Form.create({ name: "modal_form" })(ModalFormComponent);

export default ModalAddParts