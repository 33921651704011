import React from 'react'
import CollapseCustom from '../../../components/collapse-custom'
import { useIntl, FormattedMessage } from 'react-intl'
import ReportReceiveActionColumns from './action-columns'
import { Input, Form, Select, DatePicker, Row, Col } from 'antd'
import LabeRequireForm from '../../../components/label-required-form'
import _ from 'lodash'
import Button_01 from '../../../components/v2/button_01'
import Button_02 from '../../../components/v2/button_02'


const { RangePicker } = DatePicker
const { Option } = Select

const ReportReceiveAction = (props) => {
  const intl = useIntl()
  const { search, setSearch, onFilterOrder, actionColumns, refTypeList, bankList, setPage, setIsFetch } = props

  const handleApply = () => {
    setIsFetch((event) => !event)
    setPage(1)
  }

  const handleReset = () => {
    setIsFetch((event) => !event)
    setSearch((prev) => ({...prev, refType: undefined, refNo: '', taxNo: '', customerCode: '', customerName: '', paymentMethod: undefined, receiveDate: []}))
    setPage(1)
  }

  const formFilter = () => {
    return (
      <Form colon={false} className='deliveryForm'>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReceiveColumnsRefType', defaultMessage: 'Reference Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReceivePHRefType', defaultMessage: 'Select Reference Type' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'refType')}
                value={_.get(search, 'refType')}
              >
                {
                  _.map(refTypeList, (item) => (
                    <Option key={item.code}>
                      {item.label}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReceiveColumnsRefNo', defaultMessage: 'Reference No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReceivePHRefNo', defaultMessage: 'Enter Reference No' })}
                onChange={(e) => onFilterOrder(e.target.value, 'refNo')}
                value={_.get(search, 'refNo')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReceiveColumnsCustomerCode', defaultMessage: 'Customer or Vendor Code' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReceivePHCustomerCode', defaultMessage: 'Enter Customer or Vendor Code' })}
                onChange={(e) => onFilterOrder(e.target.value, 'customerCode')}
                value={_.get(search, 'customerCode')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReceiveColumnsCustomerName', defaultMessage: 'Customer or Vendor Name' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReceivePHCustomerName', defaultMessage: 'Enter Customer or Vendor Name' })}
                onChange={(e) => onFilterOrder(e.target.value, 'customerName')}
                value={_.get(search, 'customerName')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReceiveColumnsPaymentMethod', defaultMessage: 'Payment Method' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReceivePHPaymentMethod', defaultMessage: 'Select Payment Method' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'paymentMethod')}
                value={_.get(search, 'paymentMethod')}
              >
                {
                  _.map(bankList, (item) => (
                    <Option key={item.code}>
                      {item.label}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReceiveColumnsReceiveDate', defaultMessage: 'Receive Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                allowClear={true}
                placeholder={[intl.formatMessage({ id: 'reportReceivePHReceiveDateFrom', defaultMessage: 'Select From Date' }), intl.formatMessage({ id: 'reportReceivePHReceiveDateTo', defaultMessage: 'Select To Date' })]}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => onFilterOrder(value, 'receiveDate')}
                style={{ width: '100%' }}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                value={_.get(search, 'receiveDate')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
                label={<LabeRequireForm text={''} req={false} />}
              >
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                  <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                </Button_01>
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                  <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };


  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[intl.formatMessage({ id: 'reportReceivePHExpiredDateFrom', defaultMessage: 'Select From Date' }), intl.formatMessage({ id: 'reportReceivePHExpiredDateTo', defaultMessage: 'Select To Date' })]}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => onFilterOrder(value, 'searchdDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'searchdDate')}
            />
            <ReportReceiveActionColumns
              actionColumns={actionColumns}
            />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div >
  )
}

export default ReportReceiveAction
