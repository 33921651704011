import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import Button_02 from '../v2/button_02';
import Button_01 from '../v2/button_01';
import LabeRequireForm from '../label-required-form';
import { handleKeyDownNumber } from '../../component-function/fnc-number';
import { getConvertUoM } from '../../controllers/po/po';
import { errorNotification } from '../v2/notification';

const { Option } = Select;
const { TextArea } = Input;

const ModalQTYItemDOForm = ({ form, visible, onCancel, record, setTableData, closeItem, tableData, type }) => {
  const { getFieldDecorator, resetFields, getFieldValue, validateFields } = form;
  const intl = useIntl();
  const [uomList, setUomList] = useState([]);

  useEffect(() => {
    const getStockList = async () => {
      const unitData = await getConvertUoM(_.get(record, 'itemMasterId'));
      setUomList(_.get(unitData, 'data.data'));
    };
    if (visible) {
      getStockList();
    }
  }, [visible]);

  const handleSave = (record, uomList) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let tempData = tableData;

      const filterUOM = _.filter(uomList, (i) => {
        return _.get(values, 'unit') === i.uomId;
      });

      const filterItem = _.filter(tableData, (i) => {
        return _.get(record, 'itemMasterId') === i.itemMasterId && _.get(values, 'unit') === i.uomId;
      });

      const checkvalue = type === 'edit' && _.get(record, 'uomId') !== _.get(values, 'unit') ? true : false;

      if (checkvalue) {
        tempData = _.filter(tableData, (item) => {
          return (
            _.get(item, 'itemMasterId') !== _.get(record, 'itemMasterId') ||
            (_.get(item, 'uomId') !== _.get(record, 'uomId') && _.get(item, 'itemMasterId') === _.get(record, 'itemMasterId'))
          );
        });
      }

      console.log('UUOM', tempData);

      if (_.size(filterItem) > 0) {
        if (type === 'edit') {
          tempData = _.map(tempData, (i) => {
            if (i.itemMasterId === _.get(record, 'itemMasterId') && _.get(values, 'unit') === i.uomId) {
              return checkvalue
                ? {
                    ...i,
                    qty: parseInt(_.get(i, 'qty')) + parseInt(_.get(values, 'qty')),
                  }
                : {
                    ...i,
                    qty: _.get(values, 'qty'),
                  };
            } else {
              return i;
            }
          });
        } else {
          errorNotification(intl.formatMessage({ id: 'deliveryOrderQtyNoti', defaultMessage: 'Have this order' }));
          return;
        }
      } else {
        tempData.push({
          itemMasterId: _.get(record, 'itemMasterId'),
          itemCode: _.get(record, 'itemCode'),
          itemName: (_.get(record, 'itemBrandName') || '') + ' ' + (_.get(record, 'itemModelName') || '') + ' ' + (_.get(record, 'itemName') || ''),
          qty: _.get(values, 'qty'),
          uomId: _.get(values, 'unit'),
          uom: _.get(filterUOM, '[0].name'),
          index: _.size(tableData),
          statusCode: 'open',
          shippingPrice: '0',
          itemBrandName: _.get(record, 'itemBrandName'),
          itemModelName: _.get(record, 'itemModelName'),
        });
      }

      setTableData(tempData);
      resetFields();
      closeItem();
      onCancel();
    });
  };

  const validatorQty = (rule, value, callback) => {
    console.log('OKOKOKOKO',value);
    
    if (parseInt(value) === 0) {
      callback('Qty must more than 0');
    } 
    // else if (value) {
    //   const FilterUom = _.filter(uomList, (item) => {
    //     return item.uomId === getFieldValue('unit');
    //   });

    //   const altQty = _.get(FilterUom, '[0].altQty');

    //   const parsValue = parseFloat(value * altQty || 0);

    //   if (parsValue === 0) {
    //     callback('Qty must more than 0');
    //   } else {
    //     callback();
    //   }
    // } 
    else {
      callback();
    }
  };

  const handleCancel = () => {
    onCancel()
    resetFields();
  }

  return (
    <Modal
      title={_.get(record, 'itemCode') + ' ' +(_.get(record, 'itemBrandName', '') || '') + ' ' + (_.get(record, 'itemModelName', '') || '') + ' ' + (_.get(record, 'itemName', '') || '')}
      visible={visible}
      onOk={handleCancel}
      onCancel={handleCancel}
      width={500}
      centered={true}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave(record, uomList)}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form form={form} name="status" colon={false}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'deliveryOrderAddItemQty', defaultMessage: 'Qty' })} req={true} />}
            >
              {getFieldDecorator('qty', {
                initialValue: type === 'edit' ? _.get(record, 'qty') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'deliveryOrderAddItemnQtyVD', defaultMessage: 'Please Enter Qty' }),
                  },
                  {
                    validator: validatorQty,
                  },
                ],
              })(
                <Input
                  // onChange={(e) => {
                  //   handleAmount(e, e.target.value);
                  // }}
                  onKeyDown={handleKeyDownNumber}
                  placeholder={intl.formatMessage({ id: 'deliveryOrderAddItemPH', defaultMessage: 'Enter Qty' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />}
            >
              {getFieldDecorator('unit', {
                initialValue: type === 'edit' ? _.get(record, 'uomId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'deliveryOrderButtonQtyUoMPH', defaultMessage: 'Select UoM' })}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                >
                  {_.map(uomList, (item) => (
                    <Option value={item.uomId}>{item.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalQTYItemDOForm.defaultProps = {
  visible: false,
  record: [],
  onCancel: () => console.warn('onCancel not function '),
  closeItem: () => console.warn('onCancel not function '),
  type: 'add',
};

const ModalQTYItemDO = Form.create({ name: 'select_qt_Form' })(ModalQTYItemDOForm);

export default ModalQTYItemDO;
