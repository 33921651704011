import React, { useContext, useState, useRef, useEffect } from 'react'
import { ExpenseModal } from '.'
import { Row, Col, Input, Form, Divider, Dropdown, Button, Menu, Popover, Table, Select } from 'antd'
import LabeRequireForm from '../../../../components/label-required-form'
import { useIntl, FormattedMessage } from 'react-intl'
import Button01 from '../../../../components/v2/button_01'
import { DownOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency'

const { Option } = Select

const ExpenseAdditional = (props) => {
  const { form } = props
  const intl = useIntl()
  const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue } = form;
  const { handleOpenModalListProduct, handleOpenModalListService, handleEditItem, selectItem,
    setSelectItem, taxList, poSelect } = useContext(ExpenseModal)
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const sumItem = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalPrice') : 0.00
  const amount = parseFloat(getFieldValue('amount') || 0)
  const subtotal = sumItem + amount
  const tax = getFieldValue('tax')
  const taxNumber = parseFloat(getFieldValue('taxNumber') || 0.00)
  const subtotalTax = parseFloat(subtotal) + taxNumber

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left'
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 120,
      fixed: 'left'
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      render: (text, record, index) => record.qty + " " + record.uomName,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (text, record, index) => numberFormatter(record.pricePerUnit) + " " + "THB",
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscountPercentage', defaultMessage: 'Discount Percentage' }),
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (text, record, index) => numberFormatter(record.discountPercentage) + " " + "%",
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: (text, record, index) => numberFormatter(record.discountPrice) + " " + "THB",
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      fixed: 'right',
      width: 120,
      render: (text, record, index) => numberFormatter(record.totalPrice) + " " + "THB",
    },
  ]

  const deleteItem = (value) => {
    const filterSelect = _.filter(selectItem, (item) => { return item.itemMasterId !== value })
    setSelectItem(filterSelect)
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  }

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditItem(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record.itemMasterId)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const menuColumn = (
    <Menu>
      <Menu.Item onClick={handleOpenModalListProduct}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnItem" defaultMessage="Item" />
        </Button>
      </Menu.Item>
      <Menu.Item onClick={handleOpenModalListService}>
        <Button style={{ padding: 'unset' }} ghost type="link" >
          <FormattedMessage id="expenseBtnService" defaultMessage="Service" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };


  useEffect(() => {
    setFieldsValue({
      ['taxNumber']: ((parseFloat(subtotal) * parseInt(tax)) / 100).toFixed(2),
    });
  }, [tax, subtotal])

  useEffect(() => {
    if (poSelect) {
      setFieldsValue({
        ['tax']: _.toString(_.get(poSelect, 'taxPercent'))
      });
    }
  }, [poSelect])



  return (
    <div>
      <div style={{ padding: '24px 24px 0 24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ color: '#1D3557', fontWeight: 600 }}>
          <FormattedMessage id="expenseAdditionalText" defaultMessage="Additional Item/Service" />
        </div>
        <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
          <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }}>
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
            <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
          </Button01>
        </Dropdown>
      </div>
      <Divider type="horizontal" style={{ margin: '12px 0' }} />
      <div style={{ padding: '0px 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          columns={columns}
          dataSource={_.sortBy(selectItem, [function (o) { return o.index; }])}
          pagination={false}
          scroll={{ x: 1200 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      </div>
      <div style={{ padding: '0px 24px', marginBottom: '24px' }}>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormRemark', defaultMessage: 'Remark' })} req={false} />
              }
            >
              {getFieldDecorator('remark', {
              })(
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
              <div>
                <FormattedMessage id="purchesModalFormSubtotal" defaultMessage="Subtotal" />
              </div>
              <div>
                {numberFormatter(subtotal)} THB
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
              <div style={{ marginRight: '46px' }}>
                <FormattedMessage id="purchesModalFormTax" defaultMessage="Tax" />
              </div>
              <div style={{ display: 'flex' }}>
                <Row gutter={[16]}>
                  <Col span={9} style={{textAlign: 'right'}}>
                  {/* <Col span={9} > */}
                    <Form.Item
                      label=""
                    >
                      { getFieldDecorator('tax', {
                        initialValue: _.get(taxList, '[0].tax'),
                      })(
                        <Select
                        style={{ color: poSelect?'#000000A6' : undefined}}
                        disabled={ poSelect? true : false}
                        >
                          {
                            _.map(taxList, (item) =>
                              <Option value={item.tax}>
                                {item.tax}%
                              </Option>
                            )
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={15}>
                    <Form.Item
                      label=""
                    >
                      { getFieldDecorator('taxNumber', {
                        initialValue: 0.00,
                      })(
                        <Input suffix="THB" readOnly={true} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
              <div>
                <FormattedMessage id="purchesModalFormSubTotal" defaultMessage="Total" />
              </div>
              <div>
                {numberFormatter(subtotalTax)} THB
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ExpenseAdditional
