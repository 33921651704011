import httpClient from '../../components/axiosClient';

const getCustomerApi = async (allCustomers, typeApi, limit = 1500) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(
      `/v3/business-partner/manager/company/${comCode}/customer-informations/get/load-more/noprivacy?indexStart=1&indexEnd=${limit}&${typeApi}`
    );
    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export default getCustomerApi;
