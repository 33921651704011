import React, { useContext, useCallback, useRef, useMemo, useState, useEffect } from 'react';
import '../css/map.css';
import { TaskDetail } from '../../task-detail-context';
import _ from 'lodash';
import { Icon, Avatar } from 'antd';
import { GoogleMap, useLoadScript, Polyline, Marker, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import httpClient from '../../../../../axiosClient';
import { FormattedMessage } from 'react-intl';

export default () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    id: 'google-map-script',
  });
  const mapRef = useRef();
  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);
  const address = _.get(stateTask, 'viewInformationData.address');
  const pathTaskStatus = _.get(stateTask, 'viewInformationData.information.pathTaskStatus');
  const mapSpeed = _.get(stateTask, 'viewInformationData.map');
  const assignee = _.get(stateTask, 'assignee') ? _.get(stateTask, 'assignee') : [];
  const resourceActive =
    assignee &&
    assignee.filter((e) => {
      return e.active !== 1;
    });
  const resourceSelect = resourceActive && resourceActive.filter((item) => stateTask.keys.includes(item.memComId));
  const comCode = localStorage.getItem('comCode');
  const [pathPolylineData, setPathPolylineData] = useState([]);
  const [trackingConfig, setTrackingConfig] = useState();
  const [map, setMap] = useState(null);

  useEffect(() => {
    getPolyline();
  }, [_.size(resourceSelect)]);

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const ResourceComponent = useMemo(
    () => (props) => {
      const { resourceSelectNew } = props;
      return (
        resourceSelectNew &&
        resourceSelectNew.map((item, index) => (
          <OverlayView
            id={item.memComId}
            position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <Avatar key={item.memComId} src={item.pathImage} size="large" style={{ border: '3px #fff solid', zIndex: 1 }} />
          </OverlayView>
        ))
      );
    },
    [assignee]
  );

  const getPolyline = async () => {
    if (_.size(resourceSelect) === 1) {
      const memcomId = resourceSelect && resourceSelect !== undefined ? resourceSelect[0].memComId : undefined;
      if (_.size(resourceSelect) === 1 && memcomId !== undefined) {
        try {
          const response = await httpClient.get(
            `/v3/task/manager/company/${comCode}/directions/task/${_.get(stateTask, 'taskId')}/member/${memcomId}?menuType=transportation`
          );
          if (response.status == 200) {
            setPathPolylineData(response.data.data.directions);
            setTrackingConfig(_.reverse(response.data.data.trackingConfig));
          }
        } catch (error) {
          setPathPolylineData([]);
        }
      }
    } else {
      setPathPolylineData([]);
    }
  };

  const mapContainerStyle = {
    width: '100%',
    height: '390px',
    position: 'relative',
  };

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: 'cooperative',
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    setMap(map);
  }, []);

  // const onLoad = useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  // 18.318529859000243, 99.39921191296528

  return (
    <div>
      <div className="task-detail-map-address-padding">
        <div>
          <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
        </div>
        <div className="task-detail-map-address-text">{_.get(address, 'toLocation')}</div>
      </div>
      <div className="task-detail-map-address-padding-map ">
        {isLoaded && (
          <GoogleMap
            center={{ lat: parseFloat(_.get(address, 'lat')) || 0, lng: parseFloat(_.get(address, 'lng')) || 0 }}
            zoom={12}
            mapContainerStyle={mapContainerStyle}
            options={defaultMapOption}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {parseFloat(_.get(address, 'lat')) && parseFloat(_.get(address, 'lng')) && (
              <Marker
                key={address.toLocationId}
                position={{ lat: parseFloat(_.get(address, 'lat')), lng: parseFloat(_.get(address, 'lng')) }}
                icon={{
                  url: pathTaskStatus,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
              />
            )}

            {pathPolylineData &&
              pathPolylineData.map(
                (object, index) =>
                  index > 0 && (
                    <Polyline
                      key={`polyline-${index}`}
                      path={[{ ...pathPolylineData[index] }, { ...pathPolylineData[index - 1] }]}
                      options={{
                        strokeColor: object.speedColor,
                        strokeWeight: 3,
                      }}
                    />
                  )
              )}
            <ResourceComponent resourceSelectNew={resourceSelect} />
          </GoogleMap>
        )}
      </div>
      <div className="div-map-address-mapSpeed" style={{ padding: '0px 24px' }}>
        {trackingConfig && trackingConfig?.length > 0
          ? trackingConfig &&
            trackingConfig.map &&
            trackingConfig.map((item) => (
              <>
                <div className="speed-color60" style={{ backgroundColor: item.codeColor }} />
                <span className="text-control">
                  <FormattedMessage id={item.codeTxtFirst} defaultMessage="Speed" />
                </span>
                <span className="text-control">{item.codeTextCondition}</span>
                <span className="text-control" style={{ paddingRight: '20px' }}>
                  <FormattedMessage id={item.codeTxtSecond} defaultMessage="km/hrs" />
                </span>
              </>
            ))
          : mapSpeed &&
            mapSpeed.map &&
            mapSpeed.map((item) => (
              <>
                <div className="speed-color60" style={{ backgroundColor: item.color }} />
                <span className="text-control" style={{ paddingRight: '20px' }}>
                  {item.description}
                </span>
              </>
            ))}
      </div>
    </div>
  );
};
