import { Icon, Modal, Spin, Tag } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../v2/button_01';
import Button_02 from '../v2/button_02';
import { getPreviewFileApiNew, saveFileNew } from '../../controllers/import-order/api';
// import { DeliveryContext } from '../..';
import ActionOrdersPreview from './action-import';
import OrderSourcePreview from './source-import';
import './css/index.css';
import { errorNotification, successNotification } from '../v2/notification';

const ModalImportPreview = ({ open, setOpen, setOpenImport, fileHash, setFileHash, setTrigger, branchData }) => {
  const intl = useIntl();
  // const { fileHash, setFileHash, setTriggerOrder, setTrigger } = useContext(DeliveryContext);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColCustomer`, defaultMessage: 'Customer' }),
      dataIndex: 'customer',
      key: 'customer',
      width: 200,
      sorter: true,
      ellipsis: true,
      // fixed: 'left',
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'customer') ? '#e73845' : null }}>{record.customer}</div>,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSourceName`, defaultMessage: 'Source Name' }),
      dataIndex: 'sourceName',
      key: 'sourceName',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div
          style={{
            color: _.get(record.highlightCol, 'sourceName') ? '#e73845' : null,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {record.sourceName}
        </div>
        // <div style={{ color: _.get(record.highlightCol, 'sourceName') ? '#e73845' : null }}>{record.sourceName}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSourceAddress`, defaultMessage: 'Source Address' }),
      dataIndex: 'sourceAddress',
      key: 'sourceAddress',
      width: 300,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div
          style={{
            color: _.get(record.highlightCol, 'sourceAddress') ? '#e73845' : null,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {record.sourceAddress}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSourcePostcode`, defaultMessage: 'Source Postcode' }),
      dataIndex: 'sourcePostcode',
      key: 'sourcePostcode',
      width: 150,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'sourcePostcode') ? '#e73845' : null }}>{record.sourcePostcode}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSourceLat`, defaultMessage: 'Source Lat' }),
      dataIndex: 'sourceLat',
      key: 'sourceLat',
      width: 150,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div
          style={{
            color: _.get(record.highlightCol, 'sourceLat') ? '#e73845' : null,
          }}
        >
          {record.sourceLat}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSourceLon`, defaultMessage: 'Source Lon' }),
      dataIndex: 'sourceLon',
      key: 'sourceLon',
      width: 150,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'sourceLon') ? '#e73845' : null }}>{record.sourceLon}</div>,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDestinationName`, defaultMessage: 'Destination Name' }),
      dataIndex: 'destinationName',
      key: 'destinationName',
      width: 250,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div
          style={{
            color: _.get(record.highlightCol, 'destinationName') ? '#e73845' : null,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {record.destinationName}
        </div>
        // <div style={{ color: _.get(record.highlightCol, 'destinationName') ? '#e73845' : null }}>{record.destinationName}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDestinationAddress`, defaultMessage: 'Destination Address' }),
      dataIndex: 'destinationAddress',
      key: 'destinationAddress',
      width: 300,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      // render: (text, record) => (
      //   <div style={{ color: _.get(record.highlightCol, 'destinationAddress') ? '#e73845' : null }}>{record.destinationAddress}</div>
      // ),
      render: (text, record) => (
        <div
          style={{
            color: _.get(record.highlightCol, 'destinationAddress') ? '#e73845' : null,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {record.destinationAddress}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDestinationPostcode`, defaultMessage: 'Destination Postcode' }),
      dataIndex: 'destinationPostcode',
      key: 'destinationPostcode',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'destinationPostcode') ? '#e73845' : null }}>{record.destinationPostcode}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDestinationLat`, defaultMessage: 'Destination Lat' }),
      dataIndex: 'destinationLat',
      key: 'destinationLat',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'destinationLat') ? '#e73845' : null }}>{record.destinationLat}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDestinationLon`, defaultMessage: 'Destination Lon' }),
      dataIndex: 'destinationLon',
      key: 'destinationLon',
      width: 150,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'destinationLon') ? '#e73845' : null }}>{record.destinationLon}</div>
      ),
    },

    {
      title: intl.formatMessage({ id: `transportationOrdersColDeliveryDate`, defaultMessage: 'Delivery Date' }),
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'deliveryDate') ? '#e73845' : null }}>{record.deliveryDate}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColDeliveryTime`, defaultMessage: 'Delivery Time' }),
      dataIndex: 'deliveryTime',
      key: 'deliveryTime',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'deliveryTime') ? '#e73845' : null }}>{record.deliveryTime}</div>
      ),
    },

    {
      title: intl.formatMessage({ id: `transportationOrdersColContactName`, defaultMessage: 'Contact Name' }),
      dataIndex: 'contact',
      key: 'contact',
      width: 250,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'contact') ? '#e73845' : null }}>{record.contact}</div>,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColSoNo`, defaultMessage: 'SO No' }),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      width: 180,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'saleOrderNo') ? '#e73845' : null }}>{record.saleOrderNo}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColReferenceNo`, defaultMessage: 'Reference No.' }),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'referenceNo') ? '#e73845' : null }}>{record.referenceNo}</div>
      ),
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColRemark`, defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'remark') ? '#e73845' : null }}>{record.remark}</div>,
    },
    {
      title: intl.formatMessage({ id: `transportationOrdersColContactPhone`, defaultMessage: 'Contact Phone' }),
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      width: 180,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'contactPhone') ? '#e73845' : null }}>{record.contactPhone}</div>
      ),
    },

    {
      title: intl.formatMessage({ id: `planningColumnOrderType`, defaultMessage: 'Order Type' }),
      dataIndex: 'orderType',
      key: 'orderType',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'orderType') ? '#e73845' : null }}>{record.orderType}</div>,
    },

    {
      title: intl.formatMessage({ id: `planningColumnOrderStatus`, defaultMessage: 'Order Status' }),
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ color: _.get(record.highlightCol, 'orderStatus') ? '#e73845' : null }}>{record.orderStatus}</div>
      ),
    },

    // {
    //   title: intl.formatMessage({ id: `transportationOrdersColTotalParcel`, defaultMessage: 'Total Parcels' }),
    //   dataIndex: 'parcel',
    //   key: 'parcel',
    //   width: 100,
    //   sorter: true,
    //   ellipsis: true,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'parcel') ? '#e73845' : null }}>{record.parcel}</div>,
    // },

    // {
    //   title: intl.formatMessage({ id: `transportationOrdersColStatus`, defaultMessage: 'Status' }),
    //   dataIndex: 'orderStatus',
    //   key: 'orderStatus',
    //   width: 150,
    //   align: 'center',
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record, index) => {
    //     return (
    //       <div className="tag-center-div-order">
    //         <Tag className="tag-center-style-order" color={record.statusColor}>
    //           {record.statusText}
    //         </Tag>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: intl.formatMessage({ id: `transportationOrdersColSize`, defaultMessage: 'Size' }),
    //   dataIndex: 'size',
    //   key: 'size',
    //   width: 130,
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'size') ? '#e73845' : null }}>{record.size}</div>,
    // },
    // {
    //   title: intl.formatMessage({ id: `transportationOrdersColWeight`, defaultMessage: 'Weight (kg)' }),
    //   dataIndex: 'weight',
    //   key: 'weight',
    //   width: 160,
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'weight') ? '#e73845' : null }}>{record.weight}</div>,
    // },
  ];

  // const setShowColumn = newDataColumns.reduce((acc, column) => {
  //   if (column.dataIndex) {
  //     acc[column.dataIndex] = true;
  //   }
  //   return acc;
  // }, {});

  const setShowColumn = {
    index: true,
    customer: true,
    orderType: true,
    orderStatus: true,
    size: true,
    weight: true,
    parcel: true,
    contact: true,
    contactPhone: true,
    // destinationName: true,
    destinationAddress: true,
    deliveryDate: true,
    deliveryTime: true,
    // sourceName: true,
    sourceAddress: true,
    soNo: true,
    referenceNo: true,
    remark: true,
  };

  const setShowColumnArr = [
    'index',
    'customer',
    'orderType',
    'orderStatus',
    'size',
    'weight',
    'parcel',
    'contact',
    'contactPhone',
    // 'destinationName',
    'destinationAddress',
    'deliveryDate',
    'deliveryTime',
    // 'sourceName',
    'sourceAddress',
    'soNo',
    'referenceNo',
    'remark',
  ];

  const ignoreColumn = [];
  const ignoreColumnApi = ['index'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = ['index', 'deliveryDate', 'deliveryTime'];
  const columnNewSearch = columns.filter((col) => !ignoreSearchColumn.includes(col.key));
  const [columnNewSearchApi, setColumnNewSearchApi] = useState([]);

  const [showColumnApi, setShowColumnApi] = useState({});
  const [showColumnArrApi, setShowColumnArrApi] = useState([]);
  const [newColumnsApi, setNewColumnsApi] = useState([]);
  const [columnsInfo, setColumnsInfo] = useState([]);

  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [scrollTableX, setScrollTableX] = useState(3000);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);

  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [fieldChange, setFieldChange] = useState('');
  const [total, setTotal] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [mainTriggerPage, setMainTriggerPage] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [verified, setVerified] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isValid, setIsValid] = useState(true);

  console.log('fileHash', fileHash, verified);

  useEffect(() => {
    setIsValid(true);
  }, [open]);

  useEffect(() => {
    setLoading(true);
    if (fileHash && verified === false) {
      try {
        const payload = {
          fileHash: _.get(fileHash, 'fileHash'),
          importId: _.get(fileHash, 'importId'),
          detailTypeCode: _.get(fileHash, 'orderDetailTypeCode'),
          pageNumber: paginationPage,
          limit: paginationSize,
          orderBy: fieldSort,
          orderType: orderSort,
        };
        const interval = setInterval(async () => {
          const response = await getPreviewFileApiNew(payload);
          console.log('getPreviewFileApiNew create', response, fileHash);

          if (_.get(response, 'data.status.code') === 200) {
            setVerified(_.get(response.data.data, 'verified'));
            createColumns(_.get(response.data.data, 'list[0]'));
          } else if (_.get(response, 'data.status.code') === 400) {
            errorNotification(_.get(response, 'data.status.message'));
            handleCancel();
          }
        }, 3000);
        return () => clearInterval(interval);
      } catch (error) {}
    } else {
    }
  }, [fileHash, verified]);

  useEffect(() => {
    setLoading(true);
    const getApi = async () => {
      let search = {};
      if (fliterByColumn !== 'all') {
        const result = { [fliterByColumn]: fieldChange };
        search = result;
      } else {
        const filterData = newDataColumns.filter((item) => ['index', 'deliveryDate', 'deliveryTime'].indexOf(item.key) === -1);
        const result = filterData.reduce((obj, cur) => ({ ...obj, [cur.key]: fieldChange }), {});
        search = result;
      }
      try {
        const payload = {
          fileHash: _.get(fileHash, 'fileHash'),
          importId: _.get(fileHash, 'importId'),
          detailTypeCode: _.get(fileHash, 'orderDetailTypeCode'),
          pageNumber: paginationPage,
          limit: paginationSize,
          orderBy: fieldSort,
          orderType: orderSort,
          search: search,
        };
        const response = await getPreviewFileApiNew(payload);
        console.log('getPreviewFileApiNew search', response);
        setDataSource(_.get(response.data.data, 'list'));
        setTotal(_.get(response.data.data, 'orderCount'));
        setVerified(_.get(response.data.data, 'verified'));
        setIsValid(_.get(response.data.data, 'isValid'));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (fileHash && verified === true) {
      getApi();
    }
  }, [fileHash, verified, paginationPage, paginationSize, fieldSort, orderSort, fieldChange, fliterByColumn]);

  const createColumns = (data) => {
    const keys = _.filter(
      _.keys(data),
      (key) =>
        ['parcel', 'item'].some((prefix) => key.startsWith(prefix)) &&
        ['Weight', 'Size', 'Code', 'Qty', 'Uom'].some((suffix) => key.endsWith(suffix))
    );
    console.log('createColumns', data, _.keys(data), keys);

    const mapColumns = _.map(keys, (column) => {
      const number = column.match(/\d+/) ? column.match(/\d+/)[0] : '';
      const [prefix, suffix] = column.split(number);

      const prefixTitle =
        prefix === 'parcel'
          ? intl.formatMessage({ id: `transportationOrdersColParcelNew`, defaultMessage: 'Parcel' })
          : intl.formatMessage({ id: `transportationOrdersColItemNew`, defaultMessage: 'Item' });

      const suffixTitle =
        suffix === 'Size'
          ? intl.formatMessage({ id: `transportationOrdersColSize`, defaultMessage: 'Size' })
          : suffix === 'Code'
          ? intl.formatMessage({ id: `transportationOrdersColCode`, defaultMessage: 'Code' })
          : suffix === 'Qty'
          ? intl.formatMessage({ id: `transportationOrdersColQty`, defaultMessage: 'Qty' })
          : suffix === 'Uom'
          ? intl.formatMessage({ id: `transportationOrdersColUom`, defaultMessage: 'UoM' })
          : suffix === 'Weight'
          ? intl.formatMessage({ id: `transportationOrdersColWeight`, defaultMessage: 'Weight (kg)' })
          : null;

      console.log('mapColumns', prefix, suffix, number, column);

      return {
        title: prefixTitle + ' ' + number + ' ' + suffixTitle,
        dataIndex: column,
        key: column,
        width: 165,
        sorter: true,
        align: suffix === 'Weight' ? 'right' : undefined,
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div style={{ color: record.highlightCol[column] ? '#e73845' : null }}>{record[column]}</div>,
      };
    });

    const addColumns =
      _.get(fileHash, 'orderDetailTypeCode') === 'deliveryOrderParcel'
        ? [
            {
              title: intl.formatMessage({ id: `transportationOrdersColTotalParcel`, defaultMessage: 'Total Parcels' }),
              dataIndex: 'parcel',
              key: 'parcel',
              width: 125,
              sorter: true,
              ellipsis: true,
              sortDirections: ['descend', 'ascend'],
              render: (text, record) => (
                <div style={{ color: _.get(record.highlightCol, 'parcel') ? '#e73845' : null }}>{record.parcel}</div>
              ),
            },
          ]
        : [
            {
              title: intl.formatMessage({ id: `transportationOrdersColTotalItme`, defaultMessage: 'Total Item' }),
              dataIndex: 'item',
              key: 'item',
              width: 100,
              sorter: true,
              ellipsis: true,
              sortDirections: ['descend', 'ascend'],
              render: (text, record) => <div style={{ color: _.get(record.highlightCol, 'item') ? '#e73845' : null }}>{record.item}</div>,
            },
          ];

    const sortOrder = ['Code', 'Qty', 'Uom', 'Size', 'Weight']; // ลำดับการเรียง

    const sortedBySuffix = _.sortBy(mapColumns, (item) => {
      // เรียกตามตัวอักษร
      const suffix = item.dataIndex.match(/[a-zA-Z]+$/)[0];
      const order = sortOrder.indexOf(suffix);
      return order !== -1 ? order : sortOrder.length;
    });

    const sortedArray = _.sortBy(sortedBySuffix, (item) => parseInt(item.dataIndex.match(/\d+/), 10) || 0); // เรียงตามตัวเลขตรงกลาง
    const columnsInfor = [...newDataColumns, ...addColumns, ...sortedArray];
    setNewDataColumns(columnsInfor);
    setColumnsInfo(columnsInfor);
    setColumnNewSearchApi(columnsInfor.filter((col) => !ignoreColumnApi.includes(col.key)));

    const showColumn = columnsInfor.reduce((acc, column) => {
      if (column.dataIndex) {
        acc[column.dataIndex] = true;
      }
      return acc;
    }, {});
    const arrayColumns = _.map(columnsInfor, (item) => {
      return item.dataIndex;
    });

    console.log('mapColumnsEnd', columnsInfor, showColumn, arrayColumns);

    setNewColumnsApi(columnsInfor.filter((col) => !ignoreColumn.includes(col.key)));
    setShowColumnApi(showColumn);
    setShowColumnArrApi(arrayColumns);

    setDefaultShowColumn(showColumn);
    setListArrayColumns(arrayColumns);
  };

  console.log('newDataColumns', newDataColumns);

  console.log('datasouece', dataSource);
  console.log('fileHash', fileHash);

  const handleSave = async () => {
    setIsLoad(true);
    try {
      const memComId = localStorage.getItem('memComId');
      const comId = localStorage.getItem('comId');
      const payload = {
        fileHash: _.get(fileHash, 'fileHash'),
        importId: _.get(fileHash, 'importId'),
        detailTypeCode: _.get(fileHash, 'orderDetailTypeCode'),
        memComId: memComId,
        comId: comId,
        branchDetail: branchData
          ? {
              branchId: _.get(branchData, 'id'),
              branchCode: _.get(branchData, 'branchCode'),
              branchName: _.get(branchData, 'branchName'),
              branchPhone: _.get(branchData, 'branchPhone'),
              branchAddressName: _.get(branchData, 'branchAddressName'),
              branchAddress: _.get(branchData, 'branchAddressDescription'),
              branchAddressLat: _.get(branchData, 'branchAddressLat'),
              branchAddressLng: _.get(branchData, 'branchAddressLng'),
              branchRemark: _.get(branchData, 'branchRemark'),
            }
          : undefined,
      };
      const response = await saveFileNew(payload);
      successNotification(_.get(response.data.status, 'message'));
      // setTriggerOrder((current) => !current);
      setTrigger((cur) => !cur);
      setOpen(false);
      setDataSource([]);
      setVerified(false);
      setFileHash();
      setFliterByColumn('all');
      setTotal();
      setFieldChange('');
      setOrderSort();
      setFieldSort();
      setPaginationPage(1);
      setPaginationSize(10);
      resetColumn();
      setIsLoad(false);
      setOpenImport(false);
      setIsValid(false);
    } catch (error) {
      console.log('errorSave', error, error.response);
      errorNotification(_.get(error, 'response.status.message'));
      setIsLoad(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setDataSource([]);
    setVerified(false);
    setFileHash();
    setFliterByColumn('all');
    setTotal();
    setFieldChange('');
    setOrderSort();
    setFieldSort();
    setPaginationPage(1);
    setPaginationSize(10);
    resetColumn();
  };

  const resetColumn = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    setVisible(false);
    setDefaultShowColumn(showColumnApi);
    setTextErrorSelectColumn('');
    setScrollTableX(3000);
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  return (
    <Modal
      title={
        _.get(fileHash, 'orderDetailTypeCode') === 'deliveryOrderParcel'
          ? intl.formatMessage({ id: 'modalOrderImportDeliveryOrderParcel', defaultMessage: 'Import Delivery Orders (Parcel)' })
          : intl.formatMessage({ id: 'modalOrderImportDeliveryOrderItem', defaultMessage: 'Import Delivery Orders (Item)' })
      }
      visible={open}
      width={1250}
      bodyStyle={{ height: 560 }}
      centered={true}
      onCancel={() => handleCancel()}
      footer={
        <div className="layout-footer-import">
          <div className="warning-layout-footer">
            {isValid === false ? (
              <>
                <Icon type="info-circle" style={{ fontSize: '16px', color: '#e73845' }} />{' '}
                <FormattedMessage
                  id="warningImportPreview"
                  defaultMessage="This file has data errors. Please edit CSV file and re-import."
                />
              </>
            ) : null}
          </div>
          <div>
            <Button_02 key="back" btnsize="wd_df" style={{ margin: 'unset' }} onClick={() => handleCancel()} disabled={isLoad}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button_02>
            <Button_01 key="submit" type="primary" btnwidth="wd_at" onClick={() => handleSave()} disabled={isLoad || !isValid}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button_01>
          </div>
        </div>
      }
    >
      <Spin indicator={antIcon} tip="Loading..." spinning={isLoad}>
        <div>
          <ActionOrdersPreview
            columns={columnsInfo ? columnsInfo : columns}
            setShowColumn={showColumnApi ? showColumnApi : setShowColumn}
            setShowColumnArr={showColumnArrApi ? showColumnArrApi : setShowColumnArr}
            columnNewSearch={columnNewSearchApi ? columnNewSearchApi : columnNewSearch}
            newDataColumns={newDataColumns}
            listArrayColumns={listArrayColumns}
            defaultShowColumn={defaultShowColumn}
            newColumns={newColumnsApi ? newColumnsApi : newColumns}
            fliterByColumn={fliterByColumn}
            fieldChange={fieldChange}
            setNewDataColumns={setNewDataColumns}
            setListArrayColumns={setListArrayColumns}
            setDefaultShowColumn={setDefaultShowColumn}
            setScrollTableX={setScrollTableX}
            setFliterByColumn={setFliterByColumn}
            setFieldChange={setFieldChange}
            textErrorSelectColumn={textErrorSelectColumn}
            setTextErrorSelectColumn={setTextErrorSelectColumn}
            visible={visible}
            setVisible={setVisible}
            setPaginationPage={setPaginationPage}
          />
        </div>
        <div>
          <OrderSourcePreview
            newDataColumns={newDataColumns}
            scrollTableX={scrollTableX}
            paginationPage={paginationPage}
            total={total}
            loading={loading}
            dataSource={dataSource}
            setPaginationPage={setPaginationPage}
            setPaginationSize={setPaginationSize}
            setOrderSort={setOrderSort}
            setFieldSort={setFieldSort}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalImportPreview;
