import React from 'react';
import styleCustomer from './css/index.css';
import { Card, Row } from 'antd';
import Customer from './table-customer/customer';
// import Loading from '../../components/loading/index'

export default () => {


  return (
    <div>
      {/* <Loading Render={(<Customer />)} loading={true}/> */}
      <Customer />
    </div>
  )
}

