import React, { useEffect, useState } from 'react'
import Information from './information'
import Contact from './contact'
import Address from './address-book'
import TeamService from './team-service'
import { Row } from 'antd'
import style from './css/index.css'
import { useParams } from 'react-router'
import httpClient from '../../../components/axiosClient';
import _ from 'lodash';
import { PageSettings } from '../../../config/page-settings';


export default () => {

  const comCode = localStorage.getItem('comCode');

  const paramCustomerId = useParams();
  const customerId = _.get(paramCustomerId, 'vendor_id')
  const [informationData, setInformationData] = useState()
  const [contactData, setContactData] = useState()
  const [addressBookData, setAddressBookData] = useState()
  const [teamService, setTeamService] = useState()
  const [triggerAddContact, setTriggerAddContact] = useState(false)
  const [triggerAddToLocation, setTriggerAddToLocation] = useState(false)
  const [triggerTeamService, settriggerTeamService] = useState(false)
  const [triggerDeleteImg, settriggerDeleteImg] = useState(false)

  useEffect(() => {
    getData();
  }, [customerId, triggerAddContact, triggerAddToLocation, triggerTeamService, triggerDeleteImg])

  const getData = async () => {
    try {
  
      const response = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`);

      if (response.status == 200) {
        setInformationData(response.data.data)
        setContactData(response.data.data.contacts)
        setAddressBookData(response.data.data.addressBooks);
        setTeamService(response.data.data.teamservice);
      }
    } catch (error) {

    }
  }




  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        <div className='padding-card-component-top'>
          <Row className='padding-card-component'>
            {
              checkPermissionAction('P14PG2C1', 'P14PG2C1A1') ? (
                <Information informationData={informationData}
                  setTriggerAddContact={setTriggerAddContact}
                  triggerAddContact={triggerAddContact}
                  settriggerDeleteImg={settriggerDeleteImg}
                  triggerDeleteImg={triggerDeleteImg} />
              ) : null
            }
          </Row>
          <Row className='padding-card-component'>
            {
              checkPermissionAction('P14PG2C2', 'P14PG2C2A1') ? (
                <Contact contactData={contactData}
                  informationData={informationData}
                  setTriggerAddContact={setTriggerAddContact}
                  triggerAddContact={triggerAddContact} />
              ) : null
            }

          </Row>
          <Row className='padding-card-component'>
            {
              checkPermissionAction('P14PG2C3', 'P14PG2C3A1') ? (
                <Address addressBookData={addressBookData}
                  informationData={informationData}
                  setTriggerAddToLocation={setTriggerAddToLocation}
                  triggerAddToLocation={triggerAddToLocation}
                />
              ) : null
            }

          </Row>
          <Row className='padding-card-component'>
            {
              checkPermissionAction('P14PG2C4', 'P14PG2C4A1') ? (
                <TeamService
                  customerId={customerId}
                  settriggerTeamService={settriggerTeamService}
                  triggerTeamService={triggerTeamService}
                  teamService={teamService}
                />
              ) : null
            }

          </Row>
        </div>}
    </PageSettings.Consumer>
  )
}
