import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Col, Row, Collapse, Tree, Icon } from 'antd';
import '../css/Filter.css';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

const CheckboxGroup = Checkbox.Group;
const { TreeNode } = Tree;

export default React.memo((props) => {
  const {
    Data,
    checkedKeysRequest,
    checkedKeysTeam,
    expandedKeys,
    selectedKeys,
    indeterminateRequest,
    indeterminateTeam,
    setIndeterminateTeam,
    setIndeterminateRequest,
    setPaginationPage,
    LangCode,
    onCheckRequest,
    onCheckTeam,
    onExpand,
    onSelect,
    setCheckAllRequest,
    checkAllRequest,
    checkAllTeam,
    setCheckAllTeam,
    setDisbleApply
  } = props;

  const intl = useIntl();
  const teamData = _.get(Data, 'team');
  const requestData = _.get(Data, 'reqTypeGroup');
  const [visiblePanelRequest, setVisiblePanelStage] = useState(false);
  const [visiblePanelTeam, setVisiblePanelTeam] = useState(false);
  // const [checkAllTeam, setCheckAllTeam] = useState(false);
  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };
  const allKeysRequest = getAllKeys(requestData);

  console.log('teamData', teamData);

  let getAllKeyTeams = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];
      result.push(...[x.orgId, ...childKeys]);
    });

    return result;
  };
  const allKeysTeam = getAllKeyTeams(teamData);

  const handleCheckAllRequest = (e) => {
    setIndeterminateRequest(false);
    setCheckAllRequest(e.target.checked);
    setPaginationPage(1);
    setDisbleApply(false);
    if (e.target.checked === true) {
      console.log('AllkeyRequest', allKeysRequest);
      onCheckRequest(allKeysRequest);
    } else {
      onCheckRequest([]);
    }
  };

  console.log('checked data ::::', _.get(Data, 'reqTypeGroup'), checkedKeysRequest, selectedKeys, indeterminateRequest);

  const handleCheckAllTeam = (e) => {
    setIndeterminateTeam(false);
    setCheckAllTeam(e.target.checked);
    setPaginationPage(1);
    setDisbleApply(false);
    if (e.target.checked === true) {
      onCheckTeam(allKeysTeam);
    } else {
      onCheckTeam([]);
    }
  };



  const headerTitleRequest = (res) => {
    return (
      <div>
        <Row>
          <Col span={14}>
            <Checkbox onChange={handleCheckAllRequest} indeterminate={indeterminateRequest} checked={checkAllRequest} >
              <span>{_.get(res, 'stageName')}<FormattedMessage id="ToReviewRequestType" defaultMessage="Request Type" /></span>
            </Checkbox>
          </Col>
          <Col span={8} style={{textAlign: 'right'}}>
            <span className="select-text-panel-style">{`${intl.formatMessage({
              id: `filterTaskSelected`,
              defaultMessage: 'Selected',
            })} ${_.size(checkedKeysRequest)}`}</span>
          </Col>
          <Col span={2} onClick={() => setVisiblePanelStage((current) => !current)} style={{ cursor: 'pointer',textAlign: 'right' }}>
            {visiblePanelRequest ? <Icon type="up" /> : <Icon type="down" />}
          </Col>
        </Row>
      </div>
    );
  };

  const headerTitleTeam = (res) => {
    return (
      <div>
        <Row>
          <Col span={14}>
            <Checkbox onChange={handleCheckAllTeam} indeterminate={indeterminateTeam} checked={checkAllTeam}>
              <span>{_.get(res, 'teamChkboxName')}<FormattedMessage id="ToReviewRequestTeam" defaultMessage="Team" /></span>
            </Checkbox>
          </Col>
          <Col span={8} style={{textAlign: 'right'}}>
            <span className="select-text-panel-style">{`${intl.formatMessage({
              id: `filterTaskSelected`,
              defaultMessage: 'Selected',
            })} ${_.size(checkedKeysTeam)}`}</span>
          </Col>
          <Col span={2} onClick={() => setVisiblePanelTeam((current) => !current)} style={{ cursor: 'pointer',textAlign: 'right' }}>
            {visiblePanelTeam ? <Icon type="up" /> : <Icon type="down" />}
          </Col>
        </Row>
      </div>
    );
  };

  const renderTreeNodesRequest = (data) =>
    data.map((item) => {
      const title = <span>{item.reqTypeName}</span>;

      if (item.reqTypeList) {
        return (
          <TreeNode title={item.reqTypeName} key={item.reqTypeCode}>
            {renderTreeNodesRequest(item.reqTypeList)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.reqTypeCode} title={item.reqTypeName} />;
    });

  const renderTreeNodesTeam = (data) =>
    data.map((item) => {
      const title = <span>{item.teamName}</span>;

      if (item.children) {
        return (
          <TreeNode title={item.name} key={item.orgId}>
            {renderTreeNodesTeam(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.orgId} title={item.teamName} />;
    });

  return (
    <div >
      <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb' }}>
        <div>
          {headerTitleRequest()}
          {visiblePanelRequest ? (
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={false}
              onCheck={onCheckRequest}
              checkedKeys={checkedKeysRequest}
              onSelect={onSelect}
              selectedKeys={selectedKeys}
            >
              {renderTreeNodesRequest(_.get(Data, 'reqTypeGroup'))}
            </Tree>
          ) : null}
        </div>
      </div>

      <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb', borderBottom: '1px solid #e4e6eb' }}>
        <div>
          {headerTitleTeam()}
          {visiblePanelTeam ? (
            <div>
              <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={false}
                onCheck={onCheckTeam}
                checkedKeys={checkedKeysTeam}
              >
                {renderTreeNodesTeam(_.get(Data, 'team'))}
              </Tree>
            </div>
          ) : null}
        </div>
      </div>
      {/* ) : null} */}
      <div></div>
    </div>
  );
});
