import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { 
  Button,
  Row,
  Input,
  Col,
  DatePicker,
  Select,
  List,
  Icon,
  Dropdown,
  Checkbox,
  ConfigProvider,
  Menu,
 } from 'antd';
import { useAppContext } from '../../includes/indexProvider';
import Button01 from '../v2/button_01';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import styles from './css/action-report.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ActionReportV2 = (props) => {
  const { value, fnc } = props;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const dateFormat = 'ddd, MMM DD YYYY';
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const columnNew = value.columns.filter((col) => !value.ignoreFilterColumns.includes(col.key));
  const columnNewSearc = value.columns.filter((col) => !value.ignoreSearchColumns.includes(col.key));

  const menuColumn = () => {
    return (
      <Menu className="action-report-menu">
        <Menu.Item className="action-menu-item">
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={fnc.checkedValuecolumns}
                checked={value.defaultShowColumn[item.key] ? true : false}
              >
                <span className="action-menu-checkbox-span">{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item className="action-menu-item-text-error">
          {value.textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{backgroundColor : '#ffffff'}}>
          <Button.Group className="action-button-group">
            <Button className="action-button-group-button" onClick={fnc.handleCheckOk}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button className="action-button-group-button" onClick={fnc.handleReset}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className="action-report">
      <Row className="action-report-row">
        <Col span={12} className="action-report-col">
          <div className="action-report-div-left">
            <Input
              className="action-report-input action-input"
              placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
              prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
              onChange={(e) => fnc.onChangeSearch(e.target.value)}
              autoComplete="off" 
            />
            <Select
              className="action-report-select-column action-select"
              placeholder={intl.formatMessage({ id: 'hintSelect', defaultMessage: 'Select'})}
              value={value.fliterByColumn}
              onSelect={(value) => fnc.onFliterByColumn(value)}
            >
              <Option value="all">
                <FormattedMessage id="reportLeaveReportHintSeleteAllColumns" defaultMessage="All columns" />
              </Option>
              {columnNewSearc.map((item, i) => (
                <Option key={i} value={item.key}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={12} className="action-report-col">
          <div className="action-report-div-right">
            <ConfigProvider locale={locale}>
              <RangePicker
                className="action-report-rangePicker"
                defaultValue={[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]}
                format={dateFormat}
                onChange={fnc.onSelectRangePicker}
                allowClear={false}
              />
            </ConfigProvider>
            <Dropdown
              className="action-report-dropdown"
              overlayClassName="action-report-dropdown-overlay"
              trigger={['click']}
              placement="bottomRight"
              overlay={menuColumn}
              onVisibleChange={fnc.handleVisibleChange}
              visible={value.visible}
            >
              <Button01
                type="primary"
                btnsize="wd_at"
                fontSize="sm"
                onClick={fnc.handleOnVisiblecolumns}
              >
                <FormattedMessage id="reportLeaveReportBtnColumns" defaultMessage="Columns" />{" "}
                <Icon type="down" />
              </Button01>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ActionReportV2;
