import React, { useContext, useState, useEffect } from 'react';
import { Card, Row, Col, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import AssetSummaryContext from '../context';
import { getInitialAssetClass } from '../../../controllers/asset-resource/asset-controller';
import _ from 'lodash';
import './css/selectAssetClass.css';
const { Option } = Select;

const SelectAssetClass = () => {
  const { state, setState, fnc } = useContext(AssetSummaryContext);
  const { assetSelect, assetSelectObj ,mainTrigger } = state;

  const { setAssetSelect, setAssetSelectObj, setPaginationPage } = setState;
  const intl = useIntl();

  const [initialAssetClassList, setInitialAssetClassList] = useState([]);
  const [initialAssetClassListMaster, setInitialAssetClassListMaster] =
    useState([]);
  const [initialAssetClassLoading, setInitialAssetClassLoading] =
    useState(false);

  const [isAssetLoadMore, setIsAssetLoadMore] = useState(true);
  const [isAssetSearchLoadMore, setIsAssetSearchLoadMore] = useState(true);

  const [assetPage, setAssetPage] = useState(0);
  const [assetPageSize, setAssetPageSize] = useState(10);
  const [assetSearchPage, setAssetSearchPage] = useState(0);
  const [assetSearchPageSize, setAssetSearchPageSize] = useState(10);

  const [assetSearch, setAssetSearch] = useState();

  useEffect(() => {
    const getApi = async () => {
      let payload = {};
      const result = await getInitialAssetClass(payload);
      setInitialAssetClassList(_.get(result, 'dataList') || []);
    };
    getApi();
  }, [mainTrigger]);

  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: assetPage * assetPageSize + 1,
        indexEnd: assetPage * assetPageSize + 10,
        searchName: assetSearch,
      };
      const result = await getInitialAssetClass(payload);
      const setAssetClassListData = _.map(_.get(result, 'dataList'), (o) => {
        return o;
      });
      const assets = _.uniqBy(
        _.concat(initialAssetClassList, setAssetClassListData),
        'assetClassId',
      );
      setInitialAssetClassList(assets);
      setInitialAssetClassListMaster(assets);
      setInitialAssetClassLoading(false);
      setIsAssetLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
    };

    if (isAssetLoadMore) {
      getData();
    }
  }, [assetPage]);

  useEffect(() => {
    const getData = async () => {
      if (!assetSearch) {
        setInitialAssetClassList(initialAssetClassListMaster);
        setInitialAssetClassLoading(false);
      } else {
        let payload = {
          indexStart: assetSearchPage * assetSearchPageSize + 1,
          indexEnd: assetSearchPage * assetSearchPageSize + 10,
          searchName: assetSearch,
        };
        const result = await getInitialAssetClass(payload);
        const setAssetClassListData = _.map(_.get(result, 'dataList'), (o) => {
          return o;
        });
        const assets = _.uniqBy(
          _.concat(initialAssetClassList, setAssetClassListData),
          'assetClassId',
        );
        setInitialAssetClassList(assets);
        setInitialAssetClassLoading(false);
        setIsAssetSearchLoadMore(
          _.size(setAssetClassListData) > 0 ? true : false,
        );
      }
    };

    if (isAssetSearchLoadMore) {
      getData();
    }
  }, [assetSearch, assetSearchPage]);

  const handleAssetPopupScroll = (e) => {
    let target = e.target;
    if (
      !initialAssetClassLoading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight - 50
    ) {
      setInitialAssetClassLoading(true);
      if (assetSearch) {
        setAssetSearchPage(assetSearchPage + 1);
      } else {
        setAssetPage(assetPage + 1);
      }
    }
  };

  const handleAssetChangeSearch = (value) => {
    setInitialAssetClassLoading(true);
    setIsAssetSearchLoadMore(true);
    setInitialAssetClassList([]);
    setAssetSearchPage(0);
    setAssetSearch(value);
  };

  const handleAssetChange = (value) => {
    if (!value) {
      setAssetSelect();
      setAssetSelectObj();
    }
  };

  const handleAssetChangeSelect = (value) => {
    if (value !== assetSelect) {
      setAssetSearch();
      setInitialAssetClassList(initialAssetClassListMaster);

      const filterAssetClassObj = _.filter(
        initialAssetClassList,
        (item) => item.assetClassId === value,
      );
      const newAssetClassObj = _.map(filterAssetClassObj, (item) => {
        return {
          assetClassId: item.assetClassId,
          assetClassCode: item.assetClassCode,
          assetClassName: item.assetClassName,
        };
      });

      setAssetSelectObj(newAssetClassObj);
      setAssetSelect(value);
      setPaginationPage(1);
    }
  };

  const assetClassOption = (o, i) => {
    return (
      <Option
        key={i}
        value={o.assetClassId}
      >{`${o.assetClassName} (${o.totalAsset})`}</Option>
    );
  };

  return (
    <div>
      <div className="label-asset">
        <FormattedMessage
          id="assetCardListAssetClass"
          defaultMessage="Asset Class"
        />
      </div>
      <div>
        <Select
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({
            id: `assetCardListHintAssetClass`,
            defaultMessage: 'Select Asset Class',
          })}
          defaultActiveFirstOption={false}
          filterOption={false}
          showSearch
          loading={initialAssetClassLoading}
          onPopupScroll={handleAssetPopupScroll}
          onSelect={handleAssetChangeSelect}
          onSearch={handleAssetChangeSearch}
          onChange={handleAssetChange}
        >
          {initialAssetClassList &&
            initialAssetClassList.map((item, index) =>
              assetClassOption(item, index),
            )}
        </Select>
      </div>
    </div>
  );
};

export default SelectAssetClass;
