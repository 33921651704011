import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import './index.css'
import ListItemFilter from './filter'
import ListItemDetail from './item'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { useDebounce } from '../../../../../controllers/debounce'
import { getItemsCode, getItemsMasterNew } from '../../../../../controllers/warehouse/warehouse'
import Button_01 from '../../../../../components/v2/button_01'
import { getItemServiceNew } from '../../../../../controllers/expense/expense'

const ListItemServiceInvoice = (props) => {
  const { visible, setVisible, setVisibleAdd, setCreateData, dataForm } = props
  const intl = useIntl()
  const [searchGroup, setSearchGroup] = useState([]);
  const [itemList, setItemList] = useState([])

  const [optionsItemCode, setOptionsItemCode] = useState([]);
  const [loadingItemCode, setLoadingItemCode] = useState(false);
  const [itemCodeSearch, setItemCodeSearch] = useState();
  const debounceItemCodeSearch = useDebounce(itemCodeSearch, 500)

  const [optionsItemName, setOptionsItemName] = useState([]);
  const [loadingItemName, setLoadingItemName] = useState(false);
  const [itemNameSearch, setItemNameSearch] = useState();
  const debounceItemNameSearch = useDebounce(itemNameSearch, 500)

  console.log("dataForm", dataForm)

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
  ];

  useEffect(() => {
    if (visible) {
      GetSearchListData()
    }
  }, [visible])


  const GetSearchListData = async () => {
    const body = {
      "itemBrandId": [],
      "itemModelId": [],
      "itemCode": _.get(searchGroup, 'itemCode', ""),
      "itemName": _.get(searchGroup, 'itemName', ""),
      "limit": 100000,
      "page": 1,
      "customerId": _.get(dataForm, 'customerId'),
      "recently": _.get(searchGroup, 'purchase', false),
      "recentlyModule": "invoice",
      "categoryCode": "service"
    }
    const response = await getItemServiceNew(body)
    setItemList(_.get(response, 'data.data.itemList'))
  }

  const handleClose = () => {
    setSearchGroup([])
    setItemList([])
    setVisible(false)
  }

  const handleClickRow = (data) => {
    const setData = {
      ...data,
      itemMasterId: _.get(data, 'itemMasterId')
    }
    setSearchGroup({})
    setCreateData(setData)
    setVisibleAdd(true)
  }


  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        code: itemCodeSearch || '',
      };
      const response = await getItemsCode(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setOptionsItemCode(list);
    };
    getItemCode();
  }, [debounceItemCodeSearch]);

  useEffect(() => {
    const getItemName = async () => {
      const payload = {
        name: itemNameSearch || '',
      };
      const response = await getItemsMasterNew(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setOptionsItemName(list);
    };

    getItemName();
  }, [debounceItemNameSearch]);

  const onChange = async (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    // if (code === 'vendor') {
    //   handleChangeCustomer(value, true)
    // }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemServiceTitle`, defaultMessage: 'Add Item Service' })}
      visible={visible}
      onCancel={handleClose}
      centered={true}
      width={1112}
      style={{ height: 'calc(130vh - 300px)', margin: '0px 0px' }}
      // style={{ height: isCollapse ? `calc(90vh - 357px)` : `calc(100vh - 357px)`, padding: '16px' }}
      bodyStyle={{ padding: 'unset',}}
      footer={[
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className='listItemTextHeader'>
            <FormattedMessage id="btnFilter" defaultMessage="Filter" />
          </div>
          <div className='listItemDividerCenter'>
            <ListItemFilter
              onChange={onChange}
              searchGroup={searchGroup}
              onClick={GetSearchListData}
              itemCode={{
                optionsItemCode,
                loadingItemCode,
                itemCodeSearch,
                setItemCodeSearch,
              }}
              itemName={{
                optionsItemName,
                loadingItemName,
                itemNameSearch,
                setItemNameSearch,
              }}
            />
          </div>
        </Col>
        <Col span={18}>
          <div className='listItemTextHeader'>
            <FormattedMessage id="modalListItemItemText" defaultMessage="Item" />
          </div>
          <div>
            <ListItemDetail
              onChange={onChange}
              columns={columns}
              handleClickRow={handleClickRow}
              itemList={itemList}
              dataForm={dataForm}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default ListItemServiceInvoice
