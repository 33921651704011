import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import styles from './css/index.css';

const OrderReport = (props) => {
  const { loading, dataSource, columns, total, fnc, paginationPage } = props;
  const intl = useIntl();

  const showTotal = (total) => {
    return <span style={{ fontSize: '13px' }}><FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " /></span>
  };

  return (
    <div className="order-report">
      <Table
        className="order-report-table"
        loading={loading}
        rowKey={record => record.memComId}
        dataSource={dataSource}
        columns={columns}
        onChange={fnc.handleChange}
        scroll={{ y: `calc(100vh - 400px)` }}
        pagination={{
          total: total,
          showTotal: showTotal,
          // defaultCurrent: 1,
          current: paginationPage,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: fnc.handlePagination,
          onShowSizeChange: fnc.handleSizeChange,
        }}
      />
      {dataSource && dataSource.length !== 0 ?
        <div className="total-items-order-report-table">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
        : null
      }
    </div>
  );
}

export default OrderReport
