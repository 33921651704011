import React, { useEffect, useState } from 'react'
import './index.css'
import { Form, Modal } from 'antd'
import _ from 'lodash'
import { ConfigModal } from './utility'
import { FormattedMessage } from 'react-intl'
import Button02 from '../../../components/v2/button_02'
import Button01 from '../../../components/v2/button_01'
import PettyCashFormCRUD from './form/crud'
import PettyCashFormTranfer from './form/tranfer'
import PettyCashFormReceivePaid from './form/receive-paid'
import getTeamUser from '../../../controllers/user/get-user-team'
import getTeam from '../../../controllers/user/get-team'
import { savePettyCash, savePocketTranfer, updatePettyCash, receivePettyCash, paidPettyCash } from '../../../controllers/petty-cash/petty-cash'
import { successNotification, errorNotification } from '../../../components/v2/notification'
import ListItemCategory from '../../../components/list-item-category'
import moment from 'moment'

const ModalFormPettyCash = (props) => {
  const { defaultModal, setDefaultModal, form, teamListMaster, userListMaster, setTrigger, pocketListMaster,
    categoryList, setTriggerCategory } = props
  const { validateFields, resetFields, setFieldsValue, getFieldValue } = form
  const type = _.get(defaultModal, 'type')
  const config = ConfigModal(defaultModal)
  const [userList, setUserList] = useState({});
  const [teamList, setTeamList] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [pocketList, setPocketList] = useState({})
  const [visibleCategory, setVisibleCategory] = useState(false)

  useEffect(() => {
    if (_.get(defaultModal, 'visible')) {
      if (type === 'create') {
        setUserList(userListMaster)
        setTeamList(teamListMaster)
      } else if (type === 'edit') {
        DefaultListTeam(_.get(defaultModal, 'data.responsibleBy.responsibleId'))
        DefaultListUser(_.get(defaultModal, 'data.team.teamId'))
      } else if (type === 'tranfer') {
        setPocketList({
          from: pocketListMaster,
          to: pocketListMaster,
          master: pocketListMaster
        })
      } else if (type === 'receive' || type === 'paid') {
        setUserList(userListMaster)
      }
    }

  }, [_.get(defaultModal, 'visible')])

  const DefaultListTeam = async (value) => {
    const getTeamByResponsibleBy = await getTeam(value);
    const mapTeam = _.map(getTeamByResponsibleBy, (item) => {
      return {
        org_id: parseFloat(item.org_id),
        name: item.name,
      };
    });
    setTeamList({
      list: mapTeam,
      master: _.get(teamListMaster, 'master')
    });
  }

  const DefaultListUser = async (value) => {
    const body = { search: '', searchBy: 'all', paging: 1, rowsPerPages: 1000 };
    const getResponsibleByByTeam = await getTeamUser(value, body);
    const mapAssign = _.map(_.get(getResponsibleByByTeam, 'data.members.list'), (item) => {
      return {
        mem_com_id: parseFloat(item.memComId),
        fullname: item.fullname,
      };
    });
    setUserList({
      list: mapAssign,
      master: _.get(userListMaster, 'master')
    });
  }


  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      let response = {}
      const findResponsibleBy = _.find(_.get(userList, 'list'), (item) => { return parseFloat(item.mem_com_id) === parseFloat(_.get(values, 'responsibleBy')) })
      const findTeam = _.find(_.get(teamList, 'list'), (item) => { return parseFloat(item.org_id) === parseFloat(_.get(values, 'team')) })
      const findActionBy = _.find(_.get(userList, 'list'), (item) => { return parseFloat(item.mem_com_id) === parseFloat(_.get(values, 'actionBy')) })

      const body = type === 'create' || type === 'edit' ? {
        pettyCashName: _.get(values, 'pettyCashName', ""),
        remark: _.get(values, 'remark', ""),
        status: 'open',
        teamId: _.toString(_.get(findTeam, 'org_id', "")),
        team: _.get(findTeam, 'name', ""),
        responsibleBy: _.get(findResponsibleBy, 'fullname', ""),
        responsibleId: _.toString(_.get(findResponsibleBy, 'mem_com_id', "")),
      } : type === 'tranfer' ? {
        transfererId: _.get(values, 'fromPettyCash'),
        receiverId: _.get(values, 'toPettyCash'),
        amountTransfer: parseFloat(_.get(values, 'tranferAmount', 0)),
        remark: _.get(values, 'remark', ""),
      } : type === 'receive' || type === 'paid' ? {
        categoryId: _.get(values, 'category'),
        remark: _.get(values, 'remark', ""),
        credit: type === 'receive' ? parseFloat(_.get(values, 'amount', 0)) : undefined,
        debit: type === 'paid' ? parseFloat(_.get(values, 'amount', 0)) : undefined,
        pettyCashId: _.get(defaultModal, 'data.pettyCashId'),
        referenceNo: _.get(values, 'reference', ""),
        actionByName: _.get(findActionBy, 'fullname', ""),
        actionById: _.get(findActionBy, 'mem_com_id', ""),
        transectionDate: _.get(values, 'transactionDate') ? moment(_.get(values, 'transactionDate')).format('DD/MM/YYYY HH:mm') : undefined
      } : {}
      if (type === 'create') response = await savePettyCash(body)
      if (type === 'edit') response = await updatePettyCash(_.get(defaultModal, 'data.pettyCashId'), body)
      if (type === 'tranfer') response = await savePocketTranfer(body)
      if (type === 'receive') response = await receivePettyCash(body)
      if (type === 'paid') response = await paidPettyCash(body)
      if (_.get(response, 'data.status.code') === 200) {
        successNotification(_.get(response, 'data.status.message'))
        setTrigger(cur => !cur)
        setDefaultModal({
          type: '',
          data: {},
          visible: false
        })
        resetFields()
      } else {
        errorNotification(_.get(response, 'data.status.message'))
      }
    })
  }

  const handleClose = () => {
    resetFields()
    setDefaultModal({
      type: '',
      data: {},
      visible: false
    })
  }

  const handleChangeTeam = async (value) => {
    if (!isSearch) {
      if (value) {
        const responsibleBy = getFieldValue('responsibleBy');
        const body = { search: '', searchBy: 'all', paging: 1, rowsPerPages: 1000 };
        const getResponsibleByByTeam = await getTeamUser(value, body);
        const mapAssign = _.map(_.get(getResponsibleByByTeam, 'data.members.list'), (item) => {
          return {
            mem_com_id: parseFloat(item.memComId),
            fullname: item.fullname,
          };
        });
        const findResponsibleBy = _.find(mapAssign, (item) => {
          return item.mem_com_id === parseFloat(responsibleBy);
        });
        setUserList({
          ...userList,
          list: mapAssign,
        });
        setFieldsValue({ team: value });
        setFieldsValue({ responsibleBy: findResponsibleBy ? responsibleBy : undefined });
      } else {
        setIsSearch(false);
        setTeamList({ ...teamList, list: _.get(teamList, 'master') });
        setUserList({ ...userList, list: _.get(userList, 'master') });
        setFieldsValue({ responsibleBy: undefined });
      }
    } else {
      setFieldsValue({ team: value });
    }
  };

  const handleChangeUser = async (value) => {
    if (!isSearch) {
      if (value) {
        const team = getFieldValue('team');
        const getTeamByResponsibleBy = await getTeam(value);
        const mapTeam = _.map(getTeamByResponsibleBy, (item) => {
          return {
            org_id: parseFloat(item.org_id),
            name: item.name,
          };
        });
        const findTeam = _.find(mapTeam, (item) => {
          return item.org_id === parseFloat(team);
        });
        setTeamList({
          ...teamList,
          list: mapTeam,
        });
        setFieldsValue({ responsibleBy: value });
        setFieldsValue({ team: findTeam ? team : undefined });
      } else {
        setIsSearch(false);
        setTeamList({ ...teamList, list: _.get(teamList, 'master') });
        setUserList({ ...userList, list: _.get(userList, 'master') });
        setFieldsValue({ team: undefined });
      }
    } else {
      setFieldsValue({ responsibleBy: value });
    }
  };

  const handleChangeFromPocket = (value, type) => {
    const tranferAmount = getFieldValue('tranferAmount');
    const findData = _.find(_.get(pocketList, 'master'), (item) => { return item.pettyCashId === value })
    if (type === 'from') setFieldsValue({
      fromCurrentBalance: parseFloat(_.get(findData, 'currentBalance')),
      tranferAmount: parseFloat(_.get(findData, 'currentBalance'))
    })
    if (type === 'to') setFieldsValue({
      toCurrentBalance: parseFloat(_.get(findData, 'currentBalance')),
      newBalance: (parseFloat(tranferAmount) + parseFloat(_.get(findData, 'currentBalance'))).toFixed(2)
    })
  }

  const handleChangeTranferAmount = (value) => {
    const toCurrentBalance = getFieldValue('toCurrentBalance') || 0;
    const amount = value && value !== '-' ? parseFloat(value) : 0
    setFieldsValue({
      newBalance: (amount + parseFloat(toCurrentBalance)).toFixed(2)
    })
  }

  const handleSwapBalance = () => {
    setFieldsValue({
      fromPettyCash: getFieldValue('toPettyCash'),
      toPettyCash: getFieldValue('fromPettyCash'),
      fromCurrentBalance: getFieldValue('toCurrentBalance'),
      toCurrentBalance: getFieldValue('fromCurrentBalance'),
      tranferAmount: parseFloat(getFieldValue('toCurrentBalance')),
      newBalance: (parseFloat(getFieldValue('toCurrentBalance')) + getFieldValue('fromCurrentBalance')).toFixed(2)
    })
  }

  const handleOpenModalCategory = () => {
    setVisibleCategory(true)
  }

  const handleSelectCategory = (id) => {
    setFieldsValue({
      category: id,
    })
  }

  return (
    <Modal
      title={_.get(config, 'title')}
      visible={_.get(defaultModal, 'visible')}
      width={_.get(config, 'width')}
      centered={true}
      onCancel={handleClose}
      bodyStyle={{ padding: 'unset' }}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
        <Button01 style={{ margin: 'unset' }} key="draft" type="primary" btnsize="wd_px" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>
      ]}
    >
      {
        type === 'create' || type === 'edit' ? (
          <PettyCashFormCRUD
            form={form}
            userList={userList}
            teamList={teamList}
            handleChangeTeam={handleChangeTeam}
            handleChangeUser={handleChangeUser}
            defaultModal={defaultModal}
          />
        ) : type === 'tranfer' ? (
          <PettyCashFormTranfer
            form={form}
            pocketList={pocketList}
            defaultModal={defaultModal}
            handleChangeFromPocket={handleChangeFromPocket}
            handleChangeTranferAmount={handleChangeTranferAmount}
            handleSwapBalance={handleSwapBalance}
          />
        ) : type === 'receive' || type === 'paid' ? (
          <PettyCashFormReceivePaid
            form={form}
            categoryList={categoryList}
            userList={userList}
            handleOpenModalCategory={handleOpenModalCategory}
          />
        ) : null
      }

      <ListItemCategory
        visible={visibleCategory}
        setVisible={setVisibleCategory}
        setTriggerCategory={setTriggerCategory}
        handleSelectCategory={handleSelectCategory}
      />

    </Modal>
  )
}

const ModalPettyCash = Form.create({
  name: "modal_form",
  mapPropsToFields() {

  }
})(ModalFormPettyCash);

export default ModalPettyCash
