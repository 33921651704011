import React, { useState, useEffect } from 'react';
import './index.css';
import { Card, Table, Dropdown, Menu, Button, Row, Col, Modal, Select, Form, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import ReportItemMovementAction from './action';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDebounce } from '../../../controllers/debounce';
import moment from 'moment';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import Button01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';
import { getReportReconcile, exportReportReconcile } from '../../../controllers/report/accouting-report/api';
import Button_02 from '../../../components/v2/button_02';
import LabeRequireForm from '../../../components/label-required-form';
import { exportReportItemMovement, getItemListLoadMore, getReportItemMovement } from '../../../controllers/report/inventory-report/api';
import NumberFormat from 'react-number-format';

const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;

const WarehouseReportItemMovement = (props) => {
  const { form } = props

  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsWarehouseCode', defaultMessage: 'Warehouse Code' }),
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      sorter: true,
      default: false,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsWarehouseName', defaultMessage: 'Warehouse Name' }),
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      sorter: true,
      default: true,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsType', defaultMessage: 'Type' }),
      dataIndex: 'transactionTypeName',
      key: 'transactionTypeName',
      sorter: true,
      default: true,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag style={{fontSize: '14px'}} className="tag-center-style" color={_.get(record, 'type') === 'IN' ? '#3BA223' : '#C6CFE2'}>
            {_.get(record, 'transactionTypeName')}
          </Tag>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsLotNoOrSerialNo', defaultMessage: 'Lot No. or Serial No.' }),
      dataIndex: 'lotNoOrSerialNo',
      key: 'lotNoOrSerialNo',
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsCost', defaultMessage: 'Cost' }),
      dataIndex: 'cost',
      key: 'cost',
      sorter: true,
      default: true,
      render: (text, record, index) => numberFormatter(record.cost || 0) + ' THB',
    },
    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      default: true,
      render: (text, record, index) => (
        <span style={record.transactionTypeCode === 'adjust' ? { color: 'red' } : null}>
          {_.get(record, 'qty')}
          {/* <><NumberFormat value={parseFloat(record.qty)} displayType={'text'} thousandSeparator={true} /></> */}
        </span>
      ),
    },

    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsUnit', defaultMessage: 'Unit' }),
      dataIndex: 'unitName',
      key: 'unitName',
      sorter: true,
      default: true,
    },

    // {
    //   title: intl.formatMessage({ id: 'reportItemMovementColumnsAmout', defaultMessage: 'Amount' }),
    //   dataIndex: 'amount',
    //   key: 'amount',
    //   sorter: true,
    //   default: true,
    //   render: (text, record, index) => numberFormatter(record.price || 0) + ' THB',
    // },

    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsBalance', defaultMessage: 'Balance' }),
      dataIndex: 'balance',
      key: 'balance',
      sorter: true,
      default: true,
    },

    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsExpiredDate', defaultMessage: 'ExpiredDate' }),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      sorter: true,
      default: true,
    },

    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsActionBy', defaultMessage: 'Action by' }),
      dataIndex: 'actionByName',
      key: 'actionByName',
      sorter: true,
      default: true,
    },

    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsActionDate', defaultMessage: 'Action Date' }),
      dataIndex: 'actionDate',
      key: 'actionDate',
      align: 'right',
      sorter: true,
      default: true,
    },

    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsVendorOrSupplier', defaultMessage: 'Vendor or Supplier' }),
      dataIndex: 'vendorOrSupplier',
      key: 'vendorOrSupplier',
      align: 'right',
      sorter: true,
      default: true,
    },

    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsRefType', defaultMessage: 'Ref Type' }),
      dataIndex: 'refTypeName',
      key: 'refTypeName',
      align: 'right',
      sorter: true,
      default: true,
    },

    {
      title: intl.formatMessage({ id: 'reportItemMovementColumnsRefNo', defaultMessage: 'Ref No.' }),
      dataIndex: 'refNo',
      key: 'refNo',
      align: 'right',
      sorter: true,
      default: true,
    },
  ];
  const setShowColumnArr = _.map(columns, (item) => {
    return item.dataIndex;
  });
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  const ignoreColumn = _.map(
    _.filter(columns, (item) => {
      return item.default === false;
    }),
    (item) => {
      return item.dataIndex;
    }
  );
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [search, setSearch] = useState({});
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2;
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [refTypeList, setRefTypeList] = useState([]);
  const [summary, setSummary] = useState([]);
  const searchDebounce = useDebounce(search, 500);

  const [isModal, setIsModal] = useState(true);
  const [itemPage, setItemPage] = useState(0);
  const [itemPageSize, setItemPageSize] = useState(10);
  const [isItemLoadMore, setIsItemLoadMore] = useState(true);

  // const [itemSelect, setItemSelect] = useState();
  const [itemSearch, setItemSearch] = useState('');
  const [itemLoading, setItemLoading] = useState(false);
  // const [itemListView, setItemListView] = useState()

  const [itemList, setItemList] = useState([]);
  const [itemListMaster, setItemListMaster] = useState([])
  const [warehouseOwnerId, setWarehouseOwnerId] = useState([])

  const [movementModule, setMovementModule] = useState([])
  const [movementRefType, setMovementRefType] = useState([])
  const [isFetch, setIsFetch] = useState(false)

  const { getFieldDecorator, } = form;






  useEffect(() => {
    // console.log('useeffect customerSearch',customerSearch, isItemLoadMore)

    const getData = async () => {
      const indexStart = itemPage * itemPageSize + 1;
      const indexEnd = itemPage * itemPageSize + 10;

      const payload = {
        indexStart: indexStart,
        indexEnd: indexEnd,
        itemDetail: itemSearch,
      }

      const data = await getItemListLoadMore(payload);
      console.log('customerSearchx1', _.get(data, 'data.data.data.itemList'), data);

      const setItemListData = _.map(_.get(data, 'data.data.data.itemList'), (o) => {
        return setDataItem(o);
      });

      console.log('customerSearchx2', setItemListData);

      const items = itemSearch ? setItemListData : _.uniqBy(_.concat(itemList, setItemListData), 'itemMasterId');
      console.log('customerSearchx3', items);

      setItemList(items);
      setItemListMaster(items);
      setWarehouseOwnerId(_.get(data, 'data.data.data.warehouseOwnerId'))
      setItemLoading(false);
      setIsItemLoadMore(_.size(setItemListData) > 0 ? true : false);
    };
    if (isItemLoadMore) {
      getData();
    }

  }, [itemPage, itemSearch]);



  useEffect(() => {
    console.log('search', search)
    const getListDate = async () => {
      setLoading(true);
      const body = {
        "where": {
          "itemMasterDetail.item_master_id": _.get(search, 'item'),
          "warehouseId": {
            "$in": warehouseOwnerId
          },
          "$and": [
            {
              "$or": _.get(search, 'warehouse') ? [
                {
                  "warehouseCode": _.get(search, 'warehouse') ? {
                    "$regex": '.*' + _.get(search, 'warehouse') + '.*' 
                  } : undefined
                },
                {
                  "warehouseName": _.get(search, 'warehouse') ? {
                    "$regex": '.*' + _.get(search, 'warehouse') + '.*' 
                  } : undefined
                }
              ] : undefined,
            },
            {
              "transactionTypeCode": _.get(search, 'transactionTypeCode') ? _.get(search, 'transactionTypeCode') : undefined
            },
            {
              "lotNoOrSerialNo": _.get(search, 'lotNoOrSerialNo') ? {
                "$regex": '.*' + _.get(search, 'lotNoOrSerialNo') + '.*' 
              } : undefined
            },
            {
              "refTypeCode": _.get(search, 'refType') ? _.get(search, 'refType') : undefined
            },
            {
              "refNo": _.get(search, 'refNo') ? {
                "$regex": '.*' + _.get(search, 'refNo') + '.*' 
              } : undefined
            },

          ],
          "expiredDate": _.get(search, 'expiredDate') && _.size(search.expiredDate) ? {
            "startDate": moment(_.get(search, 'expiredDate[0]')).format('YYYY-MM-DD'),
            "endDate": moment(_.get(search, 'expiredDate[1]')).format('YYYY-MM-DD'),
          } : undefined,
          "actionDate": _.get(search, 'actionDate') && _.size(search.actionDate) ? {
            "startDate": moment(_.get(search, 'actionDate[0]')).format('YYYY-MM-DD'),
            "endDate": moment(_.get(search, 'actionDate[1]')).format('YYYY-MM-DD'),
          } : undefined,
        },
        "page": page,
        "pageSize": size,
        "orderBy": sortField,
        "order": sortOrder,
      };
      const response = await getReportItemMovement(body);
      console.log('response', response, _.get(response, 'data.initialData[0].detail.movementModule'), _.get(response, 'data.initialData[0].detail'),_.get(response, 'data.initialData[0]'))
      setListData(_.get(response, 'data.list') || []);
      setTotal(_.get(response, 'data.total'));
      // setRefTypeList(_.get(response, 'data.initialData'));
      setMovementModule(_.get(response, 'data.initialData[0].detail.movementModule') || [])
      setMovementRefType(_.get(response, 'data.initialData[0].detail.movementRefType') || [])
      setSummary(_.get(response, 'data.listSummary'));
      setLoading(false);
    };

    if(_.get(search, 'item')) {
      getListDate();
    }
  }, [page, size, sortOrder, sortField, isFetch, _.get(search, 'item')]);

  const setDataItem = (item) => {
    return {
      itemDetail: _.get(item, 'itemDetail'),
      itemMasterId: _.get(item, 'itemMasterId'),
    };
  };

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
    // setPage(1);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    try {
      const body = {
        "where": {
          "itemMasterDetail.item_master_id": _.get(search, 'item'),
          "warehouseId": {
            "$in": warehouseOwnerId
          },
          "$and": [
            {
              "$or": _.get(search, 'warehouse') ? [
                {
                  "warehouseCode": _.get(search, 'warehouse') ? {
                    "$regex": '.*' + _.get(search, 'warehouse') + '.*' 
                  } : undefined
                },
                {
                  "warehouseName": _.get(search, 'warehouse') ? {
                    "$regex": '.*' + _.get(search, 'warehouse') + '.*' 
                  } : undefined
                }
              ] : undefined,
            },
            {
              "transactionTypeCode": _.get(search, 'transactionTypeCode') ? _.get(search, 'transactionTypeCode') : undefined
            },
            {
              "lotNoOrSerialNo": _.get(search, 'lotNoOrSerialNo') ? {
                "$regex": '.*' + _.get(search, 'lotNoOrSerialNo') + '.*' 
              } : undefined
            },
            {
              "refTypeCode": _.get(search, 'refType') ? _.get(search, 'refType') : undefined
            },
            {
              "refNo": _.get(search, 'refNo') ? {
                "$regex": '.*' + _.get(search, 'refNo') + '.*' 
              } : undefined
            },
          ],
          "expiredDate": _.get(search, 'expiredDate') && _.size(search.expiredDate) ? {
            "startDate": moment(_.get(search, 'expiredDate[0]')).format('YYYY-MM-DD'),
            "endDate": moment(_.get(search, 'expiredDate[1]')).format('YYYY-MM-DD'),
          } : undefined,
          "actionDate": _.get(search, 'actionDate') && _.size(search.actionDate) ? {
            "startDate": moment(_.get(search, 'actionDate[0]')).format('YYYY-MM-DD'),
            "endDate": moment(_.get(search, 'actionDate[1]')).format('YYYY-MM-DD'),
          } : undefined,
        },
        "page": 1,
        "pageSize": total,
        "orderBy": sortField,
        "order": sortOrder,

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsWarehouseCode', defaultMessage: 'Warehouse Code' }),
            sequence: 1,
            colCode: 'warehouseCode',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsWarehouseName', defaultMessage: 'warehouseName' }),
            sequence: 2,
            colCode: 'warehouseName',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsType', defaultMessage: 'Type' }),
            sequence: 3,
            colCode: 'transactionTypeName',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsLotNoOrSerialNo', defaultMessage: 'Lot No. or Serial No.' }),
            sequence: 4,
            colCode: 'lotNoOrSerialNo',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsCost', defaultMessage: 'Cost' }),
            sequence: 5,
            colCode: 'cost',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsQty', defaultMessage: 'Qty' }),
            sequence: 6,
            colCode: 'qty',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsUnit', defaultMessage: 'Unit' }),
            sequence: 7,
            colCode: 'unitName',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsBalance', defaultMessage: 'Balance' }),
            sequence: 8,
            colCode: 'balance',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsExpiredDate', defaultMessage: 'ExpiredDate' }),
            sequence: 9,
            colCode: 'expiredDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsActionBy', defaultMessage: 'Action by' }),
            sequence: 10,
            colCode: 'actionByName',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsActionDate', defaultMessage: 'Action Date' }),
            sequence: 11,
            colCode: 'actionDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsVendorOrSupplier', defaultMessage: 'Vendor or Supplier' }),
            sequence: 12,
            colCode: 'vendorOrSupplier',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsRefType', defaultMessage: 'Ref Type' }),
            sequence: 13,
            colCode: 'refTypeName',
          },
          {
            colName: intl.formatMessage({ id: 'reportItemMovementColumnsRefNo', defaultMessage: 'Ref No.' }),
            sequence: 14,
            colCode: 'refNo',
          },
        ],
      };
      const response = await exportReportItemMovement(body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {}
  };

  const handleItemPopupScroll = (e) => {
    let target = e.target;
    if (!itemLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setItemLoading(true);
      setItemPage(itemPage + 1);
    }
  };

  const handleItemChange = (value) => {
    console.log('handleItemChange', value)
    if (!value) {
      // setItemSelect();
      // setItemListView([]);
    } else {
      onFilterOrder(value, 'item');
      setIsModal(false);
      setItemSearch('')
      setPage(1)
    }
  };

  const handleItemSearchChange = (value) => {
    console.log('handleCustomerSearchChange', value);
    setItemPage(0);
    setItemSearch(value);
    setIsItemLoadMore(true)
  };

  const setItemOption = (item) => {
    return (
      <Option key={`${item.itemMasterId}`} value={`${item.itemMasterId}`}>
        {item.itemDetail}
      </Option>
    );
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Card
        className="reportItemMoment"
        title={intl.formatMessage({ id: 'menuWarehouseItemMovement', defaultMessage: 'Item Movement Report' })}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
        extra={
          <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
            <Button01 key="submit" type="primary" btnsize="wd_df">
              <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
              <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
            </Button01>
          </Dropdown>
        }
      >
        <ReportItemMovementAction
          form={form}
          search={search}
          setSearch={setSearch}
          setPage={setPage}
          setIsFetch={setIsFetch}
          onFilterOrder={onFilterOrder}
          refTypeList={refTypeList}
          movementModule={movementModule}
          movementRefType={movementRefType}
          actionColumns={{
            columns,
            setShowColumn,
            newDataColumns,
            setNewDataColumns,
            setListArrayColumns,
            setDefaultShowColumn,
            listArrayColumns,
            defaultShowColumn,
            newColumns,
            textErrorSelectColumn,
            setTextErrorSelectColumn,
            visible,
            setVisible,
            columnMinimum,
          }}
          itemListSelect={{
            itemList,
            setItemOption,
            handleItemPopupScroll,
            handleItemChange,
            handleItemSearchChange,
            itemLoading
          }}
        />

        <Modal
          title={intl.formatMessage({ id: `modalItemMovementSelectItem`, defaultMessage: 'Select Item' })}
          centered={true}
          onCancel={() => setIsModal(false)}
          visible={isModal}
          bodyStyle={{ paddingTop: '12px', }}
          width={400}
          footer={[
            <Button_02 key="back" btnsize="wd_df" onClick={() => setIsModal(false)}>
              <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
            </Button_02>,
          ]}
        >
          <Form colon={false} className="deliveryForm">
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'itemMovementItem', defaultMessage: 'Item' })} req={true} />}
            >{getFieldDecorator('item', {
              initialValue:'',
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'reportItemSalesPSI', defaultMessage: 'Please Select Item' }),
                },
              ],
            })(
              <Select
                  placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefModel', defaultMessage: 'Select Item' })}
                  defaultActiveFirstOption={false}
                  showSearch
                  allowClear
                  onPopupScroll={handleItemPopupScroll}
                  // onSelect={handleItemChangeSelectForm}
                  onChange={handleItemChange}
                  onSearch={handleItemSearchChange}
                  loading={itemLoading}
                  filterOption={false}
                >
                  {itemList && itemList.map((item) => setItemOption(item))}
                </Select>
            )}

            </Form.Item>
          </Form>
        </Modal>

        <div style={{ padding: '16px 24px' }}>
          {_.get(search, 'item') && (
            <>
              <Table
                dataSource={listData}
                columns={newDataColumns}
                onChange={handleChange}
                loading={loading}
                scroll={{ x: true }}
                pagination={{
                  total: total,
                  showTotal: showTotal,
                  defaultCurrent: 1,
                  current: page,
                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                  showSizeChanger: true,
                  locale: { items_per_page: '' },
                }}
              />
              {_.size(listData) > 0 ? (
                <div className="total-items-member">
                  <span style={{ fontSize: '13px' }}>
                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                    {` ${total || 0} `}
                    <FormattedMessage id="lblitems" defaultMessage="items" />
                  </span>
                </div>
              ) : null}
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

const WarehouseReportItemMovementForm = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {
  }
})(WarehouseReportItemMovement);

export default WarehouseReportItemMovementForm;
