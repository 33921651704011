import httpClient from '../../components/axiosClient';

const getGasprice = async () => {
  try {
    const comCode = localStorage.getItem('comCode');
    const response = await httpClient.get(`/v1/travelexpense/manager/company/${comCode}/gas-price/get`);

    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    throw error; // Re-throw the error to be caught by the caller
  }
};

export default getGasprice;
