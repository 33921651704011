import React, { useState, useEffect } from 'react';
import { Select, Form, Button, Icon, notification, Alert, Spin } from 'antd';
import Button01 from '../../components/v2/button_01';
import CompanyApi from '../../components/httpClient';
import { Link } from 'react-router-dom';
import keycloak from '../../components/v2.1.0/keycloak/keycloak';
import httpClient from '../../components/axiosClient';
import { PageSettings } from '../../config/page-settings';
import _ from 'lodash';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

const qs = require('query-string');

let client = CompanyApi();

export default (props) => {
  // const { keycloak } = useKeycloak();
  // const { setTeam } = useContext(PageSettings);

  const keycloakUserId = _.get(keycloak, 'idTokenParsed.sub');
  const keycloakUsername = _.get(keycloak, 'idTokenParsed.preferred_username');
  const keycloakEmail = _.get(keycloak, 'idTokenParsed.email');
  const keycloakAccessToken = _.get(keycloak, 'token');

  const [loading, setLoading] = useState(true);

  const [tokenKey, setTokenKey] = useState([]);
  const [selectForm, setSelectForm] = useState([]);
  const [company, setCompany] = useState([]);
  const [comId, setComId] = useState();
  const [memComId, setMemComId] = useState();
  const [adminName, setAdminName] = useState();
  const [userAvatar, setUserAvatar] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [comCode, setComCode] = useState('');
  const [memId, setMemId] = useState('');

  // const comIddd = localStorage.getItem('comId');

  // get const จาก component อื่นมาใช้
  const userId = localStorage.getItem('userId');
  const name = localStorage.getItem('name');
  // const firstName = localStorage.getItem('firstName');
  // const lastName = localStorage.getItem('lastName');
  const email = localStorage.getItem('email');
  const expireInfo = localStorage.getItem('Expire');

  const [status1, setStatus1] = useState(false);
  const [status2, setStatus2] = useState(false);

  // set const ไปใช้ที่ component อื่น
  localStorage.setItem('companyId', selectForm[0]);
  localStorage.setItem('comName', selectForm[1]);
  localStorage.setItem('comId', comId);
  localStorage.setItem('memId', memId);
  localStorage.setItem('comCode', comCode);
  localStorage.setItem('memComId', memComId);
  localStorage.setItem('adminName', adminName);
  localStorage.setItem('userAvatar', userAvatar);
  localStorage.setItem('tokenKey', tokenKey);
  localStorage.setItem('expireInfo', expireInfo);

  // console.log('userId:::', userId);

  useEffect(() => {
    if (company.length == 0) {
      handleTokenCompany();
    }
  }, [keycloakUserId]);

  useEffect(() => {
    if (status1) {
      if (_.size(company) === 1) {
        setSelectForm([_.get(company[0], 'id'), _.get(company[0], 'name')]);
        setStatus2(true);
      } else {
        setLoading(false);
      }
    }
  }, [status1]);

  useEffect(() => {
    if (status2 && _.size(selectForm) !== 0 && _.size(company) === 1) {
      sendUserData();
      setTimeout(() => {
        window.location.href = '/main/dashboard';
      }, 500);
    } else {
    }
  }, [status2]);

  // useEffect(() => {
  //   if (tokenKey != '') {
  //     // handleGetCompany();
  //   }
  // }, [tokenKey]);

  const setFormDetail = async (tokenKey) => {
    const url = `${process.env.REACT_APP_URL_MANAGER}`;
    const port = `${process.env.REACT_APP_RESOURCE_MANAGER_PORT}`;
    httpClient.defaults.baseURL = `${url}:${port}`;

    const getUserProfile = await httpClient.get(`/v2/member/profile/keycloak/${keycloakUserId || userId}`, {
      headers: {
        Authorization: 'Bearer ' + tokenKey,
        // memcomid: memComId,
      },
    });

    if (getUserProfile.status === 200) {
      if (getUserProfile.data.companies) {
        // if (getUserProfile.data.companies.length <= 0) {
        //   notification['error']({
        //     message: 'Company not found',
        //     duration: 1.5,
        //   });
        //   setTimeout(() => {
        //     keycloak.logout({ redirectUri: `${window.location.origin}/main` });
        //   }, 1000);
        // }
      }

      let newCompany = [];
      for (let i = 0; i < getUserProfile.data.companies.length; i++) {
        newCompany.push({
          id: getUserProfile.data.companies[i].keycloak_groups_id,
          name: getUserProfile.data.companies[i].name,
        });
      }

      setCompany(newCompany);
      setFirstName(getUserProfile.data.member.name);
      setLastName(getUserProfile.data.member.lastname);

      localStorage.setItem('userId', keycloakUserId);
      localStorage.setItem('username', keycloakUsername);
      localStorage.setItem('email', keycloakEmail);
      localStorage.setItem('accessToken', keycloakAccessToken);
      localStorage.setItem('name', `${getUserProfile.data.member.name} ${getUserProfile.data.member.lastname}`);
      localStorage.setItem('firstName', getUserProfile.data.member.name);
      localStorage.setItem('lastName', getUserProfile.data.member.lastname);
      setTokenKey(tokenKey);
      setStatus1(true);
    } else {
      alert('Users Not Found !');
      localStorage.setItem('comId', 'undefined');
      localStorage.setItem('comCode', 'undefined');
      localStorage.setItem('companyId', 'undefined');
      keycloak.logout({ redirectUri: `${window.location.origin}/main` });
    }
  };

  // const Lang = async () => {
  //   const res = await GetLang({ companyId: '0', lang: 'EN', pageCode: '000' });
  //   setSwitchLang(res);
  //   // setSwitchLang
  // };

  const errorNotification = (type) => {
    notification[type]({
      message: 'Username or Password Incorrect',
      duration: 1.5,
    });
  };

  // ส่วนของการใช้ keycloak โดยการสร้างตัวแปร object ขึ้นมาเพื่อรับค่าจาก api แล้วเอา token ทีไ่ด้ ไป get company
  const handleTokenCompany = async () => {
    const data = {
      client_id: 'admin-cli',
      grant_type: 'password',
      username: 'admin',
      password: '#Admin123',
    };

    const axiosConfig = {
      Header: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    try {
      client.defaults.baseURL = `${process.env.REACT_APP_KEYCLOAK_URL}`;
      const res = await client.post(
        `/auth/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
        qs.stringify(data),
        axiosConfig
      );

      await setFormDetail(res.data.access_token);
    } catch (error) {}
  };

  // const handleGetCompany = async (id) => {
  //   try {
  //     client.defaults.baseURL = `${process.env.REACT_APP_KEYCLOAK_URL}`;
  //     client.defaults.headers = {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       Authorization: `Bearer ${tokenKey}`,
  //     };

  //     const user1 = id;
  //     const res = await client.get(
  //       `/auth/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${keycloakUserId}/groups`,
  //     );

  //     let newCompany = [];
  //     for (let i = 0; i < res.data.length; i++) {
  //       newCompany.push(res.data[i]);
  //     }

  //     if (newCompany.length === 0) {
  //       setTimeout(() => {
  //         localStorage.setItem('comId', 'undefined');
  //         localStorage.setItem('comCode', 'undefined');
  //         localStorage.setItem('companyId', 'undefined');
  //         keycloak.logout({ redirectUri: `${window.location.origin}/main` })
  //       }, 5000);
  //     }
  //     setCompany(newCompany);

  //   } catch (error) {

  //   }
  // };

  const getUserData = async (data = {}) => {
    const bodyData = {
      user_id: userId,
      user_name: name,
      email: email,
      company_id: selectForm[0],
      company_name: selectForm[1],
    };

    await httpClient
      .post(`/v2/login`, bodyData, {
        headers: {
          Authorization: 'Bearer ' + tokenKey,
          memcomid: memComId,
        },
      })
      .then((respone) => {
        if (respone.status === 200) {
          const cookieData = {
            comId: respone.data[0].com_id,
            memComId: respone.data[0].mem_com_id,
            name: respone.data[0].name + ' ' + respone.data[0].lastname,
            profileImg: respone.data[0].profile_img,
            exp: localStorage.getItem('Expire'),
          };

          localStorage.setItem('langValue', respone.data[0].language.toUpperCase());
          setComId(respone.data[0].com_id);
          setComCode(respone.data[0].loginCompany.com_code);
          setMemId(respone.data[0].mem_id);
          setMemComId(respone.data[0].mem_com_id);
          setAdminName(respone.data[0].name + ' ' + respone.data[0].lastname);
          setUserAvatar(respone.data[0].profile_img);
        } else {
          console.error();
        }
      })
      .catch(console.error());
  };

  const sendUserData = async () => {
    let data = {
      user_id: userId,
      company_id: selectForm,
    };
    getUserData(data);
  };

  const handleClick = () => {
    sendUserData();
    if (!selectForm) {
      errorNotification('error');
    } else {
      setTimeout(() => {
        window.location.href = '/main/dashboard';
      }, 500);
    }
  };

  const clearUserId = () => {
    localStorage.setItem('comId', 'undefined');
    localStorage.setItem('comCode', 'undefined');
    localStorage.setItem('companyId', 'undefined');
    keycloak.logout({ redirectUri: `${window.location.origin}/main` });
  };

  //  ค่าในช่อง select
  const handleSelectChange = (selected) => {
    setSelectForm(selected);
  };

  // select company เอาข้อมูล company ที่ได้จากการล็อคอิน user มาโชว์ให้เลือก
  let optionItems = company.map((item, index) => (
    <option key={item.id} value={[item.id, item.name]}>
      {item.name}
    </option>
  ));

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  return (
    <div className="company">
      <Spin indicator={antIcon} spinning={loading}>
        <React.Fragment>
          <Form.Item>
            <div>
              <Icon type="user" className="icon-style" /> <span className="username-style"> {firstName + ' ' + lastName} </span>{' '}
              <span className="icon-lock">
                <Icon type="lock" />
              </span>
            </div>
          </Form.Item>

          <Form className="form-item">
            <div className="input-label">COMPANY</div>

            <Select
              key={selectForm[1]}
              placeholder="Company"
              value={selectForm[1]}
              onChange={handleSelectChange}
              className="selection-style"
            >
              {optionItems}
            </Select>

            <Button disabled={!selectForm[0]} className="login-form-button" onClick={handleClick}>
              Login
            </Button>
          </Form>
          <Form.Item>
            <div className="remember-forgot-row">
              <Link to="/loginform" onClick={clearUserId} className="login-form-forgot ">
                {' '}
                Change Account{' '}
              </Link>
            </div>
          </Form.Item>
        </React.Fragment>
      </Spin>
    </div>
  );
};
