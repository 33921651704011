import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import _ from 'lodash';

export default (props) => {
  // Theme
  const theme = {
    space: {
      sm: '8px',
      md: '16px',
    },

    btnfontsize: {
      sm: '13px',
      md: '14px',
      lg: '16px',
    },
    btnwidth: {
      wd_df: '96px',
      wd_md: '50%',
      wd_lg: '100%',
      wd_at: 'auto',
      wd_px: '100px',
      wd_ct: '150px',
      wd_pt: '200px',
    },
    marginStyle: {
      mg_df: '0px 10px 0px 10px',
      mg_md: '0px 8px 0px 8px',
      mg_cs1: '0px 5px 0px 10px',
      mg_cs2: '0px 10px 0px 5px',
      mg_cs3: '0px 0px 0px 10px',
    },
  };

  const Buttons = styled(Button)`
    background-color: ${(props) => (_.isString(props.type) ? '#6490cf' : '#ffffff')};
    color: ${(props) => (_.isString(props.type) ? '#ffffff' : '#6490cf')};
    width: ${props.btnsize ? theme.btnwidth[props.btnsize] : theme.btnwidth.wd_df};
    font-size: ${props.fontSize ? theme.btnfontsize[props.fontSize] : theme.btnfontsize.md};
    margin: ${props.stylemargin ? theme.marginStyle[props.stylemargin] : theme.marginStyle.mg_df};
    height: 32px;
    padding: 0.25em 1em;
    border: 1px solid #6490cf;
    border-radius: 8px;
    :hover {
      background: #ffffff;
      color: #6490cf;
    }
    :focus {
      background: #6490cf;
      color: #ffffff;
      border: 1px solid #6490cf;
    }
  `;

  return <Buttons {...props}>{props.children}</Buttons>;
};
