import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import './index.css'
import ListItemFilter from './filter'
import ListItemDetail from './item'
import { useIntl, FormattedMessage } from 'react-intl'
import Button01 from '../../../../components/v2/button_01'
import _ from 'lodash'
import { searchPoExpense } from '../../../../controllers/expense/expense'
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency'
import moment from 'moment'

const ListItem = (props) => {
  const { visible, setVisible, setPOSelect, vendorData, form } = props
  const { getFieldValue } = form
  const intl = useIntl()
  const [searchGroup, setSearchGroup] = useState({});
  const [itemList, setItemList] = useState([])
  const [listPage, setListPage] = useState(1)
  const [listSize, setListSize] = useState(10)
  const [itemListTotal, setItemListTotal] = useState(0)
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [tableLoading, setTableLoading] = useState(false)
  const [trigger, setTrigger] = useState(false)

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'listPOColumnsPONo', defaultMessage: 'PO No.' }),
      dataIndex: 'poNo',
      key: 'poNo',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'listPOColumnsTotalPrice', defaultMessage: 'Total Price' }),
      dataIndex: 'subtotal',
      key: 'subtotal',
      sorter: true,
      render: (text, record, index) => numberFormatter(_.get(record, 'subtotal') || 0) + ' THB'
    },
    {
      title: intl.formatMessage({ id: 'listPOColumnsPaid', defaultMessage: 'Paid' }),
      dataIndex: 'totolPaid',
      key: 'totolPaid',
      sorter: true,
      render: (text, record, index) => numberFormatter(_.get(record, 'totolPaid') || 0) + ' THB'
    },
    {
      title: intl.formatMessage({ id: 'listPOColumnsAccurede', defaultMessage: 'Accrued Expense' }),
      dataIndex: 'totolAccruedExpense',
      key: 'totolAccruedExpense',
      sorter: true,
      render: (text, record, index) => numberFormatter(_.get(record, 'totolAccruedExpense') || 0) + ' THB'
    },
    {
      title: intl.formatMessage({ id: 'listPOColumnsDate', defaultMessage: 'PO Date' }),
      dataIndex: 'poDate',
      key: 'poDate',
      sorter: true,
      render: (text, record, index) => moment(_.get(record, 'poDate'), 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
  ];

  useEffect(() => {
    if (getFieldValue('vendor') && visible) {
      setSearchGroup({ ...searchGroup, ['vendor']: getFieldValue('vendor') });
      setTrigger(cur => !cur)
    }
  }, [getFieldValue('vendor'), visible])

  useEffect(() => {
    if (visible) {
      // if (getFieldValue('vendor')) onChange(getFieldValue('vendor'), 'vendor')
      GetSearchListData()
    }
  }, [listSize, listPage, trigger])
  console.log("searchGroupPO", searchGroup)


  const GetSearchListData = async () => {
    setTableLoading(true)
    const body = {
      "page": listPage,
      "limit": listSize,
      "statusCode": [],
      "statusGroup": "approved",
      "poNo": _.get(searchGroup, 'poNo') || "",
      "createdDateFrom": _.get(searchGroup, 'fromDate') ? moment(_.get(searchGroup, 'fromDate')).format('YYYY-MM-DD') : "",
      "createdDateTo": _.get(searchGroup, 'toDate') ? moment(_.get(searchGroup, 'toDate')).format('YYYY-MM-DD') : "",
      "vendorSupplierId": [_.get(searchGroup, 'vendor')] || [],
      "orderBy": fieldSort,
      "orderType": orderSort
    }
    const reesponse = await searchPoExpense(body)
    setItemList(_.get(reesponse, 'data.data.poList'))
    setItemListTotal(_.get(reesponse, 'data.data.totalItem'))
    setTableLoading(false)
  }


  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setListSize(pagination.pageSize);
    setListPage(pagination.current);
  };


  const handleClose = () => {
    setVisible(false)
    setSearchGroup({})
    setItemList([])
    setItemListTotal(0)
    setListPage(1);
    setListSize(10);
  }

  const handleClickRow = (data) => {
    setPOSelect(data)
    setVisible(false)
    setSearchGroup({})
    setItemList([])
    setItemListTotal(0)
  }

  const onChange = async (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `listPOTitleText`, defaultMessage: 'Select Purchase Order' })}
      visible={visible}
      onCancel={handleClose}
      centered={true}
      width={1112}
      bodyStyle={{ padding: 'unset', height: '680px' }}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className='listItemTextHeader'>
            <FormattedMessage id="btnFilter" defaultMessage="Filter" />
          </div>
          <div className='listItemDividerCenter'>
            <ListItemFilter
              onChange={onChange}
              searchGroup={searchGroup}
              setTrigger={setTrigger}
            />
          </div>
        </Col>
        <Col span={18}>
          <div className='listItemTextHeader'>
            <FormattedMessage id="listPOTitle" defaultMessage="Purchase Order" />
          </div>
          <div>
            <ListItemDetail
              onChange={onChange}
              columns={columns}
              handleClickRow={handleClickRow}
              vendorData={vendorData}
              itemList={itemList}
              itemListTotal={itemListTotal}
              handleChange={handleChange}
              form={form}
              tableLoading={tableLoading}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default ListItem
