import { Button, Card, Dropdown, Menu, Popover, Table } from 'antd';
import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash';

const TableTransporationSetting = (props) => {
  const { columns, listData, tableChange, page, setTableChange, contentAction, onRow, listTotal, popupPermisson } = props;
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setTableChange((prev) => ({ ...prev, sortOrder: 'desc' }));
      } else {
        setTableChange((prev) => ({ ...prev, sortOrder: 'asc' }));
      }
    } else {
      setTableChange((prev) => ({ ...prev, sortOrder: '' }));
    }
    setTableChange((prev) => ({ ...prev, sortField: field, page: pagination.current, size: pagination.pageSize }));
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div >
        <Popover
          placement="right"
          trigger="click"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={1}
          content={contentAction ? contentAction(record) : null}
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div style={{ padding: '16px 24px' }} onMouseLeave={handleMouseLeave}>
      <Table
        dataSource={listData}
        columns={columns}
        onChange={handleChange}
        loading={_.get(tableChange, 'loading')}
        scroll={{ x: _.sumBy(columns, 'width') }}
        pagination={{
          total: listTotal,
          showTotal: showTotal,
          defaultCurrent: 1,
          current: _.get(tableChange, 'page'),
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
        }}
        onRow={
          onRow
            ? (record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href && popupPermisson) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                },
              };
            }
            : onRow
        }
      />
      {popupPermisson ? fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref) : null}
      {_.size(listData) > 0 ? (
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${listTotal || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      ) : null}

    </div>
  );
};

export default TableTransporationSetting;
