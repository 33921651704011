import React,{useEffect,useState} from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import HttpClient from '../../../components/httpClient';
import { Icon, Row, Col, Select, List, Dropdown, Menu, Checkbox, Button, Popover } from 'antd';
import Button01 from '../../../components/v2/button_01'
import Input from '../../../components/v2/input';
import Modalmember from './modalMember';
import _ from 'lodash';

let client = HttpClient();
const { Option } = Select;

export default (props) => {
  const intl = useIntl();
  const { setRefreshTable } = props;
  const columns = props.columns;

  const ignoreColumn = ['index', 'options', 'profile'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [checkStatus, setCheckStatus] = useState(false)

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={props.checkedValuecolumns}
                checked={props.checked[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.select}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {props.textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={props.handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="orgTabMemberBtnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={props.handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="orgTabMemberBtnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div>
      <Row>
        <Col span={12} style={{ margin: 0 }}>
          <Row>
            <List.Item>
              <Input
                onChange={(e) => props.onChange(e.target.value)}
                placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search...' })}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                defaultValue={props.inputDefaultValue}
                autoComplete="off"
              />
              <Select
                className="member-select"
                onSelect={(value) => props.onSelect(value)}
                value={props.selectSearchData}
              >
                <Option className="member-select-option" value="">
                  <FormattedMessage id="orgTabMemberTxtAllColumns" defaultMessage="All columns" />
                </Option>
                {columnNew.map((item, i) => (
                  <Option key={i} value={item.key}>
                    {item.select}
                  </Option>
                ))}
              </Select>
            </List.Item>
          </Row>
        </Col>

        <Col span={12} style={{ margin: 0, paddingRight: 24 }}>
          <Row>
            <List.Item style={{ float: 'right' }} >
              <Dropdown overlay={menuColumn} trigger={['click']} onVisibleChange={props.handleVisibleChange} visible={props.visible}>
                <Button01 fontsize="sm" type="primary" onClick={props.handleOnVisiblecolumns}>
                  <FormattedMessage id="orgTabMemberBtnColumns" defaultMessage="Columns" />{" "}
                  <Icon type="down" />
                </Button01>
              </Dropdown>

              <Modalmember 
                dataMembers={props.dataMembers} 
                orgId={props.orgId} 
                setRefreshTable={setRefreshTable}
                // setrefreshDataMemBer={props.setrefreshDataMemBer}
                // refreshDataMemBer={props.refreshDataMemBer} 
              />
            </List.Item>
          </Row>
        </Col>
      </Row>
    </div>
  )
};
