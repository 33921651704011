import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Input, Select, Form } from 'antd';
import { MaintenanceContext } from './maintenance-context';
import _, { debounce } from 'lodash';
import NumberFormat from 'react-number-format';
import CurrencyInput from 'react-currency-input-field';

const { TextArea } = Input;
const { Option } = Select;

export default function MaintenanceCalculate(props) {
  const { sumSubtotalParts, sumSubtotalLabors } = props;
  const { mainState, setMainState } = useContext(MaintenanceContext);

  const intl = useIntl();
  const [valueLessLaborPart, setValueLessLaborPart] = useState(0.00)
  const [valueDiscount, setValueDiscount] = useState(0.00);
  const [valueLessDiscount, setValueLessDiscount] = useState(0.00);
  const [valueTax, setValueTax] = useState(0.00);
  const [valueSubtotalLess, setValueSubtotalLess] = useState(0.00)
  // const [valueAdjust, setValueAdjust] = useState(0.00);
  const [valueTotal, setValueTotal] = useState(0.00);



  useEffect(() => {
    fncCalculate(sumSubtotalParts, sumSubtotalLabors, mainState.discountSelete, mainState.taxSelete);
  }, [sumSubtotalParts, sumSubtotalLabors, mainState.discountSelete, mainState.taxSelete, mainState.valueInputDiscount, mainState.valueInputAdjust]);

  const fncCalculate = (subTotalParts, subTotalLabors, discount, tax) => {

    let totalParts = subTotalParts !== NaN ? subTotalParts : 0;
    let totalLabors = subTotalLabors !== NaN ? subTotalLabors : 0;

    let sumPL = parseFloat(totalParts) + parseFloat(totalLabors);


    let resultDiscount = ""
    if (_.get(discount, 'titleCode') !== 'Baht') {
      resultDiscount = _.floor((parseFloat(sumPL) * parseFloat(_.get(discount, 'percentage'))) / 100, 2);
    } else {
      resultDiscount = parseFloat(mainState.valueInputDiscount);
    }


    const resultLessLaborAndPart = parseFloat(sumPL)
    const resultLessDiscount = parseFloat(sumPL) - parseFloat(resultDiscount);
    const resultTax = _.floor((parseFloat(resultLessDiscount) * parseFloat(_.get(tax, 'percentage'))) / 100, 2);
    const resultTaxLD = resultLessDiscount + resultTax;
    const resultSubtotal = resultTaxLD
    const resultTotal = resultTaxLD + parseFloat(mainState.valueInputAdjust);

    setValueLessLaborPart(parseFloat(resultLessLaborAndPart).toFixed(2))
    setValueDiscount(parseFloat(resultDiscount).toFixed(2));
    setValueLessDiscount(parseFloat(resultLessDiscount).toFixed(2));
    setValueTax(parseFloat(resultTax).toFixed(2));
    setValueSubtotalLess(parseFloat(resultSubtotal).toFixed(2))
    setValueTotal(resultTotal);
    setMainState.setSumCalculate({
      discount: parseFloat(resultDiscount).toFixed(2),
      lessDiscount: parseFloat(resultLessDiscount).toFixed(2),
      tax: parseFloat(resultTax).toFixed(2),
      total: parseFloat(resultTotal).toFixed(2),
    });
  }

  const onChangeDiscount = (value) => {
    const result = mainState.taxDiscountData.discount.filter((item) => {
      return item.discountId === value
    });
    setMainState.setDiscountSelete(result[0]);
    setMainState.setValueInputDiscount(0.00);
  }

  const onChangeTax = (value) => {
    const result = mainState.taxDiscountData.tax.filter((item) => {
      return item.taxId === value
    });
    setMainState.setTaxSelete(result[0]);
  }

  const onChangeInputDiscount = (value) => {
    let sumPL = parseFloat(sumSubtotalParts) + parseFloat(sumSubtotalLabors);
    if (value === undefined) {
      setMainState.setValueInputDiscount(0.00);
    } else {
      if (sumPL <= value) {
        setMainState.setValueInputDiscount(0.00);
      } else {
        setMainState.setValueInputDiscount(value);
      }
    }
  }

  const onChangeInputAdjust = (value) => {
    if (value === undefined) {
      setMainState.setValueInputAdjust(0.00);
    } else {
      setMainState.setValueInputAdjust(value);
    }
  }

  const handleChangeTextArea = (e) => {
    setMainState.setValueInputRemark(e.target.value);
  }

  const onKeyDownCurrency = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if (keyCode === 45) {
      e.preventDefault();
    }
  }

  return (
    <div className="calculate">
      <div className="calculate-container">
        <Row className="calculate-container-row">
          <Col span={12} className="calculate-container-col-left">
            <div className="calculate-container-col-div">
              <div className="calculate-col-div-div">
                <span className="calculate-text">
                  <FormattedMessage id="maintenanceModalTexRemark" defaultMessage="Remark" />
                </span>
              </div>
              <TextArea
                className="calculate-textArea"
                style={{ borderRadius: '4px' }}
                placeholder={intl.formatMessage({ id: 'maintenanceModalTextAreaPlaceholderl', defaultMessage: 'Enter Remark' })}
                maxLength={250}
                onChange={handleChangeTextArea}
                value={mainState.valueInputRemark}
                autoSize={{ minRows: 10, maxRows: 10 }}
              />
            </div>
          </Col>
          <Col span={12} className="calculate-container-col-right">
            <div className="calculate-container-col-div">

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalTexPartCharge" defaultMessage="Part Charge" />
                  </span>
                </div>
                <div className="maintenance-calculate-money-right">
                  <NumberFormat
                    value={_.floor(sumSubtotalParts, 2).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                  />
                </div>
              </div>

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalTexLaborCharge" defaultMessage="Labor Charge" />
                  </span>
                </div>
                <div className="maintenance-calculate-money-right">
                  <NumberFormat
                    value={_.floor(sumSubtotalLabors, 2).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                  />
                </div>
              </div>

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalSubtotalLaborAndPart" defaultMessage="Subtotal Labor And Part Charge" />
                  </span>
                </div>
                <div className="maintenance-calculate-money-right">
                  <NumberFormat
                    value={_.floor(valueLessLaborPart, 2).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                  />
                </div>
              </div>

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalTexDiscount" defaultMessage="Discount" />
                  </span>
                  <Select
                    className="maintenance-calculate-discount-select"
                    placeholder="Discount"
                    value={_.get(mainState, 'discountSelete.discountId')}
                    onChange={onChangeDiscount}
                    style={{ marginTop: '4px' }}
                  >
                    {mainState.taxDiscountData && mainState.taxDiscountData.discount.map(item => (
                      <Option key={item.discountId} value={item.discountId}>{item.titleCode}</Option>
                    ))}
                  </Select>
                </div>
                <div className="maintenance-calculate-money-right">
                  {_.get(mainState, 'discountSelete.isBath') !== true ? (
                    <NumberFormat
                      value={_.floor(valueDiscount, 2).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                    />
                  ) : (
                    <div className="maintenance-calculate-discount-div">
                      <CurrencyInput
                        className="maintenance-currencyInput"
                        value={mainState.valueInputDiscount}
                        allowDecimals={true}
                        decimalsLimit={2}
                        decimalScale={2}
                        groupSeparator=","
                        onValueChange={(value) => onChangeInputDiscount(value)}
                        onKeyDown={onKeyDownCurrency}
                      />
                      {" "}
                      <FormattedMessage id="maintenanceModalTexAdjustBaht" defaultMessage="Baht" />
                    </div>
                  )}

                </div>
              </div>

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalTexSubtotalLessDiscount" defaultMessage="Subtotal Less Discount" />
                  </span>
                </div>
                <div className="maintenance-calculate-money-right">
                  <NumberFormat
                    value={_.floor(valueLessDiscount, 2).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                  />
                </div>
              </div>

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalTax" defaultMessage="Tax" />
                  </span>
                  <Select
                    className="maintenance-calculate-discount-select"
                    placeholder="Tax"
                    value={_.get(mainState, 'taxSelete.taxId')}
                    onChange={onChangeTax}
                    style={{ marginTop: '4px' }}
                  >
                    {mainState.taxDiscountData && mainState.taxDiscountData.tax.map(item => (
                      <Option key={item.taxId} value={item.taxId}>{item.titleCode}</Option>
                    ))}
                  </Select>
                </div>
                <div className="maintenance-calculate-money-right">
                  <NumberFormat
                    value={_.floor(valueTax, 2).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                  />
                </div>
              </div>

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalSubtotal" defaultMessage="Subtotal" />
                  </span>
                </div>
                <div className="maintenance-calculate-money-right">
                  <NumberFormat
                    value={_.floor(valueSubtotalLess, 2).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                  />
                </div>
              </div>

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalTexAdjustCharge" defaultMessage="Adjust Charge" />
                  </span>
                </div>
                <div className="maintenance-calculate-money-right">
                  <CurrencyInput
                    className="maintenance-currencyInput"
                    value={mainState.valueInputAdjust}
                    allowDecimals={true}
                    decimalsLimit={2}
                    decimalScale={2}
                    groupSeparator=","
                    onValueChange={(value) => onChangeInputAdjust(value)}
                  />
                  {" "}
                  <FormattedMessage id="maintenanceModalTexAdjustBaht" defaultMessage="Baht" />
                </div>
              </div>

              <div className="maintenance-calculate-money">
                <div className="maintenance-calculate-money-left">
                  <span className="maintenance-calculate-text">
                    <FormattedMessage id="maintenanceModalTexTotal" defaultMessage="Total" />
                  </span>
                </div>
                <div className="maintenance-calculate-money-right">
                  <NumberFormat
                    value={_.floor(valueTotal, 2).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })}
                  />
                </div>
              </div>

            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
