import httpClient from '../../components/axiosClient';

const getSkill = async () => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/skill-group`);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }

}

export default getSkill;