import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Form, Input, Select} from 'antd';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import { successNotification, errorNotification } from '../v2/notification';
import _, { debounce } from 'lodash';
import './css/index.css';
import httpClient from '../axiosClient';
import getSearchLocation from '../../function/search-location/getLocation';
import Map from './map-location';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const ModalMapLocation = React.memo((props) => {
  const intl = useIntl();
  const {
    visible,
    form,
    setVisible,
    customerId,
    setTrigger,
    triggerFNC,
    toLocation,
    filterToLocation,
    fncUpdateAddressBook,
    editData,
    setEditData,
  } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const comCode = localStorage.getItem('comCode');
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);
  const [addressArea, setAddressArea] = useState([]);
  const [latlngTo, setLatlngTo] = useState({});
  const [valueAddress, setValueAddress] = useState('');
  const [address, setAddress] = useState('');
  const [autoComplteLocation, setAutoComplteLocation] = useState();
  const [autoComplteValue, setAutoComplteValue] = useState();
  

  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await getSearchLocation({ name: fieldChang });
    setAddressArea(newData);
  }, 200);

  const handleSelect = (key, option) => {
    if (isNaN(key) == false) {
      form.setFieldsValue({ ['location']: option.props.children });
      form.setFieldsValue({ ['description']: option.props.children });
      setAutoComplteLocation(option.props.children);
      setAutoComplteValue(option.props.children);
      setPosition({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setCenter({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setLatlngTo({ lat: addressArea[key].lat, lng: addressArea[key].lng });
    } else {
      setPosition(latlng);
      setCenter(latlng);
      form.resetFields();
      form.setFieldsValue({
        ['location']: undefined,
      });
      setAutoComplteLocation();
      setAutoComplteValue();
    }
  };

  useMemo(() => {
    form.setFieldsValue({
      ['location']: valueAddress,
    });
    form.setFieldsValue({
      ['description']: valueAddress,
    });
    setAutoComplteValue(valueAddress);
    setAddress(valueAddress);
  }, [valueAddress]);

  useEffect(() => {
    if (visible) {
      setAutoComplteValue(_.get(editData, 'mapLocation'));
      setAddress(_.get(editData, 'fullAdress'));
      setPosition({ lat: parseFloat(_.get(editData, 'lat')), lng: parseFloat(_.get(editData, 'lng')) });
      setCenter({ lat: parseFloat(_.get(editData, 'lat')), lng: parseFloat(_.get(editData, 'lng')) });
      setLatlngTo({ lat: parseFloat(_.get(editData, 'lat')), lng: parseFloat(_.get(editData, 'lng')) });
    }
  }, [editData, visible]);

  const children = addressArea.map((item) => <Option key={item.key}>{item.address}</Option>);

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const body = {
        addressName: values.locationName,
        fullAddress: autoComplteValue,
        address: values.description,
        lat: latlngTo.lat,
        lng: latlngTo.lng,
        subDistrict: '',
        province: '',
        postCode: '',
        description: values.description,
      };

      try {
        const response = await httpClient.put(
          `/v3/business-partner/manager/company/${comCode}/update/address-book/${editData.companyAddressBookId}`,
          body
        );

        if (response.status == 200) {
          resetFields();
          setVisible(false);
          setLatlngTo({});
          setValueAddress('');
          setAutoComplteLocation();
          setAutoComplteValue();
          if (setTrigger) {
            setTrigger((current) => !current);
          }
          if (triggerFNC) {
            triggerFNC(customerId);
          }
          setTimeout(() => {
            if (toLocation) {
              toLocation(response.data.data);
            }
            if (filterToLocation) {
              filterToLocation(response.data.data);
            }
            if (fncUpdateAddressBook) {
              fncUpdateAddressBook(customerId, response);
            }
          }, 300);

          successNotification(response.data.status.message);
        } else {
          resetFields();
          setVisible(false);
          setLatlngTo({});
          setValueAddress('');
        }
      } catch (error) {
        resetFields();
        setVisible(false);
        setLatlngTo({});
        setValueAddress('');
        errorNotification(error.response.data.status.message);
      }
    });
  };

  const handleCreateCancel = () => {
    resetFields();
    if (setEditData) {
      setEditData();
    }
    setVisible(false);
    setLatlngTo({});
    // setValueAddress("");
    setAutoComplteLocation();
    setAutoComplteValue();
  };

  return (
    <Modal
      className="create-task-modal-from"
      bodyStyle={{ height: 'calc(100vh - 230px)', maxHeight: '605px', overflow: 'auto', overflowX: 'hidden' }}
      title={intl.formatMessage({ id: 'ModalLocationHeaderCreateFromLocation', defaultMessage: 'Select From Location' })}
      visible={visible}
      onCancel={handleCreateCancel}
      onOk={handleCreate}
      centered
      width={824}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCreateCancel} margin="unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <p>
          <FormattedMessage id="ModalLocationLocationName" defaultMessage="Location Name" />
          <LabeRequire req={true} />
        </p>
        <Form.Item>
          {getFieldDecorator('locationName', {
            initialValue: editData ? _.get(editData, 'addressName') : undefined,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationValidateLocationName', defaultMessage: 'Please enter location name' }),
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderLocationName', defaultMessage: 'Enter Location Name' })}
              autoComplete="off"
            />
          )}
        </Form.Item>

        <p>
          <FormattedMessage id="ModalLocationLocationAddress" defaultMessage="Address" />
          <LabeRequire req={true} />
        </p>
        <Form.Item>
          {getFieldDecorator('description', {
            initialValue: address ? address : undefined,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationLocationValidateAddress', defaultMessage: 'Please enter address' }),
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'ModalLocationLocationPlaceholderAddress', defaultMessage: 'Enter Address' })}
              autoComplete="off"
            ></Input>
          )}
        </Form.Item>
        <div>
          <Map
            idMap={1}
            mapModal={1}
            position={position}
            center={center}
            setPosition={setPosition}
            setCenter={setCenter}
            setLatlngTo={setLatlngTo}
            setValueAddress={setValueAddress}
            addressArea={addressArea}
            handleSearchLocation={handleSearchLocation}
            handleSelect={handleSelect}
            children={children}
            autoComplteLocation={autoComplteLocation}
            setAutoComplteLocation={setAutoComplteLocation}
          />
        </div>
      </Form>
    </Modal>
  );
});

const ModalMap = Form.create({
  name: 'modal_form',
})(ModalMapLocation);

export default React.memo(ModalMap);
