import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon } from 'antd';
import './css/crad-default.css';


export default function CradDefault(props) {
  const { description, fncImage } = props;

  return (
    <div className="crad-default">
      <Card className="crad-default-card-v3">
        <div className="crad-default-card-body">
          <div className='crad-default-card-file'>
            {fncImage()}
          </div>
          <div className='crad-default-card-text'>
          <p className="crad-default-card-v3-header">
            <FormattedMessage id={description.text1} defaultMessage={description.message1} />
          </p>
          <p className="crad-default-card-v3-title">
            <FormattedMessage id={description.text2} defaultMessage={description.message2} />
          </p>
          </div>
        </div>
      </Card>
    </div>
  )
}
