import React, { useContext, useState, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button01 from '../../../../../components/v2/button_01'
import { Divider, Table, Tag, Button, Popover } from 'antd'
import { DeliveryDetailContext } from '..'
import _ from 'lodash'
import { PageSettings } from '../../../../../config/page-settings'

const DeliveryManager = () => {
  const { checkPermissionAction } = useContext(PageSettings)
  const { handleOpenModalManager, manageList, handleDeleteManager, handleDeleteUserOrRole } = useContext(DeliveryDetailContext)
  const intl = useIntl()
  const ref = useRef()
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: `deliveryManagerColumnsUser`, defaultMessage: 'User or Role' }),
      dataIndex: 'user',
      key: 'user',
      width: '50%',
      render: (text, record, index) => {
        const mapRole = _.map(record.roleIds, (item) => {
          return {
            id: item.roleId,
            name: item.roleName,
            type: 'role'
          }
        })
        const mapUser = _.map(record.userIds, (item) => {
          return {
            id: item.userId,
            name: item.userName,
            type: 'user'
          }
        })
        const concatData = mapRole.concat(mapUser)
        return (
          <div>
            {_.map(concatData, (item) => (
              <Tag
                style={{ marginTop: '8px', border: '1px solid #1D3557', color: '#1D3557' }}
                closable={checkPermissionAction('P58PG1C2', 'P58PG1C2A3') || checkPermissionAction('P58PG1C2', 'P58PG1C2A4') ? true : false}
                onClose={() => handleDeleteUserOrRole(record, item)}
              >
                {item.name}
              </Tag>
            ))}
          </div>
        )
      }
    },
    {
      title: intl.formatMessage({ id: `deliveryManagerColumnsManager`, defaultMessage: 'Manager or Approve' }),
      dataIndex: 'manager',
      key: 'manager',
      width: '45%',
      render: (text, record, index) => {
        const mapBranch = _.map(record.branchManagerCode, (item) => { return item.name })
        return mapBranch.join(',')
      }

    },
  ];



  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        {
          checkPermissionAction('P58PG1C2', 'P58PG1C2A3') || checkPermissionAction('P58PG1C2', 'P58PG1C2A4') ? (
            <Popover
              placement="right"
              trigger="hover"
              align={{ offset: offsetPopover }}
              visible={visiblePopoverTable}
              zIndex={10000}
              content={
                <div style={{ display: 'grid' }}>
                  {
                    checkPermissionAction('P58PG1C2', 'P58PG1C2A3') ? (
                      <div>
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalManager(record)}>
                          <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                        </Button>
                      </div>
                    ) : null
                  }
                  {
                    checkPermissionAction('P58PG1C2', 'P58PG1C2A4') ? (
                      <div>
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteManager(record.id)}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      </div>
                    ) : null
                  }
                </div>
              }
            >
              <div style={{ width: 0, height: 0 }} ref={ref}></div>
            </Popover>
          ) : null
        }
      </div>
    );
  };


  return (
    <div style={{ marginTop: '56px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 24px' }}>
        <div className='deliveryTopicTitle'>
          <FormattedMessage id="deliveryOrderManager" defaultMessage="Manager" />
        </div>
        {
          checkPermissionAction('P58PG1C2', 'P58PG1C2A2') ? (
            <div>
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModalManager()}>
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button01>
            </div>
          ) : null
        }
      </div>
      <Divider type="horizontal" />
      <div style={{ padding: '0 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          columns={columns}
          dataSource={manageList}
          scroll={{ y: 220 }}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href && (checkPermissionAction('P58PG1C2', 'P58PG1C2A3') || checkPermissionAction('P58PG1C2', 'P58PG1C2A4'))) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      </div>
    </div>
  )
}

export default DeliveryManager
