import React, { useState, useEffect } from 'react';
import Holiday from '../holiday/holiday';
import WorkDayForm from './work-day-form';
import HolidaysReport from '../holiday/public-holiday';
import { ShiftSettings } from '../config/shift-type-settings';
import { PageSettings } from '../../../config/page-settings';
import { notification } from 'antd';
import httpClient from '../../../components/axiosClient';
import moment from 'moment';
import Language from '../../../includes/language';
import { useAppContext } from '../../../includes/indexProvider';
import { useIntl, FormattedMessage } from 'react-intl';

const year = new Date().getFullYear();

const minYear = year - 3;
const maxYear = year + 3;

export default () => {
  const app = useAppContext();
  const pageCode = localStorage.getItem('pageCode');
  const comId = localStorage.getItem('comId');
  const [labelShow, setLabelShow] = useState({});
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [yearAll, setYearAll] = useState([]);
  const [workDays, setWorkDays] = useState();
  const [publicDay, setPublicDay] = useState();
  const [holidaysRow, setHolidaysRow] = useState();
  const [langLocal, setLangLocal] = useState([]);

  const [formdata, setFormData] = useState();
  const [record, setRecord] = useState();
  const [localLang, setLocalLang] = useState();

  const [sorter, setSorter] = useState('ascend');
  const [filterYear, setFilterYear] = useState(moment().format('YYYY'));

  const intl = useIntl();

  // ส่วนของการเรียกใช้ภาษา
  const Lang = async () => {
    const res = await Language({
      companyId: comId,
      lang: app.state.langValue,
      pageCode: pageCode,
    });
    setLabelShow(res);
    getApi();
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };
  const failedNotification = (message) => {
    notification.error({
      message: message,
    });
  };
  const refreshDataServices = () => {
    setRefreshTable(!refreshTable);
  };

  useEffect(() => {
    const fetchData = async () => {
      await Lang();
    };
    fetchData();
  }, [year, refreshTable, sorter, filterYear]);

  useEffect(() => {
    getYears(minYear, maxYear);
    // getApi();
  }, [year, refreshTable, sorter, filterYear]);

  const getYears = (minYear, maxYear) => {
    let arr = [];
    for (var i = minYear; i <= maxYear; i++) {
      arr.push(i);
    }
    setYearAll(arr);
  };

  const getApi = async () => {
    setLoading(true);
    let sort = sorter === 'ascend' ? 'asc' : 'desc';
    try {
      const results = await httpClient.get(
        `/resource/manager/company/${comId}/holiday?year=${filterYear}&sortby=start_date&sort=${sort}`,
      );
        console.log('respon',results);
      if (results.status === 200) {
        setWorkDays(results.data.workDay);
        setPublicDay(results.data.publicHoliday);
        setHolidaysRow(results.data.publicHoliday.holidays.rows);
        setLocalLang(results.data.publicHoliday.languageDetail);
        setLangLocal(results.data.publicHoliday.language);
        setLoading(false);
      }
    } catch {
      console.error('getApi holiday........................');
    }
  };

  const handleDelete = async (record) => {
    try {
      const res = await httpClient.delete(
        `/resource/manager/company/${comId}/public/holiday/${record.comCalId}`,
      );
      if (res.status === 200) {
        successNotification(res.data.message);
        setTimeout(() => {
          refreshDataServices();
        }, 500);
      } else {
        failedNotification(res.data.message);
      }
    } catch (error) {
      if (error) {
        failedNotification(error.res.data.message);
      }
    }
  };

  const mockSelectDay = {
    mon: true,
    tue: true,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: true,
  };

  const mockApi = {
    workDay: {
      mon: true,
      tue: true,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    },
    publicHoliday: {
      language: ['en', 'th', 'ch'],
      list: [
        {
          no: 1,
          holidayId: '45139a95-1c62-462f-98aa-587cd2e680c5',
          date: '2020-01-01',
          dateName: '1 january 2020',
          language: {
            en: 'EN - English',
            th: 'TH - ไทย',
          },
          holidayName: {
            en: "New Year's Day",
            th: 'วันขึ้นปีใหม',
          },
          repeat: {
            status: true,
            repeatName: 'Annually',
          },
        },
        {
          no: 2,
          holidayId: '763c0349-4ad7-4c36-989c-10580f580dd4',
          date: '2020-01-14',
          dateName: '14 january 2020',
          language: {
            en: 'EN - English',
            th: 'TH - ไทย',
          },
          holidayName: {
            en: 'National childrens day',
            th: 'วันเด็ก',
          },
          repeat: {
            status: true,
            repeatName: 'Annually',
          },
        },
      ],
    },
  };

  const workDay = [
    {
      id: 'mon',
      day: intl.formatMessage({
        id: 'settingHolidaysTxtMon',
        defaultMessage: 'Monday',
      }),
    },
    {
      id: 'tue',
      day: intl.formatMessage({
        id: 'settingHolidaysTxtTue',
        defaultMessage: 'Tueday',
      }),
    },
    {
      id: 'wed',
      day: intl.formatMessage({
        id: 'settingHolidaysTxtWed',
        defaultMessage: 'Wednesday',
      }),
    },
    {
      id: 'thu',
      day: intl.formatMessage({
        id: 'settingHolidaysTxtThurs',
        defaultMessage: 'Thursday',
      }),
    },
    {
      id: 'fri',
      day: intl.formatMessage({
        id: 'settingHolidaysTxtFri',
        defaultMessage: 'Friday',
      }),
    },
    {
      id: 'sat',
      day: intl.formatMessage({
        id: 'settingHolidaysTxtSat',
        defaultMessage: 'Saturday',
      }),
    },
    {
      id: 'sun',
      day: intl.formatMessage({
        id: 'settingHolidaysTxtSun',
        defaultMessage: 'Sunday',
      }),
    },
  ];

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <ShiftSettings.Provider
          value={{
            state: {
              yearAll,
              year,
              visible,
              workDay,
              publicDay,
              holidaysRow,
              formdata,
              record,
              visibleEdit,
              sorter,
              filterYear,
              localLang,
              langLocal,
              labelShow,
            },
            fnc: {
              setVisible,
              successNotification,
              failedNotification,
              refreshDataServices,
              handleDelete,
              setFormData,
              setRecord,
              setVisibleEdit,
              setSorter,
              setFilterYear,
              setLocalLang,
              setLangLocal,
            },
          }}
        >
          {/* <Holiday /> */}

          {checkPermissionAction('P6S4PG1C1', 'P6S4PG1C1A1') ? (
            <WorkDayForm workDays={workDays} />
          ) : null}
          {checkPermissionAction('P6S4PG1C2', 'P6S4PG1C2A1') ? (
            <HolidaysReport />
          ) : null}
        </ShiftSettings.Provider>
      )}
    </PageSettings.Consumer>
  );
};
