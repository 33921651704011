import React, { useContext, useState, useEffect } from 'react';
import ModalCustomerPost from '../../../components/modal-post-customer';
import { PageEngagement } from '../../../config/page-settings';
import { getCustomer } from '../../../controllers/engagement';
import _ from 'lodash';
import { addPost } from '../../../controllers/engagement';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import { loadmoreCustomer } from '../../../controllers/orders-controller/api';
import AddBusinessPartner from '../../../components/add-business-partner/index';

export default React.memo(() => {
  const { fnc, visibleAddPost } = useContext(PageEngagement);
  const [customer, setCustomer] = useState([]);
  const [triggerCustomer, setTriggerCustomer] = useState(false);
  const [visibleModalCustomer, setVisibleModalCustomer] = useState(false);
  const [visibleModalLocation, setVisibleModalLocation] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [message, setMessage] = useState('');
  const [selectedImageBlob, setSelectedImageBlob] = useState([]);
  const [selectedFilesBlob, setSelectedFilesBlob] = useState([]);
  const [addressData, setAddressData] = useState();
  //---------------------- Address --------------------------------
  const [locationData, setLocationData] = useState([]);
  const [center, setCenter] = useState({ lat: 13.90607, lng: 100.51913 });
  const [position, setPosition] = useState({ lat: 13.90607, lng: 100.51913 });
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [selectAddress, setSelectAddress] = useState([]);
  const [dragMarker, setDragMarker] = useState();
  const [latLng, setLatLng] = useState({});
  const [visibleCreateAddress, setVisibleCreateAddress] = useState(false);
  const [toLocation, setToLocation] = useState();
  const [fieldChange, setFieldChange] = useState('');
  const [valueInput, setValueInput] = useState();
  const [triggerApi, setTriggerApi] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState('default');
  const [selectAddressData, setSelectAddressData] = useState();

  //--------------------------Customer -------------------------------
  const [newCustomer, setNewCustomer] = useState();
  const [customerDataNew, setCustomerDataNew] = useState();

  const [visibleAddCustomer, setVisibleAddCustomer] = useState(false);

  const [customerListMaster, setCustomerListMaster] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerPage, setCustomerPage] = useState(0);
  const [customerPageSize, setCustomerPageSize] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customerSelect, setCustomerSelect] = useState();
  const [customerSearch, setCustomerSearch] = useState();
  const [customerSearchPage, setCustomerSearchPage] = useState(0);
  const [customerSearchPageSize, setCustomerSearchPageSize] = useState(10);
  const [addressBookList, setAddressBookList] = useState([]);
  const [isCustomerLoadMore, setIsCustomerLoadMore] = useState(true);
  const [isCustomerSearchLoadMore, setIsCustomerSearchLoadMore] = useState(true);
  //---------------------------------- Customer -----------------------------------------------

  //---------------------------------- Customer -----------------------------------------------
  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: customerPage * customerPageSize + 1,
        indexEnd: customerPage * customerPageSize + 10,
        customerName: customerSearch,
      };
      const result = await loadmoreCustomer(payload);
      const setCustomerListData = _.map(_.get(result, 'customers'), (o) => {
        return o;
      });

      const customers = _.uniqBy(_.concat(customerList, setCustomerListData), 'customerId');
      setCustomerList(customers);
      setCustomerListMaster(customers);
      setCustomerLoading(false);
      setIsCustomerLoadMore(_.size(setCustomerListData) > 0 ? true : false);
    };

    if (isCustomerLoadMore && visibleAddPost === true) {
      getData();
    }
  }, [customerPage, visibleAddPost]);

  useEffect(() => {
    const getData = async () => {
      if (!customerSearch) {
        setCustomerList(customerListMaster);
        setCustomerLoading(false);
      } else {
        let payload = {
          indexStart: customerSearchPage * customerPageSize + 1,
          indexEnd: customerSearchPage * customerPageSize + 10,
          customerName: customerSearch,
        };
        const result = await loadmoreCustomer(payload);
        const setCustomerListData = _.map(_.get(result, 'customers'), (o) => {
          return o;
        });
        const customers = _.uniqBy(_.concat(customerList, setCustomerListData), 'customerId');
        setCustomerList(customers);
        setCustomerLoading(false);
        setIsCustomerSearchLoadMore(_.size(setCustomerListData) > 0 ? true : false);
      }
    };

    if (isCustomerSearchLoadMore && visibleAddPost === true) {
      getData();
    }
  }, [customerSearch, customerSearchPage, visibleAddPost]);

  useEffect(() => {
    if (customerDataNew) {
      const mapData = [
        {
          customerId: _.get(customerDataNew, 'customerId'),
          comtomerName: _.get(customerDataNew, 'customerName'),
          phone: _.get(customerDataNew, 'phone'),
          position: _.get(customerDataNew, 'position'),
          profileImg: _.get(customerDataNew, 'profileImg')
            ? _.get(customerDataNew, 'profileImg')
            : _.get(customerDataNew, 'profileImgDefault'),
          businessType: _.get(customerDataNew, 'businessType.businessTypeName')
            ? _.get(customerDataNew, 'businessType.businessTypeName')
            : '',
          customerType: _.get(customerDataNew, 'customerType.customerTypeName')
            ? _.get(customerDataNew, 'customerType.customerTypeName')
            : '',
        },
      ];

      const concatData = _.uniqBy(_.concat(mapData, customerList), 'customerId');
      setCustomerList(concatData);
    }
  }, [_.get(customerDataNew, 'customerId')]);

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setCustomerLoading(true);
      if (customerSearch) {
        setCustomerSearchPage(customerSearchPage + 1);
      } else {
        setCustomerPage(customerPage + 1);
      }
    }
  };

  const handleCustomerChange = (value) => {
    if (!value) {
      setAddressBookList([]);
      setCustomerSelect();
    }
  };

  const handleCustomerChangeSelect = (value) => {
    if (value !== customerSelect) {
      setCustomerSearch();
      // setCustomerList([]);
      setCustomerSelect(value);
    }
  };

  const handleCustomerChangeSearch = (value) => {
    setCustomerLoading(true);
    setIsCustomerSearchLoadMore(true);
    setCustomerList([]);
    setCustomerSearchPage(0);
    setCustomerSearch(value);
  };

  const handleCreatePost = async () => {
    setLoading(true);
    if (customerSelect) {
      const lat = _.get(addressData, 'lat') ? _.get(addressData, 'lat') : undefined;
      const lng = _.get(addressData, 'lng') ? _.get(addressData, 'lng') : undefined;

      const data = {
        remark: message,
        lat: lat,
        lng: lng,
        entityRef: 'customer',
        entityRefId: customerSelect,
        imageList: selectedImageBlob,
        fileList: selectedFilesBlob,
        addressBookId: _.get(addressData, 'addressId'),
      };

      try {
        const response = await addPost(data);
        setCustomerId(undefined);
        setCustomerSelect();
        setCustomerSearch();
        setCustomerPage(0);
        setSelectedImageBlob([]);
        setSelectedFilesBlob([]);
        setMessage('');
        setSelectAddress([]);
        setDragMarker();
        setVisibleInfo(false);
        setLatLng({ lat: 13.90607, lng: 100.51913 });
        setToLocation();
        setFieldChange();
        setValueInput();
        setAddressData();
        setChecked('default');
        setSelectAddressData();
        // if (postId) {
        if (response.success === true) {
          successNotification(response.message);
          if (_.size(response.data.dataFile) !== 0) {
            const sumbyObject = response.data.dataFile.filter((item) => {
              return item.success === true;
            });
            successNotification(`Attachment has ${_.size(sumbyObject)}/${_.size(response.data.dataFile)} uploaded`);
          }
          if (_.size(response.data.dataImage) !== 0) {
            const sumbyObject = response.data.dataImage.filter((item) => {
              return item.success === true;
            });
            successNotification(`Photo has ${_.size(sumbyObject)}/${_.size(response.data.dataImage)} uploaded`);
          }
          fnc.handleCancelModalAddPost();
          fnc.handleChangeSelectCustomer(customerId, true);
          fnc.handleTimelineRefresh();
          setLoading(false);
          setCustomerList([]);
        } else {
          errorNotification(response.message);
          fnc.handleOkModalAddPost();
          setLoading(false);
        }
      } catch (error) {
        fnc.handleOkModalAddPost();
        setLoading(false);
      }
    }
  };

  const handleCancelPost = () => {
    setCustomerId(undefined);
    setCustomerSelect();
    setCustomerSearch();
    setCustomerPage(0);
    setCustomerList([]);
    setSelectedImageBlob([]);
    setSelectedFilesBlob([]);
    setMessage('');
    fnc.handleCancelModalAddPost();
    setSelectAddress([]);
    setDragMarker();
    setVisibleInfo(false);
    setLatLng({ lat: 13.90607, lng: 100.51913 });
    setToLocation();
    setFieldChange();
    setValueInput();
    setAddressData();
    setChecked('default');
    setMessage(undefined);
    setSelectAddressData();
  };

  return (
    <div>
      <ModalCustomerPost
        value={{
          customer,
          customerList,
          visibleAddPost,
          visibleModalCustomer,
          visibleModalLocation,
          customerId,
          selectedFilesBlob,
          selectedImageBlob,
          addressData,
          message,
          loading,
          checked,
          locationData,
          center,
          position,
          visibleInfo,
          selectAddress,
          dragMarker,
          latLng,
          visibleCreateAddress,
          toLocation,
          fieldChange,
          valueInput,
          triggerApi,
          selectAddressData,
          customerLoading,
          customerSelect,
        }}
        fnc={{
          setTriggerCustomer,
          handleCancelPost,
          handleCreatePost,
          setVisibleModalCustomer,
          setVisibleModalLocation,
          setCustomerId,
          setSelectedFilesBlob,
          setCustomer,
          setSelectedImageBlob,
          setAddressData,
          setMessage,

          setLocationData,
          setCenter,
          setPosition,
          setVisibleInfo,
          setSelectAddress,
          setDragMarker,
          setLatLng,
          setVisibleCreateAddress,
          setToLocation,
          setFieldChange,
          setValueInput,
          setTriggerApi,
          setChecked,
          setSelectAddressData,
          handleCustomerPopupScroll,
          handleCustomerChangeSearch,
          handleCustomerChange,
          handleCustomerChangeSelect,
          setNewCustomer,
          setCustomerDataNew,
          setCustomerSelect,
        }}
      />
    </div>
  );
});
