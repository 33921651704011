import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import _, { debounce } from 'lodash';
import getSearchLocation from '../../function/search-location/getLocation';
import Map from './map-location';
import './css/index.css';
import LabeRequireForm from '../label-required-form';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;

const ModalMapLocation = (props) => {
  const intl = useIntl();
  const {
    form,
    textId,
    textDefault,
    visible,
    setVisible,
    defaultAddress,
    setDefaultAddress,
    autoComplteLocation,
    setAutoComplteLocation,
    autoComplteValue,
    setAutoComplteValue,
  } = props;
  const { getFieldDecorator, validateFields } = form;
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);
  const [addressArea, setAddressArea] = useState([]);
  const [latlngTo, setLatlngTo] = useState({});
  const [valueAddress, setValueAddress] = useState('');

  const handleSearchLocation = debounce(async (fieldChange) => {
    let newData = await getSearchLocation({ name: fieldChange });
    setAddressArea(newData);
  }, 200);

  useEffect(() => {
    if (visible === true && defaultAddress) {
      form.setFieldsValue({ ['locationName']: _.get(defaultAddress, 'locationName') });
      form.setFieldsValue({ ['address']: _.get(defaultAddress, 'address') });
      form.setFieldsValue({ ['mapLocation']: _.get(defaultAddress, 'mapLocation') });
      setAutoComplteValue(_.get(defaultAddress, 'mapLocation'));
      setPosition({ lat: _.get(defaultAddress, 'lat'), lng: _.get(defaultAddress, 'lng') });
      setCenter({ lat: _.get(defaultAddress, 'lat'), lng: _.get(defaultAddress, 'lng') });
    }
  }, [visible, defaultAddress]);

  const handleSelect = (key, option) => {
    if (isNaN(key) == false) {
      form.setFieldsValue({ ['mapLocation']: option.props.children });
      form.setFieldsValue({ ['address']: option.props.children });
      setAutoComplteLocation(option.props.children);
      setAutoComplteValue(option.props.children);
      setPosition({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setCenter({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setLatlngTo({ lat: addressArea[key].lat, lng: addressArea[key].lng });
    } else {
      setPosition(latlng);
      setCenter(latlng);
      form.resetFields();
      form.setFieldsValue({ ['mapLocation']: undefined });
      setAutoComplteLocation();
      setAutoComplteValue();
    }
  };

  useMemo(() => {
    if (valueAddress) {
      form.setFieldsValue({
        ['mapLocation']: valueAddress,
      });
      form.setFieldsValue({
        ['address']: valueAddress,
      });
      setAutoComplteValue(valueAddress);
    }
  }, [valueAddress]);

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setDefaultAddress({
        locationName: _.get(values, 'locationName'),
        address: _.get(values, 'address'),
        mapLocation: autoComplteValue,
        lat: parseFloat(_.get(latlngTo, 'lat')),
        lng: parseFloat(_.get(latlngTo, 'lng')),
      });
      setVisible(false);
      form.resetFields();
    });
  };

  const handleCreateCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const children = addressArea.map((item) => <Option key={item.key}>{item.address}</Option>);

  return (
    <Modal
      className="create-task-modal"
      bodyStyle={{ height: 'calc(100vh - 230px)', maxHeight: '605px', overflow: 'auto', overflowX: 'hidden' }}
      title={intl.formatMessage({ id: textId, defaultMessage: textDefault })}
      visible={visible}
      onCancel={handleCreateCancel}
      onOk={handleCreate}
      centered
      width={824}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCreateCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label={
            <LabeRequireForm text={intl.formatMessage({ id: 'branchLocationName', defaultMessage: 'Location Name' })} req={true} />
          }
        >
          {getFieldDecorator('locationName', {
            initialValue: defaultAddress && defaultAddress.locationName ? defaultAddress.locationName : undefined,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'branchValidateLocationName', defaultMessage: 'Please enter location name' }),
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'branchPlaceholderLocationName', defaultMessage: 'Enter Location Name' })}
              autoComplete="off"
            />
          )}
        </Form.Item>

        <Form.Item
          label={
            <LabeRequireForm text={intl.formatMessage({ id: 'branchLocationAddress', defaultMessage: 'Address' })} req={true} />
          }
        >
          {getFieldDecorator('address', {
            initialValue: defaultAddress && defaultAddress.address ? defaultAddress.address : undefined,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'branchLocationValidateAddress', defaultMessage: 'Please enter address' }),
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'branchLocationPlaceholderAddress', defaultMessage: 'Enter Address' })}
              autoComplete="off"
            ></Input>
          )}
        </Form.Item>
        <div>
          <Map
            idMap={1}
            mapModal={1}
            position={position}
            center={center}
            setLatlngTo={setLatlngTo}
            setValueAddress={setValueAddress}
            addressArea={addressArea}
            handleSearchLocation={handleSearchLocation}
            handleSelect={handleSelect}
            children={children}
            autoComplteLocation={autoComplteLocation}
            setAutoComplteLocation={setAutoComplteLocation}
          />
        </div>
      </Form>
    </Modal>
  );
};

const ModalMapLocationNew = Form.create({
  name: 'modal_form',
})(ModalMapLocation);

export default React.memo(ModalMapLocationNew);
