import { Card, Col, Row, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { AllticketContext } from '../allticket-context';
import Filter from './filter';
import FillterForm from './filterform';

const LeftCardAllTicket = () => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(AllticketContext);
  const { menuList, indeterminateRequest, indeterminateTeam, checkedKeysRequest, checkedKeysTeam, checkAllRequest,checkAllTeam } = state;
  const { setCheckedKeysRequest, setCheckedKeysTeam, setIndeterminateTeam, setIndeterminateRequest, setCheckAllRequest,setCheckAllTeam } = setState;
  const { onCheckRequest, onCheckTeam } = fnc;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  return (
    <Card className="left-card-all-ticket scroll-sm">
      <div>
        <div className="filter-text-summary-text">
          <span className="filter-text-summary-text-size">{intl.formatMessage({ id: `TicketFilter`, defaultMessage: 'Filter' })}</span>
        </div>
        <Filter
          Data={menuList.data}
          indeterminateTeam={indeterminateTeam}
          setCheckedListTeam={setState.setCheckTeam}
          setIndeterminateTeam={setIndeterminateTeam}
          setOrgIdRaw={setState.setOrgIdRaw}
          orgIdRaw={state.orgIdRaw}
          setPaginationPage={setState.setPaginationPage}
          checkedKeysTeam={checkedKeysTeam}
          autoExpandParent={autoExpandParent}
          expandedKeys={expandedKeys}
          onCheckTeam={onCheckTeam}
          onExpand={onExpand}
          setCheckAllTeam={setCheckAllTeam}
          checkAllTeam={checkAllTeam}
        ></Filter>
        <FillterForm />
      </div>
    </Card>
  );
};

export default LeftCardAllTicket;
