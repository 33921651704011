import React from 'react'
import { Modal, Row, Col, Avatar } from 'antd'
import _ from 'lodash';
import style from './css/index.css'
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';


export default ({ visible, setVisible, datasource, acknowledgeStatus }) => {




  const notiTypeName = _.get(datasource, 'notiTypeName')
  const profileImage = _.get(datasource, 'profileImage')
  const profileName = _.get(datasource, 'name')
  const orgName = _.get(datasource, 'orgName')
  const phoneNumber = _.get(datasource, 'phone')
  const description1 = _.get(datasource, 'description1')
  const description2 = _.get(datasource, 'description2')
  const description3 = _.get(datasource, 'description3')
  const planDate = _.get(datasource, 'planDate')
  const id = _.get(datasource, '_id')
  const statusRead = _.get(datasource, 'statusRead')




  return (
    <div>
      <Modal
        title={notiTypeName ? notiTypeName : ''}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        centered={true}
        width={440}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={() => setVisible(false)} className='btn-size-notification'>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize='wd_at' className='btn-size-notification' onClick={() => {
            setVisible(false)
            acknowledgeStatus(id);
          }} disabled={statusRead === 'true' ? true : false}>
            <FormattedMessage id="notificationAcknowledgeLbl" defaultMessage="Acknowledge" />
          </Button01>
        ]}
      >
        <Row>
          <Col span={4} className='avatar-align'>
            <Avatar src={profileImage} size='large'></Avatar>
          </Col>
          <Col span={20}>
            <div className='div-information'>
              <span className='name-stylee'>{profileName}</span>
              <Row>
                <span style={{ marginRight: '8px' }} className='team-style'>{orgName}</span>
                <span className='phone-style'>{phoneNumber}</span>
              </Row>
            </div>
            <div>
              <Col span={24}>
                <span className='description-style'>{description1} {description2} {description3}</span>
              </Col>
              <Col span={24}>
                <span className='date-style'>{planDate}</span>
              </Col>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}