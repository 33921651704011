import React, { useEffect, useContext } from 'react';
import { Menu } from 'antd';
import { PagePlanningSettings } from './page-planning-settings';

export default React.memo(() => {
  const app = useContext(PagePlanningSettings);

  return (
    <PagePlanningSettings.Consumer>
      {({ scenario, scenarios, handleChangeScenario }) => (
        <Menu
          mode='inline'
          style={{ 
            width: 200,
            height: '100%' 
          }}
          selectedKeys={[scenario]}
          onSelect={handleChangeScenario}
          inlineIndent={50}
        >
          {scenarios.map && scenarios.map((object) => (
            <Menu.Item key={object.scenarioId} style={{ margin: 0, height: 60, lineHeight: '60px' }}>{object.scenarioName}</Menu.Item>
          ))}
        </Menu>
      )}
    </PagePlanningSettings.Consumer>
  );
});