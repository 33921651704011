import React, { useEffect, useState } from 'react';
import { Route, matchPath, Link } from 'react-router-dom';
import cssStyle from './css/topBar.css';
import { Breadcrumb, Card } from 'antd';
import RightMenuTopbar from '../pages/menu/index';
import GetLang from './language';
import menus from '../components/layout/top-menu/menu';
import { PageSettings } from '../config/page-settings';
import _ from 'lodash';
import { dd, useQuery } from '../helpers/general-helper';

export default (props) => {
  const { pathName } = props;
  const query = useQuery();
  // get data จาก button value เพื่อนำมาเปลี่ยนภาษา จากหน้า userMenu
  const langValue = localStorage.getItem('langValue');
  const [labelShow, setLabelShow] = useState({});

  useEffect(() => {
    Lang();
  }, []);

  let newMenu = [];
  const setDataBreadcrumb = (menu = []) => {
    _.forEach(menu, (o) => {
      let oClone = { ...o };
      const match = matchPath(window.location.pathname, o.path);
      if (match) {
        let setQuery = '?';
        if (o.path === '/main/setting/user-management/users') {
          setQuery += `page=${query.get('page')}&pageSize=${query.get('pageSize')}&searchBy=${query.get('searchBy')}&search=${query.get(
            'search'
          )}`;
        } else if (o.path === '/main/resource/organization') {
          if (query.get('orgId')) {
            setQuery += `orgId=${query.get('orgId')}&parentOrgId=${query.get('parentOrgId')}&tab=${query.get('tab')}&page=${query.get(
              'page'
            )}&pageSize=${query.get('pageSize')}&searchBy=${query.get('searchBy')}&search=${query.get('search')}`;
          } else {
            oClone.link = false;
          }
        }
        newMenu.push({ ...oClone, q: setQuery });
      }
      if (o.children) setDataBreadcrumb(o.children);
    });
  };

  setDataBreadcrumb(menus);

  const Lang = async () => {
    // console.log('companyId ::',props.comId)
    const res = await GetLang({
      companyId: props.comId,
      lang: langValue ? langValue : 'EN',
      pageCode: props.pageCode,
    });
    setLabelShow(res);
  };

  const TopMenuNav = ({ menu, key }) => {
    return menu.map((m, i) => (
      <>
        <Route
          path={m.path}
          key={`${m.path}-${i}`}
          children={({ match }) => (match ? <Breadcrumb.Item>{m.title}</Breadcrumb.Item> : null)}
        />
        {m.children ? <TopMenuNav menu={m.children} key={i} /> : null}
      </>
    ));
  };

  // console.log('New Menu::: ', newMenu);

  return (
    <PageSettings.Consumer>
      {({ pageSidebarTitle }) => (
        <Card
          size="small"
          title={<span> </span>}
          extra={<RightMenuTopbar labelShow={labelShow} langValue={langValue} />}
          className="header-shadow"
        >
          <Breadcrumb separator=">">
            {newMenu.map((o, i) => (
              <Breadcrumb.Item key={`${i}`}>{o.link === true ? <Link to={o.path + o.q}>{o.title}</Link> : o.title}</Breadcrumb.Item>
            ))}
          </Breadcrumb>

          {/* <Breadcrumb separator=">">
            {menus.map((menu, i) => (
              <>
                <Route path={menu.path} key={i} children={({match}) => (
                  match ? <Breadcrumb.Item>{menu.title}</Breadcrumb.Item> : null
                )} />
                {menu.children ? <TopMenuNav menu={menu.children} key={i} /> : null}
              </>
            ))}
          </Breadcrumb> */}
        </Card>
      )}
    </PageSettings.Consumer>
  );
};
