import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getGeoCompanyApi = async () => {
  try {
    const response = await httpCiient.get(`/v5/resource/manager/company/${comCode}/web/companykey`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export { getGeoCompanyApi };
