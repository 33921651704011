import httpClient from '../../components/axiosClient';

const getUoMData = async (id) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v5/item/manager/company/${comCode}/web/uombycategory/${id}`);
    if (response.status === 200) {
      return response.data;
    }else{
      return [];
    }
  } catch (error) {
    return error.response.data;
  }
}
export {getUoMData}
