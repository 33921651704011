import httpClient from '../../components/axiosClient';

const updateTeam = async (props) => {

  const comCode = localStorage.getItem('comCode');

  // console.log('updateTeam props::: ', props);
  
  const body = {
    orgId: parseInt(props.orgId),
    memComId: parseInt(props.memComId),
    positionId: props.positionId
  }

  try {
    const response = await httpClient.put(`/v4/organizations/company/${comCode}/organization/member/${props.memOrgId}`, body);
    // console.log('updateTeam response api::: ', response);

    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response;
  }
}

export default updateTeam;