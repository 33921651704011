import React, { useEffect } from 'react';
import Map from './map';
import './css/index.css';
import { Form, Select, Row, Col, Divider, Icon } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';

const { Option } = Select;

const BusinessPartnerDetail = (props) => {
  const { customer, addressData, selectCustomer, selectAddress, selectWareHouse, setPolyline, polyline, setDistance, distance,
    marker, setMarker, setLoading, setselectCustomerItem, setSelectAdressItem, selectAddressItem, selectWareHouseItem, setVisibleAddCustomer, addCustomerData
    , loading, setVisibleAddAddress, addAddressData, setDataDistance, setDistanceMeter, setCheckCustomer, setSelectWareHouseItem, warehouseData, getItemAllApi
    , setselectProductsItem, setselectVehiclesItem
  } = props;


  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;

  const intl = useIntl();
  useEffect(() => {
    const customerId = getFieldValue('customer')
    const filterdata = customer && customer.filter(item => { return item.customerId === customerId })
    if (customerId && _.size(filterdata) !== 0) {
      setselectCustomerItem(_.get(filterdata, '[0]'))
    }
  }, [getFieldValue('customer')])


  useEffect(() => {
    const addressId = getFieldValue('customerAddess')
    const filterdata = addressData && addressData.filter(item => { return item.customerAddressBookId === addressId })
    if (addressId && _.size(filterdata) !== 0) {
      setSelectAdressItem(_.get(filterdata, '[0]'))
    }
  }, [getFieldValue('customerAddess')])

  useEffect(() => {
    if (addCustomerData) {
      setFieldsValue({
        ['customer']: _.get(addCustomerData, 'customerId')
      });
      setFieldsValue({
        ['customerAddess']: undefined
      });
      setselectCustomerItem(addCustomerData)
    }
  }, [_.get(addCustomerData, 'customerId')])

  useEffect(() => {
    if (addAddressData) {
      setFieldsValue({
        ['customerAddess']: _.get(addAddressData, 'customerAddressBookId')
      });
      setSelectAdressItem(addAddressData)
    }
  }, [addAddressData, addressData])

  // useEffect(() => {

  //   if (getFieldValue('address') !== undefined) {
  //     const wareHouseAutoSelect = warehouseData && warehouseData.filter((item) => item.selected === true);


  //     const dataSelect = _.get(wareHouseAutoSelect, '[0].distance') ? _.get(wareHouseAutoSelect, '[0]') : undefined;
  //     setSelectWareHouseItem(dataSelect);
  //     // setSelectWareHouseItem(_.get(wareHouseAutoSelect, '[0]'));
  //     // setFieldsValue({
  //     //   ['warehouse']: _.get(wareHouseAutoSelect[0], 'distance') !== undefined ? _.get(wareHouseAutoSelect[0], 'warehouseId') : undefined
  //     // });
  //   }

  //   // setSelectWareHouse(_.get(wareHouseAutoSelect, '[0]'));
  // }, [getFieldValue('address'),warehouseData])

  const handleChangeCustomer = (e) => {
    setFieldsValue({
      ['customerAddess']: undefined
    });
    setSelectAdressItem(undefined)
    setSelectWareHouseItem(undefined)

    const filterdata = customer && customer.filter(item => { return item.customerId === e })
    setselectCustomerItem(_.get(filterdata, '[0]'))


    // setTimeout(() => {
    //   getItemAllApi();
    // }, 500)

    setCheckCustomer(true)
    setselectProductsItem([]);
    setselectVehiclesItem([]);
  }

  const handleChangeAddressCustomer = (e) => {


    const filterdata = addressData && addressData.filter(item => { return item.customerAddressBookId === e })
    setSelectAdressItem(_.get(filterdata, '[0]'))

    setSelectWareHouseItem(undefined)
    setselectProductsItem([]);
    setselectVehiclesItem([]);
    // setTimeout(() => {
    //   getItemAllApi();
    // }, 500)
  }

  return (
    <div className='container-all-obj'>
      <div className='container-all-action'>
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label={<LabeRequire text={intl.formatMessage({ id: 'ModalQuotationItemTextCustomer', defaultMessage: 'Customer' })} req={true} />}
              >
                {getFieldDecorator('customer', {
                  initialValue: _.get(selectCustomer, 'customerId') ? _.get(selectCustomer, 'customerId') : undefined,
                  rules: [{ required: true, message: intl.formatMessage({ id: 'ModalQuotationItemValidateCustome', defaultMessage: 'Please select customer' }) }],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderCustomer', defaultMessage: 'Please select ' })}
                    showSearch
                    onChange={handleChangeCustomer}
                    filterOption={(input, option) =>
                      option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => setVisibleAddCustomer(true)}
                        >
                          <Icon type="plus" className='customer-icon-plus' />
                          <span className='text-add-customer'><FormattedMessage id="saleOrderModalTextAddCustomer" defaultMessage="Add Customer" /></span>
                        </div>
                      </div>
                    )}
                  >
                    {customer && customer.map((item) => (
                      <Option value={item.customerId}>{item.customerName}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<LabeRequire text={intl.formatMessage({ id: 'ModalQuotationItemTextAddress', defaultMessage: 'Customer Addess ' })} req={true} />}
              >
                {getFieldDecorator('customerAddess', {
                  initialValue: _.get(selectAddress, 'customerAddressBookId') ? _.get(selectAddress, 'customerAddressBookId') : undefined,
                  rules: [{ required: true, message: intl.formatMessage({ id: 'ModalQuotationItemValidateAddress', defaultMessage: 'Please select customer addess ' }) }],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderAddress', defaultMessage: 'Please select' })}
                    showSearch
                    disabled={getFieldValue("customer") ? false : true}
                    onChange={handleChangeAddressCustomer}
                    filterOption={(input, option) =>
                      option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    loading={loading}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => setVisibleAddAddress(true)}
                        >
                          <Icon type="plus" className='customer-icon-plus' />
                          <span className='text-add-customer'><FormattedMessage id="ModalQuotationItemAddAddress" defaultMessage="Add Location" /></span>
                        </div>
                      </div>
                    )}
                  >
                    {addressData && addressData.map((item) => (
                      <Option value={item.customerAddressBookId}>{item.addressName}</Option>

                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className='container-all-map'>
        <Map id='map'
          selectAddress={selectAddressItem}
          selectWareHouse={selectWareHouseItem}
          setPolyline={setPolyline}
          setDataDistance={setDataDistance}
          polyline={polyline}
          setDistance={setDistance}
          setDistanceMeter={setDistanceMeter}
          distance={distance}
          marker={marker}
          setMarker={setMarker}
          setLoading={setLoading}
        />
      </div>
    </div>
  )
}

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};


const ModalSettingForm = Form.create({
  name: "modal_form",
})(BusinessPartnerDetail);

export default ModalSettingForm;