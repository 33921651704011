import React, { useEffect, useState } from 'react';
import { Menu, Card, Icon } from 'antd';
import { usePermissionContext } from './PermissionContext';
import cssStyle from './css/permission.css'
import { useIntl, FormattedMessage } from 'react-intl';
import getMenu from '../../controllers/leftmenu/get-menu'
import _ from 'lodash';

const { SubMenu } = Menu;

export default (props) => {
  const app = usePermissionContext();
  const intl = useIntl();
  const [menuList, setMenuList] = useState([])

  useEffect(() => {
    const getManuData = async () => {
      const response = await getMenu({isPermission: true})
      setMenuList(response.data.leftMenu)
    }
    getManuData();
  }, [])

  const handerSelect = async (object) => {
    app.fnc.setRolesSelect(object.item.props.keys);
  }

  const roles = app.state.roles.map((elements) => {

    return (
      elements.sub.length > 0 ?
        <SubMenu
          className="subMenu-item-list"
          key={elements.code}
          title={elements.description}
        >
          {elements.sub.map((ele) => {
            return (
              <Menu.Item className="menu-item-list" key={ele.code}>{ele.description}</Menu.Item>
            );
          })}
        </SubMenu>
        :
        <Menu.Item className="menu-item-list" key={elements.code} style={{ paddingLeft: 28 }}>
          {elements.description}
        </Menu.Item>
    );
  });

  const ManuMap = menuList && menuList.map((menu) =>
    _.isObject(menu.children) ?
      <SubMenu
        keys={menu.menuName}
        title={
            <span>
              <FormattedMessage id={menu.menuName} defaultMessage={menu.menuName} />
            </span>
        }
      >
        {
          menu.children && menu.children.map((subMenu) =>
            _.isObject(subMenu.children) ?
              <SubMenu
                keys={subMenu.menuName}
                title={
                  <span>
                      <FormattedMessage id={subMenu.menuName} defaultMessage={subMenu.menuName} />
                    </span>
                }
              >
                {
                  subMenu.children && subMenu.children.map((children) =>
                    <Menu.Item keys={children.permissionPageCode} className="item-style">
                      <span>
                        <FormattedMessage id={children.menuName} defaultMessage={children.menuName} />
                      </span>
                    </Menu.Item>
                  )
                }
              </SubMenu>
              : <Menu.Item keys={subMenu.permissionPageCode} className="item-style">
                <span>
                  <FormattedMessage id={subMenu.menuName} defaultMessage={subMenu.menuName} />
                </span>
              </Menu.Item>
          )
        }
      </SubMenu>
      :
      <Menu.Item keys={menu.permissionPageCode} className="item-style" >
        <span>
          <FormattedMessage id={menu.menuName} defaultMessage={menu.menuName} />
        </span>
      </Menu.Item>
  )

  return (
    <Card
      title={intl.formatMessage({ id: 'UserManagementFunctionTitle', defaultMessage: 'Function' })}
      className='card-permission-function'
    >
      <Menu
        className="permission-manu"
        onSelect={handerSelect}
        mode="inline"
      >
        {ManuMap}
      </Menu>
    </Card>
  );
}