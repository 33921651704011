import React, { useRef,useState } from "react";
import { Table,Popover,Button } from 'antd';
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';

const ItemMasterSummaryTable = (props) => {
  const { total, paginationPage, fnc, dataSource, columns } = props
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const TotalItem = styled.div`
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
`;

const handleMouseLeave = () => {
  setVisiblePopoverTable(false);
  setRecordPopover();
  setOffsetPopover([0, 0]);
};


const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
  return (
    <div>
      <Popover
        placement="right"
        trigger="hover"
        align={{ offset: offsetPopover }}
        visible={visiblePopoverTable}
        zIndex={10000}
        content={
          <div style={{ display: 'grid' }}>
            <div>
              <Button style={{ width: 100 }} type="link" ghost onClick={() => fnc.handleEdit(record)}>
                <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
              </Button>
            </div>
            <div>
              <Button style={{ width: 100 }} type="link" ghost onClick={() => fnc.handleDelete(record.stockId)}>
                <FormattedMessage id="btnDelete" defaultMessage="Delete" />
              </Button>
            </div>
          </div>
        }
      >
        <div style={{ width: 0, height: 0 }} ref={ref}></div>
      </Popover>
    </div>
  );
};

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.index}
        onChange={fnc.handleChange}
        pagination={{
          total: total,
          showTotal: showTotal,
          current: paginationPage,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: fnc.handlePagination,
          onShowSizeChange: fnc.handleSizeChange,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />
      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      {dataSource && dataSource.length !== 0 ? (
        <TotalItem>
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </TotalItem>
      ) : null}
    </div>
  )
}

export default ItemMasterSummaryTable