import React from 'react';
import { Checkbox, Row, Col, Badge, Icon, Avatar, Divider } from 'antd';
import '../css/engagement-filter.css';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import Nodata from '../../../components/image/Nodata.svg';

export default (props) => {
  const { members, onSelectMember, memberPageHasMore, memberPageLoading, onLoadMore } = props;
  const renderMember = () =>
    members &&
    members.map((e) => {
      return (
        <div key={e.memComId} className="customer-engagement-list" onClick={() => onSelectMember(e.memComId)}>
          <Row className="padding-between-item-engage-list">
            <Col span={5}>
              <Badge
                className="badge-style-member"
                count={
                  e.selected === true ? (
                    <Icon type="check-circle" theme="filled" style={{ color: '#1D3557' }} className="icon-correct-member" />
                  ) : (
                    undefined
                  )
                }
              >
                <Avatar size="large" shape="circle" src={e.pathImage} />
              </Badge>
            </Col>
            <Col span={19}>
              <div>
                <div className="engagement-hidden-text">{e.fullname}</div>
                <div>
                  <span className="phone-member-engage">{e.phone}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Divider className="divider-style-enge" type="horizontal" />
        </div>
      );
    });

  return (
    <div className="customer-engagement-list-all">
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={(page) => onLoadMore(page)}
        hasMore={memberPageLoading && memberPageHasMore}
        useWindow={false}
        threshold={25}
      >
        {_.size(members) !== 0 ? (
          renderMember()
        ) : (
          <div className="postby-no-member">
            <div>
              <img src={Nodata} style={{ width: '100px' }}></img>
            </div>
            <div style={{ color: '#e4e4e4' }}>
              <FormattedMessage id="textNoData" defaultMessage="No Data" />
            </div>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};
