import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getListPettyCash = async (payload) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/getPettyCashPagination`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const savePettyCash = async (payload) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/createPettyCash`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updatePettyCash = async (id, payload) => {
  try {
    const response = await httpCiient.put(`/v1/pointcommission/manager/company/${comCode}/updatePettyCashPagination/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getPettyCash = async (id) => {
  try {
    const response = await httpCiient.get(`/v1/pointcommission/manager/company/${comCode}/getPettyCash/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getTransactionPettyCash = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/getPettyCashHistory`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getPocketPettyCash = async () => {
  try {
    const response = await httpCiient.get(`/v1/pointcommission/manager/company/${comCode}/transferPettyCashModelList`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const savePocketTranfer = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/transferPettyCash`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getPettyCashCategory = async () => {
  try {
    const response = await httpCiient.get(`/v1/pointcommission/manager/company/${comCode}/getPettyCashcategory`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getListCategory = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/getPettyCashcategoryPagination`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const saveCategory = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/createPettyCashcategory`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const editCategory = async (id, body) => {
  try {
    const response = await httpCiient.put(`/v1/pointcommission/manager/company/${comCode}/updatePettyCashcategory/${id}`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const deleteCategory = async (id) => {
  try {
    const response = await httpCiient.delete(`/v1/pointcommission/manager/company/${comCode}/deletePettyCashcategory/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const receivePettyCash = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/receivePettyCash`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const paidPettyCash = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/paidPettyCash`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const closePettyCash = async (body) => {
  try {
    const response = await httpCiient.put(`/v1/pointcommission/manager/company/${comCode}/updatePettyCashStatus`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const exportPettyCash = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/exportExcelPettyCash`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const exportTransectionPettyCash = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/pointcommission/manager/company/${comCode}/exportExcelPettyCashHistory`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

export {
  getListPettyCash, savePettyCash, updatePettyCash, getPettyCash, getTransactionPettyCash,
  getPocketPettyCash, savePocketTranfer, getPettyCashCategory, getListCategory, saveCategory,
  editCategory, deleteCategory, receivePettyCash, paidPettyCash, closePettyCash, exportPettyCash,
  exportTransectionPettyCash
}
