import React, { useState, useEffect } from 'react';
import './css/index.css';
import { Form, Modal, Col, Row, Select } from 'antd';
import _ from 'lodash';
import getModalLicense from '../../controllers/license/get-modal-license';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import httpClient from '../../components/axiosClient';
import { successNotification, errorNotification, warningNotification } from '../v2/notification';

const { Option } = Select;

const ModalLicense = (props) => {
  const intl = useIntl();
  const { visible, setVisible, data, form, trigger, setTrigger } = props;
  const comCode = localStorage.getItem('comCode');
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const [dataSelect, setDataSelect] = useState([]);

  useEffect(() => {
    getDataSelect();
  }, []);

  const getDataSelect = async () => {
    const response = await getModalLicense();
    if (response) {
      setDataSelect(response);
    }
  };

  const onCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const getValues = _.get(values, 'module');
      const mapValues =
        getValues &&
        getValues.map((item, index) => {
          return {
            licenseModuleId: item,
            memComId: _.get(props, 'data.memComId').toString(),
          };
        });

      const body = [
        {
          licenseModuleId: '',
          memComId: _.get(props, 'data.memComId').toString(),
        },
      ];

      try {
        const response = await httpClient.put(
          `/v3/license/manager/company/${comCode}/updatedatalistlicense`,
          _.size(mapValues) !== 0 ? mapValues : body
        );
        if (response.status === 200) {
          setVisible(false);
          successNotification(response.data.status.message);
          setTrigger(!trigger);
        }
      } catch (error) {
        setVisible(false);
        errorNotification(error.response.data.status.message);
      }
    });
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modallblLicenseTitle', defaultMessage: 'Assign Module' })}
      visible={visible}
      width={544}
      centered={true}
      onCancel={onCancel}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={onCancel} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={onCreate}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form>
        <Row>
          <Col span={24} style={{ marginBottom: '7px' }}>
            <span className="text-style">
              <FormattedMessage id="modallblLicenseTitleSelectUser" defaultMessage="Please select a module for your user" />
            </span>
          </Col>
          <Col span={24}>
            <Form.Item>
              <span className="text-style">
                <FormattedMessage id="modallblLicenseModule" defaultMessage="Module" />
              </span>
              {getFieldDecorator(
                'module',
                {}
              )(
                <Select
                  placeholder={intl.formatMessage({ id: 'modallblLicenseHintSelectUser', defaultMessage: 'Select License' })}
                  mode="multiple"
                  className="select-module"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelect && dataSelect.map((item) => <Option key={item.moduleId}>{item.moduleName}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const LicenseModule = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {
    const defaultModule = _.get(props, 'data.module');
    const mapmodule =
      defaultModule &&
      defaultModule.map((item) => {
        return item.licenseModuleId;
      });

    return {
      module: Form.createFormField({
        value: mapmodule,
      }),
    };
  },
})(ModalLicense);

export default LicenseModule;
