import React, { useEffect, useState } from 'react'
import { getTaskDetailInformation } from '../../controllers/monitor/listView';
import _ from 'lodash'
import { getCustomeField, getDisTance } from '../../controllers/task/create-task';
import { getinitialTeamApi } from '../../controllers/organizationTeam/get-orgTeam-api';
import { subTaskApi } from '../../controllers/task/task-detail.js'

const TaskDetailContext = React.createContext();

const TaskDetailProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('information')
  const [loading, setLoading] = useState(false)
  const [detailData, setDetailData] = useState({})
  const [orgData, setOrgData] = useState([])
  const [keys, setKeys] = useState([])
  const [activeKey, setActiveKey] = useState([])

  useEffect(() => {
    const getInitialApi = async () => {
      const response = await getinitialTeamApi('menuType=transportation');
      const dataInitial = response.data.team.map((i) => {
        return {
          orgId: i.orgId,
          orgName: i.name,
          selected: i.selected,
          checked: true,
        };
      });
      setOrgData(dataInitial || []);
    };
    getInitialApi();
  }, []);

  const getData = async (id) => {
    setLoading(true)
    const information = await getTaskDetailInformation(id)
    if (_.get(information, 'status') === 200) {
      const bodyLatlng = {
        fromLat: _.get(information, 'data.address.fromlat'),
        fromLng: _.get(information, 'data.address.fromlng'),
        toLat: _.get(information, 'data.address.lat'),
        toLng: _.get(information, 'data.address.lng'),
      };
      const customField = await getCustomeField('task_type', _.get(information, 'data.information.taskTypeId'), 'transportation');
      const distance = await getDisTance(bodyLatlng, 'transportation');
      setDetailData({
        taskId: id,
        information: _.get(information, 'data'),
        customeFieldData: _.get(customField, 'data'),
        distance: _.get(distance, 'data.data.distance')
      })
    }
    setLoading(false)
  }

  const getSubTaskData = async (taskId) => {
    const subTask = await subTaskApi(taskId)
    return subTask.data
  }

  const handleChangeTabs = (value) => {
    setActiveTab(value)
  }

  return (
    <TaskDetailContext.Provider
      value={{
        state: {
          activeTab,
          loading,
          detailData,
          orgData,
          keys,
          activeKey
        },
        setState: {
          setLoading,
          setKeys,
          setActiveKey
        },
        fnc: {
          handleChangeTabs,
          getData,
          getSubTaskData
        }
      }}
    >
      {children}
    </TaskDetailContext.Provider>
  )
}

export { TaskDetailProvider, TaskDetailContext }
