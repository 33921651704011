import React, { useState, useEffect, useContext } from 'react';
import { Modal, Form, Row, Col, Input, Icon, Upload, message } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { MaintenanceContext } from './maintenance-context';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled from 'styled-components';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import logoPDF from '../../pages/login/image/pdf.png';
import { v4 as uuidv4 } from 'uuid';
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import styles from './css/index.css';
import { findIndex } from 'lodash';
import _ from 'lodash';
import { successNotification, errorNotification, warningNotification } from '../v2/notification'

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const ModalFormComponent = (props) => {
  const { mainState, setMainState } = useContext(MaintenanceContext);

  //--------------------- Center State -----------------------
  const { visible, setVisible, defaultData, setEditAttachmentData, form, view } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue, setFields } = form;
  const intl = useIntl();
  //------------------------- State --------------------------
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [visibleImgGallery, setVisibleImgGallery] = useState(false);
  const [modalViewGallery, setModalViewGallery] = useState();
  const [startIndexGallery, setStartIndexGallery] = useState(0);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (defaultData) {
      const fncSetFile = async (defaultData) => {
        const newAttachments = [];
        for (let i = 0; i < defaultData.attachments.length; i++) {
          const item = defaultData.attachments[i];
          newAttachments.push({
            maintenanceAttachmentId: item.maintenanceAttachmentId,
            name: item.name,
            originFileObj: item.originFileObj,
            originalPath: item.originalPath,
            size: "",
            type: item.type,
            uid: item.uid,
            original: item.type.indexOf('pdf') > -1 ? logoPDF : (item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj)),
            thumbUrl: item.type.indexOf('pdf') > -1 ? logoPDF : (item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj)),
            thumbnail: item.type.indexOf('pdf') > -1 ? logoPDF : (item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj)),
            url: item.type.indexOf('pdf') > -1 ? logoPDF : (item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj)),
          });
        }

        setFileList(newAttachments);
        // setFileList(defaultData.attachments);
      }
      fncSetFile(defaultData);
    }
  }, [defaultData]);


  //---------------------- Center FNC ------------------------
  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const handleCreate = () => {
    // validateFields((err, values) => {
    //   if (err) {
    //     return;
    //   }

    //   // const newArray = [...upload];
    //   // const newArray = [...mainState.attachmentData];
    //   // const count = mainState.attachmentData.length ? mainState.attachmentData.length : 0;

    //   const newArray = [...mainState.dataAttachmentById];
    //   const count = mainState.dataAttachmentById.length ? mainState.dataAttachmentById.length : 0;

    //   const findIndex = mainState.dataAttachmentById.findIndex((item) => item.id === defaultData.id);

    //   // newArray.push({
    //   //   id: uuidv4(),
    //   //   name: values.attachment,
    //   //   description: values.remark,
    //   //   attachments: [...fileList],
    //   //   maintenanceAttachmentDetailId: undefined,
    //   //   maintenanceId: undefined,
    //   //   createdAt: undefined,
    //   //   createdBy: undefined,
    //   // })

    //   newArray.forEach((item, index, array) => newArray[findIndex] = 
    //     {
    //       id: array[findIndex].id,
    //       name: values.attachment,
    //       description: values.remark,
    //       attachments: [...fileList],
    //       maintenanceAttachmentDetailId: array[findIndex].maintenanceAttachmentDetailId ? array[findIndex].maintenanceAttachmentDetailId : undefined,
    //       maintenanceId: array[findIndex].maintenanceId ? array[findIndex].maintenanceId : undefined,
    //       createdAt: array[findIndex].createdAt ? array[findIndex].createdAt : undefined,
    //       createdBy: array[findIndex].createdBy ? array[findIndex].createdBy : undefined,
    //     }
    //   );

    //   setMainState.setDataAttachmentById(newArray);

    //   setVisible(false);
    //   setFileList([]);
    //   resetFields();

    //   // newArray.push({
    //   //   id: uuidv4(),
    //   //   index: count + 1,
    //   //   attachmentName: values.attachment,
    //   //   remark: values.remark,
    //   //   file: [...fileList],
    //   // })

    //   // setMainState.setAttachmentData(newArray);

    //   // setVisible(false);
    //   // setFileList([]);
    //   // resetFields();
    // });

    const newArray = [...mainState.dataAttachmentById];

    validateFields((err, values) => {
      if (err) {
        return;
      } else {
        if (values.attachment) {
          const duplicateAttachment = newArray.filter((col) => {
            return _.trim(values.attachment) === _.trim(col.name) && col.id !== defaultData.id
          });
          if (duplicateAttachment.length > 0) {
            setFields({
              attachment: {
                value: values.attachment,
                errors: [new Error('attachment is duplicate')]
              }
            })
            return;
          } else {
            const findIndex = mainState.dataAttachmentById.findIndex((item) => item.id === defaultData.id);

            newArray.forEach((item, index, array) => newArray[findIndex] =
            {
              id: array[findIndex].id,
              name: values.attachment,
              description: values.remark,
              attachments: [...fileList],
              maintenanceAttachmentDetailId: array[findIndex].maintenanceAttachmentDetailId ? array[findIndex].maintenanceAttachmentDetailId : undefined,
              maintenanceId: array[findIndex].maintenanceId ? array[findIndex].maintenanceId : undefined,
              createdAt: array[findIndex].createdAt ? array[findIndex].createdAt : undefined,
              createdBy: array[findIndex].createdBy ? array[findIndex].createdBy : undefined,
            }
            );

            setMainState.setDataAttachmentById(newArray);

            setVisible(false);
            setFileList([]);
            resetFields();
          }
        }
      }

    });
  }

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setEditAttachmentData();
    // setFileList([]);
  }

  const handlePreview = async (fileList, file) => {
    // const newfile = await fileList.map((item, index) => ({
    //   maintenanceAttachmentId: item.maintenanceAttachmentId,
    //   name: item.name,
    //   originFileObj: item.originFileObj,
    //   originalPath: item.originalPath,
    //   size: item.size,
    //   type: item.type,
    //   uid: item.uid,
    //   original: item.thumbUrl,
    //   thumbUrl: item.thumbUrl,
    //   thumbnail: item.thumbUrl,
    //   url: item.thumbUrl,
    // }));

    const newfile = [];
    for (let i = 0; i < fileList.length; i++) {
      const item = fileList[i];
      newfile.push({
        maintenanceAttachmentId: item.maintenanceAttachmentId,
        name: item.name,
        originFileObj: item.originFileObj,
        originalPath: item.originalPath,
        size: item.size,
        type: item.type,
        uid: item.uid,
        original: item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj),
        thumbUrl: item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj),
        thumbnail: item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj),
        url: item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj),
      });
    }

    let isPDF = /pdf/g.test(file.type);
    if (isPDF) return window.open(file.originalPath, "_blank");
    const fileReduce = newfile.reduce((prev, current) => {
      if (!/pdf/g.test(current.type)) {
        prev.push(current)
      }
      return prev
    }, []);

    const fileFilter = fileReduce.filter(current => !/pdf/g.test(current.type));
    const found = fileReduce.findIndex((element) => element.uid === file.uid);

    setModalViewGallery(fileFilter);
    setStartIndexGallery(found);
    setVisibleImgGallery(!visibleImgGallery);
  };

  const handleChange = async ({ file, fileList }) => {

    if (file.status !== "removed") {
      if (file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg' || file.type === 'image/jpg' || file.type === 'image/PNG') {
        // const newfile = await fileList.map((item, index) => ({

        //   maintenanceAttachmentId: item.maintenanceAttachmentId ? item.maintenanceAttachmentId : undefined,
        //   uid: item.uid,
        //   name: item.name,
        //   type: item.type,
        //   size: item.size,
        //   percent: 100,
        //   originFileObj: item.originFileObj,
        //   originalPath: item.original,
        //   original: item.original,
        // // original: item.type === 'application/pdf' ? logoPDF : item.thumbUrl,
        //   thumbnail: item.type === 'application/pdf' ? logoPDF : item.thumbUrl,
        //   thumbUrl: item.type === 'application/pdf' ? logoPDF : item.thumbUrl,
        //   url: item.type === 'application/pdf' ? logoPDF : item.thumbUrl,
        // }));

        const newfile = [];
        for (let i = 0; i < fileList.length; i++) {
          const item = fileList[i];
          newfile.push({
            maintenanceAttachmentId: item.maintenanceAttachmentId ? item.maintenanceAttachmentId : undefined,
            uid: item.uid,
            name: item.name,
            type: item.type,
            size: item.size,
            originFileObj: item.originFileObj,
            originalPath: item.originalPath,
            original: item.original,
            // original: item.type.indexOf('pdf') > -1 ? logoPDF : (item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj)),
            thumbUrl: item.type.indexOf('pdf') > -1 ? logoPDF : (item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj)),
            thumbnail: item.type.indexOf('pdf') > -1 ? logoPDF : (item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj)),
            url: item.type.indexOf('pdf') > -1 ? logoPDF : (item.maintenanceAttachmentId ? item.thumbUrl : await getBase64(item.originFileObj)),
          });
        }
        setFileList(newfile);
      } else {
        errorNotification(intl.formatMessage({ id: 'warningFileNotSupport', defaultMessage: 'file is not support' }))
      }
    }
  };

  const handleRemove = (file) => {
    const newArrRemove = fileList.filter(item => item.uid !== file.uid);
    setFileList(newArrRemove);
  }

  const handleCancelModalUpload = () => {
    setPreviewVisible(false);
  }

  const handleCancelImgGallery = () => {
    setVisibleImgGallery(false);
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <StyleModalImgGallery
          visible={visibleImgGallery}
          onCancel={handleCancelImgGallery}
          footer={null}
          width={'100%'}
        >
          <ImageGallery
            className={styles.imagegallery}
            items={modalViewGallery}
            showPlayButton={false}
            showIndex={true}
            startIndex={startIndexGallery}
            showFullscreenButton={false}
          />
        </StyleModalImgGallery>
      </div>
    );
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="attachment-upload-text">
        <FormattedMessage id="maintenanceModalAttachmentTextUpload" defaultMessage="Upload" />
      </div>
    </div>
  );

  const formAttachment = () => {
    return (
      <Form>
        <Row>
          <Form.Item>
            <p className='text-style'>
              <FormattedMessage id="maintenanceModalAttachmentTextAttachmentName" defaultMessage="Attachment Name" />
              <LabeRequire req={true} />
            </p>
            {getFieldDecorator("attachment", {
              initialValue: defaultData ? defaultData.name : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'maintenanceModalAttachmentValidateAttachmentName', defaultMessage: 'Please enter attachment name.' })
                },
              ]
            })(
              <Input 
                className='attachment-input-style' 
                placeholder={intl.formatMessage({ id: 'maintenanceModalAttachmentHinAttachmentName', defaultMessage: 'Enter Attachment Name'})}
                autoComplete="off" 
              />
            )}
          </Form.Item>
        </Row>

        <Row>
          <div className="attachment-upload-div">
            <Upload
              className="attachment-upload"
              listType="picture-card"
              accept=".pdf, .jpg, .png, .svg, .jpeg, .PNG"
              fileList={fileList}
              onPreview={(file) => handlePreview(fileList, file)}
              onChange={handleChange}
              onRemove={handleRemove}
              showUploadList={true}
              beforeUpload={() => { return false; }}
            // showUploadList={{
            //   showRemoveIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A3') ? true : false,
            // }}
            >
              {fileList.length >= 500 ? null : uploadButton}
            </Upload>
          </div>
        </Row>

        <Row>
          <Form.Item>
            <p className='text-style'>
              <FormattedMessage id="maintenanceModalAttachmentTextRemark" defaultMessage="Remark" />
              <LabeRequire req={true} />
            </p>
            {getFieldDecorator("remark", {
              initialValue: defaultData ? defaultData.description : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'maintenanceModalAttachmentValidateRemark', defaultMessage: 'Please enter remark' })
                },
              ]
            })(
              <Input
                className='attachment-input-style'
                placeholder={intl.formatMessage({ id: 'maintenanceModalAttachmentHinRemark', defaultMessage: 'Enter Remark' })}
                autoComplete="off" 
              />
            )}
          </Form.Item>
        </Row>
      </Form>
    )
  }

  const ViewAttachment = () => {
    return (
      <Form>
        <Row>
          <Form.Item>
            <p className='text-style'>
              <FormattedMessage id="maintenanceModalAttachmentTextAttachmentName" defaultMessage="Attachment Name" />
              <LabeRequire req={true} />
            </p>
            {getFieldDecorator("attachment", {
              initialValue: defaultData ? defaultData.name : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'maintenanceModalAttachmentValidateAttachmentName', defaultMessage: 'Please enter attachment name.' })
                },
              ]
            })(
              <span >{defaultData ? defaultData.name : undefined}</span>
            )}
          </Form.Item>
        </Row>

        <Row>
          <div className="attachment-upload-div">
            <Upload
              className="attachment-upload"
              listType="picture-card"
              accept=".pdf, .jpg, .png, .svg, .jpeg, .PNG"
              fileList={fileList}
              onPreview={(file) => handlePreview(fileList, file)}
              onChange={handleChange}
              onRemove={handleRemove}
              beforeUpload={() => { return false; }}
              showUploadList={{
                showRemoveIcon: false,
              }}
            >
            </Upload>
          </div>
        </Row>

        <Row>
          <Form.Item>
            <p className='text-style'>
              <FormattedMessage id="maintenanceModalAttachmentTextRemark" defaultMessage="Remark" />
              <LabeRequire req={true} />
            </p>
            {getFieldDecorator("remark", {
              initialValue: defaultData ? defaultData.description : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'maintenanceModalAttachmentValidateRemark', defaultMessage: 'Please enter remark' })
                },
              ]
            })(
              <span >{defaultData ? defaultData.description : undefined}</span>
            )}
          </Form.Item>
        </Row>
      </Form>
    )
  }

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'maintenanceModalAttachmentTitleEdit Attachment', defaultMessage: 'Edit Attachment' })}
        visible={visible}
        onOk={handleCreate}
        onCancel={handleCancel}
        centered
        footer={[
          view ? <>
            <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleCancel}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>
            <Button01 htmlType="submit" key="submit" type="primary" btnsize='wd_df' onClick={handleCancel}  >
              <FormattedMessage id="btnOk" defaultMessage="Ok" />
            </Button01>
          </> : <>
            <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleCancel}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>
            <Button01 htmlType="submit" key="submit" type="primary" btnsize='wd_df' onClick={handleCreate}  >
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button01>
          </>
        ]}
      >
        {view ? <ViewAttachment /> : formAttachment()}
      </Modal>

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={handleCancelModalUpload}
      >
        <img alt="example" src={previewImage} />
      </Modal>

      <HandlerModal />
    </div>
  )
}

const StyleModalImgGallery = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 400px;
    max-height: 500px;
  }

  .ant-modal-close {
    color: #FFFFFF;
    right: 667px;
  }

  .ant-modal-close-x {
    font-size: 24px
  }

  .image-gallery-index {
    margin: 0px 20px 0px 20px;
  }

  .image-gallery-right-nav {
    right: 30%;
  }

  .image-gallery-left-nav {
    left: 30%;
  }
`;

const ModalAttachment = Form.create({ name: "modal_form" })(ModalFormComponent);

export default ModalAttachment;