import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import LabeRequireForm from '../../../components/label-required-form';

const SummaryPaid = (props) => {
  const intl = useIntl();
  const { summary } = props;
  console.log('summary', summary, _.get(summary, 'activeQuote.[0].val'));
  return (
    <div className="price-report">
      <Row className="price-report-row">
        <Col span={6} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
              <LabeRequireForm
                text={
                  intl.formatMessage({ id: 'ARAverageTimetoPay', defaultMessage: 'Average Time to Pay' })
                }
                req={false}
                bold={true}
              />
            </p>
            <p className="price-report-text-span">{_.get(summary,'averageCreditDay.[0].val')}</p>
            {/* <NumberFormat value={_.floor(_.get(summary,'activeQuote.[0].val'), 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
              suffix={' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' })} className="price-report-text-span" /> */}
          </div>
        </Col>
        <Col span={6} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
              <LabeRequireForm
                text={
                  intl.formatMessage({ id: 'ARPaidAfterDueDate', defaultMessage: 'Paid After Due Date' })
                }
                req={false}
                bold={true}
              />
            </p>
            <p className="price-report-text-span">{_.get(summary, 'overdue.[0].val')}</p>
            {/* <NumberFormat value={_.floor(summary.wonQuote, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
              suffix={' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' })} className="price-report-text-span" /> */}
          </div>
        </Col>
        <Col span={6} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
              <LabeRequireForm
                text={
                  intl.formatMessage({ id: 'ARPaidBeforeDueDate', defaultMessage: 'Paid Before Due Date' })
                }
                req={false}
                bold={true}
              />
            </p>
            <p className="price-report-text-span">{_.get(summary, 'ondue.[0].val')}</p>
          </div>
        </Col>
        <Col span={6} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
              <LabeRequireForm text={intl.formatMessage({ id: 'ARTotalBalance', defaultMessage: 'Total Balance' })} req={false} bold={true} />
            </p>
            <p className="price-report-text-span">{_.get(summary, 'balance.[0].val')}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SummaryPaid;
