import httpClient from '../../components/axiosClient';

const getCustomerLoadMore = async (pageStart, pageEnd, search = '') => {
  const comCode = localStorage.getItem('comCode');
  const name = search ? `&customerName=${search}` : '';
  try {
    const response = await httpClient.get(
      `/v3/business-partner/manager/company/${comCode}/customer-informations/get/load-more/noprivacy?indexStart=${pageStart}&indexEnd=${pageEnd}${name}`
    );
    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export { getCustomerLoadMore };
