import React, { useRef, useEffect } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import styled from 'styled-components';
import './css/index.css';

const ModalFormComponent = ({ form,
  visible,
  handleCancel,
  handleAddItem,
  handleKeyUp,
  visibleModalAdd,
  focusInput,
  triggerAddItem }) => {

  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p-validate">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const { getFieldDecorator, resetFields ,setFieldsValue} = form;
  const ref = useRef();
  const intl = useIntl();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      resetFields();
      setFieldsValue({ ['amount']: 0 })
    }
  }, [visible, focusInput, triggerAddItem])


  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {

      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {

        e.preventDefault();

      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => { return o.charCodeAt(0) === 46 ? 1 : 0 })
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }

    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  }

  return (
    <div>
      <Modal
        title={intl.formatMessage({
          id: 'modalMaintenanceColumnsTitleAddLabors',
          defaultMessage: 'Add Labors',
        })}
        className="sale-oreder-modal-add-item"
        visible={visible}
        onOk={handleAddItem}
        onCancel={handleCancel}
        width={916}
        centered
        // footer={[
        //   <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleCancel}>
        //     <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        //   </Button02>,
        //   <Button01 htmlType="submit" key="submit" type="primary" btnsize='wd_df' onClick={handleAddItem}  >
        //     <FormattedMessage id="btnAdd" defaultMessage="Add" />
        //   </Button01>,
        // ]}

        okText={intl.formatMessage({ id: 'btnAdd', defaultMessage: 'Add' })}
        cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
        okButtonProps={
          {
            style: {
              width: '96px'
            }
          },
          {
            type: "primary"
          }
        }
        cancelButtonProps={
          {
            style: {
              width: '96px'
            }
          },
          {
            type: "danger"
          }
        }
      >
        <div className="sale-oreder-modal-add-body">
          <Form className="modal-add-form" layout="inline" onKeyUp={handleKeyUp} onSubmit={handleAddItem}>
            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalformlaborsDescription', defaultMessage: 'Description' })} req={true} />}
            >
              {getFieldDecorator('description', {
                rules: [{ required: true, message: intl.formatMessage({ id: 'modalAddItemModalformValidatelaborsDescription', defaultMessage: 'Please input description' }) }],
              }
              )(
                <Input
                  ref={ref}
                  autoFocus
                  style={{ width: '650px' }}
                  placeholder={intl.formatMessage({ id: 'modalAddItemModalformPlaceholderChargeAmount', defaultMessage: 'Select charge amount' })}
                  autoComplete="off" 
                  />
              )}
            </Form.Item>
            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalformlaborsChargeAmount', defaultMessage: 'Charge Amount (Baht)' })} req={true} />}
            >
              {getFieldDecorator('amount', {
                initialValue: 0,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalAddItemModalformValidatelaborsChargeAmount', defaultMessage: 'Please input charge amount' })
                  }
                ],
              }
              )(
                // <Input
                //   style={{ width: '200px', textAlign: 'right' }}
                //   placeholder={intl.formatMessage({ id: 'modalAddItemModalformPlaceholderChargeAmount', defaultMessage: '0.00' })}
                //   maxLength={20}
                //   onKeyDown={handleKeyDownNumberDecimal}
                // />

                <InputNumber
                  className='sale-oreder-none-count'
                  style={{ width: '200px' }}
                  placeholder={intl.formatMessage({ id: 'modalAddItemModalPartformPlaceholderUnitPrice', defaultMessage: '0.00' })}
                  maxLength={20}
                  onKeyDown={handleKeyDownNumberDecimal}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const ModalAddLabors = Form.create({ name: "modal_form" })(ModalFormComponent);

export default ModalAddLabors