import React from 'react';
import { Table, Card, Switch, Input, InputNumber } from 'antd';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import Button03 from '../../../../components/v2/button_03';
import { PageSettings } from '../../../../config/page-settings';
import { useIntl, FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

export default ({
  data,
  toggleEdit,
  setToggleEdit,
  handleEdit,
  handleSave,
  onChangeSwitch,
  onChangeInput,
  handleCancel,
  onChangeIncentive,
  onSwitchIncentive,
}) => {
  const intl = useIntl();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      className: 'onTop',
      key: 'index',
      width: '5%',
      render: (text, record, index) => record.index + 1,
    },
    {
      title: intl.formatMessage({ id: `workforceTaskTypeColNameWorkForce`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      className: 'onTop',
      width: '12.5%',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `workforceTaskTypeColLastNumberWorkForce`, defaultMessage: 'Lastest number' }),
      dataIndex: 'latestNumber',
      className: 'onTop',
      width: '15%',
      key: 'latestNumber',
    },
    {
      title: intl.formatMessage({ id: `workforceTaskTypeColDescriptionWorkForce`, defaultMessage: 'Description' }),
      dataIndex: 'description',
      className: 'onTop',
      key: 'description',
      width: '17.5%',
    },
    {
      title: intl.formatMessage({ id: `workforceTaskTypeColStandardWorkForce`, defaultMessage: 'Standard Price (Baht)' }),
      dataIndex: 'unitPrice',
      className: 'onTop',
      key: 'unitPrice',
      align: 'right',
      width: '12.5%',
      render: (text, record, index) =>
        toggleEdit ? (
          // <Input
          //   type='text'
          //   className='input-border-none'
          //   size='small'
          //   min={0}
          //   id={record.id}
          //   defaultValue={record.unitPrice}
          //   onChange={onChangeInput}
          //   onKeyDown={handleKeyDownNumber}
          //   autoComplete="off"
          // // pattern="[+-]?\d+(?:[.,]\d+)?"
          // />

          <InputNumber
            className="task-type-item-inputNumber"
            placeholder="input"
            maxLength={10}
            min={0}
            onBlur={onBlur}
            onMouseLeave={onBlur}
            f
            onKeyDown={handleKeyDownNumber}
            defaultValue={record.unitPrice}
            onChange={(value) => onChangeInput(value, record)}
            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        ) : (
          // <InputNumber
          //   className="task-type-item-inputNumber"
          //   // id={record.id}
          //   placeholder="input"
          //   maxLength={10}
          //   min={0}
          //   onKeyDown={handleKeyDownNumber}
          //   defaultValue={record.unitPrice}
          //   onChange={(value) => onChangeInput(value, record)}
          //   formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          //   parser={value => value.replace(/\$\s?|(,*)/g, '')}
          // />

          <NumberFormat value={parseFloat(record.unitPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} />
        ),
    },
    {
      title: intl.formatMessage({ id: `workforceTaskTypeColxKmWorkForce`, defaultMessage: 'x km' }),
      dataIndex: 'km',
      className: 'onTop',
      key: 'km',
      width: '12.5%',
      align: 'center',
      render: (text, record, index) => {
        return toggleEdit ? (
          <Switch id={record.id} disabled={!toggleEdit} defaultChecked={record.km} onChange={onChangeSwitch} />
        ) : (
          <Switch id={record.id} disabled={!toggleEdit} checked={record.km} onChange={onChangeSwitch} />
        );
      },
    },
    {
      title: intl.formatMessage({ id: `workforceTaskTypeColIncentiveWorkForce`, defaultMessage: 'Incentive Price (Baht)' }),
      dataIndex: 'incentivePrice',
      className: 'onTop',
      key: 'incentivePrice',
      align: 'right',
      width: '12.5%',
      render: (text, record, index) =>
        toggleEdit ? (
          // <Input
          //   type='text'
          //   className='input-border-none'
          //   size='small'
          //   min={0}
          //   id={record.id}
          //   defaultValue={record.incentivePrice}
          //   onChange={onChangeIncentive}
          //   onKeyDown={handleKeyDownNumber}
          //   autoComplete="off"
          // pattern="[+-]?\d+(?:[.,]\d+)?"
          //

          <InputNumber
            className="task-type-item-inputNumber"
            placeholder="input"
            autoComplete="off"
            maxLength={10}
            min={0}
            onKeyDown={handleKeyDownNumber}
            onMouseLeave={onBlur}
            defaultValue={record.incentivePrice}
            onChange={(value) => onChangeIncentive(value, record)}
            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        ) : (
          <NumberFormat value={parseFloat(record.incentivePrice).toFixed(2)} displayType={'text'} thousandSeparator={true} />
        ),
    },
    {
      title: intl.formatMessage({ id: `workforceTaskTypeColxKmWorkForce`, defaultMessage: 'x km' }),
      dataIndex: 'incentiveKm',
      className: 'onTop',
      key: 'incentiveKm',
      width: '12.5%',
      align: 'center',
      render: (text, record, index) => {
        return toggleEdit ? (
          <Switch id={record.id} disabled={!toggleEdit} defaultChecked={record.incentiveKm} onChange={onSwitchIncentive} />
        ) : (
          <Switch id={record.id} disabled={!toggleEdit} checked={record.incentiveKm} onChange={onSwitchIncentive} />
        );
      },
    },
  ];

  const format = (num) => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');

  const onBlur = (e) => {
    const valueBlur = e.target.value;
    return valueBlur && valueBlur.replace(/\$\s?|(,*)/g, '');
  };

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 0) {
        e.preventDefault();
      } else {
        return true;
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div style={{ width: 'auto' }}>
          <Card
            className="hieght-auto"
            title={intl.formatMessage({ id: 'menuTaskType', defaultMessage: 'Task Type' })}
            extra={
              toggleEdit
                ? [
                    <div>
                      <Button02 style={{margin : '0px 0px 0px 10px'}} onClick={handleCancel} margin=" unset">
                        <span className="new-font-semi">
                          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                        </span>
                      </Button02>

                      <Button01 type="primary" onClick={handleSave} onSubmit={handleSave}>
                        <span className="new-font-semi">
                          {' '}
                          <FormattedMessage id="btnSave" defaultMessage="Save" />
                        </span>
                      </Button01>
                    </div>,
                  ]
                : [
                    checkPermissionAction(`P25PG1C1`, `P25PG1C1A3`) ? (
                      <div>
                        <Button01 type="primary" onClick={handleEdit}>
                          <span className="new-font-semi">
                            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                          </span>
                        </Button01>
                      </div>
                    ) : null,
                  ]
            }
          >
            <Table rowKey={(record) => record.index} dataSource={data} columns={columns} pagination={false} />
          </Card>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
