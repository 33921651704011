import React, { useRef, useCallback, useContext, useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker} from '@react-google-maps/api';
import Geocode from "react-geocode";
import _ from 'lodash';
import { CustomerContext } from '../add-customer/add-customer-context';
import { useAppContext } from '../../includes/indexProvider';

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

export default ({  }) => {
  const { stateCustomer } = useContext(CustomerContext);
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);

  useEffect(() => {
    if (stateCustomer && stateCustomer.filterCustomerLocation) {
      setPosition({lat: _.get(stateCustomer, 'filterCustomerLocation.lat'), lng: _.get(stateCustomer, 'filterCustomerLocation.lng')});
      setCenter({lat: _.get(stateCustomer, 'filterCustomerLocation.lat'), lng: _.get(stateCustomer, 'filterCustomerLocation.lng')});
    }
    else{
    setCenter(latlng);
    setPosition(undefined);
    

    }
  }, [stateCustomer.filterCustomerLocation]);

  const libraries = ["places"];
  const mapContainerStyle = {
    height: `130px`,
    width: '190px',
    position: 'relative'
  }

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onMarkerClick = () => {
    if (stateCustomer && stateCustomer.filterCustomerLocation) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(stateCustomer, 'filterCustomerLocation.lat')}, ${_.get(stateCustomer, 'filterCustomerLocation.lng')}`,'_blank')
    }
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        <Marker
          position={position}
          animation={window.google.maps.Animation.DROP}
          onClick={onMarkerClick}
          // draggable={true}
          // onDragEnd={onDragEnd}
        />
      </GoogleMap>
    </div>
  )
}
