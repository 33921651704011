import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge, TimePicker } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import LabeRequireForm from '../../../../../components/label-required-form';

const { Option } = Select;
const { Search } = Input;
// const dateFormat = 'ddd, MMM DD YYYY';
const dateFormat = 'DD/MM/YYYY';
const timeformat = 'HH:mm';

const CustomerForm = ({
  form,
  setDataForm,
  dataForm,
  initialMemberList,
  formCustomerRef,
  isOpenQuotation,
  handleOpen,
  record,
  handleWarhouseFromPopupScroll,
  wareHouseFromList,
  handleWarhouseToPopupScroll,
  wareHouseToList,
  setNotWarehouseFromId,
  setNotWarehouseToId,
  setTypeCode,
  setAssignName,
}) => {
  // console.log('CustomerForm', form);
  const { getFieldDecorator } = form;
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQT, setIsOpenQT] = useState(false);
  const AssignType = [
    { id: 'internal', name: 'Internal' },
    { id: 'external', name: 'External' },
  ];

  const handleOpenQuotationNo = () => {
    setIsOpenQT(false);
  };

  const handleSelectQuotationNo = (value) => {
    setIsOpenQT(false);
  };

  const handleCancelQuotationNo = () => {
    setIsOpenQT(false);
  };

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>
        {o.fullname}
      </Option>
    );
  };

  return (
    <Form name="customer" colon={false}>
      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm bold={true}text={intl.formatMessage({ id: 'TFFromWareHouse', defaultMessage: 'From WareHouse' })} req={false} />}
          >
            {_.get(record, 'warehouseCodeFrom')} · {_.get(record, 'warehouseFrom')}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm bold={true}text={intl.formatMessage({ id: 'TFToWareHouse', defaultMessage: 'To WareHouse' })} req={false} />}
          >
            {_.get(record, 'warehouseCodeTo')} · {_.get(record, 'warehouseTo')}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm bold={true}text={intl.formatMessage({ id: 'TFAssignTo', defaultMessage: 'Assign To' })} req={false} />}
            className="form-line-height"
          >
            {_.get(record, 'assignToTypeTxt')} - {_.get(record, 'assignToName')}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm bold={true}text={intl.formatMessage({ id: 'TFTransferDate', defaultMessage: 'Transfer Date' })} req={false} />}
          >
            {_.get(record, 'transferDateTxt')}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm bold={true}text={intl.formatMessage({ id: 'TFSubmittedBy', defaultMessage: 'Submitted by' })} req={false} />}
          >
            {_.get(record, 'submitBy')}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm bold={true}text={intl.formatMessage({ id: 'TFSubmittedDate', defaultMessage: 'Submitted Date' })} req={false} />}
          >
            {_.get(record, 'submitDateTxt')}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={24}>
          <Form.Item label={<LabeRequireForm bold={true}text={intl.formatMessage({ id: 'TFRemark', defaultMessage: 'Remark' })} req={false} />}>
            {_.get(record, 'remark') ? _.get(record, 'remark') : '-'}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CustomerForm.defaultProps = {};

export default CustomerForm;
