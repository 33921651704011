import React, { useEffect } from 'react'
import { Select, Checkbox, Table, Form } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import LabeRequireForm from '../../../../components/label-required-form'
import _ from 'lodash'

const { Option } = Select

const ListItemDetail = (props) => {
  const { columns, handleClickRow, onChange, vendorData, itemList, itemListTotal, handleChange, form,
    tableLoading } = props
  const intl = useIntl()
  const { getFieldDecorator, getFieldValue } = form

  useEffect(() => {
    if (getFieldValue('vendor')) {
      onChange(getFieldValue('vendor'), 'vendor')
    }
  }, [getFieldValue('vendor')])


  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  return (
    <div style={{ height: '625px', padding: "16px" }}>
      <div style={{ marginBottom: '24px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <Form form={form} layout="vertical">
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormVendor', defaultMessage: 'Vendor or Supplier' })} req={false} />
              }
            >
              {getFieldDecorator('vendor', {
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'purchesModalFormPlaceholderVendor', defaultMessage: 'Select Vendor or Supplier' })}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  style={{ width: '400px' }}
                >
                  {vendorData && vendorData.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={itemList}
          onChange={handleChange}
          scroll={{ y: 380 }}
          loading={tableLoading}
          pagination={{
            showTotal: showTotal,
            defaultCurrent: 1,
            total: itemListTotal,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  handleClickRow(record)
                }
              },
            };
          }}
        />
        {itemList && itemList.length !== 0 ? (
          <div className="total-items-timeAttendance">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${itemListTotal || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ListItemDetail