import React, { useState, useEffect } from 'react';
import { Card, Tree, Input, Icon, Layout, Modal, Menu } from 'antd';
import { useRoleContext } from './RoleContext';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import { notificationWithIcon } from '../../components/notification';
import httpClient from '../../components/axiosClient';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/Role.css';

const { TreeNode, DirectoryTree } = Tree;
const { confirm } = Modal;

export default ({ addRole, setAddNewRole }) => {
  const app = useRoleContext();
  const intl = useIntl();
  const listRoles = app.state.roles;
  let triggerGetApi = app.state.triggerGetApi;


  const [txtSearch, setTxtSearch] = useState('');
  const comId = localStorage.getItem('comId');
  const [search, setSearch] = useState("");
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);




  useEffect(() => {
    addRole(app.state.rolesSelect);
    setCountries(listRoles);
  }, [app.state.roles]);

  /**
   * Search Input Role
   */
  useEffect(() => {
    setFilteredCountries(
      countries.filter((country) =>
        country.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, countries]);

  /**
   * Add New
   */
  const handlerNewRole = () => {
    setAddNewRole(true);
    app.fnc.setRolesSelect();
    app.fnc.setRolesAddNew();
    app.fnc.setRolesSelectName();
    app.fnc.setRoleTracking()

    app.fnc.setAlamRequestSwitch(false)
    app.fnc.setAlamTaskSwitch(false)
    app.fnc.setAlamRoleTracking(false)
  }

  /**
   * Select Node
   */
  const handleSelectNode = (key) => {
    let id = Number(key[0]);
    const found = app.state.roles.findIndex(elements => elements.per_gro_id === id);
    const roleName = found !== -1 ? app.state.roles[found].name : '';
    const roleTracking = found !== -1 ? app.state.roles[found].status_track_location : '';
    const setAlamRequest = found !== -1 ? app.state.roles[found].alarm_request : '';
    const setAlamTask = found !== -1 ? app.state.roles[found].alarm_task : '';

    app.fnc.setAlamRequestSwitch()
    app.fnc.setAlamTaskSwitch()
    app.fnc.setAlamRoleTracking()

    app.fnc.setRolesSelect(key[0]);
    app.fnc.setRolesSelectName(roleName);

    app.fnc.setRoleTracking(roleTracking)
    app.fnc.setAlamTask(setAlamTask)
    app.fnc.setAlamRequeset(setAlamRequest)

    app.fnc.setRefreshSwitch(!app.state.refreshSwitch);
    setAddNewRole(false);
  }

  /**
   * Handler Search
   */
  const handlerSearchOnChange = (event) => {
    setTxtSearch(event.target.value);
  }

  const handlerClickDelete = () => {
    confirm({
      className: "userManagement-role-modal-confirm",
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okButtonProps: {
        style: {
          width: '96px',
          background: '#6490cf',
          border: '1px solid #6490cf',
          borderRadius: '8px',
          hover: {
            background: '#ffffff',
            color: '#6490cf',
          },
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
          border: '1px solid #6490cf',
          borderRadius: '8px',
          backgroundColor: '#ffffff',
          color: '#6490CF',
          
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/company/${comId}/role/${app.state.rolesSelect}`);
          if (response.status === 200) {
            notificationWithIcon('success', response.data.message);
            app.fnc.setTriggerGetApi(!triggerGetApi);
            app.fnc.setRolesSelectName();
            app.fnc.setRoleTracking();
            app.fnc.setRolesSelect('');
            setAddNewRole(false);

          } else {
            notificationWithIcon('error', response.data.message);
          }
        }
        catch (error) {
          notificationWithIcon('error', error.response.data.message);
        }
      },
      onCancel() { },
    });
  }


  // const roles = app.state.roles.map(element => element.name.indexOf(txtSearch) > -1 ? <TreeNode className='styleTreeNode' key={element.per_gro_id} title={element.name} /> : null);

  return (
    <div className='role-card-menu'>
      <Card
        className="role-card-menu-tree"
        title={<FormattedMessage id="UserManagementRoleMenuTitleRole" defaultMessage="Role" />}
        extra={
          <span>
            {app.state.rolesSelect ?
              <Button02 style={{ margin: '0px 0px 0px 10px' }}
                fontsize='md'
                btnsize='wd_df'
                onClick={() => handlerClickDelete()}
              >
                <FormattedMessage id="UserManagementRoleMenuBtnDelete" defaultMessage="Delete" />
              </Button02> : null
            }
            <Button01
              style={{ margin: "0px 0px 0px 10px" }}
              type='primary'
              fontsize='md'
              btnsize='wd_df'
              onClick={() => handlerNewRole()}
            >
              <FormattedMessage id="UserManagementRoleMenuBtnAddNew" defaultMessage="Add New" />
            </Button01>
          </span>
        }
      >
        <div style={{ padding: '24px 24px 8px 24px' }}>
          <Input
            prefix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
            suffix={null}
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            onChange={(e) => setSearch(e.target.value)}
            autoComplete="off"
          />
        </div>

        <div className="role-menu-directory">
          <DirectoryTree
            showIcon={false}
            onSelect={handleSelectNode}
            selectedKeys={[app.state.rolesSelect]}
          >
            {filteredCountries.map((country) => (
              <TreeNode className="styleTreeNode" key={country.per_gro_id} title={country.name} />
            ))}
          </DirectoryTree>
        </div>
      </Card>
    </div>
  );
}
