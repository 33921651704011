import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Switch } from 'antd';
import styled from 'styled-components';
import cssStyle from './css/Role.css'
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';


export default Form.create({
  name: 'role',
  onFieldsChange(props, changedFields) {
  },
  mapPropsToFields(props) {
    return {
      roleName: Form.createFormField({
        value: props.roleName,
      }),
      roleTracking: Form.createFormField({
        value: props.alamRoleTracking,
      }),
      alarmRequest: Form.createFormField({
        value: props.alamRequestSwitch,
      }),
      alarmTask: Form.createFormField({
        value: props.alamTaskSwitch,
      }),
    };
  },
})((props) => {
  const { getFieldDecorator } = props.form;
  const intl = useIntl();
  // const [checkSwitch, setCheckSwitch] = useState(false);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const LabelRequire = styled.label`
    color: #ff0000;
  `;
  return (
    <div className="role-content-form">
      {/* <Row gutter={[48]}> */}
      <Form layout='vertical'>
        <Row>
          <Col span={6}>
            <Form.Item label={
              <LabeRequire
                text={intl.formatMessage({ id: 'UserManagementRoleLblRoleName', defaultMessage: 'Role Name' })}
                req={true}
              />
            }>
              {getFieldDecorator('roleName', {
                // initialValue: props.valueDefaltChange ? props.valueDefaltChange : undefined,
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'UserManagementRoleValidateRoleName', defaultMessage: 'Please enter your role name.' }),
                }],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'UserManagementRoleHintRoleName', defaultMessage: 'Please enter role name' })}
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row style={{paddingTop:'16px'}}>
          <Col span={5}>
            <Form.Item>
              <span style={{ marginRight: '16px' }}><FormattedMessage id="UserManagementRoleTrackingText" defaultMessage="Tracking Location" /></span>
              {getFieldDecorator('roleTracking', { valuePropName: 'checked' }, {
              })(<Switch />)}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item>
              <span style={{ marginRight: '16px' }}><FormattedMessage id="UserManagementRoleAlarmRequestText" defaultMessage="Alarm Request" /></span>
              {getFieldDecorator('alarmRequest', { valuePropName: 'checked' }, {
              })(<Switch />)}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item>
              <span style={{ marginRight: '16px' }}><FormattedMessage id="UserManagementRoleAlarmTaskText" defaultMessage="Alarm Task" /></span>
              {getFieldDecorator('alarmTask', { valuePropName: 'checked' }, {
              })(<Switch />)}
            </Form.Item>
          </Col>
        </Row>

      </Form>

      {/* </Row> */}
    </div>
  );
});