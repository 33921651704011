import React from 'react'
import { Form, Row, Col, Input, Divider, Table } from 'antd'
import LabeRequireForm from '../../../../components/label-required-form'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'

const PurchaseCreateDetail = (props) => {
  const { form, columns, columnsAtt, selectItem, attachmentData, editDefault } = props
  const intl = useIntl()
  const styleText = {
    color: '#050505',
    fontSize: '13px'
  }

  return (
    <div style={{ height: '540px', overflowY: 'auto' }}>
      <Form form={form} layout="vertical" className='po-form'>
        <div style={{ padding: '0px 24px 24px 24px' }}>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormVendor', defaultMessage: 'Vendor or Supplier' })} req={false} />
                }
              >
                <span style={styleText}>{_.get(editDefault, 'vendorSupplier')}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormContact', defaultMessage: 'Contact' })} req={false} />
                }
              >
                <span style={styleText}>{_.get(editDefault, 'vendorSupplierContactDetail.vendorSupplierContactName')} ・ {_.get(editDefault, 'vendorSupplierContactDetail.vendorSupplierContactPhone')}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormPaymentTerm', defaultMessage: 'Payment Term (Days)' })} req={false} />
                }
              >
                <span style={styleText}>{_.get(editDefault, 'paymentTerm') || 0}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormQuotationNo', defaultMessage: 'Quotation No.' })} req={false} />
                }
              >
                <span style={styleText}>{_.get(editDefault, 'quotationNo') || "-"}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormIssueDate', defaultMessage: 'Issue Date' })} req={false} />
                }
              >
                <span style={styleText}>{_.get(editDefault, 'issueDateTxt') || "-"}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormReferenceNo', defaultMessage: 'Reference No.' })} req={false} />
                }
              >
                <span style={styleText}>{_.get(editDefault, 'referenceNo') || "-"}</span>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="purchesModalTitleItem" defaultMessage="Item" />
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }}>
          <Table
            columns={columns}
            dataSource={selectItem}
            pagination={false}
            scroll={{ x: 1200 }}
          />
        </div>
        <div style={{ padding: '0px 24px', marginBottom: '24px' }}>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {_.get(editDefault, 'remark') || '-'}
              </Form.Item>
            </Col>
            <Col span={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                <div>
                  <FormattedMessage id="purchesModalFormSubtotal" defaultMessage="Subtotal" />
                </div>
                <div>
                  {_.get(editDefault, 'subtotalTxt')}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div style={{ marginRight: '46px' }}>
                  <FormattedMessage id="purchesModalFormDiscount" defaultMessage="Discount" />
                </div>
                <div style={{ marginRight: '125px' }}>
                  <span style={styleText}>{(_.get(editDefault, 'discountPercent') || 0).toFixed(2) || 0} %</span>
                </div>
                <div>
                  <span style={styleText}>{_.get(editDefault, 'discountTxt')}</span>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div>
                  <FormattedMessage id="purchesModalFormSubTotalAfterDiscount" defaultMessage="Subtotal After Discount" />
                </div>
                <div>
                  {_.get(editDefault, 'subtotalAfterDisTxt')}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div style={{ marginRight: '46px' }}>
                  <FormattedMessage id="purchesModalFormTax" defaultMessage="Tax" />
                </div>
                <div style={{ marginRight: '80px' }}>
                  <span style={styleText}>{_.get(editDefault, 'taxPercent') || 0} %</span>
                </div>
                <div>
                  <span style={styleText}>{_.get(editDefault, 'taxTxt')}</span>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div>
                  <FormattedMessage id="purchesModalFormSubTotal" defaultMessage="Total" />
                </div>
                <div>
                  {_.get(editDefault, 'total')}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="purchesModalTitleAttachment" defaultMessage="Attachment" />
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }}>
          <Table
            columns={columnsAtt}
            dataSource={attachmentData}
            pagination={false}

          />
        </div>
      </Form >
    </div >
  )
}

export default PurchaseCreateDetail
