import React, { useState, useRef, useEffect } from 'react'
import { Modal, Tabs } from 'antd'
import SignatureUpload from './signature-upload'
import SignatureDraw from './signature-draw'
import { useIntl,FormattedMessage } from 'react-intl';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import _ from 'lodash';
import { AddSignature } from '../../controllers/user/signature';
import { successNotification, errorNotification } from '../v2/notification'
import './css/index.css'

const { TabPane } = Tabs

export default (props) => {
  const { visible, setVisible, memComId, setTrigger } = props
  const intl = useIntl();
  const [keyTab, setKeyTab] = useState('upload')
  const [uploadImg, setUploadImg] = useState()
  const [uploadStatus, setUploadStatus] = useState(false)
  const [mouseEvent, setMouseEvent] = useState()
  const [fileList, setFileList] = useState([])
  const refCanvas = useRef()

  const handleSave = async () => {
    if (keyTab === 'upload') {
      let formData = new FormData();
      formData.append('file', uploadImg);
      formData.append('memComId', memComId);
      formData.append('name', memComId);
      const response = await AddSignature(formData)
      if (_.size(response) !== 0) {
        if (_.get(response, 'status.code') === 200) {
          successNotification(_.get(response, 'status.message'))
        } else {
          errorNotification(_.get(response, 'status.message'))
        }
      } else {
        errorNotification('error')
      }

    } else {
      const img64 = refCanvas.current.getCanvas().toDataURL('image/png')
      const tofile = dataURLtoFile(img64, memComId)
      if (tofile) {
        let formData = new FormData();
        formData.append('file', tofile);
        formData.append('memComId', memComId);
        formData.append('name', memComId);
        const response = await AddSignature(formData)
        if (_.size(response) !== 0) {
          if (_.get(response, 'status.code') === 200) {
            successNotification(_.get(response, 'status.message'))
          } else {
            errorNotification(_.get(response, 'status.message'))
          }
        } else {
          errorNotification('error')
        }
      }
    }
    setVisible(false)
    setKeyTab('upload');
    if (_.get(refCanvas, 'current')) { refCanvas.current.clear(); }
    setTrigger(current => !current)
    setFileList([])
    setMouseEvent();
    setUploadImg();
  }

  const handleCancel = () => {
    setVisible(false)
    setKeyTab('upload');
    if (_.get(refCanvas, 'current')) { refCanvas.current.clear(); }
    setUploadStatus(false)
    setUploadImg()
    setFileList([])
    setMouseEvent();
  }

  const handleTabsClick = (key) => {
    setKeyTab(key)
  }

  const handleClearCanvas = () => {
    refCanvas.current.clear();
    setMouseEvent()
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const dragStart = (value) => {
    setMouseEvent(value)

  }


  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'signatureCardTitle', defaultMessage: 'Signature' })}
        centered={true}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleSave}
        bodyStyle={{ padding: 'unset' }}
        width={500}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleCancel} style={{margin:'0px 5px'}}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          keyTab === 'signature' ?
            <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleClearCanvas} style={{margin:'0px 5px'}} >
              <FormattedMessage id="btnClear" defaultMessage="Clear" />
            </Button02>
            : null,
          <Button01 htmlType="submit" key="submit" type="primary" btnsize='wd_df' onClick={handleSave} className={uploadImg || mouseEvent ? null :'upload-button-disbled test-upload'}
            disabled={uploadImg || mouseEvent ? false : true}  style={{margin:'0px 5px'}}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}

      >
        <Tabs activeKey={keyTab}  onTabClick={handleTabsClick}>
          <TabPane tab={intl.formatMessage({ id: 'modalSignatureUploadTab', defaultMessage: 'Upload' })} key="upload">
            <SignatureUpload
              setUploadStatus={setUploadStatus}
              uploadStatus={uploadStatus}
              setUploadImg={setUploadImg}
              uploadImg={uploadImg}
              setFileList={setFileList}
              fileList={fileList}
            />
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: 'modalSignatureDrawTab', defaultMessage: 'Draw' })} key="signature">
            <SignatureDraw refCanvas={refCanvas} dragStart={dragStart} />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
