import React from 'react'
import { Form, Modal } from 'antd'
import ModalUOMAdd from './component/add'
import ModalUOMEdit from './component/edit'
import { useIntl, FormattedMessage } from 'react-intl'
import Button02 from '../v2/button_02'
import Button01 from '../v2/button_01'
import { createUoMCategory, editUoMCategory } from '../../controllers/uom/uom'
import _ from 'lodash'
import { successNotification, errorNotification } from '../v2/notification'

const ModalCategoryUOM = (props) => {
  const { form, type, visible, setVisible, setTrigger, selectCategory, setSelectCategory } = props
  const { validateFields, resetFields } = form
  const intl = useIntl()

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        const body = {
          "name": type === 'add' ? _.get(values, 'uomCategoryAdd') : _.get(values, 'uomCategoryEdit'),
          "description": type === 'add' ? _.get(values, 'descriptionAdd') : _.get(values, 'descriptionEdit'),
        }
        const response = type === 'add' ? await createUoMCategory(body) : await editUoMCategory(_.get(selectCategory, 'uomCategoryId'), body)
        if (response.status === 200) {
          if (_.get(response, 'data.error.status') === 200) {
            errorNotification(_.get(response, 'data.error.message'))
          } else {
            setTrigger(current => !current)
            setSelectCategory(_.get(response, 'data.data'))
            setVisible(false)
            successNotification(_.get(response, 'data.status.message'))
            resetFields();
          }
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      } catch (error) {
        errorNotification(_.get(error, 'response.data.status.message'))
      }
    })
  }


  const handleClose = () => {
    setVisible(false)
    resetFields();
  }

  return (
    <div>
      <Modal
        title={
          type === 'add'
            ? intl.formatMessage({ id: 'modalCategoryUOMTitleAdd', defaultMessage: 'Add UoM Category' })
            : intl.formatMessage({ id: 'modalCategoryUOMTitleEdit', defaultMessage: 'Edit UoM Category' })
        }
        visible={visible}
        width={400}
        centered={true}
        onCancel={handleClose}
        footer={[
          <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        {
          type === "add" ? (
            <ModalUOMAdd form={form} />
          ) : (
            <ModalUOMEdit form={form} selectCategory={selectCategory} />
          )
        }
      </Modal>
    </div>
  )
}

const ModalFormUOM = Form.create({
  name: 'modal_form',
})(ModalCategoryUOM);


export default ModalFormUOM
