import React, { useContext } from 'react';
import { Row, Col, Form, Icon, DatePicker, TimePicker, Input, Avatar, Badge } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { DeliveryShipmentDetailContext } from '..';
import LabeRequireForm from '../../../../../components/label-required-form';
import moment from 'moment';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';

const DeliveryOrderTaskDetailForm = (props) => {
  const { data } = props;
  return (
    <div style={{ backgroundColor: '#FFFF', borderTop: '1px solid #E5E5E5' }}>
      <Form>
        <div style={{ padding: '20px' }}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="deliveryHeaderStyle">
                <FormattedMessage id="deliveryOrderDetailTaskName" defaultMessage="Task Name" />
              </div>
              <div className="deliveryValueStyle">{_.get(data, 'information.taskName')}</div>
            </Col>
            <Col span={12}>
              <div className="deliveryHeaderStyle">
                <FormattedMessage id="deliveryOrderDetailTeam" defaultMessage="Team" />
              </div>
              <div className="deliveryValueStyle">{_.get(data, 'information.orgName')}</div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="deliveryHeaderStyle">
                <FormattedMessage id="deliveryOrderDetailStartDate" defaultMessage="Start Date" />
              </div>
              <div className="deliveryValueStyle">
                {moment(_.get(data, 'information.startDate'), 'YYYY-MM-DD').format('DD/MM/YYYY')}{' '}
                {moment(_.get(data, 'information.startTime'), 'h:mm').format('HH:mm')}
              </div>
            </Col>
            <Col span={12}>
              <div className="deliveryHeaderStyle">
                <FormattedMessage id="deliveryOrderDetailDueDate" defaultMessage="Due Date" />
              </div>
              <div className="deliveryValueStyle">
                {moment(_.get(data, 'information.dueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY')}{' '}
                {moment(_.get(data, 'information.dueTime'), 'h:mm').format('HH:mm')}
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="deliveryHeaderStyle">
                <FormattedMessage id="deliveryOrderDetailFrom" defaultMessage="From" />
              </div>
              <div className="deliveryValueStyle">
                {_.get(data, 'address.fromLocation')
                  ? _.get(data, 'address.fromLocation') + ' · ' + _.get(data, 'address.fromLocation')
                  : '-'}
              </div>
            </Col>
            <Col span={12}>
              <div className="deliveryHeaderStyle">
                <FormattedMessage id="deliveryOrderDetailDestination" defaultMessage="Destination" />
              </div>
              <div className="deliveryValueStyle">
                {_.get(data, 'address.toLocationName')} · {_.get(data, 'address.toLocation')}
              </div>
            </Col>
          </Row>{' '}
          <div className="deliveryHeaderStyle" style={{ marginTop: '25px' }}>
            <FormattedMessage id="deliveryOrderDetailAssignee" defaultMessage="Assignee" />
          </div>{' '}
          <Row gutter={[16, 16]}>
            {_.map(_.get(data, 'assignee'), (i) => {
              return (
                <Col span={12}>
                  <div style={{ paddingBottom: '0px' }} className="task-detail-assignee-padding-value">
                    <div className="task-detail-assignee-detail">
                      <Badge className="badge-style-member">
                        <Avatar size="large" shape="circle" src={_.get(i, 'pathImage')} />
                      </Badge>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                      <div className="task-detail-assignee-text-name">{_.get(i, 'fullname')}</div>
                      <div className="task-detail-assignee-text-phone">{_.get(i, 'phone') || '-'}</div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default DeliveryOrderTaskDetailForm;
