import React, { useRef, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import Geocode from "react-geocode";
import _ from 'lodash';
import { CustomerEditContext } from '../edit-customer/edit-customer-context';
import { useAppContext } from '../../includes/indexProvider';

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

export default ({ defalseLocation }) => {
  const { stateCustomerEdit } = useContext(CustomerEditContext);
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);

  useEffect(() => {
    setPosition({ lat: _.get(defalseLocation, 'lat'), lng: _.get(defalseLocation, 'lng') });
    setCenter({ lat: _.get(defalseLocation, 'lat'), lng: _.get(defalseLocation, 'lng') });
  }, []);

  useMemo(() => {
    if (stateCustomerEdit && stateCustomerEdit.filterCustomerLocation) {
      setPosition({ lat: _.get(stateCustomerEdit, 'filterCustomerLocation.lat'), lng: _.get(stateCustomerEdit, 'filterCustomerLocation.lng') });
      setCenter({ lat: _.get(stateCustomerEdit, 'filterCustomerLocation.lat'), lng: _.get(stateCustomerEdit, 'filterCustomerLocation.lng') });
    } else {
      setCenter(latlng);
    }
  }, [stateCustomerEdit.filterCustomerLocation]);

  const libraries = ["places"];
  const mapContainerStyle = {
    height: `130px`,
    width: '190px',
    position: 'relative'
  }

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";



  const onMarkerClick = () => {
    if (stateCustomerEdit && stateCustomerEdit.filterCustomerLocation) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(stateCustomerEdit, 'filterCustomerLocation.lat')}, ${_.get(stateCustomerEdit, 'filterCustomerLocation.lng',)}`, '_blank');
    }
  }

  return (
    <div>
      <GoogleMap
        id={1}
        key={1}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        <Marker
          position={position}
          animation={window.google.maps.Animation.DROP}
          onClick={onMarkerClick}
        />
      </GoogleMap>
    </div>
  )
}
