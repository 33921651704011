import React, { useState, useRef, useEffect } from 'react';
import { Modal, Row, Col, Form } from 'antd';
import { useIntl } from 'react-intl';
import './css/index.css';
import Upload from './project-upload';
import Detail from './project-detail';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { FormattedMessage } from 'react-intl';
import Resizer from 'react-image-file-resizer';
import ModalAvatar from '../modal-avatar';
import { loadmoreCustomer } from '../../../controllers/orders-controller/api';
import _ from 'lodash';
import AddBusinessPartner from '../../add-business-partner/index';
import { getMembersLoadmore, getInitialDataProject, editProject } from '../../../controllers/project/project-api';
import moment from 'moment';
import { successNotification, errorNotification } from '../../v2/notification';

const ModalCreateProject = (props) => {
  const { visible, setVisible, form, avatarList, projectData, setTrigger } = props;
  const intl = useIntl();
  const { validateFields, resetFields } = form;
  const imageRef = useRef();
  const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');
  const [imageAsset, setImageAsset] = useState();
  const [imageChecked, setImageChecked] = useState();
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [priorityData, setPriorityData] = useState([]);
  const [projectTypeData, setProjectTypeData] = useState([]);
  //--------------------------Customer -------------------------------
  const [initialCustomerList, setInitialCustomerList] = useState([]);
  const [customerPage, setCustomerPage] = useState(0);
  const [customerSearch, setCustomerSearch] = useState();
  const [customerSearchPage, setCustomerSearchPage] = useState(0);
  const [initialCustomerListMaster, setInitialCustomerListMaster] = useState([]);
  const [initialCustomerLoading, setInitialCustomerLoading] = useState(false);
  const [isCustomerLoadMore, setIsCustomerLoadMore] = useState(true);
  const [isCustomerSearchLoadMore, setIsCustomerSearchLoadMore] = useState(true);
  const [customerPageSize, setCustomerPageSize] = useState(10);
  const [newCustomer, setNewCustomer] = useState();
  const [visibleAddCustomer, setVisibleAddCustomer] = useState();
  //--------------------------Project Manager -------------------------------
  const [initialManagerList, setInitialManagerList] = useState([]);
  const [managerPage, setManagerPage] = useState(1);
  const [managerSearch, setManagerSearch] = useState();
  const [managerSearchPage, setManagerSearchPage] = useState(0);
  const [initialManagerListMaster, setInitialManagerListMaster] = useState([]);
  const [initialManagerLoading, setInitialManagerLoading] = useState(false);
  const [isManagerLoadMore, setIsManagerLoadMore] = useState(true);
  const [isManagerSearchLoadMore, setIsManagerSearchLoadMore] = useState(true);
  const [managerPageSize, setManagerPageSize] = useState(20);

  useEffect(() => {
    const dataIni = async () => {
      const response = await getInitialDataProject();
      setPriorityData(_.get(response, 'projectPriority'));
      setProjectTypeData(_.get(response, 'projectType'));
    };
    dataIni();
  }, []);

  useEffect(() => {
    if (projectData && visible) {
      setImageFileDefault(_.get(projectData, 'projectImgProfile'));
      setImageChecked({
        fileHash: _.get(projectData, 'fileHash'),
      });
    }
  }, [_.get(projectData, 'id'), visible]);

  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: customerPage * customerPageSize + 1,
        indexEnd: customerPage * customerPageSize + 10,
        customerName: customerSearch,
      };
      const result = await loadmoreCustomer(payload);
      const dataDefault = [
        {
          comtomerName: _.get(projectData, 'customer'),
          customerId: _.get(projectData, 'customerId'),
        },
      ];
      const setAssetClassListData = _.map(_.get(result, 'customers'), (o) => {
        return o;
      });
      const concatDefault = dataDefault.concat(setAssetClassListData);
      const customers = _.uniqBy(_.concat(initialCustomerList, concatDefault), 'customerId');
      setInitialCustomerList(customers);
      setInitialCustomerListMaster(customers);
      setIsCustomerLoadMore(_.size(concatDefault) > 0 ? true : false);
      setInitialCustomerLoading(false);
    };

    if (isCustomerLoadMore && visible) {
      getData();
    }
  }, [customerPage, visible]);

  useEffect(() => {
    const getData = async () => {
      let payload = {
        indexStart: managerPage * managerPageSize + 1,
        indexEnd: managerPage * managerPageSize + 10,
        managerName: managerSearch,
        managerPage: (managerPage + 1).toString(),
      };
      const result = await getMembersLoadmore(payload);
      const dataDefault = [
        {
          mem_com_id: _.get(projectData, 'projectMangerId'),
          name: _.get(projectData, 'projectManager'),
          fullname: _.get(projectData, 'projectManager'),
        },
      ];
      const setAssetClassListData = _.map(_.get(result, 'data.data'), (o) => {
        return o;
      });
      const concatDefault = dataDefault.concat(setAssetClassListData);
      const customers = _.uniqBy(_.concat(initialManagerList, concatDefault), 'mem_com_id');
      setInitialManagerList(customers);
      setInitialManagerListMaster(customers);
      setIsManagerLoadMore(_.size(concatDefault) > 0 ? true : false);
      setInitialManagerLoading(false);
    };

    if (isManagerLoadMore) {
      getData();
    }
  }, [managerPage, visible]);

  useEffect(() => {
    const getData = async () => {
      if (!customerSearch) {
        setInitialCustomerList(initialCustomerListMaster);
        setInitialCustomerLoading(false);
      } else {
        let payload = {
          indexStart: customerSearchPage * customerPageSize + 1,
          indexEnd: customerSearchPage * customerPageSize + 10,
          customerName: customerSearch,
        };
        const result = await loadmoreCustomer(payload);
        const setAssetClassListData = _.map(_.get(result, 'customers'), (o) => {
          return o;
        });
        const Customer = _.uniqBy(_.concat(initialCustomerList, setAssetClassListData), 'customerId');
        setInitialCustomerList(Customer);
        setIsCustomerSearchLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
        setInitialCustomerLoading(false);
      }
    };

    if (isCustomerSearchLoadMore) {
      getData();
    }
  }, [customerSearch, customerSearchPage]);

  useEffect(() => {
    const getData = async () => {
      if (!managerSearch) {
        setInitialManagerList(initialManagerListMaster);
        setInitialManagerLoading(false);
      } else {
        let payload = {
          indexStart: managerPage * managerPageSize + 1,
          indexEnd: managerPage * managerPageSize + 10,
          managerName: managerSearch,
          managerPage: (managerPage + 1).toString(),
        };
        const result = await getMembersLoadmore(payload);
        const setAssetClassListData = _.map(_.get(result, 'data.data'), (o) => {
          return o;
        });
        const Customer = _.uniqBy(_.concat(initialManagerList, setAssetClassListData), 'mem_com_id');
        setInitialManagerList(Customer);
        setIsManagerSearchLoadMore(_.size(setAssetClassListData) > 0 ? true : false);
        setInitialManagerLoading(false);
      }
    };

    if (isManagerSearchLoadMore) {
      getData();
    }
  }, [managerSearch, managerSearchPage]);

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (
      !initialCustomerLoading &&
      (_.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight ||
        _.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight - 1)
    ) {
      setInitialCustomerLoading(true);
      if (customerSearch) {
        setCustomerSearchPage(customerSearchPage + 1);
      } else {
        setCustomerPage(customerPage + 1);
      }
    }
  };

  const handleManagerPopupScroll = (e) => {
    let target = e.target;
    if (
      !initialManagerLoading &&
      (_.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight ||
        _.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight - 1)
    ) {
      setInitialManagerLoading(true);
      setManagerPage(managerPage + 1);
    }
  };

  const handleCustomerChangeSearch = (value) => {
    setInitialCustomerLoading(true);
    setIsCustomerSearchLoadMore(true);
    setInitialCustomerList([]);
    setCustomerSearchPage(0);
    setCustomerSearch(value);
  };

  const handleManagerChangeSearch = (value) => {
    setInitialManagerLoading(true);
    setIsManagerSearchLoadMore(true);
    setInitialManagerList([]);
    setManagerSearchPage(0);
    setManagerSearch(value);
  };

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const filterProjectType =
        projectTypeData &&
        projectTypeData.filter((item) => {
          return item.projectTypeId === _.get(values, 'projectType');
        });
      const filterPriority =
        priorityData &&
        priorityData.filter((item) => {
          return item.projectPriorityId === _.get(values, 'priority');
        });
      const filterCustomer =
        initialCustomerList &&
        initialCustomerList.filter((item) => {
          return item.customerId === _.get(values, 'customer');
        });
      const filterManager =
        initialManagerList &&
        initialManagerList.filter((item) => {
          return item.mem_com_id === _.get(values, 'projectManager');
        });
      const filterImage =
        avatarList &&
        avatarList.filter((item) => {
          return item.path === imageFile;
        });
      const body = {
        projectTypeId: _.get(filterProjectType, '[0].projectTypeId'),
        projectTypeName: _.get(filterProjectType, '[0].projectTypeName'),
        projectPriorityId: _.get(filterPriority, '[0].projectPriorityId'),
        planStart: moment(_.get(values, 'planStart')).format('YYYY-MM-DD'),
        planFinish: moment(_.get(values, 'planFinish')).format('YYYY-MM-DD'),
        customerId: _.get(filterCustomer, '[0].customerId'),
        customerName: _.get(filterCustomer, '[0].comtomerName'),
        projectMangerId: _.get(filterManager, '[0].mem_com_id').toString(),
        projectMangerName: _.get(filterManager, '[0].name'),
        referenceNo: _.get(values, 'referenceNo'),
        remark: _.get(values, 'remark'),
        projectImgProfile: _.get(filterImage, '[0].fileHash'),
        projectName: _.get(values, 'projectName'),
      };

      const response = await editProject(body, _.get(projectData, 'id'));

      if (response.status === 200) {
        setVisible(false);
        setTrigger((current) => !current);
        successNotification(response.data.massage);
        resetFields();
        imageRef.current.value = null;
        setImageFile('');
        setImageAsset();
        setImageChecked();
      } else {
        errorNotification(response.data.massage);
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    imageRef.current.value = null;
    setImageFile('');
    setImageAsset();
    setImageChecked();
  };

  const showOpenFileDialog = (e) => {
    // imageRef.current.click();
    setVisibleAvatar(true);
  };

  const deleteImage = (e) => {
    setImageFile('');
    setImageAsset();
    imageRef.current.value = null;
  };

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);
        reader.onloadend = () => {
          const image = reader.result;
          //setImage(image);
          setImageFile(image);
        };
        reader.readAsDataURL(file);

        const fileChange = dataURLtoFile(image, file.name);
        setImageAsset(fileChange);
      } else {
        errorNotification('File not supported');
      }
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalProjectTextHeaderEdit`, defaultMessage: 'Edit Project ' })}
      width={850}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleCreate}
      bodyStyle={{ padding: '24px 24px 24px 0px' }}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate} style={{ margin: 'unset' }}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <Upload
            imageRef={imageRef}
            imageFile={imageFile}
            imageFileDefault={imageFileDefault}
            handleChangePicture={handleChangePicture}
            showOpenFileDialog={showOpenFileDialog}
            deleteImage={deleteImage}
          />
        </Col>
        <Col span={18}>
          <Detail
            form={form}
            initialCustomerLoading={initialCustomerLoading}
            handleCustomerPopupScroll={handleCustomerPopupScroll}
            handleCustomerChangeSearch={handleCustomerChangeSearch}
            setVisibleAddCustomer={setVisibleAddCustomer}
            initialCustomerList={initialCustomerList}
            newCustomer={newCustomer}
            setInitialCustomerList={setInitialCustomerList}
            initialManagerList={initialManagerList}
            initialManagerLoading={initialManagerLoading}
            handleManagerPopupScroll={handleManagerPopupScroll}
            handleManagerChangeSearch={handleManagerChangeSearch}
            priorityData={priorityData}
            projectTypeData={projectTypeData}
            projectData={projectData}
          />
        </Col>
      </Row>
      <ModalAvatar
        visible={visibleAvatar}
        setVisible={setVisibleAvatar}
        avatar={avatarList}
        imageFile={imageFile}
        setImageFile={setImageFile}
        imageChecked={imageChecked}
        setImageChecked={setImageChecked}
      />

      <AddBusinessPartner visible={visibleAddCustomer} setVisible={setVisibleAddCustomer} setCustomerData={setNewCustomer} />
    </Modal>
  );
};

const ModalCreate = Form.create({
  name: 'modal_form',
})(ModalCreateProject);

export default React.memo(ModalCreate);
