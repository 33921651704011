import React, { useState, useEffect } from 'react';
import { Form, Select, Row, Col, Collapse, Checkbox, InputNumber, Divider, Empty } from 'antd';
import './css/index.css';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
const { Option } = Select;
const { Panel } = Collapse;

const financial = (x) => {
  return Number.parseFloat(x).toFixed(2);
}

const VehicleItem = (props) => {
  // console.log('product itemObjectData::: ', props);

  const intl = useIntl();
  const { selectAddressItem, selectWareHouseItem, warehouseData, setSelectWareHouseItem
    , products, vehicles, setselectProductsItem, setselectVehiclesItem, dataListItem, defaultUnits
    , setProductList, itemObjectData, visibleAddItem, productKey, setProductKey, indeterminate
    , setIndeterminate, productListKey, setProductListKey, selectCustomerItem
    , triggerAddress
  } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;

  const [wareHouseSelect, setWareHouseSelect] = useState([]);

  // console.log('Vehicle ListKey dataListItem::: ', dataListItem);
  // console.log('Vehicle ListKey productListKey::: ', productListKey);

  // console.log('Vehicle key productKey::: ', productKey);
  // console.log('Vehicle key indeterminate::: ', indeterminate);

  useEffect(() => {
    if (selectWareHouseItem) {
      setWareHouseSelect(selectWareHouseItem);
      setFieldsValue({
        ['warehouse']: _.get(selectWareHouseItem, 'warehouseId') ? _.get(selectWareHouseItem, 'warehouseId') : undefined
      });
    } else {
      setWareHouseSelect(undefined);
      setFieldsValue({
        ['warehouse']: undefined
      });
    }
  }, [selectWareHouseItem]);

  useEffect(() => {
    if (visibleAddItem === true) {
      if ((_.size(dataListItem) > 0) && (_.size(itemObjectData) > 0)) {
        const newProductKey = itemObjectData && itemObjectData.map(item => item.productId); // ของ Edit
        let uniqByProductId = _.uniqBy(newProductKey, function (e) {
          return e;
        });

        const newProductList = itemObjectData && _.chain(itemObjectData).groupBy("productId").map((value, key) => (
          {
            productId: key,
            productPricesList: value
          }
        )).value();

        let cloneProduct = [...dataListItem];

        itemObjectData.forEach((item, i) => {
          let indexProId = cloneProduct.findIndex(obj => obj.productId === item.productId);
          if (indexProId !== -1) {
            let indexProPrice = cloneProduct[indexProId].productPricesList.findIndex(obj => obj.productPriceId === item.itemId);
            if (indexProPrice !== -1) {
              cloneProduct[indexProId].productPricesList[indexProPrice].total = item.totalPrice
              cloneProduct[indexProId].productPricesList[indexProPrice].unitPrice = item.price
              cloneProduct[indexProId].productPricesList[indexProPrice].unitId = item.itemUnitId
              cloneProduct[indexProId].productPricesList[indexProPrice].unitName = item.itemUnitName
              cloneProduct[indexProId].productPricesList[indexProPrice].quantity = item.qty
            }
          }
        })

        const newProduct = newProductList && newProductList.map((item, index) => {
          const productMarster = _.find(dataListItem, ['productId', item.productId]);
          if (productMarster !== undefined) {
            return {
              productId: item.productId,
              productName: productMarster.productName,
              productPricesList: item.productPricesList.map(ele => ele.itemId)
            }
          } else {
            return {
              productId: item.productId,
              productName: cloneProduct[0].productName,
              productPricesList: item.productPricesList.map(ele => ele.itemId)
            }
          }
        });

        const defProductListKey = newProduct.map(item => item.productId);
        const dataListFilter = dataListItem.filter((item) => !defProductListKey.includes(item.productId)).map(ele => ({ productId: ele.productId, productName: ele.productName, productPricesList: [] }));
        const productListConcat = newProduct.concat(dataListFilter);

        // setProductKey(uniqByProductId);
        setProductKey([]);
        setIndeterminate(uniqByProductId);
        setProductListKey(productListConcat);
        setProductList(productListConcat); // ส่ง Data ที่เลือกออกไป Map ที่ Form

        // ******************************** จบ Set map data Item Edit **************************************
      } else {
        if (_.size(dataListItem) >= 0) {
          const newProductListKey = dataListItem && dataListItem.map((item, index) => {
            // let newFilter = productListKey.filter(ele => ele.productId === item.productId);
            // console.log('newProductListKey newFilter::: ', newFilter);

            return {
              'productId': item.productId,
              'productName': item.productName,
              'productPricesList': [],
              // 'productPricesList': newFilter.length > 0 ? newFilter[0].productPricesList : [],
              // 'productPricesList': productListKey.map(ele => ele.productPricesList),
              // 'productPricesList': productListKey.map(ele => {
              //   if (ele => ele.productId === item.productId) {
              //     return ele.productPricesList
              //   }
              // }),
            }
          });

          // console.log('newProductListKey data::: ', newProductListKey);

          setProductKey([]); // ของ Create
          setProductListKey(newProductListKey); // ของ Create
          setProductList(newProductListKey); // ส่ง Data ที่เลือกออกไป Map ที่ Form

          // const newProductListKey = dataListItem.map(item => {
          //   const newLoop = productListKey.map(ele => {
          //     if (item.productId === ele.productId) {
          //       return ele
          //     } else {
          //       return {
          //         'productId': item.productId,
          //         'productName': item.productName,
          //         'productPricesList': [],
          //       }
          //     }
          //   })
          //   // return newLoop;
          // })

          // console.log('newProductListKey data::: ', newProductListKey);

          // setProductKey([]); // ของ Create
          // setProductListKey(newProductListKey); // ของ Create
          // setProductList(newProductListKey); // ส่ง Data ที่เลือกออกไป Map ที่ Form
        }
      }
    }
  }, [dataListItem, visibleAddItem]);

  useEffect(() => {
    if (triggerAddress || selectCustomerItem || selectAddressItem) {
      setFieldsValue({
        ['products']: undefined
      });
      setFieldsValue({
        ['vehicle']: undefined
      });
      setselectProductsItem([]);
      setselectVehiclesItem([]);
    }
  }, [triggerAddress, selectCustomerItem, selectAddressItem]);

  const productsChange = (e) => {
    setselectProductsItem(e);
  }

  const vehiclesChange = (e) => {
    setselectVehiclesItem(e);
  }

  const onCheckAllChange = (e, key, item) => {
    const productPriceId = item.productPricesList.map(e => e.productPriceId);
    const someResult = productKey && productKey.some(i => i.includes(key.toString()));
    if (someResult === true) {
      const removeKey = productKey.filter(item => item !== key.toString())
      const newUpdate = productListKey.map((item, index) => {
        if (item.productId === key.toString()) {
          return { ...item, productPricesList: [] }
        } else {
          return item;
        }
      });

      setProductKey(removeKey);
      setProductListKey(newUpdate);
      setProductList(newUpdate); // ส่ง Data ที่เลือกออกไป Map ที่ Form
    } else {
      let newArrKey = [...productKey];
      newArrKey.push(key.toString());
      const newUpdate = productListKey.map((item, index) => {
        if (item.productId === key.toString()) {
          return { ...item, productPricesList: productPriceId }
        } else {
          return item;
        }
      });

      setProductKey(newArrKey);
      setProductListKey(newUpdate);
      setProductList(newUpdate); // ส่ง Data ที่เลือกออกไป Map ที่ Form
    }

    let indetermiFilter = indeterminate.filter(ele => ele !== key.toString());
    setIndeterminate(indetermiFilter);
  }

  const onChange = (checkedList, item) => {


    const newUpdate = productListKey.map((i, index) => {
      if (i.productId === item.productId) {
        return { ...i, productPricesList: checkedList }
      } else {
        return i;
      }
    });

    const filterNewUpdateItem = newUpdate.filter((ele, index) => ele.productId === item.productId);

    let checkedListAll = [];
    let indetermi = [...indeterminate];
    if (item.productPricesList.length === filterNewUpdateItem[0].productPricesList.length) {

      checkedListAll.push(item.productId)
      let listAllUniq = _.uniq([...productKey, ...checkedListAll]);
      let indetermiFilter = indetermi.filter(ele => ele !== filterNewUpdateItem[0].productId);

      setProductKey(listAllUniq);
      setIndeterminate(indetermiFilter);
    } else if (filterNewUpdateItem[0].productPricesList.length > 0) {
      const someResultIndetermi = indetermi && indetermi.some(i => i.includes(filterNewUpdateItem[0].productId.toString()));
      if (someResultIndetermi === true) {
        let indetermiFilter = indetermi.filter(ele => ele !== filterNewUpdateItem[0].productId);
        setIndeterminate(indetermiFilter);
      } else {
        indetermi.push(item.productId);
        let indetermiListUniq = _.uniq([...indetermi]);
        setIndeterminate(indetermiListUniq);
      }

      let proKeyFilter = productKey.filter(ele => ele !== filterNewUpdateItem[0].productId);
      setProductKey(_.uniq(proKeyFilter));
    } else {
      if (filterNewUpdateItem[0].productPricesList.length === 0) {
        checkedListAll = productKey.filter(ele => !item.productId.toString().includes(ele.toString()));
        let indetermiFilter = indetermi.filter(ele => ele !== filterNewUpdateItem[0].productId);
        setProductKey([]);
        setIndeterminate(indetermiFilter);
      }
    }



    setProductListKey(newUpdate);
    setProductList(newUpdate); // ส่ง Data ที่เลือกออกไป Map ที่ Form
  }

  const onSelectWareHouse = (e) => {
    const filterWareHouse = warehouseData && warehouseData.filter((item) => item.warehouseId === e)
    setFieldsValue({
      ['warehouse']: _.get(filterWareHouse, '[0].warehouseId')
    });
    setFieldsValue({
      ['products']: undefined
    });
    setFieldsValue({
      ['vehicle']: undefined
    });
    setselectProductsItem([]);
    setselectVehiclesItem([]);
    setSelectWareHouseItem(_.get(filterWareHouse, '[0]'));
  }

  const genExtra = (key, item) => {
    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <Checkbox
          onChange={(e) => onCheckAllChange(e, key, item)}
          indeterminate={indeterminate.includes(key.toString())}
          checked={productKey.includes(key.toString())}
        />
      </div>
    )
  }

  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {

      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {
        e.preventDefault();
      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => { return o.charCodeAt(0) === 46 ? 1 : 0 })
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }

    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  }

  const fncMapItem = (dataDef) => dataListItem && dataListItem.map((item, index) => {
    let findIndex = dataDef.findIndex((i) => i.productId === item.productId);
    return (
      <Panel key={item.productId} className="vehicle-item-collapse-panel" header={item.productName} extra={genExtra(item.productId, item)} >
        <Checkbox.Group style={{ width: '100%' }} onChange={(e) => onChange(e, item)} value={dataDef[findIndex] && dataDef[findIndex].productPricesList}>
          {item && item.productPricesList.length !== 0 && item.productPricesList.map((e, i) =>
            <div key={e.productPriceId}>
              <Row className="vehicle-item-row" gutter={[16, 16]}>
                <Form.Item>
                  {getFieldDecorator(`productList[${item.productId}].[${i}].productId`, {
                    initialValue: item.productId,
                  })}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(`productList[${item.productId}].[${i}].productName`, {
                    initialValue: item.productName,
                  })}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(`productList[${item.productId}].[${i}].productPriceId`, {
                    initialValue: e.productPriceId,
                  })}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(`productList[${item.productId}].[${i}].productPriceTypeId`, {
                    initialValue: e.productPriceTypeId,
                  })}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(`productList[${item.productId}].[${i}].vehicleCode`, {
                    initialValue: e.vehicleCode,
                  })}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(`productList[${item.productId}].[${i}].vehicleName`, {
                    initialValue: e.vehicleName,
                  })}
                </Form.Item>
                <Col span={8}>
                  <Checkbox value={e.productPriceId}>{e.vehicleName}</Checkbox>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`productList[${item.productId}].[${i}].unitPrice`, {
                      initialValue: parseFloat(e.unitPrice).toFixed(2)
                    })(
                      <InputNumber
                        className="vehicle-item-inputNumber"
                        placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderUnitPrice', defaultMessage: 'Input' })}
                        maxLength={10}
                        // onChange={calculateItem}
                        onKeyDown={handleKeyDownNumberDecimal}
                        formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        min={0}
                        step={0.01}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`productList[${item.productId}].[${i}].qty`, {
                      initialValue: e.quantity,
                    })(
                      <InputNumber
                        className="vehicle-item-inputNumber"
                        placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderQty', defaultMessage: 'Input' })}
                        maxLength={10}
                        // onChange={calculateItem}
                        onKeyDown={handleKeyDownNumberDecimal}
                        formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        min={0}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`productList[${item.productId}].[${i}].unit`, {
                      initialValue: e.unitId,
                    })(
                      <Select
                        className="vehicle-item-select"
                        placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderUnit', defaultMessage: 'Select' })}
                      >
                        {defaultUnits && defaultUnits.map(item =>
                          <Option key={item.unitId} value={item.unitId}>{item.unitName}</Option>
                        )}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`productList[${item.productId}].[${i}].total`, {
                      initialValue: financial(e.total)
                    })(
                      <InputNumber
                        className="vehicle-item-inputNumber"
                        placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderTotal', defaultMessage: 'Select' })}
                        onKeyDown={handleKeyDownNumberDecimal}
                        formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        min={0}
                        step={0.01}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="vehicle-item-divider" />
            </div>
          )}
        </Checkbox.Group>
      </Panel>
    )
  })

  return (
    <div className="vehicle-item">
      <Form layout="vertical">
        <div className="vehicle-item-action">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequire text={intl.formatMessage({ id: 'ModalQuotationItemTextWarehouse', defaultMessage: 'Warehouse' })} req={true} />}
              >
                {getFieldDecorator('warehouse', {
                  initialValue: _.get(wareHouseSelect, 'warehouseId') ? _.get(wareHouseSelect, 'warehouseId') : undefined,
                  rules: [{ required: true, message: intl.formatMessage({ id: 'ModalQuotationItemValidateWarehouse', defaultMessage: 'Please input the title of collection' }) }],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderWarehouse', defaultMessage: 'Please select' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.props.children[0].props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    // dropdownClassName='data-warehouse-show-km'
                    onChange={onSelectWareHouse}
                    disabled={_.get(selectAddressItem, 'customerAddressBookId') ? false : true}
                    optionLabelProp="label"
                  >
                    {
                      warehouseData && warehouseData.map(item => (
                        <Option key={item.warehouseId} label={`${item.warehouseName}`}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='qutation-setting-text-select'>
                              {item.warehouseName} · {item.warehouseAddress}
                            </div>
                            <div >
                              <span >
                                <NumberFormat value={parseFloat(item.distanceTxt)} displayType={'text'} thousandSeparator={true} suffix={`  ${item.suffix}`} />
                              </span>
                            </div>
                          </div>
                        </Option>
                      ))
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={intl.formatMessage({ id: 'ModalQuotationItemTextItem', defaultMessage: 'Item' })}
              >
                {getFieldDecorator('products')(
                  <Select
                    className='vehicle-multiple-select'
                    placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderItem', defaultMessage: 'Please select' })}
                    mode='multiple'
                    maxTagCount={2}
                    onChange={productsChange}
                    style={{ height: '30px' }}
                  >
                    {products && products.map((item) => (
                      <Option value={item.productId}>{item.productName}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={intl.formatMessage({ id: 'ModalQuotationItemTextVehicle', defaultMessage: 'Vehicle' })}
              >
                {getFieldDecorator('vehicle')(
                  <Select
                    className='vehicle-multiple-select'
                    placeholder={intl.formatMessage({ id: 'ModalQuotationItemPlaceholderVehicle', defaultMessage: 'Please select' })}
                    mode='multiple'
                    onChange={vehiclesChange}
                    maxTagCount={2}
                    style={{ height: '30px' }}
                  >
                    {vehicles && vehicles.map((item) => (
                      <Option value={item.vehicleCode}>{item.vehicleName}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="vehicle-item-table">
          <div>
            <div className="vehicle-item-column">
              <Row>
                <Col span={8}>
                  <span style={{ marginLeft: '36px' }} className="vehicle-item-column-name"><FormattedMessage id="ModalQuotationItemHeaderVehicle" defaultMessage="Vehicle" /></span>
                </Col>
                <Col span={4}>
                  <span className="vehicle-item-column-name"><FormattedMessage id="ModalQuotationItemHeaderUnitPrice" defaultMessage="Unit Price (Baht)" /></span>
                </Col>
                <Col span={4} style={{ paddingLeft: '62px' }}>
                  <span className="vehicle-item-column-name"><FormattedMessage id="ModalQuotationItemHeaderQty" defaultMessage="Qty" /></span>
                </Col>
                <Col span={4}>
                  <span className="vehicle-item-column-name"><FormattedMessage id="ModalQuotationItemHeaderUnit" defaultMessage="Unit" /></span>
                </Col>
                <Col span={4}>
                  <span className="vehicle-item-column-name"><FormattedMessage id="ModalQuotationItemHeaderTotal" defaultMessage="Total (Baht)" /></span>
                </Col>
              </Row>
            </div>

            {_.size(productListKey) !== 0 && _.size(dataListItem) !== 0 ? (
              <Collapse
                className="vehicle-item-collapse"
                expandIconPosition='right'
              >

                {fncMapItem(productListKey)}

                {/* {productListKey.length !== 0 && dataListItem.length !== 0 ? fncMapItem(productListKey) : null} */}
                {/* {(dataDistance.length > 0) && (productListKey.length > 0) ? dataDistance.map((item, index) => {
                    return <FncMapItem 
                      form={props.form}
                      item={item}
                      index={index}
                      genExtra={genExtra}
                      onChange={onChange}
                      defaultUnits={defaultUnits}
                      productListKey={productListKey}
                    />
                  }) : null} */}
              </Collapse>
            ) : (
              <Empty className="vehicle-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

          </div>
        </div>
      </Form>
    </div>
  )
}

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const ModalSettingFormVehicle = Form.create({
  name: "modal_form_vehicle",
  onValuesChange(props, values, allValues) {
    props.valuesChangeItem(values, allValues);
  }
})(VehicleItem);

export default ModalSettingFormVehicle;