import React, { useRef } from 'react';
import { Row, Col, Form, Divider, Table, Popover, Button, Tag, Card } from 'antd';
import '../../index.css';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import Button01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import { numberFormatter, numberParser } from '../../../../component-function/fnc-inputnumber-currency';
import NoDataBackground from '../../../../components/no-data-page';

const ModalAllowanceHistory = (props) => {
  const intl = useIntl();
  const { historyList } = props;

  return (
    <div style={{ height: '560px', overflowY: 'auto', padding: '24px' }}>
      {_.size(historyList) > 0 ? (
        _.map(historyList, (item) => (
          <div>
            <div>
              <span style={{ color: '#1D3557' }}> {_.get(item, 'updatedByName')} </span>
              <span style={{ color: '#050505',marginRight: '12px' }}>
                <FormattedMessage id="resourceAllowanceModalHistoryFrom" defaultMessage="changes status from" />
              </span>{' '}
              <Tag
                style={{ width: 'auto', minWidth: '85px', padding: '0px 25px', borderRadius: '10px', margin: 'unset' }}
                color={_.get(item, 'statusFrom.color')}
              >
                {_.get(item, 'statusFrom.statusName')}
              </Tag>{' '}
              <span style={{ color: '#050505',margin: '0px 12px' }}>
                <FormattedMessage id="resourceAllowanceModalHistoryTo" defaultMessage="to" />
              </span>{' '}
              {''}
              <Tag
                style={{ width: 'auto', minWidth: '85px', padding: '0px 25px', borderRadius: '10px', margin: 'unset',marginRight: '12px'  }}
                color={_.get(item, 'statusTo.color')}
              >
                {_.get(item, 'statusTo.statusName')}
              </Tag>{' '}
              <span style={{ color: '#65676B' }}>{_.get(item, 'updatedDate')} </span>{' '}
              <span style={{ lineHeight: '2.5', color: '#050505' }}>{_.get(item, 'txtReasonRemark')}</span>
            </div>
            <Divider type="horizontal" />
          </div>
        ))
      ) : (
        <div>
          <NoDataBackground
            text1={{ id: 'noDataresourceAllowance1', text: 'No Data' }}
            text2={{ id: 'noDataresourceAllowance2', text: 'History will appear here.' }}
          />
        </div>
      )}
    </div>
  );
};

export default ModalAllowanceHistory;
