import React, { useState, useEffect } from 'react';
import './index.css';
import { Card, Table, Dropdown, Menu, Button, Row, Col, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDebounce } from '../../../controllers/debounce';
import moment from 'moment';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import Button01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';
import { getBrandData, getModelData } from '../../../controllers/po/po';
import {
  exportReportItemSale,
  exportReportSaleOrder,
  getReportItemSale,
  getReportSaleOrder,
} from '../../../controllers/report/customer-report/api';
import ReportISalesOrderReportAction from './action';
import { getCustomer } from '../../../controllers/customer-vendor/customer-vendor';

const dateFormat = 'DD/MM/YYYY';

const SalesOrderReport = () => {
  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsSalesOrderNo', defaultMessage: 'Sales Order No.' }),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      default: true,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsCustomer', defaultMessage: 'Customer' }),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'item',
      key: 'item',
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsTotal', defaultMessage: 'Total' }),
      dataIndex: 'subTotal',
      key: 'subTotal',
      sorter: true,
      default: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(record.subTotal || 0) + ' THB',
    },
    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPercent',
      key: 'discountPercent',
      sorter: true,
      default: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(_.get(record, 'discount')) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsTotalAfterDiscount', defaultMessage: 'Total After Discount' }),
      dataIndex: 'subTotalAfterDiscount',
      key: 'subTotalAfterDiscount',
      sorter: true,
      default: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(record.subTotalAfterDiscount || 0) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      default: false,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={_.get(record, 'colorCode')}>
            {_.get(record, 'statusTxt')}
          </Tag>
        </div>
      ),
    },

    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsIssuedby', defaultMessage: 'Issued by' }),
      dataIndex: 'issueName',
      key: 'issueName',
      sorter: true,
      default: false,
    },

    {
      title: intl.formatMessage({ id: 'reportSalesOrderColumnsIssuedDate', defaultMessage: 'Issued Date' }),
      dataIndex: 'issueDate',
      key: 'issueDate',
      sorter: true,
      default: false,
    },
  ];
  const setShowColumnArr = _.map(columns, (item) => {
    return item.dataIndex;
  });
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  const ignoreColumn = _.map(
    _.filter(columns, (item) => {
      return item.default === false;
    }),
    (item) => {
      return item.dataIndex;
    }
  );
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [search, setSearch] = useState({ searchDate: [moment().startOf('month'), moment().endOf('month')] });
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [refTypeList, setRefTypeList] = useState([]);
  const [summary, setSummary] = useState([]);
  const searchDebounce = useDebounce(search, 500);

  const [optionsBrand, setOptionsBrand] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [brandSearch, setBrandSearch] = useState();
  const debounceBrandSearch = useDebounce(brandSearch, 500);

  const [optionsModel, setOptionsModel] = useState([]);
  const [loadingModel, setLoadingModel] = useState(false);
  const [modelSearch, setModelSearch] = useState();
  const debounceModelSearch = useDebounce(modelSearch, 500);

  const [isFetch, setIsFetch] = useState(false);

  //------------------------------------------------------
  const [customerList, setCustomerList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    const getListDate = async () => {
      setLoading(true);
      const body = {
        where: {
          saleOrderNo: _.get(search, 'soNo') || '',
          customerId: _.get(search, 'customer.customerId') || '',
          status: _.get(search, 'status') || '',
        },

        startIssueDate: _.size(_.get(search, 'searchDate')) ? moment(_.get(search, 'searchDate[0]')).format('DD/MM/YYYY') : undefined,
        endIssueDate: _.size(_.get(search, 'searchDate')) ? moment(_.get(search, 'searchDate[1]')).format('DD/MM/YYYY') : undefined,
        page: page,
        pageSize: size,
        orderBy: sortField,
        order: sortOrder,
      };
      const response = await getReportSaleOrder(body);
      setListData(_.get(response, 'data.list'));
      setTotal(_.get(response, 'data.total'));
      setStatusList(_.get(response, 'data.initialData'));
      setLoading(false);
    };
    getListDate();
  }, [page, size, sortOrder, sortField, isFetch, _.get(search, 'searchDate')]);

  useEffect(() => {
    const getCustomerData = async () => {
      const body = {
        searchBy: 'customerName',
        page: page,
        limit: 9999,
      };
      try {
        const response = await getCustomer(body);

        setCustomerList(_.get(response, 'customers', []));
      } catch (error) {
        console.log('error', error);
      }
    };
    getCustomerData(); // โหลดข้อมูลเริ่มต้น
  }, [debounceBrandSearch]);

  const onFilterOrder = async (value, code) => {
    console.log('onFilterOrder', value, code);
    if (code === 'brand' && _.size(value)) {
      setSearch({ ...search, [code]: value, model: [] });
    } else {
      setSearch({ ...search, [code]: value });
    }
    // setPage(1)
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    try {
      const body = {
        where: {
          saleOrderNo: _.get(search, 'soNo') || '',
          customerId: _.get(search, 'customer.customerId'),
          status: _.get(search, 'status'),
        },
        startIssueDate: _.size(_.get(search, 'searchDate')) ? moment(_.get(search, 'searchDate[0]')).format('DD/MM/YYYY') : undefined,
        endIssueDate: _.size(_.get(search, 'searchDate')) ? moment(_.get(search, 'searchDate[1]')).format('DD/MM/YYYY') : undefined,
        page: 1,
        pageSize: total,
        orderBy: sortField,
        order: sortOrder,

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsSalesOrderNo', defaultMessage: 'Sales Order No.' }),
            sequence: 1,
            colCode: 'saleOrderNo',
          },
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsCustomer', defaultMessage: 'Customer' }),
            sequence: 2,
            colCode: 'customerName',
          },
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsItem', defaultMessage: 'Item' }),
            sequence: 3,
            colCode: 'item',
          },
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsTotal', defaultMessage: 'Total' }),
            sequence: 4,
            colCode: 'subTotal',
          },
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsDiscount', defaultMessage: 'Discount' }),
            sequence: 5,
            colCode: 'discount',
          },
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsTotalAfterDiscount', defaultMessage: 'Total After Discount' }),
            sequence: 6,
            colCode: 'subTotalAfterDiscount',
          },
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsStatus', defaultMessage: 'Status' }),
            sequence: 7,
            colCode: 'statusTxt',
          },
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsIssuedby', defaultMessage: 'Issued by' }),
            sequence: 8,
            colCode: 'issueName',
          },
          {
            colName: intl.formatMessage({ id: 'reportSalesOrderColumnsIssuedDate', defaultMessage: 'Issued Date' }),
            sequence: 9,
            colCode: 'issueDate',
          },
        ],
      };
      const response = await exportReportSaleOrder(body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {}
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Card
        className="reportItemSalesCard"
        title={intl.formatMessage({ id: 'menuSaleOrderReport', defaultMessage: 'Sale Orders Report' })}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
        extra={
          <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
            <Button01 key="submit" type="primary" btnsize="wd_df">
              <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
              <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
            </Button01>
          </Dropdown>
        }
      >
        <ReportISalesOrderReportAction
          search={search}
          setSearch={setSearch}
          setPage={setPage}
          setIsFetch={setIsFetch}
          onFilterOrder={onFilterOrder}
          refTypeList={refTypeList}
          statusList={statusList}
          customerList={customerList}
          brand={{
            optionsBrand,
            loadingBrand,
            brandSearch,
            setBrandSearch,
          }}
        />
        <div style={{ padding: '16px 24px' }}>
          <Table
            dataSource={listData}
            columns={columns}
            onChange={handleChange}
            loading={loading}
            scroll={{ x: true }}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              current: page,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
          />
          {_.size(listData) > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default SalesOrderReport;
