import React, { useState, useEffect, useRef } from 'react';
import { Modal, Badge, notification, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
// import { useAppContext } from '../../../../includes/indexProvider';
// import { ShiftContext } from '../shift-context';
import { ShiftPlanningContext } from '../shift-context';
// import { PageSettings } from '../../../../config/page-settings';
import httpClient from '../../../../components/axiosClient';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import moment from 'moment';
import _ from 'lodash';
import ShiftSchedulePlanning from './shift-schedule-modal-planning';
import '../css/index.css';
import ShiftDateWarning from './shift-date-warning';
import ShiftResourceWarning from './shift-resource-warning';

export default React.memo(
  ({
    scheduleMainObj,
    handlePlanningCancel,
    comId,
    orgId,
    shiftsYear,
    shiftsMonth,
    planningVisibled,
    membersData,
    orgDetail,
    // resourceShiftData
  }) => {
    const intl = useIntl();
    // const app = Provider.useAppContext();
    // const { stateShiftsMain, setStateShiftsMain, fncShiftsMain } = useContext(ShiftContext);
    // const orgId = orgId;
    // const comId = comId;
    // const resourceShiftData = app.state.members;
    // const resourceShiftData = membersData;
    const thisYearMonth = moment(shiftsYear + ' ' + shiftsMonth, 'YYYY MM').format('YYYY-MM');
    const thisMonth = moment(shiftsMonth, 'MM').format('MM');
    const scheduleObj = useRef();

    const [resourcesPlannig, setResourcesPlannig] = useState([]);
    const [shiftTypeRule, setShiftTypeRule] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [scenario, setScenario] = useState();
    const [scenarioIndex, setScenarioIndex] = useState(0);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loadingPlanning, setLoadingPlanning] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [warningDate, setWarningDate] = useState([]);
    const [warningResource, setWarningResource] = useState([]);

    const [dateWarning, setDateWarning] = useState();
    const [visibleDateWarning, setVisibleDateWarning] = useState(false);
    const [txtDateWarningSuffix, setTxtDateWarningSuffix] = useState('');
    const [txtDateWarningDetail, setTxtDateWarningDetail] = useState([]);

    const [resourceWarning, setResourceWarning] = useState();
    const [visibleResourceWarning, setVisibleResourceWarning] = useState(false);
    const [txtResourceWarningSuffix, setTxtResourceWarningSuffix] = useState('');
    const [txtResourceWarningDetail, setTxtResourceWarningDetail] = useState([]);

    useEffect(() => {
      getScenarios();
    }, [orgId]);

    useEffect(() => {
      if (dateWarning) {
        onSetModalDateWarning();
      }
    }, [dateWarning, visibleDateWarning]);

    useEffect(() => {
      if (resourceWarning) {
        onSetModalResourceWarning();
      }
    }, [resourceWarning, visibleResourceWarning]);

    const getScenarios = async () => {
      setLoadingPlanning(true);
      try {
        const response = await httpClient.post(`/v1/resource/manager/shift/planning/company/${comId}/organization/${orgId}`, {
          year: shiftsYear,
          month: thisMonth,
        });
        if (response.status === 200) {
          const setMembers = [];
          _.forEach(response.data.members, (e) => {
            // const filterShiftMemberArr = _.filter(response.data.scenario[0].scenarioShiftList, (el) => {
            //   return el.ResourceId === e.memId && el.isLeave === false && el.isHoliday === false && el.orgId === orgId.toString() ? true : false;
            // });

            // const resourceShiftData = _.get(scheduleMainObj, '.current.resources[0]') ? scheduleMainObj.current.resources[0].dataSource : [];

            // const findIndexResourceOld = _.findIndex(resourceShiftData, (el) => {
            //   return el.Id === e.memId ? true : false;
            // });

            // if(_.size(filterShiftMemberArr) > 0 || findIndexResourceOld > -1) {

            setMembers.push({
              Id: e.memId,
              memComId: e.memComId,
              Text: e.fullname,
              email: e.email,
              phone: e.phone,
              pathImage: e.pathImage,
            });
            // }
          });

          // _.forEach(resourceShiftData, (e) => {
          //   const findResourceData = _.findIndex(setMembers, ['memComId', _.toInteger(e.mem_com_id)]);
          //   if(findResourceData === -1) {
          //     setMembers.push({
          //       Id: e.mem_id,
          //       memComId: e.mem_com_id,
          //       Text: e.fullname,
          //       email: e.email,
          //       phone: e.phone,
          //       pathImage: e.default_profile_img
          //     });
          //   }
          // });

          if (_.size(setMembers) <= 11) {
            for (let i = _.size(setMembers); i <= 11; i++) {
              setMembers.push({
                Id: 'mockdata-' + i,
                memComId: '',
                Text: '',
                email: '',
                phone: '',
                pathImage: '',
              });
            }
          }

          setResourcesPlannig(setMembers);
          setShiftTypeRule(response.data.shiftTypeRule);
          setScenarios(response.data.scenario);
          setScenario(response.data.scenario[0].scenarioId);
          setScenarioIndex(0);
          setWarningDate(response.data.warningDate);
          setWarningResource(response.data.warningResource);
          setLoadingPlanning(false);
        } else {
          setScenarios([]);
          setWarningDate([]);
          setWarningResource([]);
          setLoadingPlanning(false);
        }
      } catch (error) {
        setScenarios([]);
        setWarningDate([]);
        setWarningResource([]);
        setLoadingPlanning(false);
      }
    };

    const handleChangeScenario = ({ key }) => {
      const found = scenarios.findIndex((o) => o.scenarioId === key);
      setScenario(key);
      setScenarioIndex(found);
    };

    const handleApply = async () => {
      setConfirmLoading(true);
      setDisableBtn(true);
      const payload = [];

      _.forEach(scheduleObj.current.eventsData, (shift) => {
        if (parseInt(shift.orgId) === parseInt(orgId)) {
          const shiftDate = moment(shift.StartTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
          const resourceIndex = _.findIndex(payload, (o) => o.memId === shift.ResourceId);
          if (resourceIndex >= 0) {
            const shiftIndex = _.findIndex(payload[resourceIndex].shifts, (o) => o.shiftMasterId === shift.ShiftMasterId);
            if (shiftIndex >= 0) {
              payload[resourceIndex].shifts[shiftIndex].shiftDate.push(shiftDate);
            } else {
              const shiftDoc = {
                shiftMasterId: shift.ShiftMasterId,
                shiftDate: [shiftDate],
              };
              payload[resourceIndex].shifts.push(shiftDoc);
            }
          } else {
            const resourceDoc = {
              memId: shift.ResourceId,
              shifts: [
                {
                  shiftMasterId: shift.ShiftMasterId,
                  shiftDate: [shiftDate],
                },
              ],
            };
            payload.push(resourceDoc);
          }
        }
      });

      try {
        const response = await httpClient.post(
          `/resource/manager/company/${comId}/organization/${orgId}/shift-planning/form-date/${thisYearMonth}`,
          { resources: payload }
        );

        if (response.status === 200) {
          successNotification(response.data.message);
          setDisableBtn(false);
        }
      } catch (error) {
        errorCatchNotification('Error.');
        setDisableBtn(false);
      }

      setConfirmLoading(false);

      let year = moment(thisYearMonth, 'YYYY-MM').format('YYYY');
      let month = moment(thisYearMonth, 'YYYY-MM').format('MMMM');
      handlePlanningCancel(year, month, 'apply');
    };

    const successNotification = (message) => {
      notification.success({
        message: message,
      });
    };

    const errorCatchNotification = (message) => {
      notification.error({
        message: message,
      });
    };

    const modalTitle = (
      <div className="shift-planning-modal-title">
        <FormattedMessage id="orgShiftPlanningLblShift" defaultMessage="Shift Planning" />{' '}
        <FormattedMessage id="orgShiftPlanningLblOn" defaultMessage="On" />{' '}
        {thisYearMonth ? moment(thisYearMonth, 'YYYY-MM').format('MMMM YYYY') : ''}
        {/* {' - '}  */}
        <Badge className="shift-planning-title-dot" color="#1D3557" />
        {/* {app.state.organizationsDetail.name} */}
        {orgDetail.name}
      </div>
    );

    const handleOpenModalDateWarning = (dD) => {
      setDateWarning(dD);
    };

    const onSetModalDateWarning = () => {
      let txt = '';
      let data = [];
      const getWarning = _.find(warningDate, ['day', _.toString(dateWarning)]);
      if (getWarning) {
        txt = moment(getWarning.workDate, 'YYYY-MM-DD').format('ddd, MMM DD, YYYY');
        const getShiftTypeRules = _.get(getWarning, 'shiftTypeRules');
        if (getShiftTypeRules) data = getShiftTypeRules;
      }
      setTxtDateWarningSuffix(txt);
      setTxtDateWarningDetail(data);
      setVisibleDateWarning(true);
    };

    const handleCloseModalDateWarning = () => {
      setDateWarning();
      setVisibleDateWarning(false);
    };

    const handleOpenModalResourceWarning = (memComId) => {
      setResourceWarning(memComId);
    };

    const onSetModalResourceWarning = () => {
      let txt = '';
      let data = [];
      const getWarning = _.find(warningResource, ['memComId', resourceWarning]);
      if (getWarning) {
        txt = getWarning.fullname;
        const getShiftTypeRules = _.get(getWarning, 'shiftTypeRules');
        if (getShiftTypeRules) data = getShiftTypeRules;
      }
      setTxtResourceWarningSuffix(txt);
      setTxtResourceWarningDetail(data);
      setVisibleResourceWarning(true);
    };

    const handleCloseModalResourceWarning = () => {
      setResourceWarning();
      setVisibleResourceWarning(false);
    };

    return (
      <ShiftPlanningContext.Provider
        value={{
          statePlanning: {
            resourcesPlannig,
            scheduleObj,
            thisYearMonth,
            scenario,
            scenarioIndex,
            scenarios,
            shiftTypeRule,
            orgId,
            // orgName: app.state.organizationsDetail.name,
            orgName: orgDetail.name,
            comId,
            shiftsYear,
            shiftsMonth,
            warningDate,
            warningResource,
            setWarningDate,
            setWarningResource,
          },
          fncStatePlanning: {
            handleChangeScenario,
            handleOpenModalDateWarning,
            handleCloseModalDateWarning,
            handleOpenModalResourceWarning,
            handleCloseModalResourceWarning,
          },
        }}
      >
        <div>
          <Modal
            className="shift-planning-modal"
            width={'90%'}
            centered={true}
            title={modalTitle}
            visible={planningVisibled}
            okText={intl.formatMessage({ id: 'orgShiftPlanningBtnApply', defaultMessage: 'Apply' })}
            cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
            onOk={handleApply}
            onCancel={handlePlanningCancel}
            confirmLoading={confirmLoading}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={handlePlanningCancel}>
                <FormattedMessage id="btnReturn" defaultMessage="Return" />
              </Button02>,
              <Button01 key="submit" type="primary" fontsize="sm" btnsize="wd_df" disabled={disableBtn} onClick={handleApply}>
                <FormattedMessage id="btnSubmit" defaultMessage="Submit" />
              </Button01>,
            ]}
          >
            <div className="shift-planning-modal-body">
              <Spin spinning={loadingPlanning}>
                <div style={{ height: 'calc(100vh - 225px)' }}>
                  {resourcesPlannig.length > -1 ? (
                    <ShiftSchedulePlanning
                      statePlanning={{
                        resourcesPlannig,
                        scheduleObj,
                        thisYearMonth,
                        scenario,
                        scenarioIndex,
                        scenarios,
                        shiftTypeRule,
                        orgId,
                        orgName: orgDetail.name,
                        comId,
                        shiftsYear,
                        shiftsMonth,
                        warningDate,
                        warningResource,
                        setWarningDate,
                        setWarningResource,
                      }}
                      fncStatePlanning={{
                        handleChangeScenario,
                        handleOpenModalDateWarning,
                        handleOpenModalResourceWarning,
                      }}
                    />
                  ) : null}
                </div>
              </Spin>
            </div>
          </Modal>
        </div>

        <ShiftDateWarning
          visible={visibleDateWarning}
          onCancel={handleCloseModalDateWarning}
          txtWarningSuffix={txtDateWarningSuffix}
          viewWarningDetail={txtDateWarningDetail}
        />

        <ShiftResourceWarning
          visible={visibleResourceWarning}
          onCancel={handleCloseModalResourceWarning}
          txtWarningSuffix={txtResourceWarningSuffix}
          viewWarningDetail={txtResourceWarningDetail}
        />
      </ShiftPlanningContext.Provider>
    );
  }
);
