import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Icon, Input, Modal, Row, Select, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ListItemFilter from './filter';
import { messageLabel } from '../message-component';
import Button_01 from '../v2/button_01';
import { searchPo } from '../../controllers/po/po';
import NoDataBackground from '../no-data-page';
import CustomTableComponent from '../../pages/billing-note/components/table';
import { getTicketData } from '../../controllers/ticket/ticket';
import { savetListReferenceTab } from '../../controllers/reference-tab';
import { createModelData, getBrandDataList, getModelDataList } from '../../controllers/createBrandModel';
import ListItemBrand from './brandlist';
import LabeRequireForm from '../label-required-form';
import Button_02 from '../v2/button_02';
import CollapseCustom from '../collapse-custom';
import ModalCreateModelForm from './createmodel';
import ModalCreateBrandForm from './createBrand';
import ModalViewBrandModal from './createBrand/view';
import { errorNotification, successNotification } from '../v2/notification';
import filterimg from '../../assets/svg/filter.svg';
import { right } from 'glamor';

const { Option } = Select;

const CreateGroupTypeModal = ({ form, visible, onCancel, refType, dataform, setCreateItem, setBrandId, setEventDataDefault }) => {
  const [reportBrand, setReportBrand] = useState({
    itemBrandsActive: [],
    itemBrandsInactive: [],
    totalItemBrandsActive: 0,
    totalItemBrandsInactive: 0,
    totalItem: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    brand: '',
    model: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
    issueDateStart: '',
    issueDateEnd: '',
    apply: false,
  });
  const [reportModel, setReportModel] = useState({
    list: [],
    totalItem: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    brand: '',
    model: '',
    active: 'true',
    itemModelDescription: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
    issueDateStart: '',
    issueDateEnd: '',
  });
  const clearReport = reportModel;
  console.log('dataFormCus', dataform);
  const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = form;
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  // const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer } = SelectCustomerHook();
  const [event, setEvent] = useState([]);
  const [eventmodel, setEventModel] = useState([]);
  const [tempchange, setTempChange] = useState({});
  const [toggleBrand, setToggleBrand] = useState(false);
  const [selectBrand, setSelectBrand] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [recordBrand, setRecordBrand] = useState({});
  const [recordModel, setRecordModel] = useState([]);
  const [visibleCreateModel, setVisibleCreateModel] = useState(false);
  const [visibleCreateBrand, setVisibleCreateBrand] = useState(false);
  const [visibleViewBrand, setvisibleViewBrand] = useState(false);
  const [isCollapse, setIsCollapse] = useState();

  // const [event, setEvent = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setReportBrand((prev) => ({ ...prev, loading: true }));

    const getApi = async () => {
      const body = {};
      const reesponse = await getBrandDataList(body);
      console.log('reportBrand Api', reesponse);

      if (selectBrand === '') {
        setSelectBrand(_.get(reesponse, 'data.itemBrandsActive[0].itemBrandId'));
      }

      setReportBrand((prev) => ({
        ...prev,
        itemBrandsActive: _.get(reesponse, 'data.itemBrandsActive'),
        totalItemBrandsActive: _.get(reesponse, 'data.totalItemBrandsActive'),
        itemBrandsInactive: _.get(reesponse, 'data.itemBrandsInactive'),
        totalItemBrandsInactive: _.get(reesponse, 'data.totalItemBrandsInactive'),
        apply: !_.get(prev, 'apply'),
      }));
    };
    setReportBrand((prev) => ({ ...prev, loading: false }));
    if (visible === true) {
      getApi();
    }
  }, [
    visible,
    // pageTable,
    // sizeTable,
    event,
  ]);

  useEffect(() => {
    setReportModel((prev) => ({ ...prev, loading: true }));

    const getApi = async () => {
      const body = {
        itemBrandId: selectBrand,
        searchNoOrName: _.get(reportModel, 'itemModelName'),
        active: _.get(reportModel, 'active'),
        itemModelDescription: _.get(reportModel, 'itemModelDescription'),
        pageNumber: pageTable,
        limit: sizeTable,
        orderBy: _.get(reportModel, 'orderBy'),
        orderType: _.get(reportModel, 'orderSort'),
      };
      const reesponse = await getModelDataList(body);

      setReportModel((prev) => ({
        ...prev,
        list: _.get(reesponse, 'data.itemModelList'),
        totalItem: _.get(reesponse, 'data.totalModel'),
      }));
      setStatusList(_.get(reesponse, 'data.statusList'));
    };
    setReportModel((prev) => ({ ...prev, loading: false }));
    if (selectBrand) {
      getApi();
    }
  }, [
    eventmodel,
    selectBrand,
    _.get(reportModel, 'paginationPage'),
    _.get(reportModel, 'paginationSize'),
    _.get(reportModel, 'fieldSort'),
    _.get(reportModel, 'orderSort'),
    _.get(reportModel, 'statusCode'),
    _.get(reportModel, 'customerName'),
    _.get(reportModel, 'itemModelName'),
    pageTable,
    sizeTable,
    event,
  ]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('modalCreateBrandColumnModelNo', 'Model/ Part No.'),
      dataIndex: 'itemModelNo',
      key: 'itemModelNo',
      sorter: true,
      width: 180,
      ellipsis: true,
    },
    {
      title: messageLabel('modalCreateBrandColumnModelName', 'Model Name'),
      dataIndex: 'itemModelName',
      key: 'itemModelName',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalCreateBrandColumnStatus', 'Status'),
      dataIndex: 'statusName',
      key: 'statusName',
      sorter: true,
      width: 150,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={_.get(record, 'statusColor')}>
            {_.get(record, 'statusName')}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('modalCreateBrandColumnRemark', 'Remark'),
      dataIndex: 'itemModelDescription',
      key: 'itemModelDescription',
      sorter: true,
      width: 300,
    },
    {
      title: messageLabel('modalCreateBrandColumnCreatedby', 'Created by'),
      dataIndex: 'createdByName',
      key: 'createdByName',
      width: 150,
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('modalCreateBrandColumnCreatedDate', 'Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 150,
      // render: (text, record, index) => (
      //   // _.get(record, 'total') ? (
      //   <span>{moment(_.get(record, 'createdAt'), 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
      // ),
    },
    {
      title: messageLabel('modalCreateBrandColumnDeletedby', 'Deleted by'),
      dataIndex: 'deletedByName',
      key: 'deletedByName',
      width: 150,
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('modalCreateBrandColumnDeletedDate', 'Deleted Date'),
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      sorter: true,
      width: 150,
      // render: (text, record, index) => (
      //   // _.get(record, 'total') ? (
      //   <span>
      //     {_.get(record, 'deletedAt') === '-'
      //       ? _.get(record, 'deletedAt')
      //       : moment(_.get(record, 'deletedAt'), 'YYYY-MM-DD').format('DD/MM/YYYY')}
      //   </span>
      // ),
    },
  ];

  const handleClose = () => {
    onCancel();
    setReportModel({
      list: [],
      totalItem: 0,
      extraSort: undefined,
      fieldSort: '',
      orderSort: 'asc',
      paginationPage: 1,
      paginationSize: 10,
      loading: false,
      quotationNo: '',
      invoiceNo: '',
      paymentTermNo: '',
      saleOrderNo: '',
      customerName: '',
      rangePickerDate: [],
      statusCode: [],
      issueDateStart: '',
      issueDateEnd: '',
    });
  };

  const onSelectRow = async (value) => {
    const body = {
      brandId: selectBrand,
      modelId: _.get(value, 'itemModelId'),
    };
    setBrandId(selectBrand);
    setCreateItem(body);
    handleClose();
    setEvent((prev) => !prev);
  };

  const handleResetBrand = (value) => {
    setReportBrand((prev) => ({ ...prev, invoiceNo: '', saleOrderNo: '', customerId: '', statusCode: [], paginationPage: 1 }));
    setEvent((event) => !event);
  };

  const handleResetModel = (value) => {
    setReportModel((prev) => ({ ...prev, active: '', itemModelDescription: '', paginationPage: 1 }));
    setEventModel((event) => !event);
  };

  const handleToggleBrand = (value, key) => {
    setToggleBrand((prev) => !prev);
  };

  const handleChangeBrand = (value, key) => {
    setReportBrand((prev) => ({ ...prev, [key]: value }));
  };

  const handleClickApplyBrand = () => {
    setReportBrand((prev) => ({ ...prev, apply: !_.get(prev, 'apply') }));
  };

  const handleCancelBrand = () => {
    setReportBrand((prev) => ({ ...prev, brand: '', status: undefined, remark: '', apply: !_.get(prev, 'apply') }));
  };

  const handleChange = (value, key) => {
    // console.log('handleChange', value, key, statusList, reportModel)
    setReportModel((prev) => ({ ...prev, [key]: value }));
  };

  const handleopenModalBrand = (value) => {
    if (value === 'create') {
      setRecordBrand({});
    }
    setVisibleCreateBrand(true);
  };

  const handleCancelModalBrand = (value, key) => {
    setVisibleCreateBrand(false);
    setEventDataDefault((prev) => !prev);
  };

  const handleopenModalModel = () => {
    setVisibleCreateModel(true);
    setRecordModel({ itemBrandId: selectBrand });
  };

  const handleCancelModalModel = (value, key) => {
    setVisibleCreateModel(false);
  };

  const handleopenModalViewBrand = (value) => {
    setRecordBrand(value);
    setvisibleViewBrand(true);
  };

  const handleCancelModalViewBrand = (value, key) => {
    setvisibleViewBrand(false);
    setRecordBrand({});
  };

  const handleopenModalEditModel = (value) => {
    setRecordModel({ ...value, mode: 'edit' });
    setVisibleCreateModel(true);
  };

  const handleCancelModalEditModel = () => {
    setVisibleCreateModel(false);
    setRecordModel([]);
  };

  const handleApplyBrand = () => {
    setEventModel((prev) => !prev);
    setReportModel((prev) => ({ ...prev, paginationPage: 1 }));
  };

  const advancedFilter = (action, value) => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCreateBrandFilterStatus', defaultMessage: 'Status' })} req={false} />
              }
            >
              <Select
                allowClear
                value={_.get(value, 'active') || undefined}
                onChange={(value) => handleChange(value, 'active')}
                placeholder={intl.formatMessage({ id: 'modalCreateBrandFilterStatusPlaceholder', defaultMessage: 'Select Status' })}
                // mode="multiple"
              >
                {statusList &&
                  statusList.map((item) => (
                    <Option key={item.active} value={item.active}>
                      {item.statusName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCreateBrandFilterRemark', defaultMessage: 'Remark' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'itemModelDescription')}
                onChange={(e) => handleChange(e.target.value, 'itemModelDescription')}
                placeholder={intl.formatMessage({ id: 'modalCreateBrandFilterRemarkPlaceholder', defaultMessage: 'Enter Remark' })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleResetModel}>
              <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
            </Button_02>

            <Button_01
              style={{ margin: '0px 0px 0px 10px' }}
              key="print"
              type="primary"
              btnsize="wd_df"
              onClick={() => {
                handleApplyBrand();
              }}
            >
              <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
            </Button_01>
          </Col>
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    if (_.get(record, 'active') === 'true') {
      return (
        <div style={{ display: 'grid' }}>
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => onSelectRow(record)}>
              <FormattedMessage id="btnSelect" defaultMessage="Select" />
            </Button>
          </div>
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleopenModalEditModel(record)}>
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button>
          </div>
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record)}>
              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
            </Button>
          </div>
        </div>
      );
    }
  };

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'modalCreateBrandModelDeleted', defaultMessage: 'Are you sure to delete Model ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          itemModelId: _.get(record, 'itemModelId'),
          itemBrandId: _.get(record, 'itemBrandId'),
          itemModelName: _.get(record, 'itemModelName'),
          itemModelNo: _.get(record, 'itemModelNo'),
          itemModelDescription: _.get(record, 'itemModelDescription'),
          active: 'false',
        };

        try {
          const response = await createModelData(payload);

          if (_.get(response, 'status.code') === 200) {
            successNotification(response.status.message);
            setEventDataDefault((prev) => !prev);
            setEvent((prev) => !prev);
          } else {
            errorNotification(_.get(response, 'status.message'));
          }
        } catch (error) {
          errorNotification(error.response.data.data.message);
        }
      },
    });
  };

  const handleFieldSort = (value) => {
    setReportModel((prev) => ({ ...prev, orderBy: value }));
  };

  const handleOrderSort = (value) => {
    setReportModel((prev) => ({ ...prev, orderSort: value }));
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalCreateBrandTitle', defaultMessage: 'Brand and Model' })}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1122}
      zIndex={1000}
      centered={true}
      bodyStyle={{ padding: '0px 24px' }}
      style={{ height: 'calc(130vh - 300px)', margin: '0px 0px' }}
      className="modalBrandAndModel"
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row style={{ marginTop: '10px' }}>
        <Col span={6} style={{ borderRight: '1px solid #e8e8e8' }}>
          <div
            className="listItemTextHeader"
            style={{ padding: '0px 16px 16px 24px',marginLeft: '-24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <span style={{ width: '50%' }}>
              <FormattedMessage id="modalCreateBrandTitleBrand" defaultMessage="Brand" />
            </span>
            <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleopenModalBrand('create')}>
              <FormattedMessage id="btnCreate" defaultMessage="Create" />
            </Button_01>
          </div>
          <div
            className="listItemTextHeader"
            style={{ padding: '16px 16px 16px 24px',marginLeft: '-24px', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
            onClick={handleToggleBrand}
          >
            <span style={{ width: '80%' }}>
              <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />{' '}
              <span style={{ color: '#BCC5D3' }}>
                {`(`}
                <FormattedMessage id="modalCreateBrandFilterResults" defaultMessage="Results" />:{' '}
                {_.get(reportBrand, 'totalItemBrandsActive')}
                {`)`}
              </span>
            </span>

            <img src={filterimg} style={{ width: '17px' }} />
          </div>
          <div className="listItemDividerCenter" style={{ backgroundColor: '#F2F3F5', marginLeft: '-24px' }}>
            {toggleBrand === true ? (
              <ListItemFilter
                onChange={handleChangeBrand}
                onAllowClear={{}}
                searchGroup={reportBrand}
                onClick={handleClickApplyBrand}
                onCancel={handleCancelBrand}
                // refType={statusList}
                tempchange={tempchange}
              />
            ) : null}
          </div>
          <div className="listItemDividerCenter" style={{ marginLeft: '-24px' }}>
            <ListItemBrand
              reportBrand={reportBrand}
              setSelectBrand={setSelectBrand}
              selectBrand={selectBrand}
              handleopenModalViewBrand={handleopenModalViewBrand}
              setPageTable={setPageTable}
              setSizeTable={setSizeTable}
              toggleBrand={toggleBrand}
            />
          </div>
        </Col>
        <Col span={18}>
          <div
            className="listItemTextHeader"
            style={{ padding: '0px 24px 15px 24px',marginRight: '-24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <span>
              <FormattedMessage id="modalCreateBrandTitleModel" defaultMessage="Model" />
            </span>

            <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleopenModalModel()}>
              <FormattedMessage id="btnCreate" defaultMessage="Create" />
            </Button_01>
          </div>
          <div style={{ paddingTop: '16px' }}>
            <CollapseCustom
              setIsCollapse={setIsCollapse}
              label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
              extra={
                <Row style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                  <Col span={4}>
                    <FormattedMessage id="modalCreateBrandTitleModel" defaultMessage="Model" />:
                  </Col>
                  <Col span={20}>
                    <Input
                      allowClear
                      value={_.get(reportModel, 'itemModelName')}
                      onChange={(e) => handleChange(e.target.value, 'itemModelName')}
                      placeholder={intl.formatMessage({
                        id: 'modalCreateBrandFilterModelPlaceholder',
                        defaultMessage: 'Search Model No. or Name',
                      })}
                    />
                  </Col>
                </Row>
              }
            >
              {advancedFilter(setReportModel, reportModel)}
            </CollapseCustom>
          </div>
          <div style={{ height: isCollapse ? `calc(90vh - 357px)` : `calc(100vh - 357px)`, padding: '16px' }}>
            {/* {_.get(reportModel, 'referenceType') && _.size(_.get(reportModel, 'list')) > 0 ? ( */}
            <CustomTableComponent
              columns={columns}
              dataSource={_.get(reportModel, 'list')}
              total={_.get(reportModel, 'totalItem')}
              onRow={true}
              role={true}
              paginationPage={pageTable}
              setPaginationPage={setPageTable}
              paginationShow={true}
              setPaginationSize={setSizeTable}
              paginationSize={sizeTable}
              scroll={{ x: true, y: isCollapse ? `calc(72vh - 357px)` : `calc(90vh - 357px)` }}
              contentAction={contentAction}
              setFieldSort={handleFieldSort}
              setOrderSort={handleOrderSort}
            />
          </div>
        </Col>
      </Row>
      <ModalCreateBrandForm
        title={
          _.get(recordBrand, 'itemBrandId')
            ? intl.formatMessage({ id: `modalCreateBrandTitleEditBrand`, defaultMessage: 'Edit Brand' })
            : intl.formatMessage({ id: `modalCreateBrandTitleCreateBrand`, defaultMessage: 'Create Brand' })
        }
        visible={visibleCreateBrand}
        onOk={handleCancelModalBrand}
        onCancel={handleCancelModalBrand}
        setEvent={setEvent}
        recordBrand={recordBrand}
        setRecordBrand={setRecordBrand}
      />
      <ModalCreateModelForm
        title={
          _.get(recordModel, 'itemModelId')
            ? intl.formatMessage({ id: `modalEditBrandTitleCreateModel`, defaultMessage: 'Edit Model' })
            : intl.formatMessage({ id: `modalCreateBrandTitleCreateModel`, defaultMessage: 'Create Model' })
        }
        visible={visibleCreateModel}
        onOk={handleCancelModalModel}
        onCancel={recordModel ? handleCancelModalEditModel : handleCancelModalModel}
        list={_.get(reportBrand, 'itemBrandsActive')}
        setEvent={setEvent}
        recordModel={recordModel}
      />
      <ModalViewBrandModal
        title={
          intl.formatMessage({ id: `modalCreateBrandTitleViewBrand`, defaultMessage: 'Brand' }) + '・' + _.get(recordBrand, 'itemBrandNo')
        }
        visible={visibleViewBrand}
        onOk={handleCancelModalViewBrand}
        onCancel={handleCancelModalViewBrand}
        setEvent={setEvent}
        recordBrand={recordBrand}
        handleopenModalBrand={handleopenModalBrand}
        setEventDataDefault={setEventDataDefault}
      />
    </Modal>
  );
};

CreateGroupTypeModal.defaultProps = {
  // const intl = useIntl();
  // title: ,
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCreateBrandModel = Form.create({ name: 'select_qt_Form' })(CreateGroupTypeModal);

export default ModalCreateBrandModel;
