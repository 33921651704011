import React from 'react'
import { Modal, Form, Row, Col, Select, Input } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import { saveOrderType, saveTaskTypeRemark } from '../../../../controllers/setting/task-type'
import _ from 'lodash'
import { successNotification, errorNotification } from '../../../../components/v2/notification';

const { Option } = Select

const Ordertype = (props) => {
  const { visible, setVisible, form, defaultData, orderData, setTrigger } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const intl = useIntl()


  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const difference = _.difference(_.get(defaultData, 'orderTypeList'), values.orderType)
      const descrip = await saveTaskTypeRemark(_.get(defaultData, 'id'), { remark: values.description })
      let status = true
      let massege = []
      for (let index = 0; index < values.orderType.length; index++) {
        const element = values.orderType[index];
        const body = {
          taskTypeId: _.get(defaultData, 'id')
        }
        const response = await saveOrderType(element, body)
        if (_.get(response, 'status') !== 200) {
          status = false
        }
        massege.push(_.get(response, 'data.status.message'))
      }
      if (_.size(difference) > 0) {
        for (let index = 0; index < difference.length; index++) {
          const element = difference[index];
          await saveOrderType(element, { taskTypeId: null })
        }
      }
      if (status) successNotification(_.get(massege, '[0]') ? _.get(massege, '[0]') : _.get(descrip, 'data.status.message'))
      if (!status) errorNotification(_.get(massege, '[0]'))
      setTrigger(cur => !cur)
      setVisible(false)
      resetFields()
    })
  }


  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  console.log("defaultData", defaultData)

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalAddOrderType', defaultMessage: 'Add Order Type' })}
      onCancel={handleCancel}
      visible={visible}
      centered={true}
      width={544}
      bodyStyle={{ padding: '12px 24px 24px 24px' }}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item>
              <div className="text-style" style={{ fontWeight: 600 }}>
                <FormattedMessage id="settingTaskTypeColTaskType" defaultMessage="Task Type" />
              </div>
              <div className="text-style">
                {_.get(defaultData, 'name')}
              </div>
            </Form.Item>
            <Form.Item>
              <span className="text-style" style={{ fontWeight: 600 }}>
                <FormattedMessage id="settingTaskTypeColDescription" defaultMessage="Description" />
              </span>
              {getFieldDecorator('description', {
                initialValue: _.get(defaultData, 'description'),
              }
              )(
                <Input
                  placeholder={intl.formatMessage({ id: 'placeHolderDescription', defaultMessage: 'Enter Description' })}
                />
              )}
            </Form.Item>
            <Form.Item>
              <span className="text-style" style={{ fontWeight: 600 }}>
                <FormattedMessage id="planningColumnOrderType" defaultMessage="Order Type" />
              </span>
              {getFieldDecorator('orderType', {
                initialValue: _.get(defaultData, 'orderTypeList') || [],
              }
              )(
                <Select
                  placeholder={intl.formatMessage({ id: 'placeHolderOrderType', defaultMessage: 'Select Order Type' })}
                  mode="multiple"
                  className="select-module"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {orderData && orderData.map((item) => <Option key={item.orderTypeCode}>{item.orderTypeCode}</Option>)}
                </Select>
              )}
            </Form.Item>

          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const OrderTypeModal = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {

  },
})(Ordertype);

export default OrderTypeModal
