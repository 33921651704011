import React, { useRef, useState, useContext } from 'react'
import { Card, Tabs, Table, Row, Col, Button, Icon, Popover } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import { StickyContainer, Sticky } from 'react-sticky';
import _ from 'lodash'
import Button01 from '../../../components/v2/button_01';
import { TravelExpenseContext } from './context'
import { PageSettings } from '../../../config/page-settings';

const { TabPane } = Tabs

const DetailTravelExpense = React.memo((props) => {
  const { state, setState, fnc } = useContext(TravelExpenseContext)
  const intl = useIntl()
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnEmployee', defaultMessage: 'Employee' }),
      dataIndex: 'employee',
      key: 'employee',
      sorter: true,
      width: 200,
      render: (text, record, index) => <span style={{ cursor: 'pointer' }} onClick={() => fnc.handleView(record)}>{record.employee}</span>,
    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnTeam', defaultMessage: 'Team' }),
      dataIndex: 'tesm',
      key: 'tesm',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnTripOrTaskNo', defaultMessage: 'Trip or Task No.' }),
      dataIndex: 'tripTaskNo',
      key: 'tripTaskNo',
      sorter: true,
      width: 200,

    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      width: 150,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      render: (text, record, index) => (
        <div style={{ cursor: 'pointer' }} onClick={() => fnc.handleView(record)}>
          :
        </div>
      ),
    },
  ]

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <Button style={{ width: 100 }} type="link" ghost onClick={() => fnc.handleView(record)}>
                <FormattedMessage id="btnView" defaultMessage="View" />
              </Button>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <Row>
          <Col span={1} className="col-button-hide">
            <Button
              className="button-disabled-summary"
              onClick={fnc.handleToggle}
              style={{ border: 'unset' }}
            >
              <Icon
                type={state.toggle ? 'menu-unfold' : 'menu-fold'}
                style={{ cursor: 'pointer', color: '#e73845' }}
              />
            </Button>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const TableMiddle = (props) => {
    const { dataSource } = props
    return (
      <div className='travelExpenseDetailStyle' onMouseLeave={fnc.handleMouseLeave}>
        <Table
          className='table-global-scroll'
          columns={columns}
          dataSource={dataSource}
          onChange={fnc.handleChange}
          scroll={{ x: _.sumBy(columns, 'width') }}
          rowKey={(record) => record.travelExpenseId}
          pagination={{
            total: state.total,
            current: state.paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
        // onRow={(record, rowIndex) => {
        //   console.log("onRow", record, rowIndex)
        //   return {
        //     onClick: (event) => {
        //       if (!event.target.href) {
        //         const { x, y } = ref.current.getBoundingClientRect();
        //         setVisiblePopoverTable(true);
        //         setState.setOffsetPopover([event.pageX - x, event.pageY - y]);
        //         setRecordPopover(record);
        //       }
        //     },
        //   };
        // }}
        />
        {/* {fncActionTable(state.recordPopover, state.offsetPopover, state.visiblePopoverTable, state.ref)} */}
        {_.size(state.data) > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${state.total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <>
          {
            checkPermissionAction('P50PG1C2', 'P50PG1C2A1') || checkPermissionAction('P50PG1C3', 'P50PG1C3A1') ||
              checkPermissionAction('P50PG1C4', 'P50PG1C4A1') || checkPermissionAction('P50PG1C5', 'P50PG1C5A1') ?
              (
                <Card
                  title=""
                  bodyStyle={{ minHeight: 'calc(100vh - 117px)', padding: 'unset' }}
                >
                  <StickyContainer>
                    <Tabs
                      size="large"
                      activeKey={state.tab}
                      onChange={fnc.changeTab}
                      renderTabBar={handleRenderTopBar}
                      animated={false}
                      tabBarExtraContent={
                        <div className="display-extra-button-group">
                          <div className="display-extra-button-group-item">
                            <Button01 btnsize="wd_at" type="primary" style={{ marginRight: '24px' }} onClick={() => fnc.recalculate()}>
                              <FormattedMessage id={`btnRefresh`} defaultMessage="Refresh" />
                            </Button01>
                          </div>
                        </div>
                      }
                    >
                      {
                        checkPermissionAction('P50PG1C2', 'P50PG1C2A1') ? (
                          <TabPane tab={`${intl.formatMessage({ id: 'travelExpenseTabWaiting', defaultMessage: 'Waiting' })} (${_.get(state.headerTotal, 'waitingTotal') || 0})`} key="waiting">
                            <div className='travelExpenseDetailStyle' onMouseLeave={fnc.handleMouseLeave}>
                              <Table
                                className='table-global-scroll'
                                columns={columns}
                                dataSource={state.data}
                                onChange={fnc.handleChange}
                                scroll={{ x: _.sumBy(columns, 'width'), y: 'calc(100vh - 310px)' }}
                                rowKey={(record) => record.travelExpenseId}
                                pagination={{
                                  total: state.total,
                                  current: state.paginationPage,
                                  showTotal: showTotal,
                                  defaultCurrent: 1,
                                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                                  showSizeChanger: true,
                                  locale: { items_per_page: '' },
                                }}
                              // onRow={(record, rowIndex) => {
                              //   console.log("onRow", record, rowIndex)
                              //   return {
                              //     onClick: (event) => {
                              //       if (!event.target.href) {
                              //         const { x, y } = ref.current.getBoundingClientRect();
                              //         setVisiblePopoverTable(true);
                              //         setState.setOffsetPopover([event.pageX - x, event.pageY - y]);
                              //         setRecordPopover(record);
                              //       }
                              //     },
                              //   };
                              // }}
                              />
                              {/* {fncActionTable(state.recordPopover, state.offsetPopover, state.visiblePopoverTable, state.ref)} */}
                              {_.size(state.data) > 0 ? (
                                <div className="total-items-member">
                                  <span style={{ fontSize: '13px' }}>
                                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                                    {` ${state.total || 0} `}
                                    <FormattedMessage id="lblitems" defaultMessage="items" />
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </TabPane>
                        ) : null
                      }
                      {
                        checkPermissionAction('P50PG1C3', 'P50PG1C3A1') ? (
                          <TabPane tab={`${intl.formatMessage({ id: 'travelExpenseTabApproved', defaultMessage: 'Approved' })}`} key="approve">
                            <div className='travelExpenseDetailStyle' onMouseLeave={fnc.handleMouseLeave}>
                              <Table
                                className='table-global-scroll'
                                columns={columns}
                                dataSource={state.data}
                                onChange={fnc.handleChange}
                                scroll={{ x: _.sumBy(columns, 'width'), y: 'calc(100vh - 310px)' }}
                                rowKey={(record) => record.travelExpenseId}
                                pagination={{
                                  total: state.total,
                                  current: state.paginationPage,
                                  showTotal: showTotal,
                                  defaultCurrent: 1,
                                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                                  showSizeChanger: true,
                                  locale: { items_per_page: '' },
                                }}
                              // onRow={(record, rowIndex) => {
                              //   console.log("onRow", record, rowIndex)
                              //   return {
                              //     onClick: (event) => {
                              //       if (!event.target.href) {
                              //         const { x, y } = ref.current.getBoundingClientRect();
                              //         setVisiblePopoverTable(true);
                              //         setState.setOffsetPopover([event.pageX - x, event.pageY - y]);
                              //         setRecordPopover(record);
                              //       }
                              //     },
                              //   };
                              // }}
                              />
                              {/* {fncActionTable(state.recordPopover, state.offsetPopover, state.visiblePopoverTable, state.ref)} */}
                              {_.size(state.data) > 0 ? (
                                <div className="total-items-member">
                                  <span style={{ fontSize: '13px' }}>
                                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                                    {` ${state.total || 0} `}
                                    <FormattedMessage id="lblitems" defaultMessage="items" />
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </TabPane>
                        ) : null
                      }
                      {
                        checkPermissionAction('P50PG1C4', 'P50PG1C4A1') ? (
                          <TabPane tab={`${intl.formatMessage({ id: 'travelExpenseTabPaid', defaultMessage: 'Paid' })}`} key="paid">
                            <div className='travelExpenseDetailStyle' onMouseLeave={fnc.handleMouseLeave}>
                              <Table
                                className='table-global-scroll'
                                columns={columns}
                                dataSource={state.data}
                                onChange={fnc.handleChange}
                                scroll={{ x: _.sumBy(columns, 'width'), y: 'calc(100vh - 310px)' }}
                                rowKey={(record) => record.travelExpenseId}
                                pagination={{
                                  total: state.total,
                                  current: state.paginationPage,
                                  showTotal: showTotal,
                                  defaultCurrent: 1,
                                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                                  showSizeChanger: true,
                                  locale: { items_per_page: '' },
                                }}
                              // onRow={(record, rowIndex) => {
                              //   console.log("onRow", record, rowIndex)
                              //   return {
                              //     onClick: (event) => {
                              //       if (!event.target.href) {
                              //         const { x, y } = ref.current.getBoundingClientRect();
                              //         setVisiblePopoverTable(true);
                              //         setState.setOffsetPopover([event.pageX - x, event.pageY - y]);
                              //         setRecordPopover(record);
                              //       }
                              //     },
                              //   };
                              // }}
                              />
                              {/* {fncActionTable(state.recordPopover, state.offsetPopover, state.visiblePopoverTable, state.ref)} */}
                              {_.size(state.data) > 0 ? (
                                <div className="total-items-member">
                                  <span style={{ fontSize: '13px' }}>
                                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                                    {` ${state.total || 0} `}
                                    <FormattedMessage id="lblitems" defaultMessage="items" />
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </TabPane>
                        ) : null
                      }
                      {
                        checkPermissionAction('P50PG1C5', 'P50PG1C5A1') ? (
                          <TabPane tab={`${intl.formatMessage({ id: 'travelExpenseTabRejected', defaultMessage: 'Rejected' })}`} key="reject">
                            <div className='travelExpenseDetailStyle' onMouseLeave={fnc.handleMouseLeave}>
                              <Table
                                className='table-global-scroll'
                                columns={columns}
                                dataSource={state.data}
                                onChange={fnc.handleChange}
                                scroll={{ x: _.sumBy(columns, 'width'), y: 'calc(100vh - 310px)' }}
                                rowKey={(record) => record.travelExpenseId}
                                pagination={{
                                  total: state.total,
                                  current: state.paginationPage,
                                  showTotal: showTotal,
                                  defaultCurrent: 1,
                                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                                  showSizeChanger: true,
                                  locale: { items_per_page: '' },
                                }}
                              // onRow={(record, rowIndex) => {
                              //   console.log("onRow", record, rowIndex)
                              //   return {
                              //     onClick: (event) => {
                              //       if (!event.target.href) {
                              //         const { x, y } = ref.current.getBoundingClientRect();
                              //         setVisiblePopoverTable(true);
                              //         setState.setOffsetPopover([event.pageX - x, event.pageY - y]);
                              //         setRecordPopover(record);
                              //       }
                              //     },
                              //   };
                              // }}
                              />
                              {/* {fncActionTable(state.recordPopover, state.offsetPopover, state.visiblePopoverTable, state.ref)} */}
                              {_.size(state.data) > 0 ? (
                                <div className="total-items-member">
                                  <span style={{ fontSize: '13px' }}>
                                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                                    {` ${state.total || 0} `}
                                    <FormattedMessage id="lblitems" defaultMessage="items" />
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </TabPane>
                        ) : null
                      }
                    </Tabs>
                  </StickyContainer>
                </Card>
              ) : null
          }

        </>
      )}

    </PageSettings.Consumer>
  )
})

export default React.memo(DetailTravelExpense)