import React from 'react';
import { Tag } from 'antd';

import stylePost from './css/post.css';

export default ((props) => {
  const { postData, handleTags } = props;

  const splitText = (val) => {
    let lastDotIndex = val.lastIndexOf('.');
    let everythingAfterTheFinalDot = val.substring(lastDotIndex + 1);
    return everythingAfterTheFinalDot;
  }

  return (
    <div className='post-tags-view-modal' >
      <div className='post-tags-view'>
        {postData && postData.map((item, index) => (
          <Tag className='post-tags' key={index} onClick={() => handleTags(item)}>
            <span title={item.originalname}>{item.originalname.length > 30 ? item.originalname.substring(0, 30) + '...' + splitText(item.originalname) : item.originalname}</span>
          </Tag>
        ))}
      </div>
    </div>
  )
})