import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Card, Row, Popover, Icon, Modal, Button } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button03 from '../../../components/v2/button_03';
import AddTeamService from '../../../components/add-team-service/index';
import httpClient from '../../../components/axiosClient';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import Button02 from '../../../components/v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import EditTeamService from '../../../components/edit-team-service/index';
import styled from './css/team-service.css';
import _ from 'lodash';
import { PageSettings } from '../../../config/page-settings';

export default ({
  customerId,
  settriggerTeamService,
  triggerTeamService,
  teamService,
  setPageTeam,
  setSizeTeam,
  total,
  onChangeTableTeam,
  loading,
  pageTeam,
}) => {
  const intl = useIntl();

  const [visiblePopover, setVisiblePopover] = useState(false);
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: intl.formatMessage({ id: 'customerTeamServiceTableColumnTeam', defaultMessage: 'Team' }),
      // dataIndex: 'org',
      // key: 'org',
      render: (text, record, index) => (
        <div>
          {record.org.map((item, i) => {
            return (
              <span>
                {item.orgName} {i < _.size(record.org) - 1 ? ',' : ''}&nbsp;
              </span>
            );
          })}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'customerTeamServiceTableColumnTaskType', defaultMessage: 'Task Type' }),
      // dataIndex: 'taskType',
      // key: 'taskType',
      render: (text, record, index) => (
        <div>
          {record.taskType.map((item, i) => (
            <span>
              {item.taskTypeName} {i < _.size(record.taskType) - 1 ? ',' : ''}&nbsp;
            </span>
          ))}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'customerTeamServiceTableColumnPriority', defaultMessage: 'Priority' }),
      dataIndex: 'priority',
      key: 'priority',
      align: 'center',
    },
    // {
    //   title: '',
    //   key: 'options',
    //   dataIndex: 'options',
    //   align: 'center',
    //   width: '5%',
    //   render: (text, record, index) => fncActionTable(record, index),
    // },
  ];

  const { confirm } = Modal;
  const comCode = localStorage.getItem('comCode');
  const [visibleModalAddTeam, setVisibleModalAddTeam] = useState(false);
  const [visibleModalEditTeam, setVisibleModalEditTeam] = useState(false);
  const [dataDefaultEdit, setDataDefaultEdit] = useState();
  const [taskType, setTaskType] = useState();
  const [team, setTeam] = useState();
  const [formRef, setFormRef] = useState(null);
  const [formRefEdit, setFormRefEdit] = useState(null);

  useEffect(() => {
    getValueDefault();
  }, []);

  const getValueDefault = async () => {
    try {
      const response = await httpClient.get(`/v3/task/manager/company/${comCode}/initial/all-tasktype/all-team`);
      if (response.status == 200) {
        setTaskType(response.data.data.taskTypes);
        setTeam(response.data.data.teams);
      }
    } catch (error) {
      return;
    }
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const saveFormRefEdit = useCallback((node) => {
    if (node !== null) {
      setFormRefEdit(node);
    }
  }, []);

  const onCreateTeamService = async () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      } else {
        const OrgToString = values.team;
        const mapOrg = OrgToString.map((item) => {
          return item.toString();
        });

        const taskTypeToString = values.taskType;
        const mapTaskType = taskTypeToString.map((item) => {
          return item.toString();
        });

        const body = {
          customerId: customerId,
          orgId: mapOrg,
          taskTypeId: mapTaskType,
          priority: values.priority,
        };

        try {
          const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-team-service/`, body);
          if (response.status == 200) {
            settriggerTeamService(!triggerTeamService);
            successNotification(response.data.status.message);
            setVisibleModalAddTeam(false);
            formRef.resetFields();
          }
        } catch (error) {
          errorNotification(error.response.data.status.message);
        }
      }
    });
  };

  const onEditTeamService = async () => {
    formRefEdit.validateFields(async (err, values) => {
      if (err) {
        return;
      } else {
        const OrgToString = values.team;
        const mapOrg = OrgToString.map((item) => {
          return item.toString();
        });

        const taskTypeToString = values.taskType;
        const mapTaskType = taskTypeToString.map((item) => {
          return item.toString();
        });

        const body = {
          orgId: mapOrg,
          taskTypeId: mapTaskType,
          priority: values.priority,
        };
        try {
          const response = await httpClient.put(
            `/v3/business-partner/manager/company/${comCode}/customer-team-service/${dataDefaultEdit.customerTeamServiceId}`,
            body
          );
          if (response.status == 200) {
            settriggerTeamService(!triggerTeamService);
            successNotification(response.data.status.message);
            setVisibleModalEditTeam(false);
            formRefEdit.resetFields();
          }
        } catch (error) {
          errorNotification(error.response.data.status.message);
        }
      }
    });
  };

  const handlerDelete = (customerTeamServiceId) => {
    confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerTeamServiceDeleteConfirmText', defaultMessage: 'Are you sure to delete team service ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(
            `/v3/business-partner/manager/company/${comCode}/customer-team-service/${customerTeamServiceId}`
          );
          if (response.status == 200) {
            settriggerTeamService(!triggerTeamService);
            successNotification(response.data.status.message);
          }
        } catch (error) {
          errorNotification(error.response.data.status.message);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const onCancelCreate = () => {
    setVisibleModalAddTeam(false);
    formRef.resetFields();
  };

  const onCancelEdit = () => {
    setVisibleModalEditTeam(false);
    formRefEdit.resetFields();
    setDataDefaultEdit();
  };

  const handlePagination = (page) => {
    setPageTeam(page);
  };

  const handleSizeChange = (current, size) => {
    setPageTeam(current);
    setSizeTeam(size);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          // zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG2C4', 'P8PG2C4A3') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            setVisibleModalEditTeam(true);
                            setDataDefaultEdit(record);
                            handleMouseLeave();
                          }}
                        >
                          <p>
                            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                          </p>
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG2C4', 'P8PG2C4A4') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            handlerDelete(record.customerTeamServiceId);
                            handleMouseLeave();
                          }}
                        >
                          <p>
                            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                          </p>
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  // const fncActionTable = (record, index) => {
  //   return (
  //     <div>
  //       <Popover
  //         key={index}
  //         placement="leftTop"
  //         trigger="hover"
  //         visiblePopover={visiblePopover}
  //         onVisibleChange={handleOnVisiblePopover}
  //         zIndex={999}
  //         content={
  //           <div style={{ display: 'grid' }}>
  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <div>
  //                     {checkPermissionAction('P8PG2C4', 'P8PG2C4A3') ? (
  //                       <Button
  //                         style={{ width: 100 }}
  //                         type="link"
  //                         ghost
  //                         onClick={() => {
  //                           setVisibleModalEditTeam(true);
  //                           setDataDefaultEdit(record);
  //                         }}
  //                       >
  //                         <p>
  //                           <FormattedMessage id="btnEdit" defaultMessage="Edit" />
  //                         </p>
  //                       </Button>
  //                     ) : null}
  //                   </div>
  //                 )}
  //               </PageSettings.Consumer>
  //             </>

  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <div>
  //                     {checkPermissionAction('P8PG2C4', 'P8PG2C4A4') ? (
  //                       <Button style={{ width: 100 }} type="link" ghost onClick={() => handlerDelete(record.customerTeamServiceId)}>
  //                         <p>
  //                           <FormattedMessage id="btnDelete" defaultMessage="Delete" />
  //                         </p>
  //                       </Button>
  //                     ) : null}
  //                   </div>
  //                 )}
  //               </PageSettings.Consumer>
  //             </>
  //           </div>
  //         }
  //       >
  //         <Icon type="more" />
  //       </Popover>
  //     </div>
  //   );
  // };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Card
            title={intl.formatMessage({ id: 'customerTeamServiceCardHeader', defaultMessage: 'Team Service' })}
            extra={
              checkPermissionAction('P8PG2C4', 'P8PG2C4A2') ? (
                <Button01
                  type="primary"
                  onClick={() => {
                    setVisibleModalAddTeam(true);
                  }}
                >
                  <FormattedMessage id="btnAdd" defaultMessage="Add" />
                </Button01>
              ) : null
            }
          >
            <div className="containerTables" onMouseLeave={handleMouseLeave}>
              <Table
                columns={columns}
                dataSource={teamService && teamService !== undefined ? teamService : null}
                onChange={onChangeTableTeam}
                pagination={{
                  showTotal: showTotal,
                  defaultCurrent: 1,
                  total: total,
                  current: pageTeam,
                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                  showSizeChanger: true,
                  locale: { items_per_page: '' },
                  onChange: handlePagination,
                  onShowSizeChange: handleSizeChange,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      if (!event.target.href) {
                        const { x, y } = ref.current.getBoundingClientRect();
                        setVisiblePopoverTable(true);
                        setOffsetPopover([event.pageX - x, event.pageY - y]);
                        setRecordPopover(record);
                      }
                    },
                  };
                }}
              />
              {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
            </div>
            {teamService && teamService.length !== 0 ? (
              <div className="total-items-timeAttendance">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${total || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}
          </Card>
          <Modal
            className="padding-content"
            title={intl.formatMessage({ id: 'customerTeamServiceCardHeader', defaultMessage: 'Team Service' })}
            visible={visibleModalAddTeam}
            centered={true}
            onCancel={() => onCancelCreate()}
            onOk={() => onCreateTeamService()}
            width={642}
            footer={[
              <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancelCreate()}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => onCreateTeamService()}>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <AddTeamService ref={saveFormRef} taskType={taskType} teamData={team} onCreate={onCreateTeamService} intl={intl} />
          </Modal>

          <Modal
            className="padding-content"
            title={intl.formatMessage({ id: 'customerTeamServiceCardHeader', defaultMessage: 'Team Service' })}
            visible={visibleModalEditTeam}
            centered={true}
            onCancel={() => onCancelEdit()}
            onOk={() => onEditTeamService()}
            width={642}
            footer={[
              <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancelEdit()}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => onEditTeamService()}>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <EditTeamService
              ref={saveFormRefEdit}
              taskType={taskType}
              teamData={team}
              onCreate={onEditTeamService}
              dataDefault={dataDefaultEdit}
              teamService={teamService}
              intl={intl}
            />
          </Modal>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
