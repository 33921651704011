import React, { useContext, useEffect } from 'react';
import { ExpenseModal } from '.';
import { Row, Col, Input, Form, DatePicker, Select, InputNumber } from 'antd';
import LabeRequireForm from '../../../../components/label-required-form';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../component-function/fnc-number';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';

const { Option } = Select;

const ExpenseFormOrder = (props) => {
  const { form } = props;
  const intl = useIntl();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { userList, handleOpenModalPO, poSelect } = useContext(ExpenseModal);
  const dueDate = getFieldValue('dueDate');
  const issuedDate = getFieldValue('issuedDate');

  useEffect(() => {
    if (poSelect) {
      setFieldsValue({
        ['orderNo']: _.get(poSelect, 'poNo'),
        ['total']: numberFormatter(_.get(poSelect, 'subtotal')),
        ['accrued']: numberFormatter(_.get(poSelect, 'totolAccruedExpense')),
      });
    }
  }, [poSelect]);

  const validatorDueDate = (rule, value, callback) => {
    if (value) {
      const start = moment(`${moment(value).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm');
      const end = moment(`${moment(dueDate).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm');
      if (start > end) {
        callback(<FormattedMessage id="expenseValidateDueDate" defaultMessage="Issued Date more than Due Date" />);
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const validatorIssuedDate = (rule, value, callback) => {
    if (value) {
      const start = moment(`${moment(issuedDate).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm');
      const end = moment(`${moment(value).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm');
      if (start > end) {
        callback(<FormattedMessage id="expenseValidateIssuedDate" defaultMessage="Due Date more than Issued Date" />);
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  // const validatorAmount = (rule, value, callback) => {
  //   if (value || value === 0) {
  //     const parsAmount = parseFloat(value || 0)
  //     if (parsAmount === 0) {
  //       callback(<FormattedMessage id="expenseValidateAmount" defaultMessage='Amount must be more than 0' />);
  //     } else {
  //       callback();
  //     }
  //   }
  //   else {
  //     callback();
  //   }
  // }

  const validatorAmount = (rule, value, callback) => {
    if (value || value === 0 && getFieldValue('accrued')) {
      const parsAmount = parseFloat(value || 0);
      console.log('parsAmount', parsAmount, '/arrucrd: ', parseFloat(_.get(poSelect, 'totolAccruedExpense')));
      if (parsAmount === 0) {
        callback(<FormattedMessage id="expenseValidateAmount" defaultMessage="Amount must be more than 0" />);
      } else if (parsAmount > parseFloat(_.get(poSelect, 'totolAccruedExpense'))) {
        callback(<FormattedMessage id="expenseValidateAmountMore" defaultMessage="Amount must be less than Accrued Expense " />);
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const handleChangeNumberIssuedDate = (value) => {
    const start = moment(`${moment(value).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm');
    const end = moment(`${moment(dueDate).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm');
    const daysDiff = end.diff(start, 'days');
    setFieldsValue({
      ['numberDueDate']: daysDiff,
    });
  };

  const handleChangeNumberDueDate = (value) => {
    setFieldsValue({
      ['dueDate']: moment(issuedDate).add(parseInt(value), 'days'),
    });
  };

  const handleChangeDueDate = (value) => {
    const start = moment(`${moment(issuedDate).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm');
    const end = moment(`${moment(value).format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm');
    const daysDiff = end.diff(start, 'days');
    setFieldsValue({
      ['numberDueDate']: daysDiff,
    });
  };

  const currencyFormatter = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };

  return (
    <div style={{ padding: '0 24px' }}>
      <Row gutter={[24, 2]}>
        <Col span={8} onClick={() => handleOpenModalPO()}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'expenseFormOrderNo', defaultMessage: 'Purchase Order No.' })} req={false} />
            }
          >
            {getFieldDecorator('orderNo', {
              rules: [
                {
                  required: false,
                  message: intl.formatMessage({ id: 'expenseFormReqVendor', defaultMessage: 'Please Select Purchase Order No.' }),
                },
              ],
            })(
              <Input.Search
                placeholder={intl.formatMessage({ id: 'expenseFormPHOrderNo', defaultMessage: 'Select Purchase Order No.' })}
                enterButton
                className="expendeSearchInput"
                readOnly={true}
                style={{ cursor: 'pointer' }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormTotal', defaultMessage: 'Total' })} req={false} />}>
            {getFieldDecorator('total', {
              initialValue: '0.00',
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'expenseFormPHTotal', defaultMessage: 'Enter Total' })}
                disabled={true}
                suffix={'THB'}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'expenseFormAccrued', defaultMessage: 'Accrued Expense' })} req={false} />
            }
          >
            {getFieldDecorator('accrued', {
              initialValue: '0.00',
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'expenseFormPHAccrued', defaultMessage: 'Enter Accrued Expense' })}
                disabled={true}
                suffix={'THB'}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 2]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'expenseFormInvoiceNo', defaultMessage: 'Invoice No.' })}
                req={getFieldValue('orderNo') ? true : false}
              />
            }
          >
            {getFieldDecorator('invoiceNo', {
              rules: [
                {
                  required: getFieldValue('orderNo') ? true : false,
                  message: intl.formatMessage({ id: 'expenseFormReqInvoiceNo', defaultMessage: 'Please Enter Invoice No.' }),
                },
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'expenseFormPHInvoiceNo', defaultMessage: 'Enter Invoice No.' })}
                // disabled={getFieldValue('orderNo') ? false : true}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'expenseFormInvoiceDate', defaultMessage: 'Invoice Date' })}
                req={getFieldValue('orderNo') ? true : false}
              />
            }
          >
            {getFieldDecorator('invoiceDate', {
              rules: [
                {
                  required: getFieldValue('orderNo') ? true : false,
                  message: intl.formatMessage({ id: 'expenseFormReqInvoiceDate', defaultMessage: 'Please Enter Invoice Date' }),
                },
              ],
            })(
              <DatePicker
                placeholder={intl.formatMessage({ id: 'expenseFormPHInvoiceDate', defaultMessage: 'Select Invoice Date' })}
                // disabled={getFieldValue('orderNo') ? false : true}
                style={{ width: '100%' }}
                format={'DD/MM/YYYY'}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'expenseFormAmount', defaultMessage: 'Amount' })}
                req={getFieldValue('orderNo') ? true : false}

              />
            }
          >
            {getFieldDecorator('amount', {
              rules: [
                {
                  required: getFieldValue('orderNo') ? true : false,
                  message: intl.formatMessage({ id: 'expenseFormReqAmount', defaultMessage: 'Please Enter Amount' }),
                },
                {
                  validator: getFieldValue('orderNo') ? validatorAmount : validatorAmount,
                },
              ],
            })(
              <InputNumber
                className="disabled-handle-count"
                placeholder={intl.formatMessage({ id: 'expenseFormPHAmount', defaultMessage: 'Enter Amount' })}
                // disabled={getFieldValue('orderNo') ? false : true}
                suffix="THB"
                onKeyDown={handleKeyDownDecimal2Fixed}
                formatter={currencyFormatter}
                parser={currencyParser}
                controls={false}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 2]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormIssuedBy', defaultMessage: 'Issued by' })} req={true} />}
          >
            {getFieldDecorator('issuedBy', {
              initialValue: parseInt(localStorage.getItem('memComId')),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqIssuedBy', defaultMessage: 'Please Select Issued by' }),
                },
              ],
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'expenseFormPHIssuedBy', defaultMessage: 'Select Issued by' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {_.map(userList, (item) => (
                  <Option value={item.mem_com_id}>{item.fullname}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormIssuedDate', defaultMessage: 'Issued Date' })} req={true} />}
          >
            {getFieldDecorator('issuedDate', {
              initialValue: moment(),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'expenseFormReqIssuedDate', defaultMessage: 'Please Select Issued Date' }),
                },
                {
                  validator: validatorDueDate,
                },
              ],
            })(
              <DatePicker
                placeholder={intl.formatMessage({ id: 'expenseFormPHIssuedDate', defaultMessage: 'Select Issued Date' })}
                style={{ width: '100%' }}
                format={'DD/MM/YYYY'}
                allowClear={false}
                onChange={handleChangeNumberIssuedDate}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormDueDate', defaultMessage: 'Due Date' })} req={true} />}
          >
            <Row>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('numberDueDate', {
                    initialValue: 1,
                  })(
                    <Input
                      suffix={'days'}
                      className="expendeSearchInput"
                      onKeyDown={handleKeyDownNumber}
                      onChange={(e) => handleChangeNumberDueDate(e.target.value)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item>
                  {getFieldDecorator('dueDate', {
                    initialValue: moment().add(1, 'days'),
                    rules: [
                      {
                        validator: validatorIssuedDate,
                      },
                    ],
                  })(
                    <DatePicker
                      style={{ width: '100%' }}
                      className="expendeDatePicker"
                      format={'DD/MM/YYYY'}
                      allowClear={false}
                      onChange={handleChangeDueDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ExpenseFormOrder;
