import { Col, Icon, Modal, Row } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import CustomTableComponent from '../component/table';
import { messageLabel } from '../../../../components/message-component';
import ListItemFilter from './filter';
import ListDetail from './detail';
import { exportAssetsDetail, getDetailAssetExpenseReport } from '../../../../controllers/resource-report/resource-report-api';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import { errorNotification, successNotification } from '../../../../components/v2/notification';

const ModalAsset = ({ record, visible, handleClose }) => {
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const [sortFieldTable, setSortFieldTable] = useState('');
  const [sortOrderTable, setSortOrderTable] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [toggleFilter, ToggleFilter] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({});
  const [listDocumnetType, setListDocumnetType] = useState([])
  const [listCategory, setListCategory] = useState([])
  const [listTopic, setListTopic] = useState([])
  const [search, setSearch] = useState({})

  const intl = useIntl();

  console.log('filterModal', filter);
  console.log('recordProps', record);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('modalAssetExpenseColumnTicketNo', 'Ticket No.'),
      dataIndex: 'ticketNo',
      key: 'ticketNo',
      sorter: true,
      width: 200,
      ellipsis: true,
      fixed: 'left',
    },
    {
      title: messageLabel('modalAssetExpenseColumnTicketTopic', 'Ticket Topic'),
      dataIndex: 'ticketTopicName',
      key: 'ticketTopicName',
      sorter: true,
      width: 275,
    },

    {
      title: messageLabel('modalAssetExpenseColumnDecumentType', 'Decument Type'),
      dataIndex: 'documentTypeCode',
      key: 'documentTypeCode',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalAssetExpenseColumnDocumentNo', 'Document No.'),
      dataIndex: 'documentNo',
      key: 'documentNo',
      width: 200,
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('modalAssetExpenseColumnDocumentDate', 'Document Date'),
      dataIndex: 'documentDate',
      key: 'documentDate',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalAssetExpenseColumnItemCode', 'Item Code'),
      dataIndex: 'itemCode',
      key: 'itemCode',
      width: 200,
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('modalAssetExpenseColumnItem', 'Item'),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalAssetExpenseColumnItemCategory', 'Item Category'),
      dataIndex: 'itemCategoryCode',
      key: 'itemCategoryCode',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('modalAssetExpenseColumnQty', 'Qty'),
      dataIndex: 'qty',
      key: 'qty',
      width: 150,
      align: 'right',
      sorter: true,
    },
    {
      title: messageLabel('modalAssetExpenseColumnUnit', 'Unit'),
      dataIndex: 'unitName',
      key: 'unitName',
      width: 150,
      sorter: true,
    },
    {
      title: messageLabel('modalAssetExpenseColumnUnitPrice', 'Unit Price'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 150,
      sorter: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(record.unitPrice || 0) + ' THB',
    },
    {
      title: messageLabel('modalAssetExpenseColumnTotal', 'Total'),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      width: 150,
      sorter: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(record.totalPrice || 0) + ' THB',
    },
  ];

  useEffect(() => {
    if(!visible) setSearch({})
  }, [visible])

  useEffect(() => {
    if (visible) {
      const getApi = async () => {
        console.log('filter', filter, record);
        const body = {
          page: pageTable,
          pageSize: sizeTable,
          orderBy: sortFieldTable,
          order:  sortOrderTable,
          assetId: _.get(record, '_refId.asset_id'),
          ticketNo: _.get(search, 'ticketNo') || undefined,
          documentNo: _.get(search, 'documentNo') || undefined,
          itemCode: _.get(search, 'itemCode') || undefined,
          item: _.get(search, 'item') || undefined,
          documentTypeCode: _.get(search, 'documentType') || undefined,
          itemCategoryCode: _.get(search, 'itemCategory') || undefined,
          topic: _.get(search, 'topic') || undefined,
          // "remark": ""
        };
        const response = await getDetailAssetExpenseReport(body);
        setData(_.get(response, 'data.data.docs'));
        setTotal(_.get(response, 'data.data.total'));
        setListDocumnetType(_.get(response, 'data.documnetType'));
        setListCategory(_.get(response, 'data.itemCategory'));
        setListTopic(_.get(response, 'data.topic'))
      };

      getApi();
    } else {
      setData([]);
    }
  }, [visible, trigger]);

  const handleToggleFilter = () => {
    ToggleFilter((event) => !event);
  };

  const handleChangeTable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrderTable('desc');
      } else {
        setSortOrderTable('asc');
      }
    } else {
      setSortOrderTable('');
    }
    setSortFieldTable(field);
    setPageTable(pagination.current);
    setSizeTable(pagination.pageSize);
    setTrigger((event) => !event);
  };

  const handleChangeFilter = async (value, code) => {
    setFilter({ ...filter, [code]: value });
    // setPage(1);
  };

  const handleExport = async () => {
    try {
      const body = {
        page: 1,
        pageSize: total,
        orderBy: sortOrderTable,
        order: sortFieldTable,
        assetId: _.get(record, '_refId.asset_id'),
        ticketNo: _.get(filter, 'ticketNo') || undefined,
        documentNo: _.get(filter, 'documentNo') || undefined,
        itemCode: _.get(filter, 'itemCode') || undefined,
        item: _.get(filter, 'item') || undefined,

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnTicketNo', defaultMessage: 'Ticket No.' }),
            sequence: 1,
            colCode: 'ticketNo',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnTicketTopic', defaultMessage: 'Ticket Topic' }),
            sequence: 2,
            colCode: 'ticketTopicName',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnDecumentType', defaultMessage: 'Decument Type' }),
            sequence: 3,
            colCode: 'documentTypeCode',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnDocumentNo', defaultMessage: 'Document No.' }),
            sequence: 4,
            colCode: 'documentNo',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnDocumentDate', defaultMessage: 'Document Date' }),
            sequence: 5,
            colCode: 'documentDate',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnItemCode', defaultMessage: 'Item Code' }),
            sequence: 6,
            colCode: 'itemCode',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnItem', defaultMessage: 'Item' }),
            sequence: 7,
            colCode: 'itemName',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnItemCategory', defaultMessage: 'Item Category' }),
            sequence: 8,
            colCode: 'itemCategoryCode',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnQty', defaultMessage: 'Qty' }),
            sequence: 9,
            colCode: 'qty',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnUnit', defaultMessage: 'Unit' }),
            sequence: 10,
            colCode: 'unitName',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnUnitPrice', defaultMessage: 'Unit Price' }),
            sequence: 11,
            colCode: 'unitPrice',
          },
          {
            colName: intl.formatMessage({ id: 'modalAssetExpenseColumnTotal', defaultMessage: 'Total' }),
            sequence: 12,
            colCode: 'totalPrice',
          },
        ],
      };
      const response = await exportAssetsDetail(body);
      if (response.status === 200) {
        window.open(response.data.excelExportUrl);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {}
  };

  const handleApply = () => {
    setTrigger((event) => !event)
    setPageTable(1)
    setSearch({
      ticketNo: _.get(filter, 'ticketNo') || undefined,
      documentNo: _.get(filter, 'documentNo') || undefined,
      itemCode: _.get(filter, 'itemCode') || undefined,
      item: _.get(filter, 'item') || undefined,
      documentType: _.get(filter, 'documentType') || undefined,
      itemCategory: _.get(filter, 'itemCategory') || undefined,
      topic: _.get(filter, 'topic') || undefined,
    })
  }

  return (
    <Modal
      title={
        intl.formatMessage({ id: 'modalAssetTitle', defaultMessage: 'Asset' }) + ' · ' + 
        (_.get(record, 'assetNo') || '') + ' ' + 
        (_.get(record, 'asset') || '') + ' ' + 
        (_.get(record, 'serialNo') || '') 
      }
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1122}
      zIndex={1000}
      centered={true}
      bodyStyle={{ padding: 'unset', height: '600px' }}
      className="modalBrandAndModel"
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col
          span={6}
          style={{
            // overflowY: 'auto',
            // height: `580px`,
            // paddingLeft: '24px'
            borderRight: '1px solid #e8e8e8',
          }}
        >
          <div
            className="listItemTextHeader"
            style={{
              padding: '24px 16px 16px 0px',
              marginTop: '-5px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span style={{ width: '50%', marginLeft: '24px' }}>
              <FormattedMessage id="modalListItemDetailText" defaultMessage="Detail" />
            </span>
          </div>

          <div
            className="scroll-sm"
            style={{
              overflowY: 'auto',
              height: `543px`,
              // borderRight: '1px solid #e8e8e8'
            }}
          >
            <div style={{}}>
              <ListDetail data={record} />
            </div>

            <div
              className="listItemTextHeader"
              style={{
                padding: '24px 16px 16px 0px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleToggleFilter}
            >
              <span style={{ width: '50%', marginLeft: '24px' }}>
                <FormattedMessage id="modalAssetFilter" defaultMessage="Filter" />
              </span>

              <span style={{ paddingLeft: '16px' }}>{toggleFilter ? <Icon type="up" /> : <Icon type="down" />}</span>
            </div>

            {toggleFilter && (
              <div style={{}}>
                <ListItemFilter
                  onChange={handleChangeFilter}
                  searchGroup={filter}
                  listDocumnetType={listDocumnetType}
                  listCategory={listCategory}
                  listTopic={listTopic}

                  // onAllowClear={{}}
                  // onClick={handleApplyBrand}
                  // onCancel={handleCancelBrand}
                  // refType={statusList}
                  // tempchange={tempchange}
                  // groupModalType={groupModalType}
                />
                <div style={{ padding: '5px 16px 0px 24px', marginBottom: '20px' }}>
                  <Button_01
                    style={{ margin: '0px', width: '100%' }}
                    key="print"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleApply()}
                  >
                    <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                  </Button_01>
                </div>
              </div>
            )}
          </div>
        </Col>

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <Col span={18}>
          <div
            className="listItemTextHeader"
            style={{
              padding: '14px 24px 15px 24px',
              marginTop: '-5px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              <FormattedMessage id="modalAssetTickets" defaultMessage="Tickets" />
            </span>

            <Button_01 key="close" type="primary" btnsize="wd_df" style={{width: 'auto'}} onClick={() => handleExport()}>
              <FormattedMessage id="btnExport" defaultMessage="Export" />
            </Button_01>
          </div>
          <div
            style={{
              // height: `calc(118vh - 357px)`,
              padding: '16px',
            }}
          >
            <CustomTableComponent
              columns={columns}
              dataSource={data}
              total={total}
              onRow={true}
              role={true}
              onChange={handleChangeTable}
              paginationPage={pageTable}
              // setPaginationPage={setPageTable}
              paginationShow={true}
              // setPaginationSize={setSizeTable}
              paginationSize={sizeTable}
              scroll={{ x: true, y: 400 }}
              // contentAction={contentAction}
              // setFieldSort={handleFieldSort}
              // setOrderSort={handleOrderSort}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalAsset;
