import React, { useState, useCallback, useRef } from 'react';
import { Card, Table, Popover, Icon, Modal, Button, Row, Col, Input, Select } from 'antd';
import Button03 from '../../../components/v2/button_03';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import AddContact from '../../../components/add-contact/index';
import EditContact from '../../../components/edit-contact/index';
import './css/contact.css';
import httpClient from '../../../components/axiosClient';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import Button02 from '../../../components/v2/button_02';
import { PageSettings } from '../../../config/page-settings';

const { Option } = Select;

export default ({
  contactData,
  informationData,
  setTriggerAddContact,
  triggerAddContact,
  setPageCon,
  setSizeCon,
  selectSearchCon,
  onSelect,
  total,
  onChange,
  onChangeTableCon,
  loading,
  pageCon,
  visibleModalContact,
  setVisibleModalContact,
}) => {
  const intl = useIntl();
  const { confirm } = Modal;
  const customerId = _.get(informationData, 'customerId');
  const comCode = localStorage.getItem('comCode');
  console.log('contactData', contactData)

  // const [visiblePopover, setVisiblePopover] = useState(false);
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  // const [visibleModalContact, setVisibleModalContact] = useState(false);
  const [visibleModalContactEdit, setVisibleModalContactEdit] = useState(false);
  const [contactRecord, setContactRecord] = useState({});
  const [formRef, setFormRef] = useState(null);
  const [formRefEdit, setFormRefEdit] = useState(null);

  const ignoreSearchColumn = ['index', 'remark'];

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    // {
    //   title: intl.formatMessage({ id: 'customerContactTableColumnContactCode', defaultMessage: 'Contact Code' }),
    //   dataIndex: 'customerContactCode',
    //   key: 'customerContactCode',
    //   sorter: true,
    // },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnName', defaultMessage: 'Name' }),
      dataIndex: 'customerContactName',
      key: 'customerContactName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnPosition', defaultMessage: 'Position' }),
      dataIndex: 'position',
      key: 'position',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnEmail', defaultMessage: 'Email' }),
      dataIndex: 'customerContactEmail',
      key: 'customerContactEmail',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnPhone', defaultMessage: 'Phone' }),
      dataIndex: 'customerContactPhone',
      key: 'customerContactPhone',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
    // {
    //   title: '',
    //   key: 'options',
    //   dataIndex: 'options',
    //   align: 'center',
    //   width: '5%',
    //   render: (text, record, index) => fncActionTable(record, index),
    // },
  ];

  const selectSearchColumn = columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const handleEdit = (record) => {
    setContactRecord(record);
    setVisibleModalContactEdit(true);
  };

  const handleCreate = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = {
        customerId: customerId,
        customerContactName: values.name,
        customerContactEmail: values.email,
        customerContactPhone: values.phone,
        position: values.position,
        remark: values.remark,
      };

      try {
        const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-contacts`, body);
        if (response.status === 200) {
          formRef.resetFields();
          setVisibleModalContact(false);
          setTriggerAddContact(!triggerAddContact);
          successNotification(response.data.status.message);
        } else {
          errorNotification(response.data.status.message);
        }
      } catch (error) {
        errorNotification(error.response.data.status.message);
      }
    });
  };

  const handleEditSave = () => {
    formRefEdit.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      let contactId = _.get(contactRecord, 'customerContactId');
      const body = {
        customerId: customerId,
        customerContactName: values.name,
        customerContactEmail: values.email,
        customerContactPhone: values.phone,
        position: values.position,
        remark: values.remark,
      };

      try {
        const response = await httpClient.put(`/v3/business-partner/manager/company/${comCode}/customer-contacts/${contactId}`, body);

        if (response.status === 200) {
          formRefEdit.resetFields();
          setVisibleModalContactEdit(false);
          setTriggerAddContact(!triggerAddContact);
          successNotification(response.data.status.message);
        } else {
          formRefEdit.resetFields();
          setVisibleModalContact(false);
          setTriggerAddContact(!triggerAddContact);
        }
      } catch (error) {
        formRefEdit.resetFields();
        setVisibleModalContact(false);
        setTriggerAddContact(!triggerAddContact);
        errorNotification(error.response.data.status.message);
      }
    });
  };

  const handleCancel = () => {
    setVisibleModalContact(false);
    formRef.resetFields();
  };

  const handleCancelEdit = () => {
    setContactRecord({});
    setVisibleModalContactEdit(false);
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const saveFormRefEdit = useCallback((node) => {
    if (node !== null) {
      setFormRefEdit(node);
    }
  }, []);

  const handlerDelete = (customerContactId) => {
    confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerContactDeleteConfirmText', defaultMessage: 'Are you sure to delete contact ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(
            `/v3/business-partner/manager/company/${comCode}/customer-contacts/${customerContactId}`
          );
          if (response.status === 200) {
            setTriggerAddContact(!triggerAddContact);
            successNotification(response.data.status.message);
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {
          return;
        }
      },
      onCancel() {
        return;
      },
    });
  };

  // const handleOnVisiblePopover = (visiblePopover) => {
  //   setVisiblePopover(visiblePopover);
  // };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          // zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG2C2', 'P8PG2C2A3') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            handleEdit(record);
                            handleMouseLeave();
                          }}
                        >
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG2C2', 'P8PG2C2A4') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            handlerDelete(record.customerContactId);
                            handleMouseLeave();
                          }}
                        >
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  // const fncActionTable = (record, index) => {
  //   return (
  //     <div>
  //       <Popover
  //         key={index}
  //         placement="leftTop"
  //         trigger="hover"
  //         visiblePopover={visiblePopover}
  //         onVisibleChange={handleOnVisiblePopover}
  //         zIndex={999}
  //         content={
  //           <div style={{ display: 'grid' }}>
  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <div>
  //                     {checkPermissionAction('P8PG2C2', 'P8PG2C2A3') ? (
  //                       <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
  //                         <FormattedMessage id="btnEdit" defaultMessage="Edit" />
  //                       </Button>
  //                     ) : null}
  //                   </div>
  //                 )}
  //               </PageSettings.Consumer>
  //             </>
  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <div>
  //                     {checkPermissionAction('P8PG2C2', 'P8PG2C2A4') ? (
  //                       <Button style={{ width: 100 }} type="link" ghost onClick={() => handlerDelete(record.customerContactId)}>
  //                         <FormattedMessage id="btnDelete" defaultMessage="Delete" />
  //                       </Button>
  //                     ) : null}
  //                   </div>
  //                 )}
  //               </PageSettings.Consumer>
  //             </>
  //           </div>
  //         }
  //       >
  //         <Icon type="more" />
  //       </Popover>
  //     </div>
  //   );
  // };

  const handlePagination = (page) => {
    setPageCon(page);
  };

  const handleSizeChange = (current, size) => {
    setPageCon(current);
    setSizeCon(size);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          {/* <Card
            title={intl.formatMessage({ id: 'customerContactCardHeader', defaultMessage: 'Contact' })}
            extra={
              checkPermissionAction('P8PG2C2', 'P8PG2C2A2') ? (
                <Button01
                  type="primary"
                  onClick={() => {
                    setVisibleModalContact(true);
                  }}
                >
                  <FormattedMessage id="btnAdd" defaultMessage="Add" />
                </Button01>
              ) : null
            }
          > */}
            {/* <Row className="padding-row">
              <Col span={7}>
                <Input
                  className="input-search action-input border-searth-allcol"
                  onChange={(e) => onChange(e.target.value)}
                  allowClear
                  placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                  autoComplete="off"
                ></Input>
                <Select className="select-search action-select" value={selectSearchCon} onSelect={(value) => onSelect(value)}>
                  <Option value="all">
                    {<FormattedMessage id="reportTimeAttendanceHintSeleteAllColumns" defaultMessage="All columns" />}
                  </Option>
                  {selectSearchColumn.map((item, i) => (
                    <Option key={i} value={item.key}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row> */}
            <Row>
              <div className="containerTables" onMouseLeave={handleMouseLeave}>
                <Table
                  dataSource={contactData}
                  columns={columns}
                  onChange={onChangeTableCon}
                  loading={loading}
                  pagination={{
                    showTotal: showTotal,
                    defaultCurrent: 1,
                    current: pageCon,
                    total: total,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    showSizeChanger: true,
                    locale: { items_per_page: '' },
                    onChange: handlePagination,
                    onShowSizeChange: handleSizeChange,
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        if (!event.target.href) {
                          const { x, y } = ref.current.getBoundingClientRect();
                          setVisiblePopoverTable(true);
                          setOffsetPopover([event.pageX - x, event.pageY - y]);
                          setRecordPopover(record);
                        }
                      },
                    };
                  }}
                />
                {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
                </div>
                {contactData && contactData.length !== 0 ? (
                  <div className="total-items-member" style={{marginTop: '-55px', padding: '0px 24px'}}>
                    <span style={{ fontSize: '13px' }}>
                      <FormattedMessage id="lblTotal" defaultMessage="Total" />
                      {` ${total || 0} `}
                      <FormattedMessage id="lblitems" defaultMessage="items" />
                    </span>
                  </div>
                ) : null}
              
            </Row>
          {/* </Card> */}

          <Modal
            className="content-padding"
            title="Contact"
            visible={visibleModalContact}
            onCancel={handleCancel}
            centered={true}
            onOk={handleCreate}
            footer={[
              <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleCreate()}>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <AddContact ref={saveFormRef} onCreate={() => handleCreate()} intl={intl} />
          </Modal>

          <Modal title="Edit Contact" visible={visibleModalContactEdit} centered={true} onCancel={handleCancelEdit} onOk={handleEditSave}>
            <EditContact ref={saveFormRefEdit} onCreate={() => handleEditSave()} contactRecord={contactRecord} intl={intl} />
          </Modal>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
