import React from 'react'
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input } from 'antd';
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import Button_01 from '../../components/v2/button_01';

const { Option } = Select

const ListItemFilter = (props) => {
  const { onChange, searchGroup, onClick } = props
  const intl = useIntl()

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '519px' }}>
        <div style={{ padding: '16px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListCategoryName" defaultMessage="Category Name" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'categoryName')}
                value={_.get(searchGroup, 'categoryName')}
                placeholder={intl.formatMessage({ id: 'modalListCategoryNamePH', defaultMessage: 'Category Name' })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListCategoryStatus" defaultMessage="Status" />
            </div>
            <div>
              <Select
                showSearch
                allowClear={true}
                onChange={(value) => onChange(value, 'status')}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(searchGroup, 'status')}
                placeholder={intl.formatMessage({ id: 'modalListCategoryStatusPH', defaultMessage: 'Select Status' })}
                style={{ width: '100%' }}
              >
                <Option value="active">
                  <FormattedMessage id="modalListCategoryStatusActive" defaultMessage="Active" />
                </Option>
                <Option value="inactive">
                  <FormattedMessage id="modalListCategoryStatusInactive" defaultMessage="Inactive" />
                </Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px' }}>
        <Button_01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={onClick}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button_01>
      </div>
    </div>

  )
}

export default ListItemFilter
