import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import * as moment from 'moment';
import '../css/shift.css';
import '../css/shift-list.css';
import {
  Card,
  Popover,
  Icon,
  Input,
  Select,
  Button,
  Row,
  Col,
  Avatar,
  Modal,
} from 'antd';
import Table from '../../../components/v2/table';
import { ShiftSettings } from '../config/shift-type-settings';
import { PageSettings } from '../../../config/page-settings';
import Button_01 from '../../../components/v2/button_01';
import ModalForm from '../shift-type/shift-type-modal';
import EditShiftForm from '../shift-type/shift-type-edit';
import httpClient from '../../../components/axiosClient';
// import ShiftTypeForm from './shift-type-form';
import ShiftTypeFormAdd from './shift-type-form-add';
import ShiftTypeFormEdit from './shift-type-form-edit';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';

const { Option } = Select;

export default (props) => {
  const intl = useIntl();

  const { state, fnc } = useContext(ShiftSettings);

  const comId = localStorage.getItem('comId');


  const [timeArr, setTimeArr] = useState([{}]);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [visiblePopoverEdit, setVisiblePopoverEdit] = useState(false);
  const [record, setRecord] = useState();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();
  const refSearch = useRef();
  const refSearchSelect = useRef();

  const columns = [
    {
      title: '#',
      select: 'Index',
      align: 'center',
      width: '60px',
      className: 'onTop',
      key: 'index',
      fixed: 'left',
      render: (text, record, index) =>
        index + 1 + (state.page - 1) * state.pageSize,
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeColIcon',
        defaultMessage: 'Icon',
      }),
      dataIndex: 'icon_code',
      key: 'icon_code',
      width: '81px',
      align: 'center',
      className: 'onTop',
      fixed: 'left',
      render: (text, record) => (
        <img src={record.icon_code} style={{ width: '16px' }} />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeColColor',
        defaultMessage: 'Color',
      }),
      dataIndex: 'color',
      key: 'color',
      width: '81px',
      align: 'center',
      className: 'onTop',
      fixed: 'left',
      render: (text, record) => (
        <Avatar
          style={{
            backgroundColor: `${record.color}`,
            height: '24px',
            width: '24px',
          }}
          shape='square'
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeColName',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      className: 'onTop',
      fixed: 'left',
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeColType',
        defaultMessage: 'Type',
      }),
      dataIndex: 'shift_types',
      key: 'shift_types',
      width: '194px',
      className: 'onTop',
      sorter: true,
      render: (text, record) => (
        <span>
          {record.shift_types.length > 0
            ? record.shift_types[0].shift_types_name
            : '-'}
        </span>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeColTimeSpan',
        defaultMessage: 'Time Span',
      }),
      dataIndex: 'shift_time_periods',
      key: 'shift_time_periods',
      width: '350px',
      className: 'onTop',
      render: (text, record) =>
        record &&
        record.shift_time_periods.map((item, i) => (
          <div key={`time-period-${item.shift_time_period_id}`}>
            {item.start_time} - {item.end_time}
          </div>
        )),
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeLblTotalWorkingHour',
        defaultMessage: 'Total Working Hours',
      }),
      dataIndex: 'work_minute',
      key: 'work_minute',
      width: '200px',
      align: 'center',
      className: 'onTop',
      render: (text, record) => {
        const hour = _.floor(_.get(record, 'work_minute') / 60)
        const minute = _.get(record, 'work_minute') % 60
        // const result = hour + minute
        const result = `${hour}:${minute}`
        // const splitText = record && result !== 0 ? result.toString().split(".") : []
        // const textSplit = _.get(splitText, '[0]') + ':' + _.get(splitText, '[1]')
        return (
          <span>{result !== 0 ? result : result}</span>
        )
      }
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeLblLateTimeShow',
        defaultMessage: 'Late Time',
      }),
      dataIndex: 'late_minute',
      key: 'late_minute',
      width: '200px',
      align: 'center',
      className: 'onTop',
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeLblTeam',
        defaultMessage: 'Team',
      }),
      dataIndex: 'team_shift_masters',
      key: 'team_shift_masters',
      width: '350px',
      className: 'onTop',
      render: (text, record) => {
        const string = _.get(record, 'team_shift_masters').map(item => { return item.org_name })
        const toStringData = _.toString(string)
        return (
          <span>{toStringData}</span>
        )
      }
    },
    {
      title: intl.formatMessage({
        id: 'settingShiftTypeColDescription',
        defaultMessage: 'Description',
      }),
      dataIndex: 'description',
      key: 'description',
      width: '422px',
      className: 'onTop',
    },
    // {
    //   title: '',
    //   key: 'options',
    //   dataIndex: 'options',
    //   align: 'center',
    //   className: 'onTop',
    //   width: '48px',
    //   render: (text, record, index) => (
    //     <PageSettings.Consumer>
    //       {({ checkPermissionAction }) =>
    //         checkPermissionAction('P6S2PG1C1', 'P6S2PG1C1A3') ||
    //           checkPermissionAction('P6S2PG1C1', 'P6S2PG1C1A4') ? (
    //           <Popover
    //             key={index}
    //             content={actionPopover(record)}
    //             placement="leftTop"
    //             visiblePopover={visiblePopover}
    //             onVisibleChange={handleOnVisiblePopover}
    //             zIndex={999}
    //           >
    //             <Icon type="more" />
    //           </Popover>
    //         ) : null
    //       }
    //     </PageSettings.Consumer>
    //   ),
    // },
  ];

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          // key={index}
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          // visiblePopover={visiblePopover}
          // onVisibleChange={handleOnVisiblePopover}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    <div>
                      {
                        checkPermissionAction('P6S2PG1C1', 'P6S2PG1C1A3') ? (
                          <Button
                            style={{ width: 100 }}
                            type="link"
                            ghost
                            onClick={() => handleEditShiftTeyps(record)}
                          >
                            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                          </Button>
                        ) : null
                      }
                    </div>}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    <div>
                      {
                        checkPermissionAction('P6S2PG1C1', 'P6S2PG1C1A4') ? (
                          <Button
                            style={{ width: 100 }}
                            type="link"
                            ghost
                            onClick={() => confirm(record)}
                          >
                            <FormattedMessage id="orgTabMemberBtnDelete" defaultMessage="Delete" />
                          </Button>
                        ) : null
                      }
                    </div>}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    )
  }


  const onchange = (pagination, filters, sorter) => {
    fnc.setPage(pagination.current);
    fnc.setPageSize(pagination.pageSize);
    fnc.setSortColumn(sorter.field);
    fnc.setSortOrder(sorter.order ? sorter.order : false);
  };

  const showTotal = (total) => {
    return (
      <span>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const hidePopover = (e) => {
    e.preventDefault();
    setVisiblePopover(false);
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const handleSearch = _.debounce(() => {
    // console.log('-----refSearchSelect.current.rcSelect.state.value-----');
    // console.log(refSearchSelect.current.rcSelect.state.value);
    let searchDoc = {};
    // if (
    //   refSearch.current.state.value &&
    //   refSearchSelect.current.rcSelect.state.value[0] === 'name'
    // ) {
    //   searchDoc[refSearchSelect.current.rcSelect.state.value[0]] =
    //     refSearch.current.state.value;
    // } else if (refSearch.current.state.value) {
    //   searchDoc.name = refSearch.current.state.value;
    //   searchDoc.description = refSearch.current.state.value;
    // }

    const searchValue = refSearch.current.state.value;
    const searchColumn = _.get(refSearchSelect.current.rcSelect.state.value, '[0]');
    if (!searchColumn || searchColumn === 'allcolumns') {
      searchDoc = {
        all: searchValue
      };
    }
    else if (searchColumn === 'name') {
      searchDoc = {
        name: searchValue,
        description: searchValue
      };
    }
    else if (searchColumn === 'type') {
      searchDoc = {
        type: searchValue
      };
    }

    fnc.setWhere(searchDoc);
  }, 500);

  const handleEditShiftTeyps = (record) => {
    const formDataDoc = {
      name: {
        value: record.name,
      },
      shiftType: {
        value: _.get(record, 'shift_types[0].shift_types_id')
          ? _.get(record, 'shift_types[0].shift_types_id')
          : 0,
      },
      description: {
        value: record.description,
      },
    };



    const timeArrDoc = [];
    _.forEach(record.shift_time_periods, (o, i) => {
      formDataDoc[`startTime-${i}`] = o.start_time;
      formDataDoc[`endTime-${i}`] = o.end_time;

      timeArrDoc.push({
        startTime: o.start_time,
        endTime: o.end_time,
      });
    });

    if (timeArrDoc.length === 0) {
      formDataDoc[`startTime-0`] = '';
      formDataDoc[`endTime-0`] = '';

      timeArrDoc.push({
        startTime: null,
        endTime: null,
      });
    }


    setRecord(record);
    fnc.setTimeArr(timeArrDoc);
    fnc.setFormData(formDataDoc);
    // fnc.setVisible(true);
    fnc.setVisibleEdit(true);
  };

  const confirm = (service) => {
    Modal.confirm({
      className: 'services-modal-confirm',
      icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
      title: intl.formatMessage({
        id: 'lblDelete',
        defaultMessage: 'Are you sure you want to delete ?',
      }),
      okText: intl.formatMessage({
        id: 'settingHolidaysModalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'btnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      onOk() {
        fnc.handleDelete(service.shift_id);
      },
      onCancel() { },
    });
  };

  const actionPopover = (service) => {
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div className="text-left" onClick={hidePopover}>
            {checkPermissionAction('P6S2PG1C1', 'P6S2PG1C1A3') ? (
              <div style={{ paddingTop: 3 }}>
                <Button
                  className="styledPopDelete"
                  btnsize={'wd_df'}
                  type="link"
                  ghost
                  onClick={() => handleEditShiftTeyps(service)}
                >
                  <p className="action-edit-p">
                    <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                  </p>
                </Button>
              </div>
            ) : null}

            {checkPermissionAction('P6S2PG1C1', 'P6S2PG1C1A4') ? (
              <div>
                <Button
                  className="styledPopDelete"
                  btnsize={'wd_df'}
                  type="link"
                  ghost
                  onClick={() => confirm(service)}
                >
                  <p className="action-delete-p">
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </p>
                </Button>
              </div>
            ) : null}
          </div>
        )}
      </PageSettings.Consumer>
    );
  };

  const handleOpenModalAddShiftType = () => {
    setRecord();
    fnc.setVisibleAdd(true);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };


  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <ShiftSettings.Consumer>
          {({ state }) => (
            <div>
              <Card
                className='shift-card'
                title={
                  <FormattedMessage
                    id="settingShiftTypeTitle"
                    defaultMessage="Shift Type"
                  />
                }
              >
                <div>
                  <Row>
                    <Col span={12}>
                      <Input
                        ref={refSearch}
                        className="shift-input action-input"
                        placeholder={intl.formatMessage({
                          id: 'hintSearch',
                          defaultMessage: 'Search',
                        })}
                        id="search-input"
                        autoComplete="off"
                        // value={state.fieldChange}
                        onChange={handleSearch}
                        prefix={
                          <Icon
                            type="search"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                      />
                      <Select
                        className='action-select'
                        ref={refSearchSelect}
                        showSearch
                        style={{ width: 150 }}
                        placeholder={intl.formatMessage({
                          id: 'settingShiftTypeSelectAllCol',
                          defaultMessage: 'All columns',
                        })}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={handleSearch}
                      >
                        <Option key="all" value="allcolumns">
                          {
                            <FormattedMessage
                              id="settingShiftTypeSelectAllCol"
                              defaultMessage="All columns"
                            />
                          }
                        </Option>
                        <Option key="name" value="name">
                          {
                            <FormattedMessage
                              id="settingShiftTypeColName"
                              defaultMessage="Name"
                            />
                          }
                        </Option>
                        <Option key="shift_types" value="type">
                          {
                            <FormattedMessage
                              id="settingShiftTypeColType"
                              defaultMessage="Type"
                            />
                          }
                        </Option>
                      </Select>
                    </Col>

                    <Col span={12}>
                      {checkPermissionAction('P6S2PG1C1', 'P6S2PG1C1A2') ? (
                        <Button_01
                          id="handle-add-shift-type"
                          className="shift-button"
                          key="add"
                          type="primary"
                          btnsize="wd_df"
                          onClick={handleOpenModalAddShiftType}
                        >
                          <FormattedMessage
                            id="settingShiftTypeBtnShiftType"
                            defaultMessage="Add New"
                          />
                        </Button_01>
                      ) : null}

                      <ShiftTypeFormAdd record={record} setRecord={setRecord} />

                      <ShiftTypeFormEdit
                        record={record}
                        setRecord={setRecord}
                      />
                    </Col>
                  </Row>
                  <div onMouseLeave={handleMouseLeave}>
                    <Table
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            if (!event.target.href) {
                              const { x, y } = ref.current.getBoundingClientRect();
                              setVisiblePopoverTable(true);
                              setOffsetPopover([event.pageX - x, event.pageY - y]);
                              setRecordPopover(record);
                            }
                          },
                        };
                      }}
                      rowKey={(record) => record.shift_id}
                      columns={columns}
                      dataSource={state.dataShiftType}
                      loading={state.loading}
                      onChange={onchange}
                      scroll={{ x: 1000, y: 'calc(100vh - 370px)' }}
                      pagination={{
                        total: state.total,
                        showTotal: showTotal,
                        defaultCurrent: 1,
                        current: state.page,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        showSizeChanger: true,
                        locale: { items_per_page: '' },
                      }}
                    />
                    {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
                    {state.dataShiftType.length || [] > 0 ? (
                      <div className="total-items-member">
                        <span>
                          {
                            <FormattedMessage
                              id="lblTotal"
                              defaultMessage="Total"
                            />
                          }{' '}
                          {`${state.total}`}{' '}
                          {
                            <FormattedMessage
                              id="lblitems"
                              defaultMessage="items"
                            />
                          }
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Card>
            </div>
          )}
        </ShiftSettings.Consumer>
      )}
    </PageSettings.Consumer>
  );
};
