import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings';
import { Card, Icon, Modal, Button, Popover, Divider, Badge, Table } from 'antd';
import getEquipmentTools from '../../../../controllers/equipment-tools/get-equipment-tools';
import pathUrt from '../../../../function/pathUrl';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import './css/equipment-tools.css';
import _ from 'lodash';


const EquipmentTools = (props) => {
  const intl = useIntl();
  let checkPathUrl = pathUrt('/user-management/my-profile');
  let userId = props.userId;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({ id: 'UserManagementEquipmentItem', defaultMessage: 'Item' }),
      dataIndex: 'items',
      key: 'items',
    },
    {
      title: intl.formatMessage({ id: 'UserManagementEquipmentBorrowingDate', defaultMessage: 'Borrowing Date' }),
      dataIndex: 'borrowDate',
      key: 'borrowDate',
    },
    {
      title: intl.formatMessage({ id: 'UserManagementEquipmentApproveby', defaultMessage: 'Approve by' }),
      dataIndex: 'appreoveName',
      key: 'appreoveName',
    },
  ];

  const [equipmentToolsData, setEquipmentToolsData] = useState([]);

  useEffect(() => {
    const getEquipmentToolsApi = async () => {
      if (userId) {
        const response = await getEquipmentTools(userId);
        setEquipmentToolsData(_.get(response.data.member, 'equipment'));
      }
    }
    getEquipmentToolsApi();
  }, []);

  return (
    <div className='equipment-tools'>
      <Card
        className='equipment-tools-card'
        title={intl.formatMessage({ id: 'UserManagementEquipmentTitle', defaultMessage: 'Equipment & Tools' })}
      >
        <Table
          rowKey={record => record.items}
          dataSource={equipmentToolsData}
          columns={columns}
          pagination={false}
        />
      </Card>
    </div>
  )
}

export default EquipmentTools;
