import React, { useContext } from 'react'
import { TaskDetailContext } from '../context'
import _ from 'lodash'
import { Row, Col, Form } from 'antd'
import { useIntl } from 'react-intl'
import LabeRequireForm from '../../label-required-form'
import moment from 'moment'

const ModalTaskDetailInformation = () => {
  const { state } = useContext(TaskDetailContext)
  const { detailData } = state
  const intl = useIntl()
  const information = _.get(detailData, 'information')
  const customFieldData = _.get(detailData, 'customeFieldData')

  const CustomToField = () => {
    return _.size(customFieldData)
      ? customFieldData.map((o) => (
        <Col span={8} >
          <Form.Item
            key={o.titleCode}
            id={o.titleCode}
            label={<LabeRequireForm
              text={intl.formatMessage({ id: o.titleCode, defaultMessage: o.defaultTitle })}
              req={false}
            />}
          >
            {_.get(information, `information.${o.entityToField}`) || '-'}
          </Form.Item>
        </Col>
      ))
      : null;
  };

  return (
    <Form style={{ padding: '24px' }} layout="vertical" className="taskDetailForm">
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextTaskNo', defaultMessage: 'Task No' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.taskNo', '-')}</span>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextTaskName', defaultMessage: 'Task Name' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.taskName', '-')}</span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailTeamText', defaultMessage: 'Team' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.orgName', '-')}</span>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextTaskType', defaultMessage: 'Task Type' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.taskTypeName', '-')}</span>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextPriority', defaultMessage: 'Priority' })}
                req={false}
              />
            }
          >
            <img src={_.get(information, 'information.iconPriority')} style={{ width: '7px', height: '7px' }}></img>{' '}
            <span className='taskDetailFormValue'>{_.get(information, 'information.priorityName', '-')}</span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextCustomer', defaultMessage: 'Customer' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.customer', '-')}</span>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextContact', defaultMessage: 'Contact' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.contact[0].contact_name', '-')}</span>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextPhone', defaultMessage: 'Phone' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.contact[0].contact_phone', '-')}</span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextStartDate', defaultMessage: 'Start Date' })}
                req={false}
              />
            }
          >
            {_.get(information, 'information.startDate') !== '' &&
              _.get(information, 'information.durationStart') !== '' ?
              <span className='taskDetailFormValue'>{`${moment(_.get(information, 'information.startDate'), 'YYYY-MM-DD').format('DD/MM/YYYY')} 
                 ${_.get(information, 'information.durationStart')}`}</span>
              : ' - '
            }
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextDueDate', defaultMessage: 'Due Date' })}
                req={false}
              />
            }
          >
            {_.get(information, 'information.planFinish') !== '' &&
              _.get(information, 'information.durationFinish') !== '' ?
              <span className='taskDetailFormValue'>{`${moment(_.get(information, 'information.dueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY')}
              ${_.get(information, 'information.durationFinish')}`}</span>
              : ' - '
            }
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'taskDetailInformationTextReference', defaultMessage: 'Reference' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.reference', '-')}</span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <CustomToField />
      </Row>
      <Row gutter={_.size(customFieldData) > 0 ? [24] : [24, 24]}>
        <Col span={24}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: `taskDetailInformationTextRemark`, defaultMessage: 'Remark' })}
                req={false}
              />
            }
          >
            <span className='taskDetailFormValue'>{_.get(information, 'information.remark', '-') !== "" ? _.get(information, 'information.remark', '-') : "-"}</span>
          </Form.Item>
        </Col>
      </Row>
    </Form >
  )
}

export default ModalTaskDetailInformation
