import React, { useContext } from 'react';
import { Select, Modal } from 'antd'
import '../css/team.css'
import { TaskDetail } from '../../task-detail-context'
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { changeTeam } from '../../../../../../controllers/task/task-detail';
import { successNotification, errorNotification } from '../../../../../v2/notification';
import { FormattedMessage } from 'react-intl';

const { Option } = Select
const { confirm } = Modal

export default () => {

  const { stateTask, setStateTask } = useContext(TaskDetail);
  const intl = useIntl();

  const handleChageTeam = (value) => {
    confirm({
      className: "tranfer-task-modal-confirm",
      title: intl.formatMessage({ id: `monitorTabDetailTranferTaskTitleModalConfirm`, defaultMessage: 'Are you sure to change teams ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      centered: true,
      icon: null,
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      async onOk() {
        const response = await changeTeam(_.get(stateTask, 'taskId'), value)
        if (response.status === 200) {
          successNotification(response.data.message)
          setStateTask.setTriggerInformation(current => !current)
          setStateTask.setTriggerApi(current => !current)
        } else {
          errorNotification(response.data.message)
        }
      },
      onCancel() { },
    });
  }


  return (
    <div>
      <div style={{ fontSize: '12px' }}>
        <FormattedMessage id="taskDetailTeamText" defaultMessage="Team" />
      </div>
      <Select
        // className='task-detail-team-select'
        style={{ width: '100%' }}
        value={_.get(stateTask, 'selectTeam')}
        onChange={handleChageTeam}
        // disabled={stateTask.statusView ? false : true}
        // showArrow={stateTask.statusView ? true : false}
      >
        {
          stateTask.teamData && stateTask.teamData.map(item =>
            <Option key={item.orgId} value={item.orgId}>
              {item.orgName}
            </Option>
          )
        }
      </Select>
    </div>
  )
}
