import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Form,
  Icon,
  Input,
  Checkbox,
  Card,
  Typography,
  Col,
  Row,
  notification,
  Button,
} from 'antd';
import { Link } from 'react-router-dom';
import AppTitle from '../../../components/title';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import ChangPassword from './changePassword/index';
import Api from '../../../components/httpClient';
import { notificationWithIcon } from '../../../components/notification';
import cssStyle from './css/cssMageAccount.css';
import Language from '../../../includes/language';

let client = Api();
const qs = require('query-string');
var jwt = require('jsonwebtoken');

const { Text } = Typography;

const comId = localStorage.getItem('comId');
const pageCode = localStorage.getItem('pageCode');
const langValue = localStorage.getItem('langValue');
const userName = localStorage.getItem('username');
const memComId = localStorage.getItem("memComId");
// const token = localStorage.getItem('token');

const ValidatedFields = (props) => {
  const intl = useIntl();
  const {
    getFieldDecorator,
    validateFields,
    getFieldsValue,
    getFieldValue,
  } = props.form;
  const [edit, setEdit] = useState(false);
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [visible, setVisible] = useState(true);
  // ภาษา
  const [switchLang, setSwicthLang] = useState({});
  const [labelShow, setLabelShow] = useState({});
  let user_id = props.userId;
  const obj = getFieldsValue();

  const [currentPasswordStatus, setCurrentPasswordStatus] = useState(false);

  useEffect(() => {
    Lang();
  }, []);

  // ส่วนของการเรียกใช้ภาษา
  const Lang = async () => {
    const res = await Language({
      companyId: comId,
      lang: langValue,
      pageCode: pageCode,
    });
    setSwicthLang(res);
    setLabelShow(res);
  };

  const successNotification = (type) => {
    notification[type]({
      message: labelShow.notiSuccessChangedPassword
        ? labelShow.notiSuccessChangedPassword
        : 'Your Password has been changed.',
    });
  };

  const handleSubmit = (values) => {

    validateFields((err, values) => {
      if (err) {

        return;
      }
      // if(!currentPasswordStatus) {
      //   notificationWithIcon("warning", "Current Password fail.");
      // }
      else {
        // values.currentPassword != values.newPassword ? getToken(values) : notificationWithIcon("warning", "Password Duplicated.");
        getToken(values);

      }
    });
  };

  const getToken = async (data) => {
    try {
      client.defaults.baseURL = `${process.env.REACT_APP_KEYCLOAK_URL}`;

      let body = {
        'client_id': 'admin-cli',
        'grant_type': 'password',
        'username': 'admin',
        'password': '#Admin123',
      };

      const res = await client.post('/auth/realms/master/protocol/openid-connect/token', qs.stringify(body));
      let token = res.data.access_token;

      try {
        let body = {
          'type': 'password',
          'value': data.newPassword,
        };

        client.defaults.headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        const res = await client.put(`/auth/admin/realms/master/users/${user_id}/reset-password`, body);

        successNotification('success');
        setEdit(!edit);
      } catch (error) {

      }
    } catch (error) {

    }
  };

  const handleEdit = () => {
    setEdit(!edit);
    setVisible(false);
  };

  const validateCurrentPassword = async (rule, value, callback) => {
    const data = {
      client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
      grant_type: 'password',
      client_secret: `${process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET}`,
      // username: localStorage.getItem('username'),
      username: userName,
      password: value,
    };



    const axiosConfig = {
      Header: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    try {
      client.defaults.baseURL = `${process.env.REACT_APP_KEYCLOAK_URL}`;
      client.defaults.headers = {
        Authorization: '',
        'Content-Type': 'application/x-www-form-urlencoded',
      };
      const res = await client.post(
        '/auth/realms/master/protocol/openid-connect/token',
        qs.stringify(data),
        axiosConfig,
      );
    } catch (err) {
      if (err.status !== value) {
        callback(
          <span>
            {intl.formatMessage({ id: 'userMgntUsersChangePasswordValidatePasswordFail', defaultMessage: 'Password fail.' })}
          </span>
        );
      }
      // callback(err);
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && value === getFieldValue('currentPassword')) {
      callback(
        <span>
          {intl.formatMessage({ id: 'userMgntUsersChangePasswordValidatePasswordDuplicated', defaultMessage: 'Password Duplicated.' })}
        </span>
      );
    }
    callback();
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('newPassword')) {
      callback(
        <span>
          {intl.formatMessage({ id: 'userMgntUsersChangePasswordValidateConfirmPasswordInconsistent', defaultMessage: 'Two passwords that you enter is inconsistent.' })}
        </span>
      );
    } else {
      callback();
    }
  };

  const validateChecked = (rule, value, callback) => {
    try {
      if (!value === true) {
        throw new Error(intl.formatMessage({ id: 'userMgntUsersChangePasswordValidateNotRobot', defaultMessage: 'Please select I`m not robot.' }));
      } else {
        callback();
      }
    } catch (err) {
      callback(err);
    }
  };

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  return (
    <Card
      title={<h1 className='formate-Title'><FormattedMessage id="userMgntUsersTitleChangePassword" defaultMessage="Change Password" /></h1>}
      // size="small"
      // style={{ width: '100%'}}
      extra={
        edit ? (
          <Button01 style={{ width: '96px', margin: '0px' }} type="primary" fontsize="sm" onClick={handleEdit}>
            <FormattedMessage id="userMgntUsersChangePasswordBtnEdit" defaultMessage="Edit" />
          </Button01>
        ) : (
          <Button01 style={{ width: '96px', margin: '0px' }} type="primary" fontsize="sm" onClick={handleEdit}>
            <FormattedMessage id="userMgntUsersChangePasswordBtnEdit" defaultMessage="Edit" />
          </Button01>
        )
      }
    >
      {!edit ? (
        // <Text strong>
        //   {labelShow.textChangePassword
        //     ? labelShow.textChangePassword
        //     : "Choose a strong password and don't reuse it other accounts."}
        // </Text>
        <p style={{ marginLeft: '10px' }}>
          <FormattedMessage id="userMgntUsersTxtChangePassword" defaultMessage="Choose a strong password and don't reuse it other accounts." />
        </p>
      ) : (
        <div>
          <p style={{ marginLeft: '10px' }}>
            <FormattedMessage id="userMgntUsersTxtChangePassword" defaultMessage="Choose a strong password and don't reuse it other accounts." />
          </p>
          <Form>
            <Form.Item
              label={<FormattedMessage id="userMgntUsersChangePasswordLblCurrentPassword" defaultMessage="Current Password" />}
              hasFeedback
            >
              {getFieldDecorator('currentPassword', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'userMgntUsersChangePasswordValidateCurrentPassword', defaultMessage: 'Please enter your current password.' }),
                  },
                  {
                    validator: validateCurrentPassword,
                  },
                ],
              })(
                <Input.Password
                  placeholder={intl.formatMessage({ id: 'userMgntUsersChangePasswordHintCurrentPassword', defaultMessage: 'Enter current password' })}
                />,
              )}
            </Form.Item>

            <Form.Item>
              <ChangPassword labelShow={labelShow} />
            </Form.Item>

            <Form.Item
              label={
                <FormattedMessage id="userMgntUsersChangePasswordLblNewPassword" defaultMessage="New Password" />
              }
              hasFeedback
            >
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    min: 6,
                    message: intl.formatMessage({ id: 'userMgntUsersChangePasswordValidatePasswordMustNewPassword', defaultMessage: 'The password must be at least 6 leght.' }),
                  },
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'userMgntUsersChangePasswordValidateNewPassword', defaultMessage: 'Please enter your new password.' }),
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              })(
                <Input.Password
                  placeholder={intl.formatMessage({ id: 'userMgntUsersChangePasswordHintNewPassword', defaultMessage: 'Enter new password' })}
                />,
              )}
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="userMgntUsersChangePasswordLblConfirmPassword" defaultMessage="Confirm Password" />}
              hasFeedback
            >
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'userMgntUsersChangePasswordValidateConfirmPassword', defaultMessage: 'Please enter your confirm password.' }),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(
                <Input.Password
                  placeholder={intl.formatMessage({ id: 'userMgntUsersChangePasswordHintConfirmPassword', defaultMessage: 'Enter confirm password' })}
                  onBlur={handleConfirmBlur}
                />,
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('agreement', {
                valuePropName: "checked",
                rules: [
                  {
                    validator: validateChecked,
                  }
                ],
              })(
                <Checkbox>
                  <FormattedMessage id="userMgntUsersChangePasswordHintImNotRobot" defaultMessage="I'm not robot." />
                </Checkbox>,
              )}
            </Form.Item>

            <Row>
              <Col span={12} className="button">
                <Button
                  className='button-adap button-save'
                  type="primary"
                  htmlType="submit"
                  onClick={handleSubmit}
                >
                  <FormattedMessage id="userMgntUsersChangePasswordBtnSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col span={12} className="button">
                <Button02 style={{ margin: '0px 0px 0px 10px', width: '125px' }}
                  onClick={handleEdit}
                >
                  <FormattedMessage id="userMgntUsersChangePasswordBtnCancel" defaultMessage="Cancel" />
                </Button02>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Card>
  );
};
const changePassword = Form.create()(ValidatedFields);
export default changePassword;
