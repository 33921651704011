import React, { useState, useEffect, useRef, useMemo, useContext, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Select, Icon, Button, Form, Divider, DatePicker, ConfigProvider } from 'antd';
import { SaleOrderContext } from './sale-order-context';
import { useAppContext } from '../../includes/indexProvider';
import GetCustomerApi from '../../controllers/getCustomer/get-customer-api';
import GetAddressBook from '../../controllers/getCustomer/get-address-book';
import _ from 'lodash';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import { Data } from '@react-google-maps/api';
import styles from './css/index.css';
import AddCustomer from '../add-customer/index';
import httpClient from '../axiosClient';
import { successNotification, errorNotification, warningNotification } from '../v2/notification';
import AddAddressBookV2 from '../modal-create-adress-map-v2/index';
import AddBusinessPartner from '../../components/add-business-partner/index';

const { Option } = Select;

const today = new Date();
const dateFormat = 'ddd, MMM DD YYYY';

const OrderSaleAddress = (props) => {
  const {
    warehouseDetailfilter,
    setWarehouseDetailfilter,
    customerDetailfilter,
    setCustomerDetailfilter,
    form,
    setCustomerDataCreate,
    customerDataCreate,
  } = props;
  const intl = useIntl();
  const app = useAppContext();

  const langValue = app.state.langValue;
  moment.locale(langValue);
  const dateNow = moment().format(dateFormat);
  const { mainState, setMainState } = useContext(SaleOrderContext);
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const [loading, setLoading] = useState(false);
  const [allCustomer, setAllCustomer] = useState(1000);
  const [customer, setCustomer] = useState();

  //--------------- Customer ---------------
  const [visibleCustomer, setVisibleCustomer] = useState();
  const imageRef = useRef();
  const [imageFile, setImageFile] = useState();
  const [imageCustomer, setImageCustomer] = useState();
  const [cusDataBodyApi, setCusDataBodyApi] = useState({});
  const [dataPrivacy, setDataPrivacy] = useState();
  const [formCustomer, setFormCustomer] = useState(null);
  const comCode = localStorage.getItem('comCode');
  const [triggerCustomer, setTriggerCustomer] = useState(false);
  //-------------- Map Address ----------------
  const [visibleAddAdress, setVisibleAddAdress] = useState();
  const [latlngTo, setLatlngTo] = useState({});
  const [valueAddress, setValueAddress] = useState('');
  const [formRef, setFormRef] = useState(null);
  const [locale, setLocale] = useState(enUS);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    changeLocale();
  }, []);

  useEffect(() => {
    const getCustomerAll = async () => {
      let dataCustomer = await GetCustomerApi(allCustomer);
      setMainState.setCustomerData(dataCustomer.customers);
      setAllCustomer(dataCustomer.allCustomers);
      setCustomer(dataCustomer);
      setLoading(false);
    };
    getCustomerAll();
  }, [loading, triggerCustomer]);

  const customerID = getFieldValue(`customer`);
  const customerAll = customer && customer.customers;
  const resourceSelect = customerAll && customerID && customerAll.filter((item) => customerID.includes(item.customerId));
  const dataSelect = resourceSelect && resourceSelect !== undefined ? resourceSelect[0] : undefined;

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    // if (!changeLang) {
    //   moment.locale('en');
    // } else {
    //   moment.locale('th-th');
    // }
  };

  const customerList =
    customer && customer !== undefined
      ? customer.customers.map((item) => {
          return (
            <Option className="select-option" value={item.customerId}>
              {item.customerName}
            </Option>
          );
        })
      : null;

  // useEffect(() => {
  //   const customerId = getFieldValue(`customer`);

  //   if (customerId !== undefined) {
  //     handleSelectCustomer(customerId)
  //   }
  // }, [getFieldValue(`customer`)])

  const handleSelectCustomer = async (value) => {
    const addressID = getFieldValue(`address`);
    if (value) {
      const response = await GetAddressBook(value);
      setMainState.setAddressBook(response.data.toLocations);
    }

    if (addressID && customerDetailfilter !== undefined) {
      setFieldsValue({ ['address']: undefined });
      setFieldsValue({ ['shippingDate']: undefined });
      setCustomerDetailfilter({});
    }
  };

  const handleSelectAddressBook = (value) => {
    const resourceSelect = mainState.addressBook && mainState.addressBook.filter((item) => value.includes(item.customerAddressBookId));
    const dataSelect = resourceSelect && resourceSelect !== undefined ? resourceSelect[0] : undefined;
    setCustomerDetailfilter(dataSelect);
  };

  const handleFilterWareHouse = () => {
    const wareHouseID = getFieldValue(`wareHouse`);
    const data = mainState.warehouseData && mainState.warehouseData.filter((warehouse) => warehouse.wareHouseId === wareHouseID);
    setWarehouseDetailfilter(...data);
  };

  const handleCreateCustomer = () => {
    formCustomer.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const mapPrivacyTeamList =
        dataPrivacy && dataPrivacy.team !== undefined
          ? dataPrivacy.team.map((item) => {
              return item.toString();
            })
          : undefined;

      try {
        const body = {
          comCode: comCode,
          customerName: values.cusName,
          customerShortName: values.cusCode,
          businessTypeId: values.busType,
          customerTypeId: values.cusType,
          address: cusDataBodyApi.address,
          lat: cusDataBodyApi.lat,
          lng: cusDataBodyApi.lng,
          profileImg: undefined,
          phone: undefined,
          contacts: [],
          locationName: cusDataBodyApi.addressName,
          description: cusDataBodyApi.description,
          selectedPrivacy: dataPrivacy && dataPrivacy.privacy !== undefined ? dataPrivacy.privacy : 'only_me',
          privacyTeamLists: mapPrivacyTeamList,
        };

        const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations`, body);
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setTriggerCustomer(!triggerCustomer);
          handleSelectCustomer(response.data.data.customerId);

          setTimeout(() => {
            form.setFieldsValue({
              ['customer']: response.data.data.customerId,
            });
            form.setFieldsValue({
              ['address']: undefined,
            });
          }, 1000);
          if (response.data) {
            let formData = new FormData();
            formData.append('file', imageCustomer);
            setDataPrivacy();
            try {
              const resUploadImage = await httpClient.put(
                `/v3/business-partner/manager/company/${comCode}/customer-informations/${response.data.data.customerId}`,
                formData
              );
              // setTimeout(() => {
              //   setCustomerIDCreate(response.data.data.customerId);
              //   formRef.setFieldsValue({ ['customer']: response.data.data.customerId });
              //   handleGetLocation(response.data.data.customerId)
              // }, 500);
            } catch (error) {}
          }
        } else {
          errorNotification(response.data.status.messageEdit);
        }
      } catch (error) {
        if (error) {
          errorNotification(error.response.data.status.message);
        }
      }
      setVisibleCustomer(false);
      formCustomer.resetFields();
      setImageFile('');
      setImageCustomer();
      imageRef.current.value = null;
    });
  };

  const saveFormCustomer = useCallback((node) => {
    if (node !== null) {
      setFormCustomer(node);
    }
  }, []);

  const handleCancelMap = () => {
    setVisibleAddAdress(false);
    setLatlngTo({});
    setValueAddress('');
  };

  const handleCreateAddress = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const customerID = getFieldValue(`customer`);
      const customerAll = customer && customer.customers;
      const resourceSelect = customerAll && customerAll.filter((item) => customerID.includes(item.customerId));
      const dataSelect = resourceSelect && resourceSelect !== undefined ? resourceSelect[0] : undefined;

      const body = {
        customerId: customerID,
        addressName: values.locationName,
        customerName: dataSelect.customerName,
        contactName: undefined,
        fullAddress: values.location,
        address: values.location,
        subDistrict: undefined,
        district: undefined,
        province: undefined,
        postCode: undefined,
        lat: latlngTo.lat,
        lng: latlngTo.lng,
        description: values.description,
      };

      try {
        const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-address-books`, body);
        if (response.status == 200) {
          setVisibleAddAdress(false);
          formRef.resetFields();
          setLatlngTo({});
          setValueAddress('');
          successNotification(response.data.status.message);
          fncUpdateAddressBook(customerID, response);
        } else {
          setVisibleAddAdress(false);
          formRef.resetFields();
          setLatlngTo({});
          setValueAddress('');
        }
      } catch (error) {
        setVisibleAddAdress(false);
        formRef.resetFields();
        setLatlngTo({});
        setValueAddress('');
        errorNotification(error.response.data.status.message);
      }
    });
  };

  const fncUpdateAddressBook = async (customerID, dataAddressBook) => {
    if ((customerID, dataAddressBook)) {
      const response = await GetAddressBook(customerID);
      setMainState.setAddressBook(response.data.toLocations);
      setCustomerDetailfilter(dataAddressBook.data.data);
      setFieldsValue({ ['address']: dataAddressBook.data.data.customerAddressBookId });
    }
  };

  const handleCancel = () => {
    setVisibleCustomer(false);
    imageRef.current.value = null;
    setImageFile('');
    setImageCustomer();
    setDataPrivacy();
    formCustomer.resetFields();
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  useEffect(() => {
    const customerData = async () => {
      if (customerDataCreate !== undefined) {
        const response = await GetAddressBook(customerDataCreate.customerId);
        setMainState.setAddressBook(response.data.toLocations);
        setTimeout(() => {
          form.setFieldsValue({
            ['customer']: customerDataCreate.customerId,
          });
          form.setFieldsValue({
            ['address']: undefined,
          });
          mainState.addressRef.current.innerHTML = 'Address';
        }, 300);
      }
    };
    customerData();
  }, [customerDataCreate]);

  return (
    <Form>
      <div className="sale-oreder-address">
        <div className="sale-oreder-address-container">
          <Row className="sale-oreder-address-row">
            <Col span={12}>
              <div className="sale-oreder-address-col-div">
                <p className="sale-oreder-address-text">
                  <FormattedMessage id="saleOrderModalTextWareHouse" defaultMessage="Warehouse" />
                  <LabeRequire req={true} />
                </p>
                <Form.Item className="form-item-width">
                  {getFieldDecorator('wareHouse', {
                    initialValue: _.get(warehouseDetailfilter, 'wareHouseId'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'saleOrderModalValidateWareHouse', defaultMessage: 'Please select warehouse' }),
                      },
                    ],
                  })(
                    <Select
                      className="sale-oreder-address-select"
                      placeholder={intl.formatMessage({ id: 'saleOrderModalPlaceholderWareHouse', defaultMessage: 'Select Warehouse' })}
                      onSelect={handleFilterWareHouse}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {mainState.warehouseData &&
                        mainState.warehouseData.map((item) => {
                          return (
                            <Option key={item.wareHouseId} value={item.wareHouseId}>
                              {item.wareHouseName}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="sale-oreder-address-col-div">
                <p className="sale-oreder-address-text">
                  <FormattedMessage id="saleOrderModalTextCustomer" defaultMessage="Customer" />
                  <LabeRequire req={true} />
                </p>

                <div style={{ marginBottom: '8px' }}>
                  <Form.Item className="form-item-width">
                    {getFieldDecorator('customer', {
                      initialValue: _.get(customerDetailfilter, 'customerId'),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'saleOrderModalValidateCustomer', defaultMessage: 'Please select customer' }),
                        },
                      ],
                    })(
                      <Select
                        className="sale-oreder-address-select"
                        placeholder={intl.formatMessage({ id: 'saleOrderModalPlaceholderCustomer', defaultMessage: 'Select Customer' })}
                        size="small"
                        onSelect={handleSelectCustomer}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div
                              style={{ padding: '4px 8px', cursor: 'pointer' }}
                              onMouseDown={(e) => e.preventDefault()}
                              onClick={() => setVisibleCustomer(true)}
                            >
                              <Icon type="plus" className="customer-icon-plus" />
                              <span className="text-add-customer">
                                {' '}
                                <FormattedMessage id="saleOrderModalTextAddCustomer" defaultMessage="Add Customer" />
                              </span>
                            </div>
                          </div>
                        )}
                      >
                        {!loading ? customerList : [...customerList, <Option key="loading">Loading...</Option>]}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div style={{ marginBottom: '8px' }}>
                  <Form.Item className="form-item-width">
                    {getFieldDecorator('address', {
                      initialValue: _.get(customerDetailfilter, 'customerAddressBookId'),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'saleOrderModalValidateAddress', defaultMessage: 'Please select address' }),
                        },
                      ],
                    })(
                      <Select
                        className="sale-oreder-address-select"
                        placeholder={intl.formatMessage({ id: 'saleOrderModalPlaceholderAddress', defaultMessage: 'Select Address' })}
                        onSelect={handleSelectAddressBook}
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div
                              style={{ padding: '4px 8px', cursor: 'pointer' }}
                              onMouseDown={(e) => e.preventDefault()}
                              onClick={() => setVisibleAddAdress(true)}
                            >
                              <Icon type="plus" className="customer-icon-plus" />
                              <span className="text-add-customer">
                                {' '}
                                <FormattedMessage id="saleOrderModalAddAddress" defaultMessage="Add Address" />
                              </span>
                            </div>
                          </div>
                        )}
                        disabled={getFieldValue(`customer`) && getFieldValue(`customer`) !== undefined ? false : true}
                      >
                        {mainState.addressBook && mainState.addressBook !== undefined
                          ? mainState.addressBook.map((item) => <Option value={item.customerAddressBookId}>{item.addressName}</Option>)
                          : null}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={12}>
            <div className="sale-oreder-address-address-text">
              <Icon type="environment" className="icon-map" style={{ color: '#0c4da2' }} />
              <span className="sale-oreder-address-address-text-span" ref={mainState.addressRef}></span>
              {warehouseDetailfilter === undefined ? (
                <span className="sale-oreder-address-address-text-span">
                  <FormattedMessage id="saleOrderModalTextAddress" defaultMessage="Address" />: -
                </span>
              ) : (
                <span className="sale-oreder-address-address-text-span">{warehouseDetailfilter.wareHouseAddress}</span>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="sale-oreder-address-address-text">
              <Icon type="environment" className="icon-map" style={{ color: '#1D3557' }} />
              {customerDetailfilter === undefined || customerDetailfilter === {} ? (
                <span className="sale-oreder-address-address-text-p">
                  <FormattedMessage id="saleOrderModalTextAddress" defaultMessage="Address" ref={mainState.addressRef} />: -
                </span>
              ) : (
                <span className="sale-oreder-address-address-text-span" ref={mainState.addressRef}>
                  {_.get(customerDetailfilter, 'customerAddress') || _.get(customerDetailfilter, 'address') || 'Address'}
                  {/* customerDetailfilter.customerAddress || customerDetailfilter.address */}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="sale-oreder-phone-text">
              {warehouseDetailfilter === undefined ? (
                <span className="sale-oreder-address-address-text-span">
                  <FormattedMessage id="saleOrderModalTextPhone" defaultMessage="phone" />: -
                </span>
              ) : (
                <span className="sale-oreder-address-address-text-span">
                  phone:{''}
                  {warehouseDetailfilter.wareHousePhone}
                </span>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="sale-oreder-phone-text">
              <span className="sale-oreder-address-address-text-p">
                <FormattedMessage id="saleOrderModalTextPhone" defaultMessage="phone" />: -
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="sale-oreder-order-date-text" style={{ marginTop: '25px' }}>
              <span className="sale-oreder-address-address-text-p">
                <FormattedMessage id="saleOrderModalOrderDate" defaultMessage="Order Date" />:{' '}
              </span>
              <span className="sale-oreder-address-address-text-date">{dateNow}</span>
              {/* <span className="sale-oreder-address-address-text-date">{moment(today).format(dateFormat)}</span> */}
            </div>
          </Col>
          <Col span={12}>
            <div className="sale-oreder-order-date-text">
              <Row>
                <Col span={6} style={{ marginTop: '7px' }}>
                  <span className="sale-oreder-address-address-text-p">
                    <FormattedMessage id="saleOrderModalShippingDate" defaultMessage="Shipping Date" />:{' '}
                  </span>
                </Col>
                <Col span={15}>
                  <ConfigProvider locale={locale}>
                    <Form.Item className="form-item-width">
                      {getFieldDecorator('shippingDate', {
                        initialValue: moment(_.get(customerDetailfilter, 'dueDate')),
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'saleOrderModalValidateShippingDate',
                              defaultMessage: 'Please select shipping date',
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          className="sale-order-datePicker"
                          placeholder={intl.formatMessage({
                            id: 'saleOrderModalPlaceholderShippingDate',
                            defaultMessage: 'Select Shipping Date',
                          })}
                          format={dateFormat}
                        />
                      )}
                    </Form.Item>
                  </ConfigProvider>
                </Col>
                <Col span={3}>
                  <Button type="link" onClick={() => setMainState.setVisibleModalAdd(true)}>
                    + <FormattedMessage id="btnAdd" defaultMessage="Add" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      {/* <AddCustomer
        ref={saveFormCustomer}
        visible={visibleCustomer}
        onCreate={handleCreateCustomer}
        onCancel={handleCancel}
        image={{
          imageRef,
          imageFile,
          setImageFile,
          imageCustomer,
          setImageCustomer
        }}
        setCustomer={{
          setCusDataBodyApi,
        }}
        setDataPrivacy={setDataPrivacy}
        dataPrivacy={dataPrivacy}
      /> */}

      <AddBusinessPartner
        visible={visibleCustomer}
        setVisible={setVisibleCustomer}
        setCustomerData={setCustomerDataCreate}
        setTrigger={setTriggerCustomer}
      />

      {/* <CreateAddress
        visible={visibleAddAdress}
        onCancel={handleCancelMap}
        onCreate={handleCreateAddress}
        setLatlngTo={setLatlngTo}
        setValueAddress={setValueAddress}
        valueAddress={valueAddress}
        ref={saveFormRef}
      /> */}

      <AddAddressBookV2
        visible={visibleAddAdress}
        setVisible={setVisibleAddAdress}
        customerId={getFieldValue(`customer`)}
        customerName={dataSelect && dataSelect.customerName}
        toLocation={setMainState.setLocationData}
        fncUpdateAddressBook={fncUpdateAddressBook}
      />
    </Form>
  );
};
const Order = Form.create({
  name: 'modal_form',
})(OrderSaleAddress);

export default Order;
