import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Input, Row, Col, Select, InputNumber } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash'


import { numberFormatter, numberParser } from '../../../../../component-function/fnc-inputnumber-currency';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import { getConvertUoM, getStockList } from '../../../../../controllers/po/po';
import LabeRequireForm from '../../../../../components/label-required-form';
import Button_01 from '../../../../../components/v2/button_01';
import Button_02 from '../../../../../components/v2/button_02';
import SuffixInput from '../../../../../components/v2/suffix';

const { Option } = Select

const ListItemCreate = (props) => {
  const { data, visible, setVisible, form, selectItem, setSelectItem } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [uomList, setUomList] = useState([])
  const intl = useIntl()
  const qty = parseFloat(getFieldValue('qty') || 0)
  const price = parseFloat(getFieldValue('price') || 0.00)
  const discount = parseFloat(getFieldValue('discount') || 0)
  const discountNumber = parseFloat(getFieldValue('discountNumber') || 0.00)
  const inputNumberQTY = useRef();
  const inputNumberPrice = useRef();
  const inputNumberDiscountRef = useRef();
  const inputNumberDiscountNumberRef = useRef();

  console.log("data", data)

  useEffect(() => {
    const getStockList = async () => {
      const unitData = await getConvertUoM(_.get(data, 'itemMasterId'))
      setUomList(_.get(unitData, 'data.data'))
    }
    if (data && visible) {
      getStockList()
    }
  }, [data, visible])

  useEffect(() => {
    if (qty || price) {
      setFieldsValue({
        ['amount']: numberFormatter(qty * price)
      });
    }
  }, [qty, price])

  useEffect(() => {
    if (price && price > 0) {
      const percentNumber = (price * discount) / 100
      const total = (qty * price) - (percentNumber * qty)
      setFieldsValue({
        ['discountNumber']: (((price * discount) / 100) * qty).toFixed(2),
        ['total']: numberFormatter(total),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.00,
        ['discountNumber']: 0.00,
        ['total']: 0.00,
      });
    }
  }, [price, getFieldValue('amount')])

  useEffect(() => {
    if (discountNumber && discountNumber > 0) {
      const total = (qty * price) - discountNumber
      setFieldsValue({
        ['discount']: ((discountNumber / (qty * price)) * 100).toFixed(2),
        ['total']: numberFormatter(total),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.00,
        ['discountNumber']: 0.00,
        ['total']: numberFormatter(price * qty),
      });
    }
  }, [discountNumber])

  useEffect(() => {
    if (discount && discount > 0) {
      const percentNumber = (price * discount) / 100
      const total = (qty * price) - (percentNumber * qty)
      setFieldsValue({
        ['discountNumber']: (((price * discount) / 100) * qty).toFixed(2),
        ['total']: numberFormatter(total),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.00,
        ['discountNumber']: 0.00,
        ['total']: numberFormatter(price * qty),
      });
    }
  }, [discount])


  const handleSave = async () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const response = await getStockList({ 'itemMasterId': [_.get(data, 'itemMasterId')] })
      const filterUoM = _.filter(uomList, (item) => { return item.uomId === _.get(values, 'unit') })


      console.log('handleSaveGGG', selectItem, data, values)

      const findeDuplicate = _.findIndex(selectItem, function (item) {
      console.log('check', 
        item.itemMasterId === _.get(data, 'itemMasterId'), '/', 
        item.unitId === _.get(values, 'unit'), '/',
        item.discountPercent === parseFloat(_.get(values, 'discount') || 0), '/',
        item.pricePerUnitNum === parseFloat(_.get(values, 'price')), '/',
      )
      
        return item.itemMasterId === _.get(data, 'itemMasterId') &&
          item.unitId === _.get(values, 'unit') && item.discountPercent === parseFloat(_.get(values, 'discount') || 0) &&
          item.pricePerUnitNum === parseFloat(_.get(values, 'price'))
      })
      if (findeDuplicate !== -1) { // เช็คเรื่องของซ้ำ

        const newQty = parseFloat(selectItem[findeDuplicate].qtyNum) + parseFloat(_.get(values, 'qty'))
        const newTotal = parseFloat(selectItem[findeDuplicate].totalNum) + numberParser(_.get(values, 'total'))
        const newDiscountNumber = parseFloat(selectItem[findeDuplicate].discountNum) + parseFloat(_.get(values, 'discountNumber') || 0)

       // newSumItem
        selectItem[findeDuplicate].qtyNum = newQty
        selectItem[findeDuplicate].totalNum = newTotal
        selectItem[findeDuplicate].discountNum = newDiscountNumber
        // newShowInvoice
        selectItem[findeDuplicate].qtyTxt = newQty + ' ' + _.get(filterUoM, '[0].name')
        selectItem[findeDuplicate].discountTHB = numberFormatter(newDiscountNumber) + ' THB'
        selectItem[findeDuplicate].totalTxt = numberFormatter(newTotal) + ' THB'
        // newSubtotal
        const amoutdiscount = parseInt(newQty) * _.get(values, 'price') * (_.round(_.get(values, 'discount'), 2) / 100);
        const amount = parseInt(newQty) * _.get(values, 'price') - amoutdiscount;
        selectItem[findeDuplicate].subtotal = amount

      } else { // สร้างครั้งแรก
        const amoutdiscount = parseInt(qty) * _.get(values, 'price') * (_.round(_.get(values, 'discount'), 2) / 100);
        const amount = parseInt(qty) * _.get(values, 'price') - amoutdiscount;

        const itemBody = {
          "index": _.size(selectItem) + 1,
          "itemMasterId": _.get(data, 'itemMasterId'),
          "itemCode": _.get(data, 'itemCode'),
          "itemName": _.get(data, 'itemName'),
          "qtyNum": parseFloat(_.get(values, 'qty')),
          "count": parseFloat(_.get(values, 'qty')),
          "countTemp": parseFloat(_.get(values, 'qty')),
          "unitId": _.get(values, 'unit'),
          "uom": _.get(filterUoM, '[0].name'),
          // "pricePerUnit": parseFloat(_.get(values, 'price')),
          "pricePerUnitNum": parseFloat(_.get(values, 'price')),
          "discountPercent": parseFloat(_.get(values, 'discount') || 0),
          "discountNum": parseFloat(_.get(values, 'discountNumber') || 0),
          "totalNum": numberParser(_.get(values, 'total')),
          "remark": _.get(values, 'remark') || "",
          "instock": _.get(response, 'data.data[0].qty') || 0,
          "type": "service",
          "categoryCode": "service",
          "totalQty": numberParser(_.get(values, 'total')),
          "subtotal":  amount,

          // เอาไปแสดงกับ invoice
          "pricePerUnit": numberFormatter(_.get(values, 'price')) + ' ' + 'THB',
          "qtyTxt": parseFloat(_.get(values, 'qty'))+ ' ' + _.get(filterUoM, '[0].name'),
          "discountTHB": numberFormatter(parseFloat(_.get(values, 'discountNumber') || 0)) + ' THB',
          // "discountPercent": parseFloat(_.get(values, 'discount') || 0),
          "totalTxt": numberFormatter(numberParser(_.get(values, 'total'))) + ' THB',

        }
        setSelectItem([...selectItem, itemBody])
      }
      setVisible(false)
      resetFields()
    })
  }
  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  const validatorQty = (rule, value, callback) => {
    if (value) {
      const parsValue = parseFloat(value || 0)
      if (parsValue === 0) {
        callback('QTY must more than 0');
      } else {
        callback();
      }
    }
    else {
      callback();
    }
  }

  const currencyFormatter = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };

  const mouseOver = () => {
    if (inputNumberQTY.current) inputNumberQTY.current.blur();
    if (inputNumberPrice.current) inputNumberPrice.current.blur();
    if (inputNumberDiscountRef.current) inputNumberDiscountRef.current.blur()
    if (inputNumberDiscountNumberRef.current) inputNumberDiscountNumberRef.current.blur()
  }

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemTitle`, defaultMessage: 'Add Item' })}
      centered={true}
      width={900}
      onCancel={handleCancel}
      visible={visible}
      bodyStyle={{ padding: 'unset', height: '315px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={handleCancel} onMouseOver={mouseOver}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave} style={{ margin: 'unset' }} onMouseOver={mouseOver}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <div style={{ padding: '16px' }}>
        <div style={{ marginBottom: '28px' }}>
          <div style={{ marginBottom: '8px', color: '#1D3557', fontSize: '12px' }}>
            <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleItem', defaultMessage: 'Item' })} req={false} />
          </div>
          <div style={{ color: '#050505', fontSize: '13px' }}>
            {_.get(data, 'itemCode')} {_.get(data, 'itemName')}
          </div>
        </div>
        <Form form={form} layout="vertical">
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleQty', defaultMessage: 'Qty' })} req={true} />
                }
              >
                {getFieldDecorator('qty', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateQty', defaultMessage: 'Please Enter Qty' }),
                    },
                    {
                      validator: validatorQty
                    }
                  ],
                })(
                  <InputNumber
                    ref={inputNumberQTY}
                    className='disabled-handle-count'
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderQty', defaultMessage: 'Enter Qty' })}
                    onKeyDown={handleKeyDownNumber}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />
                }
              >
                {getFieldDecorator('unit', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderUnit', defaultMessage: 'Select Unit' })}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {_.map(uomList, (item) =>
                      <Option value={item.uomId}>
                        {item.name}
                      </Option>
                    )}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitlePrice', defaultMessage: 'Price/Unit' })} req={true} />
                }
              >
                {getFieldDecorator('price', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidatePrice', defaultMessage: 'Please Enter Price/Unit' }),
                    },
                  ],
                })(
                  <InputNumber
                    ref={inputNumberPrice}
                    className='disabled-handle-count'
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderPrice', defaultMessage: 'Enter Price/Unit' })}
                    suffix='THB'
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                  />
                )}
                <SuffixInput text='THB' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleAmount', defaultMessage: 'Amount' })} req={false} />
                }
              >
                {getFieldDecorator('amount', {
                  initialValue: '0.00'
                })(
                  <Input
                    maxLength={100}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleDiscount', defaultMessage: 'Discount' })} req={false} />
                }
              >
                {getFieldDecorator('discount', {
                  initialValue: '0.00',
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    ref={inputNumberDiscountRef}
                  />
                )}
                <SuffixInput text='%' />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label={
                  <LabeRequireForm text='' req={false} />
                }
              >
                {getFieldDecorator('discountNumber', {
                  initialValue: '0.00',
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    suffix='THB'
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    ref={inputNumberDiscountNumberRef}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleTotal', defaultMessage: 'Total' })} req={false} />
                }
              >
                {getFieldDecorator('total', {
                  initialValue: '0.00',
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderTotal', defaultMessage: 'Enter Total' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                    readOnly={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {getFieldDecorator('remark', {
                })(
                  <Input
                    maxLength={100}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

    </Modal>
  )
}

const ModalCreateListServiceItemInvoice = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() { },
})(ListItemCreate);

export default ModalCreateListServiceItemInvoice