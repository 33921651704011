import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Button01 from '../../../../../components/v2/button_01';

const { Option } = Select;

const ListItemFilter = (props) => {
  const { onChange, searchGroup, warehouse, stock, brand, model, itemCode, itemName, onClick } = props;
  const intl = useIntl();

  const handleCheckboxWarehouseChange = (checkedList) => {
    warehouse.setWarehouseSelect(checkedList);
    warehouse.setWarehouseInterminate(!!checkedList.length && checkedList.length < warehouse.warehouseData.length);
    warehouse.setWarehouseCheckAll(checkedList.length === warehouse.warehouseData.length);
  };

  const handleCheckWarehouseAllChange = (e) => {
    warehouse.setWarehouseSelect(
      e.target.checked
        ? _.map(warehouse.warehouseData, (item) => {
            return item.warehouseId;
          })
        : []
    );
    warehouse.setWarehouseInterminate(false);
    warehouse.setWarehouseCheckAll(e.target.checked);
  };

  const handleCheckboxStockChange = (checkedList) => {
    stock.setStockSelect(checkedList);
    stock.setStockInterminate(!!checkedList.length && checkedList.length < stock.stockData.length);
    stock.setStockCheckAll(checkedList.length === stock.stockData.length);
  };

  const handleCheckStockAllChange = (e) => {
    stock.setStockSelect(
      e.target.checked
        ? _.map(stock.stockData, (item) => {
            return item.warehouseId;
          })
        : []
    );
    stock.setStockInterminate(false);
    stock.setStockCheckAll(e.target.checked);
  };

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '580px' }}>
        <div className="listItemFilterContent">
          <div>
            <Checkbox
              indeterminate={warehouse.warehouseInterminate}
              onChange={handleCheckWarehouseAllChange}
              checked={warehouse.warehouseCheckAll}
            >
              <span className="listItemFilterContentHeadText">
                <FormattedMessage id="searchItemRequestTextWarehouse" defaultMessage="Warehouse" />
              </span>
            </Checkbox>
          </div>
          <div>
            <span onClick={() => warehouse.setPanelWarehouse((current) => !current)} style={{ cursor: 'pointer' }}>
              <FormattedMessage id="filterTaskSelected" defaultMessage="Selected" /> {_.size(warehouse.warehouseSelect)}{' '}
              {warehouse.panelWarehouse ? <Icon type="up" /> : <Icon type="down" />}
            </span>
          </div>
        </div>
        {warehouse.panelWarehouse ? (
          <Checkbox.Group style={{ marginLeft: '32px' }} value={warehouse.warehouseSelect} onChange={handleCheckboxWarehouseChange}>
            <Row gutter={[16, 16]}>
              {_.map(warehouse.warehouseData, (item) => (
                <Col span={24}>
                  <Checkbox value={item.warehouseId}>{item.warehouseName}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        ) : null}
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        {/* <div className="listItemFilterContent">
          <div>
            <Checkbox indeterminate={stock.stockInterminate} onChange={handleCheckStockAllChange} checked={stock.stockCheckAll}>
              <span className="listItemFilterContentHeadText">
                <FormattedMessage id="modalListItemFilterTextStockBalance" defaultMessage="Stock Balance" />
              </span>
            </Checkbox>
          </div>
          <div>
            <span onClick={() => stock.setPanelStock((current) => !current)} style={{ cursor: 'pointer' }}>
              <FormattedMessage id="filterTaskSelected" defaultMessage="Selected" /> {_.size(stock.stockSelect)}{' '}
              {stock.panelStock ? <Icon type="up" /> : <Icon type="down" />}
            </span>
          </div>
        </div>
        {stock.panelStock ? (
          <Checkbox.Group style={{ width: '100%', marginLeft: '32px' }} value={stock.stockSelect} onChange={handleCheckboxStockChange}>
            <Row gutter={[16, 16]}>
              {_.map(stock.stockData, (item) => (
                <Col span={24}>
                  <Checkbox value={item.warehouseId}>{item.warehouseName}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        ) : null} */}
        {/* <Divider type="horizontal" style={{ margin: 'unset' }} /> */}
        <div style={{ padding: '16px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextBrand" defaultMessage="Brand" />
            </div>
            <div>
              <Select
                showSearch
                allowClear={true}
                onSearch={(value) => brand.setBrandSearch(value)}
                onSelect={(value) => onChange(value, 'brand')}
                dataSource={brand.optionsBrand}
                filterOption={false}
                value={_.get(searchGroup, 'brand')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextBrandPlaceholder', defaultMessage: 'Select Brand' })}
                style={{ width: '100%' }}
                notFoundContent={brand.loadingBrand ? 'Loading...' : null}
              >
                {_.map(brand.optionsBrand, (item) => (
                  <Option value={item.item_brand_id}>{item.item_brand_name}</Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextModel" defaultMessage="Model" />
            </div>
            <div>
              <Select
                showSearch
                allowClear={true}
                onSearch={(value) => model.setModelSearch(value)}
                onSelect={(value) => onChange(value, 'model')}
                dataSource={model.optionsModel}
                filterOption={false}
                value={_.get(searchGroup, 'model')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextModelPlaceholder', defaultMessage: 'Select Model' })}
                style={{ width: '100%' }}
                notFoundContent={model.loadingModel ? 'Loading...' : null}
                disabled={_.get(searchGroup, 'brand') ? false : true}
              >
                {_.map(model.optionsModel, (item) => (
                  <Option value={item.item_model_id}>{item.item_model_name}</Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextItemCode" defaultMessage="Item Code" />
            </div>
            <div>
              <Select
                showSearch
                allowClear={true}
                onSearch={(value) => itemCode.setItemCodeSearch(value)}
                onSelect={(value) => onChange(value, 'itemCode')}
                dataSource={itemCode.optionsItemCode}
                filterOption={false}
                value={_.get(searchGroup, 'itemCode')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemCodePlaceholder', defaultMessage: 'Enter Item Code' })}
                style={{ width: '100%' }}
                notFoundContent={itemCode.loadingItemCode ? 'Loading...' : null}
              >
                {itemCode.optionsItemCode &&
                  itemCode.optionsItemCode.map((item) => (
                    <Option key={item.itemCode} value={item.itemMasterId}>
                      {item.itemCode}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextItemName" defaultMessage="Item Name" />
            </div>
            <div>
              <Select
                showSearch
                allowClear={true}
                onSearch={(value) => itemName.setItemNameSearch(value)}
                onSelect={(value) => onChange(value, 'itemName')}
                dataSource={itemName.optionsItemName}
                filterOption={false}
                value={_.get(searchGroup, 'itemName')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemNamePlaceholder', defaultMessage: 'Enter Item Name' })}
                style={{ width: '100%' }}
              >
                {itemName.optionsItemName &&
                  itemName.optionsItemName.map((item, index) => (
                    <Option key={item.itemCode} value={item.itemMasterId}>
                      {item.itemName}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px' }}>
        <Button01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={onClick}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button01>
      </div>
    </div>
  );
};

export default ListItemFilter;
