import httpClient from '../../components/axiosClient';

const getAllTeamBuMemCom = async (memId) => {
  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  try {
    const response = await httpClient.get(`/v3/resource/manager/company/${comId}/team-leader-all/${memId}/member/${memComId}`);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

export default getAllTeamBuMemCom;