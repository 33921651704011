import React, { useContext, useState } from 'react';
import { PageSettings } from '../../config/page-settings';
import { AccountReceiveContext } from './context';
import { StickyContainer } from 'react-sticky';
import { Button, Card, Col, Dropdown, Menu, Row, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';

import Button_01 from '../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';

import CanceledQuotation from './component-canceled/canceled';
// import QuotationModal from './modal/quotation/quotation-modal';
import ModalCustomerQuotationForm from './modal/quotation/quotation-modal';
import UploadFileModal from './modal/upload-file/upload-modal';
import './css/index.css';
import _ from 'lodash';
import PendingAR from './component-pending/pending';
import WaitingAR from './component-waiting/waiting';
import PaidAR from './component-paid/paid';
const { TabPane } = Tabs;

const SaleOrderPage = () => {
  const intl = useIntl();
  const { state, setState, func } = useContext(AccountReceiveContext);
  const {
    isOpenQuotation,
    openUpload,
    attachmentData,
    typeAttachment,
    defaultAttachment,
    initialMemberList,
    record,
    reportDataPending,
    visibleViewInvoice,
    signatureUser,
    recordInvoice,
    signatureUserInvoice,
  } = state;
  const { seIsOpenQuotation, setAttachmentData, setTypeAttachment, setDefaultAttachment, setOpenUpload, setTabKey } = setState;
  const { handleOpenModalQuotation, handleSaveQuotation, handleCancelQuotation, handleOpenViewInvoice, handleCancelViewInvoice } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataPending;

  const [visible, setVisible] = useState(false);

  console.log('record', record);

  const handleOpenModal = () => {
    handleOpenModalQuotation();
    setVisible(!visible);
  };

  const menuColumn = () => {
    return (
      // <PageSettings.Consumer>
      //   {({ checkPermissionAction }) => (
      <Menu>
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) ? ( */}
        <Menu.Item key="1">
          <Button key="1" ghost type="link" onClick={() => handleOpenModal()}>
            <FormattedMessage id="quotationLabelCreateSalesOrder" defaultMessage="Create Sale Order" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? ( */}
        <Menu.Item key="2">
          <Button key="2" ghost type="link" onClick={() => {}}>
            <FormattedMessage id="quotationLabelExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
      </Menu>
      //   )}
      // </PageSettings.Consumer>
    );
  };

  const callback = (key) => {
    setTabKey(key);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {/* {checkPermissionAction(`P22PG1C2`, `P22PG1C2A1`) || 
                checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ||
                checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ||
                checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
              {checkPermissionAction(`P61PG1C1`, `P61PG1C1A1`) ? (
                <Card className="monitor-main-card">
                  <StickyContainer>
                    <Tabs
                      className="monitor-main-tabs customer-tab-margin"
                      size="large"
                      defaultActiveKey="pending"
                      onChange={callback}
                      animated={false}
                      // tabBarExtraContent={
                      //   <Dropdown
                      //     overlay={menuColumn}
                      //     trigger={['click']}
                      //     onVisibleChange={setVisible}
                      //     visible={visible}
                      //     placement="bottomRight"
                      //     className="ant-dropdown-custom"
                      //   >
                      //     <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                      //       <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                      //       <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                      //     </Button_01>
                      //   </Dropdown>
                      // }
                    >
                      {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
                      <TabPane
                        tab={
                          intl.formatMessage({ id: `ARTabPendingforInvoice`, defaultMessage: 'Pending for Invoice' }) +
                          ` (${quotationTotal})`
                        }
                        key="pending"
                      >
                        <PendingAR />
                      </TabPane>
                      {/* ) : null} */}

                      {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
                      <TabPane
                        tab={intl.formatMessage({ id: `ARTabWaitingforPayment`, defaultMessage: 'Waiting for Payment' })}
                        key="waitingpaid"
                      >
                        <WaitingAR />
                      </TabPane>
                      {/* ) : null} */}

                      {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
                      <TabPane tab={intl.formatMessage({ id: `ARTabPaid`, defaultMessage: 'Paid' })} key="paid">
                        <PaidAR />
                      </TabPane>
                      {/* ) : null} */}

                      {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
                      <TabPane tab={intl.formatMessage({ id: `ARTabCanceled`, defaultMessage: 'Canceled' })} key="canceled">
                        <CanceledQuotation />
                      </TabPane>
                      {/* ) : null} */}
                    </Tabs>
                  </StickyContainer>
                </Card>
              ) : null}
            </Col>
          </Row>

          <ModalCustomerQuotationForm
            title={
              _.get(record, 'code') === 'viewinvoice'
                ? `${intl.formatMessage({ id: `ARViewInvoice`, defaultMessage: 'View Invoice' })}` +
                  ' · ' +
                  `${intl.formatMessage({ id: `ARSONo`, defaultMessage: 'SO No' })}` +
                  ' ' +
                  _.get(record, 'saleOrderDetail.saleOrderNo')
                : _.get(record, 'code') === 'view'
                ? `${intl.formatMessage({ id: `ARCreateInvoice`, defaultMessage: 'Create Invoice' })}`
                : _.get(record, 'code') === 'paid'
                ? `${intl.formatMessage({ id: `ARInvoice`, defaultMessage: 'Invoice' })} · ${_.get(record, 'saleOrderNo')}`
                : `${intl.formatMessage({ id: `ARPaidInvoice`, defaultMessage: 'Paid Invoice' }) + ' · ' + _.get(record, 'saleOrderNo')}`
            }
            visible={isOpenQuotation}
            onOk={handleSaveQuotation}
            onCancel={handleCancelQuotation}
            initialMemberList={initialMemberList}
            attData={attachmentData}
            setAttData={setAttachmentData}
            record={record}
            signatureUser={signatureUser}
          />

          <ModalCustomerQuotationForm
            title={
              _.get(record, 'code') === 'viewinvoice'
                ? `${intl.formatMessage({ id: `ARViewInvoice`, defaultMessage: 'View Invoice' })}` +
                  ' · ' +
                  `${intl.formatMessage({ id: `ARSONo`, defaultMessage: 'SO No' })}` +
                  ' ' +
                  _.get(record, 'saleOrderDetail.saleOrderNo')
                : _.get(record, 'code') === 'view'
                ? `${intl.formatMessage({ id: `ARCreateInvoice`, defaultMessage: 'Create Invoice' })}`
                : _.get(record, 'code') === 'paid'
                ? `${intl.formatMessage({ id: `ARInvoice`, defaultMessage: 'Invoice' })} · ${_.get(record, 'saleOrderNo')}`
                : `${intl.formatMessage({ id: `ARPaidInvoice`, defaultMessage: 'Paid Invoice' }) + ' · ' + _.get(record, 'saleOrderNo')}`
            }
            visible={visibleViewInvoice}
            onOk={handleSaveQuotation}
            onCancel={handleCancelViewInvoice}
            initialMemberList={initialMemberList}
            attData={attachmentData}
            setAttData={setAttachmentData}
            record={recordInvoice}
            signatureUser={signatureUserInvoice}
          />

          <UploadFileModal
            visible={openUpload}
            setVisible={setOpenUpload}
            setAttData={setAttachmentData}
            attData={attachmentData}
            typeAtt={typeAttachment}
            defaultAtt={defaultAttachment}
            record={record}
            saleId={_.get(record, 'saleOrderNoId')}
            typeUp={'pending'}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default SaleOrderPage;
