import React, { useLayoutEffect, useMemo, useState, useEffect } from 'react';
import './css/index.css';
import { Modal, Form, Row, Col, Input, DatePicker, Select, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_02 from '../../v2/button_02';
import Button_01 from '../../v2/button_01';
import UploadTable from '../table-upload';
import {
  createClaimHistory,
  deleteClaimDocument,
  getAssetInsuranceByClaimDate,
} from '../../../controllers/asset-resource/asset-controller';
import _ from 'lodash';
import moment from 'moment';
import { errorNotification, successNotification } from '../../v2/notification';
import UploadFileModal from '../modal-upload/index.js';
import ReactBnbGallery from 'react-bnb-gallery';
import httpClient from '../../../components/axiosClient';
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number.js';

const { Option } = Select;
const AddClaimForm = (props) => {
  const { assetId, visible, claimRef, setVisible, setTriggerApi, form } = props;
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const dateFormat = 'ddd, MMM DD YYYY';
  const [openUpload, setOpenUpload] = useState(false);
  const [attData, setAttData] = useState([]);
  const [typeAtt, setTypeAtt] = useState('add');
  const [defaultAtt, setDefaultAtt] = useState();
  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();

  const insuranceDate = getFieldValue('insuranceDate');
  const damageValue = getFieldValue('damageValue');
  const claimableAmount = getFieldValue('claimableAmount');

  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');
  const [insuranceDataClaim, setInsuranceDataClaim] = useState([]);

  const [memberClaim, setMemeberClaim] = useState();

  const [memberListMaster, setMemberListMaster] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberPage, setMemberPage] = useState(1);
  const [memberLoading, setMemberLoading] = useState(false);
  const [memberSelect, setMemberSelect] = useState();
  const [memberSearch, setMemberSearch] = useState();
  const [memberSearchPage, setMemberSearchPage] = useState(1);
  const [ismemberLoadMore, setIsMemberLoadMore] = useState(true);
  const [ismemberSearchLoadMore, setIsMemberSearchLoadMore] = useState(true);

  ///////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getData = async () => {
      const like = memberSearch ? [{ key: '', val: memberSearch }] : [];
      const indexPage = memberSearch ? 1 : memberPage;
      const payload = {
        user_id: memComId,
        like: like,
        equal: [],
        date: [],
        order: 'created_at ASC',
        limit: 10,
        index_page: indexPage,
        com_id: comId,
      };
      const result = await httpClient.post('/v2/searchusers', payload);
      if (result.status === 200) {
        const setDataMember = _.map(_.get(result, 'data.data'), (item) => {
          return {
            memComId: _.get(item, 'mem_com_id'),
            fullname: _.get(item, 'fullname'),
            phone: _.get(item, 'phone'),
          };
        });

        const members = _.uniqBy(_.concat(memberList, setDataMember), 'memComId');
        setMemberList(members);
        setMemberListMaster(members);
        setMemberLoading(false);
        setIsMemberLoadMore(_.size(setDataMember) > 0 ? true : false);
      }
    };

    if (ismemberLoadMore && visible === true) {
      getData();
    }
  }, [memberPage, visible]);

  useEffect(() => {
    const getData = async () => {
      if (!memberSearch) {
        setMemberList(memberListMaster);
        setMemberLoading(false);
      } else {
        const like = memberSearch ? [{ key: '', val: memberSearch }] : [];
        const indexPage = memberSearch ? 1 : memberSearchPage;
        const payload = {
          user_id: memComId,
          like: like,
          equal: [],
          date: [],
          order: 'created_at ASC',
          limit: 10,
          index_page: indexPage,
          com_id: comId,
        };
        const result = await httpClient.post('/v2/searchusers', payload);
        if (result.status === 200) {
          const setDataMember = _.map(_.get(result, 'data.data'), (item) => {
            return {
              memComId: _.get(item, 'mem_com_id'),
              fullname: _.get(item, 'fullname'),
              phone: _.get(item, 'phone'),
            };
          });
          const members = _.uniqBy(_.concat(memberList, setDataMember), 'memComId');
          setMemberList(members);
          setMemberLoading(false);
          setIsMemberSearchLoadMore(_.size(setDataMember) > 0 ? true : false);
        }
      }
    };

    if (ismemberSearchLoadMore && visible === true) {
      getData();
    }
  }, [memberSearch, memberSearchPage, visible]);

  const handleMemberPopupScroll = (e) => {
    let target = e.target;
    if (!memberLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 20) {
      setMemberLoading(true);
      if (memberSearch) {
        setMemberSearchPage(memberSearchPage + 1);
      } else {
        setMemberPage(memberPage + 1);
      }
    }
  };

  const handleMemberChange = (value) => {
    if (!value) {
      setMemberSelect();
    }
  };

  const handleMemberChangeSelect = (value, e) => {
    const val = _.get(e, 'props');

    if (value !== memberSelect) {
      setMemberSearch();
      setMemberSelect(value);
      if (val) {
        setMemeberClaim(val);
      } else {
        setMemeberClaim();
      }
    }
  };

  const handleMemberChangeSearch = (value) => {
    setMemberLoading(true);
    setIsMemberSearchLoadMore(true);
    setMemberList([]);
    setMemberSearchPage(1);
    setMemberSearch(value);
  };

  const options =
    memberList.map &&
    memberList.map((item) => (
      <Option key={`key-${item.memComId}`} value={item.memComId}>
        {item.fullname}
      </Option>
    ));
  ///////////////////////////////////////////////////////////////////////////////////////////////

  useLayoutEffect(() => {
    const getAssetInsuranceByClaimDateApi = async () => {
      try {
        const resp = await getAssetInsuranceByClaimDate(assetId, moment(insuranceDate).format('YYYY-MM-DD'));
        setInsuranceDataClaim(_.get(resp.data, 'assetInsurance'));
      } catch (error) { }
    };
    if (assetId) {
      getAssetInsuranceByClaimDateApi();
    }
  }, [getFieldValue('insuranceDate')]);

  useEffect(() => {
    if (parseFloat(claimableAmount) > parseFloat(damageValue)) {
      setFieldsValue({ ['claimableAmount']: damageValue });
    }
  }, [claimableAmount]);

  useEffect(() => {
    if (parseFloat(damageValue)) {
      setFieldsValue({ ['claimableAmount']: '' });
    }
  }, [damageValue]);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) return;

      if (values) {
        console.log('values', values);
        let newAttData = attData && attData.map((item) => item.fileHash);
        try {
          const payload = {
            assetId: assetId,
            assetInsuranceId: values.insuranceCompany,
            claimDate: moment(values.insuranceDate).format('YYYY-MM-DD hh:mm:ss'),
            claimBy: _.get(memberClaim, 'children'),
            claimByMemComId: _.get(memberClaim, 'value'),
            serialNo: values.claimRef || '',
            damageVal: parseFloat(values.damageValue) || 0,
            claimableTotal: parseFloat(values.claimableAmount) || 0,
            listFileHash: newAttData,
          };
          const response = await createClaimHistory(payload);
          if (_.get(response.data, 'claimId')) {
            successNotification(_.get(response.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.status, 'message'));
        }
      }
      resetFiledsAll();
    });
  };

  const onCancel = async () => {
    try {
      if (_.size(attData) > 0) {
        for (let index = 0; index < attData.length; index++) {
          const fileHash = attData[index].fileHash;
          await deleteClaimDocument(fileHash);
        }
      }
    } catch (error) { }
    resetFiledsAll();
  };

  const resetFiledsAll = () => {
    setMemberPage(1);
    setMemberLoading(false);
    setMemberSelect();
    setMemberList([]);
    setMemberListMaster([]);
    setMemberSearchPage(1);
    setMemberSearch();
    setIsMemberLoadMore(true);
    setMemeberClaim();
    setIsMemberSearchLoadMore(true);
    setAttData([]);
    setDefaultAtt();
    setTriggerApi((current) => !current);
    setVisible(false);
    resetFields();
  };

  const handleDeleteIMG = async (hash) => {
    await deleteClaimDocument(hash);
    const filter = _.filter(attData, (item) => {
      return item.fileHash !== hash;
    });
    setAttData(filter);
  };

  const handlePreview = async (file) => {
    console.log('filehandlePreview', file);
    let isPDF = _.get(file, 'type') === 'application/pdf' ? true : false;
    if (isPDF) return window.open(_.get(file, 'url'), '_blank');
    const mapdata = {
      photo: _.get(file, 'url'),
      number: 1,
      caption: _.get(file, 'remark'),
      thumbnail: _.get(file, 'url'),
    };
    setModalView(mapdata);
    setStateModal(!stateModal);
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={() => setStateModal(false)} />
      </div>
    );
  };

  const optionInsuranceDataClaim = (item) => (
    <Option key={item.assetInsuranceId} value={item.assetInsuranceId}>
      {item.insuranceName}
    </Option>
  );

  const optionClaimRef = (item, index) => (
    <Option key={`${item}-${index}`} value={`${item}`}>
      {item}
    </Option>
  );

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => onCancel()}
        centered={true}
        title={<FormattedMessage id="ModalTitleClaim" defaultMessage="Claim" />}
        width={760}
        bodyStyle={{ padding: '24px' }}
        footer={[
          <Button_02 key="back" btnsize="wd_df" onClick={() => onCancel()} style={{ margin: 'unset' }}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]}
      >
        <Form>
          <Row gutter={16}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="AssetModalTitleFormClaimDate" defaultMessage="Claim Date" />
                    <LabeRequire req={true} />
                  </span>
                  {getFieldDecorator('insuranceDate', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'AssetModalTitleFormClaimDateVL',
                          defaultMessage: 'Please Select Claim Date',
                        }),
                      },
                    ],
                  })(
                    <DatePicker
                      allowClear={false}
                      format={dateFormat}
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: 'AssetModalTitleFormClaimDatePH', defaultMessage: 'Claim Date' })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="AssetModalTitleFormInsuranceCompany" defaultMessage="Insurance Company" />
                    <LabeRequire req={true} />
                  </span>
                  {getFieldDecorator('insuranceCompany', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'AssetModalTitleFormInsuranceCompanyVL',
                          defaultMessage: 'Please Select Insurance Company',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'modalAssetAssetClassInsuranceCompanyPlaceholder',
                        defaultMessage: 'Select Insurance Company',
                      })}
                      style={{ width: '100%' }}
                      defaultActiveFirstOption={false}
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      disabled={insuranceDate ? false : true}
                    >
                      {insuranceDataClaim && insuranceDataClaim.map((item) => optionInsuranceDataClaim(item))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="AssetModalTitleFormClaimBy" defaultMessage="Claim By" />
                    <LabeRequire req={true} />
                  </span>
                  {getFieldDecorator('claimBy', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'AssetModalTitleFormClaimByVL',
                          defaultMessage: 'Please Enter Claim By',
                        }),
                      },
                    ],
                  })(
                    <Select
                      loading={memberLoading}
                      allowClear={false}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({
                        id: 'AssetModalTitleFormClaimByPH',
                        defaultMessage: 'Enter Claim By',
                      })}
                      optionFilterProp="children"
                      onPopupScroll={handleMemberPopupScroll}
                      onSearch={handleMemberChangeSearch}
                      onChange={handleMemberChange}
                      onSelect={handleMemberChangeSelect}
                    >
                      {options}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="AssetModalTitleFormAssetClaimReference" defaultMessage="Asset Claim Reference" />
                  </span>
                  {getFieldDecorator('claimRef', {
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'AssetModalTitleFormAssetClaimReferenceVL',
                          defaultMessage: 'Please Select Asset Claim Reference',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'modalAssetAssetClassAssetClaimReferencePlaceholder',
                        defaultMessage: 'Select Asset Claim Reference',
                      })}
                      style={{ width: '100%' }}
                      defaultActiveFirstOption={false}
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                    >
                      {claimRef && claimRef.map((item) => optionClaimRef(item))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="AssetModalTitleFormDamageValue" defaultMessage="Damage Value (Baht)" />
                    <LabeRequire req={true} />
                  </span>
                  {getFieldDecorator('damageValue', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'AssetModalTitleFormDamageValueVL',
                          defaultMessage: 'Please Enter Damage Value',
                        }),
                      },
                    ],
                  })(
                    <Input
                      onKeyDown={handleKeyDownDecimal2Fixed}
                      placeholder={intl.formatMessage({ id: 'AssetModalTitleFormDamageValuePH', defaultMessage: 'Enter Damage Value' })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="AssetModalTitleFormClaimableAmount" defaultMessage="Claimable Amount (Baht)" />
                    <LabeRequire req={true} />
                  </span>
                  {getFieldDecorator('claimableAmount', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'AssetModalTitleFormClaimableAmountVL',
                          defaultMessage: 'Please Enter Claimable Amount',
                        }),
                      },
                    ],
                  })(
                    <Input
                      onKeyDown={handleKeyDownDecimal2Fixed}
                      placeholder={intl.formatMessage({
                        id: 'AssetModalTitleFormClaimableAmountPH',
                        defaultMessage: 'Enter Claimable Amount',
                      })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Form>
        <UploadTable
          checkedType="add"
          setVisible={setOpenUpload}
          attData={attData}
          setTypeAtt={setTypeAtt}
          setDefaultAtt={setDefaultAtt}
          handleDeleteIMG={handleDeleteIMG}
          handlePreview={handlePreview}
        />
      </Modal>
      <UploadFileModal
        visible={openUpload}
        setVisible={setOpenUpload}
        setAttData={setAttData}
        attData={attData}
        typeAtt={typeAtt}
        defaultAtt={defaultAtt}
      />

      <HandlerModal />
    </div>
  );
};

const ClaimHistoryAdd = Form.create({
  name: 'modal_form',
  mapPropsToFields() { },
})(AddClaimForm);

export default React.memo(ClaimHistoryAdd, (prev, next) => { });
