import httpClient from '../../components/axiosClient';

const GetDataMoreDetail = async (taskId) => {
  const comId = localStorage.getItem('comId');

  const body = {
    taskId: taskId
  }

  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comId}/moredetail/finddata`, body);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const GetDataMoreDetailTicket = async (reference) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`v4/resource/manager/company/${comCode}/ticket/help-desk/detail/${reference}?callby=web`);
    if (response.status === 200) {
      return response;
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const CreateDataMoreDetail = async (taskId, detail) => {
  const comId = localStorage.getItem('comId');

  const body = {
    taskId: taskId,
    detail: detail
  }

  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comId}/moredetail/createdata`, body);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const UpDateDataMoreDetail = async (taskId, detail) => {
  const comId = localStorage.getItem('comId');

  const body = {
    detail: detail
  }


  try {
    const response = await httpClient.put(`/v3/task/manager/company/${comId}/moredetail/updatedata/${taskId}`, body);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const UploadImage = async (data) => {
  // console.log('UploadImage data', data)
  const comCode = localStorage.getItem('comCode');

  // const body = {
  //   file: file,
  //   moduleName: name
  // }


  try {
    const response = await httpClient.post(`/v3/resource/manager/company/${comCode}/upload-file/`, data);
    // console.log('UploadImage',response)
    if (response.status === 200) {
      return (response);
    } else {
      return (response);
    }
  } catch (error) {
    // console.log('catchhhhh', error.response)
    return (error.response);
  }
}

const deleteImg = async (data) => {
  const comCode = localStorage.getItem('comCode');

  const body = {
    fileHashList: data,

  }


  try {
    const response = await httpClient.put(`/v3/resource/manager/company/${comCode}/delete-file/hash-list`, body);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}



export { GetDataMoreDetail, CreateDataMoreDetail, UpDateDataMoreDetail, UploadImage, deleteImg, GetDataMoreDetailTicket }

