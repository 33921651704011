import React from 'react';
import { Card } from 'antd';
import cssStyle from './css/card.css';
import styled from 'styled-components';

export default (props) => {


  return (
    <Card
      className="card-component"
      {...props}
    >
      {props.children}
    </Card>
  );
};
