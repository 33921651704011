import React from 'react';
import CustomerForm from './customer-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import { messageLabel } from '../../../../components/message-component';
import CustomTableComponent from '../../components/table';
import PriceForm from './price-form';
import { Button, Divider } from 'antd';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal';

const DetailsTab = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    dataForm,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    setIsOpenQt,
    isOpenQt,
    handleOpenQuotation,
    formCustomerRef,
    isOpenQuotation,
    record,
    handleWarhouseFromPopupScroll,
    wareHouseFromList,
    handleWarhouseToPopupScroll,
    wareHouseToList,
    setNotWarehouseFromId,
    setNotWarehouseToId,
    notWarehouseFromId,
    handleQTYOk,
    setTypeCode,
    setAssignName,
    setTypeEdit,
    pageTable,
    sizeTable,
    setPageTable,
    setSizeTable,
  } = property;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="TFItemCode" defaultMessage="Item Code" />,
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      fixed: 'left',
      width: 200,
    },
    {
      title: <FormattedMessage id="TFItem" defaultMessage="Item" />,
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      width: 250,
    },
    {
      title: <FormattedMessage id="TFLotNoorSerailNo" defaultMessage="Lot No. or Serial No." />,
      key: 'lotOrSerialNo',
      dataIndex: 'lotOrSerialNo',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="TFQTY" defaultMessage="Qty" />,
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      width: 150,
      render: (text, record, index) => (_.get(record, 'qty') ? _.get(record, 'qty') + ' ' + _.get(record, 'uom')   : '-'),
    },
    {
      title: <FormattedMessage id="TFManufacturingDate" defaultMessage="Manufacturing Date" />,
      dataIndex: 'manufacturingDateTxt',
      key: 'manufacturingDateTxt',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="TFExpiredDate" defaultMessage="Expired Date" />,
      key: 'expiredDateTxt',
      dataIndex: 'expiredDateTxt',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="TFRecivedFDA" defaultMessage="FDA" />,
      key: 'fda',
      dataIndex: 'fda',
      width: 150,
    },
    {
      title: <FormattedMessage id="TFRecivedDate" defaultMessage="Recived Date" />,
      key: 'receiveDate',
      dataIndex: 'receiveDate',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="TFRecivedBy" defaultMessage="Recived By" />,
      key: 'receiveBy',
      dataIndex: 'receiveBy',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="TFRemark" defaultMessage="Remark" />,
      key: 'remarkTran',
      dataIndex: 'remarkTran',
      width: 200,
      sorter: true,
      render: (text, record, index) => (_.get(record, 'remarkTran') ? _.get(record, 'remarkTran')  : _.get(record, 'remark') || '-'),
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      width: 350,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const deleteAtt = (value) => {
    const filterSelect = _.filter(attData, (item) => {
      return item.id !== value;
    });
    setAttData(filterSelect);
  };

  const handleEditUploadInside = (value) => {
    handleOpenModalUpload(value);
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button
            style={{ width: 100 }}
            type="link"
            ghost
            onClick={() => {
              handleQTYOk(record);
              setTypeEdit('edit');
            }}
          >
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record)}>
            <FormattedMessage id="btnRemove" defaultMessage="Remove" />
          </Button>
        </div>
      </div>
    );
  };

  const deleteItem = (value) => {
    const filterSelect = _.filter(selectItem, (item) => {
      return _.get(item, 'movementId') !== _.get(value, 'movementId') || _.get(item, 'uomId') !== _.get(value, 'uomId') && _.get(item, 'movementId') === _.get(value, 'movementId')    ;
    });
    setSelectItem(filterSelect);
  };

  const contentActionItem = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button
            style={{ width: 100 }}
            type="link"
            ghost
            onClick={() => {
              handleQTYOk(record);
              setTypeEdit('add');
            }}
          >
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="scroll-sm scroll-height">
      <div className="content-body">
        <CustomerForm
          form={form}
          setDataForm={setDataForm}
          dataForm={dataForm}
          initialMemberList={initialMemberList}
          handleOpen={handleOpen}
          handleOpenQuotation={handleOpenQuotation}
          formCustomerRef={formCustomerRef}
          isOpenQuotation={isOpenQuotation}
          record={record}
          handleWarhouseFromPopupScroll={handleWarhouseFromPopupScroll}
          wareHouseFromList={wareHouseFromList}
          handleWarhouseToPopupScroll={handleWarhouseToPopupScroll}
          wareHouseToList={wareHouseToList}
          setNotWarehouseFromId={setNotWarehouseFromId}
          setNotWarehouseToId={setNotWarehouseToId}
          setTypeCode={setTypeCode}
          setAssignName={setAssignName}
        />
      </div>
      <div style={{ paddingTop: '10px' }}>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelItem" defaultMessage="Item" />
          </span>
          <div>
            {/* {notWarehouseFromId ? ( */}
            <Button_01
              key="add"
              style={{ margin: '0px 0px 0px 10px' }}
              type="primary"
              btnsize="wd_df"
              onClick={() => handleOpen()}
              disabled={notWarehouseFromId ? false : true}
            >
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01>
            {/* ) : null} */}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columns}
            dataSource={selectItem}
            scroll={{ x: true }}
            contentAction={contentAction}
            paginationPage={pageTable}
            setPaginationPage={setPageTable}
            paginationShow={sizeTable}
            setPaginationSize={setSizeTable}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsTab;
