import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLayer, Arrow } from 'react-laag';
import Resource from '../map/popover-resource';

const InfoBox = styled.div`
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 1000;
`;

export default (props) => {
  const {
    resource,
    taskData,
    resourceData,
    stateMain,
    setStateMain,
    resourceId,
    taskStatusData,
    intl,
    openPopoverResource,
    setResourceId,
    thisResourceId,
    onClickTrack,
    setResourceIdChecked,
    resourceIdChecked,
    setTrackingResource,
    setMainResourceMemComId,
    setMainStatusTaskTitle,
    setMainStatusTaskObject,
    setVisibleTaskStatusModal,
    LangCode,
  } = props;
  const [isOpen, setOpen] = useState(resource.memComId === thisResourceId ? true : false);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
  });

  useEffect(() => {
    if (isOpen === false) {
      setResourceId(null);
      setResourceIdChecked(false);
      setTrackingResource(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen === false && resource.memComId === thisResourceId) {
      setTimeout(() => {
        setOpen(true);
      }, 500);
      openPopoverResource(resource);
    }
  }, [thisResourceId]);

  const onClick = (e) => {
    setOpen((prev) => !prev);
    openPopoverResource(resource);
  };

  return (
    <div>
      <img
        id={`resource-marker-${resource.memComId}`}
        {...triggerProps}
        onClick={onClick}
        style={{ zIndex: 1, borderRadius: '50%', width: '40px', height: '40px', border: `3px solid ${resource.statusOnGoingColor}` }}
        src={resource.pathImage}
        alt="Avatar"
        key={resource.memComId}
      />
      {isOpen &&
        renderLayer(
          <InfoBox {...layerProps}>
            <Resource
              key={resource.memComId}
              taskData={taskData}
              resourceData={resourceData}
              resource={resource}
              stateMain={stateMain}
              setStateMain={setStateMain}
              resourceId={resourceId}
              taskStatusData={taskStatusData}
              intl={intl}
              onClickTrack={onClickTrack}
              setMainResourceMemComId={setMainResourceMemComId}
              setMainStatusTaskTitle={setMainStatusTaskTitle}
              setMainStatusTaskObject={setMainStatusTaskObject}
              setVisibleTaskStatusModal={setVisibleTaskStatusModal}
              LangCode={LangCode}
            />
            <Arrow {...arrowProps} />
          </InfoBox>
        )}
    </div>
  );
};
