import React, { useEffect, useState } from 'react';
import { Col, Form, Icon, Modal, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import { messageLabel } from '../../../../components/message-component';
import moment from 'moment';
import { getItemCheckStock, getQuotationById, getQuotationList } from '../../../../controllers/quotation/quotation-api-new';
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal';
import CustomTableComponent from '../../components/table';
import Input from '../../../../components/v2/input';
import { v4 as uuidv4 } from 'uuid';

const SelectQuotationModal = ({ form, title, visible, onOk, onCancel, setDataForm, dataForm, setSelectItem }) => {
  const [reportDataApproved, setReportDataApproved] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    orderBy: 'quotationNo',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    saleOrderNo: '',
    customerId: undefined,
    rangePickerDate: [moment().startOf('month'), moment().endOf('month')],
  });

  console.log('dataFormCus', dataForm);

  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);

  const intl = useIntl();

  useEffect(() => {
    setReportDataApproved((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tab: 'saleorderdetail',
        // startDate: moment(_.get(reportDataApproved, 'rangePickerDate[0]')).format('YYYY-MM-DD'),
        // endDate: moment(_.get(reportDataApproved, 'rangePickerDate[1]')).format('YYYY-MM-DD'),
        startDate: '',
        endDate: '',
        paging: pageTable,
        rowsPerPages: sizeTable,
        // paging: _.get(reportDataApproved, 'paginationPage'),
        // rowsPerPages: _.get(reportDataApproved, 'paginationSize'),
        orderBy: _.get(reportDataApproved, 'fieldSort'),
        orderType: _.get(reportDataApproved, 'orderSort'),
        filter: {
          quotationNo: _.get(reportDataApproved, 'quotationNo'),
          saleOrderNo: _.get(reportDataApproved, 'saleOrderNo'),
          customerId: _.get(dataForm, 'customerId') ? _.get(dataForm, 'customerId') : undefined,
        },
      };
      const response = await getQuotationList(payload);
      if (_.get(response, 'status.code') === 200) {
        setReportDataApproved((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.data'),
          quotationTotal: _.get(response, 'data.total'),
        }));
      }
    };
    setReportDataApproved((prev) => ({ ...prev, loading: false }));
    if (visible === true) {
      getApi();
    }
  }, [
    visible,
    _.get(reportDataApproved, 'rangePickerDate[0]'),
    _.get(reportDataApproved, 'rangePickerDate[1]'),
    _.get(reportDataApproved, 'paginationPage'),
    _.get(reportDataApproved, 'paginationSize'),
    _.get(reportDataApproved, 'fieldSort'),
    _.get(reportDataApproved, 'orderSort'),
    _.get(reportDataApproved, 'quotationNo'),
    _.get(reportDataApproved, 'saleOrderNo'),
    _.get(reportDataApproved, 'customerName'),
    pageTable,
    sizeTable,
  ]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('quotationLabelQuotationNo', 'Quotation No'),
      dataIndex: 'quotationNo',
      key: 'quotationNo',

      fixed: 'left',
      width: 150,
    },
    {
      title: messageLabel('quotationLabelCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',

      fixed: 'left',
      // width: 200,
    },
    {
      title: messageLabel('quotationLabelItem', 'Item'),
      dataIndex: 'totalItem',
      key: 'totalItem',

      render: (text, record, index) => _.get(record, 'totalItem'),
    },
    {
      title: messageLabel('quotationLabelTotal', 'Total'),
      dataIndex: 'total',
      key: 'total',

      width: 200,
      align: 'right',
      render: (text, record, index) =>
        _.get(record, 'total') ? (
          <span>
            {numberFormatDecimal(_.get(record, 'total'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
          </span>
        ) : null,
    },
    {
      title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
      dataIndex: 'date',
      key: 'date',

      render: (text, record, index) =>
        _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
    },
  ];

  const handleClose = () => {
    onCancel();
  };

  const onSelectQuotationRow = async (value) => {
    handleReplace(value);
  };

  const handleReplace = (value) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblReplace', defaultMessage: 'Are you sure you want to replace ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        if (value) {
          try {
            const response = await getQuotationById(value.quotationId);
            console.log('onSelectQuotationRow', _.get(response, 'data[0]'));
            console.log('onSelectQuotationRowValue', value);
            if (_.get(response, 'status.code')) {
              const itemNew = _.get(response, 'data.data[0].itemList') || [];
              setDataForm((prev) => ({
                ...prev,
                ..._.get(response, 'data.data[0]'),
                quotationObj: _.get(response, 'data.data[0]'),
                customerContact: _.get(response, 'data.data[0].customerId'),
                address: _.get(response, 'data.data[0].customerAddressBookName'),
                fullAddress: _.get(response, 'data.data[0].customerAddressBookFullAddress'),
              }));

              if (_.size(itemNew) > 0) {
                const itemNewKey = _.map(itemNew, (o) => ({ itemMasterId: o.itemId, qty: o.qty }));
                const responseItem = await getItemCheckStock({ itemMasterList: itemNewKey });
                console.log('responseItem1', _.get(responseItem, 'data'));
                const itemCheck = _.get(responseItem, 'data') || []
                console.log('responseItem2', itemCheck ,itemNew);

                let newItem = []
                newItem = itemNew.map(obj => {
                  const index = itemCheck.findIndex(el => el["itemMasterId"] == obj["itemId"]);
                  const { isOutOfStock } = index !== -1 ? itemCheck[index] : {};
                  return {
                     ...obj,
                     isOutOfStock
                  };
               });

               console.log('responseItem3', itemCheck ,newItem);

                const itemList = _.map(newItem, (o, i) => ({
                  ...o,
                  index: i + 1,
                  promotionCampaign: o.campaignName,
                  promotionCampaignId: o.campaignId,
                  credits: o.credit,
                  discountPrice: o.discount,
                  discountPercentage: o.discountPercent,
                  pricePerUnit: o.price,
                  qty: _.toString(o.qty),
                  remark: _.toString(o.remark),
                  itemMasterId: o.itemId,
                  itemCode: o.itemCode,
                  itemName: o.itemName,
                  totalPrice: _.toString(o.total),
                  uomId: _.toString(o.unit),
                  uomName: _.toString(o.unitName),
                  uuid: uuidv4(),
                }));
                console.log('responseItem4', itemList, itemNew);
                setSelectItem(itemList);
              }
              onCancel();
            }
          } catch (error) {}
        }
      },
      onCancel() {},
    });
  };

  const handleChange = (value, key) => {
    setReportDataApproved((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'quotationLabelSelectQuotationNO', defaultMessage: 'Select Quotation No.' })}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={900}
      zIndex={1000}
      centered={true}
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row style={{ marginBottom: '15px' }}>
        <Col span={8}>
          <Input
            // style={{ marginBottom: 8 }}
            placeholder={intl.formatMessage({ id: 'quotationSearchQuotationNOPlaceHolder', defaultMessage: 'Search Quotation No.' })}
            prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
            onChange={(e) => handleChange(e.target.value, 'quotationNo')}
            autoComplete="off"
          />
        </Col>
      </Row>
      <CustomTableComponent
        columns={columns}
        rowKey={(record) => record.quotationId}
        dataSource={_.get(reportDataApproved, 'quotationList')}
        // scroll={{ x: true }}
        total={_.get(reportDataApproved, 'quotationTotal')}
        role={false}
        onRow={false}
        onRowClick={(o) => onSelectQuotationRow(o)}
        paginationPage={pageTable}
        setPaginationPage={setPageTable}
        paginationShow={sizeTable}
        setPaginationSize={setSizeTable}
      />
    </Modal>
  );
};

SelectQuotationModal.defaultProps = {
  // const intl = useIntl();
  // title: ,
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalSelectQuotationForm = Form.create({ name: 'select_qt_Form' })(SelectQuotationModal);

export default ModalSelectQuotationForm;
