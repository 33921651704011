import React, { useEffect, useState } from 'react'
import style from './css/summary-invoice.css'
import { Row, Col, Input, Select } from 'antd'
import _ from 'lodash';
import SelectDiscount from './discount-select';
import SelectTax from './tax-select';
import NumberFormat from 'react-number-format';
import { useIntl, FormattedMessage } from 'react-intl';


const { TextArea } = Input;
const { Option } = Select;
export default ({ tax,
  discount,
  dataSelection,
  setRemark,
  setTaxID,
  setDiscountID,
  refDiscount,
  refTax,
  remark,
  textBath,
  setTextBath,
  defaultDiscount,
  defaultTax,

  subTotal,
  discountPrice,
  subTotalLess,
  taxPrice,
  totalPrice,

  setSubTotal,
  setDiscountPrice,
  setSubTotalLess,
  setTaxPrice,
  setTotalPrice,

  persentDiscount,
  setPersentDiscount,
  persentTax,
  setpersentTax,

}) => {
  
  const intl = useIntl()
  const totalDataSelectTion = dataSelection && dataSelection.map(item => { return item.total })
  const sumTotal = totalDataSelectTion && totalDataSelectTion.reduce((a, b) => a + b, 0)




  useEffect(() => {
    if (persentTax == undefined && persentDiscount == undefined) {
      setSubTotal(sumTotal)
      setSubTotalLess(sumTotal)
      setTotalPrice(sumTotal);
    }
    else {
      if (persentTax !== undefined && persentDiscount == undefined) {
        setSubTotal(sumTotal)
        setSubTotalLess(sumTotal)
        setTaxPrice(_.ceil(((sumTotal * persentTax) / 100), 2));
        setTotalPrice(_.ceil((sumTotal + ((sumTotal * persentTax) / 100)), 2));
      }
      else if (persentTax == undefined && persentDiscount !== undefined) {
        if (textBath == true) {
          if (sumTotal == 0) {
            setSubTotal(sumTotal)
            setSubTotalLess(sumTotal)
            setTaxPrice(sumTotal);
            setTotalPrice(sumTotal);
          } else {
            setSubTotal(sumTotal)
            setSubTotalLess(sumTotal - discountPrice)
            setTotalPrice(sumTotal - discountPrice);
          }

        } else {
          setSubTotal(sumTotal)
          setDiscountPrice(_.ceil(((sumTotal * persentDiscount) / 100), 2))
          setSubTotalLess(sumTotal - _.ceil(((sumTotal * persentDiscount) / 100), 2))
          setTotalPrice(sumTotal - _.ceil(((sumTotal * persentDiscount) / 100), 2));
        }

      }
      else if (persentTax !== undefined && persentDiscount !== undefined) {
        if (textBath == true) {
          if (sumTotal == 0) {
            setSubTotal(sumTotal)
            setSubTotalLess(sumTotal)
            setTaxPrice(sumTotal);
            setTotalPrice(sumTotal);
          } else {
            setSubTotal(sumTotal)
            setSubTotalLess(sumTotal - discountPrice)
            setTaxPrice(_.ceil((((sumTotal - discountPrice) * persentTax) / 100), 2));
            setTotalPrice((sumTotal - discountPrice) + _.ceil((((sumTotal - discountPrice) * persentTax) / 100), 2));
          }

        } else {
          setSubTotal(sumTotal)
          setDiscountPrice(_.ceil(((sumTotal * persentDiscount) / 100), 2))
          setSubTotalLess((sumTotal - _.ceil(((sumTotal * persentDiscount) / 100), 2)))
          setTaxPrice(_.ceil((((sumTotal - ((sumTotal * persentDiscount) / 100)) * persentTax) / 100), 2));
          setTotalPrice((sumTotal - _.ceil(((sumTotal * persentDiscount) / 100), 2)) + _.ceil((((sumTotal - ((sumTotal * persentDiscount) / 100)) * persentTax) / 100), 2));
        }
      }
    }
  }, [sumTotal])

  const handleSelectDiscount = (value) => {
    if (value && value !== undefined) {
      const filterDiscountPirce = discount && discount.filter((col) => value.includes(col.discountId));
      const discountPirce = filterDiscountPirce && filterDiscountPirce !== undefined ? filterDiscountPirce[0].percentage : undefined
      const Bath = filterDiscountPirce && filterDiscountPirce !== undefined ? filterDiscountPirce[0].isBath : undefined
      setPersentDiscount(discountPirce)
      if (Bath == true) {
        setDiscountPrice(0);
        setSubTotalLess(sumTotal)
        if (persentTax == undefined) {
          setTotalPrice(sumTotal)

        } else {
          setTaxPrice(_.ceil(((sumTotal * persentTax) / 100), 2))
          setTotalPrice(sumTotal + _.ceil(((sumTotal * persentTax) / 100), 2))
        }
      }
      else {
        setDiscountPrice(_.ceil(((sumTotal * discountPirce) / 100), 2));
        setSubTotalLess(sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2))
        if (persentTax == undefined) {
          setTotalPrice(sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2))

        } else {
          setTaxPrice(_.ceil((((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) * persentTax) / 100), 2))
          setTotalPrice((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) + _.ceil((((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) * persentTax) / 100), 2))
        }

      }


      setDiscountID(value)
      setTextBath(Bath)

    }


  }



  const handleSelectTax = (value) => {
    if (value && value !== undefined) {
      setTaxID(value)
      const filterTaxPirce = tax && tax.filter((col) => value.includes(col.taxId));
      const taxPirce = filterTaxPirce && filterTaxPirce !== undefined ? filterTaxPirce[0].percentage : undefined
      setpersentTax(taxPirce);
      if (persentDiscount == undefined) {
        setTaxPrice(_.ceil(((sumTotal - discountPrice) * taxPirce / 100), 2));
        setTotalPrice(sumTotal + _.ceil(((sumTotal - discountPrice) * taxPirce / 100), 2))
      }
      else {
        if (textBath == true) {
          setTaxPrice(_.ceil(((subTotalLess * taxPirce) / 100), 2));
          setTotalPrice((subTotalLess + _.ceil(((subTotalLess * taxPirce) / 100), 2)));

        } else {
          setTaxPrice(_.ceil(((subTotalLess * taxPirce) / 100), 2));
          setTotalPrice(subTotalLess + _.ceil(((subTotalLess * taxPirce) / 100), 2));

        }
      }
    }

  }

  const handleOnChange = (e) => {
    setRemark(e.target.value)
  }

  const handleOnChangeInput = (e) => {
    if (e.target.value) {
      setDiscountPrice(parseFloat(e.target.value));
      setSubTotalLess(sumTotal - e.target.value)
      if (persentTax == undefined) {
        setTotalPrice(sumTotal - e.target.value)

      } else {
        setTaxPrice(_.ceil((((sumTotal - e.target.value) * persentTax) / 100), 2))
        setTotalPrice((sumTotal - e.target.value) + _.ceil((((sumTotal - e.target.value) * persentTax) / 100), 2))
      }
    }
    else {
      setDiscountPrice(0);

    }


  }

  const handleKeyDownNumber = (e) => {

    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 0) {
        e.preventDefault();
      }
      else {
        return true;
      }
    }
    else {
      e.preventDefault();
    }
  }





  return (
    <div className='div-style-summary'>
      <Row>
        <Col span={12}>
          <p><FormattedMessage id="modalInvoiceSummaryRemarkText" defaultMessage="Remark" /></p>
          <TextArea rows={5} onChange={(e) => handleOnChange(e)} autoSize={{ minRows: 5, maxRows: 5 }} value={remark} maxLength={250}/>
        </Col>
        <Col span={12} className='align-text-center'>

          <Row>
            <Col span={12} className='align-text-right'>
              <Row className='margin-row'>
                <span><FormattedMessage id="modalInvoiceSummarySubtotalText" defaultMessage="Subtotal" /></span>
              </Row>

              <Row className='margin-row'>
                <div style={{ width: '60px', display: 'flex', paddingLeft: '100px' }}>
                  <span style={{ marginRight: '8px', marginTop: "7px" }}><FormattedMessage id="modalInvoiceSummaryDiscountText" defaultMessage="Discount" /></span>
                  <SelectDiscount
                    discount={discount}
                    handleSelectDiscount={handleSelectDiscount}
                    ref={refDiscount}
                    defaultDiscount={defaultDiscount}
                  />
                </div>
              </Row>

              <Row className='margin-row'>
                <span><FormattedMessage id="modalInvoiceSummarySubtotalLessText" defaultMessage="Subtotal Less Discount" /></span>
              </Row>

              <Row className='margin-row'>
                <div style={{ width: '60px', display: 'flex', paddingLeft: '134px' }}>
                  <span style={{ marginRight: '8px', marginTop: "7px" }}><FormattedMessage id="modalInvoiceSummaryTaxText" defaultMessage="Tax" /></span>
                  <SelectTax
                    tax={tax}
                    handleSelectTax={handleSelectTax}
                    ref={refTax}
                    defaultTax={defaultTax}
                  />
                </div>
              </Row>

              <Row className='margin-row'>
                <span><FormattedMessage id="modalInvoiceSummaryTotalText" defaultMessage="Total" /></span>
              </Row>

            </Col>

            <Col span={9} className='align-text-right'>
              <Row className='margin-row-summary'>
                <NumberFormat value={_.floor(subTotal,2).toFixed(2)} displayType={'text'} thousandSeparator={true} 
                suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
              </Row>
              {textBath && textBath == true ? (
                <Row className='margin-row-summary'>
                  <Input className='input-bath' placeholder='0.00' onChange={handleOnChangeInput} type="text" onKeyDown={(e) => handleKeyDownNumber(e)} autoComplete="off" ></Input>
                  <span> <FormattedMessage id="invoicetextBaht" defaultMessage="Baht" /></span>
                </Row>
              ) : (
                <Row className='margin-row-summary'>
                  <NumberFormat value={_.floor(discountPrice,2).toFixed(2)} displayType={'text'} thousandSeparator={true} 
                  suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
                </Row>
              )
              }

              <Row className='margin-row-summary'>
                <NumberFormat value={_.floor(subTotalLess,2).toFixed(2)} displayType={'text'} thousandSeparator={true} 
                 suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
              </Row>



              <Row className='margin-row-summary'>
                <NumberFormat value={_.floor(taxPrice,2).toFixed(2)} displayType={'text'} thousandSeparator={true} 
                suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
              </Row>

              <Row className='margin-row-summary'>
                <NumberFormat value={_.floor(totalPrice, 2).toFixed(2)} displayType={'text'} thousandSeparator={true} 
                 suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
              </Row>

              {/* <Calculate subtotal={sumTotal} discount={discountPrice} tax={taxPrice} /> */}

            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
