import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Icon,
  Row,
  Col,
  Modal,
  Form,
  DatePicker,
  Select,
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import { ShiftContext } from './shift-context';
import { PageSettings } from '../../../config/page-settings';
import Provider from '../provider';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import stylesShiftIndex from './css/index.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ModalFormComponent = ({ formData, dateStart, setDateStart, dateEnd, setDateEnd, visible, onCancel, onCreate, statePlanning, rangePickerDisabled, shiftsType, form, disableBtnAddShift }) => {
  const { getFieldDecorator } = form;
  const intl = useIntl();
  const appGetlang = useAppContext();
  const langValueState = _.get(appGetlang, 'state.langValue');
  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';

  moment.locale(langValueState);

  let formDataCheck = formData === undefined ? '' : formData;
  let shiftTypeRule = statePlanning && statePlanning.shiftTypeRule ? statePlanning.shiftTypeRule : [];
  let thisYearMonth = statePlanning && statePlanning.thisYearMonth ? statePlanning.thisYearMonth : '';

  // const [check, setCheck] = useState();



  // useEffect(() => {
  //   if (rangePickerDisabled === 'true') {
  //     setCheck(disabledDate())
  //   } else {
  //   }
  // }, []);

  const disabledDate = (current) => {
    return thisYearMonth !== current.format('YYYY-MM') ? true : false;
  }

  const onSelectRangePicker = (date, dateString) => {
    form.setFieldsValue({['range_picker']: date});
    setDateStart(date[0]);
    setDateEnd(date[1]);
  }

  return (
    <Modal
      className="shift-modal-schedule"
      title={intl.formatMessage({
        id: 'orgShiftPlanningModalTitleAdd',
        defaultMessage: 'Add Shift',
      })}
      centered
      width={611}
      visible={visible}
      okText="Submit"
      onCancel={onCancel}
      onOk={onCreate}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={onCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          fontsize="sm"
          btnsize="wd_df"
          type="primary"
          onClick={onCreate}
          disabled={disableBtnAddShift || false}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form>
        <Row gutter={[16]}>
          <Col span={8}>
            <div className="shift-modal-avatar">
              <Avatar size={114} icon="user" src={formDataCheck.pathImage || ''} />
            </div>
          </Col>
          <Col span={16}>
            <div>
              <div className="shift-modal-div">
                <span className="shift-modal-text-fullname">{formDataCheck.name || ''}</span>
              </div>
              <div className="shift-modal-div">
                <Icon className="shift-modal-icon" type="mail" />
                <span className="shift-modal-text">{formDataCheck.email || ''}</span>
              </div>
              <div className="shift-modal-div">
                <Icon className="shift-modal-icon" type="phone" />
                <span className="shift-modal-text">{formDataCheck.phone || ''}</span>
              </div>
              <div className="shift-modal-form">
                <Form.Item>
                  {getFieldDecorator('range_picker', {
                    initialValue: [dateStart, dateStart],
                    // initialValue: [moment(dateStart, dateFormat), moment(dateEnd, dateFormat)],
                    rules: [
                      {
                        type: 'array',
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgShiftPlanningValidateTime',
                          defaultMessage: 'Please select time.',
                        }),
                      },
                    ],
                  })(
                    <div className="shift-modal-form-item">
                      <Icon className="shift-modal-icon" type="calendar" />
                      <RangePicker
                        className="shift-modal-form-rangePicker"
                        placeholder={[
                          intl.formatMessage({
                            id: 'orgShiftPlanningModalHintStartDate',
                            defaultMessage: 'Please input start date',
                          }),
                          intl.formatMessage({
                            id: 'orgShiftPlanningModalHintEndDate',
                            defaultMessage: 'Please input end date',
                          }),
                        ]}
                        onChange={onSelectRangePicker}
                        value={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                        // defaultValue={[moment(dateStart, dateFormat), moment(dateStart, dateFormat)]}
                        format={dateFormat}
                        allowClear={false}
                        disabledDate={rangePickerDisabled === 'true' ? disabledDate : false}
                      />
                    </div>,
                  )}
                </Form.Item>
                <div className="shift-modal-form-item">
                  <Icon className="shift-modal-icon" type="menu" />
                  <Form.Item>
                  {getFieldDecorator('shift', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgShiftPlanningModalHintSelectShift',
                          defaultMessage: 'Please select shift',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="shift-modal-form-select"
                      placeholder={intl.formatMessage({
                        id: 'orgShiftPlanningModalHintSelectShift',
                        defaultMessage: 'Please select shift',
                      })}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {shiftsType && shiftsType.map((item, index) => {
                        return <Option key={item.shift_id} value={item.shift_id}>{item.name}</Option>
                      })}
                    </Select>
                  )}
                </Form.Item>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ModalForm = Form.create({
  name: 'modal_form',
  // mapPropsToFields(props) {
  //   const dateFormat = 'ddd, MMM DD YYYY';
  //   let rangePickerValue = props.rangePickerValue && props.rangePickerValue.length >= 0 ? moment(props.rangePickerValue[0]) : '';
  //   // let dateStart = props.dateStart && props.rangePickerValue.length >= 0 ? moment(props.rangePickerValue[0]) : '';

  //   return {
  //     // 'range_picker': Form.createFormField({
  //     //   value: [rangePickerValue, rangePickerValue]
  //     // })
  //     // 'range_picker': Form.createFormField({
  //     //   value: [props.dateStart, props.dateStart]
  //     // })
  //     // 'range_picker': Form.createFormField({
  //     //   value: [moment(props.dateStart, dateFormat), moment(props.dateStart, dateFormat)]
  //     // })
  //   }
  // },
})(ModalFormComponent);

export default ModalForm;
