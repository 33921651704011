import React from 'react';
import '../css/index.css';
import { Row, Icon, Divider, Col, Tag } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';

export default ({ value }) => {
  const { workLogsData } = value
  // const orderByLogs = _.orderBy(workLogsData, ['sequence'], ['desc']);

  const LogsAll = () => {
    const logsData = workLogsData && workLogsData.map((item, index) => <div style={{ marginBottom: '-4px' }}>
      <Row gutter={[18]}>
        <Col span={1} className='padding-col-img'>
          <Icon style={{ fill: '#9a9999', color: '#9a9999' }}
            component={() =>
              <img src={item && item.icon} className='style-icon' />
            } />
        </Col>
        <Col span={23} className='padding-col' >
          {item && item.logType === 'status_task' ?
            (
              <div className='space-text-icon'>
                <span className='name-style'>{item.updatedBy} </span>
                <span className='call-text'>{item.txt01}  </span>
                {item.fromStatus.name === 'New' && item.toStatus.name === 'New' ?
                  <>
                    <Tag className='status-tag' color='#ccc'>Blank</Tag>
                    <span className='call-text'>{item.txt02} </span>
                    <Tag className='status-tag' color={item.toStatus.color}>{item.toStatus.name}</Tag>
                  </>
                  :
                  <>
                    <Tag className='status-tag' color={item.fromStatus.color}>{item.fromStatus.name}</Tag>
                    <span className='call-text'>{item.txt02} </span>
                    <Tag className='status-tag' color={item.toStatus.color}>{item.toStatus.name}</Tag>
                  </>
                }
                <span className='call-text'>{item.createdDateTime}</span>
              </div>
            )
            :
            item && item.logType === 'assign_task' ?
              (
                <div className='space-text-icon'>
                  <span className='name-style'>{item.updatedBy}</span>
                  <span className='call-text'>{item.txt01} </span>
                  <span className='name-style'>{item.assignTo}</span>
                  <span className='call-text'>{item.createdDateTime}</span>
                </div>
              )
              :
              item && item.logType === 'call_log' ?
                (
                  <div className='space-text-icon'>
                    <span className='name-style'>{item.assigneeName}</span>
                    <span className='call-text'>{item.callTxt}</span>
                    <span className='name-style'>{item.customerName}</span>
                    <span className='call-text'><FormattedMessage id="monitorWorkLogOnText" defaultMessage="on" /> {item.callDate}</span>
                    <span className='call-text'>{item.calledTime}</span>
                    <span className='call-text'><FormattedMessage id="monitorWorkLogForText" defaultMessage="for" /> {item.duration}</span>
                  </div>
                )
                :
                item && item.logType === 'travel_expenses' ?
                  (
                    <div className='space-text-icon'>
                      <span className='name-style'>{item.updatedBy}</span>
                      <span className='call-text'>{item.txt01} </span>
                      <span className='name-style'>{item.assigneeName}</span>
                      <span className='call-text'>{item.txt02} </span>
                      <Tag className='status-tag' color={item.fromStatus.color}>{item.fromStatus.name}</Tag>
                      <span className='call-text'>{item.txt03} </span>
                      <Tag className='status-tag' color={item.toStatus.color}>{item.toStatus.name}</Tag>
                      <span className='call-text'>{item.createdDateTime} </span>
                    </div>
                  )
                  :
                  item && item.logType === 'update_task_info' ?
                    (
                      <div className='space-text-icon'>
                        <span className='name-style'>{item.assigneeName}</span>
                        <span >{item.txt01} </span>
                        <span className='name-style-lognew'>{item.from} </span>
                        <span >{item.txt02} </span>
                        <span className='name-style-lognew'>{item.to} </span>
                        <span >{item.createdDateTime} </span>
                      </div>
                    )
                    :
                    null
          }
        </Col>
      </Row>
      <Divider className='space-dividerr' />
    </div>)

    return (
      <div>
        {logsData}
      </div>
    )
  }


  return (
    <div>
      <LogsAll />
    </div>
  )
}