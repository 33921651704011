import React, { useState, useEffect } from 'react'
import { AutoComplete, Form } from 'antd'
import { useIntl } from 'react-intl'
import { getCustomer } from '../../controllers/customer-vendor/customer-vendor'
import _ from 'lodash'
import LabeRequireForm from '../label-required-form'

const { Option } = AutoComplete

const LoadmoreCustomer = (props) => {
  const intl = useIntl()
  const { form, data, setData, required, initialId, initialName, visible, notSpec } = props
  const getFieldDecorator = form ? form.getFieldDecorator : null
  const [customerData, setCustomerData] = useState({})
  const [customerDataMaster, setCustomerDataMaster] = useState({})
  const [customerlist, setCustomerlist] = useState([])
  const [customerListMaster, setCustomerListMaster] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [firstStep, setFirstStep] = useState(false)

  useEffect(() => {
    if (initialId && visible) {
      setSearch(initialName)
      setFirstStep(true)
    } else {
      setSearch("")
      setFirstStep(false)
      setCustomerData({})
      setCustomerDataMaster({})
      setCustomerlist([])
      setCustomerListMaster([])
      setPage(1)
    }
  }, [initialId, visible])


  useEffect(() => {
    if (firstStep) getCustomerData()
  }, [page, firstStep])


  const getCustomerData = async () => {
    const body = {
      searchBy: "customerName",
      searchVal: search,
      page: page,
      limit: 20,
    }
    try {
      const response = await getCustomer(body)
      setCustomerData(response);
      setCustomerDataMaster(response)
      setCustomerlist([...customerlist, ..._.get(response, 'customers', [])]);
      setCustomerListMaster([...customerListMaster, ..._.get(response, 'customers', [])])
    } catch (error) {
      console.log("error", error)
    }

  }

  const handleSelect = (value, option) => {
    if (form) {
      form.setFieldsValue({ customer: value });
      setData(_.get(option, 'props.data'));
    } else {
      setData((prev) => ({ ...prev, customer: value }))
    }
  }

  const handleChange = (value) => {
    const findCustomer = _.find(customerlist, (item) => { return item.customerName === value || item.customerId === value })
    setData((prev) => ({ ...prev, customer: findCustomer, customerSearch: value }))
  }

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (_.get(customerData, 'allCustomers') !== _.size(customerlist)) setPage(page + 1)
    }
  };

  const handleSearch = _.debounce(async (value) => {
    setSearch(value)
    if (value && value !== "") {
      const body = {
        searchBy: "customerName",
        searchVal: value,
        page: 1,
        limit: 20,
      }
      const response = await getCustomer(body)
      setCustomerData(response);
      setCustomerlist(_.get(response, 'customers', []));
    } else {
      setCustomerData(customerDataMaster)
      setCustomerlist(customerListMaster)
    }
  }, 500)

  console.log("includes", _.includes(search, 'ไม่กำหนด'))

  return (
    <Form.Item onClick={() => setFirstStep(true)} label={
      <LabeRequireForm
        text={intl.formatMessage({ id: 'customerHeader', defaultMessage: 'Customer' })}
        req={required}
      />
    }>
      {
        form ? getFieldDecorator('customer', {
          initialValue: initialId,
          rules: [
            {
              required: required,
              message: intl.formatMessage({
                id: 'customerValidate',
                defaultMessage: 'Please Select Customer',
              }),
            },
          ],
        })(
          <AutoComplete
            onSelect={handleSelect}
            onPopupScroll={handleScroll}
            onSearch={handleSearch}
            placeholder={intl.formatMessage({ id: 'customerPlaceholder', defaultMessage: 'Select Customer' })}
          >
            {
              search && !_.includes(search, 'ไม่กำหนด') ? null : (
                <Option key="notSpecified" value="notSpecified" data={{ id: "notSpecified" }}>
                  ไม่กำหนด
                </Option>
              )
            }
            {
              _.map(customerlist, (item) => (
                <Option key={_.get(item, 'customerId')} value={_.get(item, 'customerId')} data={item}>
                  {_.get(item, 'customerName')}
                </Option>
              ))
            }
          </AutoComplete>
        )
          : (
            <AutoComplete
              // onSelect={handleSelect}
              onChange={handleChange}
              onPopupScroll={handleScroll}
              onSearch={handleSearch}
              placeholder={intl.formatMessage({ id: 'customerPlaceholder', defaultMessage: 'Select Customer' })}
              value={_.get(data, 'customerSearch', "")}
              allowClear={true}
            >
              {
                search && !_.includes(search, 'ไม่กำหนด') || notSpec ? null : (
                  <Option key="notSpecified" value="notSpecified" data={{ id: "notSpecified" }}>
                    ไม่กำหนด
                  </Option>
                )
              }
              {
                _.map(customerlist, (item) => (
                  <Option key={_.get(item, 'customerId')} value={_.get(item, 'customerId')} data={item}>
                    {_.get(item, 'customerName')}
                  </Option>
                ))
              }
            </AutoComplete>
          )
      }

    </Form.Item>
  )
}

LoadmoreCustomer.defaultProps = {
  required: true,
}


export default LoadmoreCustomer
