import React, { useState, useEffect } from 'react';
import Card from '../../../components/v2/card';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import { Divider } from 'antd';
import { assetHistory } from '../../../controllers/asset-resource/asset-controller';
import _ from 'lodash';
import ViewAssetHistory from './asset-modal/viewassethistory';

const AssetHistory = (props) => {

  const { assetHistoryData, assetNo } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    console.log('------- useEffect -------');
    console.log(visible);
  }, [visible]);

  const handleOpenModal = () => {
    setVisible(true);
  }

  const handleOkModal = () => {
    setVisible(false);
  }

  const handleCancelModal = () => {
    setVisible(false);
  }

  const underline = (
    <div >
      <Divider />
    </div>
  )



  return (
    <div>
      <Card
        title={<FormattedMessage id="AssetHistoryTitle" defaultMessage="Asset History" />}
        extra={
          <div className="extra-user-details">
            <div>
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleOpenModal}>
                <FormattedMessage id="btnViewAll" defaultMessage="View All" />
              </Button01>
            </div>
          </div>
        }
      >
        {_.map(assetHistoryData, (item, index) => {
          console.log("assetHistoryData", assetHistoryData)
          return (
            <div key={`${index}`}>
              <div>{item.OverviewTxtRow1 || '-'}</div>
              <span>
                <div>{item.OverviewTxtRow2 || '-'}</div>
              </span>
              <div>{item.txtDisplayRow1[1].value || '-'}&nbsp;{item.txtDisplayRow1[2].value || '-'}</div>

              {index === (assetHistoryData.length - 1) ? null : underline}

            </div>
          );
        })}
      </Card>

      <ViewAssetHistory
        visible={visible}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        assetNo={assetNo}
      />
    </div>
  );
};
export default AssetHistory;
