import React from 'react';
import '../css/index.css';
import { Modal, Row, Col, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import SearchWarehouse from './component/warehouse';
import SearchItemGroup from './component/item-group';
import SearchItemType from './component/item-type';
import SearchItemBrand from './component/item-brand';

const SearchItemRequest = (props) => {
  const { visible, setVisible, warehouse, itemBrand, itemGroup, itemType } = props;
  const intl = useIntl();

  const handleSave = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    warehouse.setWarehouseSelect([]);
    warehouse.setWarehouseInterminate(false);
    warehouse.setWarehouseCheckAll(false);
    itemGroup.setItemGroupSelect([]);
    itemGroup.setItemGroupInterminate(false);
    itemGroup.setItemGroupCheckAll(false);
    itemType.setItemTypeSelect([]);
    itemType.setItemTypeInterminate(false);
    itemType.setItemTypeCheckAll(false);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'searchItemRequest', defaultMessage: 'Search Item' })}
      width={1063}
      centered={true}
      bodyStyle={{ padding: '16px' }}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Spin spinning={warehouse ? false : true}>
        <Row gutter={[32]} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Col span={6} style={{ paddingLeft: '0px', paddingRight: '8px' }}>
            <SearchWarehouse warehouse={warehouse} />
          </Col>
          <Col span={6} style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <SearchItemBrand itemBrand={itemBrand} />
          </Col>
          <Col span={6} style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <SearchItemGroup itemGroup={itemGroup} />
          </Col>
          <Col span={6} style={{ paddingLeft: '8px', paddingRight: '0px' }}>
            <SearchItemType itemType={itemType} />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default SearchItemRequest;
