import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Icon, DatePicker } from 'antd'
import moment from 'moment';
import SelectCustomer from '../../../../components/selectCustomer';
import '../css/action.css'

export default (props) => {
  const { handleChangeDateStart, handleChangeDateEnd, startDate, endDate, setCustomerListView, LangCode } = props;
  const intl = useIntl();
  const dateFormat = 'ddd, MMM DD YYYY';

  return (
    <div className='action-select-date-list-monitor'>
      <div className='action-select-datepicker-list-div' >
        <p className='action-select-datepicker-list-title'><FormattedMessage id={`filterTaskStartDate${LangCode}`} defaultMessage='Start Date' /></p>
        <DatePicker
          className='action-select-datepicker-list'
          format={dateFormat}
          allowClear={false}
          onChange={handleChangeDateStart}
          value={moment(startDate, dateFormat)}
        />
      </div>
      <div className='action-select-datepicker-list-div'>
        <p className='action-select-datepicker-list-title'><FormattedMessage id={`filterTaskEndDate${LangCode}`} defaultMessage='End Date' /></p>
        <DatePicker
          className='action-select-datepicker-list'
          format={dateFormat}
          allowClear={false}
          onChange={handleChangeDateEnd}
          value={moment(endDate, dateFormat)}
        />
      </div>
      <div className='action-select-datepicker-list-div'>
        <p className='action-select-datepicker-list-title'><FormattedMessage id={`filterTaskCustomer${LangCode}`} defaultMessage='Customer' /></p>
        <SelectCustomer setCustomerListView={setCustomerListView} />
      </div>
    </div>
  )
}