import httpClient from '../../components/axiosClient';

export const getApprover = async () => {

  const comCode = localStorage.getItem('comCode');
  const memComId = localStorage.getItem('memComId');

  try {
    const result = await httpClient.get(`/v4/resource/manager/company/${comCode}/approver-organization/${memComId}`);
    if (result.status === 200) {
      return result.data.data;
    }
    else {
      return false;
    }
  }
  catch (e) {
    return false;
  }
}