import React from 'react';
import { Form, Select } from 'antd';
import _ from 'lodash';
import style from './css/detail-invoice.css'

const { Option } = Select;

export default Form.create({
  name: 'customer-form',
})((props) => {
  const { getFieldDecorator } = props.form;
  const { discount, handleSelectDiscount, defaultDiscount} = props;



  return (
    <Form>
      <Form.Item>
        {getFieldDecorator('discount', {
          initialValue: defaultDiscount && `${defaultDiscount[0].discountId}`
        })(
          <Select 
            className='margin-select' 
            onSelect={(value) => handleSelectDiscount(value)}
          >
            {discount && discount.map(item =>
              <Option 
                value={item.discountId} 
                key={item.discountId}
              >
                {item.titleCode}
              </Option>
            )}
          </Select>
        )}
      </Form.Item>
    </Form>
  );
})