import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from './config/page-setting';
import { ShiftSkillSettings } from './shift-type';
import Button1 from '../../../components/v2/button_01';
import Button2 from '../../../components/v2/button_02';
import styled from 'styled-components';
import httpClient from '../../../components/axiosClient';
import _ from 'lodash';
import { Button, Card, Table, Select, Form, Modal, Input, TreeSelect, Row, Col, Checkbox, Divider, InputNumber, Icon } from 'antd';
import cssStyle from './css/shift-type.css';

const { Option } = Select;
let id = 0;

const ButtonAdd = styled(Button)`
  width: 57px;
  font-size: 13px;
  border-radius: 2px;
`;

const ButtonEdit = styled(Button)`
  width: 57px;
  font-size: 13px;
  border-radius: 2px;
  padding: 0 8px;
`;

const LabelRequire = styled.label`
  color: #ff1010;
`;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <LabelRequire>*</LabelRequire> : ''}
    </span>
  );
};

const FormSkill = React.memo(
  (props) => {
    const intl = useIntl();
    const { state, fnc } = useContext(ShiftSkillSettings);

    const {
      form,
      valueSkill,
      index,
      last,
      add,
      remove,
      skillTree,
      onChangeSkills,
      onChangeSkillLevels,
      onChangeMinRequired,
      disabled,
      skillValue,
      shiftSkillRulesValue,
    } = props;
    const { getFieldDecorator, isFieldsTouched, isFieldTouched, validateFields, getFieldValue, getFieldError } = form;

    const shiftSkillRules = getFieldValue('shiftSkillRules');
    const skillRules = Object.assign([], shiftSkillRules);
    const errorText = getFieldError(`shiftSkillRules[${index}].skillId`);

    const [checkDuplicate, setCheckDuplicate] = useState();

    const arrSkillRules =
      skillRules &&
      skillRules.filter((item) => {
        return item.skillId !== undefined;
      });

    const newArrSkillRules = arrSkillRules.map((item) => {
      return {
        skillId: parseInt(item.skillId),
        minSkillLv: item.minSkillLv,
        minReqMem: item.minReqMem,
      };
    });

    useEffect(() => {
      if (newArrSkillRules) {
        const arr = newArrSkillRules && newArrSkillRules.map((ele) => ele.skillId.toString());
        setCheckDuplicate(checkIfDuplicateExists(arr));
      }
    }, [newArrSkillRules]);

    const checkIfDuplicateExists = (shiftId) => {
      return new Set(shiftId).size !== shiftId.length;
    };

    const validateSkillLaevels = (rule, value, callback) => {
      if (value > 100) {
        callback(
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingShiftTypeModalValidateSkillLevels',
              defaultMessage: 'Prohibited skill level is more than 100.',
            })}
          </span>
        );
      } else {
        callback();
      }
    };

    const validateMinRequired = (rule, value, callback) => {
      if (value === 0) {
        callback('Minimum members must not be equal to 0 and not less than 0. !');
      } else {
        callback();
      }
    };

    const handleValidateSkills = (rule, value, callback, index) => {
      _.forEach(newArrSkillRules, (v, i) => {
        if (index !== i) {
          if (value === v.skillId) {
            callback(
              <span>
                {intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeModalValidateSkills', defaultMessage: 'This skill already exists.' })}
              </span>
            );
          }
        }
      });
      callback();
    };

    return (
      <div key={index} className="div-skill-from-body">
        <Form.Item
          className="skills-form-item"
          label={index === 0 ? <FormattedMessage id="orgTabShiftSettingShiftTypeModalLblSkills" defaultMessage="Skills" /> : ''}
          validateStatus={errorText && checkDuplicate === true ? 'error' : ''}
          help={errorText && checkDuplicate === true ? errorText : ''}
        >
          {getFieldDecorator(`shiftSkillRules[${index}].skillId`, {
            initialValue: valueSkill.skill_id,
            rules: [
              {
                validator: (rule, value, callback) => handleValidateSkills(rule, value, callback, index),
              },
            ],
          })(
            <TreeSelect
              style={{ width: '380px' }}
              value={skillValue}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={skillTree}
              placeholder="Please select"
              treeDefaultExpandAll
              // onChange={(value) => onChangeSkills(value, index)}
            />
          )}
        </Form.Item>
        <Form.Item
          className="skill-levels-form-item"
          label={
            index === 0 ? (
              <FormattedMessage id="orgTabShiftSettingShiftTypeModalLblSkillLevels" defaultMessage="Skill Levels (max: 100)" />
            ) : (
              ''
            )
          }
        >
          {getFieldDecorator(`shiftSkillRules[${index}].minSkillLv`, {
            initialValue: valueSkill.min_skill_lv,
            rules: [
              {
                pattern: new RegExp(/^[0-9\b]+$/),
                message: intl.formatMessage({
                  id: 'orgTabShiftSettingRequireOnlyNumber',
                  defaultMessage: 'Value should contain just number.',
                }),
              },
              {
                validator: validateSkillLaevels,
              },
            ],
          })(
            <InputNumber
              placeholder={intl.formatMessage({
                id: 'orgTabShiftSettingShiftTypeModalHintSkillLevels',
                defaultMessage: 'Please enter skill levels',
              })}
              style={{ width: '240px' }}
              onChange={(value) => onChangeSkillLevels(value, index)}
              type="number"
              min={0}
              max={100}
            />
          )}
        </Form.Item>

        <Form.Item className="shift-button-edit" label={index === 0 ? <span className="skills-span">xxxx</span> : ''}>
          {index === last - 1 ? (
            <ButtonAdd onClick={add} disabled={disabled}>
              <FormattedMessage id="orgTabShiftSettingShiftTypeModalBtnAdd" defaultMessage="Add" />
            </ButtonAdd>
          ) : (
            <ButtonEdit onClick={() => remove(valueSkill, index)}>
              <FormattedMessage id="orgTabShiftSettingShiftTypeModalBtnDelete" defaultMessage="Delete" />
            </ButtonEdit>
          )}
        </Form.Item>
      </div>
    );
  },
  (prev, next) => {
    return (
      prev.index === next.index &&
      prev.last === next.last &&
      prev.onChangeMinRequired === next.onChangeMinRequired &&
      prev.onChangeSkillLevels === next.onChangeSkillLevels &&
      prev.onChangeSkills === next.onChangeSkills &&
      prev.shiftSkillRulesValue === next.shiftSkillRulesValue &&
      prev.skillTree === next.skillTree &&
      prev.skillType === next.skillType &&
      prev.skillValue === next.skillValue &&
      prev.skillValue === next.skillValue &&
      prev.valueSkill === next.valueSkill &&
      prev.form === next.form &&
      prev.add === next.add &&
      prev.remove === next.remove &&
      prev.disabled === next.disabled
    );
  }
);

const ModalFormComponent = React.memo(
  (props) => {
    const { visible, onCancel, onCreate, workDay, shiftTypeEdit, setShiftTypeRuleId, setTriggerApi, form } = props;
    const { getFieldDecorator, getFieldValue, validateFields, setFieldsValue, getFieldsValue, resetFields } = form;
    const {
      shiftData,
      skillType,
      skillTree,
      orgId,
      listRoles,
      keyTab,
      refreshTable,
      setRefreshTable,
      switchShiftRules,
      comId,
    } = useContext(PageSettings);
    // const { shiftData, orgId, keyTab, refreshTable, setRefreshTable, switchShiftRules } = useContext(PageSettings);

    const { state, fnc } = useContext(ShiftSkillSettings);
    const intl = useIntl();
    const shiftSkillList = _.get(shiftTypeEdit, 'shiftSkillList');

    const [checkSkills, setCheckSkills] = useState(false);
    const [checkSkillLevels, setCheckSkillLevels] = useState(false);
    const [checkMinRequired, setCheckMinRequired] = useState(false);
    const [disabled, setDisabled] = useState('false');
    const [skillValue, setSkillValue] = useState('');

    const [shiftSkillRulesValue, setShiftSkillRulesValue] = useState([
      {
        skillId: null,
        minSkillLv: null,
        minReqMem: 1,
      },
    ]);

    useEffect(() => {
      if (checkSkills && checkSkillLevels === true) {
        setDisabled('');
      } else {
        setDisabled('');
      }
    }, [checkSkills, checkSkillLevels]);

    useEffect(() => {
      let arrValue = { ...getFieldsValue() };
      if (shiftSkillList?.length >= 1) {
        arrValue['shiftSkillRules'] = [...shiftSkillList];
        setShiftSkillRulesValue(arrValue['shiftSkillRules']);
        form.setFieldsValue(arrValue);
        setShiftTypeRuleId(shiftTypeEdit.shiftTypeRuleId);
      } else if (shiftTypeEdit && shiftSkillList) {
        arrValue['shiftSkillRules'] = [
          ...shiftSkillList,
          {
            skillId: null,
            minSkillLv: null,
            minReqMem: 1,
          },
        ];
        setShiftSkillRulesValue(arrValue['shiftSkillRules']);
        form.setFieldsValue(arrValue);
        setShiftTypeRuleId(shiftTypeEdit.shiftTypeRuleId);
      }
    }, [visible]);

    const add = () => {
      let arrValue = { ...getFieldsValue() };
      arrValue['shiftSkillRules'] = [...arrValue['shiftSkillRules'], { skillId: null, minSkillLv: null, minReqMem: null }];
      setShiftSkillRulesValue(arrValue['shiftSkillRules']);
      form.setFieldsValue(arrValue);

      setCheckSkills(!checkSkills);
      setCheckSkillLevels(!checkSkillLevels);
      setCheckMinRequired(!checkMinRequired);
    };

    const remove = (value, index) => {
      calcMinMember(0, index);
      let arrValue = { ...getFieldsValue() };
      if (!(arrValue['shiftSkillRules'] && arrValue['shiftSkillRules'].length)) return;
      arrValue['shiftSkillRules'].splice(index, 1);
      setShiftSkillRulesValue(arrValue['shiftSkillRules']);
      form.setFieldsValue(arrValue);
    };

    const onChangeSkills = (value, index) => {
      let val = value.toString();
      if (value) {
        setCheckSkills(true);
        setSkillValue(val);
      } else {
        setCheckSkills(false);
      }
    };

    const onChangeSkillLevels = (value, index) => {
      if (value >= 0) {
        setCheckSkillLevels(true);
      } else {
        setCheckSkillLevels(false);
      }
    };

    const onChangeMinRequired = (value, index) => {
      calcMinMember(value, index);
      if (value) {
        setCheckMinRequired(true);
      } else {
        setCheckMinRequired(false);
      }
    };

    const calcMinMember = (value, index) => {
      let val = getFieldValue('shiftSkillRules');
      let count = 0;
      val.forEach((ele, i) => {
        if (index !== i) {
          count = count + (ele.minReqMem ? ele.minReqMem : 0);
        } else {
          count = count + value;
        }
      });
      setFieldsValue({ minMem: count });
    };

    const validateMinimumMembers = (rule, value, callback) => {
      if (value < 0) {
        callback(
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingRequireMustNotBeEqual ',
              defaultMessage: 'Value must not be equal to 0 and not less than 0.',
            })}
          </span>
        );
      } else {
        callback();
      }
    };

    const compareMaximunMembers = (rule, value, callback) => {
      if (value < form.getFieldValue('minMem')) {
        callback(
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingRequireLimitMinimum ',
              defaultMessage: 'Prohibited members less than the minimum membership',
            })}
          </span>
        );
      } else {
        callback();
      }
    };

    const comparezero = (rule, value, callback) => {
      if (value && parseInt(value) === 0) {
        callback(
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingRequireMustNotBeEqual',
              defaultMessage: 'Value must not be equal to 0 and not less than 0.',
            })}
          </span>
        );
      } else {
        callback();
      }
    };

    const continueWorkDay = (rule, value, callback) => {
      if (value) {
        if (value && parseInt(value) === 0) {
          callback(
            <span>
              {intl.formatMessage({
                id: 'orgTabShiftSettingRequireMustNotBeEqual',
                defaultMessage: 'Value must not be equal to 0 and not less than 0.',
              })}
            </span>
          );
        }
        if (value > 1000 && parseInt(value)) {
          callback(
            <span>{intl.formatMessage({ id: 'orgTabShiftSettingRequireMaximumValue', defaultMessage: 'Not more than 1000.' })}</span>
          );
        }
      } else {
        callback();
      }
      callback();
    };

    const handleEdit = async (e) => {
      e.preventDefault();
      state.formRefEdit.validateFields(async (err, values) => {
        if (err) {
          return;
        }

        Modal.confirm({
          className: 'shift-modal-confirm',
          icon: null,
          title: intl.formatMessage({
            id: 'orgTabShiftSettingShiftTypeModalConfirmSaveTeamShift',
            defaultMessage: 'Are you sure to save team shift ?',
          }),
          centered: true,
          okButtonProps: {
            style: {
              width: '96px',
              background: '#6490cf',
              border: '1px solid #6490cf',
              borderRadius: '8px',
              hover: {
                background: '#ffffff',
                color: '#6490cf',
              },
            },
            type: 'primary',
          },
          cancelButtonProps: {
            style: {
              width: '96px',
              border: '1px solid #6490cf',
              borderRadius: '8px',
              backgroundColor: '#ffffff',
              color: '#6490CF',
            },
            type: 'danger',
          },
          async onOk() {
            const { shiftId, minMem, maxMem, shiftSkillRules, priority, continueShiftRestrict, roles, maxContinueWorkDay } = values;

            const shiftSkill = shiftSkillRules.filter((item) => {
              return item.minSkillLv !== undefined;
            });

            // const mapDay = values.work_day.map(item => {
            const mapDay = values.applyOn.map((item) => {
              let found = workDay.findIndex((e) => (e.day.indexOf(item) !== -1 ? true : false));
              let myObj = new Object();
              if (found > -1) {
                myObj[workDay[found].id] = true;
              } else {
                myObj[workDay[found].id] = false;
              }
              return myObj;
            });

            const mon = mapDay.find((item) => item.mon) === undefined ? false : true;
            const tue = mapDay.find((item) => item.tue) === undefined ? false : true;
            const wed = mapDay.find((item) => item.wed) === undefined ? false : true;
            const thu = mapDay.find((item) => item.thu) === undefined ? false : true;
            const fri = mapDay.find((item) => item.fri) === undefined ? false : true;
            const sat = mapDay.find((item) => item.sat) === undefined ? false : true;
            const sun = mapDay.find((item) => item.sun) === undefined ? false : true;

            const newArrShiftSkill = shiftSkill.map((item) => {
              return {
                skillId: String(item.skillId),
                minSkillLv: item.minSkillLv,
                minReqMem: item.minReqMem,
              };
            });

            const body = {
              orgId: orgId,
              shiftId: shiftId,
              minMem: minMem,
              maxMem: maxMem,
              mon: mon,
              tue: tue,
              wed: wed,
              thu: thu,
              fri: fri,
              sat: sat,
              sun: sun,
              shiftSkillRules: newArrShiftSkill,
              priority: priority,
              continueShiftRestrict: continueShiftRestrict,
              roles: roles,
              maxContinueWorkDay: parseInt(maxContinueWorkDay),
              comId: comId,
            };

            try {
              const response = await httpClient.put(
                `/resource/manager/organization/shift-type/rules/${state.shiftTypeRuleId}/update`,
                body
              );
              if (response.status === 200) {
                // setRefreshTable(!refreshTable);
                setTriggerApi((current) => !current);
                // fnc.setTrigerLoading(!state.trigerLoading);
                fnc.setVisibleEdit(false);
                fnc.shiftTypyNotificationSuccess(response.data.message);
              } else {
                fnc.shiftTypeNotificationError(response.data.message);
              }
            } catch (error) {
              fnc.shiftTypeNotificationError(error.response.data.message);
            }
            fnc.setShiftTypeEdit();
            state.formRefEdit.resetFields();
            fnc.setVisibleEdit(false);
          },
          onCancel() {},
        });
      });
    };

    const handleCancelEdit = () => {
      state.formRefEdit.validateFields(async (err, values) => {
        if (err) {
          return;
        }
      });
      resetFields();
      fnc.setShiftTypeEdit();
      fnc.setVisibleEdit(false);
    };

    return (
      <PageSettings.Consumer>
        {() => (
          <Modal
            className="shift-type-modal"
            visible={state.visibleEdit}
            title={<FormattedMessage id="orgTabShiftSettingShiftTypeModalTitle" defaultMessage="Team Shift" />}
            centered="true"
            onCancel={handleCancelEdit}
            onOk={handleEdit}
            width={770}
            footer={[
              <Button2 key="back" btnsize="wd_df" onClick={handleCancelEdit} margin=" unset">
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button2>,
              <Button1 key="submit" type="primary" btnsize="wd_df" onClick={handleEdit}>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button1>,
            ]}
          >
            <Form layout="vertical">
              <div style={{ display: 'flex', marginBottom: '15px' }}>
                <Form.Item
                  className="shift-type-form-item-select"
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblShiftType" defaultMessage="Shift Type" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('shiftId', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingShiftTypeModalRequireShiftType',
                          defaultMessage: 'Please enter your shift type.',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalHintShiftType',
                        defaultMessage: 'Please enter shift type',
                      })}
                      style={{ width: '260px' }}
                      showSearch
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {skillType?.length &&
                        skillType.map((item) => (
                          <Option key={item.shift_id} value={item.shift_id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  className="shift-type-form-item"
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblPriority" defaultMessage="Priority" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('priority', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingShiftTypeModalRequirePriority',
                          defaultMessage: 'Please enter your priority.',
                        }),
                      },
                      {
                        type: 'number',
                        max: 1000,
                        message: intl.formatMessage({ id: 'orgTabShiftSettingRequireMaximumValue', defaultMessage: 'Not more than 1000.' }),
                      },
                      {
                        pattern: new RegExp(/^[0-9\b]+$/),
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingRequireOnlyNumber',
                          defaultMessage: 'Value should contain just number.',
                        }),
                      },
                      {
                        validator: comparezero,
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalHintPriority',
                        defaultMessage: 'Please enter priority',
                      })}
                      type="number"
                      max={1000}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  className="shift-type-form-item"
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblMinimumMembers" defaultMessage="Minimum Members" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('minMem', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingShiftTypeModalRequireMinimumMembers',
                          defaultMessage: 'Please enter your minmum members.',
                        }),
                      },
                      {
                        type: 'number',
                        max: 1000,
                        message: intl.formatMessage({ id: 'orgTabShiftSettingRequireMaximumValue', defaultMessage: 'Not more than 1000.' }),
                      },
                      {
                        pattern: new RegExp(/^[0-9\b]+$/),
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingRequireOnlyNumber',
                          defaultMessage: 'Value should contain just number.',
                        }),
                      },
                      {
                        validator: validateMinimumMembers,
                      },
                    ],
                    // initialValue: shiftTypeEdit.minMem,
                  })(
                    <InputNumber
                      placeholder={intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalHintMinimumMembers',
                        defaultMessage: 'Please enter minmum members',
                      })}
                      // style={{ width: '148px' }}
                      style={{ width: '100%' }}
                      type="number"
                      max={1000}
                      //disabled={true}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  className="shift-type-form-item"
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblMaximunMembers" defaultMessage="Maximun Members" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('maxMem', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingShiftTypeModalRequireMaximunMembers',
                          defaultMessage: 'Please enter your maximun members.',
                        }),
                      },
                      {
                        type: 'number',
                        max: 1000,
                        message: intl.formatMessage({ id: 'orgTabShiftSettingRequireMaximumValue', defaultMessage: 'Not more than 1000.' }),
                      },
                      {
                        pattern: new RegExp(/^[0-9\b]+$/),
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingRequireOnlyNumber',
                          defaultMessage: 'Value should contain just number.',
                        }),
                      },
                      {
                        validator: compareMaximunMembers,
                      },
                    ],
                    // initialValue: shiftTypeEdit.maxMem,
                  })(
                    <InputNumber
                      placeholder={intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalHintMaximunMembers',
                        defaultMessage: 'Please enter maximun members.',
                      })}
                      // style={{ width: '148px' }}
                      style={{ width: '100%' }}
                      type="number"
                      max={1000}
                    />
                  )}
                </Form.Item>
              </div>

              <div style={{ display: 'flex', marginBottom: '15px' }}>
                <Form.Item
                  className="shift-type-shift-work"
                  label={
                    <LabeRequire
                      text={
                        <FormattedMessage
                          id="orgTabShiftSettingShiftTypeModalLblMaximumContinuous"
                          defaultMessage="Maximum continuous shift work"
                        />
                      }
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('maxContinueWorkDay', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingShiftTypeModalValidateMaximumContinuous',
                          defaultMessage: 'Please enter your maximum continuous shift work.',
                        }),
                      },
                      {
                        pattern: new RegExp(/^[0-9\b]+$/),
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingRequireOnlyNumber',
                          defaultMessage: 'Value should contain just number.',
                        }),
                      },
                      {
                        validator: continueWorkDay,
                      },
                    ],
                  })(
                    <Input
                      className="form-item-input-addon"
                      addonAfter={intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeModalTxtMaximumContinuous', defaultMessage: 'Day' })}
                      placeholder={intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalHintMaximumContinuous',
                        defaultMessage: 'Please enter maximum continuous shift work',
                      })}
                      type="number"
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
                <Form.Item
                  className="shift-type-shift-continue"
                  label={
                    <FormattedMessage
                      id="orgTabShiftSettingShiftTypeModalLblContinueShiftRestricted"
                      defaultMessage="Continue Shift Restricted"
                    />
                  }
                >
                  {getFieldDecorator('continueShiftRestrict')(
                    <Select
                      className="continue-shift-restrict"
                      placeholder={intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalHintContinueShiftRestricted',
                        defaultMessage: 'Please enter continue shift restricted',
                      })}
                      mode="multiple"
                      showSearch
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {skillType?.length &&
                        skillType.map((item) => (
                          <Option key={item.shift_id} value={item.shift_id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div style={{ display: 'flex', marginBottom: '15px' }}>
                <Form.Item
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblRole" defaultMessage="Role" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('roles', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabShiftSettingShiftTypeModalValidateRole',
                          defaultMessage: 'Please enter your role.',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="form-item-role"
                      placeholder={intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalHintRole',
                        defaultMessage: 'Please enter role',
                      })}
                      mode="multiple"
                      showSearch
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {listRoles?.length &&
                        listRoles.map((item) => (
                          <Option key={item.roleId} value={item.roleId}>
                            {item.roleName}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <Form.Item
                label={
                  <LabeRequire
                    text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblApplyOn" defaultMessage="Apply on" />}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('applyOn', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalValidateApplyOn',
                        defaultMessage: 'Please checkbox work day.',
                      }),
                    },
                  ],
                })(
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row>
                      {workDay &&
                        workDay.map((workDay) => (
                          <Col key={workDay.id} span={4} style={{ width: 'auto' }}>
                            <Checkbox className="workDay-checkbox-shift-type checkbox-border" value={workDay.day}>
                              {workDay.day}
                            </Checkbox>
                          </Col>
                        ))}
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>

              <Divider className="team-shift-divider" />

              {shiftSkillRulesValue && shiftSkillRulesValue?.length
                ? shiftSkillRulesValue.map((e, i) => (
                    <FormSkill
                      form={form}
                      valueSkill={e}
                      index={i}
                      last={shiftSkillRulesValue?.length}
                      add={add}
                      remove={remove}
                      skillType={skillType}
                      skillTree={skillTree}
                      onChangeMinRequired={onChangeMinRequired}
                      onChangeSkills={onChangeSkills}
                      onChangeSkillLevels={onChangeSkillLevels}
                      disabled={disabled}
                      skillValue={skillValue}
                      shiftSkillRulesValue={shiftSkillRulesValue}
                    />
                  ))
                : null}
            </Form>
          </Modal>
        )}
      </PageSettings.Consumer>
    );
  },
  (prevv, nextt) => {}
);

const ModalForm = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {
    const { shiftTypeEdit, workDay } = props;

    const day = shiftTypeEdit && shiftTypeEdit.applyOn ? shiftTypeEdit.applyOn.split(',') : [];

    const dayArr = day.map((item) => item.toLowerCase().trim());

    const mapDay = dayArr.map((item) => {
      let found = workDay.findIndex((e) => (e.id.indexOf(item) !== -1 ? true : false));
      if (found > -1) {
        return workDay[found].day;
      } else {
        return false;
      }
    });

    return {
      shiftId: Form.createFormField({
        value: shiftTypeEdit ? shiftTypeEdit.shiftId : '',
      }),
      minMem: Form.createFormField({
        value: shiftTypeEdit ? shiftTypeEdit.minMem : '',
      }),
      maxMem: Form.createFormField({
        value: shiftTypeEdit ? shiftTypeEdit.maxMem : '',
      }),
      applyOn: Form.createFormField({
        value: shiftTypeEdit ? mapDay : [],
      }),
      continueShiftRestrict: Form.createFormField({
        value: shiftTypeEdit ? shiftTypeEdit.continueShiftRestrict : [],
      }),
      roles: Form.createFormField({
        value: shiftTypeEdit ? shiftTypeEdit.roles : [],
      }),
      priority: Form.createFormField({
        value: shiftTypeEdit ? shiftTypeEdit.priority : '',
      }),
      maxContinueWorkDay: Form.createFormField({
        value: shiftTypeEdit ? shiftTypeEdit.maxContinueWorkDay : '',
      }),
    };
  },
  onValuesChange(_, values) {},
})(ModalFormComponent);

export default React.memo(ModalForm, (prevv, nextt) => {
  return nextt.shiftTypeEdit === prevv.shiftTypeEdit;
});
