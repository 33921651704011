import React, { useContext, useState } from 'react';
import { Row, Col, Card, Menu } from 'antd';
import './css/index.css';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Listitem from './listitem';
import Filter from './filter';
import { PlanningContext } from '../../../../pages/load-and-route/context';

export default () => {
  const { fnc, state, setState } = useContext(PlanningContext);
  const { selectGroup, isWaitingOrder, vehicleList, } = state;
  const { setIsWaitingOrder } = setState;
  const { openModalWaitingOrder, openModalPreviewOrder } = fnc;


  return (
    <div style={{ width: '100%' }}>
      {/* <Card className="card-load-and-route" bodyStyle={{ padding: 'unset' }}> */}
      <div className="leftcard" style={{ height: 'calc(100vh - 165px)' }}>
        <Filter />
        <Listitem />
      </div>
      {/* </Card> */}
    </div>
  );
};
