import React, { createContext, useEffect, useState } from 'react';
import {
  createSaleOrder,
  getIntialListSaleOrder,
  getMemberIssueBy,
  getSaleOrderById,
  getSaleOrderList,
  updateSaleOrder,
  updateStatusSaleOrder,
  uploadAttachment,
} from '../../controllers/sale-order/sale-order-api-new';
import _ from 'lodash';
import moment from 'moment';
import { getMemberAsset } from '../../controllers/asset-resource/asset-controller';
import { errorNotification, successNotification } from '../../components/v2/notification';
import {
  getSummaryAccount,
  getSaleOrderTOARList,
  getARDetail,
  getSaveAR,
  uploadARAttachment,
  getARPaidDetail,
  updateARStatus,
  getSaleOderDetail,
  getSaleOrderPendingList,
  getSaveNewAR,
} from '../../controllers/account-receive';
import { getCustomerLoadMore } from '../../controllers/getCustomer/get-customer-new';
import { GetSignaturePrint, GetSignaturePrintIssu } from '../../controllers/quotation/quotation-api-new';
import { getStockListById } from '../../controllers/po/po';

const AccountReceiveContext = createContext();

const SaleOrderProvider = ({ children }) => {
  const [isOpenQuotation, seIsOpenQuotation] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [record, setRecord] = useState();
  const [signatureUser, setSignatureUser] = useState();
  const [signatureUserInvoice, setSignatureUserInvoice] = useState();
  const [recordInvoice, setRecordInvoice] = useState();
  const [recordReport, setRecordReport] = useState();

  const [openUpload, setOpenUpload] = useState(false);
  const [openUploadPaid, setOpenUploadPaid] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [attachmentPaidData, setAttachmentPaidData] = useState([]);
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();
  const [summaryData, setSummaryData] = useState([]);
  const [paidData, setPaidData] = useState([]);
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);

  const [tabKey, setTabKey] = useState('pending');
  const [initialMemberList, setInitialMemberList] = useState([]);
  const [initialTaxList, setInitialTaxList] = useState([]);
  const [initialReasonList, setInitialReasonList] = useState([]);
  const [initialCampaignList, setInitialCampaignList] = useState([]);
  const [cancelItemData, setCancelItemData] = useState([]);
  const [event, setEvent] = useState(false);
  const [typepatment, setTypePayment] = useState('');
  const [tempVoucher, setTempVoucher] = useState([]);
  const [tempVoucherBrand, setTempVoucherBrand] = useState([]);
  const [tempVoucherModel, setTempVoucherModel] = useState([]);
  const [tempVoucherItem, setTempVoucherItem] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [saleOrderQTY, setSaleOrderQTY] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [customerPage, setCustomerPage] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [viewDetail, setViewDetail] = useState([]);
  const [totalWarehouseList, setTotalWarehouseList] = useState([]);

  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [visibleCancelPaid, setVisibleCancelPaid] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleWaiting, setVisibleWaiting] = useState(false);
  const [visiblePaid, setVisiblePaid] = useState(false);
  const [visibleCash, setVisibleCash] = useState(false);
  const [visibleBank, setVisibleBank] = useState(false);
  const [visibleCredit, setVisibleCredit] = useState(false);
  const [visibleCheque, setVisibleCheque] = useState(false);
  const [visibleVoucher, setVisibleVoucher] = useState(false);
  const [visibleAddItem, setVisibleAddItem] = useState(false);
  const [visibleSelectVoucher, setVisibleSelectVoucher] = useState(false);
  const [visibleViewInvoice, setVisibleViewInvoice] = useState(false);
  const [reportDataPending, setReportDataPending] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
  });

  const [reportDataWaiting, setReportDataWaiting] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [],
  });

  const [reportDataPaid, setReportDataPaid] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [moment().add(-3, 'month'), moment().add('month')],
  });

  const [reportDataCanceled, setReportDataCanceled] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [moment().add(-3, 'month'), moment().add('month')],
  });

  useEffect(() => {
    const getApiSummary = async () => {
      const body = {
        tabCode: tabKey,
      };

      const response = await getSummaryAccount(body);
      console.log('summaryList', response.data);
      setSummaryData(response.data.summaryList);
    };

    getApiSummary();
  }, [event, tabKey]);

  useEffect(() => {
    setReportDataPending((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'pending',
        issueDateStart: _.get(reportDataPending, 'rangePickerDate[0]')
          ? moment(_.get(reportDataPending, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : undefined,
        issueDateEnd: _.get(reportDataPending, 'rangePickerDate[1]')
          ? moment(_.get(reportDataPending, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : undefined,
        pageNumber: _.get(reportDataPending, 'paginationPage'),
        limit: _.get(reportDataPending, 'paginationSize'),
        orderBy: _.get(reportDataPending, 'fieldSort'),
        orderType: _.get(reportDataPending, 'orderSort'),
        paymentTermNo: _.get(reportDataPending, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataPending, 'saleOrderNo'),
        customerId: _.get(reportDataPending, 'customerName') ? [_.get(reportDataPending, 'customerName')] : [],
        invoiceNo: '',
        statusCode: _.get(reportDataPending, 'statusCode'),
      };
      const response = await getSaleOrderPendingList(payload);

      setReportDataPending((prev) => ({
        ...prev,
        quotationList: _.get(response, 'data.saleOrderARList'),
        quotationTotal: _.get(response, 'data.totalItem'),
      }));
    };
    setReportDataPending((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'pending') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataPending, 'rangePickerDate[0]'),
    _.get(reportDataPending, 'rangePickerDate[1]'),
    _.get(reportDataPending, 'paginationPage'),
    _.get(reportDataPending, 'paginationSize'),
    _.get(reportDataPending, 'fieldSort'),
    _.get(reportDataPending, 'orderSort'),
    _.get(reportDataPending, 'statusCode'),
    _.get(reportDataPending, 'saleOrderNo'),
    _.get(reportDataPending, 'customerName'),
  ]);

  useEffect(() => {
    setReportDataWaiting((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'waitingpaid',
        issueDateStart: _.get(reportDataWaiting, 'rangePickerDate[0]')
          ? moment(_.get(reportDataWaiting, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : undefined,
        issueDateEnd: _.get(reportDataWaiting, 'rangePickerDate[1]')
          ? moment(_.get(reportDataWaiting, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : undefined,
        pageNumber: _.get(reportDataWaiting, 'paginationPage'),
        limit: _.get(reportDataWaiting, 'paginationSize'),
        orderBy: _.get(reportDataWaiting, 'fieldSort'),
        orderType: _.get(reportDataWaiting, 'orderSort'),
        invoiceNo: _.get(reportDataWaiting, 'invoiceNo'),
        paymentTermNo: _.get(reportDataWaiting, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataWaiting, 'saleOrderNo'),
        customerId: _.get(reportDataWaiting, 'customerName') ? [_.get(reportDataWaiting, 'customerName')] : '',
      };
      const response = await getSaleOrderTOARList(payload);

      setReportDataWaiting((prev) => ({
        ...prev,
        quotationList: _.get(response, 'data.saleOrderARList'),
        quotationTotal: _.get(response, 'data.totalItem'),
      }));
      console.log('WaitingPage2', reportDataWaiting);
    };
    setReportDataWaiting((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'waitingpaid') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataWaiting, 'rangePickerDate[0]'),
    _.get(reportDataWaiting, 'rangePickerDate[1]'),
    _.get(reportDataWaiting, 'paginationPage'),
    _.get(reportDataWaiting, 'paginationSize'),
    _.get(reportDataWaiting, 'fieldSort'),
    _.get(reportDataWaiting, 'orderSort'),
    _.get(reportDataWaiting, 'invoiceNo'),
    _.get(reportDataWaiting, 'paymentTermNo'),
    _.get(reportDataWaiting, 'saleOrderNo'),
    _.get(reportDataWaiting, 'customerName'),
  ]);

  useEffect(() => {
    setReportDataPaid((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'paid',
        issueDateStart: _.get(reportDataPaid, 'rangePickerDate[0]')
          ? moment(_.get(reportDataPaid, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : undefined,
        issueDateEnd: _.get(reportDataPaid, 'rangePickerDate[0]')
          ? moment(_.get(reportDataPaid, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : undefined,
        pageNumber: _.get(reportDataPaid, 'paginationPage'),
        limit: _.get(reportDataPaid, 'paginationSize'),
        orderBy: _.get(reportDataPaid, 'fieldSort'),
        orderType: _.get(reportDataPaid, 'orderSort'),
        invoiceNo: _.get(reportDataPaid, 'invoiceNo'),
        paymentTermNo: _.get(reportDataPaid, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataPaid, 'saleOrderNo'),
        customerId: _.get(reportDataPaid, 'customerName') ? [_.get(reportDataPaid, 'customerName')] : '',
      };
      const response = await getSaleOrderTOARList(payload);
      setReportDataPaid((prev) => ({
        ...prev,
        quotationList: _.get(response, 'data.saleOrderARList'),
        quotationTotal: _.get(response, 'data.totalItem'),
      }));
    };
    setReportDataPaid((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'paid') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataPaid, 'rangePickerDate[0]'),
    _.get(reportDataPaid, 'rangePickerDate[1]'),
    _.get(reportDataPaid, 'paginationPage'),
    _.get(reportDataPaid, 'paginationSize'),
    _.get(reportDataPaid, 'fieldSort'),
    _.get(reportDataPaid, 'orderSort'),
    _.get(reportDataPaid, 'invoiceNo'),
    _.get(reportDataPaid, 'paymentTermNo'),
    _.get(reportDataPaid, 'saleOrderNo'),
    _.get(reportDataPaid, 'customerName'),
  ]);

  useEffect(() => {
    setReportDataCanceled((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'canceled',
        issueDateStart: _.get(reportDataCanceled, 'rangePickerDate[0]')
          ? moment(_.get(reportDataCanceled, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : undefined,
        issueDateEnd: _.get(reportDataCanceled, 'rangePickerDate[1]')
          ? moment(_.get(reportDataCanceled, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : undefined,
        pageNumber: _.get(reportDataCanceled, 'paginationPage'),
        limit: _.get(reportDataCanceled, 'paginationSize'),
        orderBy: _.get(reportDataCanceled, 'fieldSort'),
        orderType: _.get(reportDataCanceled, 'orderSort'),
        invoiceNo: _.get(reportDataCanceled, 'invoiceNo'),
        paymentTermNo: _.get(reportDataCanceled, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataCanceled, 'saleOrderNo'),
        customerId: _.get(reportDataCanceled, 'customerName') ? [_.get(reportDataCanceled, 'customerName')] : '',
      };

      const response = await getSaleOrderTOARList(payload);
      setReportDataCanceled((prev) => ({
        ...prev,
        quotationList: _.get(response, 'data.saleOrderARList'),
        quotationTotal: _.get(response, 'data.totalItem'),
      }));
    };
    setReportDataCanceled((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'canceled') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataCanceled, 'rangePickerDate[0]'),
    _.get(reportDataCanceled, 'rangePickerDate[1]'),
    _.get(reportDataCanceled, 'paginationPage'),
    _.get(reportDataCanceled, 'paginationSize'),
    _.get(reportDataCanceled, 'fieldSort'),
    _.get(reportDataCanceled, 'orderSort'),
    _.get(reportDataCanceled, 'invoiceNo'),
    _.get(reportDataCanceled, 'paymentTermNo'),
    _.get(reportDataCanceled, 'saleOrderNo'),
    _.get(reportDataCanceled, 'customerName'),
  ]);

  useEffect(() => {
    const getApi = async () => {
      let payload = {};
      const result = await getMemberIssueBy(payload);
      setInitialMemberList(result || []);
    };
    getApi();
  }, []);

  useEffect(() => {
    async function getUser() {
      try {
        const pageStart = 1;
        const pageEnd = customerPage;

        const data = await getCustomerLoadMore(pageStart, pageEnd);
        console.log('CustomerVoucher', data);
        setCustomerList(_.get(data, 'customers'));
        setCustomerLoading(false);
      } catch (error) {}
    }

    getUser();
  }, [customerPage]);

  useEffect(() => {
    const getApi = async () => {
      const resultTax = await getIntialListSaleOrder('tax');
      const resultReason = await getIntialListSaleOrder('reason');
      const resultCampaign = await getIntialListSaleOrder('campaign');
      setInitialTaxList(resultTax || []);
      setInitialReasonList(resultReason || []);
      setInitialCampaignList(resultCampaign || []);
    };
    getApi();
  }, []);

  useEffect(() => {
    const getApi = async () => {
      const response = await getStockListById('2aa809fe-b903-4466-a222-f9a4bb1d7352');
      const tempmap = _.map(_.get(response, 'data.data.stockList'), (witem, index) => ({
        index: index,
        qty: _.get(witem, 'qty'),
        qtyColor: _.get(witem, 'qtyColor'),
        totalCost: _.get(witem, 'totalCost'),
        warehouseCode: _.get(witem, 'warehouseCode'),
        warehouseId: _.get(witem, 'warehouseId'),
        warehouseName: _.get(witem, 'warehouseName'),
        selectQty: undefined,
      }));
      setWarehouseList(_.get(response, 'data.data.stockList'));
      setTotalWarehouseList(_.get(response, 'data.data.totalItem'));
    };
    // getApi();
  }, [visibleAddItem]);

  console.log('initialReasonList', initialReasonList);

  const handleOpenModalQuotationPending = async (row, code) => {
    try {
      const response = await getSaleOderDetail(_.get(row, 'saleOrderId'));

      setRecord({ ..._.get(response, 'data.saleOrderDetail'), code: code });

      const tempwh = _.map(_.get(response, 'data.saleOrderItemList'), (r) => ({
        ...r,
        qtyDeliver: 0,
      }));

      setSaleOrderQTY(tempwh);
      seIsOpenQuotation(true);
    } catch (error) {}
  };

  const handleOpenModalQuotationInvoice = async (row, code) => {
    try {
      const payload = {
        saleOrderId: _.get(row, 'saleOrderId'),
        tabCode: code,
        issueDateStart: '2023-01-01',
        issueDateEnd: '2025-01-01',
        saleOrderNo: '',
        customerName: '',
        paymentTermNo: '',
        pageNumber: 1,
        limit: 10,
      };

      const response = await getSaleOrderTOARList(payload);
      setViewDetail(_.get(response, 'data.saleOrderARList'));
      // setRecord({ ..._.get(response, 'data.saleOrderDetail'), code: code });
      // setSaleOrderQTY(_.get(response, 'data.saleOrderItemList'));
      // seIsOpenQuotation(true);
    } catch (error) {}
  };

  const handleOpenModalQuotation = async (row, code) => {
    if (row) {
      try {
        const response = await getARDetail(row.arId);
        const responseSignature = _.get(response, 'data.receiveById')
          ? GetSignaturePrintIssu(_.get(response, 'data.receiveById'))
          : await GetSignaturePrint();
        console.log('getSaleOrderById', code, response);

        setRecord({ ..._.get(response, 'data'), code: _.get(response, 'data.stautsCode') });
        setRecordReport(_.get(response, 'data.itemList'),);
        handleCountSize(_.get(response, 'data.itemList'),_.get(response, 'data'));
        setSignatureUser(_.get(responseSignature, 'data'));

        seIsOpenQuotation(true);
      } catch (error) {}
    } else {
      seIsOpenQuotation(true);
      setRecord();
    }
  };

  const handleCountSize = (data,alldata) => {
    let count = 0;
    let reportmap = [];
    console.log('recordReportDatadefult', data,alldata);



    let tempgdata = _.chain(data)
      .groupBy('credit')
      .map((value, key) => ({ credit: key, detail: value }))
      .value();
    console.log('recordReportData', tempgdata);
    const day = _.split(_.get(alldata,'issueDate'),'-');
    const years = parseInt(day[0]) + 543
    
    _.map(tempgdata, (item) => {
      let total = _.sumBy(_.get(item, 'detail'), 'totalNum');
      console.log('credisDays',day);
      const newday = parseInt(day[2]) + parseInt(item.credit)
      const newdate = _.toString(newday+'/'+parseInt(day[1])+'/'+years);
      reportmap.push(
        { credit: item.credit, role: 'head', total: total , creditday: newdate  }
        // item
      );
      _.map(_.get(item, 'detail'), (detail) => {
        count = count + 1;
        reportmap.push({ ...detail, index: count });
      });
    });

    setRecordReport(reportmap);
    console.log('reportmap', reportmap);
  };

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setCustomerPage(customerPage + 10);
      setCustomerLoading(true);
    }
  };

  const handleOpenModalUpload = (row) => {
    if (row) {
      setOpenUpload(true);
      setDefaultAttachment(row);
      setTypeAttachment('edit');
    } else {
      setOpenUpload(true);
      setDefaultAttachment();
      setTypeAttachment('add');
    }
  };

  const handleSaveQuotation = async (payload) => {
    // console.log('handleSaveQuotation', attachmentData[5].fileHash, payload, attachmentData);
    // try {
    const response =
      payload.code === 'edit'
        ? await updateSaleOrder(payload)
        : payload.code !== 'view'
        ? await getSaveAR(payload)
        : await getSaveNewAR(payload);

    // if (payload.code === 'edit') {
    //   response = await updateSaleOrder(payload);
    // } else {
    //   response = await getSaveNewAR(payload);
    // }

    if (_.size(attachmentData) > 0) {
      for (let index = 0; index < attachmentData.length; index++) {
        const file = _.get(attachmentData[index], 'fileRaw');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('saleOrderId', payload.code === 'edit' ? payload.saleOrderNo : payload.saleOrderNo);
        formData.append('remark', attachmentData[index].remark);
        if (attachmentData[index].fileHash === undefined) {
          await uploadARAttachment(formData);
        }
      }
    }

    console.log('createSaleOrder', response);

    if (_.get(response, 'status.code') === 200) {
      successNotification(_.get(response, 'status.message'));
    } else {
      errorNotification(_.get(response, 'status.message'));
    }

    setIsFetch((prev) => !prev);
    setVisiblePaid(false);
    seIsOpenQuotation(false);
    // } catch (error) {
    //   console.log('createSaleOrderError', error);
    //   errorNotification(_.get(error, 'status.message'));
    //   return;
    // }
  };

  const handleCancelQuotation = () => {
    seIsOpenQuotation(false);
    setRecord();
  };

  const handleOpenPaid = async (code, arId) => {
    try {
      const response = await getARPaidDetail(arId);

      setPaidData({ ..._.get(response, 'data'), code: code });
      setVisiblePaid(true);
    } catch (error) {}
  };

  const handleCancelPaid = (value) => {
    setVisiblePaid(false);
    setPaidData([]);
  };

  const handleOpenApprove = (value) => {
    setVisibleApprove(true);
  };

  const handleSaveApprove = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      setIsFetch((prev) => !prev);
      setVisibleApprove(false);
      setVisibleViewInvoice(false);  
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelApprove = () => {
    setVisibleApprove(false);
  };

  const handleOpenReject = (value) => {
    setVisibleReject(true);
  };

  const handleSaveReject = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      setIsFetch((prev) => !prev);
      setVisibleReject(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelReject = (value) => {
    setVisibleReject(false);
  };

  const handleOpenCancel = (value) => {
    setVisibleCancel(true);
  };

  const handleSaveCancel = async (value) => {
    try {
      const response = await updateARStatus(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        successNotification('Success');
        handleCancelQuotation();
      }
      setIsFetch((prev) => !prev);
      setVisibleCancel(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelCancelQT = (value) => {
    setVisibleCancel(false);
  };

  const handleOpenDelete = (value) => {
    setVisibleDelete(true);
  };

  const handleSaveDelete = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      setIsFetch((prev) => !prev);
      setVisibleDelete(false);
      seIsOpenQuotation(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelDelete = (value) => {
    setVisibleDelete(false);
  };

  const handleOpenWaiting = (value) => {
    setVisibleWaiting(true);
  };

  const handleSaveWaiting = async (value) => {
    try {
      const response = await updateARStatus(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        successNotification('Success');
        handleCancelQuotation();
      }
      setIsFetch((prev) => !prev);
      setVisibleWaiting(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelWaiting = (value) => {
    setVisibleWaiting(false);
  };

  const handleOpenModalUploadPaid = (row) => {
    if (row) {
      setOpenUploadPaid(true);
      setDefaultAttachment(row);
      setTypeAttachment('edit');
    } else {
      setOpenUploadPaid(true);
      setDefaultAttachment();
      setTypeAttachment('add');
    }
  };

  const handleOpenCancelPaid = (value) => {
    setVisibleCancelPaid(true);
    setCancelItemData(value);
  };

  const handleCloseCancelPaid = () => {
    setVisibleCancelPaid(false);
  };

  const handleOpenCash = (value) => {
    setVisibleCash(true);
    setTypePayment(value);
  };

  const handleCancelCash = (value) => {
    setVisibleCash(false);
  };

  const handleOpenBank = (value) => {
    setVisibleBank(true);
  };

  const handleCancelBank = (value) => {
    //visibleBank
    setVisibleBank(false);
  };

  const handleOpenCredit = (value) => {
    setVisibleCredit(true);
  };

  const handleCancelCredit = (value) => {
    //visibleCredit
    setVisibleCredit(false);
  };

  const handleOpenCheque = (value) => {
    setVisibleCheque(true);
  };

  const handleCancelCheque = (value) => {
    //visibleCheque
    setVisibleCheque(false);
  };

  const handleOpenVoucher = (value) => {
    setVisibleVoucher(true);
  };

  const handleCancelVoucher = (value) => {
    //visibleVoucher
    setVisibleVoucher(false);
  };

  const openVoucherListModal = (value) => {
    setVisibleSelectVoucher(true);
  };

  const handleOpenAddItem = async (value) => {
    setVisibleAddItem(true);
  };

  const handleCancelAddItem = (value) => {
    //visibleVoucher
    setVisibleAddItem(false);
  };

  const handleOpenViewInvoice = async (row) => {
    if (row) {
      try {
        const response = await getARDetail(row.arId);
        const responseSignature = await GetSignaturePrint();
        // console.log('getSaleOrderById', code, response);

        setRecordInvoice({ ..._.get(response, 'data'), code: _.get(response, 'data.stautsCode') });
        setSignatureUserInvoice(_.get(responseSignature, 'data'));
        setVisibleViewInvoice(true);
      } catch (error) {}
    } else {
      setVisibleViewInvoice(true);
      setRecord();
    }
  };

  const handleCancelViewInvoice = (value) => {
    //visibleVoucher
    setVisibleViewInvoice(false);
  };

  const handleCleckSaleOrder = async (record, selectItem) => {
    console.log('RowRecordAR', record,'/',selectItem);
    const response = await getStockListById(_.get(record, 'itemId'));
    const whrp = _.get(response, 'data.data.stockList');
    setRecordList(record);

    const tempft = _.filter(selectItem, (i) => {
      return i.saleOrderItemId === _.get(record, 'saleOrderItemId');
    });
    const ftSO = tempft[0];
    const ftsoindex = _.get(ftSO, 'warehouseSeletedList');
    console.log('ftSO', ftsoindex);
    console.log('warehouseListQTY', warehouseList);

    const tempsetWh = _.map(whrp, (i) => {
      const fineIn = _.findIndex(ftsoindex, ['warehouseId', i.warehouseId]);
      console.log('fineIn', fineIn);
      if (fineIn > -1) {
        i.qty = parseInt(i.qty) - parseInt(ftsoindex[fineIn].qty);
      }

      return i;
    });

    if (ftSO) {
      setWarehouseList(tempsetWh);
    } else {
      setWarehouseList(_.get(response, 'data.data.stockList'));
    }
  };

  return (
    <AccountReceiveContext.Provider
      value={{
        state: {
          isOpenQuotation,
          isFetch,
          openUpload,
          attachmentData,
          typeAttachment,
          defaultAttachment,
          reportDataPending,
          reportDataWaiting,
          reportDataPaid,
          reportDataCanceled,
          initialMemberList,
          initialTaxList,
          initialReasonList,
          initialCampaignList,
          record,
          visibleApprove,
          visibleReject,
          visibleCancel,
          visibleDelete,
          visibleWaiting,
          visiblePaid,
          visibleCash,
          summaryData,
          attachmentPaidData,
          paidData,
          visibleCancelPaid,
          cancelItemData,
          openUploadPaid,
          visibleBank,
          visibleCredit,
          visibleCheque,
          visibleVoucher,
          typepatment,
          visibleSelectVoucher,
          tempVoucher,
          tempVoucherBrand,
          tempVoucherModel,
          tempVoucherItem,
          customerList,
          customerLoading,
          customerPage,
          signatureUser,
          visibleAddItem,
          pageTable,
          sizeTable,
          warehouseList,
          saleOrderQTY,
          recordList,
          viewDetail,
          totalWarehouseList,
          visibleViewInvoice,
          recordInvoice,
          signatureUserInvoice,
          recordReport,
        },
        setState: {
          seIsOpenQuotation,
          setIsFetch,
          setOpenUpload,
          setAttachmentData,
          setTypeAttachment,
          setDefaultAttachment,
          setTabKey,
          setReportDataPending,
          setReportDataWaiting,
          setReportDataPaid,
          setReportDataCanceled,
          setRecord,
          setAttachmentPaidData,
          setPaidData,
          setVisibleCash,
          setVisibleCancelPaid,
          setOpenUploadPaid,
          setVisibleBank,
          setTypePayment,
          setVisibleSelectVoucher,
          setTempVoucher,
          setTempVoucherBrand,
          setTempVoucherModel,
          setTempVoucherItem,
          setCustomerPage,
          setCustomerLoading,
          setVisibleAddItem,
          setPageTable,
          setSizeTable,
          setWarehouseList,
          setSaleOrderQTY,
          setRecordList,
          setViewDetail,
          setTotalWarehouseList,
          setRecordReport,
        },
        func: {
          handleOpenModalQuotation,
          handleSaveQuotation,
          handleOpenModalUpload,
          handleCancelQuotation,
          handleOpenModalUpload,
          handleOpenApprove,
          handleSaveApprove,
          handleCancelApprove,
          handleOpenReject,
          handleSaveReject,
          handleCancelReject,
          handleOpenCancel,
          handleSaveCancel,
          handleCancelCancelQT,
          handleOpenDelete,
          handleSaveDelete,
          handleCancelDelete,
          handleOpenWaiting,
          handleSaveWaiting,
          handleCancelWaiting,
          handleOpenPaid,
          handleCancelPaid,
          handleOpenCash,
          handleCancelCash,
          handleOpenCancelPaid,
          handleCloseCancelPaid,
          handleOpenModalUploadPaid,
          handleOpenBank,
          handleCancelBank,
          handleOpenCredit,
          handleCancelCredit,
          handleOpenCheque,
          handleCancelCheque,
          handleOpenVoucher,
          handleCancelVoucher,
          openVoucherListModal,
          handleCustomerPopupScroll,
          handleOpenAddItem,
          handleCancelAddItem,
          handleOpenModalQuotationPending,
          handleCleckSaleOrder,
          handleOpenModalQuotationInvoice,
          handleOpenViewInvoice,
          handleCancelViewInvoice,
        },
      }}
    >
      {children}
    </AccountReceiveContext.Provider>
  );
};

export { AccountReceiveContext, SaleOrderProvider };
