import React, { useState, useEffect, useRef, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Table from '../../../components/v2/table';
import ActionReportV8 from '../../../components/action-report/action-report-v8';
import MemberReportTable from './member-report-table';
import ModalAddMember from './modalMember-new';
import cssStyle from './css/index.css';
import { Icon, Modal, Badge, notification, List, Popover, Button, Avatar, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import httpClient from '../../../components/axiosClient';
import { appendFile } from 'fs';
import { PageSettings } from '../../../config/page-settings';
import { OrganizationContext } from '../organization-context';

import { MemberSettings } from './page-member-settings';
import { useQuery } from '../../../helpers/general-helper';
import { getMemberData } from '../../../controllers/organization/getMemberData';
import ModalMemberSetting from './modal-member-setting';
import ModalAddUser from '../../../components/modal-add-user-new';
import _ from 'lodash';

export default (props) => {
  const app = useContext(PageSettings);
  // console.log('add :::', app.checkPermissionAction('P3PG1C5', 'P3PG1C5A2'));
  // console.log('add new :::', app.checkPermissionAction('P3PG1C5', 'P3PG1C5A6'));

  const addUserCheck = app.checkPermissionAction('P3PG1C5', 'P3PG1C5A2');
  const addUserNewCheck = app.checkPermissionAction('P3PG1C5', 'P3PG1C5A6');

  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const { stateMain, setStateMain } = useContext(OrganizationContext);
  const updated_by = 1;

  const comId = stateMain.comId;
  const orgId = stateMain.orgId;
  const parentOrgId = stateMain.parentOrgId;

  const [visiblePopover, setVisiblePopover] = useState(false);

  const [memberSettingVisibled, setMemberSettingVisibled] = useState(false);
  const [memComId, setMemComId] = useState();
  const [memOrgId, setMemOrgId] = useState();

  const history = useHistory();

  const handleModalMemberSetting = (visible, record = {}) => {
    setMemComId(record.memComId);
    setMemOrgId(record.memOrgId);
    setMemberSettingVisibled(visible);
  };

  const columns = [
    {
      id: 0,
      title: '#',
      align: 'center',
      width: 70,
      dataIndex: 'index',
      key: 'index',
      fixed: 'left',
    },
    {
      dataIndex: 'profile',
      key: 'profile',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => <Avatar src={record.profile_img} />,
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnName', defaultMessage: 'Name' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnName', defaultMessage: 'Name' }),
      dataIndex: 'fullname',
      key: 'fullname',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      width: 250,
      render: (text, record, index) => {
        return record.fullname;
        // <PageSettings.Consumer>
        //   {({ checkPermissionAction }) =>
        //     checkPermissionAction('P3PG1C5', 'P3PG1C5A4') ? (
        //       <List.Item.Meta
        //         key={index}
        //         title={
        //           <Link
        //             to={
        //               '/main/resource/organization/users-detail/' +
        //               record.memComId +
        //               '?orgId=' +
        //               record.orgId +
        //               '&parentOrgId=' +
        //               record.parentOrgId +
        //               '&tab=member&page=' +
        //               record.pageCurrent +
        //               '&pageSize=' +
        //               record.pageSize +
        //               '&searchBy=' +
        //               record.searchBy +
        //               '&search=' +
        //               record.q
        //             }
        //           >
        //             {record.fullname}
        //           </Link>
        //         }
        //       />
        //     ) : (
        //       // ) :
        //       // record.fullname
        //       record.fullname
        //     )
        //   }
        // </PageSettings.Consumer>
      },
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnPosition', defaultMessage: 'Position' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnPosition', defaultMessage: 'Position' }),
      dataIndex: 'position',
      key: 'position',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      // width: 200,
      render: (text, record, index) => {
        return record.position ? record.position : '';
      },
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnPhone', defaultMessage: 'Phone Number' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnPhone', defaultMessage: 'Phone Number' }),
      dataIndex: 'phone',
      key: 'phone',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return record.phone ? record.phone : '-';
      },
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnClockIn', defaultMessage: 'Clock In' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnClockIn', defaultMessage: 'Clock In' }),
      dataIndex: 'clockIn',
      key: 'clockIn',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnClockOut', defaultMessage: 'Clock Out' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnClockOut', defaultMessage: 'Clock Out' }),
      dataIndex: 'clockOut',
      key: 'clockOut',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnEmail', defaultMessage: 'Email' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnEmail', defaultMessage: 'Email' }),
      dataIndex: 'email',
      key: 'email',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return record.email ? record.email : '-';
      },
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnOnline', defaultMessage: 'Online' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnOnline', defaultMessage: 'Online' }),
      dataIndex: 'status_connection',
      key: 'status_connection',
      width: 150,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const status = record.status_connection === 'Active' || record.status_connection === 'ออนไลน์' ? 'green' : '#A9A9A9';
        return <Badge className="users-badge" text={record.status_connection} color={status} />;
      },
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnStatus', defaultMessage: 'Status' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnStatus', defaultMessage: 'Status' }),
      key: 'status_work',
      dataIndex: 'status_work',
      width: 150,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const status = record.status_work === 'Enable' || record.status_work === 'ใช้งาน' ? 'blue' : '#A9A9A9';
        return <Badge className="users-badge" text={record.status_work} color={status} />;
      },
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnPriority', defaultMessage: 'Priority' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnPriority', defaultMessage: 'Priority' }),
      key: 'priority',
      dataIndex: 'priority',
      width: 120,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnFavoriteShift', defaultMessage: 'Favorite Shift' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnFavoriteShift', defaultMessage: 'Favorite Shift' }),
      key: 'favorite_shift',
      dataIndex: 'favorite_shift',
      width: 190,
      ellipsis: true,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'orgTabMemberColumnMemberRestriction', defaultMessage: 'Member Restriction' }),
      select: intl.formatMessage({ id: 'orgTabMemberColumnMemberRestriction', defaultMessage: 'Member Restriction' }),
      key: 'member_restriction',
      dataIndex: 'member_restriction',
      width: 190,
      ellipsis: true,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: '',
    //   key: 'options',
    //   dataIndex: 'options',
    //   align: 'center',
    //   width: '5%',
    //   render: (text, record, index) => fncActionTable(record, index)
    // },
  ];

  const setShowColumn = {
    index: true,
    fullname: true,
    position: true,
    phone: true,
    email: true,
    status_connection: true,
    status_work: true,
    priority: false,
    favorite_shift: false,
    member_restriction: false,
    options: true,
    clockIn: true,
    clockOut: true,
    profile: true,
  };

  const setShowColumnArr = [
    'index',
    'fullname',
    'position',
    'phone',
    'email',
    'status_connection',
    'status_work',
    'options',
    'clockIn',
    'clockOut',
    'profile',
  ];

  const ignoreColumn = ['priority', 'favorite_shift', 'member_restriction'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreFilterColumns = ['index', 'options', 'profile'];
  const ignoreSearchColumns = ['index', 'options', 'profile'];

  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePopup, setVisiblePopup] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [addDataMembers, setAddDataMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [fieldChang, setFieldChang] = useState('');
  const [fliterByColumn, setFliterByColumn] = useState('all');

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  const [refreshTable, setRefreshTable] = useState(false);
  const [refreshDataMemBer, setrefreshDataMemBer] = useState(false);

  const [addMemberOrg, setAddMemberOrg] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (stateMain.orgId) {
      // setPaginationPage(1)
      const loadMembers = async () => {
        const body = {
          search: fieldChang,
          searchBy: fliterByColumn,
          orderType: orderSort,
          orderBy: fieldSort,
          paging: stateMain.page,
          rowsPerPages: paginationSize,
        };

        try {
          const response = await httpClient.post(`/v4/resource/manager/company/comid/${comId}/orgid/${orgId}/member/elastic-search`, body);

          if (response.status === 200) {
            let members = response.data.data.members.list;
            membersTable(members, paginationSize, stateMain.page);
            setTotal(response.data.data.members.totalMembers);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          return;
        }
      };
      loadMembers();
    }
  }, [stateMain.orgId, refreshTable, fieldChang, fliterByColumn, extraSort]);

  useEffect(() => {
    if (stateMain.orgId) {
      getMembersData();
    }
  }, [stateMain.orgId, refreshTable, refreshDataMemBer]);

  // const loadMembers = async () => {
  //   setLoading(true);
  //   const body = {
  //     search: fieldChang,
  //     searchBy: fliterByColumn,
  //     orderType: orderSort,
  //     orderBy: fieldSort,
  //     paging: paginationPage,
  //     rowsPerPages: paginationSize,
  //   }

  //   try {
  //     const response = await httpClient.post(`/v4/resource/manager/company/comid/${comId}/orgid/${orgId}/member/elastic-search`, body);

  //     if (response.status === 200) {
  //       console.log('response:::', response);
  //       let members = response.data.data.members.list;
  //       await membersTable(members, paginationSize, paginationPage);
  //       await setTotal(response.data.data.members.totalMembers);
  //       await setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     return
  //   }
  // };

  const membersTable = (members, pageSize, current) => {
    if (members && members !== undefined) {
      members.map((item, key) => {
        return (
          (item.pageSize = pageSize || 10),
          (item.pageCurrent = current || 1),
          (item.q = fieldChang),
          (item.searchBy = fliterByColumn),
          (item.orgId = orgId),
          (item.parentOrgId = parentOrgId)
        );
      });
      setDataSource(members);
    } else if (members == undefined) {
      setDataSource([]);
    }
  };

  const getMembersData = async () => {
    try {
      const response = await getMemberData(comCode, orgId);
      // console.log('getMembers data::: ', response);
      setAddDataMembers(response);
    } catch (error) {
      return;
    }
  };

  const refreshDataMembers = () => {
    setRefreshTable((curren) => !curren);
  };

  const handleDelete = async (member) => {
    Modal.confirm({
      className: 'member-modal-confirm',
      centered: true,
      icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      onOk() {
        deleteMember(member.memOrgId);
      },
      onCancel() {},
    });
  };

  const deleteMember = async (memOrgId) => {
    try {
      const result = await httpClient.delete(`/v2/team/${updated_by}/${memOrgId}`);
      deletedNotification(result.data.data);
      setTimeout(() => {
        setRefreshTable((curren) => !curren);
        setStateMain.setTriggerMember((curren) => !curren);
        setStateMain.setCheckMember();
      }, 2000);
    } catch (error) {
      return;
    }
  };

  const deletedNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const onChangeSearch = debounce((value) => {
    setStateMain.setPage(1);
    setFieldChang(value);
  }, 500);

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setStateMain.setPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setStateMain.setPage(current);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];

    if (listArrayColumns.length < 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    handleOnVisiblecolumns();
  };

  const handleOnModalAddMember = () => {
    setVisibleAddModal(true);
    setVisiblePopup(false);
  };

  const handleOnModalAddMemberNew = () => {
    setAddMemberOrg(true);
    setVisiblePopup(false);
  };

  const handleVisiblePopupChange = (flag) => {
    setVisiblePopup(flag);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
      // setScrollTableX(2000)
    }
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const linkToView = (record) => {
    history.push(
      '/main/resource/organization/users-detail/' +
        record.memComId +
        '?orgId=' +
        record.orgId +
        '&parentOrgId=' +
        record.parentOrgId +
        '&tab=member&page=' +
        record.pageCurrent +
        '&pageSize=' +
        record.pageSize +
        '&searchBy=' +
        record.searchBy +
        '&search=' +
        record.q
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          ey={_.get(record, 'index')}
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <div>
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) =>
                      checkPermissionAction('P3PG1C5', 'P3PG1C5A4') ? (
                        <Button style={{ width: 100,textAlign: 'left' }} type="link" ghost onClick={() => linkToView(record)}>
                          <FormattedMessage id="btnView" defaultMessage="View" />
                        </Button>
                      ) : null
                    }
                  </PageSettings.Consumer>
                </div>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P3PG1C5', 'P3PG1C5A5') ? (
                        <Button
                          style={{ width: 100, textAlign: 'left' }}
                          type="link"
                          ghost
                          onClick={() => handleModalMemberSetting(true, record)}
                        >
                          <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P3PG1C5', 'P3PG1C5A3') ? (
                        <Button style={{ width: 100 ,textAlign: 'left'}} type="link" ghost onClick={() => handleDelete(record)}>
                          <FormattedMessage id="orgTabMemberBtnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  // const menuAdd = (
  //   <PageSettings.Consumer>
  //     {({ checkPermissionAction }) =>
  //       <Menu>
  //         {
  //           checkPermissionAction('P3PG1C5', 'P3PG1C5A2') ? (
  //             <Menu.Item key="existing_User" onClick={handleOnModalAddMember}>
  //               <span>Existing User</span>
  //             </Menu.Item>
  //           ) : null
  //         }
  //         {
  //           checkPermissionAction('P3PG1C5', 'P3PG1C5A6') ? (
  //             <Menu.Item key="new_user" onClick={handleOnModalAddMemberNew}>
  //               <span >New User</span>
  //             </Menu.Item>
  //           ) : null
  //         }
  //       </Menu>
  //     }
  //   </PageSettings.Consumer>
  // );

  const menuAdd =
    addUserCheck && addUserNewCheck ? (
      <Menu>
        <Menu.Item key="existing_User" onClick={handleOnModalAddMember}>
          <span>
            <FormattedMessage id="orgExistingUser" defaultMessage="Existing User" />
          </span>
        </Menu.Item>
        <Menu.Item key="new_user" onClick={handleOnModalAddMemberNew}>
          <span>
            <FormattedMessage id="orgNewUser" defaultMessage="New User" />
          </span>
        </Menu.Item>
      </Menu>
    ) : addUserCheck === true && addUserNewCheck === false ? (
      <Menu>
        <Menu.Item key="existing_User" onClick={handleOnModalAddMember}>
          <span>
            <FormattedMessage id="orgExistingUser" defaultMessage="Existing User" />
          </span>
        </Menu.Item>
      </Menu>
    ) : addUserNewCheck === true && addUserCheck === false ? (
      <Menu>
        <Menu.Item key="new_user" onClick={handleOnModalAddMemberNew}>
          <span>
            <FormattedMessage id="orgNewUser" defaultMessage="New User" />
          </span>
        </Menu.Item>
      </Menu>
    ) : null;

  return (
    <div className="member-card">
      <div className="member-card-container">
        <div className="action-maintenance-container">
          <ActionReportV8
            value={{
              columns,
              ignoreFilterColumns,
              ignoreSearchColumns,
              fliterByColumn,
              visible,
              visiblePopup,
              defaultShowColumn,
              textErrorSelectColumn,
              menuAdd,
              addUserCheck,
              addUserNewCheck,
            }}
            fnc={{
              setVisiblePopup,
              onChangeSearch,
              onFliterByColumn,
              checkedValuecolumns,
              handleCheckOk,
              handleReset,
              handleVisibleChange,
              handleOnVisiblecolumns,
              handleOnModalAddMember,
              handleVisiblePopupChange,
            }}
          />
        </div>

        <MemberReportTable
          loading={loading}
          dataSource={dataSource}
          columns={newDataColumns}
          total={total}
          currentPage={currentPage}
          paginationPage={stateMain.page}
          fnc={{
            handleChange,
            handlePagination,
            handleSizeChange,
            fncActionTable,
          }}
        />
      </div>

      <ModalAddMember
        dataMembers={addDataMembers}
        orgId={orgId}
        setRefreshTable={setRefreshTable}
        visible={visibleAddModal}
        setVisible={setVisibleAddModal}
        setPaginationSize={setPaginationSize}
        setPaginationPage={setStateMain.setPage}
        setTriggerMember={setStateMain.setTriggerMember}
      />

      <ModalAddUser
        visible={addMemberOrg}
        setVisible={setAddMemberOrg}
        dataMain={props}
        setRefreshTable={setRefreshTable}
        permissions={{
          page: 'P3PG1C5',
          add: 'P3PG1C5A7',
          edit: 'P3PG1C5A8',
        }}
        routeFrom="org"
      />

      <MemberSettings.Provider
        value={{
          memberSettingVisibled,
          handleModalMemberSetting,
          memComId,
          memOrgId,
          setMemComId,
          setMemOrgId,
          refreshDataMembers,
          orgId,
        }}
      >
        <ModalMemberSetting />
      </MemberSettings.Provider>
    </div>
  );
};
