import { Col } from 'antd';
import React from 'react'
import _ from 'lodash'

const FeedNoteTags = (props) => {
  const { isMemComId, tags } = props
  return (
    <div>
      <Col
        span={24}
        style={isMemComId ? { display: 'flex', justifyContent: 'end', textAlign: 'right' } : { undefined }}
      >
        <div style={{ padding: '6px 0px ' }}>
          {_.map(tags, (h) => {
            return (
              <>
                <span key={_.get(h, '_id')} style={{ color: h.tagColor }}>#{h.tagName}</span>
                {" "}
              </>
            )
          })}
        </div>
      </Col>
    </div>
  )
}

export default FeedNoteTags
