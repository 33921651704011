import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Modal, Row, Col, Divider, Spin } from 'antd';
import BusinessPartnerDetail from '../business-partner-detail/index';
import getCustomerInformation from '../../controllers/getCustomer/get-customer-information';
import VehicleItem from '../vehicle-item/index';
import CalculateV1 from '../calculate/calculate-v1';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import httpClient from '../axiosClient';
import NumberFormat from 'react-number-format';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import _, { debounce } from 'lodash';
import AddCustomer from '../add-business-partner/index';
import AddAddress from '../modal-create-adress-map-v2/index';
import { getItemAll } from '../../controllers/quotation/quotation-api';

export default (props) => {
  const { value, fnc } = props;
  const {
    visibleAddItem,
    customerData,
    selectCustomer,
    selectAddress,
    selectWareHouse,
    warehouseData,
    defaultProducts,
    defaultVehicles,
    defaultUnits,
    itemObjectData,
    discountSelete,
    taxSelete,
    taxData,
    discountData,
    valueInputDiscount,
    dataEdit,
    quotationDataDefault,
    subTotalResult,
    marker,
    polyline,
    distance,
  } = value;
  const {
    handleAppplyItem,
    handleCancelItem,
    setTriggerCustomer,
    setItemObjectData,
    onChangeDiscount,
    onChangeTax,
    onChangeInput,
    setSelectAddress,
    setSelectCustomer,
    setSelectWareHouse,
    setStatusSet,
    getFieldsValue,
    getFieldValue,
    setSelectContact,
    setMarker,
    setPolyline,
    setDistance,
    setDefaultProducts,
    setDefaultVehicles,
    setDefaultUnits,
  } = fnc;
  const intl = useIntl();
  const [formRef, setFormRef] = useState(null);
  const [formRefVehicle, setFormRefVehicle] = useState(null);
  const [customer, setCustomer] = useState();
  const [custoemrAddress, setCustomerAddress] = useState();
  const [addressData, setAddressData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [triggerAddress, setTriggerAddress] = useState(false);
  const [selectAddressItem, setSelectAdressItem] = useState();
  const [selectWareHouseItem, setSelectWareHouseItem] = useState();
  const [selectCustomerItem, setselectCustomerItem] = useState();

  const [selectProductsItem, setselectProductsItem] = useState([]);
  const [selectVehiclesItem, setselectVehiclesItem] = useState([]);
  const [dataDistance, setDataDistance] = useState([]);
  const [dataListItem, setDataListItem] = useState([]);

  const [distanceMeter, setDistanceMeter] = useState(0); // ระยะทางเมตร

  const [visibleAddCustomer, setVisibleAddCustomer] = useState(false);
  const [visibleAddAddress, setVisibleAddAddress] = useState(false);
  const [addCustomerData, setAddCustomerData] = useState();
  const [addAddressData, setAddAddressData] = useState();
  const [checkCustomer, setCheckCustomer] = useState(false);
  const [warehouseDataItem, setWarehouseDataItem] = useState([]);

  const [defaultProductsItem, setDefaultProductsItem] = useState([]);
  const [defaultVehiclesItem, setDefaultVehiclesItem] = useState([]);
  const [defaultUnitsitem, setDefaultUnitsItem] = useState([]);

  const [sumCalculate, setSumCalculate] = useState({});
  const [productKey, setProductKey] = useState([]);
  const [indeterminate, setIndeterminate] = useState([]);
  const [productListKey, setProductListKey] = useState([]);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    setSelectAdressItem(selectAddress);
    setSelectWareHouseItem(selectWareHouse);
    setselectCustomerItem(selectCustomer);
    setWarehouseDataItem(warehouseData);
    setDefaultVehiclesItem(defaultVehicles);
    setDefaultUnitsItem(defaultUnits);
    setDefaultProductsItem(defaultProducts);
  }, [
    _.get(selectCustomer, 'customerId'),
    _.get(selectAddress, 'customerAddressBookId'),
    _.get(selectWareHouse, 'warehouseId'),
  ]);

  useEffect(() => {
    const customerId = _.get(selectCustomerItem, 'customerId')
      ? _.get(selectCustomerItem, 'customerId')
      : _.get(selectCustomer, 'customerId');
    const getAddress = async () => {
      if (customerId && visibleAddItem === true) {
        setLoading(true);
        const response = await getCustomerInformation(customerId);
        if (response) {
          setAddressData(_.get(response, 'addressBooks'));
          setLoading(false);
        }
      }
    };
    getAddress();
  }, [
    _.get(selectCustomer, 'customerId'),
    triggerAddress,
    _.get(selectCustomerItem, 'customerId'),
    visibleAddItem,
  ]);

  useEffect(() => {
    let wareHouseId = _.get(selectWareHouseItem, 'warehouseId');
    let customerId = _.get(selectCustomerItem, 'customerId');
    let lat = _.get(selectAddressItem, 'lat');
    let lng = _.get(selectAddressItem, 'lng');
    let productItem = selectProductsItem.length < 1 ? undefined : selectProductsItem;
    let vehiclesItem = selectVehiclesItem.length < 1 ? undefined : selectVehiclesItem;
    let distance = _.get(selectWareHouseItem, 'distance') ? _.get(selectWareHouseItem, 'distance') : _.get(quotationDataDefault, 'wareHourse[0].distance');

    const getItem = async () => {
      const response = await getItemAll(
        customerId,
        lat,
        lng,
        distance,
        wareHouseId,
        productItem,
        vehiclesItem,
      );

      if (customerId && lat && lng) {
        const filterWareHouse = _.get(response.data, 'warehouses').filter((item) => {
          return (
            item.selected === true
          );
        });

        const dataSelect = _.get(filterWareHouse, '[0].distance') ? _.get(filterWareHouse, '[0]') : undefined;
        setSelectWareHouseItem(dataSelect);
      }
      setDataListItem(_.get(response.data, 'tables'));
      setDefaultProductsItem(_.get(response.data, 'products'));
      setDefaultVehiclesItem(_.get(response.data, 'vehicles'));
      setDefaultUnitsItem(_.get(response.data, 'units'));
      setWarehouseDataItem(_.get(response.data, 'warehouses'));
    };
    getItem();
  }, [
    _.get(selectWareHouseItem, 'warehouseId'),
    _.get(selectCustomerItem, 'customerId'),
    _.get(selectAddressItem, 'customerAddressBookId'),
    selectProductsItem.length,
    selectVehiclesItem.length,
  ]);

  const getItemAllApi = async () => {

    let wareHouseId = _.get(selectWareHouseItem, 'warehouseId');
    let customerId = _.get(selectCustomerItem, 'customerId');
    let lat = _.get(selectAddressItem, 'lat');
    let lng = _.get(selectAddressItem, 'lng');
    let productItem = selectProductsItem.length < 1 ? undefined : selectProductsItem;
    let vehiclesItem = selectVehiclesItem.length < 1 ? undefined : selectVehiclesItem;
    let distance = _.get(selectWareHouseItem, 'distance') ? _.get(selectWareHouseItem, 'distance') : _.get(quotationDataDefault, 'wareHourse[0].distance');
    const response = await getItemAll(
      customerId,
      lat,
      lng,
      distance,
      wareHouseId,
      productItem,
      vehiclesItem,
    );

    const filterWareHouse = _.get(response.data, 'warehouses').filter((item) => {
      return (
        item.selected === true
      );
    });
    const dataSelect = _.get(filterWareHouse, '[0].distance') ? _.get(filterWareHouse, '[0]') : undefined;
    setSelectWareHouseItem(dataSelect);
    setDataListItem(_.get(response.data, 'tables'));
    setDefaultProductsItem(_.get(response.data, 'products'));
    setDefaultVehiclesItem(_.get(response.data, 'vehicles'));
    setDefaultUnitsItem(_.get(response.data, 'units'));
    setWarehouseDataItem(_.get(response.data, 'warehouses'))
  };

  useEffect(() => {
    if (_.size(productList) > 0) {
      let formProduct = formRefVehicle.getFieldValue('productList');
      let formProductObj = { ...formProduct };

      let productKeySelete = [];
      let productListForm = [];

      const pricesListArray = _.map(formProductObj, (val, key) => {
        return { productId: key, productPricesList: val };
      });

      productList.map((item) => {
        productKeySelete.push(...item.productPricesList);
      });

      pricesListArray.map((item) => {
        productListForm.push(...item.productPricesList);
      });

      const pricesProducts = productListForm.filter((item) =>
        productKeySelete.includes(item.productPriceId),
      );

      let resultSum = pricesProducts.reduce(function (acc, obj) {
        return acc + Number(obj.total);
      }, 0);

      setTimeout(() => {
        fnc.setSubTotalResult(_.floor(resultSum, 2).toFixed(2));
      }, 500);
    }
  }, [productList]);

  // const subTotalResult = useMemo(() => {
  //   const totalItemPart = itemObjectData && itemObjectData.map(item => { return parseFloat(item.totalPrice) });
  //   const numbers = totalItemPart.map((n) => { return parseFloat(n, 10); });
  //   const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
  //   return _.floor(sumTotal, 2).toFixed(2)
  // }, [itemObjectData.length]);

  let valueSubTotalResult = parseFloat(subTotalResult).toFixed(2);

  const handleSave = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
    });

    formRefVehicle.validateFields(async (err, valuesVihicle) => {
      if (err) {
        return;
      }

      let productListKey = valuesVihicle.productList;
      const newArray = _.map(productListKey, (val, key) => {
        return { productId: key, productPricesList: val };
      });

      let vehicleProduct = [];

      for (let i = 0; i < _.size(productList); i++) {
        for (
          let ele = 0;
          ele < productList[i].productPricesList.length;
          ele++
        ) {
          const productMarster = _.find(newArray, [
            'productId',
            productList[i].productId,
          ]);

          if (productMarster) {
            const product = _.find(productMarster.productPricesList, [
              'productPriceId',
              productList[i].productPricesList[ele],
            ]);
            vehicleProduct.push(product);
          }
        }
      }

      const newProduct = vehicleProduct.map((item) => {
        const unitName = _.find(defaultUnitsitem, ['unitId', item.unit]);

        return {
          productId: item.productId,
          itemId: item.productPriceId,
          itemTypeId: item.productPriceTypeId,
          itemCode: item.vehicleCode,
          itemName: item.productName + item.vehicleName,
          itemUnitId: item.unit,
          itemUnitName: unitName.unitName,
          price: item.unitPrice,
          qty: item.qty,
          totalPrice: item.total,
          productName: item.productName,
        };
      });

      setSelectCustomer(selectCustomerItem);
      setItemObjectData(newProduct);
      formRefVehicle.resetFields();
      formRef.resetFields();
      setselectVehiclesItem([]);
      setselectProductsItem([]);
      setProductKey([]);
      setIndeterminate([]);
      setProductListKey([]);
      handleCancelItem();
      setTimeout(() => {
        setSelectWareHouse(selectWareHouseItem);
        if (selectAddressItem) {
          setSelectAddress(selectAddressItem);
        }
        if (setSelectContact) {
          if (checkCustomer === true) {
            setSelectContact();
          }
        }
      }, 500);
      setStatusSet(true);
      setCheckCustomer(false);
    });
  };

  const valuesChangeItem = debounce((values, allValues) => {
    let productKeySelete = [];
    let productListForm = [];
    productList &&
      productList.map((item) => {
        productKeySelete.push(...item.productPricesList);
      });

    const pricesListArray = _.map(allValues.productList, (val, key) => {
      return { productId: key, productPricesList: val };
    });

    pricesListArray.map((item) => {
      productListForm.push(...item.productPricesList);
    });

    const pricesProducts = productListForm.filter((item) =>
      productKeySelete.includes(item.productPriceId),
    );

    let resultSum = pricesProducts.reduce(function (acc, obj) {
      return acc + Number(obj.total);
    }, 0);

    if (Object.keys(values).toString() === 'productList') {
      let productObjKey = Object.keys(values).toString();
      let itemArr;
      let itemKey;

      let productListArray = values.productList;
      const newArray = _.map(productListArray, (val, key) => {
        itemArr = val;
        itemKey = key;
      });

      let itemIndex = itemArr.length - 1;
      let fieldsName = Object.keys(itemArr[itemIndex]).toString();

      // ------------------------------calculate----------------------------------------
      switch (fieldsName) {
        case 'unitPrice':
          let objItem1 = pricesListArray.filter(
            (item) => item.productId === itemKey,
          )[0].productPricesList[itemIndex];
          let result1 = objItem1.unitPrice * objItem1.qty;
          let unite1 = `${productObjKey}[${itemKey}].[${itemIndex}].total`;
          formRefVehicle.setFieldsValue({
            [unite1]: _.floor(result1, 2).toFixed(2),
          });
          break;
        case 'qty':
          let objItem2 = pricesListArray.filter(
            (item) => item.productId === itemKey,
          )[0].productPricesList[itemIndex];
          let result2 = objItem2.unitPrice * objItem2.qty;
          let unite2 = `${productObjKey}[${itemKey}].[${itemIndex}].total`;
          formRefVehicle.setFieldsValue({
            [unite2]: _.floor(result2, 2).toFixed(2),
          });
          break;
        case 'total':
          let objItemTotal = pricesListArray.filter(
            (item) => item.productId === itemKey,
          )[0].productPricesList[itemIndex];
          let priceTotal =
            objItemTotal.total === null || objItemTotal.total === ''
              ? 0
              : objItemTotal.total;
          let resultTotal = priceTotal / objItemTotal.qty;
          let unitPrice = `${productObjKey}[${itemKey}].[${itemIndex}].unitPrice`;
          let uniteTotal = `${productObjKey}[${itemKey}].[${itemIndex}].total`;
          let itemArrPriceTotal =
            itemArr[itemIndex].total === null || itemArr[itemIndex].total === ''
              ? 0
              : itemArr[itemIndex].total;
          formRefVehicle.setFieldsValue({
            [unitPrice]: _.floor(resultTotal, 2).toFixed(2),
          });
          formRefVehicle.setFieldsValue({ [uniteTotal]: itemArrPriceTotal });
          break;
        default:
          break;
      }
    }
    fnc.setSubTotalResult(_.floor(resultSum, 2).toFixed(2)); // คำนาณ Products ผลลัพธ์
  }, 500);

  const handleCancel = () => {
    formRefVehicle.resetFields();
    formRef.resetFields();
    if (quotationDataDefault !== undefined) {
      const resultDiscount =
        discountData &&
        discountData.filter((discountData) => {
          return discountData.discountId === quotationDataDefault.discountId;
        });
      fnc.setDiscountSelete(resultDiscount[0]);
    } else {
      const resultDiscount =
        discountData &&
        discountData.find((discountData) => {
          return discountData.defaultData === true;
        });
      fnc.setDiscountSelete(resultDiscount);
    }
    if (subTotalResult !== 0.0) {
      fnc.setSubTotalResult(0.0);
    }

    fnc.setValueInputDiscount(0.0);

    setSelectAdressItem(selectAddress);
    setSelectWareHouseItem(selectWareHouse);
    setselectCustomerItem(selectCustomer);
    handleCancelItem();
    setselectVehiclesItem([]);
    setselectProductsItem([]);
    setIndeterminate([]);
    setCheckCustomer(false);
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const saveFormRefVehicle = useCallback((node) => {
    if (node !== null) {
      setFormRefVehicle(node);
    }
  }, []);

  return (
    <Modal
      title={intl.formatMessage({ id: 'ModalQuotationItemHeaderModal', defaultMessage: 'Add Item' })}
      visible={visibleAddItem}
      width={1400}
      centered
      bodyStyle={{ height: '594px' }}
      className="body-add-item"
      onOk={handleSave}
      onCancel={handleCancel}
      footer={[
        <>
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>
          <Button01
            key="submit"
            type="primary"
            btnsize="wd_df"
            onClick={handleSave}
          >
            <FormattedMessage id="btnApply" defaultMessage="Apply" />
          </Button01>
        </>,
      ]}
    >
      {/* <Spin spinning={loading}> */}
      <div className="container-body">
        <div className="container-body-left">
          <BusinessPartnerDetail
            ref={saveFormRef}
            customer={customerData}
            addressData={addressData}
            selectCustomer={selectCustomer}
            selectAddress={selectAddress}
            selectWareHouse={selectWareHouse}
            setPolyline={setPolyline}
            polyline={polyline}
            setDistance={setDistance}
            distance={distance}
            setDistanceMeter={setDistanceMeter}
            marker={marker}
            setMarker={setMarker}
            setLoading={setLoading}
            setselectCustomerItem={setselectCustomerItem}
            setSelectAdressItem={setSelectAdressItem}
            setSelectWareHouseItem={setSelectWareHouseItem}
            selectAddressItem={selectAddressItem}
            selectWareHouseItem={selectWareHouseItem}
            setVisibleAddCustomer={setVisibleAddCustomer}
            addCustomerData={addCustomerData}
            loading={loading}
            setVisibleAddAddress={setVisibleAddAddress}
            addAddressData={addAddressData}
            setDataDistance={setDataDistance}
            dataEdit={dataEdit}
            setCheckCustomer={setCheckCustomer}
            warehouseData={warehouseDataItem}
            getItemAllApi={getItemAllApi}
            setselectProductsItem={setselectProductsItem}
            setselectVehiclesItem={setselectVehiclesItem}
          />
        </div>
        <Divider type="vertical" className="divider-item-space" />
        <div className="container-body-right">
          <div className="container-body-right-vehicleItem">
            <VehicleItem
              ref={saveFormRefVehicle}
              selectWareHouse={selectWareHouse}
              selectWareHouseItem={selectWareHouseItem}
              warehouseData={warehouseDataItem}
              selectAddressItem={selectAddressItem}
              setSelectWareHouseItem={setSelectWareHouseItem}
              setselectProductsItem={setselectProductsItem}
              setselectVehiclesItem={setselectVehiclesItem}
              products={defaultProductsItem}
              vehicles={defaultVehiclesItem}
              defaultUnits={defaultUnitsitem}
              dataListItem={dataListItem}
              setProductList={setProductList}
              quotationDataDefault={quotationDataDefault}
              itemObjectData={itemObjectData}
              visibleAddItem={visibleAddItem}
              valuesChangeItem={valuesChangeItem}
              productKey={productKey}
              setProductKey={setProductKey}
              indeterminate={indeterminate}
              setIndeterminate={setIndeterminate}
              productListKey={productListKey}
              setProductListKey={setProductListKey}
              getItemAllApi={getItemAllApi}
              selectProductsItem={selectProductsItem}
              selectVehiclesItem={selectVehiclesItem}
              addCustomerData={addCustomerData}
              triggerAddress={triggerAddress}
              selectCustomerItem={selectCustomerItem}
            />
          </div>

          <Row style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: '50%' }} />
            <div style={{ width: '50%' }}>
              <div className="container-body-right-calculate">
                <CalculateV1
                  discountSelete={discountSelete}
                  taxSelete={taxSelete}
                  taxData={taxData}
                  discountData={discountData}
                  valueInputDiscount={valueInputDiscount}
                  subTotalResult={subTotalResult}
                  valueSubTotalResult={valueSubTotalResult}
                  setValues={{
                    sumCalculate: setSumCalculate,
                  }}
                  fnc={{
                    onChangeDiscount: onChangeDiscount,
                    onChangeTax: onChangeTax,
                    onChangeInput: onChangeInput,
                  }}
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* </Spin> */}

      <AddCustomer
        visible={visibleAddCustomer}
        setVisible={setVisibleAddCustomer}
        setTrigger={setTriggerCustomer}
        setCustomerData={setAddCustomerData}
      />

      <AddAddress
        visible={visibleAddAddress}
        setVisible={setVisibleAddAddress}
        customerId={
          _.get(selectCustomerItem, 'customerId')
            ? _.get(selectCustomerItem, 'customerId')
            : _.get(selectCustomer, 'customerId')
        }
        customerName={
          _.get(selectCustomerItem, 'customerName')
            ? _.get(selectCustomerItem, 'customerName')
            : _.get(selectCustomer, 'customerName')
        }
        setTrigger={setTriggerAddress}
        toLocation={setAddAddressData}
      />
    </Modal>
  );
};
