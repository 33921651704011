import httpCiient from '../../components/axiosClient';
import axios from 'axios';

const comCode = localStorage.getItem('comCode');
const comId = localStorage.getItem('comId');

const uploadFileApiNew = async (payload) => {
  try {
    const response = await httpCiient.post(`/file/manager/company/${comCode}/import-data/excel`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

const getPreviewFileApiNew = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/preview-exceldatav2`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const saveFileNew = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/create-dataimportv2`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};


export {
  uploadFileApiNew,
  getPreviewFileApiNew,
  saveFileNew,


};
