import React, { useState } from "react";
import styled from "styled-components";
import { useLayer, Arrow } from "react-laag";
import Task from './task/task';


const StyledMarker = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  background-color: lightgreen;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
  }
`;
const InfoBox = styled.div`
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 1000;
`;

export default function Marker({ task, resource, setStateMain, stateMain, intl }) {
  const [isOpen, setOpen] = useState(false);
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false)
  });
  return (
    <>
      <img
        {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
        src={task.pathTaskStatus} 
        style={{ zIndex: -1, width: '50px', height: '50px' }}
        key={task.taskId}
      />
      {isOpen &&
        renderLayer(
          <InfoBox {...layerProps}>
            <Task
              key={task.taskId}
              task={task}
              resource={resource}
              setStateMain={setStateMain}
              stateMain={stateMain}
              intl={intl}
            />
            <Arrow {...arrowProps} />
          </InfoBox>
        )}
    </>
  );
}
