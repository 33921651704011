import { Select, Row, Col, DatePicker, Dropdown, Icon, Menu, Checkbox, Button, TreeSelect } from 'antd'
import React from 'react'
import './css/index.css'
import { FormattedMessage, useIntl } from 'react-intl';
import Button01 from '../../../../components/v2/button_01';
import moment from 'moment';

const { Option } = Select
const { RangePicker } = DatePicker

export default (props) => {
  const { team, columns, checkedValuecolumns, defaultShowColumn, textErrorSelectColumn,
    handleCheckOk, handleReset, handleVisibleChange, visible, handleOnVisiblecolumns, teamData,
    selectTreeNode, memberData, selectTeam, mainDateStart, mainDateEnd, onChangeDate, member } = props

  const intl = useIntl()
  const ignoreColumn = [
    'index',
    'date',
  ];

  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));

  const menuColumn = () => {
    return (
      <Menu className="menu-columns">
        <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={checkedValuecolumns}
                checked={defaultShowColumn[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleCheckOk}
              style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button
              onClick={handleReset}
              style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    )
  }


  return (
    <div>
      <Row gutter={[8]} className='driver-expense-action'>
        <Col span={4}>
          <TreeSelect
            className="driver-expense-action-tree-select"
            showSearch
            dropdownStyle={{ maxHeight: '350px', overflow: 'auto', width: '260px' }}
            placeholder={intl.formatMessage({ id: 'driverExpenseReportSelectTeam', defaultMessage: 'Please Select' })}
            treeNodeFilterProp={'title'}
            treeDefaultExpandAll
            treeData={teamData}
            onChange={selectTreeNode}
          ></TreeSelect>
        </Col>
        <Col span={4}>
          <Select placeholder={intl.formatMessage({ id: 'driverExpenseReportSelectMember', defaultMessage: 'Select member' })}
            className='driver-expense-action-select-2'
            disabled={team ? false : true}
            onSelect={selectTeam}
            value={member}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              memberData && memberData.map(item => (
                <Option key={item.mem_com_id}>
                  {item.fullname}
                </Option>
              ))
            }
          </Select>
        </Col>
        <Col span={14} className='driver-expense-action-range-picker'>
          <RangePicker
            className='driver-expense-action-range-picker-style'
            format='ddd, MMM DD YYYY'
            value={[moment(mainDateStart, 'ddd, MMM DD YYYY'), moment(mainDateEnd, 'ddd, MMM DD YYYY')]}
            onChange={onChangeDate}
            allowClear={false}
            dropdownClassName='driver-expense-action-range-picker-dropdown'
            size="small"
          />
        </Col>
        <Col span={2}>
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={menuColumn}
            onVisibleChange={handleVisibleChange}
            visible={visible}
          >
            <Button01
              type="primary"
              btnsize="wd_lg"
              fontSize="sm"
              style={{ margin: 'unset' }}
              onClick={handleOnVisiblecolumns}
            >
              <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" />{" "}
              <Icon type="down" />
            </Button01>
          </Dropdown>
        </Col>
      </Row>

    </div>
  )
}
