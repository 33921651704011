import httpClient from '../../components/axiosClient';
import httpClientNoKeyCloak from '../../components/axiosClientNoKeyCloak'


const getDefaultItemAndVehicle = async () => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v3/kg-trading/manager/company/${comCode}/get/initial/product-price`);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}


const getItemAll = async (customerId, lat, lng, distance, warehouseId, productId, itemCode) => {
  const comCode = localStorage.getItem('comCode');

  const body = {
    customerId: customerId,
    lat: lat,
    lng: lng,
    distance_: distance,
    warehouseId: warehouseId,
    itemCode: itemCode,
    productId: productId
  }

  try {
    const response = await httpClient.post(`/v3/kg-trading/manager/company/${comCode}/find/product-price/`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}


const getItemQuotation = async (startDate, endDate, search, searchBy, orderBy, orderType, paging, rowsPerPages) => {
  const comCode = localStorage.getItem('comCode');

  const body = {
    startDate: startDate,
    endDate: endDate,
    search: search,
    searchBy: searchBy,
    orderType: orderType,
    orderBy: orderBy,
    paging: search ? 1 : paging,
    rowsPerPages: rowsPerPages
  }

  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/quaotation/getdata/search`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}


const getDefaultStarted = async () => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/datastarted`);
    if (response.status === 200) {
      return response.data;
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}


const updateStatus = async (quotationId, status, comment, reasonId) => {
  const comCode = localStorage.getItem('comCode');

  if (status === 'deleted') {
    const body = {
      deleted: true
    }
    try {
      const response = await httpClient.put(`/v3/quotation/manager/company/${comCode}/quaotation/updatedata/${quotationId}`, body);
      if (response.status === 200) {
        return response.data;
      } else {
        return ([]);
      }
    } catch (error) {
      return ([]);
    }
  } else {
    const body = {
      status: status,
      comment: comment,
      reasonId: reasonId
    }

    try {
      const response = await httpClient.put(`/v3/quotation/manager/company/${comCode}/quaotation/updatedata/${quotationId}`, body);
      if (response.status === 200) {
        return response.data;
      } else {
        return ([]);
      }
    } catch (error) {
      return ([]);
    }
  }


}


const createQuotation = async (data) => {
  const comCode = localStorage.getItem('comCode');
  const { status, customerId, addressbookId, contactId, memComId, warehouseId, remark, taxId, discountId, subTotal, discount, tax, total, print, detail } = data;

  const body = {
    status: status,
    customerId: customerId,
    addressbookId: addressbookId,
    contactId: contactId,
    memComId: memComId,
    warehouseId: warehouseId,
    remark: remark,
    taxId: taxId,
    discountId: discountId,
    subTotal: subTotal,
    discount: discount,
    tax: tax,
    total: total,
    print: print,
    detail: detail
  }

  try {
    const response = await httpClient.post(`/v3/quotation/manager/company/${comCode}/quaotation/createdata`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const editQuotation = async (data) => {
  const comCode = localStorage.getItem('comCode');
  const { status, customerId, addressbookId, contactId, memComId, warehouseId, remark, taxId, discountId, subTotal, discount, tax, total, print, detail, quotationId } = data;

  const body = {
    status: status,
    customerId: customerId,
    addressbookId: addressbookId,
    contactId: contactId,
    memComId: memComId,
    warehouseId: warehouseId,
    remark: remark,
    taxId: taxId,
    discountId: discountId,
    subTotal: subTotal,
    discount: discount,
    tax: tax,
    total: total,
    print: print,
    detail: detail
  }

  try {
    const response = await httpClient.put(`/v3/quotation/manager/company/${comCode}/quaotation/updatedata/${quotationId}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}



const getQuotationById = async (quotationId) => {
  const comCode = localStorage.getItem('comCode');
  // console.log('quotationId ::',quotationId)

  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/quaotation/${quotationId}`);
    // console.log('getQuotationById ::', response)
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getQuotationByIdWebView = async (quotationId,query) => {
  try {
    const response = await httpClientNoKeyCloak.get(`/v3/quotation/manager/company/${query.get('comCode')}/quaotation/${quotationId}/public/pdf?lang=${query.get('lang')}&pub=${query.get('pub')}`);
    // console.log('getQuotationById ::',response)
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    // console.log('getQuotationById ::',response)
    return [];
  }
}


const getMembers = async () => {
  const memComId = localStorage.getItem('memComId');
  const comId = localStorage.getItem('comId');

  const body = {
    com_id: `${comId}`,
    date: [],
    equal: [],
    index_page: "1",
    like: [],
    limit: "1000",
    notResign: true,
    order: "fullname asc",
    user_id: `${memComId}`,
  }

  try {
    const response = await httpClient.post(`/v2/searchusers`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getReason = async () => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/quotation/manager/company/${comCode}/reason/getdata`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}


const countPrint = async (id) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.put(`/v3/quotation/manager/company/${comCode}/quaotation/updatedata/${id}/print`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}





export { getDefaultStarted, getDefaultItemAndVehicle, getItemAll, getItemQuotation, updateStatus, createQuotation, getQuotationById, getMembers, getReason, editQuotation, countPrint, getQuotationByIdWebView };
