import React, { useContext, useState, useRef } from 'react'
import { Table, Popover, Button } from 'antd'
import { PurchaseContext } from './po-context'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import PurchaseOrderAction from './action'
import { PageSettings } from '../../config/page-settings'

const PurchaseOrderTable = (prop) => {
  const props = useContext(PurchaseContext);
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const { checkPermissionAction } = useContext(PageSettings)

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {
                record && record.statusCode !== "draft" ? (
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => props.handleEdit(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                  </div>
                ) : null
              }
              {
                record && record.statusCode === "draft" && checkPermissionAction('P56PG1C1', 'P56PG1C1A4') ? (
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => props.handleEdit(record)}>
                      <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                    </Button>
                  </div>
                ) : null
              }
              {
                record && record.statusCode !== "closed" && checkPermissionAction('P56PG1C1', 'P56PG1C1A5') ?
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => props.handleDelete(record.poId)}>
                      <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                    </Button>
                  </div>
                  : null
              }
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };


  return (
    <div style={{ height: 'calc(100vh - 190px)', overflowY: 'auto' }}>
      <PurchaseOrderAction />
      <div style={{ padding: '0px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          // className='po-table'
          columns={props.newDataColumns}
          dataSource={props.poList}
          scroll={{ x: _.sumBy(props.newDataColumns, 'width') }}
          onChange={props.handleChange}
          loading={props.loading}
          pagination={{
            total: props.total,
            current: props.paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        {props.total > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${props.total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PurchaseOrderTable
