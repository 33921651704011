import React, { useState, useEffect, useContext } from 'react';
import './index.css';
import { Card, Row, Col, Tabs, Dropdown, Icon, Menu, Modal, Divider, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../components/v2/button_01';
import BranchList from './component/branch';
import DeliveryDetail from './component/detail';
import DeliveryShipment from './component/shipment';
import DeliveryShipmentDetail from './component/shipment-detail';
import { StickyContainer, Sticky } from 'react-sticky';
import { DownOutlined } from '@ant-design/icons';
import NoDataBackground from '../../components/no-data-page';
import {
  getBranchList,
  deleteBranch,
  getBranchManager,
  getBranchManagerList,
  getBranchMemberList,
} from '../../controllers/delivery-order/branch';
import { getRole } from '../../controllers/role';
import { fncGetUsers } from '../../controllers/user/users';
import _ from 'lodash';
import { useDebounce } from '../../controllers/debounce';
import BranchModalForm from './component/branch/modal/modal';
import { successNotification, errorNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import {
  getStatus,
  getOrdersList,
  getOrdersListExport,
  getOrdersListNew,
  getOrdersListExportNew,
  getChangeNextStatus,
} from '../../controllers/orders-controller/api';
import { getSizeList } from '../../controllers/delivery-order/size';
import getCustomerApi from '../../controllers/getCustomer/get-customer-api';
import moment from 'moment';
import { useAppContext } from '../../includes/indexProvider';
import { getQueueMessages } from '../../hooks/use-queue-api';

const { TabPane } = Tabs;
export const DeliveryContext = React.createContext();

const DeliveryOrderNew = () => {
  const intl = useIntl();
  const { checkPermissionAction } = useContext(PageSettings);
  const app = useAppContext();
  const [toggle, setToggle] = useState(false);
  const [visibleBranch, setVisibleBranch] = useState(false);
  const [searchBranch, setSearchBranch] = useState();
  const [sizeBranch, setSizeBranch] = useState(20);
  const debouceSearchBranch = useDebounce(searchBranch, 500);
  const [selectBranch, setSelectBranch] = useState();
  const [selectBranchData, setSelectBranchData] = useState();
  const [selectShipmentData, setSelectShipmentData] = useState();
  const [branchList, setBranchList] = useState([]);
  const [branchDataFormResponse, setBranchDataFormResponse] = useState();
  const [recordbranch, setRecordbranch] = useState();
  const [trigger, setTrigger] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberTotal, setMemberTotal] = useState(0);
  const [memberLoading, setMemberLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderData, setOrderData] = useState();
  const [roleList, setRoleList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [manageList, setManageList] = useState([]);
  const [memberAll, setMemberAll] = useState([]);
  const [triggerManager, setTriggerManager] = useState(false);
  const [triggerMember, setTriggerMember] = useState(false);
  const [triggerOrder, setTriggerOrder] = useState(false);
  const [memberField, setMemberField] = useState('email');
  const [memberOrder, setMemberOrder] = useState('asc');
  const [memberPage, setMemberPage] = useState(1);
  const [memberSize, setMemberSize] = useState(10);
  const [memberInput, setMemberInput] = useState('');
  const [memberColumns, setMemberColumns] = useState('all');
  const debouceSearchMember = useDebounce(memberInput, 500);

  const [shipmentField, setShipmentField] = useState();
  const [shipmentOrder, setShipmentOrder] = useState();
  const [shipmentPage, setShipmentPage] = useState(1);
  const [shipmentSize, setShipmentSize] = useState(10);
  const [shipmentInput, setShipmentInput] = useState('');
  const debouceSearchShipment = useDebounce(shipmentInput, 500);

  const [visibleCreateOrder, setVisibleCreateOrder] = useState(false);
  const [statusData, setStatusData] = useState();
  const [sizeList, setSizeList] = useState([]);

  const [openImport, setOpenImport] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [searchOrder, setSearchOrder] = useState({ searchDate: [moment().startOf('month'), moment().endOf('month')] });
  const debouceSearchOrder = useDebounce(searchOrder, 500);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listCreate, setListCreate] = useState([]);
  const [isApiStatusNext, setIsApiStatusNext] = useState(false)
  const [nextStatusPageList, setNextStatusPageList] = useState([])
  const [loading, setLoading] = useState(false)

  //-----------------------------------------------------
  const [visibleViewOrder, setVisibleViewOrder] = useState(false);
  const [viewData, setViewData] = useState([])

  useEffect(() => {
    const initialData = async () => {
      const comId = localStorage.getItem('comId');
      const memComId = localStorage.getItem('memComId');
      const managerData = await getBranchManager();
      const roleData = await getRole();
      const userData = await fncGetUsers(memComId, comId, true);
      const statusList = await getStatus();
      const sizeData = await getSizeList({
        where: {},
        page: 1,
        limit: 100000,
      });
      const dataCustomer = await getCustomerApi(10000);
      setCustomerData(_.get(dataCustomer, 'customers'));
      const filter = _.get(statusList, 'data.statusList').filter((item) => {
        return item.statusCode === 'open';
      });
      setStatusData(_.get(filter, '[0]'));
      setManagerList(_.get(managerData, 'data.data.branchManagerCode'));
      setRoleList(_.get(roleData, 'data.data'));
      setUserList(userData);
      setSizeList(_.get(sizeData, 'data.data.list'));
    };
    initialData();
  }, []);

  console.log('searchOrder', searchOrder);

  useEffect(() => {
    if (_.size(listCreate) > 0 && isApiStatusNext) {
      getNextStatus(_.get(listCreate, '[0]'))
      setIsApiStatusNext(false)
      
    } else if (_.size(listCreate) === 0){
      setIsApiStatusNext(true)
      setNextStatusPageList([])
    }
  }, [listCreate])

  useEffect(() => {
    setListCreate()
    setSelectedRowKeys()
  }, [orderList])

  const getNextStatus = async (record) => {
    const response = await getChangeNextStatus(record._id)
    const filterResponse = _.filter(response.data.data.statusList, (list) => list.statusCode !== record.orderStatusCode)
    setNextStatusPageList(filterResponse)
  }

  console.log('nextStatusPageList', nextStatusPageList)

  useEffect(() => {
    const getOrderList = async () => {
      console.log('TGTGTG', trigger);
      const body = {
        orderStatus: _.get(searchOrder, 'status'),
        orderTypeCode: _.get(searchOrder, 'orderType') ? [_.get(searchOrder, 'orderType')] : undefined,
        detailTypeCode: _.get(searchOrder, 'type') ? [_.get(searchOrder, 'type')] : undefined,
        customerName: _.get(searchOrder, 'customer'),
        orderNo: _.get(searchOrder, 'doNo'),
        source: _.get(searchOrder, 'source'),
        destination: _.get(searchOrder, 'destination'),
        fromDate: _.get(searchOrder, 'searchDate[0]') ? moment(_.get(searchOrder, 'searchDate[0]')).format('YYYY-MM-DD') : undefined,
        toDate: _.get(searchOrder, 'searchDate[1]') ? moment(_.get(searchOrder, 'searchDate[1]')).format('YYYY-MM-DD') : undefined,
        orderBy: shipmentField,
        orderType: shipmentOrder,
        pageNumber: shipmentPage,
        limit: shipmentSize,
        saleOrderNo: _.get(searchOrder, 'saleOrder.saleOrderNo') ? [_.get(searchOrder, 'saleOrder.saleOrderNo')] : undefined,
        branchId: _.get(searchOrder, 'store.id') ? [_.get(searchOrder, 'store.id')] : undefined,
      };
      const response = await getOrdersListNew(body);

      setOrderList(_.get(response, 'data.list') || []);
      setOrderTotal(_.get(response, 'data.orderCount') || 0);
      setOrderData(response);
    };

    getOrderList();
  }, [_.get(searchOrder, 'searchDate'), trigger, shipmentField, shipmentOrder, shipmentPage, shipmentSize]);

  const onScrollBranch = (e) => {
    const target = e.target;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      setSizeBranch((prevValue) => prevValue + 20);
    }
  };

  useEffect(() => {
    if (branchDataFormResponse) {
      let newArr = [];
      const filterBranch = _.filter(branchList, (item) => {
        return item.id === _.get(branchDataFormResponse, 'id');
      });
      const mergedObject = Object.assign({}, _.get(filterBranch, '[0]'), branchDataFormResponse);
      newArr.push(mergedObject, ...branchList);
      setBranchList(_.uniqBy(newArr, 'id'));
    }
  }, [branchDataFormResponse]);

  console.log('branchList', branchList);

  const handleModalBranch = () => {
    setVisibleBranch(true);
  };

  const handleSearchBranch = (e) => {
    setSearchBranch(e.target.value);
  };

  const handleSelectBranch = (value, data) => {
    setSelectBranch(value);
    setSelectBranchData(data);
    setShipmentPage(1);
    setShipmentSize(10);
    setSearchOrder((prev) => {
      _.get(prev, 'searchDate');
    });
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleEditBranch = (data) => {
    setRecordbranch(data);
    setVisibleBranch(true);
  };

  const handleClickShipment = (record) => {
    setSelectShipmentData(record);
  };

  const handleExportOrder = async () => {
    const payload = {
      orderStatus: _.get(searchOrder, 'status'),
      orderTypeCode: _.get(searchOrder, 'orderType') ? [_.get(searchOrder, 'orderType')] : undefined,
      detailTypeCode: _.get(searchOrder, 'type') ? [_.get(searchOrder, 'type')] : undefined,
      customerName: _.get(searchOrder, 'customer.customerName'),
      orderNo: _.get(searchOrder, 'doNo'),
      source: _.get(searchOrder, 'source'),
      destination: _.get(searchOrder, 'destination'),
      fromDate: _.get(searchOrder, 'searchDate[0]') ? moment(_.get(searchOrder, 'searchDate[0]')).format('YYYY-MM-DD') : undefined,
      toDate: _.get(searchOrder, 'searchDate[1]') ? moment(_.get(searchOrder, 'searchDate[1]')).format('YYYY-MM-DD') : undefined,
      saleOrderNo: _.get(searchOrder, 'saleOrder.saleOrderNo') ? [_.get(searchOrder, 'saleOrder.saleOrderNo')] : undefined,
      branchId: _.get(searchOrder, 'store.id') ? [_.get(searchOrder, 'store.id')] : undefined,
      orderBy: shipmentField,
      orderType: shipmentOrder,
      pageNumber: 1,
      limit: orderTotal,
      viewFormat: [
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsDO', defaultMessage: 'DO No.' }),
          sequence: 1,
          colCode: 'orderNo',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsOrderType', defaultMessage: 'Order Type' }),
          sequence: 2,
          colCode: 'orderTypeCode',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsType', defaultMessage: 'Type' }),
          sequence: 3,
          colCode: 'detailTypeTxt',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsCustomer', defaultMessage: 'Customer' }),
          sequence: 4,
          colCode: 'customerName',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsContact', defaultMessage: 'Contact' }),
          sequence: 5,
          colCode: 'contact',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsPhone', defaultMessage: 'Phone' }),
          sequence: 6,
          colCode: 'customerContactPhone',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsOrderStatus', defaultMessage: 'Order Status' }),
          sequence: 7,
          colCode: 'orderStatus',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryColumnsTotalItem', defaultMessage: 'Total Item' }),
          sequence: 8,
          colCode: 'parcel',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentDestination', defaultMessage: 'Destination' }),
          sequence: 9,
          colCode: 'destination',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryColumnsDestinationAddress', defaultMessage: 'Destination Address' }),
          sequence: 10,
          colCode: 'destinationAddress',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryColumnsDestinationPostcode', defaultMessage: 'Destination Postcode' }),
          sequence: 11,
          colCode: 'destinationPostcode',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsDeliveryDate', defaultMessage: 'Delivery Date' }),
          sequence: 12,
          colCode: 'deliveryDate',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsDeliveryTime', defaultMessage: 'Delivery Time' }),
          sequence: 13,
          colCode: 'deliveryTime',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsSourceName', defaultMessage: 'Source Name' }),
          sequence: 14,
          colCode: 'source',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsSourceAddress', defaultMessage: 'Source Address' }),
          sequence: 15,
          colCode: 'sourceAddress',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryColumnsSourcePostcode', defaultMessage: 'Source Postcode' }),
          sequence: 16,
          colCode: 'sourcePostcode',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryColumnsStore', defaultMessage: 'Store' }),
          sequence: 17,
          colCode: 'branch',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryColumnsSoNo', defaultMessage: 'SO No' }),
          sequence: 18,
          colCode: 'saleOrderNo',
        },
       
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsReference', defaultMessage: 'Reference No.' }),
          sequence: 19,
          colCode: 'referenceNo',
        },
        {
          colName: intl.formatMessage({ id: 'deliveryShipmentColumnsRemark', defaultMessage: 'Remark' }),
          sequence: 20,
          colCode: 'remark',
        },
      ],
    };

    const response = await getOrdersListExportNew(payload);
    if (response.status === 200) {
      window.open(_.get(response, 'data.data.data.url'));
      successNotification(response.data.status.message);
    } else {
      errorNotification(response.data.status.message);
    }
  };

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={0}>
      {({ style }) => (
        <Row style={{ height: '52px' }}>
          <Col span={1} className="col-button-hide">
            <div style={{ cursor: 'pointer' }} onClick={handleToggle}>
              <Icon type={toggle ? 'menu-unfold' : 'menu-fold'} style={{ border: 'unset', marginLeft: '12px', color: '#e73845' }} />
            </div>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff', top: 'unset' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  const menuColumnShipment = () => {
    return (
      <Menu style={{width: '200px'}}>
        <Menu.Item key="1" onClick={() => setVisibleCreateOrder(true)}>
          <p key="1">
            <FormattedMessage id="deliveryOrderButtonCreateDeliveryOrder" defaultMessage="Create Delivery Order" />
          </p>
        </Menu.Item>
        <Menu.Item key="2">
          <p key="2" onClick={() => setOpenImport(true)}>
            <FormattedMessage id="deliveryOrderButtonImport" defaultMessage="Import Orders" />
          </p>
        </Menu.Item>
        <Menu.Item key="3">
          <p key="3" onClick={handleExportOrder}>
            <FormattedMessage id="deliveryOrderButtonExort" defaultMessage="Export All Orders" />
          </p>
        </Menu.Item>
        {_.size(nextStatusPageList) > 0 && <Divider style={{ margin: '10px 0px' }} />}
        

        {_.some(nextStatusPageList, item => item.statusCode === 'confirmed') && (
          <Menu.Item key="4" onClick={() => onChangeTo('confirmed')}>
            <p key="4">
              <FormattedMessage id="deliveryOrderConfirm" defaultMessage="Change to Confirm" />
            </p>
          </Menu.Item>
        )}
        {_.some(nextStatusPageList, item => item.statusCode === 'canceled') && (
          <Menu.Item key="5">
            <p key="5" onClick={() => onChangeTo('canceled')}>
              <FormattedMessage id="deliveryOrderCancel" defaultMessage="Change to Cancel" />
            </p>
          </Menu.Item>
        )}
          {_.some(nextStatusPageList, item => item.statusCode === 'open') && (
          <Menu.Item key="6">
            <p key="6" onClick={() => onChangeTo('open')}>
              <FormattedMessage id="deliveryOrderButtonOpen" defaultMessage="Change to Open" />
            </p>
          </Menu.Item>
        )}
            {_.some(nextStatusPageList, item => item.statusCode === 'rejected') && (
          <Menu.Item key="7">
            <p key="7" onClick={() => onChangeTo('rejected')}>
              <FormattedMessage id="deliveryOrderButtonRejected" defaultMessage="Change to Rejected" />
            </p>
          </Menu.Item>
        )}
        
      </Menu>
    );
  };

  const onChangeTo = async (status) => {
    setLoading(true);
    const comCode = localStorage.getItem('comCode');

    for(let i = 0; i < _.size(selectedRowKeys) ; i++) {
      const body = {
        orderStatus: status,
        comment: "",
      }
  
      const payload = {
        apiMethod: 'put',
        urlQueue: `/queue/manager/company/${comCode}/channgedeliveryorder`,
        apiUrl: `${process.env.REACT_APP_URL_MANAGER}/order-manager/v5/order/manager/company/${comCode}/web/deliveryorder-status/${selectedRowKeys[i]}`,
        payload: body,
      };

      try {
        const response = await getQueueMessages(payload);
        console.log('getQueueMessages data:', response);
        if (response.status.code === 200) {
          successNotification(_.get(response, 'status.message'));
        } else {
          errorNotification(_.get(response, 'status.message'));
        }
      } catch (error) {
        console.log('Error fetching queue messages:', error, error.response);
        errorNotification(_.get(error, 'response.data.status.message'));
      }
      console.log('onChangeTo', listCreate, payload)
    }

    console.log('onChangeTo isLoad', loading)

    setLoading(false)
    setTrigger((event) => !event);

  };

  const handleDeleteBranch = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteBranch(id);
          if (response.status === 200) {
            if (_.get(selectBranchData, 'id')) {
              let whId = _.get(selectBranchData, 'id');
              let findIndexArr = _.findIndex(branchList, function(o) {
                return o.id === whId;
              });
              if (findIndexArr > -1) {
                let filterArr = _.filter(branchList, function(o) {
                  return o.id !== whId;
                });
                setBranchList(filterArr);
              }
            }
            successNotification(_.get(response.data.status, 'message'));
            setSelectBranch();
            setSelectBranchData();
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      },
      onCancel() {},
    });
  };

  const handleCancelShipmentDetail = () => {
    setSelectShipmentData();
    setTriggerOrder((cur) => !cur);
    app.fnc.getCodeAndDataDrawer('', '');
  };

  const onFilterOrder = async (value, code) => {
    if (code === 'searchDate') setShipmentPage(1);
    setSearchOrder({ ...searchOrder, [code]: value });
  };

  return (
    <div>
      <DeliveryContext.Provider
        value={{
          handleSearchBranch,
          searchBranch,
          handleSelectBranch,
          selectBranch,
          selectBranchData,
          branchList,
          setBranchList,
          onScrollBranch,
          setBranchDataFormResponse,
          setRecordbranch,
          trigger,
          setTrigger,
          handleClickShipment,
          setSelectBranchData,
          managerList,
          roleList,
          userList,
          manageList,
          setTriggerManager,
          setTriggerMember,
          memberList,
          memberTotal,
          searchMember: {
            memberColumns,
            setMemberColumns,
            memberField,
            setMemberField,
            memberInput,
            setMemberInput,
            setMemberOrder,
            memberPage,
            setMemberPage,
            memberSize,
            setMemberSize,
          },
          visibleCreateOrder,
          setVisibleCreateOrder,
          statusData,
          orderList,
          orderTotal,
          searchShipment: {
            shipmentField,
            setShipmentField,
            shipmentInput,
            setShipmentInput,
            setShipmentOrder,
            shipmentPage,
            setShipmentPage,
            shipmentSize,
            setShipmentSize,
          },
          selectShipmentData,
          setSelectShipmentData,
          handleCancelShipmentDetail,
          setTriggerOrder,
          sizeList,
          memberAll,
          orderData,
          openImport,
          setOpenImport,
          memberLoading,
          loadingOrder,
          customerData,
          onFilterOrder,
          searchOrder,
          setSearchOrder,
          selectedRowKeys,
          setSelectedRowKeys,
          listCreate,
          setListCreate,
          visibleViewOrder,
          setVisibleViewOrder,
          viewData,
          setViewData,
        }}
      >
        {checkPermissionAction(`P58PG1C1`, `P58PG1C1A1`) ? (
          <div>
            <Row gutter={[16]}>
              <Col span={24}>
                {
                  <Spin spinning={loading}>
                  <Card
                    title={intl.formatMessage({ id: 'deliveryOrderCardTitle', defaultMessage: 'Delivery Orders' })}
                    bodyStyle={{ padding: 'unset', height: 'calc(100vh - 165px)', overflowY: 'auto' }}
                    extra={
                      checkPermissionAction('P58PG1C1', 'P58PG1C1A3') || checkPermissionAction('P58PG1C1', 'P58PG1C1A4') ? (
                        <div style={{paddingRight: '20px'}}>
                        <Dropdown overlay={menuColumnShipment} trigger={['click']} placement="bottomRight" className="ant-dropdown-custom">
                          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={(e) => e.preventDefault()} style={{margin: '10px -20px'}} >
                            <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                            <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                          </Button01>
                        </Dropdown>
                        </div>
                      ) : null
                    }
                  >
                    <StickyContainer>
                      <DeliveryShipment />
                    </StickyContainer>
                  </Card>
                  </Spin>
                }
              </Col>
            </Row>
            {/* )} */}
          </div>
        ) : null}

        <BranchModalForm visible={visibleBranch} setVisible={setVisibleBranch} record={recordbranch} setRecord={setRecordbranch} />
      </DeliveryContext.Provider>
    </div>
  );
};

export default DeliveryOrderNew;
