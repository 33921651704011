import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Row, Col, Switch, Divider, AutoComplete, Input, Select, Spin, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_01 from '../v2/button_01';
import Button_02 from '../v2/button_01';
import LabeRequireForm from '../label-required-form';
import { handleKeyDownNumber } from '../../component-function/fnc-number';
import _ from 'lodash';
import { getCustomer, getCustomerContact } from '../../controllers/customer-vendor/customer-vendor';
import { useDebounce } from '../../controllers/debounce';
import CustomerLocation from '../modal-select-customer-location/index'
import { useAppContext } from '../../includes/indexProvider';
import { successNotification, errorNotification } from '../v2/notification';
import { getQueueMessages } from '../../hooks/use-queue-api';
import ModalCreateItemDO from '../add-item-order-delivery';
import ModalParcelAdd from '../add-item-order-delivery/parcel';
import CustomTableComponent from './component/table';
import ModalQTYItemDO from '../add-item-order-delivery/qty';
import { v4 as uuidv4 } from 'uuid';
import { addItem, checkShipInCar } from '../../controllers/load-and-route/api';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import { getSaleOrderList } from '../../controllers/sale-order/sale-order-api-new';

const { Option } = Select;
const comCode = localStorage.getItem('comCode');

const DeliveryCreateOrder = (props) => {
  const {
    visible,
    setVisible,
    form,
    setTrigger,
    orderData,
    editData,
    setEditData,
    orderCheckType,
    bodyForCheck,
    bodyForAddLoad,
    setTriggerPlanning,
    menuCode,
    anyFunction,
    branchDetail,
  } = props;
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const intl = useIntl();
  const app = useAppContext();
  console.log('editData', editData);
  // const latlng =
  //   _.get(branchData, 'branchAddressLat') && _.get(branchData, 'branchAddressLng')
  //     ? {
  //         lat: _.get(branchData, 'branchAddressLat'),
  //         lng: _.get(branchData, 'branchAddressLng'),
  //       }
  //     : _.get(app, 'state.latlngCompany');
  const [customerList, setCustomerList] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState('');
  const [selectCustomer, setSelectCustomer] = useState();
  const debouceSearchBranch = useDebounce(searchCustomer, 1000);
  const [contactList, setContactList] = useState([]);
  const [selectContact, setSelectContact] = useState();

  const [visibleMapCusotmer, setVisibleMapCusotmer] = useState(false);
  const [selectFromAddress, setSelectFromAddress] = useState([]);
  const [createFromData, setCreateFromData] = useState();
  const [fromOutSide, setFromOutSide] = useState();
  const [triggerFrom, setTriggerFrom] = useState(false);
  const [visibleAddNewCompany, setVisibleAddNewCompany] = useState(false);
  const [statusFromAdd, setStatuFromsAdd] = useState('in');
  const [keyMenu, setKeyMenu] = useState([]);
  const [centerFrom, setCenterFrom] = useState();
  const [positionFrom, setPositionFrom] = useState();
  const [visibleInfoWindowFrom, setVisibleInfoWindowFrom] = useState(false);

  const [visibleMapContact, setVisibleMapContact] = useState(false);
  const [selectToAddress, setSelectToAddress] = useState([]);
  const [createToData, setCreateToData] = useState();
  const [toOutSide, setToOutSide] = useState();
  const [triggerTo, setTriggerTo] = useState(false);
  const [visibleAddNewCustomer, setVisibleAddNewCustomer] = useState(false);
  const [statusToAdd, setStatusToAdd] = useState('in');
  const [keyMenuTo, setKeyMenuTo] = useState([]);
  const [centerTo, setCenterTo] = useState();
  const [positionTo, setPositionTo] = useState();
  const [visibleInfoWindowTo, setVisibleInfoWindowTo] = useState(false);

  const [customerSwitch, setCustomerSwitch] = useState(false);
  const [recipientSwitch, setRecipientSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  //------------------------------------------------------
  const [visibleAddItem, setVisibleAddItem] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [tableData, setTableData] = useState([]);
  const [recordItem, setRecordItem] = useState();
  const [visibleEditItem, setEditItem] = useState(false);
  //----------------------------------------------------------
  const [visibleAddParcel, setVisibleAddParcel] = useState(false);
  const [recordParcel, setRecordParcel] = useState();
  //----------------------------------------
  const [saleOrderList, setSaleOrderList] = useState([]);
  const [selectSaleOrderList, setSelectSaleOrder] = useState([]);
  const [searchOrderList, setSearchOrderList] = useState('');
  const debouceSearchOrder = useDebounce(searchOrderList, 500);
  const inputSaleOrderRef = useRef();
  //-------------------------------------------------
  const [innit, setinnit] = useState(false);

  useEffect(() => {
    if (_.size(editData) > 0 && visible) {
      if (Object.keys(_.get(editData, 'sourceDetail')).length !== 0) {
        const formLocation = {
          address: _.get(editData, 'sourceDetail.address'),
          fullAddress: _.get(editData, 'sourceDetail.description'),
          lat: parseFloat(_.get(editData, 'sourceDetail.lat')),
          lng: parseFloat(_.get(editData, 'sourceDetail.lng')),
          name: _.get(editData, 'sourceDetail.address_name'),
          addressBookId: _.get(editData, 'sourceDetail.company_address_book_id')
            ? _.get(editData, 'sourceDetail.company_address_book_id')
            : uuidv4(),
          status: _.get(editData, 'sourceDetail.company_address_book_id') ? 'customer_address' : 'normal_address',
        };
        setSelectFromAddress([formLocation]);
        // setCustomerSwitch(_.get(editData, 'sourceDetail.company_address_book_id') ? true : false)
        setCreateFromData(formLocation);
        setCenterFrom({ lat: parseFloat(_.get(editData, 'sourceDetail.lat')), lng: parseFloat(_.get(editData, 'sourceDetail.lng')) });
        setPositionFrom({ lat: parseFloat(_.get(editData, 'sourceDetail.lat')), lng: parseFloat(_.get(editData, 'sourceDetail.lng')) });
      }
      const toLocation = {
        address: _.get(editData, 'destinationDetail.address'),
        fullAddress: _.get(editData, 'destinationDetail.description'),
        lat: parseFloat(_.get(editData, 'destinationDetail.lat')),
        lng: parseFloat(_.get(editData, 'destinationDetail.lng')),
        name: _.get(editData, 'destinationDetail.address_name'),
        addressBookId: _.get(editData, 'destinationDetail.customer_address_book_id')
          ? _.get(editData, 'destinationDetail.customer_address_book_id')
          : uuidv4(),
        status: _.get(editData, 'destinationDetail.customer_address_book_id') ? 'customer_address' : 'normal_address',
      };
      setSearchCustomer(_.get(editData, 'customerDetail.comtomerName'));
      setSelectCustomer(_.get(editData, 'customerDetail.customerId') ? _.get(editData, 'customerDetail') : undefined);
      setSelectContact(_.get(editData, 'contactDetail.customerContactId') ? _.get(editData, 'contactDetail') : undefined);
      // setCustomerSwitch(_.get(editData, 'sourceDetail.company_address_book_id') || _.get(editData, 'destinationDetail.customer_address_book_id') ? true : false)
      // setRecipientSwitch(_.get(editData, 'destinationDetail.customer_address_book_id') ? true : false)
      setSelectToAddress([toLocation]);
      setCreateToData(toLocation);
      setCenterTo({ lat: parseFloat(_.get(editData, 'destinationDetail.lat')), lng: parseFloat(_.get(editData, 'destinationDetail.lng')) });
      setPositionTo({
        lat: parseFloat(_.get(editData, 'destinationDetail.lat')),
        lng: parseFloat(_.get(editData, 'destinationDetail.lng')),
      });

      setFieldsValue({
        ['ordertype']: _.get(editData, 'detailTypeCode'),
        ['customerName']: _.get(editData, 'customerDetail.customerId')
          ? _.get(editData, 'customerDetail.customerId')
          : _.get(editData, 'customerDetail.comtomerName'),
        ['customerPhone']: _.get(editData, 'customerDetail.phone'),
        ['customerPostcode']: _.get(editData, 'sourceDetail.postcode'),
        ['recipientName']: _.get(editData, 'contactDetail.customerContactId')
          ? _.get(editData, 'contactDetail.customerContactId')
          : _.get(editData, 'contactDetail.customerContactName'),
        ['recipientPhone']: _.get(editData, 'contactDetail.customerContactPhone'),
        ['recipientPostcode']: _.get(editData, 'destinationDetail.postcode'),
        ['parcel']: _.size(_.get(editData, 'pacelDetails')),
        ['refNo']: _.get(editData, 'referenceNo'),
        ['remark']: _.get(editData, 'remark'),
      });

      setTableData(_.get(editData, 'table'));
    }
  }, [editData, visible]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: intl.formatMessage({ id: 'deliveryModalColumnItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      // width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryModalColumnItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      // width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryModalColumnQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      align: 'right',
      sorter: true,
      // width: 100,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryModalColumnUOM', defaultMessage: 'UOM' }),
      dataIndex: 'uom',
      key: 'uom',
      sorter: true,
      // width: 100,
      default: true,
    },
  ];

  const columnsParcel = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: intl.formatMessage({ id: 'deliveryOrderParcelNo', defaultMessage: 'Parcel No.' }),
      dataIndex: 'parcelNo',
      key: 'parcelNo',
      sorter: true,
      // width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryOrderSize', defaultMessage: 'Size' }),
      dataIndex: 'size.sizeName',
      key: 'size.sizeName',
      sorter: true,
      // width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryOrderWeight', defaultMessage: 'Weight (kg.)' }),
      dataIndex: 'weight',
      key: 'weight',
      // align: 'right',
      sorter: true,
      // width: 150,
      default: true,
      render: (text) => numberFormatter(text),
    },
  ];

  const handleCheckBeforeSave = async (value) => {
    setLoading(true);
    if (orderCheckType === 'deliveryOrderParcel' && menuCode === 'loadPlanning') {
      const response = await checkShipInCar(bodyForCheck);

      if (_.get(response, 'data.status') === 200) {
        successNotification(_.get(response, 'data.message'));
        handleSave('save');
      } else {
        errorNotification(_.get(response, 'data.message'));
        setLoading(false);
        handleCancel();
      }
    } else {
      handleSave('save');
    }
  };

  const handleSave = (statusModal) => {
    validateFields(async (err, values) => {
      if (err) {
        setLoading(false);
        return;
      }

      //deliveryOrderItem / deliveryOrderParcel

      const mapItem = _.map(tableData, (i) => ({
        _id: i._id ? i._id : undefined,
        statusCode: 'open',
        shippingPrice: '0',
        size: i,
      }));

      const mapParcel = _.map(tableData, (i) => ({
        _id: i._id ? i._id : undefined,
        weight: _.get(i, 'weight'),
        statusCode: orderCheckType === 'deliveryOrderParcel' && menuCode === 'loadPlanning' ? 'confirmed' : 'open',
        shippingPrice: '0',
        size: i.size,
      }));

      console.log('selectCustomer', selectCustomer, values);
      console.log('selectSaleOrderList', selectSaleOrderList);

      const body = {
        detailTypeCode: getFieldValue('ordertype'),
        parcelDetail: getFieldValue('ordertype') === 'deliveryOrderItem' ? mapItem : mapParcel,
        branchDetail: branchDetail ? {
          branchId: _.get(branchDetail, 'id'),
          branchCode: _.get(branchDetail, 'branchCode'),
          branchName: _.get(branchDetail, 'branchName'),
          branchPhone: _.get(branchDetail, 'branchPhone'),
          branchAddressName: _.get(branchDetail, 'branchAddressName'),
          branchAddress: _.get(branchDetail, 'branchAddressDescription'),
          branchAddressLat: _.get(branchDetail, 'branchAddressLat'),
          branchAddressLng: _.get(branchDetail, 'branchAddressLng'),
          branchRemark: _.get(branchDetail, 'branchRemark'),
        } : undefined,
        customerDetail: {
          customerId: _.get(selectCustomer, 'customerId') || undefined,
          comtomerName: _.get(selectCustomer, 'customerId')
            ? _.get(selectCustomer, 'customerName') || _.get(selectCustomer, 'comtomerName')
            : _.get(values, 'customerName'),
          businessType: _.get(selectCustomer, 'businessType') || undefined,
          customerType: _.get(selectCustomer, 'customerType') || undefined,
          phone: _.get(values, 'customerPhone'),
          profileImg: _.get(selectCustomer, 'profileImg') || undefined,
          position: _.get(selectCustomer, 'position') || undefined,
          toSave: customerSwitch,
        },
        sourceDetail: {
          company_address_book_id:
            _.get(createFromData, 'status') !== 'normal_address' ? _.get(createFromData, 'addressBookId') : undefined,
          address_name: _.get(createFromData, 'name'),
          address: _.get(createFromData, 'address'),
          lat: _.get(createFromData, 'lat'),
          lng: _.get(createFromData, 'lng'),
          description: _.get(createFromData, 'fullAddress'),
          selectedFromDestination: _.get(createFromData, 'status') !== 'normal_address' ? true : false,
          postcode: _.get(values, 'customerPostcode'),
        },
        contactDetail: {
          customerContactEmail: _.get(selectContact, 'customerContactEmail') || undefined,
          customerContactId: _.get(selectContact, 'customerContactId') || undefined,
          customerContactName: _.get(selectContact, 'customerContactId')
            ? _.get(selectContact, 'customerContactName')
            : _.get(values, 'recipientName'),
          customerContactPhone: _.get(values, 'recipientPhone'),
          position: _.get(selectContact, 'position') || undefined,
          remark: _.get(selectContact, 'remark') || undefined,
          toSave: recipientSwitch,
        },
        destinationDetail: {
          customer_address_book_id: _.get(createToData, 'status') !== 'normal_address' ? _.get(createToData, 'addressBookId') : undefined,
          address_name: _.get(createToData, 'name'),
          address: _.get(createToData, 'address'),
          lat: _.get(createToData, 'lat'),
          lng: _.get(createToData, 'lng'),
          description: _.get(createToData, 'fullAddress'),
          postcode: _.get(values, 'recipientPostcode'),
        },
        // statusDetail: statusData,
        orderStatus: orderCheckType === 'deliveryOrderParcel' && menuCode === 'loadPlanning' ? 'confirmed' : 'open',
        remark: _.get(values, 'remark'),
        referenceNo: _.get(values, 'refNo'),
        saleOrderId: _.get(selectSaleOrderList, 'saleOrderId') || null,
        saleOrderNo: _.get(selectSaleOrderList, 'saleOrderNo') || null,
      };

      const payload = {
        apiMethod: 'post',
        urlQueue: `/queue/manager/company/${comCode}/channgedeliveryorder`,
        apiUrl: `${process.env.REACT_APP_URL_MANAGER}/order-manager/v5/order/manager/company/${comCode}/web/deliveryorderv2`,
        payload: body,
      };

      const payloadUpdate = {
        apiMethod: 'put',
        urlQueue: `/queue/manager/company/${comCode}/channgedeliveryorder`,
        apiUrl: `${process.env.REACT_APP_URL_MANAGER}/order-manager/v5/order/manager/company/${comCode}/web/deliveryorderv2/${_.get(
          editData,
          'orderId'
        )}`,
        payload: body,
      };

      try {
        const response = await getQueueMessages(_.size(editData) > 0 ? payloadUpdate : payload);
        console.log('getQueueMessages data:', response);
        if (response.status.code === 200) {
          successNotification(_.get(response, 'status.message'));
          setTrigger((cur) => !cur);

          if (statusModal === 'save') setVisible(false);

          if(menuCode !== 'storeDetail') {
            setEditData([])
          }

          resetFields();
          setCustomerSwitch(false);
          setRecipientSwitch(false);
          setSearchCustomer('');
          setSelectCustomer();
          setSelectContact();
          setCreateFromData();
          setSelectFromAddress();
          setStatuFromsAdd('in');
          setCreateToData();
          setSelectToAddress();
          setStatusToAdd('in');
          setContactList([]);
          setTableData([]);
          setRecordItem([]);
          setRecordParcel([]);
          setSaleOrderList([]);
          setSelectSaleOrder([]);
          setSearchOrderList('');
          anyFunction()
          if (orderCheckType === 'deliveryOrderParcel' && menuCode === 'loadPlanning') {
            const body = {
              orgIdPlanning: _.get(bodyForCheck, 'orgIdPlanning'),
              assetId: _.get(bodyForAddLoad, 'assetId'),
              orderNo: _.get(response, 'data.orderNo'),
              productName: '-',
              total: 1,
              customerAddress: _.get(response, 'data.destination'),
              customerName: _.get(response, 'data.customer.comtomerName'),
              productIdOrder: _.get(response, 'data._id'),
              shippingDate: _.get(bodyForCheck, 'shippingDate'),
            };
            const addLaod = await addItem(body);
            if (addLaod.status === 200) {
              successNotification(addLaod.data.message);
              setTriggerPlanning((cur) => !cur);
            } else {
              errorNotification(addLaod.data.message);
            }
          }
        } else {
          errorNotification(_.get(response, 'status.message'));
        }
      } catch (error) {
        console.log('Error fetching queue messages:', error, error.response);
        errorNotification(_.get(error, 'response.data.status.message'));
      }

      // return;

      // const response = await createOrder(body);
      // if (response.status.code === 200) {
      //   successNotification(response.status.message);
      //   setTrigger((cur) => !cur);
      //   setTriggerBranch((cur) => !cur);
      //   if (statusModal === 'save') setVisible(false);
      //   resetFields();
      //   setCustomerSwitch(false);
      //   setRecipientSwitch(false);
      //   setSearchCustomer('');
      //   setSelectCustomer();
      //   setSelectContact();
      //   setCreateFromData();
      //   setSelectFromAddress();
      //   setStatuFromsAdd('in');
      //   setCreateToData();
      //   setSelectToAddress();
      //   setStatusToAdd('in');
      //   setContactList([]);
      // } else {
      //   errorNotification(response.status.message);
      // }
      setLoading(false);
    });
  };

  const handleCancel = () => {
    if(menuCode !== 'storeDetail') {
      setEditData([])
    }
    setVisible(false);
    resetFields();
    setSearchCustomer('');
    setSelectCustomer();
    setSelectContact();
    setCreateFromData();
    setSelectFromAddress();
    setStatuFromsAdd('in');
    setCreateToData();
    setSelectToAddress();
    setStatusToAdd('in');
    setContactList([]);
    setCustomerSwitch(false);
    setRecipientSwitch(false);
    setTableData([]);
    setRecordItem([]);
    setRecordParcel([]);
    setSaleOrderList([]);
    setSelectSaleOrder([]);
    setSearchOrderList('');
  };

  useEffect(() => {
    const getCustomerList = async () => {
      const body = { searchBy: 'customerName', searchVal: searchCustomer, page: 1, limit: 100, orderBy: '' };
      const response = await getCustomer(body);
      setCustomerList(_.get(response, 'customers'));
    };
    getCustomerList();
  }, [debouceSearchBranch]);

  const handleSearchCustomer = (value) => {
    setSearchCustomer(value);
  };

  const handleChangeCustomer = (value) => {
    const filterCustomer = _.filter(customerList, (item) => {
      return item.customerId === value;
    });
    if (_.size(filterCustomer) > 0) {
      setSelectCustomer(_.get(filterCustomer, '[0]'));
      setCustomerSwitch(false);
      setSelectFromAddress([]);
      setFieldsValue({
        ['customerPhone']: _.get(filterCustomer, '[0].phone'),
        ['recipientName']: undefined,
        ['recipientPhone']: undefined,
        ['recipientAddress']: undefined,
      });
    } else {
      setSelectCustomer();
      setSelectContact();
      setContactList([]);
      setCreateToData();
      setRecipientSwitch(false);
      setFieldsValue({
        ['customerPhone']: undefined,
        ['recipientName']: undefined,
        ['recipientPhone']: undefined,
        ['recipientAddress']: undefined,
      });
    }
  };

  useEffect(() => {
    const getCustomerContactList = async () => {
      const body = {
        pageNumber: 1,
        limit: 10,
        orderType: 'asc',
        searchObj: { customerContactName: '', customerContactEmail: '', customerContactPhone: '', position: '' },
        customerId: _.get(selectCustomer, 'customerId') || _.get(),
      };
      const response = await getCustomerContact(body);
      setContactList(_.get(response, 'data.list'));
    };
    if (selectCustomer || _.size(editData) > 0) {
      getCustomerContactList();
    }
  }, [selectCustomer, visible]);

  const handleChangeContact = (value) => {
    const filterContact = _.filter(contactList, (item) => {
      return item.customerContactId === value;
    });
    if (_.size(filterContact) > 0) {
      setSelectContact(_.get(filterContact, '[0]'));
      setRecipientSwitch(false);
      setFieldsValue({
        ['recipientPhone']: _.get(filterContact, '[0].customerContactPhone'),
      });
    } else {
      setSelectContact();
      setFieldsValue({
        ['recipientPhone']: undefined,
      });
    }
  };

  useEffect(() => {
    if (createFromData) {
      setFieldsValue({
        ['customerAddress']: _.get(createFromData, 'name') + ' · ' + _.get(createFromData, 'address'),
      });
    }
  }, [createFromData]);

  useEffect(() => {
    if (createToData) {
      setFieldsValue({
        ['recipientAddress']: _.get(createToData, 'name') + ' · ' + _.get(createToData, 'address'),
      });
    }
  }, [createToData]);

  useEffect(() => {
    const getApi = async () => {
      const payload = {
        tab: 'approved',
        startDate: '',
        endDate: '',
        paging: 1,
        rowsPerPages: 20,
        orderBy: undefined,
        orderType: undefined,
        filter: {
          quotationNo: '',
          saleOrderNo: debouceSearchOrder || '',
          customerName: '',
        },
      };
      const response = await getSaleOrderList(payload);
      console.log('response', response);
      if (_.get(response, 'status.code') === 200) {
        setSaleOrderList(_.get(response, 'data.data'));
      }
    };

    if (visible) {
      getApi();
    }
  }, [visible, debouceSearchOrder]);

  
  useEffect(() => {
    const getApi = async () => {
      const payload = {
        tab: 'approved',
        startDate: '',
        endDate: '',
        paging: 1,
        rowsPerPages: 20,
        orderBy: undefined,
        orderType: undefined,
        filter: {
          quotationNo: '',
          saleOrderNo: debouceSearchOrder || '',
          customerName: '',
        },
      };
      const response = await getSaleOrderList(payload);
      console.log('response', response);
      if (_.get(response, 'status.code') === 200) {
        setSaleOrderList(_.get(response, 'data.data'));
        setinnit(true)
      }
    };

    if (visible && _.size(editData) > 0) {
      getApi();
    }
  }, [visible]);

  useEffect(() => {
    if (saleOrderList && innit) {
      setinnit(false);
      const filterSale = _.filter(saleOrderList, (item) => {
        return item.saleOrderId === _.get(editData, 'saleOrderDetail.saleOrderId');
      });

      console.log('SEERE',_.get(editData, 'saleOrderDetail'));
      

      if (_.size(filterSale) > 0) {
        setFieldsValue({ saleOrderNo: _.get(editData, 'saleOrderDetail.saleOrderId') });
        setSearchOrderList(_.get(editData, 'saleOrderDetail.saleOrderNo'))
        setSelectSaleOrder(_.get(filterSale, '[0]'));
      } else if(_.get(editData, 'saleOrderDetail.saleOrderNo') !== null) {
        setFieldsValue({ saleOrderNo: _.get(editData, 'saleOrderDetail.saleOrderNo') });
        setSelectSaleOrder(_.get(editData, 'saleOrderDetail'));
      }
      else {
        setFieldsValue({ saleOrderNo: undefined });
        setSelectSaleOrder();
      }
    }
  }, [saleOrderList, innit]);

  const handleChangeSaleOrder = (value) => {
    const filterSale = _.filter(saleOrderList, (item) => {
      return item.saleOrderId === value;
    });
    

    if (_.size(filterSale) > 0) {
      console.log('REE1');
      setSelectSaleOrder(_.get(filterSale, '[0]'));
      setSearchOrderList(_.get(filterSale, '[0].saleOrderNo'))
    } else if (value) {
      console.log('REE2');
      setFieldsValue({ saleOrderNo: undefined });
      setSearchOrderList(value)
    } else {
      console.log('REE3');
      setFieldsValue({ saleOrderNo: undefined });
      setSelectSaleOrder();
      setSearchOrderList('')
    }
  };

  const handleBlurSaleOrder = () => {
    const selectedValue = getFieldValue('saleOrderNo');
    const isValid = _.some(saleOrderList, (item) => item.saleOrderId === selectedValue);
    console.log('REE3');
    if (!isValid) {
      if (getFieldValue('saleOrderNo') === _.get(editData, 'saleOrderDetail.saleOrderNo')) {
        setFieldsValue({ saleOrderNo: getFieldValue('saleOrderNo') });
      } else {
        setFieldsValue({ saleOrderNo: undefined });
        setSelectSaleOrder();
      }
    }
  };

  const disbleContact = (customerId, customerswitch, contactId) => {
    let disbled = true;
    if (contactId) {
      disbled = true;
    } else if (customerswitch === false && !customerId) {
      disbled = true;
    } else if (customerswitch === false && customerId) {
      disbled = false;
    } else if (customerswitch === true && !customerId) {
      disbled = false;
    } else if (!contactId && customerId) {
      disbled = false;
    }
    return disbled;
  };

  console.log('selectCustomer', selectCustomer);

  const openModalItem = () => {
    return setVisibleAddItem(true);
  };

  const closeModalItem = () => {
    return setVisibleAddItem(false);
  };

  const openModalParcel = () => {
    setVisibleAddParcel(true);
  };

  const closeModalParcel = () => {
    setVisibleAddParcel(false);
    setRecordParcel({});
  };

  const onChangeType = (value) => {
    console.log('onChangeType', value);
    if (value === '') setTypeModal('parcel');
    if (value === '') setTypeModal('item');
    setTableData([]);
    setRecordItem([]);
    setRecordParcel([]);
  };

  const contentAction = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button
            style={{ width: 100 }}
            type="link"
            ghost
            onClick={() => (getFieldValue('ordertype') === 'deliveryOrderParcel' ? editParcel(record) : editItem(record))}
          >
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        {getFieldValue('ordertype') === 'deliveryOrderParcel' && _.size(editData) > 0 && _.get(record, 'parcelNo') !== '-' ? null : (
          <div>
            <Button
              style={{ width: 100 }}
              type="link"
              ghost
              onClick={() => (getFieldValue('ordertype') === 'deliveryOrderParcel' ? deleteParcel(record) : deleteItem(record))}
            >
              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
            </Button>
          </div>
        )}
      </div>
    );
  };

  const editItem = (record) => {
    setRecordItem(record);
    setEditItem(true);
  };

  const deleteItem = (value) => {
    const filterSelect = _.filter(tableData, (item) => {
      return (
        (_.get(item, 'uomId') !== _.get(value, 'uomId') && _.get(item, 'itemMasterId') === _.get(value, 'itemMasterId')) ||
        _.get(item, 'itemMasterId') !== _.get(value, 'itemMasterId')
      );
    });
    setTableData(filterSelect);
  };

  const closeEditItem = (record) => {
    setRecordItem({});
    setEditItem(false);
  };

  const editParcel = (record) => {
    setRecordParcel(record);
    setVisibleAddParcel(true);
  };

  const deleteParcel = (value) => {
    const filterSelect = _.filter(tableData, (item) => {
      return _.get(item, 'parcelId') !== _.get(value, 'parcelId');
    });
    setTableData(filterSelect);
  };

  const closeEditParcel = (record) => {
    setRecordParcel([]);
    setVisibleAddParcel(false);
  };

  const mouseOver = (value) => {
    inputSaleOrderRef.current.blur();
  };

  return (
    <Modal
      title={
        <span style={{ fontSize: '15px' }}>
          {_.size(editData) > 0
            ? intl.formatMessage({ id: `orderModalTilteEdit`, defaultMessage: 'Edit Order' })
            : intl.formatMessage({ id: `orderModalTilteCreate`, defaultMessage: 'Create Order' })}
        </span>
      }
      centered={true}
      width={900}
      visible={visible}
      onCancel={handleCancel}
      destroyOnClose={true}
      zIndex={999}
      bodyStyle={{ padding: 'unset', height: 'calc(100vh - 300px)', overflowY: 'auto' }}
      footer={[
        <Button_02
          style={{ margin: '0px 10px 0px 0px' }}
          key="back"
          btnsize="wd_df"
          onClick={() => handleCancel()}
          margin="unset"
          disabled={loading}
        >
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01
          key="submit01"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleCheckBeforeSave()}
          onMouseOver={() => {
            mouseOver();
          }}
          disabled={loading || _.size(tableData) === 0}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Spin spinning={loading}>
        <Form className="deliveryForm" colon={false} form={form}>
          <div style={{ padding: '12px 24px' }}>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryModalTitleOrderType', defaultMessage: 'Order Type' })}
                      req={orderCheckType === 'deliveryOrderParcel' ? false : true}
                    />
                  }
                >
                  {getFieldDecorator('ordertype', {
                    initialValue: orderCheckType,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'deliveryModalTitleOrderTypeVD', defaultMessage: 'Please enter Order Type' }),
                      },
                    ],
                  })(
                    <Select
                      style={{ color: orderCheckType === 'deliveryOrderParcel' ? '#050505' : undefined }}
                      allowClear={true}
                      placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderOrderType', defaultMessage: 'Select Order Type' })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      disabled={orderCheckType === 'deliveryOrderParcel' ? true : false}
                      onChange={(value) => onChangeType(value)}
                      // value={_.get(searchOrder, 'orderType')}
                    >
                      {_.map(_.get(orderData, 'data.orderDetailTypeList'), (item) => (
                        <Option key={item.code}>{item.txt}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryModalSaleOrderNo', defaultMessage: 'Sale Order No.' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('saleOrderNo', {
                    //  initialValue: _.size(editData) > 0 ? _.get(editData, 'saleOrderDetail.saleOrderId') : undefined,
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: intl.formatMessage({ id: 'deliveryModalSaleOrderNoVD', defaultMessage: 'Please Enter Sale Order No.' }),
                    //   },
                    // ],
                  })(
                    <Select
                    showSearch
                      onSearch={handleChangeSaleOrder}
                      ref={inputSaleOrderRef}
                      allowClear
                      placeholder={intl.formatMessage({ id: 'deliveryModalSaleOrderNoPH', defaultMessage: 'Enter Sale Order No.' })}
                      onChange={handleChangeSaleOrder}
                      dataSource={saleOrderList}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onBlur={handleBlurSaleOrder}
                    >
                      {_.map(saleOrderList, (item) => (
                        <Option value={item.saleOrderId}>{item.saleOrderNo}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderReferenceNo', defaultMessage: 'Reference No.' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('refNo', {
                    //  initialValue:  _.size(editData) > 0 ? _.get(editData,'referenceNo') : '',
                  })(
                    <Input
                      // onKeyDown={handleKeyDownNumber}
                      // maxLength={10}
                      placeholder={intl.formatMessage({ id: 'deliveryOrderReferenceNoRE', defaultMessage: 'Enter Reference No.' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row style={{ padding: '12px 24px' }}>
            <Col span={12} style={{ color: '#1D3557', fontSize: '15px', fontWeight: 600 }}>
              <FormattedMessage id="orderModalTilteCustomer" defaultMessage="Customer" />
            </Col>
            <Col span={12} style={{ justifyContent: 'end', display: 'flex' }}>
              <div>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </div>
              <div style={{ marginLeft: '8px' }}>
                <Switch
                  disabled={_.get(selectCustomer, 'customerId') ? true : false}
                  checked={customerSwitch}
                  onChange={() => setCustomerSwitch((cur) => !cur)}
                />
              </div>
            </Col>
          </Row>
          <Divider type="horizontal" style={{ margin: 'unset' }} />
          <div style={{ padding: '12px 24px' }}>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalName', defaultMessage: 'Name' })} req={true} />}
                >
                  {getFieldDecorator('customerName', {
                    // initialValue:  _.size(editData) > 0 ? _.get(editData,'customerId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'orderModalReqName', defaultMessage: 'Please enter name' }),
                      },
                    ],
                  })(
                    <AutoComplete
                      placeholder={intl.formatMessage({ id: 'orderModalPlaceholderName', defaultMessage: 'Enter Name' })}
                      onSearch={handleSearchCustomer}
                      onChange={handleChangeCustomer}
                      dataSource={customerList}
                    >
                      {_.map(customerList, (item) => (
                        <Option value={item.customerId}>{item.customerName}</Option>
                      ))}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'orderModalPhone', defaultMessage: 'Phone Number' })} req={true} />
                  }
                >
                  {getFieldDecorator('customerPhone', {
                    // initialValue:  _.size(editData) > 0 ? _.get(editData,'customerPhone') : '',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'orderModalReqPhone', defaultMessage: 'Please enter phone number' }),
                      },
                    ],
                  })(
                    <Input
                      onKeyDown={handleKeyDownNumber}
                      maxLength={10}
                      placeholder={intl.formatMessage({ id: 'orderModalPlaceholderPhone', defaultMessage: 'Enter Phone Number' })}
                    ></Input>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalAddress', defaultMessage: 'Address' })} req={true} />}
                >
                  {getFieldDecorator('customerAddress', {
                    // initialValue:  _.size(editData) > 0 ? _.get(editData,'customerAddress') : '',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'orderModalReqAddress', defaultMessage: 'Please enter address' }),
                      },
                    ],
                  })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'orderModalPlaceholderAddress', defaultMessage: 'Enter Address' })}
                      readOnly={true}
                      onClick={() => setVisibleMapCusotmer(true)}
                    ></Input>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'deliveryModalPostcode', defaultMessage: 'Postcode' })} req={true} />
                  }
                >
                  {getFieldDecorator('customerPostcode', {
                    // initialValue:  _.size(editData) > 0 ? _.get(editData,'customerPostcode') : '',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'deliveryModalPostcodeVD', defaultMessage: 'Please Enter Postcode' }),
                      },
                    ],
                  })(
                    <Input
                      onKeyDown={handleKeyDownNumber}
                      maxLength={10}
                      placeholder={intl.formatMessage({ id: 'deliveryModalPostcodePH', defaultMessage: 'Enter Postcode' })}
                    ></Input>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row style={{ padding: '12px 24px' }}>
            <Col span={12} style={{ color: '#1D3557', fontSize: '15px', fontWeight: 600 }}>
              <FormattedMessage id="orderModalTilteRecipient" defaultMessage="Recipient" />
            </Col>
            <Col span={12} style={{ justifyContent: 'end', display: 'flex' }}>
              <div>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </div>
              <div style={{ marginLeft: '8px' }}>
                <Switch
                  disabled={disbleContact(_.get(selectCustomer, 'customerId'), customerSwitch, _.get(selectContact, 'customerContactId'))}
                  checked={recipientSwitch}
                  onChange={() => setRecipientSwitch((cur) => !cur)}
                />
              </div>
            </Col>
          </Row>
          <Divider type="horizontal" style={{ margin: 'unset' }} />
          <div style={{ padding: '12px 24px' }}>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalName', defaultMessage: 'Name' })} req={true} />}
                >
                  {getFieldDecorator('recipientName', {
                    //  initialValue: _.size(editData) > 0 ? _.get(editData,'recipientId') : '',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'orderModalReqName', defaultMessage: 'Please enter name' }),
                      },
                    ],
                  })(
                    <AutoComplete
                      placeholder={intl.formatMessage({ id: 'orderModalPlaceholderName', defaultMessage: 'Enter Name' })}
                      onChange={handleChangeContact}
                      dataSource={contactList}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {_.map(contactList, (item) => (
                        <Option value={item.customerContactId}>{item.customerContactName}</Option>
                      ))}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'orderModalPhone', defaultMessage: 'Phone Number' })} req={true} />
                  }
                >
                  {getFieldDecorator('recipientPhone', {
                    // initialValue: _.size(editData) > 0 ? _.get(editData,'recipientPhone') : '',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'orderModalReqPhone', defaultMessage: 'Please enter phone number' }),
                      },
                    ],
                  })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'orderModalPlaceholderPhone', defaultMessage: 'Enter Phone Number' })}
                      onKeyDown={handleKeyDownNumber}
                      maxLength={10}
                    ></Input>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalAddress', defaultMessage: 'Address' })} req={true} />}
                >
                  {getFieldDecorator('recipientAddress', {
                    initialValue: _.size(editData) > 0 ? _.get(editData, 'recipientAddress') : '',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'orderModalReqAddress', defaultMessage: 'Please enter address' }),
                      },
                    ],
                  })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'orderModalPlaceholderAddress', defaultMessage: 'Enter Address' })}
                      readOnly={true}
                      onClick={() => setVisibleMapContact(true)}
                    ></Input>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'deliveryModalPostcode', defaultMessage: 'Postcode' })} req={true} />
                  }
                >
                  {getFieldDecorator('recipientPostcode', {
                    initialValue: _.size(editData) > 0 ? _.get(editData, 'recipientPostcode') : '',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'deliveryModalPostcodeVD', defaultMessage: 'Please Enter Postcode' }),
                      },
                    ],
                  })(
                    <Input
                      onKeyDown={handleKeyDownNumber}
                      maxLength={10}
                      placeholder={intl.formatMessage({ id: 'deliveryModalPostcodePH', defaultMessage: 'Enter Postcode' })}
                    ></Input>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row style={{ padding: '10px 24px 0px 24px' }}>
            <Col span={12} style={{ color: '#1D3557', fontSize: '15px', fontWeight: 600 }}>
              {getFieldValue('ordertype') === 'deliveryOrderItem' ? (
                <FormattedMessage id="orderModalTilteItem" defaultMessage="Item" />
              ) : (
                <FormattedMessage id="deliveryOrderViewParcel" defaultMessage="Parcel" />
              )}
            </Col>
            <Col span={12} style={{ justifyContent: 'end', display: 'flex' }}>
              <div style={{ marginLeft: '8px' }}>
                <Button_01
                  key="submit01"
                  type="primary"
                  btnsize="wd_df"
                  onClick={() => (getFieldValue('ordertype') === 'deliveryOrderItem' ? openModalItem() : openModalParcel())}
                >
                  <FormattedMessage id="btnAdd" defaultMessage="Add" />
                </Button_01>
              </div>
            </Col>
          </Row>
          <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
          <div style={{ margin: '24px' }}>
            <CustomTableComponent
              columns={getFieldValue('ordertype') === 'deliveryOrderItem' ? columns : columnsParcel}
              dataSource={tableData}
              scroll={{ x: true }}
              role={true}
              onRow={true}
              total={0}
              contentAction={contentAction}
            />
          </div>
        </Form>
      </Spin>

      <CustomerLocation
        visible={visibleMapCusotmer}
        setVisible={setVisibleMapCusotmer}
        selectAddress={selectFromAddress}
        setSelectAddress={setSelectFromAddress}
        setCreateFromData={setCreateFromData}
        fromOutSide={fromOutSide}
        setFromOutSide={setFromOutSide}
        trigger={triggerFrom}
        setTrigger={setTriggerFrom}
        visibleAdd={visibleAddNewCompany}
        setVisibleAdd={setVisibleAddNewCompany}
        statusFromAdd={statusFromAdd}
        setStatuFromsAdd={setStatuFromsAdd}
        customerId={_.get(selectCustomer, 'customerId') || ''}
        customerName={_.get(selectCustomer, 'customerName') || ''}
        keyMenu={keyMenu}
        setKeyMenu={setKeyMenu}
        createFromData={createFromData}
        center={centerFrom}
        setCenter={setCenterFrom}
        position={positionFrom}
        setPosition={setPositionFrom}
        visibleInfoWindow={visibleInfoWindowFrom}
        setVisibleInfoWindow={setVisibleInfoWindowFrom}
      />

      <CustomerLocation
        visible={visibleMapContact}
        setVisible={setVisibleMapContact}
        selectAddress={selectToAddress}
        setSelectAddress={setSelectToAddress}
        setCreateFromData={setCreateToData}
        fromOutSide={toOutSide}
        setFromOutSide={setToOutSide}
        trigger={triggerTo}
        setTrigger={setTriggerTo}
        visibleAdd={visibleAddNewCustomer}
        setVisibleAdd={setVisibleAddNewCustomer}
        statusFromAdd={statusToAdd}
        setStatuFromsAdd={setStatusToAdd}
        customerId={_.get(selectCustomer, 'customerId') || ''}
        customerName={_.get(selectCustomer, 'customerName') || ''}
        keyMenu={keyMenuTo}
        setKeyMenu={setKeyMenuTo}
        createToData={createToData}
        center={centerTo}
        setCenter={setCenterTo}
        position={positionTo}
        setPosition={setPositionTo}
        visibleInfoWindow={visibleInfoWindowTo}
        setVisibleInfoWindow={setVisibleInfoWindowTo}
      />

      <ModalCreateItemDO visible={visibleAddItem} onCancel={closeModalItem} setTableData={setTableData} tableData={tableData} />

      <ModalQTYItemDO
        visible={visibleEditItem}
        onCancel={closeEditItem}
        record={recordItem}
        tableData={tableData}
        setTableData={setTableData}
        type={'edit'}
      />

      <ModalParcelAdd
        visible={visibleAddParcel}
        onCancel={closeModalParcel}
        setTableData={setTableData}
        record={recordParcel}
        tableData={tableData}
      />
    </Modal>
  );
};

DeliveryCreateOrder.defaultProps = {
  visible: false,
  orderData: [],
  editData: [],
  onCancel: () => console.warn('onCancel not function '),
  closeItem: () => console.warn('onCancel not function '),
  orderCheckType: 'deliveryOrderItem',
  setEditData: () => console.warn('onCancel not function '),
  setTrigger: () => console.warn('onCancel not function '),
  setTriggerPlanning: () => console.warn('onCancel not function '),
  bodyForCheck: {},
  bodyForAddLoad: {},
  anyFunction: () => console.warn('onCancel not function'),
  branchDetail: undefined
};

const DeliveryCreateOrderForm = Form.create({
  name: 'warehouse-form',
})(DeliveryCreateOrder);

export default DeliveryCreateOrderForm;
