import React from 'react';
import './css/index.css';
import { Form, Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

const ClaimHistoryView = (props) => {
  const { record } = props;
  const claimDateTxt = _.get(record, 'data') ? _.get(record.data, 'assetClaimInfo.claimDateTxt') : '';
  const damageVal = _.get(record, 'data') ? _.get(record.data, 'assetClaimInfo.damageVal') : '';
  const claimBy = _.get(record, 'data') ? _.get(record.data, 'assetClaimInfo.claimBy') : '';
  const claimableTotal = _.get(record, 'data') ? _.get(record.data, 'assetClaimInfo.claimableTotal') : '';
  const claimRef = _.get(record, 'data') ? _.get(record.data, 'assetClaimInfo.claimRef') : '';
  const insuranceName = _.get(record, 'data') ? _.get(record.data.assetClaimInfo, 'assetInsurance.insuranceName') : '';

  return (
    <Form>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            <span className="formText-formate">
              <FormattedMessage id="AssetModalTitleFormInsuranceType" defaultMessage="Insurance Date" />
            </span>
            <div className="formText-formate-value" style={{ width: '100%' }}>
              {claimDateTxt || '-'}
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <span className="formText-formate">
              <FormattedMessage id="AssetModalTitleFormInsuranceCompany" defaultMessage="Insurance Company" />
            </span>
            <div className="formText-formate-value" style={{ width: '100%' }}>
              {insuranceName || '-'}
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <span className="formText-formate">
              <FormattedMessage id="AssetModalTitleFormClaimBy" defaultMessage="Claim By" />
            </span>
            <div className="formText-formate-value" style={{ width: '100%' }}>
              {claimBy || '-'}
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <span className="formText-formate">
              <FormattedMessage id="AssetModalTitleFormAssetClaimReference" defaultMessage="Asset Claim Reference (คันที่เคลมแทน)" />
            </span>
            <div className="formText-formate-value" style={{ width: '100%' }}>
              {claimRef || '-'}
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <span className="formText-formate">
              <FormattedMessage id="AssetModalTitleFormDamageValue" defaultMessage="Damage Value (Baht)" />
            </span>
            <div className="formText-formate-value" style={{ width: '100%' }}>
              <NumberFormat value={(parseFloat(damageVal) || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <span className="formText-formate">
              <FormattedMessage id="AssetModalTitleFormClaimableAmount" defaultMessage="Claimable Amount (Baht)" />
            </span>
            <div className="formText-formate-value" style={{ width: '100%' }}>
              <NumberFormat value={(parseFloat(claimableTotal) || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ClaimHistoryView;
