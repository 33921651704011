import React from 'react'
import CollapseCustom from '../../components/collapse-custom'
import { useIntl, FormattedMessage } from 'react-intl'
import ReportARActionColumns from './action-columns'
import { Input, Form, Select, DatePicker, Row, Col } from 'antd'
import LabeRequireForm from '../../components/label-required-form'
import _ from 'lodash'
import Button_01 from '../../components/v2/button_01'
import Button_02 from '../../components/v2/button_02'
import LoadmoreCustomer from '../../components/loadmore/customer'


const { RangePicker } = DatePicker
const { Option } = Select

const ReportARAction = (props) => {
  const intl = useIntl()
  const { search, setSearch, onFilterOrder, actionColumns, statusList, typeList, setPage, setIsFetch } = props

  const handleApply = () => {
    setIsFetch((event) => !event)
    setPage(1)
  }

  const handleReset = () => {
    setIsFetch((event) => !event)
    setSearch((prev) => ({...prev, blNo: '', customer: undefined, customerSearch: '', type: undefined, dueDate: [], customerName: '', status: undefined}))
    setPage(1)
  }

  const formFilter = () => {
    return (
      <Form colon={false} className='deliveryForm'>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ARreportColumnsBlNo', defaultMessage: 'BL No.' })}
                  req={false}
                />
              }
            >
               <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'ARreportPHRefBlNo', defaultMessage: 'Enter BL No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'blNo')}
                value={_.get(search, 'blNo')}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <LoadmoreCustomer
              data={search}
              setData={setSearch}
              required={false}
              notSpec={true}
            />
            {/* <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ARreportColumnsCustomer', defaultMessage: 'Customer' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'ARreportPHRefCustomer', defaultMessage: 'Select Customer' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'customer')}
                value={_.get(search, 'customer')}
              >
                {
                  _.map(bankList, (item) => (
                    <Option key={item.code}>
                      {item.label}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item> */}
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ARreportColumnsType', defaultMessage: 'Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'ARreportPHRefType', defaultMessage: 'Select Type' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'type')}
                value={_.get(search, 'type')}
              >
                {
                  _.map(typeList, (item) => (
                    <Option key={item.code}>
                      {item.label}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={[24, 2]}>
          
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ARreportColumnsDueDate', defaultMessage: 'Due Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                allowClear={true}
                placeholder={[intl.formatMessage({ id: 'ARreportPHRefDueDateFrom', defaultMessage: 'Select From Date' }), intl.formatMessage({ id: 'ARreportPHRefDueDateTo', defaultMessage: 'Select To Date' })]}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => onFilterOrder(value, 'dueDate')}
                style={{ width: '100%' }}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                value={_.get(search, 'dueDate')}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ARreportColumnsStatus', defaultMessage: 'Status' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'ARreportPHRefStatus', defaultMessage: 'Select Status' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'status')}
                value={_.get(search, 'status')}
              >
                {
                  _.map(statusList, (item) => (
                    <Option key={item.code}>
                      {item.label}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
                label={<LabeRequireForm text={''} req={false} />}
              >
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                  <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                </Button_01>
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                  <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                </Button_02>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    );
  };


  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[intl.formatMessage({ id: 'ARreportPHRefIssuedDateFrom', defaultMessage: 'Select From Date' }), intl.formatMessage({ id: 'ARreportPHRefIssuedDateTo', defaultMessage: 'Select To Date' })]}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => onFilterOrder(value, 'searchdDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'searchdDate')}
            />
            <ReportARActionColumns
              actionColumns={actionColumns}
            />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div >
  )
}

export default ReportARAction
