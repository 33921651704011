import React, { useEffect, useState, useRef } from 'react';
import { Form, Select, Row, Col, Input, DatePicker, Divider, Table, Button, Popover, InputNumber, Dropdown, ConfigProvider } from 'antd';
import LabeRequireForm from '../../../../components/label-required-form';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import { handleKeyDownNumber, handleKeyDownDecimal2Fixed } from '../../../../component-function/fnc-number';
import { numberFormatter, numberParser } from '../../../../component-function/fnc-inputnumber-currency';
import SuffixInput from '../../../../components/v2/suffix';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import { Round } from '../../../../component-function/lodash';
import { useAppContext } from '../../../../includes/indexProvider';

const { Option } = Select;

const PurchaseCreateDetail = (props) => {
  const {
    form,
    columns,
    setVisibleItem,
    vendorData,
    contactData,
    setContactData,
    selectItem,
    openModalEdit,
    setSelectItem,
    taxList,
    columnsAtt,
    handleCreateUpload,
    attachmentData,
    setAttachmentData,
    handleEditUpload,
    onChange,
    searchGroup,
    menuColumn,
    setDeliveryList,
    deliveryList,
  } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const ref = useRef();
  const ref2 = useRef();
  const discountNumber = parseFloat(_.get(searchGroup, 'discountNumber') || 0.0);
  const tax = getFieldValue('tax');
  const taxNumber = numberParser(getFieldValue('taxNumber') || '0.00');
  const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalPrice') : 0.0;
  const subtotalDiscount = sumTotal - discountNumber;
  const subtotalTax = parseFloat(subtotalDiscount) + taxNumber;
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [visiblePopoverTableAtt, setVisiblePopoverTableAtt] = useState();
  const [recordPopoverAtt, setRecordPopoverAtt] = useState();
  const [offsetPopoverAtt, setOffsetPopoverAtt] = useState([0, 0]);
  //---------------------------------------------------------------------
  const app = useAppContext();
  const langValue = app.state.langValue;
  const [locale, setLocale] = useState(enUS);
  let codeLang = langValue ? langValue : 'EN';
  let changeLang = codeLang === 'EN' ? enUS : thTH;
  console.log('langgggg', changeLang, locale);

  console.log('Discount Number', searchGroup);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  console.log('discountNumber::', discountNumber, tax, taxNumber, sumTotal, subtotalDiscount, subtotalTax);

  useEffect(() => {
    if (getFieldValue('vendor')) {
      const vendorId = getFieldValue('vendor');
      const filterContact = _.filter(vendorData, (item) => {
        return item.customerId === vendorId;
      });
      setContactData(_.get(filterContact, '[0].contacts'));
      setFieldsValue({
        ['contact']: undefined,
      });
    }
  }, [getFieldValue('vendor')]);

  const deleteItem = (value, record) => {
    const filterSelect = _.filter(selectItem, (item) => {
      return item.index !== value;
    });
    setSelectItem(
      _.map(filterSelect, (item, index) => {
        return {
          ...item,
          index: index + 1,
        };
      })
    );
    const mapde = _.map(deliveryList, (list, index) => {
      const temp = _.filter(_.get(list, 'itemList'), (item, index) => {
        return item.itemPoId !== record.itemPoId;
      });

      list.itemList = _.chain(temp)
        .filter((item, index) => {
          return item.data !== undefined;
        })
        .map((sortindex, index) => {
          // if (_.get(sortindex, 'data') !== undefined) {
          console.log('sortindex', sortindex);
          sortindex.data.index = index + 1;
          return {
            ...sortindex,
          };
          // }
        })
        .filter((item, index) => {
          return _.get(item, 'data') !== undefined;
        })
        .sortBy('index')
        .value();


      if (_.size(_.get(list, 'itemList')) > 0) {
        console.log('listemp', temp, '/', list);
        list.total = _.sumBy(temp, 'qty');
      }
      else {
        list.total = 0;
      }

      if (_.get(list, 'total') > 0) {
        console.log('listall', list);
        return list;
      } else {
        return undefined;
      }
    });

    console.log('recordRD', mapde);

    const fillundefined = _.filter(mapde, (item, index) => {
      return item !== undefined || _.get(item, 'total') > 0;
    });

    console.log('recordFillU', fillundefined);

    setDeliveryList(
      _.map(fillundefined, (item, index) => {
        return {
          ...item,
          index: index + 1,
        };
      })
    );
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => openModalEdit(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record.index, record)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const deleteAtt = (value) => {
    const filterSelect = _.filter(attachmentData, (item) => {
      return item.id !== value;
    });
    setAttachmentData(filterSelect);
    setVisiblePopoverTableAtt(false);
    setRecordPopoverAtt();
    setOffsetPopoverAtt([0, 0]);
  };

  const handleEditUploadInside = (value) => {
    handleEditUpload(value);
    setVisiblePopoverTableAtt(false);
    setRecordPopoverAtt();
    setOffsetPopoverAtt([0, 0]);
  };

  const fncActionTable2 = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record.id)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
    setVisiblePopoverTableAtt(false);
    setRecordPopoverAtt();
    setOffsetPopoverAtt([0, 0]);
  };

  useEffect(() => {
    setFieldsValue({
      ['taxNumber']: numberFormatter(Round((parseFloat(subtotalDiscount) * tax) / 100)),
    });
  }, [tax, subtotalDiscount]);

  const validatorpaymentTerm = (rule, value, callback) => {
    if (value) {
      const parsValue = parseFloat(value || 0);
      if (parsValue === 0) {
        callback('Payment Term must more than 0');
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const currencyFormatter = (value) => {
    console.log('currencyFormatterx1', value);
    if (parseFloat(value) >= 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const currencyParser = (value) => {
    console.log('currencyParserx2', value);
    if (parseFloat(value) >= 0) {
      return value.replace(/\$\s?|(,*)/g, '');
    }
  };

  return (
    <div style={{ height: '540px', overflowY: 'auto' }}>
      <Form form={form} layout="vertical" className="po-form">
        <div style={{ padding: '0px 24px 24px 24px' }}>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'purchesModalFormVendor', defaultMessage: 'Vendor or Supplier' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('vendor', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'purchesModalFormValidateVendor',
                        defaultMessage: 'Please Select Vendor or Supplier',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'purchesModalFormPlaceholderVendor',
                      defaultMessage: 'Select Vendor or Supplier',
                    })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                  >
                    {vendorData && vendorData.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormContact', defaultMessage: 'Contact' })} req={true} />
                }
              >
                {getFieldDecorator('contact', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'purchesModalFormValidateContact', defaultMessage: 'Please Select Contact' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'purchesModalFormPlaceholderContact', defaultMessage: 'Select Contact' })}
                    disabled={getFieldValue('vendor') ? false : true}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                  >
                    {contactData && contactData.map((item) => <Option key={item.customerContactId}>{item.customerContactName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'purchesModalFormPaymentTerm', defaultMessage: 'Payment Term (Days)' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('paymentTerm', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'purchesModalFormValidatePaymentTerm',
                        defaultMessage: 'Please Enter Payment Term (Days)',
                      }),
                    },
                    {
                      validator: validatorpaymentTerm,
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownNumber}
                    placeholder={intl.formatMessage({
                      id: 'purchesModalFormPlaceholderPaymentTerm',
                      defaultMessage: 'Enter Payment Term (Days)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'purchesModalFormQuotationNo', defaultMessage: 'Quotation No.' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator(
                  'quotationNo',
                  {}
                )(
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'purchesModalFormPlaceholderQuotationNo',
                      defaultMessage: 'Select Quotation No.',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'purchesModalFormIssueDate', defaultMessage: 'Issue Date' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('issueDate', {
                  // initialValue: !changeLang ? moment.locale('en') : moment.locale('th-th'),
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'purchesModalFormValidateIssueDate', defaultMessage: 'Please Select Issue Date' }),
                    },
                  ],
                })(
                  // <ConfigProvider locale={locale}>
                  <DatePicker
                    placeholder={intl.formatMessage({ id: 'purchesModalFormPlaceholderIssueDate', defaultMessage: 'Select Issue Date' })}
                    style={{ width: '100%' }}
                    locale={locale}
                    format="DD/MM/YYYY"
                  />
                  // </ConfigProvider>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'purchesModalFormReferenceNo', defaultMessage: 'Reference No.' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator(
                  'referenceNo',
                  {}
                )(
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'purchesModalFormPlaceholderReferenceNo',
                      defaultMessage: 'Enter Reference No.',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="purchesModalTitleItem" defaultMessage="Item" />
            </div>
            <div>
              {/* <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => setVisibleItem(true)}>
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button01> */}

              <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }}>
                  <FormattedMessage id="btnAdd" defaultMessage="Add" />
                  <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                </Button01>
              </Dropdown>
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
          <Table
            columns={columns}
            dataSource={_.sortBy(selectItem, [
              function (o) {
                return o.index;
              },
            ])}
            pagination={false}
            scroll={{ x: 1200 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                },
              };
            }}
          />
          {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        </div>
        <div style={{ padding: '0px 24px', marginBottom: '24px' }}>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {getFieldDecorator('remark', {})(<Input.TextArea autoSize={{ minRows: 8, maxRows: 8 }} maxLength={500} />)}
              </Form.Item>
              {/* <LabeRequireForm style={{display: 'flex'}} text={`${_.size(getFieldValue('remark'))} / 300`}/> */}

            </Col>
            <Col span={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                <div>
                  <FormattedMessage id="purchesModalFormSubtotal" defaultMessage="Subtotal" />
                </div>
                <div>{numberFormatter(sumTotal)} THB</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div style={{ marginRight: '46px' }}>
                  <FormattedMessage id="purchesModalFormDiscount" defaultMessage="Discount" />
                </div>
                <div style={{ display: 'flex' }}>
                  <Row gutter={[16]}>
                    <Col span={9}>
                      <Form.Item label="">
                        <InputNumber
                          className="disabled-handle-count"
                          onChange={(e) => onChange(e, 'discount')}
                          value={_.get(searchGroup, 'discount')}
                          suffix="%"
                          onKeyDown={handleKeyDownDecimal2Fixed}
                          //formatter={currencyFormatter}
                          parser={currencyParser}
                          controls={false}
                          style={{ paddingRight: '20px' }}
                        />
                        <SuffixInput text="%" />
                      </Form.Item>
                    </Col>
                    <Col span={15}>
                      <Form.Item label="">
                        <InputNumber
                          className="disabled-handle-count"
                          onChange={(e) => onChange(e, 'discountNumber')}
                          value={_.get(searchGroup, 'discountNumber')}
                          suffix="THB"
                          onKeyDown={handleKeyDownDecimal2Fixed}
                          //formatter={currencyFormatter}
                          parser={currencyParser}
                          controls={false}
                          style={{ paddingRight: '20px' }}
                        />
                        <SuffixInput text="THB" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div>
                  <FormattedMessage id="purchesModalFormSubTotalAfterDiscount" defaultMessage="Subtotal After Discount" />
                </div>
                <div>{numberFormatter(subtotalDiscount)} THB</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div style={{ marginRight: '46px' }}>
                  <FormattedMessage id="purchesModalFormTax" defaultMessage="Tax" />
                </div>
                <div style={{ display: 'flex' }}>
                  <Row gutter={[16]}>
                    <Col span={9}>
                      <Form.Item label="">
                        {getFieldDecorator('tax', {
                          initialValue: _.get(taxList, '[0].taxPercent'),
                        })(
                          <Select>
                            {_.map(taxList, (item) => (
                              <Option value={item.taxPercent}>{item.taxPercentTxt}</Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={15}>
                      <Form.Item label="">
                        {getFieldDecorator('taxNumber', {
                          initialValue: '0.00',
                        })(<Input suffix="THB" readOnly={true} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div>
                  <FormattedMessage id="purchesModalFormSubTotal" defaultMessage="Total" />
                </div>
                <div>{numberFormatter(subtotalTax)} THB</div>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="purchesModalTitleAttachment" defaultMessage="Attachment" />
            </div>
            <div>
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreateUpload}>
                <FormattedMessage id="btnUpload" defaultMessage="Upload" />
              </Button01>
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
          <Table
            columns={columnsAtt}
            dataSource={_.sortBy(attachmentData, [
              function (o) {
                return o.index;
              },
            ])}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href) {
                    const { x, y } = ref2.current.getBoundingClientRect();
                    setVisiblePopoverTableAtt(true);
                    setOffsetPopoverAtt([event.pageX - x, event.pageY - y]);
                    setRecordPopoverAtt(record);
                  }
                },
              };
            }}
          />
          {fncActionTable2(recordPopoverAtt, offsetPopoverAtt, visiblePopoverTableAtt, ref2)}
        </div>
      </Form>
    </div>
  );
};

export default PurchaseCreateDetail;
