import React, { useEffect, useState } from 'react'
import { Modal, Form, Select, Input, Row } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import Button02 from '../v2/button_02';
import Button01 from '../v2/button_01';
import './css/index.css'
import styled from 'styled-components';
import getSkill from '../../controllers/skill/get-skill';
import { handleCreateSkillList, handleEditSkillList } from '../../controllers/skill/modal-add-edit-delete'
import _ from 'lodash';
import { successNotification, errorNotification, warningNotification } from '../v2/notification';

const { TextArea } = Input;
const { Option } = Select;

const AddSkill = (props) => {
  const intl = useIntl();
  const { form, visible, setVisible, skillDefault, handleSelectSkill, setDefaultValue, defaultValue, setDefaultKeys } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const [skillData, setSkillData] = useState([])

  useEffect(() => {
    const skillData = async () => {
      if (visible === true) {
        const response = await getSkill()
        if (response) {
          setSkillData(response.skillGroup)
        }
      }

    }

    skillData();
  }, [visible])


  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };





  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (defaultValue) {
        const response = await handleEditSkillList(values.skillName, values.description, _.get(defaultValue, 'skillId'), values.skillGroup,)
        if (_.size(response) !== 0) {
          setVisible(false)
          setDefaultKeys(String(values.skillGroup))
          handleSelectSkill(values.skillGroup)
          successNotification(response.status.message)
          resetFields()
          setDefaultValue();
        }

      } else {
        const response = await handleCreateSkillList(values.skillGroup, values.skillName, values.description)
        if (_.size(response) !== 0) {
          setVisible(false)
          setDefaultKeys(String(values.skillGroup))
          handleSelectSkill(values.skillGroup)
          successNotification(response.status.message)
          resetFields()
        }
      }


    })

  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
    setDefaultValue();
  }

  return (
    <Modal
      title={intl.formatMessage({ id: 'ModalSkillHeader', defaultMessage: 'Skill' })}
      centered={true}
      visible={visible}
      width={376}
      onCancel={handleCancel}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleCreate()}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form>
        <Row style={{ paddingBottom: '16px' }}>
          <span className='text-style'><FormattedMessage id="ModalSkillGroupText" defaultMessage="Skill Group" /><LabeRequire req={true} /></span>
          <Form.Item style={{ paddingTop: '8px' }}>
            {getFieldDecorator("skillGroup", {
              initialValue: skillDefault,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'ModalSkillGroupValidate', defaultMessage: 'Please select skill' })

                }
              ]
            })(<Select placeholder={intl.formatMessage({ id: 'ModalSkillGroupPlaceHolder', defaultMessage: 'Select Skill' })}>
              {skillData && skillData.map(item => <Option key={item.skillGroupId}>{item.name}</Option>)}
            </Select>)}
          </Form.Item>
        </Row>
        <Row style={{ paddingBottom: '16px' }}>
          <span className='text-style'><FormattedMessage id="ModalSkillNameText" defaultMessage="Skill Name" /><LabeRequire req={true} /></span>
          <Form.Item style={{ paddingTop: '8px' }}>
            {getFieldDecorator("skillName", {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'ModalSkillNameValidate', defaultMessage: 'Please enter name' })

                }
              ]
            })(<Input placeholder={intl.formatMessage({ id: 'ModalSkillNamePlaceholder', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
          </Form.Item>
        </Row>
        <Row>
          <span className='text-style'><FormattedMessage id="ModalSkillDescriptionText" defaultMessage="Description" /></span>
          <Form.Item style={{ paddingTop: '8px' }}>
            {getFieldDecorator("description", {
            })(<TextArea
              autoSize={{ minRows: 3, maxRows: 3 }}
              maxLength={250}
            />)}
          </Form.Item>
        </Row>

      </Form>
    </Modal>
  )
}

const skill = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {
    const { defaultValue } = props
    return {
      skillName: Form.createFormField({
        value: defaultValue ? _.get(defaultValue, 'name') : undefined,
      }),
      description: Form.createFormField({
        value: defaultValue ? _.get(defaultValue, 'description') : undefined,
      }),
    };
  }

})(AddSkill);

export default skill;
