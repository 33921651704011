import React from 'react';
import CustomerForm from './customer-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import { messageLabel } from '../../../../components/message-component';
import CustomTableComponent from '../../components/table';
import { DatePicker, Divider, Input } from 'antd';
import _ from 'lodash';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

const PaymentTab = ({ form, dataSource, setDataSource, selectItem, setSelectItem }) => {
  const intl = useIntl();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: messageLabel('quotationLabelDueDate', 'Due Date'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 200,
      render: (text, record, index) => (
        <DatePicker
          value={_.get(record, 'dueDate')}
          allowClear={false}
          format={dateFormat}
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({ id: 'quotationHintDueDate', defaultMessage: 'Enter Due Date' })}
          onChange={(e) => handleChangeDueDate(index, e)}
        />
      ),
    },
    {
      dataIndex: 'Blank',
      key: 'Blank',
      ellipsis: true,
      width: 200,
    },
    {
      title: messageLabel('quotationLabelCredit', 'Credit (days)'),
      dataIndex: 'credit',
      key: 'credit',
      ellipsis: true,
      width: 250,
    },
    {
      title: messageLabel('quotationLabelAmount', 'Amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, record, index) => numberFormatter(record.amount) + ' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' }),
    },
    {
      dataIndex: 'Blank',
      key: 'Blank',
      ellipsis: true,
      width: 250,
    },
    // {
    //   title: messageLabel('quotationLabelRemark', 'Remark'),
    //   dataIndex: 'remark',
    //   key: 'remark',
    //   render: (text, record, index) => (
    //     <Input value={_.get(record, 'remark')} onChange={(e) => handleChangeRemark(index, e.target.value)} placeholder={intl.formatMessage({ id: 'quotationEnterRemark', defaultMessage: 'Enter Remark' })} />
    //   ),
    // },
  ];

  const handleChangeDueDate = (index, value) => {
    let cloneData = [...dataSource];
    let cloneItem = [...selectItem]

    const currentDate = moment().format('YYYY-MM-DD');
    const valueDate = moment(value).format('YYYY-MM-DD');
    const diffDate = moment(valueDate).diff(currentDate, 'day');

    console.log('handleChangeDueDate', value, currentDate, valueDate, diffDate);
    console.log('cloneData', cloneData);

    const filterItem = _.filter(cloneData, (obj) => obj.credit === diffDate.toString());
    const findex = _.findIndex(cloneData, (obj) => obj.credit === diffDate.toString())

    console.log('filterItem', findex,'/',index);

    if (_.size(filterItem) > 0) {
      
      cloneData[findex].amount = cloneData[findex].amount + cloneData[index].amount;
      const filterRemoveItem = _.filter(cloneData, (obj) => obj.credit !== cloneData[index].credit);
      setDataSource(filterRemoveItem);
      _.map(cloneItem,(item,index) => {
        if (item.credits === cloneData[index].credit) {
          item.credit = cloneData[findex].credit;
          return item.credits = cloneData[findex].credit;
        }
      })
      setSelectItem(cloneItem);
    }
    else {
      _.map(cloneItem,(item) => {
        if (item.credits === cloneData[index].credit) {
          item.credit = diffDate.toString();
          return item.credits = diffDate.toString();
        }
      })
      console.log('cloneItem', cloneItem,'/',cloneData);
      setSelectItem(cloneItem);
      cloneData[index].dueDate = value;
      cloneData[index].credit = diffDate.toString();
      setDataSource(cloneData);
      
    }
    

  };

  const handleChangeRemark = (index, value) => {
    let cloneData = [...dataSource];
    cloneData[index].remark = value;
    setDataSource(cloneData);
  };

  // const dataSource = [];

  return (
    <div className="scroll-sm scroll-height" style={{ marginTop: 24 }}>
      <div className="content-body">
        <CustomTableComponent columns={columns} dataSource={dataSource} scroll={{ x: true }} total={0} role={false} onRow={false} />
      </div>
    </div>
  );
};

export default PaymentTab;
