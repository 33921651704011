import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Icon, Select, Badge, Checkbox } from 'antd'
import _ from 'lodash';
import '../css/action-search.css'

const { Option } = Select;

export default (props) => {
  const { initialData, keyTabMonitor, checkTaskNo, setCheckTaskNo } = props;
  const intl = useIntl();
  const status = _.get(initialData, 'statusStage.stageList') || [];
  const statusSLA = _.get(initialData, 'sla.slaList').filter((item) => item.sequence !== 1);

  const onChange = (e) => {
    setCheckTaskNo(e.target.checked)
  }

  return (
    <div className='footer-status-group-monitor'>
      <div className='footer-status-group-list'>
        {status.map((item) => (
          <div className='status-item' >
            <Badge className='status-item-block' color={item.statusGroupColor} />
            <div className='status-item-name'>{item.statusGroupName}</div>
          </div>
        ))}
      </div>
      <div className='footer-status-group-list-sla'>
        {
          keyTabMonitor === '2' && statusSLA.map((item) => (
            <div className='status-item' >
              <Icon type="exclamation-circle" theme="filled" className='icon-sla-monitor' style={{ color: item.code === 'warning' ? '#f88512' : '#f20000' }} />
              <div className='status-item-name'>{item.name}</div>
            </div>
          ))
        }
      </div>
      {
        keyTabMonitor === '1' ? (
          <div>
            <div style={{ paddingTop: '4px' }}>
              <Checkbox
                checked={checkTaskNo}
                onChange={onChange}
              >
                <span style={{ fontSize: '13px' }}>Display Task No.</span>
              </Checkbox>
            </div>
          </div>
        ) : null
      }
    </div>
  )
}