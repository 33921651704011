import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Button, notification, Modal, Popover, Badge, Icon, Avatar, Card, List, Row, Col, Divider } from 'antd';
import { Link, Redirect, BrowserRouter as Router, NavLink } from 'react-router-dom';
//import '../css/rightMenu.css';
import _ from 'lodash';
import styled from './notification.css';
import ModalNotification from '../../../components/modal-notification/index';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../config/page-settings';

const { confirm } = Modal;

export default ({
  count,
  notiData,
  visible,
  setVisible,
  hideNotiSelect,
  setHideNotiSelect,
  dataSelect,
  setDataSelect,
  acknowledgeStatus,
}) => {
  const intl = useIntl();

  const notiList = notiData ? _.get(notiData, 'data') : [];
  const pinDetail = notiData ? _.get(notiData, 'pinDetail') : [];

  const handleClickDetail = (data) => {
    setDataSelect(data);
    setHideNotiSelect(true);
  };

  const handleVisible = (visible) => {
    setVisible(visible);
  };

  const hide = () => {
    setVisible(false);
  };

  const content = (noti, detail) => {
    return (
      <Card
        className="notifications-card"
        title={intl.formatMessage({ id: 'notificationUnreadNotifications', defaultMessage: 'Unread Notifications' })}
        onClick={hide}
      >
        <div>
          {detail === undefined || detail === [] ? (
            <span></span>
          ) : (
            <Row>
              <List
                itemLayout="horizontal"
                dataSource={detail}
                renderItem={(item) => (
                  <Link to={`/main/notification?typeCode=${item.notiTypeName}`} Style="padding-left:5px">
                    {' '}
                    <List.Item
                      id={item._notiTypeCode}
                      style={{ border: 'none', cursor: 'pointer', paddingTop: '0px', paddingBottom: '15px' }}
                    >
                      <Row gutter={24}>
                        <Col span={22} style={{ width: '290px' }}>
                          <Icon className="text-alert" type="pushpin" /> &nbsp;&nbsp;{' '}
                          <span className="text-alert">{item.notiTypeName}</span>
                        </Col>
                        <Col span={2} style={{ paddingLeft: '54px' }}>
                          <div style={{ width: 'max-content' }}>
                            {' '}
                            <span className="text-alert">
                              {item.total} <FormattedMessage id="notificationAlertsLbl" defaultMessage="Alerts" />
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </List.Item>{' '}
                  </Link>
                )}
              />
              <Divider className="divider-width" />
            </Row>
          )}

          <Row>
            <div className="notification-div">
              <List
                className="notification-list"
                itemLayout="horizontal"
                dataSource={noti}
                renderItem={(item) => (
                  <List.Item id={item._id} className="hover-item" onClick={() => handleClickDetail(item)}>
                    {item.notiTypeCode === 'task' ? (
                      <Row gutter={[16, 16]}>
                        <Col span={4}>
                          <Avatar size={55} src={item.profileImage} />
                        </Col>
                        <Col span={20}>
                          <div style={{ paddingLeft: '10px' }}>
                            <div style={{ width: 'max-content' }}>
                              <span style={{ fontWeight: 600 }}>{item.name}</span> -{' '}
                              <span>
                                {item.description1} {item.description2}
                              </span>
                            </div>
                            <div style={{ width: 'max-content' }}>{item.description3}</div>
                            <div style={{ width: 'max-content' }}>{item.planDate}</div>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row gutter={[16, 16]}>
                        <Col span={4}>
                          <Avatar size={55} src={item.profileImage} />
                        </Col>
                        <Col span={20}>
                          <div style={{ paddingLeft: '10px' }}>
                            <div style={{ width: 'max-content' }}>
                              <span style={{ fontWeight: 600 }}>{item.name}</span> - <span>{item.description1}</span>
                            </div>
                            <div style={{ width: 'max-content' }}>
                              {item.description2} {item.description3}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </List.Item>
                )}
              />
            </div>
          </Row>
        </div>
      </Card>
    );
  };

  return (
    <PageSettings.Consumer>
      {({
        setPageSidebar,
        pageSidebar,
        pageSidebarOpen,
        pageSidebarToggled,
        handleSidebarToggled,
        handleOnSelectMenu,
        checkPermissionAction,
        pathKey,
      }) => (
        <div>
          <Popover
            visible={visible}
            onVisibleChange={handleVisible}
            placement="bottomRight"
            trigger="click"
            content={
              <div>
                {content(notiList, pinDetail)}
                <Row>
                  <div className="see-more">
                    <a>
                      <Link
                        onClick={() => setPageSidebar(['notification'])}
                        to={{ pathname: '/main/notification' }}
                        Style="padding-left:5px"
                      >
                        {' '}
                        <FormattedMessage id="notificationSeeMoreLbl" defaultMessage="See More" />{' '}
                      </Link>
                    </a>
                  </div>
                </Row>
              </div>
            }
          >
            <Badge
              className="badge-custom"
              count={count !== 0 ? count : undefined}
              style={{
                background: '#1D3557',
                boxShadow: 'rgba(254, 98, 2, 0.24) 0px 0px 1px 1px',
              }}
            >
              <div
                style={{
                  height: '30px',
                  width: '30px',
                  backgroundColor: '#f0f2f5',
                  borderRadius: '50%',
                  display: 'inline-block',
                  textAlign: 'center',
                }}
              >
                <Icon type="bell" theme="filled" style={{ fontSize: '16px', marginTop: '6px' }} />
              </div>
            </Badge>
          </Popover>
          <ModalNotification
            visible={hideNotiSelect}
            setVisible={setHideNotiSelect}
            datasource={dataSelect}
            acknowledgeStatus={acknowledgeStatus}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};
