import React from 'react';
import { Icon, Divider } from 'antd';
import styles from './css/map-control.css';
import TaskIcon from '../../../components/image/Task.svg';
import TaskOrange from '../../../components/image/TaskOrange.svg';

const MapControl = (props) => {
  const {onClickShowTask, onClickShowResource, resourceActive, taskActive} = props;
  const BagIcon = () => (<img src={TaskIcon} style={{ width: 20 }}  ></img>);
  const BagIconOrange = () => (<img src={TaskOrange} style={{ width: 20 }}   ></img>);

  return (
    <div className='map-control'>
      <Icon 
        type="user" 
        onClick={(e) => onClickShowResource(e)} 
        className={resourceActive}
      />
      <Divider className='map-control-divider' type="vertical" />
      <Icon 
        type="user" 
        onClick={(e) => onClickShowTask(e)} 
        className={taskActive} 
        component={taskActive === '' ? BagIcon : BagIconOrange}
      />
    </div>
  )
}

export default MapControl;


