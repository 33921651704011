import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Form, Input, Row, Col, Icon, Select, Modal, Divider, Badge, AutoComplete } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import { CustomerEditContext } from './edit-customer-context';
import styled from 'styled-components';
import AppAvatar from '../avatar';
import './css/index.css';
import { getBusinessType, getCustomerType } from '../add-customer/controller/controller';
import _, { debounce } from 'lodash';
import httpClient from '../../components/axiosClient';
import DefaultProfile from '../../components/image/Profile_Default.png';
import Resizer from 'react-image-file-resizer';
import MapLocation from './map-view-customer';
import ModalMapLocationFrom from '../create-customer-location/index';
import ModalEditMapLocationFrom from '../edit-customer-location/index';
import EditModalPrivacy from '../edit-modal-privacy/index';
import PrivacyController from '../../controllers/getCustomer/get-privacy-type';
import BusinessSvg from '../image/BusinessType2.svg';
import getOwner from '../../controllers/getCustomer/get-owner';

const { Option } = Select;

const ModalFormCustomer = ({
  visible,
  onCancel,
  onCreate,
  informationData,
  customerDataDefalse,
  form,
  image,
  setCustomer,
  setCheckImgDefault,
  checkImgDefault,
  privacyData,
  setprivacyData,
  type,
  nameAuto,
  setNameAuto,
}) => {
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const { imageRef, imageFile, setImageFile, imageCustomer, setImageCustomer, newImg, setNewImg } = image;
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const [businessType, setBusinessType] = useState([]);
  const [customerType, setCustomerType] = useState([]);

  const profileImgDefault = _.get(informationData, 'profileImgDefault');
  const profileImgDefaultCus = _.get(customerDataDefalse, 'profileImgDefault');
  const [visibled, setVisibled] = useState(false);
  const [visibleText, setVisibleText] = useState(false);
  // const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');
  const [newImage, setNewImage] = useState();

  const [locationFormRef, setLocationFormRef] = useState(null);
  const [editLocationFormRef, setEditLocationFormRef] = useState(null);
  const [refModalEditPrivacy, setRefModalEditPrivacy] = useState(null);

  const [customerID, setCustomerID] = useState('');
  const [cusAddressBookId, setCusAddressBookId] = useState(undefined);
  const [custName, setCustName] = useState('');

  const [addressList, setAddressList] = useState([]);
  const [triggerApi, setTriggerApi] = useState(false);

  const [latlngCustomer, setLatlngCustomer] = useState({});
  const [valueAddress, setValueAddress] = useState('');
  const [filterCustomerLocation, setFilterCustomerLocation] = useState(undefined);

  const [visibleModalFrom, setVisibleModalFrom] = useState(false);
  const [visibleModalFromEdit, setVisibleModalFromEdit] = useState(false);

  const [dataFromLocationEdit, setDataFromLocationEdit] = useState({});

  const [cusDataDefalse, setCusDataDefalse] = useState({});

  const [visibleModalEditPrivacy, setVisibleModalEditPrivacy] = useState(false);
  const [dataPrivacyType, setDataPrivacyType] = useState();
  const [dataTeam, setDataTeam] = useState();

  const [triggerCancel, setTriggerCancel] = useState(false);

  const [triggerResetFields, setTriggerResetFields] = useState(false);

  const [defalseLocation, setDefalseLocation] = useState();

  // const defImage = profileImg.includes('DDC4C9D');

  useEffect(() => {
    if (informationData) {
      setCustomerID(informationData.customerId);
      setCustName(informationData.customerName ? informationData.customerName : '');
      setCusDataDefalse({
        customerShortName: _.get(informationData, 'customerShortName'),
        customerName: _.get(informationData, 'customerName'),
        businessTypeId: _.get(informationData, 'businessType.businessTypeId'),
        customerTypeId: _.get(informationData, 'customerType.customerTypeId'),
        profileImg: newImg ? newImg : _.get(informationData, 'profileImg'),
        memComId: Number(_.get(informationData, 'createdById')),
      });
      setDefalseLocation({
        address: _.get(informationData, 'address'),
        locationName: _.get(informationData, 'locationName'),
        description: _.get(informationData, 'description'),
        lat: _.get(informationData, 'lat'),
        lng: _.get(informationData, 'lng'),
      });
      setCustomer.setCusDataBodyApi({
        customerShortName: _.get(informationData, 'customerShortName'),
        customerName: _.get(informationData, 'customerName'),
        businessTypeId: _.get(informationData, 'businessType.businessTypeId'),
        customerTypeId: _.get(informationData, 'customerType.customerTypeId'),
        profileImg: newImg ? newImg : _.get(informationData, 'profileImg'),
        address: _.get(informationData, 'address'),
        locationName: _.get(informationData, 'locationName'),
        description: _.get(informationData, 'description'),
        lat: _.get(informationData, 'lat'),
        lng: _.get(informationData, 'lng'),
      });
      const splitData = informationData && informationData.selectedPrivacy;
      const nameCode = splitData && splitData.code;
      const mapOrg =
        splitData &&
        splitData.orgIdLists.map((item) => {
          return item.orgId;
        });

      setprivacyData({
        privacy: nameCode,
        team: mapOrg,
      });

      setNameAuto([
        {
          name: _.get(informationData, 'createdBy'),
          memComId: Number(_.get(informationData, 'createdById')),
        },
      ]);
    }
    if (customerDataDefalse) {
      setCustomerID(customerDataDefalse.customerId);
      setCustName(customerDataDefalse.customerName ? customerDataDefalse.customerName : '');
      setCusDataDefalse({
        customerShortName: _.get(customerDataDefalse, 'customerCode'),
        customerName: _.get(customerDataDefalse, 'customerName'),
        businessTypeId: _.get(customerDataDefalse, 'businessTypeId'),
        customerTypeId: _.get(customerDataDefalse, 'customerTypeId'),
        profileImg: newImg ? newImg : _.get(customerDataDefalse, 'profileImg'),
        memComId: Number(_.get(customerDataDefalse, 'createdById')),
      });
      setDefalseLocation({
        address: _.get(customerDataDefalse, 'address'),
        locationName: _.get(customerDataDefalse, 'locationName'),
        description: _.get(customerDataDefalse, 'description'),
        lat: _.get(customerDataDefalse, 'lat'),
        lng: _.get(customerDataDefalse, 'lng'),
      });
      setCustomer.setCusDataBodyApi({
        customerShortName: _.get(customerDataDefalse, 'customerCode'),
        customerName: _.get(customerDataDefalse, 'customerName'),
        businessTypeId: _.get(customerDataDefalse, 'businessTypeId'),
        customerTypeId: _.get(customerDataDefalse, 'customerTypeId'),
        profileImg: newImg ? newImg : _.get(customerDataDefalse, 'profileImg'),
        address: _.get(customerDataDefalse, 'address'),
        locationName: _.get(customerDataDefalse, 'locationName'),
        description: _.get(customerDataDefalse, 'description'),
        lat: _.get(customerDataDefalse, 'lat'),
        lng: _.get(customerDataDefalse, 'lng'),
      });
      const splitData = customerDataDefalse && customerDataDefalse.selectedPrivacy;
      const nameCode = splitData && splitData.code;
      const mapOrg =
        splitData &&
        splitData.orgIdLists.map((item) => {
          return item.orgId;
        });

      setprivacyData({
        privacy: nameCode,
        team: mapOrg,
      });

      setNameAuto([
        {
          name: _.get(customerDataDefalse, 'createdBy'),
          memComId: Number(_.get(customerDataDefalse, 'createdById')),
        },
      ]);
    }
    // setCheckImgDefault(false)
  }, [informationData, customerDataDefalse, newImg]);

  const LabelRequire = styled.label`
    color: #ff1010;
  `;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  useEffect(() => {
    const getApiBusinessTypeTnfo = async () => {
      const getBusinessTypeTnfo = await getBusinessType();
      setBusinessType(getBusinessTypeTnfo);
    };
    getApiBusinessTypeTnfo();
  }, []);

  useEffect(() => {
    const getApiCustomerTypeTnfo = async () => {
      const getCustomerTypeTnfo = await getCustomerType();
      setCustomerType(getCustomerTypeTnfo);
    };
    getApiCustomerTypeTnfo();
  }, []);

  useEffect(() => {
    setImageFileDefault(DefaultProfile);
  }, [DefaultProfile]);

  useEffect(() => {
    getPrivacyType();
  }, []);

  const getPrivacyType = async () => {
    const response = await PrivacyController();
    if (response.status.code == 200) {
      setDataPrivacyType(response.data.privacyTypes);
      setDataTeam(response.data.teams);
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      setVisibleText(false);
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);

        reader.onloadend = () => {
          const image = reader.result;
          setImageFile(image);
        };
        reader.readAsDataURL(file);

        const fileChange = dataURLtoFile(image, file.name);
        setImageCustomer(fileChange);
        setCheckImgDefault(true);
      }
    }
  };

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const deleteImage = (e) => {
    setNewImg(profileImgDefault ? profileImgDefault : profileImgDefaultCus);
    setImageFile('');
    setImageCustomer();
    imageRef.current.value = null;
    setCheckImgDefault(true);
  };

  const fncOnCancel = () => {
    onCancel();
    setFilterCustomerLocation(undefined);
    setCusAddressBookId(undefined);
    // setDefalseLocation()
  };

  const handleEditFrom = () => {
    setVisibleModalFromEdit(true);
    // setDataFromLocationEdit(filterCustomerLocation);
    // setCustomer.setCusDataBodyApi(filterCustomerLocation);
  };

  useEffect(() => {
    handleGetLocation();
  }, [customerID, triggerApi]);

  const handleGetLocation = async () => {
    try {
      const body = {
        customerId: customerID,
      };
      const response = await httpClient.post(
        `/v3/business-partner/manager/company/${comCode}/customer-address-books/from-to-address`,
        body
      );

      if (response.status == 200) {
        setAddressList(response.data.data.toLocations);
      }
    } catch (error) {}
  };

  const fncSelectFromAddress = (value) => {
    const newCustomer = addressList?.filter((ele) => ele.customerAddressBookId === value);
    setCusAddressBookId(newCustomer[0].customerAddressBookId);
    setFilterCustomerLocation(newCustomer[0]);
    setCustomer.setCusDataBodyApi(newCustomer[0]);
    setCustomer.setCustomerIdApi(newCustomer[0].customerAddressBookId);
  };

  // -------------------------- Create Form Location Customer BY TAE---------------------------------------------

  const handleCreateFromLocation = () => {
    locationFormRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const body = {
        customerId: customerID,
        customerName: custName,
        addressName: values.locationName,
        fullAddress: values.location,
        address: values.location,
        description: values.description,
        lat: latlngCustomer.lat,
        lng: latlngCustomer.lng,
        subDistrict: '',
        province: '',
        postCode: '',
      };

      setFilterCustomerLocation(body);
      setCustomer.setCusDataBodyApi(body);

      // if (customerID) {
      //   try {
      //     const body = {
      //       customerId: customerID,
      //       customerName: custName,
      //       addressName: values.locationName,
      //       fullAddress: values.location,
      //       address: values.location,
      //       description: values.description,
      //       lat: latlngCustomer.lat,
      //       lng: latlngCustomer.lng,
      //       subDistrict: '',
      //       province: '',
      //       postCode: '',
      //     };

      //     const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-address-books`, body);

      //     if (response.status === 200) {
      //       setTriggerApi(!triggerApi);
      //       successNotification(response.data.status.message);
      //       setLatlngCustomer({});
      //       setTimeout(() => {
      //         setCusAddressBookId(response.data.data.customerAddressBookId);
      //         setFilterCustomerLocation(response.data.data);
      //         setCustomer.setCusDataBodyApi(response.data.data);
      //         setCustomer.setCustomerIdApi(response.data.data.customerAddressBookId);
      //       }, 500);
      //     } else {
      //       errorNotification(response.data.status.message);
      //     }
      //   } catch (error) {
      //     errorNotification(error.response.message);
      //   }
      // }
      setVisibleModalFrom(false);
      setImageFile('');
      setImageCustomer();
      imageRef.current.value = null;
      // locationFormRef.resetFields();
    });
  };

  const handleEditModalFrom = () => {
    editLocationFormRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const body = {
        addressName: values.locationName,
        fullAddress: values.location,
        address: values.location,
        description: values.description,
        lat: latlngCustomer.lat,
        lng: latlngCustomer.lng,
        customerName: dataFromLocationEdit.customerName,
        contactName: '',
        subDistrict: '',
        district: '',
        province: '',
        postCode: '',
      };
      setFilterCustomerLocation(body);
      setCustomer.setCusDataBodyApi(body);
      setDefalseLocation({
        address: values.location,
        locationName: values.locationName,
        description: values.description,
        lat: latlngCustomer.lat,
        lng: latlngCustomer.lng,
      });

      // if (dataFromLocationEdit && dataFromLocationEdit.customerAddressBookId !== "") {
      //   try {
      //     const body = {
      //       addressName: values.locationName,
      //       fullAddress: values.location,
      //       address: values.location,
      //       description: values.description,
      //       lat: latlngCustomer.lat,
      //       lng: latlngCustomer.lng,
      //       customerName: dataFromLocationEdit.customerName,
      //       contactName: '',
      //       subDistrict: '',
      //       district: '',
      //       province: '',
      //       postCode: '',
      //     };

      //     const response = await httpClient.put(
      //       `/v3/business-partner/manager/company/${comCode}/customer-address-books/${dataFromLocationEdit.customerAddressBookId}`,
      //       body,
      //     );

      //     if (response.status === 200) {
      //       setTriggerApi(!triggerApi);
      //       successNotification(response.data.status.message);
      //       setLatlngCustomer({});
      //       setTimeout(() => {
      //         setCusAddressBookId(response.data.data.customerAddressBookId);
      //         setFilterCustomerLocation(response.data.data);
      //         setCustomer.setCusDataBodyApi(response.data.data);
      //       }, 500);
      //     } else {
      //       errorNotification(response.data.status.message);
      //     }
      //   } catch (error) {

      //   }
      // }

      setVisibleModalFromEdit(false);
      // editLocationFormRef.resetFields();
    });
  };

  // --------------------------Edit Cancel Modal Form Location Customer BY TAE---------------------------------------------
  const handleCancelModalFrom = () => {
    setLatlngCustomer({});
    // cusAddressBookId(undefined);
    setCusAddressBookId(undefined);
    setVisibleModalFrom(false);
    setValueAddress(undefined);
    setDataFromLocationEdit({});
  };

  const handleCancelEditModalFrom = () => {
    setLatlngCustomer({});
    setVisibleModalFromEdit(false);
    // setTriggerCancel(!triggerCancel)
    // if (triggerResetFields == false) {
    editLocationFormRef.resetFields();

    // }
    // setValueAddress(undefined);
    // setDataFromLocationEdit(null);
    // setCusDataDefalse({});
  };

  // --------------------------Edit Save Form Location Customer BY TAE---------------------------------------------

  const saveCustomerFormRef = useCallback((node) => {
    if (node !== null) {
      setLocationFormRef(node);
    }
  }, []);

  const saveEditCustomerFormRef = useCallback((node) => {
    if (node !== null) {
      setEditLocationFormRef(node);
    }
  }, []);

  const TextPrivacy = () => {
    const nameText = _.get(privacyData, 'privacy');
    // const name = _.get(privacyData, 'name')
    const OrgList = _.get(privacyData, 'team');
    const namePrivacy = nameText && nameText !== undefined ? dataPrivacyType.filter((col) => nameText.includes(col.code)) : undefined;
    const exportName = namePrivacy && namePrivacy !== undefined ? namePrivacy[0].name : undefined;
    const codePrivacy = namePrivacy && namePrivacy !== undefined ? namePrivacy[0].code : undefined;

    return (
      <div className="icon-input">
        <Icon
          type={codePrivacy == 'team' ? 'team' : codePrivacy == 'public' ? 'global' : codePrivacy == 'only_me' ? 'lock' : undefined}
          className="style-margin"
        />
        <Form.Item style={{ marginLeft: '2px', padding: 'unset', paddingTop: '6px' }}>
          <span style={{ cursor: 'pointer' }} onClick={() => setVisibleModalEditPrivacy(true)}>
            {exportName}&nbsp;
            {OrgList && nameText == 'team' ? `(${OrgList.length})` : null}
          </span>
        </Form.Item>
      </div>
    );
  };

  const saveEditPrivacy = useCallback((node) => {
    if (node !== null) {
      setRefModalEditPrivacy(node);
    }
  }, []);

  const handleCreateEditPrivacy = () => {
    refModalEditPrivacy.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        setprivacyData(values);
        setVisibleModalEditPrivacy(false);
      }
    });
  };

  const ImgBusinessType = () => <img src={BusinessSvg} style={{ width: '17px', height: '17px' }}></img>;

  const handleSearchName = debounce(async (fieldChang) => {
    if (fieldChang !== undefined && fieldChang !== '') {
      let newData = await getOwner(fieldChang);
      if (_.size(newData) !== 0) {
        setNameAuto(newData.data.members);
      }
    } else {
      setNameAuto([]);
    }
  }, 200);

  const handleBlur = () => {
    const OwnerValue = getFieldValue(`owner`);
    // const filterValue = nameAuto && nameAuto.filter((col) => OwnerValue.includes(col.name));
    const filterValue =
      nameAuto &&
      nameAuto.filter((item) => {
        return Number(OwnerValue) === Number(item.memComId);
      });
    if (_.size(filterValue) === 0) {
      form.setFieldsValue({
        ['owner']: undefined,
      });
    }
  };

  const children =
    nameAuto && _.size(nameAuto) !== 0 && nameAuto[0].memComId !== NaN && nameAuto[0].name !== undefined
      ? nameAuto.map((item) => <Option key={item.memComId}>{item.name}</Option>)
      : null;

  return (
    <CustomerEditContext.Provider
      value={{
        stateCustomerEdit: {
          informationData,
          valueAddress,
          filterCustomerLocation,
          dataFromLocationEdit,
          cusDataDefalse,
        },
        setStateCustomerEdit: {
          setValueAddress,
          setDataFromLocationEdit,
        },
      }}
    >
      <Modal
        title={
          type
            ? intl.formatMessage({ id: 'ModalVendorEditHeader', defaultMessage: 'Edit Vendor' })
            : intl.formatMessage({ id: 'ModalCustomerEditHeader', defaultMessage: 'Edit Customer' })
        }
        centered="true"
        className="business-partner-modal"
        visible={visible}
        onCancel={fncOnCancel}
        onOk={onCreate}
        width={700}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={fncOnCancel}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={onCreate}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form layout="vertical">
          <Row>
            <Col span={8}>
              <div className="image-container">
                <AppAvatar size={150} src={`${imageFile ? imageFile : cusDataDefalse.profileImg}`} />
                <input
                  ref={imageRef}
                  type="file"
                  style={{ display: 'none' }}
                  accept=".jpg, .png, .JPG, .PNG"
                  onChange={handleChangePicture}
                />
                {imageFile ? (
                  <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
                    <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                    &nbsp; &nbsp;
                    <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImage} />
                  </div>
                ) : cusDataDefalse.profileImg && cusDataDefalse.profileImg.indexOf('defaultCustomerImage') > 0 ? (
                  <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
                    <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                  </div>
                ) : (
                  <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
                    <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                    &nbsp; &nbsp;
                    <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImage} />
                  </div>
                )}
              </div>
            </Col>
            <Col span={16}>
              <div className="customer-container">
                <div>
                  <Row>
                    <Col span={9}>
                      <Form.Item>
                        {getFieldDecorator('cusCode', {
                          initialValue: cusDataDefalse.customerShortName,
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerCode', defaultMessage: 'Please enter code' }),
                            },
                          ],
                        })(
                          <Input
                            size="large"
                            className="new-input-add-user-name"
                            placeholder={
                              type
                                ? intl.formatMessage({ id: 'ModalCustomerPlacholderVendorCode', defaultMessage: 'Enter Vendor Code' })
                                : intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerCode', defaultMessage: 'Enter Customer Code' })
                            }
                            style={{ padding: 'unset' }}
                            autoComplete="off"
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={1}>
                      <Form.Item style={{ padding: 'unset', paddingTop: '4px' }}>
                        <Badge color="#000000" className="badge-text-edit" />
                      </Form.Item>
                    </Col>

                    <Col span={14}>
                      <Form.Item style={{ marginLeft: '4px' }}>
                        {getFieldDecorator('cusName', {
                          initialValue: cusDataDefalse.customerName,
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerName', defaultMessage: 'Please enter name' }),
                            },
                          ],
                        })(
                          <Input
                            size="large"
                            className="new-input-add-user-name"
                            placeholder={
                              type
                                ? intl.formatMessage({ id: 'ModalCustomerPlacholderVendorName', defaultMessage: 'Enter Vendor Name' })
                                : intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerName', defaultMessage: 'Enter Customer Name' })
                            }
                            style={{ padding: 'unset' }}
                            autoComplete="off"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col span={24}>
                    {/* <div className='icon-input'> */}
                    <TextPrivacy />
                    {/* </div> */}
                  </Col>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon component={ImgBusinessType} className="style-margin" />
                      <Form.Item>
                        {getFieldDecorator('busType', {
                          initialValue: cusDataDefalse.businessTypeId,
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'ModalCustomerValidateBusinessType',
                                defaultMessage: 'Please select business type',
                              }),
                            },
                          ],
                        })(
                          <Select
                            className="select-hidden-border"
                            placeholder={intl.formatMessage({
                              id: 'ModalCustomerPlacholderBusinessType',
                              defaultMessage: 'Select Business Type',
                            })}
                          >
                            {businessType.map((item, index) => (
                              <Option key={item.businessTypeId}>{item.businessTypeName}</Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type="solution" className="style-margin" />
                      <Form.Item>
                        {getFieldDecorator('cusType', {
                          initialValue: cusDataDefalse.customerTypeId,
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'ModalCustomerValidateCustomerType',
                                defaultMessage: 'Please select customer type',
                              }),
                            },
                          ],
                        })(
                          <Select
                            className="select-hidden-border"
                            placeholder={
                              type
                                ? intl.formatMessage({ id: 'ModalCustomerPlacholderVendorType', defaultMessage: 'Select Vendor Type' })
                                : intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerType', defaultMessage: 'Select Customer Type' })
                            }
                          >
                            {customerType.map((item, index) => (
                              <Option key={item.customerTypeId}>{item.customerTypeName}</Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>

              <Row>
                <Divider className="divider-style-customer" />
                <div>
                  <div>
                    <p className="text-address-local">
                      <FormattedMessage id="ModalCustomerAddressText" defaultMessage="Address" />
                    </p>
                  </div>
                </div>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <MapLocation defalseLocation={defalseLocation} />
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item className="form-line-height">
                      <div className="text-location-short">
                        <span
                          className="span-format"
                          style={{ color: '#1D3557', cursor: 'pointer', fontSize: '14px' }}
                          onClick={() => handleEditFrom()}
                        >
                          {filterCustomerLocation && filterCustomerLocation !== undefined
                            ? _.get(filterCustomerLocation, 'addressName')
                            : _.get(defalseLocation, 'locationName')}
                        </span>
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <div className="text-location-short2">
                        {filterCustomerLocation && filterCustomerLocation !== undefined ? (
                          <span className="span-format" style={{ color: '#1D3557', fontSize: '14px' }}>
                            {_.get(filterCustomerLocation, 'description')}
                          </span>
                        ) : (
                          <span className="span-format" style={{ color: '#1D3557', fontSize: '14px' }}>
                            {_.get(defalseLocation, 'description')}
                          </span>
                        )}
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <div>
                        <div className="text-location-long">
                          <Icon
                            type="environment"
                            style={{
                              fontSize: '12px',
                              color: '#1D3557',
                              paddingLeft: '0px',
                              paddingRight: '4px',
                              cursor: 'pointer',
                            }}
                          />
                          {filterCustomerLocation && filterCustomerLocation !== undefined ? (
                            <span className="span-format" style={{ color: '#1D3557', fontSize: '14px' }}>
                              {_.get(filterCustomerLocation, 'address')}
                            </span>
                          ) : (
                            <span className="span-format" style={{ color: '#1D3557', fontSize: '14px' }}>
                              {_.get(defalseLocation, 'address')}
                            </span>
                          )}
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                </Col>
                {type === 'vendor' ? null : <Divider type="horizontal" />}
              </Row>
              {type === 'vendor' ? null : (
                <Row gutter={[16, 16]}>
                  <Col span={4} style={{ paddingTop: '14px', paddingRight: 'unset' }}>
                    <span>
                      Owner:
                      <LabeRequire req={true} />
                    </span>
                  </Col>
                  <Col span={12} style={{ paddingLeft: 'unset' }}>
                    <Form.Item>
                      {getFieldDecorator('owner', {
                        initialValue: String(_.get(cusDataDefalse, 'memComId')),
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'ModalCustomerValidateOwner', defaultMessage: 'Please enter owner' }),
                          },
                        ],
                      })(
                        <AutoComplete
                          className="autocompleted-style"
                          placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderOwner', defaultMessage: 'Enter Owner' })}
                          onSearch={handleSearchName}
                          onBlur={handleBlur}
                        >
                          {children}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>

      <ModalMapLocationFrom
        ref={saveCustomerFormRef}
        visible={visibleModalFrom}
        setLatlngCustomer={setLatlngCustomer}
        onCancel={() => handleCancelModalFrom()}
        onCreate={() => handleCreateFromLocation()}
        stateCustomer={{
          valueAddress,
          filterCustomerLocation,
          dataFromLocationEdit,
        }}
        setStateCustomer={{
          setValueAddress,
          setDataFromLocationEdit,
        }}
      />

      <ModalEditMapLocationFrom
        ref={saveEditCustomerFormRef}
        visible={visibleModalFromEdit}
        setLatlngCustomer={setLatlngCustomer}
        onCancel={() => handleCancelEditModalFrom()}
        onCreate={() => handleEditModalFrom()}
        stateCustomer={{
          valueAddress,
          filterCustomerLocation,
          dataFromLocationEdit,
          cusDataDefalse,
          visibleModalFromEdit,
        }}
        setStateCustomer={{
          setValueAddress,
          setDataFromLocationEdit,
        }}
        triggerCancel={triggerCancel}
        setTriggerResetFields={setTriggerResetFields}
        defalseLocation={defalseLocation}
      />

      <EditModalPrivacy
        ref={saveEditPrivacy}
        onCreate={() => handleCreateEditPrivacy()}
        setVisibleModalPrivacy={setVisibleModalEditPrivacy}
        visibleModalPrivacy={visibleModalEditPrivacy}
        dataPrivacyType={dataPrivacyType}
        dataTeam={dataTeam}
        dataPrivacy={privacyData}
        intl={intl}
      />
    </CustomerEditContext.Provider>
  );
};

const ModalForm = Form.create({
  name: 'modal_form',
  // mapPropsToFields(props) {

  //   const customerCode = _.get(props.informationData, 'customerShortName');
  //   const customerName = _.get(props.informationData, 'customerName');
  //   const businessType = props.informationData ? _.get(props.informationData.businessType, 'businessTypeId') : undefined;
  //   const customerType = props.informationData ? _.get(props.informationData.customerType, 'customerTypeId') : undefined;
  //   return {
  //     cusCode: Form.createFormField({
  //       value: customerCode,
  //     }),
  //     cusName: Form.createFormField({
  //       value: customerName,
  //     }),
  //     busType: Form.createFormField({
  //       value: businessType,
  //     }),
  //     cusType: Form.createFormField({
  //       value: customerType,
  //     }),
  //   };
  // },
})(ModalFormCustomer);

export default ModalForm;
// export default ModalFormCustomer;
