import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import SignoutApi from '../../../components/httpClient';
import GetLang from '../../../includes/language';
import { Row, Col, Modal, Typography, notification, Collapse, Card, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import Button01 from '../../../components/v2/button_01';
import { useKeycloak } from '@react-keycloak/web';
import cssStyle from './css/userMenu.css';

const { Panel } = Collapse;

// const qs = require('query-string');
// var jwt = require('jsonwebtoken');
let client = SignoutApi();

const { Text } = Typography;

export default (props) => {
  const intl = useIntl();

  const { keycloak } = useKeycloak();
  const userId = localStorage.getItem('userId');
  // const [userDetail, setUserDetail] = useState([]);
  // const [language, setLanguage] = useState({});
  const [labelShow, setLabelShow] = useState('English');
  const [buttonValue, setButtonValue] = useState();
  const [manageBtValue, setManageBtValue] = useState();
  // const [switchLang, setSwitchLang] = useState(
  //   JSON.parse(window.localStorage.getItem('switchLang')),
  // );
  // const [isOpen, setIsOpen] = useState(false);

  // get const จาก component อื่นมาใช้
  // const companyId = localStorage.getItem('companyId');
  const memComId = localStorage.getItem('memComId');
  const comId = localStorage.getItem('comId');
  const adminName = localStorage.getItem('adminName');
  let userAvatar = localStorage.getItem('userAvatar');

  const labelShow2 = props.labelShow;
  // const langValue = props.langValue;
  const hide = props.hide;

  // console.log('User Avatar Img::: ', userAvatar);

  //get data ที่เป็น object
  // const getLang = JSON.parse(window.localStorage.getItem('switchLang'));
  // const labelShowValue = localStorage.getItem('labelShowValue');
  //set data ไปใช้ที่ component อื่น
  // localStorage.setItem('langValue', buttonValue);
  localStorage.setItem('labelShow', labelShow);
  localStorage.setItem('manageBtValue', manageBtValue);

  // const langValue = localStorage.getItem('langValue'); //เต้เพิ่มมา

  // const language = props.langValue;

  // useEffect(() => {
  // getApi();
  // }, []);

  const logoutNotification = (type) => {
    notification[type]({
      message: 'Loging out',
      duration: 3,
    });
  };
  // เปลี่ยนภาษาเป็นภาษาอังกฤษ

  // console.log('props ComId',props.comId)

  const langSwicthEN = async (e) => {
    localStorage.setItem('langValue', 'EN');
    await GetLang({
      companyId: props.comId,
      lang: 'EN',
      pageCode: '001',
    });
    setLabelShow('English');
    setButtonValue('EN');
    window.location.reload();
  };

  const callback = (key) => {};

  // เปลี่ยนเป็นภาษาไทย
  const langSwicthTH = async (e) => {
    localStorage.setItem('langValue', 'TH');
    await GetLang({
      companyId: props.comId,
      lang: 'TH',
      pageCode: '001',
    });
    setLabelShow('Thai - ไทย');
    setButtonValue('TH');
    window.location.reload();
  };

  // เปลี่ยนเป็นภาษาจีน
  const langSwicthCN = (e) => {
    setLabelShow('Chinense - 中文');
    setButtonValue('CN');
    window.location.reload();
  };

  // const getApi = async () => {
  //   client.defaults.headers = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   };
  //   //comid, mem_com_id
  //   const res = await client.get(`/v2/users/${memComId}/${comId}`);
  //   // setUserDetail(res.data[0]);
  // };

  const showConfirm = () => {
    Modal.confirm({
      className: 'signOut-modal-confirm',
      title: intl.formatMessage({ id: 'lblSignOut', defaultMessage: 'Do you want to sign out ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okType: 'danger',
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'dashed',
      },
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            localStorage.setItem('comId', 'undefined');
            localStorage.setItem('companyId', 'undefined');
            localStorage.clear();
            keycloak.logout({ redirectUri: `${window.location.origin}/main` });
          }, 1000);
        }).catch(console.error());
      },
      onCancel() {},
    });
  };

  // const signOut = () => {
  //   const allLocal = localStorage;
  //   allLocal.clear();
  //   logoutNotification('warning');
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 1000);
  // };

  // const handleSignout = async (tokenKey) => {
  //   try {
  //     client.defaults.baseURL = `${process.env.REACT_APP_KEYCLOAK_URL}`;
  //     client.defaults.headers = {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       Authorization: `Bearer ${tokenKey}`,
  //     };
  //     const res = await client.post(
  //       `/auth/admin/realms/master/users/${userId}/logout/`,
  //     );

  //     if (res.status === 204) {
  //       logoutNotification('success');
  //     }
  //   } catch (error) {

  //   }
  // };

  // const handleAccountBtClick = () => {
  //   setTimeout(() => {
  //     window.location.href = '/menu/manageAccout/' + memComId;
  //   },500);
  //   // props.hide();
  // };

  return (
    <Card
      actions={[
        <div onClick={showConfirm}>
          {' '}
          <span className="text-managebt-style">
            <FormattedMessage id="btnSignOut" defaultMessage="Sign Out" />
          </span>{' '}
        </div>,
      ]}
      style={{ backgroundColor: '#FFFFFF', lineHeight: '2' }}
      bordered={false}
    >
      <Row gutter={26}>
        <Col span={9} order={1}>
          <Avatar size={90} src={`${process.env.REACT_APP_IMG_HOST}` + userAvatar} style={{ marginLeft: '7%' }} />{' '}
        </Col>
        <Col span={15} order={2}>
          <span style={{ fontSize: '16px', color: '#000000' }}>{adminName}</span> <br />
          <Collapse
            className="collapse-menu"
            defaultActiveKey={['1']}
            expandIconPosition="right"
            onChange={callback}
            style={{ paddingBottom: '6%' }}
          >
            <Panel
              className="menu-css"
              value={buttonValue}
              header={
                <Text type="secondary">
                  Language{' '}
                  <span style={{ paddingLeft: '10%', fontSize: '13px' }}>
                    {localStorage.getItem('langValue') === 'TH' ? 'Thai - ไทย' : 'English'}
                  </span>
                </Text>
              }
            >
              <div
                style={{
                  float: 'right',
                  width: '64%',
                  flexDirection: 'row',
                  paddingLeft: '0px',
                  marginRight: '0%',
                }}
              >
                <Text type="secondary" style={{ cursor: 'pointer', fontSize: '90%' }} value={buttonValue} onClick={langSwicthEN}>
                  {' '}
                  English
                </Text>{' '}
                <br />
                <Text type="secondary" style={{ cursor: 'pointer', fontSize: '90%' }} value={buttonValue} onClick={langSwicthTH}>
                  {' '}
                  Thai - ไทย
                </Text>
                <br />
              </div>
            </Panel>
          </Collapse>
          <div onClick={hide}>
            <Link to={'/main/user-management/my-profile/' + memComId}>
              <Button01 btnsize="wd_at" fontsize="sm" type="primary" style={{ margin: 'unset' }}>
                <FormattedMessage id="lblManageMyProfile" defaultMessage="Manage my profile" />
              </Button01>
            </Link>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
