import React, { useState, useEffect } from 'react'
import { Card, Button, Icon } from 'antd'
import './css/index.css'
import HeaderContent from './header'
import ActionContent from './action'
import TableContent from './table'
import { useIntl, FormattedMessage } from 'react-intl';
import { getDataDriverTask, exportDriverTask } from '../../../../controllers/driver-Task-report/api'
import _ from 'lodash'
import getAllTeam from '../../../../controllers/get-all-team/get-all-team'
import getMemberSelf from '../../../../controllers/getCustomer/get-member-self'
import IconExcel from '../../../../components/image/excel.svg';
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { errorNotification, successNotification } from '../../../../components/v2/notification'
import { PageSettings } from '../../../../config/page-settings'
import { render } from 'less'

export default () => {
  const intl = useIntl();
  const [totalHeader, setTotalHeader] = useState();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsDate', defaultMessage: 'Date' }),
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      width: 160,
      sorter: (a, b) => a.date - b.date,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsSource', defaultMessage: 'Source' }),
      dataIndex: 'from',
      key: 'from',
      // width: 350,
      sorter: (a, b) => a.from - b.from,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsItemType', defaultMessage: 'Item Type' }),
      dataIndex: 'itemType',
      key: 'itemType',
      // width: 250,
      sorter: (a, b) => a.itemType - b.itemType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsDestination', defaultMessage: 'Destination' }),
      dataIndex: 'destination',
      key: 'destination',
      // width: 350,
      sorter: (a, b) => a.destination - b.destination,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsLicensePlate', defaultMessage: 'License Plate' }),
      dataIndex: 'assetNo',
      key: 'assetNo',
      width: 150,
      sorter: (a, b) => a.assetNo - b.assetNo,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsLoaded', defaultMessage: 'Loaded (km)' }),
      dataIndex: 'heavyCarKm',
      key: 'heavyCarKm',
      width: 150,
      sorter: (a, b) => a.heavyCar - b.heavyCar,
      sortDirections: ['descend', 'ascend'],
      // render: (text, record, index) => record.heavyCarKm
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsEmpty', defaultMessage: 'Empty (km)' }),
      dataIndex: 'lightCarKm',
      key: 'lightCarKm',
      width: 150,
      sorter: (a, b) => a.lightCar - b.lightCar,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsWeight', defaultMessage: 'Weight (Ton)' }),
      dataIndex: 'weight',
      key: 'weight',
      width: 150,
      sorter: (a, b) => a.weight - b.weight,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsIncentive', defaultMessage: 'Incentive (Baht)' }),
      dataIndex: 'incentive',
      key: 'incentive',
      width: 160,
      sorter: (a, b) => a.incentive - b.incentive,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) =>
      <NumberFormat value={(parseFloat(record.incentive || 0)).toFixed(2)} displayType={'text'} thousandSeparator={true} />
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsFreightTon', defaultMessage: 'Freight/Ton' }),
      dataIndex: 'freightTon',
      key: 'freightTon',
      width: 150,
      sorter: (a, b) => a.freightTon - b.freightTon,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'driverExpenseReportColumnsFreightBaht', defaultMessage: 'Freight (Baht)' }),
      dataIndex: 'freightBath',
      key: 'freightBath',
      width: 150,
      sorter: (a, b) => a.freightBath - b.freightBath,
      sortDirections: ['descend', 'ascend'],
      render: (text,record,index) =>
      <NumberFormat value={(parseFloat(record.freightBath || 0)).toFixed(2)} displayType={'text'} thousandSeparator={true}/>
      
    },
  ]

  const setShowColumn = {
    index: true,
    date: true,
    from: true,
    destination: true,
    assetNo: true,
    itemType: true,
    weight: true,
    incentive: true,
    freightTon: true,
    freightBath: true,
    heavyCarKm: true,
    lightCarKm: true,
  };
  const setShowColumnArr = [
    'index',
    'date',
    'from',
    'destination',
    'assetNo',
    'itemType',
    'weight',
    'incentive',
    'freightTon',
    'freightBath',
    'heavyCarKm',
    'lightCarKm',
  ];

  // console.log('totalHeader ::', totalHeader, columns)


  const [tableData, setTableData] = useState();
  const [team, setTeam] = useState();
  const [teamData, setTeamData] = useState([]);
  const [member, setMember] = useState();
  const [memberData, setMemberData] = useState();

  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [visible, setVisible] = useState(false);
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const today = new Date();
  const [mainDateStart, setMainDateStart] = useState(moment(today).startOf("month"));
  const [mainDateEnd, setMainDateEnd] = useState(moment(today).endOf("month"));
  const [orderSort, setOrderSort] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();

  const [total, setTotal] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [scrollTableX, setScrollTableX] = useState(2000)

  useEffect(() => {
    const getData = async () => {
      const body = {
        orgId: team,
        memComId: member,
        startDate: moment(mainDateStart).format('YYYY-MM-DD'),
        endDate: moment(mainDateEnd).format('YYYY-MM-DD'),
        orderType: orderSort,
        orderBy: fieldSort,
        paging: paginationPage,
        rowsPerPages: paginationSize
      }
      const response = await getDataDriverTask(body)
      // const mapColumn = columns.map(item => {
      //   return {
      //     title: item.title,
      //     dataIndex: item.dataIndex,
      //     key: item.key,
      //     width: item.width,
      //     align: item.align,
      //     fixed: item.fixed,
      //     render: item.render,
      //     sorter: item.sorter,
      //     sortDirections: item.sortDirections,
      //     children: item.key === 'lightCarKm' ? [
      //       {
      //         title: `${_.get(response, 'total.TotalLightCar')}`,
      //         width: 150,
      //         align: 'center',
      //         dataIndex: 'lightCarKm',
      //         key: 'lightCarKm',
      //       }
      //     ] : undefined
      //   }
      // })
      // setNewDataColumns(mapColumn)
      setTableData(response.data)
      setTotalHeader(response.total)
      setTotal(response.totalTasks)
    }
    if (team && member) {
      getData();
    }
  }, [team, member, mainDateStart, mainDateEnd, fieldSort, orderSort, paginationPage, paginationSize]);

  const exportData = async () => {
    const body = {
      orgId: team,
      memComId: member,
      startDate: moment(mainDateStart).format('YYYY-MM-DD'),
      endDate: moment(mainDateEnd).format('YYYY-MM-DD'),
      orderType: orderSort,
      orderBy: fieldSort,
      paging: paginationPage,
      rowsPerPages: total,
      viewFormat: [
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsDate', defaultMessage: 'Date' }), sequence: 1, colCode: "date" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsFrom', defaultMessage: 'From' }), sequence: 2, colCode: "from" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsItemType', defaultMessage: 'Item Type' }), sequence: 3, colCode: "itemType" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsDestination', defaultMessage: 'Destination' }), sequence: 4, colCode: "destination" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsLicensePlate', defaultMessage: 'License Plate' }), sequence: 5, colCode: "assetNo" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsLoaded', defaultMessage: 'Loaded (km)' }), sequence: 6, colCode: "heavyCarKm" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsEmpty', defaultMessage: 'Empty (km)' }), sequence: 7, colCode: "lightCarKm" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsWeight', defaultMessage: 'Weight (Ton)' }), sequence: 8, colCode: "weight" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsIncentive', defaultMessage: 'Incentive (Baht)' }), sequence: 9, colCode: "incentive" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsFreightTon', defaultMessage: 'Freight/Ton' }), sequence: 10, colCode: "freightTon" },
        { colName: intl.formatMessage({ id: 'driverExpenseReportColumnsFreightBaht', defaultMessage: 'Freight (Baht)' }), sequence: 11, colCode: "freightBath" },
      ]
    }
    const response = await exportDriverTask(body)
    if (response.status === 200) {
      window.open(response.data.data.url);
      successNotification(response.data.status.message);
    } else {
      errorNotification(response.data.status.message);
    }
  }

  useEffect(() => {
    const getTeam = async () => {
      const response = await getAllTeam()
      setTeamData(response)
    }
    getTeam();
  }, []);

  useEffect(() => {
    const getMember = async () => {
      if (team) {
        const response = await getMemberSelf(team)
        setMemberData(response.data.members)
      }
    }
    getMember();
  }, [team]);

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) { // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(newListArrayColumns, function (result, value, key) {
        result[value] = true;
        return result;
      }, {});
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ)
      setTextErrorSelectColumn('');
    }
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 2) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find(
        (element) => element == columns[i].key,
      );
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    if (_.size(setNewArr) <= 3) {
      setScrollTableX(0)
      for (var i = 0; i < setNewArr.length; ++i) {
        if (setNewArr[i]['key'] !== 'index' && setNewArr[i]['key'] !== 'date') {
          setNewArr[i]['width'] = undefined;
        }
      }
    }
    if (_.size(setNewArr) <= 5) {
      setScrollTableX(0)
      for (var i = 0; i < setNewArr.length; ++i) {
        if (setNewArr[i]['key'] !== 'index' && setNewArr[i]['key'] !== 'date') {
          setNewArr[i]['width'] = undefined;
        }
      }
    }
    if (_.size(setNewArr) <= 7) {
      setScrollTableX(0)
      for (var i = 0; i < setNewArr.length; ++i) {
        if (setNewArr[i]['key'] !== 'index' && setNewArr[i]['key'] !== 'date') {
          setNewArr[i]['width'] = undefined;
        }
      }
    }
    if (_.size(setNewArr) <= 9) {
      setScrollTableX(2000)
      for (var i = 0; i < setNewArr.length; ++i) {
        if (setNewArr[i]['key'] !== 'index' && setNewArr[i]['key'] !== 'date') {
          setNewArr[i]['width'] = undefined;
        }
      }
    }
    // console.log('setNewArr ::', setNewArr)
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const selectTreeNode = (key) => {
    setTeam(key)
    setMember()
    setTableData()
  }

  const selectTeam = (key) => {
    setMember(key)
  }

  const handlePagination = (page) => {
    setPaginationPage(page);
  }

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  }

  const iconExcel = () => (
    <img className="monitor-icon-excel" src={IconExcel} />
  );

  const onChangeDate = (data) => {
    setMainDateStart(data[0])
    setMainDateEnd(data[1])
  }

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
        setFieldSort(field);

      } else {
        setOrderSort('asc');
        setFieldSort(field);
      }
    } else {
      setOrderSort('');
      setFieldSort('');
    }
    // setExtraSort(extra);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          {
            checkPermissionAction('P35PG1C1', 'P35PG1C1A1') ? (
              <Card
                title={intl.formatMessage({ id: 'menuDriverTaskSummary', defaultMessage: 'Driver Expenses Report' })}
                bodyStyle={{ height: 'calc(100vh - 170px)', padding: '0px 24px 24px 24px' }}
                extra={
                  <Button
                    className="monitor-button-link-export"
                    type="link"
                    onClick={exportData}
                  // disabled={_.size(tableData) !== 0 ? false : true}
                  >
                    <FormattedMessage id="btnExport" defaultMessage="Export" /> {" "}
                    <Icon component={iconExcel} />
                  </Button>}
              >
                {/* <HeaderContent
                  totalHeader={totalHeader}
                /> */}
                <ActionContent
                  teamData={teamData}
                  team={team}
                  columns={columns}
                  checkedValuecolumns={checkedValuecolumns}
                  defaultShowColumn={defaultShowColumn}
                  textErrorSelectColumn={textErrorSelectColumn}
                  handleCheckOk={handleCheckOk}
                  handleReset={handleReset}
                  handleVisibleChange={handleVisibleChange}
                  visible={visible}
                  handleOnVisiblecolumns={handleOnVisiblecolumns}
                  selectTreeNode={selectTreeNode}
                  memberData={memberData}
                  selectTeam={selectTeam}
                  mainDateStart={mainDateStart}
                  mainDateEnd={mainDateEnd}
                  onChangeDate={onChangeDate}
                  member={member}
                />
                <TableContent
                  columns={newDataColumns}
                  tableData={tableData}
                  total={total}
                  handlePagination={handlePagination}
                  handleSizeChange={handleSizeChange}
                  handleChange={handleChange}
                  totalHeader={totalHeader}
                  scrollTableX={scrollTableX}
                />
              </Card>
            ) : null
          }

        </div>
      )}
    </PageSettings.Consumer>
  )
}
