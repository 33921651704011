import React, { useState, useEffect } from 'react';
import './index.css';
import { Row, Col, Select, Icon, Avatar, Divider } from 'antd';
import AppAvatar from '../../../../components/avatar';
import _ from 'lodash';
import DefaultProfile from '../../../../components/image/NoImageR.png';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings';
import { ReactComponent as DestinationIcon } from '../../../../assets/svg/Destination.svg';

const { Option } = Select;

const ViewDetail = (props) => {
  const { viewData, setVisibleComment, setCommentData, setVisibleReasong, statusList, setStatusList } = props;
  const [imageFileDefault, setImageFileDefault] = useState('');
  const test = [{ test: 1 }, { test: 2 }];

  useEffect(() => {
    setImageFileDefault(DefaultProfile);
  }, [DefaultProfile]);

  // const filteredOptions = statusList.filter(o => !selectedItems.includes(o));

  const SelectStatus = styled(Select)`
    width: 'auto';
    .ant-select-selection--single {
      border-radius: 20px !important;
      height: 24px;
      color: #fff;
      width: fit-content;
      font-size: 13px;

      // background-color: #FEBB00;
      // border: 1px solid #FEBB00;
      background-color: ${_.get(viewData, 'statusColorCode')};
      border: 1px solid ${_.get(viewData, 'statusColorCode')};
    }
    .ant-select-selection-selected-value {
      margin-right: 5px;
      margin-top: 0px;
    }
    .ant-select-arrow .ant-select-arrow-icon svg {
      fill: #fff;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover,
    .ant-select-open .ant-select-selection {
      border-color: ${_.get(viewData, 'statusColor')} !important;
      box-shadow: none !important;
    }
  `;

  const onChangeStatus = (value, e) => {
    // statusList.filter((o) => !statusList.includes(o));
    setCommentData(e.props);
    setVisibleComment(true);

    //height: 'calc(100vh - 343px)'
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm" style={{ padding: '24px', height: '561px' }}>
      <Row gutter={[16]}>
        <Col span={4}>
          <AppAvatar size={100} src={`${_.get(viewData, 'customerImg') ? _.get(viewData, 'customerImg') : imageFileDefault}`} />
        </Col>
        <Col span={20}>
          <div>
            <div>
              {/* ------------------------------------------ Header ------------------------------------------------- */}
              <div className="viewTicketTextHeader">
                <Row gutter={[8, 8]}>
                  <Col span={18} style={{ fontWeight: 'bold' }}>
                    {_.get(viewData, 'orderRequestName')}
                  </Col>
                  <Col span={6}>
                    {/* <SelectStatus
                      className="scroll-sm"
                      dropdownStyle={{ minWidth: '200px' }}
                      size="small"
                      // value={_.get(viewData, 'statusName')}
                      value={_.get(viewData, 'statusCode')}
                      showArrow={true}
                      onChange={onChangeStatus}
                    >
                      {_.map(statusList, (item) => (
                        <Option value={item.statusCode}>{item.statusName}</Option>
                      ))}
                    </SelectStatus> */}
                    <PageSettings.Consumer>
                      {({ checkPermissionAction }) =>
                        checkPermissionAction(`P71PG1C1`, `P71PG1C1A3`) && _.size(statusList) > 0 ? (
                          <SelectStatus
                            className="scroll-sm"
                            dropdownStyle={{ minWidth: '200px' }}
                            size="small"
                            // value={_.get(viewData, 'statusName')}
                            value={_.get(viewData, 'statusTxt')}
                            showArrow={true}
                            onChange={onChangeStatus}
                          >
                            {_.map(statusList, (item) => (
                              <Option value={item.statusCode}>{_.get(item, 'statusName')}</Option>
                            ))}
                          </SelectStatus>
                        ) : (
                          <p
                            style={{
                              // backgroundColor: record['statusColor'],
                              backgroundColor: _.get(viewData, 'statusColorCode'),
                              fontSize: '13px',
                              color: 'white',
                              textAlign: 'center',
                              borderRadius: '10px',
                              height: '23px',
                              width: '100%',
                              border: `1px solid ${_.get(viewData, 'statusColorCode')}`,
                            }}
                          >
                            {_.get(viewData, 'statusTxt')}
                          </p>
                        )
                      }
                    </PageSettings.Consumer>
                  </Col>
                </Row>
              </div>
              <div style={{ marginTop: '8px', fontWeight: 'bold', fontSize: '14px' }} className="viewTicketTextHeader">
                {_.get(viewData, 'customerName')}
              </div>
              <div className="viewTicketTextDescriptionHeader">
                <FormattedMessage id="AllOrderSubmitted" defaultMessage="Submitted" /> {_.get(viewData, 'submittedDate')}
              </div>
            </div>
            {/* ------------------------------------------ Remark ------------------------------------------------- */}
            {_.get(viewData, 'adminCancelReject') === true ? (
              <div style={{ marginTop: '26px' }}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <div className="viewTicketTextTopic">
                      <FormattedMessage id="AllOrderRemark" defaultMessage="Remark" />
                    </div>
                    <div className="viewTicketTextValue">
                      <FormattedMessage id="AllOrderAdmin" defaultMessage="Admin" /> : {_.get(viewData, 'reasonCancel')} ・{' '}
                      {_.get(viewData, 'remarkCancel') ? _.get(viewData, 'remarkCancel') : '-'}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}

            {/* ----------------------------------------------------------------------------------------------- */}
            {/* ------------------------------------------ Detail ------------------------------------------------- */}
            <div style={{ marginTop: '26px' }}>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllOrderOrderType" defaultMessage="Order Type" />
                  </div>
                  <div className="viewTicketTextValue">
                    {_.get(viewData, 'ticketTypeDetail.name')} {_.get(viewData, 'orderTypeName')}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllOrderOrderDate" defaultMessage="Order Date" />
                  </div>
                  <div className="viewTicketTextValue">
                    {_.get(viewData, 'teamName')} {_.get(viewData, 'orderDate')} {_.get(viewData, 'orderTime')}{' '}
                  </div>
                </Col>
              </Row>
            </div>
            {/* ------------------------------------------ Loaction ------------------------------------------------- */}
            <div style={{ marginTop: '24px' }}>
              <div className="viewTicketTextTopic">
                <FormattedMessage id="AllOrderLocation" defaultMessage="Location" />
              </div>
              <div style={{ marginTop: '8px' }}>
                <Row gutter={[8, 8]}>
                  <Col span={1}>
                    <Icon component={DestinationIcon} style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
                  </Col>
                  <Col span={9}>
                    <span className="viewTicketTextHead" style={{ margin: 'unset' }}>
                      <FormattedMessage id="AllOrderShop" defaultMessage="Shop" />
                    </span>
                    <p className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      {_.get(viewData, 'shopName')}・ {_.get(viewData, 'location.deliveryAddress')}
                    </p>
                  </Col>
                  {_.get(viewData, 'orderTypeName') === 'Walk in' || _.get(viewData, 'customerLocation.customerAddress') === '-'  ? null : (
                    <>
                      <Col span={1}>
                        <Icon type="arrow-right" style={{ fontSize: '12px', color: '#E73845', marginRight: '5px' }} />
                      </Col>
                      <Col span={1}>
                        <Icon type="environment" style={{ fontSize: '12px', color: '#E73845', marginRight: '5px' }} />
                      </Col>
                      <Col span={9}>
                        <span className="viewTicketTextHead" style={{ margin: 'unset' }}>
                          <FormattedMessage id="AllOrderCustomer" defaultMessage="Customer" />
                        </span>
                        <p className="viewTicketTextValue" style={{ margin: 'unset' }}>
                          {_.get(viewData, 'location.customerAddressName')}・ {_.get(viewData, 'location.customerAddress')}
                        </p>
                        <p>
                          <Icon type="message" style={{ color: '#909AAF', marginRight: '3px' }} />
                          {/* <Avatar size={12} style={{ backgroundColor: '#FFF', border: '1px solid #909AAF', marginRight: '3px' }} /> */}
                          <span className="viewTicketTextDescriptionValue"> {_.get(viewData, 'customerLocation.moreAddressDetail')} </span>
                        </p>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </div>
            {/* ------------------------------------------ Order Summary ------------------------------------------------- */}
            <div style={{ marginTop: '24px' }}>
              <div className="viewTicketTextTopic">
                <FormattedMessage id="AllOrderOrderSummary" defaultMessage="Order Summary" />
              </div>
              {_.map(_.get(viewData, 'orderSummary'), (item) => {
                return (
                  <div style={{ marginTop: '8px' }}>
                    <Row gutter={[8, 8]}>
                      <Col span={2}>
                        <div className="boxOrderSummary">x{_.get(item, 'qty')}</div>
                      </Col>
                      <Col span={15}>
                        <Row>
                          <span className="viewTicketTextHead" style={{ margin: 'unset' }}>
                            {_.get(item, 'itemName')}
                          </span>
                        </Row>
                        <Row>
                          {_.map(_.get(item, 'topicOptions'), (role, index) => {
                            return _.chain(_.get(role, 'options'))
                              .filter(['selected', true])
                              .map((o, test) => {
                                return <span>{(index || test ? ', ' : '') + o.name}</span>;
                              })
                              .value();
                          })}
                        </Row>
                      </Col>
                      <Col span={5} style={{ textAlign: 'end' }}>
                        <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                          {_.get(item, 'priceTxt')}
                        </span>
                      </Col>
                      <Col span={2}></Col>
                    </Row>
                  </div>
                );
              })}
            </div>
            {/* ------------------------------------------ Remark ------------------------------------------------- */}

            <div style={{ marginTop: '26px' }}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllOrderRemark" defaultMessage="Remark" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'remark')}</div>
                </Col>
              </Row>
            </div>

            {/* ----------------------------------------------------------------------------------------------- */}
            {/* ------------------------------------------ Total ------------------------------------------------- */}
            <div style={{ marginTop: '24px' }}>
              <div>
                <Row gutter={[8, 8]}>
                  <Col span={17}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      <FormattedMessage id="AllOrderSubtotal" defaultMessage="Subtotal" />
                    </span>
                  </Col>
                  <Col span={5} style={{ textAlign: 'end' }}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      {_.get(viewData, 'subtotalTxt')}
                    </span>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              </div>
              {/* ----------------------------------------------------------------------------------------------- */}
              <div style={{ marginTop: '8px' }}>
                <Row gutter={[8, 8]}>
                  <Col span={17}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      <FormattedMessage id="AllOrderMerchantAdjusted" defaultMessage="Merchant Adjusted" />
                    </span>
                  </Col>
                  <Col span={5} style={{ textAlign: 'end' }}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      {_.get(viewData, 'merchantAdjustedTxt')}
                    </span>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              </div>
              <div style={{ marginTop: '8px' }}>
                <Row>
                  <p className="viewTicketTextDescriptionValue" style={{ margin: 'unset' }}>
                    {_.get(viewData, 'merchantAdjustRemark')}
                  </p>
                </Row>
              </div>
              {/* ----------------------------------------------------------------------------------------------- */}
              <div style={{ marginTop: '8px' }}>
                <Row gutter={[8, 8]}>
                  <Col span={17}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      <FormattedMessage id="AllOrderDeliveryFee" defaultMessage="Delivery Fee" />
                    </span>
                  </Col>
                  <Col span={5} style={{ textAlign: 'end' }}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      {_.get(viewData, 'deliveryFeeTxt')}
                    </span>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              </div>
              {/* ----------------------------------------------------------------------------------------------- */}
              <div style={{ marginTop: '8px' }}>
                <Row gutter={[8, 8]}>
                  <Col span={17}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      <FormattedMessage id="AllOrderRiderAdjusted" defaultMessage="Rider Adjusted" />
                    </span>
                  </Col>
                  <Col span={5} style={{ textAlign: 'end' }}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      {_.get(viewData, 'riderAdjustedTxt')}
                    </span>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              </div>
              <div style={{ marginTop: '8px' }}>
                <Row>
                  <p className="viewTicketTextDescriptionValue" style={{ margin: 'unset' }}>
                    {_.get(viewData, 'riderAdjustRemark')}
                  </p>
                </Row>
              </div>
              {/* ----------------------------------------------------------------------------------------------- */}
              <div style={{ marginTop: '8px' }}>
                <Row gutter={[8, 8]}>
                  <Col span={17}>
                    <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                      <FormattedMessage id="AllOrderTotal" defaultMessage="Total" />
                    </span>
                  </Col>
                  <Col span={5} style={{ textAlign: 'end' }}>
                    <span className="viewTicketTextHead" style={{ margin: 'unset' }}>
                      {_.get(viewData, 'totalTxt')}
                    </span>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              </div>
              {/* ----------------------------------------------------------------------------------------------- */}
              {_.get(viewData, 'latestRiderDetail.riderName') ? (
                <>
                  <Divider type="horizontal" />
                  <div style={{ marginTop: '8px' }}>
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <div className="viewTicketTextTopic">
                          <FormattedMessage id="AllOrderAssignee" defaultMessage="Assignee" />
                        </div>
                        <div>
                          <Row gutter={[8, 8]} style={{ marginTop: '8px' }}>
                            <Col span={2}>
                              <Avatar size="large" style={{ width: '50px', height: '50px' }} src={_.get(viewData, 'latestRiderDetail.riderImg')} />
                            </Col>
                            <Col span={21} style={{ marginLeft: '5px' }}>
                              <div className="viewTicketTextHead">{_.get(viewData, 'latestRiderDetail.riderName')}</div>
                              <div className="viewTicketTextDescriptionHeader">{_.get(viewData, 'latestRiderDetail.riderPhone')}</div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : null}
              {/* ----------------------------------------------------------------------------------------------- */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ViewDetail;
