import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { SaleOrderContext } from './sale-order-context';
import SaleOrderAddress from './sale-order-address';
import SaleOrderReport from './sale-order-report';
import SaleOrderCalculate from './sale-order-calculate';
import ModalAddItem from './modal-add-item';
import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon, Tag, Modal } from 'antd';
import _, { debounce } from 'lodash';
import { getItemValue, getItemName, getDefaultSaleOreder } from '../../controllers/sale-order/get-item';
import moment from 'moment';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import styles from './css/index.css';
import httpClient from '../axiosClient';
import { successNotification, errorNotification, warningNotification } from '../v2/notification';
import NumberFormat from 'react-number-format';
import { PageSettings } from '../../config/page-settings';

const today = new Date();
const dateFormat = "ddd, MMM DD YYYY"

const CreateSaleOrder = (props) => {
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      width: 150,

    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsItemName', defaultMessage: 'Item Name' }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsUnitPrice', defaultMessage: 'Unit Price (Baht)' }),
      dataIndex: 'UnitPrice',
      key: 'UnitPrice',
      align: 'right',
      width: 160,
      render: (text, record, index) =>
        <NumberFormat value={(parseFloat(record.UnitPrice)).toFixed(2)} displayType={'text'} thousandSeparator={true} />
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',
      width: 100,
      render: (text, record, index) =>
        <NumberFormat value={(parseFloat(record.qty))} displayType={'text'} thousandSeparator={true} />
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsUnit', defaultMessage: 'Unit' }),
      dataIndex: 'itemsUnitName',
      key: 'itemsUnitName',
      align: 'right',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'modalSaleOrderColumnsTotal', defaultMessage: 'Total (Baht)' }),
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 130,
      render: (text, record, index) =>
        <NumberFormat value={(parseFloat(record.total)).toFixed(2)} displayType={'text'} thousandSeparator={true} />
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      render: (text, record, index) => (
        <div className="table-column-delete-dot" onClick={() => handleDeleteItem(record, index)}>
          <div className='table-column-delete-line-style' />
        </div>
        // <Button className="table-column-delete" shape="circle" icon="minus" size="small" />
      )
    },
  ];

  const today = new Date();
  const { visibleModal, setVisibleModal } = props;
  const [refOrderAddress, setRefOrderAddress] = useState(null);
  const [formRefCalculate, setFormRefCalculate] = useState(null);
  const [sumCalculate, setSumCalculate] = useState({});
  const [remark, setRemark] = useState();
  const [datailItem, setDatailItem] = useState({})

  // --------------------- State Modal Add Item -----------------------------------
  const [formRefAddItem, setFormRefAddItem] = useState(null);
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [subtotal, setSubtotal] = useState(50000);
  const [sumSubtotal, setSumSubtotal] = useState("");
  const [discountSelete, setDiscountSelete] = useState({});
  const [taxSelete, setTaxSelete] = useState({});
  const [discountData, setDiscountData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [valueInputDiscount, setValueInputDiscount] = useState(0.00);
  const [warehouseData, setWarehouseData] = useState([]);
  const [itemUnitData, setItemUnitData] = useState([]);
  const [focusInput, setFocusInput] = useState(true);
  const [triggerAddItem, setTriggerAddItem] = useState(false);
  const [searchItemCodeResultItem, setSearchItemCodeResultItem] = useState([]);
  const [searchItemNameResultItem, setSearchItemNameResultItem] = useState([])
  const [calTotalShow, setCalTotalShow] = useState(null);
  // --------------------State sale order Address ---------------
  const [customerData, setCustomerData] = useState();
  const [addressBook, setAddressBook] = useState();
  const [addressData, setAddressData] = useState();
  const [warehouseDetailfilter, setWarehouseDetailfilter] = useState({});
  const [locationData, setLocationData] = useState()
  const [customerDataCreate, setCustomerDataCreate] = useState()


  useEffect(() => {
    async function getAPI() {
      axios.all([await getDefaultSaleOreder()]).then(axios.spread((defSaleOrederData) => {

        const resultDiscount = defSaleOrederData && defSaleOrederData.discount.find((discountData) => {
          return discountData.isdefault === true;
        });

        const resultTax = defSaleOrederData && defSaleOrederData.tax.find((taxData) => {
          return taxData.isdefault === true;
        });

        setWarehouseData(defSaleOrederData.Warehouse);
        setItemUnitData(defSaleOrederData.itemUnit);
        setDiscountData(defSaleOrederData.discount);
        setTaxData(defSaleOrederData.tax);
        setDiscountSelete(resultDiscount);
        setTaxSelete(resultTax);
      }));
    }
    getAPI();
  }, [visibleModal]);

  // ---------------------- State Sales Order Address -----------------------------
  const addressRef = useRef();
  const phoneRef = useRef();
  const itemCodeRef = useRef();


  // ------------------------Value Add Item------------------------------=
  const [itemSaleOrder, setItemSaleOrder] = useState([]);
  // const [itemUnit, setItemUnit] = useState([]);
  const [searchItemCode, setSearchItemCode] = useState('');
  const [searchItemCodeResult, setSearchItemCodeResult] = useState([]);

  // useEffect(() => {
  //   const getValueItem = async () => {
  //     const data = await getItemValue(comCode, searchItemCode);
  //     setSearchItemCodeResult(data);
  //   }
  //   getValueItem();
  // }, [searchItemCode]);



  const handleSave = async (status) => {
    let addressValue = null;

    refOrderAddress.validateFields((err, values) => {
      if (err) {
        return
      }
      addressValue = values;
    });

    if (customerData && addressValue !== null) {
      const filterAddress = addressBook && addressBook.filter((col) => addressValue.address.includes(col.customerAddressBookId));
      const filterCustomer = customerData && customerData.filter((col) => addressValue.customer.includes(col.customerId));

      const body = {
        billDate: moment(today).format('YYYY-MM-DD'),
        dueDate: moment(addressValue.shippingDate).format('YYYY-MM-DD'),
        orderStatus: status,
        orderRemark: remark,
        taxId: taxSelete.taxId,
        discountId: discountSelete.discountId,
        customerId: addressValue.customer,
        customerName: filterAddress && filterAddress !== undefined ? filterAddress[0].customerName : undefined,
        customerAddress: filterAddress && filterAddress !== undefined ? filterAddress[0].address : undefined,
        customerAddressBookName: filterAddress && filterAddress !== undefined ? filterAddress[0].addressName : undefined,
        customerAddressBookId: filterAddress && filterAddress !== undefined ? filterAddress[0].customerAddressBookId : undefined,
        taxNo: '',
        customerPhone: filterCustomer && filterCustomer !== undefined ? filterCustomer[0].phone : undefined,
        subTotal: parseFloat(sumSubtotal).toFixed(2),
        discount: parseFloat(sumCalculate.discount).toFixed(2),
        tax: parseFloat(sumCalculate.tax).toFixed(2),
        total: parseFloat(sumCalculate.total).toFixed(2),
        wareHouseId: warehouseDetailfilter.wareHouseId,
        wareHousePhone: '',
        wareHouseName: warehouseDetailfilter.wareHouseName,
        wareHouseAddress: warehouseDetailfilter.wareHouseAddress,
        detail: itemSaleOrder
      }



      if (parseFloat(sumCalculate.total).toFixed(2) <= 0) {
        errorNotification(<FormattedMessage id="modalSaleOrderSaveLessThan" defaultMessage="The amount cannot be negative." />);
      } else {
        try {
          const response = await httpClient.post(`/v3/order/manager/company/${comCode}/order/createdata`, body);
          if (response.status == 200) {
            successNotification(response.data.message);
            setItemSaleOrder([]);
            setVisibleModal(false);
            setValueInputDiscount(0.00);
            addressRef.current.innerHTML = 'Address';
            phoneRef.current.innerHTML = '-';
            refOrderAddress.resetFields();
            setRemark();
            props.setTriggerCreate(!props.triggerCreate);
            setDiscountSelete({});
            setTaxSelete({});
            setLocationData();
          }
        } catch (error) {
          setVisibleModal(false);
        }
      }
    }
  }

  const handleCancel = e => {
    setItemSaleOrder([]);
    setVisibleModal(false);
    setValueInputDiscount(0.00);
    addressRef.current.innerHTML = 'Address';
    phoneRef.current.innerHTML = '-';
    refOrderAddress.resetFields();
    setRemark('');
    setDiscountSelete({});
    setTaxSelete({});
    setLocationData();
  };

  // --------------------- Fnc Modal Add Item -----------------------------------

  const subTotalResult = useMemo(() => {
    const totalItemSO = itemSaleOrder && itemSaleOrder.map(item => { return item.total });
    const numbers = totalItemSO.map((n) => { return parseFloat(n, 10); });
    const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
    setSumSubtotal(_.floor(sumTotal, 2).toFixed(2));
    return _.floor(sumTotal, 2).toFixed(2)
  }, [itemSaleOrder.length]);


  const searchChangeItemCode = _.debounce((fieldChange) => {
    setSearchItemCode(fieldChange ? fieldChange : '');
  }, 1000);

  const handleDeleteItem = (record, index) => {
    const columnNew = itemSaleOrder && itemSaleOrder.filter((col, i) => i !== index);
    setItemSaleOrder(columnNew)
  }

  const handleAddItem = () => {
    const newItemOrder = [...itemSaleOrder]

    formRefAddItem.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const unitName = _.filter(itemUnitData, (e) => { return e.itemsUnitId === values.unit });
      const unitNameString = _.map(unitName, 'itemsUnitName');


      newItemOrder.push({
        itemCode: values.itemCode,
        itemName: values.itemName,
        UnitPrice: _.floor(values.unitPrice, 2).toFixed(2),
        // qty: _.floor(values.qty, 2).toFixed(2),
        qty: parseInt(values.qty),
        itemsUnitName: unitNameString.toString(),
        itemsUnitId: values.unit,
        total: parseFloat(_.floor(values.total, 2)).toFixed(2)
      })
      setSearchItemCode('');
      setItemSaleOrder(newItemOrder)
      formRefAddItem.resetFields();
      setDatailItem({})
      setFocusInput(true);
      setTriggerAddItem(!triggerAddItem);
      const dataCode = await getItemValue(comCode, undefined);
      const dataName = await getItemName(comCode, undefined);
      setSearchItemCodeResultItem(dataCode);
      setSearchItemNameResultItem(dataName);
      formRefAddItem.setFieldsValue({ ['unitPrice']: 0 });
    });
  }

  const handleCancelAddItem = async () => {
    setSearchItemCode('');
    formRefAddItem.resetFields();
    setVisibleModalAdd(false);
    setDatailItem({})
    const dataCode = await getItemValue(comCode, undefined);
    const dataName = await getItemName(comCode, undefined);
    setSearchItemCodeResultItem(dataCode);
    setSearchItemNameResultItem(dataName);
  }

  const saveFormRefAddItem = useCallback(node => {
    if (node !== null) {
      setFormRefAddItem(node);
    }
  }, []);

  const handleKeyUp = (event) => {
    const newItemOrder = [...itemSaleOrder]
    if (event.keyCode === 13) {
      formRefAddItem.validateFields(async (err, values) => {
        if (err) {
          return;
        }
        const unitName = _.filter(itemUnitData, (e) => { return e.itemsUnitId === values.unit });
        const unitNameString = _.map(unitName, 'itemsUnitName');

        newItemOrder.push({
          itemCode: values.itemCode,
          itemName: values.itemName,
          UnitPrice: _.floor(values.unitPrice, 2).toFixed(2),
          qty: parseInt(values.qty),
          itemsUnitName: unitNameString.toString(),
          itemsUnitId: values.unit,
          total: _.floor(values.total, 2).toFixed(2)
        })

        setSearchItemCode('');
        setItemSaleOrder(newItemOrder)
        formRefAddItem.resetFields();
        setDatailItem({})
        setFocusInput(true);
        setTriggerAddItem(!triggerAddItem);
        const dataCode = await getItemValue(comCode, undefined);
        const dataName = await getItemName(comCode, undefined);
        setSearchItemCodeResultItem(dataCode);
        setSearchItemNameResultItem(dataName);
        formRefAddItem.setFieldsValue({ ['unitPrice']: 0 });

      });
    }
  }
  const saveFormRefCalculate = useCallback(node => {
    if (node !== null) {
      setFormRefCalculate(node);
    }
  }, []);

  const saveFormRefDiscount = useCallback(node => {
    if (node !== null) {
      setRefOrderAddress(node);
    }
  }, []);

  return (
    <div className="sale-oreder">
      <SaleOrderContext.Provider
        value={{
          mainState: {
            addressRef,
            phoneRef,
            discountData,
            taxData,
            valueInputDiscount,
            warehouseData,
            customerData,
            addressBook,
            addressData,
            itemCodeRef,
            remark,
            locationData,
          },
          setMainState: {
            setVisibleModalAdd,
            setDiscountSelete,
            setTaxSelete,
            setValueInputDiscount,
            setCustomerData,
            setAddressData,
            setSumCalculate,
            setAddressBook,
            setRemark,
            setLocationData,
          },
          fncMain: {

          }
        }}
      >
        <Modal
          className="sale-oreder-modal"
          title={intl.formatMessage({ id: 'modalSaleOrderTitleCreate', defaultMessage: 'Create Sale Oreder' })}
          centered="true"
          width={1100}
          visible={visibleModal}
          onCancel={handleCancel}
          footer={[
            <PageSettings.Consumer>
              {({ checkPermissionAction }) =>
                <>
                  <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleCancel}>
                    <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                  </Button02>
                  {checkPermissionAction('P11PG1C1', 'P11PG1C1A6') ?
                    (
                      <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={() => handleSave('draft')} disabled={itemSaleOrder && itemSaleOrder.length < 1 ? true : false}>
                        <FormattedMessage id="btnDraft" defaultMessage="Draft" />
                      </Button02>
                    ) : null
                  }
                  {checkPermissionAction('P11PG1C1', 'P11PG1C1A7') ?
                    (<Button01 key="submit" type="primary" btnsize='wd_df' onClick={() => handleSave('completed')} disabled={itemSaleOrder && itemSaleOrder.length < 1 ? true : false}>
                      <FormattedMessage id="btnSave" defaultMessage="Save" />
                    </Button01>) : null
                  }
                </>
              }
            </PageSettings.Consumer>
          ]}
        >
          <div className="sale-oreder-modal-body">
            <div className="modal-container">
              <SaleOrderAddress
                ref={saveFormRefDiscount}
                warehouseDetailfilter={warehouseDetailfilter}
                setWarehouseDetailfilter={setWarehouseDetailfilter}
                customerDataCreate={customerDataCreate}
                setCustomerDataCreate={setCustomerDataCreate}
              />
            </div>
            <div className="modal-container">
              <SaleOrderReport itemSaleOrder={itemSaleOrder} columns={columns} />
            </div>
            <div className="modal-container">
              <SaleOrderCalculate
                ref={saveFormRefCalculate}
                subtotal={subtotal}
                discountSelete={discountSelete}
                taxSelete={taxSelete}
                subTotalResult={subTotalResult}
              />
            </div>
          </div>
        </Modal>
      </SaleOrderContext.Provider>

      <ModalAddItem
        ref={saveFormRefAddItem}
        visibleModalAdd={visibleModalAdd}
        handleAddItem={handleAddItem}
        handleCancelAddItem={handleCancelAddItem}
        onChange={searchChangeItemCode}
        // searchItemCodeResult={searchItemCodeResult}
        itemUnitData={itemUnitData}
        handleKeyUp={handleKeyUp}
        setDatailItem={setDatailItem}
        datailItem={datailItem}
        focusInput={focusInput}
        setFocusInput={setFocusInput}
        triggerAddItem={triggerAddItem}
        searchItemCodeResult={searchItemCodeResultItem}
        setSearchItemCodeResult={setSearchItemCodeResultItem}
        searchItemNameResult={searchItemNameResultItem}
        setSearchItemNameResult={setSearchItemNameResultItem}
        calTotalShow={calTotalShow}
        setCalTotalShow={setCalTotalShow}
      />

    </div>
  )
}

export default CreateSaleOrder;
