import React, { useState } from 'react';
import { Avatar, Icon, Popover, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import stylePost from './css/post.css';
import _ from 'lodash';

export default (props) => {
  const intl = useIntl();
  const { postData, handleEditPost, handleDeletePost, postAction } = props;
  const [visiblePopover, setVisiblePopover] = useState(false);

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  }

  return (
    <div className='post-header'>
      <div className='post-card-head'>
        <div className='post-card-head-left'>
          <div className='post-card-head-left-avatar'>
            <Avatar size={52} src={`${_.get(postData, 'member.profileImg')}`} />
          </div>
          <div className='post-card-left-text'>
            <p className='post-card-left-text-head'>{`${_.get(postData, 'member.fullname')}`}</p>
            <div className='post-card-head-left-text'>
              <p className='post-card-text-12'>
                {intl.formatMessage({ id: 'engagementPostTxtNotedon', defaultMessage: 'Noted on' })} {`${_.get(postData, 'createdAt')}`}
              </p>
            </div>
          </div>
        </div>
        <div className='post-card-head-right'>
          {_.get(postData, 'action.edit') && _.get(postData, 'action.delete') ? (
            <Popover
              key={1}
              placement='leftTop'
              trigger='hover'
              visiblePopover={visiblePopover}
              onVisibleChange={handleOnVisiblePopover}
              // zIndex={999}
              content={
                <div style={{ display: 'grid' }}>
                  {_.get(postData, 'action.edit') ? (
                    <Button
                      style={{ width: 100 }}
                      type='link'
                      ghost
                      onClick={() => handleEditPost(postData)}
                    >
                      <FormattedMessage id='btnEdit' defaultMessage='Edit' />
                    </Button>
                  ) : null}

                  {_.get(postData, 'action.delete') ? (
                    <Button
                      style={{ width: 100 }}
                      type='link'
                      ghost
                      onClick={() => handleDeletePost(postData)}
                    >
                      <FormattedMessage id='btnDelete' defaultMessage='Delete' />
                    </Button>
                  ) : null}
                </div>
              }
            >
              <Icon type='more' />
            </Popover>
          ) : null}
        </div>
      </div>
    </div>
  );
};
