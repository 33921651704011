import React, { useState, useEffect } from 'react';
import ModalCustomerPost from './modal-post';
import _ from 'lodash';
import { saveImageTicketNote, createTicketNote } from '../../../controllers/ticket/ticket';
import { successNotification, errorNotification } from '../../v2/notification';

export default React.memo((props) => {
  const { visible, setVisible, setTrigger, ticketId, ticketNo } = props
  const [triggerCustomer, setTriggerCustomer] = useState(false);
  const [visibleModalCustomer, setVisibleModalCustomer] = useState(false);
  const [visibleModalLocation, setVisibleModalLocation] = useState(false)
  const [customerId, setCustomerId] = useState();
  const [message, setMessage] = useState('');
  const [selectedImageBlob, setSelectedImageBlob] = useState([]);
  const [selectedFilesBlob, setSelectedFilesBlob] = useState([]);
  const [addressData, setAddressData] = useState()
  //---------------------- Address --------------------------------
  const [locationData, setLocationData] = useState([])
  const [center, setCenter] = useState({ lat: 13.90607, lng: 100.51913 });
  const [position, setPosition] = useState({ lat: 13.90607, lng: 100.51913 })
  const [visibleInfo, setVisibleInfo] = useState(false)
  const [selectAddress, setSelectAddress] = useState([])
  const [dragMarker, setDragMarker] = useState()
  const [latLng, setLatLng] = useState({})
  const [visibleCreateAddress, setVisibleCreateAddress] = useState(false)
  const [toLocation, setToLocation] = useState()
  const [fieldChange, setFieldChange] = useState('')
  const [valueInput, setValueInput] = useState()
  const [triggerApi, setTriggerApi] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState('default');
  const [selectAddressData, setSelectAddressData] = useState()


  const handleCreatePost = async () => {
    setLoading(true)
    const lat = _.get(addressData, 'lat') ? _.get(addressData, 'lat') : undefined;
    const lng = _.get(addressData, 'lng') ? _.get(addressData, 'lng') : undefined;
    const address = _.get(addressData, 'address') ? _.get(addressData, 'address') : undefined;
    const memComId = localStorage.getItem('memComId');

    let mapAll = [];
    for (let index = 0; index < _.size(selectedImageBlob); index++) {
      let formData = new FormData();
      formData.append('file', selectedImageBlob[index].file);
      formData.append('fileType', 'photo');
      const fileResponse = await saveImageTicketNote(formData)
      if (fileResponse.status === 200) {
        mapAll.push({
          fileHash: _.get(fileResponse, 'data.data.fileHash'),
          descriptionPhoto: selectedImageBlob[index].description,
          type: "photo",
        })
      }
    }
    for (let index = 0; index < _.size(selectedFilesBlob); index++) {
      let formData = new FormData();
      formData.append('file', selectedFilesBlob[index].file);
      formData.append('fileType', 'file');
      const fileResponse = await saveImageTicketNote(formData)
      mapAll.push({
        fileHash: _.get(fileResponse, 'data.data.fileHash'),
        descriptionPhoto: selectedFilesBlob[index].name,
        type: "file",
      })
    }
    console.log("mapImg", mapAll)
    const body = {
      "ticketId": ticketId,
      "address": address,
      "memComId": memComId,
      "description": message,
      "lat": lat,
      "lng": lng,
      "filesUpload": mapAll
    }
    try {
      const response = await createTicketNote(body);
      if (response.status === 200) {
        setTrigger(current => !current)
        setVisible(false)
        setCustomerId(undefined);
        setSelectedImageBlob([]);
        setSelectedFilesBlob([]);
        setMessage('');
        setSelectAddress([]);
        setDragMarker();
        setVisibleInfo(false);
        setLatLng({ lat: 13.90607, lng: 100.51913 });
        setToLocation();
        setFieldChange();
        setValueInput();
        setAddressData();
        setChecked('default');
        setSelectAddressData();
        successNotification(response.data.status.message);
        setLoading(false);
      } else {
        errorNotification(response.data.status.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  // }

  const handleCancelPost = () => {
    setVisible(false)
    setCustomerId(undefined);
    setSelectedImageBlob([]);
    setSelectedFilesBlob([]);
    setMessage('');
    setSelectAddress([]);
    setDragMarker();
    setVisibleInfo(false);
    setLatLng({ lat: 13.90607, lng: 100.51913 });
    setToLocation();
    setFieldChange();
    setValueInput();
    setAddressData();
    setChecked('default');
    setMessage(undefined);
    setSelectAddressData();
  }

  return (
    <div>
      <ModalCustomerPost
        value={{
          visible,
          visibleModalCustomer,
          visibleModalLocation,
          customerId,
          selectedFilesBlob,
          selectedImageBlob,
          addressData,
          message,
          loading,
          checked,
          locationData,
          center,
          position,
          visibleInfo,
          selectAddress,
          dragMarker,
          latLng,
          visibleCreateAddress,
          toLocation,
          fieldChange,
          valueInput,
          triggerApi,
          selectAddressData,
          ticketNo,
        }}
        fnc={{
          setTriggerCustomer,
          handleCancelPost,
          handleCreatePost,
          setVisibleModalCustomer,
          setVisibleModalLocation,
          setCustomerId,
          setSelectedFilesBlob,
          setSelectedImageBlob,
          setAddressData,
          setMessage,
          setLocationData,
          setCenter,
          setPosition,
          setVisibleInfo,
          setSelectAddress,
          setDragMarker,
          setLatLng,
          setVisibleCreateAddress,
          setToLocation,
          setFieldChange,
          setValueInput,
          setTriggerApi,
          setChecked,
          setSelectAddressData,
        }}
      />
    </div>
  )
})
