import React, { useContext, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Input, DatePicker, Select } from 'antd';
import CollapseCustom from '../../../components/collapse-custom';
import ActionColumnCustomer from './action-columns';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
// import './index.css'
import { CustomerContext } from './customer';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';

const { Option } = Select;
const { RangePicker } = DatePicker;

const CustomerAction = () => {
  const props = useContext(CustomerContext);
  const {
    setSearchGroup,
    setPaginationPage,
    searchGroup,
    setShowColumn,
    newDataColumns,
    listArrayColumns,
    defaultShowColumn,
    newColumns,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    textErrorSelectColumn,
    setTextErrorSelectColumn,
    keyTabMonitor,
    visible,
    setVisible,
    setTrigger,
    actionColumns,
    listBusinessTag,
    customerType,
    businessType,
  } = props;
  const intl = useIntl();
  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    // setPaginationPage(1);
  };

  const onApply = () => {
    setPaginationPage(1);
    setTrigger((cur) => !cur);
  };

  const onReset = () => {
    setSearchGroup({});
    setPaginationPage(1);
    setTrigger((cur) => !cur);
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="po-form-filter">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'customerFilterCustomerCode', defaultMessage: 'Customer Code' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'customerCode')}
                onChange={(e) => onChange(e.target.value, 'customerCode')}
                placeholder={intl.formatMessage({ id: 'customerFilterCustomerCodePH', defaultMessage: 'Enter Customer Code' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'customerFilterCustomerName', defaultMessage: 'Customer Name' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'customerName')}
                onChange={(e) => onChange(e.target.value, 'customerName')}
                placeholder={intl.formatMessage({ id: 'customerFilterCustomerNamePH', defaultMessage: 'Enter Customer Name' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'customerFilterBusinessType', defaultMessage: 'Business Type' })}
                  req={false}
                />
              }
            >
              <Select
                mode="multiple"
                allowClear
                value={_.get(searchGroup, 'businessType')}
                onChange={(e) => onChange(e, 'businessType')}
                maxTagCount={2}
                placeholder={intl.formatMessage({ id: 'customerFilterBusinessTypePH', defaultMessage: 'Select Business Type' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {businessType.map((item, index) => (
                  <Option value={item.businessTypeId} key={item.businessTypeId}>
                    {item.businessTypeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'customerFilterCustomerType', defaultMessage: 'Customer Type' })}
                  req={false}
                />
              }
            >
              <Select
                mode="multiple"
                allowClear
                value={_.get(searchGroup, 'customerType')}
                onChange={(e) => onChange(e, 'customerType')}
                maxTagCount={2}
                placeholder={intl.formatMessage({ id: 'customerFilterCustomerTypePH', defaultMessage: 'Select Customer Type' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {customerType.map((item, index) => (
                  <Option value={item.customerTypeId} key={item.customerTypeId}>
                    {item.customerTypeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'customerFilterBranch', defaultMessage: 'Branch' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'branch')}
                onChange={(e) => onChange(e.target.value, 'branch')}
                placeholder={intl.formatMessage({ id: 'customerFilterBranchPH', defaultMessage: 'Enter Branch' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'customerFilterSource', defaultMessage: 'Source' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'source')}
                onChange={(e) => onChange(e.target.value, 'source')}
                placeholder={intl.formatMessage({ id: 'customerFilterSourcePH', defaultMessage: 'Enter Source' })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'customerFilterTag', defaultMessage: 'Tag' })} req={false} />}
            >
              <Select
                mode="multiple"
                allowClear
                value={_.get(searchGroup, 'tag')}
                onChange={(e) => onChange(e, 'tag')}
                maxTagCount={2}
                placeholder={intl.formatMessage({ id: 'customerFilterTagPH', defaultMessage: 'Select Tag' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {listBusinessTag &&
                  _.map(listBusinessTag, (list) => (
                    <Option key={_.get(list, 'tagId')} value={_.get(list, 'tagCode')}>
                      {_.get(list, 'tagName')}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} />
          <Col span={8}>
            <div style={{ textAlign: 'center', padding: '45px 0px 0px 0px', display: 'flex', justifyContent: 'end' }}>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_02>

              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_01>
            </div>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ padding: '12px 0px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              placeholder={[
                intl.formatMessage({ id: `customerFilterDateStartPH`, defaultMessage: 'Select Date' }),
                intl.formatMessage({ id: `customerFilterDateEndPH`, defaultMessage: 'Select Date' }),
              ]}
              onChange={(e) => onChange(e, 'searchDate')}
              value={_.get(searchGroup, 'searchDate')}
              format={'DD/MM/YYYY'}
            />
            <ActionColumnCustomer
              columns={actionColumns}
              setShowColumn={setShowColumn}
              newDataColumns={newDataColumns}
              listArrayColumns={listArrayColumns}
              defaultShowColumn={defaultShowColumn}
              newColumns={newColumns}
              setNewDataColumns={setNewDataColumns}
              setListArrayColumns={setListArrayColumns}
              setDefaultShowColumn={setDefaultShowColumn}
              textErrorSelectColumn={textErrorSelectColumn}
              setTextErrorSelectColumn={setTextErrorSelectColumn}
              visible={visible}
              setVisible={setVisible}
              columnMinimum={1}
              tabValue={keyTabMonitor}
            />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default CustomerAction;
