import React, {useContext } from 'react';
import httpClient from '../../components/axiosClient';

const getAllTeam = async (app) => {

  const memComId = localStorage.getItem('memComId');
  // const app = useContext(PageSettings);

  try {
    const response = await httpClient.get(`/v2/organizations/${memComId}`);


    if (response.status == 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }

}

export default getAllTeam;