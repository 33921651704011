import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { GoogleMap, useJsApiLoader, useLoadScript, Polyline, Marker, OverlayView } from '@react-google-maps/api';
import MapControl from './map-control';
import { Icon, Card, Avatar } from 'antd';
import TruckIcon from '../../../../../components/image/truck_map.svg'

import _ from 'lodash';

const containerStyle = {
  width: '100%',
  height: 'calc(100vh - 465px)',
};

const center = {
  lat: 13.856785,
  lng: 100.520326
};

const defaultMapOptions = {
  fullscreenControl: false,
  fullscreenControlOptions: false,
  streetViewControl: false,
  clickableIcons: false,
  zoomControl: true,
  gestureHandling: "cooperative",
  // mapTypeControl: true,
  disableDefaultUI: true,
  mapTypeControlOptions: {
    style: 1,
    position: 3,
  },
};
export default (props) => {
  const { trackingResource, setTrackingResource, setlatLngResource, latLngResource } = props;
  const libraries = ["places"];
  const mapRef = useRef();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });
  let statusColor = _.get(trackingResource, 'statusColor') || '#ffffff';

  const [resourceData, setResourceData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [polylineData, setPolylineData] = useState([]);
  const [polylineGPSData, setPolylineGPSData] = useState([]);
  const [showResource, setShowResource] = useState(true);
  const [showTask, setShowTask] = useState(true);
  const [showCar, setShowCar] = useState(true)
  const lastGPS = _.get(polylineGPSData, `[${_.size(polylineGPSData) - 1}]`, center)

  console.log("lastGPS", lastGPS)

  useEffect(() => {
    if (trackingResource && trackingResource.openSpeedTrack === true) {
      let resData = _.get(trackingResource, 'dataSpeedTrack.memberProfile') ? [_.get(trackingResource, 'dataSpeedTrack.memberProfile')] : [];
      setTimeout(() => {
        setTaskData(_.get(trackingResource, 'dataTask'));
        setResourceData(resData);
        setPolylineData(_.get(trackingResource, 'dataSpeedTrack.directions') ? _.get(trackingResource, 'dataSpeedTrack.directions') : []);
        setPolylineGPSData(_.get(trackingResource, 'dataSpeedTrack.directionsGPS') ? _.get(trackingResource, 'dataSpeedTrack.directionsGPS') : []);
        setlatLngResource({
          lat: parseFloat(_.get(trackingResource, 'dataSpeedTrack.memberProfile.location_lat')),
          lng: parseFloat(_.get(trackingResource, 'dataSpeedTrack.memberProfile.location_lng'))
        });
      }, 500);
    }
  }, [trackingResource]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const hideResource = () => {
    setShowResource(current => !current);
  }

  const hideTask = () => {
    setShowTask(current => !current);
  }

  const hideCar = () => {
    setShowCar(current => !current);
  }

  const renderTask = () => taskData && taskData.map((task, index) => {
    return (
      <Marker
        className="marker-task"
        outline={'none'}
        style={{ outline: "none" }}
        zIndex={-1}
        key={task.taskId}
        id={task.taskId}
        position={new window.google.maps.LatLng(parseFloat(task.lat), parseFloat(task.lng))}
        icon={{
          url: task.pathTaskStatus,
          scaledSize: new window.google.maps.Size(40, 40),
          origin: new window.google.maps.Point(0, 0),
        }}
      />
    );
  });

  const renderResource = () => resourceData && resourceData.map((item, index) => {
    return (
      <OverlayView
        id={item.mem_id}
        position={new window.google.maps.LatLng(parseFloat(item.location_lat || 0), parseFloat(item.location_lng || 0))}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        <Avatar key={item.mem_id} src={item.default_profile_img} size='large' style={{ border: `3px ${statusColor} solid`, zIndex: 1 }} />
      </OverlayView>
    );
  });
  const renderPolyline = () => polylineData && polylineData.map((item, index) => {
    return (
      <Polyline
        key={`polyline-${index}`}
        path={[{ ...polylineData[index] }, { ...polylineData[index - 1] }]}
        options={{
          strokeColor: item.speedColor,
          strokeWeight: 3
        }}
      />
    );
  });

  const renderGPSPolyline = () => polylineGPSData && polylineGPSData.map((item, index) => {
    return (
      <Polyline
        key={`polyline-GPS-${index}`}
        path={[{ ...polylineGPSData[index] }, { ...polylineGPSData[index - 1] }]}
        options={{
          strokeColor: item.speedColor,
          strokeWeight: 3
        }}
      >

      </Polyline>
    );
  });


  return (
    <div className="map-tracking">
      <div className="map-tracking-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={latLngResource}
          options={defaultMapOptions}
          zoom={14}
          onLoad={onMapLoad}
        >
          <MapControl
            hideUser={hideResource}
            hideTask={hideTask}
            hideCar={hideCar}
            showResource={showResource}
            showTask={showTask}
            showCar={showCar}
          />

          {showTask ? renderTask() : null}
          {showResource && resourceData.length ? renderResource() : null}
          {polylineData && polylineData.length ? renderPolyline() : null}
          {showCar && polylineGPSData.length ? renderGPSPolyline() : null}
          {
            showCar && polylineGPSData.length ? (
              <Marker
                position={{ lat: _.get(lastGPS, 'lat'), lng: _.get(lastGPS, 'lng') }} // เปลี่ยนเป็นตำแหน่งที่ต้องการ
                icon={{
                  url: TruckIcon, // ใส่ URL ของไอคอนที่ต้องการ
                  scaledSize: new window.google.maps.Size(40, 40), // กำหนดขนาดของไอคอน
                }}
              />
            ) : null
          }
        </GoogleMap>
      </div>
    </div>
  )
}