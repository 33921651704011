import React, { useState, useContext, useMemo, useEffect } from 'react';
// import styles from './css/sub-task-tabs.css';
import { Collapse, Avatar, Icon, Col, Row, Divider, Upload, Modal } from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import ImageGallery from 'react-image-gallery';
import styled from 'styled-components';
import style from '../css/index.css'


const { Panel } = Collapse;


const StyleModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 400px;
    max-height: 500px;
  }

  .ant-modal-close {
    color: #FFFFFF;
    right: 667px;
  }

  .ant-modal-close-x {
    font-size: 24px
  }

  .image-gallery-index {
    margin: 0px 20px 0px 20px;
  }

  .image-gallery-right-nav {
    right: 30%;
  }

  .image-gallery-left-nav {
    left: 30%;
  }
`;

export default ({ value }) => {

  const { assigneeData, keys, subTaskData } = value;


  const resourceSelect = assigneeData && assigneeData.filter((item) => !keys.includes(item.memComId));

  const [expand, setExpand] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState([]);

  const [startIndexGallery, setStartIndexGallery] = useState(0);

  const callback = (key) => {
    setExpand(key);
  };

  const selectResKeys = resourceSelect.map(a => a.memComId);

  const handlePreview = async (files, file) => {
    const found = files && files.findIndex((element) => element.uid === file.uid);
    setModalView(files);
    setStartIndexGallery(found);
    setStateModal(!stateModal);
  };

  const handleCancel = () => {
    setStateModal(false);
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <StyleModal
          visible={stateModal}
          onCancel={handleCancel}
          footer={null}
          width={'100%'}
        >
          <ImageGallery
            className={style.imagegallery}
            items={modalView}
            showPlayButton={false}
            showIndex={true}
            startIndex={startIndexGallery}
            showFullscreenButton={false}
          />
        </StyleModal>
      </div>
    );
  };

  const headerNoExpand = (item, selectResKeys, assigneeData) => {
    const assigneeRender = item.assignee.filter((e) => selectResKeys.includes(e.memComId) || _.findIndex(assigneeData, (obj) => { return obj.memComId === e.memComId; }) === -1);
    return (
      <Row className='header-collapse'>
        <Col span={6}>
          <img src={item.icon} style={{ width: '30px', height: '30px', paddingRight: '10px' }}></img>
          <span className='text-space'>{item.subtaskName}</span>
        </Col>
        <Col span={18}>
          <div>
            <Col span={3}>
              <span className='text-space'>{assigneeRender.length !== 0 ? 'Done by' : ''}</span> &nbsp;
            </Col>
            <Col span={20} className='text-space-name' >
              {selectResKeys && assigneeRender.map((res, index) => (
                <span className='text-space' >{(index ? ',' : '') + res.fullname + ' '}
                  {item && item.subtaskCode === 'take_photo' && item.assignee.length !== 0 ? `(${_.get(res, 'subtaskDetail[0].totalFileView')})` : null}
                </span>
              ))}&nbsp;
            </Col>
          </div>
          {item.assignee.length > 3 ? <Col span={1} > ({item.assignee.length})&nbsp; </Col> : null}
        </Col>
      </Row>
    )
  }

  const StartTask = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter((e) => resourceSelectNew.includes(e.memComId) || _.findIndex(resourceSelectObject, (obj) => { return obj.memComId === e.memComId; }) === -1);

    return (
      resourceSelectNew && assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={5}>
            {i ? <div className='divider-style' /> : ''}
            <Row className='data-list-css'>
              <Col span={3}>
                <Avatar src={item.pathImage} size='large' />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className='text-space-address'>
                    <Icon type="environment" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <Icon type="calendar" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <Icon type="message" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}

            </Row>
          </Col>
        </Row>

      ))
    )
  })

  const CheckIn = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter((e) => resourceSelectNew.includes(e.memComId) || _.findIndex(resourceSelectObject, (obj) => { return obj.memComId === e.memComId; }) === -1);

    return (
      resourceSelectNew && assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={5}>
            {i ? <div className='divider-style' /> : ''}
            <Row className='data-list-css'>
              <Col span={3}>
                <Avatar src={item.pathImage} size='large' />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className='text-space-address'>
                    <Icon type="environment" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <Icon type="calendar" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <Icon type="message" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    )
  })

  const PickupCheckIn = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter((e) => resourceSelectNew.includes(e.memComId) || _.findIndex(resourceSelectObject, (obj) => { return obj.memComId === e.memComId; }) === -1);

    return (
      sequence === 1 && resourceSelectNew && assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={5}>
            {i ? <div className='divider-style' /> : ''}
            <Row className='data-list-css'>
              <Col span={3}>
                <Avatar src={item.pathImage} size='large' />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className='text-space-address'>
                    <Icon type="environment" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <Icon type="calendar" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <span className='icon-space'>Reason :</span>&nbsp;
                    <span>{item.reason ? item.reason : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <Icon type="message" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    )
  })

  const TakePhoto = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter((e) => resourceSelectNew.includes(e.memComId) || _.findIndex(resourceSelectObject, (obj) => { return obj.memComId === e.memComId; }) === -1);
    const assigneeFiles = assigneeRender.map(item => { return item })

    return (
      resourceSelectNew && assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={5}>
            {i ? <div className='divider-style' /> : ''}
            <Row className='data-list-photo-css'>
              <Col span={3}> <Avatar src={item.pathImage} size='large' /> </Col>
              <Col span={21}>
                <div className='subtask-preview-div-upload' >
                  {item.subtaskDetail.map((item, index) => {
                    // let newPath = [];
                    // const path = {
                    //   uid: index + 1,
                    //   url: item.file.path,
                    //   original: item.file.path,
                    //   thumbnail: item.file.path
                    // }
                    // newPath.push(path)
                    // // newData.push(path)
                    return (
                      <div style={{ width: 'fit-content' }}>
                        <Upload
                          className='subtask-preview-upload'
                          listType="picture-card"
                          fileList={item.files}
                          onPreview={(files) => handlePreview(item.files || [], files)}
                          showUploadList={{
                            showRemoveIcon: false
                          }}
                        />
                        <Modal className="subtask-preview-image" visible={previewVisible} footer={null} >
                          <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))
    )
  })

  const POD = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter((e) => resourceSelectNew.includes(e.memComId) || _.findIndex(resourceSelectObject, (obj) => { return obj.memComId === e.memComId; }) === -1);

    return (
      resourceSelectNew && assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={5}>
            {i ? <div className='divider-style' /> : ''}
            <Row className='data-list-css'>
              <Col span={3}> <Avatar src={item.pathImage} size='large' /> </Col>
              {item.subtaskDetail.map((item, index) => {

                let newPathPOD = [];
                const path = {
                  uid: index + 1,
                  url: item.files.path,
                  original: item.files.path,
                  thumbnail: item.files.path
                }
                newPathPOD.push(path)

                return (
                  <Col span={21}>
                    <div className='text-space'>
                      <Icon type="user" className='icon-space' style={{ color: '#b2b2b2' }} />
                      <span>{item.role ? item.role : '-'} / {item.typeName ? item.typeName : '-'}</span>
                    </div>
                    <div className='text-space'>
                      <Icon type="calendar" className='icon-space' style={{ color: '#b2b2b2' }} />
                      <span>{item.updatedAt}</span>
                    </div>
                    <div>
                      <Upload
                        listType="picture-card"
                        fileList={newPathPOD}
                        onPreview={(files) => handlePreview(newPathPOD || [], files)}
                        showUploadList={{
                          showRemoveIcon: false
                        }}
                      />
                      <Modal className="subtask-preview-image" visible={previewVisible} footer={null} onCancel={handleCancel}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      ))
    )
  })

  const CompleteTask = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter((e) => resourceSelectNew.includes(e.memComId) || _.findIndex(resourceSelectObject, (obj) => { return obj.memComId === e.memComId; }) === -1);

    return (
      resourceSelectNew && assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={5}>
            {i ? <div className='divider-style' /> : ''}
            <Row className='data-list-css'>
              <Col span={3}>
                <Avatar src={item.pathImage} size='large' />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className='text-space-address'>
                    <Icon type="environment" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <Icon type="calendar" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className='text-space'>
                    <Icon type="message" className='icon-space' style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    )
  })


  return (
    <div >
      <Collapse
        className="sub-task-collapse"
        expandIconPosition='right'
        onChange={callback}
        bordered={true}
        expandIcon={({ isActive }) => <Icon type="up" rotate={isActive ? 0 : 180} />}
      >
        {
          subTaskData.map((item) => {

            return (
              <Panel className='panel-css' header={headerNoExpand(item, selectResKeys, assigneeData)} key={item.subtaskCode} >
                {item.subtaskCode === 'start_task' ? <StartTask item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeData} /> : null}
                {item.subtaskCode === 'pickup_check_in' ? <PickupCheckIn item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeData} /> : null}
                {item.subtaskCode === 'check_in' ? <CheckIn item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeData} /> : null}
                {item.subtaskCode === 'take_photo' ? <TakePhoto item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeData} /> : null}
                {item.subtaskCode === 'pod_task' ? <POD item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeData} /> : null}
                {item.subtaskCode === 'complete_task' ? <CompleteTask item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeData} /> : null}
              </Panel>
            )
          })
        }
      </Collapse>
      <HandlerModal />
    </div>
  )
}
