import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import './index.css';
import ListItemFilter from './filter';
import ListItemDetail from './item';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../../../components/v2/button_01';
import _ from 'lodash';
import { getWareHouseList } from '../../../../../controllers/warehouse/warehouse';
import { getBrandData, getListItem, getModelData } from '../../../../../controllers/po/po';
import { useDebounce } from '../../../../../controllers/debounce';
import { getItemsCode, getItemsMaster } from '../../../../../controllers/warehouse/warehouse';
import { getListItemQuotation } from '../../../../../controllers/quotation/quotation-api-new';

const ListItem = (props) => {
  const { visible, setVisible, setVisibleAdd, setCreateData, vendorData, form, setDataForm, dataForm, handleOpen } = props;
  const intl = useIntl();
  const [searchGroup, setSearchGroup] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [listPage, setListPage] = useState(1);
  const [listSize, setListSize] = useState(10);
  const [itemListTotal, setItemListTotal] = useState(0);

  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseCheckAll, setWarehouseCheckAll] = useState(false);
  const [warehouseInterminate, setWarehouseInterminate] = useState(false);
  const [warehouseSelect, setWarehouseSelect] = useState([]);
  const [panelWarehouse, setPanelWarehouse] = useState(false);

  const [stockData, setStockData] = useState([]);
  const [stockCheckAll, setStockCheckAll] = useState(false);
  const [stockInterminate, setStockInterminate] = useState(false);
  const [stockSelect, setStockSelect] = useState([]);
  const [panelStock, setPanelStock] = useState(false);

  const [optionsBrand, setOptionsBrand] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [brandSearch, setBrandSearch] = useState();
  const debounceBrandSearch = useDebounce(brandSearch, 500);

  const [optionsModel, setOptionsModel] = useState([]);
  const [loadingModel, setLoadingModel] = useState(false);
  const [modelSearch, setModelSearch] = useState();
  const debounceModelSearch = useDebounce(modelSearch, 500);

  const [optionsItemCode, setOptionsItemCode] = useState([]);
  const [loadingItemCode, setLoadingItemCode] = useState(false);
  const [itemCodeSearch, setItemCodeSearch] = useState();
  const debounceItemCodeSearch = useDebounce(itemCodeSearch, 500);

  const [optionsItemName, setOptionsItemName] = useState([]);
  const [loadingItemName, setLoadingItemName] = useState(false);
  const [itemNameSearch, setItemNameSearch] = useState();
  const debounceItemNameSearch = useDebounce(itemNameSearch, 500);

  console.log('searchGroup', searchGroup);

  useEffect(() => {
    setSearchGroup([])
    setWarehouseSelect([])
    setPanelWarehouse(false)
    setWarehouseCheckAll(false)
    setWarehouseInterminate(false)
    setItemList([])
  }, [visible])

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsUnit', defaultMessage: 'Unit' }),
      dataIndex: 'unit',
      key: 'unit',
    },
    // {
    //   title: intl.formatMessage({ id: 'modalListColumnsStockBalance', defaultMessage: 'Stock Balance' }),
    //   dataIndex: 'stockBalance',
    //   key: 'stockBalance',
    // },
  ];

  useEffect(() => {
    async function GetWarehouse() {
      try {
        const payload = {
          indexStart: 0,
          indexEnd: 100,
          searchLeftMenu: '',
          isNotCheckPermission: true,
        };
        const response = await getWareHouseList(payload);
        setWarehouseData(_.get(response, 'data.data.warehouseList'));
      } catch (error) {}
    }
    GetWarehouse();
  }, []);

  const GetSearchListData = async () => {
    console.log('GetSearchListData', dataForm);

    const body = {
      warehouseId: warehouseSelect,
      itemBrandId: _.get(searchGroup, 'brand') ? _.get(searchGroup, 'brand') : [],
      itemModelId: _.get(searchGroup, 'model') ? _.get(searchGroup, 'model') : [],
      itemCode: _.get(searchGroup, 'itemCode') || "",
      itemName: _.get(searchGroup, 'itemName') || "",
      limit: listSize,
      // page: _.get(searchGroup, 'itemCode') || _.get(searchGroup, 'itemName') ? 1 :listPage,
      page: 1,
      customerId: _.get(dataForm, 'customerId') || '',
      recently: _.get(searchGroup, 'purchase') || false,
      recentlyModule: 'saleorder',
    };
    
    setListPage(1)
    const response = await getListItem(body);
    setItemList(_.get(response, 'data.data.itemList'));
    setItemListTotal(_.get(response, 'data.data.totalItem'));
  };

  const handlePagination = async (page) => {
    const body = {
      warehouseId: warehouseSelect,
      itemBrandId: _.get(searchGroup, 'brand') ? _.get(searchGroup, 'brand') : [],
      itemModelId: _.get(searchGroup, 'model') ? _.get(searchGroup, 'model') : [],
      itemCode: _.get(searchGroup, 'itemCode') || "",
      itemName: _.get(searchGroup, 'itemName') || "",
      limit: listSize,
      page: page,
      customerId: _.get(dataForm, 'customerId') || '',
      recently: _.get(searchGroup, 'purchase') || false,
      recentlyModule: 'saleorder',

    };
    const response = await getListItem(body);
    setItemList(_.get(response, 'data.data.itemList'));
    setItemListTotal(_.get(response, 'data.data.totalItem'));
    setListPage(page);
  };

  const handleSizeChange = async (current, size) => {
    const body = {
      warehouseId: warehouseSelect,
      itemBrandId: _.get(searchGroup, 'brand') ? _.get(searchGroup, 'brand') : [],
      itemModelId: _.get(searchGroup, 'model') ? _.get(searchGroup, 'model') : [],
      itemCode: _.get(searchGroup, 'itemCode') || "",
      itemName: _.get(searchGroup, 'itemName') || "",
      limit: size,
      page: current,
      customerId: _.get(dataForm, 'customerId') || '',
      recently: _.get(searchGroup, 'purchase') || false,
      recentlyModule: 'saleorder',

    };
    const response = await getListItem(body);
    setItemList(_.get(response, 'data.data.itemList'));
    setItemListTotal(_.get(response, 'data.data.totalItem'));
    setListPage(current);
    setListSize(size);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleClickRow = (data) => {
    setCreateData(data);
    setVisibleAdd(true);
  };

  useEffect(() => {
    const fetcBrand = async () => {
      setLoadingBrand(true);
      const body = {
        indexStart: 0,
        indexEnd: 10,
        item_brand_name: brandSearch || '',
      };
      const response = await getBrandData(body);
      setOptionsBrand(_.get(response, 'data.data.list'));
      setLoadingBrand(false);
    };
    fetcBrand(); // โหลดข้อมูลเริ่มต้น
  }, [debounceBrandSearch]);

  useEffect(() => {
    const fetcModel = async () => {
      setLoadingModel(true);
      const body = {
        indexStart: 0,
        indexEnd: 10,
        item_model_name: modelSearch || '',
        item_brand_id: _.get(searchGroup, 'brand') || [],
      };
      const response = await getModelData(body);
      setOptionsModel(_.get(response, 'data.data.list'));
      setLoadingModel(false);
    };
    if (_.get(searchGroup, 'brand')) {
      fetcModel(); // โหลดข้อมูลเริ่มต้น
    }
  }, [debounceModelSearch, searchGroup]);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        code: itemCodeSearch || '',
      };
      const response = await getItemsCode(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setOptionsItemCode(_.uniqBy(_.concat(optionsItemCode, list), 'itemMasterId'));
    };
    getItemCode();
  }, [debounceItemCodeSearch]);

  useEffect(() => {
    const getItemName = async () => {
      const payload = {
        name: itemNameSearch || '',
      };
      const response = await getItemsMaster(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setOptionsItemName(_.uniqBy(_.concat(optionsItemName, list), 'itemMasterId'));
    };

    getItemName();
  }, [debounceItemNameSearch]);

  const onChange = async (value, code) => {
    console.log('onChange',value, code);
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const onAllowClear = (value, code) => {
    if(!value){
      setSearchGroup({ ...searchGroup, [code]: undefined });
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemTitle`, defaultMessage: 'Add Item' })}
      visible={visible}
      onCancel={handleClose}
      centered={true}
      width={1112}
      
      bodyStyle={{ padding: 'unset', height: '680px' }}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className="listItemTextHeader">
            <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
          </div>
          <div className="listItemDividerCenter">
            <ListItemFilter
              onChange={onChange}
              onAllowClear={onAllowClear}
              searchGroup={searchGroup}
              onClick={GetSearchListData}
              warehouse={{
                warehouseData,
                warehouseCheckAll,
                setWarehouseCheckAll,
                warehouseInterminate,
                setWarehouseInterminate,
                warehouseSelect,
                setWarehouseSelect,
                panelWarehouse,
                setPanelWarehouse,
              }}
              stock={{
                stockData,
                stockCheckAll,
                setStockCheckAll,
                stockInterminate,
                setStockInterminate,
                stockSelect,
                setStockSelect,
                panelStock,
                setPanelStock,
              }}
              brand={{
                optionsBrand,
                loadingBrand,
                brandSearch,
                setBrandSearch,
              }}
              model={{
                optionsModel,
                loadingModel,
                modelSearch,
                setModelSearch,
              }}
              itemCode={{
                optionsItemCode,
                loadingItemCode,
                itemCodeSearch,
                setItemCodeSearch,
              }}
              itemName={{
                optionsItemName,
                loadingItemName,
                itemNameSearch,
                setItemNameSearch,
              }}
            />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader">
            <FormattedMessage id="modalListItemItemText" defaultMessage="Item" />
          </div>
          <div>
            <ListItemDetail
              onChange={onChange}
              columns={columns}
              handleClickRow={handleClickRow}
              vendorData={vendorData}
              itemList={itemList}
              listPage={listPage}
              itemListTotal={itemListTotal}
              handlePagination={handlePagination}
              handleSizeChange={handleSizeChange}
              form={form}
              setDataForm={setDataForm}
              dataForm={dataForm}
              handleOpen={handleOpen}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ListItem;
