import React from 'react';
import httpClient from '../../components/axiosClient';

const editServiceArea= async (props) => {
  const comCode = localStorage.getItem('comCode');


  let bodyData = props.body;

  if (bodyData && props.editAreaId) {
    const body = {
      areaCode: bodyData.areaCode,
      areaName: bodyData.areaName,
      areaColor: bodyData.areaColor,
      areaCenter: bodyData.areaCenter,
      areaType: bodyData.areaType,
      coordinates: bodyData.coordinates
    }


    try {
      const response = await httpClient.put(`/v3/resource/manager/company/${comCode}/area-master/${props.editAreaId}`, body);
      if (response.status === 200) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error.response;
    }
  }
}

export default editServiceArea;