import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { AutoComplete, Select, Icon, Input, Button } from 'antd';
import { GoogleMap, useLoadScript, LoadScript, withScriptjs, withGoogleMap, Marker, InfoWindow, Polygon, DrawingManager, StandaloneSearchBox } from '@react-google-maps/api';
import getSearchLocation from '../../function/search-location/getLocation';
import _, { debounce } from 'lodash';
import { ServiceAreaContext } from './index';
import markerIcon from '../../components/image/MarkerArea.svg';
import styled from './css/index.css';
import ReactDOMServer from 'react-dom/server'

const { Option } = Select;

const libraries = ["drawing", "places"];

const mapContainerStyle = {
  height: `calc(100vh - 292px)`,
  width: '100%',
  position: 'relative',
}

let polygonSeleted = [false];

const ServiceAreaMap = (props) => {
  const { mainState, mainSetState } = useContext(ServiceAreaContext);
  const { areaData } = props;
  const intl = useIntl();
  const [addressArea, setAddressArea] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 13.90607, lng: 100.51913 });
  const [infoData, setInfoData] = useState(null);
  const [isOpenInfo, setIsOpenInfo] = useState(false);



  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${localStorage.getItem('GEO_APIKEY')}`,
    libraries,
  });

  const mapRef = useRef();
  const drawingRef = useRef();


  const TopLeftControl = (controlDiv, map) => {

 

    // const controlUI = document.createElement("div");

    // let controlUI = document.getElementById('drawing-example').getElementsByClassName("gmnoprint");
    // let controlUI = document.getElementsByClassName("gmnoprint");
    // let controlUI = document.getElementById('drawing-example');
    // controlUI.getElementsByClassName("gmnoprint")[0].style.margin = "96px 0px 0px 28px";

    // let arrayOfElements = Array.from(controlUI);
    // controlUI = Array.prototype.slice.call(elements);
    // let sliceControlUI = [].slice.call(controlUI);

    // controlUI = [...controlUI];

    

    // controlUI[0].style.margin = "86px 0px 0px 28px";
    // controlDiv.appendChild(controlUI);
  }

  useEffect(() => {
    if(_.size(props.markers)) props.markers[0].setMap(null);
    if(_.size(props.polygons)) props.polygons[0].setMap(null);
    if(_.size(areaData) && mapRef.current && _.get(areaData, 'areaMasterId')) {

      setDefaultCenter(areaData.coordinates[0].defaultCenter);

      if (mainState.actionAddNew === 'edit' || mainState.actionAddNew === 'create') {
        props.markers[0] = new window.google.maps.Marker({
          position: {lat: areaData.coordinates[0].defaultCenter.lat, lng: areaData.coordinates[0].defaultCenter.lng},
          draggable: true,
          icon: {
            url: markerIcon,
            scaledSize: new window.google.maps.Size(50, 50),
            anchor: new window.google.maps.Point(12, 42),
          },
          map: mapRef.current
        });

        props.setMarkerData(props.markers[0]);

        if(areaData.coordinates[0].type === 'polygon') {
          props.polygons[0] = new window.google.maps.Polygon({
            paths: areaData.coordinates[0].coordinates,
            editable: true, 
            clickable: true,
            draggable: true,
            visible: true,
            geodesic: false,
            fillColor: '#707070', 
            strokeColor: '#707070',  
            strokeWeight: 2
          });

          let infoWindow = new window.google.maps.InfoWindow();
          props.polygons[0].addListener('click', (event) => {
            setInfoData({event: event});
          });
          props.polygons[0].setMap(mapRef.current);
          props.setPolygonData([props.polygons[0]]);
        }
      } else if (mainState.actionAddNew === 'view') {
        props.markers[0] = new window.google.maps.Marker({
          position: {lat: areaData.coordinates[0].defaultCenter.lat, lng: areaData.coordinates[0].defaultCenter.lng},
          draggable: false,
          icon: {
            url: markerIcon,
            scaledSize: new window.google.maps.Size(50, 50),
            anchor: new window.google.maps.Point(12, 42),
          },
          map: mapRef.current
        });

        if(areaData.coordinates[0].type === 'polygon') {
          props.polygons[0] = new window.google.maps.Polygon({
            paths: areaData.coordinates[0].coordinates,
            editable: false, 
            clickable: false,
            draggable: false,
            visible: true,
            geodesic: false,
            fillColor: '#707070', 
            strokeColor: '#707070',  
            strokeWeight: 2
          });
          props.polygons[0].setMap(mapRef.current);
        }
      }

      // props.markers[0] = new window.google.maps.Marker({
      //   position: {lat: areaData.coordinates[0].defaultCenter.lat, lng: areaData.coordinates[0].defaultCenter.lng},
      //   draggable: true,
      //   map: mapRef.current
      // });

      // if(areaData.coordinates[0].type === 'polygon') {
      //   props.polygons[0] = new window.google.maps.Polygon({
      //     paths: areaData.coordinates[0].coordinates,
      //     editable: true, 
      //     clickable: true,
      //     draggable: true,
      //     visible: true,
      //     geodesic: false,
      //    fillColor: '#707070', 
      //    strokeColor: '#707070', 
      //     strokeWeight: 2
      //   });
      //   props.polygons[0].setMap(mapRef.current);
      // }
    }
  }, [areaData, mainState.actionAddNew])

  const onMapLoad = useCallback((map) => {
    // window.addEventListener('keydown', (e) => {
    //   if(e.key === 'Delete') {
    //     if(_.size(props.polygons) > 0) {

    //       props.polygons[0].setMap(null);
    //       props.setClearPolygons();

    //     }
    //   }
    // }, false);

    // const leftControlDiv = document.createElement("div");
    // TopLeftControl(leftControlDiv, map);
    // map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(leftControlDiv);
    mapRef.current = map;
  }, []);

  const onDrawingRef = useCallback((drawing) => {

    // let controlUI = document.getElementById('drawing-example').getElementsByClassName("gmnoprint")[0];
    // let arrayOfElements = Array.from(controlUI);
    // let sliceControlUI = [].slice.call(controlUI);


   

    // const leftControlDiv = document.createElement("div");
    // leftControlDiv.index = 1;
    // TopLeftControl(leftControlDiv, drawing);
    // controlUI[0].style.margin = "30px";
    // drawing.controls[window.google.maps.ControlPosition.TOP_LEFT].push(leftControlDiv);

    drawingRef.current = drawing
  }, [mainState.actionAddNew]);
  
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onMarkerComplete = (data) => {
    data.setMap(null);
    if(_.size(props.markers)) {
      props.markers[0].setMap(null);
    }
    
    props.markers[0] = new window.google.maps.Marker({
      position: {lat: data.position.lat(), lng: data.position.lng()},
      draggable: true,
      icon: {
        url: markerIcon,
        scaledSize: new window.google.maps.Size(50, 50),
        anchor: new window.google.maps.Point(12, 42),
      },
      map: mapRef.current
    })

    if (data.clickable === true) {
      props.setMarkerData(props.markers[0]);
    }

    props.markers[0].addListener('dragend', (e) => {
      props.setMarkerData(props.markers[0]);
    });
  }

  const onPolygonComplete = (data) => {
    
    let coordinates = [];
    const path = data.getPath();
    for (let i = 0; i < path.length; i++) {
      let pathArray = {lat: path.getAt(i).lat(), lng: path.getAt(i).lng()};
      coordinates.push(pathArray);
    }

    data.setMap(null);
    if(_.size(props.polygons)) {
      props.polygons[0].setMap(null);
    }

    props.polygons[0] = new window.google.maps.Polygon({
      paths: coordinates,
      editable: true, 
      clickable: true,
      draggable: true,
      visible: true,
      geodesic: false,
      fillColor: '#707070', 
      strokeColor: '#707070', 
      fillOpacity: 0.35,
      strokeWeight: 2,
      strokeOpacity: 0.5,
    });



    let infoWindow = new window.google.maps.InfoWindow();
    props.polygons[0].addListener('click', (event) => {
      setInfoData({event: event});
    });

    props.polygons[0].setMap(mapRef.current);
    props.setPolygonData([props.polygons[0]]);

    // props.polygons[0].addListener('drag', (event) => {
    //   props.polygons[0].setOptions({fillOpacity: 0.5, strokeOpacity: 0.8});
    //   props.polygons[0].setMap(mapRef.current);
    // });

    // window.google.maps.addDomListener(mapRef.current, 'keyup', function (e) {
    // });

    // polygons[0].addListener('mouseover', () => {
    //   polygons[0].setOptions({strokeColor: '#c70000'});
    //   polygons[0].setMap(mapRef.current);
    // });

    // polygons[0].addListener('mouseout', () => {
    //   polygons[0].setOptions({strokeColor: "#ff0000"});
    //   polygons[0].setMap(mapRef.current);
    // });
  }

  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await getSearchLocation({ name: fieldChang });
    setAddressArea(newData);
  }, 200);

  const handleSelect = (key, option) => {if (isNaN(key) === false) {
      if(_.size(props.markers)) {
        props.markers[0].setMap(null);
      }
      props.markers[0] = new window.google.maps.Marker({
        position: {lat: addressArea[key].lat, lng: addressArea[key].lng},
        draggable: true,
        icon: {
          url: markerIcon,
          scaledSize: new window.google.maps.Size(50, 50),
          anchor: new window.google.maps.Point(12, 42),
        },
        map: mapRef.current
      })
      props.setMarkerData(props.markers[0]);
      setDefaultCenter({lat: addressArea[key].lat, lng: addressArea[key].lng});
    }
  };

  const options = addressArea.map(item =>
    <Option key={item.key}>
      {item.address}
    </Option>
  );

  const handleClickDeletePolygon = (e) => {
  

    if(_.size(props.polygons)) {
      props.polygons[0].setMap(null);
      props.setPolygonData([]);
      setIsOpenInfo(current => !current);
      setInfoData(null);
      mainSetState.setAreaName("");
    }
  }

  const onInfoWindowClose = (e) => {
    setIsOpenInfo(current => !current);
    setInfoData(null);
  }

  const defaultMapOptions = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: window.google.maps.ControlPosition.TOP_RIGHT,
    },
  };

  return (
    <div className="service-area-map">
      <div className="service-area-map-container">
        <div>
          <GoogleMap
            id="drawing-example"
            className="service-area-googleMap"
            mapContainerStyle={mapContainerStyle}
            center={defaultCenter}
            zoom={12}
            options={defaultMapOptions}
            onLoad={onMapLoad}
          >
            {mainState.actionAddNew === 'edit' || mainState.actionAddNew === 'create' ? (
              <div className="service-area-drawingManager">
                <DrawingManager
                  className="service-area-drawingManager"
                  onLoad={onDrawingRef}
                  onPolygonComplete={onPolygonComplete}
                  onMarkerComplete={onMarkerComplete}
                  options={{
                    drawingControl: true,
                    drawingControlOptions: {
                      position: window.google.maps.ControlPosition.TOP_LEFT,
                      drawingModes: [
                        window.google.maps.drawing.OverlayType.MARKER,
                        window.google.maps.drawing.OverlayType.POLYGON,
                      ]
                    },
                    polygonOptions: { 
                      editable: true, 
                      clickable: true,
                      visible: true,
                      geodesic: false,
                      fillColor: '#707070', 
                      strokeColor: '#707070', 
                      strokeWeight: 2
                    },
                    markerOptions: {
                      icon:  {
                          url: markerIcon,
                          scaledSize: new window.google.maps.Size(50, 50),
                          anchor: new window.google.maps.Point(12, 42),
                      }
                    }
                  }}
                />

                <div className="service-area-autocomplete-div">
                  <AutoComplete
                    className="service-area-autocomplete"
                    dataSource={options}
                    onSearch={handleSearchLocation}
                    onSelect={handleSelect}
                    filterOption={false}
                  >
                    <Input 
                      className="service-area-autocomplete-input"
                      placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                      prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />} 
                      autoComplete="off"
                    />
                  </AutoComplete>
                </div>
              </div>
            ) : null} 

            {infoData && (
              <InfoWindow
                position={infoData.event.latLng}
                onCloseClick={onInfoWindowClose}
                visible={isOpenInfo}
              >
                <div className="info-window-container">
                    <div className="info-window-col-areaName">
                      {mainState.areaName}
                    </div>
                    <div className="info-window-col-icon">
                      <Icon type="delete" style={{ fontSize: "16px" }} onClick={(e) => handleClickDeletePolygon()} />
                    </div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  )
}

export default ServiceAreaMap;
