import React, { useState, useEffect, useRef, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon, Form, Modal, Input, InputNumber, Select, AutoComplete, Button } from 'antd';
import _, { debounce } from 'lodash';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import styled from 'styled-components';

import stylesAddItem from './css/modal-add-item.css';
import { SaleOrderContext } from './sale-order-context';
import { getItemValue, getItemName } from '../../controllers/sale-order/get-item'
import NumberFormat from 'react-number-format';
import numeral from 'numeral';
import CurrencyInput from 'react-currency-input-field';

const { Option } = Select;

const ModalFormComponent = (props) => {
  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };
  const intl = useIntl();
  const ref = useRef(null);
  const refNumber = useRef(null);
  const comCode = localStorage.getItem('comCode');
  const { visibleModalAdd, handleAddItem, handleCancelAddItem, form, onChange, itemUnitData, handleKeyUp, setDatailItem, datailItem, focusInput, triggerAddItem
    , searchItemCodeResult, setSearchItemCodeResult, searchItemNameResult, setSearchItemNameResult, calTotalShow, setCalTotalShow } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = form;


  const [toggleInput, setToggleInput] = useState(false);
  const qty = getFieldValue('qty') !== undefined ? _.floor(getFieldValue('qty'), 2).toFixed(2) : 0;

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      resetFields();
      setFieldsValue({ ['unitPrice']: 0 })
      setFieldsValue({ ['total']: 0 })
    }
  }, [visibleModalAdd, focusInput, triggerAddItem])


  useEffect(() => {
    // const result = unitPrice * qty;
    const unitprice = _.get(datailItem, 'unitPrice') || 0
    const float = parseFloat(unitprice)
    const calculate = getFieldValue('unitPrice') ? parseFloat(getFieldValue('unitPrice')) * qty : float * qty
    setFieldsValue({ ['total']: _.floor(calculate, 2).toFixed(2) })
    // setFieldsValue({ ['total']: numeral(calculate).format('0,0.00') })
    // setCalTotalShow(_.floor(calculate, 2).toFixed(2))

  }, [_.get(datailItem, 'unitPrice'), qty, getFieldValue('unitPrice')])

  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {

      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {
        e.preventDefault();

      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => { return o.charCodeAt(0) === 46 ? 1 : 0 })
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }

    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  }

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66) {
      return true;
    }
    else {
      e.preventDefault();
    }
  }
  // useEffect(() => {
  //   const getDataDefault = async () => {
  //     const dataCode = await getItemValue(comCode, '');
  //     const dataName = await getItemName(comCode, '');
  //     setSearchItemCodeResult(dataCode);
  //     setSearchItemNameResult(dataName);
  //   }
  //   getDataDefault();
  // }, [])


  const getValueItem = debounce(async (fieldChang) => {
    const data = await getItemValue(comCode, fieldChang);
    setSearchItemCodeResult(data);
  }, 200)

  const getValueItemname = debounce(async (fieldChang) => {
    const data = await getItemName(comCode, fieldChang);
    setSearchItemNameResult(data);
  }, 200)


  const childrenCode = searchItemCodeResult.map(item =>
    <Option key={item.itemMasterId}>
      {item.itemCode}
    </Option>
  );

  const childrenName = searchItemNameResult.map(item =>
    <Option key={item.itemMasterId}>
      {item.itemName}
    </Option>
  );

  const handleSelectItemCode = (key, option) => {
    resetFields(["itemCode"], ["itemName"], ["unit"], ["unitPrice"]);
    const mapName = searchItemCodeResult && searchItemCodeResult.filter((col) => key.includes(col.itemMasterId));
    const mapNameIndex = mapName && mapName[0]
    setFieldsValue({ ['itemCode']: option.props.children });
    setFieldsValue({ ['itemName']: mapNameIndex.itemName });
    setFieldsValue({ ['unitPrice']: Number(mapNameIndex.itemStandardPrice) });
    setDatailItem({
      itemName: mapNameIndex.itemName,
      unitPrice: mapNameIndex.itemStandardPrice,
      itemId: mapNameIndex.itemMasterId,
      itemCode: mapNameIndex.itemCode
    })

  }

  const handleSelectItemName = (key, option) => {
    resetFields(["itemCode"], ["itemName"], ["unit"], ["unitPrice"]);
    const mapName = searchItemNameResult && searchItemNameResult.filter((col) => key.includes(col.itemMasterId));
    const mapNameIndex = mapName && mapName[0]
    setFieldsValue({ ['itemCode']: mapNameIndex.itemCode });
    setFieldsValue({ ['itemName']: option.props.children });
    setFieldsValue({ ['unitPrice']: Number(mapNameIndex.itemStandardPrice) });
    setDatailItem({
      itemName: mapNameIndex.itemName,
      unitPrice: mapNameIndex.itemStandardPrice,
      itemId: mapNameIndex.itemMasterId,
      itemCode: mapNameIndex.itemCode
    })
  }

  // const onBlur = () => {
  //   //  setFocusInput(false)
  // }

  const handleBlur = () => {
    setToggleInput(!toggleInput)
  }

  const checkQtyValue = (rule, value, callback) => {
    if (value < 1) {
      callback('Qty must not be less than 1')
    } else {
      callback()
    }
  }


  const onBlur = () => {

  }

  return (
    <div>
      <Modal
        className="sale-oreder-modal-add-item"
        title={<FormattedMessage id="modalAddItemModalTitleAddItem" defaultMessage="Add Item" />}
        centered="true"
        width={910}
        visible={visibleModalAdd}
        onOk={handleAddItem}
        okText={intl.formatMessage({ id: 'btnAdd', defaultMessage: 'Add' })}
        cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
        onCancel={handleCancelAddItem}
        okButtonProps={
          {
            style: {
              width: '96px'
            }
          },
          {
            type: "primary"
          }
        }
        cancelButtonProps={
          {
            style: {
              width: '96px'
            }
          },
          {
            type: "danger"
          }
        }
      // footer={[
      //   <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleCancelAddItem}>
      //     <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
      //   </Button02>,
      //   <Button01 htmlType="submit" key="submit" type="primary" btnsize='wd_df' onClick={handleAddItem}  >
      //     <FormattedMessage id="btnAdd" defaultMessage="Add" />
      //   </Button01>,
      // ]}
      >
        <div className="sale-oreder-modal-add-body">
          <Form className="modal-add-form" layout="inline" onKeyUp={handleKeyUp} onSubmit={handleAddItem}>
            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalformlabItemCode', defaultMessage: 'Item Code' })} req={true} />}
            >
              {getFieldDecorator('itemCode', {
                // initialValue: _.get(datailItem, 'itemCode'),
                rules: [{ required: true, message: intl.formatMessage({ id: 'modalAddItemModalformValidateItemCode', defaultMessage: 'Please input item code' }) }],
              }
              )(
                <AutoComplete
                  ref={ref}
                  onBlur={onBlur}
                  autoFocus={focusInput}
                  placeholder={intl.formatMessage({ id: 'modalAddItemModalformPlaceholderSelectItemCode', defaultMessage: 'Select Item Code' })}
                  dataSource={searchItemCodeResult}
                  onSearch={getValueItem}
                  onSelect={handleSelectItemCode}
                  filterOption={false}
                  style={{ width: '180px' }}
                >
                  {childrenCode}
                </AutoComplete>
              )}
            </Form.Item>

            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalformlabItemName', defaultMessage: 'Item Name' })} req={true} />}
            >
              {getFieldDecorator('itemName', {
                // initialValue: _.get(datailItem, 'itemName'),
                rules: [{ required: true, message: intl.formatMessage({ id: 'modalAddItemModalformValidatetemName', defaultMessage: 'Please input item name' }) }],
              }
              )(
                // <Input style={{ width: '180px' }} />
                <AutoComplete
                  placeholder={intl.formatMessage({ id: 'modalAddItemModalformPlaceholderSelecttemName', defaultMessage: 'Select Item name' })}
                  dataSource={searchItemNameResult}
                  onSearch={getValueItemname}
                  onSelect={handleSelectItemName}
                  filterOption={false}
                  style={{ width: '180px' }}
                >
                  {childrenName}
                </AutoComplete>

              )}
            </Form.Item>

            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalformlabUnit', defaultMessage: 'Unit' })} req={true} />}
            >
              {getFieldDecorator('unit', {
                // initialValue: initialUnit,
                rules: [{ required: true, message: intl.formatMessage({ id: 'modalAddItemModalformValidateUnit', defaultMessage: 'Please input unit' }) }],
              }
              )(<Select style={{ width: '100px' }} placeholder={intl.formatMessage({ id: 'modalAddItemModalformPlaceholderUnit', defaultMessage: 'Select Unit' })}>
                {itemUnitData && itemUnitData.map((item, index) => (
                  <Option key={item.itemsUnitId} value={item.itemsUnitId}>{item.itemsUnitName}</Option>
                ))}
              </Select>)}
            </Form.Item>




            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'orgTabMemberModalformlabUnitPrice', defaultMessage: 'Unit Price (Baht)' })} req={true} />}
            >
              {getFieldDecorator('unitPrice', {
                initialValue: 0,
                rules: [{ required: true, message: 'Please input unit price' }],
              }
              )(
                <InputNumber
                  className='sale-oreder-none-count'
                  style={{ width: '120px', textAlign: 'right' }}
                  placeholder={intl.formatMessage({ id: 'modalAddItemModalformPlaceholderUnitPrice', defaultMessage: 'Select Unit Price' })}
                  maxLength={10}
                  onKeyDown={handleKeyDownNumberDecimal}
                  formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>

            <Form.Item
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAddItemModalformlabQty', defaultMessage: 'Qty' })} req={true} />}
            >
              {getFieldDecorator('qty', {
                initialValue: 1,
                rules: [
                  { required: true, message: intl.formatMessage({ id: 'modalAddItemModalformValidateQTY', defaultMessage: 'Please input qty' }) },
                  { validator: checkQtyValue }
                ],
              }
              )(
                <InputNumber
                  className='display-none'
                  style={{ width: '70px' }}
                  maxLength={10}
                  onKeyDown={handleKeyDownNumber}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="modalAddItemModalformlabTotal" defaultMessage="Total (Baht)" />}
            >
              {getFieldDecorator('total', {
                initialValue: _.floor(0, 2).toFixed(2),
              }
              )(<InputNumber
                style={{ width: '114px', textAlign: 'right' }}
                disabled
                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                className='sale-oreder-none-count'

              />)}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const ModalAddItem = Form.create({ name: "modal_form" })(ModalFormComponent);

export default ModalAddItem;