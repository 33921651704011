import React from 'react';
import CollapseCustom from '../../../components/collapse-custom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Form, Select, DatePicker, Row, Col } from 'antd';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import LoadmoreCustomer from '../../../components/loadmore/customer';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportISalesOrderReportAction = (props) => {
  const intl = useIntl();
  const { search, setSearch, setPage, setIsFetch, onFilterOrder, customerList, refTypeList, brand, model, statusList } = props;

  const handleApply = () => {
    setIsFetch((event) => !event);
    setPage(1);
  };

  const handleReset = () => {
    setIsFetch((event) => !event);
    setSearch((prev) => ({ ...prev, soNo: '', customer: undefined, status: undefined,customerSearch: undefined, }));
    setPage(1);
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemSalesColumnsSoNo', defaultMessage: 'SO No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefSoNo', defaultMessage: 'Enter SO No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'soNo')}
                value={_.get(search, 'soNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
            // label={
            //   <LabeRequireForm text={intl.formatMessage({ id: 'reportSalesOrderFilterCustomer', defaultMessage: 'Customer' })} req={false} />
            // }
            >
              <LoadmoreCustomer data={search} setData={setSearch} required={false} notSpec={true} />
              {/* <Select
                showSearch
                allowClear={true}
                onChange={(value) => onFilterOrder(value, 'customer')}
                value={_.get(search, 'customer')}
                placeholder={intl.formatMessage({ id: 'reportSalesOrderFilterCustomerPH', defaultMessage: 'Select Customer' })}
              >
                {_.map(customerList, (item) => (
                  <Option key={_.get(item, 'customerId')} value={_.get(item, 'customerId')} data={item}>
                  {_.get(item, 'customerName')}
                </Option>
                ))}
              </Select> */}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportSalesOrderFilterStatus ', defaultMessage: 'Status' })} req={false} />
              }
            >
              <Select
                showSearch
                allowClear={true}
                onChange={(value) => onFilterOrder(value, 'status')}
                value={_.get(search, 'status')}
                placeholder={intl.formatMessage({ id: 'reportSalesOrderFilterStatusPH', defaultMessage: 'Select Status' })}
                style={{ width: '100%' }}
              >
                {_.map(statusList, (item) => (
                  <Option value={item.code}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 2]}>
          <Col span={16}></Col>
          <Col span={8} style={{ textAlign: 'right',marginTop: '-20px'  }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportReconcilePHExpiredDateFrom', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportReconcilePHExpiredDateTo', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {onFilterOrder(value, 'searchDate');setPage(1);}}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'searchDate')}
            />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default ReportISalesOrderReportAction;
