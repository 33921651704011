import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Tag, Form, Input } from 'antd'
import './index.css'
import ListItemFilter from './filter'
import ListItemDetail from './item'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import Button_01 from '../../components/v2/button_01'
import Button_02 from '../../components/v2/button_02'
import { deleteCategory, editCategory, getListCategory, saveCategory } from '../../controllers/petty-cash/petty-cash'
import LabeRequireForm from '../label-required-form'
import { errorNotification, successNotification } from '../v2/notification'

const ListItemCategory = (props) => {
  const { visible, setVisible, setTriggerCategory, handleSelectCategory } = props
  const intl = useIntl()
  const [searchGroup, setSearchGroup] = useState({ status: 'active' });
  const [itemList, setItemList] = useState([])
  const [totalItem, setTotalItem] = useState(0)
  const [tableChange, setTableChange] = useState({ page: 1, pageSize: 10, orderBy: null, orderField: null });
  const [defaultModalCRUD, setDefaultModalCRUD] = useState({ type: '', data: {}, visible: false })
  const [trigger, setTrigger] = useState(false)
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'modalListCategoryCLName', defaultMessage: 'Category Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'modalListCategoryCLStatus', defaultMessage: 'Status' }),
      dataIndex: 'statusCode',
      key: 'statusCode',
      width: 150,
      sorter: true,
      render: (text, record, index) => (
        <Tag style={{ fontSize: '14px', minWidth: '100px', textAlign: 'center', borderRadius: '10px' }} color={_.get(record, 'statusColor')}>
          {_.get(record, `statusName`)}
        </Tag>
      )
    },
    {
      title: intl.formatMessage({ id: 'modalListCategoryCLCreatedBy', defaultMessage: 'Created By' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'modalListCategoryCLCreatedDate', defaultMessage: 'Created Date' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'modalListCategoryCLDeletedBy', defaultMessage: 'Deleted By' }),
      dataIndex: 'deletedBy',
      key: 'deletedBy',
      width: 150,
      sorter: true,
      render: (text, record, index) => _.get(record, 'deletedBy', "-")
    },
    {
      title: intl.formatMessage({ id: 'modalListCategoryCLDeletedDate', defaultMessage: 'Deleted Date' }),
      dataIndex: 'deletedDate',
      key: 'deletedDate',
      width: 150,
      sorter: true,
      render: (text, record, index) => _.get(record, 'deletedDate', "-")
    },
  ];

  useEffect(() => {
    if (visible) {
      GetSearchListData()
    }
  }, [visible, tableChange, trigger])

  const TriggerApply = () => {
    setTableChange({
      ...tableChange,
      page: 1
    })
    GetSearchListData()
  }


  const GetSearchListData = async () => {
    setLoading(true)
    const body = {
      "limit": _.get(tableChange, 'pageSize'),
      "page": _.get(tableChange, 'page'),
      "order:": _.get(tableChange, 'orderBy'),
      "orderBy": _.get(tableChange, 'orderField'),
      "categoryName": _.get(searchGroup, 'categoryName'),
      "statusCode": _.get(searchGroup, 'status')
    }
    const response = await getListCategory(body)
    setItemList(_.get(response, 'data.result.docs'))
    setTotalItem(_.get(response, 'data.result.total'))
    setLoading(false)
  }

  const handleClose = () => {
    setSearchGroup({ status: 'active' })
    setItemList([])
    setTriggerCategory(cur => !cur)
    setTableChange({ page: 1, pageSize: 10, orderBy: null, orderField: null })
    setVisible(false)
  }

  const onChange = async (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const handleTableChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setTableChange((prev) => ({ ...prev, orderBy: 'desc' }));
      } else {
        setTableChange((prev) => ({ ...prev, orderBy: 'asc' }));
      }
    } else {
      setTableChange((prev) => ({ ...prev, orderBy: '' }));
    }
    setTableChange((prev) => ({ ...prev, orderField: field, page: pagination.current, pageSize: pagination.pageSize }));
  };

  const handleOpenModalCategory = (type, data) => {
    setDefaultModalCRUD({
      type: type,
      data: data,
      visible: true
    })
  }


  const handleDelete = (id) => {
    Modal.confirm({
      className: 'middleConfirmModal',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteCategory(id)
          if (_.get(response, 'data.status.code') === 200) {
            successNotification(_.get(response, 'data.status.message'))
            setTrigger(cur => !cur)
          } else {
            errorNotification(_.get(response, 'data.status.message'))
          }
        } catch (error) {
          errorNotification(_.get(error, 'response.data.status.message'))
        }

      },
      onCancel() { },
    });
  };

  const handleSelect = (data) => {
    handleSelectCategory(_.get(data, '_id'))
    setVisible(false)

  }


  return (
    <Modal
      title={intl.formatMessage({ id: `modalListCategoryTitle`, defaultMessage: 'Category' })}
      visible={visible}
      onCancel={handleClose}
      centered={true}
      width={1112}
      bodyStyle={{ padding: 'unset', height: '620px' }}
      footer={[
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className='listItemTextHeader' style={{ borderRight: '1px solid #e8e8e8' }}>
            <FormattedMessage id="btnFilter" defaultMessage="Filter" />
          </div>
          <div className='listItemDividerCenter'>
            <ListItemFilter
              onChange={onChange}
              searchGroup={searchGroup}
              onClick={TriggerApply}
            />
          </div>
        </Col>
        <Col span={18}>
          <div className='listItemTextHeaderCategory' >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <FormattedMessage id="modalListCategoryTitle" defaultMessage="Category" />
              </div>
              <div>
                <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModalCategory('create', {})}>
                  <FormattedMessage id="btnCreate" defaultMessage="Create" />
                </Button_01>
              </div>
            </div>
          </div>
          <div>
            <ListItemDetail
              columns={columns}
              itemList={itemList}
              handleTableChange={handleTableChange}
              totalItem={totalItem}
              tableChange={tableChange}
              handleOpenModalCategory={handleOpenModalCategory}
              handleDelete={handleDelete}
              handleSelect={handleSelect}
              loading={loading}
            />
          </div>
        </Col>
      </Row>

      <ModalCRUDCategory
        defaultModalCRUD={defaultModalCRUD}
        setDefaultModalCRUD={setDefaultModalCRUD}
        setTrigger={setTrigger}
        setTriggerCategory={setTriggerCategory}
      />

    </Modal>
  )
}

const ModalCRUDFormCategory = (props) => {
  const { form, defaultModalCRUD, setDefaultModalCRUD, setTrigger, setTriggerCategory } = props
  const intl = useIntl()
  const { getFieldDecorator, validateFields, resetFields } = form
  const Titile = _.get(defaultModalCRUD, 'type') === 'create' ? intl.formatMessage({ id: `modalListCategoryTitleAdd`, defaultMessage: 'Add Category' }) :
    intl.formatMessage({ id: `modalListCategoryTitleEdit`, defaultMessage: 'Edit Category' })

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = {
        categoryName: _.get(values, 'categoryName'),
        statusCode: _.get(defaultModalCRUD, 'type') === 'create' ? 'active' : undefined
      }
      let response = {}
      if (_.get(defaultModalCRUD, 'type') === 'create') response = await saveCategory(body)
      if (_.get(defaultModalCRUD, 'type') === 'edit') response = await editCategory(_.get(defaultModalCRUD, 'data._id'), body)
      if (_.get(response, 'data.status.code') === 200) {
        successNotification(_.get(response, 'data.status.message'))
        setTrigger(cur => !cur)
        setTriggerCategory(cur => !cur)
        setDefaultModalCRUD({
          type: '',
          data: {},
          visible: false
        })
        resetFields()
      } else {
        errorNotification(_.get(response, 'data.status.message'))
      }
    })
  }

  const handleClose = () => {
    setDefaultModalCRUD({
      type: '',
      data: {},
      visible: false
    })
    resetFields()
  }

  return (
    <Modal
      title={Titile}
      visible={_.get(defaultModalCRUD, 'visible')}
      width={520}
      centered={true}
      onCancel={handleClose}
      bodyStyle={{ padding: 'unset' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_02>,
        <Button_01 style={{ margin: 'unset' }} key="draft" type="primary" btnsize="wd_px" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>
      ]}
    >
      <Form form={form} layout="vertical" className="pettyCashModalCategoryForm" style={{ padding: '24px' }}>
        <Row gutter={[16, 8]} id='Form-PettyCashName'>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalListCategoryName', defaultMessage: 'Category Name' })}
                  req={true}
                />
              }
            >
              {getFieldDecorator('categoryName', {
                initialValue: _.get(defaultModalCRUD, 'type') === 'edit' ? _.get(defaultModalCRUD, 'data.name') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalListCategoryNameVL', defaultMessage: 'Please Enter Category Name' }),
                  },
                ],
              }
              )(<Input
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({ id: 'modalListCategoryNamePH', defaultMessage: 'Enter Petty Category Name' })}
              />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModalCRUDCategory = Form.create({
  name: 'modal_form',
  mapPropsToFields() { },
})(ModalCRUDFormCategory);


export default ListItemCategory
