import { Card, Col, Icon, Input, Modal, Row, Tabs } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { ReciveItemPOContext } from '../../reciveitempo-context';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import TableRecive from './table';
import { getItemReceivelist } from '../../../../controllers/receice-po/receive';

const ModalReciveItemPO = (props) => {
  const {
    visibleLotNoTable,
    setvisibleLotNoTable,
    requestDetailData,
    handleCreate,
    setItemData,
    podata,
    setReciveQTY,
    popast,
    setPOPast,
    itemDataList,
    setItemDataList,
    lotNo,
  } = props;
  const { state, setState, fnc } = useContext(ReciveItemPOContext);
  const [paginationPage, setPaginationPage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [event, setEvent] = useState(false);
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState(0);
  const intl = useIntl();

  console.log('POPOPOP', podata);

  useEffect(() => {
    async function getItem() {
      try {
        const body = {
          poId: _.get(podata, 'poId'),
          searchItem: '',
          pageNumber: page,
          limit: size,
        };

        const response = await getItemReceivelist(body);

        const reciveList = _.get(response, 'data.data.itemList');

        if (_.size(lotNo) > 0) {

          const tempsetWh = 
          _.map(reciveList, (i, index) => {
            const filterR = _.filter(lotNo,(m) => {
              // return i.itemMasterId === _.get(m, 'itemMasterId') && i.uomId === _.get(m, 'uomId')
              return i.itemPoId === _.get(m, 'itemPoId') 
            })

            const sumQty = _.sumBy(filterR, 'qty') + _.get(i, 'received')

            if (_.size(filterR) > 0) {
              i.receiveQty =  sumQty + ' / ' + _.get(i, 'qty') + ' ' + _.get(i, 'uomQtyTxt');
              i.sumQty = sumQty;
            }
            else {
              i.sumQty = 0;
            }

            console.log('filterR',filterR,'/:',lotNo,'/sum:',sumQty,'/',_.size(filterR));

            if (sumQty < _.get(i,'qty')) {
              return i
            }
            else {
              return []
            }

          });

          const filterRecive = _.filter(tempsetWh, (item) => {
            return _.size(item) !== 0 ;
          });



          console.log('tempsetWh', tempsetWh, '/', filterRecive,);
          setItemDataList(filterRecive);
        }
        else {
          setItemDataList(response.data.data.itemList);
        }

        setPOPast(_.get(podata, 'poId'));
        
        setTotal(response.data.data.totalItem);
      } catch (error) {}
    }
    // if (visibleLotNoTable && _.get(podata, 'poId') !== popast) {
    if (visibleLotNoTable) {
      getItem();
    }
  }, [visibleLotNoTable]);

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const TitleModal = () => {
    return (
      <div>
        {/* <FormattedMessage id="recivePOTitleReceiveItem" defaultMessage="Receive Item" />・{_.get(viewData, 'ticketNo')} */}
        <FormattedMessage id="recivePOTitleReceiveItem" defaultMessage="Receive Item" />・ {_.get(podata, 'poNo')} ・{' '}
        {_.get(podata, 'vendorSupplierName')}
      </div>
    );
  };

  return (
    <Modal
      visible={visibleLotNoTable}
      onCancel={() => setvisibleLotNoTable(false)}
      centered={true}
      title={<TitleModal />}
      width={'48%'}
      style={{ marginTop: '15px' }}
      bodyStyle={{ padding: '0px 0px', height: 'calc(100vh - 230px)' }}
      footer={[
        <div style={{ paddingTop: '10px' }}>
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => setvisibleLotNoTable(false)} style={{ marginTop: '10px' }}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_01>
        </div>,
      ]}
    >
      <Row>
        <Col span={14}></Col>
        <Col span={10}>
          <div className="action-report-div-left" style={{ padding: '16px 22px 12px 26px' }}>
            <Input
              className="action-report-input-7"
              placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
              allowClear
              prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
              onChange={(e) => {}}
              autoComplete="off"
            />
          </div>
        </Col>
      </Row>
      <TableRecive
        data={itemDataList}
        handleChangePage={handleChangePage}
        handleChangeSize={handleChangeSize}
        total={total}
        setSort={setSort}
        isLoading={isLoading}
        page={page}
        handleCreate={handleCreate}
        setItemData={setItemData}
        setReciveQTY={setReciveQTY}
      />
    </Modal>
  );
};

export default ModalReciveItemPO;
