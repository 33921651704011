import React, { useState, useMemo } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  AutoComplete,
} from 'antd';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  successNotification,
  errorNotification,
} from '../v2/notification';
import _, { debounce } from 'lodash';
import './css/index.css';
import httpClient from '../axiosClient';
import getSearchLocation from '../../function/search-location/getLocation';
import Map from './map-location';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const ModalMapLocation = React.memo((props) => {
  const intl = useIntl();
  const { visible, form, setVisible, customerId, customerName, setTrigger, setAddressId } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const comCode = localStorage.getItem('comCode');
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);
  const [addressArea, setAddressArea] = useState([]);
  const [latlngTo, setLatlngTo] = useState({});
  const [valueAddress, setValueAddress] = useState("");

  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await getSearchLocation({ name: fieldChang });
    setAddressArea(newData);
  }, 200);

  const handleSelect = (key, option) => {
    if (isNaN(key) == false) {
      form.setFieldsValue({ ['location']: option.props.children });
      form.setFieldsValue({ ['description']: option.props.children });
      setPosition({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setCenter({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setLatlngTo({ lat: addressArea[key].lat, lng: addressArea[key].lng });
    } else {
      setPosition(latlng);
      setCenter(latlng);
      form.resetFields();
      form.setFieldsValue({
        ['location']: undefined
      });
    }
  };

  useMemo(() => {

    form.setFieldsValue({
      ['location']: valueAddress
    });
    form.setFieldsValue({
      ['description']: valueAddress
    });
  }, [valueAddress]);


  const children = addressArea.map(item =>
    <Option key={item.key}>
      {item.address}
    </Option>
  );


  const handleCreate = () => {
    validateFields(async (err, values) => {

      if (err) {
        return;
      }

      const body = {
        customerId: customerId,
        // customerId:"2f8b7c9b-f3c1-4e02-ba1f-169d2283de06",
        addressName: values.locationName,
        customerName: customerName,
        contactName: undefined,
        fullAddress: values.location,
        address: values.location,
        subDistrict: undefined,
        district: undefined,
        province: undefined,
        postCode: undefined,
        lat: latlngTo.lat,
        lng: latlngTo.lng,
        description: values.description,
      }

      try {
        const response = await httpClient.post(
          `/v3/business-partner/manager/company/${comCode}/customer-address-books`,
          body,
        );

        if (response.status == 200) {
          resetFields();
          setVisible(false);
          setLatlngTo({});
          setValueAddress("");
          setTrigger(current => !current);
          setAddressId(response.data.data.customerAddressBookId)
          successNotification(response.data.status.message);

        } else {
          resetFields();
          setVisible(false);
          setLatlngTo({});
          setValueAddress("");

        }

      } catch (error) {
        resetFields();
        setVisible(false);
        setLatlngTo({});
        setValueAddress("");
        errorNotification(error.response.data.status.message);
      }






    });
  };

  const handleCreateCancel = () => {
    resetFields();
    setVisible(false);
    setLatlngTo({});
    setValueAddress("");

  }


  return (
    <Modal
      className="create-task-modal"
      title={intl.formatMessage({ id: 'ModalLocationHeaderCreateToLocation', defaultMessage: 'Select To Location' })}
      visible={visible}
      onCancel={handleCreateCancel}
      onOk={handleCreate}
      centered
      width={824}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCreateCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleCreate}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationName', defaultMessage: 'Location Name' })} req={true} />}
        >
          {getFieldDecorator('locationName', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationValidateLocationName', defaultMessage: 'Please enter location name' })
              },
            ],
          })(
            <Input placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderLocationName', defaultMessage: 'Enter Location Name' })} autoComplete="off" />
          )}
        </Form.Item>

        <Form.Item label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationAddress', defaultMessage: 'Address' })} req={true} />}>
          {getFieldDecorator(
            'description', {
            initialValue: valueAddress && valueAddress !== undefined ? valueAddress : undefined,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationLocationValidateAddress', defaultMessage: 'Please enter address' })
              },
            ],
          }
          )(
            <Input placeholder={intl.formatMessage({ id: 'ModalLocationLocationPlaceholderAddress', defaultMessage: 'Enter Address' })} autoComplete="off" ></Input>
          )}
        </Form.Item>

        <Form.Item
          label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationMapLocation', defaultMessage: 'Map Location' })} req={true} />}
        >
          {getFieldDecorator('location', {
            initialValue: valueAddress && valueAddress !== undefined ? valueAddress : undefined,

            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationLocationValidateMapLocation', defaultMessage: 'Please enter map location' })
              },
            ],
          })(
            <AutoComplete
              placeholder={intl.formatMessage({ id: 'ModalLocationLocationPlaceholderMapLocation', defaultMessage: 'Enter Map Location' })}
              dataSource={addressArea}
              onSearch={handleSearchLocation}
              onSelect={handleSelect}
              filterOption={false}
            >
              {children}
            </AutoComplete>
          )}
        </Form.Item>


        <div>
          <Map
            idMap={1}
            mapModal={1}
            // location={
            //   stateTask.toLocation
            //     ? stateTask.toLocation[0]
            //     : []
            // }
            position={position}
            center={center}
            setPosition={setPosition}
            setCenter={setCenter}
            setLatlngTo={setLatlngTo}
            setValueAddress={setValueAddress}
          />
        </div>
      </Form>
    </Modal>
  )
})

const ModalMap = Form.create({
  name: 'modal_form',
})(ModalMapLocation);

export default React.memo(ModalMap);