import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Modal, Spin } from 'antd';
import Vehicle from './vehicle';
import Details from './details';
import {
  getVehicle,
  getByid,
  saveEditVehical,
  reCalculate,
  getWaitingOrder,
  addItem,
  removeItem,
  confirmOrder,
  getByidExport,
  getTeamGroupDriver,
  replanningOrder,
  planningOrder,
  replanDevliveryOrder,
  clearDevliveryOrder,
  checkReConfirm,
  reConfirmOrder,
  checkConfirmOrder,
  cancelTrip,
} from '../../controllers/load-and-route/api';
import { PlanningContext } from './context';
import EditVehicel from '../../components/load-and-route-component/modal-vehicel-new/index';
import WaitingOrder from '../../components/load-and-route-component/modal-waiting-order/index';
import AddOrRemove from '../../components/load-and-route-component/modal-add-or-remove/index';
import _, { debounce, set } from 'lodash';
import ModalAddItem from '../../components/load-and-route-component/modal-add-item';
import ModalRemoveItem from '../../components/load-and-route-component/modal-remove-item/index';
import { useIntl } from 'react-intl';
import WaitingOrderLicenplate from '../../components/load-and-route-component/modal-waiting-order-licensplate/index';
import { successNotification, errorNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import ModalShippingDate from '../../components/load-and-route-component/modal-shipping-date';
import ModalPreviewOrder from '../../components/load-and-route-component/modal-preview-order';
import { useDebounce } from '../../controllers/debounce';
import CreateOrderDO from '../../components/creater-order-load-planning';

const comCode = localStorage.getItem('comCode');
let count = 0;

export default () => {
  const intl = useIntl();
  const addItemRef = useRef();
  const vehicleScrollRef = useRef();
  const [vehicle, setVehicle] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  const [vehicleItem, setVehicleItem] = useState();
  const [toggle, setToggle] = useState(false);
  const [memberPageLoading, setMemberPageLoading] = useState(false);
  const [memberPageHasMore, setMemberPageHasMore] = useState(true);

  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [page, setPage] = useState(0);
  const [pageFilter, setPageFilter] = useState(0);
  const [limit, setLimit] = useState(10);
  const [visibleEditVehicle, setVisibleEditVehicle] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [searchMin, setSearchMin] = useState();
  const [searchMax, setSearchMax] = useState();
  const [arrLoaded, setArrLoaded] = useState([]);
  const [loaded, setLoaded] = useState([]);
  const [active, setActive] = useState();
  const [activeWaiting, setActiveWaiting] = useState();
  const [dataById, setDataById] = useState();
  const [disbleButton, setDisbleButton] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [isLoadmoreFilter, setIsLoadmoreFilter] = useState(true);
  const [isLoadmore, setIsLoadmore] = useState(true);

  const [dataRow, setDataRow] = useState();
  const [dataRowRemove, setDataRowRemove] = useState();
  const [dataRowAddLicenplate, setdataRowAddLicenplate] = useState();
  const [visibleWaitingOrder, setVisibleWaitingOrder] = useState(false);
  const [visibleWaitingOrderLicanPlate, setVisibleWaitingOrderLicanPlate] = useState(false);
  const [visibleAddOrRemove, setVisibleAddOrRemove] = useState(false);
  const [visibleAddItem, setVisibleAddItem] = useState(false);
  const [visibleRemoveItem, setVisibleRemoveItem] = useState(false);
  const [triggerEditVehicle, setTriggerEditVehicle] = useState(false);
  const [visiblePreviewOrder, setVisiblePreviewOrder] = useState(false);

  const [licenseplateListMaster, setLicensePlateListMaster] = useState([]);
  const [licenseplateList, setLicensePlateList] = useState([]);
  const [licenseplatePage, setLicensePlatePage] = useState(0);
  const [licenseplatePageSize, setLicensePlatePageSize] = useState(10);
  const [licenseplateLoading, setLicensePlateLoading] = useState(false);
  const [licenseplateSelect, setLicensePlateSelect] = useState();
  const [licenseplateSearch, setLicensePlateSearch] = useState();
  const [licenseplateSearchPage, setLicensePlateSearchPage] = useState(0);
  const [licenseplateSearchPageSize, setLicensePlateSearchPageSize] = useState(10);

  const [isLicensePlateLoadMore, setIsLicensePlateLoadMore] = useState(true);
  const [isLicensePlateSearchLoadMore, setIsLicensePlateSearchLoadMore] = useState(true);
  const [triggerAll, setTriggerAll] = useState(false);
  const [shippingDate, setShippingDate] = useState(moment());
  const [shippingDateOrder, setShippingDateOrder] = useState(moment());
  const [openShippingDate, setOpenShippingDate] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingOrder, setIsWaitingOrder] = useState(true);
  const [isDragorder, setIsDragorder] = useState(false);
  const [dragOrder, setDragOrder] = useState();
  const [markDetail, setMarkDetail] = useState([]);
  const [loadmark, setLoadmark] = useState(false);
  const [selectMark, setSelectMark] = useState(false);
  const [reConfirmStatus, setReConfirmStatus] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const debouncSearchTerm = useDebounce(searchTerm, 500);
  const [hasMore, setHasMore] = useState(true);
  const [showData, setShowData] = useState([]);

  const [confirmInterval, setConfirmInterval] = useState(false);
  const [messconfirmInterval, setMessConfirmInterval] = useState(false);
  const [reconfirmInterval, setReConfirmInterval] = useState(false);
  const [confirmGetData, setConfirmGetData] = useState(false);
  const [pendingCheck, setPendingCheck] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [confirmCheck, setConfirmCheck] = useState(false);

  const [cancelTripInterval, setCancelTripInterval] = useState(false);

  //-----------------------------------------------
  const [visibleCreateOrder, setVisibleCreateOrder] = useState(false);
  //-----------------------------------------------
  const [triggerCreate, setTriggerCreate] = useState(false);

  const dataStatus = [
    {
      statusName: intl.formatMessage({ id: 'planningFilterWaiting', defaultMessage: 'Waiting' }),
      statusCode: 'waiting',
    },
    {
      statusName: intl.formatMessage({ id: 'planningFilterDelivered', defaultMessage: 'Delivered' }),
      statusCode: 'sending',
    },
  ];

  const column =
    comCode === 'PPP'
      ? [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) => index + 1,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
            dataIndex: 'orderNo',
            key: 'orderNo',
            fixed: 'left',
            width: 180,
            sorter: (a, b) => a.orderNo - b.orderNo,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
            dataIndex: 'Customer',
            key: 'Customer',
            width: 220,
            sorter: (a, b) => a.Customer - b.Customer,
            sortDirections: ['descend', 'ascend'],
          },
          // {
          //   title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
          //   dataIndex: 'productName',
          //   key: 'productName',
          //   width: 240,
          //   sorter: (a, b) => a.productName - b.productName,
          //   sortDirections: ['descend', 'ascend'],
          //   ellipsis: true,
          // },
          {
            title: intl.formatMessage({ id: `planningColumnParcel`, defaultMessage: 'parcel' }),
            dataIndex: 'parcel',
            key: 'parcel',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.parcel - b.parcel,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <NumberFormat value={record.parcel} displayType={'text'} thousandSeparator={true} />,
          },
          // {
          //   title: intl.formatMessage({ id: `planningColumnQty`, defaultMessage: 'Qty' }),
          //   dataIndex: 'qty',
          //   key: 'qty',
          //   width: 100,
          //   sorter: (a, b) => a.qty - b.qty,
          //   sortDirections: ['descend', 'ascend'],
          //   ellipsis: true,
          //   render: (text, record, index) => <NumberFormat value={record.qty} displayType={'text'} thousandSeparator={true} />,
          // },
          {
            title: intl.formatMessage({ id: `planningColumnWeight(kg)`, defaultMessage: 'Weight (kg)' }),
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.weight} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumncontactName`, defaultMessage: 'Contact' }),
            dataIndex: 'contactName',
            key: 'contactName',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => a.contactName - b.contactName,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnLoaded`, defaultMessage: 'Loaded (%)' }),
            dataIndex: 'loadedView',
            key: 'loadedView',
            width: 150,
            sorter: (a, b) => a.loadedView - b.loadedView,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
          },

          // {
          //   title: intl.formatMessage({ id: `planningColumnTaskNo`, defaultMessage: 'Task No.' }),
          //   dataIndex: 'taskNo',
          //   key: 'taskNo',
          //   width: 200,
          //   sorter: (a, b) => a.taskNo - b.taskNo,
          //   sortDirections: ['descend', 'ascend'],
          //   ellipsis: true,
          // },
          {
            title: intl.formatMessage({ id: `planningColumnAddressSource`, defaultMessage: 'Source' }),
            dataIndex: 'sourceAddress',
            key: 'sourceAddress',
            width: 240,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressDestination`, defaultMessage: 'Destination' }),
            dataIndex: 'address',
            key: 'address',
            width: 240,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryDate`, defaultMessage: 'Delivery Date' }),
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            width: 150,
            ellipsis: true,
          },

          {
            title: intl.formatMessage({ id: `ordersTitlefilter`, defaultMessage: 'Delivery Time' }),
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
            width: 150,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnReferenceNo`, defaultMessage: 'Reference No.' }),
            dataIndex: 'referenceNo',
            key: 'referenceNo',
            ellipsis: true,
            sorter: (a, b) => a.referenceNo - b.referenceNo,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
          },
        ]
      : [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) => index + 1,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
            dataIndex: 'orderNo',
            key: 'orderNo',
            fixed: 'left',
            ellipsis: true,
            width: 170,
            sorter: (a, b) => a.orderNo - b.orderNo,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderType`, defaultMessage: 'Order Type' }),
            dataIndex: 'orderType',
            key: 'orderType',
            ellipsis: true,
            width: 150,
          },
          {
            title: intl.formatMessage({ id: `planningColumnTaskNo`, defaultMessage: 'Task No' }),
            dataIndex: 'taskNo',
            key: 'taskNo',
            ellipsis: true,
            sorter: (a, b) => a.taskNo - b.taskNo,
            sortDirections: ['descend', 'ascend'],
            width: 150,
            render: (text, record, index) => (
              <>
                {record.taskNo === '' && pendingCheck === true
                  ? intl.formatMessage({ id: `monitorTabPendingTitle`, defaultMessage: 'Pending' })
                  : record.taskNo}
              </>
            ),
          },
          {
            title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
            dataIndex: 'Customer',
            key: 'Customer',
            ellipsis: true,
            sorter: (a, b) => a.Customer - b.Customer,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnParcel`, defaultMessage: 'parcel' }),
            dataIndex: 'parcel',
            key: 'parcel',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.parcel - b.parcel,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <NumberFormat value={record.parcel} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnLoaded`, defaultMessage: 'Loaded (%)' }),
            dataIndex: 'loadedView',
            key: 'loadedView',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => a.loadedView - b.loadedView,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnWeight(kg)`, defaultMessage: 'Weight (kg)' }),
            dataIndex: 'weight',
            key: 'weight',
            width: 140,
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.weight} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `quotationLabelCustomerContact`, defaultMessage: 'Contact' }),
            dataIndex: 'contactName',
            key: 'contactName',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => a.contactName - b.contactName,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `reportTaskReportViewTableContactPhone`, defaultMessage: 'Contact Phone' }),
            dataIndex: 'contactPhone',
            key: 'contactPhone',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => a.contactPhone - b.contactPhone,
            sortDirections: ['descend', 'ascend'],
          },
          // {
          //   title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
          //   dataIndex: 'productName',
          //   key: 'productName',
          //   ellipsis: true,
          //   width: 220,
          //   sorter: (a, b) => a.productName - b.productName,
          //   sortDirections: ['descend', 'ascend'],
          //   render: (text, record, index) => <span title={record.productName}>{_.truncate(record.productName, 20)}</span>,
          // },

          {
            title: intl.formatMessage({ id: `planningColumnAddressDestination`, defaultMessage: 'Destination' }),
            dataIndex: 'address',
            key: 'address',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryDate`, defaultMessage: 'Delivery Date' }),
            dataIndex: 'shippingDate',
            key: 'shippingDate',
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `ordersTitlefilter`, defaultMessage: 'Delivery Time' }),
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
            width: 150,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressSource`, defaultMessage: 'Source' }),
            dataIndex: 'sourceAddress',
            key: 'sourceAddress',
            width: 250,
            sorter: true,
            ellipsis: true,
          },

          {
            title: intl.formatMessage({ id: `planningColumnReferenceNo`, defaultMessage: 'Reference No.' }),
            dataIndex: 'referenceNo',
            key: 'referenceNo',
            ellipsis: true,
            sorter: (a, b) => a.referenceNo - b.referenceNo,
            sortDirections: ['descend', 'ascend'],
          },

          {
            title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderType`, defaultMessage: 'Order Type' }),
            dataIndex: 'orderType',
            key: 'orderType',
            ellipsis: true,
          },
        ];

  const [deliveryGroupList, setDeliveryGroupList] = useState([]);
  const [selectGroup, setSelectGroup] = useState();
  const [selectGroupPreview, setSelectGroupPreview] = useState();

  //-------------------------------function loadmore left------------------------------------------------//
  useEffect(() => {
    const getTeamApi = async () => {
      const response = await getTeamGroupDriver(moment(shippingDate).format('YYYY-MM-DD'));
      setDeliveryGroupList(_.get(response.data, 'data') || []);
    };

    getTeamApi();
  }, [shippingDate, triggerAll]);

  useEffect(() => {
    const callApi = async () => {
      const body = {
        search: debouncSearchTerm,
        searchBy: 'all',
        status: selectStatus ? selectStatus : 'all',
        loaded: [],
        orderType: 'desc',
        orderBy: '',
        paging: page + 1,
        rowsPerPages: 1000,
        group: selectGroup,
        shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
      };
      const results = await getVehicle(body);
      setVehicle(_.uniqBy(results, 'id'));
      setVehicleList(_.uniqBy(results, 'id'));
      setLoadmark(true);
      setShowData(_.slice(_.uniqBy(results, 'id'), 0, 10));
      // setLicensePlateList(_.uniqBy(results, 'id'))
      setLoading(false);
      setIsLoadmore(_.size(results) > 0 ? true : false);
    };

    if (
      !debouncSearchTerm &&
      !selectStatus &&
      !searchMax &&
      !searchMin &&
      searchMax !== 0 &&
      searchMin !== 0 &&
      selectGroup &&
      shippingDate
    ) {
      callApi();
    }
  }, [page, debouncSearchTerm, selectStatus, searchMax, searchMin, triggerAll, active, selectGroup, shippingDate, confirmGetData]);

  useEffect(() => {
    const callApi = async () => {
      if (!debouncSearchTerm && !selectStatus && !searchMax && !searchMin && searchMax !== 0 && searchMin !== 0) {
        setVehicleList(vehicle);
        setShowData(vehicle, 0, 10);
        setLoading(false);
      } else {
        let arrMaxMin = [];
        if (searchMin >= 0 && searchMax >= 0) {
          arrMaxMin[0] = searchMin;
          arrMaxMin[1] = searchMax;
        } else if (searchMin >= 0) {
          arrMaxMin[0] = searchMin;
          arrMaxMin[1] = searchMin;
        } else if (searchMax >= 0) {
          arrMaxMin[0] = searchMax;
          arrMaxMin[1] = searchMax;
        }
        const body = {
          search: debouncSearchTerm,
          searchBy: 'all',
          status: selectStatus ? selectStatus : 'all',
          loaded: arrMaxMin,
          orderType: 'desc',
          orderBy: '',
          paging: pageFilter + 1,
          rowsPerPages: 1000,
          group: selectGroup,
          shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
        };
        const results = await getVehicle(body);
        setVehicleList(_.uniqBy(results, 'id'));
        setShowData(_.slice(_.uniqBy(results, 'id'), 0, 10));
        setLoadmark(true);
        setLoading(false);
        setIsLoadmoreFilter(_.size(results) > 0 ? true : false);
      }
    };

    if (debouncSearchTerm || selectStatus || searchMax >= 0 || searchMin >= 0 || shippingDate) {
      callApi();
    }
  }, [debouncSearchTerm, pageFilter, selectStatus, searchMax, searchMin, triggerAll, active, selectGroup, shippingDate, confirmGetData]);

  useEffect(() => {
    setIsLoading(true);
    const handleGetById = async () => {
      if (active) {
        const response = await getByid(
          active,
          orderSort,
          fieldSort,
          moment(shippingDate).format('YYYY-MM-DD'),
          _.get(vehicleItem[0], 'colorCode'),
          selectGroup
        );
        if (response.status === 200) {
          const bodycheck = {
            tripId: _.get(response, 'data.data[0].tripId'),
          };

          const body = {
            tripId: _.get(response, 'data.data[0].tripId'),
          };

          try {
            const responseRe = await checkReConfirm(body);
            // console.log('Recon', _.get(response, 'data.data.enableReconfirm'));
            if (_.get(responseRe, 'data.status.code') === 200) {
              setSpinning(false);
              // setLoadButton(false)
              setReConfirmStatus(_.get(responseRe, 'data.data.enableReconfirm'));
            }

            if (_.get(response, 'data.data[0].tripNo') !== '') {
              const check = await checkConfirmOrder(bodycheck);
              setPendingCheck(true);
              console.log('Test Button Confirm', _.get(response, 'data.data[0].sending'), '|check ', _.get(check, 'data.data'));
              if (
                _.get(response, 'data.data[0].sending') === 'vehicle_sending' &&
                _.get(check, 'data.data.confirmComplete') === false &&
                _.get(responseRe, 'data.data.enableReconfirm') === false
              ) {
                setLoadButton(true);
              } else {
                setLoadButton(false);
                setPendingCheck(false);
              }
            } else {
              setLoadButton(false);
              setPendingCheck(false);
            }

            setDataById(_.get(response, 'data.data[0]'));
            console.log('carreturn', _.get(response, 'data.data[0]'));
            setDisbleButton(_.get(response, 'data'));

            // const body = {
            //   shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
            //   assetId: active,
            //   tripNo: _.get(response, 'data.data[0].tripNo'),
            // };
          } catch (error) {}
          setIsLoading(false);
        }
      }
    };
    handleGetById();
  }, [active, triggerEditVehicle, triggerAll, fieldSort, orderSort, shippingDate,triggerCreate]);

  useEffect(() => {
    const handleGetById = async () => {
      if (active) {
        const response = await getByid(
          active,
          orderSort,
          fieldSort,
          moment(shippingDate).format('YYYY-MM-DD'),
          _.get(vehicleItem[0], 'colorCode'),
          selectGroup
        );
        if (response.status === 200) {
          setDataById(_.get(response, 'data.data[0]'));
          console.log('carStatus', _.get(response, 'data.data[0]'));
          setDisbleButton(_.get(response, 'data'));
          const body = {
            tripId: _.get(response, 'data.data[0].tripId'),
          };

          // if (_.get(response, 'data.data[0].sending') === 'vehicle_waiting' && _.get(response, 'data.data[0].tripNo') !== '' ) {
          //   setLoadButton(true)
          // }

          try {
            const response = await checkReConfirm(body);

            if (_.get(response, 'data.status.code') === 200) {
              setReConfirmStatus(_.get(response, 'data.data.enableReconfirm'));
            }
          } catch (error) {}
        }
      }
    };
    if (pendingCheck === true) {
      handleGetById();
    }
  }, [confirmGetData]);

  useEffect(() => {
    const handleGetById = async () => {
      if (activeWaiting) {
        const response = await getByid(activeWaiting, orderSort, fieldSort);
        if (response.status === 200) {
          setDataById(_.get(response, 'data.data[0]'));
          setDisbleButton(_.get(response, 'data'));
        }
      }
    };
    handleGetById();
  }, [activeWaiting]);

  useEffect(() => {
    const handleGetById = async () => {
      setSpinning(true);
      for (let index = 0; index < vehicleList.length; index++) {
        console.log('vechicelColor', vehicleList[index].colorCode);
        const response = await getByid(
          vehicleList[index].id,
          orderSort,
          fieldSort,
          moment(shippingDate).format('YYYY-MM-DD'),
          vehicleList[index].colorCode,
          selectGroup
        );
        if (response.status === 200) {
          setMarkDetail((detail) => [...detail, _.get(response, 'data.data[0].orderDetail')]);
          if (_.size(vehicleList) - 1 === index) setSpinning(false);
        }
      }
      setLoadmark(false);
    };
    if (loadmark === true && visiblePreviewOrder === true) {
      handleGetById();
    }
  }, [visiblePreviewOrder, loadmark, vehicleList]);

  console.log('loadMark', loadmark);

  // useEffect(() => {
  //   const handleGetById = async () => {
  //     if (active) {
  //       const response = await getByid(active, orderSort, fieldSort);
  //       if (response.status === 200) {
  //         setDataById(_.get(response, 'data.data[0]'))
  //       }
  //     }
  //   }
  //   handleGetById();
  // }, [active]);

  // useEffect(() => {
  //   if (dataById) {
  //     let arrVehicle = [...vehicleList];
  //     const findIndex = vehicleList.findIndex(x => x.id === _.get(dataById, 'assetId'));
  //     if (findIndex > -1) {
  //       arrVehicle.forEach((item, index, array) => arrVehicle[findIndex] =
  //       {
  //         id: array[findIndex].id,
  //         assetNo: array[findIndex].assetNo,
  //         assetClassName: array[findIndex].assetClassName,
  //         icon: _.get(dataById, 'icon'),
  //         borrower: array[findIndex].borrower,
  //         loaded: _.get(dataById, 'loadedView2'),
  //         orderTotal: _.get(dataById, 'orderTotal'),
  //         itemTotal: _.get(dataById, 'itemTotal'),
  //         maxCapacity: array[findIndex].maxCapacity,
  //         maxWeight: array[findIndex].maxWeight,
  //         height: array[findIndex].height,
  //         width: array[findIndex].width,
  //         length: array[findIndex].length,
  //         planStartDate: array[findIndex].planStartDateFormat,
  //         planDueDate: array[findIndex].planDueDateFormat,
  //       });
  //       setVehicleList(arrVehicle)
  //     }
  //   }
  // }, [dataById]);

  const onLoadMore = (e) => {
    // let target = e.target;
    // if (!loading && (_.ceil(target.scrollTop + target.offsetHeight) >= target.scrollHeight)) {
    //   setLoading(true);
    //   if ((searchTerm || selectStatus || searchMax >= 0 || searchMin >= 0)) {
    //     setPageFilter(prevPage => prevPage + 1)
    //   } else {
    //     setPage(prevPage => prevPage + 1)
    //   }
    // }
  };

  useEffect(() => {
    vehicleScrollRef && vehicleScrollRef.current.addEventListener('scroll', onLoadMore);
    return () => vehicleScrollRef.current.removeEventListener('scroll', onLoadMore);
  }, [debouncSearchTerm, selectStatus, searchMax, searchMin]);

  const handleSearchAll = (value) => {
    setVehicleList([]);
    setShowData([]);
    if (value) {
      setSearchTerm(value);
      setLoading(true);
      setIsLoadmore(true);
      setPageFilter(0);
      setHasMore(true);
    } else {
      setSearchTerm(undefined);
      setVehicleList(vehicle);
      setShowData(vehicle, 0, 10);
      setLoading(true);
      setIsLoadmore(true);
      setPageFilter(0);
      setHasMore(true);
    }
  };

  const handleSearchMin = (value) => {
    if (value === null || value === '') {
      setVehicleList([]);
      setShowData([]);
      setSearchMin(undefined);
      setHasMore(true);
    } else if (value !== searchMin) {
      setVehicleList([]);
      setShowData([]);
      setSearchMin(_.toFinite(value));
      setHasMore(true);
    }

    // setLoading(true)
    setIsLoadmore(true);
    setPageFilter(0);
  };

  const handleSearchMax = (value) => {
    if (value === null || value === '') {
      setVehicleList([]);
      setShowData([]);
      setSearchMax(undefined);
      setHasMore(true);
    } else if (value !== searchMax) {
      setVehicleList([]);
      setShowData([]);
      setSearchMax(_.toFinite(value));
      setHasMore(true);
    }

    // setLoading(true)
    setIsLoadmore(true);
    setPageFilter(0);
  };

  const handleSelectStatus = (val) => {
    setVehicleList([]);
    setShowData([]);
    if (val !== undefined) {
      setSelectStatus(val);
      // setLoading(true)
      setIsLoadmore(true);
      setPageFilter(0);
      setHasMore(true);
    } else {
      setSelectStatus(undefined);
      // setVehicleList(vehicle);
      // setLoading(true)
      setIsLoadmore(true);
      setPageFilter(0);
      setHasMore(true);
    }
  };

  //-------------------------------function loadmore left------------------------------------------------//

  useEffect(() => {
    const getData = async () => {
      let payload = {
        search: licenseplateSearch,
        status: 'waiting',
        loaded: [],
        orderType: 'desc',
        orderBy: '',
        paging: licenseplatePage + 1,
        rowsPerPages: 1000,
        shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
        group: selectGroup,
      };

      const results = await getVehicle(payload);
      const licenseplates = _.uniqBy(_.concat(licenseplateList, results), 'id');
      setLicensePlateList(results);
      setLicensePlateListMaster(results);
      setLicensePlateLoading(false);
      setIsLicensePlateLoadMore(_.size(results) > 0 ? true : false);
    };

    // if (visibleAddItem === true) {
    getData();
    // }
  }, [licenseplatePage, triggerEditVehicle, visibleEditVehicle, visibleAddItem]);

  useEffect(() => {
    const getData = async () => {
      if (!licenseplateSearch) {
        setLicensePlateList(licenseplateListMaster);
        setLicensePlateLoading(false);
      } else {
        let payload = {
          search: licenseplateSearch,
          searchBy: 'assetNo',
          status: 'waiting',
          loaded: [],
          orderType: 'desc',
          orderBy: '',
          paging: licenseplateSearchPage + 1,
          rowsPerPages: 1000,
          shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
        };
        const results = await getVehicle(payload);
        const licenseplates = _.concat(licenseplateList, results);
        setLicensePlateList(results);
        setLicensePlateLoading(false);
        setIsLicensePlateSearchLoadMore(_.size(results) > 0 ? true : false);
      }
    };

    if (isLicensePlateSearchLoadMore) {
      getData();
    }
  }, [licenseplateSearch, licenseplateSearchPage]);

  useEffect(() => {
    if (_.get(dataById, 'tripId')) {
      let intervalId = setInterval(async () => {
        const body = {
          tripId: _.get(dataById, 'tripId'),
        };
        const check = await checkConfirmOrder(body);
        setConfirmGetData((current) => !current);

        if (_.get(check.data.data, 'confirmComplete') === true) {
          setLoadButton(false);
          successNotification(check.data.status.message);
          clearInterval(intervalId);
          count = 0;
        } else {
          setConfirmCheck(true);
          // setLoadButton(true);
        }
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [confirmInterval]);

  useEffect(() => {
    if (_.get(dataById, 'tripId')) {
      let intervalId = setInterval(async () => {
        const body = {
          tripId: _.get(dataById, 'tripId'),
        };
        const check = await checkConfirmOrder(body);
        setConfirmGetData((current) => !current);
        if (_.get(check.data.data, 'confirmComplete') === true) {
          successNotification(check.data.status.message);
          setLoadButton(false);
          clearInterval(intervalId);
          setSpinning(false);
        }
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [reconfirmInterval]);

  const handleLicensePlatePopupScroll = (e) => {
    // let target = e.target;
    // if (!licenseplateLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
    //   setLicensePlateLoading(true);
    //   if (licenseplateSearch) {
    //     setLicensePlateSearchPage(licenseplateSearchPage + 1);
    //   } else {
    //     setLicensePlatePage(licenseplatePage + 1);
    //   }
    // }
  };

  const handleLicensePlateChange = (value) => {
    if (!value) {
      setPage(0);
      setLicensePlateSelect();
    }
  };

  const handleLicensePlateChangeSelect = (value) => {
    if (value !== licenseplateSelect) {
      setLoading(true);
      setPage(0);
      setLicensePlateSearch();
      setLicensePlateList(licenseplateListMaster);
      setLicensePlateSelect(value);
    }
  };

  const handleLicensePlateChangeSearch = (value) => {
    setLicensePlateLoading(true);
    setIsLicensePlateSearchLoadMore(true);
    setLicensePlateList([]);
    setLicensePlateSearchPage(0);
    setLicensePlateSearch(value);
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleSelectVehicle = async (id) => {
    const vehicleObject = _.filter(vehicle, (el) => el.id === id);
    setVehicleItem(vehicleObject);
    setActive(id);
    // if (id === active && selectMark === true) {
    //   setSelectMark(false)
    // }
    // else {
    //   setSelectMark(true)
    //   setActive(id);
    // }
  };

  const handleSelectVehicleMap = (id) => {
    const vehicleObject = _.filter(vehicle, (el) => el.id === id);
    setVehicleItem(vehicleObject);
    if (id === active && selectMark === true) {
      setSelectMark(false);
    } else {
      setSelectMark(true);
      setActive(id);
    }
  };

  const openModalWaitingOrder = () => {
    setVisibleWaitingOrder(true);
  };

  const closeModalWaitingOrder = () => {
    setVisibleWaitingOrder(false);
  };

  const closeModalWaitingOrderLicenplate = () => {
    setVisibleWaitingOrderLicanPlate(false);
    setdataRowAddLicenplate();
  };

  const closeModalAddOrRemove = () => {
    setVisibleAddOrRemove(false);
  };
  const handleClickRowWaiting = (val) => {
    setDataRow(val);
    setVisibleAddItem(true);
  };

  const handleClickRowWaitingLicenplate = (val) => {
    setdataRowAddLicenplate(val);
    setVisibleWaitingOrderLicanPlate(true);
  };

  const handleClickRowRemove = (val) => {
    setDataRowRemove(val);
    setVisibleRemoveItem(true);
  };

  const handleSaveOrder = async (form, Default) => {
    const body = {
      assetId: form.licensePlate,
      orderNo: Default.orderNo,
      total: form.qty,
      productName: Default.productName,
      customerName: Default.customerName,
      address: Default.address,
      productIdOrder: Default.productIdOrder,
      remark: Default.remark,
      shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
    };
    const response = await addItem(body);
    if (response.status === 200) {
      successNotification(response.data.message);
      setActiveWaiting(form.licensePlate);
      setTriggerAll((current) => !current);
    } else {
      errorNotification(response.data.message);
      setTriggerAll((current) => !current);
    }
    setDataRow();
    setVisibleAddItem(false);
  };

  const handleCancelOrder = () => {
    setLicensePlateList([]);
    setVisibleAddItem(false);
    setDataRow();
    setLicensePlatePage(0);
    setLicensePlateSearchPage(0);
    setLicensePlateSearch();
  };

  const handleCancelRemoveOrder = () => {
    setDataRowRemove();
    setVisibleRemoveItem(false);
  };

  const handleChangeorderDetail = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handleReCalculate = (assetId) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'planningRecalculateTextButton', defaultMessage: 'Are you sure you want to Re-Calculate ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          deliveryDate: moment(shippingDate).format('YYYY-MM-DD'),
          assetId: assetId,
          orgIdPlanning: selectGroup,
        };
        const response = await replanningOrder(payload);
        // const response = await reCalculate(assetId);
        // console.log('replanningOrder',replanningOrder);
        if (response.status === 200) {
          successNotification(_.get(response.data.status, 'message'));
          setTriggerAll((current) => !current);
        } else {
          errorNotification(_.get(response.data.status, 'message'));
          setTriggerAll((current) => !current);
        }
      },
      onCancel() {},
    });
  };

  const handleConfirmOrder = (assetId) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'planningConfirmOrderTextButton', defaultMessage: 'Are you sure you want to Confirm Order ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        // console.log('TipId',_.get(dataById,'tripId'));
        const response = await confirmOrder(assetId, moment(shippingDate).format('YYYY-MM-DD'), selectGroup);
        // const response = {
        //   status: 200,
        //   data: {
        //     status: {
        //       code: 200,
        //       message: 'Confirm trip completed',
        //     },
        //     data: {
        //       confirmComplete: false,
        //       enableReconfirm: true,
        //     },
        //   },
        // };
        // console.log('countExport', response.data);
        if (response.status === 200) {
          //confirmInterval
          setPendingCheck(true);
          setConfirmGetData((current) => !current);
          setLoadButton(true);
          setConfirmInterval((current) => !current);
        } else {
          errorNotification(response.data.message);
          setTriggerAll((current) => !current);
          // setSpinning(false);
        }

        if (response.status === 400) {
          setSpinning(false);
        }
      },
      onCancel() {},
    });
  };

  const handleReConfirmOrder = (data) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'planningReConfirmOrderTextButton', defaultMessage: 'Are you sure you want to Reconfirm Order ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        setSpinning(true);
        const response = await reConfirmOrder(
          _.get(data, 'assetId'),
          _.get(dataById, 'tripId'),
          moment(shippingDate).format('YYYY-MM-DD'),
          _.get(dataById, 'tripNo'),
          selectGroup
        );

        if (response.status === 200) {
          setPendingCheck(true);
          setLoadButton(true);
          setConfirmGetData((current) => !current);
          setReConfirmInterval((current) => !current);
        } else {
          errorNotification(response.data.message);
          setTriggerAll((current) => !current);
          setSpinning(false);
        }

        if (response.status === 400) {
          setSpinning(false);
        }
      },
      onCancel() {},
    });
  };

  const handleSaveOrderNoLicenplate = async (form) => {
    const body = {
      orgIdPlanning: selectGroup,
      assetId: _.get(dataById, 'assetId'),
      orderNo: _.get(dataRowAddLicenplate, 'orderNo'),
      total: form.qty,
      productName: _.get(dataRowAddLicenplate, 'productName'),
      customerName: _.get(dataRowAddLicenplate, 'customerName'),
      address: _.get(dataRowAddLicenplate, 'address'),
      productIdOrder: _.get(dataRowAddLicenplate, 'productIdOrder'),
      remark: _.get(dataRowAddLicenplate, 'remark'),
      shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
    };
    const response = await addItem(body);
    if (response.status === 200) {
      successNotification(response.data.message);
      setTriggerAll((current) => !current);
    } else {
      errorNotification(response.data.message);
      setTriggerAll((current) => !current);
    }
    setdataRowAddLicenplate();
    setVisibleWaitingOrderLicanPlate();
  };

  const handleRemoveOrder = async (form) => {
    const body = {
      id: _.get(dataRowRemove, 'id'),
      orderNo: _.get(dataRowRemove, 'orderNo'),
      productName: _.get(dataRowRemove, 'productName'),
      total: form.qty,
      customerAddress: _.get(dataRowRemove, 'customerAddress'),
      customerName: _.get(dataRowRemove, 'customerName'),
      productIdOrder: _.get(dataRowRemove, 'productIdOrder'),
      shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
    };
    const response = await removeItem(body, _.get(dataById, 'assetId'));
    if (response.status === 200) {
      successNotification(response.data.message);
      setTriggerAll((current) => !current);
    } else {
      errorNotification(response.data.message);
      setTriggerAll((current) => !current);
    }
    setDataRowRemove();
    setVisibleRemoveItem(false);
  };

  const handleExport = async () => {
    if (dataById) {
      const formatExportFilter = _.filter(column, (item) => item.key !== 'index');

      const formatExport = _.map(formatExportFilter, (item, index) => {
        return {
          colName: item.title,
          sequence: index + 1,
          colCode: item.key,
        };
      });

      // const formatExport =
      //   comCode === 'PPP'
      //     ? [
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnOrderNo', defaultMessage: 'Order No.' }),
      //           sequence: 1,
      //           colCode: 'orderNo',
      //         },
      //         { colName: intl.formatMessage({ id: 'planningColumnItem', defaultMessage: 'Item' }), sequence: 2, colCode: 'productName' },
      //         { colName: intl.formatMessage({ id: 'planningColumnQty', defaultMessage: 'Qty' }), sequence: 3, colCode: 'qty' },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnLoaded', defaultMessage: 'Loaded (%)' }),
      //           sequence: 4,
      //           colCode: 'loadedView',
      //         },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnCustomer', defaultMessage: 'Customer' }),
      //           sequence: 5,
      //           colCode: 'Customer',
      //         },
      //         { colName: intl.formatMessage({ id: 'planningColumnTaskNo', defaultMessage: 'Task No.' }), sequence: 6, colCode: 'taskNo' },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnAddressSource', defaultMessage: 'Source' }),
      //           sequence: 7,
      //           colCode: 'sourceAddress',
      //         },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnAddressDestination', defaultMessage: 'Destination' }),
      //           sequence: 8,
      //           colCode: 'address',
      //         },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnDeliveryDate', defaultMessage: 'Delivery Date' }),
      //           sequence: 9,
      //           colCode: 'deliveryDate',
      //         },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnDeliveryTime', defaultMessage: 'Delivery Time' }),
      //           sequence: 10,
      //           colCode: 'deliveryTime',
      //         },
      //         { colName: intl.formatMessage({ id: 'planningColumnRemark', defaultMessage: 'Remark' }), sequence: 11, colCode: 'remark' },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnAssignee', defaultMessage: 'Assignee' }),
      //           sequence: 12,
      //           colCode: 'fullname',
      //         },
      //         { colName: intl.formatMessage({ id: 'planningColumnPhone', defaultMessage: 'Phone' }), sequence: 13, colCode: 'phone' },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnLicensePlate', defaultMessage: 'License Plate' }),
      //           sequence: 14,
      //           colCode: 'assetNo',
      //         },
      //       ]
      //     : [
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnOrderNo', defaultMessage: 'Order No.' }),
      //           sequence: 1,
      //           colCode: 'orderNo',
      //         },
      //         { colName: intl.formatMessage({ id: 'planningColumnItem', defaultMessage: 'Item' }), sequence: 2, colCode: 'productName' },
      //         { colName: intl.formatMessage({ id: 'planningColumnQty', defaultMessage: 'Qty' }), sequence: 3, colCode: 'qty' },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnLoaded', defaultMessage: 'Loaded (%)' }),
      //           sequence: 4,
      //           colCode: 'loadedView',
      //         },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnCustomer', defaultMessage: 'Customer' }),
      //           sequence: 5,
      //           colCode: 'Customer',
      //         },
      //         { colName: intl.formatMessage({ id: 'planningColumnTaskNo', defaultMessage: 'Task No.' }), sequence: 6, colCode: 'taskNo' },
      //         { colName: intl.formatMessage({ id: 'planningColumnAddress', defaultMessage: 'Address' }), sequence: 7, colCode: 'address' },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnShipDate', defaultMessage: 'Shipping Date' }),
      //           sequence: 8,
      //           colCode: 'shippingDate',
      //         },
      //         { colName: intl.formatMessage({ id: 'planningColumnRemark', defaultMessage: 'Remark' }), sequence: 8, colCode: 'remark' },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnAssignee', defaultMessage: 'Assignee' }),
      //           sequence: 8,
      //           colCode: 'fullname',
      //         },
      //         { colName: intl.formatMessage({ id: 'planningColumnPhone', defaultMessage: 'Phone' }), sequence: 8, colCode: 'phone' },
      //         {
      //           colName: intl.formatMessage({ id: 'planningColumnLicensePlate', defaultMessage: 'License Plate' }),
      //           sequence: 8,
      //           colCode: 'assetNo',
      //         },
      //       ];
      // console.log(formatExport);
      const body = {
        viewFormat: formatExport,
      };
      try {
        const response = await getByidExport(active, orderSort, fieldSort, body, moment(shippingDate).format('YYYY-MM-DD'));

        if (response.status === 200) {
          window.open(response.data.data.url);
          successNotification(response.data.status.message);
        } else {
          errorNotification(response.data.status.message);
        }
      } catch (error) {
        errorNotification(error.response.data.status.message);
      }
    }
  };

  const handleSelectTeam = (value, event) => {
    setSelectGroup(value);
    setSelectGroupPreview(value);
    setIsWaitingOrder(false);
    setVehicleList([]);
    setShowData([]);
    setMarkDetail([]);
    setDataById([]);
    setHasMore(true);
  };

  const setOpenPlanningModal = () => {
    setOpenShippingDate(true);
    setShippingDate(shippingDate);
  };

  const onCancelShippingDate = () => {
    setOpenShippingDate(false);
  };

  const onSaveShippingDate = async (value, setLoading) => {
    try {
      const payload = {
        deliveryDate: moment(_.get(value, 'shippingDate')).format('YYYY-MM-DD'),
        orgIdPlanning: selectGroup,
      };
      const response = await planningOrder(payload);
      if (_.get(response.data.status, 'code') === 200) {
        successNotification(_.get(response.data.status, 'message'));
        setLoading(false);
        setTriggerAll((current) => !current);
        setOpenShippingDate(false);
      } else {
        errorNotification(_.get(response.data.status, 'message'));
        setLoading(false);
      }
    } catch (error) {
      errorNotification(_.get(error.response.data.status, 'message'));
      setLoading(false);
    }
  };

  const handleSelectShippingDate = (value) => {
    setShippingDate(value);
    setShippingDateOrder(value);
    setVehicleList([]);
    setShowData([]);
    setMarkDetail([]);
    setDataById([]);
  };

  const handleDragOrderList = async (dragOrDerList) => {
    //handleReCalculate

    let mapped_array = _.map(dragOrDerList, 'orderNo');
    console.log('dateDrag', mapped_array);
    const payload = {
      deliveryDate: moment(shippingDate).format('YYYY-MM-DD'),
      assetId: _.get(dataById, 'assetId'),
      orgIdPlanning: selectGroup,
      orderNoPlanningIndex: mapped_array,
      // orderNoPlanningIndex: _.get(dataById, 'orderDetail'),
    };

    console.log('payloadDrag', payload);
    const response = await replanDevliveryOrder(payload);
    console.log('responseDrag', response);
    if (response.status === 200) {
      successNotification(_.get(response.data.status, 'message'));
      setTriggerAll((current) => !current);
    } else {
      errorNotification(_.get(response.data.status, 'message'));
      setTriggerAll((current) => !current);
    }
  };

  const handleClearOrderList = async () => {
    //handleReCalculate
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'planningPreviewOrderTextButton', defaultMessage: 'Are you sure you want to Remove All Order ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          deliveryDate: moment(shippingDate).format('YYYY-MM-DD'),
          assetId: _.get(dataById, 'assetId'),
          orgIdPlanning: selectGroup,
        };

        const response = await clearDevliveryOrder(payload);
        if (response.status === 200) {
          successNotification(_.get(response.data.status, 'message'));
          setTriggerAll((current) => !current);
        } else {
          errorNotification(_.get(response.data.status, 'message'));
          setTriggerAll((current) => !current);
        }
      },
      onCancel() {},
    });
  };

  const openModalPreviewOrder = () => {
    setVisiblePreviewOrder(true);
  };

  const closeModalPreviewOrder = () => {
    setVisiblePreviewOrder(false);
  };

  const handleSelectTeamPreview = (value, event) => {
    setSelectGroupPreview(value);
  };

  const handleCancelTrip = (assetId) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'planningCancelTripTextButton', defaultMessage: 'Are you sure you want to Cancel Trip ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        setSpinning(true);

        const body = {
          tripId: _.get(dataById, 'tripId'),
        };
        const response = await cancelTrip(body);

        if (response.data.status !== 400) {
          setPendingCheck(false);
          successNotification(response.data.status.message);
          setTriggerAll((current) => !current);
          setSpinning(false);
        } else {
          errorNotification(response.data.status.message);
          setTriggerAll((current) => !current);
          setSpinning(false);
        }
      },
      onCancel() {},
    });
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <PlanningContext.Provider
          value={{
            state: {
              vehicleScrollRef,
              toggle,
              vehicle,
              memberPageLoading,
              memberPageHasMore,
              vehicleItem,
              active,
              searchTerm,
              selectStatus,
              searchMax,
              searchMin,
              dataStatus,
              vehicleList,
              dataById,
              licenseplateLoading,
              licenseplateList,
              triggerAll,
              disbleButton,
              deliveryGroupList,
              shippingDate,
              openShippingDate,
              shippingDateOrder,
              isLoading,
              selectGroup,
              isWaitingOrder,
              isDragorder,
              visiblePreviewOrder,
              dragOrder,
              selectGroupPreview,
              markDetail,
              selectMark,
              reConfirmStatus,
              hasMore,
              showData,
              loadButton,
              visibleMenu,
              visibleCreateOrder,
            },
            setState: {
              setMemberPageLoading,
              setMemberPageHasMore,
              setVisibleEditVehicle,
              setVisibleAddOrRemove,
              setVisibleWaitingOrderLicanPlate,
              setTriggerAll,
              setShippingDate,
              setOpenShippingDate,
              setIsWaitingOrder,
              setDataById,
              setIsDragorder,
              setVisiblePreviewOrder,
              setDragOrder,
              setSelectGroupPreview,
              setHasMore,
              setShowData,
              setLoadButton,
              setVisibleMenu,
              setVisibleCreateOrder,
            },
            fnc: {
              handleToggle,
              handleSelectVehicle,
              onLoadMore,
              handleSearchAll,
              handleSearchMin,
              handleSearchMax,
              handleSelectStatus,
              openModalWaitingOrder,
              handleLicensePlatePopupScroll,
              handleLicensePlateChangeSelect,
              handleLicensePlateChangeSearch,
              handleLicensePlateChange,
              handleChangeorderDetail,
              handleConfirmOrder,
              handleReCalculate,
              handleExport,
              handleSelectTeam,
              handleSelectShippingDate,
              setShippingDateOrder,
              handleDragOrderList,
              handleClearOrderList,
              openModalPreviewOrder,
              closeModalPreviewOrder,
              handleSelectTeamPreview,
              handleSelectVehicleMap,
              handleReConfirmOrder,
              handleCancelTrip,
            },
          }}
        >
          <Spin spinning={spinning}>
            <div>
              {checkPermissionAction(`P34PG1C1`, `P34PG1C1A1`) ? (
                <div>
                  {toggle ? (
                    <Details column={column} />
                  ) : (
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Vehicle />
                      </Col>
                      <Col span={18}>
                        <Details column={column} />
                      </Col>
                    </Row>
                  )}
                </div>
              ) : null}
              <EditVehicel
                vehicleList={vehicleList}
                visible={visibleEditVehicle}
                setVisible={setVisibleEditVehicle}
                licenseplateList={licenseplateList}
                setLicensePlateList={setLicensePlateList}
                licenseId={active}
                setTrigger={setTriggerAll}
                handleLicensePlatePopupScroll={handleLicensePlatePopupScroll}
                handleLicensePlateChangeSearch={handleLicensePlateChangeSearch}
                handleLicensePlateChange={handleLicensePlateChange}
                licenseplateLoading={licenseplateLoading}
                data={dataById}
                shippingDate={shippingDate}
              />

              <WaitingOrder
                data={[]}
                assetId={active}
                visible={visibleWaitingOrder}
                onClose={closeModalWaitingOrder}
                onClickRow={handleClickRowWaiting}
                trigger={triggerAll}
                setTriggerAll={setTriggerAll}
                setOpenPlanningModal={setOpenPlanningModal}
                shippingDate={shippingDate}
                setShippingDateOrder={setShippingDateOrder}
                shippingDateOrder={shippingDateOrder}
                setShippingDate={setShippingDate}
                selectGroup={selectGroup}
              />

              <ModalAddItem
                ref={addItemRef}
                data={dataRow}
                visible={visibleAddItem}
                onSave={handleSaveOrder}
                onCancel={handleCancelOrder}
                handleLicensePlatePopupScroll={handleLicensePlatePopupScroll}
                handleLicensePlateChangeSearch={handleLicensePlateChangeSearch}
                handleLicensePlateChange={handleLicensePlateChange}
                licenseplateLoading={licenseplateLoading}
                licenseplateList={licenseplateList}
              />

              <AddOrRemove
                data={[]}
                assetId={active}
                visible={visibleAddOrRemove}
                onClose={closeModalAddOrRemove}
                onClickRow={handleClickRowWaitingLicenplate}
                onClickRowRemove={handleClickRowRemove}
                dataById={dataById}
                trigger={triggerAll}
                shippingDate={moment(shippingDate).format('YYYY-MM-DD')}
              />

              <WaitingOrderLicenplate
                data={dataRowAddLicenplate}
                visible={visibleWaitingOrderLicanPlate}
                onCancel={closeModalWaitingOrderLicenplate}
                onClickRow={handleClickRowWaitingLicenplate}
                onSave={handleSaveOrderNoLicenplate}
              />

              <ModalRemoveItem
                data={dataRowRemove}
                visible={visibleRemoveItem}
                onSave={handleRemoveOrder}
                onCancel={handleCancelRemoveOrder}
              />

              <ModalShippingDate
                visible={openShippingDate}
                shippingDateOrder={shippingDateOrder}
                onCancel={onCancelShippingDate}
                onSave={onSaveShippingDate}
              />

              <ModalPreviewOrder visible={visiblePreviewOrder} onClose={closeModalPreviewOrder} />

              <CreateOrderDO
                visible={visibleCreateOrder}
                setVisible={setVisibleCreateOrder}
                orgIdPlanning={selectGroup}
                shippingDate={moment(shippingDate).format('YYYY-MM-DD')}
                assetId= {_.get(dataById, 'assetId')}
                setTrigger={setTriggerAll}
              />
            </div>
          </Spin>
        </PlanningContext.Provider>
      )}
    </PageSettings.Consumer>
  );
};
