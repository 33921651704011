import React from 'react';
import { Drawer, Icon } from 'antd';
import './index.css';

const DrawerComponent = ({ onClose, visible, content, title, handleToggleDrawer, toggle, width }) => {
  const titleDreawer =
    <div style={{ display: 'flex' }}>
      <div onClick={onClose} style={{ fontSize: '15px', position: 'absolute', top: '11px', fontWeight: 'normal', cursor: 'pointer',color:'#707070' }}>X</div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginLeft: '25px' }}>
        <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: '600' }}>{title}</div>
        <div>
          <Icon type={toggle ? 'menu-fold' : 'menu-unfold'} style={{ color: '#e73845', cursor: 'pointer' }} onClick={handleToggleDrawer} />
        </div>
      </div>
    </div>


  return (
    <Drawer zIndex={500} classname={'custom-layout-drawer'} width={width} title={titleDreawer} placement={'left'} closable={false} onClose={onClose} visible={visible} mask={false} maskClosable={false} >
      {content}
    </Drawer>
  );
};

export default DrawerComponent;
