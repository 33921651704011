import React, { useState, useContext } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import DeliveryShipmentAction from './component/action';
import _ from 'lodash'
import { Table, Tag } from 'antd';
import { DeliveryContext } from '../..';
// import DeliveryCreateOrderForm from './modal/create-order';
// import ModalImportOrder from '../import';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import moment from 'moment';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import ModalImportOrder from '../../../../components/modal-import-order';
import DeliveryCreateOrderForm from '../../../../components/modal-create-order';

export const DeliveryShipmentContext = React.createContext();

const DeliveryShipment = () => {
  const intl = useIntl()
  const { handleClickShipment, visibleCreateOrder, setVisibleCreateOrder, statusData, selectBranch,
    selectBranchData, orderList, orderTotal, searchShipment, setTriggerOrder,
    orderData, openImport, setOpenImport, setTrigger, loadingOrder, customerData, onFilterOrder, searchOrder, setSearchOrder } = useContext(DeliveryContext)

    const listDetailType = {
      data : {
        orderDetailTypeList : [{
          code: "deliveryOrderParcel",
          txt: "Parcel"
        }],
      }
    }

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsDO', defaultMessage: 'DO No.' }),
      dataIndex: 'orderNo',
      key: 'orderNo',
      fixed: 'left',
      sorter: true,
      width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsOrderType', defaultMessage: 'Order Type' }),
      dataIndex: 'orderType',
      key: 'orderType',
      sorter: true,
      width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsTaskNo', defaultMessage: 'Task No.' }),
      dataIndex: 'taskNo',
      key: 'taskNo',
      sorter: true,
      width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsCustomer', defaultMessage: 'Customer' }),
      dataIndex: 'customer.comtomerName',
      key: 'customer.comtomerName',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsPrice', defaultMessage: 'Price (THB)' }),
      dataIndex: 'total',
      key: 'total',
      width: 150,
      sorter: true,
      default: true,
      render: (text, record, index) => numberFormatter(record.total || 0)
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsPayment', defaultMessage: 'Payment' }),
      dataIndex: 'paymentStatusText',
      key: 'paymentStatusText',
      width: 120,
      sorter: true,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsOrderStatus', defaultMessage: 'Order Status' }),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      default: true,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={record.statusColor} style={{ minWidth: '100px', width: '100%' }}>
            {record.statusText}
          </Tag>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsParcels', defaultMessage: 'Parcels' }),
      dataIndex: 'totalParcel',
      key: 'totalParcel',
      width: 120,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsContact', defaultMessage: 'Contact' }),
      dataIndex: 'contact.customerContactName',
      key: 'contact.customerContactName',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsPhone', defaultMessage: 'Phone' }),
      dataIndex: 'contact.customerContactPhone',
      key: 'contact.customerContactPhone',
      width: 150,
      sorter: true,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsDestinationName', defaultMessage: 'Destination Name' }),
      dataIndex: 'destination.address_name',
      key: 'destination.address_name',
      width: 170,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsDestination', defaultMessage: 'Destination' }),
      dataIndex: 'destination.address',
      key: 'destination.address',
      width: 350,
      default: true,
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsDeliveryDate', defaultMessage: 'Delivery Date' }),
      dataIndex: 'deliveryDateTxt',
      key: 'deliveryDateTxt',
      width: 150,
      default: true,
      render: (text, record, index) => record.deliveryDateTxt ? moment(record.deliveryDateTxt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsDeliveryTime', defaultMessage: 'Delivery Time' }),
      dataIndex: 'deliveryTimeTxt',
      key: 'deliveryTimeTxt',
      width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsSourceName', defaultMessage: 'Source Name' }),
      dataIndex: 'source.address_name',
      key: 'source.address_name',
      width: 150,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsSourceAddress', defaultMessage: 'Source Address' }),
      dataIndex: 'source.address',
      key: 'source.address',
      width: 300,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsReference', defaultMessage: 'Reference No.' }),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      width: 150,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsSoNo', defaultMessage: 'SO No' }),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'deliveryShipmentColumnsAssignee', defaultMessage: 'Assignee' }),
      dataIndex: 'assignee',
      key: 'assignee',
      width: 150,
      default: false,
      render: (text, record, index) => _.map(_.get(record, 'assignees'), (item, index) => {
        return (
          <span>{item.assigneeName} {(index + 1) !== _.size(record.assignees) ? ',' : ''}</span>
        )
      })
    },
  ];
  const setShowColumnArr = _.map(columns, (item) => { return item.dataIndex })
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  // const ignoreColumn = ['payment'];
  const ignoreColumn = _.map(_.filter(columns, (item) => { return item.default === false }), (item) => { return item.dataIndex })
  console.log("setShowColumn", ignoreColumn)

  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [searchGroup, setSearchGroup] = useState({})
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        searchShipment.setShipmentOrder('desc');
      } else {
        searchShipment.setShipmentOrder('asc');
      }
    } else {
      searchShipment.setShipmentOrder();
    }
    searchShipment.setShipmentField(field);
    searchShipment.setShipmentPage(pagination.current);
    searchShipment.setShipmentSize(pagination.pageSize);
  };


  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleDownLoadTemplate = () => {
    if (_.get(orderData, 'data.deliveryOrderTemplateXlsx')) {
      window.open(_.get(orderData, 'data.deliveryOrderTemplateXlsx'));
      successNotification(_.get(orderData, 'status.message'));
    } else {
      errorNotification(_.get(orderData, 'status.message'));
    }
  };

  console.log('orderData', orderData)

  return (
    <DeliveryShipmentContext.Provider
      value={{
        searchGroup,
        setSearchGroup,
        columns,
        setShowColumn,
        newDataColumns,
        listArrayColumns,
        defaultShowColumn,
        newColumns,
        setNewDataColumns,
        setListArrayColumns,
        setDefaultShowColumn,
        textErrorSelectColumn,
        setTextErrorSelectColumn,
        visible,
        setVisible,
        columnMinimum,
        searchShipment,
        orderData,
        customerData,
        onFilterOrder,
        searchOrder,
        setSearchOrder,
        selectBranch
      }}>
      <div style={{ height: 'calc(100vh - 170px)', overflowY: 'auto' }}>
        <DeliveryShipmentAction />
        <div style={{ padding: '16px 24px' }}>
          <Table
            dataSource={orderList}
            columns={newDataColumns}
            scroll={{ x: _.sumBy(newDataColumns, 'width') }}
            onChange={handleChange}
            loading={loadingOrder}
            pagination={{
              total: orderTotal,
              showTotal: showTotal,
              defaultCurrent: 1,
              current: searchShipment.shipmentPage,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href) {
                    handleClickShipment(record)
                  }
                },
              };
            }}
          />
          {_.size(orderList) > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${orderTotal || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>

        {/* <DeliveryCreateOrderForm
          visible={visibleCreateOrder}
          setVisible={setVisibleCreateOrder}
          statusData={statusData}
          branchData={selectBranchData}
          setTrigger={setTriggerOrder}
          setTriggerBranch={setTrigger}
        /> */}

        <DeliveryCreateOrderForm
          visible={visibleCreateOrder}
          setVisible={setVisibleCreateOrder}
          setTrigger={setTriggerOrder}
          orderData={listDetailType}
          orderCheckType={'deliveryOrderParcel'}
          menuCode={'store'}
          // editData={editData}
          // setEditData={setEditData}
          branchDetail={selectBranchData}
        />

        {/* <ModalImportOrder open={openImport} setOpen={setOpenImport} handleDownLoadTemplate={handleDownLoadTemplate} /> */}
        <ModalImportOrder open={openImport} setOpen={setOpenImport} typeTemplate={'parcel'} branchData={selectBranchData} setTrigger={setTriggerOrder}/>

      </div>
    </DeliveryShipmentContext.Provider >
  )
}

export default DeliveryShipment
