import React, { useContext } from 'react';
import '../css/status.css'
import { TaskDetail } from '../../task-detail-context'
import { Col, Select, Row } from 'antd'
import _ from 'lodash';
import styled from 'styled-components';
import CommentModal from './status-comment'
import { FormattedMessage } from 'react-intl';

const { Option } = Select

export default () => {

  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);
  const selectNew = _.get(stateTask, 'viewInformationData.information.nextStatus') ?
    _.get(stateTask, 'viewInformationData.information.nextStatus').filter((e) => { return e.code !== _.get(stateTask, 'statusTask') }) : [];

  const SelectStatus = styled(Select)`
    width: 'auto';
    .ant-select-selection--single {
      border-radius: 20px;
      height: 24px;
      color: #fff;
      width: fit-content;
      font-size: 13px;
      background-color: ${_.get(stateTask, 'viewInformationData.information.statusColor')};
      border: 1px solid ${_.get(stateTask, 'viewInformationData.information.statusColor')};
    }
    .ant-select-selection-selected-value {
      margin-right: 5px;
      margin-top: 0px;
      color:white !important;
    }
    .ant-select-arrow .ant-select-arrow-icon svg {
      fill : #fff;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover,
    .ant-select-open .ant-select-selection {
      border-color: ${_.get(stateTask, 'viewInformationData.information.statusColor')} !important;
      box-shadow: none !important;
    }
  `;

  const onChangeStatus = (value, e) => {
    setStateTask.setSelectStatus(e.props)
    setStateTask.setVisibleComment(true)
  }


  return (
    <div className='task-detail-status-boder-div-style'>
      <Row>
        <Col span={6}>
          <span><FormattedMessage id="taskDetailStatusText" defaultMessage="Status" /></span>
        </Col>
        <Col span={18}>
          <SelectStatus
            style={{ color: 'red' }}
            dropdownStyle={{ minWidth: '200px' }}
            size='small'
            value={_.get(stateTask, 'statusTask')}
            onChange={onChangeStatus}
            disabled={stateTask.statusView ? false : true}
            showArrow={stateTask.statusView ? true : false}
          >
            {selectNew.map((item) => (
              <Option value={item.status_id}>{item.code}</Option>
            ))}
          </SelectStatus>
        </Col>
      </Row>

      <CommentModal
        statusValue={_.get(stateTask, 'selectStatus')}
        setStatusValue={_.get(setStateTask, 'setSelectStatus')}
        visibleComment={_.get(stateTask, 'visibleComment')}
        setVisibleComment={_.get(setStateTask, 'setVisibleComment')}
        setTriggerApi={_.get(setStateTask, 'setTriggerApi')}
      />
    </div>
  );
}
