import React from 'react'
import { Spin, Icon, Input, Menu, Divider } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css'
import _ from 'lodash';

export default (props) => {
  const intl = useIntl();
  const { value, fnc } = props
  const { locationData, valueInput, selectAddress, loading ,selectAddressData } = value;
  const { handleInput, handleSelectLocation } = fnc;


  const renderMenu = () => locationData && locationData.map((element, index) => {
    return (
      <Menu.Item
        className='location-search-menu-item'
        key={index}
        title={element.address}
      >
        <div className='location-search-menu-item-box' >
          <div className='location-search-text-head'>{element.name}</div>
          <div className='location-search-text-detail'>{element.address}</div>
        </div>
        <Divider className='location-search-divider' type='horizontal' />
      </Menu.Item>
    )
  })

  return (
    <div className='location-search-padding'>
      <div className='location-search-input-div'>
        <Input
          className="action-report-input-7"
          placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
          prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
          onChange={(e) => handleInput(e.target.value)}
          value={valueInput}
          autoComplete="off"
        />
      </div>
      <Spin spinning={loading} delay={500}>
        <div className='location-search-div-height'>
          <Menu
            className='location-search-menu'
            mode="inline"
            onSelect={handleSelectLocation}
            // selectedKeys={_.get(selectAddress[0], 'addressId') !== null ? _.get(selectAddress[0], 'index') : undefined}
            selectedKeys={selectAddressData}

          >
            {renderMenu()}
          </Menu>
        </div>
      </Spin>
    </div >
  )
}
