import httpClient from '../../components/axiosClient';

const deletedFromAddress = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/delete/address-book/${id}`);

    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default deletedFromAddress;