import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../../includes/indexProvider';
import { Card, Table, Button, notification, List, Icon, Modal } from 'antd';
import { debounce } from 'lodash';
import { OverTimeContext } from '../report-context';
import ActionEquipmentReport from './gas-expense-action';
import httpClient from '../../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import IconExcel from '../../../../components/image/excel.svg';
import styles from './css/index.css';
import '../report-center.css';
import { PageSettings } from '../../../../config/page-settings';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';

export default () => {
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const { state, setState, fnc } = useContext(OverTimeContext);

  const StyleModalImgGallery = styled(Modal)`
    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;
    }

    .image-gallery-slide .image-gallery-image {
      max-width: 400px;
      max-height: 500px;
    }

    .ant-modal-close {
      color: #ffffff;
      right: 667px;
    }

    .ant-modal-close-x {
      font-size: 24px;
    }

    .image-gallery-index {
      margin: 0px 20px 0px 20px;
    }

    .image-gallery-right-nav {
      right: 30%;
    }

    .image-gallery-left-nav {
      left: 30%;
    }
  `;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColRequestNo',
        defaultMessage: 'Request No.',
      }),
      dataIndex: 'memReqId',
      key: 'memReqId',
      width: 150,
      sorter: (a, b) => a.memReqId - b.memReqId,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColGasName',
        defaultMessage: 'Name',
      }),
      dataIndex: 'fullname',
      key: 'fullname',
      width: 150,
      sorter: (a, b) => a.fullname - b.fullname,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColTeam',
        defaultMessage: 'Team',
      }),
      dataIndex: 'orgName',
      key: 'orgName',
      // width: 140,
      sorter: (a, b) => a.orgName - b.orgName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColRefuelingDate',
        defaultMessage: 'Refueling Date',
      }),
      dataIndex: 'reFuelDate',
      key: 'reFuelDate',
      // width: 150,
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColGasType',
        defaultMessage: 'Gas Type',
      }),
      dataIndex: 'fuelType',
      key: 'fuelType',
      // width: 120,
      sorter: (a, b) => a.fuelType - b.fuelType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColGasStation',
        defaultMessage: 'Gas Station',
      }),
      dataIndex: 'locationFuelStation',
      key: 'locationFuelStation',
      width: 350,
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColSaveLocation',
        defaultMessage: 'Location',
      }),
      dataIndex: 'locationRequestFuel',
      key: 'locationRequestFuel',
      width: 350,
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColMile',
        defaultMessage: 'Mileage',
      }),
      dataIndex: 'distance',
      key: 'distance',
      // width: 90,
      align: 'center',
      sorter: (a, b) => a.distance - b.distance,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColQty',
        defaultMessage: 'Liter',
      }),
      dataIndex: 'total',
      key: 'total',
      // width: 90,
      align: 'center',
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['descend', 'ascend'],
      //   render: (text, record, index) =>
      //     <NumberFormat value={(parseFloat(record.total))} displayType={'text'} thousandSeparator={true} />
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColGasPrice',
        defaultMessage: 'Price (Baht)',
      }),
      dataIndex: 'price',
      key: 'price',
      // width: 90,
      align: 'right',
      sorter: (a, b) => a.approverComment - b.approverComment,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => <NumberFormat value={record.price} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: intl.formatMessage({
        id: 'reportGasExpenseColPhoto',
        defaultMessage: 'Photo',
      }),
      dataIndex: 'photo',
      key: 'photo',
      // width: 90,
      align: 'center',
      render: (text, record, index) => (
        <span onClick={() => handlePreview(record.photo)} style={{ cursor: 'pointer' }}>
          {record.photo.totalPhoto}
        </span>
      ),
    },
  ];

  const setShowColumn = {
    index: true,
    memReqId: true,
    fullname: true,
    orgName: true,
    reFuelDate: true,
    fuelType: true,
    locationFuelStation: true,
    locationRequestFuel: true,
    distance: true,
    total: true,
    price: true,
    photo: true,
  };

  const setShowColumnArr = [
    'index',
    'memReqId',
    'fullname',
    'orgName',
    'reFuelDate',
    'fuelType',
    'locationFuelStation',
    'locationRequestFuel',
    'distance',
    'total',
    'price',
    'photo',
  ];
  const comCode = localStorage.getItem('comCode');
  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';
  const dateNow = moment(today).format('YYYY-MM');
  const dateNowRangePicker = [moment(today, dateFormat), moment(today, dateFormat)];

  const [loading, setLoading] = useState(false);
  const [overtimeReport, setOvertimeReport] = useState([]);

  const [visible, setVisible] = useState(false);
  const [defaultShowColumn, setDefaultShowColumn] = useState({
    ...setShowColumn,
  });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  const [selectSearchData, setSelectSearchData] = useState('all');
  const [fieldChang, setFieldChang] = useState('');
  const [refreshTable, setRefreshTable] = useState(false);

  const [rangePicker, setRangePicker] = useState(dateNowRangePicker);
  const [fromDate, setFromDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [disabled, setDisabled] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [imgData, setImgData] = useState([]);
  const [startIndexGallery, setStartIndexGallery] = useState(0);
  const [allRow, setAllRow] = useState();
  const [scrollTableX, setScrollTableX] = useState(2000);

  useEffect(() => {
    getOvertimeReport();
  }, [fieldChang, rangePicker, state.checkedKeys, extraSort, selectSearchData, state.selectedKeys]);

  const getOvertimeReport = async () => {
    setLoading(true);
    try {
      const body = {
        startDate: fromDate,
        endDate: toDate,
        orgId: _.size(state.checkedKeys) !== 0 ? state.checkedKeys : state.selectedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: fieldChang === '' ? paginationPage : 1,
        rowsPerPages: paginationSize,
        orderType: orderSort,
        orderBy: fieldSort,
      };

      const response = await httpClient.post(`/v3/resource/manager/report/fuel?menuType=transportation`, body);
      if (response.status === 200) {
        setOvertimeReport(_.get(response, 'data.data'));
        setAllRow(_.get(response, 'data.allRows'));
        setTotal(response.data.allRows);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 3) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    if (_.size(setNewArr) <= 6) {
      const filterData =
        setNewArr &&
        setNewArr.filter((item) => {
          return item.key === 'locationFuelStation' || item.key === 'locationRequestFuel';
        });
      if (_.size(filterData) < 1) {
        setScrollTableX(0);
      } else if (_.size(filterData) < 2) {
        setScrollTableX(1000);
      } else if (_.size(filterData) < 3) {
        setScrollTableX(1300);
      }
    } else if (_.size(setNewArr) <= 9) {
      setScrollTableX(1500);
    } else if (_.size(setNewArr) <= 12) {
      setScrollTableX(2000);
    }

    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    setScrollTableX(2000);
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
      setScrollTableX(2000);
    }
  };

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
  };

  const onSelectRangePicker = (date, dateString) => {
    setRangePicker(dateString);
    setFromDate(moment(date[0]).format('YYYY-MM-DD'));
    setToDate(moment(date[1]).format('YYYY-MM-DD'));
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handleExport = async () => {
    setDisabled(true);
    try {
      const body = {
        startDate: fromDate,
        endDate: toDate,
        orgId: state.checkedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: 1,
        rowsPerPages: allRow,
        orderType: orderSort,
        orderBy: fieldSort,
        viewFormat: [
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColRequestNo',
              defaultMessage: 'Request No.',
            }),
            sequence: 1,
            colCode: 'memReqId',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColGasName',
              defaultMessage: 'Name',
            }),
            sequence: 2,
            colCode: 'fullname',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColTeam',
              defaultMessage: 'Team',
            }),
            sequence: 3,
            colCode: 'orgName',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColRefuelingDate',
              defaultMessage: 'Refueling Date',
            }),
            sequence: 4,
            colCode: 'reFuelDate',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColGasType',
              defaultMessage: 'Gas Type',
            }),
            sequence: 5,
            colCode: 'fuelType',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColGasStation',
              defaultMessage: 'Gas Station',
            }),
            sequence: 6,
            colCode: 'locationFuelStation',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColSaveLocation',
              defaultMessage: 'Location',
            }),
            sequence: 7,
            colCode: 'locationRequestFuel',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColMile',
              defaultMessage: 'Mileage',
            }),
            sequence: 8,
            colCode: 'distance',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColQty',
              defaultMessage: 'Liter',
            }),
            sequence: 9,
            colCode: 'total',
          },
          {
            colName: intl.formatMessage({
              id: 'reportGasExpenseColGasPrice',
              defaultMessage: 'Price (Baht)',
            }),
            sequence: 10,
            colCode: 'price',
          },
        ],
      };
      const response = await httpClient.post(`/v3/resource/manager/report/fuel/export?menuType=transportation`, body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
        setDisabled(false);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      //errorNotification(error.response.data.status.message);
    }
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const iconExcel = () => <img className="icon-excel" src={IconExcel} />;

  const handlePreview = (record) => {
    if (_.size(record.files) !== 0) {
      setVisibleModal(true);
      const mapImg = _.get(record, 'files').map((item) => {
        return {
          original: item,
          thumbnail: item,
        };
      });
      setImgData(mapImg);
    }
  };

  const HandlerModal = () => {
    return (
      <div>
        <StyleModalImgGallery visible={visibleModal} onCancel={() => setVisibleModal(false)} footer={null} width={'100%'}>
          <ImageGallery
            className={styles.imagegallery}
            items={imgData}
            showPlayButton={false}
            showIndex={true}
            startIndex={startIndexGallery}
            showFullscreenButton={false}
          />
        </StyleModalImgGallery>
      </div>
    );
  };

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={fnc.handleToggle}>
          <Icon type={state.toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="reportGasExpenseTextHeader" defaultMessage="Gas Expense Report" />
          </p>
        </div>
      </div>
    );
  };

  return (
    <OverTimeContext.Consumer>
      {() => (
        <Card
          className="overtime-report-card"
          title={<HeaderAndToggle />}
          extra={
            <Button className="button-link-export" type="link" onClick={handleExport} disabled={disabled}>
              <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
            </Button>
          }
        >
          <ActionEquipmentReport
            columns={columns}
            handleReset={handleReset}
            handleCheckOk={handleCheckOk}
            visible={visible}
            handleVisibleChange={handleVisibleChange}
            checkedValuecolumns={checkedValuecolumns}
            onChange={handleSearch}
            onSelect={handleSelectData}
            selectSearchData={selectSearchData}
            checked={defaultShowColumn}
            textErrorSelectColumn={textErrorSelectColumn}
            handleOnVisiblecolumns={handleOnVisiblecolumns}
            onSelectRangePicker={onSelectRangePicker}
          />

          <Table
            className="report-table"
            // rowKey={record => record.index}
            onChange={handleChange}
            loading={loading}
            dataSource={overtimeReport}
            columns={newDataColumns}
            // scroll={{ x: 2500, y: 780 }}
            scroll={{ x: scrollTableX, y: `calc(100vh - 392px)` }}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              onChange: handlePagination,
              onShowSizeChange: handleSizeChange,
            }}
          />

          {allRow ? (
            <div className="total-items-overtime-report">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${allRow || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
          <HandlerModal />
        </Card>
      )}
    </OverTimeContext.Consumer>
  );
};
