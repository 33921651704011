import React, { useEffect, useState } from 'react'
import NotificationList from './notification-list'
import NotificationDetail from './notification-card-detail'
import { Col, Row } from 'antd'
import httpClient from '../../components/axiosClient';
import { NotificationContext } from './notification-context';
import ModalNotification from '../../components/modal-notification/index'
//import { useParams } from 'react-router-dom';
import { useQuery } from '../../helpers/general-helper';
import { successNotification, errorNotification, warningNotificatio } from '../../components/v2/notification';
import _ from 'lodash'


export default () => {

  // const notiCode = useParams();
  // const query = useQuery();
  const comCode = localStorage.getItem('comCode');
  const memComId = localStorage.getItem('memComId');

  const [menuData, setMenuData] = useState([])

  const [menuDataSearch, setMenuDataSearch] = useState(menuData)
  const [notiData, setNotiData] = useState()
  const [dataFilterNoti, setDataFilterNoti] = useState()
  const [disableAcknowledge, setDisableAcknowledge] = useState(false)
  const [idSelect, setIdSelect] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [triggerAcknowledge, setTriggerAcknowledge] = useState(false)
  const [visibleModalNoti, setVisibleModalNoti] = useState(false)
  const [modalData, setModalData] = useState()
  const [statusPin, setStatusPin] = useState(false)
  const [totalNoti, setTotalNoti] = useState()
  const [defaultPin, setDefaultPin] = useState()
  const [selectSearchData, setSelectSearchData] = useState('all');
  const [searchData, setSearchData] = useState('');
  const [searchDataList, setSearchDataList] = useState('');



  useEffect(() => {
    getMenuNotification();
    // if (query.get('typeCode')) {
    //   handleSelectMenu([query.get('typeCode').toLowerCase()]);
    // }
  }, [])

  useEffect(() => {
    getNotiData();
  }, [triggerAcknowledge, searchData, selectSearchData])

  useEffect(() => {
    setMenuDataSearch(menuData);
  }, [menuData])

  const getMenuNotification = async () => {
    try {
      const response = await httpClient.get(`/v3/notification/manager/noti/${comCode}/typelist`);
      if (response.status == 200) {
        setMenuData(response.data.data)
      }
    } catch (error) {

    }
  }

  const handleSelectMenu = (entity) => {
    if (menuData) {
      const entityString = entity && entity.toString();
      const filternotiData = menuData && menuData.filter((col) => entityString.includes(col.notiTypeCode));
      setDataFilterNoti(filternotiData[0]);
      setTriggerAcknowledge(!triggerAcknowledge);
      setStatusPin(true)
    }
  }


  const onSearchChange = (e) => {
    const inputSrch = e.target.value.toLowerCase();

    const dataSearch = menuData.filter((e) => e.name.toLowerCase().includes(inputSrch.toLowerCase()))

    setMenuDataSearch(dataSearch);
  }

  const handleSelectBy = (value) => {
    setSelectSearchData(value)
  }

  const handleSearch = _.debounce((fieldChange) => {
    setSearchData(fieldChange ? fieldChange : '');
  }, 1000);


  const getNotiData = async () => {
    const body = {
      search: searchData,
      searchBy: selectSearchData
    }

    try {
      const response = await httpClient.post(`/v3/notification/manager/noti/${comCode}/type?notiTypeCode=${dataFilterNoti.notiTypeCode}&memberReceiverId=${memComId}`, body);
      if (response.status == 200) {
        setNotiData(response.data.data)
        setTotalNoti(response.data.total);
        setDefaultPin(response.data.pin)

      }
    } catch (error) {

    }
  }

  const acknowledgeStatus = async (id) => {
    setDisableAcknowledge(false);

    let arrayId = []
    arrayId.push(id)

    const body = {
      id: selectedRowKeys.length !== 0 ? selectedRowKeys : arrayId,
      statusRead: true
    }


    try {
      const response = await httpClient.put(`/v3/notification/manager/noti/${comCode}/type/updatedata`, body);

      if (response.status == 200) {
        setTriggerAcknowledge(current => !current);
        setTimeout(() => {
          // setIdSelect([]);
          setSelectedRowKeys([])
        }, [1000])
        successNotification(response.data.message);
      }
    } catch (error) {
      setDisableAcknowledge(true);
    }
  }

  const pinNotification = async (status) => {

    const body = {
      memComId: memComId,
      notiTypeCode: dataFilterNoti && dataFilterNoti !== undefined ? dataFilterNoti.notiTypeCode : undefined
    }
    try {
      const response = await httpClient.post(`/v3/notification/manager/noti/${comCode}/create/pin`, body);
      if (response.status == 200) {
        setTriggerAcknowledge(!triggerAcknowledge);

      }
    } catch (error) {

    }


  }


  return (
    <NotificationContext.Provider
      value={{
        notiState: {
          menuData,
          notiData,
          dataFilterNoti,
          disableAcknowledge,
          idSelect,
          selectedRowKeys,
          visibleModalNoti,
          modalData,
          statusPin,
          totalNoti,
          defaultPin,
          selectSearchData,
          menuDataSearch
        },
        notiSetState: {
          setMenuData,
          setNotiData,
          setDataFilterNoti,
          setDisableAcknowledge,
          setIdSelect,
          setSelectedRowKeys,
          setVisibleModalNoti,
          setModalData,
          setStatusPin,
          setSelectSearchData,
        },
        notiFNC: {
          handleSelectMenu,
          acknowledgeStatus,
          pinNotification,
          handleSelectBy,
          handleSearch,
          onSearchChange,

        }
      }}
    >
      <div>
        <Row gutter={16, 16}>
          <Col span={5}>
            <NotificationList />
          </Col>
          <Col span={19}>
            <NotificationDetail selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
          </Col>
        </Row>

      </div>

      <ModalNotification
        visible={visibleModalNoti}
        setVisible={setVisibleModalNoti}
        dataFilterNoti={dataFilterNoti}
        datasource={modalData}
        acknowledgeStatus={acknowledgeStatus}
      />
    </NotificationContext.Provider>


  )
}
