import React, { useState, useEffect, useContext } from 'react';
import { Avatar, Col, Modal, Row, Table, Tabs, Divider, Icon, Badge, Spin, Input } from 'antd';
import { TaskDetail } from '../../task-detail-context';
import Button02 from '../../../../../v2/button_02';
import httpClient from '../../../../../axiosClient';
import styled from 'styled-components';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import { successNotification, errorNotification } from '../../../../../v2/notification';
import { getMemberAssigneeNew } from '../../../../../../controllers/task/create-task';
import Button01 from '../../../../../v2/button_01';
import moment from 'moment';
import './css/index.css';

const { TabPane } = Tabs;
const { confirm } = Modal;

const StylesModal = styled(Modal)`
  .ant-modal-body {
    padding: unset;
  }
  .ant-modal-content {
    max-height: 700px;
    height: 700px;
  }
`;

const comId = localStorage.getItem('comId');

export default React.memo((props) => {
  const intl = useIntl();
  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);
  const { visibleAssignee, triggerTaskDetail } = stateTask;
  const memId = localStorage.getItem('memId');

  const taskTypeName = _.get(stateTask.viewInformationData, 'information')
    ? _.get(stateTask.viewInformationData.information, 'taskTypeName')
    : '';
  const taskNo = _.get(stateTask.viewInformationData, 'information') ? _.get(stateTask.viewInformationData.information, 'taskNo') : '';
  const statusColor = _.get(stateTask.viewInformationData, 'information')
    ? _.get(stateTask.viewInformationData.information, 'statusColor')
    : '';

  const mainTaskIdAssign = _.get(stateTask, 'taskId');
  const orgId = _.get(stateTask.viewInformationData, 'information') ? _.get(stateTask.viewInformationData.information, 'orgId') : '';
  const taskTypeId = _.get(stateTask.viewInformationData, 'information')
    ? _.get(stateTask.viewInformationData.information, 'taskTypeId')
    : '';
  const toLocationLat = _.get(stateTask.centerTo, 'lat') || undefined;
  const toLocationLng = _.get(stateTask.centerTo, 'lng') || undefined;

  const startDate = _.get(stateTask.viewInformationData, 'information')
    ? _.get(stateTask.viewInformationData.information, 'startDate')
    : '';
  const startTime = _.get(stateTask.viewInformationData, 'information')
    ? _.get(stateTask.viewInformationData.information, 'startTime')
    : '';
  const concatStartDate = startDate + ' ' + startTime;
  const momentStartDate = concatStartDate ? moment(concatStartDate, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss') : undefined;

  const dueDate = _.get(stateTask.viewInformationData, 'information') ? _.get(stateTask.viewInformationData.information, 'dueDate') : '';
  const dueTime = _.get(stateTask.viewInformationData, 'information') ? _.get(stateTask.viewInformationData.information, 'dueTime') : '';
  const concatDueDate = dueDate + ' ' + dueTime;
  const momentDueDate = concatDueDate ? moment(concatDueDate, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss') : undefined;

  const [resourceTaskData, setResourceTaskData] = useState([]);
  const [resorceDataAssign, setResorceDataAssign] = useState([]);
  const [resorceDataCandidates, setResorceDataCandidates] = useState([]);
  const [resorceDataAvalible, setResorceDataAvalible] = useState([]);
  const [resorceDataOnGoing, setResorceDataOnGoing] = useState([]);
  const [resorceDataOff, setResorceDataOff] = useState([]);
  const [information, setInformation] = useState([]);
  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectTab, setSelectTab] = useState();

  const [pageAssigneeAvailable, setPageAssigneeAvailable] = useState(1);
  const [pageSizeAssigneeAvailable, setPageSizeAssigneeAvailable] = useState(10);
  const [loadingAvailable, setLoadingAvailable] = useState(false);

  const [pageAssigneeNotAvailable, setPageAssigneeNotAvailable] = useState(1);
  const [pageSizeAssigneeNotAvailable, setPageSizeAssigneeNotAvailable] = useState(10);
  const [loadingNotAvailable, setLoadingNotAvailable] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [searchUnassignee, setSearchUnassignee] = useState('');
  const [fieldSortAvailable, setFieldSortAvailable] = useState();
  const [orderSortAvailable, setOrderSortAvailable] = useState();
  const [fieldSortNotAvailable, setFieldSortNotAvailable] = useState();
  const [orderSortNotAvailable, setOrderSortNotAvailable] = useState();

  const [memberAvailable, setMemberAvailable] = useState([]);
  const [memberNotAvailable, setMemberNotAvailable] = useState([]);
  const [memberAssignee, setMemberAssignee] = useState([]);

  const [memberAvailableArrray, setMemberAvailableArrray] = useState([]);
  const [memberNotAvailableArrray, setMemberNotAvailableArrray] = useState([]);
  const [memberAvailableTotal, setMemberAvailableTotal] = useState(0);
  const [memberNotAvailableTotal, setMemberNotAvailableTotal] = useState(0);

  const [memberAssigneeShow, setMemberAssigneeShow] = useState([]);
  const [loadingView, setLoadingView] = useState(false);

  const columnsAssignee = [
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColIndex`, defaultMessage: '#' }),
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 190,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'phone',
      key: 'phone',
      width: 130,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColStatus`, defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColDistance`, defaultMessage: 'Distance (km.)' }),
      dataIndex: 'distance',
      key: 'distance',
      width: 130,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColSubtasks`, defaultMessage: 'Subtasks' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return <img key={i} className="table-status-img" src={item.icon} loading="lazy" />;
            } else {
              return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} loading="lazy" />;
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      width: 145,
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{ margin: '0px 0px 0px 10px' }} btnsize="wd_df" onClick={() => handelRemove(index, record)}>
              <FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnAssigneeBtnRemove" defaultMessage="Remove" />
            </Button02>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColIndex`, defaultMessage: '#' }),
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.index}</div>,
    },
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => (
        <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>
          <Avatar src={record.profile} />
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 190,
      sorter: true,
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.name}</div>,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'phone',
      key: 'phone',
      width: 130,
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.phone}</div>,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColStatus`, defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.status}</div>,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColDistance`, defaultMessage: 'Distance (km.)' }),
      dataIndex: 'distance',
      key: 'distance',
      width: 130,
      align: 'center',
      render: (text, record, index) => <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>{record.distance}</div>,
    },
    {
      title: intl.formatMessage({ id: `monitorAddAssigneeColSubtasks`, defaultMessage: 'Subtasks' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) => (
        <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>
          {record.subtasks.map((item, i) => {
            const iconExcel = () => {
              if (item.done == true) {
                return <img key={i} className="table-status-img" src={item.icon} loading="lazy" />;
              } else {
                return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} loading="lazy" />;
              }
            };

            return (
              <div className="table-status">
                <div key={i} className="table-status-key">
                  <Icon className="table-status-icon" component={iconExcel} />
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      dataIndex: 'options',
      key: 'options',
      width: 145,
      render: (text, record, index) => (
        <div style={{ opacity: record.statusButton === true ? 0.4 : undefined }}>
          <Button02
            style={{ margin: '0px 0px 0px 10px' }}
            className="custom-disable-button-assign"
            btnsize="wd_df"
            disabled={record.statusButton}
            onClick={() => handleAssign(record, index)}
          >
            <FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnAvailableBtnAssign" defaultMessage="Assign" />
          </Button02>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoadingAvailable(true);
    const getAvailableMember = async () => {
      const payload = {
        search: {
          name: searchUnassignee,
          phone: searchUnassignee,
        },
        orderBy: fieldSortAvailable,
        orderType: orderSortAvailable,
        page: searchUnassignee === '' ? pageAssigneeAvailable : 1,
        limit: pageSizeAssigneeAvailable,
        orgId: orgId,
        taskTypeId: taskTypeId ? taskTypeId : undefined,
        isReloadCandidate: false,
        toLat: toLocationLat, //มีก็ส่งไม่มีก็ส่ง
        toLng: toLocationLng, //มีก็ส่งไม่มีก็ส่ง
        startDate: momentStartDate, //มีก็ส่งไม่มีก็ส่ง
        endDate: momentDueDate, //มีก็ส่งไม่มีก็ส่ง
        taskId: mainTaskIdAssign, //มีก็ส่งไม่มีก็ส่ง
      };
      const responseAvailable = await getMemberAssigneeNew(payload, 'available');
      const arrayAvailable = _.get(responseAvailable.data, 'data.membersCandidate').map((el) => {
        return {
          distance: el.distance,
          index: el.index,
          isAssignee: el.isAssignee,
          isWaringReset: el.isWaringReset,
          memComId: el.memComId,
          name: el.name,
          phone: el.phone,
          profile: el.profile,
          status: el.status,
          subtasks: el.subtasks,
          color: '#fff',
          statusButton: false,
        };
      });
      setMemberAvailable(_.get(responseAvailable.data, 'data') || []);
      setMemberAvailableArrray(arrayAvailable);
      setMemberAvailableTotal(_.get(responseAvailable.data, 'data.count_available'));
      setResorceDataAssign(_.get(responseAvailable.data, 'data.membersAssignees'));
      setLoadingAvailable(false);
    };
    if (taskTypeId && orgId && visibleAssignee === true) {
      getAvailableMember();
    }
  }, [
    orgId,
    selectTab,
    triggerTaskDetail,
    visibleAssignee,
    searchUnassignee,
    fieldSortAvailable,
    orderSortAvailable,
    pageAssigneeAvailable,
    pageSizeAssigneeAvailable,
  ]);

  useEffect(() => {
    setLoadingNotAvailable(true);
    const getAvailableMember = async () => {
      const payload = {
        search: {
          name: searchUnassignee,
          phone: searchUnassignee,
        },
        orderBy: fieldSortNotAvailable,
        orderType: orderSortNotAvailable,
        page: searchUnassignee === '' ? pageAssigneeNotAvailable : 1,
        limit: pageSizeAssigneeNotAvailable,
        orgId: orgId,
        taskTypeId: taskTypeId ? taskTypeId : undefined,
        isReloadCandidate: false,
        toLat: toLocationLat, //มีก็ส่งไม่มีก็ส่ง
        toLng: toLocationLng, //มีก็ส่งไม่มีก็ส่ง
        startDate: momentStartDate, //มีก็ส่งไม่มีก็ส่ง
        endDate: momentDueDate, //มีก็ส่งไม่มีก็ส่ง
        taskId: mainTaskIdAssign, //มีก็ส่งไม่มีก็ส่ง
      };
      const responseNotAvailable = await getMemberAssigneeNew(payload, 'notavailable');
      const arrayNotAvailable = _.get(responseNotAvailable.data, 'data.membersCandidate').map((el) => {
        return {
          distance: el.distance,
          index: el.index,
          isAssignee: el.isAssignee,
          isWaringReset: el.isWaringReset,
          memComId: el.memComId,
          name: el.name,
          phone: el.phone,
          profile: el.profile,
          status: el.status,
          subtasks: el.subtasks,
          color: '#fff',
          statusButton: false,
        };
      });
      setMemberNotAvailable(_.get(responseNotAvailable.data, 'data'));
      setMemberNotAvailableArrray(arrayNotAvailable);
      setMemberNotAvailableTotal(_.get(responseNotAvailable.data, 'data.count_notavailable'));
      setLoadingNotAvailable(false);
    };
    if (taskTypeId && orgId && visibleAssignee === true) {
      getAvailableMember();
    }
  }, [
    orgId,
    selectTab,
    visibleAssignee,
    triggerTaskDetail,
    searchUnassignee,
    fieldSortNotAvailable,
    orderSortNotAvailable,
    pageAssigneeNotAvailable,
    pageSizeAssigneeNotAvailable,
  ]);

  useEffect(() => {
    setLoadingView(true);
    if (searchValue !== '') {
      const filterDataMember = _.filter(resorceDataAssign, (ele) => {
        return ele.name.toLowerCase().includes(searchValue.toLowerCase()) || ele.phone.toLowerCase().includes(searchValue.toLowerCase());
      });
      setMemberAssigneeShow(filterDataMember);
      setLoadingView(false);
    } else {
      setMemberAssigneeShow(resorceDataAssign);
      setLoadingView(false);
    }
  }, [searchValue]);

  const handelRemove = (index, record) => {
    confirm({
      className: 'assignee-remove-modal',
      icon: null,
      centered: true,
      title: intl.formatMessage({
        id: `monitorTabDetailModalAssigneeResourceModalRemove`,
        defaultMessage: 'Are you sure to remove assignee',
      }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          if (mainTaskIdAssign && record.memComId) {
            const response = await httpClient.delete(`/v3/task/manager/task/${mainTaskIdAssign}/unassign/${record.memComId}`);
            if (response.status == 200) {
              const tasks = stateTask.scheduleObjNew.current.getEvents();
              const thisTask = _.find(tasks, { TaskId: mainTaskIdAssign, ResourceId: _.toInteger(record.memComId) });
              if (thisTask) stateTask.scheduleObjNew.current.deleteEvent(thisTask.Id);
              setStateTask.setTriggerTaskDetail((current) => !current);
              successNotification(response.data.status.message);
            } else {
              errorNotification(response.data.status.message);
            }
          }
        } catch (error) {
          errorNotification(error.response.status.message);
        }
      },
      onCancel() {},
    });
  };

  const handleAssign = (record, index) => {
    confirm({
      className: 'assignee-confirm-modal',
      icon: null,
      centered: true,
      title: intl.formatMessage({
        id: `monitorTabDetailModalAssigneeResourceModalAssigne`,
        defaultMessage: 'Do you want to assign this assignee?',
      }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        if (record.isWaringReset === false) {
          resetAssigneeTaskNoWarning(record);
        } else {
          resetAssigneeTask(record);
        }
      },
      onCancel() {},
    });
  };

  const resetAssigneeTaskNoWarning = async (record) => {
    try {
      const body = {
        memComId: record.memComId,
        resetSubtask: true,
        memId: Number(memId),
        version: '2',
        orgId: orgId,
        oldOrgId: orgId,
      };
      const responseReset = await httpClient.put(`/v3/task/manager/company/${comId}/monitor/task/${mainTaskIdAssign}/reassign`, body);
      if (responseReset.status == 200) {
        setStateTask.setTriggerTaskDetail((current) => !current);
        successNotification(responseReset.data.status.message);
      } else {
        errorNotification(responseReset.data.status.message);
      }
    } catch (error) {
      errorNotification(error.responseReset.status.message);
    }
  };

  const resetAssigneeTask = (record) => {
    confirm({
      className: 'assignee-reset-modal',
      icon: null,
      title: intl.formatMessage({
        id: `monitorTabDetailModalAssigneeResourceModalReset`,
        defaultMessage: 'Do you want to reset this assignee task?',
      }),
      okText: intl.formatMessage({ id: 'btnKeep', defaultMessage: 'Keep' }),
      async onOk() {
        try {
          const body = {
            memComId: record.memComId,
            resetSubtask: false,
            memId: Number(memId),
            version: '2',
            orgId: orgId,
            oldOrgId: orgId,
          };
          const response = await httpClient.put(`/v3/task/manager/company/${comId}/monitor/task/${mainTaskIdAssign}/reassign`, body);
          if (response.status == 200) {
            setStateTask.setTriggerTaskDetail((current) => !current);
            successNotification(response.data.status.message);
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {
          errorNotification(error.response.status.message);
        }
      },
      async onCancel() {
        try {
          const body = {
            memComId: record.memComId,
            resetSubtask: true,
            memId: Number(memId),
            version: '2',
            orgId: orgId,
            oldOrgId: orgId,
          };
          const responseReset = await httpClient.put(`/v3/task/manager/company/${comId}/monitor/task/${mainTaskIdAssign}/reassign`, body);
          if (responseReset.status == 200) {
            setStateTask.setTriggerTaskDetail((current) => !current);
            successNotification(responseReset.data.status.message);
          } else {
            errorNotification(responseReset.data.status.message);
          }
        } catch (error) {
          errorNotification(error.responseReset.status.message);
        }
      },
      cancelText: intl.formatMessage({ id: 'btnReset', defaultMessage: 'Reset' }),
      centered: true,
    });
  };

  const TitleModal = () => {
    return (
      <div>
        <Badge color={statusColor} className="Statusdot" />
        <span className="assignee-details-title">{taskNo}</span>
        <Badge color="#1D3557" className="assignee-beetwen-Badge-dot" />
        <span className="assignee-details-title">{taskTypeName}</span>
      </div>
    );
  };

  const handleTab = (key) => {
    setSelectTab(key);
  };

  const handleCancel = (e) => {
    if (e) {
      if (e.currentTarget.tagName.toLowerCase() !== 'button') return;
    }
    setStateTask.setVisibleAssignee(false);
    setPageAssigneeAvailable(1);
    setPageSizeAssigneeAvailable(10);
    setPageAssigneeNotAvailable(1);
    setPageSizeAssigneeNotAvailable(10);
    setSearchUnassignee('');
    setSearchValue('');
    setOrderSortNotAvailable('');
    setFieldSortNotAvailable('');
    setOrderSortAvailable('');
    setFieldSortAvailable('');
  };

  const handleSearchAssignee = (val) => {
    setSearchValue(val);
  };

  const handleSearchAssigneeStatus = (val) => {
    setSearchUnassignee(val);
  };

  const handlePaginationAvailable = (val) => {
    setPageAssigneeAvailable(val);
  };

  const handleSizeChangeAvailable = (current, size) => {
    setPageAssigneeAvailable(current);
    setPageSizeAssigneeAvailable(size);
  };

  const handlePaginationNotAvailable = (val) => {
    setPageAssigneeNotAvailable(val);
  };

  const handleSizeChangeNotAvailable = (current, size) => {
    setPageAssigneeNotAvailable(current);
    setPageSizeAssigneeNotAvailable(size);
  };

  const handleChangeAvailable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSortAvailable('desc');
      } else {
        setOrderSortAvailable('asc');
      }
    } else {
      setOrderSortAvailable('');
    }
    setFieldSortAvailable(field);
  };

  const handleChangeNotAvailable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSortNotAvailable('desc');
      } else {
        setOrderSortNotAvailable('asc');
      }
    } else {
      setOrderSortNotAvailable('');
    }
    setFieldSortNotAvailable(field);
  };

  const operations = (
    <Input
      prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
      placeholder={intl.formatMessage({ id: 'monitorCreateTaskAddAssigneeSearch', defaultMessage: 'Search…' })}
      style={{ width: '210px' }}
      onChange={(event) => handleSearchAssigneeStatus(event.target.value)}
      value={searchUnassignee}
    />
  );

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div>
      <Modal
        title={<TitleModal />}
        visible={stateTask.visibleAssignee}
        onCancel={handleCancel}
        centered={true}
        width={1150}
        footer={[
          <Button01 btnsize="wd_df" type="primary" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button01>,
        ]}
        className="custom-body-model-add-assignee"
      >
        <Row>
          <Col span={24} className="assignee-resource-col-left" style={{ height: 570 }}>
            <div className="assignee-resource-col">
              <Row>
                <Col className="assignee-resource-col-col-left">
                  <div className="header-assignee-layout">
                    <p className="assignee-label-custom">
                      <FormattedMessage id={`monitorTaskAddAssigneeTxtAssignee`} defaultMessage={`Assignee`} /> (
                      {memberAssigneeShow ? _.size(resorceDataAssign) : _.size(resorceDataAssign)})
                    </p>
                    <Input
                      prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={intl.formatMessage({ id: 'monitorCreateTaskAddAssigneeSearch', defaultMessage: 'Search…' })}
                      style={{ width: '210px' }}
                      onChange={(event) => handleSearchAssignee(event.target.value)}
                      value={searchValue}
                    />
                  </div>
                  <div className="body-assignee-layout">
                    <Table
                      className="assignee-resource-table"
                      scroll={{ y: 160 }}
                      dataSource={searchValue ? memberAssigneeShow : resorceDataAssign}
                      columns={columnsAssignee}
                      pagination={false}
                      loading={loadingView}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="assignee-resource-col-col-right">
                  <Tabs
                    className="custom-tab-assignee"
                    defaultActiveKey="1"
                    onTabClick={(key) => handleTab(key)}
                    tabBarExtraContent={operations}
                  >
                    <TabPane
                      tab={`${intl.formatMessage({
                        id: `monitorTaskAddAssigneeTabpaneAvalible`,
                        defaultMessage: `Available`,
                      })} (${memberAvailableTotal})`}
                      key="1"
                    >
                      <div className="body-assignee-layout">
                        <Table
                          className="assignee-resource-table"
                          scroll={{ y: 160 }}
                          dataSource={memberAvailableArrray}
                          columns={columns}
                          loading={loadingAvailable}
                          onChange={handleChangeAvailable}
                          pagination={{
                            total: memberAvailableTotal,
                            current: pageAssigneeAvailable,
                            pageSizeOptions: ['10', '20', '30', '40', '50'],
                            showSizeChanger: true,
                            locale: { items_per_page: '' },
                            showTotal: showTotal,
                            onChange: handlePaginationAvailable,
                            onShowSizeChange: handleSizeChangeAvailable,
                          }}
                        />
                        {memberAvailableArrray.length > 0 ? (
                          <div className="total-items-member">
                            <span style={{ fontSize: '13px' }}>
                              <FormattedMessage id="lblTotal" defaultMessage="Total" />
                              {` ${memberAvailableTotal || 0} `}
                              <FormattedMessage id="lblitems" defaultMessage="items" />
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </TabPane>
                    <TabPane
                      tab={`${intl.formatMessage({
                        id: `monitorTaskAddAssigneeTabpaneNotAvailable`,
                        defaultMessage: `Not Available`,
                      })} (${memberNotAvailableTotal})`}
                      key="2"
                    >
                      <div className="body-assignee-layout">
                        <Table
                          className="assignee-resource-table"
                          scroll={{ y: 160 }}
                          dataSource={memberNotAvailableArrray}
                          columns={columns}
                          loading={loadingNotAvailable}
                          onChange={handleChangeNotAvailable}
                          pagination={{
                            total: memberNotAvailableTotal,
                            current: pageAssigneeNotAvailable,
                            pageSizeOptions: ['10', '20', '30', '40', '50'],
                            showSizeChanger: true,
                            locale: { items_per_page: '' },
                            showTotal: showTotal,
                            onChange: handlePaginationNotAvailable,
                            onShowSizeChange: handleSizeChangeNotAvailable,
                          }}
                        />
                        {memberNotAvailableArrray.length > 0 ? (
                          <div className="total-items-member">
                            <span style={{ fontSize: '13px' }}>
                              <FormattedMessage id="lblTotal" defaultMessage="Total" />
                              {` ${memberNotAvailableTotal || 0} `}
                              <FormattedMessage id="lblitems" defaultMessage="items" />
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
