import httpClient from '../../components/axiosClient';

const getLicense = async (orgTeam, fieldChang, fliterByColumn, orderSort, fieldSort, moduleIdArr, paginationPage, paginationSize) => {

  const comCode = localStorage.getItem('comCode');
  const body = {
    orgId: orgTeam,
    licenseModuleId: moduleIdArr,
    search: fieldChang,
    searchBy: fliterByColumn,
    orderType: orderSort,
    orderBy: fieldSort,
    paging: paginationPage,
    rowsPerPages: paginationSize
  }

  try {
    const response = await httpClient.post(`/v3/license/manager/company/${comCode}/getdatalistlicense`, body);

    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }

}

export default getLicense;