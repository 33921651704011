import React, { useContext, useRef, useState } from "react";
import CustomerAction from "./action";
import { CustomerContext } from "./customer";
import { PageSettings } from "../../../config/page-settings";
import { Button, Popover, Table } from "antd";
import { FormattedMessage } from "react-intl";
import _ from 'lodash'

const CutomerTable = (props) => {
  const { customerData, newDataColumns, handleChange, linkToView, handleEditCustomer, handlerDelete,
    memComId, total, handlePagination, handleSizeChange, paginationPage } = useContext(CustomerContext)
  const ref = useRef();
  const { tabListName } = props
  const [visiblePopoverTable, setVisiblePopoverTable] = useState(false);
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };


  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG2', 'P8PG2A1') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => linkToView(record)}>
                          <FormattedMessage id="btnView" defaultMessage="View" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
              {/* <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {_.get(record, 'createdById') === memComId || checkPermissionAction('P8PG1C1', 'P8PG1C1A3') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditCustomer(record)}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P8PG1C1', 'P8PG1C1A4') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handlerDelete(record.customerId)}>
                          <p>
                            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                          </p>
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </> */}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };


  return (
    <div style={{ marginTop: '12px' }}>
      <CustomerAction />
      <div style={{ padding: '12px 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          className="customer-table"
          dataSource={customerData}
          columns={newDataColumns}
          rowKey={(record) => record.index}
          scroll={{ x: _.sumBy(newDataColumns, 'width') }}
          onChange={handleChange}
          pagination={{
            showTotal: showTotal,
            total: total,
            defaultCurrent: 1,
            current: paginationPage,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
            onChange: handlePagination,
            onShowSizeChange: handleSizeChange,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        {customerData && customerData.length !== 0 ? (
          <div className="total-items-timeAttendance">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CutomerTable