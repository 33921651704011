import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import { Row, Col, Tabs, Modal, Spin, Divider } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { TaskDetailContext } from './context';
import ModalTaskDetailInformation from './components/information';
import ModalTaskDetailAddress from './components/address';
import ModalTaskDetailStatus from './components/status';
import ModalTaskDetailTeam from './components/team';
import ModalTaskDetailAssignee from './components/assignee';
import ModalTaskDetailTracking from './components/tracking';
import Button02 from '../v2/button_02';
import SubTaskDetail from './components/subtask';

const { TabPane } = Tabs;

const ModalTaskDetailTab = (props) => {
  const intl = useIntl();
  const { config, setConfig } = props;
  const { state, setState, fnc } = useContext(TaskDetailContext);
  const { activeTab, loading } = state;
  const { handleChangeTabs, getData } = fnc;
  const assigneeDetail = _.get(state, 'detailData.information.assignee');

  useEffect(() => {
    if (_.get(config, 'visible')) getData(_.get(config, 'id'));
  }, [config]);

  useEffect(() => {
    const mapAssignee =
      assigneeDetail &&
      assigneeDetail.map((item) => {
        return item.memComId;
      });
    setState.setKeys(mapAssignee);
  }, [assigneeDetail]);

  console.log('state', state);

  const handleClose = () => {
    setConfig({
      visible: false,
      id: null,
      type: 'view',
    });
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: `monitorTaskDetailTiltle`, defaultMessage: 'Task Detail' })}
        onCancel={handleClose}
        visible={_.get(config, 'visible')}
        centered
        bodyStyle={{ padding: 'unset' }}
        width={1100}
        footer={[
          <Button02 style={{ margin: 'unset' }} key="back" btnsize="wd_df" onClick={handleClose}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
        ]}
      >
        <Spin spinning={loading}>
          <Row>
            <Col span={19}>
              <Tabs size="large" className="taskDetailTabs" animated={false} onTabClick={handleChangeTabs} activeKey={activeTab}>
                <TabPane
                  key="information"
                  tab={intl.formatMessage({ id: `taskDetailTabInformation`, defaultMessage: 'Information' })}
                  className="taskDetailTabsPanel"
                >
                  <ModalTaskDetailInformation />
                  <div style={{ padding: '0 24px ' }}>
                    <Divider type="horizontal" style={{ margin: 'unset' }} />
                  </div>
                  <ModalTaskDetailAddress />
                </TabPane>
                <TabPane
                  key="subTask"
                  tab={intl.formatMessage({ id: `taskDetailTabSubTask`, defaultMessage: 'Sub task' })}
                  className="taskDetailTabsPanel"
                >
                  <SubTaskDetail taskDetail={state.detailData} funcGetTask={fnc.getSubTaskData} />
                </TabPane>
                {/* <TabPane
                  key="map"
                  tab={intl.formatMessage({ id: `taskDetailTabMap`, defaultMessage: 'Map' })}
                  className="taskDetailTabsPanel"
                >
                </TabPane>
                <TabPane
                  key="moreDetail"
                  tab={intl.formatMessage({ id: `taskDetailTabMoreDetail`, defaultMessage: 'More Details' })}
                  className="taskDetailTabsPanel"
                >
                </TabPane>
                <TabPane
                  key="note"
                  tab={intl.formatMessage({ id: `taskDetailTabNote`, defaultMessage: 'Note' })}
                  className="taskDetailTabsPanel"
                >
                </TabPane>
                <TabPane
                  key="request"
                  tab={intl.formatMessage({ id: `taskDetailTabRequest`, defaultMessage: 'Request' })}
                  className="taskDetailTabsPanel"
                >
                </TabPane>
                <TabPane
                  key="worklog"
                  tab={intl.formatMessage({ id: `taskDetailTabWorkLog`, defaultMessage: 'History' })}
                  className="taskDetailTabsPanel"
                >
                </TabPane> */}
              </Tabs>
            </Col>
            <Col span={5}>
              <ModalTaskDetailStatus />
              <div className="taskDetailRightpanel">
                <ModalTaskDetailTeam />
                <ModalTaskDetailAssignee />
                <ModalTaskDetailTracking />
              </div>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </div>
  );
};

export default ModalTaskDetailTab;
