import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Icon, Dropdown, Menu } from 'antd';

import Button_01 from './v2/button_01';

const ButtonActionGroup = (props) => {
  const { menu } = props;

  return (
    <Dropdown trigger={['click']} overlay={menu} placement="bottomRight">
      <Button_01 type="primary" btnsize="wd_at" fontSize="sm" onClick={(e) => e.preventDefault()}>
        <FormattedMessage id="btnActionGroup" defaultMessage="Action" /> <Icon type="down" />
      </Button_01>
    </Dropdown>
  );
};

export default ButtonActionGroup;
