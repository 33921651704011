import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const searchPoExpense = async (body) => {
  try {
    const response = await httpClient.post(`/v1/po/manager/company/${comCode}/poheader/expense/report`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const ExportPoExpense = async (body) => {
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/expense/exportlistexpense`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getStatusList = async () => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/initial/status`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getBankList = async () => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/initial/banklist`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getCreditCardType = async () => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/initial/craditcardtype`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getReasonPayment = async () => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/initial/reasonpayment`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getExpenseTax = async () => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/initial/tax`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getSummaryData = async (payload) => {
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/expense/summaryoverdue`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getItemService = async (payload) => {
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/expense/findlistitembycategory/service`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getItemServiceNew = async (payload) => {
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/itemrecentlylog/searchitemmaster`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getListExpense = async (payload) => {
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/expense/listexpense`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getExpenseDetail = async (id) => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/detail/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getExpensePaymentDetail = async (id) => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/payment/detail/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getExpenseReason = async (id) => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/initial/reason/rejected`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateExpenseStatus = async (id, payload) => {
  try {
    const response = await httpClient.put(`/expense/manager/company/${comCode}/expense/updatestatus/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const saveExpesne = async (payload) => {
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/expense/createexpense`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const editExpesne = async (id, payload) => {
  try {
    const response = await httpClient.put(`/expense/manager/company/${comCode}/expense/update/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const deleteExpesne = async (id) => {
  try {
    const response = await httpClient.delete(`/expense/manager/company/${comCode}/expense/delete/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const createPaymentExpesne = async (payload) => {
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/expense/createexpensepayment`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateAttExpense = async (payload) => {
  try {
    const response = await httpClient.put(`/expense/manager/company/${comCode}/expense/uploadfile`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateAttPaymentExpense = async (payload) => {
  try {
    const response = await httpClient.put(`/expense/manager/company/${comCode}/expense/payment/uploadfile`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};


const deleteAttPaymentExpense = async (id) => {
  try {
    const response = await httpClient.delete(`/expense/manager/company/${comCode}/expensepaymentattachment/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const deleteAttExpense = async (id) => {
  try {
    const response = await httpClient.delete(`/expense/manager/company/${comCode}/expenseattachment/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};


const getAttExpense = async (id) => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/attachment/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getAttPaymentExpense = async (id) => {
  try {
    const response = await httpClient.get(`/expense/manager/company/${comCode}/expense/payment/attachment/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};


const cancelPaymentExpense = async (payload) => {
  try {
    const response = await httpClient.put(`/expense/manager/company/${comCode}/expense/cancelexpensepayment`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};



export {
  getStatusList, ExportPoExpense, getExpenseTax, getSummaryData, getItemService, getListExpense, getExpenseDetail,
  getExpenseReason, updateExpenseStatus, saveExpesne, deleteExpesne, getExpensePaymentDetail, getBankList,
  getCreditCardType, getReasonPayment, createPaymentExpesne, updateAttExpense, updateAttPaymentExpense, getAttExpense,
  getAttPaymentExpense, deleteAttPaymentExpense, editExpesne, deleteAttExpense, cancelPaymentExpense, searchPoExpense,
  getItemServiceNew
}