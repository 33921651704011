import React, { useContext, useEffect } from 'react';
import CollapseCustom from '../../../../../components/collapse-custom';
import { useIntl } from 'react-intl';
import DeliveryActionColumn from './action-column';
import { Input, Form, Select, DatePicker, Row, Col, Icon } from 'antd';
import LabeRequireForm from '../../../../../components/label-required-form';
import { DeliveryShipmentContext } from '..';
import _ from 'lodash';
import LoadmoreSaleOrder from '../../../../../components/loadmore/saleOrder';
import LoadmoreSaleOrderV2 from '../../../../../components/loadmore/saleOrderV2';

const { RangePicker } = DatePicker;
const { Option } = Select;

const DeliveryShipmentAction = () => {
  const intl = useIntl();
  const { searchShipment, orderData, customerData, onFilterOrder, searchOrder, setSearchOrder, selectBranch } = useContext(DeliveryShipmentContext);

  // console.log("customerData",customerData)
  console.log('selectBranch', selectBranch)

  useEffect(() => {
    setSearchOrder({})
  }, [])

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleDO', defaultMessage: 'DO NO.' })} req={false} />}
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderDO', defaultMessage: 'Enter DO No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'doNo')}
                value={_.get(searchOrder, 'doNo')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'deliveryFilterTitleOrderType', defaultMessage: 'Order Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderOrderType', defaultMessage: 'Select Order Type' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'orderType')}
                value={_.get(searchOrder, 'orderType')}
              >
                {_.map(_.get(orderData, 'data.orderTypes'), (item) => (
                  <Option key={item.orderTypeCode}>{item.orderTypeCode}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleTaskNo', defaultMessage: 'Task No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderTaskNo', defaultMessage: 'Enter Task No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'taskNo')}
                value={_.get(searchOrder, 'taskNo')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleCustomer', defaultMessage: 'Customer' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderCustomer', defaultMessage: 'Enter Customer' })}
                onChange={(e) => onFilterOrder(e.target.value, 'customer')}
                value={_.get(searchOrder, 'customer')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'deliveryFilterTitleDeliveryDate', defaultMessage: 'Delivery Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                allowClear={true}
                style={{ width: '100%' }}
                placeholder={[
                  intl.formatMessage({ id: 'deliveryFilterPlaceholderFormDate', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'deliveryFilterPlaceholderToDate', defaultMessage: 'Select To Date' }),
                ]}
                onChange={(value) => onFilterOrder(value, 'deliveryDate')}
                value={_.get(searchOrder, 'deliveryDate')}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitlePayment', defaultMessage: 'Payment' })} req={false} />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderPayment', defaultMessage: 'Select Payment' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'payment')}
                value={_.get(searchOrder, 'payment')}
              >
                {_.map(_.get(orderData, 'data.payments'), (item) => (
                  <Option key={item.code}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleStatus', defaultMessage: 'Status' })} req={false} />
              }
            >
              <Select
                allowClear={true}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderStatus', defaultMessage: 'Select Status' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                mode="multiple"
                maxTagCount={3}
                onChange={(value) => onFilterOrder(value, 'status')}
                value={_.get(searchOrder, 'status')}
              >
                {_.map(_.get(orderData, 'data.statusList'), (item) => (
                  <Option key={item.statusCode}>{item.statusTxt}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <LoadmoreSaleOrderV2
              data={searchOrder}
              setData={onFilterOrder}
              required={false}
              title={intl.formatMessage({ id: 'deliveryFilterTitleSoNo', defaultMessage: 'SO No' })}
              placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderSoNo', defaultMessage: 'Select SO No' })}
              id={selectBranch}
            />
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <Input
              placeholder={intl.formatMessage({ id: `deliveryOrderParcelsInput`, defaultMessage: 'Enter Tracking No. or Scan QR Code' })}
              prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
              suffix={<Icon type="scan" style={{ color: '#6490CF' }} />}
              style={{ marginRight: '12px' }}
              onChange={(e) => onFilterOrder(e.target.value, 'doNo')}
              value={_.get(searchOrder, 'doNo')}
              allowClear={true}
            />
            <DeliveryActionColumn />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default DeliveryShipmentAction;
