import React, { useState, useEffect, useContext } from 'react';
import { Card, Button, Icon } from 'antd';
import { TaskDetail } from '../../task-detail-context';
import { FormattedMessage } from 'react-intl';
import { getTaskNote } from '../../../../../../controllers/task/get-note';
import Note from '../../../../../../components/note-monitor/index';
import ModalAddNote from '../../../../../../components/note-monitor/modal-add-note/index';
import ModalEditNote from '../../../../../../components/note-monitor/modal-edit-note/index';
import _ from 'lodash';
import '../css/note.css';
import NoDataBackgroundModal from '../../../../../no-data-page/modal-no-data';

export default () => {
  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);

  const taskId = stateTask && stateTask.taskId ? _.get(stateTask, 'taskId') : undefined;

  const taskNo =
    stateTask && stateTask.viewInformationData.information ? _.get(stateTask.viewInformationData.information, 'taskNo') : undefined;

  const tabKey = stateTask && stateTask.tabKey ? _.get(stateTask, 'tabKey') : undefined;

  const [visibleAddNote, setVisibleAddNote] = useState(false);
  const [visibleEditNote, setVisibleEditNote] = useState(false);
  const [triggerNote, setTriggerNote] = useState(false);
  const [postData, setPostData] = useState([]);
  const [editPostData, setEditPostData] = useState();

  useEffect(() => {
    const getDataNote = async () => {
      if (tabKey === 'note' && taskId) {
        const response = await getTaskNote(taskId);
        setPostData(_.get(response, 'data'));
      }
    };
    getDataNote();
  }, [triggerNote, taskId, tabKey]);

  return (
    <div className="container-div" style={{ backgroundColor: `${postData.length !== 0 ? '#f2f3f5' : '#ffffff'}` }}>
      {postData.length !== 0 ? (
        <div className="container-div-component">
          <Note
            id={taskId}
            setPostData={setPostData}
            postData={postData}
            visible={visibleEditNote}
            setVisible={setVisibleEditNote}
            setEditPostData={setEditPostData}
            setTrigger={setTriggerNote}
          />
        </div>
      ) : (
        <div className="container-div-component-content">
          <Card className="task-status-card" style={{ height: '479px' }}>
            {/* <div className="task-status-content-detail">
              <Icon className="task-status-content-icon" type="profile" />
              <p className="task-status-content-text">
                <FormattedMessage
                  id='taskDetailNoNote'
                  defaultMessage="No Note" />
              </p>
            </div> */}
            <NoDataBackgroundModal text1={{ id: 'taskDetailNoNote', text: 'No Note' }} />
          </Card>
        </div>
      )}

      <div className="fab-container">
        <Button className="fab-button" shape="circle" icon="plus" onClick={() => setVisibleAddNote(true)} />
      </div>
      {visibleAddNote && (
        <ModalAddNote
          visible={visibleAddNote}
          setVisible={setVisibleAddNote}
          taskNo={taskNo}
          taskDetailId={taskId}
          setTrigger={setTriggerNote}
        />
      )}
      {visibleEditNote && (
        <ModalEditNote
          visible={visibleEditNote}
          setVisible={setVisibleEditNote}
          taskNo={taskNo}
          taskDetailId={taskId}
          setTrigger={setTriggerNote}
          editPostData={editPostData}
          setEditPostData={setEditPostData}
        />
      )}
    </div>
  );
};
