import React, { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_02 from '../../../../../components/v2/button_02';
import Button_01 from '../../../../../components/v2/button_01';
import { handleKeyDownDecimal2Fixed } from '../../../../../component-function/fnc-number';
import _ from 'lodash';
import { getWeight } from '../../../../../controllers/orders-controller/api';

const DeliveryWeightTotal = (props) => {
  const { visible, setVisible, weightDefault, data, setData } = props;
  const intl = useIntl();
  const [weight, setWeight] = useState('0.00');
  const [weightLocal, setWeightLocal] = useState('0.00');

  useEffect(() => {
    if (visible) {
      const fetchData = async () => {
        try {
          const response = await getWeight();
          const newData = response.weighlive;
          if (newData && newData !== weightLocal && newData !== 0 && newData !== "") {
            setWeightLocal(_.toString(newData));
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
      const interval = setInterval(fetchData, 5000);
      return () => clearInterval(interval);
    }
  }, [weightLocal, visible]);

  useEffect(() => {
    if (weightLocal) {
      setWeight(weightLocal);
    }
  }, [weightLocal]);


  useEffect(() => {
    if (weightDefault) {
      setWeight((weightDefault || 0).toFixed(2));
    }
  }, [weightDefault, visible]);

  const handleSave = () => {
    setData({ ...data, ['weight']: parseFloat(weight), ['amount']: calWaight(weight ? weight : 0) });
    setVisible(false);
  };

  const calWaight = (value) => {
    const calParcel = _.get(data, 'calculationFormulaForOrder') || '';
    const result = calParcel.replace('${weight}', value ? parseFloat(value) : 0);
    const calResult = new Function('return ' + result)();
    console.log('weighth',calParcel,'/',result,'/',calResult,'/',data);
    return calResult;
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    setWeight('0.00');
  };

  const handleChange = (value) => {
    setWeight(value);
  };

  const onBlur = () => {
    setWeight(_.toString(_.ceil(weight)));
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `deliveryWeightTotal`, defaultMessage: 'Edit Total Weight' })}
      centered={true}
      width={380}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_02 style={{ margin: 'unset' }} key="back" btnsize="wd_at" onClick={() => handleReset()} margin="unset">
          <FormattedMessage id="deliveryResetWeight" defaultMessage="Reset Weight" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <div style={{ textAlign: 'center' }}>
        <div style={{ color: '#1D3557', fontSize: '12px', fontWeight: 600, marginBottom: '8px' }}>
          <FormattedMessage id="deliveryWeightTotalText" defaultMessage="Total Weight(kg)" />
        </div>
        <div>
          <Input
            className="deliveryInputNumber"
            onKeyDown={handleKeyDownDecimal2Fixed}
            value={weight}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={onBlur}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeliveryWeightTotal;
