import React from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import { Icon, Dropdown, Menu } from 'antd';

const { Option } = Select;

const ButtonActionGroup = (props) => {
  const { handleSelectGroupBy, selectGroupBy } = props;
  return (
    <div>
      <Button_01 type="primary" btnwidth="wd_at" className="header-button-filter-load">
        <FormattedMessage id="btnActionGroup" defaultMessage="Action" /> <Icon type="down" />
      </Button_01>
    </div>
  );
};

export default ButtonActionGroup;