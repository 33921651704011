import React from 'react';
import { Table, Divider, Col, Row } from 'antd';
import Button03 from '../v2/button_03';
import Button01 from '../v2/button_01';
import styleIndex from './css/index.css';
import { useIntl, FormattedMessage } from 'react-intl';

export default function MaintenanceLabors({ columns, dataSource, setVisible }) {
  const intl = useIntl();

  return (
    <div className="labors">
      <div className="maintenance-container-header">
        <Row className="maintenance-container-row">
          <Col span={12} className="maintenance-header-lift">
            <span className="maintenance-header-title">Labors</span>
          </Col>
          <Col span={12} className="maintenance-header-right">
            <Button01 type="link" onClick={() => setVisible(true)}>
              {' '}
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button01>
          </Col>
        </Row>
      </div>
      <Divider className="maintenance-sub-divider" />
      <div className="maintenance-sub-report-table">
        <Table
          size="middle"
          columns={columns}
          dataSource={dataSource}
          rowKey={(record, index) => index}
          scroll={dataSource && dataSource.length !== 0 ? { y: 163 } : { x: 'max-content' }}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
}
