import React, { useRef, useCallback } from 'react';
import { GoogleMap, LoadScript, useLoadScript, Marker, withScriptjs, StandaloneSearchBox } from '@react-google-maps/api';
import Geocode from "react-geocode";
import _ from 'lodash';
import getSearchLocation from '../../function/search-location/getLocation';
import { AutoComplete, Icon } from 'antd'
import { useIntl } from 'react-intl'
import './css/index.css'
import AutoCompleteAddress from '../autocomplete-address/index'

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

export default ({ idMap, position, center, setLatlngTo, setValueAddress, addressArea, handleSearchLocation, handleSelect, children, visible }) => {

  const intl = useIntl();
  const libraries = ["places"];
  const mapContainerStyle = {
    height: `400px`,
    width: '100%',
    position: 'relative'
  }

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onDragEnd = async (evt) => {

    setLatlngTo({ lat: evt.latLng.lat(), lng: evt.latLng.lng() });
    let area = await getSearchLocation({ location: evt.latLng });

    setValueAddress(area.address);
  }

  return (
    <div>
      <GoogleMap
        id={`${idMap}`}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        <div className="service-area-autocomplete-div">
          {visible && (
            <AutoCompleteAddress
              addressArea={addressArea}
              handleSearchLocation={handleSearchLocation}
              handleSelect={handleSelect}
              children={children}
            />
          )}
         
        </div>
        <Marker
          position={position}
          animation={window.google.maps.Animation.DROP}
          draggable={true}
          onDragEnd={onDragEnd}
        />
      </GoogleMap>
    </div>
  )
}
