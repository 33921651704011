import { Table, Card, Col, Row, Tabs, Button, Icon, Menu, Dropdown, Popover, Checkbox } from 'antd';
import React, { useContext, useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Css from './css/index.css';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { RepairticketContext } from '../repair-ticket-context';
import NoDataBackground from '../../../../components/no-data-page';
import Button_01 from '../../../../components/v2/button_01';
import { PageSettings } from '../../../../config/page-settings';

const { TabPane } = Tabs;

const RightCardRepairTicket = () => {
  const { state, setState, fnc } = useContext(RepairticketContext);
  const { checkedKeysRequest, checkedKeysTeam, isLoading, toggle, selectedRowKeys, ticketData, page, size, total } = state;
  const { setToggle, setEvent, setSort } = setState;
  const { handleChangePage, handleChangeSize, handleVisibleCreate, handleView, handleEdit } = fnc;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(2800);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);

  const data = _.get(ticketData, 'data.ticketList');

  const setShowColumn = {
    index: true,
    no: true,
    ticketDate: true,
    ticketNo: true,
    ticketDetail: true,
    status: true,
    assetno: true,
    serialno: true,
    itemrequestno: true,
    itemcode: true,
    itemname: true,
    itemgroup: true,
    cost: true,
    qty: true,
    amount: true,
  };

  const setShowColumnArr = [
    'index',
    'no',
    'ticketDate',
    'ticketNo',
    'ticketDetail',
    'status',
    'assetno',
    'serialno',
    'itemrequestno',
    'itemcode',
    'itemname',
    'itemgroup',
    'cost',
    'qty',
    'amount',
  ];

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: <FormattedMessage id="AllTicketTicketNo" defaultMessage="Ticket No." />,
      dataIndex: 'ticketNo',
      key: 'ticketNo',
      sorter: true,
      fixed: 'left',
      width: 160,
    },
    {
      title: <FormattedMessage id="AllTicketdate" defaultMessage="Ticket Date" />,
      key: 'ticketDate',
      dataIndex: 'ticketDate',
      width: 180,
      sorter: true,
    },
    {
      title: <FormattedMessage id="AllTicketdetail" defaultMessage="Ticket Details" />,
      key: 'ticketDetail',
      dataIndex: 'ticketDetail',
      width: 400,
    },
    {
      title: <FormattedMessage id="AllTicketStatus" defaultMessage="Status" />,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 150,
      render: (data, record) => {
        return (
          <span>
            <p
              style={{
                backgroundColor: '#F88512',
                // backgroundColor: _.get(record, 'statusColor'),
                color: 'white',
                textAlign: 'center',
                borderRadius: '10px',
                height: '23px',
                width: '95px',
              }}
            >
              {data}
            </p>
          </span>
        );
      },
    },
    {
      title: <FormattedMessage id="RepairTicketAssetNo" defaultMessage="Asset No." />,
      key: 'assetno',
      dataIndex: 'assetno',
      sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketSerialNo" defaultMessage="Serial No." />,
      key: 'serialno',
      dataIndex: 'serialno',
      sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketItemNo" defaultMessage="Item Request No." />,
      key: 'itemrequestno',
      dataIndex: 'itemrequestno',
      sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketItemCode" defaultMessage="Item Code" />,
      key: 'itemcode',
      dataIndex: 'itemcode',
      sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketItemName" defaultMessage="Item Name" />,
      key: 'itemname',
      dataIndex: 'itemname',
      sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketItemGroup" defaultMessage="Item Group" />,
      key: 'itemgroup',
      dataIndex: 'itemgroup',
      sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketCost" defaultMessage="Cost" />,
      key: 'cost',
      dataIndex: 'cost',
      // sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketItemQty" defaultMessage="Qty" />,
      key: 'qty',
      dataIndex: 'qty',
      sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="RepairTicketItemAmount" defaultMessage="Amount" />,
      key: 'amount',
      dataIndex: 'amount',
      sorter: true,
      // width: 200,
    },
  ];

  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreColumnButton = ['index', 'no', 'ticketDate', 'ticketNo'];
  const columnNew = columns.filter((col) => !ignoreColumnButton.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);


  const menuColumn = () => {
    return (
      // <PageSettings.Consumer>
      //   {({ checkPermissionAction }) => (
      <Menu>
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) ? ( */}
        <Menu.Item>
          <Button ghost type="link" onClick={handleVisibleCreate}>
            <FormattedMessage id="btnCreate" defaultMessage="Create" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? (
              <Menu.Item>
                <Button ghost type="link" onClick={() => []}>
                  <FormattedMessage id="ToReviewReject" defaultMessage="Reject" />
                </Button>
              </Menu.Item>
            ) : null} */}
      </Menu>
      //   )}
      // </PageSettings.Consumer>
    );
  };

  // const menuColumn = (
  //   <PageSettings.Consumer>
  //     {({ checkPermissionAction }) => (
  //       <Menu>
  //         {checkPermissionAction(`P1PG1`, `P1PG1A1`) ? (
  //           <Menu.Item key="0">
  //             <p onClick={() => handleVisibleCreate()}>
  //             <FormattedMessage id="AllticketCreate" defaultMessage="Create" />
  //             </p>
  //             {/* <Button ghost type="link" onClick={handleVisibleCreate}>

  //             </Button> */}
  //           </Menu.Item>
  //         ) : null}
  //         {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? (
  //             <Menu.Item>
  //               <Button ghost type="link" onClick={() => []}>
  //                 <FormattedMessage id="ToReviewReject" defaultMessage="Reject" />
  //               </Button>
  //             </Menu.Item>
  //           ) : null} */}
  //       </Menu>
  //     )}
  //   </PageSettings.Consumer>
  // );

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={handleToggle}>
          <Icon type={toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="Repairticket" defaultMessage="Repair Ticket" />
          </p>
        </div>
      </div>
    );
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        {/* <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                    {console.log('statusrecord', _.get(record, 'status'))}
                    {_.get(record, 'status') === 'Open' || _.get(record, 'status') === 'Draft' ? (
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button>
                    ) : null}
                  </>
                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover> */}
      </div>
    );
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  const menuSelectColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox value={item.key} onChange={checkedValuecolumns} checked={defaultShowColumn[item.key] ? true : false}>
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>{textErrorSelectColumn}</Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 3) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  return (
    <div>
      {_.size(checkedKeysRequest) !== 0 || _.size(checkedKeysTeam) !== 0 ? (
        <PageSettings.Consumer>
          {({ checkPermissionAction }) =>
            checkPermissionAction(`P1PG1`, `P1PG1A1`) ? (
              <Card
                title={<HeaderAndToggle />}
                className="right-cards"
                // extra={
                //   <div className="display-extra-button-group" style={{ padding: '0px 0px' }}>
                //     {/* <ButtonActionGroup menu={menuColumn} /> */}
                //     <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                //       <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                //         <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                //         <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                //       </Button_01>
                //     </Dropdown>
                //   </div>
                // }
              >
                <Row>
                  <Col span={19}></Col>
                  <Col span={3} style={{ margin: '15px 0px 0px 56px' }}>
                    <Dropdown
                      overlay={menuSelectColumn}
                      trigger={['click']}
                      placement="bottomRight"
                      onVisibleChange={handleVisibleChange}
                      visible={visible}
                    >
                      <Button_01 key="submit" type="primary" btnsize="wd_df" fontSize="sm" onClick={() => {}}>
                        <FormattedMessage id="reportReapirTicketReportBtnColumns" defaultMessage="Columns" />
                        <DownOutlined style={{ fontSize: '14px', paddingLeft: '4%' }} />
                      </Button_01>
                    </Dropdown>
                  </Col>
                </Row>

                <div className="containerTables" onMouseLeave={handleMouseLeave}>
                  <Row gutter={16}>
                    <Table
                      // className="custom-table-claim scroll-sm"
                      dataSource={data || []}
                      columns={newDataColumns}
                      scroll={{ x: scrollTableX, y: `calc(100vh - 366px)` }}
                      loading={isLoading}
                      onChange={onChangeTable}
                      pagination={{
                        total: totalItems,
                        current: page,
                        defaultCurrent: 1,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        showSizeChanger: true,
                        locale: { items_per_page: '' },
                        showTotal: showTotal,
                        onChange: handleChangePage,
                        onShowSizeChange: handleChangeSize,
                      }}
                      // onRow={(record, rowIndex) => {
                      //   return {
                      //     onClick: (event) => {
                      //       if (!event.target.href) {
                      //         const { x, y } = ref.current.getBoundingClientRect();
                      //         setVisiblePopoverTable(true);
                      //         setOffsetPopover([event.pageX - x, event.pageY - y]);
                      //         setRecordPopover(record);
                      //       }
                      //     },
                      //   };
                      // }}
                    />
                    {/* {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)} */}
                    <div className="total-items-member">
                      <span style={{ fontSize: '13px' }}>
                        <FormattedMessage id="lblTotal" defaultMessage="Total" />
                        {` ${totalItems || 0} `}
                        <FormattedMessage id="lblitems" defaultMessage="items" />
                      </span>
                    </div>
                  </Row>
                </div>
              </Card>
            ) : null
          }
        </PageSettings.Consumer>
      ) : (
        <div style={{ padding: '24px', backgroundColor: '#fff' }}>
          <Card className="detail-card-blank-page">
            <NoDataBackground
              text1={{ id: 'nodataRepairTicket1', text: 'Please select a Request' }}
              text2={{ id: 'nodataRepairTicket2', text: 'Request will appear here.' }}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default RightCardRepairTicket;
