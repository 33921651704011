import React from 'react';
import { Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';

const ButtonApprove = ({ onClick, record }) => {
  return (
    <div>
      <Button
        style={{ width: 100 }}
        type="link"
        ghost
        onClick={() => onClick('approve', record)}
      >
        <p><FormattedMessage id="btnApprove" defaultMessage="Approve" /></p>
      </Button>
    </div>
  )
}

const ButtonReject = ({ onClick, record }) => {
  return (
    <div>
      <Button
        style={{ width: 100 }}
        type="link"
        ghost
        onClick={() => onClick('reject', record)}
      >
        <p><FormattedMessage id="btnReject" defaultMessage="Reject" /></p>
      </Button>
    </div>
  )
}

const ButtonView = ({ onClick, record }) => {
  return (
    <div>
      <Button
        style={{ width: 100 }}
        type="link"
        ghost
        onClick={() => onClick(record)}
      >
        <p><FormattedMessage id="btnView" defaultMessage="View" /></p>
      </Button>
    </div>
  )
}

const ButtonEdit = ({ onClick, record }) => {
  return (
    <div>
      <Button
        style={{ width: 100 }}
        type="link"
        ghost
        onClick={() => onClick(record)}
      >
        <p><FormattedMessage id="btnEdit" defaultMessage="Edit" /></p>
      </Button>
    </div>
  )
}

const ButtonCancel = ({ onClick, record }) => {
  return (
    <div>
      <Button
        style={{ width: 100 }}
        type="link"
        ghost
        onClick={() => onClick('cancel', record)}
      >
        <p><FormattedMessage id="btnCancel" defaultMessage="Cancel" /></p>
      </Button>
    </div>
  )
}

const ButtonDelete = ({ onClick, record }) => {
  return (
    <div>
      <Button
        style={{ width: 100 }}
        type="link"
        ghost
        onClick={() => onClick('deleted', record)}
      >
        <p><FormattedMessage id="btnDelete" defaultMessage="Delete" /></p>
      </Button>
    </div>
  )
}

export { ButtonApprove, ButtonReject, ButtonView, ButtonEdit, ButtonCancel, ButtonDelete };