import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import WarehouseContext from '../../context';
import '../css/index.css';
import Details from './warehouse-detail';
import Manager from './warehouse-manager';
import Member from './warehouse-member';

const DetailsPage = ({ }) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);

  return (
    <div className='warehouse-layout-content warehouse-tabs-details scroll-sm'>
      <Details />
      <Manager roleCheck={state.roleCheck} />
      <Member warehouseId={state.warehouseId} setMainTrigger={setState.setTrigger} roleCheck={state.roleCheck} />
    </div>
  );
};

export default DetailsPage;
