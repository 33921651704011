import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon, Tag, Popover, Popconfirm, Row, Modal, Button } from 'antd';
import getMaintenanceReport from '../../controllers/maintenance-report/get-maintenance-report';
import ActionReportV2 from '../../components/action-report/action-report-v2';
import MaintenanceReportTable from './maintenance-report-table.jsx';
import CreateMaintenanceReport from '../../components/create-maintenance-report/index';
import EditMaintenanceReport from '../../components/edit-maintenance-report/index';
import _, { debounce } from 'lodash';
import moment from 'moment';
import { successNotification, errorNotification, warningNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import Button01 from '../../components/v2/button_01.js';
import httpClient from '../../components/axiosClient';
import './css/index.css';

const today = new Date();
const { confirm } = Modal;

const MaintenanceReport = (props) => {
  const { pageCode } = props;
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const [visiblePopover, setVisiblePopover] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsDocNo', defaultMessage: 'Doc No.' }),
      dataIndex: 'referenceDocNo',
      key: 'referenceDocNo',
      width: 150,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      width: 150,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsItemNo', defaultMessage: 'Item No.' }),
      dataIndex: 'itemNo',
      key: 'itemNo',
      width: 180,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsItemName', defaultMessage: 'Item Name' }),
      dataIndex: 'itemName',
      key: 'itemName',
      width: 150,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsVendorName', defaultMessage: 'Vendor Name' }),
      dataIndex: 'businessPartnerName',
      key: 'businessPartnerName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsVendorAddress', defaultMessage: 'Vendor Address' }),
      dataIndex: 'businessPartnerAddress',
      key: 'businessPartnerAddress',
      width: 400,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsVendorPhone', defaultMessage: 'Vendor Phone' }),
      dataIndex: 'businessPartnerPhone',
      key: 'businessPartnerPhone',
      width: 200,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsMaintenanceDate', defaultMessage: 'Maintenance Date' }),
      dataIndex: 'maintenanceServiceDate',
      key: 'maintenanceServiceDate',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'maintenanceColumnsNextMaintenanceDate', defaultMessage: 'Next Maintenance Date' }),
      dataIndex: 'nextMaintenanceServiceDate',
      key: 'nextMaintenanceServiceDate',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (record.nextMaintenanceServiceDate === '' ? ' - ' : record.nextMaintenanceServiceDate),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 70,
      render: (text, record, index) => fncActionTable(record, index),
    },
  ];

  const setShowColumn = {
    index: true,
    referenceDocNo: true,
    itemCode: true,
    itemNo: true,
    itemName: true,
    businessPartnerName: true,
    businessPartnerAddress: true,
    businessPartnerPhone: true,
    maintenanceServiceDate: true,
    nextMaintenanceServiceDate: true,
    action: true,
  };

  const setShowColumnArr = [
    'index',
    'referenceDocNo',
    'itemCode',
    'itemNo',
    'itemName',
    'businessPartnerName',
    'businessPartnerAddress',
    'businessPartnerPhone',
    'maintenanceServiceDate',
    'nextMaintenanceServiceDate',
    'action',
  ];

  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreFilterColumns = ['index', 'referenceDocNo', 'itemCode', 'itemNo', 'itemName', 'action'];
  const ignoreSearchColumns = ['index', 'action', 'maintenanceServiceDate', 'nextMaintenanceServiceDate'];

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState(false);

  const [maintenanceData, setMaintenanceData] = useState([]);
  const [defalseMaintenData, setDefalseMaintenData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [fieldChang, setFieldChang] = useState('');
  const [fliterByColumn, setFliterByColumn] = useState('all');

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [visible, setVisible] = useState(false);

  const [startDate, setStartDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [dateTrigger, setDateTrigger] = useState('');

  const [triggerAPI, setTriggerAPI] = useState(false);

  const [viewMaintenance, setViewMaintenance] = useState();

  useEffect(() => {
    async function getAPI() {
      setLoading(true);
      const response = await getMaintenanceReport({
        fieldChang,
        fliterByColumn,
        startDate,
        endDate,
        fieldSort,
        orderSort,
        paginationPage,
        paginationSize,
      });
      await setMaintenanceData(response.data);
      await setTotal(response.allRows);
      await setLoading(false);
    }
    getAPI();
  }, [dateTrigger, triggerAPI, fieldChang, fliterByColumn, extraSort]);

  const onChangeSearch = debounce((value) => {
    setFieldChang(value);
  }, 500);

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const handleOpenModal = async () => {
    setVisibleModal(true);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];

    if (listArrayColumns.length < 7) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function (result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const onSelectRangePicker = (date, dateString) => {
    setStartDate(moment(date[0]).format('YYYY-MM-DD'));
    setEndDate(moment(date[1]).format('YYYY-MM-DD'));
    setDateTrigger(dateString);
    setPaginationPage(1);
  };

  const modalConfirmDelete = (record) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.put(`/v3/maintenance/manager/company/${comCode}/delete-maintenance/${record.maintenanceId}`);
          if (response.status === 200) {
            successNotification(response.data.status.message);
            setTriggerAPI((current) => !current);
          }
        } catch (error) {
          if (error.response.status === 500) {
            errorNotification(error.response.data.error);
          }
        }
      },
      onCancel() { },
    });
  };

  const modalConfirmEdit = (record, index, check) => {
    setViewMaintenance(check);
    setDefalseMaintenData(record);
    setVisibleModalEdit(true);
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const fncActionTable = (record, index) => {
    return (
      <div>
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction(`${pageCode}PG1C1`, `${pageCode}PG1C1A3`) ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => modalConfirmEdit(record, index, true)}>
                          <FormattedMessage id="btnView" defaultMessage="View" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction(`${pageCode}PG1C1`, `${pageCode}PG1C1A3`) ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => modalConfirmEdit(record, index)}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction(`${pageCode}PG1C1`, `${pageCode}PG1C1A4`) ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => modalConfirmDelete(record)}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      </div>
    );
  };

  return (
    <div className="maintenance-report">
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {checkPermissionAction(`${pageCode}PG1C1`, `${pageCode}PG1C1A1`) ? (
              <div>
                <Card
                  className="maintenance-report-card"
                  title={intl.formatMessage({ id: 'menuAssetMaintenance', defaultMessage: 'Asset Maintenance' })}
                  extra={
                    <div className="space-create">
                      {checkPermissionAction(`${pageCode}PG1C1`, `${pageCode}PG1C1A2`) ? (
                        <Button01 btnsize="wd_lg" className="maintenance-card-extra2" type="primary" onClick={() => handleOpenModal()}>
                          <FormattedMessage id="maintenanceReportBtnCreateMaintenanceReport" defaultMessage="Create Maintenance Report" />
                        </Button01>
                      ) : null}
                    </div>
                  }
                >
                  <div className="action-maintenance-container">
                    <ActionReportV2
                      value={{
                        columns,
                        ignoreFilterColumns,
                        ignoreSearchColumns,
                        fliterByColumn,
                        visible,
                        defaultShowColumn,
                        textErrorSelectColumn,
                      }}
                      fnc={{
                        onChangeSearch,
                        onFliterByColumn,
                        onSelectRangePicker,
                        checkedValuecolumns,
                        handleCheckOk,
                        handleReset,
                        handleVisibleChange,
                        handleOnVisiblecolumns,
                      }}
                    />
                  </div>

                  <MaintenanceReportTable
                    loading={loading}
                    dataSource={maintenanceData}
                    columns={newDataColumns}
                    total={total}
                    currentPage={currentPage}
                    paginationPage={paginationPage}
                    fnc={{
                      handleChange,
                      handlePagination,
                      handleSizeChange,
                    }}
                  />
                </Card>
              </div>
            ) : null}

            <CreateMaintenanceReport visibleModal={visibleModal} setVisibleModal={setVisibleModal} setTriggerAPI={setTriggerAPI} />

            <EditMaintenanceReport
              visibleModal={visibleModalEdit}
              setVisibleModal={setVisibleModalEdit}
              setTriggerAPI={setTriggerAPI}
              defalseMaintenData={defalseMaintenData}
              setDefalseMaintenData={setDefalseMaintenData}
              view={viewMaintenance}
            />
          </div>
        )}
      </PageSettings.Consumer>
    </div>
  );
};

export default MaintenanceReport;
