import httpClient from '../../components/axiosClient';

const updateTravelExpenses = async (tab, fromDate, toDate, fieldChang, fliterByColumn, paginationPage, paginationSize, orgId, status, updateAll, listRow ,checkedAll) => {
  const comCode = localStorage.getItem('comCode');

  const body = {
    orgId: String(orgId),
    members: checkedAll,
    search: [{
      key: fliterByColumn,
      value: fieldChang,
    }],
    limit: paginationSize,
    paging: 1,
    startDate: fromDate,
    endDate: toDate,
    updateToStatus: status,
    updateAll: updateAll,
    listUpdateTravelExpenses: listRow
  }


  try {
    const response = await httpClient.put(`/v3/task/manager/company/${comCode}/search-update/travel-exprenses/${tab}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default updateTravelExpenses;