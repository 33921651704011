import React, { useState, useEffect } from 'react';
import { Modal, Card, Button, Input, Form, Row, Col, Radio, Divider, InputNumber, Checkbox } from 'antd';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import '../css/index.css';
import _ from 'lodash';
import LabeRequireForm from '../../label-required-form';
import { handleKeyDownNumber } from '../../../component-function/fnc-number';

const ModalRequestItem = (props) => {
  const { visible, setVisible, data, form, basket, setBasket } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const intl = useIntl();
  const [inputValue, setInputValue] = useState(1);
  const [initialForm, setInitialForm] = useState([]);
  const [warningUOM, setWarningUOM] = useState(false);

  useEffect(() => {
    if (visible) {
      const filterDefault = _.filter(basket, (item) => {
        return item.itemMasterId === _.get(data, 'itemMasterId');
      });
      if (_.size(filterDefault) !== 0) {
        setInputValue(_.get(filterDefault, '[0].requireAmount'));
        setInitialForm(_.get(filterDefault, '[0].options.topicOptions'));
      }
    }
  }, [basket, visible]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (inputValue !== 0 && _.get(data, 'uom.uom_id')) {
        let cloneData = data;
        cloneData.options.topicOptions = _.map(cloneData.options.topicOptions, (item) => {
          let cloneArry = { ...item };
          cloneArry.options = _.map(item.options, (e) => {
            const valueForm = values[item.topicId];
            // console.log("array", item)
            if (item.topicTypeCode === 'single') {
              return {
                ...e,
                select: valueForm === e.name ? true : false,
              };
            } else {
              const filterValue = _.filter(valueForm, (o) => {
                return o === e.name;
              });
              return {
                ...e,
                select: _.size(filterValue) > 0 ? true : false,
              };
            }
          });
          return cloneArry;
        });
        const body = {
          ...cloneData,
          requireAmount: parseFloat(inputValue),
          uomId : _.get(cloneData,'uom.uom_id'),
        };
        const filterDefault = _.filter(basket, (item) => {
          return item.itemMasterId === _.get(data, 'itemMasterId');
        });
        if (_.size(filterDefault) !== 0) {
          const mapNew = _.map(basket, (item) => {
            let clone = { ...item };
            clone.requireAmount = item.itemMasterId === _.get(data, 'itemMasterId') ? parseFloat(inputValue) : item.requireAmount;
            return clone;
          });
          setBasket(mapNew);
        } else {
          setBasket((prevItems) => [...prevItems, body]);
        }
        setWarningUOM(false)
        setVisible(false);
        setInputValue(1);
        setInitialForm([]);
        resetFields();
      } else if(_.get(data, 'uom.uom_id') === undefined) {
        setWarningUOM(true)
      }
      
      else {
        const filterDefault = _.filter(basket, (item) => {
          return item.itemMasterId !== _.get(data, 'itemMasterId');
        });
        setBasket(filterDefault);
        setVisible(false);
        setInputValue(1);
        setInitialForm([]);
        resetFields();
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setInputValue(1);
    resetFields();
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const handleClickCal = (type) => {
    if (type === 'add') {
      setInputValue(parseFloat(inputValue) + 1);
    } else {
      setInputValue(parseFloat(inputValue) - 1);
    }
  };

  const handleChangeStock = (e) => {
    if (e) {
      setInputValue(parseFloat(e));
    }
    else{
      setInputValue(parseFloat(1));
    }
    
  };

  const disbleplus = () => {
    if (parseFloat(inputValue) >= _.get(data, 'inStock')) {
      return true;
    } else {
      return false;
    }
  };

  const disbleminus = () => {
    if (parseFloat(inputValue) < 1 || _.get(data, 'inStock') === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalRequestItemText', defaultMessage: 'Request Item' })}
      centered={true}
      visible={visible}
      width={668}
      onCancel={handleCancel}
      bodyStyle={{ padding: 'unset', maxHeight: '454px', overflowY: 'scroll' }}
      zIndex={999}
      footer={[
        <span style={{ color: '#E73845', fontSize: '14px' ,display: warningUOM === true ? null : 'none'}}>
          <FormattedMessage id="warningUOM" defaultMessage="No UOM ID" />
        </span>
        ,
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleSave}
          disabled={_.get(data, 'inStock') === 0  ? true : false}
        >
          <FormattedMessage id="btnConfirm" defaultMessage="Confirm" />
        </Button01>,
      ]}
    >
      <div className="modalRequestItemBackground">
        <Card style={{ borderRadius: '10px' }} bodyStyle={{ padding: '16px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              {_.get(data, 'isHavaImg') ? (
                <div>
                  <img src={_.get(data, 'img')} className="modalRequestItemImg" />
                </div>
              ) : null}
              <div style={{ marginLeft: _.get(data, 'isHavaImg') ? '16px' : '0' }}>
                <div className="modalRequestItemName">{_.get(data, 'itemMasterName') + ' ' + _.get(data, 'branName')}</div>
                <div className="modalRequestItemWarehouse">{_.get(data, 'warehouseName')}</div>
                <div className="modalRequestItemStock">
                  <FormattedMessage id="searchItemRequestTextStock" defaultMessage="In Stock" /> : {_.get(data, 'inStockTxt')}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Button className="modalRequestItemButton" onClick={() => handleClickCal('minus')} disabled={disbleminus()}>
                <span style={{ padding: '0px 2px' }}>-</span>
              </Button>
              <InputNumber
                className="modalRequestItemInput"
                value={_.get(data, 'qty') === 0 ? 0 : inputValue}
                onChange={(e) => handleChangeStock(e)}
                onKeyDown={handleKeyDownNumber}
                disabled={_.get(data, 'qty') === 0 ? true : false}
                max={_.get(data, 'qty')}
                min={0}
              />
              <Button className="modalRequestItemButton" onClick={() => handleClickCal('add')} disabled={disbleplus()}>
                +
              </Button>
            </div>
          </div>
        </Card>
        {data && _.size(data.options.topicOptions) > 0 ? (
          <Card
            title={intl.formatMessage({ id: 'modalRequestItemOptionText', defaultMessage: 'Option' })}
            style={{ marginTop: '16px', borderRadius: '10px' }}
            bodyStyle={{ padding: '24px' }}
          >
            <Form form={form} layout="vertical">
              <Row>
                {_.map(_.get(data, 'options.topicOptions'), (item, index) => {
                  const filterTopic = _.filter(initialForm, (e) => {
                    return e.topicId === item.topicId;
                  });
                  const filterTrue = _.filter(_.get(filterTopic, '[0].options'), (e) => {
                    return e.select === true;
                  });
                  const mapMulti = _.map(_.get(filterTopic, '[0].options'), (e) => {
                    return e.select === true ? e.name : null;
                  });
                  const iniData =
                    _.get(filterTopic, '[0].topicTypeCode') === 'single'
                      ? _.get(filterTrue, '[0].name')
                      : _.filter(mapMulti, (e) => {
                          return e !== null;
                        });
                  return (
                    <Col span={24}>
                      {index !== 0 ? <Divider type="horizontal" /> : null}
                      {item.topicTypeCode === 'single' ? (
                        <Form.Item
                          label={
                            <div style={{ fontWeight: 'bold' }}>
                              <LabeRequireForm text={item.topicName} req={item.required} />
                            </div>
                          }
                        >
                          {getFieldDecorator(item.topicId, {
                            initialValue: iniData ? iniData : undefined,
                            rules: [
                              {
                                required: item.required,
                                message: intl.formatMessage({ id: 'modalRequestItemOptionValidateText', defaultMessage: 'Please Select' }),
                              },
                            ],
                          })(
                            <Radio.Group>
                              {_.map(item.options, (e) => (
                                <Radio value={e.name} style={radioStyle}>
                                  {e.name}
                                </Radio>
                              ))}
                            </Radio.Group>
                          )}
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label={
                            <div style={{ fontWeight: 'bold' }}>
                              <LabeRequireForm text={item.topicName} req={item.required} />
                            </div>
                          }
                        >
                          {getFieldDecorator(item.topicId, {
                            initialValue: iniData ? iniData : undefined,
                            rules: [
                              {
                                required: item.required,
                                message: intl.formatMessage({ id: 'modalRequestItemOptionValidateText', defaultMessage: 'Please Select' }),
                              },
                            ],
                          })(
                            <Checkbox.Group>
                              <Row gutter={[16, 16]}>
                                {_.map(item.options, (e) => (
                                  <Col span={24}>
                                    <Checkbox value={e.name}>{e.name}</Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Checkbox.Group>
                          )}
                        </Form.Item>
                      )}
                      {/* <Divider type="horizontal" /> */}
                      {/* {
                            index !== (_.size(data.options) - 1) ? (
                              <Divider type="horizontal" />
                            ) : null
                          } */}
                    </Col>
                  );
                })}
              </Row>
            </Form>
          </Card>
        ) : null}
      </div>
    </Modal>
  );
};

const ModalFormRequestItem = Form.create({
  name: 'modal_form',
})(ModalRequestItem);

export default ModalFormRequestItem;
