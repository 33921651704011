import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Avatar, Icon, Modal, Table, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { CreateTaskContext } from './create-task-context';
import _ from 'lodash';
import Button02 from '../../../v2/button_02';


const { TabPane } = Tabs;



export default ({ visibleModal, setVisibleModal }) => {
  const { CreateState, CreateSetState, CreateFNC } = useContext(CreateTaskContext);

  const intl = useIntl();
  const handleCancel = () => {
    CreateSetState.setVisibleAssignee(false)
  }



  useEffect(() => {
    setData();
  }, [CreateState.dataMemberAssignee])

  // useEffect(() => {
  //   setFilterAvailable();
  //   setFilterCandidate();
  //   setFilterOff();
  //   setFilterOnGoing();

  // }, [CreateState.refreshDataTeam])

  const defaultData = CreateState.member;
  const dataAssignee = _.get(CreateState.member, 'assignee');
  const dataAvailable = _.get(CreateState.member, 'available');
  const dataCandidates = _.get(CreateState.member, 'candidates');
  const dataOff = _.get(CreateState.member, 'off');
  const dataOnGoing = _.get(CreateState.member, 'onGoing');

  const [puchMemCom, setPuchMemCom] = useState([])
  const [filterAssignee, setFilterAssignee] = useState()
  const [filterAvailable, setFilterAvailable] = useState()
  const [filterCandidate, setFilterCandidate] = useState()
  const [filterOff, setFilterOff] = useState()
  const [filterOnGoing, setFilterOnGoing] = useState()
  const [tabKey, setTabKey] = useState()

  const handleAssign = (record, index) => {
    CreateSetState.setDataMemberAssignee(elements => [...elements, record]);

  }

  const handleTab = (key) => {
    setTabKey(key);
  }


  const setData = () => {
    if (CreateState.dataMemberAssignee !== [] && CreateState.dataMemberAssignee !== undefined) {
      const mapMemCom = CreateState.dataMemberAssignee.map((e) => {
        return e.memComId
      })
      if (tabKey == 4) {
        const filterdata = dataOff !== undefined && dataOff?.filter((col) => !mapMemCom.includes(col.memComId))
        setFilterOff(filterdata)
      } else if (tabKey == 3) {
        const filterdata = dataOnGoing !== undefined && dataOnGoing?.filter((col) => !mapMemCom.includes(col.memComId))
        setFilterOnGoing(filterdata)
      }
      else if (tabKey == 2) {
        const filterdata = dataAvailable !== undefined && dataAvailable?.filter((col) => !mapMemCom.includes(col.memComId))
        setFilterAvailable(filterdata)
      }
      else {
        const filterdata = dataCandidates !== undefined && dataCandidates?.filter((col) => !mapMemCom.includes(col.memComId))
        setFilterCandidate(filterdata)
      }
    }

  }

  const handelRemove = (index, record) => {
    const resourceGroup = record.resourceGroup;
    const removeMemComID = record.memComId
    if (resourceGroup == 'off') {
      setFilterOff(oldArray => [...oldArray, record])
    }
    else if (resourceGroup == 'candidates') {
      setFilterCandidate(oldArray => [...oldArray, record])
    }
    else if (resourceGroup == 'available') {
      setFilterAvailable(oldArray => [...oldArray, record])
    }
    else if (resourceGroup == 'onGoing') {
      setFilterOnGoing(oldArray => [...oldArray, record])
    }
    const setDataAssignee = CreateState.dataMemberAssignee.filter((col) => !removeMemComID.includes(col.memComId))
    CreateSetState.setDataMemberAssignee(setDataAssignee)


  }


  const columnsCandidate = [
    {
      dataIndex: 'partImage',
      key: 'partImage',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtCandidatesName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtCandidatesShift`, defaultMessage: 'Shift' }),
      dataIndex: 'memberShift',
      key: 'memberShift',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtCandidatesDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtCandidatesSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return (<img key={i} className="table-status-img" src={item.icon} />)
            } else {
              return (<img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />)
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handleAssign(record, index)}><FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnCandidateBtnAssign" defaultMessage="Assign" /></Button02>
          </div>
        );
      },
    },
  ];

  const columnsAssignee = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtAssigneeName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtAssigneeShift`, defaultMessage: 'Shift' }),
      dataIndex: 'memberShift',
      key: 'memberShift',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtAssigneeDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtAssigneeSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return (<img key={i} className="table-status-img" src={item.icon} />)
            } else {
              return (<img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />)
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handelRemove(index, record)}><FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnAssigneeBtnRemove" defaultMessage="Remove" /></Button02>
          </div>
        );
      },
    },
  ];

  const columnsAvailable = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtAvalibleName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtAvalibleShift`, defaultMessage: 'Shift' }),
      dataIndex: 'memberShift',
      key: 'memberShift',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtAvalibleDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtAvalibleSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return (<img key={i} className="table-status-img" src={item.icon} />)
            } else {
              return (<img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />)
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handleAssign(record, index)}><FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnAvailableBtnAssign" defaultMessage="Assign" /></Button02>
          </div>
        );
      },
    },
  ];

  const columnsOnGoing = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtOnGoingName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtOnGoingShift`, defaultMessage: 'Shift' }),
      dataIndex: 'memberShift',
      key: 'memberShift',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtOnGoingDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtOnGoingSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return (<img key={i} className="table-status-img" src={item.icon} />)
            } else {
              return (<img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />)
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handleAssign(record, index)}><FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnOnGoingBtnAssign" defaultMessage="Assign" /></Button02>
          </div>
        );
      },
    },
  ];

  const columnsOff = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtOffName`, defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtOffShift`, defaultMessage: 'Shift' }),
      dataIndex: 'memberShift',
      key: 'memberShift',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtOffDistance`, defaultMessage: 'Distance' }),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTxtOffSubtask`, defaultMessage: 'Subtask' }),
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) =>
        record.subtasks.map((item, i) => {
          const iconExcel = () => {
            if (item.done == true) {
              return (<img key={i} className="table-status-img" src={item.icon} />)
            } else {
              return (<img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />)
            }
          };

          return (
            <div className="table-status">
              <div key={i} className="table-status-key">
                <Icon className="table-status-icon" component={iconExcel} />
              </div>
            </div>
          );
        }),
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return (
          <div>
            <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => handleAssign(record, index)}><FormattedMessage id="monitorTabDetailModalAssigneeResourceColumnOffBtnAssign" defaultMessage="Assign" /></Button02>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'monitorCreateTaskAddAssigneeTxtAssignee', defaultMessage: 'Assignee' })}
        visible={CreateState.visibleAssignee}
        onCancel={handleCancel}
        centered={true}
        width={900}
        footer={null}
      >
        <Row>
          <Col span={24} className="assignee-resource-col-left" style={{ height: 576 }}>
            <div className="assignee-resource-col">
              <Row>
                <Col className="assignee-resource-col-col-left">
                  <p className="assignee-label"><FormattedMessage id={`monitorCreateTaskAddAssigneeTxtAssignee`} defaultMessage="Assignee" /></p>
                  <Table
                    className="assignee-resource-table"
                    scroll={{ y: 170 }}
                    // dataSource={stateMain.resourceTaskData.assignee || []}
                    dataSource={CreateState.dataMemberAssignee !== [] || CreateState.dataMemberAssignee !== undefined ? CreateState.dataMemberAssignee : dataAssignee}
                    columns={columnsAssignee}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="assignee-resource-col-col-right">
                  <Tabs defaultActiveKey="1" onTabClick={(key) => handleTab(key)}>
                    {/* <TabPane tab={intl.formatMessage({ id: 'monitorCreateTaskAddAssigneeTabpaneCandidates', defaultMessage: 'Candidates' })} key="1">
                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 150 }}
                        dataSource={filterCandidate ? filterCandidate : dataCandidates}
                        // dataSource={stateMain.resourceTaskData.candidates || []}
                        columns={columnsCandidate}
                      />
                    </TabPane> */}
                    <TabPane tab={intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTabpaneAvalible`, defaultMessage: 'Avalible' })} key="2">
                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 150 }}
                        dataSource={filterAvailable ? filterAvailable : dataAvailable}
                        // dataSource={stateMain.resourceTaskData.available || []}
                        columns={columnsAvailable}
                      />
                    </TabPane>
                    <TabPane tab={intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTabpaneOnGoing`, defaultMessage: 'On Going' })} key="3">

                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 150 }}
                        dataSource={filterOnGoing ? filterOnGoing : dataOnGoing}
                        // dataSource={stateMain.resourceTaskData.onGoing || []}
                        columns={columnsOnGoing}
                      />
                    </TabPane>
                    <TabPane tab={intl.formatMessage({ id: `monitorCreateTaskAddAssigneeTabpaneOff`, defaultMessage: 'Off' })} key="4">

                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 150 }}
                        dataSource={filterOff ? filterOff : dataOff}
                        // dataSource={stateMain.resourceTaskData.off || []}
                        columns={columnsOff}
                      />
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
            {/* <Divider className="assignee-resource-footer-divider" />
            <div className="assignee-resource-footer">
              <Button02 style={{margin : '0px 0px 0px 10px'}}
                btnsize="wd_df"
                // onClick={() => setStateMain.setVisibleAssignee(false)}
              >
                Cancel
              </Button02>
              <Button01
                btnsize="wd_df"
                type="primary"
                // onCilck={() => setStateMain.setVisibleAssignee(false)}
              >
                Confirm
              </Button01>
            </div> */}
          </Col>
        </Row>

      </Modal>
    </div>
  )
}
