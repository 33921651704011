import React from 'react'
import { Row, Col, Card, Icon, Input, Badge } from 'antd'
import '../index.css'
import { useIntl } from 'react-intl';
import _ from 'lodash'

const ItemRequestSearchInput = (props) => {
  const { handleToggle, toggle, handleClickBasket, basket, handleChangeInput } = props
  const intl = useIntl();
  return (
    <div>
      <Card
        className='itemRequestCard'
        bodyStyle={{ padding: '8px 16px' }}
      >
        <Row gutter={[16]}>
          <Col span={1}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '32px' }}>
              <Icon
                type={toggle ? 'menu-unfold' : 'menu-fold'}
                style={{ cursor: 'pointer', color: '#e73845', marginRight: 15 }}
                onClick={handleToggle}
              />
            </div>
          </Col>
          <Col span={22}>
            <Input
              placeholder={intl.formatMessage({ id: `itemRequestSearchItemInput`, defaultMessage: 'Search Item' })}
              prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
          </Col>
          <Col span={1}>
            <Badge
              className="itemRequestCardBasket"
              count={_.size(basket)}
              style={{
                background: '#D5484B',
                boxShadow: 'rgba(254, 98, 2, 0.24) 0px 0px 1px 1px',
              }}
            >
              <div
                onClick={handleClickBasket}
                style={{
                  height: '30px',
                  width: '30px',
                  backgroundColor: '#f0f2f5',
                  borderRadius: '50%',
                  display: 'inline-block',
                  textAlign: 'center',
                  cursor: 'pointer'
                }}
              >
                <Icon type="shopping-cart" style={{ fontSize: '20px', marginTop: '5px', color: '#1D3557' }} />
              </div>
            </Badge>
          </Col>
        </Row>


      </Card>
    </div>
  )
}

export default ItemRequestSearchInput
