import React, { useContext } from 'react';
import { SaleOrderProvider } from './context';

import SaleOrderPage from './sale-order-page';

const BillingNotePage = () => {
  return (
    <SaleOrderProvider>
      <SaleOrderPage />
    </SaleOrderProvider>
  );
};

export default BillingNotePage; 
