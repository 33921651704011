import React, { useRef, useState, useEffect } from 'react';
import { Modal, Tabs, Form, Row, Col, Select } from 'antd';
import Overview from './overview';
import Details from './details';
import Upload from './upload';
import Resizer from 'react-image-file-resizer';
import DefaultProfile from '../../image/NoImageR.png';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css';
import _ from 'lodash';
import { saveAsset, saveAssetImg } from '../../../controllers/asset-resource/asset-controller';
import moment from 'moment';
import { successNotification, errorNotification } from '../../v2/notification';

const { TabPane } = Tabs;

const ModalAddAsset = React.memo((props) => {
  const intl = useIntl();
  const { visible, setVisible, form, setMainTrigger } = props;
  const { validateFields, resetFields } = form;
  const imageRef = useRef();
  const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');
  const [imageAsset, setImageAsset] = useState();
  const [tabKey, setTabKey] = useState('1');
  const [disbleButton, setDisbleButton] = useState(false);

  const handleOk = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setDisbleButton(true);
      const assetValueDate = _.get(values, 'assetValueDate') ? moment(_.get(values, 'assetValueDate')).format('YYYY-MM-DD') : undefined;
      const purchaseDate = _.get(values, 'purchaseDate') ? moment(_.get(values, 'purchaseDate')).format('YYYY-MM-DD') : undefined;
      const mapSuitable = _.get(values, 'suitable')
        ? _.get(values, 'suitable').map((item) => {
          return {
            suitableId: item,
          };
        })
        : undefined;

      const body = {
        assetClassId: _.get(values, 'assetClass') ? _.get(values, 'assetClass') : undefined,
        transactionTypeId: _.get(values, 'source') ? _.get(values, 'source') : undefined,
        assetBrandId: _.get(values, 'brand') ? _.get(values, 'brand') : undefined,
        assetModelId: _.get(values, 'model') ? _.get(values, 'model') : undefined,
        assetTypeId: _.get(values, 'assetType') ? _.get(values, 'assetType') : undefined,
        assetName: _.get(values, 'assetName') ? _.get(values, 'assetName') : undefined,
        salvagePrice: _.get(values, 'salvagePrice') ? _.get(values, 'salvagePrice') : undefined,
        price: _.get(values, 'price') ? _.get(values, 'price') : undefined,
        orgId: _.get(values, 'owner') ? _.get(values, 'owner') : undefined,
        assetValueDate: assetValueDate,
        postingDate: purchaseDate,
        description: _.get(values, 'remark') ? _.get(values, 'remark') : undefined,
        registerBy: _.get(values, 'register') ? _.get(values, 'register') : undefined,
        serialNo: _.get(values, 'serialNo') ? _.get(values, 'serialNo') : undefined,
        detail: [],
        suitable: mapSuitable,
        assetBookValue: _.get(values, 'assetBookValue') ? _.get(values, 'assetBookValue') : undefined,
      };

      const response = await saveAsset(body);
      if (response.status === 200) {
        successNotification(response.message);
        setVisible(false);
        setDisbleButton(false);
        if (imageAsset) {
          let formData = new FormData();
          formData.append('file', imageAsset);
          await saveAssetImg(_.get(response, 'data.asset_id'), formData);
        }
        setImageAsset();
        imageRef.current.value = null;
        setImageFile('');
        resetFields();
        setMainTrigger((current) => !current);
        setTabKey('1');
      } else {
        setDisbleButton(false)
        errorNotification(response.message);
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setImageAsset();
    resetFields();
    setImageFile('');
    imageRef.current.value = null;
    setTabKey('1');
  };

  useEffect(() => {
    setImageFileDefault(DefaultProfile);
  }, [DefaultProfile]);

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);
        reader.onloadend = () => {
          const image = reader.result;
          //setImage(image);
          setImageFile(image);
        };
        reader.readAsDataURL(file);

        const fileChange = dataURLtoFile(image, file.name);
        setImageAsset(fileChange);
      } else {
        errorNotification('File not supported');
      }
    }
  };

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const deleteImage = (e) => {
    setImageFile('');
    setImageAsset();
    imageRef.current.value = null;
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const tabChange = (key) => {
    setTabKey(key);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalAssetAssetHeadModal', defaultMessage: 'Register Asset' })}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={871}
      centered={true}
      bodyStyle={{ padding: '0px', height: 'auto', minHeight: '510px' }}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel} disabled={disbleButton}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleOk} disabled={disbleButton}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Tabs className="asset-tab-tabpane" onTabClick={tabChange} activeKey={tabKey}>
        <TabPane tab={intl.formatMessage({ id: 'modalAssetAssetTabOverView', defaultMessage: 'Overview' })} key="1">
          <Row style={{ padding: '4px 24px 24px 0px' }}>
            <Col span={7}>
              <Upload
                imageRef={imageRef}
                imageFile={imageFile}
                imageFileDefault={imageFileDefault}
                handleChangePicture={handleChangePicture}
                showOpenFileDialog={showOpenFileDialog}
                deleteImage={deleteImage}
              />
            </Col>
            <Col span={17}>
              <Overview form={form} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'modalAssetAssetTabDetails', defaultMessage: 'Details' })} key="2">
          <Row style={{ padding: '4px 24px 24px 0px' }}>
            <Col span={7}>
              <Upload
                imageRef={imageRef}
                imageFile={imageFile}
                imageFileDefault={imageFileDefault}
                handleChangePicture={handleChangePicture}
                showOpenFileDialog={showOpenFileDialog}
                deleteImage={deleteImage}
              />
            </Col>
            <Col span={17}>
              <Details form={form} />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Modal>
  );
});

const ModalAsset = Form.create({
  name: 'modal_form',
  mapPropsToFields() { },
})(ModalAddAsset);
export default React.memo(ModalAsset, (prev, next) => {
  return prev.visible === next.visible;
});
