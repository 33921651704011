import httpClient from '../../components/axiosClient';

const searchLocation = async (customer , fieldChange) => {

  const body = {
    name: fieldChange ? fieldChange : "",
    customerId: customer ? customer : undefined,
  }

  try {
    const response = await httpClient.post(`/v1/resource/manager/search/customer/location/type`, body);

    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default searchLocation;