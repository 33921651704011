import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Divider, Dropdown } from 'antd';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import Button_01 from '../v2/button_01';
import CustomTableComponent from '../../pages/invoice-new/components/table';
import ModalSelectReferenceForm from './reference-tab-modal';
import { getListReference, savetListReferenceTab } from '../../controllers/reference-tab';
import moment from 'moment';

const ReferenceTab = ({ visiblemodal, selectItem, setSelectItem, dataform, typeModal, typeRef }) => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const [refType, setRefType] = useState([]);
  const [event, setEvent] = useState(false);
  // const [selectItem, setSelectItem] = useState([]);

  useEffect(() => {
    const getAPi = async () => {
      const payload =
        typeRef === 'po'
          ? {
            referenceType: typeRef,
            referenceId: _.get(dataform, 'poId'),
          }
          : {
            referenceType: typeRef,
            // referenceId:  dataform,
            referenceId: _.get(dataform, 'ticketId'),
          };
      const respone = await getListReference(payload);
      console.log('refType', _.get(respone, 'data.listRefType'));
      setRefType(_.get(respone, 'data.listRefType'));
      setSelectItem(_.get(respone, 'data.listReference'));
    };
    if (visiblemodal === true) {
      getAPi();
    }
  }, [visiblemodal, event]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'referTabReferenceNo', defaultMessage: 'Reference No.' }),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      sorter: (a, b) => {
        return a.referenceNo.localeCompare(b.referenceNo);
      },
      width: 150,
      fixed: 'left',
    },
    // {
    //   title: intl.formatMessage({ id: 'referenceTabReferenceNo', defaultMessage: 'Reference No.' }),
    //   dataIndex: 'itemName',
    //   key: 'itemName',
    //   sorter: (a, b) => {
    //     return a.itemName.localeCompare(b.itemName);
    //   },
    // },
    {
      title: intl.formatMessage({ id: 'referenceTabReferenceType', defaultMessage: 'Reference Type' }),
      dataIndex: 'referenceType',
      key: 'referenceType',
      sorter: (a, b) => {
        return a.referenceType.localeCompare(b.referenceType);
      },
      // render: (text, record, index) => console.log('qtyTxt');,
    },
    {
      title: intl.formatMessage({ id: 'referenceTabBusinessPartner', defaultMessage: 'Business Partner' }),
      dataIndex: 'businessPartnerName',
      key: 'businessPartnerName',
      sorter: (a, b) => {
        return a.businessPartnerName.localeCompare(b.businessPartnerName);
      },
      // render: (text, record, index) => _.get(record,'pricePerUnit') + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'referenceTabCreatedby', defaultMessage: 'Created by' }),
      dataIndex: 'createdByName',
      key: 'createdByName',
      sorter: (a, b) => {
        return a.createdByName.localeCompare(b.createdByName);
      },
      // render: (text, record, index) => (record.credit ? record.credit : 0),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalCreferenceTabCreatedDateolumnsDiscount', defaultMessage: 'Created Date' }),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: (a, b) => {
        return a.createdDate.localeCompare(b.createdDate);
      },
      render: (text, record, index) => typeModal === 'view' ? _.get(record, 'createdDate') : moment(_.get(record, 'createdDate'), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
  ];

  const onOpen = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const contentActionItem = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        {/* <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalEdit(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div> */}
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => RemoveRef(record)}>
            <FormattedMessage id="btnRefRemove" defaultMessage="Remove" />
          </Button>
        </div>
      </div>
    );
  };

  const RemoveRef = async (value) => {
    const filterSelect = _.filter(selectItem, (item) => {
      return item.referenceId !== value.referenceId;
    });
    const mapindex = _.map(filterSelect, (item, index) => {
      return { ...item, index: index + 1 };
    });

    if (typeModal === 'view') {
      const body = typeRef === 'po' ? {
        referenceType: 'po',
        referenceId: _.get(dataform, 'poId'),
        referenceNo: _.get(dataform, 'poNo'),
        businessPartnerName: _.get(dataform, 'vendorSupplier'),
        businessPartnerId: _.get(dataform, 'vendorSupplierId'),
        createdBy: localStorage.getItem('memComId'),
        createdByName: _.get(dataform, 'createdBy'),
        createdDate: moment(_.get(dataform, 'issueDate'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
        refToList: mapindex,
      } : {
        referenceType: 'ticket',
        referenceId: _.get(dataform, 'ticketId'),
        referenceNo: _.get(dataform, 'ticketNo'),
        businessPartnerName: _.get(dataform, 'contactName'),
        businessPartnerId: _.get(dataform, 'contactRefId'),
        createdBy: _.get(dataform, 'submittedBy'),
        createdByName: _.get(dataform, 'submittedByName'),
        createdDate: moment(_.get(dataform, 'submittedDateTxt'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
        refToList: mapindex,
      };;
      await savetListReferenceTab(body);
    }

    setSelectItem(mapindex);
  };

  return (
    <div className="scroll-sm" style={{ height: '540px', overflowY: 'auto' }}>
      <div>
        <div className="item-title" style={{ justifyContent: 'end' }}>
          <div style={{ marginBottom: '10px' }}>
            <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }} onClick={onOpen}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01>
          </div>
        </div>
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columns}
            dataSource={selectItem}
            scroll={{ x: 1000 }}
            contentAction={contentActionItem}
          // rowKey={(record) => record.uuid}
          />
        </div>
      </div>
      <ModalSelectReferenceForm
        visible={visible}
        onCancel={onCancel}
        refType={refType}
        setSelectItem={setSelectItem}
        selectItem={selectItem}
        typeModal={typeModal}
        dataform={dataform}
        typeRef={typeRef}
        setEventRef={setEvent}
      />
    </div>
  );
};

export default ReferenceTab;
