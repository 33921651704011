import React, { useState, useEffect, useMemo } from 'react';
import { Button, Icon, Popover, Menu, Avatar } from 'antd';
import '../css/rightMenu.css';
import '../css/platform.css';
import { useAppContext } from '../../../includes/indexProvider';
import _ from 'lodash';
import styled from 'styled-components';
import httpClient from '../../../components/axiosClient';

const LogoAvatar = styled(Avatar)`
  box-shadow: 0 0px 5px 0 rgba(215, 215, 215);
`;

const LabelCompanyName = styled.span`
  margin-left: 20px;
`;

export default (props) => {
  const app = useAppContext();
  // const profileCompanies = localStorage.getItem('getUserProfileCompanies');

  const userId = app.state.userId;
  const fullName = app.state.fullName;
  const email = app.state.email;
  const comId = app.state.comId;
  const companies = _.isObject(app.state.dataUser) ? app.state.dataUser.companies : [];
  // const comName = app.state.comName;
  // const comCode = app.state.comCode;
  // const memId = app.state.memId;
  
  const CompanyMenu = () => {
    const handleSelectCompany = async ({ item, key }) => {
      const { id, name } = item.props;
      try {
        const response = await httpClient.post(`/v2/login`, {
          'user_id': userId,
          'user_name': fullName,
          'email': email,
          'company_id': id,
          'company_name': name
        });
        if (response.status === 200) {
          if (_.size(response.data) > 0) {
            localStorage.setItem('comId', key);
            localStorage.setItem('comName', name);
            localStorage.setItem('companyId', id);
            localStorage.setItem('comCode', response.data[0].loginCompany.com_code);
            localStorage.setItem('memComId', response.data[0].mem_com_id);
            localStorage.setItem('memId', response.data[0].mem_id);

            if (window.location.pathname.indexOf('/menu/users/') >= 0) {
               window.location.href = '/menu/user';
            }
            else {
               window.location.reload();
               window.location.href = '/main/dashboard';
            }
          }
        }
      }
      catch (error) {
     
      }
    };

    const list = companies.map((obj, i) => {
      return (
        <Menu.Item key={obj.com_id} name={obj.name} id={obj.keycloak_groups_id}>
          {!obj.logo ? <LogoAvatar>C</LogoAvatar> : <LogoAvatar src={obj.logo} />}
          <span style={{ marginLeft: '20px' }}>{obj.name}</span>
        </Menu.Item>
      );
    });

    return (
      <Menu
        className="menu-company"
        defaultSelectedKeys={[comId]}
        mode={'vertical'}
        theme={'light'}
        onSelect={handleSelectCompany}
      >
        {list}
      </Menu>
    );
  };


  const companyName = useMemo(() => {
    const name = companies && companies.filter(item => parseInt(item.com_id) === parseInt(app.state.comId)).map(ele => ele.name);
    if (name) {
      return name.toString();
    }
  }, [companies]);

  return (
    <Popover placement="bottomRight" content={<CompanyMenu />} trigger="click">
      <Button className="kgt-btn-style" title={companyName}>
      <Icon type="appstore" className="icon-button" />
       {  _.truncate(companyName, {
        'length': 24,
        // 'separator': ' '
      })}
        {/* {companyName} */}
     
      </Button>
    </Popover>
  );
};
