import { Table, Card, Col, Row, Tabs, Button, Icon, Menu, Dropdown, Popover } from 'antd';
import React, { useContext, useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Css from './index.css';
import _ from 'lodash';
import { ReciveItemPOContext } from '../../reciveitempo-context';
import { PageSettings } from '../../../../config/page-settings';

const { TabPane } = Tabs;

const TableRecive = (props) => {
  const { state, setState, fnc } = useContext(ReciveItemPOContext);
  const { checkPermissionAction } = useContext(PageSettings);
  const {
    data,
    handleChangePage,
    handleChangeSize,
    requestDetailData,
    total,
    setSort,
    setEvent,
    isLoading,
    page,
    handleCreate,
    setItemData,
    setReciveQTY,
  } = props;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(600);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  // const data = [
  //   { ticketNo: 'RT-IC220001', ticketType: 'ม้าบิน ปุ๋ยเกษตร แบบดีมาก', ReceivedQty: '50 / 50 Sack' },
  //   { ticketNo: 'RT-IC220002', ticketType: '้เต่าบิน ปุ๋ยดีกว่า แบบมากมาก', ReceivedQty: '100 / 100 Sack' },
  // ];

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="recivePOItemCode" defaultMessage="Item Code" />,
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 100,
    },
    {
      title: <FormattedMessage id="recivePOItem" defaultMessage="Item" />,
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      width: 250,
    },
    {
      title: <FormattedMessage id="recivePOReceivedQty" defaultMessage="Received Qty" />,
      dataIndex: 'receiveQty',
      key: 'receiveQty',
      sorter: true,
      width: 150,
    },
  ];

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div>
          <p>
            <FormattedMessage id="recivePOPurchaseOrder" defaultMessage="Purchase Order" />
          </p>
        </div>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  return (
    <div className="containerTables" onMouseLeave={handleMouseLeave}>
      <Row gutter={16}>
        <Table
          className="custom-table-claim"
          dataSource={data || []}
          columns={columns}
          scroll={{ x: scrollTableX, y: `calc(100vh - 437px)` }}
          loading={isLoading}
          onChange={onChangeTable}
          rowClassName="rowcursor"
          pagination={{
            total: totalItems,
            current: page,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
            showTotal: showTotal,
            onChange: handleChangePage,
            onShowSizeChange: handleChangeSize,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  // const { x, y } = ref.current.getBoundingClientRect();
                  // setVisiblePopoverTable(true);
                  // setOffsetPopover([event.pageX - x, event.pageY - y]);
                  // setRecordPopover(record);
                  console.log('recordRecice', record );
                  setItemData(record);
                  setReciveQTY(_.get(record,'received'))
                  handleCreate();
                }
              },
            };
          }}
        />
        {/* {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)} */}
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${totalItems || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      </Row>
    </div>
  );
};

export default TableRecive;
