import { Card, Col, Row, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { PageSettings } from '../../config/page-settings';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { VoucherContext } from './voucher-context';
import CardTableVoucher from './table';
import moment from 'moment';
import { getMemberIssueBy } from '../../controllers/quotation/quotation-api-new';
import { useIntl } from 'react-intl';
import { getBrandVoucher, getModelVoucher, getVoucherDetail, getVoucherList } from '../../controllers/voucher/voucher';
import ModalFormCreateVoucher from './modal/create';
import ModalViewVoucher from './modal/view';
import { getCustomerLoadMore } from '../../controllers/getCustomer/get-customer-new';

const VoucherPage = () => {
  const [paginationPage, setPaginationPage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [voucherList, setVoucherList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [event, setEvent] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState();
  const [searchGroup, setSearchGroup] = useState({
    effdate: [moment().startOf('month'), moment().endOf('month')]
  });
  const [brandList, setBrandList] = useState([]);
  const [brandId, setBrandId] = useState('');
  const [dataModel, setDataModel] = useState([]);
  const [warehouseList, setWarehouseList] = useState();
  const [totalBrand, setTotalBrand] = useState(10);
  const [totalModel, setTotalModel] = useState(10);
  const [totalWarhouse, setTotalWarhouse] = useState(10);
  const [totalType, setTotalType] = useState(10);
  const [totalGroup, setTotalGroup] = useState(10);
  const [numbertypeList, setNumberTypeList] = useState(10);
  const [trigger, setTrigger] = useState(false);
  const [statusList, setStatusList] = useState();
  const [memberList, setMemberList] = useState();
  const [rowsOfPageMemberList, setRowsOfPageMemberList] = useState(true)
  const [rowsOfPageBrand, setRowsOfPageBrand] = useState(true)
  const [rowsOfPageModel, setRowsOfPageModel] = useState(true)
  const [eventModel, setEventModel] = useState(false)
  const [typeview, setTypeView] = useState([]);
  const [brandLoading, setBrandLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [voucherId, setVoucherId] = useState('');
  const [toggleActive, setToggleActive] = useState(false);
  const [customerPage, setCustomerPage] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [searchBrandName, setSearchBrandName] = useState('')
  const [searchModelName, setSearchModelName] = useState('')

  const intl = useIntl();

  useEffect(() => {
    const getARReport = async () => {
      // console.log('valueFilter', page, size, searchGroup);
      const body = {
        effDateFrom: _.get(searchGroup, 'effdate[0]') ? moment(_.get(searchGroup, 'effdate[0]')).format('YYYY-MM-DD') : undefined,
        effDateTo: _.get(searchGroup, 'effdate[1]') ? moment(_.get(searchGroup, 'effdate[1]')).format('YYYY-MM-DD') : undefined,
        expDateFrom: _.get(searchGroup, 'exdate[0]') ? moment(_.get(searchGroup, 'exdate[0]')).format('YYYY-MM-DD') : undefined,
        expDateTo: _.get(searchGroup, 'exdate[1]') ? moment(_.get(searchGroup, 'exdate[1]')).format('YYYY-MM-DD') : undefined,
        createdFrom: _.get(searchGroup, 'createDate[0]') ? moment(_.get(searchGroup, 'createDate[0]')).format('YYYY-MM-DD') : undefined,
        createdTo: _.get(searchGroup, 'createDate[1]') ? moment(_.get(searchGroup, 'createDate[1]')).format('YYYY-MM-DD') : undefined,
        voucherNo: _.get(searchGroup, 'Voucherno') || '',
        voucherName: _.get(searchGroup, 'Voucherno') || '',
        customerId: _.get(searchGroup, 'customer') ? [_.get(searchGroup, 'customer')] : [],
        statusCode: _.get(searchGroup, 'status') ? [_.get(searchGroup, 'status')] : [],
        reference: _.get(searchGroup, 'ref') || '',
        brandId: _.get(searchGroup, 'brand') || '',
        modelId: _.get(searchGroup, 'model') || '',
        pageNumber: page,
        limit: size,
        orderBy: _.get(sort, 'field'),
        orderType: _.get(sort, 'order') ? _.get(sort, 'order') === 'descend' ? 'desc' : 'asc' : undefined ,
      };

      const responase = await getVoucherList(body);
      setVoucherList(_.get(responase, 'data.voucherList'));
      setStatusList(_.get(responase, 'data.statusVoucher'));
      setTotal(_.get(responase, 'data.totalItem'));
    };
    getARReport();
  }, [_.get(searchGroup, 'effdate'), page, size, trigger,event]);

  useEffect(() => {
    const getApi = async () => {
      let payload = {};
      const result = await getMemberIssueBy(payload);
      setMemberList(result || []);
      const allModelarray = [
        { item_model_id: '*', item_model_name: intl.formatMessage({ id: 'allModels', defaultMessage: 'All Models' }) },
      ];
      setDataModel(allModelarray);
    };
    getApi();
  }, []);

  useEffect(() => {
    async function getUser() {
      try {
        const pageStart = 1;
        const pageEnd = customerPage;

        if (rowsOfPageMemberList) {
          const data = await getCustomerLoadMore(pageStart, pageEnd);

          // console.log('CustomerVoucher', data);
          const custommer = _.get(data, 'customers')
          const allCustommer = [
            {customerId: "*", customerName: intl.formatMessage({ id: 'allCustomer', defaultMessage: 'All Customer' }) }
          ]
          const company = [
            {customerId: localStorage.getItem('comId'), customerName: localStorage.getItem('comName')}
          ]
  
          const list1 = allCustommer.concat(custommer)
          const list2 = list1.concat(company)
  
          // setUserData(list2);
          // setUserData(custommer);
  
          setMemberList(list2);
          setRowsOfPageMemberList(data.rowsOfPage === data.allCustomers ? false : true)
          setCustomerLoading(false);
        }
        
      } catch (error) {}
    }

    getUser();
  }, [customerPage]);

  useEffect(() => {
    const getBrand = async () => {
      const body = {
        indexStart: 0,
        indexEnd: totalBrand,
        item_brand_name: searchBrandName,
      };
      if(rowsOfPageBrand) {
        const responase = await getBrandVoucher(body);
        const listbrand = _.get(responase, 'data.list');
        const allBrandarray = [
          { item_brand_id: '*', item_brand_name: intl.formatMessage({ id: 'allBrands', defaultMessage: 'All Brands' }) },
        ];
        setBrandList(allBrandarray.concat(listbrand));
        setRowsOfPageBrand(_.get(responase, 'data.totalItems') === _.size(_.get(responase, 'data.list')) ? false : true)
        // setBrandList(newArray);
        setBrandLoading(false);
      }
      
    };
    getBrand();
  }, [totalBrand, searchBrandName]);

  useEffect(() => {
    const getModel = async () => {
      const body = {
        indexStart: 0,
        indexEnd: totalModel,
        item_model_name: searchModelName,
        item_brand_id: brandId === '*' ? [] : [brandId],
      };

      const allModelarray = [
        { item_model_id: '*', item_model_name: intl.formatMessage({ id: 'allModels', defaultMessage: 'All Models' }) },
      ];

      if (brandId === '*') {
        setDataModel(allModelarray);
      } else {
        if(rowsOfPageModel) {

          const responase = await getModelVoucher(body);
          // console.log('ModelData', responase.data);
          const listmodel = _.get(responase, 'data.list');
          setDataModel(allModelarray.concat(listmodel));
          setRowsOfPageModel(_.get(responase, 'data.totalItems') === _.size(_.get(responase, 'data.list')) ? false : true)
          setModelLoading(false);

        }
        
      }

    };

    if (brandId) {
      getModel();
    }
  }, [totalModel, eventModel, searchModelName]);

  useEffect(() => {
    setTotalModel(10)
    setRowsOfPageModel(true)
    setEventModel((event) => !event)
  }, [brandId])

  useEffect(() => {
    setSearchBrandName('')
    setTotalBrand(10)
    setRowsOfPageBrand(true)
    
    setSearchModelName('')
    setTotalModel(10)
    setRowsOfPageModel(true)
  }, [visibleCreate])



  const handleBrandPopupScroll = (e) => {
    let target = e.target;
    if (!brandLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setTotalBrand(totalBrand + 10);
      setBrandLoading(true);
    }
  };

  const handleModelPopupScroll = (e) => {
    let target = e.target;
    if (!modelLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setTotalModel(totalModel + 10);
      setModelLoading(true);
    }
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleVisibleCreate = () => {
    setVisibleCreate(true);
  };

  const handleView = (record) => {
    setTypeView(record);
    setVoucherId(_.get(record, 'voucherId'));
    setVisibleView(true);
  };

  const handleEdit = async (record) => {
    setVoucherId(_.get(record, 'voucherId'));
    handleVisibleCreate();
  };

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setCustomerPage(_.toNumber(customerPage) + 10);
      setCustomerLoading(true);
    }
  };

  const handleSearchBrand = (value) => {
    setSearchBrandName(value)
    setTotalBrand(10)
    setRowsOfPageBrand(true)
  }

  const handleSearchModel = (value) => {
    setSearchModelName(value)
    setTotalModel(10)
    setRowsOfPageModel(true)
  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction(`P63PG1C1`, `P63PG1C1A1`) ? (
          <VoucherContext.Provider
            value={{
              state: {
                voucherList,
                paginationPage,
                isLoading,
                fillter,
                visibleCreate,
                visibleView,
                event,
                editRecord,
                sort,
                page,
                total,
                isLoading,
                searchGroup,
                brandId,
                dataModel,
                brandList,
                totalBrand,
                totalModel,
                totalGroup,
                totalType,
                warehouseList,
                totalWarhouse,
                numbertypeList,
                trigger,
                statusList,
                memberList,
                typeview,
                voucherId,
                toggleActive,
                customerLoading,
                customerPage,
                isFetch,
              },
              setState: {
                setPaginationPage,
                setIsLoading,
                setFillter,
                setVisibleCreate,
                setEditRecord,
                setEvent,
                setSort,
                setSearchGroup,
                setBrandId,
                setTotalBrand,
                setTotalModel,
                setTotalType,
                setTotalGroup,
                setTotalWarhouse,
                setTrigger,
                setTypeView,
                setVoucherId,
                setBrandList,
                setToggleActive,
                setCustomerPage,
                setCustomerLoading,
                setVisibleView,
                setIsFetch,
              },
              fnc: {
                handleChangePage,
                handleChangeSize,
                handleVisibleCreate,
                handleView,
                handleEdit,
                handleBrandPopupScroll,
                handleModelPopupScroll,
                handleCustomerPopupScroll,
                handleSearchBrand,
                handleSearchModel,
              },
            }}
          >
            <Row gutter={[16, 16]}>
              <CardTableVoucher />
            </Row>
            <ModalFormCreateVoucher visible={visibleCreate} setVisible={setVisibleCreate} />
            <ModalViewVoucher visible={visibleView} setVisible={setVisibleView} />
          </VoucherContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default VoucherPage;
