import React, { useEffect, useState } from 'react';
import { Table, Modal, DatePicker } from 'antd';
import Button_01 from '../../v2/button_01';
import Button_02 from '../../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import ActionReportV9 from '../../action-report/action-report-v9';
import { getWaitingOrder, replanWaiting } from '../../../controllers/load-and-route/api';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import './css/index.css';
import { successNotification, errorNotification } from '../../v2/notification';
import moment from 'moment';

const comCode = localStorage.getItem('comCode');

export default (props) => {
  const {
    data,
    visible,
    onClose,
    onClickRow,
    trigger,
    setTriggerAll,
    setOpenPlanningModal,
    shippingDate,
    setShippingDateOrder,
    shippingDateOrder,
    setShippingDate,
  } = props;
  const intl = useIntl();

  const columns =
    comCode === 'PPP'
      ? [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) => record.index,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
            dataIndex: 'orderNo',
            key: 'orderNo',
            fixed: 'left',
            width: 180,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
            dataIndex: 'productName',
            key: 'productName',
            fixed: 'left',
            width: 240,
            ellipsis: true,
            sorter: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnQty`, defaultMessage: 'Qty' }),
            dataIndex: 'total',
            key: 'total',
            sorter: true,
            width: 100,
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.total} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnWeight(kg)`, defaultMessage: 'Weight (kg)' }),
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.weight} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
            dataIndex: 'customerName',
            key: 'customerName',
            width: 240,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressSource`, defaultMessage: 'Source' }),
            dataIndex: 'sourceAddress',
            key: 'sourceAddress',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressDestination`, defaultMessage: 'Destination' }),
            dataIndex: 'address',
            key: 'address',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryDate`, defaultMessage: 'Delivery Date' }),
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            width: 150,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryTime`, defaultMessage: 'Delivery Time' }),
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
            width: 150,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
            dataIndex: 'remark',
            key: 'remark',
            sorter: true,
            ellipsis: true,
          },
        ]
      : [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => record.index,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
            dataIndex: 'orderNo',
            key: 'orderNo',
            width: 150,
            fixed: 'left',
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
            dataIndex: 'productName',
            key: 'productName',
            fixed: 'left',
            width: 280,
            ellipsis: true,
            sorter: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnQty`, defaultMessage: 'Qty' }),
            dataIndex: 'total',
            key: 'total',
            sorter: true,
            ellipsis: true,
            width: 100,
            render: (text, record, index) => <NumberFormat value={record.total} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnWeight(kg)`, defaultMessage: 'Weight (kg)' }),
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.weight} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
            dataIndex: 'customerName',
            key: 'customerName',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressSource`, defaultMessage: 'Source' }),
            dataIndex: 'sourceAddress',
            key: 'sourceAddress',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressDestination`, defaultMessage: 'Destination' }),
            dataIndex: 'address',
            key: 'address',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryDate`, defaultMessage: 'Delivery Date' }),
            dataIndex: 'shippingDate',
            key: 'shippingDate',
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
            dataIndex: 'remark',
            key: 'remark',
            sorter: true,
            ellipsis: true,
          },
        ];

  const [dataSource, setDataSource] = useState([]);
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [fieldChange, setFieldChange] = useState('');
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [totalNumber, setTotalNumber] = useState();
  const [statusButton, setStatusButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const ignoreFilterColumns = [];
  const ignoreSearchColumns = ['index'];

  console.log('shippingDate', shippingDate);
  useEffect(() => {
    setLoading(true);
    const callApi = async () => {
      const payload = {
        search: fieldChange,
        searchBy: fliterByColumn,
        orderType: orderSort,
        orderBy: fieldSort,
        paging: fieldChange ? 1 : paginationPage,
        rowsPerPages: paginationSize,
        shippingDate: moment(shippingDateOrder).format('YYYY-MM-DD'),
      };

      const response = await getWaitingOrder(payload);
      setDataSource(_.get(response, 'data'));
      setTotalNumber(_.get(response, 'totalData'));
      setTotal(_.get(response, 'totalData'));
      setStatusButton(_.get(response, 'dataEnableButton'));
      setLoading(false);
    };
    if (visible === true) {
      callApi();
    }
  }, [visible, fieldChange, fliterByColumn, orderSort, fieldSort, paginationSize, trigger, paginationPage, shippingDateOrder]);

  const onChangeSearch = (value) => {
    setFieldChange(value);
  };

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleClose = () => {
    onClose();
    setDataSource([]);
    setFliterByColumn('all');
    setFieldChange('');
    setExtraSort();
    setFieldSort();
    setOrderSort();
    setPaginationPage(1);
    setPaginationSize(10);
    setShippingDateOrder(shippingDate);
  };

  const handleSelectDateOrder = (value) => {
    setShippingDateOrder(value);
  };

  const handleReCalculate = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'planningRecalculateTextButton', defaultMessage: 'Are you sure you want to Re-Calculate ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await replanWaiting();
        if (response.status === 200) {
          successNotification(response.data.message);
          setTriggerAll((current) => !current);
        } else {
          errorNotification(response.data.message);
          setTriggerAll((current) => !current);
        }
      },
      onCancel() {},
    });
  };

  return (
    <div>
      <Modal
        title={`${intl.formatMessage({ id: `planningTextWaitingOrders`, defaultMessage: 'Waiting Orders' })} (${totalNumber})`}
        width={'98%'}
        className="modal-waiting-order"
        centered
        visible={visible}
        onCancel={handleClose}
        bodyStyle={{ height: '590px' }}
        footer={
          statusButton
            ? [
                <Button_02 key="back" btnsize="wd_at" onClick={handleReCalculate}>
                  <FormattedMessage id="buttonRePlanning" defaultMessage="Re-Planning" />
                </Button_02>,
                <Button_01 type="primary" btnwidth="wd_at" onClick={handleClose}>
                  <FormattedMessage id="btnClose" defaultMessage="Close" />
                </Button_01>,
              ]
            : [
                <Button_01 type="primary" btnwidth="wd_at" onClick={handleClose}>
                  <FormattedMessage id="btnClose" defaultMessage="Close" />
                </Button_01>,
              ]
        }
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }} className="action-waiting-order-table">
            <ActionReportV9
              value={{
                columns,
                ignoreFilterColumns,
                ignoreSearchColumns,
                fliterByColumn,
              }}
              fnc={{
                onChangeSearch,
                onFliterByColumn,
              }}
            />
            <div style={{ display: 'flex' }}>
              <DatePicker
                onChange={handleSelectDateOrder}
                defaultValue={moment(shippingDate)}
                value={moment(shippingDateOrder)}
                allowClear={false}
                style={{ width: '100%' }}
                format="ddd, MMM DD YYYY"
              />
              <Button_01 type="primary" btnsize="wd_at" onClick={() => setOpenPlanningModal()}>
                <FormattedMessage id="buttonPlanning" defaultMessage="Planning" />
              </Button_01>
            </div>
          </div>
          <div>
            <Table
              className="table-waiting-order scroll-sm"
              rowKey={(record) => record.productIdOrder}
              onChange={handleChange}
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              scroll={{ x: 1900, y: 415 }}
              pagination={{
                total: total,
                showTotal: showTotal,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showSizeChanger: true,
                locale: { items_per_page: '' },
                onChange: handlePagination,
                onShowSizeChange: handleSizeChange,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => onClickRow(record), // click row
                };
              }}
            />

            {dataSource && dataSource.length !== 0 ? (
              <div className="total-items-waiting-order">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${totalNumber || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};
