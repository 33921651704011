import httpClient from '../../components/axiosClient';

const getTaskWorklogs = async (taskId) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/task/manager/company/${comCode}/task/${taskId}/work/logs`);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}



export default getTaskWorklogs;