import React, { useState, useEffect } from 'react';
import { Input, Button, notification, Icon, Form } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HttpClient from '../../components/httpClient';
import clientNew from '../../components/axiosClient';
import { useKeycloak } from '@react-keycloak/web';

let client = HttpClient();

localStorage.getItem('newColumns2');
const memComId = localStorage.getItem('memComId');

const ForgotPassword = (props) => {
  const { keycloak } = useKeycloak();

  const { getFieldDecorator, validateFields, getFieldError, isFieldTouched } = props.form;
  const emailError = isFieldTouched('email') && getFieldError('email');

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const result = await clientNew.get(`/v2/users/${memComId}/`);
    const user = result.data;
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const result = await clientNew.post('/v2/createcode/', values);

        if (result.status == 200) {
          successNotification(result.data.data);
          setTimeout(() => {
            window.location.href = '/validate-code';
          }, 500);
        } else console.error();
      }
    });
  };

  const handleLogin = () => {
    window.location.href = '/main/dashboard';
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h3>
        <b>Find Your Account</b>
      </h3>
      <p style={{ color: '#000000' }}>
        Please enter your Username or email address <br /> or phone number to search for your account{' '}
      </p>
      <Form.Item>
        {getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail.',
            },
            {
              required: true,
              message: 'Please, input your E-mail',
            },
          ],
        })(<Input placeholder="Username, email, or phone number" autoComplete="off" />)}
      </Form.Item>
      <Form.Item>
        <StyledButton htmlType="submit">Authenticate</StyledButton>
      </Form.Item>
      <Form.Item>
        <div className="remember-forgot-row" style={{ marginTop: '10px' }}>
          <Link className="login-form-forgot" onClick={handleLogin}>
            {' '}
            Login{' '}
          </Link>
        </div>
      </Form.Item>
    </Form>
  );
};

const StyledButton = styled(Button)`
  margin: 25px 0px 0px 0px;
  width: 100%;
  box-shadow: 'none';
  border-radius: 0px;
  background-color: #1d3557;
  border-color: #1d3557;
  color: #ffffff;
`;

const WrappedRegistrationForm = Form.create({ name: 'sendEmail' })(ForgotPassword);

export default WrappedRegistrationForm;
