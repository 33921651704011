import React, { useEffect, useState } from 'react';
import TaskType from './task-type';
import './css/index.css';
import { getTaskType, saveTaskType } from '../../../../controllers/setting/task-type';
import { successNotification, errorNotification } from '../../../../components/v2/notification'
import { PageSettings } from '../../../../config/page-settings'
import _ from 'lodash';

export default () => {
  const [taskTypeData, setTaskTypeData] = useState([]);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [dataTaskTypeNew, setDataTaskTypeNew] = useState([]);

  // const taskTypeDataClone = [...taskTypeData]

  useEffect(() => {
    const getApiTaskType = async () => {
      const data = await getTaskType('menuType=workforce');
      setTaskTypeData(data);
    }
    getApiTaskType();
  }, [toggleEdit, dataTaskTypeNew])


  const handleEdit = () => {
    setToggleEdit(true);
  }

  const handleSave = async (e) => {
    e.preventDefault();
    if (dataTaskTypeNew.length > 0) {
      try {
        const response = await saveTaskType(dataTaskTypeNew);
        if (response.statusCode) {
          successNotification(response.message);
          setToggleEdit(false);
        } else {
          errorNotification(response.message);
        }
      } catch (error) {
        errorNotification(error.response.data.message);
      }
    } else {
      setToggleEdit(false);
    }
  }

  const handleCancel = () => {
    setToggleEdit(false);
    setDataTaskTypeNew([]);
  }

  const onChangeSwitch = (checked, event) => {
    let newDataTaskType = [...dataTaskTypeNew];
    const findIndexTaskType = _.findIndex(newDataTaskType, ['id', event.target.id]);
    if (findIndexTaskType > -1) {
      newDataTaskType[findIndexTaskType].km = checked;
    }
    else {
      const findIndexTaskTypeMain = _.findIndex(taskTypeData, ['id', event.target.id]);
      const unitPrice = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'unitPrice') : 0;
      const incentiveKm = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'incentiveKm') : null;
      const incentivePrice = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'incentivePrice') : 0;
      const setTaskType = {
        id: event.target.id,
        unitPrice: unitPrice,
        km: checked,
        incentivePrice: incentivePrice,
        incentiveKm: incentiveKm
      };
      newDataTaskType.push(setTaskType);
    }
    setDataTaskTypeNew(newDataTaskType);
  }

  const onChangeInput = (event, record) => {

    const unitPrice = parseFloat(event) ? parseFloat(event) : 0.00;
    let newDataTaskType = [...dataTaskTypeNew];
    const findIndexTaskType = _.findIndex(newDataTaskType, ['id', record.id]);
    if (findIndexTaskType > -1) {
      newDataTaskType[findIndexTaskType].unitPrice = unitPrice;
    }
    else {
      const findIndexTaskTypeMain = _.findIndex(taskTypeData, ['id', record.id]);
      const km = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'km') : null;
      const incentiveKm = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'incentiveKm') : null;
      const incentivePrice = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'incentivePrice') : 0;
      const setTaskType = {
        id: record.id,
        unitPrice: unitPrice,
        km: km,
        incentivePrice: incentivePrice,
        incentiveKm: incentiveKm
      };


      newDataTaskType.push(setTaskType);
    }
    setDataTaskTypeNew(newDataTaskType);
  }

  const onSwitchIncentive = (checked, event) => {
    let newDataTaskType = [...dataTaskTypeNew];
    const findIndexTaskType = _.findIndex(newDataTaskType, ['id', event.target.id]);
    if (findIndexTaskType > -1) {
      newDataTaskType[findIndexTaskType].incentiveKm = checked;
    }
    else {
      const findIndexTaskTypeMain = _.findIndex(taskTypeData, ['id', event.target.id]);
      const unitPrice = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'incentivePrice') : 0;
      const km = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'km') : null;
      const incentivePrice = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'incentivePrice') : 0;

      const setTaskType = {
        id: event.target.id,
        unitPrice: unitPrice,
        km: km,
        incentivePrice: incentivePrice,
        incentiveKm: checked
      };
      newDataTaskType.push(setTaskType);
    }
    setDataTaskTypeNew(newDataTaskType);
  }

  const onChangeIncentive = (event, record) => {
    const incentivePrice = parseFloat(event) ? parseFloat(event) : 0.00;
    let newDataTaskType = [...dataTaskTypeNew];
    const findIndexTaskType = _.findIndex(newDataTaskType, ['id', record.id]);
    if (findIndexTaskType > -1) {
      newDataTaskType[findIndexTaskType].incentivePrice = incentivePrice;
    }
    else {
      const findIndexTaskTypeMain = _.findIndex(taskTypeData, ['id', record.id]);
      const km = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'km') : null;
      const unitPrice = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'unitPrice') : 0;
      const incentiveKm = findIndexTaskTypeMain > -1 ? _.get(taskTypeData[findIndexTaskTypeMain], 'incentiveKm') : null;

      const setTaskType = {
        id: record.id,
        unitPrice: unitPrice,
        km: km,
        incentivePrice: incentivePrice,
        incentiveKm: incentiveKm
      };
      newDataTaskType.push(setTaskType);
    }
    setDataTaskTypeNew(newDataTaskType);
  };

  return (
    <PageSettings.Consumer>
      {
        ({ checkPermissionAction }) => (
          checkPermissionAction(`P25PG1C1`, `P25PG1C1A1`) ?
            <div>
              <TaskType
                data={taskTypeData}
                toggleEdit={toggleEdit}
                setToggleEdit={setToggleEdit}
                handleEdit={handleEdit}
                handleSave={handleSave}
                handleCancel={handleCancel}
                onChangeSwitch={onChangeSwitch}
                onChangeInput={onChangeInput}
                onChangeIncentive={onChangeIncentive}
                onSwitchIncentive={onSwitchIncentive}
              />
            </div>
            : null
        )
      }

    </PageSettings.Consumer>
  )
}