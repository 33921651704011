import React, { useContext, useState, useEffect } from 'react';
import ModalCustomerPost from './modal-post';
import { PageEngagement } from '../../config/page-settings';
import _, { map } from 'lodash';
import { successNotification, errorNotification } from '../v2/notification';
import { postTaskData, postTaskDataImageFile } from '../../controllers/post-task/post-all';
import { v4 as uuidv4 } from 'uuid';

export default React.memo((props) => {
  const { visible, setVisible, taskNo, taskDetailId, setTrigger, subTaskWfId } = props
  const [customer, setCustomer] = useState([]);
  const [triggerCustomer, setTriggerCustomer] = useState(false);
  const [visibleModalCustomer, setVisibleModalCustomer] = useState(false);
  const [visibleModalLocation, setVisibleModalLocation] = useState(false)
  const [customerId, setCustomerId] = useState();
  const [message, setMessage] = useState('');
  const [selectedImageBlob, setSelectedImageBlob] = useState([]);
  const [selectedFilesBlob, setSelectedFilesBlob] = useState([]);
  const [addressData, setAddressData] = useState()
  //---------------------- Address --------------------------------
  const [locationData, setLocationData] = useState([])
  const [center, setCenter] = useState({ lat: 13.90607, lng: 100.51913 });
  const [position, setPosition] = useState({ lat: 13.90607, lng: 100.51913 })
  const [visibleInfo, setVisibleInfo] = useState(false)
  const [selectAddress, setSelectAddress] = useState([])
  const [dragMarker, setDragMarker] = useState()
  const [latLng, setLatLng] = useState({})
  const [visibleCreateAddress, setVisibleCreateAddress] = useState(false)
  const [toLocation, setToLocation] = useState()
  const [fieldChange, setFieldChange] = useState('')
  const [valueInput, setValueInput] = useState()
  const [triggerApi, setTriggerApi] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState('default');
  const [selectAddressData, setSelectAddressData] = useState()



  useEffect(() => {
    const setData = async () => {
      // const data = await getCustomer(1, 10000);
      // setCustomer(data.customers);
    }
    setData();
  }, [triggerCustomer])

  // console.log('selectedImageBlob ::', selectedImageBlob)

  const handleCreatePost = async () => {
    setLoading(true)
    // if (customerId) {
    const lat = _.get(addressData, 'lat') ? _.get(addressData, 'lat') : undefined;
    const lng = _.get(addressData, 'lng') ? _.get(addressData, 'lng') : undefined;
    const address = _.get(addressData, 'address') ? _.get(addressData, 'address') : undefined;
    const memComId = localStorage.getItem('memComId');

    const data = {
      taskId: taskDetailId,
      address: address,
      memComId: memComId,
      description: message,
      lat: lat,
      lng: lng,
      subTaskWfId:subTaskWfId,
    }

    try {
      const response = await postTaskData(data);
      if (response.status === 200) {
        let mapImg = [];
        for (let index = 0; index < _.size(selectedImageBlob); index++) {
          let formData = new FormData();
          formData.append('file', selectedImageBlob[index].file);
          formData.append('uuid', uuidv4());
          formData.append('type', 'photo');
          formData.append('descriptionPhoto', selectedImageBlob[index].description);
          const fileResponse = await postTaskDataImageFile(_.get(response, 'data._id'), formData)
          mapImg.push(fileResponse.status === 200)
        }
        let mapFile = [];
        for (let index = 0; index < _.size(selectedFilesBlob); index++) {
          let formData = new FormData();
          formData.append('file', selectedFilesBlob[index].file);
          formData.append('uuid', uuidv4());
          formData.append('type', 'file');
          const fileResponse = await postTaskDataImageFile(_.get(response, 'data._id'), formData)
          mapFile.push(fileResponse.status === 200)
        }

        setTrigger(current => !current)
        setVisible(false)
        setCustomerId(undefined);
        setSelectedImageBlob([]);
        setSelectedFilesBlob([]);
        setMessage('');
        setSelectAddress([]);
        setDragMarker();
        setVisibleInfo(false);
        setLatLng({ lat: 13.90607, lng: 100.51913 });
        setToLocation();
        setFieldChange();
        setValueInput();
        setAddressData();
        setChecked('default');
        setSelectAddressData();
        successNotification(response.message);
        setLoading(false);

        if (_.size(mapFile) !== 0) {
          const mapFileTrue = mapFile.map(item => { return item === true })
          successNotification(`Attachment has ${_.size(mapFileTrue)}/${_.size(selectedFilesBlob)} uploaded`);
        }
        if (_.size(mapImg) !== 0) {
          const mapFileTrue = mapImg.map(item => { return item === true })
          successNotification(`Photo has ${_.size(mapFileTrue)}/${_.size(selectedImageBlob)} uploaded`);
        }
      } else {
        errorNotification(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  // }

  const handleCancelPost = () => {
    setVisible(false)
    setCustomerId(undefined);
    setSelectedImageBlob([]);
    setSelectedFilesBlob([]);
    setMessage('');
    // fnc.handleCancelModalAddPost();
    setSelectAddress([]);
    setDragMarker();
    setVisibleInfo(false);
    setLatLng({ lat: 13.90607, lng: 100.51913 });
    setToLocation();
    setFieldChange();
    setValueInput();
    setAddressData();
    setChecked('default');
    setMessage(undefined);
    setSelectAddressData();
  }

  return (
    <div>
      <ModalCustomerPost
        value={{
          customer,
          visible,
          visibleModalCustomer,
          visibleModalLocation,
          customerId,
          selectedFilesBlob,
          selectedImageBlob,
          addressData,
          message,
          loading,
          checked,
          locationData,
          center,
          position,
          visibleInfo,
          selectAddress,
          dragMarker,
          latLng,
          visibleCreateAddress,
          toLocation,
          fieldChange,
          valueInput,
          triggerApi,
          selectAddressData,
          taskNo,
        }}
        fnc={{
          setTriggerCustomer,
          handleCancelPost,
          handleCreatePost,
          setVisibleModalCustomer,
          setVisibleModalLocation,
          setCustomerId,
          setSelectedFilesBlob,
          setCustomer,
          setSelectedImageBlob,
          setAddressData,
          setMessage,

          setLocationData,
          setCenter,
          setPosition,
          setVisibleInfo,
          setSelectAddress,
          setDragMarker,
          setLatLng,
          setVisibleCreateAddress,
          setToLocation,
          setFieldChange,
          setValueInput,
          setTriggerApi,
          setChecked,
          setSelectAddressData,
        }}
      />
    </div>
  )
})
