import React, { useEffect, useState } from 'react';
import { Form, Modal, Card, Row, Col, Select, DatePicker, Input, Collapse, Icon, Divider, AutoComplete, Avatar, Tag } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import LabeRequireForm from '../label-required-form';
import Button_01 from '../v2/button_01';
import Button_02 from '../v2/button_02';
import ModalReasonRequestForm from './reason';

const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
const { Search } = Input;

const TypeItemRequest = (props) => {
  const {
    visible,
    setVisible,
    form,
    basket,
    handleClickItem,
    setBasket,
    setMainTrigger,
    requestTypeData,
    typeModal,
    recordRequest,
    changeToApproval,
    changeToReject,
    changeTab,
    changeToCancel,
  } = props;
  const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue, resetFields } = form;
  const intl = useIntl();
  const basketWarehouse = _.get(basket, 'itemRequestList');
  // const [basketWarehouse, setBasketWarehouse] = useState([]);
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState('');
  const [indexEnd, setIndexEnd] = useState(50);
  const [requestDetailData, setRequestDetailData] = useState([]);

  //-------------------------------
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [tempTab, setTempTab] = useState(changeTab);
  const [tempType, setTempType] = useState(typeModal);

  console.log('basketWarehouse', tempTab);

  useEffect(() => {
    if (visible === true) {
      setTempTab(changeTab);
      setTempType(typeModal);
    }
  }, [visible]);

  const handleCancel = () => {
    setRequestDetailData([]);
    resetFields();
    setVisible(false);
    setTempTab();
    setTempType();
  };

  const headerTilte = (name, size) => {
    return (
      <div className="modalBasketCollapsHeader">
        {name} ({size})
      </div>
    );
  };

  // const handleRemoveAll = () => {
  //   Modal.confirm({
  //     className: 'customer-modal-confirm',
  //     centered: true,
  //     icon: null,
  //     width: 280,
  //     title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
  //     okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
  //     cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
  //     okButtonProps: {
  //       style: {
  //         width: '96px',
  //       },
  //       type: 'primary',
  //     },
  //     cancelButtonProps: {
  //       style: {
  //         width: '96px',
  //       },
  //       type: 'danger',
  //     },
  //     async onOk() {
  //       setBasket([]);
  //       setBasketWarehouse([]);
  //     },
  //     onCancel() {},
  //   });
  // };

  const openRejectModal = (name, size) => {
    setVisibleReject(true);
  };

  const closeRejectModal = (name, size) => {
    setVisibleReject(false);
  };

  const openApporveModal = (name, size) => {
    setVisibleApprove(true);
  };

  const closeApproveModal = (name, size) => {
    setVisibleApprove(false);
  };

  const openCancelModal = (name, size) => {
    setVisibleCancel(true);
  };

  const closeCModal = (name, size) => {
    setVisibleCancel(false);
  };

  const handelButton = (type, tab) => {
    console.log('type&tab', type, '/', tab);
    if (type && tab) {
      if (type === 'my') {
        if (_.get(basket, 'statusCode') === 'waiting' && _.size(_.get(basket, 'approval1Info')) <= 0) {
          return [
            <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
              <FormattedMessage id="ToReviewClose" defaultMessage="Close" />
            </Button_02>,
            <Button_01 style={{ margin: '0px 0px 0px 10px', width: '20%' }} key="submit" type="primary" onClick={() => openCancelModal()}>
              <FormattedMessage id="MyRequestCancelRequest" defaultMessage="Cancel Request" />
            </Button_01>,
          ];
        } else {
          return [
            <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={handleCancel}>
              <FormattedMessage id="ToReviewClose" defaultMessage="Close" />
            </Button_01>,
          ];
        }
      } else if (tab === '1') {
        return [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="ToReviewClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => openRejectModal()}>
            <FormattedMessage id="ToReviewReject" defaultMessage="Reject" />
          </Button_01>,
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => openApporveModal()}>
            <FormattedMessage id="ToReviewApprove" defaultMessage="Approve" />
          </Button_01>,
        ];
      } else {
        return [
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="ToReviewClose" defaultMessage="Close" />
          </Button_01>,
        ];
      }
    } else {
      return [];
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'menuItemRequest', defaultMessage: 'Item Request' }) + ' · ' + _.get(basket, 'requestNo')}
      centered={true}
      visible={visible}
      width={668}
      zIndex={500}
      onCancel={handleCancel}
      bodyStyle={{ padding: 'unset', maxHeight: '454px', overflowY: 'scroll' }}
      footer={handelButton(tempType, tempTab)}
    >
      <div className="modalRequestItemBackground">
        <Card style={{ borderRadius: '10px' }} bodyStyle={{ padding: '16px 24px' }}>
          <Form form={form} layout="vertical">
            <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRequestorText', defaultMessage: 'Requestor' })}
                      req={false}
                      bold={true}
                    />
                  }
                >
                  <div>{_.get(basket, 'requestorInfo.requestorName')}</div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemExpectDateText', defaultMessage: 'Expect Date' })}
                      req={false}
                      bold={true}
                    />
                  }
                >
                  <div>{_.get(basket, 'expectDate')}</div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRecipientText', defaultMessage: 'Recipient' })}
                      req={false}
                      bold={true}
                    />
                  }
                >
                  <div>{_.get(basket, 'recipientInfo.name')}</div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemPhoneText', defaultMessage: 'Phone' })}
                      req={false}
                      bold={true}
                    />
                  }
                >
                  <div>{_.get(basket, 'phone')}</div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRequestTypeText', defaultMessage: 'Request Type' })}
                      req={false}
                      bold={true}
                    />
                  }
                >
                  <div>{_.get(basket, 'requestItemType')}</div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRequestDetailText', defaultMessage: 'Request Detail' })}
                      req={false}
                      bold={true}
                    />
                  }
                >
                  <div>{_.get(basket, 'requestDetailModal')}</div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRemarkText', defaultMessage: 'Remark' })}
                      req={false}
                      bold={true}
                    />
                  }
                >
                  <div>{_.get(basket, 'remark')}</div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <div style={{ marginTop: '16px' }}>
          {_.size(basketWarehouse) > 0 ? (
            <Collapse className="modalBasketCollaps" style={{ borderRadius: '10px' }} expandIconPosition="right" defaultActiveKey={['1']}>
              <Panel
                key={1}
                header={<div style={{ paddingLeft: '14px' }}>{headerTilte(basket.warehouseName, _.size(basketWarehouse))}</div>}
              >
                {_.map(basketWarehouse, (e, i) => (
                  <div style={{ padding: '0px 8px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => {}}>
                      <div>
                        <div style={{ display: 'flex' }}>
                          {e.itemImgUrl ? (
                            <div>
                              <img src={e.itemImgUrl} className="modalRequestItemImg" />
                            </div>
                          ) : null}
                          <div style={{ marginLeft: _.get(e, 'itemImgUrl') ? '16px' : '0' }}>
                            <div className="modalBasketCardTextName">{e.itemCode + ' · ' + e.itemMasterName + ' ' + e.branName}</div>

                            <div className="modalBasketCardTextDescription">{e.addOn}</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="modalBasketCardTextStock">{e.qty}</div>
                        <div className="modalBasketCardTextItems">
                          {e.uomName}
                          {/* <FormattedMessage id="assetpreventiveItem" defaultMessage="Items" /> */}
                        </div>
                      </div>
                    </div>
                    {i !== _.size(basketWarehouse) - 1 ? <Divider type="horizontal" /> : null}
                  </div>
                ))}
              </Panel>
            </Collapse>
          ) : null}

          {_.get(basket, 'approval1Info.name') ? (
            <div style={{ marginTop: '16px' }}>
              <Card style={{ borderRadius: '10px' }} bodyStyle={{ padding: '16px 24px' }}>
                <div className="modalRequestStatusAttachment">
                  <FormattedMessage id="approvalTabApprovalTitle" defaultMessage="Approval" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '8px', textAlign: 'center' }}>
                      <Avatar src={_.get(basket, 'approval1Info.imgUrl')} />
                    </div>
                    <div>
                      <div style={{ color: '#1D3557', fontSize: '15px', fontWeight: '700px' }}>
                        <FormattedMessage id="approvalTabApprovalLineApprover" defaultMessage="Line Approver" /> {' · '}{' '}
                        <span style={{ color: '#505050' }}>{_.get(basket, 'approval1Info.teamName')}</span>
                      </div>
                      <div style={{ color: '#505050', fontSize: '15px', fontWeight: 'bold' }}>{_.get(basket, 'approval1Info.name')}</div>
                      <div style={{ marginBottom: '16px', color: '#9A9999', fontSize: '13px' }}>
                        <FormattedMessage id="approvalTabApprovalReviewed" defaultMessage="Reviewed on" />{' '}
                        {_.get(basket, 'approval1Info.approveAt')}
                      </div>
                      {_.get(basket, 'approval1Info.statusCode') === 'reject' ? (
                        <div style={{ color: '#050505', fontSize: '14px' }}>
                          {_.get(basket, 'approval1Info.reason') ? `${_.get(basket, 'approval1Info.reason')},` : ''}{' '}
                          {_.get(basket, 'approval1Info.comment')}
                        </div>
                      ) : <div style={{ color: '#050505', fontSize: '14px' }}>{_.get(basket, 'approval1Info.comment')}</div>}
                    </div>
                  </div>
                  <div>
                    <Tag style={{ borderRadius: '15px' }} color={_.get(basket, 'approval1Info.statusColor')}>
                      {' '}
                      {_.get(basket, 'approval1Info.statusTxt')}
                    </Tag>
                  </div>
                </div>
                {_.get(basket, 'approval2Info.name') ? (
                  <div style={{ marginTop: '16px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '8px', textAlign: 'center' }}>
                          <Avatar src={_.get(basket, 'approval2Info.imgUrl')} />
                        </div>
                        <div>
                          <div style={{ color: '#1D3557', fontSize: '15px', fontWeight: '700px' }}>
                            <FormattedMessage id="approvalTabApprovalWareHouseApprover" defaultMessage="WareHouse Approver" /> {' · '}{' '}
                            <span style={{ color: '#505050' }}>{_.get(basket, 'approval2Info.teamName')}</span>
                          </div>
                          <div style={{ color: '#505050', fontSize: '15px', fontWeight: 'bold' }}>
                            {_.get(basket, 'approval2Info.name')}
                          </div>
                          <div style={{ marginBottom: '16px', color: '#9A9999', fontSize: '13px' }}>
                            <FormattedMessage id="approvalTabApprovalReviewed" defaultMessage="Reviewed on" />{' '}
                            {_.get(basket, 'approval2Info.approveAt')}
                          </div>
                          {_.get(basket, 'approval2Info.statusCode') === 'reject' ? (
                            <div style={{ color: '#050505', fontSize: '14px' }}>
                              {_.get(basket, 'approval2Info.reason') ? `${_.get(basket, 'approval2Info.reason')},` : ''}{' '}
                              {_.get(basket, 'approval2Info.comment')}
                            </div>
                          ) : (
                            <div style={{ color: '#050505', fontSize: '14px' }}>{_.get(basket, 'approval2Info.comment')}</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <Tag style={{ borderRadius: '15px' }} color={_.get(basket, 'approval2Info.statusColor')}>
                          {' '}
                          {_.get(basket, 'approval2Info.statusTxt')}
                        </Tag>
                      </div>
                    </div>
                  </div>
                ) : null}
              </Card>
            </div>
          ) : null}
        </div>
      </div>
      <ModalReasonRequestForm
        title={intl.formatMessage({ id: 'ModalViewItemRequestTitleReject', defaultMessage: 'Reject' }) + ' · ' + _.get(basket, 'requestNo')}
        visible={visibleReject}
        onCancel={closeRejectModal}
        typeModalReason={'reject'}
        initialReasonList={_.get(basket, 'reason.reject')}
        recordRequest={recordRequest}
        onOk={changeToReject}
        mainModalCancel={handleCancel}
      />
      <ModalReasonRequestForm
        title={
          intl.formatMessage({ id: 'ModalViewItemRequestTitleApporve', defaultMessage: 'Approve' }) + ' · ' + _.get(basket, 'requestNo')
        }
        visible={visibleApprove}
        onCancel={closeApproveModal}
        typeModalReason={'approve'}
        recordRequest={recordRequest}
        onOk={changeToApproval}
        mainModalCancel={handleCancel}
      />
      <ModalReasonRequestForm
        title={intl.formatMessage({ id: 'ModalViewItemRequestTitleCancel', defaultMessage: 'Cancel' }) + ' · ' + _.get(basket, 'requestNo')}
        visible={visibleCancel}
        onCancel={closeCModal}
        typeModalReason={'cancel'}
        initialReasonList={_.get(basket, 'reason.cancel')}
        recordRequest={recordRequest}
        onOk={changeToCancel}
        mainModalCancel={handleCancel}
      />
    </Modal>
  );
};

const ModalTypeItemRequest = Form.create({
  name: 'modal_form',
})(TypeItemRequest);

export default ModalTypeItemRequest;
