import React, { useState, useRef, useEffect, useContext } from 'react';
import { Card, Popover, Table, Modal, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Button01 from '../../../components/v2/button_01';
import ModalGasprice from '../modal/ModalGasprice';
import getGasprice from '../../../controllers/gasprice/getgasprice-api';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';
import removeGasprice from '../../../controllers/gasprice/remove-gasprice-api';

function GaspriceTable() {
  const ref = useRef();
  const intl = useIntl();
  const { state, setState, fnc } = useContext(PageSettings);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [getDataGasprice, setgetDataGasprice] = useState();
  const [data_Update, setdataUpdate] = useState();
  const [isloading, setIsloading] = useState(false);
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const total = _.size(getDataGasprice) || 0;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      render: (text, record, index) => (
        <span>
          <p>{index += 1}</p>
        </span>
      ),
    },
    {
      title: intl.formatMessage({ id: `GasTypeTableC2`, defaultMessage: 'Gas Type' }),
      dataIndex: 'fuel_name',
      key: 'fuel_name',
      width: 90,
      // sorter: (a, b) => a.empCode - b.empCode,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `GasTypeTableC3`, defaultMessage: 'Price/L' }),
      dataIndex: 'fuel_cost',
      key: 'fuel_cost',
      width: 120,
      render: (text, record, index) => (
        <span>
          <p>{text} THB</p>
        </span>
      ),
      // sorter: (a, b) => a.Name - b.Name,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `GasTypeTableC4`, defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      width: 230,
      // sorter: (a, b) => a.Name - b.Name,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `GasTypeTableC5`, defaultMessage: 'Submitted by' }),
      dataIndex: 'created_by',
      key: 'created_by',
      width: 120,
      // sorter: (a, b) => a.Name - b.Name,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `GasTypeTableC6`, defaultMessage: 'Submitted Date' }),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 120,
      render: (text, record, index) => {
        const date = new Date(text);
        const day = date.toLocaleDateString('en-US', { weekday: 'short' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const formattedDate = `${day} ${date.getDate()}, ${month} ${date.getFullYear()}`;
    
        return (
          <span>
            <p>{formattedDate}</p>
          </span>
        );
      },
    }
  ];

  useEffect(() => {
    logGasprice();

    getGasprice()
      .then((gasprice) => {
        setgetDataGasprice(gasprice);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isloading]);

  const logGasprice = async () => {
    setIsLoading(true);
    try {
      const gasprice = await getGasprice();
      console.log(gasprice);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const showmodal = () => {
    setShowModal(true);
    setTitle('0');
  };

  const showmodalEdit = (value) => {
    setShowModal(true);
    setTitle('1');
    setdataUpdate(value);
  };

  const handleOk = (e) => {
    setIsloading((prev) => !prev)
    setShowModal(false);
    logGasprice();
  };

 

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleDelete = (value) => {
    const data = {
      fuel_type_id: value?.fuel_type_id,
      fuel_name: value?.fuel_name,
      fuel_cost: value?.fuel_cost,
      description: value?.description,
      deleted: true,
    };
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'GasPriceMasterTableDeleteConfirmText', defaultMessage: 'Are you sure to delete a gas price ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          if (removeGasprice(value?.fuel_type_id, data)) {
            handleOk();
            successNotification(intl.formatMessage({ id: 'Deletedatasuccess', defaultMessage: 'Delete data success' }));
          } else {
            errorNotification(intl.formatMessage({ id: 'Failtodeletedata', defaultMessage: 'Fail to delete data' }));
          }
        } catch (error) {}
      },
    });
  };



  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          // key={index}
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          // visiblePopover={visiblePopover}
          // onVisibleChange={handleOnVisiblePopover}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P36PG1C1', 'P36PG1C1A3') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => showmodalEdit(record)}>
                          <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P36PG1C1', 'P36PG1C1A4') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            handleDelete(record);
                          }}
                        >
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <>
      <Card
        title={<FormattedMessage id={'title-gasprice'} defaultMessage="Gas Price" />}
        extra={
          <Button01 type="primary" onClick={showmodal}>
            <FormattedMessage id={'btn-create-myrequest'} defaultMessage="Create" />
          </Button01>
        }
        style={{ borderRadius: '10px' }}
      >
        <div onMouseLeave={handleMouseLeave}>
          <Table
            className="report-table"
            columns={columns}
            loading={isLoading}
            dataSource={getDataGasprice}
            scroll={{ x: 1300, y: `calc(70vh - 373px)` }}
            pagination={{
              total: total,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              showTotal: showTotal,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  console.log('event', event, record);

                  if (!event.target.href) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                },
              };
            }}
          />
          {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        </div>
        <div>
          <ModalGasprice title={title} showModal={showModal} onCloseModal={handleOk} dataUpdate={data_Update} />
        </div>
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      </Card>
    </>
  );
}

export default GaspriceTable;
