import { Col, DatePicker, Form, Row, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
import { numberFormatDecimal } from '../../../../../component-function/fnc-number-format-decimal';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const PriceFormView = ({ form, selectItem, setDataForm, dataForm, initialTaxList }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'subtotal') : 0.0;
  const discountNumber =
    _.size(selectItem) > 0
      ? getFieldValue('discount')
        ? parseFloat(getFieldValue('discount'))
        : parseFloat(_.toString(_.get(dataForm, 'discountPercent')))
      : 0.0;
  const discount = _.size(selectItem) > 0 ? (parseFloat(sumTotal) * discountNumber) / 100 : 0.0;
  const subtotalMinust = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'subtotal') - discount : 0.0;
  const subtotalDiscount =
    _.size(selectItem) > 0
      ? getFieldValue('discountNumber')
        ? parseFloat(sumTotal) - parseFloat(getFieldValue('discountNumber'))
        : parseFloat(sumTotal) - discount
      : 0.0;
  const taxNumber =
    _.size(selectItem) > 0
      ? getFieldValue('tax')
        ? parseFloat(getFieldValue('tax'))
        : parseFloat(_.toString(_.get(dataForm, 'taxPercent')))
      : 0.0;
  const tax = _.size(selectItem) > 0 ? (parseFloat(subtotalDiscount) * taxNumber) / 100 : 0.0;
  const subtotalTax = _.size(selectItem) > 0 ? parseFloat(subtotalDiscount) + tax : 0.0;

  ///-----------------------

  console.log('TaxCheck', _.size(selectItem) > 0 && _.get(dataForm, 'taxPercentage') !== undefined);
  console.log('PriceForm', selectItem, dataForm);
  console.log('PriceForm X', sumTotal, discount, discountNumber, tax, taxNumber, '/', subtotalMinust, subtotalDiscount, subtotalTax);

  useEffect(() => {
    if (discount && discount > 0) {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    } else {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  }, [discount, sumTotal]);

  useEffect(() => {
    if (discountNumber && discountNumber > 0) {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    } else {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  }, [discountNumber, sumTotal]);

  useEffect(() => {
    if (tax >= 0) {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  }, [tax, subtotalDiscount]);

  useEffect(() => {
    if (tax >= 0) {
      setFieldsValue({
        ['taxNumber']: parseFloat(tax).toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  }, [tax, subtotalDiscount]);

  const onChangeDiscount = (value) => {
    console.log('onChangeDiscount', value);
    if (value && value > 0) {
      console.log('PriceForm X discountNumber', (sumTotal * value) / 100, sumTotal * value);
      setFieldsValue({
        // ['discount']: value.toFixed(2),
        ['discountNumber']: ((sumTotal * value) / 100).toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  };

  const onChangeDiscountNumber = (value) => {
    if (value && value > 0) {
      setFieldsValue({
        ['discount']: ((value / sumTotal) * 100).toFixed(2),
        // ['discountNumber']: value.toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  };

  return (
    <Form name="pirce" className="po-form">
      <Row gutter={[24]}>
        <Col span={10}>
          <Row gutter={[24]}>
            {/* <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidto', defaultMessage: 'Valid to' })} req={false} />
                }
              >
                {_.get(dataForm, 'validDay') || '-'} <FormattedMessage id="quotationLabelDay" defaultMessage="Day" />,{' '}
                {_.get(dataForm, 'validDate') ? moment(_.get(dataForm, 'validDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {_.get(dataForm, 'remark') || '-'}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelSubtotal" defaultMessage="Subtotal" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'subTotal')) || '0.00'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelDiscount" defaultMessage="Discount" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">{numberFormatter(_.get(dataForm, 'discountPercent')) + '%'}</div>
                <div className="group-form-item-price-input-view">
                  {numberFormatter(_.get(dataForm, 'discount')) || '0.00'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelSubtotalAfterDiscount" defaultMessage="Subtotal After Discount" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'subTotalAfterDiscount')) || '0.00'}{' '}
                <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelTax" defaultMessage="Tax" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">{numberFormatter(_.get(dataForm, 'taxPercent')) + '%'}</div>
                <div className="group-form-item-price-input-view">
                  {numberFormatter(_.get(dataForm, 'tax'))} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelTotal" defaultMessage="Total" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'total')) || '0.00'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PriceFormView.defaultProps = {};

export default PriceFormView;
