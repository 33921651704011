import React, { useState, useEffect } from 'react';
import { Row, Col, List, Tag, Divider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import { useAppContext } from '../../../includes/indexProvider';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import styles from './css/summary.css';


export default ({ stateMain, LangCode }) => {

  const intl = useIntl();
  const app = useAppContext();

  const [locale, setLocale] = useState(enUS);
  const [summaryData, setSummaryData] = useState([]);
  const [tasks, setTasks] = useState(0);
  const [warning, setWarning] = useState(0);
  const [timeout, setTimeout] = useState(0);

  // console.log('summary keyTabMonitor::: ', stateMain.keyTabMonitor);
  // console.log('summary GanttView summaryDataGantt::: ', stateMain.summaryDataGantt);
  // console.log('summary MapView summaryData::: ', stateMain.summaryData);

  // console.log('summary GanttView mainOrgIdArrString::: ', stateMain.mainOrgIdArrString);
  // console.log('summary TasksGantt::: ', stateMain.tasksGantt);
  // console.log('summary WarningGantt::: ', stateMain.warningGantt);
  // console.log('summary TimeoutGantt::: ', stateMain.timeoutGantt);

  // console.log('summary tasks::: ', stateMain.tasks);
  // console.log('summary warning::: ', stateMain.warning);
  // console.log('summary timeout::: ', stateMain.timeout);

  useEffect(() => {
    if (stateMain.mainOrgIdArrString === undefined || stateMain.mainOrgIdArrString === "") {
      setSummaryData([]);
    } else {
      if (stateMain.summaryData && stateMain.keyTabMonitor === "mapView") {
        // console.log('------------------- summary mapView --------------------');
        setSummaryData(stateMain.summaryData);
        // setTasks(stateMain.tasks);
        // setWarning(stateMain.warning);
        // setTimeout(stateMain.timeout);
      } 

      if (stateMain.summaryDataGantt && stateMain.keyTabMonitor === "ganttView") {
        // console.log('------------------- summary ganttView --------------------');
        setSummaryData(stateMain.summaryDataGantt);
        // setTasks(stateMain.tasksGantt);
        // setWarning(stateMain.warningGantt);
        // setTimeout(stateMain.timeoutGantt);
      }
    }
  }, [stateMain.mainDate, stateMain.mainOrgIdArrString, stateMain.summaryDataGantt, stateMain.summaryData]);

  useEffect(() => {
    changeLocale();
  }, []);

  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);

  const changeLocale = () => {
    let changeLang = langValueState === 'EN' ? enUS : thTH;
    setLocale(changeLang);
    if (changeLang == 'EN') {
      moment.locale('en');
    } else if (changeLang == 'TH') {
      moment.locale('th-th');
    }
  };

  const data = [
    {
      id: 1,
      name: intl.formatMessage({ id: `monitorSummaryTextNewWorkDesk`, defaultMessage: 'New' }),
      color: '#1890ff',
      quantity: '4',
    },
    {
      id: 2,
      name: intl.formatMessage({ id: `monitorSummaryTextScheduledWorkDesk`, defaultMessage: 'Scheduled' }),
      color: '#febb02',
      quantity: '15',
    },
    {
      id: 3,
      name: intl.formatMessage({ id: `monitorSummaryTextAssignedWorkDesk`, defaultMessage: 'Assigned' }),
      color: '#0c4da2',
      quantity: '8',
    },
    {
      id: 4,
      name: intl.formatMessage({ id: `monitorSummaryTextAcceptedWorkDesk`, defaultMessage: 'Accepted' }),
      color: '#f88512',
      quantity: '24',
    },
    {
      id: 5,
      name: intl.formatMessage({ id: `monitorSummaryTextInProgressWorkDesk`, defaultMessage: 'In Progress' }),
      color: '#31a24c',
      quantity: '18',
    },
    {
      id: 6,
      name: intl.formatMessage({ id: `monitorSummaryTextCompletedWorkDesk`, defaultMessage: 'Completed' }),
      color: '#21723e',
      quantity: '12',
    },
    {
      id: 7,
      name: intl.formatMessage({ id: `monitorSummaryTextReturnedWorkDesk`, defaultMessage: 'Returned' }),
      color: '#fa383e',
      quantity: '1',
    },
    {
      id: 8,
      name: intl.formatMessage({ id: `monitorSummaryTextCancelledWorkDesk`, defaultMessage: 'Cancelled' }),
      color: '#9a9999',
      quantity: '0',
    },
  ];

  return (
    <div>
      <div className="monitor-summary-Row">
        <div className="monitor-summary-detail-tasks">
          {/* <p className="monitor-summary-col-p">{stateMain.keyTabMonitor === 'mapView' ? stateMain.tasks : stateMain.tasksGantt}</p> */}
          <p className="monitor-summary-col-p">{summaryData.tasks ? summaryData.tasks : 0}</p>
          <p className="monitor-summary-col-text-tasks">
            <FormattedMessage
              id={`monitorSummaryTextTasksWorkDesk`}
              defaultMessage="Tasks"
            />
          </p>
        </div>
        <Divider className="monitor-summary-divider" type="vertical" />
        <div className="monitor-summary-detail-warning">
          {/* <p className="monitor-summary-col-p">{stateMain.keyTabMonitor === 'mapView' ? stateMain.warning : stateMain.warningGantt}</p> */}
          <p className="monitor-summary-col-p">{summaryData.warning ? summaryData.warning : 0}</p>
          <p className="monitor-summary-col-text-warning">
            <FormattedMessage
              id={`monitorSummaryTextWarningWorkDesk`}
              defaultMessage="Warning"
            />
          </p>
        </div>
        <Divider className="monitor-summary-divider" type="vertical" />
        <div className="monitor-summary-detail-timeout">
          <p className="monitor-summary-col-p">{summaryData.timeout ? summaryData.timeout : 0}</p>
          <p className="monitor-summary-col-text-timeout">
            <FormattedMessage
              id={`monitorSummaryTextTimeoutWorkDesk`}
              defaultMessage="Delayed"
            />
          </p>
        </div>

        {/* <Row>
          <Col span={8} className="monitor-summary-detail-col" >
            <p className="monitor-summary-col-p">{stateMain.tasks}</p>
            <p className="monitor-summary-col-text-tasks">
              <FormattedMessage
                id="monitorSummaryTextTasks"
                defaultMessage="Tasks"
              />
            </p>
          </Col>
          <Col span={8} className="monitor-summary-detail-col" >
            <p className="monitor-summary-col-p-warning">{stateMain.warning}</p>
            <p className="monitor-summary-col-text-warning">
              <FormattedMessage
                id="monitorSummaryTextWarning"
                defaultMessage="Warning"
              />
            </p>
          </Col>
          <Col span={8} className="monitor-summary-detail-col" >
            <p className="monitor-summary-col-p-timeout">{stateMain.timeout}</p>
            <p className="monitor-summary-col-text-timeout">
              <FormattedMessage
                id="monitorSummaryTextTimeout"
                defaultMessage="Timeout"
              />
            </p>
          </Col>
        </Row> */}
      </div>

      <div className="monitor-summary-text-status">
        <span className="monitor-summary-text-p-status">
          <FormattedMessage
            id={`monitorSummaryTextStatusWorkDesk`}
            defaultMessage="Status"
          />
        </span>
      </div>

      <div className="monitor-summary-body-status">
        {summaryData.statusSummary?.length ? (
          <List
            size="default"
            dataSource={summaryData.statusSummary}
            renderItem={(item) => (
              <List.Item className="monitor-summary-body-status-List">
                <Col span={10}>
                  <span className="monitor-summary-body-status-name">
                    {item.txt}
                  </span>
                </Col>
                <Col span={4} className="monitor-summary-body-status-col-tag">
                  <Tag
                    className="monitor-summary-body-status-tag"
                    color={item.color}
                  />
                </Col>
                <Col span={10}>
                  <span className="monitor-summary-body-status-quantity">
                    {item.count}
                  </span>
                </Col>
              </List.Item>
            )}
          />
        ) : (
          <List
            size="default"
            dataSource={data}
            renderItem={(item) => (
              <List.Item className="monitor-summary-body-status-List">
                <Col span={10}>
                  <span className="monitor-summary-body-status-name">
                    {item.name}
                  </span>
                </Col>
                <Col span={4} className="monitor-summary-body-status-col-tag">
                  <Tag
                    className="monitor-summary-body-status-tag"
                    color={item.color}
                  />
                </Col>
                <Col span={10}>
                  <span className="monitor-summary-body-status-quantity">
                    0
                  </span>
                </Col>
              </List.Item>
            )}
          />
        )}
      </div>
    </div>
  );
};
