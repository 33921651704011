import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Icon, DatePicker, Select, Divider } from 'antd';
import moment from 'moment';
import '../css/action-search.css';
import _ from 'lodash';

const { Option } = Select;

const SelectDate = (props) => {
  const { dateDefault, setSelectDateDefault, selectDateDefault } = props;
  const intl = useIntl();

  const onChangeDate = (value, event) => {
    const item = event.props;

    setSelectDateDefault(item);
  };

  const dateOption = (item, index) => {
    return (
      <Option key={item.code} label={item.txt} value={item.value}>
        {item.txt}
      </Option>
    );
  };

  return (
    <div className="action-select-team-monitor">
      <Select
        placeholder={intl.formatMessage({
          id: 'monitorSelectDateMap',
          defaultMessage: 'Select Date',
        })}
        style={{ width: '165px' }}
        defaultValue={_.get(selectDateDefault, 'value') ?? undefined}
        defaultActiveFirstOption={false}
        filterOption={(input, option) => option.props.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showSearch
        onChange={onChangeDate}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div className="maximum-task-text-css">
              <FormattedMessage id="monitorSelectMaximumTask" defaultMessage="Maximum 200 Tasks" />
            </div>
          </div>
        )}
      >
        {_.get(dateDefault, 'dateList') && _.get(dateDefault, 'dateList').map((item, index) => dateOption(item, index))}
      </Select>
    </div>
  );
};

export default SelectDate;
