import React, { useContext, useEffect } from 'react';
import { Form, Select } from 'antd'
import './css/index.css'
import { CreateTaskContext } from './create-task-context'
import { useIntl, FormattedMessage } from 'react-intl';

const { Option } = Select

const TeamInfornation = (props) => {
  const intl = useIntl()

  const { form } = props
  const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue } = form;
  const { CreateState, CreateSetState, CreateFNC } = useContext(CreateTaskContext);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    const team = getFieldValue('team')
    if (team) {
      CreateSetState.setOrgId(team)
    }

  }, [getFieldValue('team')]);

  return (
    <div style={{ padding: '16px' }}>
      <Form>
        <Form.Item
          className='create-task-tab-information-form-item'
          label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBTeam', defaultMessage: 'Team' })} req={true} />}
        >
          {getFieldDecorator("team", {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'createTaskMntVLTeam', defaultMessage: 'Please select a Team' })
              }
            ]
          })(
            <Select
              placeholder={intl.formatMessage({ id: 'createTaskMntPHTeam', defaultMessage: 'Select a Team' })}
            >
              {
                CreateState.teamData && CreateState.teamData.map(item =>
                  <Option key={item.orgId}>
                    {item.name}
                  </Option>)
              }
            </Select>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}

const Team = Form.create({
  name: "modal_form",

})(TeamInfornation);

export default Team;
