import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getWarehouseByItemMaster = async (id, payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/itemmaster/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getWarehouseListByItemMaster = async (id, payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/nostock/itemmaster/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

export { getWarehouseByItemMaster, getWarehouseListByItemMaster };