import React from 'react'
import httpClient from '../../components/axiosClient';

const getItemCode = async (value) => {

  const comCode = localStorage.getItem('comCode');
   

  try {
    const response = await httpClient.get(`/v3/maintenance/manager/company/${comCode}/getitemmaster?itemCode=${value !== undefined ? value : ''}`)
    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const getItemName = async (value) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/maintenance/manager/company/${comCode}/getitemmaster?itemName=${value !== undefined ? value : ''}`)
    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const getItemNo = async (value) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/maintenance/manager/company/${comCode}/getitemno?itemNo=${value !== undefined ? value : ''}`)
    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

export { getItemCode, getItemName, getItemNo }