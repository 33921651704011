import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { ListViewContext, MonitorContext } from '../monitor-context';
import HeaderListView from '../list-view/header-list-view-report';
import ActionListView from '../list-view/action-list-view-report';
import ListView from '../list-view/list-view-report';
import Styles from './css/list-view.css';
import httpClient from '../../../components/axiosClient';

import { Row, Col } from 'antd';
import _, { debounce } from 'lodash';
import moment from 'moment';

const today = new Date();
const dateFormat = "ddd, MMM DD YYYY"
const dateApi = 'YYYY-MM-DD';
const dateNowRangePicker = [moment(today, dateFormat), moment(today, dateFormat)];

export default () => {
  const intl = useIntl();
  const {
    stateMain,
    setStateMain,
  } = useContext(MonitorContext);

  const { LangCode } = stateMain
  const typeApi = LangCode && LangCode === `WorkDesk` ? `menuType=workdesk` : ``

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index

    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableTaskNoWorkDesk`, defaultMessage: 'Task No.' }),
      dataIndex: 'taskNo',
      key: 'taskNo',
      width: 180,
      fixed: 'left',
      sorter: (a, b) => a.taskNo - b.taskNo,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <a className="list-view-a-task-view" key={record.task_id} onClick={() => handleOnClickTaskId(record)}>{record.taskNo}</a>
      )
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableTaskTypeWorkDesk`, defaultMessage: 'Task Type' }),
      dataIndex: 'taskType',
      key: 'taskType',
      width: 180,
      fixed: 'left',
      sorter: (a, b) => a.taskType - b.taskType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableCustomerWorkDesk`, defaultMessage: 'Customer' }),
      dataIndex: 'customer',
      key: 'customer',
      width: 200,
      fixed: 'left',
      sorter: (a, b) => a.customer - b.customer,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableContactNameWorkDesk`, defaultMessage: 'Contact Name' }),
      dataIndex: 'contactName',
      key: 'contactName',
      width: 250,
      // fixed: 'left',
      sorter: (a, b) => a.contactName - b.contactName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableContactPhoneWorkDesk`, defaultMessage: 'Contact Phone' }),
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      sorter: (a, b) => a.contactPhone - b.contactPhone,
      sortDirections: ['descend', 'ascend'],
      //width: 180,
      // fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableFromLocationWorkDesk`, defaultMessage: 'From Location' }),
      dataIndex: 'fromLocation',
      key: 'fromLocation',

    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableToLocationWorkDesk`, defaultMessage: 'To Location' }),
      dataIndex: 'toLocation',
      key: 'toLocation',

    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableStatusWorkDesk`, defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableStartDateWorkDesk`, defaultMessage: 'Start Date' }),
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: (a, b) => a.startDate - b.startDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableDueDateWorkDesk`, defaultMessage: 'Due Date' }),
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: (a, b) => a.dueDate - b.dueDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableActualStartDateWorkDesk`, defaultMessage: 'Actual Start Date' }),
      dataIndex: 'actualStartDate',
      key: 'actualStartDate',
      sorter: (a, b) => a.actualStartDate - b.actualStartDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableActualCompletedDateWorkDesk`, defaultMessage: 'Actual Completed Date' }),
      dataIndex: 'actualCompletedDate',
      key: 'actualCompletedDate',
      sorter: (a, b) => a.actualCompletedDate - b.actualCompletedDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableRemarkWorkDesk`, defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',

    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableReference1WorkDesk`, defaultMessage: 'Reference 1' }),
      dataIndex: 'reference1',
      key: 'reference1',
      sorter: (a, b) => a.reference1 - b.reference1,
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableReference2WorkDesk`, defaultMessage: 'Reference 2' }),
      dataIndex: 'reference2',
      key: 'reference2',
      sorter: (a, b) => a.reference2 - b.reference2,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableReference3WorkDesk`, defaultMessage: 'Reference 3' }),
      dataIndex: 'reference3',
      key: 'reference3',
      sorter: (a, b) => a.reference3 - b.reference3,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `monitorTabListViewTableCreatedDateWorkDesk`, defaultMessage: 'Created Date' }),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: (a, b) => a.createdDate - b.createdDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `reportTaskReportViewTableAssigneeNameWorkDesk`, defaultMessage: 'Assignee Name' }),
      dataIndex: 'assigneeName',
      key: 'assigneeName',
      sorter: (a, b) => a.assigneeName - b.assigneeName,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const setShowColumn = {
    index: true,
    taskNo: true,
    taskType: true,
    customer: true,
    contactName: true,
    contactPhone: false,
    fromLocation: false,
    toLocation: false,
    status: false,
    startDate: true,
    dueDate: true,
    actualStartDate: true,
    actualCompletedDate: true,
    remark: false,
    reference1: false,
    reference2: false,
    reference3: false,
    createdDate: false,
    assigneeName: false,

  };

  const setShowColumnArr = [
    'index',
    'taskNo',
    'taskType',
    'customer',
    'contactName',
    'startDate',
    'dueDate',
    'actualStartDate',
    'actualCompletedDate',

  ];

  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  const ignoreColumn = ['contactPhone', 'fromLocation', 'toLocation', 'status', 'remark', 'reference1', 'reference2', 'reference3', 'createdDate', 'assigneeName'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const [listViewData, setListviewData] = useState([]);
  const [total, setTotal] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fieldChang, setFieldChang] = useState('');
  const [selectFilter, setSelectFilter] = useState('all');
  const [rangePicker, setRangePicker] = useState(dateNowRangePicker);

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const [loading, setLoading] = useState(false);
  const [listViewReport, setListViewReport] = useState([]);
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [selectSearchData, setSelectSearchData] = useState('all');
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  const [tasks, setTasks] = useState(0);
  const [warning, setWarning] = useState(0);
  const [timeout, setTimeout] = useState(0);
  const [completed, setComplete] = useState(0);

  useEffect(() => {
    if (stateMain.mainOrgIdArrString || stateMain.mainDate) {
      getListViewReport();
    }
    const interval = setInterval(() => {
      getListViewReport();
    }, 180000);
    return () => clearInterval(interval);
  }, [stateMain.mainOrgIdArrString, stateMain.mainDate, fieldChang, rangePicker, extraSort, stateMain.valueModalVisibleStatus,
  stateMain.refreshDateAndTime, stateMain.refreshUpdateTask, stateMain.refreshTable]);

  const getListViewReport = async () => {
    setLoading(true);
    if (stateMain.mainOrgIdArrString || stateMain.mainDate) {
      let mainOrgIdArrStringNew = stateMain.mainOrgIdArrString && stateMain.mainOrgIdArrString.split(",");


      try {
        const body = {
          fromDate: moment(stateMain.mainDateStartAction).format('YYYY-MM-DD'),
          utilDate: moment(stateMain.mainDateEndAction).format('YYYY-MM-DD'),
          orgId: mainOrgIdArrStringNew ? mainOrgIdArrStringNew : [''],
          search: fieldChang,
          searchBy: selectFilter,
          paging: paginationPage,
          rowsPerPages: '1000',
          ordertype: orderSort,
          orderby: fieldSort,
        }

        const response = await httpClient.post(`/v3/task/manager/company/${comId}/tasklistview?menuType=workdesk`, body);

        if (response.status === 200) {
          setListViewReport(response.data.data.result);
          setTotal(response.data.data.totals);
          setTasks(response.data.summary.totalTasks);
          setWarning(response.data.summary.taskWarning);
          setTimeout(response.data.summary.taskTimeout);
          setComplete(response.data.summary.countComplete);
          setStateMain.setConutrowForExcel(response.data.summary.conutrowForExcel);

          setStateMain.setTotal(response.data.data.totals);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setListViewReport([]);
      setLoading(false);
    }

  };

  const handleOnClickTaskId = (record) => {
    if (record.task_id) {
      setStateMain.setMainTaskId(record.task_id);
      setStateMain.setSelectTaskData(record);
      setTimeout(() => {
        setStateMain.setVisibleTaskDetailModal(true);
      }, 500);
    }
  }

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
        setStateMain.setOrderSort('desc');
      } else {
        setOrderSort('asc');
        setStateMain.setOrderSort('asc');
      }
    } else {
      setOrderSort('');
      setStateMain.setOrderSort('');

    }
    setExtraSort(extra);
    setFieldSort(field);

    setStateMain.setFieldSort(field);
  };

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
    setStateMain.setSelectSearchData(value === undefined ? 'all' : value);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
    setStateMain.setPaginationPage(page);
  }

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
    setStateMain.setPaginationSize(size);
    setStateMain.setPaginationPage(current);
  }

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
    setStateMain.setFieldChang(fieldChang ? fieldChang : '');
  }, 500);

  const handleSelectSearch = (value) => {
    setSelectFilter(value === undefined ? 'all' : value);
  };

  const onSelectRangePicker = (date, dateString) => {
    setRangePicker(dateString);
    setStateMain.setRangePicker(dateString);
    setStateMain.setFromDate(moment(date[0]).format("YYYY-MM-DD"));
    setStateMain.setToDate(moment(date[1]).format("YYYY-MM-DD"));

    setStateMain.setMainDateStartAction(date[0]);
    setStateMain.setMainDateEndAction(date[1]);

    setStateMain.setMainDate(moment(date[0]).format(dateApi));
    setStateMain.setMainStartDate(moment(date[0]).format(dateApi));
    setStateMain.setMainEndDate(moment(date[0]).format(dateApi));
  }

  const handleSelectTeam = (value) => {
    setStateMain.setMainOrgId(value);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) { // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(newListArrayColumns, function (result, value, key) {
        result[value] = true;
        return result;
      }, {});
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ)
      setTextErrorSelectColumn('');
    }
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 6) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find(
        (element) => element == columns[i].key,
      );
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  return (

    <MonitorContext.Consumer>
      {({ stateMain }) => (
        <ListViewContext.Provider
          value={{
            state: {
              listViewData,
              columns,
              total,
              selectFilter,
              visible,
              defaultShowColumn,
              textErrorSelectColumn,
              newDataColumns,
              listViewReport,
              loading,
              tasks,
              warning,
              timeout,
              completed,
              stateMain,
              setStateMain
            },
            fnc: {
              handleSearch,
              handleSelectSearch,
              onSelectRangePicker,
              handleVisibleChange,
              handleOnVisiblecolumns,
              handleSelectTeam,
              handleReset,
              handleCheckOk,
              checkedValuecolumns,
              handlePagination,
              handleSizeChange,
              handleChange,
              handleSelectData
            }
          }}
        >
          <div className="stylesMain-list-view-body">
            <Row>
              <Col span={24}>
                <HeaderListView LangCode={LangCode} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ActionListView stateMain={stateMain} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ListView />
              </Col>
            </Row>
          </div>
        </ListViewContext.Provider>
      )}
    </MonitorContext.Consumer>
  );
};
