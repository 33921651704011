import { Card, Col, Row, Tabs, Button, Icon, Menu, Dropdown } from 'antd';
import React, { useContext, useState } from 'react';

import { useIntl, FormattedMessage } from 'react-intl';
import { ApprovalContext } from '../approval-context';

import { Sticky, StickyContainer } from 'react-sticky';
import Button01 from '../../../components/v2/button_01';
import ButtonActionGroup from './component/action-table';
import Waiting from './waiting';
import Css from './css/index.css';
import _ from 'lodash';
import NoDataBackground from '../../../components/no-data-page';
import { DownOutlined } from '@ant-design/icons';
import { PageSettings } from '../../../config/page-settings';

const { TabPane } = Tabs;

const RightCardApproval = () => {
  const { state, setState, fnc } = useContext(ApprovalContext);
  const { checkPermissionAction } = useContext(PageSettings);
  const {
    LangCode,
    checkedKeysRequest,
    checkedKeysTeam,
    changeTab,
    preChangeTab,
    searchList,
    apiDisplay,
    rightCardDisplay,
    changeStatus,
  } = state;
  const { setChangeTab, setPreChangeTab } = setState;
  const { handleToggle, changeToApproval, changeToReject, handleChangePage, handleChangeSize, onChangeTable } = fnc;
  const intl = useIntl();
  // const { setSpanRight, setSpanLeft, setShow, setStatusSummary, setKeyTabMonitor } = setState;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="ToReviewRequestNo" defaultMessage="Request No" />,
      dataIndex: 'requestNo',
      key: 'requestNo',
      sorter: true,
      fixed: 'left',
      sortDirections: ['descend', 'ascend'],
      width: 150,
    },
    {
      title: <FormattedMessage id="ToReviewRequestType" defaultMessage="Request Type" />,
      dataIndex: 'requestTypeName',
      key: 'requestTypeName',
      sorter: true,
      fixed: 'left',
      width: 180,
    },
    {
      title: <FormattedMessage id="ToReviewRequestRequestor" defaultMessage="Requestor" />,
      dataIndex: 'requestor',
      key: 'requestor',
      render: (text, record, index) =>
        (
          <span>
            {_.get(record, 'modalViewCode') === 'registration_customer'
              ? _.split(_.get(record, 'requestor'), '•', 1)
              : _.get(record, 'requestor')}
          </span>
        ) || null,
      // sorter: true,
      // width: 200,
    },
    {
      title: <FormattedMessage id="ToReviewRequestDetails" defaultMessage="Request Details." />,
      key: 'requestDetail',
      dataIndex: 'requestDetail',
      width: 350,
    },
    {
      title: <FormattedMessage id="ToReviewRequestTeam" defaultMessage="Team." />,
      key: 'teamName',
      dataIndex: 'teamName',
      // render:(text, record, index) => <span>{record === null ? null : record.slice(3)}</span> || null,

      // width: 150,
    },
    {
      title: <FormattedMessage id="ToReviewRequestDate" defaultMessage="Request Date" />,
      key: 'requestDate',
      dataIndex: 'requestDate',
      sorter: true,
      // width: 100,
    },
  ];

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={0}>
      {({ style }) => (
        <Row style={{ height: '52px' }}>
          <Col span={1} className="col-button-hide">
            <div style={{ cursor: 'pointer' }} onClick={handleToggle}>
              <Icon type="menu-fold" style={{ border: 'unset', marginLeft: '12px' }} />
            </div>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff', top: 'unset' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  const handletabclick = (value) => {
    setChangeTab(value);
    handleChangePage(1);
    handleChangeSize(1, 10);
    setPreChangeTab(changeTab);
  };

  const menuColumn = (
    <Menu>
      {checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) ? (
        <Menu.Item>
          <Button ghost type="link" onClick={() => changeToApproval()}>
            <FormattedMessage id="ToReviewApprove" defaultMessage="Approve" />
          </Button>
        </Menu.Item>
      ) : null}
      {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) && _.size(changeStatus) === 1 ? (
          <Menu.Item>
            <Button ghost type="link" onClick={() => changeToReject()}>
              <FormattedMessage id="ToReviewReject" defaultMessage="Reject" />
            </Button>
          </Menu.Item>
        ) : null} */}
    </Menu>
  );

  return (
    <div>
      {rightCardDisplay === true ? (
        checkPermissionAction(`P49PG2C2`, `P49PG2C2A1`) ||
        checkPermissionAction(`P49PG2C3`, `P49PG2C3A1`) ||
        checkPermissionAction(`P49PG2C4`, `P49PG2C4A1`) ? (
          <Card className="right-card">
            <StickyContainer>
              <Tabs
                className="monitor-main-tabs"
                size="large"
                defaultActiveKey="1"
                animated={false}
                renderTabBar={handleRenderTopBar}
                onTabClick={handletabclick}
                tabBarExtraContent={
                  <div>
                    {changeTab !== '2' && changeTab !== '3' ? (
                      checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) || checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? (
                        <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                            <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                            <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                          </Button01>
                        </Dropdown>
                      ) : null
                    ) : null}
                  </div>
                }
              >
                {checkPermissionAction(`P49PG2C2`, `P49PG2C2A1`) ? (
                  <TabPane tab={intl.formatMessage({ id: `approvalTabWaitingTitle`, defaultMessage: 'Waiting' })} key="1">
                    <Waiting columns={columns} tab={'Waiting'}></Waiting>
                  </TabPane>
                ) : null}
                {checkPermissionAction(`P49PG2C3`, `P49PG2C3A1`) ? (
                  <TabPane tab={intl.formatMessage({ id: `approvalTabApprovalTitle`, defaultMessage: 'Approved' })} key="2">
                    <Waiting columns={columns} tab={'Approval'}></Waiting>
                  </TabPane>
                ) : null}
                {checkPermissionAction(`P49PG2C4`, `P49PG2C4A1`) ? (
                  <TabPane tab={intl.formatMessage({ id: `approvalTabRejectedTitle`, defaultMessage: 'Rejected' })} key="3">
                    <Waiting columns={columns} tab={'Rejected'}></Waiting>
                  </TabPane>
                ) : null}
              </Tabs>
            </StickyContainer>
          </Card>
        ) : null
      ) : (
        <div style={{ padding: '24px', backgroundColor: '#fff' }}>
          <Card className="detail-card-blank-page">
            <NoDataBackground
              text1={{ id: 'nodataApproval1', text: 'Please select a Request' }}
              text2={{ id: 'nodataApproval2', text: 'Request will appear here.' }}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default RightCardApproval;
