import React, { useState, useEffect } from 'react'
import './index.css'
import { Card, Table, Dropdown, Menu, Button, Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
import ReportReceiveAction from './action'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { useDebounce } from '../../../controllers/debounce'
import moment from 'moment'
import { successNotification, errorNotification } from '../../../components/v2/notification'
import Button01 from '../../../components/v2/button_01'
import { DownOutlined } from '@ant-design/icons'
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency'
import { getReportReceive, exportReportReceive } from '../../../controllers/report/accouting-report/api'
import { useAppContext } from '../../../includes/indexProvider'

const AccoutingReportReceive = () => {
  const intl = useIntl()
  const app = useAppContext();
  const langValue = app.state.langValue;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsRefType', defaultMessage: 'Reference Type' }),
      dataIndex: 'referenceTypeText',
      key: 'referenceTypeText',
      fixed: 'left',
      sorter: true,
      width: 180,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsRefNo', defaultMessage: 'Reference No.' }),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      sorter: true,
      width: 180,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsRefDate', defaultMessage: 'Reference Date' }),
      dataIndex: 'referenceDate',
      key: 'referenceDate',
      sorter: true,
      width: 180,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsCode', defaultMessage: 'Code' }),
      dataIndex: 'code',
      key: 'code',
      width: 200,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsName', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsPaymentMethod', defaultMessage: 'Payment Method' }),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      width: 180,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      align: 'right',
      sorter: true,
      default: true,
      render: (text, record, index) => `${numberFormatter(record.amount || 0)} THB`
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsPaidDate', defaultMessage: 'Paid Date' }),
      dataIndex: 'paidDate',
      key: 'paidDate',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsBank', defaultMessage: 'Bank' }),
      dataIndex: 'bank',
      key: 'bank',
      width: 150,
      default: true,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsDescription', defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      width: 200,
      default: true,
      sorter: true,
      render: (text, record, index) => langValue === 'TH' ? _.get(record, 'description.descriptionTH') : _.get(record, 'description.descriptionEN')
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsReceiveBy', defaultMessage: 'Receive By' }),
      dataIndex: 'receiveBy',
      key: 'receiveBy',
      width: 150,
      default: true,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'reportReceiveColumnsReceiveDate', defaultMessage: 'Receive Date' }),
      dataIndex: 'receiveDate',
      key: 'receiveDate',
      width: 150,
      default: true,
      sorter: true,
    },
  ];
  const setShowColumnArr = _.map(columns, (item) => { return item.dataIndex })
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  const ignoreColumn = _.map(_.filter(columns, (item) => { return item.default === false }), (item) => { return item.dataIndex })
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [search, setSearch] = useState({searchdDate: [moment().startOf('month'), moment().endOf('month')]});
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2
  const [sortOrder, setSortOrder] = useState('')
  const [sortField, setSortField] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([])
  const [refTypeList, setRefTypeList] = useState([])
  const searchDebounce = useDebounce(search, 500)
  const [summary, setSummary] = useState([])
  const [bankList, setBankList] = useState([])
  const filterBank = _.filter(summary, (item) => { return item.code === "banktransfer" })
  const filterCheque = _.filter(summary, (item) => { return item.code === "cheque" })
  const filterCash = _.filter(summary, (item) => { return item.code === "cash" })
  const filterOther = _.filter(summary, (item) => { return item.code !== "banktransfer" && item.code !== "cash" && item.code !== "cheque" })
  const [isFetch, setIsFetch] = useState(false)

  useEffect(() => {
    const getListDate = async () => {
      setLoading(true)
      const body = {
        "where": {
          "referenceType": _.get(search, 'refType') || undefined,
          "referenceNo": _.get(search, 'refNo') || undefined,
          "taxNo": _.get(search, 'taxNo') || undefined,
          "code": _.get(search, 'customerCode') || undefined,
          "name": _.get(search, 'customerName') || undefined,
          "paymentMethod": _.get(search, 'paymentMethod') || undefined,
          "referenceDate": _.get(search, 'searchdDate') && _.size(search.searchdDate) ? {
            "startDate": moment(_.get(search, 'searchdDate[0]')).format('YYYY-MM-DD'),
            "endDate": moment(_.get(search, 'searchdDate[1]')).format('YYYY-MM-DD'),
          } : undefined,
          "receiveDate": _.get(search, 'receiveDate') && _.size(search.receiveDate) ? {
            "startDate": moment(_.get(search, 'receiveDate[0]')).format('YYYY-MM-DD'),
            "endDate": moment(_.get(search, 'receiveDate[1]')).format('YYYY-MM-DD'),
          } : undefined
        },
        "page": page,
        "pageSize": size,
        "orderBy": sortField,
        "order": sortOrder,
      }
      const response = await getReportReceive(body)
      setListData(_.get(response, 'data.list'))
      setTotal(_.get(response, 'data.total'))
      setRefTypeList(_.get(response, 'data.initialData'))
      setSummary(_.get(response, 'data.listSummary'))
      setBankList(_.get(response, 'data.initialDataPaymentMethod'))
      setLoading(false)
      
    }
    getListDate()
  }, [page, size, sortOrder, sortField, isFetch, _.get(searchDebounce, 'searchdDate')])

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
    // setPage(1)
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    try {
      const body = {
        "where": {
          "referenceType": _.get(search, 'refType') || undefined,
          "referenceNo": _.get(search, 'refNo') || undefined,
          "taxNo": _.get(search, 'taxNo') || undefined,
          "code": _.get(search, 'customerCode') || undefined,
          "name": _.get(search, 'customerName') || undefined,
          "paymentMethod": _.get(search, 'paymentMethod') || undefined,
          "referenceDate": _.get(search, 'searchdDate') && _.size(search.searchdDate) ? {
            "startDate": moment(_.get(search, 'searchdDate[0]')).format('YYYY-MM-DD'),
            "endDate": moment(_.get(search, 'searchdDate[1]')).format('YYYY-MM-DD'),
          } : undefined,
          "receiveDate": _.get(search, 'receiveDate') && _.size(search.receiveDate) ? {
            "startDate": moment(_.get(search, 'receiveDate[0]')).format('YYYY-MM-DD'),
            "endDate": moment(_.get(search, 'receiveDate[1]')).format('YYYY-MM-DD'),
          } : undefined
        },
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsRefType', defaultMessage: 'Reference Type' }),
            sequence: 1,
            colCode: 'referenceTypeText',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsRefNo', defaultMessage: 'Reference No.' }),
            sequence: 2,
            colCode: 'referenceNo',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsRefDate', defaultMessage: 'Reference Date' }),
            sequence: 3,
            colCode: 'referenceDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsCode', defaultMessage: 'Code' }),
            sequence: 4,
            colCode: 'code',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsName', defaultMessage: 'Name' }),
            sequence: 5,
            colCode: 'name',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsPaymentMethod', defaultMessage: 'Payment Method' }),
            sequence: 6,
            colCode: 'paymentMethod',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsAmount', defaultMessage: 'Amount' }),
            sequence: 7,
            colCode: 'amount',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsPaidDate', defaultMessage: 'Paid Date' }),
            sequence: 8,
            colCode: 'paidDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsBank', defaultMessage: 'Bank' }),
            sequence: 9,
            colCode: 'bank',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsDescription', defaultMessage: 'Description' }),
            sequence: 10,
            colCode: 'description',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsReceiveBy', defaultMessage: 'Receive By' }),
            sequence: 11,
            colCode: 'receiveBy',
          },
          {
            colName: intl.formatMessage({ id: 'reportReceiveColumnsReceiveDate', defaultMessage: 'Receive Date' }),
            sequence: 12,
            colCode: 'receiveDate',
          },
        ],
        "page": 1,
        "pageSize": total,
        "orderBy": sortField,
        "order": sortOrder,
      }
      const response = await exportReportReceive(body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) { }
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );


  return (
    <div>
      <Card
        className='reportReceiveCard'
        title={intl.formatMessage({ id: 'menuReportReceivePayment', defaultMessage: 'Receive and Payment' })}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
        extra={<Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
          <Button01 key="submit" type="primary" btnsize="wd_df">
            <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
            <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
          </Button01>
        </Dropdown>}
      >
        <Row style={{ padding: '32px 24px 16px 24px' }}>
          <Col span={5} style={{ textAlign: 'center' }}>
            <div className='reportReceiveTextHead'>
              <FormattedMessage id="menuReportReceivePaymentCash" defaultMessage="Cash" />
            </div>
            <div className='reportReceiveTextValue'>
              {numberFormatter(_.get(filterCash, '[0].total') || 0)} THB
            </div>
          </Col>

          <Col span={5} style={{ textAlign: 'center' }}>
            <div className='reportReceiveTextHead'>
              <FormattedMessage id="menuReportReceivePaymentBank" defaultMessage="Bank Transfer" />
            </div>
            <div className='reportReceiveTextValue'>
              {numberFormatter(_.get(filterBank, '[0].total') || 0)} THB
            </div>
          </Col>

          <Col span={5} style={{ textAlign: 'center' }}>
            <div className='reportReceiveTextHead'>
              <FormattedMessage id="menuReportCheque" defaultMessage="Cheque" />
            </div>
            <div className='reportReceiveTextValue'>
              {numberFormatter(_.get(filterCheque, '[0].total') || 0)} THB
            </div>
          </Col>

          <Col span={5} style={{ textAlign: 'center' }}>
            <div className='reportReceiveTextHead'>
              <FormattedMessage id="menuReportReceivePaymentOther" defaultMessage="Other" />
            </div>
            <div className='reportReceiveTextValue'>
              {numberFormatter(_.sumBy(filterOther, function (o) { return o.total; }))} THB
            </div>
          </Col>

          <Col span={4} style={{ textAlign: 'center' }}>
            <div className='reportReceiveTextHead'>
              <FormattedMessage id="menuReportReceivePaymentTotal" defaultMessage="Total" />
            </div>
            <div className='reportReceiveTextValue'>
              {numberFormatter(_.sumBy(_.filter(summary, (item) => { return item.code !== null }), function (o) { return o.total; }))} THB
            </div>
          </Col>
        </Row>
        <ReportReceiveAction
          search={search}
          setSearch={setSearch}
          onFilterOrder={onFilterOrder}
          refTypeList={refTypeList}
          bankList={bankList}
          setPage={setPage}
          setIsFetch={setIsFetch}
          actionColumns={{
            columns,
            setShowColumn,
            newDataColumns,
            setNewDataColumns,
            setListArrayColumns,
            setDefaultShowColumn,
            listArrayColumns,
            defaultShowColumn,
            newColumns,
            textErrorSelectColumn,
            setTextErrorSelectColumn,
            visible,
            setVisible,
            columnMinimum
          }}
        />
        <div style={{ padding: '16px 24px' }}>
          <Table
            dataSource={listData}
            columns={newDataColumns}
            scroll={{ x: _.sumBy(newDataColumns, 'width') }}
            onChange={handleChange}
            loading={loading}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              current: page,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
          />
          {_.size(listData) > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  )
}

export default AccoutingReportReceive
