import React from 'react';
import {
  Avatar,
  Icon,
  Row,
  Col,
  Tag,
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import stylesShiftIndex from './css/index.css';

const ModalFormComponent = ({ formData }) => {

  const intl = useIntl();
  const appGetlang = useAppContext();
  const langValueState = _.get(appGetlang, 'state.langValue');

  moment.locale(langValueState);


  return (
    <Row gutter={[16]}>
      <Col span={8}>
        <div className="shift-modal-avatar">
          <Avatar size={114} icon="user" src={formData.pathImage || ''} />
        </div>
      </Col>
      <Col span={16}>
        <div>
          <div className="shift-modal-div">
            <span className="shift-modal-text-fullname">{formData.name || ''}</span>
          </div>
          <div className="shift-modal-div">
            <Icon className="shift-modal-icon" type="mail" />
            <span className="shift-modal-text">{formData.email || ''}</span>
          </div>
          <div className="shift-modal-div">
            <Icon className="shift-modal-icon" type="phone" />
            <span className="shift-modal-text">{formData.phone || ''}</span>
          </div>
          <div className="shift-modal-div">
              <Icon className="shift-modal-icon" type="calendar" style={{marginTop:'4px'}}/>
            {
              formData.shiftTime && formData.shiftTime.map(item => (
                <div>
                  <span className="shift-modal-text">{item}</span>
                </div>
              ))
            }
          </div>
          <div className="shift-modal-div">
            <Icon className="shift-modal-icon" type="menu" />
            <Tag className="shift-modal-tag" color={formData.Color || '#ffffff'}>{formData.Subject || ''}</Tag>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ModalFormComponent;
