import { Card, Col, Icon, Input, Modal, Row, Tabs } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import TableRecive from './table';
import { getARVoucherPaid } from '../../../../../../controllers/account-receive';
import Button_01 from '../../../../../../components/v2/button_01';

const ModalAddVoucher = (props) => {
  const { visible, setvisible, setItemData, paidData, visiblePaid, setTempVoucher, tempVoucher,handleSelectVoucher } = props;
  const [paginationPage, setPaginationPage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [itemDataList, setItemDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [serach, setSearch] = useState('');
  const [event, setEvent] = useState(false);
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState(0);
  const intl = useIntl();

  useEffect(() => {
    async function getItem() {
      const brandList = _.map(_.get(paidData, 'groupBrandTotalPaid'), (e, i) => e.brandId);

      const modelList = _.map(_.get(paidData, 'groupModelTotalPaid'), (e, i) => e.modelId);

      try {
        const body = {
          ownerId: _.get(paidData, 'customerId'),
          brandIdList: brandList,
          modelIdList: modelList,
          searchNoOrName: serach,
          limit: size,
          pageNumber: page,
        };

        const response = await getARVoucherPaid(body);

        setItemDataList(response.data.voucherList);
        setTempVoucher(response.data.voucherList);
        setTotal(response.data.totalItem);
      } catch (error) {}
    }
    if (visiblePaid) {
      getItem();
    }
  }, [visiblePaid, serach, event]);

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const TitleModal = () => {
    return (
      <div>
        <FormattedMessage id="ARAddVouncher" defaultMessage="Add Vouncher" />
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setvisible(false)}
      centered={true}
      title={<TitleModal />}
      width={1084}
      style={{ marginTop: '15px' }}
      bodyStyle={{ padding: '0px 0px', height: 'calc(100vh - 170px)' }}
      footer={[
        <div style={{ paddingTop: '10px' }}>
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => setvisible(false)} style={{ marginTop: '10px' }}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_01>
        </div>,
      ]}
    >
      <Row>
        <Col span={14}></Col>
        <Col span={10}>
          <div className="action-report-div-left" style={{ padding: '16px 22px 12px 26px' }}>
            <Input
              className="action-report-input-7"
              placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
              allowClear
              prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
              onChange={(e) => {
                setSearch(e);
              }}
              autoComplete="off"
            />
          </div>
        </Col>
      </Row>
      <TableRecive
        data={_.size(tempVoucher) > 0 ? tempVoucher : itemDataList}
        setEvent={setEvent}
        handleChangePage={handleChangePage}
        handleChangeSize={handleChangeSize}
        total={total}
        setSort={setSort}
        isLoading={isLoading}
        page={page}
        setItemData={setItemData}
        setvisible={setvisible}
        handleSelectVoucher={handleSelectVoucher}
      />
    </Modal>
  );
};

export default ModalAddVoucher;
