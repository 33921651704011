import { Card, Col, Row, Select, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import LeftCardApproval from './left-card';
import { ApprovalContext } from './approval-context';
import RightCardApproval from './right-card';
import _, { set } from 'lodash';
import {
  getMenuFilter,
  getWaiting,
  getApprove,
  getReject,
  getSearchApproval,
  approveAllRequest,
  rejectAllRequest,
  getReasonReject,
  getDataModalItemRequest,
} from '../../controllers/approval/approval-controller';
import { PageSettings } from '../../config/page-settings';
import { errorNotification, successNotification } from '../../components/v2/notification';
import ModalRequestStatus from '../../components/request/modal-status';
import ModalRegisterKYC from '../../components/request/modal-kyc';
import ModalRegister from '../../components/request/modal-register';
import ModalRedeem from '../../components/request/modal-redeem';
import MainTypeItemRequest from '../../components/modal-request-item';
import ModalRejectStatus from '../../components/modal-status/reject';
import { useIntl } from 'react-intl';
import ModalApproveStatus from '../../components/modal-status/approve';

const { Option } = Select;

const Approval = () => {
  const intl = useIntl();
  const [checkedListRequest, setCheckedListRequest] = useState();
  const [checkteam, setCheckTeam] = useState();
  const [orgIdRaw, setOrgIdRaw] = useState();
  const [paginationPage, setPaginationPage] = useState();
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [menuList, setMenuList] = useState([]);
  const [indeterminateRequest, setIndeterminateRequest] = useState(false);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [checkedKeysRequest, setCheckedKeysRequest] = useState([]);
  const [checkedKeysTeam, setCheckedKeysTeam] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [changeStatus, setChangeStatus] = useState([]);
  const [changeTab, setChangeTab] = useState('1');
  const [preChangeTab, setPreChangeTab] = useState('1');
  const [checkAllRequest, setCheckAllRequest] = useState(false);
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleView, setVisibleView] = useState(false);
  const [viewData, setViewData] = useState();
  const [trigger, setTrigger] = useState(false);
  const [disbleApply, setDisbleApply] = useState(true);
  const [visibleKYC, setVisibleKYC] = useState(false);
  const [visibleRegister, setVisibleRegister] = useState(false);
  const [visibleRedeem, setVisibleRedeem] = useState(false);
  const [apiDisplay, setApiDisplay] = useState(false);
  const [rightCardDisplay, setRightCardDisplay] = useState(false);
  const [sort, setSort] = useState([]);

  //------------------------------------------
  const [visibleItemRequest, setVisibleItemRequest] = useState(false);
  const [mainTrigger, setMainTrigger] = useState(false);
  //-------------------------------------------

  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [resonReject, setReasonReject] = useState([]);

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };

  useEffect(() => {
    const getApiMenu = async () => {
      const response = await getMenuFilter();
      setMenuList(response.data);
    };
    getApiMenu();
    // getApiSearch();
  }, []);

  useEffect(() => {
    console.log('setDisbleApply', disbleApply);
    if (_.size(fillter) > 0) {
      setDisbleApply(false);
    } else {
      setDisbleApply(true);
    }
  }, [fillter]);

  useEffect(() => {
    setIsLoading(true);
    setSearchList([]);

    if (changeTab === '1' && rightCardDisplay === true) {
      setTimeout(() => {
        getApiSearch();
        setIsLoading(false);
        setApiDisplay(true);
      }, 1000);
    } else if (changeTab === '2') {
      setTimeout(() => {
        getApiApprove();
        setIsLoading(false);
      }, 1000);
    } else if (changeTab === '3') {
      setTimeout(() => {
        getApiReject();
        setIsLoading(false);
      }, 1000);
    }
  }, [changeTab, page, size, trigger, sort]);

  const getApiSearch = async (value) => {
    let payload = {
      requestTypes: checkedKeysRequest,
      teams: checkedKeysTeam,
      pageNumber: page,
      limit: size,
      requestor: _.get(fillter, 'requestor') || undefined,
      requestNo: _.get(fillter, 'reno') || undefined,
      submitDateFrom: _.get(fillter, 'pmdatestart') || undefined,
      submitDateTo: _.get(fillter, 'pmdateto') || undefined,
      orderBy: _.get(sort, 'columnKey'),
      orderType: _.get(sort, 'order') === 'descend' ? 'desc' : _.get(sort, 'order') ? 'asc' : undefined,
    };
    const response = await getSearchApproval(payload);
    console.log('resSearch', response.data);
    setSearchList(response.data);
  };

  const getApiApprove = async (value) => {
    console.log('valueAppro', fillter);
    let payload = {
      requestTypes: checkedKeysRequest,
      teams: checkedKeysTeam,
      pageNumber: page,
      limit: size,
      requestor: _.get(fillter, 'requestor'),
      requestNo: _.get(fillter, 'reno'),
      submitDateFrom: _.get(fillter, 'pmdatestart'),
      submitDateTo: _.get(fillter, 'pmdateto'),
      orderBy: _.get(sort, 'columnKey'),
      orderType: _.get(sort, 'order') === 'descend' ? 'desc' : _.get(sort, 'order') ? 'asc' : undefined,
    };
    const response = await getApprove(payload);
    console.log('getApprove', response.data);
    setSearchList(response.data);
  };

  const getApiReject = async (value) => {
    let payload = {
      requestTypes: checkedKeysRequest,
      teams: checkedKeysTeam,
      pageNumber: page,
      limit: size,
      requestor: _.get(fillter, 'requestor') || undefined,
      requestNo: _.get(fillter, 'reno') || undefined,
      submitDateFrom: _.get(fillter, 'pmdatestart') || undefined,
      submitDateTo: _.get(fillter, 'pmdateto') || undefined,
      orderBy: _.get(sort, 'columnKey'),
      orderType: _.get(sort, 'order') === 'descend' ? 'desc' : _.get(sort, 'order') ? 'asc' : undefined,
    };
    const response = await getReject(payload);
    console.log('getApiReject', response.data);
    setSearchList(response.data);
  };

  const changeToApproval = async (value) => {
    setIsLoading(true);
    let payload = { requestIdList: changeStatus };
    const response = await approveAllRequest(value ? value : payload);
    if (_.get(response.status, 'code') === 200) {
      successNotification(_.get(response.status, 'message'));
      setTimeout(() => {
        getApiSearch();
        setSelectedRowKeys([]);
        setIsLoading(false);
        setVisibleItemRequest(false);
      }, 2000);
    } else {
      errorNotification(_.get(response.status, 'message'));
      setSelectedRowKeys([]);
      setIsLoading(false);
    }
  };

  const changeToReject = async (value) => {
    setIsLoading(true);
    const response = await rejectAllRequest(value);
    console.log('changeStatusRe', response);
    successNotification(_.get(response.status, 'message'));
    if (_.get(response.status, 'code') === 200) {
      setTimeout(() => {
        getApiSearch();
        setVisibleItemRequest(false);
        setIsLoading(false);
      }, 2000);
    } else {
      errorNotification(_.get(response.status, 'message'));
      setSelectedRowKeys([]);
      setIsLoading(false);
    }
  };

  const allKeysRequest = getAllKeys(_.get(menuList, 'data.reqTypeGroup'));
  const onCheckRequest = (checkedKeys) => {
    setCheckedKeysRequest(checkedKeys);
    setIndeterminateRequest(!!_.size(checkedKeys) && _.size(checkedKeys) < _.size(allKeysRequest));
    setCheckAllRequest(_.size(checkedKeys) === _.size(allKeysRequest));
    setDisbleApply(false);
  };

  const allKeysTeam = getAllKeys(_.get(menuList, 'data.team'));
  const onCheckTeam = (checkedKeys) => {
    setCheckedKeysTeam(checkedKeys);
    setIndeterminateTeam(!!_.size(checkedKeys) && _.size(checkedKeys) < _.size(allKeysTeam));
    setCheckAllTeam(_.size(checkedKeys) === _.size(allKeysTeam));
    setDisbleApply(false);
    // if (checkedKeys.length === 0) {
    //   setIndeterminateTeam(false);
    // } else {
    //   setIndeterminateTeam(true);
    // }
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleChangePage = (page) => {
    console.log('pageFine', page);
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
  };

  const handleView = (value) => {
    setViewData(value);
    setVisibleView(true);
  };

  const handleKYC = (value) => {
    setViewData(value);
    setVisibleKYC(true);
  };

  const handleRegister = (value) => {
    setViewData(value);
    setVisibleRegister(true);
  };

  const handleRedeem = (value) => {
    setViewData(value);
    setVisibleRedeem(true);
  };

  const handleItemRequest = (value) => {
    setViewData(value);
    setVisibleItemRequest(true);
  };

  const handleOpenApprove = (value) => {
    setViewData(value);
    setVisibleApprove(true);
  };

  const handleCloseApprove = () => {
    setViewData([]);
    setVisibleApprove(false);
  };

  const handelSaveApprove = (values) => {
    const payload = {
      requestIdList: values.id,
      approverComment: values.resonRemark || null,
    }
    changeToApproval(payload)
    handleCloseApprove()
  };

  const handleopenReject = async (value) => {
    setViewData(value);
    if (_.get(value,'requestTypeCode') === 'requestitem') {
      const response = await getDataModalItemRequest(_.get(value, 'memReqId'));
      setReasonReject(_.get(response, 'data[0].reason.reject'))
    }
    else {
      const reaspone = await getReasonReject(_.get(value,'requestTypeCode'));
      console.log('RREE',reaspone);
      
      setReasonReject(reaspone)
    }
    setVisibleReject(true);
  };

  
  const handelSaveReject = (values) => {
    const payload = {
      requestIdList: values.id,
      reasonId: values.reasonId,
      statusRemark: values.resonRemark || null,
    }
    changeToReject(payload)
    handleCloseReject()
  };

  const handleCloseReject = () => {
    setViewData([]);
    setVisibleReject(false);
  };

  const setReasonOption = (reason) => {
    return (
      // <Option key={`${reason.reasonId}`} value={`${reason.reasonId}`}>
      //   {reason.titleCode}
      // </Option>
      <Option key={`${reason}`} value={`${reason}`}>
        {reason}
      </Option>
    );
  };



  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction(`P49PG2C1`, `P49PG2C1A1`) ? (
          <ApprovalContext.Provider
            value={{
              state: {
                checkedListRequest,
                checkteam,
                orgIdRaw,
                paginationPage,
                spanRight,
                spanLeft,
                menuList,
                indeterminateRequest,
                indeterminateTeam,
                checkedKeysRequest,
                checkedKeysTeam,
                searchList,
                checkAllRequest,
                checkAllTeam,
                changeTab,
                isLoading,
                fillter,
                selectedRowKeys,
                disbleApply,
                visibleRegister,
                apiDisplay,
                rightCardDisplay,
                changeStatus,
              },
              setState: {
                setSpanRight,
                setSpanLeft,
                setCheckedListRequest,
                setCheckTeam,
                setOrgIdRaw,
                setPaginationPage,
                setCheckedKeysRequest,
                setCheckedKeysTeam,
                setIndeterminateRequest,
                setIndeterminateTeam,
                setChangeStatus,
                setChangeTab,
                setCheckAllRequest,
                setCheckAllTeam,
                setIsLoading,
                setFillter,
                setSelectedRowKeys,
                setTrigger,
                setVisibleRegister,
                setPreChangeTab,
                setDisbleApply,
                setRightCardDisplay,
              },
              fnc: {
                onCheckRequest,
                onCheckTeam,
                getApiSearch,
                getApiApprove,
                getApiReject,
                changeToApproval,
                changeToReject,
                handleToggle,
                handleChangePage,
                handleChangeSize,
                handleView,
                handleKYC,
                handleRegister,
                handleRedeem,
                onChangeTable,
                handleItemRequest,
                handleopenReject,
                handleOpenApprove,
              },
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={toggle ? 0 : 6}>
                <LeftCardApproval />
              </Col>
              <Col span={toggle ? 24 : 18}>
                <RightCardApproval />
              </Col>
            </Row>
            <ModalRequestStatus
              visible={visibleView}
              setVisible={setVisibleView}
              status={changeTab === '1' ? 'waiting' : changeTab === '2' ? 'approve' : 'reject'}
              data={viewData}
              setTrigger={setTrigger}
            />

            <ModalRegisterKYC
              visible={visibleKYC}
              setVisible={setVisibleKYC}
              status={changeTab === '1' ? 'waiting' : changeTab === '2' ? 'approve' : 'reject'}
              data={viewData}
              setTrigger={setTrigger}
            />
            <ModalRegister
              visible={visibleRegister}
              setVisible={setVisibleRegister}
              status={changeTab === '1' ? 'waiting' : changeTab === '2' ? 'approve' : 'reject'}
              data={viewData}
              setTrigger={setTrigger}
            />

            <ModalRedeem
              visible={visibleRedeem}
              setVisible={setVisibleRedeem}
              status={changeTab === '1' ? 'waiting' : changeTab === '2' ? 'approve' : 'reject'}
              data={viewData}
              setTrigger={setTrigger}
            />

            <MainTypeItemRequest
              visible={visibleItemRequest}
              setVisible={setVisibleItemRequest}
              setMainTrigger={setMainTrigger}
              recordRequest={viewData}
              changeToApproval={changeToApproval}
              changeToReject={changeToReject}
              changeTab={changeTab}
              typeModal={'review'}
            />

            <ModalRejectStatus
              title={
                intl.formatMessage({ id: 'ModalReject', defaultMessage: 'Reject' }) + ' · ' + _.get(viewData, 'requestNo')
              }
              visible={visibleReject}
              objId={'requestIdList'}
              id={[_.get(viewData, 'memReqId')]}
              onOk={handelSaveReject}
              onCancel={handleCloseReject}
              initialReasonList={resonReject}
              setReasonOption={setReasonOption}
            />
            <ModalApproveStatus
              title={
                intl.formatMessage({ id: 'ModalApprove', defaultMessage: 'Approve' }) + ' · ' + _.get(viewData, 'requestNo')
              }
              visible={visibleApprove}
              objId={'requestIdList'}
              id={[_.get(viewData, 'memReqId')]}
              onOk={handelSaveApprove}
              onCancel={handleCloseApprove}
            />
          </ApprovalContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default Approval;
