import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Icon, Input, Modal, Row, Select, Spin, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import ListItemFilter from './filter';
import { messageLabel } from '../message-component';
import Button_01 from '../v2/button_01';
import CustomTableComponent from '../../pages/billing-note/components/table';
import LabeRequireForm from '../label-required-form';
import Button_02 from '../v2/button_02';
import { errorNotification, successNotification } from '../v2/notification';
import ModalQTYItemDO from './qty';
import { getBrandData, getListItemNew, getModelData } from '../../controllers/po/po';
import { useDebounce } from '../../controllers/debounce';

const { Option } = Select;

const ModalCreateItemDO = ({ form, visible, onCancel, setTableData, tableData }) => {
  const [reportItem, setReportItem] = useState({
    list: [],
    totalItem: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    name: '',
    brand: [],
    model: [],
    code: '',
  });
  const clearReport = reportItem;
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  // const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer } = SelectCustomerHook();
  const [event, setEvent] = useState([]);
  const [eventmodel, setEventModel] = useState([]);
  const [visibleItem, setvisibleItem] = useState(false);
  const [record, setRecord] = useState(false);
  const [isCollapse, setIsCollapse] = useState();
  //----------------------------------------------------
  const [optionsBrand, setOptionsBrand] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [brandSearch, setBrandSearch] = useState();
  const debounceBrandSearch = useDebounce(brandSearch, 500);

  const [optionsModel, setOptionsModel] = useState([]);
  const [loadingModel, setLoadingModel] = useState(false);
  const [modelSearch, setModelSearch] = useState();
  const debounceModelSearch = useDebounce(modelSearch, 500);
  //---------------------------------------------------------

  // const [event, setEvent = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (visible === false) {
      setReportItem({
        list: [],
        totalItem: 0,
        extraSort: undefined,
        fieldSort: '',
        orderSort: undefined,
        orderBy: undefined,
        paginationPage: 1,
        paginationSize: 10,
        loading: false,
        name: '',
        brand: [],
        model: [],
        code: '',
      })
      setSizeTable(10)
      setPageTable(1)
    }
  }, [visible])

  useEffect(() => {
    setReportItem((prev) => ({ ...prev, loading: true }));

    const getApi = async () => {
      const body = {
        itemBrandId: _.size(_.get(reportItem, 'brand')) > 0 ? [_.get(reportItem, 'brand')] : [],
        itemModelId:  _.size(_.get(reportItem, 'model') ) > 0 ? [_.get(reportItem, 'model')] : [],
        itemCode: _.get(reportItem, 'code') ? _.get(reportItem, 'code') : '',
        itemName: _.get(reportItem, 'name') ? _.get(reportItem, 'name') : '',
        limit: sizeTable,
        page: pageTable,
        customerId: '',
        recently: false,
        recentlyModule: 'deliveryOrderItem',
        categoryCode: 'product'
      };

      const response = await getListItemNew(body);

      setReportItem((prev) => ({
        ...prev,
        list: _.get(response, 'data.data.itemList'),
        totalItem: _.get(response, 'data.data.totalItem'),
        loading: false
      }));
    };

    if (visible === true) {
      getApi();
    }
  }, [
    eventmodel,
    visible,
    _.get(reportItem, 'paginationPage'),
    _.get(reportItem, 'paginationSize'),
    _.get(reportItem, 'fieldSort'),
    _.get(reportItem, 'orderSort'),
    pageTable,
    sizeTable,
    event,
  ]);

  useEffect(() => {
    const fetcBrand = async () => {
      setLoadingBrand(true);
      const body = {
        indexStart: 0,
        indexEnd: 9999,
        item_brand_name: brandSearch || '',
      };
      const response = await getBrandData(body);
      setOptionsBrand(_.get(response, 'data.data.list'));
      setLoadingBrand(false);
    };
    if (visible) {
      fetcBrand(); // โหลดข้อมูลเริ่มต้น
    }
    
  }, [debounceBrandSearch, visible]);

  useEffect(() => {
    const fetcModel = async () => {
      
      const body = {
        indexStart: 0,
        indexEnd: 9999,
        item_model_name: modelSearch || '',
        item_brand_id: [_.get(reportItem, 'brand')] || [],
      };
      const response = await getModelData(body);
      setOptionsModel(_.get(response, 'data.data.list'));
      setLoadingModel(false);
    };
    if (_.get(reportItem, 'brand') && visible) {
      fetcModel(); // โหลดข้อมูลเริ่มต้น
    }
  }, [debounceModelSearch, reportItem]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      // render: (text, record, index) => index + 1,
    },
    {
      title: messageLabel('deliveryModalItemItemCode', 'Item Code'),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 150,
    },
    {
      title: messageLabel('deliveryModalItemItem', 'Item'),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      width: 300,
      render: (text, record, index) => (
        <> {(_.get(record, 'itemBrandName', '') || '') + ' ' + (_.get(record, 'itemModelName', '') || '') + ' ' + (_.get(record, 'itemName', '') || '')}</>
      ),
    },
    {
      title: messageLabel('deliveryModalItemUnit', 'Unit'),
      dataIndex: 'unit',
      key: 'unit',
      sorter: true,
      width: 100,
    },
  ];

  const handleClose = () => {
    onCancel();
    setReportItem({
      list: [],
      totalItem: 0,
      extraSort: undefined,
      fieldSort: '',
      orderSort: undefined,
      orderBy: undefined,
      paginationPage: 1,
      paginationSize: 10,
      loading: false,
      name: '',
      code: '',
      active: undefined,
      itemMDescription: '',
      customerName: '',
      rangePickerDate: [],
      statusCode: [],
      issueDateStart: '',
      issueDateEnd: '',
    });
  };

  const onSelectRow = async (value) => {
    setRecord(value);
    openItemQty();
  };

  const handleResetModel = (value) => {
    setReportItem((prev) => ({ ...prev, active: '', itemModelDescription: '', paginationPage: 1 }));
    setEventModel((event) => !event);
  };

  const handleApplyFilter = (value) => {
    // setReportItem((prev) => ({ ...prev, paginationPage: 1 }));
    setPageTable(1);
    setEventModel((event) => !event);
  };

  const handleChange = (value, key) => {
    console.log('handleChange', value, key,)
    // setReportItem((prev) => ({ ...prev, [key]: value }));

    if (key === 'brand' && !value) {
      setReportItem((prev) => ({ ...prev, brand: undefined, model: undefined }));
    } else {
      setReportItem((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleFieldSort = (value) => {
    setReportItem((prev) => ({ ...prev, orderBy: value }));
  };

  const handleOrderSort = (value) => {
    setReportItem((prev) => ({ ...prev, orderSort: value }));
  };

  const openItemQty = (value) => {
    setvisibleItem(true);
  };

  const closeItemQty = (value) => {
    setvisibleItem(false);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'deliveryModalItemAddItemProduct', defaultMessage: 'Add Item Product' })}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1122}
      zIndex={1000}
      centered={true}
      bodyStyle={{ padding: '0px 24px', marginTop: '-9px' }}
      style={{ margin: '0px 0px', height: 'calc(130vh - 300px)' }}
      className="modalBrandAndModel"
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Spin spinning={_.get(reportItem,'loading')}>
        <Row style={{ marginTop: '10px' }}>
          <Col span={6} style={{}}>
            <div
              className="listItemTextHeader"
              style={{
                padding: '24px 16px 16px 0px',
                marginLeft: '-24px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ width: '50%', paddingLeft: '16px' }}>
                <FormattedMessage id="deliveryModalItemFilter" defaultMessage="Filter" />
              </span>
            </div>
            <div style={{ marginLeft: '-24px' }}>
              {/* {toggleBrand === true ? ( */}
              <ListItemFilter onChange={handleChange} searchGroup={reportItem} optionsBrand={optionsBrand} optionsModel={optionsModel} />
              <div style={{ padding: '5px 16px 5px 24px' }}>
                <Button_01
                  style={{ margin: '0px', width: '100%' }}
                  key="print"
                  type="primary"
                  btnsize="wd_df"
                  onClick={() => {
                    handleApplyFilter();
                  }}
                >
                  <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                </Button_01>
              </div>
            </div>
          </Col>
          <Col span={18} style={{ borderLeft: '1px solid #e8e8e8', height: 'calc(-357px + 118vh)' }}>
            <div
              className="listItemTextHeader"
              style={{
                padding: '24px 24px 16px 24px',
                marginRight: '-24px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>
                <FormattedMessage id="deliveryModalItemItem" defaultMessage="Item" />
              </span>
            </div>
            <div style={{ padding: '24px', }}>
              <CustomTableComponent
                columns={columns}
                dataSource={_.get(reportItem, 'list')}
                total={_.get(reportItem, 'totalItem')}
                onRow={true}
                role={true}
                onRowClick={onSelectRow}
                paginationPage={pageTable}
                setPaginationPage={setPageTable}
                paginationShow={true}
                setPaginationSize={setSizeTable}
                paginationSize={sizeTable}
                scroll={{ x: _.sumBy(columns, 'width'),y: 'calc(-385px + 100vh)', }}
                // scroll={{ x: true }}
                setFieldSort={handleFieldSort}
                setOrderSort={handleOrderSort}
              />
            </div>
          </Col>
        </Row>
      </Spin>
      <ModalQTYItemDO
        visible={visibleItem}
        onCancel={closeItemQty}
        record={record}
        closeItem={handleClose}
        tableData={tableData}
        setTableData={setTableData}
      />
    </Modal>
  );
};

// CreateItemDOModal.defaultProps = {
//   visible: false,
//   onOk: () => console.warn('onOk not function '),
//   onCancel: () => console.warn('onCancel not function '),
// };

// const ModalCreateItemDO = Form.create({ name: 'select_qt_Form' })(CreateItemDOModal);

export default ModalCreateItemDO;
