import React, { useState, useCallback } from 'react'
import { Card, Table, Popover, Icon, Modal, Row } from 'antd'
import Button03 from '../../../components/v2/button_03'
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01'
import AddContact from '../../../components/add-contact/index'
import EditContact from '../../../components/edit-contact/index'
import './css/contact.css'
import httpClient from '../../../components/axiosClient';
import {
  successNotification,
  errorNotification,
} from '../../../components/v2/notification';
import Button02 from '../../../components/v2/button_02';
import { PageSettings } from '../../../config/page-settings';


export default ({ contactData, informationData, setTriggerAddContact, triggerAddContact }) => {
  const intl = useIntl();
  const { confirm } = Modal;
  const customerId = _.get(informationData, 'customerId')
  const comCode = localStorage.getItem('comCode');



  const [visibleModalContact, setVisibleModalContact] = useState(false)
  const [visibleModalContactEdit, setVisibleModalContactEdit] = useState(false)
  const [contactRecord, setContactRecord] = useState({});
  const [formRef, setFormRef] = useState(null);
  const [formRefEdit, setFormRefEdit] = useState(null);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnName', defaultMessage: 'Name' }),
      dataIndex: 'customerContactName',
      key: 'customerContactName',
    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnPosition', defaultMessage: 'Position' }),
      dataIndex: 'position',
      key: 'position',

    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnEmail', defaultMessage: 'Email' }),
      dataIndex: 'customerContactEmail',
      key: 'customerContactEmail',
    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnPhone', defaultMessage: 'Phone' }),
      dataIndex: 'customerContactPhone',
      key: 'customerContactPhone',
    },
    {
      title: intl.formatMessage({ id: 'customerContactTableColumnRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => (
        <PageSettings.Consumer>
          {({ checkPermissionAction }) =>
            <Popover placement="leftTop" key={index} zIndex={999}
              content={
                <>
                  <Row>
                    {
                      checkPermissionAction('P14PG2C2', 'P14PG2C2A3') ? (
                        <Button01
                          btnsize="wd_df"
                          type="link" ghost
                          onClick={() => handleEdit(record)}
                        >
                          <p><FormattedMessage id="btnEdit" defaultMessage="Edit" /></p>
                        </Button01>
                      ) : null
                    }

                  </Row>
                  <Row>
                    {
                      checkPermissionAction('P14PG2C2', 'P14PG2C2A4') ? (
                        <Button01
                          btnsize="wd_df"
                          type="link" ghost
                          onClick={() => handlerDelete(record.customerContactId)}
                        >
                          <p><FormattedMessage id="btnDelete" defaultMessage="Delete" /></p>
                        </Button01>
                      ) : null
                    }
                  </Row>

                </>
              }

            >
              <Icon type="more" />
            </Popover >}
        </PageSettings.Consumer>)
    },
  ];

  const handleEdit = (record) => {
    setContactRecord(record);
    setVisibleModalContactEdit(true);
  }

  const handleCreate = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }


      const body = {
        customerId: customerId,
        customerContactName: values.name,
        customerContactEmail: values.email,
        customerContactPhone: values.phone,
        position: values.position,
        remark: values.remark
      }

      try {

        const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-contacts`, body);

        if (response.status === 200) {
          formRef.resetFields();
          setVisibleModalContact(false);
          setTriggerAddContact(!triggerAddContact)
          successNotification(response.data.status.message);
        } else {
          errorNotification(response.data.status.message);
        }

      } catch (error) {
        errorNotification(error.response.data.status.message);

      }


    });
  };

  const handleEditSave = () => {
    formRefEdit.validateFields(async (err, values) => {

      if (err) {
        return;
      }

      let contactId = _.get(contactRecord, 'customerContactId');
      const body = {
        customerId: customerId,
        customerContactName: values.name,
        customerContactEmail: values.email,
        customerContactPhone: values.phone,
        position: values.position,
        remark: values.remark
      }

      try {
        const response = await httpClient.put(`/v3/business-partner/manager/company/${comCode}/customer-contacts/${contactId}`, body);

        if (response.status === 200) {
          formRefEdit.resetFields();
          setVisibleModalContactEdit(false);
          setTriggerAddContact(!triggerAddContact)
          successNotification(response.data.status.message);

        } else {
          formRefEdit.resetFields();
          setVisibleModalContact(false);
          setTriggerAddContact(!triggerAddContact)

        }

      } catch (error) {
        formRefEdit.resetFields();
        setVisibleModalContact(false);
        setTriggerAddContact(!triggerAddContact)
        errorNotification(error.response.data.status.message);
      }







    });
  };

  const handleCancel = () => {
    setVisibleModalContact(false)
    formRef.resetFields();

  }

  const handleCancelEdit = () => {
    setContactRecord({});
    setVisibleModalContactEdit(false)
  }

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const saveFormRefEdit = useCallback(node => {
    if (node !== null) {
      setFormRefEdit(node);
    }
  }, []);

  const handlerDelete = (customerContactId) => {

    confirm({
      className: "customer-modal-confirm",
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerContactDeleteConfirmText', defaultMessage: 'Are you sure to delete contact ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      async onOk() {

        try {

          const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/customer-contacts/${customerContactId}`);

          if (response.status === 200) {
            setTriggerAddContact(!triggerAddContact)
            successNotification(response.data.status.message);
          } else {
            errorNotification(response.data.status.message);
          }

        } catch (error) {

        }
      },
      onCancel() {

      }
    })
  }


  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        <div>
          <Card
            title={intl.formatMessage({ id: 'customerContactCardHeader', defaultMessage: 'Contact' })}
            extra={ checkPermissionAction('P14PG2C2','P14PG2C2A2') ? (
              <Button03 type='link' onClick={() => { setVisibleModalContact(true); }}>
                + <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button03> ) : null
            }
          >
            <Table
              dataSource={contactData}
              columns={columns}
              pagination={false}

            />
          </Card>
          <Modal
            className='content-padding'
            title={intl.formatMessage({ id: 'customerContactCardHeader', defaultMessage: 'Contact' })}
            visible={visibleModalContact}
            onCancel={handleCancel}
            centered={true}
            onOk={handleCreate}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}}  key="back" btnsize="wd_df" onClick={() => handleCancel()}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01
                key="submit"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleCreate()}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <AddContact
              ref={saveFormRef}
              onCreate={() => handleCreate()}
              intl={intl}
            />
          </Modal>

          <Modal
            title={intl.formatMessage({ id: 'venderEditContactCardHeader', defaultMessage: 'Edit Contact' })}
            visible={visibleModalContactEdit}
            centered={true}
            onCancel={handleCancelEdit}
            onOk={handleEditSave}
          >
            <EditContact
              ref={saveFormRefEdit}
              onCreate={() => handleEditSave()}
              contactRecord={contactRecord}
              intl={intl}
            />
          </Modal>


        </div>}
    </PageSettings.Consumer>
  )
}
