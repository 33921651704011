import React, { Component, useEffect } from 'react';

import { Col, Divider, Modal, Row, Table } from 'antd';

import { useReactToPrint } from 'react-to-print';

import _ from 'lodash';

import { FormattedMessage, useIntl } from 'react-intl';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import moment from 'moment';
import './index.css';

// import { useState } from "react";

class LoopTable extends Component {}

export default class BiliingNotePrint extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.state = {
      ComloopTable: [],
      ComloopTableDetail: [],
      DataQT: this.props.record,
      DataCreadit: this.props.recordReport,
      totalCredit: 0,
      page: 0,
    };
  }

  render() {
    const DataQT = this.props.record;
    const DataCreadit = this.props.recordReport;
    const visible = this.props.visibleModal;
    const totalNum = _.get(DataQT, 'total') ? _.get(DataQT, 'total') : 0;
    const signatureUser = this.props.signatureUser;
    const signtureIssu = this.props.signatureIssu;
    const memComName = localStorage.getItem('name');
    const sizeChange = 11;
    const MaximumCal = 14;
    const MaximumRow = 15;
    const chunkArrayTest = _.chunk(DataCreadit, MaximumRow);
    const chuckLastIndex = _.findLastIndex(chunkArrayTest, (e) => {
      return e;
    });
    const chunkArrayDe = [...chunkArrayTest, { blank: _.size(chunkArrayTest) }];
    const chunkArray =
      _.size(chunkArrayTest[chuckLastIndex]) <= MaximumRow && _.size(chunkArrayTest[chuckLastIndex]) >= sizeChange
        ? chunkArrayDe
        : chunkArrayTest;

    console.log('recordReport', this.state.DataCreadit);

    const LoopTableCredit = (indexchuck) => {
      const viewloop = [];
      let indexcount = 0;
      let chuckcount = indexchuck;

      const callsize = this.props.sizeRecordReport + 2 - (this.props.sizeRecordReport - _.size(this.state.DataCreadit));
      const callsizeLarge = callsize + 10;

      console.log('chunkArray', chuckcount, chunkArray, _.size(this.state.DataCreadit));
      for (let index = 0; _.size(chunkArray[chuckcount]) >= sizeChange ? index < MaximumRow : index < sizeChange; index++) {
        // for (let index = 0; this.props.sizeRecordReport >= 9 ? index < callsizeLarge: index < callsize; index++) {
        console.log('ArrayIndex', _.size(chunkArray[chuckcount]), '/', index);
        let borderBottomLine = _.size(chunkArray[chuckcount]) >= sizeChange && index === MaximumRow ? '1px solid' : 'unset';
        let HeightSpace = _.size(chunkArray[chuckcount]) >= sizeChange ? 'tdCreaditTextBlank' : 'tdCreaditTextBlank10';
        viewloop.push(
          index >= _.size(chunkArray[chuckcount]) || _.get(chunkArray[chuckcount], 'blank') ? (
            <tr
              style={{
                height: '35px',
                paddingTop: '10px',
                fontSize: '17px',
              }}
            >
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
            </tr>
          ) : (
            // _.get(chunkArray[chuckcount][index], 'invoiceNo') ? (
            <>
              <tr
                style={{
                  height: '20px',
                  paddingTop: '10px',
                  fontSize: '17px',
                }}
              >
                <td
                  className="tdCreaditText"
                  span={1}
                  style={{
                    textAlign: 'center',
                    borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'index')}
                </td>
                <td
                  className="tdCreaditText"
                  span={11}
                  style={{
                    textAlign: 'left',
                    paddingLeft: '10px',
                    borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'invoiceNo')}
                </td>
                <td
                  className="tdCreaditText"
                  span={4}
                  style={{
                    textAlign: 'left',
                    paddingRight: '10px',
                    borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
                    borderLeft: 'unset',
                  }}
                >
                  {/* {numberFormatter(_.get(chunkArray[chuckcount][index], 'issueDate'))} */}
                  {_.get(chunkArray[chuckcount][index], 'issueDate')}
                </td>
                <td
                  className="tdCreaditText"
                  span={2}
                  style={{
                    textAlign: 'left',
                    paddingRight: '10px',
                    borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
                    borderLeft: 'unset',
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'dueDate')}
                </td>
                <td
                  className="tdCreaditText"
                  span={2}
                  style={{
                    borderLeft: '1px solid',
                    textAlign: 'left',
                    borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'remark')}
                </td>
                <td
                  className="tdCreaditText"
                  span={4}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
                  }}
                >
                  {/* {numberFormatter(_.get(chunkArray[chuckcount][index], 'amount'))} */}
                  {numberFormatter(_.get(chunkArray[chuckcount][index], 'amountNum'))}
                </td>
              </tr>
            </>
          )
          // )
          // : (
          //   <tr
          //     style={{
          //       height: '35px',
          //       paddingTop: '10px',
          //       fontSize: '17px',
          //     }}
          //   >
          //     <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
          //     <td
          //       span={11}
          //       className="tdCreaditTextRe"
          //       style={{
          //         width: '125px',
          //         paddingTop: '0px',
          //         textAlign: 'left',
          //         // paddingLeft: '10px',
          //         borderBottom: index >= MaximumCal ? '1px solid' : 'unset',
          //         // wordWrap: 'break-word',
          //       }}
          //     >
          //       <div className="tdCreaditTextRemark">{_.get(chunkArray[chuckcount][index], 'remarkline')}</div>
          //     </td>
          //     <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
          //     <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
          //     <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
          //     {/* <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td> */}
          //   </tr>
          // )
        );
      }
      let countPage = chuckcount + 1;
      if (countPage === _.size(chunkArray) - 1) {
        countPage = _.size(chunkArray) - 1;
      }
      // console.log('chunkArrayviewloop', viewloop);
      return viewloop;
      // this.setState({ ComloopTable: viewloop, page: countPage });
    };

    const ThaiNumberToText = (number) => {
      console.log('numberPrint', number);
      const splitDot = _.split(number, '.');
      const fullNumber = _.get(splitDot, '[0]');
      const dotNumber = _.get(splitDot, '[1]');
      const num = ['ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
      const pos = ['หน่วย', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน'];
      let res = '';
      for (let index = 0; index < _.size(fullNumber); index++) {
        if (fullNumber[index] !== '0') {
          res = res + num[parseInt(fullNumber[index])];
          res = res + pos[_.size(fullNumber) - (index + 1)];
        }
      }
      let resDot = '';
      for (let index = 0; index < _.size(dotNumber); index++) {
        if (dotNumber[index] !== '0') {
          resDot = resDot + num[parseInt(dotNumber[index])];
          resDot = resDot + pos[_.size(dotNumber) - (index + 1)];
        }
      }
      res = res.replace('หนึ่งสิบ', 'สิบ');
      res = res.replace('ศูนย์หน่วย', '');
      res = res.replace('หนึ่งหน่วย', 'เอ็ด');
      res = res.replace('หนึ่งหน่วย', 'เอ็ด');
      res = res.replace('สองสิบ', 'ยี่สิบ');
      res = res.replace('หน่วย', '');
      if (res === 'เอ็ด') {
        res = 'หนึ่ง';
      }
      resDot = resDot.replace('หนึ่งสิบ', 'สิบ');
      resDot = resDot.replace('ศูนย์หน่วย', '');
      resDot = resDot.replace('หนึ่งหน่วย', 'เอ็ด');
      resDot = resDot.replace('หนึ่งหน่วย', 'เอ็ด');
      resDot = resDot.replace('สองสิบ', 'ยี่สิบ');
      resDot = resDot.replace('หน่วย', '');
      if (resDot === 'เอ็ด') {
        resDot = 'หนึ่ง';
      }
      // if () {

      // }
      if (parseInt(dotNumber) === 0) {
        return res + 'บาทถ้วน';
      }
      if (parseInt(dotNumber) > 0) {
        return res + 'บาท' + resDot + 'สตางค์';
      }
    };

    return (
      <div ref={this.props.ref}>
        {chunkArray.map((item, index) => {
          return (
            <div className="printable" style={{ padding: '55px', width: '1000px', height: '1200px' }}>
              <Row type="flex" align="middle">
                <Col span={12} style={{ fontSize: '24px' }}>
                  {_.get(DataQT, 'companyName')}
                </Col>
                <Col span={12} style={{ textAlign: 'right', fontSize: '24px' }}>
                  <FormattedMessage id="BLPrintTitleTopic" defaultMessage="ใบวางบิล / ใบแจ้งหนี้" />
                </Col>
              </Row>
              <Row>
                <Col span={20} style={{ fontSize: '17px' }}>
                  {_.get(DataQT, 'companyAddress')}
                </Col>
                <Col span={4} style={{ fontSize: '20px', textAlign: 'right' }}>
                  <FormattedMessage id="QTPrintTitlePage" defaultMessage="หน้า" /> {index + 1} / {_.size(chunkArray)}
                </Col>
              </Row>
              <Row style={{}}>
                <Col span={8} style={{ fontSize: '17px', color: '#050505', display: 'flex' }}>
                  <span style={{ width: '115px' }}>
                    <FormattedMessage id="QTPrintTitleTax" defaultMessage="เลขผู้เสียภาษี" />:{' '}
                  </span>
                  <span style={{ color: '#050505' }}>{_.get(DataQT, 'companyTaxNo')}</span>
                </Col>
              </Row>
              <Row style={{}}>
                <Col span={8} style={{ fontSize: '17px', color: '#050505', display: 'flex' }}>
                  <span style={{ width: '115px' }}>
                    <FormattedMessage id="QTPrintTitlePhone" defaultMessage="เบอร์โทรศัพท์" />:{' '}
                  </span>
                  <span style={{ color: '#050505' }}>{_.get(DataQT, 'companyPhone')}</span>
                </Col>
              </Row>
              {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <div style={{ width: '50%' }}>
                  <div className="TopicData">
                    <FormattedMessage id="QTPrintTitleCustomer" defaultMessage="ลูกค้า" />{' '}
                  </div>
                  <div className="topiData30">{_.get(DataQT, 'customerName')}</div>
                  <div className="TopicData">{_.get(DataQT, 'customerAddress')} </div>
                  <div className="TopicData">
                    <FormattedMessage id="QTPrintTitleContact" defaultMessage="ผู้ติดต่อ" />:{' '}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {_.get(DataQT, 'customerContact')}
                  </div>
                  <div className="TopicData" style={{ paddingTop: '0px' }}>
                    <FormattedMessage id="QTPrintTitlePhoneContact" defaultMessage="เบอร์โทรศัพท์" />: {_.get(DataQT, 'customerPhone')}
                  </div>
                </div>

                <div>
                  <div className="TopicDataR">
                    <FormattedMessage id="BLPrintTitleBLNO" defaultMessage="เลขที่ใบวางบิล" />
                  </div>
                  <div className="topiData30R">{_.get(DataQT, 'billingNo')}</div>
                  <div className="TopicDataR">
                    {' '}
                    <FormattedMessage id="QTPrintTitleDate" defaultMessage="วันที่ทำรายการ" />
                    <p style={{ textAlign: 'right', fontSize: '24px' }}>
                      {moment(_.get(DataQT, 'issueDate'))
                        .add(543, 'year')
                        .format('DD/MM/YYYY')}
                    </p>{' '}
                  </div>
                </div>
              </div> */}

              <div className="borderInfoTop" style={{ display: 'flex', marginTop: '10px' }}>
                <div className="paddingInFoTop" style={{ width: '590px', paddingBottom: '10px' }}>
                  <div className="TopicData" style={{ display: 'flex', paddingTop: '10px' }}>
                    <span style={{ width: '50%', display: 'flex' }}>
                      <span style={{ width: '105px' }}>
                        <FormattedMessage id="QTPrintTitleCustomerCode" defaultMessage="รหัสลูกค้า" />:
                      </span>
                      <span>{_.get(DataQT, 'customerCode')} </span>
                    </span>
                    <span style={{ width: '50%' }}>
                      {' '}
                      <FormattedMessage id="QTPrintTitleTax" defaultMessage="เลขผู้เสียภาษี" />
                      :&nbsp; {_.get(DataQT, 'customerTaxNo')}
                    </span>
                  </div>
                  <div className="TopicData" style={{ display: 'flex' }}>
                    <span style={{ width: '105px' }}>
                      <FormattedMessage id="QTPrintTitleCustomer" defaultMessage="ลูกค้า" />:
                    </span>
                    <span style={{}}>{_.get(DataQT, 'customerName')}</span>
                  </div>
                  <div className="TopicData" style={{ display: 'flex' }}>
                    <span style={{ width: '18%' }}>
                      <FormattedMessage id="SOPrintTitleAddress" defaultMessage="ที่อยู่" />:
                    </span>
                    <span style={{ width: '82%' }}>{_.get(DataQT, 'customerAddress')}</span>
                  </div>
                  <div className="TopicData" style={{ display: 'flex' }}>
                    {' '}
                    <span style={{ width: '50%' }}>
                      <FormattedMessage id="QTPrintTitleContact" defaultMessage="ผู้ติดต่อ" />:{' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {_.get(DataQT, 'customerContact')}{' '}
                    </span>
                    <span style={{ width: '50%' }}>
                      <FormattedMessage id="QTPrintTitlePhoneContact" defaultMessage="เบอร์โทรศัพท์" />: &nbsp;
                      {_.get(DataQT, 'customerPhone')}
                    </span>
                  </div>
                </div>
                <div style={{ width: '351px', borderLeft: '1px solid' }}>
                  <div className="paddingInFoTop" style={{ height: '50%', borderBottom: '1px solid' }}>
                    <div className="TopicDataRPO" style={{ paddingTop: '10px' }}>
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="BLPrintTitleBLNO" defaultMessage="เลขที่ใบวางบิล" />:{' '}
                      </span>
                      {/* <span style={{ width: '50%' }}> {_.get(DataQT, 'billingNo')}</span> */}
                    </div>
                    {/* <div className="topiData30R">{_.get(DataQT, 'poNo')}</div> */}
                    <div className="TopicDataRPO">
                      {' '}
                      <span style={{ width: '50%' }}>
                        <span style={{ width: '50%' }}> {_.get(DataQT, 'billingNo')}</span>
                      </span>
                      {/* <span>
                        {' '}
                        {moment(_.get(DataQT, 'issueDate'))
                          .add(543, 'year')
                          .format('DD/MM/YYYY')}
                      </span> */}
                    </div>
                  </div>

                  <div className="paddingInFoTop" style={{ height: '50%' }}>
                    <div className="TopicDataRPO" style={{ paddingTop: '10px' }}>
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="QTPrintTitleDate" defaultMessage="วันที่ทำรายการ" />:
                      </span>
                    </div>
                    <div className="TopicDataRPO">
                      {' '}
                      <span>
                        {' '}
                        {moment(_.get(DataQT, 'issueDate'))
                          .add(543, 'year')
                          .format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <table className="table" style={{ border: '1px solid', width: '890px', marginBottom: '0px', borderBottom: 'unset' }}>
                <thead>
                  <tr className="tdBG" style={{}}>
                    <th className="thHeadCredit" style={{ width: '50px' }}>
                      <div className="conTh">#</div>
                    </th>
                    {/* <th style={{ border: '1px solid', textAlign: 'center', width: '400px', }}> */}
                    <th className="thHeadCredit" style={{ width: '178px' }}>
                      <div className="conTh">
                        <FormattedMessage id="BLPrintTitleList" defaultMessage="เลขที่ใบแจ้งหนี้" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '121px' }}>
                      <div className="conTh">
                        {' '}
                        <FormattedMessage id="ฺBLPrintTitleUnitissu" defaultMessage="วันที่ใบแจ้งหนี้" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '100px' }}>
                      <div className="conTh">
                        <FormattedMessage id="BLPrintTitleDuedateColumn" defaultMessage="ครบกำหนด" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '241px' }}>
                      <div className="conTh">
                        <FormattedMessage id="BLPrintTitleRemark" defaultMessage="หมายเหตุ" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '150px' }}>
                      <div className="conTh">
                        {' '}
                        <FormattedMessage id="QTPrintTitlePrice" defaultMessage="ราคา" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ height: '10px' }}>{_.map(LoopTableCredit(index), (item) => item)}</tbody>
              </table>
              {_.size(chunkArray[index]) >= sizeChange ? null : (
                <>
                  {' '}
                  <table style={{ border: '1px solid', borderBottom: '1px', width: '890px' }}>
                    <tbody style={{ height: '10px' }}>
                      <tr style={{ borderTop: '1px solid', height: '20px' }}>
                        {/* <td style={{ fontSize: '14px', borderTop: '1px solid', padding: ' 6px 0px 6px 7px', width: '25%' }}>
                          <span style={{  }}>
                            <FormattedMessage id="BlPrintPaidamount" defaultMessage="ยอดที่ชำระแล้ว" />
                          </span>
                          : {numberFormatter(_.get(DataQT, 'paidAmount'))}
                        </td> */}
                        <td
                          colSpan="2"
                          style={{
                            fontSize: '17px',
                            borderTop: '1px solid',
                            paddingBottom: '0px',
                            padding: ' 3px 12px',
                            width: '50%',
                          }}
                        >
                          <FormattedMessage id="QTPrintTitleRemark" defaultMessage="หมายเหตุ" />:
                        </td>
                        {/* <td
                          style={{ fontSize: '14px', borderTop: '1px solid', padding: ' 6px 7px', width: '25%', borderLeft: '1px solid' }}
                        >
                          <span style={{}}>
                            <FormattedMessage id="BlPrintAccamount" defaultMessage="ยอดค้างชำระ" />
                          </span>
                          :{numberFormatter(_.get(DataQT, 'accrued'))}

                        </td> */}

                        <td
                          className="tdPriceText"
                          colspan="3"
                          style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', verticalAlign: 'bottom' }}
                        >
                          <FormattedMessage id="QTPrintTitlePhoneTotalPrice" defaultMessage="ราคารวม" />
                        </td>
                        <td
                          className="tdPrice"
                          style={{ textAlign: 'right', paddingRight: '10px', borderTop: '1px solid', verticalAlign: 'bottom' }}
                        >
                          {numberFormatter(_.get(DataQT, 'total'))}
                        </td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        {/* <td
                          colspan="2"
                          style={{ fontSize: '17px', borderTop: '1px solid', padding: ' 6px 12px', width: '50%',  }}
                        >
                          <FormattedMessage id="BLPrintTitleDuedate" defaultMessage="วันที่ครบกำหนด" />:{' '}

                          {moment(_.get(DataQT, 'dueDate'))
                            .add(543, 'year')
                            .format('DD/MM/YYYY')}
                        </td> */}

<td className="tdPriceText" rowspan="4" colSpan="2" style={{ paddingTop: '1px', verticalAlign: 'top' }}>
                          {_.get(DataQT, 'remark')}
                        </td>

                        <td colspan="3" className="tdPriceText">
                          <FormattedMessage id="QTPrintTitleDiscount" defaultMessage="ส่วนลด" /> {0}%
                        </td>
                        <td className="tdPrice">{numberFormatter(0)}</td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        {/* <td
                          colSpan="2"
                          style={{
                            fontSize: '17px',
                            borderTop: '1px solid',
                            paddingBottom: '0px',
                            padding: ' 3px 12px',
                            
                          }}
                        >
                          <FormattedMessage id="QTPrintTitleRemark" defaultMessage="หมายเหตุ" />:
                        </td> */}

                        <td colspan="3" className="tdPriceText">
                          <FormattedMessage id="QTPrintTitlesubTotalAfterDiscount" defaultMessage="ราคารวมหลังหักส่วนลด" />
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'total'))}</td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        {/* <td className="tdPriceText" rowspan="2" colSpan="2" style={{ paddingTop: '1px', verticalAlign: 'top' }}>
                          {_.get(DataQT, 'remark')}
                        </td> */}
                        <td colspan="3" className="tdPriceText">
                          <FormattedMessage id="QTPrintTitleTaxPre" defaultMessage="ภาษี" /> {0}%
                        </td>
                        <td className="tdPrice">{numberFormatter(0)}</td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        <td colspan="3" className="tdPriceText">
                          <FormattedMessage id="QTPrintTitlesubAllTotal" defaultMessage="รวมทั้ั้งสิ้น" />
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'total'))}</td>
                      </tr>
                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        <td colspan="6" style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', padding: ' 6px 12px' }}>
                          <span style={{}}>
                            {' '}
                            <span style={{}}>
                              <FormattedMessage id="QTPrintTitlesubAllLabel" defaultMessage="ตัวอักษร" />
                            </span>
                            :
                          </span>{' '}
                          {_.get(DataQT, 'total') === null ? 'ศูนย์บาทถ้วน' : ThaiNumberToText(totalNum.toFixed(2))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" style={{ border: '1px solid' }}>
                    <tbody style={{ height: '10px' }}>
                      <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                        <td className="tdSignatureText">
                          {/* <td style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', width: '25%' }}> */}
                          <FormattedMessage id="BLPrintTitleReciver" defaultMessage="ผู้วางบิล" />
                        </td>
                        <td className="tdSignatureText">
                          <FormattedMessage id="ARPrintTitleReciverPrice" defaultMessage="ผู้รับเงิน" />
                        </td>
                        <td className="tdSignatureText">
                          <FormattedMessage id="ARPrintTitleCheck" defaultMessage="ผู้ตรวจสอบ" />
                        </td>
                        <td className="tdSignatureText">
                          <FormattedMessage id="BLPrintTitleSender" defaultMessage="ผู้อนุมัติ" />
                        </td>
                      </tr>
                      <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                        <td className="tdSignatureLine">
                          <>
                            <div div classname="lineheight" />
                            _______________________
                          </>
                        </td>
                        <td className="tdSignatureLine">
                          <>
                            <div div classname="lineheight" />
                            _______________________
                          </>
                        </td>
                        <td className="tdSignatureLine">
                          <div div classname="lineheight" />
                          _______________________
                        </td>
                        <td className="tdSignatureLine">
                          <>
                            <div div className="lineHeight" />
                            _______________________
                          </>
                        </td>
                      </tr>
                      <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                        <td className="tdSignatureCircle">
                          (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                        </td>
                        <td className="tdSignatureCircle">
                          (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                        </td>
                        <td className="tdSignatureCircle">
                          (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                          {/* {_.get(DataQT, 'receiveBy') ? (
                            '( ' + _.get(DataQT, 'receiveBy') + ' )'
                            // memComName
                          ): (
                            <>
                             {memComName ? '( ' + memComName +  ' )':  <>(<span style={{ marginRight: '125px', marginLeft: '50px' }} />)</> }
                            </>
                          )} */}
                        </td>
                        <td className="tdSignatureCircle">
                          (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                        </td>
                      </tr>
                      <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                        <td className="tdSignatureLine">
                          <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                        </td>
                        <td className="tdSignatureLine">
                          <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                        </td>
                        <td className="tdSignatureLine">
                          <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                          {/* {_.get(DataQT, 'receiveDate') ? (
                            // _.get(DataQT, 'receiveDate')
                            moment(_.get(DataQT, 'receiveDate'))
                              .add(543, 'year')
                              .format('DD/MM/YYYY')
                          ): (
                            // _.get(signatureUser, '[0].fileUrl') ? moment()
                            // .add(543, 'year')
                            // .format('DD/MM/YYYY')  : 
                            moment()
                            .add(543, 'year')
                            .format('DD/MM/YYYY')
                          )} */}
                        </td>
                        <td className="tdSignatureLine">
                          <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                        </td>
                      </tr>
                    </tbody>
                  </table>{' '}
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

// const QuotationPrint = (props) => {
//   const {quotationPrintrRef,title} = props;

//   console.log('refPrint',quotationPrintrRef,title);

//   return (
//     <div >
//       <div ref={quotationPrintrRef} style={{ padding: '10px' }}>
//         TEST
//       </div>
//     </div>

//   );
// };

// export default QuotationPrint;
