import React from 'react';
import client from '../components/axiosClient';


export default async (props) => {

  const token = localStorage.getItem('token');
  const memComId = localStorage.getItem('memComId');

  const lang = localStorage.getItem('langValue') === 'undefined' ? 'EN' : localStorage.getItem('langValue');
  
  const data = {
    companyId: props.companyId,
    lang: lang,
    pageCode: props.pageCode,
    
  };

  const url = `${process.env.REACT_APP_URL_MANAGER}`;
  const port = `${process.env.REACT_APP_RESOURCE_MANAGER_PORT}`;

  let setUrl = url;
  if(port !== undefined)setUrl += `:${port}`;
  client.defaults.baseURL = setUrl;

  const getLanguage = await client.post('/language/company', data, {
    headers: {
      'Authorization': "Bearer " + token,
      'memcomid': memComId,
    }
  });

  return getLanguage.data[0];
};
