import { Checkbox, Col, Divider, Form, Icon, Input, Modal, Row, Select, TimePicker } from 'antd';
import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import WarehouseContext from '../context';
import _ from 'lodash';
import { useEffect } from 'react';
import './css/index.css';
import { saveWareHouse, saveWarehouseSetting, updateWarehouseSetting } from '../../../controllers/warehouse/warehouse';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import MapWithPolygon from '../../../components/map-area-polygon';
import getServiceArea from '../../../controllers/service-area/get-service-area';
import getServiceAreaById from '../../../controllers/service-area/get-service-area-byid';
import moment from 'moment';
const { TextArea } = Input;
const { Option } = Select;
const WarehouseSettingModal = ({ form, record, setRecord, visible, setVisible }) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { warehouseId } = state;
  const { setTrigger } = setState;
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const format = 'HH:mm';
  const [serviceAreaList, setServiceAreaList] = useState([]);
  const [serviceAreaById, setServiceAreaById] = useState();
  const [checkedDay, setCheckedDay] = useState([]);
  const serviceAreaId = getFieldValue('area');

  const days = [
    {
      id: 'mon',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayMonday', defaultMessage: 'Monday' }),
    },
    {
      id: 'tue',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayTuesday', defaultMessage: 'Tuesday' }),
    },
    {
      id: 'wed',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayWednesday', defaultMessage: 'Wednesday' }),
    },
    {
      id: 'thu',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayThursday', defaultMessage: 'Thursday' }),
    },
    {
      id: 'fri',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayFriday', defaultMessage: 'Friday' }),
    },
    {
      id: 'sat',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDaySaturday', defaultMessage: 'Saturday' }),
    },
    {
      id: 'sun',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDaySunday', defaultMessage: 'Sunday' }),
    },
  ];

  useEffect(() => {
    let objNew = {};
    let arrNew = [];
    if (record) {
      objNew.sun = _.get(record, 'sun');
      objNew.mon = _.get(record, 'mon');
      objNew.tue = _.get(record, 'tue');
      objNew.wed = _.get(record, 'wed');
      objNew.thu = _.get(record, 'thu');
      objNew.fri = _.get(record, 'fri');
      objNew.sat = _.get(record, 'sat');
    }

    let keys = Object.keys(objNew);
    arrNew = keys.filter((key) => objNew[key] === true);
    setFieldsValue({ ['day']: arrNew });
  }, [record]);

  useEffect(() => {
    async function getAPI() {
      const response = await getServiceArea();
      setServiceAreaList(_.get(response, 'areaMasterList'));
    }
    getAPI();
  }, []);

  useEffect(() => {
    async function getApiById() {
      if (serviceAreaId) {
        const response = await getServiceAreaById({ serviceAreaId });
        setServiceAreaById(_.get(response, 'areaMasterDetail'));
      }
    }
    getApiById();
  }, [serviceAreaId]);

  const onOk = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (values) {
        const area = _.filter(serviceAreaList, (item) => item.areaMasterId === _.get(values, 'area'));
        const payload = {
          warehouseId: warehouseId,
          sun: compareBoolean('sun', _.get(values, 'day')),
          mon: compareBoolean('mon', _.get(values, 'day')),
          tue: compareBoolean('tue', _.get(values, 'day')),
          wed: compareBoolean('wed', _.get(values, 'day')),
          thu: compareBoolean('thu', _.get(values, 'day')),
          fri: compareBoolean('fri', _.get(values, 'day')),
          sat: compareBoolean('sat', _.get(values, 'day')),
          startTime: _.get(values, 'fromTime') ? moment(_.get(values, 'fromTime')).format(format) : undefined,
          endTime: _.get(values, 'toTime') ? moment(_.get(values, 'toTime')).format(format) : undefined,
          areaCode: _.get(area[0], 'areaCode'),
          areaName: _.get(area[0], 'areaName'),
          areaMasterId: _.get(area[0], 'areaMasterId'),
        };

        if (_.get(record, 'id')) {
          try {
            const response = await updateWarehouseSetting(_.get(record, 'id'), payload);
            console.log('saveWarehouseSetting', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              utilResetValue();
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {}
        } else {
          try {
            const response = await saveWarehouseSetting(payload);
            console.log('saveWarehouseSetting', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              utilResetValue();
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {}
        }
      }
    });
  };

  const utilResetValue = () => {
    setTrigger((current) => !current);
    setServiceAreaById();
    setVisible(false);
    resetFields();
    setRecord();
  };

  const compareBoolean = (key, array) => {
    return array.includes(key);
  };

  const onCancel = () => {
    setVisible(false);
    setServiceAreaById();
    resetFields();
    setRecord();
  };

  const compareStartTime = (rule, value, callback) => {
    let valueEndTime = form.getFieldValue(`toTime`);
    if (value >= valueEndTime) {
      callback(
        <span>
          {intl.formatMessage({
            id: 'orgTabServiceValidateStartTime',
            defaultMessage: 'The start time should not be greater than or equal to the end time.',
          })}
        </span>
      );
    }
    callback();
  };

  const compareEndTime = (rule, value, callback) => {
    let valueStartTime = form.getFieldValue(`fromTime`);
    if (value <= valueStartTime) {
      callback(
        <span>
          {intl.formatMessage({
            id: 'orgTabServiceValidateEndTime',
            defaultMessage: 'The end time should not be less than or equal to the start time.',
          })}
        </span>
      );
    }
    callback();
  };

  return (
    <Modal
      title={
        _.get(record, 'id')
          ? intl.formatMessage({ id: `warehouseTitleEditServiceTime`, defaultMessage: 'Edit Service Time' })
          : intl.formatMessage({ id: `warehouseTitleCreateServiceTime`, defaultMessage: 'Create Service Time' })
      }
      visible={visible}
      centered
      width={700}
      bodyStyle={{ padding: '6px 24px 24px 24px' }}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => onOk()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form colon={false}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={<LabeRequireForm text={<FormattedMessage id="warehouseTitleApplyon" defaultMessage="Apply on" />} req={true} />}
                >
                  {getFieldDecorator('day', {
                    // initialValue: _.get(record, 'warehouseCode') ? _.get(record, 'warehouseCode') : undefined,

                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'warehouseValidateApplyon',
                          defaultMessage: 'Please select Apply on',
                        }),
                      },
                    ],
                  })(
                    <Checkbox.Group style={{ width: '100%' }}>
                      <div className="row-item-checkbox">
                        {days &&
                          days.map((item, key) => (
                            <div className="item-checkbox" key={item.id} style={{ width: 'auto' }}>
                              <Checkbox className="checkbox-border" value={item.id} role="checkbox" data-testid={`checkbox-${item.id}`}>
                                {item.day}
                              </Checkbox>
                            </div>
                          ))}
                      </div>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalCodeOrName', defaultMessage: 'Area' })} req={true} />
                  }
                >
                  {getFieldDecorator('area', {
                    initialValue: _.get(record, 'areaMasterId') ? _.get(record, 'areaMasterId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqCodeOrName', defaultMessage: 'Please select Area' }),
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionLabelProp="label"
                      placeholder={intl.formatMessage({
                        id: 'warehouseModalReqNamePlaceholderCodeName',
                        defaultMessage: 'Select Area',
                      })}
                    >
                      {serviceAreaList &&
                        serviceAreaList.map((item) => (
                          <Option key={item.areaMasterId} value={item.areaMasterId} label={item.areaName}>
                            {item.areaName}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <div style={{ padding: '12px 0 0' }}>
                  <MapWithPolygon areaList={_.get(serviceAreaById, 'coordinates') || []} />
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalFromTime', defaultMessage: 'From Time' })} req={true} />
                  }
                >
                  {getFieldDecorator('fromTime', {
                    initialValue: _.get(record, 'startTime') ? moment(_.get(record, 'startTime'), 'HH:mm') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseSettingValidatefromtime', defaultMessage: 'Please select from time' }),
                      },
                      {
                        validator: compareStartTime,
                      },
                    ],
                  })(
                    <TimePicker
                      style={{ width: '100%' }}
                      format={format}
                      placeholder={intl.formatMessage({ id: 'warehouseSettingPlfromtime', defaultMessage: 'Select from Time' })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalToTime', defaultMessage: 'To Time' })} req={true} />
                  }
                >
                  {getFieldDecorator('toTime', {
                    initialValue: _.get(record, 'endTime') ? moment(_.get(record, 'endTime'), 'HH:mm') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseSettingValidatetotime', defaultMessage: 'Please select to Time' }),
                      },
                      {
                        validator: compareEndTime,
                      },
                    ],
                  })(
                    <TimePicker
                      style={{ width: '100%' }}
                      format={format}
                      placeholder={intl.formatMessage({ id: 'warehouseSettingPltotime', defaultMessage: 'Select to Time' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const WarehouseSettingModalForm = Form.create({
  name: 'warehouse-form',
})(WarehouseSettingModal);

export default WarehouseSettingModalForm;
