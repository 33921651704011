import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
// import ModalCustomerQuotationForm from '....//select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
const dateFormat = 'ddd, MMM DD YYYY';

const CustomerFormView = ({ dataForm, form, }) => {
  const intl = useIntl();
  const { getFieldDecorator } = form;

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>
        {o.fullname}
      </Option>
    );
  };

  return (
    <Form name="customer" colon={false}>
     
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'ARInvoiceNo', defaultMessage: 'Invoice No.' })} req={false}  bold={true} />}
          >
            {_.get(dataForm, 'invoiceNo') || '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'ARTotal', defaultMessage: 'Total' })} req={false}  bold={true} />
            }
          >
            {_.get(dataForm, 'totalTxt') ? _.get(dataForm, 'totalTxt') : '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'ARDueDate', defaultMessage: 'Due Date' })}
                req={false}
                bold={true}
              />
            }
          >
            {_.get(dataForm, 'dueDateTxt') || '-'}
          </Form.Item>
        </Col>
      </Row>

    </Form>
  );
};

CustomerFormView.defaultProps = {};

export default CustomerFormView;

