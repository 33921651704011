import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Form, Select, DatePicker, Modal, Input, List, Checkbox, Radio, InputNumber } from 'antd';
import Button_02 from '../../v2/button_02';
import Button_01 from '../../v2/button_01';
import './css/index.css';
import { style } from 'glamor';
import _ from 'lodash';
import { SavetNewAssetPreventive } from '../../../controllers/asset-resource/asset-controller';
import { errorNotification, successNotification } from '../../v2/notification';

const moment = require('moment');

const { Option } = Select;

const AddPreventiveForm = (props) => {
  const { visible, setVisible, form, pmadd } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [isEndsOn, setIsEndsOn] = useState(false);
  const [isEndsAfter, setIsEndsAfter] = useState(true);
  const [isWeekly, setIsWeekly] = useState(true);
  const [dayCheck, setDayCheck] = useState('');
  const [endAfter, setEndAfter] = useState(0);
  const dateFormat = 'ddd, MMM DD YYYY';
  const intl = useIntl();
  const pmDaily = _.get(pmadd, 'repeatType');
  const pmList = _.get(pmadd, 'itemPmList');
  const pmTeam = _.get(pmadd, 'teamList');

  const workDay = [
    { id: 1, day: intl.formatMessage({ id: 'preventiveFormMon', defaultMessage: 'Mon' }), checklist: true },
    { id: 2, day: intl.formatMessage({ id: 'preventiveFormTue', defaultMessage: 'Tue' }), checklist: true },
    { id: 3, day: intl.formatMessage({ id: 'preventiveFormWed', defaultMessage: 'Wed' }), checklist: true },
    { id: 4, day: intl.formatMessage({ id: 'preventiveFormThu', defaultMessage: 'Thu' }), checklist: true },
    { id: 5, day: intl.formatMessage({ id: 'preventiveFormFri', defaultMessage: 'Fri' }), checklist: true },
    { id: 6, day: intl.formatMessage({ id: 'preventiveFormSat', defaultMessage: 'Sat' }), checklist: true },
    { id: 7, day: intl.formatMessage({ id: 'preventiveFormSun', defaultMessage: 'Sun' }), checklist: true },
  ];

  useEffect(() => {
    if (isWeekly === false && getFieldValue('PMDate') !== undefined) {
      let momentTwo = moment(getFieldValue('PMDate')).format('dddd');
      console.log('CheckDatePM', momentTwo.slice(0, 3), workDay[0].day);
      setDayCheck(momentTwo.slice(0, 3));
    }
    console.log('isOn', isEndsOn);
  }, [getFieldValue('PMDate'), isWeekly]);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const resetFiledsAll = () => {
    setVisible(false);
    setIsEndsOn(false);
    setIsEndsAfter(true);
    setIsWeekly(true);
    setDayCheck('');
  };

  const checkedValues = (Checked) => {};

  const isEnbleRadioOn = (e) => {
    setIsEndsOn(true);
    setIsEndsAfter(false);
  };

  const isEnbleRadioAfter = (e) => {
    setIsEndsAfter(true);
    setIsEndsOn(false);
  };

  const onselect = (e) => {
    console.log('selectPM', getFieldValue('PMDate'));

    if (e === 'weekly') {
      setIsWeekly(false);
    } else {
      setIsWeekly(true);
      setDayCheck('');
    }
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) return;

      if (values) {
        console.log('values', values);
        // let newAttData = attData && attData.map((item) => item.fileHash);
        try {
          const payload = {
            pmDate: moment(values.PMDate).format('YYYY-MM-DD'),
            repeat: values.Repeat,
            repeatTypeCode: values.repeatTypeCode,
            repeatOn: {
              mon: dayCheck === workDay[0].day ? true : false,
              tue: dayCheck === workDay[1].day ? true : false,
              wed: dayCheck === workDay[2].day ? true : false,
              thu: dayCheck === workDay[3].day ? true : false,
              fri: dayCheck === workDay[4].day ? true : false,
              sat: dayCheck === workDay[5].day ? true : false,
              sun: dayCheck === workDay[6].day ? true : false,
            },
            endOn: moment(values.endOn).format('YYYY-MM-DD'),
            endAfter: endAfter,
            itemPmId: values.items,
            orgId: values.Team,
            remark: values.remark,
          };
          console.log('payloadPm', payload);
          const response = await SavetNewAssetPreventive(payload);
          console.log('response', response.data);
          if (_.get(response.data.status, 'code') === 1) {
            successNotification(_.get(response.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.status, 'message'));
        }
      }

      resetFiledsAll();
      setIsEndsOn(false);
      setIsEndsAfter(true);
      setIsWeekly(true);
      setDayCheck('');
    });
  };

  const onChange = (value) => {
    setEndAfter(value);
  };

  const checkRepeat = (rule, value, callback) => {
    console.log('valueRepeat', value);
    if (value > 0) {
      return callback();
    } else {
      callback(
        intl.formatMessage({
          id: 'AssetModalTitleFormPMDateI',
          defaultMessage: 'Please Select Repeat',
        })
      );
    }
  };

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => resetFiledsAll()}
        centered={true}
        title={<FormattedMessage id="ModalTitlePreventiveMaintenance" defaultMessage="Add Preventive maintenance" />}
        width={760}
        bodyStyle={{ padding: '24px' }}
        footer={[
          <Button_02 key="back" btnsize="wd_df" onClick={() => resetFiledsAll()} style={{ margin: 'unset' }}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]}
      >
        <Form>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="AssetModalTitleFormPMDate" defaultMessage="PM Date" />
                  <LabeRequire req={true} />
                </span>
                {getFieldDecorator('PMDate', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'AssetModalTitleFormPMDateVL',
                        defaultMessage: 'Please Select PM Date',
                      }),
                    },
                  ],
                })(
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'AssetModalSelectFormPMDATE', defaultMessage: 'Select PM Date' })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="AssetModalTitleFormRepeat" defaultMessage="Repeat" />
                  <LabeRequire req={true} />
                </span>
                {/* <List.Item style={{ padding: '0px 0px 0px' }}> */}
                <Row>
                  <Col span={12}>
                    <Form.Item>
                      {getFieldDecorator('Repeat', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'AssetModalTitleFormRepeatVL',
                              defaultMessage: 'Please Select Repeat',
                            }),
                          },
                        ],
                      })(
                        <InputNumber
                          className="input-left-end action-input"
                          placeholder={intl.formatMessage({ id: 'AssetModalTitleFormRepeatPH', defaultMessage: 'Enter Repeat' })}
                          autoComplete="off"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      {getFieldDecorator('repeatTypeCode', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'AssetModalTitleFormRepeatVL',
                              defaultMessage: 'Please Select Repeat',
                            }),
                          },
                        ],
                      })(
                        <Select
                          className="action-select"
                          placeholder={<FormattedMessage id="AssetModalSelectFormSelect" defaultMessage="Select" />}
                          onSelect={onselect}
                        >
                          {pmDaily &&
                            pmDaily.map((item) => (
                              // <Option key={item.code} value={`${item.enableRepeatOn}`}>
                              <Option key={item.code} value={item.code}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="AssetModalTitleFormRepeatOn" defaultMessage="Repeat on" />
                  <LabeRequire req={true} />
                </span>

                <Row>
                  {workDay.map((workDay, key) => (
                    <Col key={workDay.id} span={4} style={{ width: 'auto', padding: '5px 10px 10px 0px' }}>
                      <Checkbox
                        className="workDay-checkbox checkbox-border"
                        disabled={isWeekly}
                        checked={dayCheck === workDay.day ? true : false}
                        value={workDay.day}
                        role="checkbox"
                      >
                        {workDay.day}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {getFieldDecorator('endAll', {
              rules: [
                {
                  required: isEndsOn,
                  message: intl.formatMessage({
                    id: 'AssetModalTitleFormEndVL',
                    defaultMessage: 'Please Select On or After',
                  }),
                },
              ],
            })(
              <Row gutter={24}>
                <Col span={24}>
                  <span className="formText-formate">
                    <FormattedMessage id="AssetModalTitleFormEnd" defaultMessage="Ends" />
                    <LabeRequire req={true} />
                  </span>
                </Col>

                <Row gutter={12}>
                  <Col span={3} style={{ padding: '15px' }}>
                    <Form.Item>
                      <Radio onClick={isEnbleRadioOn} checked={isEndsOn}>
                        <FormattedMessage id="AssetModalTitleFormOn" defaultMessage="On" />
                      </Radio>
                    </Form.Item>
                  </Col>

                  <Col span={8} style={{ padding: '15px' }}>
                    <Form.Item>
                      {getFieldDecorator('endOn', {
                        rules: [
                          {
                            required: isEndsOn,
                            message: intl.formatMessage({
                              id: 'AssetModalTitleFormPMDateI',
                              defaultMessage: 'Please Select On',
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          allowClear={false}
                          disabled={!isEndsOn}
                          format={dateFormat}
                          style={{ width: '100%' }}
                          placeholder={intl.formatMessage({ id: 'AssetModalSelectFormPMDATE', defaultMessage: 'Select PM Date' })}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <Row gutter={12}>
                    <Col span={3} style={{ padding: '15px' }}>
                      <Radio onClick={isEnbleRadioAfter} checked={isEndsAfter}>
                        <FormattedMessage id="AssetModalTitleFormAfter" defaultMessage="After" />
                      </Radio>
                    </Col>

                    <Col span={8} style={{ padding: '15px' }}>
                      <List.Item style={{ paddingTop: '1px' }}>
                        {getFieldDecorator('endAfter', {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'AssetModalTitleFormPMDateI',
                                defaultMessage: 'Please Select After',
                              }),
                            },
                          ],
                        })(<InputNumber className="input-left-end action-input" disabled={!isEndsAfter} />)}
                        <Input 
                          className="input-right-end action-input" 
                          defaultValue={intl.formatMessage({id: 'AssetModalTitleFormDefaultOccurrences', defaultMessage: 'Occurrences',
                          })}
                          disabled={!isEndsAfter} 
                        />
                      </List.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Row>
            )}
          </Form.Item>
          <Row gutter={24}>
            <Col span={[12]}>
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="AssetModalTitleFormItems" defaultMessage="Items" />
                  <LabeRequire req={true} />
                </span>

                {getFieldDecorator('items', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'AssetModalTitleFormItemVL',
                        defaultMessage: 'Please Select Items',
                      }),
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'AssetModalTitleFormItemPH', defaultMessage: 'Select Items' })}
                    mode="multiple"
                  >
                    {pmList && pmList.map((item) => <Option value={item.itemPnId}>{item.name}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="AssetModalTitleFormPMTeam" defaultMessage="Team" />
                  <LabeRequire req={true} />
                </span>
                {getFieldDecorator('Team', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'AssetModalTitleFormPMTeamVL',
                        defaultMessage: 'Please Select Team',
                      }),
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'AssetModalTitleFormPMTeamPH', defaultMessage: 'Select Team' })}
                  >
                    {pmTeam && pmTeam.map((item) => <Option value={`${item.orgId}`}>{item.teamName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="AssetModalTitleFormPMRemark" defaultMessage="Remark" />
                </span>
                {getFieldDecorator('remark')(<Input style={{ width: '100%' }} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

const PreventiveAdd = Form.create({
  name: 'modal_form',
  mapPropsToFields() {},
})(AddPreventiveForm);

export default PreventiveAdd;
