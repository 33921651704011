import React, { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import Button01 from '../../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import ViewDetail from './detail';
import ViewNote from './note';
import { getTicketDetail, getTicketNote } from '../../../../controllers/ticket/ticket';
import _, { includes } from 'lodash';
import ModalComment from './comment';
import ViewMoreDetails from './moredeatil';
import ModalReasonForm from '../reason/reason-modal';
import { getNextStatus } from '../../../../controllers/allorder';

const { TabPane } = Tabs;

const ViewModalAllOrder = (props) => {
  const intl = useIntl();
  const { visible, setVisible, orderId,setViewData,viewData,statusList,setStatusList,resonList,nextStatus } = props;
 
  const [visibleComment, setVisibleComment] = useState(false);
  const [commentData, setCommentData] = useState();
  const [noteData, setNoteData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [tab, setTab] = useState('detail');
  const [reasonId, setReasonId] = useState([]);
  const [statusModal, setStatusModal] = useState(statusList);

  //-------------------------------------------------------
  const [visibleWaiting, setVisibleWaiting] = useState(false);

  //-------------------------------------------------------

  console.log('DataView',viewData);
  console.log('resonList',statusList);

    useEffect(() => {
    const getById = async () => {
      let tempstatus = [];
      const newstatus = tempstatus.push(_.get(viewData, 'statusCode'),nextStatus)

      const filterStatus = _.filter(statusList, (e) => {
        return _.includes( nextStatus , e.statusCode) ;
      });

      console.log('StatusView',filterStatus,'/',nextStatus);

      setStatusModal(filterStatus);

    };
    if (viewData && visible === true) {
      console.log('StatusViewTo',_.get(viewData, 'statusCode'));
      getById();
    }
  }, [visible,viewData]);


  // useEffect(() => {
  //   const getById = async () => {
  //     const response = await getTicketDetail(orderId);
  //     const getNote = await getTicketNote(orderId);
  //     setViewData(_.get(response, 'data.data'));
  //     setNoteData(_.get(getNote, 'data.data.data.noteList'));
  //   };
  //   if (orderId && visible) {
  //     getById();
  //   }
  // }, [orderId, trigger, visible]);

  const handleClose = () => {
    setVisible(false);
    setTab('detail');
  };

  const TitleModal = () => {
    return (
      <div>
        <FormattedMessage id="AllOrderHead" defaultMessage="Order" />・{_.get(viewData, 'orderRequestNo')}
      </div>
    );
  };

  const hanldeClickMap = (lat, lng) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
  };

  const onTabChange = (value) => {
    setTab(value);
  };

  const handleSaveWaiting = () => {
    setVisibleWaiting(false);
  };

  const handleCancelWaiting = () => {
    setVisibleWaiting(false);
  };

  return (
    <Modal
      title={<TitleModal />}
      visible={visible}
      onCancel={handleClose}
      bodyStyle={{ padding: 'unset', paddingBottom: '10px',height: '601px' }}
      centered={true}
      width={892}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
    >
      <div style={{ height: '480px' }}>
        <Tabs animated={false} className="viewTicketTab" onChange={onTabChange} activeKey={tab}>
          <TabPane className="viewTicketTab" tab={<FormattedMessage id="TabDetail" defaultMessage="Detail" />} key="detail">
            <ViewDetail
              viewData={viewData}
              setVisibleComment={setVisibleComment}
              setCommentData={setCommentData}
              setVisibleReasong={{ setVisibleWaiting }}
              statusList={statusModal}
              setStatusList={setStatusList}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="TabMoreDetails" defaultMessage="More Details" />} key="moredetail">
            <ViewMoreDetails viewData={viewData} hanldeClickMap={hanldeClickMap} />
          </TabPane>
          {/* <TabPane tab={<FormattedMessage id="TabNote" defaultMessage="Note" />} key="note">
            <ViewNote viewData={viewData} noteData={noteData} hanldeClickMap={hanldeClickMap} setTrigger={setTrigger} />
          </TabPane> */}
        </Tabs>
      </div>
      <ModalComment
        visible={visibleComment}
        setVisible={setVisibleComment}
        commentData={commentData}
        viewData={viewData}
        setTrigger={setTrigger}
        resonList={resonList}
        handleClose={handleClose}
      />
      <ModalReasonForm
        title={intl.formatMessage({ id: 'AllOrderWaitingforMerchant', defaultMessage: 'Waiting for Merchant' }) + ' · ' + 12345}
        visible={visibleWaiting}
        onOk={handleSaveWaiting}
        onCancel={handleCancelWaiting}
        initialReasonList={reasonId}
        arId={'12345'}
        code={'waiting'}
      />
    </Modal>
  );
};

export default ViewModalAllOrder;
