import React, { useState } from 'react';
import Card from '../../../components/v2/card';
import { useIntl, FormattedMessage } from 'react-intl';
import AppAvatar from '../../../components/avatar';
import AppSwitch from '../../../components/switch';
import Button01 from '../../../components/v2/button_01';
import '../css/index.css';
import { Row, Col, Typography, ConfigProvider, Divider, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';

const { Title } = Typography;
const AssetOverview = (props) => {
  const { assetData, handleEdit } = props;
  console.log('assetdata', assetData);
  return (
    <div>
      <Card
        title={<FormattedMessage id="AssetOverviewTitle" defaultMessage="Overview" />}
        extra={
          <div className="extra-user-details">
            {/* <AppSwitch /> */}
            <div>
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleEdit()}>
                <FormattedMessage id="btnEdit" defaultMessage="Edit" />
              </Button01>
            </div>
          </div>
        }
      >
        <div className="between-list-user-detail">
          <Row>
            <Col span={8}>
              <div className="image-container">
                <AppAvatar
                  size={150}
                  icon={'user'}
                  // src={`${process.env.REACT_APP_IMG_HOST}` + imageProfile}
                  src={assetData.pathImage || ' '}
                />
              </div>
            </Col>
            <Col span={16}>
              <Row>
                <Title className="title-name-new" level={4}>
                  <span className="title-span-name-no-pointer">{assetData.assetNo || ' '}</span>
                  &nbsp;<span>{' · '}</span>&nbsp;
                  <span className="title-span-name-no-pointer">{assetData.assetName || ' '}</span>
                </Title>
              </Row>
              <Row>
                <div style={{marginBottom: '16px' }}>
                  <Tag className="status-asset-tag" color={assetData.color} style={{ textAlign: 'center',fontSize: '13px',padding: '2px 14px',minWidth: 'auto'}}>
                    {assetData.statusTxt}
                  </Tag>
                </div>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewClass" defaultMessage="Asset Class" />
                  </div>
                  <div className="lbl-value-list">{assetData.assetClassName || ' '}</div>
                </Col>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewType" defaultMessage="Asset Type" />
                  </div>
                  <div className="lbl-value-list">{assetData.assetTypeName || ' '}</div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewOwner" defaultMessage="Owner" />
                  </div>
                  <div className="lbl-value-list">{assetData.orgName || ' '}</div>
                </Col>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewSerial" defaultMessage="Serial No." />
                  </div>
                  <div className="lbl-value-list">{assetData.serialNo || ' '}</div>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewRegister" defaultMessage="Register By" />
                  </div>
                  <div className="lbl-value-list">{assetData.registerName || ' '}</div>
                </Col>
              </Row> */}

              <Divider />

              <Row>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewPurchaseDate" defaultMessage="Purchase Date" />
                  </div>
                  <div className="lbl-value-list">
                    {assetData.postingDate && assetData.postingDate !== '-'
                      ? moment(assetData.postingDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                      : '-'}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewPrice" defaultMessage="Price" />
                  </div>
                  <div className="lbl-value-list">
                    {assetData.price ? (
                      <>
                        {numberFormatter(assetData.price) + ' '} <FormattedMessage id="labelTHB" defaultMessage="THB" />
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewAssetValueDate" defaultMessage="Asset Value Date" />
                  </div>
                  <div className="lbl-value-list">
                    {assetData.assetValueDate && assetData.assetValueDate !== '-'
                      ? moment(assetData.assetValueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                      : '-'}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewSalvagePrice" defaultMessage="Salvage Price" />
                  </div>
                  <div className="lbl-value-list">
                    {assetData.salvagePrice ? (
                      <>
                        {numberFormatter(assetData.salvagePrice) + ' '} <FormattedMessage id="labelTHB" defaultMessage="THB" />
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewAssetBookValue" defaultMessage="AssetBook Value" />
                  </div>
                  <div className="lbl-value-list">
                    {assetData.assetBookValue ? (
                      <>
                        {numberFormatter(assetData.assetBookValue) + ' '} <FormattedMessage id="labelTHB" defaultMessage="THB" />
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewSource" defaultMessage="Source" />
                  </div>
                  <div className="lbl-value-list">{assetData.transactionTypeName || '-'}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewAssetSuitable" defaultMessage="Suitable" />
                  </div>
                  <div className="lbl-value-list">{_.get(assetData, 'suitable') || '-'}</div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewRegisterBy" defaultMessage="Register By" />
                  </div>
                  <div className="lbl-value-list">{assetData.registerName || ' '}</div>
                </Col>
              </Row>

              <Divider />
              <Row>
                <Col span={24}>
                  <div className="lbl-title-list-bold ">
                    <FormattedMessage id="AssetOverviewRemark" defaultMessage="Remark" />
                  </div>
                  <div className="lbl-value-list">{assetData.description || ' '}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <br />
      </Card>
    </div>
  );
};
export default AssetOverview;
