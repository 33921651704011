import { useState, useEffect } from 'react';
import httpClient from '../../components/axiosClient';

const getSearchLocation = async (keyword) => {

  try {
    let params = '';
    if (Object.keys(keyword)[0] === 'location') {
      params = `latlng/?lat=${keyword.location.lat()}&lng=${keyword.location.lng()}`;
    }
    else if (Object.keys(keyword)[0] === 'address') {
      params = `latlng/?lat=${keyword.address.lat}&lng=${keyword.address.lng}`;
    }
    else if (Object.keys(keyword)[0] === 'name') {
      params = `name/?name=${keyword.name}`;
    } else {
      params = '';
    }


    if (keyword) {
      const response = await httpClient.get(`/v1/resource/manager/search/location/type/${params}`);

      if (response.status === 200) {
        if (response?.data && Array.isArray(response?.data)) {
          const newArea = response.data.map((item, index) => {
            return {
              key: index,
              ...item
            }
          });
          return newArea;
        } else {
          return response.data;
        }
      }
    }
  } catch (error) {

  }
}

export default getSearchLocation;


