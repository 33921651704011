import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import Button_01 from '../v2/button_01';

const { Option } = Select;

const ListItemFilter = (props) => {
  const { onChange, searchGroup, onAllowClear, onClick, refType,tempchange } = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  console.log('tempchange',tempchange);

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '580px' }}>
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div style={{ padding: '16px 16px 16px 0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="ReferenceFilterNo" defaultMessage="Reference No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'referenceNo')}
                value={_.get(searchGroup, 'referenceNo')}
                placeholder={intl.formatMessage({
                  id: 'ReferenceFilterNoPlaceHolder',
                  defaultMessage: 'Enter Reference No.',
                })}
              />
            </div>
          </div>


          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="ReferenceFilterReferenceType" defaultMessage="Reference Type" />
            </div>
            <Select
              showSearch
              onChange={(value) => onChange(value, 'referenceType')}
              filterOption={false}
              value={ tempchange !== undefined ? _.get(tempchange, 'value') :_.get(searchGroup, 'referenceType')}
              placeholder={intl.formatMessage({ id: 'ReferenceFilterReferenceTypePlaceHolder', defaultMessage: 'Select Reference Type' })}
              style={{ width: '100%' }}
            >
              {_.map(refType, (item) => (
                <Option key={item.code} value={item.code}>
                  {item.refTxt}
                </Option>
              ))}
            </Select>
            {/* <div>
              <Select
                showSearch
                allowClear={true}
                mode='multiple'
                onSearch={(value) => model.setModelSearch(value)}
                onChange={(value) => onChange(value, 'model')}
                dataSource={model.optionsModel}
                filterOption={false}
                value={_.get(searchGroup, 'model')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextModelPlaceholder', defaultMessage: 'Select Model' })}
                style={{ width: '100%' }}
                notFoundContent={model.loadingModel ? 'Loading...' : null}
                disabled={_.get(searchGroup, 'brand') ? false : true}
              >
                {_.map(model.optionsModel, (item) =>
                  <Option value={item.item_model_id}>
                    {item.item_model_name}
                  </Option>
                )}
              </Select>
            </div>  */}
          </div>

          {/* <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="ReferenceFilterBusinessPartner" defaultMessage="Business Partner" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'businessPartnerName')}
                value={_.get(searchGroup, 'businessPartnerName')}
                placeholder={intl.formatMessage({
                  id: 'ReferenceFilterBusinessPartnerPlaceHolder',
                  defaultMessage: 'Enter Business Partner',
                })}
              />
            </div>
          </div> */}

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="ReferenceFilterFromCreatedDate" defaultMessage="From Created Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                style={{ width: '100%' }}
                value={_.get(searchGroup, 'issueDateStart') ? moment(_.get(searchGroup, 'issueDateStart')) : ''}
                onChange={(e) => onChange(e, 'issueDateStart')}
                placeholder={intl.formatMessage({ id: 'ReferenceFilterFromCreatedDatePlaceHolder', defaultMessage: 'Select Created Date' })}
              />
              {/* <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'itemCode')}
                value={_.get(searchGroup, 'itemCode')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemCodePlaceholder', defaultMessage: 'Enter Item Code' })}
              /> */}
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="ReferenceFilterToCreatedDate" defaultMessage="To Created Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                value={_.get(searchGroup, 'issueDateEnd') ? moment(_.get(searchGroup, 'issueDateEnd')) : ''}
                style={{ width: '100%' }}
                onChange={(e) => onChange(e, 'issueDateEnd')}
                placeholder={intl.formatMessage({ id: 'ReferenceFilterToCreatedDatePlaceHolder', defaultMessage: 'Select Created Date' })}
              />
              {/* <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'itemName')}
                value={_.get(searchGroup, 'itemName')}
                placeholder={intl.formatMessage({
                  id: 'modalListItemFilterTextItemNamePlaceholder',
                  defaultMessage: 'Enter Sale Order No.',
                })}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px 16px 16px 0px' }}>
        <Button_01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={onClick}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button_01>
      </div>
    </div>
  );
};

export default ListItemFilter;
