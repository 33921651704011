import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import HttpClient from '../../../components/httpClient';
import ModalAddUser from '../../../components/modal-add-user-new';
import qs from 'qs';
import { debounce } from 'lodash';
import styled from 'styled-components';
import '../css/users.css';
import '../user/css/pagination.css';
import './modalDelete.css';
import './css/user_ex.css';
import Language from '../../../includes/language';
import InviteUsers from '../inviteUser/index';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import { Link, useHistory } from 'react-router-dom';
import { Row, Card, Col, List, Icon, Select, Badge, Button, Menu, Dropdown, Popover, Modal, Checkbox, Avatar } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Table from '../../../components/v2/table';
import Input from '../../../components/v2/input';
import { useAppContext } from '../../../includes/indexProvider';
import clientNew from '../../../components/axiosClient';
import { PageSettings } from '../../../config/page-settings';
import AddUser from '../../users/user/add-user/index';
import { dd, useQuery } from '../../../helpers/general-helper';
import _ from 'lodash';

const ButtonGroup = Button.Group;
const { Option } = Select;
let client = HttpClient();

const AppUsers = (props) => {
  const app = useAppContext();
  const intl = useIntl();
  const query = useQuery();
  const showEdit = app.state.show.userEdit;
  const userInvite = app.state.show.userInvite;
  const [labelShow, setLabelShow] = useState({});
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [keyword, setKeyword] = useState(query.get('search') || '');

  const allColumns = [
    {
      id: 0,
      title: '#',
      select: 'Index',
      align: 'center',
      width: 70,
      dataIndex: 'row_index',
      key: 'row_index',
      render: (text, record, index) => {
        return record.row_index;
      },
    },
    {
      dataIndex: 'profile',
      key: 'profile',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile_img} />,
    },
    {
      id: 1,
      title: <FormattedMessage id="userMgntUsersColumnEmpCode" defaultMessage="Employee Code" />,
      select: intl.formatMessage({ id: 'userMgntUsersColumnEmpCode', defaultMessage: 'Employee Code' }),
      width: 150,
      dataIndex: 'emp_code',
      key: 'emp_code',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return record.emp_code ? record.emp_code : '-';
      },
    },
    {
      id: 2,
      title: <FormattedMessage id="userMgntUsersColumnName" defaultMessage="Name" />,
      select: intl.formatMessage({ id: 'userMgntUsersColumnName', defaultMessage: 'Name' }),
      width: 280,
      dataIndex: 'fullname',
      key: 'fullname',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        if (record.fullname) {
          return record.fullname;
          // <PageSettings.Consumer>
          //   {({ setPageSidebarTitle, checkPermissionAction }) =>
          //     checkPermissionAction('P2S1PG2', 'P2S1PG2A1') ? (
          //       <List.Item.Meta
          //         key={index}
          //         title={
          //           <Link
          //             onClick={() => setPageSidebarTitle(['User Management-User-User Detail'])}
          //             style={{ fontWeight: 'initial' }}
          //             to={
          //               '/main/user-management/users/users-detail/' +
          //               record.mem_com_id +
          //               '?page=' +
          //               record.pageCurrent +
          //               '&pageSize=' +
          //               record.pageSize +
          //               '&searchBy=' +
          //               record.searchBy +
          //               '&search=' +
          //               record.q
          //             }
          //           >
          //             {record.fullname}
          //           </Link>
          //         }
          //       />
          //     ) : (
          //       record.fullname
          //     )
          //   }
          // </PageSettings.Consumer>
        } else {
          return '-';
        }
      },
    },
    {
      id: 3,
      title: <FormattedMessage id="userMgntUsersColumnGender" defaultMessage="Gender" />,
      select: intl.formatMessage({ id: 'userMgntUsersColumnGender', defaultMessage: 'Gender' }),
      width: 120,
      dataIndex: 'gender',
      key: 'gender',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return record.gender ? record.gender : '-';
      },
    },
    {
      id: 4,
      title: <FormattedMessage id="userMgntUsersColumnEmail" defaultMessage="Email" />,
      select: intl.formatMessage({ id: 'userMgntUsersColumnEmail', defaultMessage: 'Email' }),
      width: 200,
      dataIndex: 'email',
      key: 'email',
      filterMultiple: false,
      onFilter: (value, record) => record.address.indexOf(value) === 0,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return record.email ? record.email : '-';
      },
    },
    {
      id: 5,
      title: <FormattedMessage id="userMgntUsersColumnPhone" defaultMessage="Phone Number" />,
      select: intl.formatMessage({ id: 'userMgntUsersColumnPhone', defaultMessage: 'Phone Number' }),
      width: 180,
      dataIndex: 'phone',
      key: 'phone',
      filterMultiple: false,
      onFilter: (value, record) => record.address.indexOf(value) === 0,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return record.phone ? record.phone : '-';
      },
    },
    {
      id: 6,
      title: <FormattedMessage id="userMgntUsersColumnOnline" defaultMessage="Online" />,
      select: intl.formatMessage({ id: 'userMgntUsersColumnOnline', defaultMessage: 'Online' }),
      width: 150,
      dataIndex: 'status_connection',
      key: 'status_connection',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const status = record.status_connection === 'Active' ? 'green' : '#A9A9A9';
        return <Badge className="users-badge" text={record.txtStatusConnection} color={status} />;
      },
    },
    {
      id: 7,
      title: <FormattedMessage id="userMgntUsersColumnStatus" defaultMessage="Status" />,
      select: intl.formatMessage({ id: 'userMgntUsersColumnStatus', defaultMessage: 'Status' }),
      width: 150,
      dataIndex: 'status_work',
      key: 'status_work',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const status = record.status_work === 'Enable' ? 'blue' : '#A9A9A9';
        return <Badge className="users-badge" text={record.txtStatusWork} color={status} />;
      },
    },
    {
      id: 8,
      title: <FormattedMessage id="userMgntUsersColumnLastActivity" defaultMessage="Last Activity" />,
      select: intl.formatMessage({ id: 'userMgntUsersColumnLastActivity', defaultMessage: 'Last Activity' }),
      width: 250,
      dataIndex: 'last_activity',
      key: 'last_activity',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return record.last_activity ? <span>{moment(`${record.last_activity}`).format('LLL')}</span> : '-';
      },
    },
    // {
    //   select: intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' }),
    //   align: 'center',
    //   width: 70,
    //   dataIndex: 'operation',
    //   key: 'operation',
    //   render: (text, record, index) => {
    //     return (
    //       <PageSettings.Consumer>
    //         {({ checkPermissionAction }) => (
    //           <div>
    //             {checkPermissionAction('P2S1PG1C1', 'P2S1PG1C1A4') ? (
    //               <Popover
    //                 key={index}
    //                 zIndex={999}
    //                 content={content(record)}
    //                 placement="leftTop"
    //                 visiblePopover={visiblePopover}
    //                 onVisibleChange={handleOnVisiblePopover}
    //               >
    //                 <Icon type="more" />
    //               </Popover>
    //             ) : null}
    //           </div>
    //         )}
    //       </PageSettings.Consumer>
    //     );
    //   },
    // },
  ];

  const setShowColumn = {
    row_index: true,
    emp_code: true,
    fullname: true,
    gender: true,
    email: true,
    phone: true,
    status_connection: true,
    status_work: true,
    last_activity: false,
    operation: false,
    profile: true,
  };

  const setShowColumnArr = [
    'row_index',
    'emp_code',
    'fullname',
    'gender',
    'email',
    'phone',
    'status_connection',
    'status_work',
    'operation',
    'profile',
  ];

  const ignoreColumn = ['last_activity'];
  const newColumns = allColumns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreColumnSelectable = ['row_index', 'operation', 'profile'];
  const selectableColumns = allColumns.filter((col) => !ignoreColumnSelectable.includes(col.key));

  const ignoreSearchColumn = ['row_index', 'last_activity', 'operation', 'profile'];
  const searchColumns = allColumns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const [dataUsers, setDataUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectSearchData, setSelectSearchData] = useState(query.get('searchBy') || '');
  const [selectValue1, setSelectValue1] = useState(query.get('searchBy') || '');

  const [usersLength, setUsersLength] = useState();
  const extraField = 'created_at';
  const extraOrder = 'ASC';
  const [visibleColumns, setVisibleColumns] = useState(false);
  const [newDataColumns1, setNewDataColumns1] = useState([]);

  const [currentDisplayColumns, setCurrentDisplayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [recordPerPage, setRecordPerPage] = useState(parseInt(query.get('pageSize')) || 10);
  const [defaultCurrentConst, setDefaultCurrentConst] = useState(parseInt(query.get('page')) || 1);
  const [pageSizeOptionsConst, setPageSizeOptionsConst] = useState(['10', '20', '30', '40', '50']);
  const [showSizeChangerConst, setShowSizeChangerConst] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);

  const [addUserVisible, setAddUserVisible] = useState(false);
  const [orgId, setOrgId] = useState();
  const [orgIdMain, setOrgIdMain] = useState();
  const [expandedKeys, setExpandedKeys] = useState();

  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();
  const history = useHistory();

  useEffect(() => {
    loadContent(undefined, undefined, recordPerPage, keyword !== '' ? 1 : defaultCurrentConst);
  }, [keyword, refreshTable, selectSearchData]);

  useEffect(() => {
    setNewDataColumns1(newColumns);
  }, [labelShow]);

  // get data จาก button value เพื่อนำมาเปลี่ยนภาษา จากหน้า userMenu
  localStorage.getItem('newColumns2');
  const comId = localStorage.getItem('comId');
  const pageCode = localStorage.getItem('pageCode');
  const memComId = localStorage.getItem('memComId');

  const loadContent = async (field, order, pageSize, current) => {
    setLoading(true);
    const exField = field === undefined ? extraField : field;
    const exOrder = order === undefined ? extraOrder : order;
    const record = pageSize == undefined ? `${recordPerPage}` : pageSize;
    const checkCurrent = current == undefined ? `${defaultCurrentConst}` : current;

    let setSelect = selectSearchData == undefined ? '' : selectSearchData;
    let setValue = keyword == undefined ? '' : keyword;

    const key = setSelect;
    const val = setValue.toLowerCase();

    const bodyData = {
      user_id: memComId,
      like: [],
      equal: [],
      date: [],
      order: `${exField} ${exOrder}`,
      limit: `${record}`,
      index_page: `${checkCurrent}`,
      com_id: comId,
    };

    if (val) {
      if (key === 'status_connection') {
        if ('active'.indexOf(val) !== -1) {
          bodyData.equal.push({ key: `${key}`, val: `Active` });
        } else if ('in active'.indexOf(val) !== -1) {
          bodyData.equal.push({ key: `${key}`, val: `In active` });
        } else {
          bodyData.equal.push({ key: `${key}`, val: `${val}` });
        }
      } else {
        bodyData.like.push({ key: `${key}`, val: `${val}` });
      }
    }

    try {
      const result = await clientNew.post('/v2/searchusers', bodyData);
      const users = result.data.data;
      const usersTotal = result.data.total_record ? result.data.total_record : 0;
      setLoading(false);
      setDefaultCurrentConst(parseInt(checkCurrent));
      setRecordPerPage(record);
      if (users) {
        setDataTable(users, pageSize, current, key);
        setUsersLength(usersTotal);
      } else {
        setDataTable([], pageSize, current, key);
        setUsersLength(usersTotal);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const setDataTable = (users, pageSize, current, searchBy) => {
    if (users) {
      users.map((item, key) => {
        return (item.pageSize = pageSize || 10), (item.pageCurrent = current || 1), (item.q = keyword), (item.searchBy = searchBy);
      });
      setDataUsers(users);
    }
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    let order = extra.order === 'descend' ? 'DESC' : 'ASC';
    let current = pagination.current;
    let pageSize = pagination.pageSize;
    loadContent(field, order, pageSize, current);
  };

  const deleteId = async (mem_com_id) => {
    const data = {
      client_id: 'admin-cli',
      grant_type: 'password',
      username: 'admin',
      password: '#Admin123',
    };
    const axiosConfig = {
      Header: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    client.defaults.baseURL = `${process.env.REACT_APP_KEYCLOAK_URL}`;
    const res = await client.post('/auth/realms/master/protocol/openid-connect/token', qs.stringify(data), axiosConfig);

    let tokens = res.data.access_token;
    try {
      client.defaults.baseURL = `${process.env.REACT_APP_URL_MANAGER_ADD_PORT}`;
      const response = await clientNew.delete(`/v2/user/${memComId}/${mem_com_id}`);
      if (response.status === 200) {
        client.defaults.baseURL = `${process.env.REACT_APP_KEYCLOAK_URL}`;
        client.defaults.headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${tokens}`,
        };
        successNotification(response.data.data);
        setRefreshTable((current) => !current);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        errorNotification(error.response.statusText);
      }
    }
  };

  const handleSearch = debounce((keyword) => {
    setKeyword(keyword);
  }, 500);

  // event SelectData
  const handleSelectData = (value) => {
    setSelectSearchData(value);
    setSelectValue1(value);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const checkedValueColumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked == true) {
      const found = currentDisplayColumns.find((element) => element == val);
      if (found == undefined) {
        setCurrentDisplayColumns([...currentDisplayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...currentDisplayColumns];
      const found = currentDisplayColumns.findIndex((element) => element == val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setCurrentDisplayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleOnVisibleColumns = () => {
    setVisibleColumns(!visibleColumns);
  };

  const handleVisibleChange = (flag) => {
    setVisibleColumns(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns1.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setDefaultShowColumn(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (currentDisplayColumns.length <= 3) {
      setTextErrorSelectColumn(<FormattedMessage id="userMgntUsersMesTxtShowColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }

    for (let i = 0; i < allColumns.length; i++) {
      const found = currentDisplayColumns.find((element) => element == allColumns[i].key);
      if (found != undefined) {
        setNewArr.push(allColumns[i]);
      }
    }
    setNewDataColumns1(setNewArr);
    handleOnVisibleColumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns1(newColumns);
    setCurrentDisplayColumns(newListArrayColumns);
    handleOnVisibleColumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {selectableColumns.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox value={item.key} onChange={checkedValueColumns} checked={defaultShowColumn[item.key] ? true : false}>
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>{textErrorSelectColumn}</Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <ButtonGroup style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </ButtonGroup>
        </Menu.Item>
      </Menu>
    );
  };

  const hidePopover = (e) => {
    e.preventDefault();
    setVisiblePopover(false);
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  // const content = (users) => {
  const confirm = (users) => {
    if (users.mem_com_id == memComId) {
      Modal.warning({
        className: 'users-modal-warning',
        title: intl.formatMessage({ id: 'userMgntUserLblUserDelete', defaultMessage: 'You cannot delete your account.' }),
        okText: intl.formatMessage({ id: 'modalConfirmBtnClose', defaultMessage: 'Close' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
      });
    } else {
      Modal.confirm({
        className: 'users-modal-confirm',
        icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
        title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
        okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
        cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'dashed',
        },
        onOk() {
          deleteId(users.mem_com_id, refreshTable);
        },
        onCancel() {},
      });
    }
  };

  // return (
  //   <div onClick={hidePopover}>
  //     {showEdit === false ? null : (
  //       <div>
  //         <Button className="styledPopDelete" btnsize={'wd_df'} type="link" ghost>
  //           <p className="action-delete-p">
  //             <FormattedMessage id="edit" defaultMessage="Edit" />
  //           </p>
  //         </Button>
  //         <br />
  //       </div>
  //     )}
  //     <Button className="styledPopDelete" btnsize={'wd_df'} type="link" ghost onClick={() => confirm(users)}>
  //       <p className="action-delete-p">
  //         <FormattedMessage id="btnDelete" defaultMessage="Delete" />
  //       </p>
  //     </Button>
  //   </div>
  // );
  // };

  const showModal = () => {
    setAddUserVisible(true);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const linkToView = (record) => {
    history.push(
      '/main/user-management/users/users-detail/' +
        record.mem_com_id +
        '?page=' +
        record.pageCurrent +
        '&pageSize=' +
        record.pageSize +
        '&searchBy=' +
        record.searchBy +
        '&search=' +
        record.q
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              {record !== undefined ? (
                <>
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => linkToView(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                  </>

                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => confirm(record)}>
                      <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                    </Button>
                  </>
                </>
              ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div className="componentUsers">
          {checkPermissionAction('P2S1PG1C1', 'P2S1PG1C1A1') ? (
            <Card
              extra={
                checkPermissionAction('P2S1PG1C1', 'P2S1PG1C1A7') ? (
                  <Button01 className="btn_user_ex" type="primary" fontsize="wd_at" onClick={showModal}>
                    <FormattedMessage id="UserManagementAddNewUserTxtButton" defaultMessage="Add User" />
                  </Button01>
                ) : null
              }
              className="user-management-users-card"
              title={<FormattedMessage id="userMgntUsersLblTitle" defaultMessage="User" />}
            >
              <Row className="styled-row">
                <Col span={12} className="styled-col-input">
                  <Row>
                    <List.Item>
                      <Input
                        className="user-management-users-input action-input"
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                        prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
                        defaultValue={keyword}
                        autoComplete="off"
                      />
                      <Select className="styledAppSelect action-select" onChange={handleSelectData} value={selectValue1}>
                        <Option value="">
                          <FormattedMessage id="userMgntUsersLblAllcolumns" defaultMessage="All columns" />
                        </Option>
                        {searchColumns.map((item, i) => (
                          <Option key={i} value={item.key}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </List.Item>
                  </Row>
                </Col>
                <Col span={12} className="styled-col-select">
                  <Row>
                    <List.Item style={{ float: 'right' }}>
                      {/* <AddUser setRefreshTable={setRefreshTable} refreshTable={refreshTable} /> */}

                      <ModalAddUser
                        visible={addUserVisible}
                        setVisible={setAddUserVisible}
                        dataMain={{
                          orgId,
                          setOrgId,
                          setOrgIdMain,
                          setExpandedKeys,
                        }}
                        setRefreshTable={setRefreshTable}
                        permissions={{
                          page: 'P2S1PG1C1',
                          add: 'P2S1PG1C1A5',
                          edit: 'P2S1PG1C1A6',
                        }}
                        routeFrom="user"
                      />
                      {/* {checkPermissionAction('P2S1PG1C1', 'P2S1PG1C1A2') ? (
                        userInvite === false ? null : (
                          <InviteUsers className="btn_user_ex" comId={comId} lang="TH" pageCode={pageCode} labelShow={labelShow} />
                        )
                      ) : null} */}
                      <Dropdown overlay={menuColumn} trigger={['click']} visible={visibleColumns} onVisibleChange={handleVisibleChange}>
                        <Button01 className="btn_user_ex2" type="primary" btnsize="wd_at" fontSize="sm" onClick={handleOnVisibleColumns}>
                          <FormattedMessage id="userMgntUserBtnColumns" defaultMessage="Columns" /> <Icon type="down" />
                        </Button01>
                      </Dropdown>
                    </List.Item>
                  </Row>
                </Col>
              </Row>
              {/* Table dataSource ของ User ทั้งหมด */}
              <div onMouseLeave={handleMouseLeave}>
                <Table
                  className="users-table"
                  style={{ marginTop: 10 }}
                  size={'middle'}
                  rowKey={(record) => record.mem_com_id}
                  columns={newDataColumns1}
                  dataSource={dataUsers}
                  loading={loading}
                  scroll={{ x: 2000, y: `calc(100vh - 370px)` }}
                  onChange={handleChange}
                  pagination={{
                    total: usersLength,
                    showTotal: showTotal,
                    pageSizeOptions: pageSizeOptionsConst,
                    showSizeChanger: showSizeChangerConst,
                    locale: { items_per_page: '' },
                    current: defaultCurrentConst,
                    pageSize: recordPerPage,
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        if (!event.target.href) {
                          const { x, y } = ref.current.getBoundingClientRect();
                          setVisiblePopoverTable(true);
                          setOffsetPopover([event.pageX - x, event.pageY - y]);
                          setRecordPopover(record);
                        }
                      },
                    };
                  }}
                />
                {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
              </div>
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  marginTop: '-40px',
                  marginLeft: '5px',
                }}
              >
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${usersLength || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            </Card>
          ) : null}
        </div>
      )}
    </PageSettings.Consumer>
  );
};

const StyledButton = styled(Button)`
  margin: 5px;
  box-shadow: 'none';
  font-size: 13px;
  background-color: #1d3557;
  border-color: #1d3557;
  color: #ffffff;
  border-radius: 2px;
  :hover {
    background: #ffffff;
    color: #1d3557;
  }
`;

export default AppUsers;
