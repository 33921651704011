import httpClient from '../../components/axiosClient';

const getCustomerInformation = async (customerId) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`);
    if (response.status == 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getCustomerInformation;