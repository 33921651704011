import React from 'react'
import { DatePicker, Divider, Select, Input } from 'antd';
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import Button01 from '../../../../components/v2/button_01'

const { Option } = Select

const ListItemFilter = (props) => {
  const { onChange, searchGroup, setTrigger } = props
  const intl = useIntl()

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '580px' }}>
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div style={{ padding: '16px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="listPOFilterPONo" defaultMessage="PO No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'poNo')}
                value={_.get(searchGroup, 'poNo')}
                placeholder={intl.formatMessage({ id: 'listPOFilterPHPONo', defaultMessage: 'Enter PO No.' })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="listPOFilterFromPODate" defaultMessage="From PO Date" />
            </div>
            <div>
              <DatePicker
                allowClear={true}
                onChange={(value) => onChange(value, 'fromDate')}
                value={_.get(searchGroup, 'fromDate')}
                placeholder={intl.formatMessage({ id: 'listPOFilterPHPODate', defaultMessage: 'Select PO Date' })}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="listPOFilterToPODate" defaultMessage="To PO Date" />
            </div>
            <div>
              <DatePicker
                allowClear={true}
                onChange={(value) => onChange(value, 'toDate')}
                value={_.get(searchGroup, 'toDate')}
                placeholder={intl.formatMessage({ id: 'listPOFilterPHPODate', defaultMessage: 'Select PO Date' })}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px' }}>
        <Button01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={() => setTrigger(cur => !cur)}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button01>
      </div>
    </div>

  )
}

export default ListItemFilter
