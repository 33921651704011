import React, { useState, useEffect } from 'react';
import Card from '../../../components/v2/card';
import { useIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Form, Select, DatePicker } from 'antd';
import Button01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import AssetPreventiveTable from './assetpreventive-table';
import _ from 'lodash';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const AssetPreventive = (props) => {
  const { handleOpenAddPM, cencelPreventiveApi, pmlist, page, isLoading, form, prePage, preSize } = props;
  const pmData = _.get(props.pmlist, 'pmList');
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [isFilter, setIsFilter] = useState(false);
  const [filterPM, setFilterPM] = useState([]);
  const [rangeDate, setRangeDate] = useState(false);
  // const [page, setPage] = useState(1);
  const intl = useIntl();

  useEffect(() => {
    filterData();
  }, [getFieldValue('PMitem')]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: `preventiveRound`, defaultMessage: 'Round' }),
      dataIndex: 'round',
      key: 'round',
      sorter: true,
      fixed: 'left',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: `preventiveDate`, defaultMessage: 'Date' }),
      dataIndex: 'dateFormat',
      key: 'dateFormat',
      sorter: true,
      fixed: 'left',
      width: 180,
    },
    {
      title: intl.formatMessage({ id: `preventiveDescription`, defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: `preventiveTeam`, defaultMessage: 'Team.' }),
      key: 'team',
      dataIndex: 'team',
      sorter: true,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: `preventiveAssignees`, defaultMessage: 'Assignees.' }),
      key: 'assignee',
      dataIndex: 'assignee',
      sorter: true,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: `preventiveTaskNo`, defaultMessage: 'Task No.' }),
      key: 'taskNo',
      dataIndex: 'taskNo',
      sorter: true,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: `preventiveStatus`, defaultMessage: 'Status' }),
      key: 'taskStatusTxt',
      dataIndex: 'taskStatusTxt',
      sorter: true,
      width: 150,
      render: (text, record, index) => (
        <div className="status-Preventive" style={{ background: `${record.taskStatusColor}` }}>
          {record.taskStatusTxt}
        </div>
      ),
    },
  ];

  const handleOpenFilter = () => {
    setIsFilter(!isFilter);
    setFilterPM([]);
  };

  const handleChangeDate = (value) => {
    setRangeDate(value);
  };

  const filterData = () => {
    setFilterPM(_.filter(pmData, { round: getFieldValue('PMitem') }));
  };

  return (
    <Card
      bodyStyle={{ padding: 'unset' }}
      title={<FormattedMessage id="AssetPreventive" defaultMessage="Preventive Maintenance (PM)" />}
      extra={
        <div className="extra-user-details">
          <div>
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenAddPM()}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button01>
          </div>
        </div>
      }
    >
      <div className="pmpadding">
        <Row gutter={16}>
          <Col span={8} style={{ padding: '16px 0px' }}>
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenFilter()} style={{width: 'auto'}}>
              <FormattedMessage id="assetpreventiveFillter" defaultMessage="Filter" />{' '}
              <DownOutlined style={{ fontSize: '10px', paddingLeft: '20%', marginRight: '10px' }} />
            </Button01>
          </Col>
        </Row>
      </div>
      {isFilter === true && (
        <div className="filterBackground">
          <Form style={{ width: '100%' }}>
            <Row gutter={[24, 24]} style={{ margin: '0px' }}>
              <Col span={11} style={{ padding: '16px 16px 0px 0px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="assetpreventiveItem" defaultMessage="Items" />
                  </span>
                  {getFieldDecorator('PMitem')(
                    <Select
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: 'assetpreventivePlaceholderItem', defaultMessage: 'Select Items' })}
                    >
                      {pmData &&
                        pmData.map((item) => (
                          <Option key={item.round} value={`${item.round}`}>
                            {item.round}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={11} style={{ padding: '16px 4px 0px 12px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="assetpreventiveDate" defaultMessage="Date" />
                  </span>
                  {getFieldDecorator('PMdate')(
                    <RangePicker
                      style={{ width: '100%' }}
                      onChange={handleChangeDate}
                      defaultValue={rangeDate}
                      placeholder={[
                        intl.formatMessage({
                          id: 'assetpreventivePlaceholderDate',
                          defaultMessage: 'Select From Date -',
                        }),
                        intl.formatMessage({
                          id: 'assetpreventivePlaceholderDate2',
                          defaultMessage: 'Select To Date',
                        }),
                      ]}
                      format={dateFormat}
                      allowClear={false}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={11} style={{ padding: '16px 16px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="assetpreventiveAssignee" defaultMessage="Assignee" />
                  </span>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'assetpreventivePlaceholderItem', defaultMessage: 'Select Assignee' })}
                  >
                    {pmData &&
                      pmData.map((item) => (
                        <Option key={item.assignee} value={`${item.assignee}`}>
                          {item.assignee}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={11} style={{ padding: '16px 16px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="assetpreventiveStatus" defaultMessage="Status" />
                  </span>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'assetpreventivePlaceholderStatus', defaultMessage: 'Select Status' })}
                  >
                    {pmData &&
                      pmData.map((item) => (
                        <Option key={item.taskStatusTxt} value={`${item.taskStatusTxt}`}>
                          {item.taskStatusTxt}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      <div className="pmpadding">
        <Row gutter={16}>
          <AssetPreventiveTable
            columns={columns}
            page={prePage}
            size={preSize}
            pmlist={pmlist}
            isLoading={isLoading}
            filterPM={filterPM}
            handlePrePage={props.handlePrePage}
            handlePreSize={props.handlePreSize}
            cencelPreventiveApi={cencelPreventiveApi}
          />
        </Row>
      </div>
    </Card>
  );
};

const PreventiveFillter = Form.create({
  name: 'filter_form',
  mapPropsToFields() {},
})(AssetPreventive);

export default PreventiveFillter;
