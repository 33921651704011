import httpClient from '../../components/axiosClient';

const getMemberInformation = async (dataAPI) => {
  const comCode = localStorage.getItem('comCode');
  if (dataAPI) {
    try {
      const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/monitor/member/${dataAPI.memComId}/infomation?orgId=${dataAPI.orgId}&startDate=${dataAPI.startDate}&endDate=${dataAPI.endDate}`);
      if (response.status === 200) {
        return (response.data.data);
      } else {
        return ({});
      }
    } catch (error) {
      return ({});
    } 
  }
}

export default getMemberInformation;
