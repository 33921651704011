import { Modal, Col, Row, Form, Select, Input, InputNumber } from 'antd';
import React, { useContext, useState } from 'react';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import { AllowanceContext } from './allowancecontext';
import _ from 'lodash';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { saveSettingDistanceRate, saveSettingTaskRate, saveSettingTripRate } from '../../../controllers/setting/allowanceapi';
const { Option } = Select;

const AllowanceEditModal = (props) => {
  const { form } = props;
  const { state, setState, fnc } = useContext(AllowanceContext);
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const { visibleEdit, viewAllType, allowanceListRight } = state;
  const { setVisibleEdit,setEvent } = setState;
  const dataTask = _.get(allowanceListRight, 'taskTypeIdList');
  const dataTaskrate = _.get(allowanceListRight, 'taskRate.settingList');
  const dataDistance = _.get(allowanceListRight, 'distanceRate.settingList');
  const dataTrip = _.get(allowanceListRight, 'tripRate.settingList');
  const [setting, setSetting] = useState([]);
  getFieldDecorator('keys', { initialValue: viewAllType === 'task' ? dataTaskrate : viewAllType === 'trip' ? dataTrip : dataDistance });
  // getFieldDecorator('keys', { initialValue: [1] });
  const keys = getFieldValue('keys');
  let id = 1;

  console.log('settingValue', setting);

  const resetFiledsAll = () => {
    setVisibleEdit(false);
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) return;

      if (values) {
        console.log('valuesRight', values);

        let settings = [];
        if (viewAllType === 'task' || viewAllType === 'trip') {
          for (let index = 0; index < _.get(values, 'tasktype.length'); index++) {
            settings.push({
              taskTypeId: _.get(values, `tasktype[${index}]`),
              taskTypeName: _.get(values, `tasktype[${index}]`),
              driver1Percent: _.get(values, `driver1[${index}]`),
              driver2Percent: _.get(values, `driver2[${index}]`),
            });
          }
        }

        if (viewAllType === 'distance') {
          for (let index = 0; index < _.get(values, 'tripallowance.length'); index++) {
            settings.push({
              distanceFrom: _.get(values, `fromdistance[${index}]`),
              distanceTo: _.get(values, `todistance[${index}]`),
              tripAllowance: _.get(values, `tripallowance[${index}]`),
            });
          }
        }

        try {
          const payload =
            viewAllType === 'trip'
              ? {
                  settingList: settings,
                }
              : {
                  defaultRate: viewAllType === 'task' ? _.get(values, 'defaulttaskrate') : _.get(values, 'defaultdistance'),
                  settingList: settings,
                };

          console.log('payloadAll', payload);
          const response =
            viewAllType === 'task'
              ? await saveSettingTaskRate(payload)
              : viewAllType === 'distance'
              ? await saveSettingDistanceRate(payload)
              : await saveSettingTripRate({
                  settingList: settings,
                });

          console.log('responseAllo', response);

            successNotification(_.get(response, 'status.message'));
            setEvent((current) => !current);

        } catch (error) {
          console.log('errorall',error.response);
          errorNotification(_.get(error.response, 'data.status.message'));
        }
      }

      resetFiledsAll();
    });
  };

  const removeForm = (k) => {
    if (keys.length === 1) {
      return;
    }
    setFieldsValue({
      keys: keys.filter((key, index) => index !== k),
    });
  };

  const addForm = () => {
    const nextKeys = keys.concat(id++);
    setFieldsValue({
      keys: nextKeys,
    });
  };

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const handleChangeDriver1 = (e,index) => {
    setFieldsValue({ [`driver2[${index}]`]: 100 - e });
  };

  const handleChangeDriver2 = (e,index) => {
    setFieldsValue({ [`driver1[${index}]`]: 100 - e });
  };

  const formItems = _.map(keys, (k, index) => (
    <Row gutter={[12, 5]} style={{ marginBottom: 10 }} key={index}>
      <Col span={7}>
        {viewAllType === 'distance' ? (
          <Form.Item key={index}>
            {getFieldDecorator(`fromdistance[${index}]`, {
              initialValue: index === 0 ? 0 : _.get(k, 'distanceFrom'),
              rules: [
                {
                  required: true,
                  message: 'Please Enter From Distance',
                },
              ],
            })(<InputNumber min={index === 0 ? 0 : getFieldValue(`todistance[${index-1}]`)+1} placeholder={intl.formatMessage({ id: 'modalEditFromDistance', defaultMessage: 'Enter From Distance (km)' })} />)}
          </Form.Item>
        ) : (
          <Form.Item key={index}>
            {getFieldDecorator(`tasktype[${index}]`, {
              initialValue: _.get(k, 'taskTypeId'),
              rules: [
                {
                  required: true,
                  message: 'Please Select Task Type',
                },
              ],
            })(
              <Select
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({ id: 'modalEditTaskType', defaultMessage: 'Select Task Type' })}
              >
                {_.map(dataTask, (item, index) => {
                  return (
                    <Option key={_.get(item, 'taskTypeId')} value={_.get(item, 'taskTypeId')}>
                      {_.get(item, 'taskTypeName')}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        )}
      </Col>
      <Col span={7}>
        {viewAllType === 'distance' ? (
          <Form.Item key={index}>
            {getFieldDecorator(`todistance[${index}]`, {
              initialValue: _.get(k, 'distanceTo'),
              rules: [
                {
                  required: true,
                  message: 'Please Enter To Distance (km)',
                },
              ],
            })(<InputNumber min={getFieldValue(`fromdistance[${index}]`)} placeholder={intl.formatMessage({ id: 'modalEditToDistance', defaultMessage: 'Enter To Distance (km)' })} />)}
          </Form.Item>
        ) : (
          <Form.Item key={index}>
            {getFieldDecorator(`driver1[${index}]`, {
              initialValue: _.get(k, 'driver1Percent'),
              rules: [
                {
                  required: true,
                  message: 'Please Enter Driver 1 ',
                },
              ],
            })(
              <InputNumber
                onChange={(e) => handleChangeDriver1(e, index)}
                max={100}
                min={0}
                placeholder={intl.formatMessage({ id: 'modalEditDriver1', defaultMessage: 'Enter Driver 1' })}
              />
            )}
          </Form.Item>
        )}
      </Col>

      <Col span={7}>
        {viewAllType === 'distance' ? (
          <Form.Item key={index}>
            {getFieldDecorator(`tripallowance[${index}]`, {
              initialValue: _.get(k, 'tripAllowance'),
              rules: [
                {
                  required: true,
                  message: 'Please Enter Trip Allowance',
                },
              ],
            })(<InputNumber min={getFieldValue('defaultdistance')} placeholder={intl.formatMessage({ id: 'modalEditDriver2', defaultMessage: 'Enter Trip Allowance (THB)' })} />)}
          </Form.Item>
        ) : (
          <Form.Item key={index}>
            {getFieldDecorator(`driver2[${index}]`, {
              initialValue: _.get(k, 'driver2Percent'),
              rules: [
                {
                  required: true,
                  message: 'Please Enter Driver 2 ',
                },
              ],
            })(
              <InputNumber
              onChange={(e) => handleChangeDriver2(e, index)}
              max={100}
              min={0}
                placeholder={intl.formatMessage({ id: 'modalEditDriver2', defaultMessage: 'Enter Driver 2' })}
              />
            )}
          </Form.Item>
        )}
      </Col>
      <Col span={3}>
        {keys.length > 1 && index !== keys.length - 1 ? (
          <Button_02 key="back" btnsize="wd_df" onClick={() => removeForm(index)} style={{ width: '100%', margin: 'unset' }}>
            <FormattedMessage id="modalRequestOptionDelete" defaultMessage="Delete" />
          </Button_02>
        ) : (
          <Button_02 key="back" btnsize="wd_df" onClick={addForm} style={{ width: '100%', margin: 'unset' }}>
            <FormattedMessage id="modalRequestOptionAdd" defaultMessage="Add" />
          </Button_02>
        )}
      </Col>
    </Row>
  ));

  return (
    <Modal
      visible={visibleEdit}
      onCancel={() => resetFiledsAll()}
      centered={true}
      title={
        <>
          {viewAllType === 'task' && <FormattedMessage id="AllowanceEditCard" defaultMessage="Edit Task Rate" />}
          {viewAllType === 'trip' && <FormattedMessage id="AllowanceEditCardTrip" defaultMessage="Edit Trip Rate" />}
          {viewAllType === 'distance' && <FormattedMessage id="AllowanceEditCardDistance" defaultMessage="Edit Distance Rate" />}
        </>
      }
      width={760}
      style={{ marginTop: '15px' }}
      bodyStyle={{ padding: '5px 24px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={() => resetFiledsAll()} style={{ margin: 'unset' }}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form>
        <Row gutter={24}>
          <Col span={12} style={{ padding: '0px 10px 0px 10px' }}>
            {viewAllType === 'distance' && (
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="allowanceDefaultDistance" defaultMessage="Default Distance Rate (THB)" />
                  <LabeRequire req={true} />
                </span>
                {getFieldDecorator('defaultdistance', {
                  initialValue: _.get(allowanceListRight, 'distanceRate.default'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'AllowanceFormEnterDistanceRate',
                        defaultMessage: 'Please Enter Distance Rate (THB)',
                      }),
                    },
                  ],
                })(
                  <Input
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'allowanceEnterAllowanceRate', defaultMessage: 'Enter Distance Rate (THB)' })}
                  />
                )}
              </Form.Item>
            )}
            {viewAllType === 'task' && (
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="allowanceDefaultTaskType" defaultMessage="Default Task Rate (THB)" />
                  <LabeRequire req={true} />
                </span>
                {getFieldDecorator('defaulttaskrate', {
                  initialValue: _.get(allowanceListRight, 'taskRate.default'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'AllowanceFormtasktype',
                        defaultMessage: 'Please Select Task Rate',
                      }),
                    },
                  ],
                })(
                  <Input
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'allowanceEnterAllowance', defaultMessage: 'Enter Task Rate (THB)' })}
                  />
                )}
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={[12, 5]}>
          <Col span={7}>
            {viewAllType === 'distance' ? (
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="modalEditallowancefromdistance" defaultMessage="From Distance (km)" />
                  <LabeRequire req={true} />
                </span>
              </Form.Item>
            ) : (
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="modalEditAllowanceTaskType" defaultMessage="Task Type" />
                  <LabeRequire req={true} />
                </span>
              </Form.Item>
            )}
          </Col>

          <Col span={7}>
            {viewAllType === 'distance' ? (
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="modalEditAllowancetodistance" defaultMessage="To Distance (km)" />
                  <LabeRequire req={true} />
                </span>
              </Form.Item>
            ) : (
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="modalEditAllowanceDriver1" defaultMessage="Driver 1 (%)" />
                  <LabeRequire req={true} />
                </span>
              </Form.Item>
            )}
          </Col>
          <Col span={7}>
            {viewAllType === 'distance' ? (
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="modalEditAllowanceTripAllowance" defaultMessage="Trip Allowance (THB)" />
                  <LabeRequire req={true} />
                </span>
              </Form.Item>
            ) : (
              <Form.Item>
                <span className="formText-formate">
                  <FormattedMessage id="modalEditAllowanceDriver2" defaultMessage="Driver 2 (%)" />
                  <LabeRequire req={true} />
                </span>
              </Form.Item>
            )}
          </Col>
        </Row>
        {formItems}
      </Form>
    </Modal>
  );
};

const AllowanceEditModalForm = Form.create({
  name: 'Allowance_create_form',
  mapPropsToFields() {},
})(AllowanceEditModal);

export default AllowanceEditModalForm;
