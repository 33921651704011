import { Col, Form, Icon, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../v2/button_01';
import './index.css';
import _ from 'lodash';

const CollapseCustom = (props) => {
  const { label = '', children, extra, setIsCollapse } = props;
  const intl = useIntl();
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  console.log("extra",extra)

  useEffect(() => {
    if (_.isFunction(setIsCollapse)) setIsCollapse(isOpen)
  }, [isOpen])

  return (
    <div>
      <div className="collapse-layout">
        <div>
          <Button_01
            key="submit"
            type="primary"
            btnsize="wd_df"
            style={{ marginRight: 'unset', marginLeft: 'unset' }}
            onClick={() => setIsOpen((current) => !current)}
          >
            {label} {isOpen ? <Icon type="up" /> : <Icon type="down" />}
          </Button_01>
        </div>
        {extra ? extra : null}
      </div>
      <div className="collapse-parent" ref={ref} style={isOpen ? { height: ref.current.scrollHeight + 'px' } : { height: '0px' }}>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};
export default CollapseCustom;
