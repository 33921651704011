import React from 'react'
import { Form, Row, Col, Input, Divider, Table } from 'antd'
import LabeRequireForm from '../../../../components/label-required-form'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal'
import moment from 'moment'

const ExpenseDetail = (props) => {
  const { form, columns, columnsAtt, selectItem, attachmentData, editDefault, subTotal, subTotalTax, total } = props
  const intl = useIntl()
  const styleText = {
    color: '#050505',
    fontSize: '13px'
  }

  return (
    <div>
      <Form form={form} layout="vertical" className='expenseForm'>
        <div style={{ padding: '12px 24px' }}>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormVendor', defaultMessage: 'Vendor or Supplier' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'vendor.vendorName') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormContact', defaultMessage: 'Contact' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'vendor.vendorContactName') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormPhone', defaultMessage: 'Phone' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'vendor.vendorContactPhone') || '-'}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormOrderNo', defaultMessage: 'Purchase Order No.' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'poNo') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormTotal', defaultMessage: 'Total' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'totalPo') ? numberFormatDecimal(_.get(editDefault, 'totalPo') || 0) : "-"}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormAccrued', defaultMessage: 'Accrued Expense' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'accruedExpense') ? numberFormatDecimal(_.get(editDefault, 'accruedExpense') || 0) : '-'}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormInvoiceNo', defaultMessage: 'Invoice No.' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'invoiceNo') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormInvoiceDate', defaultMessage: 'Invoice Date' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'invoiceDate') ? moment(_.get(editDefault, 'invoiceDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormAmount', defaultMessage: 'Amount' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'amountInvoice') ? numberFormatDecimal(_.get(editDefault, 'amountInvoice') || 0) : '-'}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormIssuedBy', defaultMessage: 'Issued by' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'issued.name') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormIssuedDate', defaultMessage: 'Issued Date' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'issuedDate') ? moment(_.get(editDefault, 'issuedDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormDueDate', defaultMessage: 'Due Date' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'dueDate') ? moment(_.get(editDefault, 'dueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="expenseViewTitleItem" defaultMessage="Additional Item / Service" />
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }}>
          <Table
            columns={columns}
            dataSource={selectItem}
            pagination={false}
            scroll={{ x: 1200 }}
          />
        </div>
        <div style={{ padding: '0px 24px', marginBottom: '24px' }}>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'expenseViewFormRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                <span style={styleText}>{_.get(editDefault, 'remark') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                <div>
                  <FormattedMessage id="expenseViewFormSubtotal" defaultMessage="Subtotal" />
                </div>
                <div>
                  {numberFormatDecimal(subTotal || 0)}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div style={{ marginRight: '46px' }}>
                  <FormattedMessage id="expenseViewFormTax" defaultMessage="Tax" />
                </div>
                <div>
                  <span style={styleText}>{_.get(editDefault, 'tax.tax') || 0} %</span>{" "}<span style={styleText}>{numberFormatDecimal(subTotalTax)}</span>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div>
                  <FormattedMessage id="expenseViewFormSubTotal" defaultMessage="Total" />
                </div>
                <div>
                  {numberFormatDecimal(total)}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="expenseViewTitleAttachment" defaultMessage="Attachment" />
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }}>
          <Table
            columns={columnsAtt}
            dataSource={attachmentData}
            pagination={false}
          />
        </div>
      </Form >
    </div >
  )
}

export default ExpenseDetail
