import { Button, Col, DatePicker, Form, Input, Row, Select, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import CustomTableComponent from '../components/table';
import CollapseCustom from '../../../components/collapse-custom';
import { messageLabel } from '../../../components/message-component';
import _ from 'lodash';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import { AccountReceiveContext } from '../context';
import QuotationModal from '../modal/quotation/quotation-modal';
import moment from 'moment';
import SummaryWaiting from '../components/summary-waiting';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import ActionColumnBilling from '../components/action-column';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'ddd, MMM DD YYYY';

const CanceledQuotation = () => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const { isOpenQuotation, reportDataCanceled, summaryData, customerList } = state;
  const { seIsOpenQuotation, setReportDataCanceled, setIsFetch } = setState;
  const { handleOpenModalQuotation, handleCustomerPopupScroll ,handleOpenModalQuotationTask } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataCanceled;
  const intl = useIntl();

  const handleFieldSort = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, fieldSort: value }));
  };

  const handleOrderSort = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, orderSort: value }));
  };

  const handlePaginationPage = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationPage: value }));
  };

  const handlePaginationSize = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationSize: value }));
  };

  const handleSelectRangePicker = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, rangePickerDate: value }));
  };

  const handleApply = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationPage: 1 }));
    setIsFetch((event) => !event);
  };

  const handleReset = (value) => {
    setReportDataCanceled((prev) => ({
      ...prev,
      invoiceNo: '',
      billingNo: '',
      customerName: undefined,
      rangePickerDate: [],
      paginationPage: 1,
      statusCode: undefined,
    }));
    setIsFetch((event) => !event);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
    },
    {
      title: messageLabel('billingฺBLNo', 'BL No.'),
      dataIndex: 'billingNo',
      key: 'billingNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    // {
    //   title: messageLabel('billingSOVNo', 'SO No.'),
    //   dataIndex: 'invoiceNo',
    //   key: 'invoiceNo',
    //   sorter: true,

    //   width: 170,
    // },
    {
      title: messageLabel('billingCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,

      // width: 200,
    },
    {
      title: messageLabel('billingType', 'Type'),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
    },
    {
      title: messageLabel('billingItems', 'Items'),
      dataIndex: 'totalItem',
      key: 'totalItem',
      sorter: true,
      align: 'right',
    },
    // {
    //   title: messageLabel('billingInvoiceQty', 'Invoice Qty'),
    //   dataIndex: 'totalItem',
    //   key: 'totalItem',
    //   sorter: true,
    //   width: 200,
    //   align: 'right',
    // },

    {
      title: messageLabel('billingTotalAmount', 'Total Amount'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      width: 200,
      align: 'right',
    },
    // {
    //   title: messageLabel('billingPaidAmount', 'Paid Amount'),
    //   dataIndex: 'paidAmount',
    //   key: 'paidAmount',
    //   sorter: true,
    //   width: 200,
    // },
    // {
    //   title: messageLabel('billingAccruedAmount', 'Accrued Amount'),
    //   dataIndex: 'accrued',
    //   key: 'accrued',
    //   sorter: true,
    //   width: 200,
    // },
    {
      title: messageLabel('quotationLabelDueDate', 'Due Date'),
      dataIndex: 'dueDateTxt',
      key: 'dueDateTxt',
      sorter: true,
    },
    {
      title: messageLabel('invoiceLabelTeam', 'Team'),
      dataIndex: 'team',
      key: 'team',
      sorter: true,
      render: (text, record, index) => _.get(record, 'team') || '-',
    },
    {
      title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
      dataIndex: 'issueBy',
      key: 'issueBy',
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: true,
      // render: (text, record, index) =>
      //   _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
    },
  ];

  const setShowColumn = {
    index: true,
    invoiceNo: true,
    customerName: true,
    totalItem: true,
    billingNo: true,
    statusTxt: true,
    totalTxt: true,
    issueBy: true,
    issueDateTxt: true,
    saleOrderNo: true,
    paidAmount: true,
    accrued: true,
    dueDateTxt: true,
    type : true
  };

  const setShowColumnArr = [
    'index',
    'invoiceNo',
    'customerName',
    'totalItem',
    'billingNo',
    'statusTxt',
    'totalTxt',
    'issueBy',
    'issueDateTxt',
    'saleOrderNo',
    'paidAmount',
    'accrued',
    'dueDateTxt',
    'type'
  ];

  const ignoreColumn = ['team'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);

  const advancedFilter = (action, value) => {
    const handleChange = (value, key) => {
      action((prev) => ({ ...prev, [key]: value }));
    };

    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'BLblNoFilter', defaultMessage: 'BL No.' })} req={false} />}>
              <Input
                allowClear
                value={_.get(value, 'billingNo')}
                onChange={(e) => handleChange(e.target.value, 'billingNo')}
                placeholder={intl.formatMessage({ id: 'BLblNoFilterPlaceHolder', defaultMessage: 'Enter BL No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />
              }
            >
              <Select
                allowClear
                showSearch
                value={_.get(value, 'customerName')}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'VoucherCustomerPlaceHolder', defaultMessage: 'Select Customer' })}
                onPopupScroll={handleCustomerPopupScroll}
                onSearch={(e) => handleChange(e, 'customerName')}
                onChange={(e) => handleChange(e, 'customerName')}
              >
                {_.map(customerList, (item, index) => (
                  <Option key={item.customerId} value={item.customerId}>
                    {item.customerName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right', marginTop: '40px' }}>
            <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
              <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
            </Button_02>

            <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handleApply()}>
              <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
            </Button_01>
          </Col>
        </Row>
        {/* <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right', marginTop: '20px' }}>
            <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
              <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
            </Button_02>

            <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handleApply()}>
              <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
            </Button_01>
          </Col>
        </Row> */}
      </Form>
    );
  };

  const contentAction = (record) => {
    // console.log('content', record);
    return (
      <div style={{ display: 'grid' }}>
          {_.get(record, 'type') === 'Task' ? (
          <div>
            <Button style={{ width: 150 }} type="link" ghost onClick={() => handleOpenModalQuotationTask(record, 'canceled')}>
              <FormattedMessage id="btnView" defaultMessage="View" />
            </Button>
          </div>
        ) : (
          <div>
            <Button style={{ width: 150 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'canceled')}>
              <FormattedMessage id="btnView" defaultMessage="View" />
            </Button>
          </div>
        )}
        {/* <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'canceled')}>
            <FormattedMessage id="btnView" defaultMessage="View" />
          </Button>
        </div> */}
        {/* <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'edit')}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div> */}
      </div>
    );
  };

  const summary = {
    balance: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'balance';
    }),
    ondue: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'ondue';
    }),
    overdue: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'overdue';
    }),
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <RangePicker
                placeholder={
                  intl.formatMessage({ id: 'ARStartDate', defaultMessage: 'Start Date' }) +
                  ' ~ ' +
                  intl.formatMessage({ id: 'AREndDate', defaultMessage: 'End Date' })
                }
                value={rangePickerDate}
                format={dateFormat}
                onChange={handleSelectRangePicker}
                allowClear={true}
              />
              <ActionColumnBilling
                columns={columns}
                setShowColumn={setShowColumn}
                newDataColumns={newDataColumns}
                listArrayColumns={listArrayColumns}
                defaultShowColumn={defaultShowColumn}
                newColumns={newColumns}
                setNewDataColumns={setNewDataColumns}
                setListArrayColumns={setListArrayColumns}
                setDefaultShowColumn={setDefaultShowColumn}
                textErrorSelectColumn={textErrorSelectColumn}
                setTextErrorSelectColumn={setTextErrorSelectColumn}
                visible={visible}
                setVisible={setVisible}
                columnMinimum={1}
              />
            </div>
          }
        >
          {advancedFilter(setReportDataCanceled, reportDataCanceled)}
        </CollapseCustom>
      </div>
      <div className="content-body-report">
        <CustomTableComponent
          rowKey={(record) => record.arId}
          columns={newDataColumns}
          dataSource={quotationList}
          scroll={{ x: true }}
          role={true}
          onRow={true}
          total={quotationTotal}
          loading={loading}
          paginationPage={paginationPage}
          setFieldSort={handleFieldSort}
          setOrderSort={handleOrderSort}
          setPaginationSize={handlePaginationSize}
          setPaginationPage={handlePaginationPage}
          contentAction={contentAction}
        />
      </div>
    </div>
  );
};

export default CanceledQuotation;
