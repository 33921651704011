import httpClient from '../../components/axiosClient';

const getItemGroupAndType = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/itemgrouptypeselectpage`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getRequestItemDetail = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/itemrequestpage`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const saveRequestItem = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/itemrequest`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const loadMoreRequestDetail = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/ticketno/loadmore`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}


export { getItemGroupAndType, getRequestItemDetail, saveRequestItem, loadMoreRequestDetail }