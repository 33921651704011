import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Table, Popover, Modal, Select, Input, Icon, Divider, Avatar } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import WarehouseModalMemberTransfer from '../../warehouse-modal/warehouseModalMemberTransfer';
import { getMemberWarehouseAllWhere, deleteMemberWarehouseById } from '../../../../controllers/warehouse/member';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import { messageLabel } from '../../../../components/message-component';
import Button_01 from '../../../../components/v2/button_01';
import { render } from 'less';
import { PageSettings } from '../../../../config/page-settings';
import WarehouseContext from '../../context';

const { Option } = Select;

const WarehouseMember = (props) => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const { warehouseId, setMainTrigger, roleCheck } = props;
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { warehouseManagerList, warehouseDetail } = state;
  const intl = useIntl();
  const ref = useRef();
  const inputRef = useRef();
  const selectRef = useRef();
  const [initial, setInitial] = useState(true);
  const [memberWarehouse, setMemberWarehouse] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [columnKey, setColumnKey] = useState('all');
  const [order, setOrder] = useState('asc');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [trigger, setTrigger] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [inputSearchDebounce, setInputSearchDebounce] = useState('');
  const [selectSearch, setSelectSearch] = useState('all');

  // const [valueSelect, setValueSelect] = useState('all');

  const columns = [
    {
      title: '#',
      select: 'Index',
      align: 'center',
      width: 70,
      key: 'index',
      render: (text, record, index) => (page - 1) * pageSize + (index + 1),
    },
    {
      title: '',
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 80,
      render: (text, record, index) => <Avatar size={32} src={record.profile} />,
    },
    {
      title: messageLabel('warehouseMemberName', 'Name'),
      dataIndex: 'fullname',
      key: 'name',
      sorter: true,
      sortOrder: columnKey === 'name' ? (order === 'asc' ? 'ascend' : 'descend') : false,
    },
    {
      title: messageLabel('warehouseMemberPhone', 'Phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      sortOrder: columnKey === 'phone' ? (order === 'asc' ? 'ascend' : 'descend') : false,
    },
    {
      title: messageLabel('warehouseMemberEmail', 'Email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: columnKey === 'email' ? (order === 'asc' ? 'ascend' : 'descend') : false,
    },
  ];

  useEffect(() => {
    setInitial(true);
  }, [warehouseId]);

  useEffect(() => {
    if (initial) {
      setPage(1);
      setPageSize(10);
      setTotal(0);
      setOrder('asc');
      // setColumnKey('name');
      setInputSearch('');
      setInputSearchDebounce('');
      setSelectSearch('all');
      setInitial(false);
    }
  }, [initial]);

  useEffect(() => {
    if (!initial && !visible) {
      fncGetInitial();
    }
  }, [initial, visible, page, pageSize, order, columnKey, inputSearchDebounce, selectSearch, trigger]);

  const fncGetInitial = async () => {
    setLoading(true);
    let payload = {
      pageNumber: page,
      limit: pageSize,
      orderBy: columnKey,
      orderType: order,
    };
    if (inputSearchDebounce) {
      payload['search'] = {};
      if (selectSearch !== 'all') {
        payload['search'][selectSearch] = inputSearchDebounce;
      } else {
        payload['search']['name'] = inputSearchDebounce;
        payload['search']['phone'] = inputSearchDebounce;
        payload['search']['email'] = inputSearchDebounce;
      }
    }
    const gMemberWarehouse = await getMemberWarehouseAllWhere(payload, warehouseId);
    setMemberWarehouse(gMemberWarehouse.data);
    setTotal(gMemberWarehouse.total);
    setLoading(false);
    console.log(gMemberWarehouse);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    setColumnKey(sorter.columnKey);
    setOrder(sorter.order === 'descend' ? 'desc' : 'asc');
  };

  const handleClickAdd = () => {
    setVisible(true);
  };

  const handleClickOk = () => {
    setVisible(false);
  };

  const handleClickCancel = () => {
    setVisible(false);
  };

  const handleDelete = (memComId) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteMemberWarehouseById(memComId, warehouseId);
          if (response.status) {
            successNotification(response.message);
            setTrigger((current) => !current);
          } else {
            errorNotification(response.message);
          }
        } catch (error) {
          errorNotification('เกิดข้อผิดพลาด');
        }
      },
      onCancel() { },
    });
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
               {checkPermissionAction(`P52PG1C3`, `P52PG1C3A3`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.memComId)}>
                  <FormattedMessage id="btnRemove" defaultMessage="Remove" />
                </Button>
              </div>
               ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleChangeSearch = (value) => {
    setInputSearch(value);
    searchValue(value);
    setPage(1);
  };

  const searchValue = _.debounce((value) => {
    setInputSearchDebounce(value);
  }, 1000);

  const handleChangeSelect = (value) => {
    setSelectSearch(value);
    setPage(1);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div style={{ paddingTop: 14 }}>
      <div className="layout-wh-manager">
        <div>
          <p className="layout-wh-manager-title">
            <FormattedMessage id="warehouseMemberTitle" defaultMessage="Member" />
          </p>
        </div>
        {checkPermissionAction(`P52PG1C3`, `P52PG1C3A2`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
        <div>
          <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ marginRight: 'unset' }} onClick={() => handleClickAdd()}>
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          </Button_01>
        </div>
        ) : null}
      </div>
      <Divider type="horizontal" style={{ marginTop: '12px', marginBottom: '12px' }} />

      <div className="warehouse-layout-details" onMouseLeave={handleMouseLeave}>
        <div style={{ padding: '10px 0' }}>
          <Input
            className="action-report-input action-input"
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
            onChange={(e) => handleChangeSearch(e.target.value)}
            autoComplete="off"
            value={inputSearch}
          />
          <Select
            className="action-report-select-column action-select"
            placeholder={intl.formatMessage({ id: 'hintSelect', defaultMessage: 'Select' })}
            value={selectSearch}
            onSelect={(value) => handleChangeSelect(value)}
          >
            <Option value="all">
              <FormattedMessage id="reportLeaveReportHintSeleteAllColumns" defaultMessage="All columns" />
            </Option>
            <Option value="name">
              <FormattedMessage id="warehouseMemberName" defaultMessage="Name" />
            </Option>
            <Option value="phone">
              <FormattedMessage id="warehouseMemberPhone" defaultMessage="Phone" />
            </Option>
            <Option value="email">
              <FormattedMessage id="warehouseMemberEmail" defaultMessage="Email" />
            </Option>
          </Select>
        </div>
        <Table
          loading={loading}
          rowKey={(record) => record.key}
          columns={columns}
          sizeWidth="lg"
          dataSource={memberWarehouse}
          onChange={handleTableChange}
          pagination={{
            showTotal: showTotal,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            total: total,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (checkPermissionAction(`P52PG1C3`, `P52PG1C3A3`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ) {
                  if (!event.target.href) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                  
                }

              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        {memberWarehouse.length > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>

      <WarehouseModalMemberTransfer warehouseId={warehouseId} visible={visible} onOk={handleClickOk} onCancel={handleClickCancel} setMainTrigger={setMainTrigger} />
    </div>
  );
};

export default WarehouseMember;
