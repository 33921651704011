import { Modal } from 'antd';
import { successNotification, errorNotification } from '../v2/notification';
import { updateStatus } from '../../controllers/quotation/quotation-api';
import _ from 'lodash';


const deleteQuotation = (lang, record, setTriggerApi) => {

  Modal.confirm({
    className: 'holiday-modal-confirm',
    icon: 'none',
    title: lang.title,
    okText: lang.ok,
    okButtonProps: {
      style: {
        width: '96px',
      },
      type: "primary",
    },
    cancelButtonProps: {
      style: {
        width: '96px',
      },
      type: "danger",
    },
    cancelText: lang.cancel,

    centered: true,
    async onOk() {
      const id = _.get(record, 'quotationId');

      const response = await updateStatus(id, 'deleted');
      if (response.status === 200) {
        successNotification(response.message);
        setTriggerApi(current => !current)
      } else {
        errorNotification(response.message);
        setTriggerApi(current => !current)
      }
    },
    onCancel() {
    },
  });
}

export { deleteQuotation };