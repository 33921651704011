import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Switch, InputNumber } from 'antd';
import _, { set } from 'lodash';
import { PageTaskDistance } from '../../config/page-settings';
import { getFromLocation, getCustomerAddressBook, setCustomerAddressBook } from '../../controllers/task/task-distance';
import FromLocation from './from-location';
import ToLocation from './to-location';
import { successNotification } from '../../components/v2/notification';
import NumberFormat from 'react-number-format';
import '../transportation/setting/task-type/css/index.css';
import { PageSettings } from '../../config/page-settings';
import { useIntl } from 'react-intl';

const memComId = localStorage.getItem('memComId');

export default React.memo(
  () => {
    const intl = useIntl();
    const [fromLocationList, setFromLocationList] = useState([]);
    const [fromLocationListSearch, setFromLocationListSearch] = useState([]);
    const [fromLocationSelect, setFromLocationSelect] = useState([]);
    const [fromLocationSearch, setFromLocationSearch] = useState('');
    const [customerListMaster, setCustomerListMaster] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [customerPage, setCustomerPage] = useState(0);
    const [customerPageSize, setCustomerPageSize] = useState(10);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [customerSelect, setCustomerSelect] = useState();
    const [customerSearch, setCustomerSearch] = useState();
    const [customerSearchPage, setCustomerSearchPage] = useState(0);
    const [customerSearchPageSize, setCustomerSearchPageSize] = useState(10);
    const [addressBookList, setAddressBookList] = useState([]);
    const [isCustomerLoadMore, setIsCustomerLoadMore] = useState(true);
    const [isCustomerSearchLoadMore, setIsCustomerSearchLoadMore] = useState(true);
    const [searchAddressBook, setSearchAddressBook] = useState();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [distanceId, setDistanceId] = useState();
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState();
    const [order, setOrder] = useState();
    const [enableEdit, setEnableEdit] = useState(false);
    const [dataUpdate, setDataUpdate] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [toggle, setToggle] = useState(true);

    useEffect(() => {
      const getData = async () => {
        const data = await getFromLocation();
        const setCompanyAddressList = _.map(_.get(data, 'companyAddressList.list'), (o) => {
          return {
            fromId: _.get(o, 'comAddressBookId'),
            fromName: _.get(o, 'addressName'),
          };
        });
        setFromLocationList(setCompanyAddressList);
        setFromLocationListSearch(setCompanyAddressList);
      };

      getData();
    }, []);

    useEffect(() => {
      const getData = async () => {
        let payload = {
          companyAddressId: _.head(fromLocationSelect),
          customerSearch: {
            customerIndexStart: customerPage * customerPageSize + 1,
            customerIndexEnd: customerPage * customerPageSize + 10,
            customerName: customerSearch,
          },
        };
        const data = await getFromLocation(payload);
        const setCustomerListData = _.map(_.get(data, 'customerList.list'), (o) => {
          return setDataCustomer(o);
        });
        const customers = _.uniqBy(_.concat(customerList, setCustomerListData), 'customerId');
        setCustomerList(customers);
        setCustomerListMaster(customers);
        setCustomerLoading(false);
        setIsCustomerLoadMore(_.size(setCustomerListData) > 0 ? true : false);
      };

      if (_.size(fromLocationSelect) > 0 && isCustomerLoadMore) {
        getData();
      }
    }, [fromLocationSelect, customerPage]);

    useEffect(() => {
      const getData = async () => {
        if (!customerSearch) {
          setCustomerList(customerListMaster);
          setCustomerLoading(false);
        } else {
          let payload = {
            companyAddressId: _.head(fromLocationSelect),
            customerSearch: {
              customerIndexStart: customerSearchPage * customerSearchPageSize + 1,
              customerIndexEnd: customerSearchPage * customerSearchPageSize + 10,
              customerName: customerSearch,
            },
          };
          const data = await getFromLocation(payload);
          const setCustomerListData = _.map(_.get(data, 'customerList.list'), (o) => {
            return setDataCustomer(o);
          });
          const customers = _.uniqBy(_.concat(customerList, setCustomerListData), 'customerId');
          setCustomerList(customers);
          setCustomerLoading(false);
          setIsCustomerSearchLoadMore(_.size(setCustomerListData) > 0 ? true : false);
        }
      };

      if (isCustomerSearchLoadMore) {
        getData();
      }
    }, [customerSearch, customerSearchPage]);

    useEffect(() => {
      const getData = async () => {
        let payload = {
          companyAddressId: _.head(fromLocationSelect),
          customerId: customerSelect,
          searchCustomerAddress: searchAddressBook,
          pageNumber: page,
          rowPerPage: rowPerPage,
          sortBy: orderBy,
          sortType: order,
        };
        const data = await getCustomerAddressBook(payload);

        setAddressBookList(_.get(data, 'destinationList') || []);
        setTotalPage(_.get(data, 'rowTotal') || 0);
        setDataUpdate([]);
        setSaveLoading(false);
        setLoading(false);
      };

      if (customerSelect && !enableEdit) {
        getData();
      }
    }, [fromLocationSelect, customerSelect, searchAddressBook, page, rowPerPage, orderBy, order, enableEdit]);

    useEffect(() => {
      if (distanceId) {
      }
    }, [distanceId]);

    const setDataCustomer = (customer) => {
      return {
        customerId: _.get(customer, 'customerId'),
        customerName: _.get(customer, 'name'),
      };
    };

    const handleChangeSearch = _.debounce((value) => {
      setFromLocationSearch(value);
      setFromLocationListSearch(search(fromLocationList, value));
    }, 100);

    const handleChangeSelect = (keys) => {
      if (!_.isMatch(fromLocationSelect, keys)) {
        if (_.head(customerSelect)) {
          setLoading(true);
          setPage(1);
        }
        setEnableEdit(false);
        setDataUpdate([]);
        setAddressBookList([]);
        setFromLocationSelect(keys);
      }
    };

    const search = (items, searchValue) =>
      _.filter(items, (item) => {
        return _.toLower(item.fromName).indexOf(_.toLower(searchValue)) > -1;
      });

    const handleCustomerPopupScroll = (e) => {
      let target = e.target;
      if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
        setCustomerLoading(true);
        if (customerSearch) {
          setCustomerSearchPage(customerSearchPage + 1);
        } else {
          setCustomerPage(customerPage + 1);
        }
      }
    };

    const handleCustomerChange = (value) => {
      if (!value) {
        setEnableEdit(false);
        setPage(1);
        setTotalPage(0);
        setAddressBookList([]);
        setSearchAddressBook();
        setCustomerSelect();
      }
    };

    const handleCustomerChangeSelect = (value) => {
      if (value !== customerSelect) {
        setLoading(true);
        setEnableEdit(false);
        setPage(1);
        setSearchAddressBook();
        setCustomerSearch();
        setCustomerList(customerListMaster);
        setCustomerSelect(value);
      }
    };

    const handleCustomerChangeSearch = (value) => {
      setCustomerLoading(true);
      setIsCustomerSearchLoadMore(true);
      setCustomerList([]);
      setCustomerSearchPage(0);
      setCustomerSearch(value);
    };

    const handleAddressBookSearch = (value) => {
      setLoading(true);
      setEnableEdit(false);
      setPage(1);
      setSearchAddressBook(value);
    };

    const handleChangeStatusAuto = async (checked, data) => {
      let addressBookListClone = [...addressBookList];
      const dataUpdate = {
        companyAddressId: _.head(fromLocationSelect),
        customerAddressId: data.customerAddressId,
        auto: checked,
        distance: _.toNumber(data.distance),
        memComId: memComId,
      };
      const updateAddressBookDistance = await setCustomerAddressBook(dataUpdate);
      if (_.get(updateAddressBookDistance, 'status.code') === 200) {
        successNotification(_.get(updateAddressBookDistance, 'status.message'));
        const findRecord = _.findIndex(addressBookListClone, ['customerAddressId', data.customerAddressId]);
        if (findRecord > -1) {
          addressBookListClone[findRecord].auto = checked;
        }
      }

      setAddressBookList(addressBookListClone);
    };

    const handleClickDistance = (record) => {
      setDistanceId(record);
    };

    const handleBlurDistance = async (distance, data) => {
      let addressBookListClone = [...addressBookList];
      const dataUpdate = {
        companyAddressId: _.head(fromLocationSelect),
        customerAddressId: data.customerAddressId,
        auto: data.auto,
        distance: _.toNumber(distance) || 0,
        memComId: memComId,
      };
      const updateAddressBookDistance = await setCustomerAddressBook(dataUpdate);
      if (_.get(updateAddressBookDistance, 'status.code') === 200) {
        successNotification(_.get(updateAddressBookDistance, 'status.message'));
        const findRecord = _.findIndex(addressBookListClone, ['customerAddressId', data.customerAddressId]);
        if (findRecord > -1) {
          addressBookListClone[findRecord].distance = distance;
        }
      }

      setDistanceId();
      setAddressBookList(addressBookListClone);
    };

    const handleKeyDownNumberDecimal = (e) => {
      let keyCode = e.key.charCodeAt(0);
      const splitValue = _.split(e.target.value, '.');
      if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
        if (keyCode === 46 && e.target.value.indexOf('.') > 1) e.preventDefault();
        if (keyCode === 46) {
          let sumDot = _.sumBy(e.target.value, (o) => {
            return o.charCodeAt(0) === 46 ? 1 : 0;
          });
          if (sumDot >= 1) e.preventDefault();
        }
      } else if (keyCode === 45) {
        e.preventDefault();
      } else {
        e.preventDefault();
      }
      if (_.get(splitValue, '[1]')) {
        if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) e.preventDefault();
      }
    };

    const handlePagination = (page) => {
      setLoading(true);
      setPage(page);
    };

    const handleSizeChange = (current, size) => {
      setLoading(true);
      setRowPerPage(size);
      setPage(current);
    };

    const handleChangeTable = (pagination, filters, sorter, extra) => {
      const sOrderBy = _.get(sorter, 'field');
      const sOrder = _.get(sorter, 'order');

      setLoading(true);
      setEnableEdit(false);
      setDataUpdate([]);
      if (sOrder) {
        setOrderBy(sOrderBy);
        setOrder(sOrder === 'ascend' ? 'asc' : 'desc');
      } else {
        setOrderBy();
        setOrder();
      }
    };

    const handleEnableEdit = (status) => {
      setEnableEdit(status);
    };

    const handleChangeDataOnRow = (valueType, value, record) => {
      let dataClone = [...dataUpdate];
      let dataMaster = [...addressBookList]
      console.log("dataMaster", dataMaster)
      const findIndex = _.findIndex(dataClone, ['customerAddressId', record.customerAddressId]);
      const findIndexMaster = _.findIndex(dataMaster, ['customerAddressId', record.customerAddressId]);

      if (findIndex > -1) {
        if (valueType === 'auto') {
          dataClone[findIndex].auto = value;
          dataMaster[findIndexMaster].auto = value;
        } else if (valueType === 'distance') {
          dataClone[findIndex].distance = _.toNumber(value) || 0;
        } else if (valueType === 'heavyCar') {
          dataClone[findIndex].heavyCar = _.toNumber(value) || 0;
          if (_.toNumber(value) <= 0) {
            dataClone[findIndex].auto = true;
            dataMaster[findIndexMaster].auto = true;
          } else {
            dataClone[findIndex].auto = false;
            dataMaster[findIndexMaster].auto = false;
          }
        } else if (valueType === 'lightCar') {
          dataClone[findIndex].lightCar = _.toNumber(value) || 0;
        } else if (valueType === 'shippingCost') {
          dataClone[findIndex].shippingCost = _.toNumber(value) || 0;
        } else if (valueType === 'travelExpenses') {
          dataClone[findIndex].travelExpenses = _.toNumber(value) || 0;
        } else if (valueType === 'statusCalcKm') {
          dataClone[findIndex].statusCalcKm = value;
        }
      } else {
        dataClone.push({
          customerAddressId: record.customerAddressId,
          auto: valueType === 'heavyCar' && _.toNumber(value) > 0 ? false : valueType === 'auto' ? value : record.auto,
          distance: valueType === 'distance' ? _.toNumber(value) || 0 : record.distance,
          heavyCar: valueType === 'heavyCar' ? _.toNumber(value) || 0 : record.heavyCar,
          lightCar: valueType === 'lightCar' ? _.toNumber(value) || 0 : record.lightCar,
          shippingCost: valueType === 'shippingCost' ? _.toNumber(value) || 0 : record.shippingCost,
          travelExpenses: valueType === 'travelExpenses' ? _.toNumber(value) || 0 : record.travelExpenses,
          statusCalcKm: valueType === 'statusCalcKm' ? value : record.statusCalcKm,
        });
        if (valueType === 'heavyCar' && _.toNumber(value) > 0) {
          dataMaster[findIndexMaster].auto = false;
        } 
      }
      setDataUpdate(dataClone);
      setAddressBookList(dataMaster)
    };

    console.log("address",addressBookList,dataUpdate)

    const handleSaveCustomerAddressBook = async () => {
      if (_.size(dataUpdate) > 0) {
        setSaveLoading(true);
        setLoading(true);

        const updateAll = await Promise.all(
          _.map(dataUpdate, async (o) => {
            const dataUpdate = {
              companyAddressId: _.head(fromLocationSelect),
              customerAddressId: o.customerAddressId,
              auto: o.auto,
              distance: o.distance,
              heavyCar: o.heavyCar,
              lightCar: o.lightCar,
              shippingCost: o.shippingCost,
              travelExpenses: o.travelExpenses,
              statusCalcKm: o.statusCalcKm,
              memComId: memComId,
            };
            const updateAddressBookDistance = await setCustomerAddressBook(dataUpdate);
            if (_.get(updateAddressBookDistance, 'status.code') === 200) {
              return 1;
            } else {
              return 0;
            }
          })
        );

        const message = `${_.size(dataUpdate) === _.sum(updateAll) ? _.sum(updateAll) : _.sum(updateAll) + '/' + _.size(dataUpdate)
          } Updated`;
        successNotification(message);
      }
      setEnableEdit(false);
    };

    const handleToggle = () => {
      setToggle(!toggle);
    };

    const columns = [
      {
        key: 'index',
        title: '#',
        dataIndex: 'index',
        width: '5%',
        align: 'center',
        render: (text, record, index) => record.index,
      },
      {
        key: 'address',
        title: intl.formatMessage({ id: `taskDistanceColumnAddress`, defaultMessage: 'Address' }),
        dataIndex: 'addressName',
        width: '31%',
        align: 'left',
        sorter: true,
        ellipsis: true,
        render: (text, record, index) => {
          const address = `${record.addressName} · ${record.address}`;
          return address;
        },
      },
      {
        key: 'auto',
        title: intl.formatMessage({ id: `taskDistanceColumnGPS`, defaultMessage: 'GPS' }),
        dataIndex: 'auto',
        width: '7%',
        align: 'center',
        render: (text, record, index) =>
          enableEdit ? (
            <Switch
              defaultChecked={record.auto}
              checked={record.auto}
              // onChange={(checked) => handleChangeStatusAuto(checked, record)}
              onChange={(checked) => handleChangeDataOnRow('auto', checked, record)}
            />
          ) : (
            <Switch checked={record.auto} disabled={!enableEdit} />
          ),
      },
      // {
      //   key: 'distance',
      //   title: intl.formatMessage({ id: `taskDistanceColumnDistance`, defaultMessage: 'Distance (km)' }),
      //   dataIndex: 'distance',
      //   width: '14%',
      //   align: 'center',
      //   render: (text, record, index) => {
      //     return enableEdit ?
      //       <InputNumber
      //         className='task-type-item-inputNumber'
      //         defaultValue={record.distance}
      //         min={0}
      //         step={0.01}
      //         max={999999}
      //         // onBlur={(e) => handleBlurDistance(e.target.value, record)}
      //         onChange={(value) => handleChangeDataOnRow('distance', value, record)}
      //         onKeyDown={handleKeyDownNumberDecimal} style={{ width: '80%' }} />
      //       :
      //       <label
      //         style={{ color: enableEdit ? '#1D3557' : '#BCC5D3' }}
      //         onClick={record.auto ? null : () => handleClickDistance(record)}>
      //         <NumberFormat value={record.distance} displayType={'text'} thousandSeparator={true} />
      //       </label>
      //   }
      // },
      {
        key: 'heavyCar',
        title: intl.formatMessage({ id: `taskDistanceColumnDistance`, defaultMessage: 'Distance' }),
        dataIndex: 'heavyCar',
        width: '14%',
        align: 'center',
        render: (text, record, index) => {
          return enableEdit ? (
            <InputNumber
              className="task-type-item-inputNumber"
              defaultValue={record.heavyCar}
              min={0}
              step={0.01}
              max={999999}
              // onBlur={(e) => handleBlurDistance(e.target.value, record)}
              onChange={(value) => handleChangeDataOnRow('heavyCar', value, record)}
              onKeyDown={handleKeyDownNumberDecimal}
              style={{ width: '80%' }}
            />
          ) : (
            <label style={{ color: enableEdit ? '#1D3557' : '#BCC5D3' }} onClick={record.auto ? null : () => handleClickDistance(record)}>
              <NumberFormat value={parseFloat(record.heavyCar).toFixed(2)} displayType={'text'} thousandSeparator={true} />
            </label>
          );
        },
      },
      // {
      //   key: 'lightCar',
      //   title: intl.formatMessage({ id: `taskDistanceColumnLightCar`, defaultMessage: 'Empty (km)' }),
      //   dataIndex: 'lightCar',
      //   width: '14%',
      //   align: 'center',
      //   render: (text, record, index) => {
      //     return enableEdit ? (
      //       <InputNumber
      //         className="task-type-item-inputNumber"
      //         defaultValue={record.lightCar}
      //         min={0}
      //         step={0.01}
      //         max={999999}
      //         // onBlur={(e) => handleBlurDistance(e.target.value, record)}
      //         onChange={(value) => handleChangeDataOnRow('lightCar', value, record)}
      //         onKeyDown={handleKeyDownNumberDecimal}
      //         style={{ width: '80%' }}
      //       />
      //     ) : (
      //       <label style={{ color: enableEdit ? '#1D3557' : '#BCC5D3' }} onClick={record.auto ? null : () => handleClickDistance(record)}>
      //         <NumberFormat value={parseFloat(record.lightCar).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      //       </label>
      //     );
      //   },
      // },
      // {
      //   key: 'shippingCost',
      //   title: intl.formatMessage({ id: `taskDistanceColumnFreight`, defaultMessage: 'Freight' }),
      //   dataIndex: 'shippingCost',
      //   width: '14%',
      //   align: 'center',
      //   render: (text, record, index) => {
      //     return enableEdit ? (
      //       <InputNumber
      //         className="task-type-item-inputNumber"
      //         defaultValue={record.shippingCost}
      //         min={0}
      //         step={0.01}
      //         max={999999}
      //         // onBlur={(e) => handleBlurDistance(e.target.value, record)}
      //         onChange={(value) => handleChangeDataOnRow('shippingCost', value, record)}
      //         onKeyDown={handleKeyDownNumberDecimal}
      //         style={{ width: '80%' }}
      //       />
      //     ) : (
      //       <label style={{ color: enableEdit ? '#1D3557' : '#BCC5D3' }} onClick={record.auto ? null : () => handleClickDistance(record)}>
      //         <NumberFormat value={parseFloat(record.shippingCost).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      //       </label>
      //     );
      //   },
      // },
      // {
      //   key: 'travelExpenses',
      //   title: intl.formatMessage({ id: `taskDistanceColumnAllowance`, defaultMessage: 'Allowance' }),
      //   dataIndex: 'travelExpenses',
      //   width: '14%',
      //   align: 'center',
      //   render: (text, record, index) => {
      //     return enableEdit ? (
      //       <InputNumber
      //         className="task-type-item-inputNumber"
      //         defaultValue={record.travelExpenses}
      //         min={0}
      //         step={0.01}
      //         max={999999}
      //         // onBlur={(e) => handleBlurDistance(e.target.value, record)}
      //         onChange={(value) => handleChangeDataOnRow('travelExpenses', value, record)}
      //         onKeyDown={handleKeyDownNumberDecimal}
      //         style={{ width: '80%' }}
      //       />
      //     ) : (
      //       <label style={{ color: enableEdit ? '#1D3557' : '#BCC5D3' }} onClick={record.auto ? null : () => handleClickDistance(record)}>
      //         <NumberFormat value={parseFloat(record.travelExpenses).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      //       </label>
      //     );
      //   },
      // },
      // {
      //   key: 'statusCalcKm',
      //   title: intl.formatMessage({ id: `taskDistanceColumnStatusCalcKm`, defaultMessage: 'x km' }),
      //   dataIndex: 'statusCalcKm',
      //   width: '11%',
      //   align: 'left',
      //   render: (text, record, index) =>
      //     enableEdit ?
      //       <Switch
      //         defaultChecked={record.statusCalcKm}
      //         // onChange={(checked) => handleChangeStatusAuto(checked, record)}
      //         onChange={(checked) => handleChangeDataOnRow('statusCalcKm', checked, record)}
      //       />
      //       :
      //       <Switch checked={record.statusCalcKm} disabled={!enableEdit} />
      // }
    ];

    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <PageTaskDistance.Provider
            value={{
              columns,
              fromLocationList,
              fromLocationListSearch,
              fromLocationSelect,
              fromLocationSearch,
              customerList,
              customerLoading,
              customerSelect,
              customerSearch,
              addressBookList,
              searchAddressBook,
              loading,
              page,
              totalPage,
              rowPerPage,
              enableEdit,
              saveLoading,
              toggle,
              handleChangeSearch,
              handleChangeSelect,
              handleCustomerPopupScroll,
              handleCustomerChange,
              handleCustomerChangeSelect,
              handleCustomerChangeSearch,
              handleAddressBookSearch,
              handlePagination,
              handleSizeChange,
              handleChangeTable,
              handleEnableEdit,
              handleSaveCustomerAddressBook,
              handleToggle,
            }}
          >
            {checkPermissionAction('P6S5PG1C1', 'P6S5PG1C1A1') ? (
              <Row gutter={[16, 16]}>
                <Col span={toggle ? 5 : 0}>
                  <FromLocation />
                </Col>
                <Col span={toggle ? 19 : 24}>
                  <ToLocation />
                </Col>
              </Row>
            ) : null}
          </PageTaskDistance.Provider>
        )}
      </PageSettings.Consumer>
    );
  },
  (prev, next) => {
    return _.isMatch(prev, next);
  }
);

