import React, { useContext, useEffect } from 'react';
import { Form, Row, Col, Input, Divider, Icon, Select, DatePicker } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import NavigatorSVG from '../../../image/navigator.svg';
import ArrowSVG from '../../../image/arrow_location.svg';
import { CreateTaskContext } from './create-task-context';
import './css/index.css';
import _ from 'lodash';
import { getDisTance, getMemberAssignee } from '../../../../controllers/task/create-task';
import moment from 'moment';

const { Option } = Select;

const TabInformation = (props) => {
  const { form } = props;
  const intl = useIntl();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { CreateState, CreateSetState, CreateFNC } = useContext(CreateTaskContext);

  const ref = _.get(CreateState, 'customFieldData');

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const NavigatorIcon = () => <img src={NavigatorSVG} style={{ marginBottom: '10px', marginLeft: '3.4px' }}></img>;

  useEffect(() => {
    const taskType = getFieldValue('taskType');
    if (taskType) {
      const filterData = _.get(CreateState, 'taskTypeData').filter((item) => {
        return item.taskTypeId === taskType;
      });
      CreateSetState.setTaskTypeId(taskType);
    }
  }, [getFieldValue('taskType')]);

  useEffect(() => {
    const customerId = getFieldValue('customer');
    if (customerId) {
      const filterData = _.get(CreateState, 'customerData').filter((item) => {
        return item.customerId === customerId;
      });
      CreateSetState.setCustomerId(customerId);
      CreateSetState.setCustomerName(_.get(filterData, '[0].customerName'));
      setFieldsValue({
        ['contact']: undefined,
      });
      setFieldsValue({
        ['phone']: undefined,
      });
    }
  }, [getFieldValue('customer')]);

  useEffect(() => {
    const contactId = getFieldValue('contact');
    if (contactId) {
      const filterData = _.get(CreateState, 'contactData').filter((item) => {
        return item.customerContactId === contactId;
      });
      setFieldsValue({
        ['phone']: _.get(filterData, '[0].customerContactPhone'),
      });
    }
  }, [getFieldValue('contact')]);

  useEffect(() => {
    if (_.get(CreateState, 'newCustomer')) {
      CreateSetState.setCustomerId(_.get(CreateState, 'newCustomer.customerId'));
      CreateSetState.setCustomerName(_.get(CreateState, 'newCustomer.customerName'));
      setTimeout(() => {
        setFieldsValue({
          ['customer']: _.get(CreateState, 'newCustomer.customerId'),
        });
      }, 2000);
    }
  }, [_.get(CreateState, 'newCustomer.customerId')]);

  useEffect(() => {
    if (_.get(CreateState, 'newContact')) {
      setTimeout(() => {
        setFieldsValue({
          ['contact']: _.get(CreateState, 'newContact'),
        });
      }, 1000);
    }
  }, [_.get(CreateState, 'newContact')]);

  const polyLineGoogleMap = () => {
    const toLocation = getFieldValue('toLocation');
    if (toLocation) {
      const filterTo = _.get(CreateState, 'toLocationData').filter((item) => {
        return item.customerAddressBookId === toLocation;
      });
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${parseFloat(_.get(filterTo, '[0].lat'))}, ${parseFloat(
          _.get(filterTo, '[0].lng')
        )}`,
        '_blank'
      );
    }
  };

  useEffect(() => {
    if (_.get(CreateState, 'newCustomerAddress')) {
      setTimeout(() => {
        setFieldsValue({
          ['toLocation']: _.get(CreateState, 'newCustomerAddress.customerAddressBookId'),
        });
      }, 1000);
    }
  }, [_.get(CreateState, 'newCustomerAddress.customerAddressBookId')]);

  useEffect(() => {
    getMember();
  }, [getFieldValue('taskType'), getFieldValue('fromLocation'), CreateState.orgId, getFieldValue('startDate'), getFieldValue('dueDate')]);

  const getMember = async () => {
    const taskTypeId = getFieldValue('taskType');
    const fromLocation = getFieldValue('fromLocation');
    const startDate = getFieldValue('startDate');
    const dueDate = getFieldValue('dueDate');
    const filterFrom =
      CreateState.fromLocationData &&
      CreateState.fromLocationData.filter((item) => {
        return item.comAddressBookId === fromLocation;
      });
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss').toString();

    if (taskTypeId && CreateState.orgId) {
      const body = {
        taskTypeId: taskTypeId ? taskTypeId : '',
        orgId: CreateState.orgId ? CreateState.orgId : '',
        latDeliver: _.get(filterFrom, '[0].lat') || '',
        lngDeliver: _.get(filterFrom, '[0].lng') || '',
        planStart: formateStartDate ? formateStartDate : '',
        planFinish: formateDueDate ? formateDueDate : '',
      };
      const responseMember = await getMemberAssignee(body, 'workdesk');
      CreateSetState.setMember(_.get(responseMember, 'data.data'));
    }
  };

  getFieldDecorator('keys', { initialValue: [] });
  const formItems =
    ref &&
    ref.map((k, index) => {
      return (
        <div>
          {
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={k.displayTitle} req={k.required && k.required !== null ? true : false} />}
                required={false}
                key={k.entityToField}
              >
                {getFieldDecorator(`${k.entityToField}`, {
                  rules: [
                    {
                      required: k.required && k.required !== null ? true : false,
                      whitespace: true,
                      message: `${intl.formatMessage({ id: 'createTaskMntEnter', defaultMessage: 'Enter' })} ${k.displayTitle}`,
                    },
                  ],
                })(
                  <Input
                    placeholder={`${intl.formatMessage({ id: 'createTaskMntEnter', defaultMessage: 'Enter' })} ${k.displayTitle}`}
                    autoComplete="off"
                  />
                )}
              </Form.Item>
            </Col>
          }
        </div>
      );
    });

  const validatorStartDate = (rule, value, callback) => {
    const startDate = value;
    const dueDate = getFieldValue(`dueDate`);
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm')
      .toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm')
      .toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  const validatorDueDate = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const dueDate = value;
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm')
      .toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm')
      .toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  return (
    <div className="create-task-tab-information">
      <Form>
        <Row>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLbTaskNo', defaultMessage: 'Task No.' })} req={false} />}
              >
                {getFieldDecorator('taskNo', {
                  rules: [
                    {
                      required: false,
                      message: intl.formatMessage({ id: 'createTaskMntLbTaskNo', defaultMessage: 'Task No.' }),
                    },
                  ],
                })(
                  <Input
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntLbTaskNo', defaultMessage: 'Task No.' })}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLbTaskName', defaultMessage: 'Task Name' })} req={true} />}
              >
                {getFieldDecorator('taskName', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLTaskName', defaultMessage: 'Please enter a Task Name' }),
                    },
                  ],
                })(
                  <Input
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHTaskName', defaultMessage: 'Enter a Task Name' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBTaskType', defaultMessage: 'Task Type' })} req={true} />}
              >
                {getFieldDecorator('taskType', {
                  initialValue: _.size(CreateState.taskTypeData) === 1 ? _.get(CreateState, 'taskTypeData[0].taskTypeId') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLTaskType', defaultMessage: 'Please select a Task Type' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHTaskType', defaultMessage: 'Select a Task Type' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {CreateState.taskTypeData &&
                      CreateState.taskTypeData.map((item) => <Option key={item.taskTypeId}>{item.taskTypeName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBPriority', defaultMessage: 'Priority' })} req={true} />}
              >
                {getFieldDecorator('priority', {
                  initialValue: _.get(CreateState, 'defaultPriority') ? _.get(CreateState, 'defaultPriority.priorityId') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntPHPriority', defaultMessage: 'Select a Priority' }),
                    },
                  ],
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHPriority', defaultMessage: 'Select a Priority' })}
                  >
                    {CreateState.priorityData &&
                      CreateState.priorityData.map((item) => (
                        <Option key={item.priorityId}>
                          <img src={item.icon} style={{ width: '7px', height: '7px' }}></img> {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={
                  <LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBReference', defaultMessage: 'Reference' })} req={false} />
                }
              >
                {getFieldDecorator('reference', {
                  rules: [
                    {
                      required: false,
                      message: intl.formatMessage({ id: 'createTaskMntLBReference', defaultMessage: 'Reference' }),
                    },
                  ],
                })(
                  <Input
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntLBReference', defaultMessage: 'Reference' })}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={
                  <LabeRequire
                    text={intl.formatMessage({ id: 'createTaskMntLBCustomer', defaultMessage: 'Select a Customer' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('customer', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLCustomer', defaultMessage: 'Please select a Customer' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHCustomer', defaultMessage: 'Select a Customer' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => CreateSetState.setVisibleCustomer(true)}
                        >
                          <Icon type="plus" className="customer-icon-plus" />
                          <span className="text-add-customer"> Add Customer</span>
                        </div>
                      </div>
                    )}
                  >
                    {CreateState.customerData &&
                      CreateState.customerData.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBContact', defaultMessage: 'Contact' })} req={true} />}
              >
                {getFieldDecorator('contact', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLContact', defaultMessage: 'Please select a Contact' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHContact', defaultMessage: 'Select a Contact' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={getFieldValue('customer') ? false : true}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            CreateSetState.setVisibleContact(true);
                          }}
                        >
                          <Icon type="plus" className="customer-icon-plus" />
                          <span className="text-add-customer">
                            <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                            <FormattedMessage id="customerContactCardHeader" defaultMessage="Contact" />
                          </span>
                        </div>
                      </div>
                    )}
                  >
                    {CreateState.contactData &&
                      CreateState.contactData.map((item) => <Option key={item.customerContactId}>{item.customerContactName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBPhone', defaultMessage: 'Phone' })} req={false} />}
              >
                {getFieldDecorator('phone', {
                  rules: [
                    {
                      required: false,
                      message: intl.formatMessage({ id: 'createTaskMntLBPhone', defaultMessage: 'Phone' }),
                    },
                  ],
                })(
                  <Input
                    readOnly={true}
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntLBPhone', defaultMessage: 'Phone' })}
                    disabled={getFieldValue('contact') ? false : true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={
                  <LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBStartDate', defaultMessage: 'Start Date' })} req={false} />
                }
              >
                {getFieldDecorator('startDate', {
                  rules: [
                    {
                      required: getFieldValue('dueDate') ? true : false,
                      message: intl.formatMessage({ id: 'createTaskMntPHStartDate', defaultMessage: 'Select Start Date and Time' }),
                    },
                    {
                      validator: validatorStartDate,
                    },
                  ],
                })(
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHStartDate', defaultMessage: 'Select Start Date and Time' })}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBEndDate', defaultMessage: 'Due Date' })} req={false} />}
              >
                {getFieldDecorator('dueDate', {
                  rules: [
                    {
                      required: getFieldValue('startDate') ? true : false,
                      message: intl.formatMessage({ id: 'createTaskMntPHEndDate', defaultMessage: 'Select Due Date and Time' }),
                    },
                    {
                      validator: validatorDueDate,
                    },
                  ],
                })(
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHEndDate', defaultMessage: 'Select Due Date and Time' })}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            {_.size(ref) === 1 ? <Row gutter={[24]}>{formItems}</Row> : null}
          </Row>
          {_.size(ref) > 1 ? <Row gutter={[24]}>{formItems}</Row> : null}
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBRemark', defaultMessage: 'Remark' })} req={false} />}
              >
                {getFieldDecorator(
                  'remark',
                  {}
                )(
                  <Input
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHRemark', defaultMessage: 'Enter Remark' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Divider type="horizontal" style={{ margin: '24px 0px' }} />
            <div style={{ paddingBottom: '5px' }}>
              <span className="create-task-tab-text-address">
                <FormattedMessage id="createTaskMntTitleAddress" defaultMessage="Address" />{' '}
              </span>
              <Icon component={NavigatorIcon} className="create-task-tab-text-icon" onClick={polyLineGoogleMap} />
            </div>
            <Row>
              <Col span={10}>
                <Form.Item
                  className="create-task-tab-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'createTaskMntLBDestination', defaultMessage: 'Destination' })}
                      req={true}
                    />
                  }
                >
                  <Row>
                    <Col span={1}>
                      <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
                    </Col>
                    <Col span={22}>
                      {getFieldDecorator('toLocation', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'createTaskMntVLDestination',
                              defaultMessage: 'Please select a Destination Location',
                            }),
                          },
                        ],
                      })(
                        <Select
                          placeholder={intl.formatMessage({
                            id: 'createTaskMntPHDestination',
                            defaultMessage: 'Select a Destination Location',
                          })}
                          disabled={getFieldValue('customer') ? false : true}
                          className="create-task-tab-information-select-location"
                          optionLabelProp="label"
                          dropdownRender={(menu) => (
                            <div>
                              {menu}
                              <Divider style={{ margin: '4px 0' }} />
                              <div
                                style={{ padding: '4px 8px', cursor: 'pointer' }}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => {
                                  CreateSetState.setVisibleAddCustomerAddress(true);
                                }}
                              >
                                <div>
                                  <Icon type="plus" className="text-add-address" />
                                  <span className="text-add-address">
                                    <FormattedMessage id={`monitorCreateTaskTxtAddLocation`} defaultMessage="Add Location" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        >
                          {CreateState.toLocationData &&
                            CreateState.toLocationData.map((item) => (
                              <Option
                                className="create-task-tab-information-select-option"
                                style={{ color: '#1D3557' }}
                                key={item.customerAddressBookId}
                                label={`${item.addressName} · ${item.address}`}
                              >
                                <Row>
                                  <Col span={20} className="qutation-setting-text-select">
                                    <span>{item.addressName}</span>
                                  </Col>
                                  <Col span={4}>
                                    <span
                                      onClick={() => {
                                        CreateSetState.setVisibleEditCustomerAddress(true);
                                        CreateSetState.setEditTolocationData(item);
                                      }}
                                    >
                                      <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                                    </span>
                                  </Col>
                                </Row>
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Row>
      </Form>
    </div>
  );
};

const TabInfo = Form.create({
  name: 'modal_form',
})(TabInformation);

export default TabInfo;
