import { Card, Tabs, Table, Button, Icon } from 'antd';
import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ActionReportV5 from '../../../components/action-report/action-report-v5'
import _, { debounce } from 'lodash';
import moment from 'moment';
import './css/index.css'
import styled from 'styled-components';
import ModalTaskDetail from '../../../components/modal-task-detail/index'
import IconExcel from '../../../components/image/excel.svg';

const { TabPane } = Tabs;

export default React.memo((props) => {
  const { value, fnc } = props
  const [visibleModalTask, setVisibleModalTask] = useState(false)
  const [taskDetailData, setTaskDetailData] = useState()
  const { tabStatusData,
    tableData,
    fliterByColumn,
    orgTeamIdArr,
    fieldChang,
    nameStatus,
    fromDate,
    toDate,
    selectKeysTable,
    paginationPage,
    paginationSize,
    nameStatusCode,
    selectedRowKeys,
    defaultDate,
    tableLoding,
    tabChange,
    textValue,
  } = value

  const {
    setTabChange,
    setFliterByColumn,
    setOrgTeamIdArr,
    setFieldChang,
    setNameStatus,
    setFromDate,
    setToDate,
    setSelectKeysTable,
    setPaginationPage,
    setPaginationSize,
    handleClickStatus,
    setNameStatusCode,
    setSelectedRowKeys,
    setSelectAllRow,
    setDefaultDate,
    handleExportExcel,
    setTextValue,
  } = fnc

  const intl = useIntl()
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      // fixed: 'left',
      width: '5%',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnsTaskNo', defaultMessage: 'Task No' }),
      dataIndex: 'taskNo',
      key: 'taskNo',
      width: '20%',
      render: (text, record, index) =>
        <div
          onClick={() => {
            setVisibleModalTask(true)
            setTaskDetailData(record)
          }}
          style={{ cursor: 'pointer' }}>
          <span>{record.taskNo}</span>
        </div>
    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnsCustomer', defaultMessage: 'Customer' }),
      dataIndex: 'customer',
      key: 'customer',
      width: '20%',
    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnsAssignee', defaultMessage: 'Assignee' }),
      dataIndex: 'assignee',
      key: 'assignee',
      width: '20%',
    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnsTaskType', defaultMessage: 'Task Type' }),
      dataIndex: 'taskType',
      key: 'taskType',
      width: '15%',
    },
    {
      title: intl.formatMessage({ id: 'travelExpenseColumnsCompletedDate', defaultMessage: 'Completed Date' }),
      dataIndex: 'completedDate',
      key: 'completedDate',
      width: '20%',
    },
  ];



  const ignoreSearchColumns = ['index', 'completedDate'];
  const getRowData = _.get(tableData, 'rows')


  const TotalItem = styled.div`
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
`;
  const onChangeSearch = (value) => {
    setTextValue(value)
    setTimeout(() => {
      setFieldChang(value);
    }, 200);
  };

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const handleChangeTeam = (value) => {
    let dataCreate = [];
    value.forEach((element) => {
      dataCreate.push((element).toString());
    });
    const orgIdArr = dataCreate.toString();
    setOrgTeamIdArr(dataCreate);
  };

  const onSelectRangePicker = (date, dateString) => {
    setDefaultDate(date)
    setFromDate(moment(date[0]).format("YYYY-MM-DD"));
    setToDate(moment(date[1]).format("YYYY-MM-DD"));
  }

  const handlePagination = (page) => {
    setPaginationPage(page);
  }

  const showTotal = () => {
    return <span style={{ fontSize: '13px' }}><FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " /></span>
  };

  const iconExcel = () => (
    <img className="monitor-icon-excel" src={IconExcel} />
  );


  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  }




  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectKeysTable(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
    },
    onSelectAll: (selected, selectedRows) => {
      setSelectAllRow(selected)
    },
    getCheckboxProps: record => ({
    }),
    selectedRowKeys,
  };

  const handleClickTab = (key) => {
    setTabChange(key)
    setSelectedRowKeys([])
    setNameStatus()
  }


  return (
    <Card className='travel-expense-card-status-height'>
      {_.size(tabStatusData) !== 0 ?(
        <Tabs onTabClick={handleClickTab}
          className='travel-expense-card-tabs'
          // activeKey={tabChange}
          tabBarExtraContent={
            <Button
              className="travel-expense-card-export-excel"
              type="link"
              onClick={() => handleExportExcel(getRowData)}
              disabled={_.size(getRowData) !== 0 ? false : true}
            >
              <FormattedMessage id="btnExport" defaultMessage="Export" /> {" "}
              <Icon component={iconExcel} />
            </Button>}
        >
          {
            tabStatusData && tabStatusData.map(item => (
              <TabPane tab={intl.formatMessage({ id: item.nameCount, defaultMessage: item.nameCount })} key={item.statusCode}>
                <div className='travel-expense-card-status-table'>
                  <div style={{ paddingBottom: '16px' }}>
                    <ActionReportV5
                      value={{
                        columns,
                        ignoreSearchColumns,
                        fliterByColumn,
                        orgTeamIdArr,
                        fieldChang,
                        nameStatus,
                        selectKeysTable,
                        nameStatusCode,
                        defaultDate,
                        textValue,
                      }}
                      fnc={{
                        onChangeSearch,
                        onFliterByColumn,
                        handleChangeTeam,
                        setNameStatus,
                        onSelectRangePicker,
                        handleClickStatus,
                        setNameStatusCode,
                      }}
                      tabStatusData={tabStatusData}
                    />
                  </div>
                  <Table
                    className='table-travel-expense'
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={_.get(tableData, 'rows')}
                    key={item => item.taskId}
                    scroll={{ y: `calc(100vh - 370px)` }}
                    loading={tableLoding}
                    pagination={{
                      total: _.get(tableData, 'totalNoLimit'),
                      showTotal: showTotal,
                      current: paginationPage,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      showSizeChanger: true,
                      locale: { items_per_page: '' },
                      onChange: handlePagination,
                      onShowSizeChange: handleSizeChange,
                    }}
                  />
                  {_.size(tableData, 'rows') !== 0 ?
                    <TotalItem>
                      <span style={{ fontSize: '13px' }}>
                        <FormattedMessage id="lblTotal" defaultMessage="Total" />
                        {` ${_.get(tableData, 'totalNoLimit') || 0} `}
                        <FormattedMessage id="lblitems" defaultMessage="items" />
                      </span>
                    </TotalItem>
                    : null
                  }
                </div>
              </TabPane>
            ))
          }
        </Tabs>
      ) : null }

      <ModalTaskDetail
        visible={visibleModalTask}
        setVisible={setVisibleModalTask}
        taskData={taskDetailData}
        setTaskData={setTaskDetailData}
      />

    </Card>
  )
}, (prev, next) => {
  //  return _.isMatch(_.get(prev, 'value.assigneeData'), _.get(next, 'value.assigneeData')) &&
  //  _.isMatch(_.get(prev, 'value.teamData'), _.get(next, 'value.teamData'))
})