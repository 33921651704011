import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import _ from 'lodash';

export default (props) => {
  const theme = {
    space: {
      sm: '8px',
      md: '16px',
    },
    btnfontsize: {
      sm: '13px',
      md: '14px',
      lg: '16px',
    },
    btnwidth: {
      wd_df: '96px',
      wd_md: '50%',
      wd_lg: '100%',
      wd_at: 'auto',
    },
    fontColor: {
      fc_orange: '#1D3557',
      fc_black: '#000000',
      fc_white: '#FFFFFF	',
    },
    marginStyle: {
      mg_df: '0px 10px 0px 10px',
      mg_md: '0px 8px 0px 8px',
      mg_cs1: '0px 5px 0px 10px',
      mg_cs2: '0px 10px 0px 5px',
      mg_cs3: '0px 0px 0px 10px',
    },
  };

  const Buttons = styled(Button)`
    font-size: 13px;
    color: ${(props) => (_.isString(props.fontColor) ? theme.fontColor[props.fontColor] : '#1D3557')};
    margin: ${props.styleMargin ? theme.marginStyle[props.styleMargin] : theme.marginStyle.mg_df};
    padding: 0.25em 1em;
    border-radius: 2px;
    font-weight: 600;
    font-stretch: normal;
  `;

  return <Buttons {...props}>{props.children}</Buttons>;
};
