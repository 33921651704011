import httpClient from '../../components/axiosClient';

const handleCreateSkill = async (name, description) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    name: name,
    description: description
  }
  try {
    const response = await httpClient.post(`/v3/resource/manager/company/${comCode}/skill-group/create`, body);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

const handleEditSkill = async (name, description, skillId) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    name: name,
    description: description
  }
  try {
    const response = await httpClient.put(`/v3/resource/manager/company/${comCode}/skill-group/update/${skillId}`, body);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }

}

const handleDeletedSkill = async (skillId) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v3/resource/manager/company/${comCode}/skill-group/remove/${skillId}`);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }

}

export {
  handleCreateSkill, handleEditSkill, handleDeletedSkill
}