import React, { useRef, useState } from 'react';
import '../index.css';
import { Card, Icon, Table, Popover, Button, Menu } from 'antd';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import ButtonActionGroup from '../../../components/action-button';

const PointDetail = (props) => {
  const {
    handleToggle,
    toggle,
    paginationPage,
    handleChange,
    handleView,
    handleImport,
    pointData,
    pointTotal,
    columns,
    exportData,
    exportDataDetail,
  } = props;
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const TitleCard = () => {
    return (
      <>
        <Icon
          type={toggle ? 'menu-unfold' : 'menu-fold'}
          style={{ cursor: 'pointer', color: '#e73845', marginRight: 15 }}
          onClick={handleToggle}
        />
        {intl.formatMessage({ id: `menuCampaignPoint`, defaultMessage: 'Point' })}
      </>
    );
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              {record !== undefined ? (
                <>
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                  </>
                </>
              ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={handleImport}>
        <p>
          <FormattedMessage id="btnImport" defaultMessage="Import" />
        </p>
      </Menu.Item>
      <Menu.Item key="1" onClick={exportData}>
        <p>
          <FormattedMessage id="btnExportSummary" defaultMessage="Export Summary" />
        </p>
      </Menu.Item>
      <Menu.Item key="1" onClick={exportDataDetail}>
        <p>
          <FormattedMessage id="btnExportDetail" defaultMessage="Export Detail" />
        </p>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      className="campaignPointCard"
      title={<TitleCard />}
      bodyStyle={{ padding: '24px', height: 'calc(100vh - 160px)' }}
      extra={
        <div>
          <ButtonActionGroup menu={menu} />
        </div>
      }
    >
      <div onMouseLeave={handleMouseLeave}>
        <Table
          dataSource={pointData}
          columns={columns}
          onChange={handleChange}
          scroll={{x: 1000, y: 'calc(100vh - 300px)' }}
          pagination={{
            total: pointTotal,
            current: paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        {_.size(pointData) > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${pointTotal || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </Card>
  );
};

export default PointDetail;
