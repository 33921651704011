import React from 'react'
import { Col, Form, Icon, Input, InputNumber, Row, Select } from 'antd'
import LabeRequireForm from '../../../../components/label-required-form'
import { useIntl, FormattedMessage } from 'react-intl'
import SuffixInput from '../../../../components/v2/suffix'
import _ from 'lodash'
import { handleKeyDownDecimal2Fixed, handleKeyDownDecimal2FixedNegative } from '../../../../component-function/fnc-number'

const { Option } = Select

const PettyCashFormTranfer = (props) => {
  const intl = useIntl()
  const { form, pocketList, defaultModal, handleChangeFromPocket, handleChangeTranferAmount, handleSwapBalance } = props
  const { getFieldDecorator, getFieldValue } = form

  const currencyFormatter = (value) => {
    return (value || "").replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return (value || "").replace(/\$\s?|(,*)/g, '');
  };

  const checkIfNegative = (number) => {
    if (number < 0) {
      return true
    } else {
      return false
    }
  };


  const validatorAmount = (rule, value, callback) => {
    if (value) {
      const amount = parseFloat(value || 0)
      const fromCurrentBalance = parseFloat(getFieldValue('fromCurrentBalance') || 0)
      const isNegative = checkIfNegative(fromCurrentBalance)
      if (isNegative) {
        if (amount < fromCurrentBalance) {
          callback(<FormattedMessage id="pettyCashModalFormFromPettyCashValidator" defaultMessage="Tranfer Amount more than Current Balance" />)
        } else if (amount > 0) {
          callback(<FormattedMessage id="pettyCashModalFormFromPettyCashValidator" defaultMessage="Tranfer Amount more than Current Balance" />)
        } else {
          callback()
        }
      } else {
        if (amount > fromCurrentBalance) {
          callback(<FormattedMessage id="pettyCashModalFormFromPettyCashValidator" defaultMessage="Tranfer Amount more than Current Balance" />)
        } else {
          callback()
        }
      }
    } else {
      callback();
    }


  }

  return (
    <Form form={form} layout="vertical" className="pettyCashModalForm" style={{ padding: '24px' }}>
      <Row gutter={[18, 6]}>
        <Col span={11}>
          <Row gutter={[16, 8]} id='Form-FromPettyCash'>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'pettyCashModalFormFromPettyCash', defaultMessage: 'From Petty Cash' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('fromPettyCash', {
                  initialValue: _.get(defaultModal, 'data.pettyCashId', ''),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'pettyCashModalFormFromPettyCashVL', defaultMessage: 'Please Select From Petty Cash' }),
                    },
                  ],
                }
                )(
                  <Select
                    placeholder={intl.formatMessage({ id: 'pettyCashModalFormFromPettyCashPH', defaultMessage: 'Select From Petty Cash' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                    onSelect={(value) => handleChangeFromPocket(value, 'from')}
                  >
                    {
                      _.map(_.get(pocketList, 'from'), (item) => {
                        const disbled = item.pettyCashId === getFieldValue('toPettyCash') ? true : false
                        return (
                          <Option value={_.get(item, 'pettyCashId')} disabled={disbled}>
                            {_.get(item, 'pettyCash')}
                          </Option>
                        )
                      })
                    }
                  </Select>)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'pettyCashModalFormCurrentBalance', defaultMessage: 'Current Balance' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('fromCurrentBalance', {
                  initialValue: (_.get(defaultModal, 'data.balance', 0)).toFixed(2),
                }
                )(
                  <InputNumber
                    disabled={true}
                    style={{ color: '#050505' }}
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'pettyCashModalFormCurrentBalancePH', defaultMessage: 'Enter Current Balance' })}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'pettyCashModalFormTranferAmount', defaultMessage: 'Tranfer Amount' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('tranferAmount', {
                  initialValue: (_.get(defaultModal, 'data.balance', 0)).toFixed(2),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'pettyCashModalFormTranferAmountVL', defaultMessage: 'Please Enter Tranfer Amount' }),
                    },
                    {
                      validator: validatorAmount
                    }
                  ]
                }
                )(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'pettyCashModalFormTranferAmountPH', defaultMessage: 'Enter Tranfer Amount' })}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangeTranferAmount}
                    onKeyDown={handleKeyDownDecimal2FixedNegative}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <div style={{ height: '228px', justifyContent: 'center', display: 'flex', alignItems: 'center' }} onClick={handleSwapBalance}>
            <Icon type="swap-right" style={{ fontSize: '30px', marginTop: '15px', color: '#00000080', cursor: 'pointer' }} />
          </div>
        </Col>
        <Col span={11}>
          <Row gutter={[16, 8]} id='Form-ToPettyCash'>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'pettyCashModalFormToPettyCash', defaultMessage: 'To Petty Cash' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('toPettyCash', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'pettyCashModalFormToPettyCashVL', defaultMessage: 'Please Select To Petty Cash' }),
                    },
                  ],
                }
                )(
                  <Select
                    placeholder={intl.formatMessage({ id: 'pettyCashModalFormToPettyCashPH', defaultMessage: 'Select To Petty Cash' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                    onSelect={(value) => handleChangeFromPocket(value, 'to')}
                  >
                    {
                      _.map(_.get(pocketList, 'to'), (item) => {
                        const disbled = item.pettyCashId === getFieldValue('fromPettyCash') ? true : false
                        return (
                          <Option value={_.get(item, 'pettyCashId')} disabled={disbled}>
                            {_.get(item, 'pettyCash')}
                          </Option>
                        )
                      })
                    }
                  </Select>)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'pettyCashModalFormCurrentBalance', defaultMessage: 'Current Balance' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('toCurrentBalance', {
                }
                )(
                  <InputNumber
                    disabled={true}
                    style={{ color: '#050505' }}
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'pettyCashModalFormCurrentBalancePH', defaultMessage: 'Enter Current Balance' })}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'pettyCashModalFormNewBalance', defaultMessage: 'New Balance' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('newBalance', {
                }
                )(
                  <InputNumber
                    disabled={true}
                    style={{ color: '#050505' }}
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'pettyCashModalFormNewBalancePH', defaultMessage: 'Enter New Balance' })}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row >
      <Row gutter={[18, 6]}>
        <Col span={24}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'pettyCashModalFormRemark', defaultMessage: 'Remark' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('remark', {
            }
            )(
              <Input.TextArea
                autoComplete="off"
                autoSize={{ minRows: 3, maxRows: 3 }}
                placeholder={intl.formatMessage({ id: 'pettyCashModalFormRemarkPH', defaultMessage: 'Enter Remark' })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form >
  )
}

export default PettyCashFormTranfer
