import React, { useContext, useState, useEffect } from 'react';
import ModalCustomerPost from './modal-post';
import _ from 'lodash';
import { successNotification, errorNotification } from '../../v2/notification';
import { editTicketNote, saveImageTicketNote } from '../../../controllers/ticket/ticket';

export default React.memo((props) => {
  const { visible, setVisible, setTrigger, editPostData, setEditPostData, ticketNo } = props

  const [customer, setCustomer] = useState([]);
  const [triggerCustomer, setTriggerCustomer] = useState(false);
  const [visibleModalCustomer, setVisibleModalCustomer] = useState(false);
  const [visibleModalLocation, setVisibleModalLocation] = useState(false)
  const [customerId, setCustomerId] = useState();
  const [message, setMessage] = useState('');
  const [selectedImageBlob, setSelectedImageBlob] = useState([]);
  const [selectedFilesBlob, setSelectedFilesBlob] = useState([]);
  const [addressData, setAddressData] = useState()
  //---------------------- Address --------------------------------
  const [locationData, setLocationData] = useState([])
  const [center, setCenter] = useState({ lat: 13.90607, lng: 100.51913 });
  const [position, setPosition] = useState({ lat: 13.90607, lng: 100.51913 })
  const [visibleInfo, setVisibleInfo] = useState(false)
  const [selectAddress, setSelectAddress] = useState([])
  const [dragMarker, setDragMarker] = useState()
  const [latLng, setLatLng] = useState({})
  const [visibleCreateAddress, setVisibleCreateAddress] = useState(false)
  const [toLocation, setToLocation] = useState()
  const [fieldChange, setFieldChange] = useState('')
  const [valueInput, setValueInput] = useState()
  const [triggerApi, setTriggerApi] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState('default');
  const [selectAddressData, setSelectAddressData] = useState()
  const [deleteImg, setDeleteImg] = useState([])
  const [deleteUpload, setDeleteUpload] = useState([])

  console.log("editPostData", editPostData)

  useEffect(() => {
    if (editPostData && visible) {
      const mapImage = _.get(editPostData, 'attachmentsPhoto').map(item => {
        return {
          attachmentId: item.filehash,
          description: item.descriptionPhoto,
          pathImage: item.uri,
          file: item.uri,
          fileType: 'url'
        }
      })
      const mapFile = _.get(editPostData, 'attachmentsFile').map(item => {
        return {
          attachmentId: item.filehash,
          description: '',
          file: item.uri,
          name: item.originalname,
          fileType: 'url'
        }
      })
      setSelectedImageBlob(mapImage)
      setSelectedFilesBlob(mapFile)
      setMessage(_.get(editPostData, 'description'))
      setSelectAddress([
        {
          address: _.get(editPostData, 'address'),
          lat: _.get(editPostData, 'lat'),
          lng: _.get(editPostData, 'lng'),
          name: _.get(editPostData, 'address'),
          addressId: null,
        }
      ])
      setAddressData({
        address: _.get(editPostData, 'address'),
        lat: _.get(editPostData, 'lat'),
        lng: _.get(editPostData, 'lng'),
        name: _.get(editPostData, 'address'),
        addressId: null,
      })
      setLatLng(
        {
          lat: parseFloat(_.get(editPostData, 'lat')),
          lng: parseFloat(_.get(editPostData, 'lng')),
        }
      )
      setCenter(
        {
          lat: parseFloat(_.get(editPostData, 'lat')),
          lng: parseFloat(_.get(editPostData, 'lng')),
        }
      )
      setPosition(
        {
          lat: parseFloat(_.get(editPostData, 'lat')),
          lng: parseFloat(_.get(editPostData, 'lng')),
        }
      )
    }

  }, [_.get(editPostData, 'noteid'), visible])

  console.log("selectedImageBlob", selectedImageBlob)

  const handleCreatePost = async () => {
    setLoading(true)
    const lat = _.get(addressData, 'lat') ? _.get(addressData, 'lat') : undefined;
    const lng = _.get(addressData, 'lng') ? _.get(addressData, 'lng') : undefined;
    const address = _.get(addressData, 'address') ? _.get(addressData, 'address') : undefined;
    const memComId = localStorage.getItem('memComId');

    let mapAll = [];
    const filterBlobImg = _.filter(selectedImageBlob, (item) => { return item.fileType === 'blob' })
    const filterBlobFile = _.filter(selectedFilesBlob, (item) => { return item.fileType === 'blob' })
    for (let index = 0; index < _.size(filterBlobImg); index++) {
      let formData = new FormData();
      formData.append('file', filterBlobImg[index].file);
      formData.append('fileType', 'photo');
      const fileResponse = await saveImageTicketNote(formData)
      if (fileResponse.status === 200) {
        mapAll.push({
          fileHash: _.get(fileResponse, 'data.data.fileHash'),
          descriptionPhoto: filterBlobImg[index].description,
          type: "photo",
        })
      }
    }

    for (let index = 0; index < _.size(filterBlobFile); index++) {
      let formData = new FormData();
      formData.append('file', filterBlobFile[index].file);
      formData.append('fileType', 'file');
      const fileResponse = await saveImageTicketNote(formData)
      mapAll.push({
        fileHash: _.get(fileResponse, 'data.data.fileHash'),
        descriptionPhoto: filterBlobFile[index].name,
        type: "file",
      })
    }
    const filterUrlImg = _.filter(selectedImageBlob, (item) => { return item.fileType === 'url' })
    const filterUrlFile = _.filter(selectedFilesBlob, (item) => { return item.fileType === 'url' })
    const mapImage = _.map(filterUrlImg, (item) => {
      return {
        filehash: item.attachmentId,
        description: item.descriptionPhoto || "",
        type: "photo",
      }
    })
    const mapFile = _.map(filterUrlFile, (item) => {
      return {
        filehash: item.attachmentId,
        description: item.name,
        type: "file",
      }
    })

    const concatCheckDelete = _.get(editPostData, 'attachmentsFile').concat(_.get(editPostData, 'attachmentsPhoto'))
    const concatUrlDelete = mapImage.concat(mapFile)
    const diffByDelete = _.differenceBy(concatCheckDelete, concatUrlDelete, 'filehash');
    // const diffByPass = _.differenceBy(concatUrlDelete, concatCheckDelete, 'filehash');
    console.log("filterBlobFile", filterBlobFile)

    for (let index = 0; index < concatUrlDelete.length; index++) {
      const element = concatUrlDelete[index];
      mapAll.push({
        fileHash: element.filehash,
        descriptionPhoto: element.description,
        type: element.type,
      })
    }
    const body = {
      "address": address,
      "memComId": memComId,
      "description": message,
      "lat": lat,
      "lng": lng,
      "filesUpload": mapAll,
      "filesDelete": _.map(diffByDelete, (item) => { return item.filehash })
    }
    try {
      const response = await editTicketNote(_.get(editPostData, 'noteid'), body);
      if (response.status === 200) {
        setTrigger(current => !current)
        setVisible(false)
        setCustomerId(undefined);
        setSelectedImageBlob([]);
        setSelectedFilesBlob([]);
        setMessage('');
        setSelectAddress([]);
        setDragMarker();
        setVisibleInfo(false);
        setLatLng({ lat: 13.90607, lng: 100.51913 });
        setToLocation();
        setFieldChange();
        setValueInput();
        setAddressData();
        setChecked('default');
        setSelectAddressData();
        successNotification(response.data.status.message);
        setLoading(false);
      } else {
        errorNotification(response.data.status.message);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error)
      setLoading(false);
    }
  }

  const handleCancelPost = () => {
    setVisible(false)
    setCustomerId(undefined);
    setSelectedImageBlob([]);
    setSelectedFilesBlob([]);
    setMessage('');
    // fnc.handleCancelModalAddPost();
    setSelectAddress([]);
    setDragMarker();
    setVisibleInfo(false);
    setLatLng({ lat: 13.90607, lng: 100.51913 });
    setToLocation();
    setFieldChange();
    setValueInput();
    setAddressData();
    setChecked('default');
    setMessage(undefined);
    setSelectAddressData();
    setEditPostData()
  }

  return (
    <div>
      <ModalCustomerPost
        value={{
          customer,
          visible,
          visibleModalCustomer,
          visibleModalLocation,
          customerId,
          selectedFilesBlob,
          selectedImageBlob,
          addressData,
          message,
          loading,
          checked,
          locationData,
          center,
          position,
          visibleInfo,
          selectAddress,
          dragMarker,
          latLng,
          visibleCreateAddress,
          toLocation,
          fieldChange,
          valueInput,
          triggerApi,
          selectAddressData,
          editPostData,
          deleteImg,
          deleteUpload,
          ticketNo,
        }}
        fnc={{
          setTriggerCustomer,
          handleCancelPost,
          handleCreatePost,
          setVisibleModalCustomer,
          setVisibleModalLocation,
          setCustomerId,
          setSelectedFilesBlob,
          setCustomer,
          setSelectedImageBlob,
          setAddressData,
          setMessage,

          setLocationData,
          setCenter,
          setPosition,
          setVisibleInfo,
          setSelectAddress,
          setDragMarker,
          setLatLng,
          setVisibleCreateAddress,
          setToLocation,
          setFieldChange,
          setValueInput,
          setTriggerApi,
          setChecked,
          setSelectAddressData,
        }}
      />
    </div>
  )
})
