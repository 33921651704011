import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import moment from 'moment';

const { Option } = Select;

const ListItemFilterTask = (props) => {
  const { onChange, searchGroup, onAllowClear, onClick, team } = props;
  const {
    setCheckedListTeam,
    setTeamId,
    setIndeterminateTeam,
    setCheckAllTeam,
    indeterminateTeam,
    checkAllTeam,
    teamSelect,
    setTeamSelect,
    checkedListTeam,
    panelTeam,
    planOptionsTeam,
    teamList,
    setPanelTeam,
    teamStatusList,
    teamTaskTypeList,
  } = team;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';
  console.log('teamList', team, teamList);
  const handleCheckAllTeam = (e) => {
    const planOptionsTeamm = teamList && teamList.map((item) => item.orgId);
    console.log('Allteam', e);
    setCheckedListTeam(e.target.checked ? planOptionsTeamm : []);
    setTeamSelect(e.target.checked ? planOptionsTeamm : []);
    setIndeterminateTeam(false);
    setCheckAllTeam(e.target.checked);
  };

  const onChangeGroupTeam = (checkedList) => {
    console.log('checkedList', checkedList);
    setCheckedListTeam(checkedList);
    setCheckAllTeam(checkedList.length === planOptionsTeam.length);
    setIndeterminateTeam(!!checkedList.length && checkedList.length < planOptionsTeam.length);
  };

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '580px' }}>
        <div className="listItemFilterContent">
          <div>
            <Checkbox indeterminate={indeterminateTeam} onChange={handleCheckAllTeam} checked={checkAllTeam}>
              <span className="listItemFilterContentHeadText">
                <FormattedMessage id="searchItemBillingTeam" defaultMessage="Team" />
              </span>
            </Checkbox>
          </div>
          <div>
            <span onClick={() => setPanelTeam((current) => !current)} style={{ cursor: 'pointer' }}>
              <FormattedMessage id="filterTaskSelected" defaultMessage="Selected" /> {_.size(teamSelect)}{' '}
              {panelTeam ? <Icon type="up" /> : <Icon type="down" />}
            </span>
          </div>
        </div>
        {panelTeam ? (
          <Checkbox.Group style={{ marginLeft: '32px' }} value={checkedListTeam} onChange={onChangeGroupTeam}>
            <Row gutter={[16, 16]}>
              {_.map(teamList, (item) => (
                <Col span={24}>
                  <Checkbox value={item.orgId}>{item.orgName}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        ) : null}
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div style={{ padding: '16px 16px 16px 16px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddTaskNo" defaultMessage="Task No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'taskNo')}
                value={_.get(searchGroup, 'taskNo')}
                placeholder={intl.formatMessage({
                  id: 'BLAddTaskNoPlaceholder',
                  defaultMessage: 'Enter Task No.',
                })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddTaskType" defaultMessage="Task Type" />
            </div>
            <div>
              <Select
                style={{ width: '100%' }}
                allowClear={true}
                onChange={(e) => onChange(e, 'taskTypeId')}
                value={_.get(searchGroup, 'taskTypeId')}
                placeholder={intl.formatMessage({
                  id: 'BLAddTaskNamePlaceholder',
                  defaultMessage: 'Select Task Type',
                })}
              >
                {teamTaskTypeList && teamTaskTypeList.map((o) => <Option value={o.taskTypeId}>{o.taskTypeName}</Option>)}
              </Select>
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddTaskName" defaultMessage="Task Name" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'taskName')}
                value={_.get(searchGroup, 'taskName')}
                placeholder={intl.formatMessage({
                  id: 'BLAddTaskNamePlaceholder',
                  defaultMessage: 'Enter Task Name',
                })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddTaskStatus" defaultMessage="Status" />
            </div>
            <div>
              <Select
                style={{ width: '100%' }}
                allowClear={true}
                onChange={(e) => onChange(e, 'statusId')}
                value={_.get(searchGroup, 'statusId')}
                placeholder={intl.formatMessage({
                  id: 'BLAddStatusPlaceholder',
                  defaultMessage: 'Select Status',
                })}
              >
                {teamStatusList && teamStatusList.map((o) => <Option value={o.statusId}>{o.statusName}</Option>)}
              </Select>
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLFromLocation" defaultMessage="From Location" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'fromLocation')}
                value={_.get(searchGroup, 'fromLocation')}
                placeholder={intl.formatMessage({
                  id: 'BLAddFromLocationPlaceholder',
                  defaultMessage: 'Enter From Location',
                })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLToLocation" defaultMessage="To Location" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'toLocation')}
                value={_.get(searchGroup, 'toLocation')}
                placeholder={intl.formatMessage({
                  id: 'BLAddToLocationPlaceholder',
                  defaultMessage: 'Enter To Location',
                })}
              />
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddCreatedDate" defaultMessage="Created Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                value={_.get(searchGroup, 'createdAt') ? moment(_.get(searchGroup, 'createdAt')) : ''}
                style={{ width: '100%' }}
                onChange={(e) => onChange(e, 'createdAt')}
                placeholder={intl.formatMessage({ id: 'BLAddCreatedDatePlaceholder', defaultMessage: 'Select Created Date' })}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px 16px 16px 0px' }}>
        <Button_01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={onClick}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button_01>
      </div>
    </div>
  );
};

export default ListItemFilterTask;
