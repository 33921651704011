import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Icon } from 'antd';
import UserAvatar from './manageAccount/userAvatar';
import Notification from './notification/notification';
import Platform from './platform/platform';
import { dbInital } from '../../firebase-conf';
import httpClient from '../../components/axiosClient';
import _ from 'lodash';
import { successNotification, errorNotification, warningNotification } from '../../components/v2/notification'

export default (props) => {
  const labelShow = props.labelShow;
  const langValue = props.langValue;

  const memComId = localStorage.getItem('memComId');
  const comCode = localStorage.getItem('comCode');

  const [notiCount, setNotiCount] = useState();
  const [hideNoti, setHideNoti] = useState(false);
  const [hideNotiSelect, setHideNotiSelect] = useState(false);
  const [notiData, setNotiData] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [triggerAcknowledge, setTriggerAcknowledge] = useState(false)


  useEffect(() => {
    getDataFirebase();
  }, [])

  useEffect(() => {
    // getNotification();
  }, [hideNoti])

  const getDataFirebase = () => {
    var ref = dbInital.ref(`/workd-backend/${process.env.REACT_APP_FIREBASE_ENV}/notifications/${comCode}/${memComId}/counter`);
    ref.on("value", function (snapshot) {
      setNotiCount(snapshot.val());
    });
  }

  // const getNotification = async () => {
  //   try {
  //     const response = await httpClient.get(`/v3/notification/manager/noti/${comCode}/type?memberReceiverId=${memComId}&statusRead=false`);
    
  //     setNotiData(_.get(response, 'data'))
  //   } catch (error) {
     
  //   }
  // }

  const acknowledgeStatus = async (id) => {
    let arrayId = []
    arrayId.push(
      id
    )
    const body = {
      id: arrayId,
      statusRead: true
    }

    try {
      const response = await httpClient.put(`/v3/notification/manager/noti/${comCode}/type/updatedata`, body);
      if (response.status == 200) {
        setTriggerAcknowledge(!triggerAcknowledge);
        successNotification(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        errorNotification(error.response.statusText);
      }
    }
  }

  return (
    <div>
      <Row gutter={25} className="platform-style">
        <Col
          span={11}
          order={1}
          style={{
            paddingRight: '0px',
            marginRight: '17.5px',
            width: 'auto',
          }}
        >
          <Platform />
        </Col>
        <Col
          span={4}
          order={2}
          style={{ paddingTop: '0px', paddingRight: '8.5px', paddingLeft: '0px' }}
        >
          <Notification
            count={notiCount}
            notiData={notiData}
            visible={hideNoti}
            setVisible={setHideNoti}
            hideNotiSelect={hideNotiSelect}
            setHideNotiSelect={setHideNotiSelect}
            dataSelect={dataSelect}
            setDataSelect={setDataSelect}
            acknowledgeStatus={acknowledgeStatus}
          />
        </Col>
        <Col span={2} order={4} style={{ paddingRight: '12.5px', paddingLeft: '0px', paddingBottom: '6px' }}>
          <UserAvatar labelShow={labelShow} langValue={langValue} />
        </Col>
      </Row>
    </div>
  );
};
