import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import cssStyle from './css/actionServies.css';
import AddArea from './addArea';
// import AddArea from './addArea_test';
import { Row, Col, List, Select, Icon, Dropdown, Button, Checkbox, Menu } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Input from '../../../components/v2/input';

const { Option } = Select;

export default (props) => {
  const intl = useIntl();

  const {
    columns,
    areaList,
    taskTypes,
    maxBuffer,
    checked,
    selectSearchData,
    onChange,
    onSelect,
    handleReset,
    handleCheckOk,
    checkedValuecolumns,
    handleOnVisiblecolumns,
    handleVisibleChange,
    textErrorSelectColumn,
    visible,
    comId,
    orgId,
    refreshDataServices,
    customerData
  } = props;

  const columnSelect = [...columns];
  columnSelect.splice(0, 1);
  columnSelect.splice(4, 1);

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnSelect.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={checkedValuecolumns}
                checked={checked[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.select}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="orgTabServiceBtnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="orgTabServiceBtnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div>
      <Row>
        <Col span={12} style={{ marginBottom: '15px ' }}>
          <Row>
            <List.Item>
              <Input
                className="services-input-search action-input"
                onChange={e => onChange(e.target.value)}
                placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                autoComplete="off"
              />
              <Select className="services-select action-select" onSelect={(value) => onSelect(value)} value={selectSearchData}>
                <Option value="">
                  <FormattedMessage id="orgTabServiceTxtAllColumns" defaultMessage="All columns" />
                </Option>
                {columnSelect.map((item, i) => (
                  <Option key={i} value={item.key}>
                    {item.select}
                  </Option>
                ))}
              </Select>
            </List.Item>
          </Row>
        </Col>

        <Col span={12} style={{ margin: 0, paddingRight: 24 }}>
          <Row>
            <List.Item style={{ float: 'right' }} >
              <Dropdown overlay={menuColumn} trigger={['click']} onVisibleChange={handleVisibleChange} visible={visible}>
                <Button01 fontsize="sm" type="primary" onClick={handleOnVisiblecolumns}>
                  <FormattedMessage id="orgTabServiceBtnColumns" defaultMessage="Columns" /> {" "}
                  <Icon type="down" />
                </Button01>
              </Dropdown>

              <AddArea areaList={areaList} taskTypes={taskTypes} maxBuffer={maxBuffer} comId={comId} orgId={orgId} refreshDataServices={refreshDataServices} customerData={customerData} />

            </List.Item>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
