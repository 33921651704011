import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Col, Divider, Form, Modal, Row, Select, Tabs, Input, DatePicker } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import { messageLabel } from '../../../../components/message-component';
import moment from 'moment';
import { getQuotationList } from '../../../../controllers/quotation/quotation-api-new';
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal';
import CustomTableComponent from '../../components/table';

const { Option } = Select;
const { Search } = Input;
const dateFormat = 'ddd, MMM DD YYYY';

const SelectQuotationModal = ({ form, title, visible, onOk, onCancel, setDataForm, dataForm }) => {
  const [reportDataApproved, setReportDataApproved] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [moment().startOf('month'), moment().endOf('month')],
  });

  useEffect(() => {
    setReportDataApproved((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tab: 'approved',
        startDate: moment(_.get(reportDataApproved, 'rangePickerDate[0]')).format('YYYY-MM-DD'),
        endDate: moment(_.get(reportDataApproved, 'rangePickerDate[1]')).format('YYYY-MM-DD'),
        paging: _.get(reportDataApproved, 'paginationPage'),
        rowsPerPages: _.get(reportDataApproved, 'paginationSize'),
        orderBy: _.get(reportDataApproved, 'fieldSort'),
        orderType: _.get(reportDataApproved, 'orderSort'),
        filter: {
          quotationNo: _.get(reportDataApproved, 'quotationNo'),
          saleOrderNo: _.get(reportDataApproved, 'saleOrderNo'),
          customerName: _.get(reportDataApproved, 'customerName'),
        },
      };
      const response = await getQuotationList(payload);
      if (_.get(response, 'status.code') === 200) {
        setReportDataApproved((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.data'),
          quotationTotal: _.get(response, 'data.total'),
        }));
      }
    };
    setReportDataApproved((prev) => ({ ...prev, loading: false }));
    if (visible === true) {
      getApi();
    }
  }, [
    visible,
    _.get(reportDataApproved, 'rangePickerDate[0]'),
    _.get(reportDataApproved, 'rangePickerDate[1]'),
    _.get(reportDataApproved, 'paginationPage'),
    _.get(reportDataApproved, 'paginationSize'),
    _.get(reportDataApproved, 'fieldSort'),
    _.get(reportDataApproved, 'orderSort'),
    _.get(reportDataApproved, 'quotationNo'),
    _.get(reportDataApproved, 'saleOrderNo'),
    _.get(reportDataApproved, 'customerName'),
  ]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('quotationLabelQuotationNo', 'Quotation No'),
      dataIndex: 'quotationNo',
      key: 'quotationNo',
      sorter: true,
      fixed: 'left',
      width: 150,
    },
    {
      title: messageLabel('quotationLabelCustomer', 'Customer'),
      dataIndex: 'customerContactName',
      key: 'customerContactName',
      sorter: true,
      fixed: 'left',
      // width: 200,
    },
    {
      title: messageLabel('quotationLabelItem', 'Item'),
      dataIndex: 'totalItem',
      key: 'totalItem',
      sorter: true,
      render: (text, record, index) => _.get(record, 'totalItem'),
    },
    {
      title: messageLabel('quotationLabelTotal', 'Total'),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      width: 200,
      align: 'right',
      render: (text, record, index) =>
        _.get(record, 'total') ? (
          <span>
            {numberFormatDecimal(_.get(record, 'total'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
          </span>
        ) : null,
    },
    {
      title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (text, record, index) =>
        _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
    },
  ];

  const handleClose = () => {
    onCancel();
  };

  const onSelectQuotationRow = (value) => {
    console.log('onSelectQuotationRow', value);
    setDataForm((prev) => ({ ...prev, quotationObj: value }));
    onCancel()
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={900}
      zIndex={1000}
      centered={true}
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <CustomTableComponent
        columns={columns}
        rowKey={(record) => record.quotationId}
        dataSource={_.get(reportDataApproved, 'quotationList')}
        // scroll={{ x: true }}
        total={_.get(reportDataApproved, 'quotationTotal')}
        role={false}
        onRow={false}
        onRowClick={(o) => onSelectQuotationRow(o)}
      />
    </Modal>
  );
};

SelectQuotationModal.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalSelectQuotationForm = Form.create({ name: 'select_qt_Form' })(SelectQuotationModal);

export default ModalSelectQuotationForm;
