import React, { useState, useEffect, useContext } from 'react';
import { Avatar, Modal, Row, Col, List, Button, Icon } from 'antd';
import IconExcel from '../../../components/image/excel.svg';
import Card from '../../../components/v2/card';
import Table from '../../../components/v2/table';
import Button02 from '../../../components/v2/button_02';
import cssStyle from './css/index.css';
import cssStyleCenter from '../report-center.css';
import ActionTimeAttendance from './action-time-attendance';
import moment from 'moment';
import { useAppContext } from '../../../includes/indexProvider';
import httpClient from '../../../components/axiosClient';
import { debounce } from 'lodash';
import { OverTimeContext } from '../report-context';
import { PageSettings } from '../../../config/page-settings';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import GetLang from '../../../includes/language';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import Button_01 from '../../../components/v2/button_01';

const comId = localStorage.getItem('comId');
const pageCode = localStorage.getItem('pageCode');
// const pageCode = '001';

export default (props) => {
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const { state, setState, fnc } = useContext(OverTimeContext);

  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';
  const dateNow = moment(today).format('YYYY-MM');
  const dateNowRangePicker = [moment(today, dateFormat), moment(today, dateFormat)];
  const [labelShow, setLabelShow] = useState({});

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnEmployeeCode', defaultMessage: 'Employee code' }),
      dataIndex: 'employeeCode',
      key: 'employeeCode',
      width: 200,
      sorter: (a, b) => a.employeeCode - b.employeeCode,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnName', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
      // fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnTeam', defaultMessage: 'Team' }),
      dataIndex: 'team',
      key: 'team',
      width: 200,
      sorter: (a, b) => a.team - b.team,
      sortDirections: ['descend', 'ascend'],
      // fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnEmail', defaultMessage: 'Email' }),
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email - b.email,
      sortDirections: ['descend', 'ascend'],
      width: 250,
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnPhone', defaultMessage: 'Phone Number' }),
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnTimeAttendance', defaultMessage: 'Time Attendance' }),
      dataIndex: 'timeAttendance',
      key: 'timeAttendance',
      sorter: (a, b) => a.timeAttendance - b.timeAttendance,
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnTotalHours', defaultMessage: 'Total Hours' }),
      dataIndex: 'actual',
      key: 'actual',
      width: 200,
      sorter: (a, b) => a.actual - b.actual,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnDistanceClockIn', defaultMessage: 'Distance (Clock In)' }),
      dataIndex: 'clockInDistance',
      key: 'clockInDistance',
      sorter: (a, b) => a.clockInDistance - b.clockInDistance,
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnCommentClockIn', defaultMessage: 'Comment (Clock In)' }),
      dataIndex: 'clockInComment',
      key: 'clockInComment',
      sorter: (a, b) => a.clockInComment - b.clockInComment,
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnDistanceClockOut', defaultMessage: 'Distance (Clock Out)' }),
      dataIndex: 'clocOutDistance',
      key: 'clocOutDistance',
      sorter: (a, b) => a.clocOutDistance - b.clocOutDistance,
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnCommentClockOut', defaultMessage: 'Comment (Clock Out)' }),
      dataIndex: 'clocOutComment',
      key: 'clocOutComment',
      sorter: (a, b) => a.clocOutComment - b.clocOutComment,
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnAddressClockIn', defaultMessage: 'Address (Clock In)' }),
      dataIndex: 'clockInAddress',
      key: 'clockInAddress',
      width: 250,
      sorter: (a, b) => a.clockInAddress - b.clockInAddress,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnAddressClockOut', defaultMessage: 'Address (Clock Out)' }),
      dataIndex: 'clockOutAddress',
      key: 'clockOutAddress',
      width: 250,
      sorter: (a, b) => a.clockOutAddress - b.clockOutAddress,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnImage', defaultMessage: 'Image' }),
      dataIndex: 'image',
      key: 'image',
      width: 180,
      render: (text, record) => (
        <div>
          {record.clockInImg ? (
            <Avatar
              src={record.clockInImg}
              style={{ margin: '4px' }}
              size="large"
              shape="square"
              onClick={() => handleClickImage(record, 'in')}
            />
          ) : null}
          {record.clockOutImg ? (
            <Avatar
              src={record.clockOutImg}
              style={{ margin: '4px' }}
              size="large"
              shape="square"
              onClick={() => handleClickImage(record, 'out')}
            />
          ) : null}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnApproverClockIn', defaultMessage: 'Approver ClockIn' }),
      dataIndex: 'approverClockIn',
      key: 'approverClockIn',
      width: 200,
      sorter: (a, b) => a.approverClockIn - b.approverClockIn,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnApproverClockOut', defaultMessage: 'Approver ClockOut' }),
      dataIndex: 'approverClockOut',
      key: 'approverClockOut',
      width: 200,
      sorter: (a, b) => a.approverClockOut - b.approverClockOut,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTimeAttendanceColumnShift', defaultMessage: 'Shift' }),
      dataIndex: 'shiftName',
      key: 'shiftName',
      width: 200,
      sorter: (a, b) => a.shiftName - b.shiftName,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const setShowColumn = {
    index: true,
    employeeCode: true,
    name: true,
    team: true,
    email: true,
    phone: true,
    timeAttendance: true,
    actual: true,
    clockInComment: true,
    clocOutComment: true,
    clockInDistance: true,
    clocOutDistance: true,
    clockInAddress: false,
    clockOutAddress: false,
    image: true,
    status: true,
    approverClockIn: false,
    approverClockOut: false,
    shiftName: false,
  };

  const setShowColumnArr = [
    'index',
    'employeeCode',
    'name',
    'team',
    'email',
    'phone',
    'timeAttendance',
    'actual',
    'clockInComment',
    'clocOutComment',
    'clockInDistance',
    'clocOutDistance',
    'image',
    'status',
    // 'approverClockIn',
    // 'approverClockOut',
    // 'shiftName',
  ];

  const ignoreColumn = ['clockInAddress', 'clockOutAddress', 'shiftName', 'approverClockIn', 'approverClockOut'];

  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const [loading, setLoading] = useState(false);
  const [dataAttendance, setDataAttendance] = useState([]);
  const [total, setTotal] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [extra, setExtra] = useState();
  const [field, setField] = useState();
  const [order, setOrder] = useState();
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [profile, setProfile] = useState('');
  const [workCheck, setWorkCheck] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [monthPicker, setMonthPicker] = useState(dateNow);

  const [selectSearchData, setSelectSearchData] = useState('all');
  const [fieldChang, setFieldChang] = useState('');
  const [checkSearch, setCheckSearch] = useState(false);
  const [rangePicker, setRangePicker] = useState(dateNowRangePicker);
  const [fromDate, setFromDate] = useState(
    moment(today)
      .startOf('month')
      .format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState(
    moment(today)
      .endOf('month')
      .format('YYYY-MM-DD')
  );
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dataTimeAttendance();
  }, [state.checkedKeys, fromDate, toDate, fieldChang, extra, paginationSize, selectSearchData, state.selectedKeys]);

  const dataTimeAttendance = async () => {
    setLoading(true);

    try {
      const body = {
        fromDate: fromDate,
        utilDate: toDate,
        orgId: _.size(state.checkedKeys) !== 0 ? state.checkedKeys : state.selectedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: fieldChang === '' ? paginationPage : 1,
        rowsPerPages: paginationSize,
        ordertype: order,
        orderby: field,
      };

      await httpClient.post(`/v1/resource/manager/companies/${comId}/members/report`, body).then((res) => {
        if (res.status === 200) {
          setDataAttendance(res.data.data.timeattendances);
          setTotal(res.data.data.totals);
          setLoading(false);
        } else {
          setLoading(false);
          errorNotification('Data not found');
          setDataAttendance([]);
          setTotal();
        }
      });
    } catch (error) {
      setLoading(false);
      errorNotification('Data not found');
      setDataAttendance([]);
      setTotal();
    }
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    } else {
      setOrder('');
    }
    setExtra(extra);
    setField(field === 'status' ? 'statusClockIn' : field);
  };

  const onSelectMonthPicker = (date) => {
    const startOfMonth = moment(date, 'YYYY/MM')
      .startOf('month')
      .format('YYYY-MM-DD');
    const endOfMonth = moment(date, 'YYYY/MM')
      .endOf('month')
      .format('YYYY-MM-DD');
    setFromDate(startOfMonth);
    setToDate(endOfMonth);
  };

  const onSelectRangePicker = (date, dateString) => {
    setRangePicker(dateString);
    setFromDate(moment(date[0]).format('YYYY-MM-DD'));
    setToDate(moment(date[1]).format('YYYY-MM-DD'));
  };

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
    setCheckSearch(!checkSearch);
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }

    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationPage(current);
    setPaginationSize(size);
  };

  const avatarImage = (record) => {
    const clockInImg = () => {
      if (record.clockInImg) {
        return (
          <Avatar
            style={{ margin: '4px' }}
            icon="user"
            shape="square"
            src={record.clockInImg}
            size="large"
            onClick={() => handleShowImageClockIn(record)}
          />
        );
      } else {
        return null;
      }
    };
    const clockOutImg = () => {
      if (record.clockOutImg) {
        return (
          <Avatar
            style={{ margin: '4px' }}
            icon="user"
            shape="square"
            src={record.clockOutImg}
            size="large"
            onClick={() => handleShowImageClockOutImg(record)}
          />
        );
      } else {
        return null;
      }
    };
    return (
      <div>
        {clockInImg()}
        {clockOutImg()}
      </div>
    );
  };

  const handleClickImage = (record, type) => {
    let pathImage = '';
    let txtInOut = '';
    if (type === 'in') {
      pathImage = record.clockInImg ? record.clockInImg : '';
      txtInOut = intl.formatMessage({ id: 'reportTimeAttendanceModalLblImageClockIn', defaultMessage: 'Clock In' });
    }
    if (type === 'out') {
      pathImage = record.clockOutImg ? record.clockOutImg : '';
      txtInOut = intl.formatMessage({ id: 'reportTimeAttendanceModalLblImageClockOut', defaultMessage: 'Clock Out' });
    }

    if (pathImage !== '') {
      setProfile(record.profile);
      setAvatar(pathImage);
      setWorkCheck(txtInOut);
      setVisibleModal(true);
    }
  };

  const handleShowImageClockIn = (record) => {
    if (record.clockInImg) {
      setProfile(record.profile);
      setWorkCheck('Clock In');
      setAvatar(record.clockInImg);
      setVisibleModal(true);
    }
  };

  const handleShowImageClockOutImg = (record) => {
    if (record.clockOutImg) {
      setProfile(record.profile);
      setWorkCheck('Clock Out');
      setAvatar(record.clockOutImg);
      setVisibleModal(true);
    }
  };

  const handleClose = () => {
    setVisibleModal(false);
    setTimeout(() => {
      setProfile('');
    }, 100);
  };

  const handleExport = async () => {
    try {
      const body = {
        fromDate: fromDate,
        utilDate: toDate,
        orgId: state.checkedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: paginationPage === 1 ? paginationPage : 1,
        rowsPerPages: total,
        orderby: order,
        ordertype: field,
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnEmployeeCode', defaultMessage: 'Employee code' }),
            sequence: 1,
            colCode: 'employeeCode',
          },
          { colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnName', defaultMessage: 'Name' }), sequence: 2, colCode: 'name' },
          { colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnTeam', defaultMessage: 'Team' }), sequence: 3, colCode: 'team' },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnEmail', defaultMessage: 'Email' }),
            sequence: 4,
            colCode: 'email',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnPhone', defaultMessage: 'Phone Number' }),
            sequence: 5,
            colCode: 'phone',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnTimeAttendance', defaultMessage: 'Time Attendance' }),
            sequence: 6,
            colCode: 'timeAttendance',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnTotalHours', defaultMessage: 'Total Hours' }),
            sequence: 7,
            colCode: 'actual',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnDistanceClockIn', defaultMessage: 'Distance (Clock In)' }),
            sequence: 8,
            colCode: 'clockInDistance',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnCommentClockIn', defaultMessage: 'Comment (Clock In)' }),
            sequence: 9,
            colCode: 'clockInComment',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnDistanceClockOut', defaultMessage: 'Distance (Clock Out)' }),
            sequence: 10,
            colCode: 'clocOutDistance',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnCommentClockOut', defaultMessage: 'Comment (Clock Out)' }),
            sequence: 11,
            colCode: 'clocOutComment',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnAddressClockIn', defaultMessage: 'Address (Clock In)' }),
            sequence: 12,
            colCode: 'clockInAddress',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnAddressClockOut', defaultMessage: 'Address (Clock Out)' }),
            sequence: 13,
            colCode: 'clockOutAddress',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnImage', defaultMessage: 'Image' }),
            sequence: 14,
            colCode: 'image',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnStatus', defaultMessage: 'Status' }),
            sequence: 15,
            colCode: 'status',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnApproverClockIn', defaultMessage: 'Approver ClockIn' }),
            sequence: 16,
            colCode: 'approverClockIn',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnApproverClockOut', defaultMessage: 'Approver ClockOut' }),
            sequence: 17,
            colCode: 'approverClockOut',
          },
          {
            colName: intl.formatMessage({ id: 'reportTimeAttendanceColumnShift', defaultMessage: 'Shift' }),
            sequence: 18,
            colCode: 'shiftName',
          },
        ],
      };

      const response = await httpClient.post(`/v1/resource/manager/companies/${state.comId}/export/members/report/timeattendance`, body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      errorNotification(error.response.data.status.message);
    }
  };

  const iconExcel = () => <img className="icon-excel" src={IconExcel} />;

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={fnc.handleToggle}>
          <Icon type={state.toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="reportTimeAttendanceTitle" defaultMessage="Time Attendance" />
          </p>
        </div>
      </div>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P4S1PG1C1', 'P4S1PG1C1A1') ? (
          <div style={{ backgroundColor: '#ffffff', height: '100%' }}>
            <Card
              className="time-attendance-card"
              title={<HeaderAndToggle />}
              extra={
                <Button_01 style={{width: '100%'}} type='primary' onClick={handleExport} >
                  <FormattedMessage id="btnExport" defaultMessage="Export" />
                </Button_01>
              }
            >
              <ActionTimeAttendance
                onChange={handleSearch}
                onSelect={handleSelectData}
                selectSearchData={selectSearchData}
                langValue={langValue}
                labelShow={labelShow}
                onSelectMonthPicker={onSelectMonthPicker}
                onSelectRangePicker={onSelectRangePicker}
                columns={columns}
                checkedValuecolumns={checkedValuecolumns}
                checked={defaultShowColumn}
                handleReset={handleReset}
                handleCheckOk={handleCheckOk}
                visible={visible}
                handleOnVisiblecolumns={handleOnVisiblecolumns}
                handleVisibleChange={handleVisibleChange}
                textErrorSelectColumn={textErrorSelectColumn}
              />

              <Table
                className="report-table"
                // className="timeAttendance-report-table"
                rowKey={(record) => record.index}
                onChange={handleChange}
                loading={loading}
                dataSource={dataAttendance}
                columns={newDataColumns}
                // scroll={_.size(dataAttendance) > 5 ? { x: 2500, y: `calc(100vh - 410px)` } : { x: 2500 }}
                // scroll={_.size(dataAttendance) > 5 ? { x: 2500, y: `calc(100vh - 410px)` } : {x: 'auto'}}
                scroll={{ x: 2500, y: `calc(100vh - 410px)` }}
                pagination={{
                  total: total,
                  showTotal: showTotal,
                  defaultCurrent: 1,
                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                  showSizeChanger: true,
                  locale: { items_per_page: '' },
                  onChange: handlePagination,
                  onShowSizeChange: handleSizeChange,
                }}
              />
              {dataAttendance.length !== 0 ? (
                <div className="total-items-timeAttendance">
                  <span style={{ fontSize: '13px' }}>
                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                    {` ${total || 0} `}
                    <FormattedMessage id="lblitems" defaultMessage="items" />
                  </span>
                </div>
              ) : null}
              <Modal
                title={<FormattedMessage id="reportTimeAttendanceModalLblTitle" defaultMessage="Image" />}
                visible={visibleModal}
                onCancel={handleClose}
                footer={[
                  <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" key="back" onClick={handleClose}>
                    <FormattedMessage id="reportTimeAttendanceModalBtnClose" defaultMessage="Close" />
                  </Button02>,
                ]}
              >
                <Row>
                  <Col span={12}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      <FormattedMessage id="reportTimeAttendanceModalLblProfile" defaultMessage="Profile" />
                    </p>
                    <div className="timeAttendance-modal-col-img-profile">
                      <img className="modal-col-img" style={{ width: '100%', height: '300px' }} src={profile} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{workCheck}</p>
                    <div className="timeAttendance-modal-col-img-clockin">
                      <img className="modal-col-img" style={{ maxWidth: '240px', height: '300px' }} src={avatar} />
                    </div>
                  </Col>
                </Row>
              </Modal>
            </Card>
          </div>
        ) : null
      }
    </PageSettings.Consumer>
  );
};
