import React, { useState, useContext } from 'react';
import { Table, Divider, Col, Row, Icon, Popover, Modal, Upload, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { MaintenanceContext } from './maintenance-context';
import Button01 from '../v2/button_01';
import Button03 from '../v2/button_03';

export default function MaintenanceAttachment(props) {
  const intl = useIntl();
  const { setVisible } = props;
  const { mainState, setMainState, fncMain } = useContext(MaintenanceContext);
  const [visiblePopover, setVisiblePopover] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'i',
      key: 'i',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'maintenanceModalAttachmentColumnsAttachmentName', defaultMessage: 'Attachment Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      width: 220,
      render: (text, record, index) => (
        <div style={{ width: '170px' }}>
          <span className="attachmentName-hidden-text">{record.attachmentName}</span>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'maintenanceModalAttachmentColumnsFile', defaultMessage: 'File' }),
      dataIndex: 'file',
      key: 'file',
      width: 300,
      render: (text, record, index) => fncFile(record, index),
    },
    {
      title: intl.formatMessage({ id: 'maintenanceModalAttachmentColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      render: (text, record, index) => (
        <div style={{ width: '380px' }}>
          <span className="attachmentName-hidden-text">{record.remark}</span>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 70,
      render: (text, record, index) => fncActionTable(record, index),
    },
  ];

  const fncFile = (record, index) => {
    return (
      <div className="maintenance-column-file">
        {/* {record.file.map((item, index) =>
          <div className='maintenance-column-file-text' onClick={() => fncMain.modalEditAttachment(record, index)}>
            <span className='maintenance-column-file-name'>{item.name}</span>
            {index !== record.file.length - 1 ? (
              <span>,</span>
            ) : null}
          </div>
        )} */}

        {/* {record.file.map((item, index) => {
          return (
            <>
              <span 
                onClick={() => fncMain.modalEditAttachment(record, index)} 
                className='maintenance-column-file-name'
              >
                {item.name}
              </span>
              {index !== record.file.length - 1 ? (
                <span>,</span>
              ) : null}
            </>
          )
        })} */}

        <Upload
          className="attachment-upload"
          listType="picture-card"
          fileList={record.file}
          onPreview={() => fncMain.modalEditAttachment(record)}
          beforeUpload={() => {
            return false;
          }}
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: false,
          }}
        />
      </div>
    );
  };

  const modalConfirmDelete = (record, index) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const filteredItems = mainState.attachmentData.filter((item) => item.id !== record.id);
        setMainState.setAttachmentData(filteredItems);
      },
      onCancel() {},
    });
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);

    // setVisiblePopover(true);
    // setTimeout(() => {
    //   setVisiblePopover(false);
    // }, 1000);
  };

  const fncActionTable = (record, index) => {
    return (
      <div>
        <Popover
          key={record.id}
          placement="leftTop"
          trigger="hover"
          // visiblePopover={visiblePopover}
          // onVisibleChange={handleOnVisiblePopover}
          // zIndex={999}
          content={
            <div className="table-action-columns">
              <>
                <Button
                  style={{ width: 100 }}
                  type="link"
                  ghost
                  onClick={() => {
                    // setVisiblePopover(false);
                    fncMain.modalEditAttachment(record, index);
                  }}
                >
                  <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                </Button>
              </>

              <>
                <Button
                  style={{ width: 100 }}
                  type="link"
                  ghost
                  onClick={() => {
                    // setVisiblePopover(false);
                    modalConfirmDelete(record);
                  }}
                >
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </>
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      </div>
    );
  };

  return (
    <div className="attachment">
      <div className="maintenance-container-header">
        <Row className="maintenance-container-row">
          <Col span={12} className="maintenance-header-lift">
            <span className="maintenance-header-title">
              <FormattedMessage id="maintenanceModalAttachmentTitleAttachment" defaultMessage="Attachment" />
            </span>
          </Col>
          <Col span={12} className="maintenance-header-right">
            <Button01 className="maintenance-header-button" type="link" onClick={() => setVisible(true)}>
              {' '}
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button01>
          </Col>
        </Row>
      </div>
      <Divider className="maintenance-sub-divider" />
      <div className="maintenance-sub-report-table">
        <Table
          size="middle"
          rowKey={(record) => record.id}
          // onRow={(record, index) => {
          //   return {
          //     onClick: () => fncMain.modalEditAttachment(record, index)
          //   };
          // }}
          dataSource={mainState.attachmentData}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
}
