import React, { useContext } from 'react'
import { Tabs, Card, Badge } from 'antd';
import { PageEngagement } from '../../../config/page-settings';
import stylesEngFilter from '../css/engagement-filter.css';
import CustomerFilter from './filter-customer';
import CustomerList from './customer';
import Postby from './postBy'
import PostbyFilter from './filter-post-by'
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

const { TabPane } = Tabs;

export default () => {
  const { initialLoad, tabs, customers, filterCustomer, customerCountChecked, customerCountAll, customerCheckAll, customerIsFilter, fnc, filterOrganization,
    memberCheckAll, memberCountChecked, memberCountAll, customerIndeterminate, members, memberOrganizationSelect, memberIndeterminate,
    customerPageLoading, customerPageHasMore, memberPageLoading, memberPageHasMore
  } = useContext(PageEngagement);

  const onChangeTab = _.debounce((activeKey) => {
    fnc.handleChangeTabs(activeKey);
  }, 1000);

  const onSearch = (value) => {
    fnc.handleSearchCustomer(value);
  }

  const onSelectTeam = (orgId) => {
    fnc.handleSelectCustomerOrganization(orgId);
  }

  const onSelectActivities = (activitiesId) => {
    fnc.handleSelectCustomerActivity(activitiesId);
  }

  const onSelectCustomer = (customerId) => {
    fnc.handleChangeSelectCustomer(customerId);
  }

  const onLoadMoreCustomer = (page) => {
    fnc.handleChangeCustomerPage(page);
  }

  const onLoadMoreMember = (page) => {
    fnc.handleChangeMemberPage(page)
  }

  const onSelectTeamPostBy = (orgId) => {
    fnc.handleSelectMemberOrganization(orgId);
  }
  const onChecked = (e) => {
    fnc.handleCheckAll(e.target.checked);
  }

  const onCheckAllMember = (e) => {
    fnc.handleCheckMemberAll(e.target.checked);
  }

  const onSelectMember = (memberId) => {
    fnc.handleChangeSelectMember(memberId);
  }

  return (
    <div className='engagement-filter'>
      <Card className='engagement-filter-card'>
        <Tabs className='engagement-filter-tabs' defaultActiveKey={tabs} onChange={onChangeTab} >
          <TabPane className='engagement-filter-tabpane'
            tab={
              <Badge dot={(!initialLoad && (customerCheckAll === false || customerIsFilter === true)) ? true : false} offset={[7, -10]}>
                <span><FormattedMessage id='engagementTextTabPaneCustomer' defaultMessage='Customer' /></span>
              </Badge>
            }
            key='customer'
          >
            <div className='all-object-engagement'>
              <CustomerFilter
                filterCustomer={filterCustomer}
                customerIsFilter={customerIsFilter}
                customers={customers}
                customerCheckAll={customerCheckAll}
                customerCountChecked={customerCountChecked}
                customerCountAll={customerCountAll}
                customerIndeterminate={customerIndeterminate}
                onChecked={onChecked}
                onSearch={onSearch}
                onSelectTeam={onSelectTeam}
                onSelectActivities={onSelectActivities}
              />

              <CustomerList
                customers={customers}
                customerPageLoading={customerPageLoading}
                customerPageHasMore={customerPageHasMore}
                onLoadMore={onLoadMoreCustomer}
                onSelectCustomer={onSelectCustomer}
              />
              <div style={{ justifyContent: 'center', display: 'flex' }}>

              </div>
            </div>
          </TabPane>

          <TabPane
            className='engagement-filter-tabpane'
            tab={
              <Badge dot={!initialLoad && memberCheckAll === false ? true : false} offset={[7, -10]}>
                <span><FormattedMessage id='engagementTextTabPanePostBy' defaultMessage='Post By' /></span>
              </Badge>}
            key='postBy'
          >
            <div className='all-object-engagement'>
              <PostbyFilter
                memberCheckAll={memberCheckAll}
                memberCountChecked={memberCountChecked}
                memberCountAll={memberCountAll}
                dataMember={filterOrganization}
                memberIndeterminate={memberIndeterminate}
                onCheckAllMember={onCheckAllMember}
                onSelectTeamPostBy={onSelectTeamPostBy}
                memberOrganizationSelect={memberOrganizationSelect}
              />
              <Postby
                members={members}
                memberPageLoading={memberPageLoading}
                memberPageHasMore={memberPageHasMore}
                onLoadMore={onLoadMoreMember}
                onSelectMember={onSelectMember}
              />
            </div>
          </TabPane>
        </Tabs>
      </Card>
    </div>
  )
}