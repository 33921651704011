import React from 'react'
import { Form, Modal, Row, Col, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_02 from '../../../../../components/v2/button_02';
import Button_01 from '../../../../../components/v2/button_01';
import LabeRequireForm from '../../../../../components/label-required-form';
import _ from 'lodash'
import { createBranchManagerList, updateBranchManagerList } from '../../../../../controllers/delivery-order/branch';
import { successNotification, errorNotification } from '../../../../../components/v2/notification';

const { Option } = Select

const ManagerModal = (props) => {
  const { visible, setVisible, record, setRecord, form, managerList, roleList, userList, branchData, setTrigger } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;
  const intl = useIntl();

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const mapRole = _.map(values.role, (item) => {
        const filterData = _.filter(roleList, (e) => { return e.per_gro_id === parseInt(item) })
        return {
          "roleId": _.get(filterData, '[0].per_gro_id'),
          "roleName": _.get(filterData, '[0].name')
        }
      })
      const mapUser = _.map(values.user, (item) => {
        const filterData = _.filter(userList, (e) => { return e.mem_com_id === parseInt(item) })
        return {
          "userId": _.get(filterData, '[0].mem_com_id'),
          "userName": _.get(filterData, '[0].name'),
          "userImage": _.get(filterData, '[0].profile_img')
        }
      })
      const body = {
        "branchId": _.get(branchData, 'id'),
        "branchCode": _.get(branchData, 'branchCode'),
        "branchName": _.get(branchData, 'branchName'),
        "branchManagerCode": _.get(values, 'manager'),
        "roleIds": mapRole,
        "userIds": mapUser
      }
      const response = record ? await updateBranchManagerList(_.get(record, 'id'), body) : await createBranchManagerList(body)
      if (_.get(response, 'status') === 200) {
        successNotification(_.get(response, 'data.status.message'))
        setTrigger(cur => !cur)
        setVisible(false)
        resetFields()
        setRecord()
      } else {
        errorNotification(_.get(response, 'data.status.message'))
      }

    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
    setRecord()
  }

  return (
    <Modal
      title={
        record
          ? intl.formatMessage({ id: `managerModalTilteCreate`, defaultMessage: 'Edit Manager' })
          : intl.formatMessage({ id: `managerModalTilteEdit`, defaultMessage: 'Add New Manager' })
      }
      centered={true}
      width={600}
      visible={visible}
      onCancel={handleCancel}
      bodyStyle={{ padding: '8px 24px 24px 24px' }}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form colon={false} form={form}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'managerModalRole', defaultMessage: 'Role' })} req={false} />}
            >
              {getFieldDecorator('role', {
                initialValue: record && _.size(record.roleIds) > 0 ? _.map(_.get(record, 'roleIds'), (item) => { return _.toString(item.roleId) }) : [],
                rules: [
                  {
                    required: getFieldValue('user') ? false : true,
                    message: intl.formatMessage({ id: 'managerModalReqRole', defaultMessage: 'Please select user' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'managerModalPlaceholderRole', defaultMessage: 'Select Role' })}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  mode='multiple'
                  maxTagCount={3}
                >
                  {
                    _.map(roleList, (item) => (
                      <Option key={item.per_gro_id}>
                        {item.name}
                      </Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'managerModalUser', defaultMessage: 'User' })} req={false} />}
            >
              {getFieldDecorator('user', {
                initialValue: record && _.size(record.userIds) > 0 ? _.map(_.get(record, 'userIds'), (item) => { return _.toString(item.userId) }) : [],
                rules: [
                  {
                    required: getFieldValue('role') ? false : true,
                    message: intl.formatMessage({ id: 'managerModalReqUser', defaultMessage: 'Please select role' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'managerModalPlaceholderUser', defaultMessage: 'Select User' })}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  mode='multiple'
                  maxTagCount={3}
                >
                  {
                    _.map(userList, (item) => (
                      <Option key={item.mem_com_id}>
                        {item.name}
                      </Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'managerModalManage', defaultMessage: 'Manage or Approve' })} req={true} />}
            >
              {getFieldDecorator('manager', {
                initialValue: record && _.size(record.branchManagerCode) > 0 ? _.map(_.get(record, 'branchManagerCode'), (item) => { return _.toString(item.code) }) : [],
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'managerModalReqManage', defaultMessage: 'Please select manage or approve' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'managerModalPlaceholderManager', defaultMessage: 'Select Manage or Approve' })}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  mode='multiple'
                  maxTagCount={3}
                >
                  {
                    _.map(managerList, (item) => (
                      <Option key={item.code}>
                        {item.name}
                      </Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ManagerModalForm = Form.create({
  name: 'warehouse-form',
})(ManagerModal);

export default ManagerModalForm;

