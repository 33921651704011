import React from 'react'
import { Select } from 'antd'
import '../css/action-search.css'
import { FormattedMessage } from 'react-intl'

const { Option } = Select

export default (props) => {
  const { handleSelectGroupBy, selectGroupBy } = props
  return (
    <div>
      <span style={{ marginRight: '10px' }}><FormattedMessage id="monitorTextGroupBy" defaultMessage="Group by" /> : </span>
      <Select
        className='action-groupby-select'
        placeholder='Select Group By'
        onSelect={handleSelectGroupBy}
        value={selectGroupBy}
      >
        <Option key='team'>
          <FormattedMessage id="userMgntUsersDetailLblTeam" defaultMessage="Team" />
        </Option>
        <Option key='customer'>
          <FormattedMessage id="customerTableCardHeader" defaultMessage="Customer" />
        </Option>
      </Select>
    </div>
  )
}
