import httpClient from '../../components/axiosClient';

const getRole = async () => {
  const comId = localStorage.getItem('comId');
  try {
    const response = await httpClient.get(`/company/${comId}/role`);
    if (response.status === 200) {
      return (response);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

export { getRole }
