import React, { useContext } from 'react'
import _ from 'lodash'
import { Modal, Form, Row, Col, Select, Input } from 'antd'
import Button01 from '../../../../components/v2/button_01'
import Button02 from '../../../../components/v2/button_02'
import { FormattedMessage, useIntl } from 'react-intl'
import LabeRequireForm from '../../../../components/label-required-form/index'
import { updateStatusTicket } from '../../../../controllers/ticket/ticket'
import { successNotification, errorNotification } from '../../../../components/v2/notification'
import { AllticketContext } from '../../allticket-context'

const { Option } = Select
const { TextArea } = Input

const ModalComment = (props) => {
  const { visible, setVisible, commentData, viewData, form, setTrigger } = props
  const { getFieldDecorator, resetFields, validateFields } = form
  const { state, setState, fnc } = useContext(AllticketContext);
  const { setEvent } = setState;
  const intl = useIntl()

  const TitleModal = () => {
    return (
      <div>
        {_.get(commentData, 'children')}・{_.get(viewData, 'ticketNo')}
      </div>
    )
  }

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        const body = {
          "reason": _.get(values, 'reason'),
          "remark": _.get(values, 'remark'),
          "statusCode": _.get(commentData, 'value')
        }
        const response = await updateStatusTicket(_.get(viewData, 'ticketId'), body)
        if (_.get(response, 'data.status.code') === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setVisible(false)
          resetFields()
          setTrigger(curent => !curent)
          setEvent(curent => !curent)
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      } catch (error) {
        errorNotification(_.get(error, 'response.data.status.message'))
      }
    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  return (
    <Modal
      title={<TitleModal />}
      visible={visible}
      centered={true}
      width={373}
      onCancel={handleCancel}
      bodyStyle={{ padding: '12px 24px' }}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseStatusReasonText', defaultMessage: 'Reason' })} req={true} />
              }
            >
              {getFieldDecorator('reason', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalTravelExpenseStatusReasonValidate', defaultMessage: 'Please Select Reason' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalTravelExpenseStatusReasonPlaceholder', defaultMessage: 'Select Reason' })}
                >
                  {
                    _.map(_.get(viewData, 'reasonList'), (item) => (
                      <Option key={item}>
                        {item}
                      </Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseStatusRemarkText', defaultMessage: 'Remark' })} req={false} />
              }
            >
              {getFieldDecorator('remark', {
              })(
                <TextArea
                  autosize={{ minRows: 3, maxRows: 3 }}
                  maxLength={250}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModalCommentForm = Form.create({
  name: 'modal_form',
})(ModalComment);

export default ModalCommentForm
