import React from 'react';
import { Avatar, Divider, Icon, Tag } from 'antd';
import Button_01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import './css/index.css';
import { FormattedMessage } from 'react-intl';

export default (props) => {
  const { intl, task, setVisibleTaskDetailModal, setMainTaskId, setMainTaskIdAssign, setVisibleAssignee, LangCode } = props;
  const assignee = _.get(task, 'assignee') || [];
  const taskName = _.get(task, 'taskName') || '';
  const taskNo = _.get(task, 'taskNo') || '';
  const taskId = _.get(task, 'taskId');
  const statusColor = _.get(task.status, 'statusColor') || '';
  const statusName = _.get(task.status, 'statusName') || '';
  const taskType = _.get(task.taskType, 'taskTypeName') || '';
  const priority = _.get(task.priority, 'priorityName') || '';
  const customerName = _.get(task, 'customerName') || '';
  const contactName = _.get(task.contact[0], 'name') || '';
  const contactPhone = _.get(task.contact[0], 'phone') || '';
  const startDate =
    _.get(task, 'startDateView') && _.get(task, 'startTimeView') ? _.get(task, 'startDateView') + ' ' + _.get(task, 'startTimeView') : '';
  const endDate =
    _.get(task, 'endDateView') && _.get(task, 'endTimeView') ? _.get(task, 'endDateView') + ' ' + _.get(task, 'endTimeView') : '';
  const deliveryAddress = _.get(task, 'deliveryAddress') || '';
  const remark = _.get(task, 'remark') || '';
  const reference = _.get(task, 'reference') || '';
  const pathTaskStatus = _.get(task, 'pathTaskStatus') || '';

  const handleOnClickTask = (taskId) => {
    if (taskId) {
      setMainTaskId(taskId);
      setVisibleTaskDetailModal(true);
    }
  };

  const headerButton = (taskId) => {
    if (taskId) {
      setMainTaskIdAssign(taskId);
      setVisibleAssignee(true);
    }
  };

  const OneAssignee = ({ assignee }) => {
    const pathImage = _.get(assignee[0], 'pathImage') || '';
    const fullname = _.get(assignee[0], 'fullname') || '';
    const phone = _.get(assignee[0], 'phone') || '';
    return (
      <div className="popover-container-map-header-task">
        <div className="popover-container-map-header-avatar">
          <Avatar src={pathImage} shape="circle" size="large" />
        </div>
        <div className="popover-container-map-header-detail">
          <div className="popover-container-map-header-fullname">{fullname}</div>
          <div className="popover-container-map-header-phone">{phone}</div>
        </div>
      </div>
    );
  };

  const MoreThanOneAssignee = ({ assignee }) => {
    return (
      <div className="popover-container-map-header-task">
        {_.size(assignee)} <FormattedMessage id={`monitorMapPopoverAssignee${LangCode}`} defaultMessage="Assignees" />
      </div>
    );
  };

  const NoAssignee = ({ assignee }) => {
    return (
      <div className="popover-container-map-header-task">
        <FormattedMessage id={`monitorMapPopoverNoAssignee${LangCode}`} defaultMessage="No Assignee" />
      </div>
    );
  };

  const HeaderTask = ({ assignee }) => {
    return (
      <div className="popover-task-map-container-header">
        {_.size(assignee) === 1 && <OneAssignee assignee={assignee} />}
        {_.size(assignee) > 1 && <MoreThanOneAssignee assignee={assignee} />}
        {_.size(assignee) < 1 && <NoAssignee assignee={assignee} />}
        <div>
          <Button_01 className="button-assign-popover" type="primary" btnsize="wd_at" onClick={() => headerButton(taskId)}>
            <FormattedMessage id={`monitorMapPopoverAssignee${LangCode}`} defaultMessage="Assignees" />
          </Button_01>
        </div>
      </div>
    );
  };

  const HeaderTaskNo = ({ taskNo, statusColor, statusName }) => {
    return (
      <div className="popover-task-map-container-header-task" onClick={() => handleOnClickTask(taskId)}>
        <div className="tag-task-map-taskno">{taskNo}</div>
        <div>
          <Tag className="tag-task-map" color={statusColor}>
            {statusName}
          </Tag>
        </div>
      </div>
    );
  };

  const BodyTaskDetail = ({
    taskName,
    taskType,
    priority,
    customerName,
    contactName,
    contactPhone,
    startDate,
    endDate,
    deliveryAddress,
    remark,
    reference,
  }) => {
    const dateConcat = startDate && endDate ? startDate + ' - ' + endDate : '';
    const dateShow = _.truncate(dateConcat, { length: 40 });

    return (
      <div className="popover-task-map-container-body">
        <div className="popover-task-map-row">
          <span className="popover-task-map-row-title">
            <FormattedMessage id={`monitorMapPopoverTaskName${LangCode}`} defaultMessage="Task Name" />:{' '}
          </span>
          <p className="popover-task-map-row-info">{taskName}</p>
        </div>
        <div className="popover-task-map-row">
          <span className="popover-task-map-row-title">
            <FormattedMessage id={`monitorMapPopoverTaskType${LangCode}`} defaultMessage="Task Type" />:{' '}
          </span>
          <p className="popover-task-map-row-info">{taskType}</p>
        </div>
        <div className="popover-task-map-row">
          <span className="popover-task-map-row-title">
            <FormattedMessage id={`monitorMapPopoverPriority${LangCode}`} defaultMessage="Priority" />:{' '}
          </span>
          <p className="popover-task-map-row-info">{priority}</p>
        </div>
        <div className="popover-task-map-row">
          <span className="popover-task-map-row-title">
            <FormattedMessage id={`monitorMapPopoverCustomer${LangCode}`} defaultMessage="Customer" />:{' '}
          </span>
          <p className="popover-task-map-row-info">{customerName}</p>
        </div>
        <div className="popover-task-map-row">
          <span className="popover-task-map-row-title">
            <FormattedMessage id={`monitorMapPopoverContact${LangCode}`} defaultMessage="Contact" />:{' '}
          </span>
          <p className="popover-task-map-row-info">
            {contactName}, {contactPhone}
          </p>
        </div>
        <div className="popover-task-map-row">
          <span className="popover-task-map-row-title-date">
            <FormattedMessage id={`monitorMapPopoverDateTime${LangCode}`} defaultMessage="Date Time" />:{' '}
          </span>
          <p className="popover-task-map-row-info-date" title={dateConcat}>
            {dateShow}
          </p>
        </div>
        <div className="popover-task-map-row">
          <span className="popover-task-map-row-title">
            <FormattedMessage id={`monitorMapPopoverReference${LangCode}`} defaultMessage="Reference" />:{' '}
          </span>
          <p className="popover-task-map-row-info">{reference}</p>
        </div>
        <div className="popover-task-map-row-overflow-address">
          <span className="popover-task-map-row-title-address">
            <FormattedMessage id={`monitorMapPopoverAddress${LangCode}`} defaultMessage="Address" />:{' '}
          </span>
          <p className="popover-task-map-row-info-overflow" title={deliveryAddress}>
            {deliveryAddress}
          </p>
        </div>
        <div className="popover-task-map-row-overflow-remark">
          <span className="popover-task-map-row-title">
            <FormattedMessage id={`monitorMapPopoverRemark${LangCode}`} defaultMessage="Remark" />:{' '}
          </span>
          <p className="popover-task-map-row-info-overflow" title={remark}>
            {remark}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="popover-task-map-container">
      <HeaderTask assignee={assignee} />
      <HeaderTaskNo taskNo={taskNo} statusColor={statusColor} statusName={statusName} />
      <BodyTaskDetail
        taskName={taskName}
        taskType={taskType}
        priority={priority}
        customerName={customerName}
        contactName={contactName}
        contactPhone={contactPhone}
        startDate={startDate}
        endDate={endDate}
        deliveryAddress={deliveryAddress}
        remark={remark}
        reference={reference}
      />
    </div>
  );
};
