import React, { useState, useEffect, useContext } from 'react';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Col, Row, Form, Select, DatePicker, Input, Dropdown, Menu, Button, Card, Divider, Icon } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css';
import moment from 'moment';
import { AllticketContext } from '../allticket-context';
import LabeRequireForm from '../../../../../components/label-required-form';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const Filtter = (props) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(AllticketContext);
  const {
    ticketData,
    changeTab,
    isLoading,
    fillter,
    requestDetailData,
    vendorFilterdata,
    changeTicketNo,
    changeVender,
    brandData,
    modelData,
    totalBrand,
    totalModel,
    vendorData,
    statusData,
  } = state;
  const { setChangeStatus, setFillter, setChangeTab, setChangeTicketNo, setEvent, setTotalBrand, setTotalModel,setChangeVender } = setState;
  const { getApiSearch, changeToApproval, changeToReject, getApiApprove, getApiReject } = fnc;
  const { columns, form, tab } = props;
  const [rangeDate, setRangeDate] = useState(false);
  const paddingFilter = '2px 16px 0px';
  const statusLsit = _.get(ticketData, 'data.statusList');

  const handleChangeDate = (value) => {
    setRangeDate(value);
    setFillter({
      ...fillter,
      ['pmdatestart']: moment(_.get(value, '[0]')).format(dateFormat),
      ['pmdateto']: moment(_.get(value, '[1]')).format(dateFormat),
    });
    handleFillter(value);
  };

  const handleFillter = (value, code) => {
    console.log('valueFill', value);
    setFillter({ ...fillter, [code]: value });
  };

  const handleChangeTicketNo = (value, code) => {
    console.log('valueFill', value);
    if (value === '') {
      handleFillter(value, code);
      setChangeTicketNo(value);
    } else {
      setChangeTicketNo(_.size(requestDetailData) > 0 ? _.get(requestDetailData, 'pono') : null);
      handleFillter(value, code);
    }
  };

  const handleChangeVender = (value, code) => {
    console.log('valueFill', value);
    if (value === '') {
      handleFillter(value, code);
      setChangeVender(value);
    } else {
      setChangeVender(_.size(vendorFilterdata) > 0 ? _.get(vendorFilterdata, 'vendorSupplierId') : null);
      handleFillter(value, code);
    }
  };

  return (
    <div>
      <div>
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalReceiveVendorText', defaultMessage: 'Vendor or Supplier' })}
                    req={false}
                    bold={true}
                  />
                }
              >
                <Select
                  placeholder={intl.formatMessage({ id: 'modalReceiveVendorPlaceholeder', defaultMessage: 'Select Vendor or Supplier' })}
                  showSearch
                  allowClear
                  value={changeVender ? changeVender : undefined}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(e) => {
                    handleFillter(e, 'vender');
                    setChangeVender(e, 'vender');
                  }}
                >
                  {_.map(vendorData, (item) => (
                    <Option key={item.customerId}>{item.customerName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'recivePOPONo', defaultMessage: 'PO No.' })} req={false} bold={true} />
                }
              >
                <Input
                  className="inputWidth"
                  allowClear
                  onChange={(e) => {
                    handleFillter(e.target.value, 'pono');
                    setChangeTicketNo(e.target.value);
                  }}
                  value={changeTicketNo ? changeTicketNo : undefined}
                  placeholder={intl.formatMessage({ id: 'recivePOPlaceholderPONo', defaultMessage: 'Enter PO No.' })}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'recivePOStatus', defaultMessage: 'Status' })} req={false} bold={true} />
                }
              >
                <Select
                  allowClear
                  placeholder={intl.formatMessage({ id: 'recivePOPlaceholderStatus', defaultMessage: 'Select Status' })}
                  showSearch
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(e) => {
                    handleFillter(e, 'status');
                  }}
                  value={_.get(fillter,'status') ? _.get(fillter,'status') : undefined }
                >
                  {_.map(statusData, (item) => (
                    <Option key={item.statusCode}>{item.txt}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalFromPODate', defaultMessage: 'From PO Date' })}
                    req={false}
                    bold={true}
                  />
                }
              >
                <DatePicker
                  className="inputWidth"
                  placeholder={intl.formatMessage({ id: 'modalPlaceholderPODate', defaultMessage: 'Select PO Date' })}
                  format="DD/MM/YYYY"
                  onChange={(e) => handleFillter(e ? moment(e).format('YYYY-MM-DD') : undefined, 'startdate')}
                  allowClear={true}
                  value={_.get(fillter,'startdate') ? moment(_.get(fillter,'startdate')) : undefined }
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ padding: paddingFilter }}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalToPODate', defaultMessage: 'To PO Date' })}
                    req={false}
                    bold={true}
                  />
                }
              >
                <DatePicker
                  className="inputWidth"
                  placeholder={intl.formatMessage({ id: 'modalPlaceholderPODate', defaultMessage: 'Select PO Date' })}
                  format="DD/MM/YYYY"
                  allowClear={true}
                  onChange={(e) => handleFillter(e ? moment(e).format('YYYY-MM-DD') : undefined, 'enddate')}
                  value={_.get(fillter,'enddate') ? moment(_.get(fillter,'enddate')) : undefined }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const FillterForm = Form.create({
  name: 'filter_form',
  mapPropsToFields() {},
})(Filtter);

export default FillterForm;
