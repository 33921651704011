import React, { useState, useEffect, useContext } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { ApprovalContext } from '../../approval-context';
import _ from 'lodash';
import { Table, Col, Row, Form, Select, DatePicker, Input, Dropdown, Menu, Button, Card } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button01 from '../../../../components/v2/button_01';
import '../css/index.css';
import moment from 'moment';
import NoDataBackground from '../../../../components/no-data-page';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const Filtter = (props) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(ApprovalContext);
  const { searchList, changeTab, isLoading, fillter } = state;
  const { setChangeStatus, setFillter, setChangeTab } = setState;
  const { getApiSearch, changeToApproval, changeToReject, getApiApprove, getApiReject } = fnc;
  const { columns, form, tab } = props;
  const [rangeDate, setRangeDate] = useState(false);
  const paddingFilter = '2px 16px 0px';

  const handleChangeDate = (value) => {
    console.log('DateFilter', value);
    if (_.size(value) > 0) {
      setFillter({
        ...fillter,
        ['pmdatestart']: moment(_.get(value, '[0]')).format(dateFormat),
        ['pmdateto']: moment(_.get(value, '[1]')).format(dateFormat),
      });
    } else {
      setFillter({
        ...fillter,
        ['pmdatestart']: undefined,
        ['pmdateto']: undefined,
      });
    }
  };

  const handleFillter = (value, code) => {
    setFillter({ ...fillter, [code]: value });
  };
  return (
    <div  style={{ padding: '16px'}}>
      <Form>
        <Row gutter={24}>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="ToReviewRequestNoForm" defaultMessage="Request No." />

              <Input
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'ToReviewRequestNoEnterForm', defaultMessage: 'Enter Request No.' })}
                onChange={(e) => handleFillter(e.target.value, 'reno')}
                allowClear
              ></Input>
            </Form.Item>
          </Col>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="ToReviewRequestorForm" defaultMessage="Requestor" />

              <Input
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'ToReviewRequestorEnterForm', defaultMessage: 'Enter Requestor' })}
                onChange={(e) => handleFillter(e.target.value, 'requestor')}
                allowClear
              ></Input>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="ToReviewSubmittedDateForm" defaultMessage="Submitted Date" />

              <RangePicker
                style={{ fontSize: '14px', fontFamily: 'tabular-nums' }}
                className="inputWidth"
                onChange={handleChangeDate}
                placeholder={[
                  intl.formatMessage({
                    id: 'ToReviewSubmittedDateFrom',
                    defaultMessage: 'Select From Date',
                  }),
                  intl.formatMessage({
                    id: 'ToReviewSubmittedDateTo',
                    defaultMessage: 'Select To Date',
                  }),
                ]}
                format="ddd, MMM DD YYYY"
                allowClear={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const FillterForm = Form.create({
  name: 'filter_form',
  mapPropsToFields() {},
})(Filtter);

export default FillterForm;
