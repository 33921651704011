import React, { useContext } from 'react';
import { DeliveryShipmentDetailContext } from '..';
import _ from 'lodash';
import NoDataBackground from '../../../../../components/no-data-page';
import { FormattedMessage } from 'react-intl';
import { Divider, Icon } from 'antd';

const DeliveryOrderLogs = () => {
  const { logData } = useContext(DeliveryShipmentDetailContext);

  const iconColor = { color: '#6490CF', marginRight: '16px', fontSize: '16px' };
  const fontnormal = { color: '#050505', marginRight: '5px', fontWeight: 'normal' };
  const fontBold = { color: '#050505', marginRight: '5px', fontWeight: 'bold' };
  const WhiteSpace = { whiteSpace: 'nowrap' };

  console.log('LogData', logData);

  const ActionText = (props) => {
    const { item } = props;
    const isIcon = CheckIcon(item);
    const isText = CheckText(item);
    const isStatus = CheckStatus(item);
    const isAction = CheckAction(item);
    return (
      <div style={{ display: 'flex', }}>
        <div>{isIcon}</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>
            <span style={{ color: '#050505', fontWeight: 'bold', marginRight: '5px' }}>{_.get(item, 'createdByName')} </span>
          </div>
          <div>{isText}</div>
          <div>{isStatus}</div>
          <div>{isAction}</div>
          <div>
            <span style={fontnormal}>
              <FormattedMessage id="ActionLogDOOn" defaultMessage="on" />
            </span>
          </div>
          <div>
            <span style={{ color: '#65676B' }}>{_.get(item, 'createdDate')} </span>
          </div>
        </div>
      </div>
      // <div style={{ display: 'flex' }}>
      //   <div>{isIcon}</div>
      //   <div>
      //     <span style={{ color: '#050505', fontWeight: 'bold', marginRight: '5px' }}>{_.get(item, 'createdByName')} </span>
      //   </div>
      //   <div>{isText}</div>
      //   <div>{isStatus}</div>
      //   <div>{isAction}</div>
      //   <div>
      //     <span style={fontnormal}>
      //       <FormattedMessage id="ActionLogDOOn" defaultMessage="on" />
      //     </span>
      //   </div>
      //   <div>
      //     <span style={{ color: '#65676B' }}>{_.get(item, 'createdDate')} </span>
      //   </div>
      // </div>
    );
  };

  const CheckIcon = (props) => {
    const { changeCode, dataTo, statusCode } = props;
    if (statusCode === 'confirmed' || statusCode === 'completed' ) {
      return <Icon type="check-circle" style={iconColor} />;
    } else {
      if (changeCode === 'create' || changeCode === 'created-parcel' ||changeCode === 'import' ) {
        return <Icon type="plus-circle" style={iconColor} />;
      } else if (changeCode === 'marked-paid') {
        return <Icon type="check-circle" style={iconColor} />;
      } else {
        return <Icon style={iconColor} type="edit" />;
      }
    }
  };

  const CheckText = (props) => {
    const { changeCode } = props;
    let text = '';
    if (changeCode === 'create') {
      text = <FormattedMessage id="ActionLogDOCreated" defaultMessage="created delivery order No." />;
    } else if (changeCode === 'created-parcel') {
      text = <FormattedMessage id="ActionLogDOCreatedParcel" defaultMessage="created  Parcel No." />;
    } else if (changeCode === 'status') {
      text = <FormattedMessage id="ActionLogDOChangedOrder" defaultMessage="changed order status " />;
    } else if (changeCode === 'customer' || changeCode === 'recipient') {
      text = <FormattedMessage id="ActionLogDOChanged" defaultMessage="changed" />;
    } else if (changeCode === 'status-parcel') {
      text = <FormattedMessage id="ActionLogDOChangedParcel" defaultMessage="changed parcel no." />;
    } else if (changeCode === 'import') {
      text = <FormattedMessage id="ActionLogDOimported" defaultMessage="imported delivery order No." />;
    } else if (changeCode === 'marked-paid') {
      text = <FormattedMessage id="ActionLogDOMark" defaultMessage="marked this order as paid" />;
    } else {
      text = <FormattedMessage id="ActionLogDOEdit" defaultMessage="edit" />;
    }
    return <span style={fontnormal}>{text}</span>;
  };

  const CheckStatus = (props) => {
    const { changeCode } = props;
    let text = '';
    if (changeCode !== 'create' && changeCode !== 'created-parcel' && changeCode !== 'import' && changeCode !== 'marked-paid') {
      if (changeCode === 'total-shipping-price') {
        text = <FormattedMessage id="ActionLogDOTSP" defaultMessage="Total Shipping Price" />;
      }

      if (changeCode === 'customer') {
        text = <FormattedMessage id="ActionLogDOCustomer" defaultMessage="customer" />;
      }

      if (changeCode === 'recipient') {
        text = <FormattedMessage id="ActionLogDORecipient" defaultMessage="recipient" />;
      }

      if (changeCode === 'reference-no') {
        text = <FormattedMessage id="ActionLogDOReferenceNO" defaultMessage="Reference No." />;
      }

      if (changeCode === 'shipping-date') {
        text = <FormattedMessage id="ActionLogDOShippingDate " defaultMessage="Shipping Date" />;
      }

      if (changeCode === 'time-window') {
        text = <FormattedMessage id="ActionLogDOTimeWindow" defaultMessage="Time Window" />;
      }

      if (changeCode === 'remark') {
        text = <FormattedMessage id="ActionLogDORemark" defaultMessage="Remark" />;
      }
    }
    return <span style={text ? fontnormal : null}>{text}</span>;
  };

  const CheckAction = (props) => {
    const { changeCode, dataNo, dataFrom, dataTo } = props;
    if (
      changeCode !== 'create' &&
      changeCode !== 'created-parcel' &&
      changeCode !== 'import' &&
      changeCode !== 'marked-paid' &&
      changeCode !== 'remark'
    ) {
      const isPacel = changeCode === 'status-parcel' ? true : false;
      return (
        <div style={{ display: 'flex' }}>
          {isPacel ? (
            <span>
              <span style={fontBold}>{dataNo}</span>
              <span style={fontnormal}>
                <FormattedMessage id="ActionLogDOStatus" defaultMessage="status" />
              </span>
            </span>
          ) : null}
          {isPacel || changeCode === 'status' ? null : (
            <span style={fontnormal}>
              <FormattedMessage id="ActionLogDOFrom" defaultMessage="from" />{' '}
            </span>
          )}
          <span style={fontBold}>{dataFrom} </span>
          <span style={fontBold}>
            <FormattedMessage id="ActionLogDOTo" defaultMessage="to" />
          </span>{' '}
          <span style={fontBold}>{dataTo}</span>{' '}
        </div>
      );
    } else {
      if (changeCode !== 'marked-paid' && changeCode !== 'remark' ) {
        return <span style={fontBold}>{dataNo}</span>;
      }
    }
  };

  // _.get(item, 'changeCode') === 'status' ||
  // _.get(item, 'changeCode') === 'total-shipping-price' ||
  // _.get(item, 'changeCode') === 'customer' ||
  // _.get(item, 'changeCode') === 'recipient' ||
  // _.get(item, 'changeCode') === 'reference-no' ||
  // _.get(item, 'changeCode') === 'shipping-date' ||
  // _.get(item, 'changeCode') === 'time-window' ||
  // _.get(item, 'changeCode') === 'remark' ||
  // _.get(item, 'changeCode') === 'import' ||
  // _.get(item, 'changeCode') === 'status-parcel'

  return (
    <div style={{ height: 'calc(-165px + 100vh)', overflowY: 'auto', padding: '30px' }}>
      {_.size(logData) > 0 ? (
        _.map(logData, (item) => (
          <div>
            <ActionText item={item} />
            <Divider type="horizontal" />
          </div>
        ))
      ) : (
        <div>
          <NoDataBackground
            text1={{ id: 'noDataresourceActionLog1', text: 'No Data' }}
            text2={{ id: 'noDataresourceActionLog2', text: 'Action Log will appear here.' }}
          />
        </div>
      )}
    </div>
  );
};

export default DeliveryOrderLogs;
