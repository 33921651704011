import React, { useState, useEffect } from 'react'
import { Select, Form } from 'antd'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import LabeRequireForm from '../label-required-form'
import { getFromLocation } from '../../controllers/task/task-distance'

const { Option } = Select

const LoadmoreSource = (props) => {
  const intl = useIntl()
  const { form, data, setData, required, initialId,notSpec } = props
  const getFieldDecorator = form ? form.getFieldDecorator : null
  const [sourceList, setSourceList] = useState([])
  const [firstStep, setFirstStep] = useState(false)

  useEffect(() => {
    if (initialId) {
      setFirstStep(true)
    }
  }, [initialId])

  useEffect(() => {
    if (firstStep) getSourceList()
  }, [firstStep])

  const getSourceList = async () => {
    const response = await getFromLocation()
    setSourceList(_.get(response, 'companyAddressList.list'));
  }

  const handleSelect = (value, option) => {
    if (form) {
      setData(_.get(option, 'props.data'));
    } else {
      setData((prev) => ({ ...prev, source: value }))
    }
  }

  return (
    <Form.Item onClick={() => setFirstStep(true)} label={
      <LabeRequireForm
        text={intl.formatMessage({ id: 'sourceHeader', defaultMessage: 'Source' })}
        req={required}
      />
    }>
      {
        form ? getFieldDecorator('source', {
          initialValue: initialId,
          rules: [
            {
              required: required,
              message: intl.formatMessage({
                id: 'modalTaskSettingSourceVLD',
                defaultMessage: 'Please Select Source',
              }),
            },
          ],
        })(
          <Select
            onSelect={handleSelect}
            placeholder={intl.formatMessage({ id: 'sourcePlaceholder', defaultMessage: 'Select Source' })}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option key="notSpecified" value="notSpecified" data={{ id: "notSpecified" }}>
              ไม่กำหนด
            </Option>
            {
              _.map(sourceList, (item) => (
                <Option key={_.get(item, 'comAddressBookId')} value={_.get(item, 'comAddressBookId')} data={item}>
                  {_.get(item, 'addressName')}
                </Option>
              ))
            }
          </Select>
        ) : (
          <Select
            onSelect={handleSelect}
            placeholder={intl.formatMessage({ id: 'sourcePlaceholder', defaultMessage: 'Select Source' })}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={_.get(data, 'source')}
          >
            { notSpec ? null :
            <Option key="notSpecified" value="notSpecified" data={{ id: "notSpecified" }}>
              ไม่กำหนด
            </Option>
}
            {
              _.map(sourceList, (item) => (
                <Option key={_.get(item, 'comAddressBookId')} value={_.get(item, 'comAddressBookId')} data={item}>
                  {_.get(item, 'addressName')}
                </Option>
              ))
            }
          </Select>
        )
      }
    </Form.Item>

  )

}

LoadmoreSource.defaultProps = {
  required: true,
}

export default LoadmoreSource