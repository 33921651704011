import { Modal, Col, Row, Form, Select, Input,InputNumber } from 'antd';
import React, { useContext, useEffect } from 'react';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import { AllowanceContext } from './allowancecontext';
import _ from 'lodash';
import { updateAllowance, createAllowance, getDistance } from '../../../controllers/setting/allowanceapi';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import { event } from 'jquery';
const { Option } = Select;

const AllowanceEditListModal = (props) => {
  const { form, distance } = props;
  const { state, setState, fnc } = useContext(AllowanceContext);
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const { visibleEditList, allowanceListLeft, allowanceListRight, sourcList, destinationList, editList } = state;
  const { setVisibleEditList,setEditList,setEvent } = setState;
  const {  } = fnc;
  const paddinginputLeft = '16px 10px 0px 10px';
  const paddinginputRight = '16px 10px 0px 10px';
  const dataTask = _.get(allowanceListRight, 'taskTypeIdList');
  const dataSource = _.get(sourcList, 'list');
  const dataDestination = _.get(destinationList, 'list');

  useEffect(() => {
    handleSelectTask(getFieldValue('tasktype'))
  }, [getFieldValue('tasktype')])
  

  useEffect(() => {
    if (getFieldValue('source') && getFieldValue('destination')) {
      searchDistance(getFieldValue('source'), getFieldValue('destination'));
      
    }
  }, [getFieldValue('source'), getFieldValue('destination')]);

  const searchDistance = async (source, destination) => {
    try {
      let payload = {
        sourceId: source,
        destinationId: destination,
      };
      
      const response = await getDistance(payload);
      if (response.status.code === 1) {
        const responeDistance = _.get(response, 'data.distance')
        await setFieldsValue({ ['distance']: responeDistance });
        handleChangeDistance();
        // setDistance(_.get(response, 'data.distance'));
      }
    } catch (error) {}
  };

  const resetFiledsAll = () => {
    setVisibleEditList(false);
    resetFields();
    setEditList([]);
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) return;

      if (values) {
        console.log('valuesAllowance', values);
        try {
          const payload = {
            taskTypeId: values.tasktype,
            taskTypeName: _.get(_.filter(_.get(allowanceListRight, 'taskTypeIdList'), { taskTypeId: values.tasktype })[0], 'taskTypeName'),
            sourceId: values.source,
            destinationId: values.destination,
            distance: values.distance,
            allowance: values.allowancethb,
            driver1Allowance: values.driver1,
            driver2Allowance: values.driver2,
            tripAllowance: values.allowancetripthb,
            driver1TripAllowance: values.tripdriver1,
            driver2TripAllowance: values.tripdriver2,
          };
          console.log('payloadAllo', payload);
          const response = await updateAllowance(payload, _.get(editList, 'allowanceId'));
          console.log('response', response);

            successNotification(_.get(response.status, 'message'));
            setEvent(current => !current)

        } catch (error) {
          errorNotification(_.get(error.response.status, 'message'));
        }
      }

      resetFiledsAll();
    });
  };

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const handleDistance = () => {
    if (getFieldValue('source') && getFieldValue('destination')) {
      searchDistance(getFieldValue('source'), getFieldValue('destination'));
      handleChangeDistance();
    }
  };

  const handleSelectTask = (value) => {
    setFieldsValue({ ['allowancethb']: _.get(allowanceListRight, 'taskRate.default') });
    const drive1 = _.get(_.filter(_.get(allowanceListRight, 'taskRate.settingList'), { taskTypeId: value })[0], 'driver1Percent');
    const drive2 = _.get(_.filter(_.get(allowanceListRight, 'taskRate.settingList'), { taskTypeId: value })[0], 'driver2Percent');
    setFieldsValue({ ['driver1']: getFieldValue('allowancethb') * (drive1 / 100) });
    setFieldsValue({ ['driver2']: getFieldValue('allowancethb') * (drive2 / 100) });
  };

  const handleChangeAllowance = (value) => {
    const drive1 = _.get(_.filter(_.get(allowanceListRight, 'taskRate.settingList'), { taskTypeId: getFieldValue('tasktype') })[0], 'driver1Percent');
    const drive2 = _.get(_.filter(_.get(allowanceListRight, 'taskRate.settingList'), { taskTypeId: getFieldValue('tasktype') })[0], 'driver2Percent');
    setFieldsValue({ ['driver1']: value * (drive1 / 100) });
    setFieldsValue({ ['driver2']: value * (drive2 / 100) });
  };

  const handleChangeDistance = () => {
    console.log('triptest', getFieldValue('allowancetripthb'));
    _.map(_.get(allowanceListRight, 'distanceRate.settingList'), (item, index) => {
      if (getFieldValue('distance') > _.get(item, 'distanceFrom') && getFieldValue('distance') <= _.get(item, 'distanceTo')) {
        setFieldsValue({ ['allowancetripthb']: _.get(item, 'tripAllowance') });
      }
    });
    const drive1 = _.get(
      _.filter(_.get(allowanceListRight, 'tripRate.settingList'), { taskTypeId: getFieldValue('tasktype') })[0],
      'driver1Percent'
    );
    const drive2 = _.get(
      _.filter(_.get(allowanceListRight, 'tripRate.settingList'), { taskTypeId: getFieldValue('tasktype') })[0],
      'driver2Percent'
    );
    setFieldsValue({ ['tripdriver1']: getFieldValue('allowancetripthb') * (drive1 / 100) });
    setFieldsValue({ ['tripdriver2']: getFieldValue('allowancetripthb') * (drive2 / 100) });
    console.log('tripthb', drive1);
  };

  const handleChangeDrive1 = (e) => {
    console.log('drive1Edit', e);
    setFieldsValue({ driver2: getFieldValue('allowancethb') - e });
  };

  const handleChangeDrive2 = (e) => {
    console.log('drive1Edit', e);
    setFieldsValue({ driver1: getFieldValue('allowancethb') - e });
  };

  const handleChangeDriveTrip1 = (e) => {
    console.log('drive1Edit', e);
    setFieldsValue({ tripdriver2: getFieldValue('allowancetripthb') - e });
  };

  const handleChangeDriveTrip2 = (e) => {
    console.log('drive1Edit', e);
    setFieldsValue({ tripdriver1: getFieldValue('allowancetripthb') - e });
  };


  return (
    <Modal
      visible={visibleEditList}
      onCancel={() => resetFiledsAll()}
      centered={true}
      title={<FormattedMessage id="EditAllowance" defaultMessage="Edit Allowance" />}
      width={760}
      style={{ marginTop: '15px' }}
      bodyStyle={{padding: '15px 24px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={() => resetFiledsAll()} style={{ margin: 'unset' }}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form>
        <Row gutter={24}>
          <Col span={12} style={{ padding: '0px 10px 0px 10px' }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="allowanceTaskType" defaultMessage="Task Type" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('tasktype', {
                initialValue: _.get(editList, 'taskTypeId'),
                // initialValue: getFieldValue('tasktype'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormtasktype',
                      defaultMessage: 'Please Select Task Type',
                    }),
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'allowanceSelectTaskType', defaultMessage: 'Select Task Type' })}
                  onSelect={handleSelectTask}
                >
                  {dataTask &&
                    _.map(dataTask, (item, index) => {
                      return (
                        <Option key={_.get(item, 'taskTypeId')} value={_.get(item, 'taskTypeId')}>
                          {_.get(item, 'taskTypeName')}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="allowanceSource" defaultMessage="Source" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('source', {
                initialValue: _.get(editList, 'source.sourceId'),
                // initialValue: getFieldValue('source'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormSource',
                      defaultMessage: 'Please Select Source',
                    }),
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'allowanceSelectSource', defaultMessage: 'Select Source' })}
                >
                  {dataSource &&
                    _.map(dataSource, (item, index) => {
                      return (
                        <Option key={_.get(item, 'sourceId')} value={_.get(item, 'sourceId')}>
                          {_.get(item, 'addressName')}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: paddinginputRight }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="allowanceDestination" defaultMessage="Destination" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('destination', {
                initialValue: _.get(editList, 'destination.destinationId'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormDestination',
                      defaultMessage: 'Please Select Destination',
                    }),
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'allowanceSelectDestination', defaultMessage: 'Select Destination' })}
                  onSelect={handleDistance}
                >
                  {dataDestination &&
                    _.map(dataDestination, (item, index) => {
                      return (
                        <Option key={_.get(item, 'destinationId')} value={_.get(item, 'destinationId')}>
                          {_.get(item, 'addressName')}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="allowanceDistance" defaultMessage="Distance (km)" />
              </span>
              {getFieldDecorator('distance', { initialValue: _.get(editList, 'distance') })(
                <InputNumber
                  style={{ width: '100%' }}
                  disabled={true}
                  placeholder={intl.formatMessage({
                    id: 'allowanceDistance',
                    defaultMessage: 'Distance (km)',
                  })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------*/}
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="AllowanceTitleTHB" defaultMessage="Allowance (THB)" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('allowancethb', {
                initialValue: _.get(editList, 'allowance'),
                // initialValue: getFieldValue('allowancethb'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormTHB',
                      defaultMessage: 'Please Enter Allowance',
                    }),
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  onChange={handleChangeAllowance}
                  placeholder={intl.formatMessage({ id: 'allowanceEnterAllowance', defaultMessage: 'Enter Allowance (THB)' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="allowanceFormDriver1" defaultMessage="Driver 1 Allowance (THB)" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('driver1', {
                initialValue: _.get(editList, 'driver1Allowance'),
                // initialValue: getFieldValue('driver1'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormDriver1',
                      defaultMessage: 'Please Enter Driver 1',
                    }),
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'allowanceEnterDriver1', defaultMessage: 'Enter Driver 1 Allowance (THB)' })}
                  onChange={handleChangeDrive1}
                  max={getFieldValue('allowancethb')}
                  min={0}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: paddinginputRight }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="allowanceFormDriver2" defaultMessage="Driver 2 Allowance (THB)" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('driver2', {
                initialValue: _.get(editList, 'driver2Allowance'),
                // initialValue: getFieldValue('driver2'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormDriver2',
                      defaultMessage: 'Please Enter Driver 2',
                    }),
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'allowanceEnterDriver2', defaultMessage: 'Enter Driver 2 Allowance (THB)' })}
                  onChange={handleChangeDrive2}
                  max={getFieldValue('allowancethb')}
                  min={0}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------*/}
        {/* <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="AllowanceTitleTripTHB" defaultMessage="Trip Allowance (THB)" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('allowancetripthb', {
                initialValue: getFieldValue('allowancetripthb') ? getFieldValue('allowancetripthb') : _.get(editList, 'tripAllowance'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormtripTHB',
                      defaultMessage: 'Please Enter Trip Allowance',
                    }),
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'allowanceEnterTripAllowance', defaultMessage: 'Enter Trip Allowance (THB)' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="allowanceFormTripDriver1" defaultMessage="Driver 1 Trip Allowance (THB)" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('tripdriver1', {
                initialValue: getFieldValue('tripdriver1') ? getFieldValue('tripdriver1') : _.get(editList, 'driver1TripAllowance'),
                // initialValue: getFieldValue('tripdriver1'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormTripDriver1',
                      defaultMessage: 'Please Enter Trip Driver 1 Trip',
                    }),
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({
                    id: 'allowanceEnterTripDriver1',
                    defaultMessage: 'Enter Driver 1 Trip Allowance (THB)',
                  })}
                  onChange={handleChangeDriveTrip1}
                  max={getFieldValue('allowancetripthb')}
                  min={0}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: paddinginputRight }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="allowanceFormTripDriver2" defaultMessage="Driver 2 Trip Allowance (THB)" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('tripdriver2', {
                initialValue: getFieldValue('tripdriver2') ? getFieldValue('tripdriver2') : _.get(editList, 'driver2TripAllowance'),
                // initialValue: getFieldValue('tripdriver2'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormTripDriver2',
                      defaultMessage: 'Please Enter Driver 2 Trip',
                    }),
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({
                    id: 'allowanceEnterTripDriver2',
                    defaultMessage: 'Enter Driver 2 Trip Allowance (THB)',
                  })}
                  onChange={handleChangeDriveTrip2}
                  max={getFieldValue('allowancetripthb')}
                  min={0}
                />
              )}
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </Modal>
  );
};

const AllowanceEditListModalForm = Form.create({
  name: 'Allowance_create_form',
  mapPropsToFields() {},
})(AllowanceEditListModal);

export default AllowanceEditListModalForm;
