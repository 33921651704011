import React from 'react';
import { Form, Row, Col, Modal, Icon, Input, Badge, Select, Divider, AutoComplete } from 'antd';
import './css/index.css';
import Button01 from '../v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import AppAvatar from '../avatar';
import _ from 'lodash';
import styled from 'styled-components';

export default ({ visible, customer, setVisible }) => {
  const intl = useIntl();
  const LabelRequire = styled.label`
    color: #ff1010;
  `;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'ModalCustomerViewHeader', defaultMessage: 'Customer' })}
      className="business-partner-modal"
      centered={true}
      visible={visible}
      width={700}
      onCancel={() => setVisible(false)}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => setVisible(false)}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
    >
      <Form colon={false} hideRequiredMark={true}>
        <Row>
          <Col span={8}>
            <div className="image-container">
              <AppAvatar size={150} src={`${_.get(customer, 'profileImg')}`} />
            </div>
          </Col>
          <Col span={16}>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'ModalCustomerCustomerCode', defaultMessage: 'Customer Code' })}
                      req={true}
                    />
                  }
                  className="form-customer"
                >
                  {_.get(customer, 'customerCode')}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: '4px' }}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'ModalCustomerCustomerName', defaultMessage: 'Customer Name' })}
                      req={true}
                    />
                  }
                  className="form-customer"
                >
                  {_.get(customer, 'customerName')}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequire text={intl.formatMessage({ id: 'ModalCustomerCustomerTeam', defaultMessage: 'Team' })} req={true} />}
                  className="form-customer"
                >
                  {_.get(customer, 'customerName')}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: '4px' }}
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'ModalCustomerCustomerOwner', defaultMessage: 'Owner' })} req={true} />
                  }
                  className="form-customer"
                >
                  {_.get(customer, 'valuePrivacy')}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'ModalCustomerBusinessType', defaultMessage: 'Business Type' })}
                      req={true}
                    />
                  }
                  className="form-customer"
                >
                  {_.get(customer, 'businessType')}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: '4px' }}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'ModalCustomerCustomerType', defaultMessage: 'Customer Type' })}
                      req={true}
                    />
                  }
                  className="form-customer"
                >
                  {_.get(customer, 'customerType')}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'ModalCustomerCustomerTaxno', defaultMessage: 'Tax No.' })} req={false} />
                  }
                  className="form-customer"
                >
                  {_.get(customer, 'taxNo')}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginLeft: '4px' }}
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'ModalCustomerCustomerPhone', defaultMessage: 'Phone' })} req={false} />
                  }
                  className="form-customer"
                >
                  {_.get(customer, 'phone')}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Divider type="horizontal" style={{ marginTop: '14px' }} />
              <div style={{ paddingBottom: '10px' }}>
                <div>
                  <p className="text-address-local">
                    <FormattedMessage id="ModalCustomerAddressText" defaultMessage="Address" />
                  </p>
                </div>
                <Row>
                  <div>
                    <Form.Item className="form-line-height">
                      <div>
                        <Icon
                          type="environment"
                          style={{
                            fontSize: '12px',
                            color: '#1D3557',
                            paddingLeft: '0px',
                            paddingRight: '4px',
                            cursor: 'pointer',
                          }}
                        />
                        <span style={{ color: '#1D3557' }}>
                          {_.get(customer, 'locationName')} <Badge color="#000000" className="badge-text" /> {_.get(customer, 'address')}
                        </span>
                      </div>
                    </Form.Item>
                  </div>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
