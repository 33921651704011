import React from 'react';
import { Avatar, Divider, Icon } from 'antd';
import Button_01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import './css/index.css';

export default (props) => {
  const { resourceId, taskStatusData, resource, onClickTrack, setMainResourceMemComId, setMainStatusTaskTitle, setMainStatusTaskObject, setVisibleTaskStatusModal, LangCode } = props

  const arrayChunk = _.chunk(taskStatusData, 3);

  const sortTaskStatusData = [
    ..._.filter(arrayChunk[0], el => el.code === 'Todo')
    , ..._.filter(arrayChunk[0], el => el.code === 'Doing')
    , ..._.filter(arrayChunk[0], el => el.code === 'Done')
  ];

  const fullname = resource.fullname;
  const pathImage = resource.pathImage;
  const phone = resource.phone;
  const statusOnGoingColor = resource.statusOnGoingColor;

  const handleClick = (item) => {
    setMainResourceMemComId(resourceId);
    setMainStatusTaskTitle(item.title);
    setMainStatusTaskObject(item.tasks);
    setVisibleTaskStatusModal(true);
  }

  return (
    <div className='popover-container-map'>
      <div className='popover-container-map-header'>
        <div className='popover-container-map-header-avatar'>
          <Avatar src={pathImage} shape='circle' size='large' style={{ border: `3px solid ${statusOnGoingColor}` }} />
        </div>
        <div className='popover-container-map-header-detail'>
          <div className='popover-container-map-header-fullname'>
            {fullname}
          </div>
          <div className='popover-container-map-header-phone'>
            {phone}
          </div>
        </div>
      </div>
      <Divider type='horizontal' className='divider-map-header' />
      <div className='popover-container-map-body'>
        {
          sortTaskStatusData && sortTaskStatusData.map((item) => (
            <div className='popover-container-map-body-item' onClick={() => handleClick(item)}>
              <div className='popover-container-map-body-item-detail'>
                <div className='popover-container-map-body-item-name'>
                  {item.title}
                </div>
                <div className='popover-container-map-body-item-total'>
                  {item.unit}
                </div>
              </div>
              <div className='popover-container-map-body-item-divider' >
                <Divider type='horizontal' className='divider-map-body' />
              </div>
            </div>
          ))
        }
        {
          arrayChunk[1] !== undefined && arrayChunk[1].map((item) => (
            <div className='popover-container-map-body-item' >
              <div className='popover-container-map-body-item-detail' onClick={() => onClickTrack(resource.memComId)}>
                <div className='popover-container-map-body-item-name'>
                  {item.title}
                </div>
                <div className='popover-container-map-body-item-total'>
                  <Icon type="right" />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}