import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getTaskType = async (type) => {

  try {
    const response = await httpClient.get(`/v3/task/manager/company/${comCode}/getsettingtasktype?${type}`);
    if (response.status === 200) {
      return response.data
    } else {
      return false
    }
  } catch (error) {
    return error.response
  }

}

const getOrderType = async () => {
  try {
    const response = await httpClient.get(`/v5/order/manager/company/${comCode}/web/deliveryordertype`);
    if (response.status === 200) {
      return response.data
    } else {
      return false
    }
  } catch (error) {
    return error.response
  }

}


const saveTaskType = async (result = []) => {

  try {
    const response = await httpClient.put(`/v3/task/manager/company/${comCode}/updatesettingtasktype`, result);
    if (response.status === 200) {


      return response.data
    } else {

      return false
    }
  } catch (error) {

    return error.response

  }
}

const saveOrderType = async (code, result = '') => {

  try {
    const response = await httpClient.put(`/v5/order/manager/company/${comCode}/web/deliveryordertype/${code}`, result);
    if (response.status === 200) {
      return response
    } else {

      return false
    }
  } catch (error) {

    return error.response

  }
}

const saveTaskTypeRemark = async (id, result) => {

  try {
    const response = await httpClient.put(`/v4/task/manager/company/${comCode}/task-type/${id}`, result);
    if (response.status === 200) {
      return response
    } else {
      return false
    }
  } catch (error) {

    return error.response

  }
}


export { getTaskType, saveTaskType, getOrderType, saveOrderType, saveTaskTypeRemark };