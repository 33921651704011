import httpClient from '../../../components/axiosClient';

const getReportItemSale = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/item-sale-order-report`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {}
};

const exportReportItemSale = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/item-sale-order-report-excel`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {}
};

const getReportSaleOrder = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/saleOrderReport`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {}
};

const exportReportSaleOrder = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/saleOrderReport-excel`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {}
};

export { getReportItemSale, exportReportItemSale, getReportSaleOrder, exportReportSaleOrder };
