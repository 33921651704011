import React, { useContext, useState } from 'react';
import { PageSettings } from '../../config/page-settings';
import { AccountReceiveContext } from './context';
import { StickyContainer } from 'react-sticky';
import { Button, Card, Col, Dropdown, Menu, Row, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';

import Button_01 from '../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';


// import QuotationModal from './modal/quotation/quotation-modal';
import UploadFileModal from './modal/upload-file/upload-modal';
import './css/index.css';
import _ from 'lodash';

import WaitingTF from './component-waiting/waitingTF';
import ApprovedTF from './component-approved/approvedTF';
import CanceledTF from './component-canceled/canceled';
import RejectedTF from './component-rejected/rejectedTf';
import CompletedTF from './component-completed/completedTF';
import ModalTranferForm from './modal/quotation/tranfer-modal';
const { TabPane } = Tabs;

const TranferItemPage = () => {
  const intl = useIntl();
  const { state, setState, func } = useContext(AccountReceiveContext);
  const {
    isOpenQuotation,
    openUpload,
    attachmentData,
    typeAttachment,
    defaultAttachment,
    initialMemberList,
    record,
    reportDataWaiting,
  } = state;
  const { seIsOpenQuotation, setAttachmentData, setTypeAttachment, setDefaultAttachment, setOpenUpload, setTabKey } = setState;
  const { handleOpenModalTransfer, handleSaveTranfer, handleCancelQuotation, callbackTab } = func;
  const { tranferList, tranferTotal, paginationPage, loading, rangePickerDate } = reportDataWaiting;

  const [visible, setVisible] = useState(false);

  console.log('record', record);

  const handleOpenModal = () => {
    handleOpenModalTransfer([] , 'waiting');
    setVisible(!visible);
  };

  const menuColumn = () => {
    return (
      // <PageSettings.Consumer>
      //   {({ checkPermissionAction }) => (
      <Menu>
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) ? ( */}
        <Menu.Item key="1">
          <Button key="1" ghost type="link" onClick={() => handleOpenModal()}>
            <FormattedMessage id="TFButtonItem" defaultMessage="Transfer Item" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? ( */}
        {/* <Menu.Item key="2">
          <Button key="2" ghost type="link" onClick={() => {}}>
            <FormattedMessage id="quotationLabelExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item> */}
        {/* ) : null} */}
      </Menu>
      //   )}
      // </PageSettings.Consumer>
    );
  };



  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {/* {checkPermissionAction(`P22PG1C2`, `P22PG1C2A1`) || 
                checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ||
                checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ||
                checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
              {checkPermissionAction(`P65PG1C1`, `P65PG1C1A1`) ? (
                <Card className="monitor-main-card">
                  <StickyContainer>
                    <Tabs
                      className="monitor-main-tabs customer-tab-margin"
                      size="large"
                      defaultActiveKey="waiting"
                      onChange={callbackTab}
                      animated={false}
                      tabBarExtraContent={
                        <Dropdown
                          overlay={menuColumn}
                          trigger={['click']}
                          onVisibleChange={setVisible}
                          visible={visible}
                          placement="bottomRight"
                          className="ant-dropdown-custom"
                        >
                          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                            <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                            <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                          </Button_01>
                        </Dropdown>
                      }
                    >
                      {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
                      <TabPane
                        tab={intl.formatMessage({ id: `TFWaiting`, defaultMessage: 'Waiting' }) + ` (${tranferTotal})`}
                        key="waiting"
                      >
                        <WaitingTF/>
                      </TabPane>
                      {/* ) : null} */}

                      {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
                      <TabPane tab={intl.formatMessage({ id: `TFApproved`, defaultMessage: 'Approved' })} key="approved">
                        <ApprovedTF />
                      </TabPane>
                      {/* ) : null} */}

                      {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
                      <TabPane tab={intl.formatMessage({ id: `TFCompleted`, defaultMessage: 'Completed' })} key="completed">
                        <CompletedTF />
                      </TabPane>
                      {/* ) : null} */}

                      {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
                      <TabPane tab={intl.formatMessage({ id: `TFRejected`, defaultMessage: 'Rejected' })} key="rejected">
                        <RejectedTF />
                      </TabPane>
                      {/* ) : null} */}

                      {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
                      <TabPane tab={intl.formatMessage({ id: `TFCanceled`, defaultMessage: 'Canceled' })} key="canceled">
                        <CanceledTF />
                      </TabPane>
                      {/* ) : null} */}
                    </Tabs>
                  </StickyContainer>
                </Card>
              ) : null}
            </Col>
          </Row>

          <ModalTranferForm
            title={
              _.get(record, 'type') === 'view'
                ? `${intl.formatMessage({ id: `TFTransferItem`, defaultMessage: 'Transfer Item' })} · ${_.get(record, 'transferNo')}`
                : _.get(record, 'type') === 'edit'
                ? `${intl.formatMessage({ id: `TFEditTransfer`, defaultMessage: 'Edit Transfer' })} · ${_.get(record, 'transferNo')}`
                : `${intl.formatMessage({ id: `TFTransferItem`, defaultMessage: 'Transfer Item' })}`
            }
            visible={isOpenQuotation}
            onOk={handleSaveTranfer}
            onCancel={handleCancelQuotation}
            initialMemberList={initialMemberList}
            attData={attachmentData}
            setAttData={setAttachmentData}
            record={record}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default TranferItemPage;
