import React from 'react'
import { Row, Col, Form, Tag } from 'antd'
import './css/index.css'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import NumberFormat from 'react-number-format'

export default (props) => {
  const { dataDefault } = props
  const intl = useIntl()

  //---------------------Source ANd Suitable ---------------------------//


  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
      </span>
    );
  };




  return (
    <div>
      <Form>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetPurchaseDate', defaultMessage: 'Purchase Date' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'postingDate') || '-'}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetPriceBaht', defaultMessage: 'Price (Baht)' })} req={false} />}>
              <span className='asset-view-text'><NumberFormat value={(parseFloat(_.get(dataDefault, 'price'))).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetValueDate', defaultMessage: 'Asset Value Date' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'assetValueDate') || '-'}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSalvagePrice', defaultMessage: 'Salvage Price (Baht)' })} req={false} />}>
              <span className='asset-view-text'><NumberFormat value={(parseFloat(_.get(dataDefault, 'salvagePrice'))).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetBookValue', defaultMessage: 'Asset Book Value (Baht)' })} req={false} />}>
              <span className='asset-view-text'><NumberFormat value={(parseFloat(_.get(dataDefault, 'assetBookValue'))).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSource', defaultMessage: 'Source' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'transactionTypeName') || '-'}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSuitable', defaultMessage: 'Suitable' })} req={false} />}>
              {
                _.get(dataDefault, 'dataSuitable').map(item => (
                  <Tag className='asset-suitable-tag' key={item.suitableId}>{item.dataSuitableName}</Tag>
                ))
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}


