import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from './config/page-setting';
import Button1 from '../../../components/v2/button_01';
import Button2 from '../../../components/v2/button_02';
import styled from 'styled-components';
import _ from 'lodash';
import { Button, Card, Table, Select, Form, Modal, Input, TreeSelect, Row, Col, Checkbox, Divider, InputNumber, Icon } from 'antd';
import cssStyle from './css/shift-type.css';

const { Option } = Select;
let id = 0;

const ButtonAdd = styled(Button)`
  width: 57px;
  font-size: 13p;
  border-radius: 2px;
`;

const ButtonEdit = styled(Button)`
  width: 57px;
  font-size: 13px;
  border-radius: 2px;
  padding: 0 8px;
`;

const LabelRequire = styled.label`
  color: #ff1010;
`;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
    </span>
  );
};

const FormSkill = React.memo((props) => {
  const intl = useIntl();

  // shiftSkillRules
  const {
    form,
    add,
    valueSkill,
    index,
    last,
    remove,
    skillTree,
    onChangeSkills,
    onChangeSkillLevels,
    onChangeMinRequired,
    disabled,
    skillValue,
  } = props;

  const { getFieldDecorator, getFieldValue, getFieldError } = form;

  const shiftSkillRules = getFieldValue('shiftSkillRules');

  const disabledType = getFieldError(`shiftSkillRules[${index}].skillId`);
  const arrSkillRules = shiftSkillRules.filter((item) => {
    return item.skillId !== undefined;
  });

  const [checkDuplicate, setCheckDuplicate] = useState();

  useEffect(() => {
    if (arrSkillRules) {
      let arr = [];
      arrSkillRules.forEach((item) => {
        const hour = item.skillId;
        return arr.push(hour);
      });
      setCheckDuplicate(checkIfDuplicateExists(arr));
    }
  }, [arrSkillRules]);

  const checkIfDuplicateExists = (arr) => {
    return new Set(arr).size !== arr.length;
  };
  const validateSkillLaevels = (rule, value, callback) => {
    if (value > 100) {
      callback(
        <span>
          {intl.formatMessage({
            id: 'orgTabShiftSettingShiftTypeModalValidateSkillLevels',
            defaultMessage: 'Prohibited skill level is more than 100.',
          })}
        </span>
      );
    } else {
      callback();
    }
  };

  const validateMinRequired = (rule, value, callback) => {
    if (value < 0) {
      callback('Minimum members must not be equal to 0 and not less than 0. !');
    } else {
      callback();
    }
  };

  const handleValidateSkills = (rule, value, callback, index) => {
    _.forEach(arrSkillRules, (v, i) => {
      if (index !== i) {
        if (value === v.skillId) {
          callback(
            <span>
              {intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeModalValidateSkills', defaultMessage: 'This skill already exists.' })}
            </span>
          );
        }
      }
    });
    callback();
  };

  return (
    <div key={index} className="div-skill-from-body">
      <Form.Item
        className="skills-form-item"
        label={index === 0 ? <FormattedMessage id="orgTabShiftSettingShiftTypeModalLblSkills" defaultMessage="Skills" /> : ''}
        validateStatus={disabledType && checkDuplicate === true ? 'error' : ''}
        help={disabledType && checkDuplicate === true ? disabledType : ''}
      >
        {getFieldDecorator(`shiftSkillRules[${index}].skillId`, {
          rules: [
            {
              validator: (rule, value, callback) => handleValidateSkills(rule, value, callback, index),
            },
          ],
        })(
          <TreeSelect
            style={{ width: '380px' }}
            value={skillValue}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={skillTree}
            placeholder={intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeModalHintSkills', defaultMessage: 'Please enter skills' })}
            treeDefaultExpandAll
            onChange={(value) => onChangeSkills(value, index)}
          />
        )}
      </Form.Item>
      <Form.Item
        className="skill-levels-form-item"
        label={
          index === 0 ? (
            <FormattedMessage id="orgTabShiftSettingShiftTypeModalLblSkillLevels" defaultMessage="Skill Levels (max: 100)" />
          ) : (
            ''
          )
        }
      >
        {getFieldDecorator(`shiftSkillRules[${index}].minSkillLv`, {
          rules: [
            {
              pattern: new RegExp(/^[0-9\b]+$/),
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireOnlyNumber',
                defaultMessage: 'Value should contain just number.',
              }),
            },
            {
              validator: validateSkillLaevels,
            },
          ],
        })(
          <InputNumber
            placeholder={intl.formatMessage({
              id: 'orgTabShiftSettingShiftTypeModalHintSkillLevels',
              defaultMessage: 'Please enter skill levels',
            })}
            style={{ width: '240px' }}
            onChange={(value) => onChangeSkillLevels(value, index)}
            //type="number"
            //min={0}
            max={100}
          />
        )}
      </Form.Item>
      {/* <Form.Item
        className="min-require-form-item"
        label={index === 0 ? 'Min. Required Members' : ''}
      >
        {getFieldDecorator(`shiftSkillRules[${index}].minReqMem`, {
          rules: [
            {
              type: 'number',
              max: 1000,
              message: 'Not more than 1000!',
            },
            {
              pattern: /^(?:\d*)$/,
              message: 'Value should contain just number!',
            },
            {
              validator: validateMinRequired,
            },
          ],
        })(
          <InputNumber
            placeholder="Enter minimum"
            style={{ width: '152px' }}
            onChange={(value) => onChangeMinRequired(value, index)}
            type="number"
            min={0}
            max={1000}
          />,
        )}
      </Form.Item> */}
      <div style={{ marginLeft: '15px' }}>
        <Form.Item className="shift-button-add" label={index === 0 ? <span className="skills-span">xxxx</span> : ''}>
          {index === last - 1 ? (
            <ButtonAdd onClick={add} disabled={disabled}>
              <FormattedMessage id="orgTabShiftSettingShiftTypeModalBtnAdd" defaultMessage="Add" />
            </ButtonAdd>
          ) : (
            <ButtonEdit onClick={() => remove(valueSkill, index)}>
              <FormattedMessage id="orgTabShiftSettingShiftTypeModalBtnDelete" defaultMessage="Delete" />
            </ButtonEdit>
          )}
        </Form.Item>
      </div>
    </div>
  );
});

const ModalFormComponent = ({ visible, onCancel, onCreate, workDay, form }) => {
  const { getFieldDecorator, getFieldValue, validateFields, setFieldsValue, getFieldsValue, resetFields } = form;
  const { shiftData, skillType, skillTree, listRoles } = useContext(PageSettings);
  const intl = useIntl();

  const [checkSkills, setCheckSkills] = useState(false);
  const [checkSkillLevels, setCheckSkillLevels] = useState(false);
  const [checkMinRequired, setCheckMinRequired] = useState(false);
  const [disabled, setDisabled] = useState('false');
  const [skillValue, setSkillValue] = useState();
  const [shiftSkillRulesValue, setShiftSkillRulesValue] = useState([
    {
      skillId: null,
      minSkillLv: null,
      minReqMem: 1,
    },
  ]);

  useEffect(() => {
    if (checkSkills && checkSkillLevels === true) {
      setDisabled('');
    } else {
      setDisabled('false');
    }
  }, [checkSkills, checkSkillLevels]);

  useEffect(() => {
    setShiftSkillRulesValue([
      {
        skillId: null,
        minSkillLv: null,
        minReqMem: 1,
      },
    ]);
    resetFields({
      shiftSkillRules: [
        {
          skillId: null,
          minSkillLv: null,
          minReqMem: 1,
        },
      ],
      workDay: [],
    });
  }, [visible]);

  const add = () => {
    let arrValue = { ...getFieldsValue() };
    arrValue['shiftSkillRules'] = [
      ...arrValue['shiftSkillRules'],
      {
        skillId: null,
        minSkillLv: null,
        minReqMem: 1,
      },
    ];
    setShiftSkillRulesValue(arrValue['shiftSkillRules']);
    form.setFieldsValue(arrValue);
    setCheckSkills(!checkSkills);
    setCheckSkillLevels(!checkSkillLevels);
    setCheckMinRequired(!checkMinRequired);
  };

  const remove = (value, index) => {
    calcMinMember(0, index);
    let arrValue = { ...getFieldsValue() };
    if (!(arrValue['shiftSkillRules'] && arrValue['shiftSkillRules'].length)) return;
    arrValue['shiftSkillRules'].splice(index, 1);
    setShiftSkillRulesValue(arrValue['shiftSkillRules']);
    form.setFieldsValue(arrValue);
  };

  getFieldDecorator('oldValue', { initialValue: [{}] });
  const oldValue = getFieldValue('oldValue');

  const formSkill = oldValue.map((k, index) => (
    <Form.Item required={false} key={k}>
      <div className="div-skill-from-body">
        <Form.Item className="skills-form-item" label={index === 0 ? 'Skills' : ''}>
          {getFieldDecorator(`shiftSkillRules[${index}].skillId`)(
            <Select placeholder="select skills" style={{ width: '300px' }} onChange={(value) => onChangeSkills(value, index)}>
              {skillTree?.length &&
                skillTree.map((item) => (
                  <Option key={item.key} value={item.key}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item className="skill-levels-form-item" label={index === 0 ? 'Skill Levels (max: 100)' : ''}>
          {getFieldDecorator(`shiftSkillRules[${index}].minSkillLv`)(
            <InputNumber placeholder="Enter level" style={{ width: '200px' }} onChange={(value) => onChangeSkillLevels(value, index)} />
          )}
        </Form.Item>
        <Form.Item className="min-require-form-item" label={index === 0 ? 'Min. Required Members' : ''}>
          {getFieldDecorator(`shiftSkillRules[${index}].minReqMem`)(
            <InputNumber placeholder="Enter minimum" style={{ width: '152px' }} onChange={(value) => onChangeMinRequired(value, index)} />
          )}
        </Form.Item>
        {/* <Form.Item>
          {index === oldValue.length - 1 ? (
            <Button onClick={addSkills} disabled={disabled}>
              Add Shift
            </Button>
          ) : (
              <Button onClick={() => removeSkills(k, index)}>Delete</Button>
            )}
        </Form.Item> */}
      </div>
    </Form.Item>
  ));

  const onChangeSkills = (value, index) => {
    if (value) {
      setCheckSkills(true);
      setSkillValue(value);
    } else {
      setCheckSkills(false);
    }
  };

  const onChangeSkillLevels = (value, index) => {
    if (value >= 0) {
      setCheckSkillLevels(true);
    } else {
      setCheckSkillLevels(false);
    }
  };

  const onChangeMinRequired = (value, index) => {
    calcMinMember(value, index);
    if (value) {
      setCheckMinRequired(true);
    } else {
      setCheckMinRequired(false);
    }
  };

  const calcMinMember = (value, index) => {
    let val = getFieldValue('shiftSkillRules');
    let count = 0;
    val.forEach((ele, i) => {
      if (index !== i) {
        count = count + (ele.minReqMem ? ele.minReqMem : 0);
      } else {
        count = count + value;
      }
    });
    setFieldsValue({ minmum: count });
  };

  const skillLevelsMax = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value > 100) {
      callback('Skill Levels (max: 100)!');
    } else {
      callback();
    }
  };

  const validateMinimumMembers = (rule, value, callback) => {
    if (value < 0) {
      callback(
        // 'Minimum members must not be equal to 0 and not less than 0. !'
        <span>
          {intl.formatMessage({
            id: 'orgTabShiftSettingRequireMustNotBeEqual ',
            defaultMessage: 'Value must not be equal to 0 and not less than 0.',
          })}
        </span>
      );
    } else {
      callback();
    }
  };

  const compareMaximunMembers = (rule, value, callback) => {
    if (value < form.getFieldValue('minmum')) {
      callback(
        // 'Prohibited members less than the minimum membership!'
        <span>
          {intl.formatMessage({
            id: 'orgTabShiftSettingRequireLimitMinimum ',
            defaultMessage: 'Prohibited members less than the minimum membership',
          })}
        </span>
      );
    } else {
      callback();
    }
  };

  const comparezero = (rule, value, callback) => {
    if (value && parseInt(value) === 0) {
      callback(
        // 'Minimum Working Time must not be equal to 0 and not less than 0. !',
        <span>
          {intl.formatMessage({
            id: 'orgTabShiftSettingRequireMustNotBeEqual',
            defaultMessage: 'Value must not be equal to 0 and not less than 0.',
          })}
        </span>
      );
    } else {
      callback();
    }
  };

  const continueWorkDay = (rule, value, callback) => {
    if (value) {
      if (value && parseInt(value) === 0) {
        callback(
          // 'Minimum Working Time must not be equal to 0 and not less than 0. !',
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingRequireMustNotBeEqual',
              defaultMessage: 'Value must not be equal to 0 and not less than 0.',
            })}
          </span>
        );
      }
      if (value && parseInt(value) > 1000) {
        callback(
          // 'Not more than 1000!',
          <span>{intl.formatMessage({ id: 'orgTabShiftSettingRequireMaximumValue', defaultMessage: 'Not more than 1000.' })}</span>
        );
      }
    } else {
      callback();
    }
    callback();
  };

  const handleCancel = () => {
    setCheckSkills(false);
    setCheckSkillLevels(false);
    onCancel();
  };

  return (
    <PageSettings.Consumer>
      {() => (
        <Modal
          className="shift-type-modal"
          visible={visible}
          title={<FormattedMessage id="orgTabShiftSettingShiftTypeModalTitle" defaultMessage="Team Shift" />}
          centered="true"
          onCancel={handleCancel}
          onOk={onCreate}
          width={770}
          footer={[
            <Button
              key="back"
              btnsize="wd_df"
              onClick={handleCancel}
              margin=" unset"
              style={{ width: '96px', border: '1px solid #6490cf', borderRadius: '8px', backgroundColor: '#ffffff', color: '#6490CF' }}
            >
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button>,
            <Button
              key="submit"
              type="primary"
              btnsize="wd_df"
              onClick={onCreate}
              style={{
                width: '96px',
                background: '#6490cf',
                border: '1px solid #6490cf',
                borderRadius: '8px',
                hover: {
                  background: '#ffffff',
                  color: '#6490cf',
                },
              }}
            >
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <div style={{ display: 'flex', marginBottom: '15px' }}>
              <Form.Item
                className="shift-type-form-item-select"
                label={
                  <LabeRequire
                    text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblShiftType" defaultMessage="Shift Type" />}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('shiftType', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalRequireShiftType',
                        defaultMessage: 'Please select shift type!',
                      }),
                    },
                  ],
                })(
                  <Select
                    style={{ width: '260px' }}
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingShiftTypeModalHintShiftType',
                      defaultMessage: 'Please enter shift type',
                    })}
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {skillType?.length &&
                      skillType.map((item) => (
                        <Option key={item.shift_id} value={item.shift_id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                className="shift-type-form-item"
                label={
                  <LabeRequire
                    text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblPriority" defaultMessage="Priority" />}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('priority', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalRequirePriority',
                        defaultMessage: 'Please enter your priority.',
                      }),
                    },
                    {
                      type: 'number',
                      max: 1000,
                      message: intl.formatMessage({ id: 'orgTabShiftSettingRequireMaximumValue', defaultMessage: 'Not more than 1000.' }),
                    },
                    {
                      // pattern: /^(?:\d*)$/,
                      pattern: new RegExp(/^[0-9\b]+$/),
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingRequireOnlyNumber',
                        defaultMessage: 'Value should contain just number.',
                      }),
                    },
                    {
                      validator: comparezero,
                    },
                  ],
                })(
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingShiftTypeModalHintPriority',
                      defaultMessage: 'Please enter priority',
                    })}
                    type="number"
                    max={1000}
                  />
                )}
              </Form.Item>
              <Form.Item
                className="shift-type-form-item"
                label={
                  <LabeRequire
                    text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblMinimumMembers" defaultMessage="Minimum Members" />}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('minmum', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalRequireMinimumMembers',
                        defaultMessage: 'Please enter your minmum members.',
                      }),
                    },
                    {
                      type: 'number',
                      max: 1000,
                      message: intl.formatMessage({ id: 'orgTabShiftSettingRequireMaximumValue', defaultMessage: 'Not more than 1000.' }),
                    },
                    {
                      // pattern: /^(?:\d*)$/,
                      pattern: new RegExp(/^[0-9\b]+$/),
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingRequireOnlyNumber',
                        defaultMessage: 'Value should contain just number.',
                      }),
                    },
                    {
                      validator: validateMinimumMembers,
                    },
                  ],
                  //initialValue: 0,
                })(
                  <InputNumber
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingShiftTypeModalHintMinimumMembers',
                      defaultMessage: 'Please enter minmum members',
                    })}
                    // style={{ width: '148px' }}
                    style={{ width: '100%' }}
                    type="number"
                    max={1000}
                    //disabled={true}
                  />
                )}
              </Form.Item>
              <Form.Item
                className="shift-type-form-item"
                label={
                  <LabeRequire
                    text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblMaximunMembers" defaultMessage="Maximun Members" />}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('maximun', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalRequireMaximunMembers',
                        defaultMessage: 'Please enter your maximun members.',
                      }),
                    },
                    {
                      type: 'number',
                      max: 1000,
                      message: intl.formatMessage({ id: 'orgTabShiftSettingRequireMaximumValue', defaultMessage: 'Not more than 1000.' }),
                    },
                    {
                      pattern: new RegExp(/^[0-9\b]+$/),
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingRequireOnlyNumber',
                        defaultMessage: 'Value should contain just number.',
                      }),
                    },
                    {
                      validator: compareMaximunMembers,
                    },
                  ],
                })(
                  <InputNumber
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingShiftTypeModalHintMaximunMembers',
                      defaultMessage: 'Please enter maximun members.',
                    })}
                    // style={{ width: '148px' }}
                    style={{ width: '100%' }}
                    type="number"
                    max={1000}
                  />
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', marginBottom: '15px' }}>
              <Form.Item
                className="shift-type-shift-work"
                label={
                  <LabeRequire
                    text={
                      <FormattedMessage
                        id="orgTabShiftSettingShiftTypeModalLblMaximumContinuousDay"
                        defaultMessage="Maximum continuous shift work (Day)"
                      />
                    }
                    req={true}
                  />
                }
              >
                {getFieldDecorator('maxContinueWorkDay', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalValidateMaximumContinuous',
                        defaultMessage: 'Please enter your maximum continuous shift work.',
                      }),
                    },
                    {
                      pattern: new RegExp(/^[0-9\b]+$/),
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingRequireOnlyNumber',
                        defaultMessage: 'Value should contain just number.',
                      }),
                    },
                    {
                      validator: continueWorkDay,
                    },
                  ],
                })(
                  <Input
                    className="form-item-input-addon"
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingShiftTypeModalHintMaximumContinuous',
                      defaultMessage: 'Please enter maximum continuous shift work',
                    })}
                    type="number"
                    autoComplete="off"
                  />
                )}
              </Form.Item>
              <Form.Item
                className="shift-type-shift-continue"
                label={
                  <LabeRequire
                    text={
                      <FormattedMessage
                        id="orgTabShiftSettingShiftTypeModalLblContinueShiftRestricted"
                        defaultMessage="Continue Shift Restricted"
                      />
                    }
                    req={true}
                  />
                }
              >
                {getFieldDecorator('continueShiftRestrict')(
                  <Select
                    className="continue-shift-restrict"
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingShiftTypeModalHintContinueShiftRestricted',
                      defaultMessage: 'Please enter continue shift restricted',
                    })}
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {skillType?.length &&
                      skillType.map((item) => (
                        <Option key={item.shift_id} value={item.shift_id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', marginBottom: '15px' }}>
              <Form.Item
                label={
                  <LabeRequire text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblRole" defaultMessage="Role" />} req={true} />
                }
              >
                {getFieldDecorator('roles', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'orgTabShiftSettingShiftTypeModalValidateRole',
                        defaultMessage: 'Please enter your role.',
                      }),
                    },
                  ],
                })(
                  <Select
                    className="form-item-role"
                    placeholder={intl.formatMessage({
                      id: 'orgTabShiftSettingShiftTypeModalHintRole',
                      defaultMessage: 'Please enter role',
                    })}
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {listRoles?.length &&
                      listRoles.map((item) => (
                        <Option key={item.roleId} value={item.roleId}>
                          {item.roleName}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </div>

            <Form.Item
              label={
                <LabeRequire
                  text={<FormattedMessage id="orgTabShiftSettingShiftTypeModalLblApplyOn" defaultMessage="Apply on" />}
                  req={true}
                />
              }
            >
              {getFieldDecorator('work_day', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'orgTabShiftSettingShiftTypeModalValidateApplyOn',
                      defaultMessage: 'Please checkbox work day.',
                    }),
                  },
                ],
                initialValue: [],
              })(
                <Checkbox.Group style={{ width: '100%' }}>
                  <Row>
                    {workDay &&
                      workDay.map((workDay) => (
                        <Col span={4} style={{ width: 'auto' }}>
                          <Checkbox key={workDay.id} className="workDay-checkbox-shift-type checkbox-border" value={workDay.day}>
                            {workDay.day}
                          </Checkbox>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              )}
            </Form.Item>

            <Divider className="team-shift-divider" />

            {shiftSkillRulesValue?.length
              ? shiftSkillRulesValue.map((e, i) => (
                  <FormSkill
                    form={form}
                    key={i}
                    valueSkill={e}
                    index={i}
                    last={shiftSkillRulesValue?.length}
                    add={add}
                    remove={remove}
                    skillType={skillType}
                    skillTree={skillTree}
                    onChangeMinRequired={onChangeMinRequired}
                    onChangeSkills={onChangeSkills}
                    onChangeSkillLevels={onChangeSkillLevels}
                    disabled={disabled}
                    skillValue={skillValue}
                  />
                ))
              : null}
          </Form>
        </Modal>
      )}
    </PageSettings.Consumer>
  );
};

const ModalForm = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {
    return {
      skillId: Form.createFormField({
        value: null,
      }),
      minSkillLv: Form.createFormField({
        value: null,
      }),
    };
  },
})(ModalFormComponent);

export default React.memo(ModalForm);
