import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import _ from 'lodash';

export default (props) => {
  // Theme
  const theme = {
    space: {
      sm: '8px',
      md: '16px',
    },
    btnfontsize: {
      sm: '13px',
      md: '14px',
      lg: '16px',
      smm: '10px',
    },
    btnwidth: {
      wd_df: '96px',
      wd_md: '50%',
      wd_lg: '100%',
      wd_at: 'auto',
      wd_splg: '83px',
    },
    marginStyle: {
      mg_df: '0px 10px 0px 10px',
      mg_md: '0px 8px 0px 8px',
      mg_cs1: '0px 5px 0px 10px',
      mg_cs2: '0px 10px 0px 5px',
      mg_cs3: '0px 0px 0px 10px',
    },
  };

  const Buttons = styled(Button)`
    /* Adapt the colors based on primary prop */
    background-color: ${(props) => (_.isString(props.type) ? '#1D3557' : '#ffffff')};
    color: ${(props) => (_.isString(props.type) ? '#ffffff' : '#1D3557')};
    width: 83px;
    font-size: ${props.fontsize ? theme.btnfontsize[props.fontsize] : theme.btnfontsize.smm};
    margin: ${props.margin ? theme.marginStyle[props.margin] : theme.marginStyle.mg_df};
    height: 20px;
    padding: 0.25em 1em;
    border: 1px solid #1d3557;
    border-radius: 11px;
    :hover {
      background: #1d3557;
      color: #ffffff;
    }
  `;

  return <Buttons {...props}>{props.children}</Buttons>;
};
