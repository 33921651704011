import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
// import ModalCustomerQuotationForm from '....//select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'ddd, MMM DD YYYY';

const PriceFormView = ({ dataForm, form, initialMemberList, receiveData,visiblePaid }) => {
  const intl = useIntl();
  const { getFieldDecorator, validateFields, setFieldsValue } = form;

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>
        {o.fullname}
      </Option>
    );
  };

  useEffect(() => {
    if (visiblePaid) {
      setFieldsValue({
        ['total']: numberFormatter(receiveData),
      });
      validateFields(['total'], (errors, values) => {

        if (errors) {
          console.log('Validation failed:', errors);
        } else {
          console.log('Validation passed:', values);
        }
      });
    }
   
  }, [receiveData]);

  const validatorTotal = (rule, value, callback) => {
    console.log('receiveData', receiveData, '/', _.get(dataForm, 'total'));

           if (receiveData > _.get(dataForm, 'total')) {
          console.log('ruletest');
          callback(
            intl.formatMessage({ id: 'OverTotalAmount', defaultMessage: 'Over Total Amount' }) + ' ' +
              numberFormatter(receiveData) + ' '+
              intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })
          );
        } else {
          callback();
        }
  };

  return (
    <Form name="customer" colon={false}>
      <Divider type="horizontal" style={{ marginBottom: '10px' }} />
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'ARReceiveTotal', defaultMessage: 'Receive Total' })}
                req={false}
                bold={true}
              />
            }
          >
            {_.get(dataForm, 'code') === 'waiting'
              ? getFieldDecorator('total', {
                  initialValue: receiveData,
                  trigger: 'onchange',
                  rules: [
                    {
                      validator: validatorTotal,
                    },
                  ],
                })(
                  <Input
                    readOnly
                    style={{ backgroundColor: '#F8F8F8' }}
                    maxLength={_.get(dataForm, 'total')}
                  />
                )
              : numberFormatter(receiveData)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'ARReceiveBy', defaultMessage: 'Receive by' })}
                req={false}
                bold={true}
              />
            }
          >
            {_.get(dataForm, 'code') === 'waiting'
              ? getFieldDecorator('receiveById', {
                  initialValue: _.get(dataForm, 'receiveById') || '',
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'quotationHintIssueBy', defaultMessage: 'Select Issue by' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {initialMemberList && initialMemberList.map((item, index) => MemberOption(item, index))}
                  </Select>
                )
              : _.get(dataForm, 'receiveBy') || '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'ARReceiveDate ', defaultMessage: 'Receive Date' })}
                req={false}
                bold={true}
              />
            }
          >
            {_.get(dataForm, 'code') === 'waiting'
              ? getFieldDecorator('receiveDate', {
                initialValue: moment(),
                  // initialValue: _.get(dataForm, 'receiveDate') ? moment(_.get(dataForm, 'receiveDate'), 'YYYY-MM-DD') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnIssueDate', defaultMessage: 'please select Issue Date' }),
                    },
                  ],
                })(
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'ARHintIssueDate', defaultMessage: 'Select Issue Date' })}
                  />
                )
              : _.get(dataForm, 'receiveDate')
              ? _.get(dataForm, 'receiveDateTxt')
              : '-'}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={24}>
          <Form.Item
            colon={false}
            label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })} req={false} />}
          >
            {_.get(dataForm, 'code') === 'waiting'
              ? getFieldDecorator('remark', { initialValue: _.get(dataForm, 'remark') || '' })(
                  <TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={250} />
                )
              : _.get(dataForm, 'remarkPaid') || '-'}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

PriceFormView.defaultProps = {};

export default PriceFormView;
