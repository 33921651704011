import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';

const { Option } = Select;

const ListDetail = (props) => {
  const { data } = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  return (
    <div>
      <div 
        style={{ 
          overflowY: 'auto', 
          // height: `calc(109vh - 357px)`, 
          padding: '5px 0px 0px 24px' 
        }}
        >
          <div style={{ padding: '16px 16px 8px 0px' }}>
            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <LabeRequireForm text={intl.formatMessage({ id: 'reportAssetExpenseLabelAssetClassAndType', defaultMessage: 'Asset Class and Type' })} req={false} />
              </div>
              <div>
                {_.get(data, 'assetClassName') + ' ' + _.get(data, 'assetTypeName')}
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <LabeRequireForm text={intl.formatMessage({ id: 'reportAssetExpenseLabelOwner', defaultMessage: 'Owner' })} req={false} />
              </div>
              <div>
                {_.get(data, 'owner') || '-'}
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <LabeRequireForm text={intl.formatMessage({ id: 'reportAssetExpenseLabelInternalCost', defaultMessage: 'Internal Cost' })} req={false} />
              </div>
              <div>
                {numberFormatter(_.get(data, 'internalCost', 0)) + ' THB'}
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <LabeRequireForm text={intl.formatMessage({ id: 'reportAssetExpenseLabelExternalCost', defaultMessage: 'External Cost' })} req={false} />
              </div>
              <div>
                {numberFormatter(_.get(data, 'externalCost', 0)) + ' THB'}
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <LabeRequireForm text={intl.formatMessage({ id: 'reportAssetExpenseLabelTotalCost', defaultMessage: 'Total Cost' })} req={false} />
              </div>
              <div>
                {numberFormatter(_.get(data, 'total', 0)) + ' THB'}
              </div>
            </div>

          </div>
      </div>
    </div>
  );
};

export default ListDetail;
