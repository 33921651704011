import React, { useState, useEffect, createContext } from 'react';
import ItemMasterTable from './item-master-table';
import ActionReportV1 from '../../components/action-report/action-report-v1';
import getItemMaster from '../../controllers/item-master/get-item-master';
import { getDataAll, getModelName } from '../../controllers/item-master/get-data-all';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Modal } from 'antd';
import _, { debounce } from 'lodash';
import { successNotification, errorNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import Button01 from '../../components/v2/button_01.js';
import httpClient from '../../components/axiosClient';
import './css/index.css';
import ModalForm from '../../components/create-edit-item-master/index';
import NumberFormat from 'react-number-format';

export const ItemMasterDetail = createContext();

export default () => {
  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: <FormattedMessage id="itemMasterColumnItemCode" defaultMessage="Item Code" />,
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      width: 150,
    },
    {
      title: <FormattedMessage id="itemMasterColumnBrandNo" defaultMessage="Brand" />,
      dataIndex: 'itemBrandName2',
      key: 'itemBrandName2',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 200,
      // render: (text, record, index) => _.get(record, 'itemBrandNo') + " " + _.get(record, 'brandName')
    },
    {
      title: <FormattedMessage id="itemMasterColumnModelNo" defaultMessage="Model" />,
      dataIndex: 'itemModelName2',
      key: 'itemModelName2',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 200,
      // render: (text, record, index) => _.get(record, 'itemModelNo') + " " + _.get(record, 'modelName')
    },
    {
      title: <FormattedMessage id="itemMasterColumnItemName" defaultMessage="Item Name" />,
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      // fixed: 'left',
      width: 200,
      // render: (text, record, index) => record.modelName ? _.truncate(record.brandName + ' ' +  record.modelName   + ' ' + record.itemName, { length: 100 }) :  _.truncate(record.brandName + ' '  + record.itemName, { length: 100 }) ,
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      title: <FormattedMessage id="itemMasterColumnItemGroup" defaultMessage="Item Group" />,
      dataIndex: 'itemGroupName2',
      key: 'itemGroupName2',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 220,
      // render: (text, record, index) => _.get(record, 'itemGroupCode') + " " + _.get(record, 'itemGroupName')
    },
    {
      title: <FormattedMessage id="itemMasterColumnItemType" defaultMessage="Item Type" />,
      dataIndex: 'itemTypeName2',
      key: 'itemTypeName2',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 220,
      // render: (text, record, index) => _.get(record, 'itemTypeCode') + " " + _.get(record, 'itemTypeName')
    },
    {
      title: <FormattedMessage id="itemMasterColumnDimension" defaultMessage="Dimension(m.)" />,
      dataIndex: 'dimension',
      key: 'dimension',
      // sorter: 'false',
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => `${record.width} * ${record.length} * ${record.height}`,
      width: 160,
    },
    {
      title: <FormattedMessage id="itemMasterColumnStandardCost" defaultMessage="Standard Cost" />,
      dataIndex: 'standardCost',
      key: 'standardCost',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 160,
      render: (text, record, index) => (
        <NumberFormat value={(parseFloat(record.standardCost) || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: <FormattedMessage id="itemMasterColumnStandardPrice" defaultMessage="Standard Price" />,
      dataIndex: 'standardPrice',
      key: 'standardPrice',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 160,
      render: (text, record, index) => (
        <NumberFormat value={(parseFloat(record.standardPrice) || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: <FormattedMessage id="itemMasterColumnWeight" defaultMessage="Weight (kg)" />,
      dataIndex: 'weight',
      key: 'weight',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 160,
    },
    {
      title: <FormattedMessage id="itemMasterColumnUoMCategory" defaultMessage="UoM Category" />,
      dataIndex: 'uomCategoryName',
      key: 'uomCategoryName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 160,
    },
    {
      title: <FormattedMessage id="itemMasterColumnUoM" defaultMessage="UoM" />,
      dataIndex: 'uomName',
      key: 'uomName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 160,
    },
    {
      title: <FormattedMessage id="itemMasterColumnPickingType" defaultMessage="Picking Type" />,
      dataIndex: 'pickingTypeName',
      key: 'pickingTypeName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: <FormattedMessage id="itemMasterColumnRemark" defaultMessage="Remark" />,
      dataIndex: 'description',
      key: 'description',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      width: 140,
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ];

  const setShowColumn = {
    index: true,
    itemCode: true,
    itemBrandName2: true,
    itemModelName2: false,
    itemName: true,
    itemGroupName2: true,
    itemTypeName2: true,
    dimension: true,
    weight: false,
    uomCategoryName: false,
    uomName: true,
    standardCost: true,
    standardPrice: false,
    pickingTypeName: false,
    description: false,
  };

  const setShowColumnArr = [
    'index',
    'itemCode',
    'itemBrandName2',
    // 'itemModelName2',
    'itemName',
    'itemGroupName2',
    'itemTypeName2',
    'dimension',
    // 'weight',
    // 'uomCategoryName',
    'uomName',
    // 'pickingTypeName',
    // 'standardCost',
    // 'standardPrice',
    // 'description',
  ];

  const comCode = localStorage.getItem('comCode');
  const ignoreColumn = ['itemModelName2' ,'weight', 'uomCategoryName', 'pickingTypeName', 'standardPrice', 'description'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreFilterColumns = ['index', 'itemCode', 'action'];
  const ignoreSearchColumns = ['index'];

  const [visibleModal, setVisibleModal] = useState(false);

  const [recordItemMaster, setRecordItemMaster] = useState();

  const [itemMasterData, setItemMasterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [fieldChang, setFieldChang] = useState('');
  const [fliterByColumn, setFliterByColumn] = useState('all');

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [visible, setVisible] = useState(false);
  const [scrollTableX, setScrollTableX] = useState(1450);

  const [triggerAPI, setTriggerAPI] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [dataDefault, setDataDefault] = useState();
  const [eventDataDefault, setEventDataDefault] = useState(false)
  const [brandId, setBrandId] = useState('');
  const [dataModel, setDataModel] = useState([]);
  const [createItem, setCreateItem] = useState();
  const [groupTypeId, setGroupTypeId] = useState();

  useEffect(() => {
    async function getAPI() {
      setLoading(true);
      const response = await getItemMaster({ fieldChang, fliterByColumn, fieldSort, orderSort, paginationPage, paginationSize });

      await setItemMasterData(response.data);
      await setTotal(response.allRows);
      await setLoading(false);
    }
    getAPI();
  }, [triggerAPI, fieldChang, fliterByColumn, extraSort, paginationPage, paginationSize]);

  console.log('paginationPage useEff', paginationPage, paginationSize)

  useEffect(() => {
    async function getAPI() {
      const response = await getDataAll();
      // console.log('responseSetDataDefault', response)

      await setDataDefault(response);
    }
    getAPI();
  }, [eventDataDefault]);

  useEffect(() => {
    const tempBID = _.get(createItem, 'brandId');
    async function getAPI() {
      // console.log('brandId useEffect', brandId, _.size(brandId))
      if(brandId) {
        const response = await getModelName({ brandId });
        await setDataModel(response);
        // console.log('BrandIDEditResponse', response)
      }
        
    }
    if ((brandId && _.size(brandId) )|| createItem) {
      // console.log('BrandIDEdit is true', brandId, '/createitem:', tempBID, _.size(brandId));
      getAPI();

    }
  }, [brandId, visibleModal, createItem,eventDataDefault]);

  const onChangeSearch = debounce((value) => {
    
    setTimeout(() => {
      setPaginationPage(1)
      setFieldChang(value);
    }, 300)
  }, 500);

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const handleOpenModal = (record) => {
    
    setVisibleModal(true);
    setRecordItemMaster(record);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    console.log('paginationSize onch', current, size)
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    console.log('listArrayColumns', listArrayColumns, columns)
    if (listArrayColumns.length < 5) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element === columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    if (_.size(setNewArr) > 8) {
      setScrollTableX(2300);
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    setScrollTableX(1600);
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'itemMasterTableDeleteConfirmText', defaultMessage: 'Are you sure to delete item ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v3/item/manager/${comCode}/deletedataitemmaster/${record.itemMasterId}`);
          if (response.status == 200) {
            setTriggerAPI((current) => !current);
            successNotification(response.data.status.message);
            // setFieldChang(fieldChang)
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {}
      },
    });
  };

  return (
    <div className="maintenance-report">
      <ItemMasterDetail.Provider
        value={{
          state: {
            dataDefault,
            dataModel,
          },
          setState: {
            setBrandId,
          },
        }}
      >
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {checkPermissionAction('P16PG1C1', 'P16PG1C1A1') ? (
                <div>
                  <Card
                    className="itemmaster-report-card scroll-sm"
                    title={intl.formatMessage({ id: 'itemMasterTextHeader', defaultMessage: 'Item Master' })}
                    extra={
                      <div className="space-create">
                        {checkPermissionAction('P16PG1C1', 'P16PG1C1A2') ? (
                          <Button01
                            Button01
                            btnsize="wd_lg"
                            className="maintenance-card-extra2"
                            type="primary"
                            onClick={() => handleOpenModal()}
                          >
                            <FormattedMessage id="btnAddNew" defaultMessage="Add New" />
                          </Button01>
                        ) : null}
                      </div>
                    }
                  >
                    <div className="action-maintenance-container">
                      <ActionReportV1
                        value={{
                          columns,
                          ignoreFilterColumns,
                          ignoreSearchColumns,
                          fliterByColumn,
                          visible,
                          defaultShowColumn,
                          textErrorSelectColumn,
                        }}
                        fnc={{
                          onChangeSearch,
                          onFliterByColumn,
                          checkedValuecolumns,
                          handleCheckOk,
                          handleReset,
                          handleVisibleChange,
                          handleOnVisiblecolumns,
                        }}
                      />
                    </div>

                    <ItemMasterTable
                      loading={loading}
                      // dataSource={testDataSource}
                      dataSource={itemMasterData}
                      columns={newDataColumns}
                      total={total}
                      currentPage={currentPage}
                      paginationPage={paginationPage}
                      scrollTableX={scrollTableX}
                      fnc={{
                        handleChange,
                        handlePagination,
                        handleSizeChange,
                        handleOpenModal,
                        handleDelete,
                      }}
                    />
                  </Card>
                </div>
              ) : null}
              <ModalForm
                visible={visibleModal}
                setVisible={setVisibleModal}
                setTriggerAPI={setTriggerAPI}
                record={recordItemMaster}
                setRecord={setRecordItemMaster}
                dataDefault={dataDefault}
                setBrandId={setBrandId}
                dataModel={dataModel}
                setCreateItem={setCreateItem}
                createItem={createItem}
                setEventDataDefault={setEventDataDefault}
                groupTypeId={groupTypeId}
                setGroupTypeId={setGroupTypeId}
              />
            </div>
          )}
        </PageSettings.Consumer>
      </ItemMasterDetail.Provider>
    </div>
  );
};
