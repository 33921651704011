import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getTranferList = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/transferitem/page`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getItemTranferList = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/transfer/itemlist`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getItemDetail = async (id) => {
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/warehouse/transferitem/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getSaveTranfer = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/transferitem`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response.data
  }
};

const getTranferItemDetail = async (id) => {
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/warehouse/transferitem/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getItemListDetail = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/transfer/itemdetail`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const uploadARAttachmentPaid = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/paiddocument`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const updateTFStatus = async (payload,id) => {
  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/web/warehouse/transferitem/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getARReportList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/saleorder/ar/report`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getTFModel = async (payload) => {
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/itemmodel/loadmore`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getARVoucherPaid = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/voucherforpaid`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

export {
  getTranferList,
  getItemTranferList,
  getItemDetail,
  getSaveTranfer,
  getTranferItemDetail,
  getItemListDetail,
  updateTFStatus,
  getTFModel,
};
