import React, { useState, useEffect, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import httpClient from '../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import GetLang from '../../includes/language';
import { dd, useQuery } from '../../helpers/general-helper';

const AppContext = createContext();

const AppProvider = (props) => {
  const { children, comId } = props;
  const history = useHistory();
  const query = useQuery();
  const memComId = localStorage.getItem('memComId');
  const pageCode = localStorage.getItem('pageCode');
  const lang = localStorage.getItem('langValue') === 'undefined' ? 'EN' : localStorage.getItem('langValue');
  const today = new Date();
  const dateTimeApi = 'YYYY-MM-DD HH:mm';
  const shiftsDateNow = moment(today).format(dateTimeApi);

  const [orgId, setOrgId] = useState();
  const [parentOrgId, setParentOrgId] = useState();
  const [parentId, setParentId] = useState();
  const [menuTree, setMenuTree] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsDetail, setOrganizationsDetail] = useState({});
  const [organizationsContacts, setOrganizationsContacts] = useState([]);
  const [organizationsApprovers, setOrganizationsApprovers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [addNewOrganization, setAddNewOrganization] = useState(true);
  const [users, setUsers] = useState([]);
  const [requestType, setRequestType] = useState([]);
  const [members, setMembers] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [shiftsType, setShiftsType] = useState([]);

  const [mainShiftsDateTimePicker, setMainShiftsDateTimePicker] = useState(shiftsDateNow);
  const [shiftsYear, setShiftsYear] = useState(moment().format('YYYY'));
  const [shiftsMonth, setShiftsMonth] = useState(moment().format('MMMM'));
  const [languageValue, setLanguageValue] = useState();
  const [refreshSaveFrom, setRefreshSaveForm] = useState(false);
  const [paginationPage, setPaginationPage] = useState();

  const [pageDefault, setPageDefault] = useState(false);

  const [valueDefault, setValueDefault] = useState();
  const [loadingShift, setLoadingShift] = useState(false);
  const [keyTabsMain, setKeyTabsMain] = useState();

  const [triggerTabChange, setTriggerTabChange] = useState(false);

  const [teamTypeData, setTeamTypeData] = useState();
  const [loading, setloading] = useState(false)

  const [keyDetail, setKeyDetail] = useState('');

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getOrganization();
    getRequestType();
    getShiftType();
    if (orgId && orgId !== parseInt(query.get('orgId'))) history.push('/main/resource/organization');
  }, [orgId, refresh, shiftsMonth, shiftsYear, refreshSaveFrom ,keyDetail]);

  useEffect(() => {
    getOrganizationTreeManu();
  }, [orgId, refresh, refreshSaveFrom]);

  const getOrganization = async () => {
    setLoadingShift(true);
    setloading(true)
    const data = {
      com_id: comId,
      org_id: orgId,
      search_team: {
        name: '',
      },
      members: {
        conditional: [],
        order: 'fullname ASC',
        limit: '100',
        index_page: '1',
      },
      services: {},
      shift: {
        date: `${shiftsYear}-${moment().month(shiftsMonth).format('MM')}`
      },
    };

    const response = await httpClient.post('/v2/organizationdetails', data);
    if (response.status === 200) {
      const data = _.isObject(response.data.treeTeams) ? response.data.treeTeams : [];
      const teamMember = _.isObject(response.data.members) ? response.data.members.data : [];
      const teamShift = _.isObject(response.data.shift) ? response.data.shift : [];
      const teamDetails = _.isObject(response.data.organization) ? response.data.organization.teamDetails[0] : [];
      const teamType = _.isObject(response.data.organization) ? response.data.organization.teamType : [];
      const detail = {
        ...teamDetails,
        teamType: [...teamType],
      };
      const contacts = _.isObject(response.data.organization) ? response.data.organization.teamContact : [];
      const approvers = _.isObject(response.data.organization) ? response.data.organization.teamApprover : [];

      setTeamTypeData(response.data.organization.teamDetails[0]);
      setOrganizations(data);
      setOrganizationsDetail(detail);
      setOrganizationsContacts(contacts);
      setOrganizationsApprovers(approvers);
      setMembers(teamMember);
      setShifts(teamShift);
      setLoadingShift(false);
      setloading(false)
    } else {
      setLoadingShift(false);
    }
  };

  const getOrganizationTreeManu = async () => {
    try {
      const response = await httpClient.get(`/v1/resource/manager/company/${comId}/organizations`);

      if (response.status === 200) {
        const data = _.isObject(response.data) ? response.data : [];
        const startOrgId = _.minBy(data, (o) => { return o.parent === '' ? '' : parseInt(o.parent); });
        const dataSortBy = _.sortBy(data, 'name');
        await setMenuTree(creatingTree(dataSortBy, startOrgId.parent));
      }
    } catch (error) {
      return
    }
  };

  const creatingTree = (items, orgId = '', link = 'parent') => {
    return items.filter(item => parseInt(item[link]) === orgId || item[link] === orgId)
      .map(item => ({
        ...item,
        key: item.org_id,
        title: item.name,
        value: item.org_id,
        children: creatingTree(items, item.org_id)
      })
    );
  }

  const getUsers = async () => {
    try {
      const response = await httpClient.get(`/v2/users/${memComId}?comId=${comId}`);

      if (response.status === 200) {
        await setUsers(response.data);
      }
    } catch (error) {
      return
    }
  };

  const getRequestType = async () => {
    try {
      const response = await httpClient.get(`/v2/request/type/${comId}`);

      if (response.status === 200) {
        await setRequestType([...response.data]);
      }
    } catch (error) {
      return
    }
  }

  const getShiftType = async () => {
    try {
      const response = await httpClient.get(`/resource-old/companies/${comId}/shift-types`);

      if (response.status === 200) {
        await setShiftsType(response.data);
      }
    } catch (error) {
      return
    }
  };

  const getRefresh = () => {
    setRefresh(!refresh);
  };

  const Lang = async () => {
    const res = await GetLang({
      companyId: comId,
      lang: lang,
      pageCode: pageCode,
    });
    setLanguageValue(res);
  };

  // const getShiftTypeSetting = async () => {
  //   const result = await httpClient.get(`/resource/manager/organization/${orgId}/shift/rules?comId=${comId}`);
  // }

  // const newList = (teamMember) => {
  //   const list = teamMember && teamMember.map((object) => {
  //     const { mem_id, fullname, profile_img, email, phone } = object;
  //     return {
  //       Id: mem_id,
  //       Text: fullname,
  //       email: email,
  //       phone: phone,
  //       pathImage: profile_img
  //     };
  //   });
  //   return list;
  // }

  // const newShifts = (teamShift) => {
  //   let shiftsArr = [];
  //   teamShift && teamShift.forEach((object) => {
  //     object.shifts.forEach((element) => {
  //       const { mem_shift_id, shift_name, shift_date, mem_id, color, is_holiday, teamName, start, finish } = element;
  //       shiftsArr.push({
  //         Id: mem_shift_id,
  //         Subject: shift_name,
  //         StartTime: moment(shift_date).format('YYYY-MM-DD 00:00'),
  //         EndTime: moment(shift_date).format('YYYY-MM-DD 23:59'),
  //         Color: color,
  //         ResourceId: Number(mem_id),
  //         IsAllDay: true,
  //         isReadonly: is_holiday ? true : false,
  //         TeamName: teamName ? teamName : '',
  //         Start: moment(start, 'HH:mm:ss').format('HH:mm'),
  //         Finish: moment(finish, 'HH:mm:ss').format('HH:mm'),
  //       });
  //     });
  //   });
  //   return shiftsArr;
  // }

  return (
    <AppContext.Provider
      value={{
        state: {
          comId,
          orgId,
          parentOrgId,
          parentId,
          loadingShift,
          menuTree,
          organizations,
          organizationsDetail,
          organizationsContacts,
          organizationsApprovers,
          refresh,
          addNewOrganization,
          users,
          requestType,
          members,
          shifts,
          shiftsYear,
          shiftsMonth,
          shiftsType,
          languageValue,
          refreshSaveFrom,
          paginationPage,
          pageDefault,
          valueDefault,
          mainShiftsDateTimePicker,
          keyTabsMain,
          triggerTabChange,
          teamTypeData,
          loading
        },
        fnc: {
          setOrgId,
          setParentOrgId,
          setParentId,
          setOrganizations,
          setOrganizationsDetail,
          setOrganizationsContacts,
          setOrganizationsApprovers,
          setRefresh,
          getRefresh,
          setAddNewOrganization,
          setUsers,
          setRequestType,
          setMembers,
          setShifts,
          setShiftsYear,
          setShiftsMonth,
          setRefreshSaveForm,
          setPaginationPage,
          setPageDefault,
          setValueDefault,
          setMainShiftsDateTimePicker,
          setKeyTabsMain,
          setTriggerTabChange,
          setTeamTypeData,
          setKeyDetail
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export default {
  AppProvider,
  useAppContext,
};
