import httpClient from '../../components/axiosClient';

const getMemberSelf = async (orgId) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/team/${orgId}/all-members`);


    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getMemberSelf;