import React from 'react';
import MyMapComponent from './mapComponent';
import _ from 'lodash';

const MapWithPolygon = ({ areaList }) => {
  const polygon = [];
  const point = [];

  areaList.forEach((element) => {
    if (element.type === 'polygon') {
      polygon.push(element);
    }
    if (element.type === 'point') {
      point.push(element);
    }
  });

  return <MyMapComponent isMarkerShown={true} polygon={polygon} point={point} />;
};

export default MapWithPolygon;
