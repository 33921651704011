import React from 'react';
import httpClient from '../../components/axiosClient';

const getServiceArea= async () => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/area-master`);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getServiceArea;