import React from 'react';
import stylePost from './css/post.css';

export default ((props) => {
  const { postData, handleHashtag } = props;




  const fncHashtag = (text) => {
    let inputText = text;
    if (inputText) {
      inputText = inputText.split(/(\s)/g).map((item, i) => {
        if (/(^|\s)#[a-zA-Z0-9ก-ฮ๑-๙เแโไใ]+/g.test(item)) {
          return (
            <p key={i} className='post-message-text' onClick={() => handleHashtag(item)} >
              &nbsp;{item}&nbsp;
            </p>
          );
        }
        return item;
      });
      return inputText;
    }
  }

  return (
    <div className='post-message'>
      {fncHashtag(postData.description)}
    </div>
  )
})