import React from 'react'
import './index.css'
import { PettyCashDetailContext } from './context'
import PettyCashDetailContent from './content'

const PagePettyCashDetail = () => {
  return (
    <PettyCashDetailContext>
      <PettyCashDetailContent />
    </PettyCashDetailContext>
  )
}

export default PagePettyCashDetail
