import React, { useContext } from 'react';
import { Input, Icon, Table, Tag } from 'antd';
import Button01 from '../../../../../components/v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import { DeliveryShipmentDetailContext } from '..';
import _ from 'lodash'
import { numberFormatDecimal } from '../../../../../component-function/fnc-number-format-decimal'

const DeliveryOrderParcel = () => {
  const { handleOpenModalParcel, editData, handleSearchParcel, handleCheckCustomerAddress } = useContext(DeliveryShipmentDetailContext)
  const intl = useIntl()

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '7%',
      render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: `deliveryParcelsColumnsTracking`, defaultMessage: 'Tracking No.' }),
      dataIndex: 'parcelNo',
      key: 'parcelNo',
      width: '30%',
    },
    {
      title: intl.formatMessage({ id: `deliveryParcelsColumnsSize`, defaultMessage: 'Size' }),
      dataIndex: 'size',
      key: 'size',
      width: '20%',
      render: (text, record, index) => _.get(record, 'size.sizeName')
    },
    {
      title: intl.formatMessage({ id: `deliveryParcelsColumnsWeight`, defaultMessage: 'Weight' }),
      dataIndex: 'weight',
      key: 'weight',
      width: '17%',
      render: (text, record, index) => numberFormatDecimal(parseFloat(_.get(record, 'weight') || 0))
    },
    // {
    //   title: intl.formatMessage({ id: `deliveryParcelsColumnsShippingPrice`, defaultMessage: 'Shipping Price' }),
    //   dataIndex: 'price',
    //   key: 'price',
    //   width: '20%',
    //   render: (text, record, index) => numberFormatDecimal(parseFloat(_.get(record, 'price') || 0))
    // },
    {
      title: intl.formatMessage({ id: `deliveryParcelsColumnsStatus`, defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: '26%',
      render: (text, record, index) => <Tag className="tag-center-style" style={{ textAlign: 'center', maxWidth: '100px', width: 'auto', minWidth: '80px' }} color={_.get(record, 'status.statusColor')}>{_.get(record, 'status.statusText.EN')}</Tag>
    },
  ];

  const handleClickRow = (record) => {
    if (!_.get(editData, 'sourceDetail.address')) return handleCheckCustomerAddress('parcel')
    handleOpenModalParcel(record)
  }

  return (
    <div style={{ height: 'calc(100vh - 167px)' }}>
      <div style={{ padding: '24px', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Input
            placeholder={intl.formatMessage({ id: `deliveryOrderParcelsInput`, defaultMessage: 'Enter Tracking No. or Scan QR Code' })}
            prefix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
            suffix={<Icon type="scan" style={{ color: "#6490CF" }} />}
            onChange={(e) => handleSearchParcel(e.target.value)}
          />
        </div>
        {
          _.get(editData, 'orderStatus') === "open" ? (
            <div>
              <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }} onClick={() => handleOpenModalParcel()}>
                <FormattedMessage id="btnAdd" defaultMessage="btnAdd" />
              </Button01>
            </div>
          ) : null
        }
      </div>
      <div style={{ padding: '0 24px' }}>
        <div style={{ borderRadius: '10px solid #E5E5E5', border: '1px solid #E5E5E5', height: 'calc(100vh - 265px)' }}>
          <Table
            columns={columns}
            dataSource={_.get(editData, 'pacelDetails')}
            scroll={{ y: 'calc(100vh - 330px)' }}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href && _.get(record, 'status.statusCode') !== 'canceled') {
                    handleClickRow(record)
                  }
                },
              };
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DeliveryOrderParcel
