import React, { useContext } from 'react';
import { Row, Col, Card, Menu } from 'antd';
import Button_01 from '../../components/v2/button_01';
import './css/index.css';
import Filter from './components/vehicle/filter';
import ListItem from './components/vehicle/list-item';
import { PlanningContext } from './context';
import { FormattedMessage } from 'react-intl';
import ButtonActionGroup from '../../components/action-button';
import _ from 'lodash';

export default () => {
  const { fnc, state, setState } = useContext(PlanningContext);
  const { selectGroup, isWaitingOrder, vehicleList } = state;
  const { setIsWaitingOrder } = setState;
  const { openModalWaitingOrder, openModalPreviewOrder } = fnc;

  const titleAction = (onOpen) => {
    const menu = (
      <Menu>
        <Menu.Item key="2">
          <p onClick={onOpen} disabled={isWaitingOrder}>
            <FormattedMessage id="buttonWaitingOrder" defaultMessage="Waiting Order" />
          </p>
        </Menu.Item>
        {vehicleList && _.size(vehicleList) !== 0 ? (
          <Menu.Item key="3">
            <p onClick={openModalPreviewOrder}>
              <FormattedMessage id="previewOrderPlaning" defaultMessage="Preview order Planing" />
            </p>
          </Menu.Item>
        ) : null}
      </Menu>
    );

    return (
      <div className="header-card-filter-load">
        <div>
          <span>
            <FormattedMessage id="planningFilterVehicle" defaultMessage="Vehicle" />
          </span>
        </div>
        <div>
          <ButtonActionGroup menu={menu} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Card className="card-load-and-route" title={titleAction(openModalWaitingOrder)} bodyStyle={{ padding: 'unset' }}>
        <div className="card-load-and-route-all-object">
          <Filter />
          <ListItem />
        </div>
      </Card>
    </div>
  );
};
