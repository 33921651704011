import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Tree, Icon, Input, notification, Modal } from 'antd';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import Provider from './provider';
import styled from 'styled-components';
import httpClient from '../../components/axiosClient';
import { PageSettings } from '../../../src/config/page-settings';
import cssStyle from './css/organizationTree.css';
import { dd, useQuery } from '../../helpers/general-helper';
import _ from 'lodash';

const { TreeNode, DirectoryTree } = Tree;

const dataList = [];
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, name, parent } = node;
    if (!_.find(dataList, ['key', key])) dataList.push({ key, title: name, parent });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export default (props) => {
  const { setOrgIdSelect } = props;
  const intl = useIntl();
  const query = useQuery();
  const app = Provider.useAppContext();
  const appState = app.state;
  const comId = app.state.comId;
  let orgId = app.state.orgId;

  let disabledButton = orgId ? false : true;

  const [gSource, setGSource] = useState([]);
  const [source, setSource] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [orgSelectTree, setOrgSelectTree] = useState('');
  const [refreshTable, setRefreshTable] = useState(false);

  const handelClickOrganization = (orgId) => {
    const parentId = app.state.orgId ? app.state.orgId : '';
    app.fnc.setOrgId();
    app.fnc.setParentId(parentId);
    app.fnc.setAddNewOrganization(true);
    app.fnc.setPageDefault(true);

    // if (parentId === undefined) {
    //   app.fnc.setAddNewOrganization(false);
    // }
  };

  // const loop = (data) =>
  //   data.map((item) => {
  //     const index = item.title.indexOf(searchValue);
  //     const beforeStr = item.title.substr(0, index);
  //     const afterStr = item.title.substr(index + searchValue.length);
  //     const title =
  //       index > -1 ? (
  //         <span>
  //           {beforeStr}
  //           <span style={{ color: '#1D3557' }}>{searchValue}</span>
  //           {afterStr}
  //         </span>
  //       ) : (
  //         <p className="styleTreeNode">{item.title}</p>
  //       );

  //     if (item.children && item.children.length) {
  //       return (
  //         <TreeNode  key={item.key} title={title}>
  //           {loop(item.children)}
  //         </TreeNode>
  //       );
  //     }
  //     return (
  //       <TreeNode key={item.key} title={title} />
  //     );
  //   });

  // const handleSelectNode = (selectedKeys, e) => {

  //   setRefreshTable(!refreshTable);
  //   setOrgSelectTree(parseInt(selectedKeys[0]));
  //   app.fnc.setOrgId(parseInt(selectedKeys[0]));
  //   app.fnc.setAddNewOrganization(false);
  //   app.fnc.setPageDefault(true);
  // };

  // const onSearch = (e) => {
  //   const { value } = e.target;
  //   const expKeys = gSource
  //     .map((item) => {
  //       if (item.title.indexOf(value) > -1) {
  //         return getParentKey(item.key, source);
  //       }
  //       return null;
  //     })
  //     .filter((item, i, self) => item && self.indexOf(item) === i);

  //   setExpandedKeys(expKeys);
  //   setSearchValue(value);
  //   setAutoExpandParent(true);
  // };

  // const getParentKey = (key, tree) => {
  //   let parentKey;
  //   for (let i = 0; i < tree.length; i++) {
  //     const node = tree[i];
  //     if (node.children) {
  //       if (node.children.some((item) => item.key === key)) {
  //         parentKey = node.key;
  //       } else if (getParentKey(key, node.children)) {
  //         parentKey = getParentKey(key, node.children);
  //       }
  //     }
  //   }
  //   return parentKey;
  // };

  // const onExpand = (expandedKeys, { expanded, node }) => {
  //   setRefreshTable(!refreshTable);
  //   setExpandedKeys(expandedKeys);
  //   setAutoExpandParent(false);
  // };

  const handleDelete = (contact) => {
    Modal.confirm({
      className: 'organizationTree-modal-confirm',
      icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
      title: intl.formatMessage({ id: 'userMgntUsersDetailModalDelete', defaultMessage: 'Are you sure to delete ?' }),
      okText: intl.formatMessage({ id: 'btnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'btnNo', defaultMessage: 'No' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const orgId = orgSelectTree;
        try {
          const response = await httpClient.delete(`/resource/manager/company/${comId}/delete/organization/${orgId}`);
          if (response.status === 200) {
            deletedNotificationSuccess(response.data.message);
            app.fnc.setAddNewOrganization(true);
            app.fnc.setOrgId();
            app.fnc.setParentId();
            app.fnc.setPageDefault(false);
          }
        } catch (error) {
          deletedNotificationError(error.response.data.message);
        }
      },
      onCancel() {},
    });
  };

  generateList(app.state.menuTree);

  const onChange = (e) => {
    const { value } = e.target;
    const expandedKeys = dataList
      .map((item) => {
        if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return getParentKey(item.key, app.state.menuTree);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setExpandedKeys(value ? expandedKeys : []);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const onExpand = (expandedKeys, e) => {
    let newExpandedKeys = [...expandedKeys];
    let nodeChildren = _.get(e, 'node.props.children');
    if (!e.expanded) {
      _.remove(newExpandedKeys, (o) => {
        return _.find(nodeChildren, ['key', o]) ? true : false;
      });
    }
    setRefreshTable(!refreshTable);
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const handleSelectOrganization = (selectedKeys) => {
    const getParentKeyAll = getParentKey(_.toInteger(selectedKeys[0]), app.state.menuTree);
    setRefreshTable(!refreshTable);
    setOrgSelectTree(parseInt(selectedKeys[0]));
    setOrgIdSelect(parseInt(selectedKeys[0]));
    app.fnc.setOrgId(parseInt(selectedKeys[0]));
    app.fnc.setParentOrgId(getParentKeyAll);
    app.fnc.setAddNewOrganization(false);
    app.fnc.setPageDefault(true);
    app.fnc.setValueDefault();
  };

  const renderTreeNodes = (data) =>
    data.map((item) => {
      const index = item.title.toLowerCase().indexOf(searchValue.toLowerCase());
      const searchMatch = index > -1 ? item.title.substr(index, searchValue.length) : '';
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#f50' }}>{searchMatch}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return (
          <TreeNode title={item.title} key={`${item.key}`} title={title}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={`${item.key}`} title={title} />;
    });

  const deletedNotificationSuccess = (message) => {
    notification.success({
      message: message,
    });
  };

  const deletedNotificationError = (message) => {
    notification.error({
      message: message,
    });
  };

  useEffect(() => {
    setGSource([...props.gData]);
    setSource([...props.data]);
    if (query.get('orgId') && orgId === undefined) {
      handleSelectOrganization([query.get('orgId')]);
      // onExpand([(query.get('parentOrgId') === 'undefined' ? _.toInteger(query.get('orgId')) : _.toInteger(query.get('parentOrgId')))]);
    }
  }, [props, refreshTable]);

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <>
          {checkPermissionAction('P3PG1C1', 'P3PG1C1A1') ? (
            <Card
              className="organizationTree-card"
              title={<FormattedMessage id="orgTreeLblTitleOrganization" defaultMessage="Organization" />}
              extra={
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {orgId && orgSelectTree && checkPermissionAction('P3PG1C1', 'P3PG1C1A3') ? (
                        <Button02 style={{margin : '0px 0px 0px 10px'}} fontsize="sm" btnsize="wd_df" margin="mg_cs1" onClick={() => handleDelete()}>
                          <FormattedMessage id="orgTreeBtnDelete" defaultMessage="Delete" />
                        </Button02>
                      ) : null}

                      {checkPermissionAction('P3PG1C1', 'P3PG1C1A2') ? (
                        <Button01
                          type="primary"
                          fontsize="sm"
                          btnsize="wd_df"
                          margin="mg_cs2"
                          onClick={() => handelClickOrganization()}
                          disabled={disabledButton}
                        >
                          <FormattedMessage id="orgTreeBtnAddNew" defaultMessage="Add New" />
                        </Button01>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              }
            >
              <div className="organizationTree-body">
                <div className="organization-manu-input-div">
                  <Input
                    className="organization-manu-input"
                    style={{ marginBottom: 8 }}
                    placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                    prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
                    onChange={onChange}
                    autoComplete="off"
                  />
                </div>

                <DirectoryTree
                  className="organization-manu-Tree"
                  showIcon={false}
                  onSelect={handleSelectOrganization}
                  onExpand={onExpand}
                  expandedKeys={
                    _.size(expandedKeys) > 0
                      ? expandedKeys
                      : [query.get('parentOrgId') === 'undefined' ? query.get('orgId') : query.get('parentOrgId')]
                  }
                  autoExpandParent={true}
                  switcherIcon={<Icon type="caret-down" style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.65)' }} />}
                  defaultSelectedKeys={[query.get('orgId')]}
                  selectable={true}
                >
                  {renderTreeNodes(app.state.menuTree)}
                </DirectoryTree>
              </div>
            </Card>
          ) : null}
        </>
      )}
    </PageSettings.Consumer>
  );
};
