import httpClient from '../../components/axiosClient';

const getDataSenderReciver = async ({ orgTeamIdArr, fliterByColumn, fieldChang, paginationPage, paginationSize }) => {

  const comCode = localStorage.getItem('comCode');

  const body = {
    orgId: orgTeamIdArr,
    search: fieldChang,
    searchBy: fliterByColumn,
    paging: paginationPage ? paginationPage : 1,
    rowsPerPages: paginationSize
  }
  try {
    const response = await httpClient.post(`/v3/item/manager/${comCode}/finddatamemberbyorg`, body);
    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}


const cancelItem = async ({ item_id }) => {

  const comCode = localStorage.getItem('comCode');

  const body = {
    itemId: item_id,
    status: 'cancelled'
  }
  try {
    const response = await httpClient.put(`/v3/item/manager/${comCode}/updatedataitem`, body);
    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export { getDataSenderReciver, cancelItem }

