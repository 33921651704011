import React, { useState, useContext, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import { PlanningContext } from '../../../../pages/load-and-route/context';
import SvgComponent from './svgmark';

export default function SimpleMap() {
  const { state, fnc } = useContext(PlanningContext);
  const { vehicleScrollRef, active, vehicle, dataById, vehicleList, visiblePreviewOrder, markDetail, selectMark } = state;
  const dataSource = _.get(dataById, 'orderDetail');
  const colormark = _.filter(vehicle, { id: active });

  const mapPolyRef = useRef(null);
  const [googleMapzoom, setGoogleMapzoom] = useState(12);
  const [googleMapcenter, setGoogleMapcenter] = useState({ lat: 13.90607, lng: 100.51913 });

  const bootstrapURLKeys = {
    key: `${localStorage.getItem('GEO_APIKEY')}`,
    libraries: ['drawing'].join(','),
  };

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      fullscreenControlOptions: false,
      streetViewControl: false,
      zoomControl: true,
      clickableIcons: false,
      disableDefaultUI: true,
    };
  };

  const Marker = ({ color, number }) => {
    return (
      <div style={{ width: 70, height: 30 }}>
        <SvgComponent text={`${number + 1}`} color={color} />
      </div>
    );
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: 'calc(100vh - 163px)', width: '100%' }}>
      <GoogleMapReact
        id={'monitor-map-view'}
        ref={mapPolyRef}
        bootstrapURLKeys={bootstrapURLKeys}
        zoom={googleMapzoom}
        center={googleMapcenter}
        options={getMapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {selectMark === true
          ? _.map(dataSource, (item, index) => {
              return (
                <Marker
                  lat={item.destinationLat}
                  lng={item.destinationLng}
                  // color={_.get(vehicleList[colormark], 'colorCode')}
                  color={_.get(item, 'pinColor')}
                  number={index}
                />
              );
            })
          : _.map(markDetail, (itemve, indexcolor) => {
              return _.map(markDetail[indexcolor], (item, index) => {
                return (
                  <Marker
                    lat={item.destinationLat}
                    lng={item.destinationLng}
                    color={_.get(item, 'pinColor')}
                    number={index}
                  />
                );
              });
            })}
      </GoogleMapReact>
    </div>
  );
}
