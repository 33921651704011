import httpClient from '../../components/axiosClient';
import _ from 'lodash';

const getPolylineAndDistance = async (marker) => {
  const comCode = localStorage.getItem('comCode');

  const body = {
    latFrom: parseFloat(_.get(marker,'[0].lat')),
    lngFrom: parseFloat(_.get(marker,'[0].lng')),
    latTo: parseFloat(_.get(marker,'[1].lat')),
    lngTo: parseFloat(_.get(marker,'[1].lng')),
  }
  
  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comCode}/polyline/point-to-point`,body);
    if (response.status === 200) {
      return await response.data
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }

}

export default getPolylineAndDistance;