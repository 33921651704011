import React from 'react'
import { Checkbox, Row, Col } from 'antd';
import '../../css/index.css'
import { FormattedMessage } from 'react-intl';
import _ from 'lodash'
import Nodata from '../../../image/Nodata.svg'

const SearchItemGroup = (props) => {
  const { itemGroup } = props

  const handleCheckboxChange = (checkedList) => {
    itemGroup.setItemGroupSelect(checkedList);
    itemGroup.setItemGroupInterminate(!!checkedList.length && checkedList.length < itemGroup.itemGroupData.length);
    itemGroup.setItemGroupCheckAll(checkedList.length === itemGroup.itemGroupData.length);
  };

  const handleCheckAllChange = (e) => {
    itemGroup.setItemGroupSelect(e.target.checked ? _.map(itemGroup.itemGroupData, (item) => { return item.itemGroupId }) : []);
    itemGroup.setItemGroupInterminate(false);
    itemGroup.setItemGroupCheckAll(e.target.checked);
  };

  return (
    <div className='searchItemRequestContent'>
      <div className='searchItemRequestContentHeader'>
        <div>
          <span>{_.size(itemGroup.itemGroupSelect)} <FormattedMessage id="textItem" defaultMessage="item" /></span>
        </div>
        <div>
          <span><FormattedMessage id="searchItemRequestTextItemGroup" defaultMessage="Item Group" /></span>
        </div>
      </div>
      <div className='searchItemRequestContentCheckAll'>
        <Checkbox
          indeterminate={itemGroup.itemGroupInterminate}
          onChange={handleCheckAllChange}
          checked={itemGroup.itemGroupCheckAll}
        >
          <FormattedMessage id="searchItemRequestTextItemGroup" defaultMessage="Item Group" />
        </Checkbox>
      </div>
      <div style={{ maxHeight: '360px', overflowY: _.size(itemGroup.itemGroupData) < 9 ? 'hidden' : 'scroll' }}>
        {
          _.size(itemGroup.itemGroupData) > 0 ? (
            <Checkbox.Group
              style={{ width: '100%' }}
              value={itemGroup.itemGroupSelect}
              onChange={handleCheckboxChange}
            >
              {
                _.map(itemGroup.itemGroupData, (item) => (
                  <Row className='searchItemRequestContentDetail' style={{ paddingTop: '12px' }}>
                    <Col span={24}>
                      <Checkbox value={item.itemGroupId}>{item.itemGroupName}</Checkbox>
                    </Col>
                  </Row>
                ))
              }
            </Checkbox.Group>
          ) : (
            <div className='searchItemRequestContentNoDataValidate'>
              <div>
                <img src={Nodata} style={{ width: '100px' }}></img>
              </div>
              <div style={{ color: '#e4e4e4' }}>
                <FormattedMessage id="searchItemRequestTextWarehouseNoItemGroup" defaultMessage="No Item Group" />
              </div>
              <div style={{ color: '#e4e4e4' }}>
                <FormattedMessage id="searchItemRequestTextWarehouseValidateItem" defaultMessage="Please Select Warehouse" />
              </div>
            </div >
          )
        }
      </div >
    </div >
  )
}

export default SearchItemGroup
