import httpClient from '../../components/axiosClient';
import moment from 'moment';

const today = new Date();
const dateApi = 'YYYY-MM-DD';
const dateNow = moment(today).format(dateApi);

const getGanttApi = async (props) => {
  const {startDate, endDate, orgIdArrString, selectFilter, search,typeApi } = props;
  const comId = localStorage.getItem('comId');
    try {
      const response = await httpClient.get(    
        `/v3/task/manager/map/task?startDate=${startDate}&endDate=${endDate}&com_id=${comId}&org_id=${orgIdArrString}&searchBy=${selectFilter}&search=${search}&${typeApi}`
        );
      const data =  await response.data;      
      return data;
    } catch (error) {
      return error.response
    }
}

export default getGanttApi;