import React from 'react';
import Card from '../../../components/v2/card';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import _ from 'lodash';
import './css/index.css';
import { Icon, Popover, Button, Divider } from 'antd';

const AssetInsurance = (props) => {
  const { dataById, handleClickPopover, setSectionModalInsurance } = props;

  return (
    <div>
      <Card
        title={<FormattedMessage id="AssetInsuranceTitle" defaultMessage="Insurance" />}
        extra={
          <div className="extra-user-details">
            <div>
              <Button01
                key="submit"
                type="primary"
                btnsize="wd_df"
                onClick={() => {
                  handleClickPopover('add');
                }}
              >
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button01>
            </div>
          </div>
        }
      >
        {_.map(_.slice(_.get(dataById, 'dataInsuranceActive'), 0, 3), (item, index) => {
          return (
            <div key={`${item.assetInsuranceId}`}>
              <div className="insurance-content-data">
                <div>
                  <div className="insurance-content-text">{item.insuranceName}</div>
                  <div className="insurance-content-text">{item.coverage_period}</div>
                  <div className="insurance-content-text insurance-content-data">
                    <div>
                      <span><FormattedMessage id="assetInsuranceLabelTotalClaim" defaultMessage="Total Claim" />:{item.totalClaim}</span>
                    </div>
                    <div>
                      <span><FormattedMessage id="assetInsuranceLabelAttachment" defaultMessage="Attachment" />:{item.totalDoc}</span>
                    </div>
                  </div>
                </div>
                <div className="insurance-content-text">
                  <Popover
                    placement="right"
                    trigger="hover"
                    content={
                      <div style={{ display: 'grid' }}>
                        <div>
                          <Button
                            style={{ width: 100 }}
                            type="link"
                            ghost
                            onClick={() => {
                              handleClickPopover('view', item);
                              setSectionModalInsurance(true);
                            }}
                          >
                            <FormattedMessage id="btnView" defaultMessage="View" />
                          </Button>
                        </div>
                        <div>
                          <Button
                            style={{ width: 100 }}
                            type="link"
                            ghost
                            onClick={() => {
                              handleClickPopover('edit', item);
                              setSectionModalInsurance(false);
                            }}
                          >
                            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                          </Button>
                        </div>
                        <div>
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleClickPopover('delete', item)}>
                            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                          </Button>
                        </div>
                      </div>
                    }
                  >
                    <Icon type="more" />
                  </Popover>
                </div>
              </div>
              {_.size(_.slice(_.get(dataById, 'dataInsuranceActive'), 0, 3)) !== index + 1 ? <Divider type="horizontal" /> : null}
            </div>
          );
        })}
      </Card>
    </div>
  );
};
export default AssetInsurance;
