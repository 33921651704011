import React, { createContext, useEffect, useRef, useState } from 'react';
import FeedNote from './feed-note';
import ActionNote from './action-note';
import { StickyContainer } from 'react-sticky';
import { ActionNoteContext } from './note_Provirder';
import { getTagList } from '../../controllers/note/feed';
import _ from 'lodash';

const NoteComponent = ({ data, noteData, visible }) => {
  const imageRef = useRef();
  const fileRef = useRef();
  const scrollDivRef = useRef(null);
  const [selectPostAddress, setSelectPostAddress] = useState();
  const [loading, setLoading] = useState(false)
  const [indexStart, setIndexStart] = useState(0);
  const [indexEnd, setIndexEnd] = useState(10);
  const [tagList, setTagList] = useState();
  useEffect(() => {
    const getTagListFnc = async () => {
      const body = {
        indexStart: 0,
        indexEnd: 9999,
        tagCode: '',
        tagName: '',
      };

      const response = await getTagList(body);

      console.log('ERERERER', _.get(response, 'data'));


      setTagList(_.get(response, 'data.data.tagList'))

    };
    if (visible) {
      getTagListFnc();
    }
  }, [visible]);

  const setDataAddress = (data) => {
    setSelectPostAddress(data);
  };

  const handleCloseAddress = () => {
    setSelectPostAddress([]);
  };

  const handleScrollEnd = (scroll) => {
    const middleScrollPosition = (scrollDivRef.current.scrollHeight - scrollDivRef.current.clientHeight) / 2;
    if (scroll === 10) {
      scrollDivRef.current.scrollTop = scrollDivRef.current.scrollHeight;
    } else {
      scrollDivRef.current.scrollTop = middleScrollPosition
    }
  }

  const handleScroll = (e, total) => {
    if (e.target.scrollTop === 0 && indexEnd < total) {
      setLoading(true)
      setIndexStart((prevPage) => prevPage + 10)
      setIndexEnd((prevPage) => prevPage + 10);
    }
  }



  return (
    <ActionNoteContext.Provider
      value={{
        state: {
          selectPostAddress,
          imageRef,
          fileRef,
          indexStart,
          indexEnd,
          scrollDivRef,
          loading,
          tagList
        },
        setState: {
          setSelectPostAddress,
          setLoading,
          setIndexStart,
          setIndexEnd
        },
        fnc: {
          handleCloseAddress,
          setDataAddress,
          handleScrollEnd,
          handleScroll
        },
      }}
    >
      <div>
        <FeedNote data={data} />

        <ActionNote data={noteData} tagList={tagList} />
      </div>
    </ActionNoteContext.Provider>
  );
};

export default NoteComponent;
