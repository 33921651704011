import React, { useState, useEffect, useRef } from 'react';
import { Table, Modal, Divider, Input, Icon, Spin } from 'antd';
import Button_01 from '../../v2/button_01';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import './css/index.css';
import _ from 'lodash';
import { getWaitingOrder, getDeliveryOrder } from '../../../controllers/load-and-route/api';
import { InfinityTable } from 'antd-table-infinity';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const comCode = localStorage.getItem('comCode');

export default (props) => {
  const { assetId, visible, onClose, onClickRow, onClickRowRemove, dataById, trigger, shippingDate } = props;
  const intl = useIntl();

  // console.log('dataById ::', dataById)

  const columns =
    comCode === 'PPP'
      ? [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) => record.index,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
            dataIndex: 'orderNo',
            key: 'orderNo',
            fixed: 'left',
            width: 180,
            sorter: (a, b) => a.orderNo - b.orderNo,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
            dataIndex: 'productName',
            key: 'productName',
            fixed: 'left',
            width: 240,
            sorter: (a, b) => a.productName - b.productName,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <span title={record.productName}>{_.truncate(record.productName, 20)}</span>,
          },
          {
            title: intl.formatMessage({ id: `planningColumnQty`, defaultMessage: 'Qty' }),
            dataIndex: 'total',
            key: 'total',
            width: 100,
            sorter: (a, b) => a.total - b.total,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.total} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnWeight(kg)`, defaultMessage: 'Weight (kg)' }),
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.weight} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnLoaded`, defaultMessage: 'Loaded (%)' }),
            dataIndex: 'loadedView',
            key: 'loadedView',
            width: 150,
            sorter: (a, b) => a.loadedView - b.loadedView,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
            dataIndex: 'customerName',
            key: 'customerName',
            width: 220,
            sorter: (a, b) => a.customerName - b.customerName,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressSource`, defaultMessage: 'Source' }),
            dataIndex: 'sourceAddress',
            key: 'sourceAddress',
            width: 240,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressDestination`, defaultMessage: 'Destination' }),
            dataIndex: 'address',
            key: 'address',
            width: 240,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryDate`, defaultMessage: 'Delivery Date' }),
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            width: 150,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryTime`, defaultMessage: 'Delivery Time' }),
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
            width: 150,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
          },
        ]
      : [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) => record.index,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
            dataIndex: 'orderNo',
            key: 'orderNo',
            fixed: 'left',
            ellipsis: true,
            width: 170,
            sorter: (a, b) => a.orderNo - b.orderNo,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
            dataIndex: 'productName',
            key: 'productName',
            fixed: 'left',
            ellipsis: true,
            width: 240,
            sorter: (a, b) => a.productName - b.productName,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <span title={record.productName}>{_.truncate(record.productName, 20)}</span>,
          },
          {
            title: intl.formatMessage({ id: `planningColumnQty`, defaultMessage: 'Qty' }),
            dataIndex: 'total',
            key: 'total',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.qty - b.qty,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <NumberFormat value={record.total} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnWeight(kg)`, defaultMessage: 'Weight (kg)' }),
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.weight} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnLoaded`, defaultMessage: 'Loaded (%)' }),
            dataIndex: 'loadedView',
            key: 'loadedView',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => a.loadedView - b.loadedView,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: true,
            width: 240,
            sorter: (a, b) => a.customerName - b.customerName,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressSource`, defaultMessage: 'Source' }),
            dataIndex: 'sourceAddress',
            key: 'sourceAddress',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressDestination`, defaultMessage: 'Destination' }),
            dataIndex: 'address',
            key: 'address',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnShipDate`, defaultMessage: 'Shipping Date' }),
            dataIndex: 'shippingDate',
            key: 'shippingDate',
            width: 200,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
          },
        ];

  const columnsDelivery =
    comCode === 'PPP'
      ? [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) => index + 1,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
            dataIndex: 'orderNo',
            key: 'orderNo',
            fixed: 'left',
            width: 180,
            sorter: (a, b) => a.orderNo - b.orderNo,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
            dataIndex: 'productName',
            key: 'productName',
            fixed: 'left',
            width: 240,
            sorter: (a, b) => a.productName - b.productName,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <span title={record.productName}>{_.truncate(record.productName, 20)}</span>,
          },
          {
            title: intl.formatMessage({ id: `planningColumnQty`, defaultMessage: 'Qty' }),
            dataIndex: 'total',
            key: 'total',
            width: 100,
            sorter: (a, b) => a.total - b.total,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.total} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnWeight(kg)`, defaultMessage: 'Weight (kg)' }),
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.weight} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnLoaded`, defaultMessage: 'Loaded (%)' }),
            dataIndex: 'loadedView',
            key: 'loadedView',
            width: 150,
            sorter: (a, b) => a.loadedView - b.loadedView,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
            dataIndex: 'customerName',
            key: 'customerName',
            width: 220,
            sorter: (a, b) => a.customerName - b.customerName,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressSource`, defaultMessage: 'Source' }),
            dataIndex: 'sourceAddress',
            key: 'sourceAddress',
            width: 240,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressDestination`, defaultMessage: 'Destination' }),
            dataIndex: 'address',
            key: 'address',
            width: 240,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryDate`, defaultMessage: 'Delivery Date' }),
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            width: 150,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnDeliveryTime`, defaultMessage: 'Delivery Time' }),
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
            width: 150,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
          },
        ]
      : [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) => index + 1,
          },
          {
            title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
            dataIndex: 'orderNo',
            key: 'orderNo',
            fixed: 'left',
            ellipsis: true,
            width: 170,
            sorter: (a, b) => a.orderNo - b.orderNo,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
            dataIndex: 'productName',
            key: 'productName',
            fixed: 'left',
            width: 240,
            ellipsis: true,
            sorter: (a, b) => a.productName - b.productName,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <span title={record.productName}>{_.truncate(record.productName, 20)}</span>,
          },
          {
            title: intl.formatMessage({ id: `planningColumnQty`, defaultMessage: 'Qty' }),
            dataIndex: 'total',
            key: 'total',
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.qty - b.qty,
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => <NumberFormat value={record.total} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnWeight(kg)`, defaultMessage: 'Weight (kg)' }),
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['descend', 'ascend'],
            ellipsis: true,
            render: (text, record, index) => <NumberFormat value={record.weight} displayType={'text'} thousandSeparator={true} />,
          },
          {
            title: intl.formatMessage({ id: `planningColumnLoaded`, defaultMessage: 'Loaded (%)' }),
            dataIndex: 'loadedView',
            key: 'loadedView',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => a.loadedView - b.loadedView,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: true,
            width: 240,
            sorter: (a, b) => a.customerName - b.customerName,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressSource`, defaultMessage: 'Source' }),
            dataIndex: 'sourceAddress',
            key: 'sourceAddress',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnAddressDestination`, defaultMessage: 'Destination' }),
            dataIndex: 'address',
            key: 'address',
            width: 250,
            sorter: true,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnShipDate`, defaultMessage: 'Shipping Date' }),
            dataIndex: 'shippingDate',
            key: 'shippingDate',
            width: 200,
            ellipsis: true,
          },
          {
            title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
          },
        ];

  // const columnsDelivery =  [
  //   {
  //     title: '#',
  //     dataIndex: 'index',
  //     key: 'index',
  //     width: 50,
  //     align: 'center',
  //     render: (text, record, index) => index + 1
  //   },
  //   {
  //     title: intl.formatMessage({ id: `planningColumnOrderNo`, defaultMessage: 'Order No.' }),
  //     dataIndex: 'orderNo',
  //     key: 'orderNo',
  //     width: 175,
  //     sorter: true,
  //     sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `planningColumnItem`, defaultMessage: 'Item' }),
  //     dataIndex: 'productName',
  //     key: 'productName',
  //     width: 170,
  //     sorter: true,
  //     sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `planningColumnQty`, defaultMessage: 'Qty' }),
  //     dataIndex: 'total',
  //     key: 'total',
  //     width: 120,
  //     sorter: true,
  //     sortDirections: ['descend', 'ascend'],
  //     render: (text, record, index) =>
  //       <NumberFormat value={record.total} displayType={'text'} thousandSeparator={true} />
  //   },
  //   {
  //     title: intl.formatMessage({ id: `planningColumnLoaded`, defaultMessage: 'Loaded (%)' }),
  //     dataIndex: 'loadedView',
  //     key: 'loadedView',
  //     width: 140,
  //     sorter: true,
  //     sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `planningColumnCustomer`, defaultMessage: 'Customer' }),
  //     dataIndex: 'customerName',
  //     key: 'customerName',
  //     width: 180,
  //     sorter: true,
  //     ellipsis: true,
  //     sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `planningColumnAddress`, defaultMessage: 'Address' }),
  //     dataIndex: 'address',
  //     key: 'address',
  //     width: 200,
  //     sorter: true,
  //     sortDirections: ['descend', 'ascend'],
  //     ellipsis: true
  //   },
  //   {
  //     title: intl.formatMessage({ id: `planningColumnShipDate`, defaultMessage: 'Shipping Date' }),
  //     dataIndex: 'shippingDate',
  //     key: 'shippingDate',
  //     width: 180,
  //     // sorter: true,
  //     ellipsis: true
  //   },
  //   {
  //     title: intl.formatMessage({ id: `planningColumnRemark`, defaultMessage: 'Remark' }),
  //     dataIndex: 'remark',
  //     key: 'remark',
  //     // width: '18%',
  //     ellipsis: true
  //   },

  // ];

  //-------------------------waiting order--------------------------------//
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [fieldChange, setFieldChange] = useState();
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [waitingData, setWaitingData] = useState();
  const [waitingDataMaster, setWaitingDataMaster] = useState([]);
  const [waitingDataPaging, setWaitingDataPaging] = useState(1);
  const [waitingDataPagingFilter, setWaitingDataPagingFilter] = useState(0);
  const [waitingDataSource, setWaitingDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  //-------------------------delivery order--------------------------------//
  const [totalDelivery, setTotalDelivery] = useState();
  const [extraSortDelivery, setExtraSortDelivery] = useState();
  const [fieldSortDelivery, setFieldSortDelivery] = useState();
  const [orderSortDelivery, setOrderSortDelivery] = useState();
  const [waitingDataSourceDelivery, setWaitingDataSourceDelivery] = useState([]);
  const [loadingDelivery, setLoadingDelivery] = useState(false);

  useEffect(() => {
    setLoadingDelivery(true);
    const getDataWaitingOrRemove = async () => {
      const response = await getDeliveryOrder(assetId, orderSortDelivery, fieldSortDelivery, shippingDate);
      setWaitingDataSourceDelivery(_.get(response, 'data'));
      // setTotalDelivery(response.data.length > 0 ? response.data.length : 0);
      setTotalDelivery(_.size(_.get(response, 'data')) > 0 ? _.size(_.get(response, 'data')) : 0);
      setLoadingDelivery(false);
    };
    if (visible === true && assetId) {
      getDataWaitingOrRemove();
    }
  }, [visible, assetId, orderSortDelivery, fieldSortDelivery, trigger]);

  useEffect(() => {
    setLoading(true);
    const getDataWaitingOrRemove = async () => {
      if (visible) {
        // setLoading(true)
        const body = {
          assetId: _.get(dataById, 'assetId'),
          search: fieldChange,
          searchBy: 'all',
          orderType: orderSort,
          orderBy: fieldSort,
          paging: fieldChange ? 1 : paginationPage,
          rowsPerPages: paginationSize,
          shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
        };
        const response = await getWaitingOrder(body);
        if (_.size(response) !== 0) {
          setWaitingData(response);
          setWaitingDataSource(response.data);
          setLoading(false);
          setTotal(_.get(response, 'totalData'));
        }
      }
    };
    if (visible === true && assetId) {
      getDataWaitingOrRemove();
    }
  }, [visible, fieldChange, orderSort, fieldSort, paginationPage, paginationSize, trigger]);

  // useEffect(() => {
  //   if (!fieldChange) {
  //     setWaitingDataSource(waitingDataMaster)
  //   } else {
  //     const getDataWaitingOrRemove = async () => {
  //       const body = {
  //         search: fieldChange,
  //         searchBy: "all",
  //         orderType: orderSort,
  //         orderBy: fieldSort,
  //         paging: waitingDataPagingFilter + 1,
  //         rowsPerPages: 10
  //       }
  //       const response = await getWaitingOrder(body)
  //       if (_.size(response !== 0)) {
  //         setWaitingDataSource(prevItems => [...prevItems, ..._.get(response, 'data')])
  //       }
  //       // const concat = _.concat(waitingDataSource, response.data)
  //       // setWaitingData(response)
  //     }
  //     if (fieldChange) {
  //       getDataWaitingOrRemove();

  //     }
  //   }

  // }, [fieldChange, orderSort, fieldSort, trigger]);

  const onChangeSearch = (value) => {
    setWaitingDataSource([]);
    setFieldChange(value);
    setWaitingDataPagingFilter(0);
  };

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const loadMoreContent = () => (
    <div
      style={{
        textAlign: 'center',
        paddingTop: 40,
        paddingBottom: 40,
        border: '1px solid #e8e8e8',
      }}
    >
      <Spin tip="Loading..." />
    </div>
  );

  const handleFetch = (e) => {
    // console.log('handleFetch',e)
    const target = e.target;
    // console.log('target.scrollTop + target.clientHeigh',e.scrollHeight-10)
    if (target.scrollTop + target.clientHeight === target.scrollHeight) {
      // setLoading(true)
      if (fieldChange) {
        setWaitingDataPagingFilter((current) => current + 1);
      } else {
        setWaitingDataPaging((current) => current + 1);
      }
    }
  };

  const handleChangeDelivery = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSortDelivery('desc');
      } else {
        setOrderSortDelivery('asc');
      }
    } else {
      setOrderSortDelivery('');
    }
    setExtraSortDelivery(extra);
    setFieldSortDelivery(field);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div>
      <Modal
        title={`${_.get(dataById, 'assetNo')} · ${_.get(dataById, 'assetClassName')}${
          _.get(dataById, 'borrower.fullname') ? ` · ${_.get(dataById, 'borrower.fullname')}` : ''
        } ${_.get(dataById, 'loadedView')}`}
        width={'98%'}
        centered
        visible={visible}
        onCancel={onClose}
        bodyStyle={{ height: '580px', padding: '10px 0px 0px 0px' }}
        footer={[
          <Button_01 type="primary" btnwidth="wd_at" onClick={onClose}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_01>,
        ]}
      >
        <div>
          <div style={{ marginBottom: '24px', height: '210px' }}>
            <div className="table-waiting-add-or-remove">
              <div>
                <span className="table-waiting-add-or-remove-text-head">{`${intl.formatMessage({
                  id: `planningTextDeliveryOrders`,
                  defaultMessage: 'Delivery Orders',
                })} (${totalDelivery})`}</span>
              </div>
            </div>
            <Divider type="horizontal" style={{ margin: '16px 0px 8px 0px' }} />
            <div style={{ padding: '0px 24px' }}>
              <Table
                // size='small'
                className="table-waiting-order-remove scroll-sm table-placeholder-deliver"
                loading={loadingDelivery}
                rowKey={(record) => record.productIdOrder}
                onChange={handleChangeDelivery}
                dataSource={waitingDataSourceDelivery}
                columns={columnsDelivery}
                scroll={{ x: 2000, y: 115 }}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => onClickRowRemove(record), // click row
                  };
                }}
              />
            </div>
          </div>

          <div>
            <div className="table-waiting-add-or-remove">
              <div>
                <span className="table-waiting-add-or-remove-text-head">
                  {`${intl.formatMessage({ id: `planningFilterWaitingOrder`, defaultMessage: 'Waiting Orders' })} (${_.get(
                    waitingData,
                    'totalData'
                  )})`}{' '}
                </span>
              </div>
              <div>
                <Input
                  className="action-report-input"
                  placeholder={intl.formatMessage({
                    id: 'hintSearch',
                    defaultMessage: 'Search',
                  })}
                  prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
                  onChange={(e) => onChangeSearch(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <Divider type="horizontal" style={{ margin: '8px 0px' }} />
            <div style={{ padding: '0px 24px' }}>
              <Table
                // size='small'
                className="table-waiting-order-remove scroll-sm"
                loading={loading}
                rowKey={(record) => record.productIdOrder}
                onChange={handleChange}
                dataSource={waitingDataSource}
                columns={columns}
                scroll={{ x: 2000, y: 163 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => onClickRow(record), // click row
                  };
                }}
                pagination={{
                  showTotal: showTotal,
                  total: total,
                  defaultCurrent: 1,
                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                  showSizeChanger: true,
                  locale: { items_per_page: '' },
                  onChange: handlePagination,
                  onShowSizeChange: handleSizeChange,
                }}
              />
              {_.get(waitingData, 'totalData') && _.get(waitingData, 'totalData').length !== 0 ? (
                <div className="total-items-waiting-order">
                  <span style={{ fontSize: '13px' }}>
                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                    {` ${_.get(waitingData, 'totalData') || 0} `}
                    <FormattedMessage id="lblitems" defaultMessage="items" />
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
