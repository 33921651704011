import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Select, Input, AutoComplete, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import LabeRequireForm from '../../../../components/label-required-form';
import { SelectCustomerHook } from './selectHook';
import _ from 'lodash';
import Addcustomer from '../../../../components/add-business-partner/index';

const { Option } = Select;

const SelectCustomerModal = (props) => {
  const {
    form,
    title,
    visible,
    onOk,
    setIsOpen,
    dataForm,
    setDataForm,
    isOpenQuotation,
    setSelectCustomer,
    selectCustomer,
    setSelectItem,
  } = props;
  const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = form;
  const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer,searchCustomer } = SelectCustomerHook();
  const intl = useIntl();
  console.log('propssssss', props);
  const [loading, setLoading] = useState(false);
  const [visibleCustomer, setvisibleCustomer] = useState(false);
  const [customerTrigger, setCustomerTrigger] = useState(false);

  console.log('customerList',customerList);

  useEffect(() => {
    if (visible) {
      resetCustomer(visible);
    }
  }, [visible,customerTrigger]);

  useEffect(() => {
    if (_.get(dataForm, 'customerId') && visible) {
      setLoading(true);
      setFieldsValue({
        ['customer']: _.get(dataForm, 'customerId') || undefined,
      });

      setTimeout(() => {
        setFieldsValue({
          ['customerContact']: _.get(dataForm, 'customerContactId') || undefined,
          ['customerContactPhone']: _.get(dataForm, 'customerContactPhone') || undefined,
          ['customerAddress']: _.get(dataForm, 'customerAddressBookId') || undefined,
        });
      }, 300);
      setLoading(false);
    }
  }, [dataForm, visible]);

  useEffect(() => {
    if (!loading) {
      const customer = getFieldValue('customer');
      if (customer) {
        const filterCustomer = _.filter(customerList, (item) => {
          return item.customerId === customer;
        });
        if (_.size(filterCustomer) > 0) {
          console.log('filterCustomer');
          setSelectCustomer(_.get(filterCustomer, '[0]'));
          setFieldsValue({
            ['customerContact']: undefined,
            ['customerContactPhone']: null,
            ['customerAddress']: undefined,
          });
        } else {
          console.log('errorrrrrrr');
          setSelectCustomer();
          setFieldsValue({
            ['customerContact']: undefined,
            ['customerContactPhone']: null,
            ['customerAddress']: undefined,
          });
        }
      }
    }
  }, [getFieldValue('customer')]);

  useEffect(() => {
    if (getFieldValue('customerContact')) {
      const filterContact = _.filter(contactList, (item) => {
        return item.customerContactId === getFieldValue('customerContact');
      });
      console.log('filterContact', filterContact, contactList, getFieldValue('customerContact'));
      setFieldsValue({
        ['customerContactPhone']: _.get(filterContact, '[0].customerContactPhone'),
      });
    }
  }, [getFieldValue('customerContact')]);

  useEffect(() => {
    if (isOpenQuotation === false && visible === false && _.isFunction(resetFields())) {
      resetFields();
    }
  }, [isOpenQuotation]);

  // useEffect(() => {
  //   if (isOpenQuotation === false && visible === false && _.isFunction(resetFields())) {
  //     resetFields();
  //   }
  // }, [customerTrigger]);

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('handleSave', contactList, addressList, values);

      if (err) {
        return;
      }
      const filterContact = _.filter(contactList, (item) => {
        return item.customerContactId === values.customerContact;
      });
      const filterAddress = _.filter(addressList, (item) => {
        return item.customerAddressBookId === values.customerAddress;
      });
      // setDataForm((prev) => ({ ...prev, ...values, ..._.get(filterContact, '[0]'), ..._.get(filterAddress, '[0]'), ...selectCustomer }));
      let check = false;
      setDataForm((prev) => {
        console.log('checkData', prev, values);
        if (_.get(prev, 'customerId') !== _.get(values, 'customer')) {
          check = true;
          return { ...prev, ...values, ..._.get(filterContact, '[0]'), ..._.get(filterAddress, '[0]'), ...selectCustomer };
        }
        return prev;
      });

      if (check) {
        setSelectItem([]);
      }

      // return
      setLoading(false);
      onOk();
    });
  };

  const handleCancel = () => {
    setIsOpen(false);
    setFieldsValue({
      ['customer']: _.get(dataForm, 'customerId') || undefined,
      ['customerContact']: _.get(dataForm, 'customerContact') || undefined,
      ['customerContactPhone']: _.get(dataForm, 'customerContactPhone') || undefined,
      ['customerAddress']: _.get(dataForm, 'customerAddressBookId') || undefined,
    });
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' })}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      width={600}
      zIndex={1000}
      bodyStyle={{ padding: '12px 24px 24px 24px' }}
      centered={true}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Spin spinning={loading}>
        <Form name="customer" colon={false}>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={true} />
                }
              >
                {getFieldDecorator('customer', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnCustomer', defaultMessage: 'please select customer' }),
                    },
                  ],
                })(
                  <AutoComplete
                    placeholder={intl.formatMessage({ id: 'quotationHintCustomer', defaultMessage: 'Select Customer' })}
                    onSearch={handleSearchCustomer}
                    onSelect={_.get(customerList, '[0].addnew') ? () => {setvisibleCustomer(true)} : null}
                    dataSource={customerList}
                  >
                    {_.map(customerList, (item) => (
                      <Option value={item.customerId}>{item.customerName}</Option>
                    ))}
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelContact', defaultMessage: 'Contact' })} req={false} />
                }
              >
                {getFieldDecorator('customerContact', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnContact', defaultMessage: 'please select contact' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'quotationHintContact', defaultMessage: 'Select Contact' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contactList &&
                      contactList.map((contact) => (
                        <Option key={`${contact.customerContactId}`} value={`${contact.customerContactId}`}>
                          {contact.customerContactName}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'quotationLabelPhoneNumber', defaultMessage: 'Phone Number' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('customerContactPhone', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnPhoneNumber', defaultMessage: 'please select Phone Number' }),
                    },
                  ],
                })(
                  <Input
                    readOnly
                    placeholder={intl.formatMessage({ id: 'quotationHintPhoneNumber', defaultMessage: 'Select Phone Number' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'quotationLabelModalCustomerAddress', defaultMessage: 'Address' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('customerAddress', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnModalCustomerAddress', defaultMessage: 'Please select address' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'quotationHintModalCustomerAddress', defaultMessage: 'Select Address' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {addressList &&
                      addressList.map((address) => (
                        <Option
                          key={`${address.customerAddressBookId}`}
                          value={`${address.customerAddressBookId}`}
                          label={`${address.addressName} · ${address.fullAddress}`}
                        >
                          {address.addressName}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Addcustomer visible={visibleCustomer} setVisible={setvisibleCustomer} setTrigger={setCustomerTrigger} cusname={searchCustomer} />
    </Modal>
  );
};

SelectCustomerModal.defaultProps = {
  // title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalSelectCustomerForm = Form.create({ name: 'Customer_Form' })(SelectCustomerModal);

export default ModalSelectCustomerForm;
