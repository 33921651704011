import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, DatePicker, InputNumber } from 'antd'
import './css/index.css'
import { useIntl, FormattedMessage } from 'react-intl'
import { getInitial, calAsset, getSuitable } from '../../../controllers/asset-resource/asset-controller'
import _, { debounce } from 'lodash'

const { TextArea } = Input
const { Option } = Select

export default (props) => {
  const intl = useIntl()
  const { form } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;

  //---------------------Source ANd Suitable ---------------------------//
  const [suitableData, setSuitableData] = useState([]);
  const [sourceData, setSourceData] = useState([]);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    const getApi = async () => {
      const body = {

      }
      const response = await getInitial(body)
      const response2 = await getSuitable()
      Promise.all([response, response2]).then((values) => {
        setSourceData(_.get(values, '[0]dataList'))
        setSuitableData(_.get(values, '[1]'))
      })
    }
    getApi();
  }, []);


  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);
    const splitValue = _.split(e.target.value, '.');
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 1) e.preventDefault();
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => { return o.charCodeAt(0) === 46 ? 1 : 0 });
        if (sumDot >= 1) e.preventDefault();
      }
    }
    else if (keyCode === 45) {
      e.preventDefault();
    }
    else {
      e.preventDefault();
    }
    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) e.preventDefault();
    }
  }

  useEffect(() => {
    const calcApi = debounce(async () => {
      const price = getFieldValue("price")
      const salvagePrice = getFieldValue("salvagePrice")
      if (price !== undefined && salvagePrice !== undefined) {
        const body = {
          price: parseFloat(getFieldValue("price")),
          salvagePrice: parseFloat(getFieldValue("salvagePrice")),
          assetClassId: getFieldValue("assetClass")
        }
        const response = await calAsset(body)
        form.setFieldsValue({
          ['assetBookValue']: _.get(response, 'assetBookValue')
        });
      }

    }, 200)
    calcApi();
  }, [getFieldValue("assetClass"), getFieldValue("price"), getFieldValue("salvagePrice")]);

  return (
    <div>
      <Form>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetPurchaseDate', defaultMessage: 'Purchase Date' })} req={false} />}>
              {getFieldDecorator('purchaseDate', {
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'modalAssetPurchaseDatePlaceholder', defaultMessage: 'Select Date' })}
                ></DatePicker>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetPriceBaht', defaultMessage: 'Price (Baht)' })} req={false} />}>
              {getFieldDecorator('price', {
              })(
                <InputNumber
                  placeholder={intl.formatMessage({ id: 'modalAssetPriceBahtPlaceholder', defaultMessage: 'Enter Price' })}
                  className='asset-item-inputNumber'
                  min={0}
                  step={0.01}
                  max={9999999999}
                  onKeyDown={handleKeyDownNumberDecimal} style={{ width: '100%' }}
                  formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetValueDate', defaultMessage: 'Asset Value Date' })} req={false} />}>
              {getFieldDecorator('assetValueDate', {
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'modalAssetAssetValueDatePlaceholder', defaultMessage: 'Select Date' })}
                ></DatePicker>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSalvagePrice', defaultMessage: 'Salvage Price (Baht)' })} req={false} />}>
              {getFieldDecorator('salvagePrice', {
              })(
                <InputNumber
                  placeholder={intl.formatMessage({ id: 'modalAssetSalvagePricePlaceholder', defaultMessage: 'Enter Price' })}
                  className='asset-item-inputNumber'
                  min={0}
                  step={0.01}
                  max={9999999999}
                  onKeyDown={handleKeyDownNumberDecimal} style={{ width: '100%' }}
                  formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetBookValue', defaultMessage: 'Asset Book Value (Baht)' })} req={false} />}>
              {getFieldDecorator('assetBookValue', {
              })(
                <InputNumber
                  placeholder={intl.formatMessage({ id: 'modalAssetAssetBookValuePlaceholder', defaultMessage: 'Enter Asset Book Value' })}
                  className='asset-item-inputNumber'
                  min={0}
                  step={0.01}
                  readOnly
                  max={9999999999}
                  onKeyDown={handleKeyDownNumberDecimal} style={{ width: '100%' }}
                  formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSource', defaultMessage: 'Source' })} req={false} />}>
              {getFieldDecorator('source', {
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalAssetSourcePlaceholder', defaultMessage: 'Select Source' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    sourceData && sourceData.map(item =>
                      <Option key={item.transactionTypeId}>
                        {item.transactionTypeName}
                      </Option>)
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSuitable', defaultMessage: 'Suitable' })} req={false} />}>
              {getFieldDecorator('suitable', {
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalAssetSuitablePlaceholder', defaultMessage: 'Select Suitable' })}
                  mode='multiple'
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className='asset-suitable-multi'
                >
                  {
                    suitableData && suitableData.map(item =>
                      <Option key={item.suitableId}>
                        {item.suitableName}
                      </Option>)
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}


