import React, { useState, useContext, useEffect } from 'react';
import { Col, Divider, Form, Icon, Input, Modal, Row, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
import UploadImageComponent from '../../../../../components/upload-image-custom';
import Button_01 from '../../../../../components/v2/button_01';
import Button_02 from '../../../../../components/v2/button_02';
import { DeliveryContext } from '../../..';
import ModalMapLocationNew from '../../../../../components/modal-address-noAPI/index';
import _ from 'lodash';
import './modal.css';
import { createBranch, updateBranch, uploadImageBranch } from '../../../../../controllers/delivery-order/branch';
import { errorNotification, successNotification } from '../../../../../components/v2/notification';
import { handleKeyDownPhone } from '../../../../../component-function/fnc-number';
const { TextArea } = Input;
const { Option } = Select;

const BranchModal = ({ form, record, setRecord, visible, setVisible }) => {
  const intl = useIntl();
  const { setBranchDataFormResponse, handleSelectBranch } = useContext(DeliveryContext);
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const [isOpenModalAddress, setIsOpenModalAddress] = useState(false);
  const [autoComplteLocation, setAutoComplteLocation] = useState();
  const [autoComplteValue, setAutoComplteValue] = useState();
  const [defaultAddress, setDefaultAddress] = useState();
  const [imageFile, setImageFile] = useState();

  useEffect(() => {
    if (record && visible === true) {
      setDefaultAddress({
        locationName: _.get(record, 'branchAddressName'),
        address: _.get(record, 'branchAddressDescription'),
        mapLocation: _.get(record, 'branchAddressDescription'),
        lat: parseFloat(_.get(record, 'branchAddressLat')),
        lng: parseFloat(_.get(record, 'branchAddressLng')),
      });
    }
  }, [record, visible]);

  useEffect(() => {
    if (defaultAddress && visible === true) {
      setFieldsValue({ ['address']: _.get(defaultAddress, 'address') });
    }
  }, [defaultAddress, visible]);

  const onOk = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const payload = {
        branchCode: values.code,
        branchName: values.name,
        branchPhone: values.phone,
        branchAddressName: _.get(defaultAddress, 'locationName'),
        branchAddressDescription: _.get(defaultAddress, 'address'),
        branchAddressLat: _.get(defaultAddress, 'lat'),
        branchAddressLng: _.get(defaultAddress, 'lng'),
        branchRemark: values.remark,
        branchStatus: true,
      };

      let dataLeftPage;
      if (record) {
        /////update
        try {
          const response = await updateBranch(_.get(record, 'id'), payload);
          if (_.get(response, 'data.status.code') === 200) {
            dataLeftPage = _.get(response, 'data.data');
            if (_.get(response, 'data.data.id') && _.get(imageFile, 'file')) {
              let whId = _.get(response, 'data.data.id');
              let formData = new FormData();
              formData.append('file', imageFile.file);
              const res = await uploadImageBranch(whId, formData);
              if (_.get(res.data.data, 'branchPhotoUrl')) {
                let url = _.get(res.data.data, 'branchPhotoUrl');
                dataLeftPage.branchPhotoUrl = url;
              }
            }
            successNotification(_.get(response.data.status, 'message'));
            setBranchDataFormResponse(dataLeftPage);
            handleSelectBranch(_.get(response, 'data.data.id'), dataLeftPage)
            setDefaultAddress();
            setImageFile();
            setVisible(false);
            setRecord();
            resetFields();
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) { }
      } else {
        /////create
        try {
          const response = await createBranch(payload);
          if (_.get(response, 'data.status.code') === 200) {
            dataLeftPage = _.get(response, 'data.data');
            if (_.get(response, 'data.data.id') && _.get(imageFile, 'file')) {
              let whId = _.get(response, 'data.data.id');
              let formData = new FormData();
              formData.append('file', imageFile.file);
              const res = await uploadImageBranch(whId, formData);
              if (_.get(res.data.data, 'branchPhotoUrl')) {
                let url = _.get(res.data.data, 'branchPhotoUrl');
                dataLeftPage.branchPhotoUrl = url;
              }
            }
            successNotification(_.get(response.data.status, 'message'));
            setBranchDataFormResponse(dataLeftPage);
            handleSelectBranch(_.get(response, 'data.data.id'), dataLeftPage)
            setDefaultAddress();
            setImageFile();
            setVisible(false);
            setRecord();
            resetFields();
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) { }
      }
    });
  };

  const onCancel = () => {
    setDefaultAddress();
    setRecord();
    setImageFile();
    setVisible(false);
    resetFields();
  };

  const openMapLocation = () => {
    setIsOpenModalAddress(true);
  };

  return (
    <Modal
      title={
        record
          ? intl.formatMessage({ id: `branchModalTilteEdit`, defaultMessage: 'Edit Branch' })
          : intl.formatMessage({ id: `branchModalTilteCreate`, defaultMessage: 'Create Branch' })
      }
      visible={visible}
      centered
      width={700}
      // bodyStyle={{ height: 555 }}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => onOk()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form colon={false}>
        <Row gutter={[24, 0]}>
          <Col span={7} style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
            <UploadImageComponent record={record} imageFile={imageFile} setImageFile={setImageFile} />
          </Col>
          <Col span={17}>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'branchModalCode', defaultMessage: 'Code' })} req={true} />}
                >
                  {getFieldDecorator('code', {
                    initialValue: _.get(record, 'branchCode') ? _.get(record, 'branchCode') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'branchModalReqCode', defaultMessage: 'Please enter code' }),
                      },
                    ],
                  })(<Input placeholder={intl.formatMessage({ id: 'branchPlaceholderCode', defaultMessage: 'Enter Code' })} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'branchModalName', defaultMessage: 'Name' })} req={true} />}
                >
                  {getFieldDecorator('name', {
                    initialValue: _.get(record, 'branchName') ? _.get(record, 'branchName') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'branchModalReqName', defaultMessage: 'Please enter name' }),
                      },
                    ],
                  })(<Input placeholder={intl.formatMessage({ id: 'branchPlaceholderName', defaultMessage: 'Enter  Name' })} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'branchModalPhone', defaultMessage: 'Phone' })} req={true} />}
                >
                  {getFieldDecorator('phone', {
                    initialValue: _.get(record, 'branchPhone') ? _.get(record, 'branchPhone') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'branchModalReqPhone', defaultMessage: 'Please enter phone' }),
                      },
                    ],
                  })(
                    <Input
                      maxLength={10}
                      onKeyDown={handleKeyDownPhone}
                      placeholder={intl.formatMessage({ id: 'branchPlaceholderPhone', defaultMessage: 'Enter phone' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'branchModalAddress', defaultMessage: 'Address' })} req={true} />
                  }
                >
                  {getFieldDecorator('address', {
                    initialValue: _.get(record, 'branchAddressDescription') ? _.get(record, 'branchAddressDescription') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'branchModalReqAddress',
                          defaultMessage: 'Please enter Address',
                        }),
                      },
                    ],
                  })(
                    <Row>
                      <Col span={1}>
                        <Icon type="environment" style={{ fontSize: '12px', color: '#0c4da2', marginRight: '5px' }} />
                      </Col>
                      <Col span={23}>
                        {getFieldValue('address') ? (
                          <span onClick={() => openMapLocation()} className="place-custom-address">
                            {getFieldValue('address')}
                          </span>
                        ) : (
                          <span onClick={() => openMapLocation()} className="place-custom">
                            <FormattedMessage id="branchModalPlaceAddress" defaultMessage="Select Address" />
                          </span>
                        )}
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Divider type="horizontal" style={{ margin: '16px 0px' }} />
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'branchModalRemark', defaultMessage: 'Remark' })} req={false} />
                  }
                >
                  {getFieldDecorator('remark', {
                    initialValue: _.get(record, 'branchRemark') ? _.get(record, 'branchRemark') : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'branchModalRemark',
                          defaultMessage: 'Please enter Remark',
                        }),
                      },
                    ],
                  })(<TextArea autoComplete="off" autoSize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalMapLocationNew
          textId="warehouseModalPlaceAddress"
          textDefault="Select Address Warehouse"
          visible={isOpenModalAddress}
          setVisible={setIsOpenModalAddress}
          defaultAddress={defaultAddress}
          setDefaultAddress={setDefaultAddress}
          autoComplteLocation={autoComplteLocation}
          setAutoComplteLocation={setAutoComplteLocation}
          autoComplteValue={autoComplteValue}
          setAutoComplteValue={setAutoComplteValue}
        />
      </Form>
    </Modal>
  );
};

const BranchModalForm = Form.create({
  name: 'warehouse-form',
})(BranchModal);

export default BranchModalForm;
