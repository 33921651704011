import httpClient from '../../components/axiosClient';

const updatePositionMaster = async (props) => {

  const comCode = localStorage.getItem('comCode');

  // console.log('updatePositionMaster props::: ', props);

  if (props.positionName) {

    const body = {
      positionName: props.positionName
    }

    try {
      const response = await httpClient.put(`/v4/resource/manager/company/${comCode}/position-master/${props.positionId}`, body);
      if (response.status === 200) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error.response;
    }
  }
}

export default updatePositionMaster;