import React from 'react';
import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import ProjectDetail from './detail';
import ProjectStakeholder from './stakeholder';
import ModalEditProject from '../../../components/project/modal-edit/index';
import ModalStakeHolder from '../../../components/project/modal-stakeholder/index';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import './css/index.css';
import {
  getAvatarList,
  getProjectById,
  deleteStakeholder,
  deleteProject,
  getSearchStakeHolder,
} from '../../../controllers/project/project-api';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';

const DetailPage = () => {
  const intl = useIntl();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleModalStakeHolder, setVisibleModalStakeHolder] = useState(false);
  const { project_id } = useParams();
  const history = useHistory();

  const [avatarList, setAvatarList] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [stakedata, setStakeData] = useState();
  const [totalstake, setTotalStake] = useState();
  const [triggerStake, setTriggerStake] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  useEffect(() => {
    const getAvatarApi = async () => {
      const response = await getAvatarList();
      setAvatarList(response || []);
    };

    getAvatarApi();
  }, []);

  useEffect(() => {
    const getById = async () => {
      const response = await getProjectById(project_id);
      const item = _.get(response, 'data');
      setProjectData({
        id: item.projectId,
        index: item.index,
        projectNo: item.projectNo,
        projectName: item.projectName,
        projectType: item.projectTypeName,
        priority: item.projectPriorityName,
        customer: item.customerName,
        projectManager: item.projectMangerName,
        planDate: item.planDate,
        reference: item.referenceNo,
        remark: item.remark,
        projectImgProfile: item.projectImgProfile,
        projectTypeId: item.projectTypeId,
        projectTypeName: item.projectTypeName,
        projectPriorityId: item.projectPriorityId,
        planStart: moment(item.planStart, 'YYYY-MM-DD'),
        planFinish: moment(item.planFinish, 'YYYY-MM-DD'),
        customerId: item.customerId,
        projectMangerId: parseInt(item.projectMangerId),
        projectPriorityIcon: item.projectPriorityIcon,
        fileHash: item.projectImgProfileFileHash,
      });
    };
    getById();
  }, [project_id, trigger]);

  useEffect(() => {
    const getSkate = async () => {
      const body = {
        search: '',
        searchBy: 'all',
        orderType: 'desc',
        orderBy: '',
        paging: paginationPage,
        rowsPerPages: paginationSize,
      };
      const skate = await getSearchStakeHolder(body, project_id);
      setStakeData(skate.data.data);
      setTotalStake(skate.data.total);
    };
    getSkate();
  }, [project_id, triggerStake, paginationPage, paginationSize]);

  const handleEditProject = () => {
    setVisibleEdit(true);
  };

  const handleDeleteProject = (data) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmDeleteProject`,
        defaultMessage: 'Are you sure to delete project',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteProject(data.id);
        if (response.status === 200) {
          setTrigger((current) => !current);
          successNotification(response.data.massage);
          historyHome();
        } else {
          errorNotification(response.data.massage);
        }
      },
      onCancel() {},
    });
  };

  const handleDeleteSkateholder = (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmDeleteSkate`,
        defaultMessage: 'Are you sure to delete stakeholder',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteStakeholder(id);
        if (response.status === 200) {
          setTriggerStake((current) => !current);
          successNotification(response.data.massage);
        } else {
          errorNotification(response.data.massage);
        }
      },
      onCancel() {},
    });
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };
  const historyHome = () => {
    history.push('/main/project/list');
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <div className="padding-between-card">
            {checkPermissionAction('P39PG2C1', 'P39PG2C1A1') ? (
              <ProjectDetail handleEditProject={handleEditProject} handleDeleteProject={handleDeleteProject} record={projectData} />
            ) : null}
          </div>
          <div>
            {checkPermissionAction('P39PG2C2', 'P39PG2C2A1') ? (
              <ProjectStakeholder
                setVisible={setVisibleModalStakeHolder}
                stakedata={stakedata}
                totalstake={totalstake}
                paginationPage={paginationPage}
                handleDeleteSkateholder={handleDeleteSkateholder}
                handlePagination={handlePagination}
                handleSizeChange={handleSizeChange}
              />
            ) : null}
          </div>
          <ModalEditProject
            visible={visibleEdit}
            setVisible={setVisibleEdit}
            avatarList={avatarList}
            projectData={projectData}
            setTrigger={setTrigger}
          />

          <ModalStakeHolder
            visible={visibleModalStakeHolder}
            setVisible={setVisibleModalStakeHolder}
            id={project_id}
            setTriggerStake={setTriggerStake}
            stakedata={stakedata}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default DetailPage;
