import React from 'react';
import '../index.css';
import { Card, Select, Divider, Checkbox, Icon, Row, Col, AutoComplete, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

const { Option } = Select;

const PointFilter = (props) => {
  const intl = useIntl();
  const {
    pointTypeSelect,
    setPointTypeSelect,
    pointTypeData,
    pointTypeInterminate,
    setPointTypeInterminate,
    setPointTypeCheckAll,
    pointTypeCheckAll,
    setPanelPointType,
    panelPointType,
    roleData,
    handleSelectRole,
    roleSelect,
    memberData,
    handleMemberPopupScroll,
    handleSearch,
    memberSelect,
    handleSelecMember,
    handleSearchPhone,
    phoneSearch,
  } = props;

  const handleCheckboxPointTypeChange = (checkedList) => {
    setPointTypeSelect(checkedList);
    setPointTypeInterminate(!!checkedList.length && checkedList.length < pointTypeData.length);
    setPointTypeCheckAll(checkedList.length === pointTypeData.length);
  };

  const handleCheckPointTypeAllChange = (e) => {
    setPointTypeSelect(
      e.target.checked
        ? _.map(pointTypeData, (item) => {
            return item.pointTypeCode;
          })
        : []
    );
    setPointTypeInterminate(false);
    setPointTypeCheckAll(e.target.checked);
  };

  return (
    <Card
      className="campaignPointCard"
      title={intl.formatMessage({ id: `menuCampaignPointFilter`, defaultMessage: 'Filter' })}
      bodyStyle={{ padding: 'unset', height: 'calc(100vh - 160px)' }}
    >
      <div style={{ padding: '16px 16px 24px 16px' }}>
        <div>
          <div className="campaignPointCardTitleFilter">
            <FormattedMessage id="menuCampaignPointRoleText" defaultMessage="Role" />
          </div>
          <div style={{ marginTop: '8px' }}>
            <Select
              placeholder={intl.formatMessage({ id: `menuCampaignPointRolePlaceholder`, defaultMessage: 'Select Role' })}
              style={{ width: '100%' }}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              mode="multiple"
              value={roleSelect}
              maxTagCount={1}
              onChange={handleSelectRole}
            >
              {_.map(roleData, (item) => (
                <Option value={item.roleId} key={item.roleId}>
                  {item.roleName}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div style={{ marginTop: '16px' }}>
          <div className="campaignPointCardTitleFilter">
            <FormattedMessage id="menuCampaignPointNameText" defaultMessage="Name" />
          </div>
          <div style={{ marginTop: '8px' }}>
            <Select
              placeholder={intl.formatMessage({ id: `menuCampaignPointNamePlaceholder`, defaultMessage: 'Select Name' })}
              style={{ width: '100%' }}
              onPopupScroll={handleMemberPopupScroll}
              showSearch
              onSearch={handleSearch}
              showArrow
              mode="multiple"
              maxTagCount={1}
              filterOption={false}
              value={memberSelect}
              onChange={handleSelecMember}
            >
              {_.map(memberData, (item) => (
                <Option value={item.memberId} key={item.memberId}>
                  {item.memberName}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div style={{ marginTop: '16px' }}>
          <div className="campaignPointCardTitleFilter">
            <FormattedMessage id="menuCampaignPointNamePhone" defaultMessage="Phone" />
          </div>
          <div style={{ marginTop: '8px' }}>
            <Input
              placeholder={intl.formatMessage({ id: `menuCampaignPointPhonePlaceholder`, defaultMessage: 'Enter Phone' })}
              style={{ width: '100%' }}
              value={phoneSearch}
              onChange={(e) => handleSearchPhone(e.target.value)}
            />
          </div>
        </div>
      </div>

      <Divider type="horizontal" style={{ margin: 'unset' }} />
      <div className="campaignPointCardFilterContent">
        <div>
          <Checkbox indeterminate={pointTypeInterminate} onChange={handleCheckPointTypeAllChange} checked={pointTypeCheckAll}>
            <span className="campaignPointCardFilterContentText">
              <FormattedMessage id="menuCampaignPointFilterPointType" defaultMessage="Point Type" />
            </span>
          </Checkbox>
        </div>
        <div>
          <span onClick={() => setPanelPointType((current) => !current)} style={{ cursor: 'pointer' }}>
            <FormattedMessage id="campaignPointCardFilterSelected" defaultMessage="Selected" /> {_.size(pointTypeSelect)}{' '}
            {panelPointType ? <Icon type="up" /> : <Icon type="down" />}
          </span>
        </div>
      </div>
      {panelPointType ? (
        <Checkbox.Group style={{ width: '100%', marginLeft: '32px' }} value={pointTypeSelect} onChange={handleCheckboxPointTypeChange}>
          <Row gutter={[16, 16]}>
            {_.map(pointTypeData, (item) => (
              <Col span={24}>
                <Checkbox value={item.pointTypeCode}>{item.pointName}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      ) : null}
      <Divider type="horizontal" style={{ margin: 'unset' }} />
    </Card>
  );
};

export default PointFilter;
