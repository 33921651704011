import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Modal,
  Transfer,
  Icon,
  Popover,
  Divider,
  Row,
  Col,
  Avatar,
  Select,
  Button,
  Tag
} from 'antd';
import Input from '../../../components/v2/input';
import Table from '../../../components/v2/table';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import httpClient from '../../../components/axiosClient';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import Provider from '../provider';
import difference from 'lodash/difference';
import styled from 'styled-components';
import './index.css';
import { PageSettings } from '../../../config/page-settings';
import _ from 'lodash';
import ModalApprover from '../../../components/modal-resource-approver/index'
import { SaveApprover } from '../../../controllers/organization/approver';

const DivDetail = styled.div`
  margin-top: 36px;
  margin-bottom: 24px;
`;

const ApproverAvatar = styled(Avatar)`
  border: 0.5px solid #e8e8e8;
`;

const filterOption = (inputValue, items) => {
  if (!inputValue) {
    return items;
  }
  const lowercasedFilter = inputValue.toLowerCase();
  const filteredData = items.filter(item => {
    return Object.keys(item).some(key =>
      typeof item[key] === "string" && item[key].toLowerCase().includes(lowercasedFilter)
    );
  })
  return filteredData;
};

const TableTransfer = ({
  leftColumns,
  rightColumns,
  ...restProps
}) => {
  const intl = useIntl();
  return (
    <Transfer
      className="transfer-approver"
      {...restProps}
      showSelectAll={false}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;
        const rowSelection = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        let isLeft = direction === 'left';
        let defalutData = [];
        defalutData = filterOption(
          restProps.search[isLeft ? 0 : 1],
          filteredItems,
        );

        return (
          <div>
            <Input
              className="input-search-approver"
              value={restProps.search[isLeft ? 0 : 1]}
              onChange={(e) => {
                restProps.handleSearch(direction, e.target.value);
              }}
              placeholder={intl.formatMessage({
                id: 'orgTabDetailApproverModalHintSearch',
                defaultMessage: 'Search',
              })}
              prefix={<Icon type="search" className="input-search-approver-prefix" style={{ color: "rgba(0,0,0,.25)" }}
                autoComplete="off"
              />}
            />
            <Table
              className="transfer-table-approver"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={defalutData}
              pagination={false}
              size="small"
              style={{ pointerEvents: listDisabled ? 'none' : null }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
              scroll={{ y: 300 }}
            />
          </div>
        );
      }}
    </Transfer>
  );
};

export default React.memo((props) => {
  const intl = useIntl();
  const mApp = useContext(PageSettings);
  const app = Provider.useAppContext();
  const memComId = localStorage.getItem('memComId');
  const { dataSource, dataUsers, orgId, setTriggerApprover, setCheckApprover, setTrigger } = props;

  const [visiblePopover, setVisiblePopover] = useState(false);

  const optionsRequestType = [...props.requestType];
  const updateBy = 1;

  const leftTableColumns = [
    {
      dataIndex: 'title',
      title: intl.formatMessage({
        id: 'orgTabDetailApproverColumnName',
        defaultMessage: 'Approver Name',
      }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({
        id: 'orgTabDetailApproverColumnEmail',
        defaultMessage: 'Email',
      }),
    },
  ];

  const rightTableColumns = [
    {
      dataIndex: 'title',
      title: intl.formatMessage({
        id: 'orgTabDetailApproverColumnName',
        defaultMessage: 'Approver Name',
      }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({
        id: 'orgTabDetailApproverColumnEmail',
        defaultMessage: 'Email',
      }),
    },
  ];

  const columns = [
    {
      title: '#',
      select: 'Index',
      align: 'center',
      width: 70,
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    // {
    //   dataIndex: 'profile',
    //   key: 'profile',
    //   width: 70,
    //   render: (text, record, index) => <Avatar src={record.profile_img} />,
    // },
    {
      title: intl.formatMessage({
        id: 'orgTabDetailApproverColumnUserRole',
        defaultMessage: 'User/Role',
      }),
      dataIndex: 'name',
      key: 'name',
      width: 375,
      render: (text, record, index) => {
        if (record.version === '1') {
          return (
            <PageSettings.Consumer>
              {({ checkPermissionAction }) =>
                <Tag key={record.memComId} className='tax-role-name-style' closable={checkPermissionAction('P3PG1C4', 'P3PG1C4A4') ? true : false} onClose={() => handleDeleteTag(record, record.memComId, 'MemberRow')}>{record.name}</Tag>
              }
            </PageSettings.Consumer>
          )
        } else if (record.version === '2') {
          return (
            <PageSettings.Consumer>
              {({ checkPermissionAction }) =>
                <div>
                  {
                    record.roles && record.roles.map(item => (
                      <Tag key={item.roleId} className='tax-role-name-style' closable={checkPermissionAction('P3PG1C4', 'P3PG1C4A4') ? true : false} onClose={() => handleDeleteTag(record, item.roleId, 'Role')}>{item.roleName}</Tag>
                    ))
                  }
                  {
                    record.users && record.users.map(item => (
                      <Tag key={item.memComId} className='tax-role-name-style' closable={checkPermissionAction('P3PG1C4', 'P3PG1C4A4') ? true : false} onClose={() => handleDeleteTag(record, item.memComId, 'memCom')}>{item.name}</Tag>
                    ))
                  }
                </div>}
            </PageSettings.Consumer>
          )
        }
      }

    },
    // {
    //   title: intl.formatMessage({
    //     id: 'orgTabDetailApproverColumnPhone',
    //     defaultMessage: 'Phone Number',
    //   }),
    //   dataIndex: 'phone',
    //   key: 'phone',
    // },
    // {
    //   title: intl.formatMessage({
    //     id: 'orgTabDetailApproverColumnEmail',
    //     defaultMessage: 'Email',
    //   }),
    //   dataIndex: 'email',
    //   key: 'email',
    // },
    {
      title: intl.formatMessage({
        id: 'orgTabDetailApproverColumnManageOrApprove',
        defaultMessage: 'Manage or Approve',
      }),
      dataIndex: 'description',
      key: 'description',
      width: 375,
      render: (text, record, index) => {
        if (record.version === '1') {
          var requestTypeText = '';
          if (record.request_type.length > 0) {
            record.request_type.forEach((element) => {
              if (requestTypeText === '') {
                requestTypeText += `${element.req_type_name}`;
              } else {
                requestTypeText += `, ${element.req_type_name}`;
              }
            });
          } else {
            requestTypeText = '-';
          }
          return requestTypeText;
        } else if (record.version === '2') {
          var requestTypeText = '';
          if (record.request_type.length > 0) {
            record.request_type.forEach((element) => {
              if (requestTypeText === '') {
                requestTypeText += `${element.name}`;
              } else {
                requestTypeText += `, ${element.name}`;
              }
            });
          } else {
            requestTypeText = '-';
          }
          return requestTypeText;
        }

      },
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => fncActionTable(record, index)
    },
  ];

  const [visible, setVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [approverKeys, setApproverKeys] = useState([]);

  const dataKeys = approverKeys.filter((x) => x !== undefined);
  const dataKeysInt = dataKeys.map((num) => {
    return parseInt(num);
  });

  const [requestTypeVisible, setRequestTypeVisible] = useState(false);
  const [approverPathImage, setApproverPathImage] = useState('');
  const [approverName, setApproverName] = useState('');
  const [approverEmail, setApproverEmail] = useState('');
  const [approverMobile, setApproverMobile] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsID, setSelectedItemID] = useState([]);
  const [approverID, setApproverID] = useState('');

  const [selected, setSelected] = useState([]);
  const [inputSearch, setInputSearch] = useState(['', '']);

  const [dataSourceBefore, setDataSourceBefore] = useState();
  const [memCom, setMemCom] = useState();
  const [disButtonApprover, setDisButtonApprover] = useState(false);
  const [disButtonReq, setDisButtonReq] = useState(false);
  const [dataDefault, setDataDefault] = useState()

  // console.log('Approver PathImage::: ', approverPathImage);
  // console.log('Approver memCom::: ', memCom);
  // console.log('Approver dataSource::: ', dataSource);

  useEffect(() => {
    getDataUsers();
    getDataApprover(dataSource);
    getRefreshKey();
    filterDataSourceBefore();
  }, [dataUsers, dataSource]);

  const valueFilterInModal = useMemo(() =>
    dataSource.filter((item) => {
      return parseInt(item.memComId) === parseInt(memCom);
    }
    ), [memCom]);

  useEffect(() => {
    setValueInModal();
  }, [memCom]);

  const setValueInModal = () => {
    if (valueFilterInModal.length !== 0) {

      const filterOrgAppId = valueFilterInModal.map((item) => {
        return item._id
      })

      const filterPhone = valueFilterInModal.map((item) => {
        return item.phone
      })

      const filterEmail = valueFilterInModal.map((item) => {
        return item.email
      })

      const filterFullName = valueFilterInModal.map((item) => {
        return item.name
      })

      const filterProfileImage = valueFilterInModal.map((item) => {
        return item.profile_img
      })

      // console.log('Approver valueFilterInModal::: ', valueFilterInModal);
      // console.log('Approver filterProfileImage::: ', filterProfileImage);

      setApproverID(filterOrgAppId ? filterOrgAppId : '');
      setApproverPathImage(filterProfileImage ? `${filterProfileImage}` : '');
      setApproverName(filterFullName !== null ? filterFullName : ' - ');
      setApproverEmail(filterEmail !== null ? filterEmail : ' - ');
      setApproverMobile(filterPhone !== null ? filterPhone : ' - ');
      setRequestTypeVisible(true);
    }
    else {
      setRequestTypeVisible(false);
    }
  }

  const filterDataSourceBefore = () => {
    const mapDataSource = dataSource.map((dataSource) => {
      return dataSource.memComId
    })
    setDataSourceBefore(mapDataSource);
  }

  const getDataUsers = () => {
    if (dataUsers) {
      const newUsers = [];
      dataUsers.forEach((element) => {
        let setData = {
          key: element.mem_com_id,
          title: element.fullname,
          email: element.email,
        };
        newUsers.push(setData);
      });
      setUsers(newUsers);
    }
  }

  const getDataApprover = (dataApprover) => {
    const approverTargetKeys = [];
    if (dataApprover) {
      for (let i = 0; i < dataApprover.length; i++) {
        approverTargetKeys.push(parseInt(dataApprover[i].memComId));
      }
    }
    setApproverKeys(approverTargetKeys);
  }

  const getRefreshKey = () => {
    if (dataSource) {
      const newApprover = [];
      dataSource.forEach((element) => {
        newApprover.push(parseInt(element.memComId));
      });
      setApproverKeys(newApprover);
    }
  };

  const handlerClickModal = () => {
    setVisible(true);
    getRefreshKey();
  };

  const handleOk = async () => {
    setDisButtonApprover(true);
    const approversCreate = [];
    approverKeys.forEach((element) => {
      approversCreate.push({ mem_com_id: `${element}` });
    });

    const body = {
      org_id: `${orgId}`,
      created_by: `${memComId}`,
      mem_com_id: dataKeysInt,
    };

    try {
      const response = await httpClient.post(
        `/v2/organization/approvers/create?comId=${mApp.comId}`,
        body,
      );

      if (response.status === 200) {
        setTimeout(() => {
          props.setRefreshSaveFrom(curren => !curren);
        });
        setTimeout(() => {
          checkModalID(body);
        }, 2500);
        successNotification(response.data.message);
        setTriggerApprover(current => !current)
      } else {
        errorNotification(response.data.message);
      }
    } catch (error) {
      if (error) {
        errorNotification(error.response.data.message);
      }
    }
    setInputSearch(['', '']);
    setVisible(false);
    setSelected([]);
    getRefreshKey();
    setDisButtonApprover(false);
  };

  const checkModalID = (body) => {

    const mapApproverKey = approverKeys.map((item) => {
      return item.toString()
    })

    // console.log('Approver mapApproverKey::: ', mapApproverKey);

    const filterMemcom = mapApproverKey.filter((item) => !dataSourceBefore.includes(item));

    // console.log('Approver filterMemcom::: ', filterMemcom);

    if (filterMemcom.length == 1) {
      setMemCom(filterMemcom.toString());
    }
    else if (filterMemcom.length > 1) {
      setRequestTypeVisible(false);
    }
    else {
      setRequestTypeVisible(false)
    }
  }

  const handleCancel = () => {
    setVisible(false);
    setSelected([]);
    setInputSearch(['', '']);
    getRefreshKey();
    setMemCom();
  };

  const handleSearch = (dir, value) => {
    let option = [...inputSearch];
    if (dir === 'left') {
      option[0] = value;
    } else {
      option[1] = value;
    }
    setInputSearch(option);
  };

  const selectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelected([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handlerChangeSelect = (nextTargetKeys, direction, moveKeys) => {
    setApproverKeys(nextTargetKeys);
  };

  const handlerDelete = (approverId) => {
    Modal.confirm({
      className: 'approver-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'orgTabDetailApproverModalDeleteTitle', defaultMessage: 'Do you want to delete these approver ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v2/organization/approvers/${updateBy}/${approverId}`);
          if (response.status === 200) {
            successNotification(response.data.message);
            setTriggerApprover(current => !current)
            setCheckApprover()
            setTimeout(() => {
              props.setRefreshSaveFrom(curren => !curren);
            }, 500);
          }
        } catch (error) {
          if (error.response.status === 500) {
            errorNotification(error.response.data.error);
          }
          return
        }
      },
      onCancel() { },
    });
  };

  const handleAssignRequestType = (props) => {

    // console.log('handleAssignRequestType::: ', props);
    const { memComId, id, pathImage, name, email, phone, types } = props;

    const newRequestTypeID = [];
    if (types) {
      if (types.length > 0) {
        types.forEach((element) => {
          newRequestTypeID.push(element.name);
        });
      }
    }
    // console.log('handleAssignRequestType::: ',newRequestTypeID);


    setApproverID(id ? id : '');
    setApproverPathImage(pathImage ? `${pathImage}` : '');
    setApproverName(name ? name : '');
    setApproverEmail(email ? email : '');
    setApproverMobile(phone ? phone : '');
    setMemCom(memComId);

    setSelectedItems([...newRequestTypeID]);
    setRequestTypeVisible(true);
  };

  const handleRequestTypeOk = async () => {
    setDisButtonReq(true);
    try {
      const body = {
        updateBy: updateBy,
        requestId: selectedItemsID,
      };
      const response = await httpClient.put(
        `/approvers/${approverID}/request-types?comId=${mApp.comId}`,
        body,
      );

      if (response.status === 200) {
        successNotification(response.data.data);
        props.setRefreshSaveFrom(curren => !curren);
        setMemCom();
      } else {
        errorNotification(response.data.data);
      }
      props.setRefreshSaveFrom(curren => !curren);
    } catch (error) {
      setSelectedItems([]);
      setRequestTypeVisible(false);
      setDisButtonReq(false);
      if (error.response.status === 500) {
        errorNotification(error.response.data.error);
      }
      return
    }
    setSelectedItems([]);
    setRequestTypeVisible(false);
    setDisButtonReq(false);
  };

  const handleRequestTypeCancel = () => {
    setRequestTypeVisible(false);
    setMemCom();
    setSelectedItems();
  };

  const handleChangeRequestType = (value, option) => {
    const requestsID = [];
    value.forEach((name) => {
      const found = optionsRequestType.find((element) =>
        element.name === name ? name : false,
      );
      if (found) {
        requestsID.push(found.req_id);
      }
    });
    setSelectedItems([...value]);
    setSelectedItemID([...requestsID]);
  };

  const filteredOptions = optionsRequestType.filter((o) => {
    return o;
  });

  const hidePopover = (e) => {
    e.preventDefault();
    setVisiblePopover(false);
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const handleEdit = (value) => {
    if (_.get(value, 'version') === '1') {
      const mapRequest = _.map(_.get(value, 'request_type'), item => { return item.req_id })
      setDataDefault({
        role: [],
        user: _.get(value, 'memComId') ? [parseInt(_.get(value, 'memComId'))] : undefined,
        requestType: mapRequest,
        id: _.get(value, '_id')
      })
    } else if (_.get(value, 'version') === '2') {
      const mapUser = _.get(value, 'users').map(item => { return parseInt(item.memComId) })
      const mapRole = _.get(value, 'roles').map(item => { return item.roleId })
      const mapRequest = _.get(value, 'request_type').map(item => { return item.id })
      setDataDefault({
        role: mapRole,
        user: mapUser,
        requestType: mapRequest,
        id: _.get(value, '_id')
      })
    }
    setVisible(true)
  }

  const handleDeleteTag = async (record, Id, Type) => {
    // console.log('handleDeleteTag ::', record, Id, Type)
    if (Type === 'MemberRow') {
      handleDeleteNoModal(_.get(record, '_id'))
    } else if (Type === 'Role') {
      if (_.size(record.roles) === 1 && _.size(record.users) === 0) {
        handleDeleteNoModal(_.get(record, '_id'))
      } else {
        const filterRole = record && _.get(record, 'roles').filter(item => { return item.roleId !== Id })
        const mapRole = filterRole && filterRole.map(item => { return item.roleId })
        const mapUser = record && _.get(record, 'users').map(item => { return item.memComId.toString() })
        const mapRequest = record && _.get(record, 'request_type').map(item => { return item.id })
        const response = await SaveApprover(memComId, mapUser, mapRole, orgId, mapRequest, _.get(record, '_id'))
        if (_.size(response) !== 0) {
          if (response.status.code === 200) {
            successNotification(response.status.message)
            setTriggerApprover(current => !current)
          } else {
            errorNotification(response.status.message)
          }
        } else {
          errorNotification('error');
        }
        setTrigger(current => !current)
      }
    } else if (Type === 'memCom') {
      if (_.size(record.users) === 1 && _.size(record.roles) === 0) {
        handleDeleteNoModal(_.get(record, '_id'))
      } else {
        const filterUser = record && _.get(record, 'users').filter(item => { return item.memComId !== Id })
        const mapUser = filterUser && filterUser.map(item => { return item.memComId.toString() })
        const mapRole = record && _.get(record, 'roles').map(item => { return item.roleId })
        const mapRequest = record && _.get(record, 'request_type').map(item => { return item.id })
        const response = await SaveApprover(memComId, mapUser, mapRole, orgId, mapRequest, _.get(record, '_id'))
        if (_.size(response) !== 0) {
          if (response.status.code === 200) {
            successNotification(response.status.message)
            setTriggerApprover(current => !current)
          } else {
            errorNotification(response.status.message)
          }
        } else {
          errorNotification('error');
        }
        setTrigger(current => !current)
      }
    }

  }

  const handleDeleteNoModal = async (id) => {
    try {
      const response = await httpClient.delete(`/v2/organization/approvers/${updateBy}/${id}`);
      if (response.status === 200) {
        successNotification(response.data.message);
        setTriggerApprover(current => !current)
        setCheckApprover()
        props.setRefreshSaveFrom(curren => !curren);
      }
    } catch (error) {
      if (error.response.status === 500) {
        errorNotification(error.response.data.error);
      }
      return
    }
  }

  const fncActionTable = (record, index) => {
    return (
      <div>
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    <div>
                      {checkPermissionAction('P3PG1C4', 'P3PG1C4A3') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() =>
                            // handleAssignRequestType({
                            //   memComId: record.memComId,
                            //   id: record._id,
                            //   pathImage: record.pathImage,
                            //   name: record.name,
                            //   email: record.email,
                            //   phone: record.phone,
                            //   types: record.reqTypeCode,
                            // })
                            handleEdit(record)
                          }
                        >
                          <FormattedMessage id="orgTabDetailApproverBtnAssign" defaultMessage="Assign" />
                        </Button>
                      ) : null}
                    </div>}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    <div>
                      {checkPermissionAction('P3PG1C4', 'P3PG1C4A4') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => handlerDelete(record._id)}
                        >
                          <FormattedMessage id="orgTabDetailApproverBtnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      </div>
    )
  }

  // console.log('Request Type', selectedItems)

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div className="approver-card">
          <DivDetail>
            <Row
              className="approver-row-header"
              onClick={hidePopover}
              gutter={[16, 16]}
              align="middle"
            >
              <Col className="gutter-row" span={20}>
                <p className="approver-p">
                  <FormattedMessage
                    id="orgTabDetailTitleOrganizationManager"
                    defaultMessage="Organization Manager"
                  />
                </p>
              </Col>
              {checkPermissionAction('P3PG1C4', 'P3PG1C4A2') ? (
                <Col
                  className="gutter-row"
                  span={4}
                  style={{ textAlign: 'right' }}
                >
                  <Button01
                    type="primary"
                    fontsize="sm"
                    btnsize="wd_df"
                    onClick={handlerClickModal}
                  >
                    <FormattedMessage
                      id="orgTabDetailApproverBtnAddNew"
                      defaultMessage="Add New"
                    />
                  </Button01>
                </Col>
              ) : null}
            </Row>
          </DivDetail>

          <Divider className="approver-divider" style={{ marginBottom: '20px !important' }} />

          <Table
            rowKey={(record) => record.key}
            size={'middle'}
            columns={columns}
            sizeWidth="lg"
            dataSource={dataSource}
            pagination={false}
          />

          {/* <Modal
            title={
              <FormattedMessage
                id="orgTabDetailTitleApprover"
                defaultMessage="Approver"
              />
            }
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'85%'}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}}
                key="back"
                fontsize="sm"
                btnsize="wd_df"
                onClick={handleCancel}
              >
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01
                key="submit"
                fontsize="sm"
                btnsize="wd_df"
                type="primary"
                onClick={handleOk}
                disabled={disButtonApprover}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <TableTransfer
              titles={[
                intl.formatMessage({
                  id: 'orgTabDetailApproverModalTxtUser',
                  defaultMessage: 'User',
                }),
                intl.formatMessage({
                  id: 'orgTabDetailApproverModalTxtApprover',
                  defaultMessage: 'Approver',
                }),
              ]}

              dataSource={users}
              targetKeys={approverKeys}

              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
              onChange={handlerChangeSelect}
              onSelectChange={selectChange}
              selectedKeys={selected}
              search={inputSearch}
              onSearch={handleSearch}
              handleSearch={handleSearch}
            />
          </Modal> */}

          {/* <Modal
            title={
              <FormattedMessage
                id="orgTabDetailApproverModalTitleRequestType"
                defaultMessage="Request Type"
              />
            }
            centered
            visible={requestTypeVisible}
            onOk={handleRequestTypeOk}
            onCancel={handleRequestTypeCancel}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}}
                key="back"
                fontsize="sm"
                btnsize="wd_df"
                onClick={handleRequestTypeCancel}
              >
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01
                key="submit"
                fontsize="sm"
                type="primary"
                btnsize="wd_df"
                onClick={handleRequestTypeOk}
                disabled={disButtonReq}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <Row gutter={[8, 8]} align="middle">
              <Col span={10} align="center">
                {approverPathImage ? (
                  <ApproverAvatar size={128} src={approverPathImage} />
                ) : (
                  <ApproverAvatar size={128} icon="user" />
                )
                }
              </Col>
              <Col span={14}>
                <p style={{ fontWeight: 'bold' }}>{approverName}</p>
                <p>{approverEmail}</p>
                <p>{approverMobile}</p>
              </Col>
            </Row>
            <Row gutter={[8, 48]} align="middle">
              <Col span={24} align="center">
                <Select
                  mode="multiple"
                  placeholder={intl.formatMessage({
                    id: 'orgTabDetailApproverModalReqTypeHint',
                    defaultMessage: 'Select request type',
                  })}
                  value={selectedItems}
                  onChange={handleChangeRequestType}
                  style={{ width: '100%' }}
                  showArrow={true}
                >
                  {filteredOptions.map((item) => (
                    <Select.Option key={item.req_id} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Modal> */}

          <ModalApprover
            visible={visible}
            setVisible={setVisible}
            orgId={orgId}
            setTrigger={setTrigger}
            data={dataDefault}
            setData={setDataDefault}
            setTriggerApprover={setTriggerApprover}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
});
