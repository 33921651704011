import React, { useState, useEffect } from 'react'
import { Select, Form } from 'antd'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import LabeRequireForm from '../label-required-form'
import { getTaskType } from '../../controllers/setting/task-type'

const { Option } = Select

const LoadmoreTaskType = (props) => {
  const intl = useIntl()
  const { form, data, setData, required, initialId } = props
  const getFieldDecorator = form ? form.getFieldDecorator : null
  const [taskTypeList, setTaskTypeList] = useState([])
  const [firstStep, setFirstStep] = useState(false)


  useEffect(() => {
    if (initialId) {
      setFirstStep(true)
    }
  }, [initialId])

  useEffect(() => {
    if (firstStep) getTaskTypeList()
  }, [firstStep])

  const getTaskTypeList = async () => {
    const response = await getTaskType('menuType=transportation')
    setTaskTypeList(response);
  }

  const handleSelect = (value, option) => {
    if (form) {
      setData(_.get(option, 'props.data'));
    } else {
      setData((prev) => ({ ...prev, taskType: value }))
    }
  }

  return (
    <Form.Item onClick={() => setFirstStep(true)} label={
      <LabeRequireForm
        text={intl.formatMessage({ id: 'taskTypeHeader', defaultMessage: 'Task Type' })}
        req={required}
      />
    }>
      {
        form ? getFieldDecorator('taskType', {
          initialValue: initialId,
          rules: [
            {
              required: required,
              message: intl.formatMessage({
                id: 'modalTaskSettingTaskTypeVLD',
                defaultMessage: 'Please Select TaskType',
              }),
            },
          ],
        })(
          <Select
            onSelect={handleSelect}
            placeholder={intl.formatMessage({ id: 'taskTypePlaceholder', defaultMessage: 'Select TaskType' })}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option key="notSpecified" value="notSpecified" data={{ id: "notSpecified" }}>
              ไม่กำหนด
            </Option>
            {
              _.map(taskTypeList, (item) => (
                <Option key={_.get(item, 'id')} value={_.get(item, 'id')} data={item}>
                  {_.get(item, 'name')}
                </Option>
              ))
            }
          </Select>
        ) : (
          <Select
            allowClear={true}
            onChange={handleSelect}
            placeholder={intl.formatMessage({ id: 'taskTypePlaceholder', defaultMessage: 'Select TaskType' })}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={_.get(data, 'taskType') || undefined}
          >
            <Option key="notSpecified" value="notSpecified" data={{ id: "notSpecified" }}>
              ไม่กำหนด
            </Option>
            {
              _.map(taskTypeList, (item) => (
                <Option key={_.get(item, 'id')} value={_.get(item, 'id')} data={item}>
                  {_.get(item, 'name')}
                </Option>
              ))
            }
          </Select>
        )
      }
    </Form.Item>

  )

}

LoadmoreTaskType.defaultProps = {
  required: true,
}

export default LoadmoreTaskType