import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import Button_01 from '../v2/button_01';
import Button_02 from '../v2/button_02';

const { Option } = Select;

const ListItemFilter = (props) => {
  const { onChange, searchGroup, onAllowClear, onClick, refType, tempchange, onCancel, groupModalType } = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  console.log('tempchange', refType);

  return (
    <div>
      <div style={{ overflowY: 'auto', height: `calc(109vh - 357px)`, padding: '5px 0px 0px 24px' }}>
        {/* <Divider type="horizontal" style={{ margin: 'unset' }} /> */}
        {groupModalType === 'type' ? (
          <div style={{ padding: '16px 16px 8px 0px' }}>
            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="modalCreateGroupTypeTypeCode" defaultMessage="Type Code" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'code')}
                  value={_.get(searchGroup, 'code')}
                  placeholder={intl.formatMessage({
                    id: 'modalCreateGroupTypeTypeCodePlaceHolder',
                    defaultMessage: 'Enter Type Code',
                  })}
                />
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="modalCreateGroupTypeTypeName" defaultMessage="Type Name" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'name')}
                  value={_.get(searchGroup, 'name')}
                  placeholder={intl.formatMessage({ id: 'modalCreateGroupTypeTypeNamePlaceHolder', defaultMessage: 'Enter Type Name' })}
                />
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="modalCreateBrandTitleStatus" defaultMessage="Status" />
              </div>
              <Select
                allowClear
                showSearch
                onChange={(value) => onChange(value, 'active')}
                filterOption={false}
                value={_.get(searchGroup, 'active')}
                placeholder={intl.formatMessage({ id: 'modalCreateBrandTitleStatusPlaceholder', defaultMessage: 'Select Status' })}
                style={{ width: '100%' }}
              >
                {_.map(refType, (item) => (
                <Option value={item.active}>
                  {item.statusName}
                </Option>
              ))}
                {/* <Option value={'Active'}>{'Active'}</Option>
                <Option value={'Inactive'}>{'Inactive'}</Option> */}
              </Select>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="modalCreateGroupFilterDescription" defaultMessage="Description" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'itemTypeDescription')}
                  value={_.get(searchGroup, 'itemTypeDescription')}
                  placeholder={intl.formatMessage({
                    id: 'modalCreateGroupFilterDescriptionPlaceHolder',
                    defaultMessage: 'Enter Description',
                  })}
                />
              </div>
            </div>
            {/* <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="ReferenceFilterToCreatedDate" defaultMessage="To Created Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                value={_.get(searchGroup, 'issueDateEnd') ? moment(_.get(searchGroup, 'issueDateEnd')) : ''}
                style={{ width: '100%' }}
                onChange={(e) => onChange(e, 'issueDateEnd')}
                placeholder={intl.formatMessage({ id: 'ReferenceFilterToCreatedDatePlaceHolder', defaultMessage: 'Select Created Date' })}
              />
            </div>
          </div> */}
          </div>
        ) : (
          <div style={{ padding: '16px 16px 8px 0px' }}>
            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="modalCreateGroupTypeGroupCode" defaultMessage="Group Code" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'code')}
                  value={_.get(searchGroup, 'code')}
                  placeholder={intl.formatMessage({
                    id: 'modalCreateGroupTypeGroupCodePlaceHolder',
                    defaultMessage: 'Enter Group Code',
                  })}
                />
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="modalCreateGroupTypeGroupName" defaultMessage="Group Name" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'name')}
                  value={_.get(searchGroup, 'name')}
                  placeholder={intl.formatMessage({ id: 'modalCreateGroupTypeGroupNamePlaceHolder', defaultMessage: 'Enter Group Name' })}
                />
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="modalCreateBrandTitleStatus" defaultMessage="Status" />
              </div>
              <Select
                allowClear
                showSearch
                onChange={(value) => onChange(value, 'active')}
                filterOption={false}
                value={_.get(searchGroup, 'active')}
                placeholder={intl.formatMessage({ id: 'modalCreateBrandTitleStatusPlaceholder', defaultMessage: 'Select Status' })}
                style={{ width: '100%' }}
              >
                {_.map(refType, (item) => (
                <Option value={item.active}>
                  {item.statusName}
                </Option>
              ))}
                {/* <Option value={'Active'}>{'Active'}</Option>
                <Option value={'Inactive'}>{'Inactive'}</Option> */}
              </Select>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="modalCreateGroupFilterDescription" defaultMessage="Description" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'itemGroupDescription')}
                  value={_.get(searchGroup, 'itemGroupDescription')}
                  placeholder={intl.formatMessage({
                    id: 'modalCreateGroupFilterDescriptionPlaceHolder',
                    defaultMessage: 'Enter Description',
                  })}
                />
              </div>
            </div>
          </div>
        )}

        {/* <div style={{ textAlign: 'center', padding: '0px 16px 16px 0px', display: 'flex', justifyContent: 'end' }}>
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()}>
            <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
          </Button_02>

          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => onClick()}>
            <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
          </Button_01>
        </div> */}
      </div>
    </div>
  );
};

export default ListItemFilter;
