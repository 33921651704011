import React, { useRef, useState, useEffect, useContext } from 'react';
import AssetOverview from './assetoverview';
import AssetClaimSummary from './assetclaim-summary';
import AssetInsurance from './assetinsurance';
import AssetAttachment from './assetattachment';
import AssetSubAsset from './assetsubasset';
import AssetHistory from './assethistory';
import AssetPreventive from './assetpreventive';
import { Card, Row, Col, Form, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import {
  assetClaimById,
  deleteAssetClaimHistory,
  getAssetInsurance,
  getAssetInsuranceByClaimDate,
  getByIdAsset,
  getClaimReference,
  getAssetPreventiveList,
  getInitialData,
  cancelAssetPreventive,
} from '../../../controllers/asset-resource/asset-controller';
import {
  getAssetClaimSummaryHistory,
  assetHistory,
  assetUserList,
  deleteAssetInsurance,
} from '../../../controllers/asset-resource/asset-controller';
import _ from 'lodash';
import RegisterViewEdit from '../../../components/asset-components/view-edit/index';
import Insurance from '../../../components/asset-components/asset-insurance/index';
import moment from 'moment';
import ClaimHistoryAdd from '../../../components/asset-components/claim-history/claim-history';
import UploadFileModal from '../../../components/asset-components/modal-upload';
import ClaimHistoryViewEdit from '../../../components/asset-components/claim-history/claim-history-edit';
import ReactBnbGallery from 'react-bnb-gallery';
import { useIntl } from 'react-intl';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import AddPreventiveForm from '../../../components/asset-components/asset-preventive/preventive-form';
import AssetSummaryContext from '../context';

let dateFormat = 'YYYY/MM/DD hh:mm:ss';

const AssetDetailList = () => {
  const intl = useIntl();
  // const { state, setState, fnc } = useContext(AssetSummaryContext);
  // const { setIsEdit } = setState;
  const [assetName, setAssetName] = useState([]);
  const [assetBrandName, setAssetBrandName] = useState([]);
  const [assetClassName, setAssetClassName] = useState([]);
  const [assetTypeCode, setAssetTypeCode] = useState([]);
  const [orgName, setOrgName] = useState([]);
  const [serialNo, setSerialNo] = useState([]);
  const [registerName, setRegisterName] = useState([]);
  const [description, setDescription] = useState([]);

  const [dataDocumentType, setDatDocumentType] = useState([]);
  const [dataDocumentUpload, setDataDocumentUpload] = useState([]);
  const [dataInsuranceActive, setDataInsuranceActive] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [visibleViewAndEdit, setVisibleViewAndEdit] = useState(false);
  const [mainTrigger, setMainTrigger] = useState(false);
  const [defaultEdit, setDefaultEdit] = useState();
  const [reloadCardpage, setReloadCardpage] = useState();

  const { id } = useParams();
  const [dataById, setDataById] = useState();
  const [triggerApi, setTriggerApi] = useState(false);
  const [insuranceData, setInsuranceData] = useState([]);
  const [insuranceDataClaim, setInsuranceDataClaim] = useState([]);
  const [claimRef, setClaimRef] = useState([]);
  const [claimHistory, setClaimHistory] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [insuranceId, setInsuranceId] = useState();
  const [rangeDate, setRangeDate] = useState([moment().startOf('year'), moment().endOf('year')]);
  const [openClaim, setOpenClaim] = useState(false);
  const [openClaimAction, setOpenClaimAction] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [recordClaim, setRecordClaim] = useState();
  const [attachmentData, setAttachmentData] = useState([]);
  const [typeAtt, setTypeAtt] = useState('add');
  const [defaultAtt, setDefaultAtt] = useState();
  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();
  const [openPM, setOpenPM] = useState();


  console.log('rangeDate', rangeDate);

  const [visibleAddInsurance, setVisibleAddInsurance] = useState(false);
  const [assetHistoryData, setAssetHistoryData] = useState();
  const [assetHistoryDataLog, setAssetHistoryDataLog] = useState();
  const [assetHistoryDataActionList, setAssetHistoryDataActionList] = useState();
  // const [assetHistoryUserTeam, setAssetHistoryUserTeam] = useState();
  const [assetNo, setAssetNo] = useState();
  const [assetUser, setAssetUser] = useState();
  const [assetHistoryUser, setAssetHistoryUser] = useState();
  const [assetHistoryUserTeam, setAssetHistoryUserTeam] = useState();
  const [assetHistoryUserEdit, setAssetHistoryUserEditer] = useState();
  const [historyRangeDate, setHistoryRangeDate] = useState([moment().startOf('month'), moment().endOf('month')]);
  const [typeModalInsurance, setTypeModalInsurance] = useState('view');
  const [startInsuranceData, setStartInsuranceData] = useState();
  const [sectionModalInsurance, setSectionModalInsurance] = useState(false);
  const [assetCreateAt, setAssetCreateAt] = useState();
  // const [assetCreateTo, setAssetCreateTo] = useState();
  const [preventive, setPreventive] = useState();
  const [prePage, setPrePage] = useState(1);
  const [preSize, setPreSize] = useState(10);
  const [pmAdd, setPMAdd] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const dateFormat = 'YYYY-MM-DD';

  const date = moment().format(dateFormat);

  useEffect(() => {
    const name = '';
    const getData = async () => {
      if (id) {
        const response = await getByIdAsset(id);
        setAssetCreateAt(_.get(response.data[0], 'createdAtDateVal'));
        setDataById(response);
        setAssetNo(_.get(response.data[0], 'assetNo'));

        // setAssetCreateTo(date)
        // console.log('qqqqqq:::', _.get(response.data[0], 'assetNo'));
        // console.log("Created ", _.get(response.data[0], 'createdAtDateVal'))
        // console.log("Current ", date);
      }
    };
    getData();
    getDataMain();
  }, [id, triggerApi, mainTrigger]);
  // console.log("Current ", assetCreateTo);
  console.log('created ', assetCreateAt);

  useEffect(() => {
    const getAssetHistory = async () => {
      const payload = {
        indexStart: 0,
        indexEnd: 3,
        actionType: 'all',
        createdBy: '',
      };
      try {
        const response = await assetHistory(payload, assetNo, assetCreateAt, date);
        setAssetHistoryData(_.get(response, 'data.data.list'));
      } catch (error) {
        setAssetHistoryData([]);
      }

      // console.log('responsexxx:::', response);
    };
    getAssetHistory();
    // assetUserList();
  }, [id, assetNo]);

  // console.log('assetCreateAtFormat:::', assetCreateAtFormat);

  const AssetHistoryAllAction = async () => {
    // const payload = {
    //   actionType: 'all',
    //   createdBy: '89',
    // };
    const name = '';

    const responseaction = await assetHistory();
    // const responseuser = await assetUserList(name);
    // setAssetUser(_.get(responseuser, 'data.members'));
    // setAssetHistoryDataLog(responseaction);
    setAssetHistoryDataActionList(_.get(responseaction, 'data.data.actionsList'));
    console.log('setAssetHistoryDataActionList', assetHistoryDataActionList);
  };

  useEffect(() => {
    getAssetInsuranceApi();
  }, [id]);

  useEffect(() => {
    getClaimReferenceApi();
  }, [id]);

  useEffect(() => {
    getClaimSummaryData();
  }, [id, page, pageSize, _.get(insuranceId, 'value'), orderSort, fieldSort, triggerApi, _.get(rangeDate, '[0]'), _.get(rangeDate, '[1]')]);

  useEffect(() => {
    getPreventiveApi();
    getPreventiveModal();
  }, [id, prePage, preSize]);

  const getDataMain = async () => {
    if (id) {
      const response = await getByIdAsset(id);
      setAssetData(_.get(response.data, [0]));
      setAssetName(_.get(response.data[0], 'assetName'));
      setAssetBrandName(_.get(response.data[0], 'assetBrandName'));
      setAssetClassName(_.get(response.data[0], 'assetClassName'));
      setAssetTypeCode(_.get(response.data[0], 'assetTypeCode'));
      setOrgName(_.get(response.data[0], 'orgName'));
      setSerialNo(_.get(response.data[0], 'serialNo'));
      setRegisterName(_.get(response.data[0], 'registerName'));
      setDescription(_.get(response.data[0], 'description'));
      console.log('response:::', response);
    }
  };

  const getAssetInsuranceApi = async () => {
    try {
      const resp = await getAssetInsurance(id);
      setInsuranceData(_.get(resp.data, 'assetInsurance'));
    } catch (error) { }
  };

  const getClaimReferenceApi = async () => {
    try {
      const resp = await getClaimReference(id);

      setClaimRef(_.get(resp.data, 'assetSerialNoList'));
    } catch (error) { }
  };

  const getClaimSummaryData = async () => {
    setIsloading(true);
    try {
      const payload = {
        seachDateFrom: moment(_.get(rangeDate, '[0]'), 'ddd, MMM DD YYYY').format(dateFormat),
        seachDateTo: moment(_.get(rangeDate, '[1]'), 'ddd, MMM DD YYYY').format(dateFormat),
        assetId: id,
        assetInsuranceId: _.get(insuranceId, 'value'),
        limit: pageSize,
        page: page,
        claimRef: '',
        insurance: '',
        claimBy: '',
        damageVal: '',
        claimable: '',
        orderBy: fieldSort,
        orderType: orderSort,
      };
      const response = await getAssetClaimSummaryHistory(payload);

      setClaimHistory(_.get(response, 'data'));
      setIsloading(false);

      setIsloading(false);
    } catch (error) {
      setIsloading(false);
    }
  };

  const handleEdit = (value) => {
    setVisibleViewAndEdit(true);
    setDefaultEdit(value);
  };

  const handleChangeTable = (event, sorter, extra) => {
    const { current, pageSize } = event;
    let field = extra.field;
    setPage(current);
    setPageSize(pageSize);

    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
  };

  const handleChangeDate = (value) => {
    setRangeDate(value);
  };

  const handleChangeInsurance = (value, e) => {
    console.log('handleChangeInsurance', value, e);
    if (value) {
      setInsuranceId(e.props);
    } else {
      setInsuranceId();
    }
  };

  const handleActionClaimHistory = () => {
    setOpenClaim(true);
  };

  const handleActionViewEdit = (x, y) => {
    if (y === 'delete') {
      handleDeleteClaimhistorys(x.claimId);
    } else {
      getAssetClaimById(x.claimId, y);
    }
  };

  const getAssetClaimById = async (claimId, type) => {
    try {
      const response = await assetClaimById(claimId);
      if (response.status === 200) {
        setRecordClaim({ data: _.get(response.data, 'data'), type: type, stamp: type });
        setOpenClaimAction(true);
      }
    } catch (error) { }
  };

  const handleClickPopoverInsurance = (type, record) => {
    if (type === 'delete') {
      handleDeleteInsurenc(record);
    } else {
      setTypeModalInsurance(type);
      setVisibleAddInsurance(true);
      setStartInsuranceData(record);
    }
  };

  const handleDeleteInsurenc = (record) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteAssetInsurance(_.get(record, 'assetInsuranceId'));
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setTriggerApi((current) => !current);
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  const handleDeleteClaimhistorys = (record) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteAssetClaimHistory(record);
        console.log('response: delete:', response);
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setTriggerApi((current) => !current);
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  //-------------------------- Preventive Page ----------------------------------------//

  const getPreventiveApi = async () => {
    setIsloading(true);
    try {
      const payload = {
        itemPmId: [],
        dateFrom: '',
        dateTo: '',
        assignee: [],
        statusTaskId: [],
        limit: preSize,
        pageNumber: prePage,
      };
      const resp = await getAssetPreventiveList(payload);
      setPreventive(_.get(resp, 'data'));
      setPrePage(_.get(resp.data, 'totalPage'));
      setPreSize(_.get(resp.data, 'totalRows'));
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
    }
  };

  const cencelPreventiveApi = async (id, only) => {
    setIsloading(true);
    try {
      const payload = {
        assetPmId: id,
        cancelOnlyOne: only,
      };
      console.log('cancelPm', payload);
      const resp = await cancelAssetPreventive(payload);
      getPreventiveApi();
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
    }
  };

  const getPreventiveModal = async () => {
    try {
      const resp = await getInitialData();
      console.log('initData', resp.data);
      setPMAdd(resp.data);
    } catch (error) { }
  };

  const handleOpenAddPM = () => {
    setOpenPM(true);
  };

  const handlePrePage = (page) => {
    console.log('PagePm', page);
    setPrePage(page);
  };

  const handlePreSize = (current, size) => {
    console.log('PagePm1', size);
    setPreSize(size);
    setPrePage(current);
  };

  //------------------------------------------------------------------------------------//

  return (
    <div>
      <div>
        <Row gutter={16}>
          <Col span={18}>
            <div className="frame">
              <AssetOverview assetData={assetData} handleEdit={handleEdit} />
            </div>
            <div className="frame">
              <AssetClaimSummary
                insuranceData={insuranceData}
                claimData={claimHistory}
                isLoading={isLoading}
                page={page}
                rangeDate={rangeDate}
                dateFormat={dateFormat}
                handleChange={handleChangeTable}
                handleChangeDate={handleChangeDate}
                handleChangeInsurance={handleChangeInsurance}
                handleActionClaimHistory={handleActionClaimHistory}
                handleActionViewEdit={handleActionViewEdit}
              />
            </div>
            <div classsname={'frame'}>
              <AssetPreventive
                handleOpenAddPM={handleOpenAddPM}
                pmlist={preventive}
                isLoading={isLoading}
                prePage={prePage}
                preSize={preSize}
                handlePrePage={handlePrePage}
                handlePreSize={handlePreSize}
                cencelPreventiveApi={cencelPreventiveApi}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="frame">
              <div className="frame">
                <AssetInsurance dataById={dataById} handleClickPopover={handleClickPopoverInsurance} />
              </div>
              <div className="frame">
                <AssetSubAsset />
              </div>
              <div className="frame">
                <AssetAttachment dataById={dataById} setTriggerApi={setTriggerApi} />
              </div>
              <div className="frame">
                <AssetHistory
                  historyRangeDate={historyRangeDate}
                  AssetHistoryAllAction={AssetHistoryAllAction}
                  assetUser={assetUser}
                  assetHistoryDataActionList={assetHistoryDataActionList}
                  assetHistoryData={assetHistoryData}
                  assetNo={assetNo}
                />
              </div>
            </div>
          </Col>
        </Row>

        <RegisterViewEdit
          getDataOverview={getDataMain}
          visible={visibleViewAndEdit}
          setVisible={setVisibleViewAndEdit}
          typeModal="edit"
          defaultEdit={assetData}
          setMainTrigger={setMainTrigger}
          setIsEdit={setIsEdit}
        />

        {/* <Row gutter={[16, 16]}>
                  <Col span={toggle ? 0 : 5}>
                    <CardCategory />
                  </Col>
                  <Col span={toggle ? 24 : 19}>
                    <AssetSummaryReport />
                  </Col>
                </Row> */}

        <Insurance
          visible={visibleAddInsurance}
          setVisible={setVisibleAddInsurance}
          typeModal={typeModalInsurance}
          assetId={id}
          setTriggerApi={setTriggerApi}
          startInsuranceData={startInsuranceData}
          setTypeModalInsurance={setTypeModalInsurance}
          sectionModalInsurance={sectionModalInsurance}
        />
        <ClaimHistoryAdd
          assetId={id}
          visible={openClaim}
          claimRef={claimRef}
          setVisible={setOpenClaim}
          setTriggerApi={setTriggerApi}
          trigger={triggerApi}
        />

        <ClaimHistoryViewEdit
          assetId={id}
          record={recordClaim}
          visible={openClaimAction}
          claimRef={claimRef}
          setVisible={setOpenClaimAction}
          setTriggerApi={setTriggerApi}
          setRecordClaim={setRecordClaim}
        />
        {console.log('pmadd', pmAdd)}
        <AddPreventiveForm visible={openPM} setVisible={setOpenPM} pmadd={pmAdd} />
        {/* modal upload */}
      </div>
    </div>
  );
};

export default AssetDetailList;
