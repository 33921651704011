import React, { useState, useEffect } from 'react'
import './index.css'
import { Card, Table } from 'antd'
import { FormattedMessage } from 'react-intl'
import ReportInstockAction from './action'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { warehouseReportInstock, getWareHouseList, warehouseReportInstockExport } from '../../../controllers/warehouse/warehouse'
import { getDataAll } from '../../../controllers/item-master/get-data-all'
import { useDebounce } from '../../../controllers/debounce'
import moment from 'moment'
import { getBrandData, getModelData } from '../../../controllers/po/po'
import { successNotification, errorNotification } from '../../../components/v2/notification'

const WarehouseReportInstock = () => {
  const intl = useIntl()

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,

    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsWarehouse', defaultMessage: 'Warehouse' }),
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      fixed: 'left',
      sorter: true,
      width: 180,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsItemName', defaultMessage: 'Item Name' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      width: 180,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      align: 'right',
      width: 130,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsLotNo', defaultMessage: 'Lot No. or Serial No.' }),
      dataIndex: 'lotOrSerialNo',
      key: 'lotOrSerialNo',
      width: 200,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsItemGroup', defaultMessage: 'Item Group' }),
      dataIndex: 'itemGroupName',
      key: 'itemGroupName',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsItemType', defaultMessage: 'Item Type' }),
      dataIndex: 'itemTypeName',
      key: 'itemTypeName',
      width: 150,
      sorter: true,
      default: true,
    },
    // {
    //   title: intl.formatMessage({ id: 'reportInstockColumnsCategory', defaultMessage: 'Category' }),
    //   dataIndex: 'uomName',
    //   key: 'uomName',
    //   width: 120,
    //   sorter: true,
    //   default: true,
    // },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsBrand', defaultMessage: 'Brand' }),
      dataIndex: 'itemBrandName',
      key: 'itemBrandName',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsModel', defaultMessage: 'Model' }),
      dataIndex: 'itemModelName',
      key: 'itemModelName',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsFDA', defaultMessage: 'FDA' }),
      dataIndex: 'fda',
      key: 'fda',
      width: 150,
      default: true,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsManufacturingDate', defaultMessage: 'Manufacturing Date' }),
      dataIndex: 'manufacturingDate',
      key: 'manufacturingDate',
      width: 200,
      default: true,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'reportInstockColumnsExpiredDate', defaultMessage: 'Expired Date' }),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      width: 150,
      default: true,
      sorter: true,
    },
  ];
  const setShowColumnArr = _.map(columns, (item) => { return item.dataIndex })
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  const ignoreColumn = _.map(_.filter(columns, (item) => { return item.default === false }), (item) => { return item.dataIndex })
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [search, setSearch] = useState({});
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2
  const [sortOrder, setSortOrder] = useState('')
  const [sortField, setSortField] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([])
  const [dataDefault, setDataDefault] = useState()
  const [warehouseData, setWarehouseData] = useState([])
  const searchDebounce = useDebounce(search, 500)

  const [optionsBrand, setOptionsBrand] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [brandSearch, setBrandSearch] = useState();
  const debounceBrandSearch = useDebounce(brandSearch, 500)

  const [optionsModel, setOptionsModel] = useState([]);
  const [loadingModel, setLoadingModel] = useState(false);
  const [modelSearch, setModelSearch] = useState();
  const debounceModelSearch = useDebounce(modelSearch, 500)

  useEffect(() => {
    async function getAPI() {
      const response = await getDataAll();
      const warehouseData = await getWareHouseList({
        indexStart: 0,
        indexEnd: 10000,
        searchLeftMenu: '',
        isNotCheckPermission: true,
      })
      setDataDefault(response);
      setWarehouseData(_.get(warehouseData, 'data.data.warehouseList'))
    }
    getAPI();
  }, []);

  useEffect(() => {
    const fetcBrand = async () => {
      setLoadingBrand(true);
      const body = {
        "indexStart": 0,
        "indexEnd": 100,
        "item_brand_name": brandSearch || ""
      }
      const response = await getBrandData(body)
      setOptionsBrand(_.get(response, 'data.data.list'));
      setLoadingBrand(false);
    };
    fetcBrand(); // โหลดข้อมูลเริ่มต้น
  }, [debounceBrandSearch]);

  useEffect(() => {
    const fetcModel = async () => {
      setLoadingModel(true);
      const body = {
        "indexStart": 0,
        "indexEnd": 100,
        "item_model_name": modelSearch || "",
        "item_brand_id": [_.get(search, 'brand')]
      }
      const response = await getModelData(body)
      setOptionsModel(_.get(response, 'data.data.list'));
      setLoadingModel(false);
    };
    if (_.get(search, 'brand')) {
      fetcModel(); // โหลดข้อมูลเริ่มต้น
    }
  }, [debounceModelSearch, search]);


  useEffect(() => {
    const getListDate = async () => {
      setLoading(true)
      const body = {
        "warehouseId": _.get(search, 'warehouse') || "",
        "itemName": _.get(search, 'itemName') || "",
        "lotSerialNo": _.get(search, 'lotNo') || "",
        "itemGroupId": _.get(search, 'itemGroup') || "",
        "itemTypeId": _.get(search, 'itemType') || "",
        "itemBrandId": _.get(search, 'brand') || "",
        "itemModelId": _.get(search, 'model') || "",
        "fda": _.get(search, 'fda') || "",
        "expiredDateFrom": _.get(search, 'expiredDate') && _.size(search.expiredDate) > 0 ? moment(_.get(search, 'expiredDate[0]')).format('YYYY-MM-DD') : "",
        "expiredDateTo": _.get(search, 'expiredDate') && _.size(search.expiredDate) > 0 ? moment(_.get(search, 'expiredDate[1]')).format('YYYY-MM-DD') : "",
        "manufacturingDateFrom": _.get(search, 'manufacturingDate') && _.size(search.manufacturingDate) > 0 ? moment(_.get(search, 'manufacturingDate[0]')).format('YYYY-MM-DD') : "",
        "manufacturingDateTo": _.get(search, 'manufacturingDate') && _.size(search.manufacturingDate) > 0 ? moment(_.get(search, 'manufacturingDate[1]')).format('YYYY-MM-DD') : "",
        "pageNumber": page,
        "limit": size,
        "orderBy": sortField,
        "orderType": sortOrder,
      }
      const response = await warehouseReportInstock(body)
      setListData(_.get(response, 'data.data.list'))
      setTotal(_.get(response, 'data.data.totalItems'))
      setLoading(false)
    }
    getListDate()
  }, [page, size, sortOrder, sortField, searchDebounce])


  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
    setPage(1)
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    try {
      const body = {
        "warehouseId": _.get(search, 'warehouse') || "",
        "itemName": _.get(search, 'itemName') || "",
        "lotSerialNo": _.get(search, 'lotNo') || "",
        "itemGroupId": _.get(search, 'itemGroup') || "",
        "itemTypeId": _.get(search, 'itemType') || "",
        "itemBrandId": _.get(search, 'brand') || "",
        "itemModelId": _.get(search, 'model') || "",
        "fda": _.get(search, 'fda') || "",
        "expiredDateFrom": _.get(search, 'expiredDate') && _.size(search.expiredDate) > 0 ? moment(_.get(search, 'expiredDate[0]')).format('YYYY-MM-DD') : "",
        "expiredDateTo": _.get(search, 'expiredDate') && _.size(search.expiredDate) > 0 ? moment(_.get(search, 'expiredDate[1]')).format('YYYY-MM-DD') : "",
        "manufacturingDateFrom": _.get(search, 'manufacturingDate') && _.size(search.manufacturingDate) > 0 ? moment(_.get(search, 'manufacturingDate[0]')).format('YYYY-MM-DD') : "",
        "manufacturingDateTo": _.get(search, 'manufacturingDate') && _.size(search.manufacturingDate) > 0 ? moment(_.get(search, 'manufacturingDate[1]')).format('YYYY-MM-DD') : "",
        "pageNumber": 1,
        "limit": total,
        "orderBy": sortField,
        "orderType": sortOrder,
        "viewFormat": _.map(_.filter(columns, (e) => { return e.dataIndex !== 'index' }), (item, index) => {
          return {
            colName: item.title,
            sequence: index + 1,
            colCode: item.dataIndex,
          }
        })
      };
      const response = await warehouseReportInstockExport(body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) { }
  };

  return (
    <div>
      <Card
        className='reportInstockCard'
        title={intl.formatMessage({ id: 'menuWarehouseInstock', defaultMessage: 'In Stock Report' })}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
      >
        <ReportInstockAction
          search={search}
          onFilterOrder={onFilterOrder}
          dataDefault={dataDefault}
          warehouseData={warehouseData}
          handleExport={handleExport}
          brand={{
            optionsBrand,
            loadingBrand,
            brandSearch,
            setBrandSearch,
          }}
          model={{
            optionsModel,
            loadingModel,
            modelSearch,
            setModelSearch,
          }}
          actionColumns={{
            columns,
            setShowColumn,
            newDataColumns,
            setNewDataColumns,
            setListArrayColumns,
            setDefaultShowColumn,
            listArrayColumns,
            defaultShowColumn,
            newColumns,
            textErrorSelectColumn,
            setTextErrorSelectColumn,
            visible,
            setVisible,
            columnMinimum
          }}
        />
        <div style={{ padding: '16px 24px' }}>
          <Table
            dataSource={listData}
            columns={newDataColumns}
            scroll={{ x: _.sumBy(newDataColumns, 'width') }}
            onChange={handleChange}
            loading={loading}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              current: page,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
          />
          {_.size(listData) > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  )
}

export default WarehouseReportInstock
