import React from 'react'
import Nodata from '../../../components/image/Nodata_web.svg'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash';
import { Divider, Tag } from 'antd';

const ModalTabsHistory = (props) => {
  const { historyList } = props;
  return (
    <div style={{ height: '480px', overflowY: 'auto' }}>
      {_.size(historyList) > 0 ? (
        <div style={{padding: '24px'}}>
        {_.map(historyList, (item) => (
          <div>
            <div>
              <span style={{ color: '#1D3557' }}> {_.get(item, 'updatedByName')} </span>
              <span style={{ color: '#050505',marginRight: '12px' }}>
                <FormattedMessage id="resourceAllowanceModalHistoryFrom" defaultMessage="changes status from" />
              </span>{' '}
              <Tag
                style={{ width: 'auto', minWidth: '85px', padding: '0px 25px', borderRadius: '10px', margin: 'unset' }}
                color={_.get(item, 'statusFrom.color')}
              >
                {_.get(item, 'statusFrom.statusName')}
              </Tag>{' '}
              <span style={{ color: '#050505',margin: '0px 12px' }}>
                <FormattedMessage id="resourceAllowanceModalHistoryTo" defaultMessage="to" />
              </span>{' '}
              {''}
              <Tag
                style={{ width: 'auto', minWidth: '85px', padding: '0px 25px', borderRadius: '10px', margin: 'unset',marginRight: '12px'  }}
                color={_.get(item, 'statusTo.color')}
              >
                {_.get(item, 'statusTo.statusName')}
              </Tag>{' '}
              <span style={{ color: '#65676B' }}>{_.get(item, 'updatedDate')} </span>{' '}
              <span style={{ lineHeight: '2.5', color: '#050505' }}>{_.get(item, 'txtReasonRemark')}</span>
            </div>
            <Divider type="horizontal" />
          </div>
        ))}
        </div>
      ) : (
      <div style={{ display: 'grid', justifyContent: 'center', height: '100%' }}>
        <img src={Nodata} style={{ width: '195px' }}></img>
        <div>
          <div style={{ color: '#1D3557', textAlign: 'center', fontSize: '14px', fontWeight: 600 }}>
            <FormattedMessage id="textNoData" defaultMessage="No Data" />
          </div>
          <div style={{ color: '#9A9999', textAlign: 'center', fontSize: '14px' }}>
            <FormattedMessage id="memberExpenseModalHistoryNodata" defaultMessage="History will appear here." />
          </div>
        </div>
      </div>
      )}
    </div>
  )
}

export default ModalTabsHistory
