import React, { useState } from 'react';
import { Form, Row, Col, Select, Modal, Icon } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import style from './css/index.css';

const editModalPrivacy = ({ form, onCreate, visibleModalPrivacy, setVisibleModalPrivacy, dataPrivacyType, dataTeam, intl }) => {
  // const [disablePrivacy, setDisablePrivacy] = useState(true)

  const { Option } = Select;
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;

  const LabelRequire = styled.label`
    color: #ff1010;
  `;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const validatorResetTeam = (rule, value, callback) => {
    if (value !== 'team') {
      setFieldsValue({
        ['team']: undefined,
      });
      callback();
    } else {
      callback();
    }
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'ModalPrivacyTextHeader', defaultMessage: 'Select Audience' })}
        className="padding-modal"
        centered={true}
        width={375}
        visible={visibleModalPrivacy}
        onCancel={() => setVisibleModalPrivacy(false)}
        onOk={onCreate}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={() => setVisibleModalPrivacy(false)}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={onCreate}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form>
          <Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <span className="text-style">
                    <FormattedMessage id="ModalPrivacyTextAudience" defaultMessage="Audience" />
                    <LabeRequire req={true} />
                  </span>
                  {getFieldDecorator('privacy', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'ModalPrivacyValidateAudience', defaultMessage: 'Please select privacy.' }),
                      },
                      {
                        validator: validatorResetTeam,
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({ id: 'ModalPrivacyPlaceholderAudience', defaultMessage: 'Select Privacy' })}
                      // onSelect={(value) => disblePrivacy(value)}
                    >
                      {dataPrivacyType &&
                        dataPrivacyType.map((item, i) => (
                          <Option key={item.code} value={item.code}>
                            <Icon type={item.code == 'team' ? 'team' : item.code == 'public' ? 'global' : 'lock'} className="image-size" />{' '}
                            &nbsp;
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Col span={24}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="ModalPrivacyTextTeam" defaultMessage="Team" />
                  <LabeRequire req={getFieldValue(`privacy`) === 'team' ? true : false} />
                </span>
                {getFieldDecorator('team', {
                  rules: [
                    {
                      required: getFieldValue(`privacy`) === 'team' ? true : false,
                      message: intl.formatMessage({ id: 'ModalPrivacyValidateTeam', defaultMessage: 'Please select team.' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'ModalPrivacyPlaceholderTeam', defaultMessage: 'Select Team' })}
                    mode="multiple"
                    disabled={getFieldValue(`privacy`) === 'team' ? false : true}
                  >
                    {dataTeam && dataTeam.map((item, i) => <Option value={item.orgId}>{item.name}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
const ModalPrivacy = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {
    return {
      privacy: Form.createFormField({
        value: props.dataPrivacy && props.dataPrivacy !== undefined ? props.dataPrivacy.privacy : undefined,
      }),
      team: Form.createFormField({
        value: props.dataPrivacy && props.dataPrivacy !== undefined ? props.dataPrivacy.team : undefined,
      }),
    };
  },
})(editModalPrivacy);

export default ModalPrivacy;
