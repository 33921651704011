import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messageLabel } from '../../../../../components/message-component';
import CustomTableComponent from '../../../components/table';
import { DatePicker, Divider, Input } from 'antd';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import moment from 'moment';

const dateFormat = 'ddd, MMM DD YYYY';

const PaymentTabView = ({ form, dataSource }) => {
  const intl = useIntl();

  console.log('PaymentTabView', dataSource);
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: messageLabel('quotationLabelDueDate', 'Due Date'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 200,
      render: (text, record, index) =>
        moment(_.get(record, 'dueDate')).format('DD/MM/YY'),
        // <DatePicker
        //   allowClear={false}
        //   format={dateFormat}
        //   style={{ width: '100%' }}
        //   placeholder={intl.formatMessage({ id: 'quotationHintDueDate', defaultMessage: 'Enter Due Date' })}
        // />
    },
    {
      dataIndex: 'Blank',
      key: 'Blank',
      ellipsis: true,
      width: 200,
    },
    {
      title: messageLabel('quotationLabelCredit', 'Credit (days)'),
      dataIndex: 'credit',
      key: 'credit',
      ellipsis: true,
      width: 250,
    },
    {
      title: messageLabel('quotationLabelAmount', 'Amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, record, index) => numberFormatter(record.amount) + ' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' }),
    },
    {
      dataIndex: 'Blank',
      key: 'Blank',
      ellipsis: true,
      width: 250,
    },
    // },
    // {
    //   title: messageLabel('quotationLabelCredit', 'Credit (days)'),
    //   dataIndex: 'credit',
    //   key: 'credit',
    //   ellipsis: true,
    // },
    // {
    //   title: messageLabel('quotationLabelAmount', 'Amount'),
    //   dataIndex: 'amount',
    //   key: 'amount',
    //   align : 'right',
    //   render: (text, record, index) =>
    //     numberFormatter(record.amount) + ' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' }),
    // },
    // {
    //   title: messageLabel('quotationLabelRemark', 'Remark'),
    //   dataIndex: 'remark',
    //   key: 'remark',
    //   render: (text, record, index) => (
    //     _.get(record, 'remark') || '-'
    //   ),
    // },
  ];

  // const dataSource = [];

  return (
    <div className="scroll-sm scroll-height" style={{ marginTop: 24 }}>
      <div className="content-body">
        <CustomTableComponent columns={columns} dataSource={dataSource} scroll={{ x: true }} total={0} role={false} onRow={false} />
      </div>
    </div>
  );
};

export default PaymentTabView;
