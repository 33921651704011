import React, { useState, useEffect, useContext } from 'react';
import { KeycloakProvider, useKeycloak } from '@react-keycloak/web';
import keycloak from '../components/v2.1.0/keycloak/keycloak';
import { Route, useLocation, useHistory } from 'react-router-dom';
import Dashboard from '../layout/index';
import { AppProvider } from '../includes/indexProvider';
import Loading from '../components/v2/loading';
import { PageSettings } from '../config/page-settings';
import httpClient from '../components/axiosClient';
import _ from 'lodash';
import { dd, checkDefaultPath } from '../helpers/general-helper';
// import PathRoute from '../controllers/leftmenu/find-path-route'
import RouteData from '../config/page-route';

const keycloakProviderInitConfig = {
  onLoad: 'login-required',
  checkLoginIframe: false,
  enableLogging: true,
};

export default function RouteConfigExample() {
  let location = useLocation();
  let getDefaultSelectMenu = checkDefaultPath(location.pathname);

  const history = useHistory();
  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');
  const [pageSidebar, setPageSidebar] = useState([getDefaultSelectMenu.selectKey]);
  const [pageSidebarOpen, setPageSidebarOpen] = useState(getDefaultSelectMenu.openKey);
  const [pageSidebarTitle, setPageSidebarTitle] = useState([]);
  const [pageSidebarToggled, setPageSidebarToggled] = useState(false);
  const [permissionList, setPermissionList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [memberProfile, setMemberProfile] = useState();
  const [pathKey, setPathKey] = useState();
  const [menuLicense, setMenuLicense] = useState([]);

  const [team, setTeam] = useState([]);
  // console.log('pageSidebarTitle:::', pageSidebarTitle);
  useEffect(() => {
    const getMemberProfile = async (keycloakUserId) => {
      try {
        const result = await httpClient.get(`/v2/member/profile/keycloak/${keycloakUserId}`);
        if (result.status === 200) {
          setMemberProfile(result.data);
        }
      } catch (e) {
        return;
      }
    };
    const keycloakUserId = localStorage.getItem('userId');
    if (!memberProfile) getMemberProfile(keycloakUserId);
  });

  const onKeycloakEvent = async (event, error) => {
    const comCode = localStorage.getItem('comCode');

    if (event === 'onAuthSuccess') {
      localStorage.setItem('token', keycloak);

      try {
        const [permissionPage, getRole, getLicense] = await Promise.all([
          httpClient.get(`/company/${comId}/permission/action/P`),
          httpClient.get(`/v2/roles/${memComId}`),
          httpClient.get(`/v3/authorization/manager/company/${comCode}/permission/menu`),
        ]);

        if (permissionPage.status === 200) {
          setPermissionList(permissionPage.data);
        } else {
          setPermissionList([]);
        }

        if (getRole.status === 200) {
          setRoleList(getRole.data);
        } else {
          setRoleList([]);
        }

        if (getLicense.status === 200) {
          setMenuLicense(_.get(getLicense, 'data.data.leftMenu') || []);
        } else {
          setMenuLicense([]);
        }
      } catch (error) {
        setMenuLicense([]);
      }

      // try {
      //   const permissionPage = await httpClient.get(`/company/${comId}/permission/action/P`);
      //   if (permissionPage.status === 200) {
      //     setPermissionList(permissionPage.data);
      //   }
      //   else {
      //     setPermissionList([]);
      //   }
      // }
      // catch (error) {
      //   setPermissionList([]);
      // }

      // try {
      //   const getRole = await httpClient.get(`/v2/roles/${memComId}`);
      //   if (getRole.status === 200) {
      //     setRoleList(getRole.data);
      //   }
      //   else {
      //     setRoleList([]);
      //   }
      // }
      // catch (error) {
      //   setRoleList([]);
      // }
    }

    if (event === 'onAuthRefreshError' || event === 'onAuthLogout') {
      localStorage.setItem('userId', 'undefined');
      localStorage.setItem('memComId', 'undefined');
      localStorage.setItem('comId', 'undefined');
      localStorage.setItem('companyId', 'undefined');
      keycloak.logout({ redirectUri: `${window.location.origin}/main` });
    }
  };

  const onKeycloakTokens = async (tokens) => {
    // localStorage.setItem('token', tokens.token);
    /*
    try {
      const permissionPage = await httpClient.get(`/company/${comId}/permission/action/P`);
      if(permissionPage.status === 200) {
        setPermissionList(permissionPage.data);
      }
      else {
        setPermissionList([]);
      }
    }
    catch (error) {
      setPermissionList([]);
    }

    try {
      const getRole = await httpClient.get(`/v2/roles/${memComId}`);
      if(getRole.status === 200) {
        setRoleList(getRole.data);
      }
      else {
        setRoleList([]);
      }
    }
    catch (error) {
      setRoleList([]);
    }
    */
  };

  const handleOnSelectMenu = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    setPageSidebarTitle(selectedKeys);
    setPageSidebar(selectedKeys);
    const filterKey =
      RouteData &&
      RouteData.filter((e) => {
        return e.menuCode === selectedKeys.toString();
      });
    const pathKey = filterKey !== undefined ? filterKey[0].path : undefined;
    if (pathKey) {
      history.push(pathKey);
    }
  };

  const handleSidebarToggled = () => {
    setPageSidebarToggled(!pageSidebarToggled);
  };

  const checkPermissionAction = (pageCode, actionCode) => {
    let action = false;

    if (_.get(memberProfile, 'member.is_super_admin')) return true;

    if (pageCode === 'user-management' && actionCode === 'my-profile') {
      return true;
    }

    const pageRoute = _.find(RouteData, ['pages', pageCode]);
    if (pageRoute) {
      const checkLicense = _.find(menuLicense, ['licenseModuleCode', pageRoute.licenseCode]);
      if (!_.get(checkLicense,'isAllowed')) return false;
    }

    const foundPage = _.findIndex(permissionList, ['code', pageCode]);
    if (foundPage > -1) {
      const foundAction = _.findIndex(permissionList[foundPage].action, ['code', actionCode]);
      if (foundAction > -1) {
        if (permissionList[foundPage].action[foundAction].permission.length > 0) {
          const roles = permissionList[foundPage].action[foundAction].permission[0].roles;
          const users = permissionList[foundPage].action[foundAction].permission[0].users;

          if (roles !== '') {
            const rolesArr = roles.split(',');
            _.forEach(roleList, (value) => {
              action =
                _.findIndex(rolesArr, function (o) {
                  return Number(o) === Number(value.per_gro_id);
                }) > -1
                  ? true
                  : false;

              if ((pageCode === 'P3PG1C1' && actionCode === 'P3PG1C1A3') || pageCode === 'P2S2PG1' || pageCode === 'P2S3PG1') {
                const isOrgAdmin = _.filter(roleList, { is_org_admin: 1 });
                if (isOrgAdmin.length === 0 && action === true) {
                  action = false;
                }
              }

              if (action) return false;
            });
          }

          if (users !== '' && !action) {
            const usersArr = users.split(',');
            action =
              _.findIndex(usersArr, function (o) {
                return Number(o) === Number(memComId);
              }) > -1
                ? true
                : false;
          }
        }

        if ((pageCode === 'P3PG1C1' && actionCode === 'P3PG1C1A3') || pageCode === 'P2S2PG1' || pageCode === 'P2S3PG1') {
          const isOrgAdmin = _.filter(roleList, { is_org_admin: 1 });
          if (isOrgAdmin.length === 0 && action === true) {
            action = false;
          }
        }
      }
    }

    return action;
  };

  return (
    <PageSettings.Provider
      value={{
        pageSidebar,
        pageSidebarOpen,
        pageSidebarTitle,
        pageSidebarToggled,
        handleSidebarToggled,
        setPageSidebar,
        setPageSidebarOpen,
        setPageSidebarTitle,
        handleOnSelectMenu,
        checkPermissionAction,
        memberProfile,
        comId,
        pathKey,
        team,
        setTeam,
      }}
    >
      <KeycloakProvider keycloak={keycloak} initConfig={keycloakProviderInitConfig} onEvent={onKeycloakEvent}>
        <AppProvider>{memberProfile ? <Dashboard /> : null}</AppProvider>
      </KeycloakProvider>
    </PageSettings.Provider>
  );
}

function RouteWithSubRoutes(route) {
  const { keycloak, initialized } = useKeycloak();
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setLoading(true);
  };

  if (!initialized) {
    return <Loading />;
  }

  if (!keycloak.authenticated) {
    window.location.href = '/';
  }

  return <PageSettings.Consumer>{() => <Route path={route.path} component={route.component} />}</PageSettings.Consumer>;
}
