import React from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import LabeRequireForm from '../label-required-form';
import Button_02 from '../v2/button_02';
import Button_01 from '../v2/button_01';
import { createModelData } from '../../controllers/createBrandModel';
import { errorNotification, successNotification } from '../v2/notification';
import { createItemBasePay, updateItemBasePay } from '../../controllers/base-pay/basepay';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;
const { TextArea } = Input;

const ModalItem = ({ form, title, visible, setVisible, setToggle, record, menuCode, listType,reApi }) => {
  const { getFieldDecorator, resetFields } = form;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const payload = _.get(record, 'basePayId')
        ? {
            basePay: _.get(values, 'itemName'),
            statusCode: 'active',
            noType: _.get(values, 'noType') || 'pay',
          }
        : {
            basePayName: _.get(values, 'itemName'),
            statusCode: 'active',
            noType: _.get(values, 'noType') || 'pay',
          };

      try {
        const response = _.get(record, 'basePayId')
          ? await updateItemBasePay(payload, _.get(record, 'basePayId'))
          : await createItemBasePay(payload);

        if (_.get(response, 'data.status.code') === 200) {
          successNotification(response.data.status.message);
          if (_.isFunction(reApi)) {
            reApi();
          }
          handleCancel();
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.data.message);
      }

      setToggle((event) => !event);
    });
  };

  const handleCancel = () => {
    resetFields();
    setVisible(false);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      width={520}
      centered={true}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCloseItem2" defaultMessage="Close" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="status" colon={false}>
        {menuCode !== 'basePay' ? (
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalSelectItemLabelType', defaultMessage: 'Type' })} req={true} />}
              >
                {getFieldDecorator('noType', {
                  initialValue: _.get(record, 'noType') ? _.get(record, 'noType') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalBasePayFormValidateType', defaultMessage: 'Please Select Type' }),
                    },
                  ],
                })(
                  <Select
                    className="select-list-customer-monitor"
                    placeholder={intl.formatMessage({ id: 'modalSelectItemPHRefType', defaultMessage: 'Select Type' })}
                    defaultActiveFirstOption={false}
                    allowClear={false}
                  >
                    {listType &&
                      listType.map((item) => <Option value={item.code}>{langValue === 'TH' ? item.lang.TH : item.lang.EN}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalSelectItemLabelItemName', defaultMessage: 'Item Name' })}
                  req={true}
                />
              }
            >
              {getFieldDecorator('itemName', {
                initialValue: _.get(record, 'basePayName') ? _.get(record, 'basePayName') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'modalBasePayFormValidateItemName',
                      defaultMessage: 'Please Enter Item Name',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({
                    id: 'modalSelectItemPHRefItemName',
                    defaultMessage: 'Enter Item Name',
                  })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalItem.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalItemForm = Form.create({ name: 'basepayItem_Form' })(ModalItem);

export default ModalItemForm;
