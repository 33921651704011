import React, { useEffect, useState } from 'react';
import { Form, Modal, Card, Row, Col, Select, DatePicker, Input, Collapse, Icon, Divider, AutoComplete } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import ModalTypeItemRequest from './modelview';
import { getDataModalItemRequest } from '../../controllers/approval/approval-controller';

const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
const { Search } = Input;

const MainTypeItemRequest = (props) => {
  const { visible, setVisible, form, setMainTrigger, typeModal, recordRequest,changeToApproval,changeToReject,changeTab,changeToCancel } = props;
  const intl = useIntl();
  const [basket, setBasket] = useState([]);

  useEffect(() => {
    const getAPi = async () => {
      console.log('MemRe', _.get(recordRequest, 'memReqId'));
      const response = await getDataModalItemRequest(_.get(recordRequest, 'memReqId'));
      setBasket(_.get(response, 'data[0]'));
    };

    if (visible === true) {
      getAPi();
    }
  }, [visible]);

  return (
    <ModalTypeItemRequest
      visible={visible}
      setVisible={setVisible}
      basket={basket}
      setBasket={setBasket}
      handleClickItem={() => {}}
      setMainTrigger={setMainTrigger}
      typeModal={typeModal}
      recordRequest={_.get(recordRequest, 'memReqId')}
      changeToApproval={changeToApproval}
      changeToReject={changeToReject}
      changeTab={changeTab}
      changeToCancel={changeToCancel}
    />
  );
};

export default MainTypeItemRequest;
