import React, { useState,useEffect } from 'react';
import HeaderView from '../../../components/view-quotation-component/view-header-quotation-action';
import PrintPreview from '../../../components/print-report/view-quotation';
import DetailView from '../../../components/view-quotation-component/view-data-quotation';
import CommentView from '../../../components/view-quotation-component/view-comment-quotation';
import { Card, Divider } from 'antd';
import _ from 'lodash';
import './css/index.css'
import { useIntl, FormattedMessage } from 'react-intl';
import { getQuotationById, countPrint } from '../../../controllers/quotation/quotation-api.js';


export default (props) => {
  const { data, fncQuatation, componentRef, discount, selectDiscount, triggerAPICountPost } = props;
  const { handleViewQuotationMain, handleEditQuotationMain, handleApprovedMain, handleRejectMain, hanndleDeleteMain, hanndleCancelStatusMain, handlePrintMain, setSelectDiscount, setTriggerAPICountPost } = fncQuatation;
  const intl = useIntl();
  const comInfo = _.get(data, 'comInfo');
  const quotationId = _.get(data, 'quotationId');
  const filter = discount && discount.filter(item => { return item.discountId === _.get(data, 'discountId') });

  const [countNumberPrint, setCountNumberPrint] = useState();
  // const [triggerAPICountPost, setTriggerAPICountPost] = useState(false);

  // console.log('Quotation countNumberPrint:::', countNumberPrint);

  useEffect(() => {
    if (_.get(data, 'quotationId')) {
      const countPrint = async () => {
        try {
          const response = await getQuotationById(_.get(data, 'quotationId'))
          if (response.status.code == 200) {
            setCountNumberPrint(_.get(response, 'data[0].print'));
          }
        } catch (error) {
          return
        }
      }
      countPrint();
    }
  }, [triggerAPICountPost]);

  // // useEffect(() => {
    
  // //   setSelectDiscount(_.get(filter, '[0]'))
  // // }, [quotationId])

  // console.log('dataaaaaa:::', selectDiscount);

  // console.log('Quotation discount:::', discount);
  // console.log('Quotation filter:::', filter);
  // console.log('Quotation data:::', data);

  const langConFirmCancel = {
    title: intl.formatMessage({ id: 'quotationModalDeleteConfirm', defaultMessage: 'Are you sure to cancel Quotation ?' }),
    ok: intl.formatMessage({ id: 'quotationModalCancelBtnConfirm', defaultMessage: 'Confirm' }),
    cancel: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })
  }

  const langConFirmDelete = {
    title: intl.formatMessage({ id: 'quotationModalDeleteConfirm', defaultMessage: 'Are you sure to delete Quotation ?' }),
    ok: intl.formatMessage({ id: 'quotationModalCancelBtnConfirm', defaultMessage: 'Confirm' }),
    cancel: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })
  }

  const [visiblePopover, setVisiblePopover] = useState(false);

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover)
  }

  const handleViewQuotation = async (e) => {
    const response = await getQuotationById(_.get(e, 'quotationId'))
    if (_.size(response) !== 0) {
      handleViewQuotationMain(_.get(response, 'data[0]'), _.get(response, 'comInfo'));
    }
  }

  const handleEditQuotation = async (e) => {
    const response = await getQuotationById(_.get(e, 'quotationId'));
    if (_.size(response) !== 0) {
      handleEditQuotationMain(_.get(response, 'data[0]'));
    }
  }

  const handleApproved = (status, record) => {
    handleApprovedMain(record);
  }

  const handleReject = (status, record) => {
    handleRejectMain(record);
  }

  const hanndleDelete = (status, record) => {
    hanndleDeleteMain(langConFirmDelete, record);
  }

  const hanndleCancelStatus = (status, record) => {
    hanndleCancelStatusMain(langConFirmCancel, record)
  }

  const handlePrint = async (e) => {
    const response = await getQuotationById(_.get(e, 'quotationId'))
    if (_.size(response) !== 0) {
      handlePrintMain(_.get(response, 'data[0]'), _.get(response, 'comInfo'));
    }

    const filter = discount && discount.filter(item => { return item.discountId === _.get(e, 'discountId') })
    setSelectDiscount(_.get(filter, '[0]'))
  }

  const hanldeAfterPrint = async (id) => {

    await countPrint(id);
    // console.log('hanldeAfterPrint response::: ', countPrint);

    setTriggerAPICountPost(current => !current);

    // setTimeout(async() => {
    //   try {
    //     const response = await getQuotationById(id);
    //     console.log('hanldeAfterPrint response::: ', response);
  
    //     if (response.status.code == 200) {
    //       setCountNumberPrint(_.get(response, 'data[0].print'));
    //     }
    //   } catch (error) {
    //     return
    //   }
    // }, 1000)
  }

  return (
    <div>
      <Card
        className='post-card-engage-quotation'
      >
        <div className='layout-view'>
          <div className='layout-view-header-quotation'>
            <HeaderView
              fromPage='engagement-quotation'
              data={data}
              visiblePopover={visiblePopover}
              componentRef={componentRef}
              fnc={{
                handleViewQuotation,
                handleOnVisiblePopover,
                handleEditQuotation,
                handleApproved,
                handleReject,
                hanndleDelete,
                hanndleCancelStatus,
                handlePrint,
                hanldeAfterPrint
              }}
            />
            <DetailView data={data} />
          </div>

          <div className='layout-view-print'>
            {quotationId ? <PrintPreview data={data} dataHeader={comInfo} selectDiscount={_.get(filter, '[0]')} /> : null}
          </div>

          {(data && data.statusCode === 'approve') || (data && data.statusCode === 'reject') ?
            <div className='layout-view-comment-quotation'>
              <CommentView data={data} />
            </div>
            :
            null
          }
          {(data && data.statusCode === 'approve') ? <Divider /> : null}
          {data && data.statusCode === 'approve' ?
            <div style={{ display: 'flex', alignContent: 'center', padding: '8px 0px' }}>
              <div style={{ paddingLeft: '16px' }}>
                {/* <span>Printed {data && data.print}  {data.print > 1 ? 'Copies' : 'Copy'}</span> */}
                <span>Printed {countNumberPrint}  {data.print > 1 ? 'Copies' : 'Copy'}</span>
              </div>
            </div>
            : null
          }
        </div>
      </Card>
    </div>
  )
}