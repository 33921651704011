import React, { useContext, useState } from 'react';
import './css/index.css';
import CollapseCustom from '../../../components/collapse-custom';
import Button_01 from '../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Select, Input, DatePicker, Row, Col, Menu, Dropdown, Icon, AutoComplete } from 'antd';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import ActionColumnMovement from './action-column';
import ButtonActionGroup from '../../../components/action-button';
import { PageSettings } from '../../../config/page-settings';

const { Option } = Select;
const { RangePicker } = DatePicker;
const InputGroup = Input.Group;

const WarehouseMovementAction = (props) => {
  const {
    setSearchGroup,
    setPaginationPage,
    searchGroup,
    columns,
    setShowColumn,
    newDataColumns,
    listArrayColumns,
    defaultShowColumn,
    newColumns,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    textErrorSelectColumn,
    setTextErrorSelectColumn,
    visible,
    setVisible,
    handleOpenModal,
    fncForm,
    roleCheck,
    handleExport,
  } = props;
  const intl = useIntl();
  const [visibleAction, setVisibleAction] = useState(false);
  const [changename, setChangename] = useState(false);
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;

  const onChange = (value, code) => {
    if (code === 'itemCode') {
      const filterArr = fncForm.itemCodeList.filter((item) => item.itemMasterId === value);
      fncForm.setSelectItem(_.get(filterArr, '[0]'));
    } else if (code === 'itemName') {
      const filterArr = fncForm.itemNameList.filter((item) => item.itemMasterId === value);
      fncForm.setSelectItem(_.get(filterArr, '[0]'));
    }
    setSearchGroup({ ...searchGroup, [code]: value });
    setChangename(false);
    setPaginationPage(1);
    console.log('ItemCodeMoment', _.get(fncForm, 'selectItem.itemCode'));
  };

  const onChangeForm = (value, code) => {
    console.log('onChangeForm', value);
    if (value === undefined) {
      setChangename(true);
      fncForm.setSelectItem(_.get(value, '[0]'));
      setSearchGroup({ ...searchGroup, [code]: value });
    }
    else{
      fncForm.setSelectItem((prev) =>({...prev, [code]: value}));
    }
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="warehouseMovementFrom">
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseMovementItemCodeText', defaultMessage: 'Item Code' })}
                  req={false}
                />
              }
            >
              <AutoComplete
                allowClear={true}
                onSearch={fncForm.handleSearchCode}
                onChange={(value) => onChangeForm(value, 'itemCode')}
                onSelect={(value) => onChange(value, 'itemCode')}
                dataSource={fncForm.itemCodeList}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(searchGroup, 'itemCode')}
                placeholder={intl.formatMessage({ id: 'warehouseMovementItemCodePlaceholder', defaultMessage: 'Enter Item Code' })}
              >
                {fncForm.itemCodeList &&
                  fncForm.itemCodeList.map((item) => (
                    <Option key={item.itemCode} value={item.itemMasterId}>
                      {item.itemCode}
                    </Option>
                  ))}
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseMovementItemNameText', defaultMessage: 'Item Name' })}
                  req={false}
                />
              }
            >
              <AutoComplete
                allowClear={true}
                onSearch={fncForm.handleSearchName}
                onSelect={(value) => onChange(value, 'itemName')}
                onChange={(value) => onChangeForm(value, 'itemName')}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                dataSource={fncForm.itemNameList}
                value={_.get(fncForm, 'selectItem.itemName')}
                placeholder={intl.formatMessage({ id: 'warehouseMovementItemNamePlaceholder', defaultMessage: 'Enter Item Name' })}
              >
                {fncForm.itemNameList &&
                  fncForm.itemNameList.map((item, index) => (
                    <Option key={item.itemCode} value={item.itemMasterId}>
                      {item.itemName}
                    </Option>
                  ))}
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'warehouseMovementTypeText', defaultMessage: 'Type' })} req={false} />}
            >
              <Select
                allowClear
                value={_.get(searchGroup, 'type')}
                
                onChange={(value) => onChange(value, 'type')}
                placeholder={intl.formatMessage({ id: 'warehouseMovementTypePlaceholder', defaultMessage: 'Select Type' })}
              >
                {fncForm.movementType &&
                  fncForm.movementType.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseMovementExpiredDateText', defaultMessage: 'Expired Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                style={{ width: '100%' }}
                allowClear
                value={_.get(searchGroup, 'expiredDate')}
                onChange={(value) => onChange(value, 'expiredDate')}
                placeholder={[
                  intl.formatMessage({ id: 'warehouseMovementExpiredDateFrom', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'warehouseMovementExpiredDateTo', defaultMessage: 'Select To Date' }),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseMovementLotNoText', defaultMessage: 'Lot No. Serial No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'lotNo')}
                onChange={(e) => onChange(e.target.value, 'lotNo')}
                placeholder={intl.formatMessage({
                  id: 'warehouseMovementLotNoPlaceholder',
                  defaultMessage: 'Select Lot No. or Serial No.',
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseMovementReferenceType', defaultMessage: 'Reference Type' })}
                  req={false}
                />
              }
            >
              {/* <Input
                allowClear
                value={_.get(searchGroup, 'referenceType')}
                onChange={(e) => onChange(e.target.value, 'referenceType')}
                placeholder={intl.formatMessage({ id: 'warehouseMovementReferenceTypePlaceholder', defaultMessage: 'Enter Reference Type' })}
              /> */}
              <Select
                allowClear={true}
                onChange={(value) => onChange(value, 'referenceType')}
                filterOption={false}
                value={_.get(searchGroup, 'referenceType')}
                // value={ changename === false ? _.get(fncForm, 'selectItem.referenceType') : undefined}
                placeholder={intl.formatMessage({
                  id: 'warehouseMovementReferenceTypePlaceholder',
                  defaultMessage: 'Select Reference Type',
                })}
              >
                {fncForm.refTypeList &&
                  fncForm.refTypeList.map((item, index) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseMovementReferenceNo', defaultMessage: 'Reference No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'referenceNo')}
                onChange={(e) => onChange(e.target.value, 'referenceNo')}
                placeholder={intl.formatMessage({ id: 'warehouseMovementReferenceNoPlaceholder', defaultMessage: 'Enter Reference No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'warehouseMovementCostText', defaultMessage: 'Cost' })} req={false} />}
            >
              <InputGroup compact>
                <Row>
                  <Col span={11}>
                    <Input
                      className="warehouseMovementInput1"
                      style={{ textAlign: 'center' }}
                      allowClear
                      value={_.get(searchGroup, 'minimum')}
                      onChange={(e) => onChange(e.target.value, 'minimum')}
                      placeholder={intl.formatMessage({ id: 'warehouseMovementCostMinimum', defaultMessage: 'Enter Minimum' })}
                    />
                  </Col>
                  <Col span={2}>
                    <Input
                      className="warehouseMovementInputComma"
                      style={{
                        pointerEvents: 'none',
                        backgroundColor: '#fff',
                      }}
                      placeholder="~"
                      disabled
                    />
                  </Col>
                  <Col span={11}>
                    <Input
                      className="warehouseMovementInput2"
                      style={{ textAlign: 'center' }}
                      allowClear
                      value={_.get(searchGroup, 'maximum')}
                      onChange={(e) => onChange(e.target.value, 'maximum')}
                      placeholder={intl.formatMessage({ id: 'warehouseMovementCostMaximum', defaultMessage: 'Enter Maximum' })}
                    />
                  </Col>
                </Row>
              </InputGroup>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseMovementMovementDateText', defaultMessage: 'Movement Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                style={{ width: '100%' }}
                allowClear
                value={_.get(searchGroup, 'movementDate')}
                onChange={(value) => onChange(value, 'movementDate')}
                placeholder={[
                  intl.formatMessage({ id: 'warehouseMovementMovementDateFrom', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'warehouseMovementMovementDateTo', defaultMessage: 'Select To Date' }),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const handleVisibleChange = (flag) => {
    setVisibleAction(flag);
  };

  const handleOnVisiblecolumns = () => {
    setVisibleAction(!false);
  };

  const menuColumn = () => {
    return (
      <Menu className="menu-columns">
        {checkPermissionAction(`P52PG1C6`, `P52PG1C6A2`) ? (
          <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }} onClick={() => handleOpenModal('item')}>
            <FormattedMessage id="btnRecive" defaultMessage="Receive Item" />
          </Menu.Item>
        ) : null}
        {/* <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }} onClick={() => handleOpenModal('po')}>
          <FormattedMessage id="btnRecivePO" defaultMessage="Receive PO" />
        </Menu.Item> */}
        {checkPermissionAction(`P52PG1C6`, `P52PG1C6A3`) ? (
          <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }} onClick={() => handleExport()}>
            <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
          </Menu.Item>
        ) : null}
      </Menu>
    );
  };

  return (
    <div>
      <div>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <div style={{ display: 'flex' }}>
              <ActionColumnMovement
                columns={columns}
                setShowColumn={setShowColumn}
                newDataColumns={newDataColumns}
                listArrayColumns={listArrayColumns}
                defaultShowColumn={defaultShowColumn}
                newColumns={newColumns}
                setNewDataColumns={setNewDataColumns}
                setListArrayColumns={setListArrayColumns}
                setDefaultShowColumn={setDefaultShowColumn}
                textErrorSelectColumn={textErrorSelectColumn}
                setTextErrorSelectColumn={setTextErrorSelectColumn}
                visible={visible}
                setVisible={setVisible}
                columnMinimum={2}
              />
              {checkPermissionAction('P52PG1C6', 'P52PG1C6A2') || checkPermissionAction(`P52PG1C6`, `P52PG1C6A3`)  ? (
                roleCheck ? (
                  <ButtonActionGroup menu={menuColumn} />
                ) : null
              ) : null}
            </div>
          }
        >
          {formFilter()}
        </CollapseCustom>
      </div>
      <div></div>
    </div>
  );
};

export default WarehouseMovementAction;
