import React, { useState, useEffect } from 'react';
import ItemMasterTable from './item-master-table';
import ActionReportV1 from '../../components/action-report/action-report-v1';
import getItemMaster from '../../controllers/item-master/get-item-master';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon, Tag, Popover, Popconfirm, Row, Modal, Button } from 'antd';
import _, { debounce } from 'lodash';
import moment from 'moment';
import { successNotification, errorNotification, warningNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import Button01 from '../../components/v2/button_01.js';
import httpClient from '../../components/axiosClient';
import './css/index.css';
import ModalItem from '../../components/modal-item'

export default () => {

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      key: 'brandName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Model',
      dataIndex: 'modelName',
      key: 'modelName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Item Code',
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Item No',
      dataIndex: 'itemNo',
      key: 'itemNo',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Reference 1',
      dataIndex: 'reference1',
      key: 'reference1',
    },
    {
      title: 'Reference 2',
      dataIndex: 'reference2',
      key: 'reference2',
    },
    {
      title: 'Reference 3',
      dataIndex: 'reference3',
      key: 'reference3',
    },
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   key: 'action',
    //   align: 'center',
    //   width: 70,
    //   // fixed: 'right',
    //   render: (text, record, index) => fncActionTable(record, index)
    // },
  ];

  const setShowColumn = {
    index: true,
    brandName: true,
    modelName: true,
    itemCode: true,
    itemNo: true,
    itemName: true,
    unitPrice: true,
    currency: true,
    unit: true,
    description: true,
    reference1: true,
    reference2: true,
    reference3: true,
    action: true,
  };

  const setShowColumnArr = [
    'index',
    'brandName',
    'modelName',
    'itemCode',
    'itemNo',
    'itemName',
    'unitPrice',
    'currency',
    'unit',
    'description',
    'reference1',
    'reference2',
    'reference3',
    'action',
  ];

  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreFilterColumns = ['index', 'referenceDocNo', 'itemCode', 'itemNo', 'itemName', 'action'];
  const ignoreSearchColumns = ['index', 'action', 'unitPrice', 'currency', 'unit', 'description', 'reference1', 'reference2', 'reference3'];

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState(false);

  const [itemMasterData, setItemMasterData] = useState([]);
  const [defalseMaintenData, setDefalseMaintenData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [fieldChang, setFieldChang] = useState('');
  const [fliterByColumn, setFliterByColumn] = useState('all');

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [visible, setVisible] = useState(false);

  // const [startDate, setStartDate] = useState(moment(today).format("YYYY-MM-DD"));
  // const [endDate, setEndDate] = useState(moment(today).format("YYYY-MM-DD"));
  const [dateTrigger, setDateTrigger] = useState('');

  const [triggerAPI, setTriggerAPI] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function getAPI() {
      setLoading(true);
      const response = await getItemMaster({ fieldChang, fliterByColumn, fieldSort, orderSort, paginationPage, paginationSize });

      await setItemMasterData(response.data);
      await setTotal(response.allRows);
      await setLoading(false);
    }
    getAPI();
  }, [dateTrigger, triggerAPI, fieldChang, fliterByColumn, extraSort]);

  const onChangeSearch = debounce((value) => {
    setFieldChang(value);
  }, 500);

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  }

  const handleOpenModal = async () => {
    setVisibleModal(true);
  }

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  }

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  }

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];

    if (listArrayColumns.length < 7) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find(
        (element) => element == columns[i].key,
      );
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className="maintenance-report">
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {checkPermissionAction('P13PG1C1', 'P13PG1C1A1') ? (
              <div>
                <Card
                  className="maintenance-report-card"
                  title="Item Master"
                  extra={
                    <>
                      {
                        checkPermissionAction('P13PG1C1', 'P13PG1C1A2') ? (
                          <Button
                            className="maintenance-card-extra"
                            type="link"
                            onClick={() => handleOpenModal()}
                          >
                            <FormattedMessage id="itemMasterReportCreateItemMaster" defaultMessage="Create Item Master" />
                          </Button>
                        ) : null
                      }
                    </>
                  }
                >
                  <div className="action-maintenance-container">
                    <ActionReportV1
                      value={{
                        columns,
                        ignoreFilterColumns,
                        ignoreSearchColumns,
                        fliterByColumn,
                        visible,
                        defaultShowColumn,
                        textErrorSelectColumn,
                      }}
                      fnc={{
                        onChangeSearch,
                        onFliterByColumn,
                        checkedValuecolumns,
                        handleCheckOk,
                        handleReset,
                        handleVisibleChange,
                        handleOnVisiblecolumns,
                      }}
                    />
                  </div>

                  <ItemMasterTable
                    loading={loading}
                    dataSource={itemMasterData}
                    columns={newDataColumns}
                    total={total}
                    currentPage={currentPage}
                    paginationPage={paginationPage}
                    fnc={{
                      handleChange,
                      handlePagination,
                      handleSizeChange,
                    }}
                  />

                </Card>
              </div>
            ) : null}

            <ModalItem
              intl={intl}
              visible={visibleModal}
              setVisible={setVisibleModal}
            />

          </div>
        )}
      </PageSettings.Consumer>
    </div>
  )
}