import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import './index.css';
import ListItemFilter from './filter';
import ListItemDetail from './item';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../v2/button_01';
import _ from 'lodash';
import { getWareHouseList } from '../../../controllers/warehouse/warehouse';
import { getBrandData, getModelData, getListItem, getListItemNew } from '../../../controllers/po/po';
import { useDebounce } from '../../../controllers/debounce';
import { getItemsCode, getItemsMaster } from '../../../controllers/warehouse/warehouse';
import ItemMasterMainModal from '../../create-edit-item-master/modal-main';

const ListItem = (props) => {
  const { visible, setVisible, setVisibleAdd, setCreateData, vendorData, form } = props;
  const { getFieldValue } = form;
  const intl = useIntl();
  const [searchGroup, setSearchGroup] = useState([]);

  const [itemList, setItemList] = useState([]);
  const [listPage, setListPage] = useState(1);
  const [listSize, setListSize] = useState(10);
  const [itemListTotal, setItemListTotal] = useState(0);

  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseCheckAll, setWarehouseCheckAll] = useState(false);
  const [warehouseInterminate, setWarehouseInterminate] = useState(false);
  const [warehouseSelect, setWarehouseSelect] = useState([]);
  const [panelWarehouse, setPanelWarehouse] = useState(false);

  const [stockData, setStockData] = useState([]);
  const [stockCheckAll, setStockCheckAll] = useState(false);
  const [stockInterminate, setStockInterminate] = useState(false);
  const [stockSelect, setStockSelect] = useState([]);
  const [panelStock, setPanelStock] = useState(false);

  const [optionsBrand, setOptionsBrand] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [brandSearch, setBrandSearch] = useState();
  const debounceBrandSearch = useDebounce(brandSearch, 500);
  const [optionsBrandTemp, setOptionsBrandTemp] = useState([]);

  const [optionsModel, setOptionsModel] = useState([]);
  const [loadingModel, setLoadingModel] = useState(false);
  const [modelSearch, setModelSearch] = useState();
  const debounceModelSearch = useDebounce(modelSearch, 500);

  //---------------------------
  const [visibleModalItem, setVisibleModalItem] = useState(false);
  //---------------------------
  const [event, setEvent] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsUnit', defaultMessage: 'Unit' }),
      dataIndex: 'unit',
      key: 'unit',
    },
    // {
    //   title: intl.formatMessage({ id: 'modalListColumnsStockBalance', defaultMessage: 'Stock Balance' }),
    //   dataIndex: 'stockBalance',
    //   key: 'stockBalance',
    // },
  ];

  useEffect(() => {
    if (getFieldValue('vendor') && visible) {
      setSearchGroup({ ...searchGroup, ['vendor']: getFieldValue('vendor') });
    }
  }, [getFieldValue('vendor'), visible]);

  console.log('vendor', getFieldValue('vendor'));

  useEffect(() => {
    async function GetWarehouse() {
      try {
        const payload = {
          indexStart: 0,
          indexEnd: 100,
          searchLeftMenu: '',
          isNotCheckPermission: true,
        };
        const response = await getWareHouseList(payload);
        setWarehouseData(_.get(response, 'data.data.warehouseList'));
      } catch (error) { }
    }
    GetWarehouse();
  }, []);

  useEffect(() => {
    if (visible) {
      GetSearchListData();
    }
  }, [visible, listSize, listPage, _.get(searchGroup, 'vendor'), _.get(searchGroup, 'purchase'), event]);

  const GetSearchListData = async () => {
    const body = {
      itemBrandId: _.get(searchGroup, 'brand') ? _.get(searchGroup, 'brand') : [],
      itemModelId: _.get(searchGroup, 'model') ? _.get(searchGroup, 'model') : [],
      itemCode: _.get(searchGroup, 'itemCode') ? _.get(searchGroup, 'itemCode') : '',
      itemName: _.get(searchGroup, 'itemName') ? _.get(searchGroup, 'itemName') : '',
      limit: listSize,
      page: listPage,
      customerId: _.get(searchGroup, 'vendor') || '',
      recently: _.get(searchGroup, 'purchase') || false,
      recentlyModule: 'PO',
      categoryCode: "product"
    };
    const response = await getListItemNew(body);
    setItemList(_.get(response, 'data.data.itemList'));
    setItemListTotal(_.get(response, 'data.data.totalItem'));
  };

  const handlePagination = (page) => {
    console.log('PageList', page);
    setListPage(_.get(page, 'current'));
  };

  const handleSizeChange = (current, size) => {
    setListPage(current);
    setListSize(size);
  };

  const handleClose = () => {
    setWarehouseSelect([]);
    setWarehouseCheckAll(false);
    setItemList([]);
    setItemListTotal(0);
    setListPage(1);
    setListSize(10);
    setVisible(false);
    setSearchGroup([]);
  };

  const handleClickRow = (data) => {
    setCreateData(data);
    setVisibleAdd(true);
  };

  useEffect(() => {
    const fetcBrand = async () => {
      setLoadingBrand(true);
      const body = {
        indexStart: 0,
        indexEnd: 10,
        item_brand_name: brandSearch || '',
      };
      const response = await getBrandData(body);
      setOptionsBrand(_.get(response, 'data.data.list'));
      // setBrandSearch
      setLoadingBrand(false);
    };
    fetcBrand(); // โหลดข้อมูลเริ่มต้น
  }, [debounceBrandSearch]);

  useEffect(() => {
    const fetcModel = async () => {
      setLoadingModel(true);
      const body = {
        indexStart: 0,
        indexEnd: 10,
        item_model_name: modelSearch || '',
        item_brand_id: _.get(searchGroup, 'brand'),
      };
      const response = await getModelData(body);
      setOptionsModel(_.get(response, 'data.data.list'));
      setLoadingModel(false);
    };
    if (_.get(searchGroup, 'brand')) {
      fetcModel(); // โหลดข้อมูลเริ่มต้น
    }
  }, [debounceModelSearch, searchGroup]);

  const onChange = async (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    // if (code === 'vendor' && handleChangeCustomer) {
    //   handleChangeCustomer(value, true)
    // }
  };

  const handleClick = async () => {
    GetSearchListData();
    setListPage(1);
  };

  const onClickVisibleItemMaster = async () => {
    setVisibleModalItem(true);
  };

  const filterDataNewItemMaster = async (value) => {
    console.log('ResReturn', value);
    setSearchGroup({
      ...searchGroup,
      brand: [_.get(value, 'item_brand_id')],
      model: _.get(value, 'item_model_id') === null ? undefined : [_.get(value, 'item_model_id')],
      itemCode: _.get(value, 'item_code'),
      itemName: _.get(value, 'item_name'),
    });

    const filterBrand = _.filter(optionsBrand, (t) => {
      return t.item_brand_id === _.get(value, 'item_brand_id')
    })

    const filterModel = _.filter(optionsModel, (t) => {
      return t.item_model_id === _.get(value, 'item_model_id')
    })

    console.log('filterBrand', filterBrand, '/value:', value);

    if (_.size(filterBrand) <= 0) {
      const temp = _.concat(optionsBrand, { item_brand_id: _.get(value, 'item_brand_id'), item_brand_name: _.get(value, 'item_brand_name') });
      const tempuniq = _.uniqBy(temp, 'item_brand_id');
      const tempmodel = _.concat(optionsModel, { item_model_id: _.get(value, 'item_model_id'), item_model_name: _.get(value, 'item_model_name') });
      const tempuniqModel = _.uniqBy(tempmodel, 'item_model_id');
      setOptionsBrand(tempuniq);
      if (_.get(value, 'item_model_id') === null) {
        setOptionsModel(optionsModel)
      }
      else {
        setOptionsModel(tempuniqModel)
      }

    }
    else {

      if (_.size(filterBrand) <= 0) {
        const tempmodel = _.concat(optionsModel, { item_model_id: _.get(value, 'item_model_id'), item_model_name: _.get(value, 'item_model_name') });
        const tempuniqModel = _.uniqBy(tempmodel, 'item_model_id');
        if (_.get(value, 'item_model_id') === null) {
          setOptionsModel(optionsModel)
        }
        else {
          setOptionsModel(tempuniqModel)
        }
      }
    }



    setEvent((prev) => !prev);
  };

  // const searchBrandData = async (value) => {
  //   if (condition) {

  //   }
  //   setBrandSearch();
  // };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemTitle`, defaultMessage: 'Add Item' })}
      visible={visible}
      onCancel={handleClose}
      centered={true}
      width={1112}
      bodyStyle={{ padding: 'unset', height: '680px' }}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className="listItemTextHeader">
            <FormattedMessage id="btnFilter" defaultMessage="Filter" />
          </div>
          <div className="listItemDividerCenter">
            <ListItemFilter
              onChange={onChange}
              searchGroup={searchGroup}
              onClick={handleClick}
              warehouse={{
                warehouseData,
                warehouseCheckAll,
                setWarehouseCheckAll,
                warehouseInterminate,
                setWarehouseInterminate,
                warehouseSelect,
                setWarehouseSelect,
                panelWarehouse,
                setPanelWarehouse,
                setListPage,
              }}
              stock={{
                stockData,
                stockCheckAll,
                setStockCheckAll,
                stockInterminate,
                setStockInterminate,
                stockSelect,
                setStockSelect,
                panelStock,
                setPanelStock,
              }}
              brand={{
                optionsBrand,
                loadingBrand,
                brandSearch,
                setBrandSearch,
              }}
              model={{
                optionsModel,
                loadingModel,
                modelSearch,
                setModelSearch,
              }}
            />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader">
            <FormattedMessage id="modalListItemItemText" defaultMessage="Item" />
          </div>
          <div>
            <ListItemDetail
              onChange={onChange}
              columns={columns}
              handleClickRow={handleClickRow}
              vendorData={vendorData}
              itemList={itemList}
              itemListTotal={itemListTotal}
              handlePagination={handlePagination}
              handleSizeChange={handleSizeChange}
              form={form}
              listPage={listPage}
              onClickVisibleItemMaster={onClickVisibleItemMaster}
            />
          </div>
        </Col>
      </Row>
      <ItemMasterMainModal
        visibleModal={visibleModalItem}
        setVisibleModal={setVisibleModalItem}
        typeModal={'po'}
        filterDataNewItemMaster={filterDataNewItemMaster}
      />
    </Modal>
  );
};

export default ListItem;
