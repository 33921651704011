import React from 'react';
import { FormattedMessage } from 'react-intl';

const menus = [
  {
    path: '/main/dashboard',
    title: <FormattedMessage id="menuDashboard" defaultMessage="Dashbord" />,
    menuCode: 'dashboard',
    link: false,
  },
  {
    path: '/main/resource',
    title: <FormattedMessage id="menuResource" defaultMessage="Resource" />,
    link: false,
    children: [
      {
        path: '/main/resource/organization',
        title: <FormattedMessage id="menuOrganization" defaultMessage="Organization" />,
        menuCode: 'organization',
        link: true,
        children: [
          {
            path: '/main/resource/organization/users-detail',
            title: <FormattedMessage id="menuUserDetail" defaultMessage="Users Detail" />,
            link: false,
          },
        ],
      },
      {
        path: '/main/resource/allowance',
        title: <FormattedMessage id="menuResourceAllowance" defaultMessage="Allowance" />,
        menuCode: 'organization',
        link: true,
        children: []
      },
      {
        path: '/main/resource/member-expense',
        title: <FormattedMessage id="menuMemberExpense" defaultMessage="Member Expense" />,
        menuCode: 'organization',
        link: true,
        children: []
      },
      {
        path: '/main/resource/asset',
        title: <FormattedMessage id="menuAsset" defaultMessage="Asset Summary Report" />,
        menuCode: 'asset',
        link: true,
        children: [
          {
            path: '/main/resource/asset/asset-detail',
            title: <FormattedMessage id="menuAssetDetail" defaultMessage="Asset Detail" />,
            link: false,
          },
        ],
      },
      {
        path: '/main/resource/fine',
        title: <FormattedMessage id="menuFine" defaultMessage="Fine" />,
        menuCode: 'fine',
        link: true,
      },
      {
        path: '/main/resource/report',
        title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
        link: false,
        children: [
          {
            path: '/main/resource/report/time-attendance',
            title: <FormattedMessage id="menuTimeAttendance" defaultMessage="Time Attendance" />,
            menuCode: 'time-attendance',
            link: false,
          },
          {
            path: '/main/resource/report/overtime',
            title: <FormattedMessage id="menuOverTimeReport" defaultMessage="Overtime Report" />,
            menuCode: 'overt-time',
            link: false,
          },
          {
            path: '/main/resource/report/leave-report',
            title: <FormattedMessage id="menuLeaveReport" defaultMessage="Leave Report" />,
            menuCode: 'leave',
            link: false,
          },
          {
            path: '/main/resource/report/outside',
            title: <FormattedMessage id="menuOutSide" defaultMessage="Outside Report" />,
            menuCode: 'outside',
            link: false,
          },
          {
            path: '/main/resource/report/asset-expense-report',
            title: <FormattedMessage id="menuAssetExpenseReport" defaultMessage="Asset Expense Report" />,
            menuCode: 'asset-expense-report',
            link: false,
          },
          {
            path: '/main/resource/report/borrow',
            title: <FormattedMessage id="menuBorrow" defaultMessage="Borrow Request" />,
            menuCode: 'equipment',
            link: false,
          },
          {
            path: '/main/resource/report/benefit-deduct',
            title: <FormattedMessage id="reportBenefitReportTitle" defaultMessage="Benefit & Deduct Report" />,
            menuCode: 'benefit-deduct-report',
            link: false,
          },
        ],
      },
      {
        path: '/main/resource/setting',
        title: <FormattedMessage id="menuSetting" defaultMessage="Setting" />,
        link: false,
        children: [
          {
            path: '/main/resource/setting/request-type',
            title: <FormattedMessage id="menuRequestType" defaultMessage="Request Type" />,
            menuCode: 'request-type',
            link: false,
          },
          {
            path: '/main/resource/setting/shift-type',
            title: <FormattedMessage id="menuShiftType" defaultMessage="Shift Type" />,
            menuCode: 'shift-type',
            link: false,
          },
          {
            path: '/main/resource/setting/holidays',
            title: <FormattedMessage id="menuHolidays" defaultMessage="Holidays" />,
            menuCode: 'holidays',
            link: false,
          },
          {
            path: '/main/resource/setting/skill',
            title: <FormattedMessage id="menuSkill" defaultMessage="Skill" />,
            menuCode: 'skill',
            link: false,
          },
          {
            path: '/main/resource/setting/service-area',
            title: <FormattedMessage id="menuServiceArea" defaultMessage="Service Area" />,
            menuCode: 'service-area',
            link: false,
          },
        ],
      },
    ],
  },
  {
    path: '/main/crm',
    title: <FormattedMessage id="menuCRM" defaultMessage="CRM" />,
    link: false,
    children: [
      {
        path: '/main/crm/customers',
        title: <FormattedMessage id="menuCustomer" defaultMessage="Customer" />,
        menuCode: 'crm-customer',
        link: true,
        children: [
          {
            path: '/main/crm/customers/customer-detail/',
            title: <FormattedMessage id="menuCustomerDetail" defaultMessage="Customer Detail" />,
            link: false,
          },
        ],
      },
      // {
      //   path: '/main/crm/sale-order',
      //   title: <FormattedMessage id="menuSaleOrder" defaultMessage="Sale Order" />,
      //   menuCode: 'crm-sale-order',
      //   link: false,
      // },
      {
        path: '/main/crm/engagement',
        title: <FormattedMessage id="menuEngagement" defaultMessage="Engagement" />,
        menuCode: 'crm-engagement',
        link: false,
      },
      // {
      //   path: '/main/crm/quotation',
      //   title: <FormattedMessage id="menuQuotation" defaultMessage="Quotation" />,
      //   menuCode: 'crm-quotation',
      //   link: false,
      // },
      // {
      //   path: '/main/crm/invoice',
      //   title: <FormattedMessage id="menuInvoice" defaultMessage="Invoice" />,
      //   menuCode: 'accounting-invoice',
      //   link: false,
      // },
      // {
      //   path: '/main/crm/report',
      //   title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
      //   link: false,
      //   children: [
      //     {
      //       path: '/main/crm/report/item-sales',
      //       title: <FormattedMessage id="menuItemSaleReport" defaultMessage="Item Sales Report" />,
      //       menuCode: 'item-sale-report',
      //       link: false,
      //     },
      //     {
      //       path: '/main/crm/report/sale-order-report',
      //       title: <FormattedMessage id="menuSaleOrderReport" defaultMessage="Sale Orders Report" />,
      //       menuCode: 'sale-order-report',
      //       link: false,
      //     },
      //   ],
      // },
      {
        path: '/main/crm/allorder',
        title: <FormattedMessage id="menuOrderReport" defaultMessage="All Order" />,
        menuCode: 'order',
        link: false,
      },
    ],
  },
  {
    path: '/main/sales',
    title: <FormattedMessage id="menuSalse" defaultMessage="Sales" />,
    link: false,
    children: [
      {
        path: '/main/sales/store',
        title: <FormattedMessage id="menuTransportationOrder" defaultMessage="Store" />,
        menuCode: 'transportation-order',
        link: false,
      },
      {
        path: '/main/sales/sale-order',
        title: <FormattedMessage id="menuSaleOrder" defaultMessage="Sale Order" />,
        menuCode: 'crm-sale-order',
        link: false,
      },
      {
        path: '/main/sales/quotation',
        title: <FormattedMessage id="menuQuotation" defaultMessage="Quotation" />,
        menuCode: 'crm-quotation',
        link: false,
      },
      {
        path: '/main/sales/invoice',
        title: <FormattedMessage id="menuInvoice" defaultMessage="Invoice" />,
        menuCode: 'accounting-invoice',
        link: false,
      },
      {
        path: '/main/sales/report',
        title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
        link: false,
        children: [
          {
            path: '/main/sales/report/item-sales',
            title: <FormattedMessage id="menuItemSaleReport" defaultMessage="Item Sales Report" />,
            menuCode: 'item-sale-report',
            link: false,
          },
          {
            path: '/main/sales/report/sale-order-report',
            title: <FormattedMessage id="menuSaleOrderReport" defaultMessage="Sale Orders Report" />,
            menuCode: 'sale-order-report',
            link: false,
          },
        ],
      },
    ],
  },
  {
    path: '/main/transporation',
    title: <FormattedMessage id="menuTransporation" defaultMessage="Transporation" />,
    link: false,
    children: [
      {
        path: '/main/transporation/monitor',
        title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
        menuCode: 'transportation-monitor',
        link: false,
      },
      {
        path: '/main/transporation/travel-expenses',
        title: <FormattedMessage id="menuTravelExpenses" defaultMessage="Travel Expense" />,
        menuCode: 'transportation-planning',
        link: false,
      },
      {
        path: '/main/transporation/planning',
        title: <FormattedMessage id="menuPlaning" defaultMessage="Load and Route Planning" />,
        menuCode: 'travel-expenses',
        link: false,
      },
      {
        path: '/main/transporation/delivery-orders',
        title: <FormattedMessage id="menuDeliveryOrderList" defaultMessage="Delivery Orders" />,
        menuCode: 'delivery-order-list',
        link: false,
      },
      {
        path: '/main/transporation/report',
        title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
        link: false,
        children: [
          {
            path: '/main/transporation/report/resourc-task-summary',
            title: <FormattedMessage id="menuResourceTask" defaultMessage="Resource Task Summary" />,
            menuCode: 'resourcetasksummary',
            link: false,
          },
          {
            path: '/main/transporation/report/driver-task-summary',
            title: <FormattedMessage id="menuDriverTaskSummary" defaultMessage="Driver Expenses Report" />,
            menuCode: 'driver-task-summary',
            link: false,
          },
          {
            path: '/main/transporation/report/task',
            title: <FormattedMessage id="reportTaskReportViewTitle" defaultMessage="Task Report" />,
            menuCode: 'task-report',
            link: false,
          },
          {
            path: '/main/transporation/report/gas-expense',
            title: <FormattedMessage id="menuGasExpenseReport" defaultMessage="Gas Expense" />,
            menuCode: 'gas-expense-report',
            link: false,
          },
          {
            path: '/main/transporation/report/task-assignment',
            title: <FormattedMessage id="menuTaskAssignmentReport" defaultMessage="Task Assigment Report" />,
            menuCode: 'task-assignment-report',
            link: false,
          },
        ],
      },
      {
        path: '/main/transporation/setting',
        title: <FormattedMessage id="menuSetting" defaultMessage="Setting" />,
        link: false,
        children: [
          {
            path: '/main/transporation/setting/task-type',
            title: <FormattedMessage id="menuTaskType" defaultMessage="Task Type" />,
            menuCode: 'task-type',
            link: false,
          },
          {
            path: '/main/transporation/setting/task-distance',
            title: <FormattedMessage id="menuTaskDistance" defaultMessage="Task Distance" />,
            menuCode: 'task-distance',
            link: false,
          },
          // {
          //   path: '/main/transporation/setting/allowance',
          //   title: <FormattedMessage id="menuAllowance" defaultMessage="Allowance" />,
          //   menuCode: 'allowance',
          //   link: false,
          // },
          {
            path: '/main/transporation/setting/gasprice',
            title: <FormattedMessage id="menuGasprice" defaultMessage="Gas Price" />,
            menuCode: 'gas-price',
            link: false,
          },
          {
            path: '/main/transporation/setting/size',
            title: <FormattedMessage id="menuParcelSize" defaultMessage="Size" />,
            menuCode: 'parcel-size',
            link: false,
          },

          {
            path: '/main/transporation/setting/freight',
            title: <FormattedMessage id="menuGasPriceSetting" defaultMessage="Freight" />,
            menuCode: 'gas-price-setting',
            link: false,
          },
          {
            path: '/main/transporation/setting/trip-allowance',
            title: <FormattedMessage id="menuTripAllowanceSetting" defaultMessage="Trip Allowance" />,
            menuCode: 'trip-allowance-setting',
            link: false,
          },
          {
            path: '/main/transporation/setting/allowance',
            title: <FormattedMessage id="menuAllowanceSetting" defaultMessage="Allowance" />,
            menuCode: 'allowance-setting',
            link: false,
          },
        ],
      },
      {
        path: '/main/transporation/maintenace',
        title: <FormattedMessage id="menuAssetMaintenance" defaultMessage="Asset Maintenance" />,
        menuCode: 'transportation-asset-maintenance',
        link: false,
      },
    ],
  },
  {
    path: '/main/workforce',
    title: <FormattedMessage id="menuWorkforce" defaultMessage="Workforce" />,
    link: false,
    children: [
      {
        path: '/main/workforce/monitor',
        title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
        menuCode: 'workforce-monitor',
        link: false,
      },
      {
        path: '/main/workforce/travel-expenses',
        title: <FormattedMessage id="menuTravelExpenses" defaultMessage="Travel Expense" />,
        menuCode: 'travel-expenses',
        link: false,
      },
      {
        path: '/main/workforce/report',
        title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
        link: false,
        children: [
          {
            path: '/main/workforce/report/resource-task-summary',
            title: <FormattedMessage id="menuResourceTask" defaultMessage="Resource Task Summary" />,
            link: false,
          },
          {
            path: '/main/workforce/report/task',
            title: <FormattedMessage id="reportTaskReportViewTitle" defaultMessage="Task Report" />,
            menuCode: 'workforce-task-report',
            link: false,
          },
          {
            path: '/main/workforce/report/task-assignment',
            title: <FormattedMessage id="menuTaskAssignmentReportW" defaultMessage="Task Assigment Report" />,
            menuCode: 'task-assignment-report-w',
            link: false,
          },
        ],
      },
      {
        path: '/main/workforce/setting',
        title: <FormattedMessage id="menuSetting" defaultMessage="Setting" />,
        link: false,
        children: [
          {
            path: '/main/workforce/setting/task-type',
            title: <FormattedMessage id="menuTaskType" defaultMessage="Task Type" />,
            menuCode: 'workforce-task-type',
            link: false,
          },
        ],
      },
      {
        path: '/main/workforce/maintenace',
        title: <FormattedMessage id="menuAssetMaintenance" defaultMessage="Asset Maintenance" />,
        menuCode: 'transportation-asset-maintenance',
        link: false,
      },
    ],
  },
  {
    path: '/main/workdesk',
    title: <FormattedMessage id="menuWorkDesk" defaultMessage="WorkDesk" />,
    link: false,
    children: [
      {
        path: '/main/workdesk/monitor',
        title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
        menuCode: 'workdesk-monitor',
        link: false,
      },
      {
        path: '/main/workdesk/travel-expenses',
        title: <FormattedMessage id="menuTravelExpenses" defaultMessage="Travel Expense" />,
        menuCode: 'travel-expenses',
        link: false,
      },
      {
        path: '/main/workdesk/report',
        title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
        link: false,
        children: [
          {
            path: '/main/workdesk/report/resource-task-summary',
            title: <FormattedMessage id="menuResourceTask" defaultMessage="Resource Task Summary" />,
            menuCode: 'workdesk-resourcetasksummary',
            link: false,
          },
          {
            path: '/main/workdesk/report/task',
            title: <FormattedMessage id="reportTaskReportViewTitle" defaultMessage="Task Report" />,
            menuCode: 'workdesk-task-report',
            link: false,
          },
        ],
      },
      {
        path: '/main/workdesk/setting',
        title: <FormattedMessage id="menuSetting" defaultMessage="Setting" />,
        link: false,
        children: [
          {
            path: '/main/workdesk/setting/task-type',
            title: <FormattedMessage id="menuTaskType" defaultMessage="Task Type" />,
            menuCode: 'workdesk-task-type',
            link: false,
          },
        ],
      },
      {
        path: '/main/workdesk/maintenace',
        title: <FormattedMessage id="menuAssetMaintenance" defaultMessage="Asset Maintenance" />,
        menuCode: 'transportation-asset-maintenance',
        link: false,
      },
    ],
  },
  {
    path: '/main/sourcing',
    title: <FormattedMessage id="menuSourcing" defaultMessage="Sourcing" />,
    link: false,
    children: [
      {
        path: '/main/sourcing/vendors',
        title: <FormattedMessage id="menuVendor" defaultMessage="Vendor/Supplier" />,
        menuCode: 'sourcing-vendor',
        link: true,
        children: [
          {
            path: '/main/sourcing/vendors/vendor-detail/',
            title: <FormattedMessage id="menuVendorDetail" defaultMessage="Vendor Detail" />,
            link: false,
          },
        ],
      },
      {
        path: '/main/sourcing/po',
        title: <FormattedMessage id="menuPO" defaultMessage="Purchase Order" />,
        menuCode: 'sourcing-procurement-po',
        link: true,
      },
      {
        path: '/main/sourcing/report',
        title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
        link: false,
        children: [
          {
            path: '/main/sourcing/report/item-purchase',
            title: <FormattedMessage id="menuItemPurchaseReport" defaultMessage="Item Purchase Report" />,
            menuCode: 'item-purchase-report',
            link: false,
          },
        ],
      },
    ],
  },
  {
    path: '/main/inventory',
    title: <FormattedMessage id="menuInventory" defaultMessage="Inventory" />,
    link: false,
    children: [
      {
        path: '/main/inventory/item-masters',
        title: <FormattedMessage id="menuItemMaster" defaultMessage="Item Master Data" />,
        menuCode: 'inventory-item-masterdata',
        link: true,
        children: [
          {
            path: '/main/inventory/item-masters/item-detail/',
            title: <FormattedMessage id="menuItemMaster" defaultMessage="Item Master" />,
            link: false,
          },
        ],
      },
      {
        path: '/main/inventory/item',
        title: <FormattedMessage id="menuItem" defaultMessage="Item" />,
        menuCode: 'inventory-item',
        link: false,
      },
      {
        path: '/main/inventory/maintenace',
        title: <FormattedMessage id="menuAssetMaintenance" defaultMessage="Asset Maintenance" />,
        menuCode: 'inventory-asset-maintenance',
        link: false,
      },
      {
        path: '/main/inventory/warehouse-sub',
        title: <FormattedMessage id="menuWarehouseSub" defaultMessage="Warehouse" />,
        menuCode: 'warehouse-sub',
        link: false,
      },
      {
        path: '/main/inventory/item-request',
        title: <FormattedMessage id="menuItemRequest" defaultMessage="Item Request" />,
        menuCode: 'itemRequest',
        link: false,
      },
      {
        path: '/main/inventory/setting',
        title: <FormattedMessage id="menuSetting" defaultMessage="Setting" />,
        link: false,
        children: [
          {
            path: '/main/inventory/setting/uom',
            title: <FormattedMessage id="menuUOM" defaultMessage="Unit of Measures (UOM)" />,
            menuCode: 'request-type',
            link: false,
          },
        ],
      },
      {
        path: '/main/inventory/receiveItemfromPO',
        title: <FormattedMessage id="menuReceiveItemPo" defaultMessage="Receive Item from PO" />,
        menuCode: 'receive-item-po',
        link: false,
      },
      {
        path: '/main/inventory/tranferitem',
        title: <FormattedMessage id="menuWarehouseTransfer" defaultMessage="Tranfer Item" />,
        menuCode: 'warehouse-transfer',
        link: false,
      },
      {
        path: '/main/inventory/report',
        title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
        link: false,
        children: [
          {
            path: '/main/inventory/report/instock',
            title: <FormattedMessage id="menuWarehouseInstock" defaultMessage="In Stock Report" />,
            menuCode: 'warehouse-report-instock',
            link: false,
          },
          {
            path: '/main/inventory/report/item-request',
            title: <FormattedMessage id="menuWarehouseItemRequest" defaultMessage="Item Request" />,
            menuCode: 'warehouse-report-itemrequest',
            link: false,
          },
          {
            path: '/main/inventory/report/item-movement',
            title: <FormattedMessage id="menuItemMovementReport" defaultMessage="Item Movement Report" />,
            menuCode: 'item-movement-report',
            link: false,
          },
          {
            path: '/main/inventory/report/receive-item-from-po',
            title: <FormattedMessage id="menuPoReciveReport" defaultMessage="Receive Item from PO Report" />,
            menuCode: 'po-receive-report',
            link: false,
          },
        ],
      },
    ],
  },
  {
    path: '/main/accounting',
    title: <FormattedMessage id="menuAccounting" defaultMessage="Accounting" />,
    link: false,
    children: [
      {
        path: '/main/accounting/invoice',
        title: <FormattedMessage id="menuInvoice" defaultMessage="Invoice" />,
        menuCode: 'accounting-invoice',
        link: false,
      },
      {
        path: '/main/accounting/expense',
        title: <FormattedMessage id="menuExpense" defaultMessage="Expense" />,
        menuCode: 'accounting-expense',
        link: false,
      },
      {
        path: '/main/accounting/petty-cash',
        title: <FormattedMessage id="menuPettyCash" defaultMessage="Petty Cash" />,
        menuCode: 'petty-cash',
        link: true,
        children: [
          {
            path: '/main/accounting/petty-cash/detail',
            title: <FormattedMessage id="menuPettyCashDetail" defaultMessage="Petty Cash Details" />,
            link: false,
          },
        ]
      },
      {
        path: '/main/accounting/report',
        title: <FormattedMessage id="menuReport" defaultMessage="Report" />,
        link: false,
        children: [
          {
            path: '/main/accounting/report/receive-payment',
            title: <FormattedMessage id="menuReportReceivePayment" defaultMessage="Receive and Payment" />,
            menuCode: 'report-accounting-receive-payment',
            link: false,
          },
          {
            path: '/main/accounting/report/reconcile',
            title: <FormattedMessage id="menuReportReconcile" defaultMessage="Reconcile Report" />,
            menuCode: 'report-accounting-reconcile',
            link: false,
          },
        ],
      },
    ],
  },
  {
    path: '/main/notification',
    title: <FormattedMessage id="menuNotification" defaultMessage="Notification" />,
    menuCode: 'notification',
    link: false,
  },
  {
    path: '/main/user-management',
    title: <FormattedMessage id="menuUserManagement" defaultMessage="User Management" />,
    link: false,
    children: [
      {
        path: '/main/user-management/users',
        title: <FormattedMessage id="menuUser" defaultMessage="Users" />,
        menuCode: 'users',
        link: true,
        children: [
          {
            path: '/main/user-management/users/users-detail/',
            title: <FormattedMessage id="menuUserDetail" defaultMessage="Users Detail" />,
            link: false,
          },
          {
            path: '/main/user-management/my-profile/',
            title: <FormattedMessage id="menuMyProfile" defaultMessage="My Profile" />,
            link: false,
          },
        ],
      },
      {
        path: '/main/user-management/role',
        title: <FormattedMessage id="menuRole" defaultMessage="Role" />,
        menuCode: 'role',
        link: false,
      },
      {
        path: '/main/user-management/permission',
        title: <FormattedMessage id="menuPermissions" defaultMessage="Permission" />,
        menuCode: 'permission',
        link: false,
      },
    ],
  },
  {
    path: '/main/company',
    title: <FormattedMessage id="menuCompany" defaultMessage="Company" />,
    link: false,
    children: [
      {
        path: '/main/company/company-profile',
        title: <FormattedMessage id="menuCompanyProfile" defaultMessage="Company Profile" />,
        menuCode: 'company-profile',
        link: false,
      },
      {
        path: '/main/company/license',
        title: <FormattedMessage id="menuLicense" defaultMessage="License" />,
        menuCode: 'license',
        link: false,
      },
    ],
  },
  {
    path: '/main/project',
    title: <FormattedMessage id="menuProject" defaultMessage="Project" />,
    link: false,
    children: [
      {
        path: '/main/project/list/',
        title: <FormattedMessage id="menuProjectProfile" defaultMessage="Project Profile" />,
        menuCode: 'project-profile',
        link: true,
        children: [
          {
            path: '/main/project/list/project-detail/',
            title: <FormattedMessage id="menuProjectDetail" defaultMessage="Project Detail" />,
            link: false,
          },
        ],
      },
    ],
  },
  {
    path: '/main/request',
    title: <FormattedMessage id="menuRequest" defaultMessage="Request" />,
    link: false,
    children: [
      {
        path: '/main/request/approval',
        title: <FormattedMessage id="menuApproveRequestSub" defaultMessage="To Review" />,
        menuCode: 'request-approval',
        link: false,
      },
      {
        path: '/main/request/myrequest',
        title: <FormattedMessage id="menuMyRequestSub" defaultMessage="My Request" />,
        menuCode: 'my-request',
        link: false,
      },
      // {
      //   path: '/main/company/license',
      //   title: <FormattedMessage id="menuLicense" defaultMessage="License" />,
      //   menuCode: 'license',
      //   link: false,
      // },
    ],
  },
  {
    path: '/main/ticket',
    title: <FormattedMessage id="menuTicket" defaultMessage="Ticket" />,
    link: false,
    children: [
      {
        path: '/main/ticket/Allticket',
        title: <FormattedMessage id="menuTicketAll" defaultMessage="All Ticket" />,
        menuCode: 'ticket-all',
        link: false,
      },
      {
        path: '/main/ticket/report',
        title: <FormattedMessage id="menuTicketReport" defaultMessage="Report" />,
        link: false,
        children: [

          {
            path: '/main/ticket/report/repiar-ticket',
            title: <FormattedMessage id="menuRepairTicketReport" defaultMessage="Repair Ticket" />,
            menuCode: 'repair-ticket-report',
            link: false,
          },
        ],
      },
    ],
  },
  {
    path: '/main/campaign',
    title: <FormattedMessage id="menuCampaign" defaultMessage="Campaign" />,
    link: false,
    children: [
      {
        path: '/main/campaign/point',
        title: <FormattedMessage id="menuCampaignPoint" defaultMessage="Point" />,
        menuCode: 'point',
        link: false,
      },
      {
        path: '/main/campaign/voucher',
        title: <FormattedMessage id="menuVoucher" defaultMessage="Voucher" />,
        menuCode: 'voucher',
        link: false,
      },
    ],
  },

  {
    path: '/main/account',
    title: <FormattedMessage id="menuAccounting" defaultMessage="Account " />,
    link: false,
    children: [
      {
        path: '/main/account/account-receivable',
        title: <FormattedMessage id="accountingReceivable" defaultMessage="Account Receivable" />,
        menuCode: 'accounting-receivable',
        link: false,
      },
      {
        path: '/main/account/account-receivable-report',
        title: <FormattedMessage id="accountingReceivableReport" defaultMessage="AR Report" />,
        menuCode: 'accounting-receivable-report',
        link: false,
      },
    ],
  },

  // {
  //   path: '/main/order',
  //   title: <FormattedMessage id="menuOrder" defaultMessage="Order" />,
  //   link: false,
  //   children: [
  //     {
  //       path: '/main/order/allorder',
  //       title: <FormattedMessage id="menuOrderReport" defaultMessage="All Order" />,
  //       menuCode: 'order',
  //       link: false,
  //     },
  //   ],
  // },


  // {
  //   path: '/main/warehouse-parent',
  //   title: <FormattedMessage id="menuWarehouseParent" defaultMessage="Warehouse" />,
  //   link: false,
  //   children: [
  //     {
  //       path: '/main/warehouse-parent/warehouse-sub',
  //       title: <FormattedMessage id="menuWarehouseSub" defaultMessage="Warehouse" />,
  //       menuCode: 'warehouse-sub',
  //       link: false,
  //     },
  //   ],
  // },

  // {
  //   path: '/main/user-management',
  //   title: <FormattedMessage id="breadcrumbUserManagement" defaultMessage="User management" />,
  //   link: false,
  //   children: [
  //     {
  //       path: '/main/user-management/users',
  //       title: <FormattedMessage id="breadcrumbUsers" defaultMessage="Users" />,
  //       link: true,
  //       children: [
  //         {
  //           path: '/main/user-management/users/users-detail',
  //           title: <FormattedMessage id="breadcrumbUserDetail" defaultMessage="User Detail" />,
  //           link: false
  //         },
  //         {
  //           path: '/main/user-management/my-profile',
  //           title: <FormattedMessage id="breadcrumbMyProfile" defaultMessage="My Profile" />,
  //           link: false
  //         }
  //       ]
  //     },
  //     {
  //       path: '/main/user-management/role',
  //       title: <FormattedMessage id="breadcrumbRole" defaultMessage="Role" />,
  //       link: false
  //     },
  //     {
  //       path: '/main/user-management/permission',
  //       title: <FormattedMessage id="breadcrumbPermission" defaultMessage="Permission" />,
  //       link: false
  //     }
  //   ]
  // },
  // {
  //   path: '/main/company',
  //   title: <FormattedMessage id="breadcrumbCompany" defaultMessage="Company" />,
  //   link: true,
  //   children: [
  //     {
  //       path: '/main/company/license',
  //       title: <FormattedMessage id="breadcrumbLicense" defaultMessage="License" />,
  //       link: false
  //     }
  //   ]
  // },
  // {
  //   path: '/main/organization',
  //   title: <FormattedMessage id="breadcrumbOrganization" defaultMessage="Organization" />,
  //   link: true,
  //   children: [
  //     {
  //       path: '/main/organization/users-detail',
  //       title: <FormattedMessage id="breadcrumbUserDetail" defaultMessage="User Detail" />,
  //       link: false
  //     }
  //   ]
  // },
  // {
  //   path: '/main/customer',
  //   title: <FormattedMessage id="breadcrumbCustomer" defaultMessage="Customer" />,
  //   link: true,
  //   children: [
  //     {
  //       path: '/main/customers/customer-detail',
  //       title: <FormattedMessage id="breadcrumbCustomerDetail" defaultMessage="Customer detail" />,
  //       link: false,
  //     }
  //   ]
  // },
  // {
  //   path: '/main/monitor',
  //   title: <FormattedMessage id="breadcrumbMonitor" defaultMessage="Monitor" />,
  //   link: false
  // },
  // {
  //   path: '/main/report',
  //   title: <FormattedMessage id="breadcrumbReport" defaultMessage="Report" />,
  //   link: false,
  //   children: [
  //     {
  //       path: '/main/report/time-attendance',
  //       title: <FormattedMessage id="breadcrumbTimeAttendance" defaultMessage="Time attendance" />,
  //       link: false,
  //     },
  //     {
  //       path: '/main/report/overt-time',
  //       title: <FormattedMessage id="breadcrumbOvertime" defaultMessage="Overtime" />,
  //       link: false,
  //     },
  //     {
  //       path: '/main/report/leave',
  //       title: <FormattedMessage id="breadcrumbLeave" defaultMessage="Leave" />,
  //       link: false,
  //     },
  //     {
  //       path: '/main/report/outside',
  //       title: <FormattedMessage id="breadcrumbOutside" defaultMessage="Outside" />,
  //       link: false,
  //     },
  //     {
  //       path: '/main/report/task',
  //       title: <FormattedMessage id="breadcrumbTask" defaultMessage="Task" />,
  //       link: false,
  //     },
  //     {
  //       path: '/main/report/travel',
  //       title: <FormattedMessage id="breadcrumbResourceTaskSummary" defaultMessage="Resource Task Summary" />,
  //       link: false,
  //     }
  //   ]
  // },
  // {
  //   path: '/main/notification',
  //   title: <FormattedMessage id="breadcrumbNotification" defaultMessage="Notification" />,
  //   link: false
  // },
  // {
  //   path: '/main/settings',
  //   title: <FormattedMessage id="breadcrumbSettings" defaultMessage="Settings" />,
  //   link: false,
  //   children: [
  //     {
  //       path: '/main/settings/request-type',
  //       title: <FormattedMessage id="breadcrumbRequestType" defaultMessage="Request Type" />,
  //       link: false
  //     },
  //     {
  //       path: '/main/settings/shift-type',
  //       title: <FormattedMessage id="breadcrumbShiftType" defaultMessage="Shift Type" />,
  //       link: false
  //     },
  //     {
  //       path: '/main/settings/task-type',
  //       title: <FormattedMessage id="breadcrumbTaskType" defaultMessage="Task Type" />,
  //       link: false
  //     },
  //     {
  //       path: '/main/settings/holidays',
  //       title: <FormattedMessage id="breadcrumbHolidays" defaultMessage="Holidays" />,
  //       link: false
  //     },
  //     {
  //       path: '/main/settings/skill',
  //       title: <FormattedMessage id="menuSkill" defaultMessage="Skill" />,
  //       link: false
  //     }
  //   ]
  // },
  // {
  //   path: '/main/invoice',
  //   title: <FormattedMessage id="breadcrumbInvoice" defaultMessage="Invoice" />,
  //   link: false
  // },
  // {
  //   path: '/main/sale-order',
  //   title: <FormattedMessage id="breadcrumbSaleOrder" defaultMessage="Sale Order" />,
  //   link: false
  // },
  // {
  //   path: '/main/maintenance-report',
  //   title: <FormattedMessage id="breadcrumbMaintenanceReport" defaultMessage="Maintenance Report" />,
  //   link: false
  // },
  // {
  //   path: '/main/item-master',
  //   title:<FormattedMessage id="breadcrumbItemMaster" defaultMessage="Item Master" />,
  //   link: false,
  //   children: [
  //     {
  //       path: '/main/item-masters/item-detail/',
  //       title: <FormattedMessage id="breadcrumbItemMaster" defaultMessage="Item Master" />,
  //       link: false,
  //     }
  //   ]
  // },
  // {
  //   path: '/main/vendor',
  //   title: <FormattedMessage id="breadcrumbVendor" defaultMessage="Vendor" />,
  //   link: false,
  //   children: [
  //     {
  //       path: '/main/vendors/vendor-detail',
  //       title: <FormattedMessage id="breadcrumbVendorDetail" defaultMessage="Vendor detail" />,
  //       link: false,
  //     }
  //   ]
  // }
];

export default menus;
