import React, { useState } from 'react';
import { Modal, Row, Col, Form, Select, InputNumber, Spin } from 'antd';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import _ from 'lodash';

const AddItem = (props) => {
  const intl = useIntl();
  const { form, data, visible, onCancel, onSave } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const [loading, setLoading] = useState(false);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setLoading(true);
      onSave(values);
      setLoading(false);
      setTimeout(() => {
        resetFields();
      }, 1000);
    });
  };

  const handleCancel = () => {
    onCancel();
    setTimeout(() => {
      resetFields();
    }, 1000);
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'planningTextAddItem', defaultMessage: 'Add an Item' })}
        centered
        visible={visible}
        onCancel={handleCancel}
        onOk={handleCreate}
        // bodyStyle={{ height: '350px' }}
        footer={[
          <div>
            <Button02 style={{ margin: '0px 0px 0px 10px' }} btnwidth="wd_at" onClick={handleCancel} disabled={loading}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>
            <Button01 type="primary" btnwidth="wd_at" onClick={loading === false ? handleCreate : null} disabled={loading}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>
          </div>,
        ]}
      >
        <Spin spinning={loading}>
          <Form colon={false}>
            <Row gutter={[24, 16]}>
              <Col span={12}>
                <Form.Item
                  className="vehicle-form-item"
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleOrderNo', defaultMessage: 'Order No' })} req={false} />}
                >
                  <span>{_.get(data, 'orderNo') ? _.get(data, 'orderNo') : '-'}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="vehicle-form-item"
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleCustomer', defaultMessage: 'Customer' })} req={false} />}
                >
                  <span>{_.get(data, 'customerName') ? _.get(data, 'customerName') : '-'}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={12}>
                <Form.Item
                  className="vehicle-form-item"
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleItem', defaultMessage: 'Item' })} req={false} />}
                >
                  <span>{_.get(data, 'productName') ? _.get(data, 'productName') : '-'}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="vehicle-form-item"
                  label={<LabeRequire text={intl.formatMessage({ id: 'vehicleQty', defaultMessage: 'Qty' })} req={true} />}
                >
                  {getFieldDecorator('qty', {
                    initialValue: _.get(data, 'total') !== undefined ? _.get(data, 'total') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'vehicleQtyPlateValidate', defaultMessage: 'Please Enter Qty' }),
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder={intl.formatMessage({ id: 'vehicleQtyPlatePlacholer', defaultMessage: 'Enter Qty' })}
                      maxLength={4}
                      onKeyDown={handleKeyDownNumber}
                      max={parseInt(_.get(data, 'total'))}
                      min={1}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

const ModalAddItem = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {},
})(AddItem);

export default ModalAddItem;
