import React, { useRef, useState } from 'react';
import { Modal, Icon, Select } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../components/v2/button_01';
import Button02 from '../components/v2/button_02';

const ModalPDFViewver = (props) => {
  const { visible, setVisible, url, handlePrint, handlePrintA5, singlePage } = props
  const intl = useIntl()
  const iframeRef = useRef(null);
  const [sizeType, setSizeType] = useState('full')

  const handleCancel = () => {
    setVisible(false)
    setSizeType('full')
  }

  const handlePrintDialog = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.print();
    }
  }

  const handleSelectType = async (type) => {
    if (type === 'full') await handlePrint()
    if (type === 'half') await handlePrintA5()
    setSizeType(type)
  }

  const footer = (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon type="file-text" style={{ color: '#1D3557' }} />
        <span style={{ color: '#1D3557', fontSize: '14px', marginLeft: '8px', fontWeight: 'bold' }}><FormattedMessage id="pdfViewverPaperSize" defaultMessage="Paper Size" /> : </span>
        <Select value={sizeType} style={{ width: '113px', marginLeft: '8px' }} onSelect={handleSelectType}>
          <Select.Option key="full">
            <FormattedMessage id="pdfViewverPaperFullSize" defaultMessage="Full Size" />
          </Select.Option>
          {
            singlePage ? (
              <Select.Option key="half">
                <FormattedMessage id="pdfViewverPaperHalfSize" defaultMessage="Half Size" />
              </Select.Option>
            ) : null
          }
        </Select>
      </div>
      <div>
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handlePrintDialog}>
          <FormattedMessage id="btnPrint" defaultMessage="Print" />
        </Button01>
      </div>
    </div>
  )

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'pdfViewverTitle', defaultMessage: 'PDF Document Preview' })}
        onCancel={handleCancel}
        visible={visible}
        centered={true}
        width={1100}
        bodyStyle={{ padding: 'unset' }}
        footer={footer}
      >
        {url ? (
          <iframe ref={iframeRef} src={url} width="100%" height="600px" />
        ) : (
          <p>Loading PDF...</p>
        )}
      </Modal>
    </div>
  )
}

export default ModalPDFViewver