import React, { useState, useEffect, useCallback } from 'react'
import { Table, Card, Row, Popover, Icon, Modal } from 'antd'
import Button01 from '../../../components/v2/button_01';
import Button03 from '../../../components/v2/button_03';
import AddTeamService from '../../../components/add-team-service/index'
import httpClient from '../../../components/axiosClient';
import {
  successNotification,
  errorNotification,
  warningNotification,
} from '../../../components/v2/notification';
import Button02 from '../../../components/v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import EditTeamService from '../../../components/edit-team-service/index'
import styled from './css/team-service.css';
import _ from 'lodash';
import { PageSettings } from '../../../config/page-settings';


export default ({ customerId, settriggerTeamService, triggerTeamService, teamService }) => {
  const intl = useIntl();



  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'customerTeamServiceTableColumnTeam', defaultMessage: 'Team' }),
      // dataIndex: 'org',
      // key: 'org',
      render: (text, record, index) => (
        <div>
          {record.org.map((item, i) => {

            return <span>{item.orgName} {i < (_.size(record.org) - 1) ? ',' : ''}&nbsp;</span>
          }
          )}
        </div>
      ),

    },
    {
      title: intl.formatMessage({ id: 'customerTeamServiceTableColumnTaskType', defaultMessage: 'Task Type' }),
      // dataIndex: 'taskType',
      // key: 'taskType',
      render: (text, record, index) => (
        <div>
          {record.taskType.map((item, i) =>
          (
            <span>{item.taskTypeName} {i < (_.size(record.taskType) - 1) ? ',' : ''}&nbsp;</span>
          )
          )}
        </div>
      ),

    },
    {
      title: intl.formatMessage({ id: 'customerTeamServiceTableColumnPriority', defaultMessage: 'Priority' }),
      dataIndex: 'priority',
      key: 'priority',
      align: 'center',
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => (
        <PageSettings.Consumer>
          {({ checkPermissionAction }) =>
            <Popover placement="leftTop" key={index} zIndex={999}
              content={
                <>
                  <Row>
                    {
                      checkPermissionAction('P14PG2C4', 'P14PG2C4A3') ? (
                        <Button01
                          btnsize="wd_df"
                          type="link" ghost
                          onClick={() => {
                            setVisibleModalEditTeam(true)
                            setDataDefaultEdit(record)
                          }}
                        >
                          <p><FormattedMessage id="btnEdit" defaultMessage="Edit" /></p>
                        </Button01>
                      ) : null
                    }

                  </Row>
                  <Row>
                    {checkPermissionAction('P14PG2C4', 'P14PG2C4A4') ? (
                      <Button01
                        btnsize="wd_df"
                        type="link" ghost
                        onClick={() => handlerDelete(record.customerTeamServiceId)}
                      >
                        <p><FormattedMessage id="btnDelete" defaultMessage="Delete" /></p>
                      </Button01>) : null
                    }

                  </Row>

                </>
              }

            >
              <Icon type="more" />
            </Popover >}
        </PageSettings.Consumer>)
    },
  ];




  const { confirm } = Modal;
  const comCode = localStorage.getItem('comCode');
  const [visibleModalAddTeam, setVisibleModalAddTeam] = useState(false)
  const [visibleModalEditTeam, setVisibleModalEditTeam] = useState(false)
  const [dataDefaultEdit, setDataDefaultEdit] = useState()
  const [taskType, setTaskType] = useState()
  const [team, setTeam] = useState()
  const [formRef, setFormRef] = useState(null)
  const [formRefEdit, setFormRefEdit] = useState(null)


  useEffect(() => {
    getValueDefault();
  }, [])

  const getValueDefault = async () => {
    try {
      const response = await httpClient.get(`/v3/task/manager/company/${comCode}/initial/all-tasktype/all-team`);


      if (response.status == 200) {

        setTaskType(response.data.data.taskTypes)
        setTeam(response.data.data.teams)
      }
    } catch (error) {

    }
  }

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const saveFormRefEdit = useCallback(node => {
    if (node !== null) {
      setFormRefEdit(node);
    }
  }, []);

  const onCreateTeamService = async () => {




    formRef.validateFields(async (err, values) => {

      if (err) {
        return;
      } else {



        const OrgToString = values.team
        const mapOrg = OrgToString.map(item => { return item.toString() })

        const taskTypeToString = values.taskType
        const mapTaskType = taskTypeToString.map(item => { return item.toString() })

        const body = {
          customerId: customerId,
          orgId: mapOrg,
          taskTypeId: mapTaskType,
          priority: values.priority,
        }



        // const body1 = {
        //   customerId: "6941ffe6-6964-498e-ae97-166755d7424e",
        //   orgId: "3",
        //   taskTypeId: "fa6d1dc6-1acb-4155-82f1-859cefa5cece",
        //   priority: 10,
        // }
        try {
          const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-team-service/`, body);
          if (response.status == 200) {
            settriggerTeamService(!triggerTeamService)
            successNotification(response.data.status.message);
            setVisibleModalAddTeam(false)
            formRef.resetFields()

          }
        } catch (error) {
          errorNotification(error.response.data.status.message);

        }

      }
    }
    )
  }

  const onEditTeamService = async () => {
    formRefEdit.validateFields(async (err, values) => {

      if (err) {
        return;
      }
      else {


        const OrgToString = values.team
        const mapOrg = OrgToString.map(item => { return item.toString() })

        const taskTypeToString = values.taskType
        const mapTaskType = taskTypeToString.map(item => { return item.toString() })

        const body = {
          orgId: mapOrg,
          taskTypeId: mapTaskType,
          priority: values.priority,
        }
        try {
          const response = await httpClient.put(`/v3/business-partner/manager/company/${comCode}/customer-team-service/${dataDefaultEdit.customerTeamServiceId}`, body);
          if (response.status == 200) {
            settriggerTeamService(!triggerTeamService)
            successNotification(response.data.status.message);
            setVisibleModalEditTeam(false)
            formRefEdit.resetFields()

          }
        } catch (error) {
          errorNotification(error.response.data.status.message);

        }

      }
    }
    )
  }

  const handlerDelete = (customerTeamServiceId) => {
    confirm({
      className: "customer-modal-confirm",
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerTeamServiceDeleteConfirmText', defaultMessage: 'Are you sure to delete team service ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/customer-team-service/${customerTeamServiceId}`);
          if (response.status == 200) {
            settriggerTeamService(!triggerTeamService)
            successNotification(response.data.status.message);

          }
        } catch (error) {
          errorNotification(error.response.data.status.message);

        }
      },
      onCancel() {

      }
    })
  }


  const onCancelCreate = () => {
    setVisibleModalAddTeam(false)
    formRef.resetFields()
  }

  const onCancelEdit = () => {
    setVisibleModalEditTeam(false)
    formRefEdit.resetFields()
    setDataDefaultEdit();
  }


  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        <div>
          <Card
            title={intl.formatMessage({ id: 'customerTeamServiceCardHeader', defaultMessage: 'Team Service' })}
            extra={ checkPermissionAction('P14PG2C4','P14PG2C4A2') ? (
              <Button03 type='link'
                onClick={() => { setVisibleModalAddTeam(true); }}
              >
                + <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button03> ) : null
            }
          >
            <Table
              columns={columns}
              dataSource={teamService && teamService !== undefined ? teamService : null}
              pagination={false}
            />
          </Card>
          <Modal
            className='padding-content'
            title={intl.formatMessage({ id: 'customerTeamServiceCardHeader', defaultMessage: 'Team Service' })}
            visible={visibleModalAddTeam}
            centered={true}
            onCancel={() => onCancelCreate()}
            onOk={() => onCreateTeamService()}
            width={642}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={() => onCancelCreate()}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01
                key="submit"
                type="primary"
                btnsize="wd_df"
                onClick={() => onCreateTeamService()}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <AddTeamService
              ref={saveFormRef}
              taskType={taskType}
              teamData={team}
              onCreate={onCreateTeamService}
              intl={intl}
            />
          </Modal>

          <Modal
            className='padding-content'
            title={intl.formatMessage({ id: 'customerTeamServiceCardHeader', defaultMessage: 'Team Service' })}
            visible={visibleModalEditTeam}
            centered={true}
            onCancel={() => onCancelEdit()}
            onOk={() => onEditTeamService()}
            width={642}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df"
                onClick={() => onCancelEdit()}
              >
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01
                key="submit"
                type="primary"
                btnsize="wd_df"
                onClick={() => onEditTeamService()}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <EditTeamService
              ref={saveFormRefEdit}
              taskType={taskType}
              teamData={team}
              onCreate={onEditTeamService}
              dataDefault={dataDefaultEdit}
              teamService={teamService}
              intl={intl}
            />
          </Modal>
        </div>}
    </PageSettings.Consumer>
  )
}
