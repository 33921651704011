import { Button, Card, Dropdown, Menu, Modal, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';
import TableTransporationSetting from '../components/table';
import _ from 'lodash';
import FilterSetting from '../components/filterSetting';
import ModalSetting from '../components/modal';
import { ModalConfirmSetting } from '../components/modelConfirm';
import { deleteAllowance, getAllowanceList, settingAllowanceExport } from '../../../controllers/task/setting';
import moment from 'moment';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';

const PageAllowance = () => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const intl = useIntl();
  const memComId = localStorage.getItem('memComId');
  const name = localStorage.getItem('name');
  const [visible, setVisible] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnCustomer', defaultMessage: 'Customer' }),
      dataIndex: 'customerDetail.name',
      key: 'customerDetail.name',
      sorter: true,
      default: true,
      fixed: 'left',
      width: 150,
      render: (text, record, index) => _.get(record, 'customerDetail.name', 'ไม่กำหนด') || 'ไม่กำหนด',
    },
    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnProject', defaultMessage: 'Project' }),
      dataIndex: 'projectDetail.name',
      key: 'projectDetail.name',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => (
        <>
          {_.get(record, 'projectDetail.no') !== 'ไม่กำหนด' ? _.get(record, 'projectDetail.no', '') + ' ' + _.get(record, 'projectDetail.name', '') : _.get(record, 'projectDetail.no')}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnTaskType', defaultMessage: 'Task Type' }),
      dataIndex: 'taskTypeDetail.name',
      key: 'taskTypeDetail.name',
      sorter: true,
      default: true,
      width: 150,
      render: (text, record, index) => _.get(record, 'taskTypeDetail.name', 'ไม่กำหนด') || 'ไม่กำหนด',
    },
    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnSource', defaultMessage: 'Source' }),
      dataIndex: 'sourceLocationDetail.name',
      key: 'sourceLocationDetail.name',
      default: true,
      width: 500,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          {_.get(record, 'sourceLocationDetail.id') ? _.get(record, 'sourceLocationDetail.name') + ' ' + _.get(record, 'sourceLocationDetail.fullName', '') : _.get(record, 'sourceLocationDetail.name')}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnDestination', defaultMessage: 'Destination' }),
      dataIndex: 'destinationLocationDetail.name',
      key: 'destinationLocationDetail.name',
      default: true,
      width: 500,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          {_.get(record, 'destinationLocationDetail.id') ? _.get(record, 'destinationLocationDetail.name') + ' ' + _.get(record, 'destinationLocationDetail.fullName', '') : _.get(record, 'destinationLocationDetail.name')}
        </>
      ),
    },

    // {
    //   title: intl.formatMessage({ id: 'AllowanceSettingColumnDistance', defaultMessage: 'Distance(km)' }),
    //   dataIndex: 'detail.distance',
    //   key: 'detail.distance',
    //   align: 'right',
    //   sorter: true,
    //   default: false,
    //   width: 150,
    //   render: (text, record, index) => numberFormatter(_.get(record, 'detail.distance', 0)),
    // },

    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnAllowance', defaultMessage: 'Allowance' }),
      dataIndex: 'detail.price',
      key: 'detail.price',
      align: 'right',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.price', 0)) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnDrive1', defaultMessage: 'Drive 1' }),
      dataIndex: 'detail.price',
      key: 'detail.price',
      align: 'right',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.price1', 0)) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnDrive2', defaultMessage: 'Drive 2' }),
      dataIndex: 'detail.price',
      key: 'detail.price',
      align: 'right',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.price2', 0)) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnUpdateby', defaultMessage: 'Update by' }),
      dataIndex: 'updatedName',
      key: 'updatedName',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => _.get(record, 'updatedName') || _.get(record, 'createdName'),
    },

    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnUpdateDate', defaultMessage: 'Update Date' }),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      default: true,
      width: 150,
      render: (text, record, index) => moment.unix(_.get(record, 'updatedAt')).format('DD/MM/YYYY'),
    },
  ];

  const [listData, setListData] = useState([]);
  const [scrollX, setScrollX] = useState(2500);
  const [search, setSearch] = useState();
  const [isFetch, setIsFetch] = useState();
  const [tableChange, setTableChange] = useState({
    page: 1,
    size: 10,
    loading: false,
    sortField: undefined,
    sortOrder: undefined,
  });
  const [listTotal, setListTotal] = useState(0);
  const [freightData, setFreightData] = useState();
  const [freightPrice, setFreightPrice] = useState(0);
  const [editData, setEditData] = useState(0);

  useEffect(() => {
    console.log('search', search);
    const getData = async () => {
      let distance = [];

      if (_.get(search, 'distance1')) {
        distance.push({
          'detail.distance': {
            $gte: parseFloat(_.get(search, 'distance1')),
          },
        });
      }

      if (_.get(search, 'distance2')) {
        distance.push({
          'detail.distance': {
            $lte: parseFloat(_.get(search, 'distance2')),
          },
        });
      }

      const body = {
        where: {
          'customerDetail.id': _.get(search, 'customer.customerId')
            ? _.get(search, 'customer.customerId') || undefined
            : _.get(search, 'customerSearch') === 'notSpecified'
              ? null
              : _.get(search, 'customerSearch'),
          'projectDetail.id': _.get(search, 'project.projectId')
            ? _.get(search, 'project.projectId') || undefined
            : _.get(search, 'projectSearch') === 'notSpecified'
              ? null
              : _.get(search, 'projectSearch'),
          'taskTypeDetail.id': _.get(search, 'taskType', undefined) === 'notSpecified' ? null : _.get(search, 'taskType'),
          'sourceLocationDetail.name': _.get(search, 'source')
            ? {
              $regex: _.get(search, 'source'),
            }
            : undefined,
          'destinationLocationDetail.name': _.get(search, 'destination')
            ? {
              $regex: _.get(search, 'destination'),
            }
            : undefined,
          $and: _.size(distance) ? distance : undefined,
        },
        page: _.get(tableChange, 'page'),
        pageSize: _.get(tableChange, 'size'),
        orderBy: _.get(tableChange, 'sortField') ? _.get(tableChange, 'sortField') : undefined,
        order: _.get(tableChange, 'sortOrder') !== '' ? _.get(tableChange, 'sortOrder') : undefined,
      };

      const response = await getAllowanceList(body);
      setListData(_.get(response, 'data.data.docs'));
      setListTotal(_.get(response, 'data.data.total'));
    };

    getData();
  }, [isFetch, tableChange]);

  const menuColumn = (
    <Menu>
      {checkPermissionAction('P77PG1C1', 'P77PG1C1A2') ? (
        <Menu.Item>
          <Button
            style={{ padding: 'unset' }}
            ghost
            type="link"
            onClick={() => {
              setVisible(true);
            }}
          >
            <FormattedMessage id="btnCreateAllowance" defaultMessage="Create Allowance" />
          </Button>
        </Menu.Item>
      ) : null}
      <Menu.Item>
        <Button
          style={{ padding: 'unset' }}
          ghost
          type="link"
          onClick={() => {
            handleExport();
          }}
        >
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  const contentAction = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        {checkPermissionAction('P77PG1C1', 'P77PG1C1A3') ? (
          <div>
            <Button
              style={{ width: 100 }}
              type="link"
              ghost
              onClick={() => {
                handleEdit(record);
              }}
            >
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button>
          </div>
        ) : null}
        {checkPermissionAction('P77PG1C1', 'P77PG1C1A4') ? (
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteAllowance(record._id)}>
              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  const handleDeleteAllowance = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const body = {
          updatedBy: parseFloat(memComId),
          updatedName: name,
        };
        const response = await deleteAllowance(body, id);
        if (_.get(response, 'data.status.code') === 200) {
          successNotification(_.get(response, 'data.status.message'));
          setIsFetch((current) => !current);
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      },
      onCancel() { },
    });
  };

  const handleApply = () => {
    setIsFetch((event) => !event);
    setTableChange((prev) => ({ ...prev, page: 1 }));
  };

  const handleReset = () => {
    setIsFetch((event) => !event);
    setSearch((prev) => ({
      customer: undefined,
      project: undefined,
      taskType: undefined,
      source: '',
      destination: '',
      distance1: '',
      distance2: '',
    }));
    setTableChange((prev) => ({ ...prev, page: 1 }));
  };

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
  };

  const handleEdit = (value) => {
    setEditData(value);
    setVisible(true);
  };

  const handleExport = async () => {
    try {
      let distance = [];

      if (_.get(search, 'distance1')) {
        distance.push({
          'detail.distance': {
            $gte: parseFloat(_.get(search, 'distance1')),
          },
        });
      }

      if (_.get(search, 'distance2')) {
        distance.push({
          'detail.distance': {
            $lte: parseFloat(_.get(search, 'distance2')),
          },
        });
      }
      const body = {
        where: {
          'customerDetail.id': _.get(search, 'customer.customerId')
            ? _.get(search, 'customer.customerId') || undefined
            : _.get(search, 'customerSearch') === 'notSpecified'
              ? null
              : _.get(search, 'customerSearch'),
          'projectDetail.id': _.get(search, 'project.projectId')
            ? _.get(search, 'project.projectId') || undefined
            : _.get(search, 'projectSearch') === 'notSpecified'
              ? null
              : _.get(search, 'projectSearch'),
          'taskTypeDetail.id': _.get(search, 'taskType', undefined) === 'notSpecified' ? null : _.get(search, 'taskType'),
          'sourceLocationDetail.name': _.get(search, 'source')
            ? {
              $regex: _.get(search, 'source'),
            }
            : undefined,
          'destinationLocationDetail.name': _.get(search, 'destination')
            ? {
              $regex: _.get(search, 'destination'),
            }
            : undefined,
          $and: _.size(distance) ? distance : undefined,
        },
        page: 1,
        pageSize: listTotal,
        orderBy: _.get(tableChange, 'sortField') ? _.get(tableChange, 'sortField') : undefined,
        order: _.get(tableChange, 'sortOrder') !== '' ? _.get(tableChange, 'sortOrder') : undefined,

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnCustomer', defaultMessage: 'Customer' }),
            sequence: 1,
            colCode: 'customerDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnProject', defaultMessage: 'Project' }),
            sequence: 2,
            colCode: 'projectDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnTaskType', defaultMessage: 'Task Type' }),
            sequence: 3,
            colCode: 'taskTypeDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnSource', defaultMessage: 'Source' }),
            sequence: 4,
            colCode: 'sourceLocationDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnDestination', defaultMessage: 'Destination' }),
            sequence: 5,
            colCode: 'destinationLocationDetail.name',
          },
          // {
          //   colName: intl.formatMessage({ id: 'AllowanceSettingColumnDistance', defaultMessage: 'Distance(km)' }),
          //   sequence: 6,
          //   colCode: 'detail.distance',
          // },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnAllowance', defaultMessage: 'Allowance' }),
            sequence: 6,
            colCode: 'detail.price',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnDrive1', defaultMessage: 'Drive 1' }),
            sequence: 7,
            colCode: 'detail.price1',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnDrive2', defaultMessage: 'Drive 2' }),
            sequence: 8,
            colCode: 'detail.price2',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnUpdateby', defaultMessage: 'Update by' }),
            sequence: 9,
            colCode: 'updatedName',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnUpdateDate', defaultMessage: 'Update Date' }),
            sequence: 10,
            colCode: 'updatedAt',
          },
        ],
      };
      const response = await settingAllowanceExport(body);
      console.log('AAAAAaA', response.data.data);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) { }
  };

  return (
    <div>
      {checkPermissionAction('P77PG1C1', 'P77PG1C1A1') ? (
        <Card
          className="reportItemSalesCard"
          title={intl.formatMessage({ id: 'menuAllowanceSetting', defaultMessage: 'Allowance' })}
          bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
          extra={
            // checkPermissionAction('P77PG1C1', 'P77PG1C1A2') ? (
            <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              <Button_01 key="submit" type="primary" btnsize="wd_df">
                <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button_01>
            </Dropdown>
            // ) : null
          }
        >
          <FilterSetting
            type={'Allowance'}
            search={search}
            handleApply={handleApply}
            handleReset={handleReset}
            onFilterOrder={onFilterOrder}
            setSearch={setSearch}
          />
          <TableTransporationSetting
            columns={columns}
            listData={listData}
            listTotal={listTotal}
            tableChange={tableChange}
            setTableChange={setTableChange}
            scrollX={scrollX}
            contentAction={contentAction}
            onRow={true}
            popupPermisson={
              checkPermissionAction('P77PG1C1', 'P77PG1C1A3') || checkPermissionAction('P77PG1C1', 'P77PG1C1A4') ? true : false
            }
          />
        </Card>
      ) : null}
      <ModalSetting
        visible={visible}
        setVisible={setVisible}
        type="allowance"
        data={editData}
        setData={setEditData}
        setTrigger={setIsFetch}
      />
    </div>
  );
};

export default PageAllowance;
