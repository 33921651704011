import React, { useState } from 'react';
import { Button, Card, Row, Col, Avatar, Divider, Icon } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import _ from 'lodash';
import './css/index.css';
import { PageSettings } from '../../../config/page-settings';
import { FormattedMessage, useIntl } from 'react-intl';

const ProjectDetail = (props) => {
  const { handleEditProject, handleDeleteProject, record } = props;
  const intl = useIntl();

  const iconPriority = () => <img className="prirority-icon-propject" src={_.get(record, 'projectPriorityIcon')} />;

  const actionButton = (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div className="action-button-group-project">
          {checkPermissionAction('P39PG2C1', 'P39PG2C1A3') ? (
            <div>
              <Button02 style={{margin : '0px 0px 0px 10px'}} className="action-button-group-delete" btnsize="wd_df" fontsize="sm" onClick={() => handleDeleteProject(record)}>
                <FormattedMessage id="btnDelete" defaultMessage="Delete" />
              </Button02>
            </div>
          ) : null}
          {checkPermissionAction('P39PG2C1', 'P39PG2C1A2') ? (
            <div>
              <Button01 className="action-button-group-delete" btnsize="wd_df" fontsize="sm" type="primary" onClick={handleEditProject}>
                <FormattedMessage id="btnEdit" defaultMessage="Edit" />
              </Button01>
            </div>
          ) : null}
        </div>
      )}
    </PageSettings.Consumer>
  );

  const Content = () => {
    return (
      <div>
        <Row>
          <Col span={24}>
            <p className="header-title-project">
              {_.get(record, 'projectNo') || '-'} · {_.get(record, 'projectName') || '-'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="title-item-project">
              <FormattedMessage id="projectDetailLabelProjectType" defaultMessage="Project Type" />
            </p>
            <p className="title-value-project">{_.get(record, 'projectTypeName') || '-'}</p>
          </Col>
          <Col span={12}>
            <p className="title-item-project">
              <FormattedMessage id="projectDetailLabelPriority" defaultMessage="Priority" />
            </p>
            <p style={{ display: 'flex' }}>
              <Icon component={iconPriority} style={{ paddingRight: '6px' }} />
              {_.get(record, 'priority') || ''}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="title-item-project">
              <FormattedMessage id="projectDetailLabelCustomer" defaultMessage="Customer" />
            </p>
            <p className="title-value-project">{_.get(record, 'customer') || '-'}</p>
          </Col>
          <Col span={12}>
            <p className="title-item-project">
              <FormattedMessage id="projectDetailLabelProjectManager" defaultMessage="Project Manager" />
            </p>
            <p className="title-value-project">{_.get(record, 'projectManager') || '-'}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p className="title-item-project">
              <FormattedMessage id="projectDetailLabelPlanDate" defaultMessage="Plan Date" />
            </p>
            <p className="title-value-project">{_.get(record, 'planDate') || '-'}</p>
          </Col>
          <Col span={12}>
            <p className="title-item-project">
              <FormattedMessage id="projectDetailLabelReference" defaultMessage="Reference No." />
            </p>
            <p className="title-value-project">{_.get(record, 'reference') || '-'}</p>
          </Col>
        </Row>
        <Divider type="horizontal" />
        <Row>
          <Col span={24}>
            <p className="title-item-project">
              <FormattedMessage id="projectDetailLabelRemark" defaultMessage="Remark" />
            </p>
            <p className="title-value-project line-remark-value">{_.get(record, 'remark') || '-'}</p>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <div>
      <Card
        className="card-detail-project"
        title={intl.formatMessage({ id: `projectDetailLabelProjectDetails`, defaultMessage: 'Project Details' })}
        extra={actionButton}
      >
        <Row>
          <Col span={6}>
            <div className="layout-avatar-project">
              <Avatar size={160} icon="user" src={_.get(record, 'projectImgProfile') || '-'} />
            </div>
          </Col>
          <Col span={14}>
            <Content />
          </Col>
          <Col span={4}></Col>
        </Row>
      </Card>
    </div>
  );
};

export default ProjectDetail;
