import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../../includes/indexProvider';
import {
  Button,
  Row,
  Input,
  Col,
  DatePicker,
  Select,
  List,
  Icon,
  Dropdown,
  Checkbox,
  ConfigProvider,
  Menu,
} from 'antd';
import Button01 from '../../../../components/v2/button_01';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import styled from 'styled-components';
import cssStyleCenter from '../report-center.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default ({
  columns,
  checked,
  onSelectRangePicker,
  handleOnVisiblecolumns,
  textErrorSelectColumn,
  handleReset,
  handleCheckOk,
  visible,
  handleVisibleChange,
  checkedValuecolumns,
  onChange,
  onSelect,
  selectSearchData,
}) => {
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;

  const ignoreColumn = ['index', 'empCode', 'Name'];
  const columnNew = columns && columns !== undefined ? columns.filter((col) => !ignoreColumn.includes(col.key)) : [];

  const ignoreSearchColumn = ['index', 'Round', 'Distance', 'Hour', 'Summary','new','scheduled','assigned','accepted','in_progress','returned','cancelled','completed'];
  const columnNewSearch = columns && columns !== undefined ? columns.filter((col) => !ignoreSearchColumn.includes(col.key)) : [];


  const dateFormat = 'ddd, MMM DD YYYY';
  const today = new Date();

  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew && columnNew !== undefined ? columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={checkedValuecolumns}
                checked={checked[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          )) : null}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div>
      <Row>
        <Col span={12} style={{ margin: 0 }}>
          <Row>
            <List.Item className="over-time-report-list-left">
              <Input
                className="over-time-report-input action-input"
                placeholder={intl.formatMessage({
                  id: 'hintSearch',
                  defaultMessage: 'Search',
                })}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                onChange={(e) => onChange(e.target.value)}
                autoComplete="off"
              />
              <Select
                className="over-time-report-select action-select"
                onSelect={(value) => onSelect(value)}
                value={selectSearchData}
                placeholder={intl.formatMessage({
                  id: 'hintSelect',
                  defaultMessage: 'Select',
                })}
              >
                <Option value="all">
                  <FormattedMessage id="orgTabMemberTxtAllColumns" defaultMessage="All columns" />
                </Option>
                {columnNewSearch.map((item, i) => (
                  <Option key={i} value={item.key}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </List.Item>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <List.Item className="over-time-report-list-right">
              <ConfigProvider locale={locale}>
                <RangePicker
                  className="report-rangePicker"
                  onChange={onSelectRangePicker}
                  defaultValue={
                    langValue === 'EN'
                      ? [moment(today, dateFormat), moment(today, dateFormat)]
                      : [moment(today, dateFormat), moment(today, dateFormat)]
                  }
                  format={dateFormat}
                  allowClear={false}
                  size="small"
                />
              </ConfigProvider>

              <Dropdown
                className="over-time-report-dropdown"
                overlay={menuColumn}
                trigger={['click']}
                onVisibleChange={handleVisibleChange}
                visible={visible}
                placement="bottomRight"
              >
                <Button01
                  type="primary"
                  btnsize="wd_at"
                  fontSize="sm"
                  onClick={handleOnVisiblecolumns}
                >
                  <FormattedMessage id="reportOverTimeReportBtnColumns" defaultMessage="Columns" />{" "}
                  <Icon type="down" />
                </Button01>
              </Dropdown>
            </List.Item>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
