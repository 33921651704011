import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Col, Row, Tabs, Button, Icon } from 'antd';
import { MonitorContext } from '../transportation-context';
import { PageSettings } from '../../../config/page-settings';
import { useIntl, FormattedMessage } from 'react-intl';
import { StickyContainer, Sticky } from 'react-sticky';
import Button01 from '../../../components/v2/button_01';
import IconExcel from '../../../components/image/excel.svg';
import GanttView from './gantt/index';
import MapView from './map/index';
import ListView from './list/index';

import './css/index.css';
const { TabPane } = Tabs;

export default (props) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(MonitorContext);
  const { valueState, valueSetState, valueFnc } = props;
  const { statusSummary, keyTabMonitor, show, spanRight, spanLeft, setMainOrgId, resourceData, LangCode } = state;

  const { setSpanRight, setSpanLeft, setShow, setStatusSummary, setKeyTabMonitor } = setState;

  const handleVisibleSummary = () => {
    setStatusSummary((current) => !current);
  };

  const callback = (key) => {
    setKeyTabMonitor(key);
    // valueSetState.setSelectGroupBy('team')
  };

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <Row>
          <Col span={1} className="col-button-hide">
            <Button className="button-disabled-summary" onClick={handleVisibleSummary} style={{ border: 'unset', marginLeft: '12px' }}>
              <Icon type={statusSummary ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
            </Button>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  const iconExcel = () => <img className="monitor-icon-excel" src={IconExcel} />;

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <Card className="right-card">
          <StickyContainer>
            <Tabs
              className="monitor-main-tabs"
              size="large"
              defaultActiveKey="1"
              onChange={callback}
              animated={false}
              renderTabBar={handleRenderTopBar}
              tabBarExtraContent={
                <div className="display-extra-button-group">
                  <div className="display-extra-button-group-item">
                    {checkPermissionAction(`P22PG1`, `P22PG1A2`) ? (
                      <div>
                        <Button01 btnsize="wd_at" type="primary" onClick={() => setState.setMainVisibleCreateTask(true)}>
                          <FormattedMessage id={`monitorCreateTaskTxtCreateTask${LangCode}`} defaultMessage="Create Task" />
                        </Button01>
                      </div>
                    ) : null}
                  </div>
                  {keyTabMonitor === '3' ? (
                    <div className="display-extra-button-group-item">
                      <Button
                        className="monitor-button-link-export"
                        type="link"
                        onClick={fnc.handleExport}
                      // disabled={disabled}
                      >
                        <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
                      </Button>
                    </div>
                  ) : null}
                </div>
              }
            >
              {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? (
                <TabPane tab={intl.formatMessage({ id: `monitorTabGanttViewTitle${LangCode}`, defaultMessage: 'Gantt View' })} key="1">
                  <GanttView valueState={valueState} valueSetState={valueSetState} valueFnc={valueFnc} />
                </TabPane>
              ) : null}

              {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? (
                <TabPane tab={intl.formatMessage({ id: `monitorTabMapViewTitle${LangCode}`, defaultMessage: 'Map View' })} key="2">
                  <MapView />
                </TabPane>
              ) : null}

              {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? (
                <TabPane tab={intl.formatMessage({ id: `monitorTabListViewTitle${LangCode}`, defaultMessage: 'List View' })} key="3">
                  <ListView />
                </TabPane>
              ) : null}
            </Tabs>
          </StickyContainer>
        </Card>
      )}
    </PageSettings.Consumer>
  );
};
