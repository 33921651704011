import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon, Input, Menu, Tree } from 'antd';
import Button01 from '../../components/v2/button_01';
import { ServiceAreaContext } from './index';
import { PageSettings } from '../../config/page-settings';
import styles from './css/index.css';

const { TreeNode, DirectoryTree } = Tree;

const ServiceArea = (props) => {
  const {} = props;
  const intl = useIntl();
  const { mainState, mainSetState, mainFnc } = useContext(ServiceAreaContext);

  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    let lowercasedValue = e.target.value.toLowerCase();

    const excludeColumns = ['areaMasterId', 'comCode', 'areaCode'];

    if (lowercasedValue !== '') {
      const serviceArea =
        mainState.serviceAreaData &&
        mainState.serviceAreaData.filter((item) => {
          return Object.keys(item).some((key) =>
            excludeColumns.includes(key)
              ? false
              : item[key]
                  .toString()
                  .toLowerCase()
                  .includes(lowercasedValue)
          );
        });
      setSearchResults(serviceArea);
    } else {
      setSearchResults(mainState.serviceAreaData);
    }
  };

  const onSelect = (selectedKeys, info) => {
    if (selectedKeys.length === 0) {
      mainSetState.setActionAddNew('default');
      mainSetState.setServiceAreaId(null);
      mainSetState.setServiceAreaDataById({
        areaCode: '',
        areaMasterId: '',
        areaName: '',
        comCode: '',
        coordinates: [],
      });
    } else {
      mainSetState.setActionAddNew('view');
      mainSetState.setServiceAreaId(selectedKeys.toString());
    }
  };

  const loopTree = (item) => {
    const index = item.areaName.toLowerCase().indexOf(searchTerm.toLowerCase());
    const searchMatch = index > -1 ? item.areaName.substr(index, searchTerm.length) : '';
    const beforeStr = item.areaName.substr(0, index);
    const afterStr = item.areaName.substr(index + searchTerm.length);

    const title =
      index > -1 ? (
        <span>
          {beforeStr}
          <span style={{ color: '#f50' }}>{searchMatch}</span>
          {afterStr}
        </span>
      ) : (
        <span>{item.areaName}</span>
      );

    if (item.children) {
      return (
        <TreeNode key={item.areaMasterId} title={title}>
          {loopTree(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode key={item.areaMasterId} title={title} />;
  };

  return (
    <div className="service-area">
      <div className="service-area-container">
        <Card
          className="service-area-crad"
          title={intl.formatMessage({
            id: 'serviseAreaTitleServiceArea',
            defaultMessage: 'Service Area',
          })}
          extra={
            <PageSettings.Consumer>
              {({ checkPermissionAction }) =>
                checkPermissionAction('P18PG1C1', 'P18PG1C1A2') ? (
                  <div style={{ marginRight: '14px' }}>
                    <Button01 type="primary" fontsize="sm" btnsize="wd_df" styleMargin="mg_cs3" onClick={mainFnc.fncAddNewArea}>
                      <FormattedMessage id="serviseAreaBtnAddNew" defaultMessage="Add New" />
                    </Button01>
                  </div>
                ) : null
              }
            </PageSettings.Consumer>
          }
        >
          <div className="service-area-body">
            <div className="service-area-input">
              <Input
                placeholder={intl.formatMessage({
                  id: 'hintSearch',
                  defaultMessage: 'Search',
                })}
                prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
                onChange={handleSearch}
                autoComplete="off"
              />
            </div>
            <div className="service-area-div-menu scroll-sm">
              <Tree className="service-area-tree" blockNode="false" onSelect={onSelect} selectedKeys={[`${mainState.serviceAreaId}`]}>
                {searchTerm.length < 1
                  ? mainState.serviceAreaData && mainState.serviceAreaData.map((item) => loopTree(item))
                  : searchResults.map((item) => loopTree(item))}
              </Tree>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ServiceArea;
