import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../v2/button_01';
import { Icon, TreeSelect } from 'antd';
import './index.css';
import TicketLocationModal from '../../../pages/allticket/modal/location-modal';
import { ActionNoteContext } from '../note_Provirder';
import { useAppContext } from '../../../includes/indexProvider';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import { saveImageTicketDescription } from '../../../controllers/ticket/ticket';
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';
import { errorNotification } from '../../v2/notification';
import { RenderTag as RenderTagEdit } from './post-tag';
import { Preview01, Preview02, Preview03, Preview04, Preview05, PreviewPlus, PreviewSingle } from '../../note-monitor/preview-picture';
import PostAddress from './post-address';
import { saveFeedList, uploadFeedList } from '../../../controllers/note/feed';
import { RiLuggageCartFill } from 'react-icons/ri';
import { DownOutlined } from '@ant-design/icons';

const ActionNote = ({ data, tagList }) => {
  const intl = useIntl();
  const note = useContext(ActionNoteContext);
  const app = useAppContext();
  const toggle = app.state.toggleDrawer;
  const trigger = app.fnc.handleTriggerDrawer;
  const contentDrawerData = app.state.contentDrawer
  const { imageRef, fileRef, selectPostAddress } = note.state;
  const { setDataAddress, handleCloseAddress } = note.fnc;

  //---------------------Post--------------------------//

  const [selectedImageBlob, setSelectedImageBlob] = useState([]);
  const [selectedFilesBlob, setSelectedFilesBlob] = useState([]);

  const [checked, setChecked] = useState('default');

  //----------------------------------------------------

  //-------------------Location------------------------//
  const [visibleLocation, setVisibleLocation] = useState(false);
  const [selectLocation, setSelectLocation] = useState();

  const [visibleInfoWindow, setVisibleInfoWindow] = useState(true);
  const [center, setCenter] = useState('');
  const [position, setPosition] = useState('');

  const [loading, setLoading] = useState(false);

  //---------------------------------------------------//

  const [message, setMessage] = useState('');
  const [tag, setTag] = useState([]);
  const [tagSearch, setTagSearch] = useState('');

  const memComLogin = localStorage.getItem('memComId');
  const nameLocal = localStorage.getItem('name');
  //--------------------------------------------------

  useEffect(() => {

    if (_.size(contentDrawerData) > 0) {
      setTag([]);
      handleCloseAddress();
      setMessage('');
      setSelectedFilesBlob([]);
      setSelectedImageBlob([]);
      setTagSearch('');
    }
  }, [contentDrawerData]);

  const showOpenFileDialog = (e) => {
    imageRef.current.value = null;
    if (loading !== true) {
      imageRef.current.click();
    }
  };

  const showOpenFileDialogFile = () => {
    fileRef.current.value = null;
    if (loading !== true) {
      fileRef.current.click();
    }
  };

  const handlePostLocation = () => {
    setVisibleLocation(true);
  };

  const onChangeMessage = async (text) => {
    setMessage(text);
  };

  const onChangeTag = async (text) => {

    setTag(text);
  };

  const onSearchTag = async (text) => {

    setTagSearch(text);
  };

  const uploadEdit = async () => {
    let mapImageAll = [];
    let mapFileAll = [];
    const filterBlobImg = _.filter(selectedImageBlob, (item) => {
      return item.fileType === 'Blob';
    });
    const filterBlobFile = _.filter(selectedFilesBlob, (item) => {
      return item.fileType === 'Blob';
    });
    for (let index = 0; index < _.size(filterBlobImg); index++) {
      let formData = new FormData();
      formData.append('file', filterBlobImg[index].file);
      formData.append('fileType', 'photo');
      const fileResponse = await saveImageTicketDescription(formData);
      if (fileResponse.status === 200) {
        mapImageAll.push({
          fileHash: _.get(fileResponse, 'data.data.fileHash'),
          descriptionPhoto: filterBlobImg[index].description,
          type: 'photo',
        });
      }
    }

    for (let index = 0; index < _.size(filterBlobFile); index++) {
      let formData = new FormData();
      formData.append('file', filterBlobFile[index].file);
      formData.append('fileType', 'file');
      const fileResponse = await saveImageTicketDescription(formData);
      mapFileAll.push({
        fileHash: _.get(fileResponse, 'data.data.fileHash'),
        descriptionPhoto: filterBlobFile[index].name,
        type: 'file',
      });
    }
    const filterUrlImg = _.filter(selectedImageBlob, (item) => {
      return item.fileType === 'url';
    });
    const filterUrlFile = _.filter(selectedFilesBlob, (item) => {
      return item.fileType === 'url';
    });
    const mapImage = _.map(filterUrlImg, (item) => {
      return {
        fileHash: item.attachmentId,
        descriptionPhoto: item.descriptionPhoto || '',
        type: 'photo',
      };
    });
    const mapFile = _.map(filterUrlFile, (item) => {
      return {
        fileHash: item.attachmentId,
        descriptionPhoto: item.name,
        type: 'file',
      };
    });

    const concatImage = mapImage.concat(mapImageAll);
    const concatFile = mapFile.concat(mapFileAll);
    return {
      concatImage,
      concatFile,
    };
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChangePicture = async (e) => {
    let newArr = [...selectedImageBlob];
    const files = e.target.files;

    console.log('PEPE', e);

    for (let i = 0; i < files.length; i++) {
      const file = await fileToBase64(files[i]);
      const fileResize = dataURLtoFile(file, files[i].name);

      newArr.push({
        attachmentId: uuidv4(),
        description: '',
        pathImage: URL.createObjectURL(fileResize),
        file: fileResize,
        fileType: 'Blob',
        uri: URL.createObjectURL(fileResize),
      });
    }
    setSelectedImageBlob(newArr);

    Array.from(newArr).map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    );
  };

  const handleChangeFile = (e) => {
    let newArr = [...selectedFilesBlob];
    const file = e.target.files;

    if (file) {
      let fileMin = _.filter(file, (el) => el.size <= 2097152);

      if (file.length !== fileMin.length) {
        let fileCount = file.length - fileMin.length;
        errorNotification(
          intl.formatMessage({
            id: 'errorImageAndFileMoreThan2MB',
            defaultMessage: 'Upload failed, please upload a file that is less than 2 MB.',
          })
        );
      }

      for (let i = 0; i < fileMin.length; i++) {
        if (fileMin[i].name.match(/\.(pdf|PDF)$/) && fileMin[i].size <= 2097152) {
          newArr.push({
            attachmentId: uuidv4(),
            description: '',
            file: fileMin[i],
            name: fileMin[i].name,
            fileType: 'Blob',
          });
          setSelectedFilesBlob(newArr);
        }
      }
    }
  };

  const renderTagFile = (fileList, removeFile) => {
    return (
      <div className="post-tags-view-modal">
        <div className="post-tags-view">
          {fileList &&
            fileList.map((item) => {
              return _.size(selectedImageBlob) > 0 ? (
                _.get(item, 'file') ? (
                  <RenderTagEdit fileList={item} handleTags={handleTags} handleRemove={removeFile} type={'create'} />
                ) : (
                  <RenderTagEdit fileList={item} handleTags={handleTagsEdit} handleRemove={removeFile} type={'edit'} />
                )
              ) : (
                <RenderTagEdit fileList={item} handleTags={handleTags} handleRemove={removeFile} type={'create'} />
              );
            })}
        </div>
      </div>
    );
  };

  const handleTagsEdit = (data) => {
    window.open(data.fileUrl, '_blank');
  };

  const handleTags = (data) => {
    if (_.get(data, 'file')) {
      const fileTag = URL.createObjectURL(data.file);
      let isPDF = /pdf/g.test(data.name.toLowerCase());
      if (isPDF) return window.open(fileTag, '_blank');
    }
  };

  const removeFile = (val) => {
    let newArr = [...selectedFilesBlob];
    const remove = _.filter(newArr, (item) => {
      return item.attachmentId !== val;
    });
    setSelectedFilesBlob(remove);
  };

  const renderPreview = (imageList) => {
    let sum = imageList.length;

    console.log('imageList', imageList);

    switch (sum) {
      case 1:
        return (
          <>
            <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} />
          </>
        );
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} />;
    }
  };

  const handleDeleteSingleImage = (data) => {
    let newArr = [...selectedImageBlob];
    if (data && data.attachmentId) {
      const remove = _.filter(newArr, (item) => {
        return item.attachmentId !== data.attachmentId;
      });

      console.log('RRERRE', remove);
      setSelectedImageBlob(remove);
      if (_.size(remove) <= 0) {
        handleBack();
      }
    }
  };

  const onChangeCommentImg = (e, item, index) => {
    let newArr = [...selectedImageBlob];
    if (e.target.value.length >= 0) {
      newArr[index].description = e.target.value;
    }

    setSelectedImageBlob(newArr);
  };

  const handleBack = (data) => {
    setChecked('default');
  };

  const handlePreviewImage = (data) => {
    setChecked('actionView');
  };

  const handleAddress = (data) => {
    if (data) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'addressLat')}, ${_.get(data, 'addressLng')}`, '_blank');
    } else if (data) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'lat')}, ${_.get(data, 'lng')}`, '_blank');
    }
  };

  const TreeNode = ({ level, title }) => <span style={{ fontWeight: level === 1 ? 'bold' : 'normal' }}>{title}</span>;

  const renderTreeNodes = (data, level) =>
    _.map(data, (item) => (
      // <TreeSelect.TreeNode title={<TreeNode level={level} title={item.tagName} />} value={item.tagId} key={item.tagId}>
      <TreeSelect.TreeNode style={{ fontWeight: level === 1 ? 'bold' : 'normal' }} title={item.tagName} value={item.tagId} key={item.tagId}>
        {item.tagChilds && renderTreeNodes(item.tagChilds, level + 1)}
      </TreeSelect.TreeNode>
    ));

  const handleCreateFeed = async () => {
    let tagTemp = [];
    note.setState.setLoading(true)
    if (tag) {
      const fnc = (tagList, tag) => {
        const response = [];

        _.map(tagList, (i) => {
          const findIndex = _.findIndex(tag, (tagId) => {
            return tagId === i.tagId ? true : false;
          });
          if (findIndex > -1) {
            response.push({
              ...i,
            });
          }

          if (_.size(i.tagChilds) > 0) {
            const getData = fnc(i.tagChilds, tag);
            response.push(...getData);
          }
        });

        return response;
      };

      tagTemp = fnc(tagList, tag);
    }

    console.log('TAGTAG', tagTemp);

    const body = {
      feedsId: '',
      formCode: 'deliveryorder',
      formName: 'Delivery Order',
      feedRefId: data,
      createdByName: nameLocal,
      createdById: memComLogin,
      feedsTags: _.size(tagTemp) ? tagTemp : [],
      feedsDescription: message,
      feedsLocation:
        {
          lat: _.get(selectPostAddress, 'lat'),
          lng: _.get(selectPostAddress, 'lng'),
          fullAddress: _.get(selectPostAddress, 'address'),
        } || undefined,
    };

    const responese = await saveFeedList(body);

    if (_.get(responese, 'status') === 200) {
      setTag([]);
      handleCloseAddress();
      setMessage('');
      if (_.size(selectedImageBlob) > 0 || _.size(selectedFilesBlob) > 0) {
        const concatFile = selectedImageBlob.concat(selectedFilesBlob);
        let status = []
        for (let index = 0; index < concatFile.length; index++) {
          const element = concatFile[index];
          let formData = new FormData();
          formData.append('file', _.get(element, 'file'));
          formData.append('remark', _.get(element, 'description') || '');
          formData.append('fileName', _.get(element, 'file.name'));
          formData.append('formCode', 'deliveryorder');
          formData.append('feedsId', _.get(responese, 'data.data.feedsId'));
          formData.append('memComId', memComLogin);
          const responeseImage = await uploadFeedList(formData);
          if (_.get(responeseImage, 'status') === 200) {
            status.push(responeseImage)
          } else {
            status.push({ ...responeseImage, fileName: _.get(element, 'file.name') })
          }
        }
        if (_.size(status) === _.size(concatFile)) {
          const findError = _.filter(status, (item) => { return _.get(item, 'status') === 500 })
          if (_.size(findError) > 0) {
            _.map(findError, (item) => errorNotification(`${_.get(item, 'fileName')} ${_.get(item, 'data.error')}`))
          }
          setSelectedFilesBlob([]);
          setSelectedImageBlob([]);
          trigger();
        }
        // _.map(selectedFilesBlob, async (i) => {
        //   let formData = new FormData();
        //   formData.append('file', _.get(i, 'file'));
        //   formData.append('remark', _.get(i, 'description') || '');
        //   formData.append('fileName', _.get(i, 'file.name'));
        //   formData.append('formCode', 'deliveryorder');
        //   formData.append('feedsId', _.get(responese, 'data.data.feedsId'));
        //   formData.append('memComId', memComLogin);
        //   const responeseImage = await uploadFeedList(formData);
        // });

        // trigger();
      } else {
        trigger();
      }
    }
    note.setState.setLoading(false)
  };

  console.log('CHCHCHC', checked);

  return (
    <div
      style={{
        height: '180px',
        overflowY: 'auto',
        padding: '10px 16px',
        borderTop: '1px solid #e8e8e8',
      }}
    >
      <div className="action-note-component">
        {/* <div className="action-note-group-space">texttt</div> */}
        <div className="action-note-group">
          <TreeSelect
            
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: '460px', overflow: 'auto', width: '300px' }}
            placeholder={intl.formatMessage({ id: 'AllticketSelecttopic', defaultMessage: 'Select Topic' })}
            allowClear
            multiple
            treeNodeFilterProp={'title'}
            treeDefaultExpandAll
            maxTagCount={3}
            onChange={onChangeTag}
            value={tag}
            searchValue={tagSearch}
            onSearch={onSearchTag}
          >
            {renderTreeNodes(tagList, 1)}
          </TreeSelect>
        </div>
        <div className="action-note-group">
          <TextArea
            placeholder={intl.formatMessage({
              id: 'noteTextAreaPH',
              defaultMessage: 'Note something here...',
            })}
            style={{ boxShadow: 'none' }}
            className="text-area-post"
            rows={1}
            onChange={(e) => onChangeMessage(e.target.value)}
            value={message}
            autoSize={{ minRows: 1, maxRows: 4 }}
            suffixIcon={<DownOutlined />}
          ></TextArea>
        </div>
        <div className="action-note-group">
          <div className="engagement-modal-body" style={{ paddingLeft: '13px' }}>
            <input
              ref={imageRef}
              type="file"
              multiple
              style={{ display: 'none', height: '300px' }}
              accept=".jpg, .png, .JPG, .PNG"
              onChange={handleChangePicture}
            />
            <input ref={fileRef} type="file" multiple style={{ display: 'none' }} accept=".pdf" onChange={handleChangeFile} />
          </div>
        </div>
        <div className="action-note-group">
          {_.size(selectPostAddress) > 0 ? (
            <PostAddress
              action="create"
              postData={{
                addressLat: _.get(selectPostAddress, 'lat'),
                addressLng: _.get(selectPostAddress, 'lng'),
                addressName: _.get(selectPostAddress, 'address'),
                addressId: _.get(selectPostAddress, 'addressBookId'),
              }}
              handleAddress={handleAddress}
              handleCloseAddress={handleCloseAddress}
            />
          ) : null}
        </div>

        <div className="action-note-group">{_.size(selectedFilesBlob) > 0 ? renderTagFile(selectedFilesBlob, removeFile) : null} </div>

        <div className="action-note-group">
          {selectedImageBlob && _.size(selectedImageBlob) > 0 ? (
            checked === 'default' ? (
              <div style={{ height: toggle ? '500px' : '300px' }}>{renderPreview(selectedImageBlob)}</div>
            ) : (
              <div style={{ height: toggle ? '500px' : '300px' }}>
                <PreviewSingle
                  action="actionView"
                  imageList={selectedImageBlob}
                  handleDeleteSingleImage={handleDeleteSingleImage}
                  onChangeCommentImg={onChangeCommentImg}
                  handleBack={handleBack}
                  type="add"
                  setOpenGallery={() => { }}
                />
              </div>
            )
          ) : null}
        </div>
        <TicketLocationModal
          visibleLocation={visibleLocation}
          selectLocation={selectLocation}
          setSelectLocation={setSelectLocation}
          setVisibleLocation={setVisibleLocation}
          center={center}
          setCenter={setCenter}
          position={position}
          setPosition={setPosition}
          visibleInfoWindow={visibleInfoWindow}
          setVisibleInfoWindow={setVisibleInfoWindow}
          selectPostAddress={note.state.selectPostAddress}
          setSelectPostAddress={setDataAddress}
          typeAddress={'Post'}
        />
      </div>
      <div
        className="action-note-group"
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
        }}
      >
        <div className="action-note-icon-group" style={{ fontSize: '18px' }}>
          <div style={{ paddingRight: '18px' }}>
            <Icon onClick={showOpenFileDialog} type="camera" style={{ color: '#6490CF' }} />
          </div>
          <div style={{ paddingRight: '18px' }}>
            <Icon onClick={handlePostLocation} type="environment" style={{ cursor: 'pointer', color: '#6490CF' }} />
          </div>
          <div>
            <Icon onClick={showOpenFileDialogFile} type="file-text" style={{ color: '#6490CF' }} />
          </div>
        </div>
        <div>
          <Button_01
            onClick={handleCreateFeed}
            style={{ marginRight: 0 }}
            key="submit"
            type="primary"
            btnsize="wd_df"
            disabled={
              _.size(tag) > 0 || message || _.size(selectPostAddress) > 0 || _.size(selectedFilesBlob) > 0 || _.size(selectedImageBlob) > 0
                ? false
                : true
            }
          >
            <FormattedMessage id="btnCreate" defaultMessage="Create" />
          </Button_01>
        </div>
      </div>
    </div>
  );
};

export default ActionNote;
