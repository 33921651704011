import React, { useState } from 'react'
import { Button, Row, Col, Avatar, Icon, Popover, Modal } from 'antd'
import './index.css'
import _ from 'lodash'
import ReactBnbGallery from 'react-bnb-gallery';
import {
  Preview01,
  Preview02,
  Preview03,
  Preview04,
  Preview05,
  PreviewPlus,
} from '../../../../components/note-monitor/preview-picture';
import { FormattedMessage, useIntl } from 'react-intl';
import AddNote from '../../../../components/ticket/modal-add-note/index'
import EditNote from '../../../../components/ticket/modal-edit-note/index'
import { RenderTag } from './post-tag';
import { deleteTicketNote } from '../../../../controllers/ticket/ticket';
import { successNotification, errorNotification } from '../../../../components/v2/notification';

const ViewNote = (props) => {
  const { hanldeClickMap, noteData, viewData, setTrigger } = props
  const intl = useIntl()
  const [OpenGallery, setOpenGallery] = useState(false)
  const [galleryImg, setGalleryImg] = useState([])
  const [numberImage, setNumberImage] = useState(0)
  const [visibleAddNote, setVisibleAddNote] = useState(false)
  const [visibleEditNote, setVisibleEditNote] = useState(false)
  const [editPostData, setEditPostData] = useState()

  const colorizeText = (text) => {
    const parts = text.split(/(#[^\s]+)/g);
    const coloredText = parts.map((part, index) => {
      if (part.startsWith('#')) {
        return (
          <span key={index} style={{ color: '#E73845' }}>
            {part}
          </span>
        );
      }
      return part;
    });
    return coloredText;
  }

  const handlePreviewImage = (page, data, itemData) => {
    const mapPhoto = data && _.size(data) !== 0 ? data.map((item, index) => {
      return {
        photo: _.get(item, 'uri'),
        number: index,
        caption: _.get(item, 'descriptionPhoto'),
        subcaption: `${intl.formatMessage({ id: 'subtaskNoteNotedby', defaultMessage: 'Noted By' })} ${_.get(itemData, 'fullname')}`,
        thumbnail: _.get(item, 'uri'),
      }
    }
    ) : []
    setGalleryImg(mapPhoto)
    setOpenGallery(true)
    setNumberImage(page)
  }

  const fncPreview = (imageList, item) => {
    let sum = _.size(imageList);
    switch (sum) {
      case 1:
        return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
    }

  };

  const handleAddNote = () => {
    setVisibleAddNote(true)
  }

  const handleEdit = (data) => {
    console.log("handleEdit", data)
    const makeData = {
      attachmentsPhoto: _.map(data.attachmentsPhoto, (item) => {
        return {
          filehash: item.filehash,
          descriptionPhoto: item.descriptionPhoto,
          uri: item.fileUrl,
        }
      }) || [],
      attachmentsFile: _.map(data.attachmentsFile, (item) => {
        return {
          filehash: item.filehash,
          originalname: item.descriptionPhoto || "file",
          file: item.fileUrl,
        }
      }) || [],
      description: data.description,
      address: data.address,
      lat: data.lat,
      lng: data.lng,
      noteid: data.noteId,

    }
    setEditPostData(makeData)
    setVisibleEditNote(true)
  }

  const handleTags = (data) => {
    window.open(data.fileUrl, '_blank');
  };

  const renderTagFile = (fileList) => {
    return (
      <div className="post-tags-view-modal">
        <div className="post-tags-view">
          {fileList && fileList.map((item) => <RenderTag fileList={item} handleTags={handleTags} />)}
        </div>
      </div>
    );
  };

  const handleDelete = (id) => {
    Modal.confirm({
      className: 'middleConfirmModal',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteTicketNote(id)
          if (_.get(response, 'data.status.code') === 200) {
            successNotification(_.get(response, 'data.status.message'))
            setTrigger(current => !current)
          } else {
            errorNotification(_.get(response, 'data.status.message'))
          }
        } catch (error) {
          errorNotification(_.get(error, 'response.data.status.message'))
        }

      },
      onCancel() { },
    });
  };

  return (
    <div style={{ backgroundColor: `${_.size(noteData) !== 0 ? '#f2f3f5' : '#ffffff'}`, height: '435px', overflowY: 'scroll', padding: '12px 24px' }}>
      {
        _.map(noteData, (item) => {
          return (
            <div className='viewTicketCardNote'>
              <Row style={{ padding: '24px 24px 0px 24px' }} gutter={[16]}>
                <Col span={2} style={{ textAlign: 'center' }}>
                  <Avatar size="large" src={item.member.profileImg} />
                </Col>
                <Col span={22}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <div className='viewTicketTextValue' style={{ margin: 'unset' }}>
                        {item.member.fullname}
                      </div>
                      <div className='viewTicketTextValueNoted'>
                        {item.createdAt}
                      </div>
                    </div>
                    <div>
                      {
                        item.action.edit || item.action.delete ||
                          item.member.memComId === localStorage.getItem('memComId') ? (
                          <Popover
                            key={item.index}
                            placement='leftTop'
                            trigger='hover'
                            content={
                              <div style={{ display: 'grid' }}>
                                {
                                  item.action.edit || item.member.memComId === localStorage.getItem('memComId') ? (
                                    <Button
                                      style={{ width: 100 }}
                                      type='link'
                                      ghost
                                      onClick={() => handleEdit(item)}
                                    >
                                      <FormattedMessage id='btnEdit' defaultMessage='Edit' />
                                    </Button>
                                  ) : null
                                }
                                {
                                  item.action.delete || item.member.memComId === localStorage.getItem('memComId') ? (
                                    <Button
                                      style={{ width: 100 }}
                                      type='link'
                                      ghost
                                      onClick={() => handleDelete(item.noteId)}
                                    >
                                      <FormattedMessage id='btnDelete' defaultMessage='Delete' />
                                    </Button>
                                  ) : null
                                }
                              </div>
                            }
                          >
                            <Icon type='more' />
                          </Popover>
                        ) : null
                      }

                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ marginTop: '24px', padding: '0 24px' }}>
                <div>
                  
                  {_.size(_.get(item,'description')) ? colorizeText(`${item.description}`) : null}
                </div>
              </div>
              {
                item.address ? (
                  <div style={{ marginTop: '14px', padding: '0 24px' }}>
                    <div className='viewTicketTextValueAddress' style={{ cursor: 'pointer' }} onClick={() => hanldeClickMap(item.lat, item.lng)}>
                      {` - at ${item.address} >`}
                    </div>
                  </div>
                ) : null
              }
              {item.attachmentsFile && _.size(item.attachmentsFile) > 0 ? (
                <div style={{ marginTop: '14px', padding: '0 24px' }}>
                  {
                    renderTagFile(item.attachmentsFile)
                  }
                </div>
              )
                : null
              }
              {
                _.size(item.attachmentsPhoto) > 0 ? (
                  <div style={{ marginTop: '20px' }}>
                    <div>{fncPreview(_.map(item.attachmentsPhoto, (e) => {
                      return {
                        uri: e.fileUrl,
                        descriptionPhoto: e.descriptionPhoto
                      }
                    }), item.member)}</div>
                  </div>
                ) : null
              }
            </div>
          )
        })
      }
      <div className="viewTicketAddNoteContainer" onClick={() => handleAddNote()}>
        <Button className="viewTicketAddNoteButton" shape="circle" icon="plus" />
      </div>
      <ReactBnbGallery
        show={OpenGallery}
        photos={galleryImg}
        onClose={() => setOpenGallery(false)}
        activePhotoIndex={numberImage}
      />
      <AddNote
        visible={visibleAddNote}
        setVisible={setVisibleAddNote}
        ticketId={_.get(viewData, 'ticketId')}
        ticketNo={_.get(viewData, 'ticketNo')}
        setTrigger={setTrigger}
      />
      <EditNote
        visible={visibleEditNote}
        setVisible={setVisibleEditNote}
        ticketId={_.get(viewData, 'ticketId')}
        ticketNo={_.get(viewData, 'ticketNo')}
        setTrigger={setTrigger}
        editPostData={editPostData}
        setEditPostData={setEditPostData}
      />
    </div>
  )
}

export default ViewNote
