import { Modal, Col, Row, Form, Select, Input, InputNumber, DatePickevisibleModalr } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import './css/index.css';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { FineContext } from './finecontext';
import Button_02 from '../../components/v2/button_02';
import Button_01 from '../../components/v2/button_01';
const { Option } = Select;
const { TextArea } = Input;


const FineViewModal = () => {
  const { state, setState, fnc } = useContext(FineContext);
  const intl = useIntl();
  const { visibleViewModal,record } = state;
  const { setVisibleViewModal } = setState;
  const { handleOpenModal } = fnc;
  const paddinginputLeft = '16px 10px 0px 10px';
  const paddinginputRight = '16px 10px 0px 10px';

  console.log('recordFine',record);

  const resetFiledsAll = () => {
    setVisibleViewModal(false);
  };

  return (
    <Modal
      visible={visibleViewModal}
      onCancel={() => resetFiledsAll()}
      centered={true}
      title={
        <>
          <FormattedMessage id="FineDetail" defaultMessage="Fine Detail" /> {_.get(record,'fineNo')}
        </>
      }
      zIndex={998}
      width={760}
      style={{ marginTop: '15px' }}
      bodyStyle={{ padding: '5px 24px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={() => resetFiledsAll()} style={{ margin: 'unset' }}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModal('edit',record)}>
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </Button_01>,
      ]}
    >
      <Form>
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineEmployee" defaultMessage="Employee" />
              </span>
              <div className='viewdetil'> {_.get(record,'employeeName')} </div>
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: paddinginputRight }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineAmountTHB" defaultMessage="Amount (THB)" />
              </span>
              <div className='viewdetil'> {_.get(record,'amountText')} </div>
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------*/}
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineDate" defaultMessage="Fine Date" />
              </span>
              <div className='viewdetil'> {_.get(record,'fineDateText')} </div>
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: paddinginputRight }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineFormReason" defaultMessage="Reason" />
              </span>

              <div className='viewdetil'> {_.get(record,'fineReasonName')} </div>
              </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------*/}
        <Row gutter={24}>
          <Col span={24} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineDescription" defaultMessage="Description" />
              </span>
              <div className='viewdetil'> {_.get(record,'description')} </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FineViewModal;
