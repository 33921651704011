import React, { useState, useRef, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button01 from '../../../../../components/v2/button_01'
import { Divider, Table, Avatar, Button, Popover, Select } from 'antd'
import _ from 'lodash'
import AppinputSearch from '../../../../../components/inputSearch'
import { DeliveryDetailContext } from '..'
import { PageSettings } from '../../../../../config/page-settings'

const { Option } = Select

const DeliveryMember = () => {
  const intl = useIntl()
  const ref = useRef()
  const { checkPermissionAction } = useContext(PageSettings)
  const { handleOpenModalMember, memberList, memberTotal, handleDeleteMember, searchMember, handleChangeTable,
    handleSearchMember, handleSelectColumnsMember, memberLoading } = useContext(DeliveryDetailContext)
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '7%',
    },
    {
      title: null,
      dataIndex: 'img',
      key: 'img',
      width: '8%',
      render: (text, record, index) => <Avatar shape='circle' src={record.imageUrl} />
    },
    {
      title: intl.formatMessage({ id: `deliveryMemberColumnsName`, defaultMessage: 'Name' }),
      dataIndex: 'fullname',
      key: 'fullname',
      width: '30%',
      sorter: true
    },
    {
      title: intl.formatMessage({ id: `deliveryMemberColumnsPhone`, defaultMessage: 'Phone' }),
      dataIndex: 'phone',
      key: 'phone',
      width: '15%',
      sorter: true
    },
    {
      title: intl.formatMessage({ id: `deliveryMemberColumnsEmail`, defaultMessage: 'Email' }),
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      sorter: true
    },
  ];
  const ignoreColumn = ['index', 'img'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        {
          checkPermissionAction(`P58PG1C3`, `P59PG1C3A3`) ? (
            <Popover
              placement="right"
              trigger="hover"
              align={{ offset: offsetPopover }}
              visible={visiblePopoverTable}
              zIndex={10000}
              content={
                <div style={{ display: 'grid' }}>
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteMember(record.memComId)}>
                      <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                    </Button>
                  </div>
                </div>
              }
            >
              <div style={{ width: 0, height: 0 }} ref={ref}></div>
            </Popover>
          ) : null
        }
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };



  return (
    <div style={{ margin: '56px 0' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 0 0 24px' }}>
        <div className='deliveryTopicTitle'>
          <FormattedMessage id="deliveryOrderMember" defaultMessage="Member" />
        </div>
        {
          checkPermissionAction(`P58PG1C3`, `P58PG1C3A2`) ? (
            <div>
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModalMember()}>
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button01>
            </div>
          ) : null
        }

      </div>
      <Divider type="horizontal" />
      <div style={{ padding: '0 24px' }} onMouseLeave={handleMouseLeave}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AppinputSearch
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            onChange={handleSearchMember}
            value={_.get(searchMember, 'memberInput')}
            className='deliveryMemberInput'
          />
          <Select
            className="deliveryMemberSelect action-select"
            onSelect={handleSelectColumnsMember}
            value={_.get(searchMember, 'memberColumns')}
          >
            <Option value="all">
              <FormattedMessage id="orgTabServiceTxtAllColumns" defaultMessage="All columns" />
            </Option>
            {columnNew.map((item, i) => (
              <Option key={i} value={item.key}>
                {item.title}
              </Option>
            ))}
          </Select>
        </div>
        <div style={{ marginTop: '16px' }}>
          <Table
            columns={columns}
            dataSource={memberList}
            onChange={handleChangeTable}
            loading={memberLoading}
            pagination={{
              total: memberTotal,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              // onChange: handlePagination,
              // onShowSizeChange: handleSizeChange,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href && checkPermissionAction(`P58PG1C3`, `P59PG1C3A3`)) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                },
              };
            }}
          />
          {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
          {memberList && memberList.length !== 0 ? (
            <div className="total-items-timeAttendance">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${memberTotal || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div >
  )
}

export default DeliveryMember
