import React, { useContext } from 'react';
import {  QuotationProvider } from './context';
import './index.css'
import QuotationPage from './quotation-page';

const QuotationProviderPage = () => {

  return (
    <QuotationProvider>
      <QuotationPage />
    </QuotationProvider>
  );
};

export default QuotationProviderPage;
