import httpClient from '../../components/axiosClient';

const getItemValue = async (comCode, value) => {

  try {
    // const response = await httpClient.get(`/v3/order/manager/company/${comCode}/getitemmaster?itemCode=43857-0209`)
    const response = await httpClient.get(`/v3/order/manager/company/${comCode}/getitemmaster?itemCode=${value}`)
    if (response.status === 200) {

      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const getItemName = async (comCode, value) => {

  try {
    // const response = await httpClient.get(`/v3/order/manager/company/${comCode}/getitemmaster?itemCode=43857-0209`)
    const response = await httpClient.get(`/v3/order/manager/company/${comCode}/getitemmaster?itemName=${value}`)
    if (response.status === 200) {

      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const getDefaultSaleOreder = async () => {
  const comCode = localStorage.getItem('comCode');

  if (comCode) {
    try {
      const response = await httpClient.get(`/v3/order/manager/company/${comCode}/getdetailcreate`);
      if (response.status === 200) {
        return await response.data;
      } else {
        return {};
      }
    } catch (error) {

    }
  }

}

export { getItemValue, getDefaultSaleOreder, getItemName };