import httpClient from '../../components/axiosClient';

const getOrgTeamApi = async (props) => {

  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  try {
    const response = await httpClient.get(
      `/v3/resource/manager/company/${comId}/team-leader-all/${props}/member/${memComId}`,
    );

    const data = await response.data.data.organization;
    return data;

  } catch (error) {

    return error.response;
  }
}

const getinitialTeamApi = async (type) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/task/manager/company/${comCode}/initial/data?${type}`);
    if (response.status == 200) {
      return response
    }
  } catch (error) {
    return error.response
  }
}

export { getOrgTeamApi, getinitialTeamApi };