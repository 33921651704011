import React from 'react'
import { Avatar, Badge, Col } from 'antd'
import { FormattedMessage } from 'react-intl'

const FeedNoteUser = (props) => {
  const { isMemComId, name, date, image } = props
  return (
    <>
      {isMemComId ? (
        <Col span={24} style={{ display: 'flex', justifyContent: 'end', textAlign: 'right' }}>
          <div style={{ paddingBottom: '0px' }} className="task-detail-assignee-padding-value">
            <div>
              <div className="note-name">{name}</div>
              <div className="note-date"><FormattedMessage id="noteDrawerNotedOn" defaultMessage="Noted on" /> {date}</div>
            </div>
            <div className="task-detail-assignee-detail" style={{ marginLeft: '20px' }}>
              <Badge className="badge-style-member">
                <Avatar size="large" shape="circle" src={image} />
              </Badge>
            </div>
          </div>
        </Col>
      ) : (
        <Col span={24}>
          <div style={{ paddingBottom: '0px' }} className="task-detail-assignee-padding-value">
            <div className="task-detail-assignee-detail">
              <Badge className="badge-style-member">
                <Avatar size="large" shape="circle" src={image} />
              </Badge>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <div className="note-name">{name}</div>
              <div className="note-date"><FormattedMessage id="noteDrawerNotedOn" defaultMessage="Noted on" /> {date}</div>
            </div>
          </div>
        </Col>
      )}
    </>
  )
}

export default FeedNoteUser
