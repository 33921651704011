import axios from 'axios';
import join from 'url-join';
import _ from 'lodash';

let isAbsoluteURLRegex = /^(?:\w+:)\/\//;

const memComId = localStorage.getItem('memComId');

const instance = axios.create();

instance.defaults.timeout = 86400;

instance.interceptors.request.use(async (config) => {

  var url = `${process.env.REACT_APP_URL_MANAGER}`;
  var port = `${process.env.REACT_APP_RESOURCE_MANAGER_OLD_PORT}`;
  if (!isAbsoluteURLRegex.test(config.url)) {

    if(config.url.indexOf('/v3/authorization/manager/') !== -1){
      port = `${process.env.REACT_APP_AUTHORIZATION_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v3/post/manager/') !== -1) {
      port = `${process.env.REACT_APP_POST_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v3/license/manager/') !== -1) {
      port = `${process.env.REACT_APP_LICENSE_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v3/invoice/manager/') !== -1) {
      port = `${process.env.REACT_APP_INVOICE_MANAGER_PORT}`;
    }
    else if (config.url.indexOf('/v3/item/manager/') !== -1) {
      port = `${process.env.REACT_APP_ITEM_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v3/custom-field/manager/') !== -1) {
      port = `${process.env.REACT_APP_CUSTOMFIELD_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v3/order/manager/') !== -1) {
      port = `${process.env.REACT_APP_ORDER_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v3/maintenance/manager/') !== -1) {
      port = `${process.env.REACT_APP_MAINTENANCE_MANAGER}`;
    }

    else if (config.url.indexOf('/v3/notification/manager/') !== -1) {
      port = `${process.env.REACT_APP_NOTIFICATION_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v3/kg-trading/manager/') !== -1) {
      port = `${process.env.REACT_APP_KG_TRADING_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v3/quotation/manager/') !== -1) {
      port = `${process.env.REACT_APP_QUOTATION_MANAGER_PORT}`;
    }

    else if (config.url.indexOf('/v1/warehouse/manager/') !== -1) {
      port = `${process.env.REACT_APP_WAREHOUSE_MANAGER_PORT}`;
    }

    else if (config.url === '/task/manager/organizations/task/'
      || config.url === '/task/manager/organizations/task/assign/merber/'
      || config.url.indexOf('/task/manager/task/') !== -1
      || config.url.indexOf('/v3/task/manager/') !== -1
      || config.url.indexOf('/v3/task/manager/task-resource/') !== -1
      || config.url.indexOf('/v3/task/manager/task-information/') !== -1
      || config.url.indexOf('/v3/task/manager/company/') !== -1
      || config.url.indexOf('/v3/task/manager/taskinform/update/') !== -1
      || config.url.indexOf('/v4/task/manager/') !== -1
    ) {
      port = `${process.env.REACT_APP_TASK_MANAGER_PORT}`;
    }
    else if (config.url.indexOf('/v3/business-partner/manager/') !== -1) {
      port = `${process.env.REACT_APP_BUSINESS_PARTNER_MANAGER_PORT}`;
    }
    else if (config.url.indexOf('/company/user/') !== -1
      || config.url.indexOf('/language/company') !== -1
      || config.url.indexOf('/payd-statements/') !== -1
      || config.url.indexOf('/companies/members') !== -1
      || config.url.indexOf('/organization/services/') !== -1
      || config.url.indexOf('/members/report/') !== -1
      || config.url.indexOf('/resource/manager/organization/') !== -1
      || config.url.indexOf('/resource/manager/shift-type/rules/') !== -1
      || config.url.indexOf('/companies/master-areas/') !== -1
      || config.url.indexOf('/v1/resource/manager/companies/') !== -1
      || config.url.indexOf('/v1/resource/manager/search/location/type/') !== -1
      || config.url.indexOf('/upload/members-documents') !== -1
      || config.url.indexOf('/members-documents/') !== -1
      || config.url.indexOf('/upload/members-profile') !== -1
      || config.url.indexOf('/members-profile') !== -1
      || config.url.indexOf('/leaves') !== -1
      || config.url.indexOf('/outside') !== -1
      || config.url.indexOf('/resource/manager/') !== -1
      || config.url.indexOf('/company/') !== -1
      || config.url.indexOf('/resource/manager/company/') !== -1
    ) {
      port = `${process.env.REACT_APP_RESOURCE_MANAGER_PORT}`;
    }

    let setUrl = url;
    if (port !== undefined) setUrl += `:${port}`;
    config.url = join(`${url}:${port}`, config.url);
  }

  // else if (config.url.indexOf('/customer/manager/') !== -1) {
  //   port = `8207`;
  // }

  config.mode = 'no-cors';
  config.credentials = 'same-origin';


  config.headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Max-Age': '86400',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Headers': 'Content-Type',
    // 'Keep-Alive': 'timeout=2, max=100',
    // 'Connection': 'Keep-Alive',
    'Content-Type': 'application/json',
    'memComId': memComId,
  };


  return config;
});

export default instance;
