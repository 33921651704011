import React from 'react';
import { Icon } from 'antd';
import stylePost from './css/post.css';
import _ from 'lodash'

export default ((props) => {
  const { action, postData, handleAddress, handleCloseAddress } = props;

  return (
    <div className='post-address' >
      <p
        className='post-card-text-11'
        onClick={() => handleAddress({
          addressLat: postData.lat,
          addressLng: postData.lng,
          addressName: postData.address,
        })}
      >
        - at {`${postData.address} >`}
      </p>
    </div>
  )
})