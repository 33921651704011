import React, { useContext } from 'react';
import { TaskDetailContext } from '../context';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { Avatar, Badge, Icon } from 'antd';

const ModalTaskDetailAssignee = () => {
  const { state, setState } = useContext(TaskDetailContext);
  const { detailData } = state;
  const intl = useIntl();
  const information = _.get(detailData, 'information');
  const assignee = _.get(information, 'assignee');

  const handleSelect = (memComId) => {
    const thisKeys = [...state.keys];

    const findIndexMemComId = _.findIndex(thisKeys, (val) => {
      return val === memComId ? true : false;
    });
    if (findIndexMemComId > -1) {
      thisKeys.splice(findIndexMemComId, 1);
    } else {
      thisKeys.push(memComId);
    }
    setState.setKeys(thisKeys);
  };

  const assigneeDetailData =
    assignee &&
    assignee.map((item) => {
      const findIndexMemComId = _.findIndex(state.keys, (val) => {
        return val === item.memComId ? true : false;
      });
      return (
        <div className="task-detail-assignee-padding-value">
          <div
            className={item.active === 0 ? 'task-detail-assignee-detail ' : 'task-detail-assignee-detail-disable'}
            onClick={() => handleSelect(item.memComId)}
          >
            <Badge
              className="badge-style-member"
              count={
                findIndexMemComId > -1 ? (
                  <Icon type="check-circle" theme="filled" style={{ color: '#1D3557' }} className="icon-correct-member" />
                ) : null
              }
            >
              <Avatar size="large" shape="circle" src={item.pathImage} />
            </Badge>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <div className="task-detail-assignee-text-name">{item.fullname}</div>
            <div className="task-detail-assignee-text-phone">{item.phone || '-'}</div>
            {item.subtask &&
              item.subtask.map((item, i) => {
                const iconExcel = () => {
                  if (item.done == true) {
                    return <img key={i} className="table-status-img" src={item.icon} />;
                  } else {
                    return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)', opacity: 0.5 }} />;
                  }
                };

                return (
                  <div className="table-status">
                    <div key={i} className="table-status-key">
                      <Icon className="table-status-icon" component={iconExcel} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    });

  return (
    <div style={{ padding: '0px 12px 24px 12px' }}>
      <span style={{ fontSize: '14px', color: '#65676B' }}>
        <FormattedMessage id="taskDetailAssigneeText" defaultMessage="Assignee" />
      </span>
      <div style={{ marginTop: '16px' }}>{assigneeDetailData}</div>
    </div>
  );
};

export default ModalTaskDetailAssignee;
