import React, { useState, useRef, useEffect } from 'react';
import { Input, Form } from 'antd';
import Card from '../../../components/v2/card';
import Table from '../../../components/v2/table';
import { useIntl, FormattedMessage } from 'react-intl';
import { notificationWithIcon } from '../../../components/notification';
import cssStyle from '../css/leave.css';
import clientNew from '../../../components/axiosClient';
import { PageSettings } from '../../../config/page-settings';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

const EditableCell = React.memo((props) => {
  const intl = useIntl();

  const {
    editable,
    dataIndex,
    title,
    record,
    index,
    children,
    updateLeavedata,
    ...restProps
  } = props;

  const [editing, setEditing] = useState(false);
  const [editLeaveId, setEditLeaveId] = useState();
  const [valueMaximum, setValueMaximum] = useState();

  const editCellRef = useRef();

  useEffect(() => {
    if (editLeaveId) {
      editCellRef.current.focus();
      editCellRef.current.select();
    }
  }, [editLeaveId]);

  const handleEdit = (id_row) => {
    if (id_row) {
      setEditLeaveId(id_row);
      setEditing(!editing);
    }
  };

  const saveLeaveData = (record) => {
    const valueMaxEdit = editCellRef.current.state.value;
    if (record.leaveTypeId) {
      if (record.leaveMaximum !== valueMaxEdit) {
        setValueMaximum(valueMaxEdit);
        updateLeavedata(record.leaveTypeId, valueMaxEdit);
      }
    }
    setEditLeaveId();
    setEditing();
  };

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if (
      (keyCode >= 48 && keyCode <= 57) ||
      keyCode === 65 ||
      keyCode === 66 ||
      keyCode === 46
    ) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const renderCell = (form) => {
    const { children, dataIndex, record, title } = props;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'userMgntUsersDetailLblReqMaximum',
                defaultMessage: 'Please input maximum / year (days)',
              }),
            },
            // {
            //   pattern: new RegExp(/^\d+(\.\d)?\d*$/),
            //   // message: 'Value should contain just number'
            // }
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={editCellRef}
            style={{ textAlign: 'center' }}
            onPressEnter={() => saveLeaveData(record)}
            onBlur={() => saveLeaveData(record)}
            className="input-width"
            onKeyDown={handleKeyDownNumber}
            autoComplete="off"
            maxLength={4}
          />,
        )}
      </Form.Item>
    ) : (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {checkPermissionAction('P2S1PG2C13', 'P2S1PG2C13A2') ? (
              <div
                className="editable-cell-value-wrap"
                onClick={() => handleEdit(record.leaveTypeId)}
              >
                {children}
              </div>
            ) : (
              <div>{children}</div>
            )}
          </div>
        )}
      </PageSettings.Consumer>
    );
  };

  return (
    <td {...restProps}>
      {editable ? (
        <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
});

export default React.memo((props) => {
  const { memComId } = props;
  const intl = useIntl();

  const [selectDateOrHour, setSelectDateOrHour] = useState('hour');
  const [leaveData, setLeaveData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  const columns = [
    {
      dataIndex: 'index',
      title: '#',
      align: 'center',
      width: 70,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      dataIndex: 'leaveType',
      title: intl.formatMessage({
        id: 'userMgntUsersDetailColLeaveType',
        defaultMessage: 'Leave Type',
      }),
    },
    {
      dataIndex: 'leaveUse',
      title:
        selectDateOrHour == 'day'
          ? intl.formatMessage({
              id: 'userMgntUsersDetailColLeaveUsedDays',
              defaultMessage: 'Leave Used (Days)',
            })
          : intl.formatMessage({
              id: 'userMgntUsersDetailColLeaveUsedHour',
              defaultMessage: 'Leave Used (Hour)',
            }),
      align: 'center',
    },
    {
      dataIndex: 'leaveMaximum',
      title:
        selectDateOrHour == 'day'
          ? intl.formatMessage({
              id: 'userMgntUsersDetailColLeaveMaximumDays',
              defaultMessage: 'Maximum/ Year (Days)',
            })
          : intl.formatMessage({
              id: 'userMgntUsersDetailColLeaveMaximumHour',
              defaultMessage: 'Maximum/ Year (Hour)',
            }),
      align: 'center',
      editable: true,
    },
    {
      dataIndex: 'leaveAvailable',
      title:
        selectDateOrHour == 'day'
          ? intl.formatMessage({
              id: 'userMgntUsersDetailColLeaveAvailableDays',
              defaultMessage: 'Available (Days)',
            })
          : intl.formatMessage({
              id: 'userMgntUsersDetailColLeaveAvailableHour',
              defaultMessage: 'Available (Hour)',
            }),
      align: 'center',
    },
  ];

  useEffect(() => {
    getApiLeave();
  }, [memComId, refreshTable, selectDateOrHour]);

  const getApiLeave = async () => {
    const result = await clientNew.get(
      `/v1/resource/manager/member/${memComId}/leaves?type=${selectDateOrHour}`,
    );
    if (result.data.status.code === 200) {
      setLeaveData(result.data.data.leaves);
    }
  };

  const updateLeavedata = async (leaveTypeId, leaveMaximum) => {
    const parseMaximum = parseFloat(leaveMaximum);

    if (
      isNaN(parseMaximum) ||
      parseMaximum === null ||
      parseMaximum === '' ||
      parseMaximum === undefined
    ) {
      return false;
    } else {
      const body = {
        leaveMaximum: Number(leaveMaximum),
        type: selectDateOrHour,
      };
      try {
        const result = await clientNew.put(
          `/v1/resource/manager/member/${memComId}/leaves/${leaveTypeId}`,
          body,
        );
        if (result) {
          refreshLeave();
          notificationWithIcon('success', result.data.status.message);
        }
      } catch {
        notificationWithIcon('error', 'Update fail.');
      }
      return true;
    }
  };

  const refreshLeave = () => {
    setRefreshTable(!refreshTable);
  };

  const columnsE = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        updateLeavedata: updateLeavedata,
      }),
      className: 'validate-error',
    };
  });

  console.log('test', columns);

  return (
    <div>
      <Card
        title={
          <FormattedMessage
            id="userMgntUsersDetailTitleLeave"
            defaultMessage="Annual Leave Balances"
          />
        }
        extra={
          <div>
            {
              // selectDateOrHour == 'day' ?
              //   (
              //     <div>
              //       <span onClick={() => setSelectDateOrHour('day')} className='select-type'>
              //         <FormattedMessage id="userMgntUsersDetailLeaveTableTxtDay" defaultMessage=" Day " />
              //       </span>
              //       <span> | </span>
              //       <span onClick={() => setSelectDateOrHour('hour')} className='not-select-type'>
              //         <FormattedMessage id="userMgntUsersDetailLeaveTableTxtHour" defaultMessage=" Hour " />
              //       </span>
              //     </div>
              //   )
              //   :
              <div>
                {/* <span onClick={() => setSelectDateOrHour('day')} className='not-select-type'>
                      <FormattedMessage id="userMgntUsersDetailLeaveTableTxtDay" defaultMessage=" Day " />
                    </span>
                    <span> | </span> */}
                <span
                  onClick={() => setSelectDateOrHour('hour')}
                  className="select-type"
                >
                  <FormattedMessage
                    id="userMgntUsersDetailLeaveTableTxtHour"
                    defaultMessage=" Hour "
                  />
                </span>
              </div>
            }
          </div>
        }
      >
        <Table
          // sizeWidth={'lg'}
          // size={'middle'}
          rowClassName={(record) => record.leaveTypeId}
          components={components}
          rowKey="id"
          columns={columnsE}
          dataSource={leaveData}
          pagination={false}
        />
      </Card>
    </div>
  );
});
