import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Input, Row, Col, Select, InputNumber } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import { getConvertUoM, getStockList } from '../../../../../controllers/po/po';
import _ from 'lodash'
import { numberFormatter, numberParser } from '../../../../../component-function/fnc-inputnumber-currency';
import LabeRequireForm from '../../../../../components/label-required-form';
import Button_01 from '../../../../../components/v2/button_01';
import Button_02 from '../../../../../components/v2/button_02';
import SuffixInput from '../../../../../components/v2/suffix';
import { Round } from '../../../../../component-function/lodash';

const { Option } = Select

const ListItemEdit = (props) => {
  const { data, visible, setVisible, form, selectItem, setSelectItem } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [uomList, setUomList] = useState([])
  const intl = useIntl()
  const qty = parseFloat(getFieldValue('qty') || 0)
  const price = parseFloat(getFieldValue('price') || 0.00)
  const discount = parseFloat(getFieldValue('discount') || 0)
  const amount = Round(qty * price)
  const inputNumberRef = useRef();
  const inputNumberDiscountRef = useRef();
  const inputNumberQTYRef = useRef();

  useEffect(() => {
    if (qty || price) {
      setFieldsValue({
        ['amount']: numberFormatter(qty * price)
      });
    }
  }, [qty, price])

  useEffect(() => {
    const getStockList = async () => {
      const unitData = await getConvertUoM(_.get(data, 'itemMasterId'))
      setUomList(_.get(unitData, 'data.data'))
    }
    if (data && visible) {
      getStockList()
    }
  }, [data, visible])

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      console.log("handleSave")
      const response = await getStockList({ 'itemMasterId': [_.get(data, 'itemMasterId')] })
      const filterUoM = _.filter(uomList, (item) => { return item.uomId === _.get(values, 'unit') })
      const itemBody = {
        "index": _.get(data, 'index'),
        "itemMasterId": _.get(data, 'itemMasterId'),
        "itemCode": _.get(data, 'itemCode'),
        "itemName": _.get(data, 'itemName'),
        "qty": parseFloat(_.get(values, 'qty')),
        "count": parseFloat(_.get(values, 'qty')),
        "countTemp": parseFloat(_.get(values, 'qty')),
        "uomId": _.get(values, 'unit'),
        "uomName": _.get(filterUoM, '[0].name'),
        "pricePerUnit": parseFloat(_.get(values, 'price')),
        "promotionCampaign": _.get(values, 'promotion'),
        "discountPercentage": parseFloat(_.get(values, 'discount') || 0),
        "discountPrice": parseFloat(_.get(values, 'discountNumber') || 0),
        "totalPrice": numberParser(_.get(values, 'total')),
        "remark": _.get(values, 'remark') || "",
        "instock": _.get(response, 'data.data[0].qty') || 0,
        "categoryCode": "service",
        "uuid": _.get(data, 'uuid'),
      }

      const updateSelectItem = [...selectItem];

      const filterSelectItemMaster = _.filter(updateSelectItem, (o) =>
        o.uuid !== itemBody.uuid &&
        o.itemMasterId === itemBody.itemMasterId &&
        o.uomId === itemBody.uomId &&
        o.pricePerUnit === itemBody.pricePerUnit &&
        o.discountPercentage === itemBody.discountPercentage
      ).map((obj) => {
        return {
          ...obj,
          totalPrice: parseFloat(obj.totalPrice) + parseFloat(itemBody.totalPrice),
          qty: _.toNumber(obj.qty) + _.toNumber(itemBody.qty),
          discountPrice: ((parseFloat(itemBody.pricePerUnit) * parseFloat(itemBody.discountPercentage)) / 100) * (_.toNumber(obj.qty) + _.toNumber(itemBody.qty)),
        }
      })

      if (_.size(filterSelectItemMaster) > 0) {
        const mapItem = _.map(updateSelectItem, (o) => {
          if (o.uuid === _.get(filterSelectItemMaster, '[0].uuid')) {
            return { ..._.get(filterSelectItemMaster, '[0]') }
          } else {
            return { ...o }
          }
        }).filter((obj) => obj.uuid !== itemBody.uuid)
        setSelectItem(mapItem);

      } else {
        const filterSelect = _.filter(selectItem, (item) => {
          return item.index !== _.get(data, 'index');
        });
        setSelectItem(filterSelect.concat([itemBody]));
      }

      // const filterSelect = _.filter(selectItem, (item) => {
      //   return item.index !== _.get(data, 'index')
      // })
      // setSelectItem(filterSelect.concat([itemBody]))
      setVisible(false)
      resetFields()
    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }


  const validatorQty = (rule, value, callback) => {
    if (value) {
      const parsValue = parseFloat(value || 0)
      if (parsValue === 0) {
        callback('QTY must more than 0');
      } else {
        callback();
      }
    }
    else {
      callback();
    }
  }

  const currencyFormatter = (value) => {
    return (_.toString(value || "")).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return (_.toString(value || "")).replace(/\$\s?|(,*)/g, '');
  };

  const mouseOver = (value) => {
    if(inputNumberRef.current) inputNumberRef.current.blur();
    if(inputNumberQTYRef.current) inputNumberQTYRef.current.blur();
    if(inputNumberDiscountRef.current) inputNumberDiscountRef.current.blur()
  };

  const handleChangeQTY = (e) => {
    const calAmount = Round(parseFloat(e) * price)
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: 0.00,
        ['discountNumber']: 0.00,
        ['total']: numberFormatter(calAmount),
      });
    } else {
      setFieldsValue({
        ['price']: 0.0,
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  const handleChangePrice = (e) => {
    const amount = Round(qty * parseFloat(e))
    const calTotalPercent = Round((amount * discount) / 100)
    const calTotalPrice = amount - calTotalPercent

    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calTotalPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  const handleChangeDiscount = (e) => {
    const calPercent = Round((amount * parseFloat(e)) / 100)
    const calTotalPrice = amount - calPercent
    console.log("calPercent", calPercent, calTotalPrice)
    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };

  const handleChangeDiscountNumber = (e) => {
    const calPercent = Round((parseFloat(e) / amount) * 100)
    const calTotalPrice = amount - parseFloat(e)
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };


  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemTitleEdit`, defaultMessage: 'Edit Item' })}
      centered={true}
      width={900}
      onCancel={handleCancel}
      visible={visible}
      bodyStyle={{ padding: 'unset', height: '315px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={handleCancel} onMouseOver={mouseOver}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleSave}
          style={{ margin: 'unset' }}
          // onMouseOver={() => {
          //   mouseOver();
          // }}
          onMouseOver={mouseOver}
        >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <div style={{ padding: '16px' }}>
        <div style={{ marginBottom: '28px' }}>
          <div style={{ marginBottom: '8px', color: '#1D3557', fontSize: '12px' }}>
            <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleItem', defaultMessage: 'Item' })} req={false} />
          </div>
          <div style={{ color: '#050505', fontSize: '13px' }}>
            {_.get(data, 'itemCode')} {_.get(data, 'itemName')}
          </div>
        </div>
        <Form form={form} layout="vertical">
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleQty', defaultMessage: 'Qty' })} req={true} />
                }
              >
                {getFieldDecorator('qty', {
                  initialValue: _.get(data, 'qty'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateQty', defaultMessage: 'Please Enter Qty' }),
                    },
                    {
                      validator: validatorQty
                    }
                  ],
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderQty', defaultMessage: 'Enter Qty' })}
                    onKeyDown={handleKeyDownNumber}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    ref={inputNumberQTYRef}
                    onChange={handleChangeQTY}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />
                }
              >
                {getFieldDecorator('unit', {
                  initialValue: _.get(data, 'uomId'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderUnit', defaultMessage: 'Select Unit' })}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {_.map(uomList, (item) =>
                      <Option value={item.uomId}>
                        {item.name}
                      </Option>
                    )}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitlePrice', defaultMessage: 'Price/Unit' })} req={true} />
                }
              >
                {getFieldDecorator('price', {
                  initialValue: _.get(data, 'pricePerUnit'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidatePrice', defaultMessage: 'Please Enter Price/Unit' }),
                    },
                  ],
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderPrice', defaultMessage: 'Enter Price/Unit' })}
                    suffix='THB'
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangePrice}
                  />
                )}
                <SuffixInput text='THB' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleAmount', defaultMessage: 'Amount' })} req={false} />
                }
              >
                {getFieldDecorator('amount', {
                  initialValue: _.get(data, 'pricePerUnit') * _.get(data, 'qty'),
                })(
                  <Input
                    maxLength={100}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleDiscount', defaultMessage: 'Discount' })} req={false} />
                }
              >
                {getFieldDecorator('discount', {
                  initialValue: _.get(data, 'discountPercentage'),
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    ref={inputNumberRef}
                    onChange={handleChangeDiscount}
                  />
                )}
                <SuffixInput text='%' />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label={
                  <LabeRequireForm text='' req={false} />
                }
              >
                {getFieldDecorator('discountNumber', {
                  initialValue: _.get(data, 'discountPrice'),
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    suffix='THB'
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangeDiscountNumber}
                    ref={inputNumberDiscountRef}
                  />
                )}
                <SuffixInput text='THB' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleTotal', defaultMessage: 'Total' })} req={false} />
                }
              >
                {getFieldDecorator('total', {
                  initialValue: _.get(data, 'totalPrice'),
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderTotal', defaultMessage: 'Enter Total' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    // suffix="THB"
                    readOnly={true}
                  />
                )}
                <SuffixInput text='THB' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {getFieldDecorator('remark', {
                  initialValue: _.get(data, 'remark'),
                })(
                  <Input
                    maxLength={100}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

    </Modal>
  )
}

const ModalEditListServiceItemPO = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() { },
})(ListItemEdit);

export default ModalEditListServiceItemPO
