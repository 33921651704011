import React, { useContext, useState, useEffect, useRef } from 'react';
import { Card, Icon, Avatar, Tag, Button, Menu } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import AssetSummaryContext from '../context';
import BlankAssetReport from './blankAssetClass';
import Button01 from '../../../components/v2/button_01';
import './css/index.css';
import ActionAssetClass from './actionAssetClass';
import DataAssetClassReport from './dataTableAssetClass';
import IconExcel from '../../../components/image/excel.svg';
import { searchTableAsset, teamAsset } from '../../../controllers/asset-resource/asset-controller';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import httpClient from '../../../components/axiosClient';
import { PageSettings } from '../../../config/page-settings';
import _ from 'lodash';
import ButtonActionGroup from '../../../components/action-button';

const comCode = localStorage.getItem('comCode');

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const AssetSummaryReport = () => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(AssetSummaryContext);
  const {
    toggle,
    total,
    assetSelect,
    fliterByColumn,
    fieldChange,
    orderSort,
    fieldSort,
    paginationPage,
    paginationSize,
    dataSource,
    orgData,
    orgId,
    purChaseStartDate,
    purChaseEndDate,
    purChaseMinPrice,
    purChaseMaxPrice,
    mainTrigger,
    assetSelectObj,
    price,
    checkedType,
    checkedBrand,
    checkedModel,
    checkedStatus,
    triggerItem,
    dataAsset,
    dataChecked,
    loading,
    isEdit,
  } = state;

  const { setVisibleRegister, setScrollTableX, setOrgData, setTotal, setDataSource, setLoading, setTriggerItem, setIsEdit } = setState;

  const { handleToggle } = fnc;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColImage`,
        defaultMessage: 'Image',
      }),
      dataIndex: 'pathImage',
      key: 'pathImage',
      width: 80,
      fixed: 'left',
      render: (text, record, index) => <Avatar shape="square" size={32} src={record.pathImage} />,
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColAssetNo`,
        defaultMessage: 'Asset No.',
      }),
      dataIndex: 'assetNo',
      key: 'assetNo',
      width: 170,
      fixed: 'left',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColAsset`,
        defaultMessage: 'Asset',
      }),
      dataIndex: 'assetView',
      key: 'assetView',
      // width: 250,
      // sorter: true,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColSuitable`,
        defaultMessage: 'Suitable',
      }),
      dataIndex: 'suitables',
      key: 'suitables',
      // width: 250,
      // sorter: true,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColStatus`,
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      // width: 140,
      align: 'center',
      render: (text, record, index) => (
        <div style={{ alignItems: 'center' }}>
          <Tag className="status-asset-tag" color={record.status.statusColor}>
            {record.status.statusView}
          </Tag>
        </div>
      ),
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColActionBy`,
        defaultMessage: 'Action By',
      }),
      dataIndex: 'actionBy',
      key: 'actionBy',
      // width: 250,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColSerialNo`,
        defaultMessage: 'Serial No.',
      }),
      dataIndex: 'serialNo',
      key: 'serialNo',
      // width: 200,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColRegisterBy`,
        defaultMessage: 'Register By',
      }),
      dataIndex: 'registerBy',
      key: 'registerBy',
      // width: 200,
      // sorter: true,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColInsuranceCompany`,
        defaultMessage: 'Insurance Company',
      }),
      dataIndex: 'insurance',
      key: 'insurance',
      // width: 200,
      // sorter: true,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColStartPeriod`,
        defaultMessage: 'Start Period',
      }),
      dataIndex: 'insuranceCoverageStart',
      key: 'insuranceCoverageStart',
      // width: 200,
      // sorter: true,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColEndPeriod`,
        defaultMessage: 'End period',
      }),
      dataIndex: 'insuranceCoverageEnd',
      key: 'insuranceCoverageEnd',
      // width: 200,
      // sorter: true,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `assetSummaryReportColNumberOfClaims`,
        defaultMessage: 'Number of claims',
      }),
      dataIndex: 'totalClaimable',
      key: 'totalClaimable',
      // width: 200,
      // sorter: true,
      // sortDirections: ['descend', 'ascend'],
    },
  ];

  const setShowColumn = {
    index: true,
    pathImage: false,
    assetNo: true,
    assetView: true,
    suitables: false,
    status: true,
    actionBy: true,
    serialNo: false,
    registerBy: false,
    insurance: true,
    insuranceCoverageStart: false,
    insuranceCoverageEnd: false,
    totalClaimable: false,
  };

  const setShowColumnArr = ['index', 'assetNo', 'assetView', 'status', 'actionBy', 'insurance'];

  const ignoreColumn = ['pathImage', 'serialNo', 'registerBy', 'insuranceCoverageStart', 'insuranceCoverageEnd', 'totalClaimable','suitables'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = [
    'index',
    'pathImage',
    'status',
    'registerBy',
    'insurance',
    'insuranceCoverageStart',
    'insuranceCoverageEnd',
    'totalClaimable',
  ];
  const columnNewSearch = columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({
    ...setShowColumn,
  });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const getTeamAsset = async () => {
      const payload = {
        assetClassId: assetSelect,
      };
      const result = await teamAsset(payload);
      setOrgData(result || []);
    };
    getTeamAsset();
  }, [assetSelect, mainTrigger]);

  useEffect(() => {
    const searchTable = async () => {
      setLoading(true);
      const body = {
        filter: {
          search: fieldChange,
          searchBy: fliterByColumn,
          startDate: purChaseStartDate,
          endDate: purChaseEndDate,
          orgId: orgId,
          price: price,
          assetClass: _.get(dataAsset, 'assetObj'),
          assetBrand: checkedBrand,
          assetModel: checkedModel,
          assetType: checkedType,
          assetStatus: checkedStatus,
        },
        paging: fieldChange ? 1 : paginationPage,
        rowsPerPages: paginationSize,
        orderType: orderSort,
        orderBy: fieldSort,
      };

      const response = await searchTableAsset(body);
      setDataSource(_.get(response, 'data') || 0);
      setTotal(_.get(response, 'total') || 0);

      setLoading(false);
      setTriggerItem(false);
    };
    if (_.get(dataAsset.assetObj[0], 'assetClassId')) {
      searchTable();
    }
  }, [
    _.get(dataAsset, 'assetObj'),
    checkedType,
    checkedBrand,
    checkedModel,
    checkedStatus,
    orgId,
    fliterByColumn,
    fieldChange,
    orderSort,
    fieldSort,
    paginationPage,
    paginationSize,
    price,
    triggerItem,
    purChaseStartDate,
    purChaseEndDate,
  ]);

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={handleToggle}>
          <Icon type={toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="assetCardListTitleAsset" defaultMessage="Asset" />
          </p>
        </div>
      </div>
    );
  };

  const handleExport = async () => {
    console.log('export');
    setDisabled(true);
    try {
      const body = {
        filter: {
          search: fieldChange,
          searchBy: fliterByColumn,
          startDate: purChaseStartDate,
          endDate: purChaseEndDate,
          orgId: orgId,
          price: price,
          assetClass: assetSelectObj,
          assetBrand: checkedBrand,
          assetModel: checkedModel,
          assetType: checkedType,
          assetStatus: checkedStatus,
        },
        paging: 1,
        rowsPerPages: total,
        orderType: orderSort,
        orderBy: fieldSort,
        viewFormat: [
          {
            colName: intl.formatMessage({
              id: 'assetSummaryReportColAssetNo',
              defaultMessage: 'Asset No.',
            }),
            sequence: 1,
            colCode: 'assetNo',
          },
          {
            colName: intl.formatMessage({
              id: 'assetSummaryReportColAsset',
              defaultMessage: 'Asset',
            }),
            sequence: 2,
            colCode: 'assetView',
          },
          {
            colName: intl.formatMessage({
              id: 'assetSummaryReportColStatus',
              defaultMessage: 'Status',
            }),
            sequence: 3,
            colCode: 'statusView',
          },
          {
            colName: intl.formatMessage({
              id: 'assetSummaryReportColActionBy',
              defaultMessage: 'Action By',
            }),
            sequence: 4,
            colCode: 'actionBy',
          },
          {
            colName: intl.formatMessage({
              id: 'assetSummaryReportColSerialNo',
              defaultMessage: 'Serial No.',
            }),
            sequence: 5,
            colCode: 'serialNo',
          },
          {
            colName: intl.formatMessage({
              id: 'assetSummaryReportColRegisterBy',
              defaultMessage: 'Register By',
            }),
            sequence: 6,
            colCode: 'registerBy',
          },
          {
            colName: intl.formatMessage({
              id: `assetSummaryReportColInsuranceCompany`,
              defaultMessage: 'Insurance Company',
            }),
            sequence: 7,
            colCode: 'insurance',
          },
          {
            colName: intl.formatMessage({
              id: `assetSummaryReportColStartPeriod`,
              defaultMessage: 'Start Period',
            }),
            sequence: 8,
            colCode: 'insuranceCoverageStart',
          },
          {
            colName: intl.formatMessage({
              id: `assetSummaryReportColEndPeriod`,
              defaultMessage: 'End period',
            }),
            sequence: 9,
            colCode: 'insuranceCoverageEnd',
          },
          {
            colName: intl.formatMessage({
              id: `assetSummaryReportColNumberOfClaims`,
              defaultMessage: 'Number of claims',
            }),
            sequence: 10,
            colCode: 'totalClaimable',
          },
        ],
      };
      const response = await httpClient.post(`/v3/item/manager/${comCode}/searchnewfilter/exportexcel`, body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
        setDisabled(false);
      } else {
        errorNotification(response.data.status.message);
        setDisabled(false);
      }
    } catch (error) {}
  };

  const iconExcel = () => <img className="icon-excel" src={IconExcel} />;

  const menu = (checkPermissionAction) => (
    <Menu>
      {checkPermissionAction('P36PG1C1', 'P36PG1C1A6') ? (
        <Menu.Item key="0">
          <p onClick={handleExport}>
            <FormattedMessage id="btnExport" defaultMessage="Export" />
          </p>
        </Menu.Item>
      ) : null}
      {checkPermissionAction('P36PG1C1', 'P36PG1C1A2') ? (
        <Menu.Item key="1">
          <p onClick={() => setVisibleRegister(true)}>
            <FormattedMessage id="assetRegister" defaultMessage="Register Asset" />
          </p>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <Card
      className="right-card-asset"
      title={<HeaderAndToggle />}
      extra={
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div style={{ display: 'flex' }}>
              {/* {checkPermissionAction('P36PG1C1', 'P36PG1C1A6') ? (
                <div style={{ marginRight: '10px' }}>
                  <Button className="button-link-export" type="link" onClick={handleExport} disabled={disabled}>
                    <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
                  </Button>
                </div>
              ) : null}
              {checkPermissionAction('P36PG1C1', 'P36PG1C1A2') ? (
                <div>
                  <Button01
                    type="primary"
                    btnsize="wd_at"
                    fontSize="sm"
                    onClick={() => setVisibleRegister(true)}
                    style={{ margin: 'unset' }}
                  >
                    <FormattedMessage id="assetRegister" defaultMessage="Register Asset" />
                  </Button01>
                </div>
              ) : null} */}
              <ButtonActionGroup menu={menu(checkPermissionAction)} />
            </div>
          )}
        </PageSettings.Consumer>
      }
    >
      <div className="layout-asset-report">
        {assetSelect ? (
          <div>
            <ActionAssetClass
              columns={columns}
              setShowColumn={setShowColumn}
              setShowColumnArr={setShowColumnArr}
              columnNewSearch={columnNewSearch}
              newDataColumns={newDataColumns}
              listArrayColumns={listArrayColumns}
              defaultShowColumn={defaultShowColumn}
              setScrollTableX={setScrollTableX}
              newColumns={newColumns}
              setNewDataColumns={setNewDataColumns}
              setListArrayColumns={setListArrayColumns}
              setDefaultShowColumn={setDefaultShowColumn}
            />
            <DataAssetClassReport newDataColumns={newDataColumns} />
          </div>
        ) : (
          <BlankAssetReport />
        )}
      </div>
    </Card>
  );
};

export default AssetSummaryReport;
