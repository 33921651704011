import React, { useState, useEffect } from 'react'
import './index.css'
import { Card, Tabs, Dropdown, Menu, Button, Modal, Tag } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { StickyContainer } from 'react-sticky'
import Button01 from '../../components/v2/button_01'
import { DownOutlined } from '@ant-design/icons'
import ExpenseMain from './components/main'
import _ from 'lodash'
import ExpenseCreate from './modal/create-expense'
import ExpenseEdit from './modal/edit-expense'
import { fncGetUsers } from '../../controllers/user/users'
import getVenderApi from '../../controllers/getCustomer/get-vendor'
import {
  getListExpense, getStatusList, getSummaryData, getExpenseTax, deleteExpesne,
  getBankList, getCreditCardType, getReasonPayment, ExportPoExpense
} from '../../controllers/expense/expense'
import ViewExpense from './modal/view'
import { searchPo } from '../../controllers/po/po'
import moment from 'moment'
import { successNotification, errorNotification } from '../../components/v2/notification'
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency'

export const ExpenseContext = React.createContext();

const { TabPane } = Tabs

const PageExpense = () => {
  const intl = useIntl()
  const langValue = localStorage.getItem('langValue');
  const [visibleCreate, setVisibleCreate] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [userList, setUserList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const tabList = [
    {
      title: intl.formatMessage({ id: `expenseTabsWatingApprove`, defaultMessage: 'Waiting for Approval' }),
      key: 'waiting',
      show: true,
      showStatus: true
    },
    {
      title: intl.formatMessage({ id: `expenseTabsWatingPayment`, defaultMessage: 'Waiting for Payment' }),
      key: 'approved',
      show: true,
      showStatus: false
    },
    {
      title: intl.formatMessage({ id: `expenseTabsPaid`, defaultMessage: 'Paid' }),
      key: 'paid',
      show: false,
      showStatus: false
    },
    {
      title: intl.formatMessage({ id: `expenseTabsRejected`, defaultMessage: 'Rejected' }),
      key: 'rejected',
      show: false,
      showStatus: false
    },
    {
      title: intl.formatMessage({ id: `expenseTabsCanceled`, defaultMessage: 'Canceled' }),
      key: 'canceled',
      show: false,
      showStatus: false
    }
  ]
  const [summaryList, setSummaryList] = useState()
  const [poList, setPoList] = useState([])
  const [expenseList, setExpenseList] = useState([])
  const [expenseTotal, setexpenseTotal] = useState(0)
  const [paginationPage, setPaginationPage] = useState(1)
  const [paginationSize, setPaginationSize] = useState(10);
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [visibleView, setVisibleView] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [defaultData, setDefaultData] = useState()
  const [searchGroup, setSearchGroup] = useState({})
  const [rangDate, setRangDate] = useState([])
  const [statusList, setStatusList] = useState([])
  const [statusListMaster, setStatusListMaster] = useState([])
  const [taxList, setTaxList] = useState([])
  const [bankList, setBankList] = useState([])
  const [creditCardTypeList, setCreditCardTypeList] = useState([])
  const [reasonPaymentList, setReasonPaymentList] = useState([])
  const [tabClick, setTabClick] = useState('waiting')
  const [tableLoading, setTableLoading] = useState(false)
  const [editData, setEditData] = useState()

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsExpenseNo', defaultMessage: 'Expense No.' }),
      dataIndex: 'expenseNo',
      key: 'expenseNo',
      fixed: 'left',
      sorter: true,
      width: 150
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsVendor', defaultMessage: 'Vendor or Supplier' }),
      dataIndex: 'vendorName',
      key: 'vendorName',
      width: 180,
      sorter: true,
      render: (text, record, index) => (_.get(record, 'vendor.vendorCode') || "") + " " + _.get(record, 'vendor.vendorName')
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsTotal', defaultMessage: 'Total' }),
      dataIndex: 'expenseTotal',
      key: 'expenseTotal',
      align: 'right',
      width: 200,
      sorter: true,
      render: (text, record, index) => `${numberFormatter(parseFloat(_.get(record, 'expenseTotal')))} THB`
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsStatus', defaultMessage: 'Status' }),
      dataIndex: 'statusName',
      key: 'statusName',
      align: 'center',
      sorter: true,
      render: (text, record, index) => (
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          <Tag className="tag-center-style" color={record.status.color}>
            {_.get(record, `status.lang[${langValue}]`)}
          </Tag>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsDueDate', defaultMessage: 'Due Date' }),
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: true,
      render: (text, record, index) => _.get(record, 'dueDate') ? moment(_.get(record, 'dueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsPONo', defaultMessage: 'PO No.' }),
      dataIndex: 'poNo',
      key: 'poNo',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsPODate', defaultMessage: 'PO Date' }),
      dataIndex: 'poDate',
      key: 'poDate',
      sorter: true,
      render: (text, record, index) => _.get(record, 'poDate') ? moment(_.get(record, 'poDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsInvoiceNo', defaultMessage: 'Invoice No.' }),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsInvoiceDate', defaultMessage: 'Invoice Date' }),
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      sorter: true,
      render: (text, record, index) => _.get(record, 'invoiceDate') ? moment(_.get(record, 'invoiceDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsIssuedBy', defaultMessage: 'Issued By.' }),
      dataIndex: 'issuedBy',
      key: 'issuedBy',
      width: 180,
      sorter: true,
      render: (text, record, index) => _.get(record, 'issued.name')
    },
    {
      title: intl.formatMessage({ id: 'expenseColumnsIssuedDate', defaultMessage: 'Issued Date' }),
      dataIndex: 'issuedDate',
      key: 'issuedDate',
      sorter: true,
      render: (text, record, index) => _.get(record, 'issuedDate') ? moment(_.get(record, 'issuedDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
  ];

  useEffect(() => {
    const initialData = async () => {
      const comId = localStorage.getItem('comId');
      const memComId = localStorage.getItem('memComId');
      const bodyPo = {
        "page": 1,
        "limit": 100000,
        "statusCode": [],
        "statusGroup": 'approved',
        "poNo": "",
        "createdDateFrom": "",
        "createdDateTo": "",
        "vendorSupplierId": [],
      }
      const poData = await searchPo(bodyPo)
      const userData = await fncGetUsers(memComId, comId, true);
      const vendorAll = await getVenderApi(10000);
      const statusData = await getStatusList()
      const taxData = await getExpenseTax()
      const bankList = await getBankList()
      const creditTypeList = await getCreditCardType()
      const paymentReasonList = await getReasonPayment()
      setPoList(_.get(poData, 'data.data.poList'))
      setUserList(userData);
      setVendorList(_.get(vendorAll, 'customers'));
      const mapWating = _.filter(_.get(statusData, 'data.result'), (item) => { return _.get(item, 'code') === 'draft' || _.get(item, 'code') === 'waiting' })
      setStatusListMaster(_.get(statusData, 'data.result'))
      setStatusList(mapWating)
      setTaxList(_.get(taxData, 'data.data'))
      setBankList(_.get(bankList, 'data.data'))
      setCreditCardTypeList(_.get(creditTypeList, 'data.data'))
      setReasonPaymentList(_.get(paymentReasonList, 'data.data'))
    }
    initialData()
  }, [])

  useEffect(() => {
    const listData = async () => {
      setTableLoading(true)
      const body = {
        "tab": tabClick,
        "startDate": _.get(rangDate, '[0]') ? moment(_.get(rangDate, '[0]')).format('YYYY-MM-DD') : "",
        "endDate": _.get(rangDate, '[1]') ? moment(_.get(rangDate, '[1]')).format('YYYY-MM-DD') : "",
        "paging": paginationPage,
        "rowsPerPages": paginationSize,
        "orderBy": fieldSort,
        "orderType": orderSort,
        "filter": {
          "expenseNo": _.get(searchGroup, 'expenseNo') || "",
          "vendorName": _.get(searchGroup, 'vendor') || "",
          "statusCode": _.get(searchGroup, 'status') || "",
          "dueStartDate": _.get(searchGroup, 'dueDate[0]') ? moment(_.get(searchGroup, 'dueDate[0]')).format('YYYY-MM-DD') : "",
          "dueEndDate": _.get(searchGroup, 'dueDate[1]') ? moment(_.get(searchGroup, 'dueDate[1]')).format('YYYY-MM-DD') : "",
          "poNo": _.get(searchGroup, 'poNo') || "",
          "invoiceNo": _.get(searchGroup, 'invoiceNo') || ""
        }
      }
      const response = await getListExpense(body);
      setExpenseList(_.get(response, 'data.data'))
      setexpenseTotal(_.get(response, 'data.total'))
      setTableLoading(false)
    }
    listData()
  }, [trigger, paginationPage, paginationSize, rangDate, fieldSort, orderSort, tabClick])

  useEffect(() => {
    const summaryData = async () => {
      const body = {
        "tab": tabClick
      }
      const summaryData = await getSummaryData(body)
      setSummaryList(_.get(summaryData, 'data.data'))
    }
    if (tabClick === "waiting" || tabClick === "approved") {
      summaryData()
    }
  }, [trigger, tabClick])


  const handleExport = async () => {
    try {
      const body = {
        "tab": tabClick,
        "startDate": _.get(rangDate, '[0]') ? moment(_.get(rangDate, '[0]')).format('YYYY-MM-DD') : "",
        "endDate": _.get(rangDate, '[1]') ? moment(_.get(rangDate, '[1]')).format('YYYY-MM-DD') : "",
        "paging": 1,
        "rowsPerPages": expenseTotal,
        "orderBy": fieldSort,
        "orderType": orderSort,
        "filter": {
          "expenseNo": _.get(searchGroup, 'expenseNo') || "",
          "vendorName": _.get(searchGroup, 'vendor') || "",
          "statusCode": _.get(searchGroup, 'status') || "",
          "dueStartDate": _.get(searchGroup, 'dueDate[0]') ? moment(_.get(searchGroup, 'dueDate[0]')).format('YYYY-MM-DD') : "",
          "dueEndDate": _.get(searchGroup, 'dueDate[1]') ? moment(_.get(searchGroup, 'dueDate[1]')).format('YYYY-MM-DD') : "",
          "poNo": _.get(searchGroup, 'poNo') || "",
          "invoiceNo": _.get(searchGroup, 'invoiceNo') || ""
        },
        "viewFormat": _.map(_.filter(columns, (e) => { return e.dataIndex !== 'index' }), (item, index) => {
          return {
            colName: item.title,
            sequence: index + 1,
            colCode: item.dataIndex,
          }
        })
      }
      const response = await ExportPoExpense(body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) { }
  };


  const handleOpenModalCreate = () => {
    setVisibleCreate(true)
  }

  const handleOpenModalEdit = (data) => {
    setEditData(data)
    setVisibleEdit(true)
  }

  const menuColumn = (
    <Menu>
      <Menu.Item onClick={handleOpenModalCreate}>
        <Button style={{ padding: 'unset' }} ghost type="link" >
          <FormattedMessage id="expenseBtnCreateExpnese" defaultMessage="Create Expense" />
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  const handleDelete = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteExpesne(id)
        if (response.status === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setTrigger(cur => !cur)
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      },
      onCancel() { },
    });

  }

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const handleOpenView = (value) => {
    setDefaultData(value)
    setVisibleView(true)
  }

  const onChangeSearch = async (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const onResetSearch = async () => {
    setSearchGroup({});
    setTrigger(cur => !cur)
  };

  const handleTabClick = (value) => {
    const mapWating = _.filter(statusListMaster, (item) => { return _.get(item, 'code') === 'draft' || _.get(item, 'code') === 'waiting' })
    if (value === "waiting") {
      setStatusList(mapWating)
    } else {
      setStatusList(statusListMaster)
    }
    setSearchGroup({});
    setExpenseList([])
    setTabClick(value)
    setPaginationPage(1)
  }



  return (
    <ExpenseContext.Provider
      value={{
        expenseList,
        expenseTotal,
        paginationPage,
        vendorList,
        poList,
        searchGroup,
        rangDate,
        statusList,
        summaryList,
        tableLoading,
        setTrigger,
        setRangDate,
        handleDelete,
        handleChange,
        handleOpenView,
        onChangeSearch,
        onResetSearch,
        handleOpenModalEdit
      }}
    >
      <div>
        <Card
          bodyStyle={{ padding: 'unset' }}
        >
          <StickyContainer>
            <Tabs
              className="expense-main-tabs"
              size="large"
              defaultActiveKey="1"
              animated={false}
              onTabClick={handleTabClick}
              activeKey={tabClick}
              tabBarExtraContent={
                <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                  <Button01 key="submit" type="primary" btnsize="wd_df">
                    <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                    <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                  </Button01>
                </Dropdown>
              }
            >
              {
                _.map(tabList, (item) => (
                  <TabPane tab={item.title} key={item.key}>
                    <ExpenseMain show={item.show} showStatus={item.showStatus} columns={columns} />
                  </TabPane>
                ))
              }
            </Tabs>
          </StickyContainer>
        </Card>

        <ExpenseCreate
          visible={visibleCreate}
          setVisible={setVisibleCreate}
          userList={userList}
          vendorList={vendorList}
          taxList={taxList}
          setTrigger={setTrigger}
        />

        <ExpenseEdit
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          userList={userList}
          vendorList={vendorList}
          taxList={taxList}
          setTrigger={setTrigger}
          defaultData={editData}
        />

        <ViewExpense
          visible={visibleView}
          setVisible={setVisibleView}
          trigger={trigger}
          setTrigger={setTrigger}
          defaultData={defaultData}
          setDefaultData={setDefaultData}
          handleDeleteExpense={handleDelete}
          bankList={bankList}
          creditCardTypeList={creditCardTypeList}
          userList={userList}
          reasonPaymentList={reasonPaymentList}
          handleOpenModalEdit={handleOpenModalEdit}
        />

      </div>
    </ExpenseContext.Provider>

  )
}

export default PageExpense
