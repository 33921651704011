import React, { useContext, useState, useRef } from 'react';
import { Table, Popover, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import AssetSummaryContext from '../context';
import { PageSettings } from '../../../config/page-settings';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import './css/dataTableAssetClass.css';

const DataAssetClassReport = (props) => {
  const { newDataColumns } = props;
  const history = useHistory();
  const { state, setState, fnc } = useContext(AssetSummaryContext);
  const { paginationPage, total, scrollTableX, dataSource, loading } = state;
  const { setPaginationPage, setPaginationSize, setOrderSort, setFieldSort } = setState;
  const { handleView } = fnc;
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          // key={index}
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          // visiblePopover={visiblePopover}
          // onVisibleChange={handleOnVisiblePopover}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P36PG1C1', 'P36PG1C1A4') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            history.push(`/main/resource/asset/asset-detail/${record.assetId}`);
                          }}
                        >
                          <FormattedMessage id="btnView" defaultMessage="View" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P36PG1C1', 'P36PG1C1A3') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record, 'edit')}>
                          <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        onChange={handleChange}
        loading={loading}
        columns={newDataColumns}
        rowKey={(record) => record.assetId}
        scroll={{ x: scrollTableX, y: `calc(100vh - 360px)` }}
        dataSource={dataSource}
        pagination={{
          total: total,
          current: paginationPage,
          showTotal: showTotal,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: handlePagination,
          onShowSizeChange: handleSizeChange,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              console.log('event', event, record);

              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />
      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      {dataSource.length > 0 ? (
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default DataAssetClassReport;
