import React, { useContext, useEffect, useRef } from 'react';
import { Icon, Divider } from 'antd';
import './css/index.css';
import { PlanningContext } from '../../context';
import _ from 'lodash';
import Nodata from '../../../../components/image/Nodata.svg';
import { FormattedMessage } from 'react-intl';

export default () => {
  const ref = useRef();
  const { state, fnc } = useContext(PlanningContext);
  const { vehicleScrollRef, vehicle, active, vehicleList } = state;
  const { handleSelectVehicle, onLoadMore } = fnc;

  const vehicleRender =
    vehicleList &&
    vehicleList.map((item) => {
      return (
        <div
          className={`div-load-and-route-list ${active === item.id ? 'active-select' : ''}`}
          key={item.id}
          onClick={() => handleSelectVehicle(item.id)}
        >
          <div className="lisence-load-and-route-div">
            <div>
              <span className={`text-header-vehicle ${active === item.id ? 'active-select-text' : ''}`}>
                {item.serialNo} {item.serialNo !== null ? '·' : ''} {item.assetClassName}{' '}
              </span>
            </div>
            <div>
              <img
                src={item.icon}
                className={active === item.id || item.sending === 'vehicle_sending' ? 'vehicleSvg-active' : 'vehicleSvg'}
              />
            </div>
          </div>
          {_.get(item.borrower, 'fullname') ? (
            <div style={{ marginBottom: '4px' }}>
              <span className={`text-span-vehicle ${active === item.id ? 'active-select-text' : ''}`}>
                {_.get(item.borrower, 'fullname')} · {_.get(item.borrower, 'phone')}{' '}
              </span>
            </div>
          ) : null}
          <div>
            <span className={`text-span-vehicle ${active === item.id ? 'active-select-text' : ''}`}>
              {item.loadedView} · {item.orderTotal} · {item.itemTotal}{' '}
            </span>
          </div>
        </div>
      );
    });

  return (
    <div ref={vehicleScrollRef} className="list-vehicles scroll-sm">
      {vehicleList && _.size(vehicleList) !== 0 ? (
        vehicleRender
      ) : (
        <div className="postby-no-member">
          <div>
            <img src={Nodata} style={{ width: '100px' }}></img>
          </div>
          <div style={{ color: '#e4e4e4' }}>
            <FormattedMessage id="textNoData" defaultMessage="No Data" />
          </div>
        </div>
      )}
    </div>
  );
};
