import React, { useState, useMemo, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  AutoComplete,
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import './css/index.css';
import getSearchLocation from '../../function/search-location/getLocation';
import Map from './map-location';
import _, { debounce } from 'lodash';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const ModalMapLocation = React.memo((props) => {
  const intl = useIntl();
  const { visible, setLatlngCustomer, onCancel, onCreate, form, stateCustomer, setStateCustomer, triggerCancel, setTriggerResetFields, defalseLocation } = props;
  const { getFieldDecorator } = form;
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')

  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);
  const [addressArea, setAddressArea] = useState([]);
  const [locationAddress, setLocationAddress] = useState("");
  const [address, setAddress] = useState("")
  const [locationName, setlocationName] = useState("")

  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await getSearchLocation({ name: fieldChang });
    setAddressArea(newData);
  }, 200);

  const handleSelect = (key, option) => {
    if (isNaN(key) == false) {
      form.setFieldsValue({ ['location']: option.props.children });
      form.setFieldsValue({ ['description']: option.props.children });
      setPosition({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setCenter({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setLatlngCustomer({ lat: addressArea[key].lat, lng: addressArea[key].lng });
    } else {
      setPosition(latlng);
      setCenter(latlng);
      form.setFieldsValue({
        ['location']: undefined
      });
    }
  };

  useEffect(() => {
    if (stateCustomer.visibleModalFromEdit === true) {
      setlocationName(_.get(defalseLocation, 'locationName'));
      setLocationAddress(_.get(defalseLocation, 'address'));
      setAddress(_.get(defalseLocation, 'description'))
      setPosition({ lat: _.get(defalseLocation, 'lat'), lng: _.get(defalseLocation, 'lng') });
      setCenter({ lat: _.get(defalseLocation, 'lat'), lng: _.get(defalseLocation, 'lng') });
    }

  }, [defalseLocation, stateCustomer.visibleModalFromEdit]);

  useMemo(() => {
    form.setFieldsValue({
      ['location']: stateCustomer.valueAddress
    });
    form.setFieldsValue({
      ['description']: stateCustomer.valueAddress
    });
  }, [stateCustomer.valueAddress]);

  const children = addressArea.map(item =>
    <Option key={item.key}>
      {item.address}
    </Option>
  );

  return (
    <Modal
      className="create-task-modal"
      title={intl.formatMessage({ id: 'ModalLocationHeaderEditLocation', defaultMessage: 'Edit Location' })}
      visible={visible}
      onCancel={onCancel}
      onOk={onCreate}
      centered
      width={824}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={onCreate}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationName', defaultMessage: 'Location Name' })} req={true} />}
        >
          {getFieldDecorator('locationName', {
            // initialValue: stateCustomer.dataFromLocationEdit ? _.get(stateCustomer, 'cusDataDefalse.locationName') : undefined,
            initialValue: _.get(defalseLocation, 'locationName'),
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationValidateLocationName', defaultMessage: 'Please enter location name' })
              },
            ],
          })(
            <Input placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderLocationName', defaultMessage: 'Enter Location Name' })} autoComplete="off" />
          )}
        </Form.Item>

        <Form.Item label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationAddress', defaultMessage: 'Address' })} req={true} />}>
          {getFieldDecorator('description', {
            // initialValue: stateCustomer.dataFromLocationEdit ? _.get(stateCustomer, 'cusDataDefalse.description') : undefined,
            initialValue: address,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationLocationValidateAddress', defaultMessage: 'Please enter address' })
              },
            ],
          }
          )(
            <Input placeholder={intl.formatMessage({ id: 'ModalLocationLocationPlaceholderAddress', defaultMessage: 'Enter Address' })} autoComplete="off" ></Input>
          )}
        </Form.Item>

        <Form.Item
          label={<LabeRequire text={intl.formatMessage({ id: 'ModalLocationLocationMapLocation', defaultMessage: 'Map Location' })} req={true} />}
        >
          {getFieldDecorator('location', {
            // initialValue: locationAddress ? locationAddress : undefined,
            initialValue: locationAddress,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalLocationLocationValidateMapLocation', defaultMessage: 'Please enter map location' })
              },
            ],
          })(
            <AutoComplete
              placeholder={intl.formatMessage({ id: 'ModalLocationLocationPlaceholderMapLocation', defaultMessage: 'Enter Map Location' })}
              dataSource={addressArea}
              onSearch={handleSearchLocation}
              onSelect={handleSelect}
              filterOption={false}
            >
              {children}
            </AutoComplete>
          )}
        </Form.Item>



        <div>
          <Map
            position={position}
            center={center}
            setPosition={setPosition}
            setCenter={setCenter}
            setLatlngCustomer={setLatlngCustomer}
            setStateCustomer={setStateCustomer}
            setTriggerResetFields={setTriggerResetFields}
          />
        </div>
      </Form>
    </Modal>
  )
})

const ModalMap = Form.create({
  name: 'modal_form',
})(ModalMapLocation);

export default React.memo(ModalMap);