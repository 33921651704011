import { Table, Card, Col, Row, Tabs, Button, Icon, Menu, Dropdown, Popover } from 'antd';
import React, { useContext, useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { Sticky, StickyContainer } from 'react-sticky';
import Css from './css/index.css';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { AllticketContext } from '../allticket-context';
import { PageSettings } from '../../../../../config/page-settings';
import NoDataBackground from '../../../../../components/no-data-page';

const { TabPane } = Tabs;

const RightCardAllticket = () => {
  const { state, setState, fnc } = useContext(AllticketContext);
  const { checkPermissionAction } = useContext(PageSettings);
  const { checkedKeysRequest, checkedKeysTeam, isLoading, toggle, selectedRowKeys, ticketData, page, total } = state;
  const { setToggle, setEvent, setSort, setRequestDetailData,setVendorFilterdata,setLotNo } = setState;
  const { handleChangePage, handleChangeSize, handleVisibleCreate, handleView, handleEdit, closeTicketModal } = fnc;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(1000);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  // const data = _.get(ticketData, 'data.ticketList');
  // const data = ticketData;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      fixed: 'left',
      // render: (text, record, index) => <span>{(index + 1).toString()}</span>,
    },
    {
      title: <FormattedMessage id="recivePOPONo" defaultMessage="PO No." />,
      dataIndex: 'poNo',
      key: 'poNo',
      sorter: true,
      fixed: 'left',
      width: 180,
    },
    {
      title: <FormattedMessage id="recivePOItem" defaultMessage="Item" />,
      dataIndex: 'itemAmount',
      key: 'itemAmount',
      // sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="recivePOTotalPrice" defaultMessage="Total Price" />,
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      // sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="AllTicketStatus" defaultMessage="Status" />,
      dataIndex: 'statusName',
      key: 'statusName',
      sorter: true,
      width: 200,
      align: 'center',
      render: (data, record) => {
        return (
          <span>
            <p
              style={{
                // backgroundColor: record['statusColor'],
                backgroundColor: _.get(record, 'statusColor'),
                color: 'white',
                textAlign: 'center',
                borderRadius: '10px',
              }}
            >
              {data}
            </p>
          </span>
        );
      },
      // width: 200,
    },
    {
      title: <FormattedMessage id="recivePOPODate" defaultMessage="PO Date" />,
      key: 'poDate',
      dataIndex: 'poDate',
      width: 150,
      sorter: true,
    },
  ];

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item>
          <Button ghost type="link" onClick={handleVisibleCreate}>
            <FormattedMessage id="btnCreate" defaultMessage="Create" />
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div>
          <p>
            <FormattedMessage id="recivePOPurchaseOrder" defaultMessage="Purchase Order" />
          </p>
        </div>
      </div>
    );
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  return (
    <div>
      <Card title={<HeaderAndToggle />} className="right-cards">
        <div className="containerTables" onMouseLeave={handleMouseLeave}>
          <Row gutter={16}>
            <Table
              className="custom-table-claim"
              dataSource={ticketData || []}
              columns={columns}
              scroll={{ x: scrollTableX, y: `calc(100vh - 357px)` }}
              loading={isLoading}
              onChange={onChangeTable}
              rowClassName="rowcursor"
              pagination={{
                total: totalItems,
                current: page,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showSizeChanger: true,
                locale: { items_per_page: '' },
                showTotal: showTotal,
                onChange: handleChangePage,
                onShowSizeChange: handleChangeSize,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (!event.target.href) {
                      // const { x, y } = ref.current.getBoundingClientRect();
                      // setVisiblePopoverTable(true);
                      // setOffsetPopover([event.pageX - x, event.pageY - y]);
                      // setRecordPopover(record);
                      setVendorFilterdata(record);
                      setRequestDetailData(record);
                      setLotNo([]);
                      closeTicketModal();
                    }
                  },
                };
              }}
            />
            {/* {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)} */}
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${totalItems || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default RightCardAllticket;
