import React from 'react';
import CustomerForm from './customer-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import { messageLabel } from '../../../../components/message-component';
import CustomTableComponent from '../../components/table';
import PriceForm from './price-form';
import { Button, Divider, Dropdown } from 'antd';
import { cutStrNumberFormatter, numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';

const DetailsTab = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    dataForm,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    handleOpenQuotation,
    formCustomerRef,
    isOpenQuotation,
    record,
    editItem,
    handleDeleteAttAchment,
    menuColumn,
  } = property;

  console.log('dataForm ->', dataForm);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 120,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      render: (text, record, index) => record.qty + ' ' + record.uomName,
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (text, record, index) =>
        numberFormatter(record.pricePerUnit) + ' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' }),
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnCredits', defaultMessage: 'Credit (days)' }),
      dataIndex: 'credits',
      key: 'credits',
      sorter: true,
      render: (text, record, index) => record.credits || '-',
    },
    // {
    //   title: intl.formatMessage({ id: 'quotationLblColumnPromotionCampaign', defaultMessage: 'Promotion or Campaign' }),
    //   dataIndex: 'promotionCampaign',
    //   key: 'promotionCampaign',
    //   render: (text, record, index) => record.promotionCampaign || '-',
    // },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: (text, record, index) =>
      numberFormatter(parseFloat(record.discountPrice ? record.discountPrice : 0).toFixed(2)) + ' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' }),
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      fixed: 'right',
      // width: 125,
      render: (text, record, index) =>
      numberFormatter(parseFloat(record.totalPrice).toFixed(2)) + ' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' }),
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      width: 350,
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  console.log('issueDate', form.getFieldValue('date'), form.getFieldValue('validDate'), form.getFieldValue('validDay'));

  const deleteAtt = (value) => {
    console.log('deleteAtt', value);
    if (_.get(value, 'form') === 'backend') {
      handleDeleteAttAchment(_.get(value, 'id'));
    }

    const filterSelect = _.filter(attData, (item) => {
      return item.id !== value.id;
    });
    setAttData(filterSelect);
  };

  const handleEditUploadInside = (value) => {
    handleOpenModalUpload(value);
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const deleteItem = (value) => {
    console.log('selectItem ------ ', selectItem, record, value)
    const filterSelect = _.filter(selectItem, (item) => {
      return item.uuid !== value
    });
    console.log('selectItem filter', filterSelect)
    setSelectItem(filterSelect);
  };

  const contentActionItem = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => editItem(record)}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div>

        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record.uuid)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="scroll-sm scroll-height" style={{height: '550px'}}>
      <div className="content-body">
        <CustomerForm
          form={form}
          setDataForm={setDataForm}
          dataForm={dataForm}
          initialMemberList={initialMemberList}
          handleOpen={handleOpen}
          handleOpenQuotation={handleOpenQuotation}
          formCustomerRef={formCustomerRef}
          isOpenQuotation={isOpenQuotation}
          record={record}
        />
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelItem" defaultMessage="Item" />
          </span>
          <div>
            {/* <Button_01 key="add" disabled={!_.get(dataForm, 'customerName')} style={{ margin: '0px 0px 0px 10px' }} type="primary" btnsize="wd_df" onClick={() => setVisibleItem(true)}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01> */}

            <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }}>
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button_01>
            </Dropdown>
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columns}
            dataSource={selectItem}
            // total={_.size(selectItem)}
            scroll={{ x: true }}
            contentAction={contentActionItem}
            rowKey={(record) => record.uuid}
          />
          <div className="content-body-price">
            <PriceForm
              form={form}
              selectItem={selectItem}
              setDataForm={setDataForm}
              dataForm={dataForm}
              initialTaxList={initialTaxList}
              record={record}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelAttactment" defaultMessage="Attactment" />
          </span>
          <div>
            <Button_01
              key="add"
              style={{ margin: '0px 0px 0px 10px' }}
              type="primary"
              btnsize="wd_df"
              onClick={() => handleOpenModalUpload()}
            >
              <FormattedMessage id="btnUpload" defaultMessage="Upload" />
            </Button_01>
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columnsAttach}
            dataSource={attData}
            scroll={{ x: true }}
            contentAction={contentAction}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsTab;
