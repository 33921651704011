import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { MaintenanceContext } from './maintenance-context';
import getItemUnit from '../../controllers/maintenance-report/get-item-unit';
import getTaxDiscount from '../../controllers/maintenance-report/get-tax-discount';
import MaintenanceDetail from './maintenance-detail';
import MaintenanceParts from './maintenance-parts';
import MaintenanceLabors from './maintenance-labors';
import MaintenanceCalculate from './maintenance-calculate';
import MaintenanceAttachment from './maintenance-attachment';
import ModalAddParts from './modal-add-item-parts';
import ModalAddLabors from './modal-add-item-labors';
import ModalAddAttachment from './modal-add-attachment';
import ModalEditAttachment from './modal-edit-attachment';
import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { Card, Icon, Tag, Modal } from 'antd';
import _, { debounce } from 'lodash';
import moment from 'moment';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import httpClient from '../axiosClient';
import { successNotification, errorNotification, warningNotification } from '../v2/notification';
import { PageSettings } from '../../config/page-settings';
import './css/index.css';
import getCustomerVenderApi from '../../controllers/getCustomer/get-customer-vender';
import Resizer from 'react-image-file-resizer';
import { checkDocument } from '../../controllers/maintenance-report/getDocumentNo';

const CreateMaintenanceReport = (props) => {
  const { visibleModal, setVisibleModal, setTriggerAPI } = props;
  const memComId = localStorage.getItem('memComId');
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const today = new Date();

  const columnsParts = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsPartCode', defaultMessage: 'Part Code' }),
      dataIndex: 'partCode',
      key: 'partCode',
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsPartNo', defaultMessage: 'Part No.' }),
      dataIndex: 'partNo',
      key: 'partNo',
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsPartName', defaultMessage: 'Part Name' }),
      dataIndex: 'partDescription',
      key: 'partDescription',
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'partQty',
      key: 'partQty',
      align: 'center',
      width: 100,
      render: (text, record, index) => <NumberFormat value={parseFloat(record.partQty)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsUnit', defaultMessage: 'Unit' }),
      dataIndex: 'itemUnitName',
      key: 'itemUnitName',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsUnitPrice', defaultMessage: 'Unit Price (Baht)' }),
      dataIndex: 'partPrice',
      key: 'partPrice',
      align: 'right',
      width: 160,
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.partPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsTotal', defaultMessage: 'Total (Baht)' }),
      dataIndex: 'chargeAmount',
      key: 'chargeAmount',
      align: 'right',
      width: 130,
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.chargeAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      render: (text, record, index) => (
        <div className="table-column-delete-dot" onClick={() => handleDeleteItemParts(index)}>
          <div className="table-column-delete-line-style" />
        </div>
      ),
    },
  ];

  const columnsLabors = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsDescription', defaultMessage: 'Description' }),
      dataIndex: 'laborDescription',
      key: 'laborDescription',
    },
    {
      title: intl.formatMessage({ id: 'modalMaintenanceColumnsChargeAmount', defaultMessage: 'Charge Amount (Baht)' }),
      dataIndex: 'chargeAmount',
      key: 'chargeAmount',
      width: 250,
      align: 'right',
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.chargeAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      render: (text, record, index) => (
        <div className="table-column-delete-dot" onClick={() => handleDeleteItemLabors(index)}>
          <div className="table-column-delete-line-style" />
        </div>
      ),
    },
  ];

  const [formRefAddItemParts, setFormRefAddItemParts] = useState(null);
  const [formRefAddItemLabors, setFormRefAddItemLabors] = useState(null);

  //-----------------------maintenanceDetail --------------------------
  const [maintenanceDetail, setMaintenanceDetail] = useState(null);
  const refAddress = useRef();
  const [disabledSave, setDisabledSave] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [vendorData, setVendorData] = useState();
  const [vendorAddress, setVendorAddress] = useState();
  const [itemName, setItemName] = useState([]);
  const [itemNo, setItemNo] = useState([]);
  const [itemCode, setItemCode] = useState([]);
  const [itemNoAll, setItemNoAll] = useState([]);
  const [venderId, setVenderId] = useState();
  const [itemMasterId, setItemMasterId] = useState();
  const [addressId, setAddressId] = useState();
  const [itemNoId, setItemNoId] = useState();
  const [numberState, setNumberState] = useState();
  //-------------------------------------------------------------------
  const [dataParts, setDataParts] = useState([]); // Data API Parts
  const [visibleParts, setVisibleParts] = useState(false);
  const [focusInputParts, setFocusInputParts] = useState(true);
  const [triggerAddItemParts, setTriggerAddItemParts] = useState(false);
  const [sumSubtotalParts, setSumSubtotalParts] = useState(0);

  const [dataLabors, setDatalabors] = useState([]); // Data API Labors
  const [visibleLabors, setVisibleLabors] = useState(false);
  const [focusInputLabors, setFocusInputLabors] = useState(true);
  const [triggerAddItemLabors, setTriggerAddItemLabors] = useState(false);
  const [sumSubtotalLabors, setSumSubtotalLabors] = useState(0);

  const [visibleAttachment, setVisibleAttachment] = useState(false);
  const [visibleEditAttachment, setVisibleEditAttachment] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [taxDiscountData, setTaxDiscountData] = useState();

  const [sumCalculate, setSumCalculate] = useState({});
  const [discountSelete, setDiscountSelete] = useState({});
  const [taxSelete, setTaxSelete] = useState({});
  const [valueInputDiscount, setValueInputDiscount] = useState(0.0);
  const [valueInputAdjust, setValueInputAdjust] = useState(0.0);
  const [valueInputRemark, setValueInputRemark] = useState('');

  const [editAttachmentData, setEditAttachmentData] = useState();

  //-------------------------- Detail FNC ------------------------------------
  useEffect(() => {
    const getDataVender = async () => {
      const response = await getCustomerVenderApi(10000);
      if (response) {
        setVendorData(response.customers);
      }
    };
    getDataVender();
  }, [trigger]);

  //--------------------------------------------------------------------------

  useEffect(() => {
    if (visibleModal === true) {
      async function getAPI() {
        axios.all([await getItemUnit(), await getTaxDiscount()]).then((resData) => {
          let unit = resData[0];
          let taxDiscount = resData[1];
          setUnitData(unit);
          setTaxDiscountData(taxDiscount);
          fncTaxDiscount(taxDiscount);
        });
      }
      getAPI();
    }
  }, [visibleModal]);

  const fncTaxDiscount = (taxDiscount) => {
    if (taxDiscount !== undefined) {
      const resultDiscount =
        taxDiscount &&
        taxDiscount.discount.find((discountData) => {
          return discountData.isdefault === true;
        });

      const resultTax =
        taxDiscount &&
        taxDiscount.tax.find((taxData) => {
          return taxData.isdefault === true;
        });

      setDiscountSelete(resultDiscount);
      setTaxSelete(resultTax);
    } else {
      setDiscountSelete({
        discountId: '1689d686-4b8f-4fdb-aff2-1cc4acd78dcd',
        isBath: false,
        isdefault: true,
        percentage: 0,
        titleCode: '0%',
      });
      setTaxSelete({
        isdefault: true,
        percentage: 0,
        taxId: 'fe41cd1b-8579-4d5c-82dc-ae1b3f932fc0',
        titleCode: '0%',
      });
    }
  };

  const saveMaintenanceDetail = useCallback((node) => {
    if (node !== null) {
      setMaintenanceDetail(node);
    }
  }, []);

  const handleCancel = (e) => {
    setVisibleModal(false);
    setDisabledSave(false);
    setVendorAddress();
    maintenanceDetail.resetFields();
    refAddress.current.innerHTML = 'Address';
    setValueInputDiscount(0.0);
    setValueInputAdjust(0.0);
    setValueInputRemark('');
    setDiscountSelete({});
    setTaxSelete({});
    setItemName([]);
    setItemNo([]);
    setItemCode([]);
    setDataParts([]);
    setDatalabors([]);
    setVenderId();
    setItemMasterId();
    setAddressId();
    setAttachmentData([]);
    setItemNoId();
    setNumberState();
  };

  const handleCreate = async (e) => {
    maintenanceDetail.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        const valCheck = await checkDocument(values.docNo);

        if (valCheck === false) {
          maintenanceDetail.setFields({
            docNo: {
              value: values.docNo,
              errors: [new Error(intl.formatMessage({ id: 'duplicateDataDocNo', defaultMessage: 'docNo is duplicate' }))],
            },
          });
          return;
        }
      } catch (error) {
        return;
      }

      if (dataParts.length === 0) {
        errorNotification(intl.formatMessage({ id: 'errorMaintenanceNoParts', defaultMessage: 'parts is no data' }));
        return;
      }
      if (dataLabors.length === 0) {
        errorNotification(intl.formatMessage({ id: 'errorMaintenanceNoLabors', defaultMessage: 'labors is no data' }));
        return;
      }

      setDisabledSave(true);
      const busPartnerName =
        vendorData &&
        vendorData.find((data) => {
          return data.customerId === values.venderId;
        });

      const busPartnerAddress =
        vendorAddress &&
        vendorAddress.find((data) => {
          return data.customerAddressBookId === values.address;
        });

      const itemData =
        itemName &&
        itemName.find((data) => {
          return data.itemMasterId === values.itemName;
        });

      let arr = [];
      _.map(itemNoAll, (el) => {
        arr.push(...el.item);
      });

      const FilterItemNoId =
        arr &&
        arr.find((data) => {
          return data.itemId === values.itemNo;
        });

      const body = {
        referenceDocNo: values.docNo,
        itemId: values.itemNo,
        itemNo: FilterItemNoId.itemNo,
        itemCode: itemData.itemCode,
        itemName: itemData.itemName,
        itemMasterId: itemData.itemMasterId,
        reference1: undefined,
        reference2: undefined,
        reference3: undefined,
        remark: valueInputRemark,
        maintenanceServiceBy: `${memComId}`,
        maintenanceServiceDate: moment(values.maintenanceDate).format('YYYY-MM-DD 00:00:00'),
        nextMaintenceServiceDate: values.nextMaintenanceDate ? moment(values.nextMaintenanceDate).format('YYYY-MM-DD 00:00:00') : undefined,
        businessPartnerId: values.venderId,
        businessPartnerName: busPartnerName.customerName,
        businessPartnerAddress: busPartnerAddress.address,
        businessPartnerAddressId: values.address,
        businessPartnerPhone: values.phone,
        taxId: taxSelete.taxId,
        discountId: discountSelete.discountId,
        adjust: valueInputAdjust,
        laborCharge: Number(sumSubtotalLabors),
        partCharge: Number(sumSubtotalParts),
        discount: Number(parseFloat(sumCalculate.discount).toFixed(2)),
        tax: Number(parseFloat(sumCalculate.tax).toFixed(2)),
        total: Number(parseFloat(sumCalculate.total).toFixed(2)),
        laborDetail: dataLabors,
        partDetail: dataParts,
      };

      if (parseFloat(sumCalculate.total).toFixed(2) < 0) {
        errorNotification(<FormattedMessage id="maintenanceTexNotiSaveLessThan" defaultMessage="The amount cannot be negative." />);
        setDisabledSave(false);
      } else {
        try {
          const response = await httpClient.post(`/v3/maintenance/manager/company/${comCode}/new-maintenance/`, body);

          if (response.status === 200) {
            if (response.data.status.code === 400) {
              errorNotification(response.data.status.message);
              setDisabledSave(false);
            } else {
              await fncCreateAttachment(response.data.data.maintenance.maintenanceId);
              setVisibleModal(false);
              setDisabledSave(false);
              setTriggerAPI((current) => !current);
              maintenanceDetail.resetFields();
              refAddress.current.innerHTML = 'Address';
              setValueInputDiscount(0.0);
              setValueInputAdjust(0.0);
              setValueInputRemark('');
              setDiscountSelete({});
              setTaxSelete({});
              setItemName([]);
              setItemNo([]);
              setItemCode([]);
              setVenderId();
              setItemMasterId();
              setAddressId();
              setDataParts([]);
              setDatalabors([]);
              setAttachmentData([]);
              successNotification(response.data.status.message);
              setItemNoId();
              setNumberState();
            }
          }
        } catch (error) {
          // if (error.response.status === 500) {
          //   errorNotification(error.response.data.error);
          // }
          setVisibleModal(false);
          setDisabledSave(false);
        }
      }
    });
  };

  //--------------------------cal table-------------------------------
  const subTotalResultParts = useMemo(() => {
    const totalItemPart =
      dataParts &&
      dataParts.map((item) => {
        return item.chargeAmount;
      });
    const numbers = totalItemPart.map((n) => {
      return parseFloat(n, 10);
    });
    const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
    setSumSubtotalParts(_.floor(sumTotal, 2).toFixed(2));
    return _.floor(sumTotal, 2).toFixed(2);
  }, [dataParts.length]);

  const subTotalResultLabors = useMemo(() => {
    const totalItemLabors =
      dataLabors &&
      dataLabors.map((item) => {
        return item.chargeAmount;
      });
    const numbers = totalItemLabors.map((n) => {
      return parseFloat(n, 10);
    });
    const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
    setSumSubtotalLabors(_.floor(sumTotal, 2).toFixed(2));
    return _.floor(sumTotal, 2).toFixed(2);
  }, [dataLabors.length]);

  const saveFormRefAddItemParts = useCallback((node) => {
    if (node !== null) {
      setFormRefAddItemParts(node);
    }
  }, []);

  const saveFormRefAddItemLabors = useCallback((node) => {
    if (node !== null) {
      setFormRefAddItemLabors(node);
    }
  }, []);

  const addItemParts = () => {
    const ItemDataParts = [...dataParts];
    formRefAddItemParts.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        if (values.partNo) {
          const validatePartNo = ItemDataParts.filter((col) => {
            return values.partNo === col.partNo;
          });
          if (validatePartNo.length > 0) {
            formRefAddItemParts.setFields({
              partNo: {
                value: values.partNo,
                errors: [new Error(intl.formatMessage({ id: 'duplicatePartNo', defaultMessage: 'part no. is  duplicate' }))],
              },
            });
            return;
          } else {
            const unitName = _.filter(unitData, (e) => {
              return e.itemsUnitId === values.unit;
            });
            const unitNameString = _.map(unitName, 'itemsUnitName');

            ItemDataParts.push({
              partCode: values.partCode,
              partNo: values.partNo,
              partDescription: values.partName,
              partQty: Number(values.qty),
              partPrice: values.unitPrice === '.' ? _.floor(0, 2).toFixed(2) : _.floor(values.unitPrice, 2).toFixed(2),
              chargeAmount: _.floor(values.total, 2).toFixed(2),
              itemUnitId: values.unit,
              itemUnitName: unitNameString.toString(),
            });
            setDataParts(ItemDataParts);
            formRefAddItemParts.resetFields();
            setTriggerAddItemParts((current) => !current);
          }
        }
      }
    });
  };

  const fncCreateAttachment = (maintenanceId) => {
    if (maintenanceId) {
      attachmentData.forEach(async (item) => {
        try {
          const body = {
            maintenanceId: maintenanceId,
            name: item.attachmentName,
            description: item.remark,
          };

          const response = await httpClient.post(`/v3/maintenance/manager/company/${comCode}/maintenance/new-attachment/detail`, body);

          if (response.status === 200) {
            item.file.forEach(async (file) => {
              if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
                const image = await resizeFile(file.originFileObj);
                const fileChange = dataURLtoFile(image, file.originFileObj.name.toLowerCase());

                let formData = new FormData();
                formData.append('file', file.size < 92160 ? file.originFileObj : fileChange);
                formData.append('type', 'photo');
                formData.append('maintenanceAttachmentDetailId', _.get(response, 'data.data.added.maintenanceAttachmentDetailId'));
                formData.append('maintenanceId', _.get(response, 'data.data.added.maintenanceId'));

                try {
                  const response = await httpClient.post(`/v3/maintenance/manager/company/${comCode}/maintenance/new-attachment`, formData);
                } catch (error) {
                  return;
                }
              } else if (file.name.match(/\.(pdf|PDF)$/)) {
                let formDataPDF = new FormData();
                formDataPDF.append('file', file.originFileObj);
                formDataPDF.append('type', 'pdf');
                formDataPDF.append('maintenanceAttachmentDetailId', _.get(response, 'data.data.added.maintenanceAttachmentDetailId'));
                formDataPDF.append('maintenanceId', _.get(response, 'data.data.added.maintenanceId'));

                try {
                  await httpClient.post(`/v3/maintenance/manager/company/${comCode}/maintenance/new-attachment`, formDataPDF);
                } catch (error) {
                  return;
                }
              }
            });
          }
        } catch (error) {
          return;
        }
      });
      setTriggerAPI((current) => !current);
    }
  };

  const handleKeyUpParts = (event) => {
    event.preventDefault();
    const ItemDataParts = [...dataParts];
    if (event.keyCode === 13) {
      formRefAddItemParts.validateFields((err, values) => {
        if (err) {
          return;
        } else {
          if (values.partNo) {
            const validatePartNo = ItemDataParts.filter((col) => {
              return _.trim(values.partNo) === _.trim(col.partNo);
            });
            if (validatePartNo.length > 0) {
              formRefAddItemParts.setFields({
                partNo: {
                  value: values.partNo,
                  errors: [new Error(intl.formatMessage({ id: 'duplicatePartNo', defaultMessage: 'part no. is  duplicate' }))],
                },
              });
              return;
            } else {
              const unitName = _.filter(unitData, (e) => {
                return e.itemsUnitId === values.unit;
              });
              const unitNameString = _.map(unitName, 'itemsUnitName');

              ItemDataParts.push({
                partCode: values.partCode,
                partNo: values.partNo,
                partDescription: values.partName,
                partQty: Number(values.qty),
                partPrice: values.unitPrice === '.' ? _.floor(0, 2).toFixed(2) : _.floor(values.unitPrice, 2).toFixed(2),
                chargeAmount: _.floor(values.total, 2).toFixed(2),
                itemUnitId: values.unit,
                itemUnitName: unitNameString.toString(),
              });
              setDataParts(ItemDataParts);
              formRefAddItemParts.resetFields();
              formRefAddItemParts.setFieldsValue({ ['unitPrice']: undefined });
              setTriggerAddItemParts((current) => !current);
            }
          }
        }
      });
    }
  };

  const addItemLabors = () => {
    const ItemDataLabors = [...dataLabors];
    formRefAddItemLabors.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        if (values.description) {
          const validateDescription = ItemDataLabors.filter((col) => {
            return _.trim(values.description) === _.trim(col.laborDescription);
          });
          if (validateDescription.length > 0) {
            formRefAddItemLabors.setFields({
              description: {
                value: values.description,
                errors: [new Error(intl.formatMessage({ id: 'duplicateDescription', defaultMessage: 'description is duplicate' }))],
              },
            });
            return;
          } else {
            ItemDataLabors.push({
              laborCode: undefined,
              laborDescription: values.description,
              chargeAmount: values.amount === '.' ? _.floor(0, 2).toFixed(2) : _.floor(values.amount, 2).toFixed(2),
            });
            setDatalabors(ItemDataLabors);
            formRefAddItemLabors.resetFields();
            setTriggerAddItemLabors((current) => !current);
          }
        }
      }
    });
  };

  const handleKeyUpLabors = (event) => {
    event.preventDefault();
    const ItemDataLabors = [...dataLabors];
    if (event.keyCode === 13) {
      formRefAddItemLabors.validateFields((err, values) => {
        if (err) {
          return;
        } else {
          if (values.description) {
            const validateDescription = ItemDataLabors.filter((col) => {
              return _.trim(values.description) === _.trim(col.laborDescription);
            });
            if (validateDescription.length > 0) {
              formRefAddItemLabors.setFields({
                description: {
                  value: values.description,
                  errors: [new Error(intl.formatMessage({ id: 'duplicateDescription', defaultMessage: 'description is duplicate' }))],
                },
              });
              return;
            } else {
              ItemDataLabors.push({
                laborCode: undefined,
                laborDescription: values.description,
                chargeAmount: values.amount === '.' ? _.floor(0, 2).toFixed(2) : _.floor(values.amount, 2).toFixed(2),
              });
              setDatalabors(ItemDataLabors);
              formRefAddItemLabors.resetFields();
              formRefAddItemLabors.setFieldsValue({ ['amount']: undefined });
              setTriggerAddItemLabors((current) => !current);
            }
          }
        }
      });
    }
  };

  const handleCancelParts = () => {
    setVisibleParts(false);
    formRefAddItemParts.resetFields();
  };

  const handleCancelLabors = () => {
    setVisibleLabors(false);
    formRefAddItemLabors.resetFields();
  };

  const handleDeleteItemParts = (index) => {
    const dataItemNew = dataParts && dataParts.filter((col, i) => i !== index);
    setDataParts(dataItemNew);
  };

  const handleDeleteItemLabors = (index) => {
    const dataItemNew = dataLabors && dataLabors.filter((col, i) => i !== index);
    setDatalabors(dataItemNew);
  };

  const modalEditAttachment = (record, index) => {
    setEditAttachmentData(record, index);
    setVisibleEditAttachment(true);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  return (
    <div className="create-maintenance-report">
      <MaintenanceContext.Provider
        value={{
          mainState: {
            attachmentData,
            taxDiscountData,
            discountSelete,
            taxSelete,
            valueInputDiscount,
            valueInputAdjust,
            valueInputRemark,
          },
          setMainState: {
            setAttachmentData,
            setDiscountSelete,
            setTaxSelete,
            setValueInputDiscount,
            setValueInputAdjust,
            setSumCalculate,
            setValueInputRemark,
          },
          fncMain: {
            modalEditAttachment,
          },
        }}
      >
        <Modal
          className="maintenance-report-modal"
          title={intl.formatMessage({ id: 'modalMaintenanceReportTitleCreate', defaultMessage: 'Create Maintenance Report' })}
          centered="true"
          width={1100}
          visible={visibleModal}
          onCancel={handleCancel}
          footer={[
            <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel} margin=" unset">
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 key="submit" btnsize="wd_df" type="primary" disabled={disabledSave} onClick={handleCreate}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>,
          ]}
        >
          <div className="maintenance-modal-body">
            <div className="modal-container">
              <MaintenanceDetail
                ref={saveMaintenanceDetail}
                refAddress={refAddress}
                vendorData={vendorData}
                vendorAddress={vendorAddress}
                setTrigger={setTrigger}
                setVendorAddress={setVendorAddress}
                itemName={itemName}
                setItemName={setItemName}
                itemNo={itemNo}
                setItemNo={setItemNo}
                itemCode={itemCode}
                setItemCode={setItemCode}
                itemNoAll={itemNoAll}
                setItemNoAll={setItemNoAll}
                setVenderId={setVenderId}
                venderId={venderId}
                itemMasterId={itemMasterId}
                setItemMasterId={setItemMasterId}
                addressId={addressId}
                setAddressId={setAddressId}
                itemNoId={itemNoId}
                setItemNoId={setItemNoId}
                numberState={numberState}
                setNumberState={setNumberState}
              />
              <MaintenanceParts columns={columnsParts} dataSource={dataParts} setVisible={setVisibleParts} />
              <MaintenanceLabors columns={columnsLabors} dataSource={dataLabors} setVisible={setVisibleLabors} />
              <MaintenanceCalculate sumSubtotalParts={sumSubtotalParts} sumSubtotalLabors={sumSubtotalLabors} />
              <MaintenanceAttachment setVisible={setVisibleAttachment} />
            </div>
          </div>
        </Modal>

        <ModalAddAttachment visible={visibleAttachment} setVisible={setVisibleAttachment} />

        <ModalEditAttachment
          visible={visibleEditAttachment}
          setVisible={setVisibleEditAttachment}
          defaultData={editAttachmentData}
          setEditAttachmentData={setEditAttachmentData}
        />
      </MaintenanceContext.Provider>

      <ModalAddParts
        ref={saveFormRefAddItemParts}
        visible={visibleParts}
        focusInput={focusInputParts}
        triggerAddItem={triggerAddItemParts}
        handleCancel={handleCancelParts}
        handleAddItem={addItemParts}
        handleKeyUp={handleKeyUpParts}
        unitData={unitData}
      />

      <ModalAddLabors
        ref={saveFormRefAddItemLabors}
        visible={visibleLabors}
        focusInput={focusInputLabors}
        triggerAddItem={triggerAddItemLabors}
        handleCancel={handleCancelLabors}
        handleAddItem={addItemLabors}
        handleKeyUp={handleKeyUpLabors}
      />
    </div>
  );
};

export default CreateMaintenanceReport;
