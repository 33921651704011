import React, { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Col, Row, Tag, InputNumber } from 'antd';
import { CreateTaskContext } from './create-task-context';
import TabInformation from './create-task-information';
import TabMoreDetail from './create-task-more-detail';
import Team from './create-task-team';
import Assignee from './create-task-assignee';
import './css/index.css';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../v2/button_01';
import Button02 from '../../../v2/button_02';
import _ from 'lodash';
import {
  getTaskTypeData,
  getCustomerData,
  getCustomerLocationAndConatact,
  getCustomeField,
  addContact,
  addFromLocation,
} from '../../../../controllers/task/create-task';
import AddBusinessPartner from '../../../add-business-partner/index';
import AddContact from '../../../add-contact-v2/index';
import ModalAssignee from './create-task-assignee-modal';
import { EditorState } from 'draft-js';
import moment from 'moment';
import httpClient from '../../../../components/axiosClient';
import { deleteImg, CreateDataMoreDetail } from '../../../../controllers/more-detail/more-detail-api';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import CompanyLocation from '../../../../components/modal-select-company-location/index';
import CustomerLocation from '../../../../components/modal-select-customer-location/index';
import { useAppContext } from '../../../../includes/indexProvider';

const { TabPane } = Tabs;
const { confirm } = Modal;

export default React.memo((props) => {
  const { visible, setVisible, setTriggerTaskDetail } = props;
  const intl = useIntl();
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  //-------------------Ref --------------------------
  const informationRef = useRef();
  //-------------------Visible --------------------------
  const [visibleCustomer, setVisibleCustomer] = useState(false);
  const [visibleContact, setVisibleContact] = useState(false);
  const [visibleAddCompanyAddress, setVisibleAddCompanyAddress] = useState(false);
  const [visibleEditComapanyAddress, setVisibleEditComapanyAddress] = useState(false);
  const [visibleAddCustomerAddress, setVisibleAddCustomerAddress] = useState(false);
  const [visibleEditCustomerAddress, setVisibleEditCustomerAddress] = useState(false);
  const [visibleAssignee, setVisibleAssignee] = useState(false);
  //-------------------Data --------------------------
  const [taskTypeData, setTaskTypeData] = useState([]);
  const [priorityData, setPriorityData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [contactData, setContactData] = useState([]);

  const [fromLocationData, setFromLocationData] = useState([]);
  const [toLocationData, setToLocationData] = useState([]);
  const [editFromLocationData, setEditFromLocationData] = useState();
  const [editTolocationData, setEditTolocationData] = useState();
  const [customFieldData, setCustomFieldData] = useState([]);
  //------------------- New Data from Modal ------------
  const [newCustomer, setNewCustomer] = useState();
  const [newContact, setNewContact] = useState();
  const [newCompanyAddress, setNewCompanyAddress] = useState();
  const [newCustomerAddress, setNewCustomerAddress] = useState();
  //------------------- Utility -----------------------
  const [defaultPriority, setDefaultPriority] = useState();
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();

  const [selectFromAddress, setSelectFromAddress] = useState([]);
  const [createFromData, setCreateFromData] = useState();
  const [fromOutSide, setFromOutSide] = useState();
  const [triggerFrom, setTriggerFrom] = useState(false);
  const [visibleAddNewCompany, setVisibleAddNewCompany] = useState(false);
  const [statusFromAdd, setStatuFromsAdd] = useState('in');
  const [keyMenu, setKeyMenu] = useState([]);
  const [centerFrom, setCenterFrom] = useState(latlng);
  const [positionFrom, setPositionFrom] = useState(latlng);
  const [visibleInfoWindowFrom, setVisibleInfoWindowFrom] = useState(false);

  const [selectToAddress, setSelectToAddress] = useState([]);
  const [createToData, setCreateToData] = useState();
  const [toOutSide, setToOutSide] = useState();
  const [triggerTo, setTriggerTo] = useState(false);
  const [visibleAddNewCustomer, setVisibleAddNewCustomer] = useState(false);
  const [statusToAdd, setStatusToAdd] = useState('in');
  const [keyMenuTo, setKeyMenuTo] = useState([]);
  const [centerTo, setCenterTo] = useState(latlng);
  const [positionTo, setPositionTo] = useState(latlng);
  const [visibleInfoWindowTo, setVisibleInfoWindowTo] = useState(false);

  //----------------- Temporary Data ---------------------------
  const [temporaryData, setTemporaryData] = useState([]);
  const [orgId, setOrgId] = useState();
  const [taskTypeId, setTaskTypeId] = useState();
  const [member, setMember] = useState([]);
  const [triggerCustomer, setTriggerCustomer] = useState(false);
  const [triggerContact, setTriggerContact] = useState(false);
  const [triggerCustomerAddress, setTriggerCustomerAddress] = useState(false);
  const [triggerCompanyAddress, setTriggerCompanyAddress] = useState(false);
  const [disTancePolyline, setDisTancePolyline] = useState();
  const [dataMemberAssignee, setDataMemberAssignee] = useState([]);

  const [numberTask, setNumberTask] = useState(1);
  const [editorStaet, setEditorStaet] = useState(EditorState.createEmpty());
  const [imgSrc, setImgSrc] = useState([]);
  const [allSrc, setAllSrc] = useState([]);
  const [disbleEditor, setDisbleEditor] = useState(false);
  const [statusSave, setStatusSave] = useState();
  const [textValue, setTextValue] = useState('');
  const comId = localStorage.getItem('comId');
  const [filterAssignee, setFilterAssignee] = useState();
  const [filterAvailable, setFilterAvailable] = useState();
  const [filterNotAvailable, setFilterNotAvailable] = useState();
  const [filterCandidate, setFilterCandidate] = useState();
  const [filterOff, setFilterOff] = useState();
  const [filterOnGoing, setFilterOnGoing] = useState();

  const [selectTab, setSelectTab] = useState();

  const [pageAssigneeAvailable, setPageAssigneeAvailable] = useState(1);
  const [pageSizeAssigneeAvailable, setPageSizeAssigneeAvailable] = useState(10);
  const [loadingAvailable, setLoadingAvailable] = useState(false);

  const [pageAssigneeNotAvailable, setPageAssigneeNotAvailable] = useState(1);
  const [pageSizeAssigneeNotAvailable, setPageSizeAssigneeNotAvailable] = useState(10);
  const [loadingNotAvailable, setLoadingNotAvailable] = useState(false);

  const [searchUnassignee, setSearchUnassignee] = useState('');
  const [fieldSortAvailable, setFieldSortAvailable] = useState();
  const [orderSortAvailable, setOrderSortAvailable] = useState();
  const [fieldSortNotAvailable, setFieldSortNotAvailable] = useState();
  const [orderSortNotAvailable, setOrderSortNotAvailable] = useState();

  const [memberAvailable, setMemberAvailable] = useState();
  const [memberNotAvailable, setMemberNotAvailable] = useState();
  const [memberAssignee, setMemberAssignee] = useState([]);

  const [memberAvailableArrray, setMemberAvailableArrray] = useState([]);
  const [memberNotAvailableArrray, setMemberNotAvailableArrray] = useState([]);
  const [refData, setRefData] = useState([]);


  const handleCreate = () => {
    let infoError = false;
    let teamError = false;

    let setInfo = null;
    informationRef.current.validateFields((err, values) => {
      setInfo = values;
      if (err) {
        return (infoError = true);
      }
    });

    if (infoError !== true) {
      confirm({
        className: 'customer-modal-confirm',
        centered: true,
        icon: null,
        title: intl.formatMessage({
          id: `monitorCreateTaskModalConfirm`,
          defaultMessage: 'Are you sure to save task.',
        }),
        okText: intl.formatMessage({
          id: 'modalBtnConfirm',
          defaultMessage: 'Confirm',
        }),
        cancelText: intl.formatMessage({
          id: 'modalBtnCancel',
          defaultMessage: 'Cancel',
        }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        async onOk() {
          const mapMember = dataMemberAssignee.map((e) => {
            return {
              memComId: e.memComId,
              memOrgId: e.memOrgId,
              orgId: e.orgId,
            };
          });
          const numberFromStart = _.get(setInfo, 'startDate')
            ? Number(moment(_.get(setInfo, 'startDate'), 'YYYY-MM-DD HH:mm:ss').unix())
            : undefined;
          const numberToStart = _.get(setInfo, 'dueDate')
            ? Number(moment(_.get(setInfo, 'dueDate'), 'YYYY-MM-DD HH:mm:ss').unix())
            : undefined;
          const filterName = contactData && contactData.filter((col) => setInfo.contact.includes(col.customerContactId));
          const filterRef = refData && refData.filter(col => { return col.referenceNo === setInfo.reference })

          const body = {
            version: '2',
            statusCode: 'new',
            orgId: _.get(setInfo, 'team') ? _.get(setInfo, 'team') : '',
            assignees: mapMember ? mapMember : '',
            orderRefCode: ' ',
            planStart: numberFromStart ? numberFromStart : '',
            planFinish: numberToStart ? numberToStart : '',
            cusId: _.get(setInfo, 'customer') ? _.get(setInfo, 'customer') : '',
            cusName: customerName && customerName !== undefined ? customerName : '',
            cusEmail: ' ',
            cusPhone: '',
            deliveryAddress: _.get(createToData, 'address') ? _.get(createToData, 'address') : '',
            toAddressName: _.get(createToData, 'name') ? _.get(createToData, 'name') : '',
            lat: _.get(createToData, 'lat') ? _.get(createToData, 'lat') : '',
            lng: _.get(createToData, 'lng') ? _.get(createToData, 'lng') : '',
            formAddressName: _.get(createFromData, 'name') ? _.get(createFromData, 'name') : '',
            fromAddress: _.get(createFromData, 'address') ? _.get(createFromData, 'address') : '',
            fromLat: _.get(createFromData, 'lat') ? _.get(createFromData, 'lat') : '',
            fromLng: _.get(createFromData, 'lng') ? _.get(createFromData, 'lng') : '',
            remark: _.get(setInfo, 'remark') ? _.get(setInfo, 'remark') : undefined,
            sla: '',
            requestNo: '',
            priorityId: _.get(setInfo, 'priority') ? _.get(setInfo, 'priority') : '',
            customerContactId: _.get(setInfo, 'contact') !== 'temporary' ? _.get(setInfo, 'contact') : undefined,
            contacts: [
              {
                name: filterName && filterName !== undefined ? filterName[0].customerContactName : '-',
                phone: _.get(setInfo, 'phone') ? _.get(setInfo, 'phone') : '-',
                email: filterName && filterName !== undefined ? filterName[0].customerContactEmail : '-',
                position: filterName && filterName !== undefined ? filterName[0].position : '-',
                entityRef: filterName && filterName[0].customerContactId !== 'temporary' ? filterName[0].customerContactId : undefined,
                entityType: filterName && filterName[0].customerContactId !== 'temporary' ? 'customer-contact' : undefined,
              },
            ],
            fromAddressId: _.get(createFromData, 'status') === 'customer_address' ? _.get(createFromData, 'addressBookId') : undefined,
            toAddressId: _.get(createToData, 'status') === 'customer_address' ? _.get(createToData, 'addressBookId') : undefined,
            copy: numberTask,
            taskName: _.get(setInfo, 'taskName') ? _.get(setInfo, 'taskName') : '',
            reference_type: _.size(filterRef) !== 0 ? _.get(filterRef, '[0].referenceType') : undefined,
            reference: _.size(filterRef) !== 0 ? _.get(filterRef, '[0].referenceNo') : setInfo.reference,
            reference_id: _.size(filterRef) !== 0 ? _.get(filterRef, '[0].referenceId') : undefined,
          };

          if (customFieldData && customFieldData !== undefined) {
            for (let index = 0; index < customFieldData.length; index++) {
              // const element = array[index];
              const entityToField = _.get(customFieldData[index], 'entityToField');
              if (entityToField) {
                body[entityToField] = _.get(setInfo, entityToField) || '';
              }
            }
          }

          try {
            const response = await httpClient.post(
              `/v3/task/manager/company/${comId}/task-type/${setInfo.taskType}/add-task/?menuType=workforce`,
              body
            );
            if (response.status == 200) {
              if (textValue && textValue !== '<p></p>' && textValue !== '<p></p>\n') {
                const replaceValue1 = _.replace(textValue, '<code>', '#ABC#');
                const replaceValue2 = _.replace(replaceValue1, '</code>', '#ABC#');
                const splitValue = _.split(replaceValue2, '#ABC#');
                const stringValue = `${splitValue[0]}<span>${splitValue[1]}</span>${splitValue[2] !== 'undefined' ? splitValue[2] : ''}`;
                const diff = _.differenceBy(allSrc, imgSrc, 'fullPathIs');
                const mapDiff =
                  diff &&
                  diff.map((item) => {
                    return item.fileCodeIs;
                  });
                await deleteImg(mapDiff);
                await CreateDataMoreDetail(_.get(response, 'data.data.taskId'), stringValue);
                setAllSrc([]);
                setImgSrc([]);
              }
              successNotification(response.data.status.message);
              setRefData([])
              setVisible(false);
              setNumberTask(1);
              setNewCustomer();
              setNewContact();
              setCustomerId();
              setCustomerName();
              setNewCustomerAddress();
              setNewCompanyAddress();
              informationRef.current.resetFields();
              setOrgId();
              setTaskTypeId();
              setDataMemberAssignee([]);
              setTextValue('');
              setDisTancePolyline();
              setContactData([]);
              setStatusSave();
              setCreateFromData();
              setSelectFromAddress();
              setStatuFromsAdd('in');
              setCreateToData();
              setSelectToAddress();
              setStatusToAdd('in');
              setCustomFieldData([]);
              setEditorStaet(EditorState.createEmpty());
              setTriggerTaskDetail((current) => !current);
              setNumberTask(1);
            } else {
              errorNotification(response.data.status.message);
            }
          } catch (error) {
            errorNotification(error.response.data.status.message);
            setVisible(false);
            setNumberTask(1);
            setNewCustomer();
            setNewContact();
            setCustomerId();
            setCustomerName();
            setNewCustomerAddress();
            setNewCompanyAddress();
            informationRef.current.resetFields();
            setOrgId();
            setTaskTypeId();
            setDataMemberAssignee([]);
            setTextValue('');
            setDisTancePolyline();
            setContactData([]);
            setStatusSave();
            setCreateFromData();
            setSelectFromAddress();
            setStatuFromsAdd('in');
            setCreateToData();
            setSelectToAddress();
            setStatusToAdd('in');
            setCustomFieldData([]);
            setEditorStaet(EditorState.createEmpty());
            setTriggerTaskDetail((current) => !current);
          }
        },
        onCancel() { },
      });
    }
  };
  const handleCancel = () => {
    setVisible(false);
    informationRef.current.resetFields();
    setNewCustomer();
    setNewContact();
    setCustomerId();
    setCustomerName();
    setNewCustomerAddress();
    setNewCompanyAddress();
    setOrgId();
    setTaskTypeId();
    setDataMemberAssignee([]);
    setTextValue('');
    setDisTancePolyline();
    setEditorStaet(EditorState.createEmpty());
    setAllSrc([]);
    setImgSrc([]);
    setContactData([]);
    setStatusSave();
    setCreateFromData();
    setSelectFromAddress();
    setStatuFromsAdd('in');
    setCreateToData();
    setSelectToAddress();
    setStatusToAdd('in');
    setNumberTask(1);
    setCustomFieldData([]);
    setRefData([])

  };

  useEffect(() => {
    const GetDefaultData = async () => {
      const taskTypeData = await getTaskTypeData('workforce');
      Promise.all([taskTypeData, customerData]).then((values) => {
        //---------------[0]----------------------------
        // setTaskTypeData(_.get(values, '[0]data.taskType'))
        setPriorityData(_.get(values, '[0]data.priority'));
        const filerDefault = _.get(values, '[0]data.priority').filter((item) => {
          return item.isDefault === true;
        });
        setDefaultPriority(_.get(filerDefault, '[0]'));
        setTeamData(_.get(values, '[0]data.team'));
        //---------------[1]----------------------------
      });
    };
    GetDefaultData();
  }, []);

  useEffect(() => {
    const getCustomerAll = async () => {
      if (visible === true) {
        const dataCustomer = await getCustomerData(10000, 'workforce');
        setCustomerData(_.get(dataCustomer, 'data.customers'));
      }
    };
    getCustomerAll();
  }, [triggerCustomer, visible]);

  useEffect(() => {
    const getCustomerCotactLocation = async () => {
      if (customerId) {
        const dataContact = await getCustomerLocationAndConatact(customerId, 'workforce');
        setContactData(_.get(dataContact, 'data.data.contactCustomer'));
        setFromLocationData(_.get(dataContact, 'data.data.fromLocations'));
        setToLocationData(_.get(dataContact, 'data.data.toLocations'));
      }
    };
    getCustomerCotactLocation();
  }, [customerId, triggerContact, triggerCustomerAddress, triggerCompanyAddress]);

  useEffect(() => {
    const CustomeField = async () => {
      const responseCustom = await getCustomeField('task_type', taskTypeId, 'workforce');
      setCustomFieldData(responseCustom.data);
    };
    CustomeField();
  }, [taskTypeId]);

  const handleSaveContactTemporary = () => {
    confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmCustomerContact`,
        defaultMessage: 'Are you sure to save customer contact.',
      }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const filterData =
          contactData &&
          contactData.filter((item) => {
            return item.customerContactId === 'temporary';
          });
        const body = {
          customerId: customerId,
          customerContactName: _.get(filterData, '[0].customerContactName'),
          customerContactEmail: _.get(filterData, '[0].customerContactEmail'),
          customerContactPhone: _.get(filterData, '[0].customerContactPhone'),
          position: _.get(filterData, '[0].position'),
          remark: _.get(filterData, '[0].remark'),
        };
        const response = await addContact(body);
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setTriggerContact((current) => !current);
          setNewContact(response.data.data.customerContactId);
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  const saveFromAddress = async () => {
    setVisibleAddNewCompany(true);
    setStatuFromsAdd('out');
  };

  const saveToAddress = async () => {
    setVisibleAddNewCustomer(true);
    setStatusToAdd('out');
  };

  return (
    <>
      <CreateTaskContext.Provider
        value={{
          CreateState: {
            taskTypeData,
            priorityData,
            teamData,
            defaultPriority,
            customerData,
            contactData,
            newCustomer,
            triggerContact,
            newContact,
            fromLocationData,
            toLocationData,
            disTancePolyline,
            newCustomerAddress,
            newCompanyAddress,
            visibleAssignee,
            orgId,
            taskTypeId,
            member,
            dataMemberAssignee,
            customFieldData,
            editorStaet,
            imgSrc,
            allSrc,
            disbleEditor,
            textValue,
            temporaryData,
            statusSave,
            selectFromAddress,
            createFromData,
            selectToAddress,
            createToData,
            filterAssignee,
            filterAvailable,
            filterCandidate,
            filterOff,
            filterOnGoing,
            pageAssigneeAvailable,
            pageSizeAssigneeAvailable,
            pageAssigneeNotAvailable,
            pageSizeAssigneeNotAvailable,
            selectTab,
            searchUnassignee,
            fieldSortAvailable,
            orderSortAvailable,
            fieldSortNotAvailable,
            orderSortNotAvailable,
            memberAvailable,
            memberNotAvailable,
            memberAssignee,
            filterNotAvailable,
            memberAvailableArrray,
            memberNotAvailableArrray,
            loadingAvailable,
            loadingNotAvailable,
            refData,
          },
          CreateSetState: {
            setContactData,
            setVisibleCustomer,
            setVisibleContact,
            setCustomerId,
            setDisTancePolyline,
            setVisibleAddCustomerAddress,
            setCustomerName,
            setVisibleEditCustomerAddress,
            setEditTolocationData,
            setVisibleAddCompanyAddress,
            setVisibleEditComapanyAddress,
            setEditFromLocationData,
            setVisibleAssignee,
            setOrgId,
            setTaskTypeId,
            setMember,
            setDataMemberAssignee,
            setEditorStaet,
            setImgSrc,
            setAllSrc,
            setDisbleEditor,
            setTextValue,
            setContactData,
            setTemporaryData,
            setStatusSave,
            setSelectFromAddress,
            setSelectToAddress,
            setTaskTypeData,
            setCustomFieldData,
            setFilterAssignee,
            setFilterAvailable,
            setFilterCandidate,
            setFilterOff,
            setFilterOnGoing,
            setPageSizeAssigneeAvailable,
            setPageAssigneeAvailable,
            setPageAssigneeNotAvailable,
            setPageSizeAssigneeNotAvailable,
            setSearchUnassignee,
            setSelectTab,
            setFieldSortAvailable,
            setOrderSortAvailable,
            setFieldSortNotAvailable,
            setOrderSortNotAvailable,
            setMemberAvailable,
            setMemberNotAvailable,
            setMemberAssignee,
            setFilterNotAvailable,
            setMemberAvailableArrray,
            setMemberNotAvailableArrray,
            setLoadingAvailable,
            setLoadingNotAvailable,
            setRefData,
          },
          CreateFNC: {
            handleSaveContactTemporary,
            saveFromAddress,
            saveToAddress,
          },
        }}
      >
        <Modal
          title={intl.formatMessage({
            id: 'createTaskMntTilteCreate',
            defaultMessage: 'Create a Task',
          })}
          centered={true}
          onOk={handleCreate}
          onCancel={handleCancel}
          visible={visible}
          bodyStyle={{ padding: 'unset', height: '98%' }}
          width={1150}
          footer={
            <div>
              <div>
                <div>
                  <div
                    style={{
                      float: 'left',
                      lineHeight: '32px',
                      paddingRight: '5px',
                      height: '32px',
                      paddingLeft: '4px',
                    }}
                  >
                    <FormattedMessage id="monitorCreateTaskTxtCopiesTask" defaultMessage="Copies (Max 10)" /> :
                  </div>
                  <div style={{ float: 'left' }}>
                    <InputNumber
                      min={1}
                      type="number"
                      size="default"
                      className="create-task-copies-task-number "
                      max={10}
                      value={numberTask}
                      onChange={(e) => setNumberTask(e)}
                    />
                  </div>
                </div>
                <div>
                  <Button02 key="back" onClick={handleCancel} style={{ margin: 'unset' }}>
                    <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                  </Button02>
                  <Button01 key="submit" type="primary" onClick={() => handleCreate()} id="saveButton">
                    <FormattedMessage id="btnSave" defaultMessage="Save" />
                  </Button01>
                </div>
              </div>
            </div>
          }
        >
          <Row className="create-task-body-panel">
            <Col span={18} className="create-task-boder-div-style">
              <Tabs className="create-task-tab-tabpane">
                <TabPane tab={intl.formatMessage({ id: 'createTaskMntTabInformation', defaultMessage: 'Information' })} key="1">
                  <TabInformation ref={informationRef} />
                </TabPane>
                <TabPane tab={intl.formatMessage({ id: 'createTaskMntTabMoreDetails', defaultMessage: 'More Details' })} key="2">
                  <TabMoreDetail />
                </TabPane>
              </Tabs>
            </Col>
            <Col span={6}>
              <div className="create-task-border-style">
                <span className="create-task-text-status">
                  <FormattedMessage id="createTaskMntLBStatus" defaultMessage="Status" />
                </span>
                <Tag color="#1890ff" className="create-task-tag-status">
                  <FormattedMessage id="createTaskMntLBNew" defaultMessage="New" />
                </Tag>
              </div>
              <Assignee />
            </Col>
          </Row>
        </Modal>

        <ModalAssignee />
        {visibleCustomer && (
          <AddBusinessPartner
            visible={visibleCustomer}
            setVisible={setVisibleCustomer}
            setCustomerData={setNewCustomer}
            setTrigger={setTriggerCustomer}
          />
        )}

        {visibleContact && (
          <AddContact
            visible={visibleContact}
            setVisible={setVisibleContact}
            customerId={informationRef.current && informationRef.current.getFieldValue(`customer`)}
            setTriggerAPI={setTriggerContact}
            setContactID={setNewContact}
            setTemporaryData={setTemporaryData}
          />
        )}

        <CompanyLocation
          visible={visibleAddCompanyAddress}
          setVisible={setVisibleAddCompanyAddress}
          selectAddress={selectFromAddress}
          setSelectAddress={setSelectFromAddress}
          setCreateFromData={setCreateFromData}
          fromOutSide={fromOutSide}
          setFromOutSide={setFromOutSide}
          trigger={triggerFrom}
          setTrigger={setTriggerFrom}
          visibleAdd={visibleAddNewCompany}
          setVisibleAdd={setVisibleAddNewCompany}
          statusFromAdd={statusFromAdd}
          setStatuFromsAdd={setStatuFromsAdd}
          keyMenu={keyMenu}
          setKeyMenu={setKeyMenu}
          createFromData={createFromData}
          center={centerFrom}
          setCenter={setCenterFrom}
          position={positionFrom}
          setPosition={setPositionFrom}
          visibleInfoWindow={visibleInfoWindowFrom}
          setVisibleInfoWindow={setVisibleInfoWindowFrom}
        />

        <CustomerLocation
          visible={visibleAddCustomerAddress}
          setVisible={setVisibleAddCustomerAddress}
          selectAddress={selectToAddress}
          setSelectAddress={setSelectToAddress}
          setCreateFromData={setCreateToData}
          fromOutSide={toOutSide}
          setFromOutSide={setToOutSide}
          trigger={triggerTo}
          setTrigger={setTriggerTo}
          visibleAdd={visibleAddNewCustomer}
          setVisibleAdd={setVisibleAddNewCustomer}
          statusFromAdd={statusToAdd}
          setStatuFromsAdd={setStatusToAdd}
          customerId={customerId}
          customerName={customerName}
          keyMenu={keyMenuTo}
          setKeyMenu={setKeyMenuTo}
          createToData={createToData}
          center={centerTo}
          setCenter={setCenterTo}
          position={positionTo}
          setPosition={setPositionTo}
          visibleInfoWindow={visibleInfoWindowTo}
          setVisibleInfoWindow={setVisibleInfoWindowTo}
        />
      </CreateTaskContext.Provider>
    </>
  );
});
