import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Icon, Modal, Row, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import { messageLabel } from '../../../../components/message-component';
import moment from 'moment';
import { getItemCheckStock, getQuotationById, getQuotationList } from '../../../../controllers/quotation/quotation-api-new';
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal';
import CustomTableComponent from '../../components/table';
import Input from '../../../../components/v2/input';
import { v4 as uuidv4 } from 'uuid';
import LabeRequireForm from '../../../../components/label-required-form';
import { SelectCustomerHook } from '../select-customer-new/selectHook';
import ListItemFilter from './filter';
import { getSaleOrderPendingList } from '../../../../controllers/account-receive';
import { AccountReceiveContext, InvoiceContext } from '../../context';
import { getInvoiceList } from '../../../../controllers/invoice-new/invoice-api';

const { Option } = Select;

const SelectQuotationModal = ({ form, title, visible, onOk, onCancel, setDataForm, dataForm, setSelectItem, selectItem }) => {
  const [reportDataPending, setReportDataPending] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
    issueDateStart: '',
    issueDateEnd: '',
  });
  const clearReport = reportDataPending;
  console.log('dataFormCus', dataForm);
  const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = form;
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer } = SelectCustomerHook();
  const [searchGroup, setSearchGroup] = useState([]);
  const [event, setEvent] = useState([]);
  const { state, setState, func } = useContext(AccountReceiveContext);
  // const { setDefaultSaleOrder } = setState;
  // const { handleOpenModalQuotationPending } = func;

  const intl = useIntl();

  console.log('reportDataPending', reportDataPending);

  useEffect(() => {
    setReportDataPending((prev) => ({ ...prev, loading: true }));

    const mapCustomerID = _.map(selectItem, (item) => {
      return _.get(item, 'invoiceId');
    });

    const getApi = async () => {
      const payload = {
        isCreateBilling: true,
        tabCode: 'pending',
        startDate: _.get(reportDataPending, 'issueDateStart')
          ? moment(_.get(reportDataPending, 'issueDateStart')).format('YYYY-MM-DD')
          : undefined,
        endDate: _.get(reportDataPending, 'issueDateEnd')
          ? moment(_.get(reportDataPending, 'issueDateEnd')).format('YYYY-MM-DD')
          : undefined,
        pageNumber: pageTable,
        limit: sizeTable,
        orderBy: _.get(reportDataPending, 'fieldSort'),
        orderType: _.get(reportDataPending, 'orderSort'),
        paymentTermNo: _.get(reportDataPending, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataPending, 'saleOrderNo'),
        customerId: _.get(reportDataPending, 'customerName') ? [_.get(reportDataPending, 'customerName')] : [],
        invoiceNo: _.get(reportDataPending, 'invoiceNo') ? _.get(reportDataPending, 'invoiceNo') : '',
        statusCode: ['waiting'],
        invoiceIdNin: mapCustomerID ? mapCustomerID : undefined,
        type: 'invoice',
      };
      const response = await getInvoiceList(payload);

      setReportDataPending((prev) => ({
        ...prev,
        quotationList: _.get(response, 'data.invoiceList'),
        quotationTotal: _.get(response, 'data.totalItem'),
      }));
    };
    setReportDataPending((prev) => ({ ...prev, loading: false }));
    if (visible === true) {
      getApi();
    }
  }, [
    visible,
    _.get(reportDataPending, 'paginationPage'),
    _.get(reportDataPending, 'paginationSize'),
    _.get(reportDataPending, 'fieldSort'),
    _.get(reportDataPending, 'orderSort'),
    _.get(reportDataPending, 'statusCode'),
    _.get(reportDataPending, 'customerName'),
    pageTable,
    sizeTable,
    event,
  ]);

  // useEffect(() => {
  //   if (visible === true) {
  //     handleSearchCustomer(_.get(dataForm, 'customerId'));
  //   }
  // }, [visible]);

  useEffect(() => {
    if (getFieldValue('customerName')) {
      const onChange = async (value, code) => {
        setReportDataPending({ ...reportDataPending, [code]: value });
      };
      onChange(getFieldValue('customerName'), 'customerName');
    }
  }, [getFieldValue('customerName')]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('invoiceLabelINVNo', 'INV No.'),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('invoiceLabelSONo', 'SO No.'),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      // width: 200,
    },
    {
      title: messageLabel('BLDueDate', 'Due Date'),
      dataIndex: 'dueDateTxt',
      key: 'dueDateTxt',
      sorter: true,
      // render: (text, record, index) => _.get(record, 'customerName'),
    },
    {
      title: messageLabel('invoiceLabelIssued Date', 'Issued Date'),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: true,
    },
    {
      title: messageLabel('BLAmount', 'Amount'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      width: 150,
      align: 'right',
      // render: (text, record, index) => (
      //   // _.get(record, 'total') ? (
      //   <span>{numberFormatDecimal(_.get(record, 'totalTxt'))}</span>
      // ),
      // ) : null,
    },
    // {
    //   title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
    //   dataIndex: 'issueBy',
    //   key: 'issueBy',
    //   sorter: true,
    //   // render: (text, record, index) => '',
    // },
    {
      title: messageLabel('BLRemark', 'Remark'),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      render: (text, record, index) => _.get(record, 'remark') || '-',
    },
  ];

  const handleClose = () => {
    onCancel();
    setReportDataPending({
      quotationList: [],
      quotationTotal: 0,
      extraSort: undefined,
      fieldSort: '',
      orderSort: 'asc',
      paginationPage: 1,
      paginationSize: 10,
      loading: false,
      quotationNo: '',
      invoiceNo: '',
      paymentTermNo: '',
      saleOrderNo: '',
      customerName: '',
      rangePickerDate: [],
      statusCode: [],
      issueDateStart: '',
      issueDateEnd: '',
    });
    setSearchGroup();
  };

  const onSelectQuotationRow = async (value) => {
    let temp = [...selectItem];
    temp.push(value);
    const mapselectitem = _.map(temp, (item, i) => {
      return {
        ...item,
        index: i + 1,
      };
    });

    setSelectItem(mapselectitem);
    // setSelectItem((prev) => [...prev, value]);
    onCancel();
  };

  const handleSave = async (value) => {
    // handleOpenModalQuotationPending(value);
    onCancel();
  };

  const handleReplace = (value) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblReplace', defaultMessage: 'Are you sure you want to replace ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        if (value) {
          console.log('saleselectvalue', value);
          // handleOpenModalQuotationPending(value);
          onCancel();
        }
      },
      onCancel() {},
    });
  };

  const handleChange = (value, key) => {
    setReportDataPending((prev) => ({ ...prev, [key]: value }));
  };

  const onAllowClear = (value, code) => {
    if (value) {
      setReportDataPending({ ...reportDataPending, [code]: '' });
    }
  };

  const onClick = () => {
    setEvent((prev) => !prev);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'BLAddInvoice', defaultMessage: 'Add Invoice' })}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1120}
      zIndex={1000}
      centered={true}
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className="listItemTextHeader" style={{ padding: '0px 16px 16px 0px' }}>
            <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
          </div>
          <div className="listItemDividerCenter">
            <ListItemFilter onChange={handleChange} onAllowClear={onAllowClear} searchGroup={reportDataPending} onClick={onClick} />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader" style={{ paddingTop: '0px' }}>
            <FormattedMessage id="BLAddInvoiceTitle" defaultMessage="Invoice" />
          </div>
          <div style={{ height: '625px', padding: '16px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <Form form={form} layout="vertical" className="po-form">
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })} req={false} />
                  }
                  style={{ width: '300px' }}
                >
                  {getFieldDecorator('customerName', {
                    initialValue: _.get(dataForm, 'customerId'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'invoiceLabelCustomerPlaceholder', defaultMessage: 'please select customer' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'purchesModalFormPlaceholderVendor',
                        defaultMessage: 'Select Vendor or Supplier',
                      })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      style={{ width: '400px' }}
                      onSearch={handleSearchCustomer}
                    >
                      {customerList && customerList.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </Row>
            <CustomTableComponent
              columns={columns}
              rowKey={(record) => record.quotationId}
              dataSource={_.get(reportDataPending, 'quotationList')}
              // scroll={{ x: true }}
              total={_.get(reportDataPending, 'quotationTotal')}
              role={false}
              onRow={false}
              onRowClick={(o) => onSelectQuotationRow(o)}
              paginationPage={pageTable}
              setPaginationPage={setPageTable}
              paginationShow={sizeTable}
              setPaginationSize={setSizeTable}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

SelectQuotationModal.defaultProps = {
  // const intl = useIntl();
  // title: ,
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalSelectinvoiceForm = Form.create({ name: 'select_qt_Form' })(SelectQuotationModal);

export default ModalSelectinvoiceForm;
