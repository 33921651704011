import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Icon, Divider, Select, Avatar, Input, Col, Row, Badge, Spin } from 'antd';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import WriteHashTag from '../post-component/post-hash-tag';
import './css/index.css';
import { FormattedMessage, useIntl } from 'react-intl';
import PostAddress from '../post/post-address';
import ModalAddCustomer from '../add-business-partner/index';
import ModalSelectLocation from '../modal-select-location/index';
import Resizer from 'react-image-file-resizer';
import { Preview01, Preview02, Preview03, Preview04, Preview05, PreviewPlus, PreviewSingle } from '../preview-picture/preview-picture.js';
import { v4 as uuidv4 } from 'uuid';
import { RenderTag } from '../post-component/post-tag';
import _, { set } from 'lodash';
import { errorNotification } from '../v2/notification';

const { Option } = Select;
const { TextArea } = Input;

export default ({ form, value, fnc }) => {
  const intl = useIntl();
  const imageRef = useRef();
  const fileRef = useRef();

  const [text, setText] = useState('');

  const {
    customer,
    customerList,
    visibleAddPost,
    visibleModalCustomer,
    visibleModalLocation,
    customerId,
    selectedFilesBlob,
    selectedImageBlob,
    addressData,
    message,
    locationData,
    center,
    position,
    visibleInfo,
    selectAddress,
    dragMarker,
    latLng,
    visibleCreateAddress,
    toLocation,
    fieldChange,
    valueInput,
    triggerApi,
    loading,
    checked,
    selectAddressData,
    customerLoading,
    customerSelect,
  } = value;
  const {
    setTriggerCustomer,
    handleCancelPost,
    handleCreatePost,
    setVisibleModalCustomer,
    setVisibleModalLocation,
    setCustomerId,
    setSelectedFilesBlob,
    setSelectedImageBlob,
    setCustomer,
    setAddressData,
    setMessage,
    setLocationData,
    setCenter,
    setPosition,
    setVisibleInfo,
    setSelectAddress,
    setDragMarker,
    setLatLng,
    setVisibleCreateAddress,
    setToLocation,
    setFieldChange,
    setValueInput,
    setTriggerApi,
    setChecked,
    setSelectAddressData,
    handleCustomerPopupScroll,
    handleCustomerChangeSearch,
    handleCustomerChange,
    handleCustomerChangeSelect,
    setNewCustomer,
    setCustomerDataNew,
    setCustomerSelect,
  } = fnc;

  const showOpenFileDialog = (e) => {
    imageRef.current.value = null;
    if (loading !== true) {
      imageRef.current.click();
    }
  };

  const showOpenFileDialogFile = () => {
    fileRef.current.value = null;
    if (loading !== true) {
      fileRef.current.click();
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChangePicture = async (e) => {
    // console.log('handleChangePicture:::', e);
    let newArr = [...selectedImageBlob];
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = await resizeFile(files[i]);
      const fileResize = dataURLtoFile(file, files[i].name);

      newArr.push({
        attachmentId: uuidv4(),
        description: '',
        pathImage: URL.createObjectURL(fileResize),
        file: fileResize,
      });
    }
    setSelectedImageBlob(newArr);
    Array.from(newArr).map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    );
  };

  // const handleChangePicture = async (e) => {
  //   let newArr = [...selectedImageBlob];
  //   const files = e.target.files[0];
  //   console.log('handleChangePicture files:::', files);
  //   const file = await resizeFile(files);
  //   console.log('handleChangePicture file:::', files);
  //   const fileResize = dataURLtoFile(file, files.name);
  //   console.log('handleChangePicture fileResize:::', fileResize);
  //   for (let i = 0; i < fileResize.length; i++) {
  //     newArr.push(
  //       {
  //         attachmentId: uuidv4(),
  //         description: '',
  //         pathImage: URL.createObjectURL(fileResize[i]),
  //         file: fileResize[i],
  //       }

  //     )
  //   }
  //   console.log('handleChangePicture newArr:::', newArr);
  //   setSelectedImageBlob(newArr);

  //   Array.from(files).map(
  //     (file) => URL.revokeObjectURL(file) // avoid memory leak
  //   );
  // }

  const handleChangeFile = (e) => {
    let newArr = [...selectedFilesBlob];
    const file = e.target.files;

    if (file) {
      let fileMin = _.filter(file, (el) => el.size <= 2097152);

      if (file.length !== fileMin.length) {
        let fileCount = file.length - fileMin.length;
        errorNotification(
          intl.formatMessage({
            id: 'errorImageAndFileMoreThan2MB',
            defaultMessage: 'Upload failed, please upload a file that is less than 2 MB.',
          })
        );
      }

      for (let i = 0; i < fileMin.length; i++) {
        if (fileMin[i].name.match(/\.(pdf|PDF)$/) && fileMin[i].size <= 2097152) {
          newArr.push({
            attachmentId: uuidv4(),
            description: '',
            file: fileMin[i],
            name: fileMin[i].name,
          });
          setSelectedFilesBlob(newArr);
        }
      }
    }
  };
  const removeFile = (val) => {
    let newArr = [...selectedFilesBlob];
    const remove = _.filter(newArr, (item) => {
      return item.attachmentId !== val;
    });
    setSelectedFilesBlob(remove);
  };

  const handleTags = (data) => {
    if (_.get(data, 'file')) {
      const fileTag = URL.createObjectURL(data.file);
      let isPDF = /pdf/g.test(data.name.toLowerCase());
      if (isPDF) return window.open(fileTag, '_blank');
    }
  };

  const renderTagFile = (fileList, removeFile) => {
    return (
      <div className="post-tags-view-modal">
        <div className="post-tags-view">
          {fileList && fileList.map((item) => <RenderTag fileList={item} handleTags={handleTags} handleRemove={removeFile} />)}
        </div>
      </div>
    );
  };

  const renderPreview = (imageList) => {
    let sum = imageList.length;

    switch (sum) {
      case 1:
        return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} />;
    }
  };

  const handlePreviewImage = (data) => {
    setChecked('actionView');
  };

  const handleDeleteSingleImage = (data) => {
    let newArr = [...selectedImageBlob];
    if (data && data.attachmentId) {
      const remove = _.filter(newArr, (item) => {
        return item.attachmentId !== data.attachmentId;
      });
      setSelectedImageBlob(remove);
    }
  };

  const handleBack = (data) => {
    setChecked('default');
  };

  const onChangeSelect = (val) => {
    setCustomerId(val);
    setAddressData();
    setSelectAddress([]);
  };

  const handleChangeHashTag = (val) => {
    let repl = val.replace(/(^|\W)(#[a-z\d][\w-]*)/gi, '$1<p style="color: #1D3557">$2</p>');
    // return(repl);
    setMessage(repl);
  };

  const handleInputChange = (e) => {
    setText(e.target.value);
    // matchHashTags(e.target.value)
  };

  const matchHashTags = (text) => {
    let string = text;
    let regex = /(^|\W)(#[a-z\d][\w-]*)/gi;
    // let regex = /#(\w*[0-9a-zA-Z]+\w*[0-9a-zA-Z])/gi;
    let matches = string.matchAll(regex);

    for (var match of matches) {
      string = string.replace(match[0], <b style={{ color: '#1D3557' }}>{match[0]}</b>);
      setText(string);
    }
  };

  const onChangeMessage = async (text) => {
    // console.log('onChangeMessage::: ', text);
    setMessage(text);
  };

  // const matchHashTags = (text) => {
  //   let hashTags, i, len, word, words;
  //   words = text.split(/[\s\r\n]+/);
  //   hashTags = [];

  //   // console.log('onChangeMessage matches::: ', matches);

  //   for (i = 0, len = words.length; i < len; i++) {
  //       word = words[i];
  //       if (word.indexOf('#') === 0) {
  //         hashTags.push(word);
  //       }
  //   }
  //   // hashTags.replace(/(^|\s)#[a-zA-Z0-9][\w-]*\b/g, "$1<span>$2</span>");
  //   console.log('onChangeMessage hashTags::: ', hashTags);
  //   // setMessage(hashTags)
  // }

  const Hashtag = (text) => {
    let inputText = text;
    if (inputText) {
      inputText = inputText.split(/(\s)/g).map((item, i) => {
        if (/(^|\s)#[a-zA-Z0-9ก-ฮ๑-๙เแโไใ]+/g.test(item)) {
          return (
            <span key={i} style={{ color: '#1D3557' }}>
              {item}
            </span>
          );
        }
        return item;
      });
      return inputText;
    }
  };

  const onChangeCommentImg = (e, item, index) => {
    let newArr = [...selectedImageBlob];
    if (e.target.value.length >= 0) {
      newArr[index].description = e.target.value;
    }

    setSelectedImageBlob(newArr);
  };

  const handleClickLocation = () => {
    if (addressData) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(addressData, 'lat')}, ${_.get(addressData, 'lng')}`, '_blank');
    }
  };

  const handleAddress = (data) => {
    if (data) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'addressLat')}, ${_.get(data, 'addressLng')}`, '_blank');
    }
  };

  const handleCloseAddress = (data) => {
    if (data.addressId === addressData.addressId) {
      setAddressData();
      setDragMarker();
      setVisibleInfo(false);
      setSelectAddressData();
      setSelectAddress([]);
    }
  };

  const handleOpenModal = () => {
    if (customerSelect !== undefined && loading !== true) {
      setVisibleModalLocation(true);
      setDragMarker();
      setVisibleInfo(false);
    } else {
      setVisibleModalLocation(false);
    }
  };

  // const handleChangeHashTag = (e) => {
  //   // setMessage(e.target.value)
  //   let text = e.target.value;

  //   let regex = /\W(\#[a-zA-Z]+\b)(?!;)/gm;
  //   const txtStr = text.replace(regex);
  //   const strRender = text.split(/(\s)/g).map((item, i) => {
  //     if (/(^|\s)#[a-zA-Z0-9ก-ฮ๑-๙เแโไใ]+/g.test(item)) {
  //       return (
  //         <span key={i} style={{ color: '#1D3557' }}>
  //           {item}
  //         </span>
  //       );
  //     }
  //     return item;
  //   });

  //   strRender.forEach((element, index) => {
  //     if (_.isObject(element)) {
  //       strRender[index] = `<span style="color:#1D3557">${element.props.children}</span>`
  //     }
  //   });

  //   const arrayToText = strRender.join('');
  //   const textMaster = arrayToText.replace(/(<([^>]+)>)/gi, "");

  //   // setHtml(arrayToText);
  //   setMessage(textMaster)
  // }

  // console.log('image blob', selectedImageBlob)
  const nameLocal = localStorage.getItem('name');

  return (
    <div>
      <Modal
        className="engagement-modal-create-view-edit"
        width={650}
        title={intl.formatMessage({ id: 'engagementTextCreatePost', defaultMessage: 'Create Post' })}
        visible={visibleAddPost}
        onOk={handleCreatePost}
        onCancel={handleCancelPost}
        footer={[
          <div className="modal-create-post-footer-group">
            <div className="modal-create-post-footer-icon">
              <div style={{ paddingRight: '18px' }}>
                <Icon type="camera" onClick={showOpenFileDialog} style={{ color: '#1D3557' }} />
              </div>
              <div style={{ paddingRight: '18px' }}>
                <Icon
                  type="environment"
                  style={
                    customerSelect !== undefined ? { cursor: 'pointer', color: '#1D3557' } : { cursor: 'not-allowed', color: '#1D3557' }
                  }
                  onClick={handleOpenModal}
                />
              </div>
              <div>
                <Icon type="file-text" onClick={showOpenFileDialogFile} style={{ color: '#1D3557' }} />
              </div>
            </div>
            <div>
              <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelPost} disabled={loading === true ? true : false}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>
              <Button01
                key="submit"
                type="primary"
                btnsize="wd_df"
                disabled={
                  customerSelect === undefined ||
                    (_.size(selectedFilesBlob) === 0 &&
                      _.size(selectedImageBlob) === 0 &&
                      _.get(addressData, 'addressId') === undefined &&
                      _.size(message) === 0) ||
                    loading === true
                    ? true
                    : false
                }
                onClick={handleCreatePost}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>
            </div>
          </div>,
        ]}
      >
        <Spin spinning={loading}>
          <div className="engagement-modal-body">
            <input
              ref={imageRef}
              type="file"
              multiple
              style={{ display: 'none' }}
              accept=".jpg, .png, .JPG, .PNG"
              onChange={handleChangePicture}
            />
            <input ref={fileRef} type="file" multiple style={{ display: 'none' }} accept=".pdf" onChange={handleChangeFile} />

            <div className="engagement-modal-create-post-select">
              <Select
                className="customer-select-post-engage"
                placeholder={
                  <div className="customer-select-post-engage-placeholer">
                    <Avatar size="large" src="https://manager.rapidtech.app/img/picUserDefault" />
                    <span className="engage-placeholer-select">
                      <FormattedMessage id="engagementTextSelectCustomer" defaultMessage="Select Customer" />
                    </span>
                  </div>
                }
                size="small"
                showSearch
                value={customerSelect}
                defaultActiveFirstOption={false}
                filterOption={false}
                loading={customerLoading}
                onPopupScroll={handleCustomerPopupScroll}
                onSearch={handleCustomerChangeSearch}
                onChange={handleCustomerChange}
                onSelect={handleCustomerChangeSelect}
                dropdownStyle={{ width: '200px' }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setVisibleModalCustomer(true)}
                    >
                      <Icon type="plus" className="customer-icon-plus" />
                      <span className="text-add-customer">
                        <FormattedMessage id="engagementTextAddCustomer" defaultMessage="Add Customer" />
                      </span>
                    </div>
                  </div>
                )}
              >
                {customerList &&
                  customerList.map((item, index) => (
                    <Option key={index} value={item.customerId}>
                      <Row>
                        <Col span={4}>
                          <Avatar className="engagment-working-avatar" size="large" src={item.profileImg} />
                        </Col>
                        <Col span={20}>
                          <div style={{ paddingLeft: '16px' }}>
                            <div>
                              <span>{item.comtomerName}</span>
                            </div>
                            <div>
                              <div>
                                <span className="custype-bustype-engage">
                                  {item.businessType}, {item.customerType}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Option>
                  ))}
              </Select>
            </div>

            <div className="engagement-modal-create-post">
              {/* -------------------------------------------- #hashtag lib เก็บไว้ก่อนๆ -----------------------------*/}
              {/* <WriteHashTag setMessage={setMessage} visible={visibleAddPost} /> */}

              <TextArea
                placeholder="Type a message or hashtag for describe"
                className="text-area-post"
                rows={1}
                onChange={(e) => onChangeMessage(e.target.value)}
                value={message}
                autoSize={{ minRows: 1, maxRows: 4 }}
              ></TextArea>

              {/* <TextField
                id="outlined-multiline-static"
                className="inputText"
                multiline
                onChange={handleInputChange}
                placeholder="Enter text"
              /> */}

              {/* 
              <TextArea
                placeholder='Type a message or hashtag for describe'
                className='text-area-post'
                rows={1}
                onChange={(e) => handleChangeHashTag(e.target.value)}
                // value={message}
                autoSize={{ minRows: 1, maxRows: 4 }}
              />

              <div>{message}</div> */}

              {/* {_.get(addressData, 'address') ?
                <p className='post-card-text-11' onClick={handleClickLocation}> -  at {_.get(addressData, 'address')}</p>
                : null
              } */}

              {_.get(addressData, 'address') && _.get(addressData, 'address') !== 'Address not not found !' ? (
                <PostAddress
                  action="create"
                  postData={{
                    addressLat: _.get(addressData, 'lat'),
                    addressLng: _.get(addressData, 'lng'),
                    addressName: _.get(addressData, 'address'),
                    addressId: _.get(addressData, 'addressId'),
                  }}
                  handleAddress={handleAddress}
                  handleCloseAddress={handleCloseAddress}
                />
              ) : null}

              {selectedFilesBlob && selectedFilesBlob.length > 0 ? renderTagFile(selectedFilesBlob, removeFile) : null}
            </div>

            {selectedImageBlob && selectedImageBlob.length > 0 ? (
              checked === 'default' ? (
                renderPreview(selectedImageBlob)
              ) : (
                <div>
                  <PreviewSingle
                    action="actionView"
                    imageList={selectedImageBlob}
                    handleDeleteSingleImage={handleDeleteSingleImage}
                    onChangeCommentImg={onChangeCommentImg}
                    handleBack={handleBack}
                    type="add"
                  />
                </div>
              )
            ) : null}
          </div>
        </Spin>
      </Modal>
      <ModalAddCustomer
        visible={visibleModalCustomer}
        setTrigger={setTriggerCustomer}
        setVisible={setVisibleModalCustomer}
        setId={setCustomerSelect}
        setCustomerData={setNewCustomer}
        setData={setCustomerDataNew}
      />
      <ModalSelectLocation
        value={{
          visibleModalLocation,
          locationData,
          center,
          position,
          visibleInfo,
          selectAddress,
          dragMarker,
          latLng,
          visibleCreateAddress,
          toLocation,
          fieldChange,
          valueInput,
          triggerApi,
          addressData,
          selectAddressData,
        }}
        fnc={{
          setVisibleModalLocation,
          setAddressData,
          setCenter,
          setPosition,
          setVisibleInfo,
          setSelectAddress,
          setDragMarker,
          setLatLng,
          setVisibleCreateAddress,
          setToLocation,
          setFieldChange,
          setValueInput,
          setTriggerApi,
          setLocationData,
          setSelectAddressData,
        }}
        customer={customerSelect}
      />
    </div>
  );
};
