import React, { useState, useEffect, useContext } from 'react';
import _, { debounce } from 'lodash';
import ActionReportV3 from '../../components/action-report/action-report-v3.jsx';
import CradDefault from '../../components/crad-default/crad-default.jsx';
import LicenseReportTable from './license-report-table.jsx';
import moment from 'moment';
import Button01 from '../../components/v2/button_01.js';
import Button02 from '../../components/v2/button_02';
import httpClient from '../../components/axiosClient';
import { PageSettings } from '../../config/page-settings';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon, Tag, Popover, Row, Modal, Col, Avatar } from 'antd';
import { RiBuildingLine } from 'react-icons/ri';
import { successNotification, errorNotification, warningNotification } from '../../components/v2/notification';
import styles from './css/index.css';
import LicenseSummary from './license-summary';
import getLicense from '../../controllers/license/get-data-license';
import ModalLicense from '../../components/modal-license-module/index';
import getAllTeam from '../../controllers/get-all-team/get-all-team.js';
import NoDataBackground from '../../components/no-data-page/index.js';

// const description = {
//   text1:'cradDefaultTxtDetailDescription1',
//   defaultMessage1: 'Please select a team',
//   text2: 'cradDefaultTxtDetailDescription2',
//   defaultMessage2: 'License will appear here.',
// };

export default function License(props) {
  const {} = props;
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const [visiblePop, setVisiblePop] = useState(false);

  const description = {
    text1: intl.formatMessage({
      id: 'cradDefaultTxtDetailDescription1',
      defaultMessage: 'Please select a team',
    }),
    text2: intl.formatMessage({
      id: 'cradDefaultTxtDetailDescription1',
      defaultMessage: 'License will appear here',
    }),
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '7%', //5%
      align: 'center',
      render: (text, record, index) => record.index,
    },
    {
      title: '',
      dataIndex: 'profileImg',
      key: 'profileImg',
      width: '8%',
      render: (text, record, index) => <Avatar src={record.profileImg} />,
    },
    {
      title: intl.formatMessage({
        id: 'licenseColumnEmployeeID',
        defaultMessage: 'Employee ID',
      }),
      dataIndex: 'empId',
      key: 'empId',
      // align: 'center',
      width: '15%',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'licenseColumnName',
        defaultMessage: 'Name',  
      }),
      dataIndex: 'fullname',
      key: 'fullname',
      width: '20%',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'licenseColumnModule',
        defaultMessage: 'Module',
      }),
      dataIndex: 'moduleName',
      key: 'moduleName',
      render: (text, record, index) => (
        <Row>
          <PageSettings.Consumer>
            {({ checkPermissionAction }) => (
              <div>
                {checkPermissionAction('P12S1PG1C1', 'P12S1PG1C1A3') ? (
                  <div>
                    {record.module &&
                      record.module.map((item) => (
                        <Tag
                          key={item.licenseModuleId}
                          closable
                          className="license-tag"
                          onClose={() => handleDelete(item.licenseModuleId, record)}
                        >
                          {item.moduleName}
                        </Tag>
                      ))}
                  </div>
                ) : (
                  <div>
                    {record.module &&
                      record.module.map((item) => (
                        <Tag key={item.licenseModuleId} className="license-tag">
                          {item.moduleName}
                        </Tag>
                      ))}
                  </div>
                )}
              </div>
            )}
          </PageSettings.Consumer>
        </Row>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '5%',
      render: (text, record, index) => (
        <Popover
          placement="leftTop"
          // trigger="click"
          // visible={visiblePop}
          // onVisibleChange={handleVisiblePop}
          content={
            <>
              <Row>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <>
                      {checkPermissionAction('P12S1PG1C1', 'P12S1PG1C1A2') ? (
                        <Button01
                          ghost
                          btnsize="wd_df"
                          type="link"
                          onClick={() => {
                            setVisibleModalLicense(true);
                            setDataModalLicense(record);
                          }}
                        >
                          <FormattedMessage id="btnAdd" defaultMessage="Add" />
                        </Button01>
                      ) : null}
                    </>
                  )}
                </PageSettings.Consumer>
              </Row>
            </>
          }
        >
          <Icon type="edit" />
        </Popover>
      ),
    },
  ];

  const ignoreSearchColumns = ['index', 'profileImg', 'moduleName', 'action'];

  const app = useContext(PageSettings);
  const [loading, setLoading] = useState(false);
  const [licenseData, setLicenseData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [fieldChang, setFieldChang] = useState('');
  const [fliterByColumn, setFliterByColumn] = useState('all');

  const [teamData, setTeamData] = useState([]);
  const [orgTeamIdArr, setOrgTeamIdArr] = useState([]);
  const [moduleIdArr, setModuleIdArr] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [dataModalLicense, setDataModalLicense] = useState();
  const [visibleModalLicense, setVisibleModalLicense] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [triggerDelete, setTriggerDelete] = useState(false);

  useEffect(() => {
    getDataApi();
  }, [
    triggerUpdate,
    orgTeamIdArr,
    fieldChang,
    fliterByColumn,
    orderSort,
    fieldSort,
    triggerDelete,
    moduleIdArr,
    paginationPage,
    paginationSize,
  ]);

  const getDataApi = async () => {
    const response = await getLicense(
      orgTeamIdArr,
      fieldChang,
      fliterByColumn,
      orderSort,
      fieldSort,
      moduleIdArr,
      paginationPage,
      paginationSize
    );
    if (response.status.code === 200) {
      setSummaryData(response.data.summary);
      setLicenseData(response.data.users.data);
      setTotal(response.data.users.allRows);
    }
  };

  useEffect(() => {
    getTeam();
  }, []);

  const getTeam = async () => {
    const response = await getAllTeam();
    if (response) {
      setTeamData(response);
    }
  };

  const handleDelete = async (key, record) => {
    try {
      const body = {
        licenseModuleId: key,
        memComId: _.get(record, 'memComId'),
      };
      const response = await httpClient.put(`/v3/license/manager/company/${comCode}/deletedatalistlicense`, body);
      if (response.status === 200) {
        successNotification(response.data.status.message);
        setTriggerDelete((current) => !current);
      }
    } catch (error) {
      errorNotification(error.response.data.status.message);
    }
  };

  const onChangeSearch = debounce((value) => {
    setFieldChang(value);
    setPaginationPage(1);
  }, 500);

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChangeTeam = (value) => {
    setPaginationPage(1);
    let dataCreate = [];
    value.forEach((element) => {
      dataCreate.push(element.toString());
    });
    const orgIdArr = dataCreate.toString();
    setOrgTeamIdArr(dataCreate);
  };

  const iconBlankSpace = () => <RiBuildingLine />;

  const handleSelectModule = (value) => {
    setPaginationPage(1);
    let arr = [];
    const findIndex = _.findIndex(moduleIdArr, (o) => {
      return o === value;
    });
    if (findIndex >= 0) {
      setModuleIdArr([]);
    } else {
      arr.unshift(value);
      setModuleIdArr(arr);
    }
  };

  return (
    <div className="license">
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {checkPermissionAction('P12S1PG1C1', 'P12S1PG1C1A1') ? (
              <div className="license-container">
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <LicenseSummary
                      data={summaryData}
                      blank={orgTeamIdArr}
                      handleSelectModule={handleSelectModule}
                      moduleIdArr={moduleIdArr}
                    />
                  </Col>
                  <Col span={18}>
                    <Card
                      className="license-card"
                      title={intl.formatMessage({ id: 'companyInformationCardLicense', defaultMessage: 'License' })}
                    >
                      <div className="action-license-container">
                        <ActionReportV3
                          value={{
                            columns,
                            ignoreSearchColumns,
                            fliterByColumn,
                            teamData,
                            orgTeamIdArr,
                          }}
                          fnc={{
                            onChangeSearch,
                            onFliterByColumn,
                            handleChangeTeam,
                          }}
                        />
                      </div>
                      {orgTeamIdArr.length === 0 && moduleIdArr.length === 0 && fieldChang === '' ? (
                        // <CradDefault description={description} iconBlankSpace={iconBlankSpace} />
                        <NoDataBackground
                          text1={{ id: 'nodataXLicense1', text: 'Please select a Module' }}
                          text2={{ id: 'nodataXLicense2', text: 'License will appear here.' }}
                        />
                      ) : (
                        <LicenseReportTable
                          loading={loading}
                          dataSource={licenseData}
                          columns={columns}
                          total={total}
                          paginationPage={paginationPage}
                          fnc={{
                            handleChange,
                            handlePagination,
                            handleSizeChange,
                          }}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>

                <ModalLicense
                  visible={visibleModalLicense}
                  setVisible={setVisibleModalLicense}
                  data={dataModalLicense}
                  trigger={triggerUpdate}
                  setTrigger={setTriggerUpdate}
                />
              </div>
            ) : null}
          </div>
        )}
      </PageSettings.Consumer>
    </div>
  );
}
