import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import PersonalInformation from './personalInfomation';
import PersonalInformation from './personalInfomation-new';
import PayD from './payD/index';
import EmergencyContact from './emergencyContact';
import EditableTable from './leaves';
import Classification from './payD/classification';
import Team from './team/index';
import EquipmentTools from './equipment-tools/index';
import Skill from './skill';
import Role from './role';
import BasePay from './basePay';
import Document from './document/index';
import GetLang from '../../../includes/language';
import httpClient from '../../../components/axiosClient';
import { Row, Col, Layout } from 'antd';
import { useAppContext } from '../../../includes/indexProvider';
import cssStyle from './css/index.css';
import logoPDF from '../../../pages/login/image/pdf.png';
import _, { random } from 'lodash';
import { PageSettings } from '../../../config/page-settings';
import Signature from './signature';

const pageCode = '001';

export default (props) => {
  const app = useAppContext();
  const params = useParams();
  const companyId = localStorage.getItem('companyId');
  const memComId = localStorage.getItem('memComId'); // monitoring id (admin)
  const loginUser = localStorage.getItem('userId');
  const userId = params.user_id; // user on table id
  const [paydSource, setPaydSource] = useState();
  const [paydDefault, setPaydDefault] = useState();
  const [documentCompany, setDocumentCompany] = useState([]);
  const [document, setDocument] = useState([]);
  const [selectSource, setSelectSource] = useState();
  const [dataCall, setDataCall] = useState();
  const [deleteClass, setDeleteClass] = useState();
  const [memClassAmount, setMemClassAmount] = useState();
  const [checkPayDShow, setCheckPaydShow] = useState();

  // ภาษา
  const comId = localStorage.getItem('comId');
  const [labelShow, setLabelShow] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(1);
  localStorage.setItem('language', JSON.stringify(labelShow));

  useEffect(() => {
    const checkPaydDefault = paydDefault ? paydDefault[1] : null;
    setCheckPaydShow(checkPaydDefault);
  }, [paydSource]);

  useEffect(() => {
    Lang();
    paydData();
  }, [dataCall, deleteClass, refresh, refresh2]);

  const Lang = async () => {
    const res = await GetLang({
      companyId: comId,
      lang: app.state.langValue,
      pageCode: pageCode,
    });
    setLabelShow(res);
  };

  const paydData = async () => {
    const dataSource = await httpClient.get(`/company/user/${userId}`);

    const defaultClassId =
      dataSource.data.PayDSummary.memClassId !== 0
        ? dataSource.data.PayDSummary.memClassId
        : 'Disable';
    const defaultClassName =
      dataSource.data.PayDSummary.memClassId !== 0
        ? dataSource.data.PayDSummary.class
        : 'Disable';
    const defaultMemClassAmount =
      dataSource.data.PayDSummary.memClassId !== 0
        ? dataSource.data.PayDSummary.memClassAmount
        : '';
    setPaydDefault([defaultClassId, defaultClassName]);
    setPaydSource(dataSource);
    setDocumentCompany(dataSource.data.documentCompany);
    setDocument(dataSource.data.documents);
    setSelectSource(dataSource.data.companyClasses);
    setMemClassAmount(defaultMemClassAmount);
  };

  document.map((obj, index) => {
    const fileDoc = {
      uid: index + 1,
      document_id: obj.docId,
      document_name: obj.name,
      original: obj.type === 'pdf' ? logoPDF : obj.path,
      thumbnail: obj.type === 'pdf' ? logoPDF : obj.path,
      thumbUrl: obj.type === 'pdf' ? logoPDF : obj.path,
      url: obj.type === 'pdf' ? logoPDF : obj.path,
      originalPath: obj.path,
      type: obj.type,
    };

    const found = documentCompany.findIndex(
      (ele) => ele.docComId === obj.docComId,
    );
    if (found >= 0) {
      if (!_.isArray(documentCompany[found].files)) {
        documentCompany[found].files = [];
      }
      const checkIsFile = documentCompany[found].files.findIndex(
        (ele) => ele.document_id === fileDoc.document_id,
      );
      if (checkIsFile === -1) {
        documentCompany[found].files.push(fileDoc);
      }
    }
  });

  const functionCallCreate = (value) => {
    const checkValue = value ? value[0] : null;
    setDataCall(checkValue);
  };

  const functionCallDelete = (value) => {
    setDeleteClass(value);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <Row gutter={[16]}>
          <Col span={18}>
            {checkPermissionAction('P2S1PG2C1', 'P2S1PG2C1A1') ? (
              <div className="frame">
                <PersonalInformation
                  user_id={userId}
                  companyId={companyId}
                  memComId={memComId}
                  loginUser={loginUser}
                  labelShow={labelShow}
                />
              </div>
            ) : null}

            {checkPermissionAction('P2S1PG2C9', 'P2S1PG2C9A1') ||
            checkPermissionAction('P2S1PG2C10', 'P2S1PG2C10A1') ||
            checkPermissionAction('P2S1PG2C11', 'P2S1PG2C11A1') ? (
              checkPayDShow ? (
                <div className="frame">
                  <PayD
                    paydSource={paydSource}
                    memComId={memComId}
                    comId={comId}
                    labelShow={labelShow}
                    langValue={app.state.langValue}
                    checkPayDShow={checkPayDShow}
                  />
                </div>
              ) : null
            ) : null}

            {checkPermissionAction('P2S1PG2C2', 'P2S1PG2C2A1') ? (
              <div className="frame">
                <EmergencyContact
                  companyId={companyId}
                  labelShow={labelShow}
                  statusPersanalInformation
                  className="emergency-contact"
                />
              </div>
            ) : null}

            {checkPermissionAction('P2S1PG2C13', 'P2S1PG2C13A1') ? (
              <div className="frame">
                <EditableTable memComId={userId} />
              </div>
            ) : null}

            {checkPermissionAction('P2S1PG2C14', 'P2S1PG2C14A1') ? (
              <div className="frame">
                <EquipmentTools userId={params.user_id} />
              </div>
            ) : null}
          </Col>

          <Col span={6}>
            {/* {checkPermissionAction('P2S1PG2C8', 'P2S1PG2C8A1') ? (
              <div className="frame">
                <Classification
                  userId={params.user_id}
                  // paydSource={paydSource}
                  selectSource={selectSource}
                  createdBy={memComId}
                  // paydDefault={paydDefault}
                  memClassAmount={memClassAmount}
                  functionCallCreate={functionCallCreate}
                  functionCallDelete={functionCallDelete}
                  labelShow={labelShow}
                />
              </div>
            ) : null} */}

            {checkPermissionAction('P2S1PG2C4', 'P2S1PG2C4A1') ? (
              <div className="frame">
                <Team
                  userId={params.user_id}
                  companyId={companyId}
                  createdBy={params.user_id}
                  labelShow={labelShow}
                />
              </div>
            ) : null}

            {checkPermissionAction('P2S1PG2C5', 'P2S1PG2C5A1') ? (
              <div className="frame">
                <Role
                  userId={params.user_id}
                  companyId={companyId}
                  createdBy={params.user_id}
                  labelShow={labelShow}
                />
              </div>
            ) : null}

            {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A1') ? (
              <div className="frame">
                <BasePay
                  userId={params.user_id}
                  companyId={companyId}
                  createdBy={params.user_id}
                  labelShow={labelShow}
                />
              </div>
            ) : null}

            {checkPermissionAction('P2S1PG2C6', 'P2S1PG2C6A1') ? (
              <div className="frame">
                <Skill
                  userId={params.user_id}
                  companyId={companyId}
                  createdBy={params.user_id}
                  labelShow={labelShow}
                />
              </div>
            ) : null}

            {checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A1') ? (
              <div className="frame">
                <Document
                  onChangeFile={(i) => {
                    let ii = refresh + 1;
                    setRefresh(ii);
                  }}
                  onChangeFile2={(index, file) => {
                    let i = refresh2 + 1;
                    setRefresh2(i);
                    paydData();
                  }}
                  userId={params.user_id}
                  companyId={companyId}
                  memComId={params.user_id}
                  labelShow={labelShow}
                  documentCompany={documentCompany}
                />
              </div>
            ) : null}

            {checkPermissionAction('P2S1PG2C15', 'P2S1PG2C15A1') ? (
              <div className="frame">
                <Signature view="view" memComId={params.user_id} />
              </div>
            ) : null}
          </Col>
        </Row>
      )}
    </PageSettings.Consumer>
  );
};
